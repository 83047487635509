import { useState } from "react";
import { Button, message, Row, Col, Form, Input, Switch } from "antd";
import { postData } from "../../../../apiServices/common";
import { BUYING_OFFICE_ADD_FROM_MODAL } from "../../../../apiServices/API_ENDPOINTS";
import CustomDatePicker from "common/CustomDatePicker";
import moment from "moment";

const BuyingOfficeForm = (props) => {
  // Props
  const { onCloseModal, refetch } = props;

  // State
  const [loading, setLoading] = useState(false);

  // Antd
  const [modalForm] = Form.useForm();

  const createNewEntry = async (formValues) => {
    setLoading(true);

    const bodyData = {
      address: formValues?.address,
      business_relation_in_year: formValues?.business_relation_in_year,
      business_relation_since: formValues?.business_relation_since
        ? moment(formValues?.business_relation_since)?.format("YYYY-MM-DD")
        : null,
      contract_number: formValues?.contract_number,
      contract_person: formValues?.contract_person,
      email: formValues?.email,
      liaison_status: formValues?.liaison_status ? true : false,
      name: formValues?.name,
      short_name: formValues?.short_name,
      status: formValues?.status ? true : false,
      website: formValues?.website,
    };

    const response = await postData(BUYING_OFFICE_ADD_FROM_MODAL, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Entry Created Successfully");
      refetch();
      modalForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const onDateChange = (date, dateString) => {
    const yearDifference = moment().diff(moment(dateString), "days");
    const businessRelation = Math.round(yearDifference / 365);
    modalForm.setFieldsValue({
      business_relation_in_year: businessRelation,
    });
  };

  return (
    <Form form={modalForm} layout="vertical" onFinish={createNewEntry}>
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input Buying Office name!",
              },
            ]}
          >
            <Input placeholder={"Ex: Buying Office Name"} size="small" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Short Name" name="short_name">
            <Input placeholder={"Ex: Buying Office Short Name"} size="small" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Address" name="address">
            <Input placeholder="Address" size="small" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Contact Person" name="contract_person">
            <Input placeholder="Contact person" size="small" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Contact Number" name="contract_number">
            <Input placeholder="Contact number" size="small" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Email" name="email">
            <Input placeholder="Email" size="small" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Website" name="website">
            <Input placeholder="Website" size="small" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="business_relation_since"
            label="Business Relation Since"
          >
            <CustomDatePicker
              onChange={onDateChange}
              disabledDate={(current) =>
                current.isAfter(moment().subtract(1, "day"))
              }
              style={{
                width: "100%",
              }}
              size="small"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Business Relation In Year"
            name="business_relation_in_year"
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="status"
            label="Active Status"
            className="collection-create-form_last-form-item"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" size="small" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="liaison_status"
            label="Is Liaison Office"
            className="collection-create-form_last-form-item"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" size="small" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Button
            type="danger"
            style={{ width: "100%" }}
            onClick={() => {
              onCloseModal();
            }}
            size="small"
          >
            Discard
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
            size="small"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BuyingOfficeForm;
