import { Col, Form, DatePicker } from "antd";
import CustomDatePicker from "common/CustomDatePicker";

import moment from "moment";

const BuyerStyleChecklistFilterFields = ({ initialTableData }) => {
  const saveToStorage = (value, name) => {
    const existingFilter =
      JSON.parse(localStorage.getItem("buyer_style_checklist_filter")) || {};
    existingFilter[name] = value;
    localStorage.setItem(
      "buyer_style_checklist_filter",
      JSON.stringify(existingFilter),
    );
  };

  return (
    <>
      <Col span={4}>
        <Form.Item label="Date" name="filter_date">
          <CustomDatePicker
            style={{ width: "100%" }}
            onChange={(value) =>
              saveToStorage(moment(value).format("YYYY-MM-DD"), "start")
            }
            size="small"
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default BuyerStyleChecklistFilterFields;
