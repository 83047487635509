import {
    CREATE_NEW_BUYING_OFFICE,
    DELETE_BUYING_OFFICE,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS, GET_BUYING_OFFICE_DETAIL,
    GET_BUYING_OFFICE_LIST,
    SHOW_MESSAGE,
    UPDATE_BUYING_OFFICE_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_BUYING_OFFICE_ENDPOINT,
    DELETE_BUYING_OFFICE_ENDPOINT, EDIT_BUYING_OFFICE_ENDPOINT,
    GET_BUYING_OFFICE_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetBuyingOfficeList = (props) => {
    const { page, per_page, name, short_name, contract_person } = props;
    
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios.get(`${GET_BUYING_OFFICE_ENDPOINT}`, {
            params: {
                page: page,
                per_page: per_page,
                ...(name && { name: name }),
                ...(short_name && { short_name: short_name }),
                ...(contract_person && { contract_person: contract_person }),
            },
        })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({
                        type: GET_BUYING_OFFICE_LIST,
                        payload: data.data.data,
                    });
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: FETCH_ERROR, payload: error.message });
            });
    };
};

export const onCreateBuyingOffice = (buyingOffice) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_BUYING_OFFICE_ENDPOINT}`, buyingOffice)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_BUYING_OFFICE, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Item Buying Office Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Buying Office Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};
export const onUpdateSelectedBuyingOffice = (bOfcId, buyingOffice) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_BUYING_OFFICE_ENDPOINT}/${bOfcId}`, buyingOffice)

            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_BUYING_OFFICE_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "BuyingOffice Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'BuyingOffice updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};

export const onGetBuyingOfficeDetails = (buyingOfficeId) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`/api/buying_office/${buyingOfficeId}/edit`)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BUYING_OFFICE_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "BuyingOffice Updated Successfully",
                    });
                    // HandleNotification('success', 'bottomRight', 'Buying Office updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    // HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onDeleteBuyingOffice = (buyingOffice) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_BUYING_OFFICE_ENDPOINT}/${buyingOffice.id}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_BUYING_OFFICE, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted Buying Office Successfully",
                    });

                    HandleNotification('success', 'bottomRight', 'Buying Office deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
