import { Fragment, useEffect, useState } from "react";
import { Button, message, Row, Col, Modal, Form, Input, Select } from "antd";
import { getData, postData, putData } from "../../../../apiServices/common";
import {
  INQUIRY_REMARKS_ADD_ENDPOINT,
  INQUIRY_REMARKS_EDIT_ENDPOINT,
} from "../../../../apiServices/API_ENDPOINTS";
import { hasPermission, isArrayAndHasValue } from "../../../../utils/functions";

const InquiryRemarksForm = (props) => {
  // Props
  const { isEdit, onCloseModal, refetch, selectedItem } = props;

  // State
  const [loading, setLoading] = useState(false);

  // Antd
  const [form] = Form.useForm();
  const { Option } = Select;
  const { confirm } = Modal;

  // Constants
  const api_base_url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue({
        id: selectedItem?.id,
        name: selectedItem?.name,
      });
    }
  }, [selectedItem, form]);

  const createNewRemark = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
    };

    const response = await postData(INQUIRY_REMARKS_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Reamrk Created Successfully");
      refetch();
      form.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedRemark = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
    };

    const response = await putData(
      `${INQUIRY_REMARKS_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Remark Edited Successfully");
      refetch();
      form.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={isEdit ? editSelectedRemark : createNewRemark}
    >
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item name="id" hidden />
          <Form.Item
            name="name"
            placeholder="Remark Name"
            label="Remark Name"
            rules={[
              {
                required: true,
                message: "Remark Name Required",
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={6}>
        <Col span={12}>
          <Button
            type="danger"
            style={{ width: "100%" }}
            onClick={() => {
              onCloseModal();
            }}
            size="small"
          >
            Discard
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
            size="small"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InquiryRemarksForm;
