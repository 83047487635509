import { useContext, useEffect } from "react";
import { Col, Form, Input, InputNumber, Row, Table, Typography } from "antd";
import { AssignmentContext } from "context/AssignmentContext/AssignmentContextProvider";
import { commaSeparateNumber } from "utils/functions";

const AssignmentForm = (props) => {
  // Props
  const { isEdit } = props;

  // Contexts
  const { addFormData, setAddFormData } = useContext(AssignmentContext);

  // Antd
  const [assignmentForm] = Form.useForm();

  useEffect(() => {
    if (addFormData) {
      assignmentForm.setFieldsValue({
        style_no: addFormData?.style_info?.style_no,
        internal_po: addFormData?.internal_po,
        original_po: addFormData?.original_po,
        line: addFormData?.line_info?.name,
      });
    }
  }, [addFormData, assignmentForm]);

  const onChangeAssignment = (value, record) => {
    const addFormDataCopy = { ...addFormData };
    const assignmentsCopy = [...addFormDataCopy?.assignments];
    const foundAssignment = assignmentsCopy.find(
      (item) => item.id === record.id,
    );

    foundAssignment["assigned"] = value;
    foundAssignment["remain_assigned"] = foundAssignment["total_remain_assigned"] - value;
    addFormDataCopy["assignments"] = assignmentsCopy;
    setAddFormData(addFormDataCopy);
  };

  const columns = [
    {
      title: "Sub-Style",
      dataIndex: ["sub_style_info", "sub_style_no"],
      key: "sub_style",
    },
    {
      title: "Color",
      dataIndex: ["color_info", "name"],
      key: "color_name",
      width: "20%",
    },
    {
      title: "Size",
      dataIndex: ["size_info", "name"],
      key: "size_name",
    },
    {
      title: "Assigned Qty",
      dataIndex: ["total_assigned"],
      key: "total_assigned",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : 0}</span>;
      },
    },
    {
      title: "Assignment Remaining",
      dataIndex: ["remain_assigned"],
      key: "remain_assigned",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : 0}</span>;
      },
    },
    {
      title: "Cutting Piece",
      dataIndex: ["cutting_piece"],
      key: "cutting_piece",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : 0}</span>;
      },
    },
    {
      title: "Assignment",
      key: "assignment",
      render(_, record) {
        return (
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Assignment"
            size="small"
            onChange={(value) => onChangeAssignment(value, record)}
            value={record?.assigned || 0}
            min={0}
            max={record?.total_remain_assigned}
          />
        );
      },
    },
  ];

  const TableSummary = ({pageData}) => {
    const assignedQtySum = pageData?.reduce((total, item) => {
      return total + item.total_assigned;
    }, 0);
    const remainAssignedQtySum = pageData?.reduce((total, item) => {
      return total + Number(item?.remain_assigned);
    }, 0);
    const cuttingPieceQtySum = pageData?.reduce((total, item) => {
      return total + item?.cutting_piece;
    }, 0);
    const assignmentQtySum = pageData?.reduce((total, item) => {
      return total + Number(item?.assigned || 0);
    }, 0);

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} id="total">
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} id="color" />
          <Table.Summary.Cell index={2} id="size" />
          <Table.Summary.Cell index={3} id="assigned_qty">
            <Typography.Text>{assignedQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} id="assignment_remaining">
            <Typography.Text>{remainAssignedQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} id="cutting_piece">
            <Typography.Text>{cuttingPieceQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} id="assignment">
            <Typography.Text>{assignmentQtySum}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <>
      <Form layout="vertical" form={assignmentForm}>
        <Row gutter={8}>
          <Form.Item name="sales_contract_id" hidden />
          <Form.Item name="buying_office_id" hidden />
          <Form.Item name="buyer_id" hidden />
          <Form.Item name="line_id" hidden />
          <Form.Item name="style_id" hidden />
          <Form.Item name="po_map_id" hidden />
          <Form.Item name="transaction_no_per_day" hidden />

          <Col span={6}>
            <Form.Item name="style_no" label="Style">
              <Input disabled={true} size="small" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="internal_po" label="Internal PO">
              <Input disabled={true} size="small" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="original_po" label="Original PO">
              <Input disabled={true} size="small" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="line" label="Line">
              <Input disabled={true} size="small" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        pagination={false}
        size={"small"}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={addFormData?.assignments}
        columns={columns}
        sticky={true}
        summary={(pageData) => {
          return <TableSummary pageData={pageData} />;
        }}
      />
    </>
  );
};

export default AssignmentForm;
