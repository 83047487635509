import {
    CREATE_NEW_BANK, CREATE_NEW_BRANCH,
    DELETE_BANK,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS, GET_BANK_ACCOUNT_DETAIL, GET_BANK_DETAIL,
    GET_BANK_LIST,
    SHOW_MESSAGE,
    UPDATE_BANK_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_BANK_ENDPOINT, CREATE_BRANCH_ENDPOINT,
    DELETE_BANK_ENDPOINT,
    EDIT_BANK_ENDPOINT,
    GET_BANK_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetBankList = (page, per_page, name) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_BANK_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && { name: name }),
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BANK_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onGetBankDetails = (bankId) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`api/banks/${bankId}/edit`)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BANK_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Bank Updated Successfully",
                    });
                    // HandleNotification('success', 'bottomRight', 'Bank Account updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    // HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onCreateBank = (bank) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_BANK_ENDPOINT}`, bank)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_BANK, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Bank Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'bank Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                console.log('error ', error);
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onUpdateSelectedBank = (bank) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_BANK_ENDPOINT}/${bank.id}`, bank)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_BANK_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "bank Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'bank updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onDeleteBank = (bank) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_BANK_ENDPOINT}/${bank['id']}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_BANK, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted bank Successfully",
                    });

                    HandleNotification('success', 'bottomRight', 'bank deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onCreateBranch = (branch) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_BRANCH_ENDPOINT}`, branch)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_BRANCH, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Bank Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Branch Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                console.log('error ', error);
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};

