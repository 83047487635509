import { useState, useEffect, useCallback } from "react";
import {
  Card,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Select,
  InputNumber,
  Table,
  Typography,
  Divider,
  Space,
  Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  SALES_CONTRACT_LIST,
  STYLE_WISE_COST_ADD,
  STYLE_WISE_COST_VIEW,
  STYLE_WISE_COST_EDIT,
  REST_SALES_CONTRACT,
} from "../../../../apiServices/API_ENDPOINTS";

import {
  PLANNING_REQUESTS,
  UN_PLANNING_BUYER_INQUIRY_LIST,
  USER_LIST,
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData, putData } from "../../../../apiServices/common";

import HandleNotification from "../../../../common/Notification";
import { alertPop } from "apiServices/common/helper";
import { isArrayAndHasValue } from "utils/functions";
import { DAILY_LINE_TARGET_DELETE_PERMISSION } from "routes/permissions";
import CustomDatePicker from "common/CustomDatePicker";


const PlanningRequestForm = (props) => {
  // Props
  const { form, editMode, viewMode } = props;
  // const [code, setCode] = useState("");

  //   States
  //const [checkedInquiries, setCheckedInquiries] = useState(null);
  const [userList, setUserList] = useState([]);
  const [inquiryList, setInquiryList] = useState([]);

   // console.log("checkedInquiries",checkedInquiries);

  const getUserList = useCallback(async (filterValues) => {
    const payload = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };
    const response = await getData(USER_LIST, false, payload);

    if (response && response?.status === 200) {
      setUserList(response?.data?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  const getInquiryList = useCallback(async (filterValues) => {
    const payload = {
      //per_page: filterValues?.per_page || 10,
      ...filterValues,
    };
    const response = await getData(UN_PLANNING_BUYER_INQUIRY_LIST, false, payload);

    if (response && response?.status === 200) {
      //console.log('inq data', response?.data?.data?.data);
      setInquiryList(response?.data?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  // Router
  const navigate = useNavigate();
  const { id } = useParams();

  const getSelectedPlanningRequestInfo = useCallback(
    async (id) => {
    
      const query = `${PLANNING_REQUESTS}/${id}/show`;
      
      const response = await getData(query);

      //console.log('response', response);

      if (response && response?.data?.code === 200) {
        // const daysData = response?.data?.data?.days?.map((item) => item?.days);
        
        // setCheckedDays(daysData);
        const buyer_inquiry_id = response?.data?.data?.buyer_inquiry_id;
        getInquiryList({id: buyer_inquiry_id});
        // if(editMode){
        //   setCheckedInquiries(buyer_inquiry_id);
        // }
        
        form.setFieldsValue({
          request_date: moment(response?.data?.data?.request_date).format("DD-MM-YYYY"),
          request_to: response?.data?.data?.request_to.id,
          request_by: response?.data?.data?.request_by.name,
        });
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error in fetching weekend Info",
          null,
        );
      }
    },
    [form],
  );

  // const handleNameChange = (e) => {
  //   const name = e.target.value;
  //   // Replace spaces and special characters with dashes
  //   const codeValue = name.replace(/[^a-zA-Z0-9]/g, "-");
  //   setCode(codeValue);
  //   form.setFieldsValue({ code: codeValue });
  // };

  useEffect(() => {
    // get selected cost info on edit mode
    if (id) {
      console.log("id", id);
      getSelectedPlanningRequestInfo(id);
    }
  }, [editMode, id, getSelectedPlanningRequestInfo]);

  useEffect(() => {
    // get selected cost info on edit mode
    getUserList(id);
  }, []);

  useEffect(() => {
    // get selected cost info on edit mode
    getInquiryList(id);
  }, []);



  const onFinishPlanningRequest = async (values) => {
    //console.log("values", values);

    const checkedInquiries = isArrayAndHasValue(inquiryList) && inquiryList?.filter((item) => item?.isChecked);
    const checkedInquiryIds = checkedInquiries.map((item) => item.id);
    //console.log('checkedInquiryIds', checkedInquiryIds);

    const query = editMode
      ? `${PLANNING_REQUESTS}/${id}`
      : `${PLANNING_REQUESTS}`;
    const bodyData = {
      request_date: moment(values?.request_date).format("YYYY-MM-DD"),
      request_to: values?.request_to,
      buyer_inquiry_id: checkedInquiryIds,
    };

    const response = editMode
      ? await putData(query, bodyData)
      : await postData(query, bodyData);

    // console.log("response", response);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        `Planning Request ${editMode ? "updated" : "added"} successfully!`,
        null,
      );
      form.resetFields();
      navigate("/production-planning/planningRequests");
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        `Error ${editMode ? "updating" : "adding"} Planning Request!`,
        null,
      );
    }
  };


  const onCheck = (id) => {
    const inquiryListCopy = isArrayAndHasValue(inquiryList)
      ? [...inquiryList]
      : [];

    const isExistsAndChecked = inquiryListCopy?.find(
      (item) => item?.id === id && item?.isChecked,
    );

    //console.log("isExistsAndChecked", isExistsAndChecked);

    const foundIndex = inquiryListCopy?.findIndex((item) => item?.id === id);

    if (isExistsAndChecked) {
      // Change the existing carton status to false
      inquiryListCopy[foundIndex]["isChecked"] = false;
    } else {
      // Change the existing carton status to true
      inquiryListCopy[foundIndex]["isChecked"] = true;
    }

    setInquiryList(inquiryListCopy);
  };

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishPlanningRequest}
      >
        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Request Date"
              name="request_date"
              initialValue={moment().format("DD-MM-YYYY")}
              //rules={[
                //{
                //  required: true,
                //  message: "Request date is required",
                //},
              //]}
            >
              <CustomDatePicker
                disabled
                placeholder={"Request Date"}
                value={moment().format("DD-MM-YYYY")}
              />
              {/* <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} /> */}
              {/* <Input placeholder="Request Date" disabled size="small"   /> */}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Request by"
              name="request_by"
              initialValue={localStorage.getItem("name") || "User"}
            >
              <Input placeholder="Entry Date" disabled size="small" />
            </Form.Item>
            
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="Request To" name="request_to">
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="User List"
                optionFilterProp="children"
                aria-readonly="true"
                // onSelect={(value) => {
                //       onSelectWeekendHoliday(value);
                //     }}
                showSearch
                onSearch={(value) => {
                  getUserList({ name: value });
                }}
              >
                {isArrayAndHasValue(userList) &&
                  userList.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option?.name}
                      </option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Card className="mt-3" title="Unplanned Inquiries and Styles">
        <div className="custome-table">
          <table border="1">
            <thead>
              <tr>
                <th key="checkbox_header" className="bc-java ">
                  {/* <Checkbox
                  // checked={
                  //   isArrayAndHasValue(selectedCarton) &&
                  //   selectedCarton.every((item) => item?.status === 1)
                  // }
                  // onChange={(e) => checkAll(e)}
                  /> */}
                </th>
                <th key="inquiry" className="bc-java ">Inquiry</th>
                <th key="style_header" className="bc-java ">Style</th>
                <th key="sub_style_header" className="bc-java ">Sub Style</th>
                <th key="order_qty" className="bc-java ">Order Qty</th>
                <th key="pcd" className="bc-java ">PCD</th>
                <th key="shipment_date" className="bc-java ">Shipment Date</th>
              </tr>
            </thead>
            <tbody>
              {isArrayAndHasValue(inquiryList) &&
                inquiryList?.map((inquiry, inquiryIndex) => {
                  let sub_style = Array.isArray(inquiry?.style_inquiry_map?.style_info?.sub_style) ? inquiry?.style_inquiry_map?.style_info?.sub_style : [];
                  sub_style = sub_style?.map(
                    (item) => item?.sub_style_no,
                  )?.join(",");
                  return (
                    <tr key={`carton_row_key_${inquiry?.id}`} >
                      {/* Checkbox */}
                      <td
                        className="t-body sticky"
                      >
                        <Checkbox
                          onChange={() => onCheck(inquiry?.id)}
                          checked={inquiry?.isChecked}
                          disabled={editMode && inquiry?.isChecked}
                        />
                      </td>
                      {/* Carton ID */}
                      <td
                        className="t-body sticky"
                      >
                        {inquiry?.inquiry_no}
                      </td>
                      <td
                        className="t-body sticky"
                      >
                        {inquiry?.style_inquiry_map?.style_info?.style_no}
                      </td>
                      <td
                        className="t-body sticky"
                      >
                        {sub_style}
                      </td>
                      <td
                        className="t-body sticky"
                      >
                        {inquiry?.expected_order_quantity}
                      </td>
                      <td
                        className="t-body sticky"
                      >
                        {
                          inquiry?.style_info?.active_sales_contract_map_info
                            ?.pcd
                        }
                      </td>
                      <td
                        className="t-body sticky"
                      >
                        {inquiry?.expected_delivery_date}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default PlanningRequestForm;
