import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {onDeleteItem, onGetItemList} from "../../../redux/actions";
import ListItem from "./ListItem";
import CreateItem from "../CreateItem";
import { GET_ITEM_ENDPOINT } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { message } from "antd";


const ItemList = (props) => {
    const {
      itemList,
      categoryList,
      form,
      currentPage,
      setCurrentPage,
      showFilter,
      setShowFilter,
    } = props;
    const dispatch = useDispatch();
    const totalItemList = useSelector(({item}) => item.totalItem);
    const pageSize = useSelector(({item}) => item.pageSize);
    const filterData = useSelector(({filter}) => filter.filterData);
    const [toDeleteItem, setToDeleteItem] = useState(null);
    const [isAddItem, onSetAddItem] = useState(false);
    const [isShowDetail, onShowDetail] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [attributeInfo, setAttributeInfo] = useState(null);


    const handleAddItemOpen = () => {
        onSetAddItem(true)
    }

    const onPageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleAddItemClose = () => {
        onSetAddItem(false)
        setSelectedItem(null)
    }
    const onViewItemDetail = (Item) => {
        setSelectedItem(Item);
        onShowDetail(true);
    };
    const onOpenEditItem = (event, Item) => {
        setSelectedItem(Item);
        handleAddItemOpen();
    };

    const onUpdateItem = (Item) => {
        setSelectedItem(Item);
        const attribute_infos = Item?.category_info?.attribute_info;
        const attribute_value_infos = Item?.category_info?.attribute_value_info;
        setAttributeInfo(attribute_infos);
        let attribute_info_array = [];

        attribute_infos && attribute_infos.forEach((item, index) => {
            attribute_value_infos && attribute_value_infos.foreach((value, idx) => {
                if(value.attribute_id !== item.id){
                    attribute_info_array.push({
                        attribute_id: item?.id,
                        attribute_name: item?.name,
                        attribute_value: "N/A"
                    });
                }
            })
        });
        handleAddItemClose();
    };

    const onSelectItemForDelete = (Item) => {
        setToDeleteItem(Item);
    };

    const onSelectedItemToDelete  = (record) => {
        dispatch(onDeleteItem(record));
        setToDeleteItem(null);
        props.refetch()
    }


    useEffect(() => {
        dispatch(
            onGetItemList({
                page: currentPage,
                per_page: 10,
                ...filterData,
            }),
        );
    }, [props.refresh, currentPage, filterData, dispatch]);

    const copyItem = async(item) => {
        const customQuery = `${GET_ITEM_ENDPOINT}/${item?.id}/copy`;
        const bodyData = {};

        const response = await getData(customQuery, false, bodyData);

        if (response?.status === 200) {
            dispatch(
                onGetItemList({
                    page: currentPage,
                    per_page: 10,
                }),
            );
            message.success("Item copied successfully");
        }
        else {
            message.error("Error copying item data");
        }

    }

    return (
        <>
            <ListItem
                itemList={itemList}
                categoryList={categoryList}
                extra={props.extra}
                page={currentPage}
                pageSize={pageSize}
                totalItemList={totalItemList}
                onPageChange={onPageChange}
                handleAddItemOpen={handleAddItemOpen}
                handleAddItemClose={handleAddItemClose}
                onSelectItemForDelete={onSelectItemForDelete}
                onViewItemDetail={onViewItemDetail}
                onOpenEditItem={onOpenEditItem}
                onSelectedItemToDelete={onSelectedItemToDelete}
                selectedItem={selectedItem} 
                copyItem={copyItem}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
            />

            <CreateItem
                itemList={itemList}
                categoryList={categoryList}
                refetch={props.refetch}
                isAddItem={isAddItem}
                handleAddItemClose={handleAddItemClose}
                selectedItem={selectedItem}
                onUpdateItem={onUpdateItem}
            />
        </>
    )
}

export default ItemList;