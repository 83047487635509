import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Card, DatePicker, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import { alertPop } from "../../../apiServices/common/helper";
import { commaSeparateNumber, showTotalPrice } from "../../../utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const StyleDetailsInitial = (props) => {
  // Props
  const {
    selectedStyles,
    setSelectedStyle,
    styleList,
    view,
    totalStyleOrderQty,
    totalStyleOrderValue,
  } = props;

  // Antd
  const { confirm } = Modal;
  const { Option } = Select;

  const styleSelect = (value) => {
    let index = selectedStyles.findIndex((item) => item?.style_id === value);

    if (index === -1) {
      let product = styleList.find((item) => {
        return item.id === value;
      });
      product["is_edit"] = true;
      product["pcd"] = undefined;
      setSelectedStyle((oldArray) => [...oldArray, product]);
    } else {
      alertPop("warning", "Selected product exists on the list.");
    }
  };

  const removeSelectedProduct = (productId) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        let filter = selectedStyles.filter((item) => item.id !== productId);
        setSelectedStyle([...filter]);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const onChangeHandel = (val, index, context) => {
    if (context === "pcd") {
      selectedStyles[index][context] = moment(val).format("YYYY-MM-DD");
      setSelectedStyle([...selectedStyles]);
    } else {
      selectedStyles[index][context] = val;
      setSelectedStyle([...selectedStyles]);
    }
  };
  const required = <span style={{ color: "red" }}>*</span>;
  return (
    <Card
      className="custome-table"
      title={<span style={{ fontSize: "14px" }}>{`Style Details`}</span>}
    >
      <div className="root-table">
        <table className="pi-product-details-table">
          <thead>
            <tr>
              <th className="t-header bc-java ">Serial {required}</th>
              <th className="t-header bc-java ">Style No {required}</th>
              <th className="t-header bc-java ">Description {required}</th>
              <th className="t-header bc-java ">PCD {required}</th>
              <th className="t-header bc-java ">Order Quantity (pc.)</th>
              <th className="t-header bc-java">Unit Price $</th>
              <th className="t-header bc-java">Total Value $</th>
              <th className="t-header bc-java "></th>
            </tr>
          </thead>
          <tbody>
            {selectedStyles?.length ? (
              <>
                {selectedStyles.map((product, index) => (
                  <tr key={index}>
                    {/* Serial */}
                    <td className="t-body">{index + 1}</td>
                    {/* Style No */}
                    <td className="t-body">
                      {product?.style_no
                        ? product?.style_no || ""
                        : product?.style_info?.style_no}
                    </td>
                    {/* Description */}
                    <td className="t-body">
                      <Input
                        disabled={true}
                        onChange={(e) =>
                          onChangeHandel(e, index, "description")
                        }
                        value={
                          product?.style_info?.description
                            ? product?.style_info?.description
                            : // : product?.contract_value
                              product?.description
                        }
                        size="small"
                      />
                    </td>
                    {/* PCD */}
                    <td className="t-body">
                      <CustomDatePicker
                        value={
                          product?.pcd
                            ? moment(product?.pcd, "YYYY-MM-DD")
                            : null
                        }
                        onChange={(e) => onChangeHandel(e, index, "pcd")}
                        size="small"
                      />
                    </td>
                    {/* Order Quantity (pc.) */}
                    <td className="t-body">
                      <InputNumber
                        defaultValue={
                          product?.order_quantity
                            ? product?.order_quantity
                            : product?.quantity || undefined
                        }
                        index={index}
                        name={"quantity"}
                        disabled={view}
                        onChange={(value) =>
                          onChangeHandel(value, index, "order_quantity")
                        }
                        maxLength={14}
                        style={{ width: "100px" }}
                        formatter={(value) => commaSeparateNumber(value)}
                        precision={2}
                        size="small"
                      />
                    </td>
                    {/* Unit Price $ */}
                    <td className="t-body">
                      <InputNumber
                        defaultValue={
                          product?.unit_price
                            ? product?.unit_price
                            : product?.price || undefined
                        }
                        disabled={view}
                        index={index}
                        name={"price"}
                        onChange={(value) => {
                          onChangeHandel(value, index, "unit_price");
                        }}
                        style={{ width: "100px" }}
                        formatter={(value) => commaSeparateNumber(value)}
                        // precision={2}
                        size="small"
                      />
                    </td>
                    <td>{commaSeparateNumber(showTotalPrice(product))}</td>

                    {!product.is_edit
                      ? !product.is_edit
                      : !view && (
                          <td
                            className="t-body"
                            onClick={() => {
                              removeSelectedProduct(product?.id);
                            }}
                          >
                            <MinusCircleOutlined
                              className="cursore-pointer"
                              style={{ fontSize: "16px", color: "red" }}
                            />
                          </td>
                        )}
                  </tr>
                ))}
              </>
            ) : (
              ""
            )}

            {selectedStyles?.length ? (
              <tr>
                <td className="t-body bc-white" colSpan="1">
                  <strong>Subtotal</strong>
                </td>
                <td className="t-body bc-white" colSpan="3"></td>
                <td className="t-body bc-white" colSpan="1">
                  {commaSeparateNumber(
                    totalStyleOrderQty
                      ? Number(totalStyleOrderQty).toFixed(2)
                      : 0,
                  )}
                </td>
                <td className="t-body bc-white" colSpan="1"></td>
                <td className="t-body">
                  <strong>
                    {commaSeparateNumber(
                      totalStyleOrderValue
                        ? Number(totalStyleOrderValue).toFixed(2)
                        : 0,
                    )}
                  </strong>
                </td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>

      <Select
        className="mt-3"
        showSearch
        placeholder="Select a Style"
        optionFilterProp="children"
        onChange={styleSelect}
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
        size="small"
        style={{ width: "300px" }}
        disabled={view}
      >
        {styleList?.length ? (
          <>
            {styleList.map((item) => {
              return (
                <Option
                  value={item?.id}
                  key={item?.id}
                  disabled={item?.active_sales_contract_map_info}
                >
                  {item?.style_no}
                </Option>
              );
            })}
          </>
        ) : (
          ""
        )}
      </Select>
    </Card>
  );
};

export default StyleDetailsInitial;
