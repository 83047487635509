import { useCallback, useEffect, useState } from "react";
import { Button, Col, DatePicker, message, Row, Typography, Form } from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import { getData, postData } from "../../apiServices/common";
import {
  OT_SIGNAL_SECTION_WITH_OT_SIGNALS_AND_LINE_ID,
  DECISION_POINT_AVAILABILIY,
} from "../../apiServices/API_ENDPOINTS";
import { useLocation } from "react-router";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";

const OvertimeSignals = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [fullData, setFullData] = useState(null);
  const [decisionPointAvailabilityData, setDecisionPointAvailabilityData] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const { Title } = Typography;

  const [overtimeSignalForm] = Form.useForm();

  // Fetch Overtime Signals - No date is needed here as per your request
  const getAllOvertimeSignals = useCallback(async () => {
    try {
      const payload = {};
      setLoading(true);
      const query = `${OT_SIGNAL_SECTION_WITH_OT_SIGNALS_AND_LINE_ID}`;
      const response = await getData(query, false, payload);

      if (response?.status === 200) {
        setFullData(response?.data?.data);
      } else {
        message.error("Error getting Overtime Signals data");
      }
    } catch (error) {
      message.error(
        "An unexpected error occurred while fetching overtime signals.",
      );
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch Decision Point Availability with the date included in the payload
  const getAllDecisionPointAvailability = useCallback(async (selectedDate) => {
    try {
      const payload = {
        date: selectedDate
          ? moment(selectedDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      };
      setLoading(true);
      const query = `${DECISION_POINT_AVAILABILIY}`;
      const response = await postData(query, payload);

      if (response?.code === 200) {
        setDecisionPointAvailabilityData(response?.data);
      } else {
        message.error("Error getting Decision Point Availability data");
      }
    } catch (error) {
      message.error(
        "An unexpected error occurred while fetching decision point availability.",
      );
    } finally {
      setLoading(false);
    }
  }, []);

  // Effects - Fetch data on component mount
  useEffect(() => {
    getAllOvertimeSignals();
    getAllDecisionPointAvailability();
  }, [getAllOvertimeSignals, getAllDecisionPointAvailability]);

  // Form submit handler - Trigger Decision Point Availability API with selected date
  const handleFormSubmit = (values) => {
    const selectedDate = values.date;
    console.log(
      "Selected Date for Decision Point Availability: ",
      selectedDate,
    );

    // Fetch Decision Point Availability with the selected date
    getAllDecisionPointAvailability(selectedDate);
  };

  const getResult = (OTSignalSection, OTSignal) => {
    // Ensure decisionPointAvailabilityData is available before proceeding
    if (!decisionPointAvailabilityData) {
      console.log("Decision Point Availability data is not available yet.");
      return "Loading..."; // or return null or any placeholder value
    }

    // Log decisionPointAvailabilityData to check if it is populated
    console.log("decisionPointAvailabilityData", decisionPointAvailabilityData);

    // Find the section in the decisionPointAvailabilityData
    const sectionData = decisionPointAvailabilityData.find((eachItem) => {
      return eachItem?.section === OTSignalSection;
    });

    // If the section is found, check the signals in that section
    if (sectionData) {
      const signalData = sectionData.data.find(
        (eachSignal) => eachSignal.signal === OTSignal,
      );
      return signalData ? signalData.overtime_application : ""; // Return overtime_application or a fallback message
    }

    return ""; // Fallback if the section isn't found
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader title={<Title level={5}>Overtime Signals</Title>} />
        }
        content={
          <>
            <Form
              form={overtimeSignalForm}
              layout="vertical"
              onFinish={handleFormSubmit}
              initialValues={{
                date: moment(), // Set the initial value of the DatePicker to the current date
              }}
            >
              <Row gutter={6} style={{ marginLeft: 5 }}>
                <Col span={4}>
                  <Form.Item
                    name="date"
                    label="Date"
                    rules={[
                      {
                        required: true,
                        message: "Date is Required",
                      },
                    ]}
                  >
                    <CustomDatePicker
                      size="medium"
                      style={{ width: "100%" }}
                      defaultValue={moment()} // This sets the DatePicker default value to the current date
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", marginTop: 31 }}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>

            {fullData && fullData.length > 0 ? (
              <div className="table" style={{ marginTop: 10 }}>
                <table
                  border="1"
                  cellPadding="5"
                  cellSpacing="0"
                  style={{
                    width: "100%",
                    borderColor: "#ccc",
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#1b4b72" }}>
                      <th style={{ textAlign: "left", color: "#fff" }}>
                        Section
                      </th>
                      <th style={{ textAlign: "left", color: "#fff" }}>Line</th>
                      <th style={{ textAlign: "left", color: "#fff" }}>
                        OT Signals
                      </th>
                      <th style={{ textAlign: "left", color: "#fff" }}>
                        Result
                      </th>
                      {/* <th style={{ textAlign: "left", color: "#fff" }}>Details</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {fullData.map((eachSignalSection, sectionIndex) => {
                      let sectionRowCount =
                        eachSignalSection.ot_signal_lines.reduce(
                          (acc, eachLine) =>
                            acc + eachLine.ot_signal_data.length,
                          0,
                        );

                      return eachSignalSection.ot_signal_lines.map(
                        (eachOtSignalLine, lineIndex) => {
                          return eachOtSignalLine.ot_signal_data.map(
                            (eachOtSignalData, signalIndex) => (
                              <tr
                                key={`${sectionIndex}-${lineIndex}-${signalIndex}`}
                              >
                                {/* Render Section name only for the first row of the section */}
                                {signalIndex === 0 && lineIndex === 0 && (
                                  <td rowSpan={sectionRowCount}>
                                    {eachSignalSection?.name}
                                  </td>
                                )}

                                {/* Render Line name only for the first OT Signal of each line */}
                                {signalIndex === 0 && (
                                  <td
                                    rowSpan={
                                      eachOtSignalLine.ot_signal_data.length
                                    }
                                  >
                                    {eachOtSignalLine?.name}
                                  </td>
                                )}

                                {/* OT Signal */}
                                <td>{eachOtSignalData?.name}</td>

                                {/* Placeholder for Result and Details */}
                                <td>
                                  {getResult(
                                    eachSignalSection?.name,
                                    eachOtSignalData?.name,
                                  )}
                                </td>
                                {/* <td>Planned more than 8h</td> */}
                              </tr>
                            ),
                          );
                        },
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>No data available.</div>
            )}
          </>
        }
      />
    </>
  );
};

export default OvertimeSignals;
