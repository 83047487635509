import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import SecurityBoltForm from "./SecurityBoltForm";
import { SECURITY_BOLT_SEAL_EDIT } from "../../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import moment from "moment";

export default function EditSecurityBolt(props) {
  // States
  const [editMode, setEditMode] = useState(false);

  //   Refs
  const propertyFormRef = useRef(null);

  //   Antd
  const [form] = Form.useForm();

  //   Router
  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/ex-factory/security-bolt-seal-log");
  };
  const { securityBoltId } = useParams();

  const getSelectedNotingDetails = useCallback(
    async (securityBoltId) => {
      const response = await getData(
        `${SECURITY_BOLT_SEAL_EDIT}/${securityBoltId}`,
      );

      if (response && response.status === 200) {
        const tableData = response?.data?.data;

        form.setFieldsValue({
          ...tableData,
          security_bolt_seal_number: tableData?.security_bolt_seal_number,
          using_date: tableData.using_date
            ? moment(tableData?.using_date)
            : null,
          vehicle_number: tableData?.vehicle_number,
          vehicle_size: tableData?.vehicle_size,
          buyer_id: tableData?.buyer_id,
          style_number: tableData?.style_number,
          po_number: tableData?.po_number,
          order_quantity: tableData?.order_quantity,
          shipped_quantity: tableData?.shipped_quantity,
          carton_quantity: tableData?.carton_quantity,
          examination_report: tableData?.examination_report,
          challan_no: tableData?.challan_no,
          volume_in_cbm: tableData?.volume_in_cbm,
          driver_name: tableData?.driver_name,
          driver_mobile_number: tableData?.driver_mobile_number,
          driver_license_or_nid_number: tableData?.driver_license_or_nid_number,
          vehicle_arrival_date: tableData?.vehicle_arrival_date,
          vehicle_arrival_time: tableData?.vehicle_arrival_time,
          dispatch_date: tableData?.dispatch_date,
          dispatch_time: tableData?.dispatch_time,
          delivery_place: tableData?.delivery_place
        });
      } else {
        alertPop(
          "error",
          response?.messages ? response?.messages : "Something Went Wrong!",
        );
      }
    },
    [form],
  );

  useEffect(() => {
    if (securityBoltId) {
      setEditMode(true);
      getSelectedNotingDetails(securityBoltId);
    }
  }, [securityBoltId, getSelectedNotingDetails]);

  const submitForm = () => {
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={`${
              securityBoltId
                ? "Update Security Bolt Seal Log"
                : "Add Security Bolt Seal Log"
            }`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => submitForm()}
                >
                  {`${
                    securityBoltId
                      ? "Update"
                      : "Save"
                  }`}
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <SecurityBoltForm
        ref={propertyFormRef}
        form={form}
        editMode={editMode}
        securityBoltId={securityBoltId}
      />
    </>
  );
}
