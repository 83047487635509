import {
    FETCH_ERROR,
    FETCH_START, FETCH_SUCCESS,
     USER_LOGIN
} from '../ActionTypes'
import JwtAxios from "../../apiServices";

import HandleNotification from "../../common/Notification";
import {useNavigate} from "react-router";

export const onHandleUserLogin = (payload) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`/api/login`, {
                params: {
                    email: payload.email,
                    password: payload.password,
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: USER_LOGIN, payload: data.data.data});

                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
                HandleNotification('error', 'bottomRight', 'Invalid Credentials', 'Please try with valid email and password!')

            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});

            });
    };
};

export default onHandleUserLogin;


