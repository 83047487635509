import { Line } from "@ant-design/plots";
import { Card, Col, Typography } from "antd";

export default function LineData({ data }) {
  // console.log(data);
  const config = {
    data,
    xField: "hour",
    yField: "value",
    seriesField: "type",
    colorField: "type",
    scale: { color: { range: ["#ff7f0e ", "#aaaaaa"] } },
    point: {
      size: 4,
      shape: "circle",
    },
    lineStyle: {
      lineWidth: 2,
    },
  };

  return (
    <Col
      span={12}
      style={{
        marginBottom: "20px",
      }}
    >
      <Card>
        <Line {...config} />
        <Typography.Title level={5} style={{ textAlign: "center" }}>
          <span
            style={{
              marginRight: "20px",
            }}
          >
            X-Axis = Hour
          </span>
          <span>Y-Axis = Product Qty</span>
        </Typography.Title>
      </Card>
    </Col>
  );
}
