import { checkRes } from "./helper";
import { alertPop } from "./helper";
import JwtAxios from "apiServices";

const base_url = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("token");

// const token = "Bearer 2|lWuHm4FemrisBecE9Qh96xhuehsP2jwyJQC4UYY8";
//   Cookies.get("OTLToken") ||

// axios.defaults.headers.post["Content-Type"] = "application/json";

const alert = (messages) => {
  alertPop("error", messages ? messages : "Something went wrong");
};

/* query ---> api url to query with
   no_token ---> acts as a flag for no need to use token */
export const getData = async (
  query,
  no_token,
  bodyData,
  customHeader,
  responseType,
) => {
  try {
    let responseData = await JwtAxios.get(`${base_url}${query}`, {
      ...(responseType && { responseType: responseType }),
      headers: no_token
        ? {}
        : {
            // Authorization: `Bearer ${token}`,
            ...(customHeader && customHeader),
          },
      ...(bodyData && { params: bodyData }),
    });
    return responseData;
  } catch (error) {
    let errors = error.response?.data?.errors;

    if (error.response?.data?.message) {
      alert(error.response?.data?.message);
    } else if (errors?.length) {
      if (errors && Object.keys(errors).length) {
        for (var key of Object.keys(errors)) {
          // console.log(key + " -> " + errors[key]);
          alert(errors[key]);
        }
      }
    } else {
      alert();
    }
  }
};

export const getWithData = async (query, no_token, payload) => {
  console.log("in get with", payload);

  try {
    let data = await JwtAxios.get(`${base_url}${query}`, {
      // headers: no_token
      //   ? {}
      //   : {
      //       Authorization: `Bearer ${token}`,
      //     },
      params: payload,
    });
    return data;
  } catch (error) {
    let errors = error.response?.data?.errors;

    if (error.response?.data?.message) {
      alert(error.response?.data?.message);
    } else if (errors?.length) {
      if (errors && Object.keys(errors).length) {
        for (var key of Object.keys(errors)) {
          // console.log(key + " -> " + errors[key]);
          alert(errors[key]);
        }
      }
    } else {
      alert();
    }
  }
};

export const deleteData = async (query, no_token) => {
  try {
    let res = await JwtAxios({
      method: "delete",
      url: `${base_url}${query}`,
      // headers: no_token
      //   ? {}
      //   : {
      //       Authorization: `Bearer ${token}`,
      //     },
      // data: data,
    });

    if (checkRes(res?.data?.code)) {
      return res.data;
    } else {
      if (res?.data?.message?.length) {
        res?.data?.message.forEach((mess) => {
          alertPop("error", mess);
        });
      } else {
        alert();
      }
    }
  } catch (error) {
    let errors = error.response?.data?.errors;

    if (error.response?.data?.message) {
      alert(error.response?.data?.message);
    } else if (errors?.length) {
      if (errors && Object.keys(errors)?.length) {
        for (var key of Object.keys(errors)) {
          // console.log(key + " -> " + errors[key]);
          alert(errors[key]);
        }
      }
    } else {
      alert();
    }

    return false;
  }
};
/* query ---> api url to query with
     data ---> data to be posted
     no_token ---> acts as a flag for no need to use token */
export const postData = async (query, data, no_token) => {
  try {
    // let headers = {};
    // if (!no_token) {
    //   headers = {
    //     Authorization: `Bearer ${token}`,
    //   };
    // }

    // Check if data is FormData (for file uploads)
    const contentType =
      data instanceof FormData ? "multipart/form-data" : "application/json";

    let res = await JwtAxios({
      method: "post",
      url: `${base_url}${query}`,
      headers: {
        // ...headers,
        // Authorization: `Bearer ${token}`,
        "Content-Type": contentType,
      },
      data: data,
    });

    if (checkRes(res?.data?.code)) {
      return res.data;
    } else {
      if (res?.data?.message?.length) {
        res?.data?.message.forEach((mess) => {
          alertPop("error", mess);
        });
      } else {
        alert();
      }
    }
  } catch (error) {
    let errors = error.response?.data?.errors;

    if (error.response?.data?.message) {
      alert(error.response?.data?.message);
    } else if (errors?.length) {
      if (errors && Object.keys(errors)?.length) {
        for (var key of Object.keys(errors)) {
          // console.log(key + " -> " + errors[key]);
          alert(errors[key]);
        }
      }
    } else {
      alert();
    }

    return false;
  }
};

export const putData = async (query, data, no_token) => {
  // let headers = {};
  // if (!no_token) {
  //   headers = {
  //     Authorization: `Bearer ${token}`,
  //   };
  // }

  // Check if data is FormData (for file uploads)
  const contentType =
    data instanceof FormData ? "multipart/form-data" : "application/json";

  try {
    let res = await JwtAxios({
      method: "put",
      url: `${base_url}${query}`,
      headers: {
        // ...headers,
        // Authorization: `Bearer ${token}`,
        "Content-Type": contentType,
      },
      data: data,
    });

    if (checkRes(res?.data?.code)) {
      return res.data;
    } else {
      if (res?.data?.message?.length) {
        res?.data?.message.forEach((mess) => {
          alertPop("error", mess);
        });
      } else {
        alert();
      }
    }
  } catch (error) {
    let errors = error.response?.data?.errors;
    if (error.response?.data?.message) {
      alert(error.response?.data?.message);
    } else if (errors?.length) {
      if (errors && Object.keys(errors)?.length) {
        for (var key of Object.keys(errors)) {
          // console.log(key + " -> " + errors[key]);
          alert(errors[key]);
        }
      }
    } else {
      alert();
    }

    return false;
  }
};

export const withTimeout = (promise, timeoutDuration = 10000) => {
  return new Promise((resolve, reject) => {
    // Timeout logic: Reject the promise after the specified duration
    const timeoutId = setTimeout(() => {
      reject(new Error("Request timed out"));
    }, timeoutDuration);

    // Resolve the passed promise and clear the timeout if it completes
    promise
      .then((res) => {
        clearTimeout(timeoutId);
        resolve(res);
      })
      .catch((err) => {
        clearTimeout(timeoutId);
        reject(err);
      });
  });
};
