import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Typography,
} from "antd";
import { getData, postData } from "apiServices/common";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import AppPageHeader from "../../../../common/PageHeader";

const UpdateGarmentsTracking = (props) => {
  //   Routes
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  //   Antd Constants
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const body = {
        good_garments_qty: values.good_garments_qty,
        defect_garments_qty: values.defect_garments_qty,
        reject_garments_qty: values.reject_garments_qty,
      };
      const query = `/api/digitalization/garments-tracking-update-qty/${id}`;
      const response = await postData(query, body);

      if (response?.code === 200) {
        form.resetFields();
        navigate("/digitalization/garments-tracking");
        message.success("Garments Tracking Updated Successfully");
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to update Garments Tracking");
    }
  };

  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        onClick={() => form.submit()}
        style={{ marginRight: 5 }}
      >
        {" "}
        Save
      </Button>
      <Button
        size="small"
        htmlType="submit"
        onClick={() => {
          form.resetFields();
          navigate("/digitalization/garments-tracking");
        }}
      >
        {" "}
        Reset
      </Button>
    </div>
  );

  useEffect(() => {
    if (id) {
      const init = async () => {
        try {
          const response = await getData(
            `/api/digitalization/garments-tracking/${id}`,
          );
          const data = response.data?.data;
          form.setFieldsValue({
            ...data,
            date: data.date,
            buyer_info_name: data.buyer_info?.name,
            line_info_name: data.line_info?.name,
            style_info_name: data.style_info?.name,
            defect_garments_qty: data.defect_garments_qty,
            good_garments_qty: data.good_garments_qty,
            reject_garments_qty: data.reject_garments_qty,
          });
        } catch (error) {
          console.log(error);
        }
      };
      init();
    }
  }, [id]);

  return (
    <>
      <AppPageHeader
        title={
          <Typography.Title level={5}>
            Update Garments Tracking
          </Typography.Title>
        }
        extra={extra}
      />

      <Layout.Content className="item-details">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label="Date" name="date">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Buyer Name" name="buyer_info_name">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Line Name" name="line_info_name">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Style Name" name="style_info_name">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Defect Garments Qty"
                name="defect_garments_qty"
                rules={[
                  {
                    required: true,
                    message: "Please enter Defect Garments Qty",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Good Garments Qty"
                name="good_garments_qty"
                rules={[
                  {
                    required: true,
                    message: "Please enter Good Garments Qty",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Reject Garments Qty"
                name="reject_garments_qty"
                rules={[
                  {
                    required: true,
                    message: "Please enter Reject Garments Qty",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </>
  );
};

export default UpdateGarmentsTracking;
