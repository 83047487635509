import {
    GET_DASHBOARD_DATA,
} from '../ActionTypes';

const initialState = {
    dashboardData: [],
};

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                dashboardData: action.payload,
            };

        default:
            return state;
    }
};

export default DashboardReducer;
