import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Button,
  Affix,
  Typography,
  Space,
} from "antd";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useLocation } from "react-router-dom";
import { getData } from "../../../apiServices/common";
import HandleNotification from "common/Notification";
import ReactBigScheduler from "./ReactBigScheduler";

const ProductionPlanningDashboard = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  //   States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button key="3" size="small" type="primary">
        <Link to="/production-planning/exceptions/create">Add New</Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>Production Planning Dashboard</Title>}
            // actions={extra}
          />
        </Card>
      </Affix>

      <div className="containt-body">
        {/* <Card>
            <ViewComponent />
        </Card> */}
        <Card>
          <ReactBigScheduler />
        </Card>
      </div>
    </Fragment>
  );
};

export default ProductionPlanningDashboard;
