import {
  GET_ITEM_LIST,
  CREATE_NEW_ITEM,
  DELETE_ITEM,
  GET_ITEM_DETAIL, UPDATE_ITEM_DETAIL
} from '../ActionTypes';

const initialState = {
  itemList: [],
  totalItem: null,
  selectedItem: null,
  pageSize: 10,
  page:1,
};

const itemReducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_ITEM_LIST:
      return {
        ...state,
        itemList: action.payload.data,
        totalItem: action.payload.total,
        pageSize: action.payload.per_page,
        page: action.payload.current_page
      };



    case CREATE_NEW_ITEM:
      return {
        ...state,
        itemList: [action.payload.data, ...state.itemList],
        totalItem: state.totalItem + 1,
      };

    case DELETE_ITEM: {
      return {
        ...state,
        itemList: action.payload.list,
        totalItem: action.payload.total,
      };
    }

    case GET_ITEM_DETAIL:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case UPDATE_ITEM_DETAIL:
      return {
        ...state,
        selectedItem: action.payload,
        itemList: state.itemList.map((item) =>
            item.id === action.payload.id ? action.payload : item,
        ),
      };

    default:
      return state;
  }
};

export default itemReducer;
