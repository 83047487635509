import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row, Select, Input, message } from "antd";
import {
  getData,
} from "../../../apiServices/common";
import {
  UNIT_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";

const UnitConvertionFilterFields = (props) => {
  // Props
  const { initialTableData } = props;

  // States
  const [unitList, setUnitList] = useState(null);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Option } = Select;

  const getAllUnits = async () => {
    setLoading(true);

    const response = await getData(UNIT_LIST_ENDPOINT);
    if (response?.status === 200) {
      setLoading(false);
      setUnitList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting unit list data");
    }
  };

  // Effects
  useEffect(() => {
    getAllUnits();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item
          name="convert_from"
          placeholder="Convert From"
          label="Convert From"
          rules={[
            {
              required: true,
              message: "Convert From Unit Required",
            },
          ]}
        >
          <Select
            placeholder="Select Unit"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            style={{ width: "100%" }}
            size="small"
          >
            {unitList?.map((item, index) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          name="convert_to"
          placeholder="Convert To"
          label="Convert To"
          rules={[
            {
              required: true,
              message: "Convert To Unit Required",
            },
          ]}
        >
          <Select
            placeholder="Select Unit"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            style={{ width: "100%" }}
            size="small"
          >
            {unitList?.map((item, index) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default UnitConvertionFilterFields;
