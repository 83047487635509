import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postData, getData } from "../../../../apiServices/common";
import AddExportSalesContractForm from "./AddExportSalesContractForm";
import { useDispatch } from "react-redux";
import { onSaveFreightTypes } from "redux/actions/Export";

export default function EditExportSalesContract(props) {
  const { isView } = props;
  let { exportId } = useParams();
  const dispatch = useDispatch();

  const { width, view } = props;
  const propertyFormRef = useRef(null);
  const [permitInfo, setPermitInfo] = useState();
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const discartForm = () => {
    // propertyFormRef.current.discart();
    navigate("/merchandising/style");
  };

  const addProduct = () => {
    // alert("adfafd");
    // return false;
    form.submit();
    //propertyFormRef.current.submit();
  };
  // const base_url = process.env.REACT_APP_BASE;
  const edit_url = `/api/accounting/sales-contract-commercial/view/${exportId}`;
  const getPIInfo = useCallback(async () => {
    let res = await getData(edit_url);
    if (res) {
      let masterData = res?.data?.data[0];

      // FOR UPDATED FREIGHT SAVE TYPES
      if (masterData?.sales_contract_commercial?.freight_value) {
        const savedTypes = masterData?.sales_contract_commercial?.freight_value.map(item => (
          {
            id: item?.type_id,
            item: item?.freight_type?.type_name,
            value: item?.value
          }))
        dispatch(onSaveFreightTypes(savedTypes));
      }// FOR UPDATED FREIGHT SAVE TYPES

      setPermitInfo(masterData);
    }
  }, [dispatch, edit_url]);

  useEffect(() => {
    getPIInfo();
  }, [getPIInfo]);


  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`${isView ? "View" : "Update"} Sales Contract / Master LC`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
                hidden={view}
              >
                Update
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddExportSalesContractForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        form={form}
        view={view}
      />
    </>
  );
}
