import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Layout, Menu } from "antd";
import { useLocation } from "react-router-dom";

const SideBar = (props) => {
  // Props
  const { collapsed, menuList } = props;

  // Router
  const location = useLocation();

  //   States
  const [openKeys, setOpenKeys] = useState([localStorage.getItem("keys")]);
  const [current, setCurrent] = useState(
    location.pathname === "/dashboard" ? "/" : location.pathname,
  );

  //   Antd Constants
  const { Sider } = Layout;

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (menuList.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
    localStorage.setItem("keys", latestOpenKey);
  };

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);


  return (
    <Sider
      width={200}
      className="site-layout-background"
      collapsible
      collapsed={collapsed}
      trigger={null}
    >
      <Menu
        mode="inline"
        selectedKeys={[current]}
        defaultOpenKeys={localStorage.getItem("keys")}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{
          height: "100%",
          borderRight: 0,
        }}
        items={menuList}
        inlineCollapsed={collapsed}
        theme="dark"
      />
    </Sider>
  );
};
export default SideBar;
