import { useEffect, useState } from "react";
import { Button, List, Typography } from "antd";
import { isArrayAndHasValue } from "../../../../utils/functions";

const RecentlyVisited = () => {
  // States
  const [visitedData, setVisitedData] = useState([]);

  // Antd
  const { Title } = Typography;

  useEffect(() => {
    // Get recently visited lists from localstorage
    const locationItem = localStorage.getItem("locationHistory");
    const locationHistory = locationItem ? JSON.parse(locationItem) : [];
    setVisitedData(locationHistory);
  }, []);

  return (
    <>
      <div>
        <Title level={5} className="centered-item-parent">
          Recently Visited
        </Title>
      </div>
      <List>
        {isArrayAndHasValue(visitedData) &&
          visitedData?.map((item, index) => {
            return (
              <List.Item key={index}>
                <List.Item.Meta
                  title={
                    <a href={item} target="_blank" rel="noreferrer">
                      {item}
                    </a>
                  }
                  // description={item.email}
                />
              </List.Item>
            );
          })}
      </List>
    </>
  );
};

export default RecentlyVisited;
