import React, { useEffect, useState } from "react";
import { Card, Select, Modal, InputNumber } from "antd";
import {
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { alertPop } from "../../../apiServices/common/helper";
import { getData } from "../../../apiServices/common";


const { confirm } = Modal;
const { Option } = Select;
export default function BuyingOfficeBankAcc({
  // buyingOffice,
  buyingOfficeAccs,
  selectedBuyingOfficeBankAccs,
  setSelectedBuyingOfficeBankAccs,
  view,
  title,
}) {
  const [bankAccs, setBankAccs] = useState([]);
  const [selected, setSelected] = useState();

  const productSelect = (value) => {
    let index = selectedBuyingOfficeBankAccs.findIndex(
      (item) => item?.account_info?.id === value || item?.id === value
    );

    if (index === -1) {
      let bank = bankAccs.find((item) => {
        return (
          item?.account_info?.id === value || item?.account_info?.id === value
        );
      });
      setSelectedBuyingOfficeBankAccs((oldArray) => [...oldArray, bank]);
    } else {
      alertPop("warning", "Selected Bank A/C. exists on the list.");
    }
  };

  const removeSelectedProduct = (productId) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        let filter = selectedBuyingOfficeBankAccs.filter(
          (item) => item.id !== productId
        );
        setSelectedBuyingOfficeBankAccs([...filter]);
        setSelected(null);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    setBankAccs(buyingOfficeAccs);
    // getAllBnakList();
  }, [buyingOfficeAccs]);


  return (
    <Card style={{padding: '0px'}} className="custome-table" title={<span style={{fontSize: '14px'}}>{title}</span>}>
      <div className="root-table">
        <table className="pi-product-details-table">
          <thead>
            <tr>
              <th className="t-header bc-java ">Serial</th>
              <th className="t-header bc-java ">Bank Name</th>
              <th className="t-header bc-java ">Branch Name & Address</th>
              <th className="t-header bc-java ">SWIFT Code</th>
              <th className="t-header bc-java "></th>
            </tr>
          </thead>
          <tbody>
            {selectedBuyingOfficeBankAccs?.length ? (
              <>
                {selectedBuyingOfficeBankAccs.map((product, index) => (
                  <tr key={index}>
                    <td className="t-body">{index + 1}</td>
                    <td className="t-body">
                      {product?.account_info?.bank_info?.name
                        ? product?.account_info?.bank_info?.name || ""
                        : product?.bank_info?.name}
                    </td>
                    <td className="t-body">
                      {product?.account_info?.branch_info?.address &&
                      product?.account_info?.branch_info?.name
                        ? product?.account_info?.branch_info?.name +
                            "," +
                            product?.account_info?.branch_info?.address || ""
                        : product?.branch_info?.name +
                            ", " +
                            product?.branch_info?.address || ""}
                    </td>
                    <td className="t-body">
                      {product?.account_info?.bank_info?.swift_code
                        ? product?.account_info?.bank_info?.swift_code || ""
                        : product?.bank_info?.swift_code || ""}
                    </td>

                    {!view && (
                      <td
                        className="t-body"
                        onClick={() => {
                          removeSelectedProduct(product.id);
                        }}
                      >
                        <MinusCircleOutlined
                          className="cursore-pointer"
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </>
            ) : (
              ""
            )}

            {/* {selectedBuyingOfficeBankAccs?.length ? (
              <tr>
                <td className="t-body bc-white" colSpan="3"></td>
                <td className="t-body">{showAllTotal()}</td>
              </tr>
            ) : (
              ""
            )} */}
          </tbody>
        </table>
      </div>

      <Select
        className="mt-2"
        dropdownStyle={{ minWidth: 350 }}
        showSearch
        placeholder="Select a Bank"
        optionFilterProp="children"
        onChange={productSelect}
        // onSearch={onSearch}
        // filterOption={(input, option) =>
        //   option.children.toLowerCase().includes(input.toLowerCase())
        // }
        size="small"
        style={{ width: "300px" }}
        // disabled={view}
        value={selected}
      >
        {bankAccs?.length ? (
          <>
            {bankAccs.map((item, i) => (
              <Option
                value={item?.account_info?.id}
                key={item?.account_info?.id}
              >
                {item?.account_info?.account_number +
                  "-" +
                  item?.account_info?.branch_info?.name +
                  "-" +
                  item?.account_info?.bank_info?.name}
              </Option>
            ))}

            {/* {bankAccs.map((item) =>
              item.account_info.map((nestedItem) => (
                <Option value={nestedItem.id} key={nestedItem.id}>
                  {nestedItem.account_number}
                </Option>
              ))
            )} */}
          </>
        ) : (
          ""
        )}
      </Select>
    </Card>
  );
}
