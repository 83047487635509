import { useEffect, useState } from "react";
import { Col, Form, Row, Select, Input } from "antd";
import {
  GET_SUPPLIER_ENDPOINT,
  ALL_SALES_CONTRACT_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";

const ImportPiFilterFields = ({ initialTableData }) => {
  const { Option } = Select;
  const [salseCotractList, setSalseCotractList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);

  useEffect(() => {
    getSalseCotractList();
    getSupplierList();
  }, []);

  const getSalseCotractList = async () => {
    let res = await getData(ALL_SALES_CONTRACT_LIST);

    if (res) {
      setSalseCotractList(res?.data?.data || []);
    }
  };

  const getSupplierList = async () => {
    const bodyData = {
      per_page: 1000
    }
    let res = await getData(GET_SUPPLIER_ENDPOINT, false, bodyData);

    if (res) {
      setSupplierList(res?.data?.data?.data || []);
    }
  };
  return (
    <>
      <Col span={4}>
        <Form.Item label="Sales Contract No" name="sales_contract_id">
          <Select
            placeholder="Sales Contract"
            showSearch
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {salseCotractList?.length &&
              salseCotractList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.reference_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Suppliers" name="supplier_id">
          <Select
            showSearch
            placeholder="Select a Supplier"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {supplierList?.length &&
              supplierList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="PI Number" name="pi_number">
          <Input placeholder="PI Number" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Status" name="status">
          <Select placeholder="Status" style={{ width: "100%" }} size="small">
            <option value="Created">Created</option>
            <option value="Updated">Updated</option>
            <option value="Approved">Approved</option>
            <option value="LC_Requested">LC Requested</option>
            <option value="LC_Opened">LC Opened</option>
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default ImportPiFilterFields;
