import { CREATE_FILTER_DATA, FETCH_START, FETCH_SUCCESS } from "../ActionTypes";

export const onCreateNewFilter = (filterData) => {
  if (!filterData)
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      dispatch({ type: CREATE_FILTER_DATA, payload: null });
      dispatch({ type: FETCH_SUCCESS });
    };

  const validData = {};
  const falsyValues = [null, undefined, ""];

  for (const [key, value] of Object.entries(filterData)) {
    if (!falsyValues.includes(value)) {
      validData[key] = value;
    }
  }

  if (Object.keys(validData).length !== 0) {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      dispatch({ type: CREATE_FILTER_DATA, payload: validData || null });
      dispatch({ type: FETCH_SUCCESS });
    };
  }
};
