import { message } from "antd";
import {
  GARMENTS_PARTS_LIST,
  GET_STYLE_LIST_ENDPOINT,
  POST_CUTTING_PROCESS_INFO,
} from "apiServices/API_ENDPOINTS";
import { getData, postData, withTimeout } from "apiServices/common";
import HandleNotification from "common/Notification";
import { useState, useEffect, createContext, useCallback } from "react";
import { isArrayAndHasValue } from "utils/functions";

export const PostCuttingProcessContext = createContext();

const PostCuttingProcessContextProvider = ({ children }) => {
  // States
  const [styleList, setStyleList] = useState(null);
  const [selectedStyleDetails, setSelectedStyleDetails] = useState(null);
  const [cuttingTableData, setCuttingTableData] = useState(null);
  const [showFactoryInputs, setShowFactoryInputs] = useState(false);
  const [subContractStatus, setSubContractStatus] = useState(null);
  const [garmentsParts, setGarmentsParts] = useState(null);

  const getStyleList = useCallback(async (filterValues) => {
    const query = `${GET_STYLE_LIST_ENDPOINT}`;
    const bodyData = {
      page: filterValues?.page || 1,
      ...filterValues,
    };

    try {
      const response = await withTimeout(getData(query, false, bodyData));

      if (response && response?.data?.code === 200) {
        setStyleList(response?.data?.data?.data);
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error getting style list",
          null,
        );
      }
    } catch (error) {
      HandleNotification(
        "error",
        "bottomRight",
        error.message || "API Response Timeout",
        null,
      );
    }
  }, []);

  const getAllGarmentsParts = useCallback(
    async (filterValues) => {
      const query = `${GARMENTS_PARTS_LIST}`;
      const bodyData = {
        ...filterValues,
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setGarmentsParts(response?.data?.data?.data);
      } else {
        message.error("Error getting garments parts data");
      }
    },
    [],
  );

  useEffect(() => {
    // Get styles list on first render
    getStyleList();
    getAllGarmentsParts();
  }, [getStyleList, getAllGarmentsParts]);

  const getPostCuttingTableData = useCallback(async (props) => {
    const {
      sales_contract_id,
      style_id,
      movement_date,
      buying_office_id,
      sub_contract_factory_id,
    } = props;

    const query = `${POST_CUTTING_PROCESS_INFO}`;
    const bodyData = {
      sales_contract_id: sales_contract_id,
      style_id: style_id,
      movement_date: movement_date,
      buying_office_id: buying_office_id || null,
      sub_contract_factory_id: sub_contract_factory_id || null,
    };

    const response = await postData(query, bodyData);
    if (response && response?.code === 200) {
      setCuttingTableData(response?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting post cutting process table data",
        null,
      );
    }
  }, []);

  const onSelectStyle = (id) => {
    const styleId = id ? Number(id) : null;
    const selectedStyle = styleList.find((style) => style.id === styleId);
    setSubContractStatus(selectedStyle.subcontract_status);
    if(selectedStyle.buying_office_info) {
      setShowFactoryInputs(false);
    } else if(selectedStyle.factory_info){
      setShowFactoryInputs(true);
    }
    setSelectedStyleDetails(selectedStyle);
  };

  const onSelectGarmentsParts = (record_id, part_id) => {
    const foundGarmentsPart = garmentsParts?.find((item) => item?.id === part_id);
    const cuttingTableDataCopy = structuredClone(cuttingTableData);
    const foundCuttingItem = isArrayAndHasValue(cuttingTableDataCopy) && cuttingTableDataCopy?.find((item) => item?.id === record_id);
    foundCuttingItem["process_products"]["garment_part_info"] = foundGarmentsPart;

    setCuttingTableData(cuttingTableDataCopy);
  }

  const onChangeProcessData = (record_id, new_data, context) => {
    const cuttingTableDataCopy = structuredClone(cuttingTableData);
    const foundCuttingItem = isArrayAndHasValue(cuttingTableDataCopy) && cuttingTableDataCopy?.find((item) => item?.id === record_id);
    foundCuttingItem["process_products"][context] = new_data;
    
    setCuttingTableData(cuttingTableDataCopy);
  }

  return (
    <PostCuttingProcessContext.Provider
      value={{
        styleList,
        getPostCuttingTableData,
        onSelectStyle,
        selectedStyleDetails,
        getStyleList,
        cuttingTableData,
        setCuttingTableData,
        showFactoryInputs,
        setShowFactoryInputs,
        subContractStatus,
        setSubContractStatus,
        garmentsParts,
        onSelectGarmentsParts,
        onChangeProcessData,
      }}
    >
      {children}
    </PostCuttingProcessContext.Provider>
  );
};

export default PostCuttingProcessContextProvider;
