import { useState } from "react";
import { Button, Popconfirm, Space, Table, message } from "antd";
import { hasPermission } from "../../../utils/functions";
import { ACTIVITY_LOG_VIEW_PERMISSION } from "../../../routes/permissions";
import { DELETE_ACTIVITY_LOG_REPORT } from '../../../apiServices/API_ENDPOINTS';
import { getData } from "../../../apiServices/common";

const ExportActivity = (props) => {
  // Props
  const { reportTableData, getActivityReportList } = props;

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/activity_logs/")[1] : "";
    return updatedPath;
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/inventory_details/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  const deleteSelectedTracking = async (name) => {
    const updatedName = cutPath(name);
    const query = `${DELETE_ACTIVITY_LOG_REPORT}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);
    if (response && response?.data?.code === 200) {
      message.success("Inventory Details Deleted Successfully");
      getActivityReportList();
    } else {
      message.error("Something went wrong");
    }
  };

  const tableColumns = [
    {
      title: "File Name",
      key: "file_name",
      width: "90%",
      render: (_, record) => {
        return <div>{cutPath(record)}</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Space>
            <Button
              onClick={() => openFile(cutPath(record))}
              disabled={!hasPermission([ACTIVITY_LOG_VIEW_PERMISSION])}
            >
              {hasPermission([ACTIVITY_LOG_VIEW_PERMISSION])
                ? "Download"
                : "No Permission"}
            </Button>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                deleteSelectedTracking(record);
              }}
            >
              <Button
                type="primary"
                danger
                disabled={!hasPermission([ACTIVITY_LOG_VIEW_PERMISSION])}
              >
                {hasPermission([ACTIVITY_LOG_VIEW_PERMISSION])
                  ? "Delete"
                  : "No Permission"}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="table" style={{ marginTop: 10 }}>
      <Table dataSource={reportTableData} columns={tableColumns} />
    </div>
  );
};

export default ExportActivity;
