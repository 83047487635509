import { Button, Collapse, Form, Layout, Space } from "antd";
import { STORE_CUTTING_PRODUCT_ENDPOINT } from "apiServices/API_ENDPOINTS";
import { postData } from "apiServices/common";
import HandleNotification from "common/Notification";
import AppPageHeader from "common/PageHeader";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import { CuttingContext } from "context/CuttingContext/CuttingContextProvider";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { CUTTING_CREATE_PERMISSION } from "routes/permissions";
import { hasPermission, isArrayAndHasValue } from "utils/functions";
import CuttingDetails from "../CuttingDetails/CuttingDetails";
import CuttingForm from "./CuttingForm";

const { Content } = Layout;
const { Panel } = Collapse;

const CreateCuttingEntry = (props) => {
  // Contexts
  const { cuttingTableData,subContractStatus } = useContext(CuttingContext);
  const [isSubmit, setIsSubmit] = useState(false);

  // Antd
  const [form] = Form.useForm();

  // Router
  const navigate = useNavigate();

  const goBackToCuttingRoute = () => {
    navigate("/production/cutting");
  };

  const extra = (
    <Space>
      <Button size="small" onClick={goBackToCuttingRoute}>
        Back
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        onClick={() => form.submit()}
        disabled={!hasPermission([CUTTING_CREATE_PERMISSION]) || isSubmit}
      >
        {hasPermission([CUTTING_CREATE_PERMISSION])
          ? "Submit"
          : "No Permission"}
      </Button>
      <Button size="small" htmlType="submit" onClick={() => form.resetFields()}>
        {" "}
        Reset
      </Button>
    </Space>
  );

  const onFinish = async (values) => {
    setIsSubmit(true);
    
    const filteredCuttingProducts = cuttingTableData?.filter((item) => item?.cutting_products?.cutting_piece);
    
    const cuttingProducts =
      isArrayAndHasValue(filteredCuttingProducts) &&
      filteredCuttingProducts?.map((item) => {
        return {
          cutting_product_id: item?.cutting_products?.cutting_product_id,
          history_id: item?.cutting_products?.history_id,
          internal_po: item?.internal_po,
          original_po: item?.original_po,
          po_map_id: item?.po_map_id,
          color_id: item?.color?.id,
          sub_style_id: item?.sub_style_id,
          size_id: item?.size?.id,
          cutting_piece: item?.cutting_products?.cutting_piece,
          remain_piece: item?.cutting_products?.remain_piece,
          cutting_day: item?.cutting_products?.cutting_day,
          transaction_no_per_day:
            item?.cutting_products?.transaction_no_per_day,
        };
      });

    const query = `${STORE_CUTTING_PRODUCT_ENDPOINT}`;
    const bodyData = {
      sales_contract_id: values?.sales_contract_id,
      buying_office_id: values?.buying_office_id || null,
      style_id: values?.style_id,
      cutting_date: values?.cutting_date,
      cutting_products: cuttingProducts,
      buyer_id: values?.buyer_id || null,
      use_both_style_po: values?.use_both_style_po,
      subcontract_status: subContractStatus,
      sub_contract_factory_id: values?.factory_id || null,
    };

    const response = await postData(query, bodyData);

    if (response) {
      HandleNotification(
        "success",
        "bottomRight",
        "Cutting created successfully",
        null,
      );
      // setIsSubmit(false);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
      setIsSubmit(false);
    }
  };

  const formSubmitting = () => {
    setIsSubmit(false);
  };

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader title="Cutting Entry" extra={extra} />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 15 }}>
            <Panel header={<b>Order Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <CuttingForm form={form} formSubmitting={formSubmitting} />
              </Form>
            </Panel>
          </Collapse>
          {/* Cutting Details Table */}
          <CuttingDetails />
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default CreateCuttingEntry;
