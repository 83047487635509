import React, { useEffect, useState } from "react";
import { Form, Layout, Button, Collapse, Typography } from "antd";
import InventoryLayOutCommon from "../../InventoryFabric/LayOut/InventoryLayOutCommon";
import AppPageHeader from "../../../common/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  onGetShipmentDetails,
  onGetStyleDetails,
  onGetStyleList,
  onShipmentStore,
} from "../../../redux/actions";
import ShipmentForm from "./ShipmentForm";
import { useNavigate } from "react-router";
import ShipmentDetails from "../ShipmentDetails/ShipmentDetails";
import { hasPermission } from "../../../utils/functions";
import { SHIPPING_CREATE_PERMISSION } from '../../../routes/permissions';

const { Content } = Layout;
const { Panel } = Collapse;
const { Title } = Typography;

const ShipmentEntry = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const styleList = useSelector(({ style }) => style.styleList);
  const selectedStyleDetails = useSelector(
    ({ style }) => style.selectedStyleDetails,
  );
  const styleDetails = selectedStyleDetails && selectedStyleDetails.data[0];
  const selectedShippingDetails = useSelector(
    ({ shipment }) => shipment.selectedShipmentDetails,
  );
  const selectedShipping =
    selectedShippingDetails && selectedShippingDetails.data;
  const shippingInfo =
    selectedShippingDetails &&
    selectedShippingDetails.data.shipping_product_info;

  const loading = useSelector(({ common }) => common.loading);

  const [shipmentTableData, setShipmentTableData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [selectedInternalPO, setSelectedInternalPO] = useState(null);
  const [originalPO, setOriginalPO] = useState("");
  const [selectedPoStatus, setSelectedPoStatus] = useState(null);


  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const shippingArr = {
    color_id: [],
    size_id: [],
    shipping_piece: [],
    sub_style_id: [],
    remain_piece: [],
    transfer_piece: [],
    shipping_day: [],
  };

  const shipmentPayloadArr = shipmentTableData?.map((item, idx) => {
    const remainingPc =
      parseInt(item?.daily_consumption?.remain_piece) -
      parseInt(item?.shipping_information?.day_quantity);
    shippingArr.color_id.push(item?.color.id);
    shippingArr.size_id.push(item?.size?.id);
    shippingArr.sub_style_id.push(item?.sub_style_id);
    shippingArr.shipping_piece.push(
      parseInt(item?.shipping_information?.day_quantity),
    );
    shippingArr.remain_piece.push(remainingPc);
    shippingArr.transfer_piece.push(item?.transfer_information?.total);
    shippingArr.shipping_day.push(
      parseInt(selectedShipping?.total_shipping_day),
    );
  });

  const onChangeOriginalPO = (value, options) => {
    setSelectedInternalPO(options?.internal_po);
    setOriginalPO(options?.original_po);
    setSelectedPoStatus(options?.po_status);
    form.setFieldValue("internal_po", options?.internal_po);
  };


  const onChangeInternalPO = (value, options) => {
    setOriginalPO(options?.original_po);
    setSelectedInternalPO(options?.internal_po);
    setSelectedPoStatus(options?.po_status);
    form.setFieldValue("original_po", options?.original_po);
  };

  const onShippingDateChange = (date, dateString) => {
    const requestBody = {
      sales_contract_id:
        styleDetails?.style?.sales_contract_map_info?.sales_contract_id,
      style_id: styleDetails?.style?.id,
      internal_po: selectedInternalPO,
      shipping_date: dateString,
      buying_office_id: styleDetails?.style?.buying_office_id,
    };
    dispatch(onGetShipmentDetails(requestBody));
  };

  const onChangeStyle = (value, options) => {
    dispatch(onGetStyleDetails(value));
  };

  const onFinish = (values) => {
    values["original_po"] = originalPO;
    values["internal_po"] = selectedInternalPO;
    const styleDetailsInfo = {
      sales_contract_id:
        styleDetails?.style?.sales_contract_map_info?.sales_contract_id,
      buying_office_id: styleDetails?.style?.buying_office_id,
      style_id: styleDetails?.style?.id,
    };
    values = { ...values, ...shippingArr, ...styleDetailsInfo };

    dispatch(onShipmentStore(values));
    form.resetFields();
    navigate("/forwarding/store-shipment-info");
    refetch();
  };

  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => form.submit()}
        disabled={!hasPermission([SHIPPING_CREATE_PERMISSION])}
      >
        {hasPermission([SHIPPING_CREATE_PERMISSION])
          ? "Submit"
          : "No Permission"}
      </Button>
      <Button htmlType="submit" size="small" onClick={() => form.resetFields()}>
        {" "}
        Reset
      </Button>
    </div>
  );

  useEffect(() => {
    dispatch(onGetStyleList(1, 10));
    form.resetFields();
  }, []);


  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader title={<Title level={5}>Shipment Entry</Title>} extra={extra} />
        <Content className="item-details">
          <Collapse
            defaultActiveKey={["1"]}
            style={{ marginTop: 10 }}
          >
            <Panel header={<b>Order Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <ShipmentForm
                  onChangeOriginalPO={onChangeOriginalPO}
                  styleDetails={styleDetails}
                  onChangeStyle={onChangeStyle}
                  styleList={styleList}
                  onChangeInternalPO={onChangeInternalPO}
                  selectedShipping={selectedShipping}
                  originalPO={originalPO}
                  onShippingDateChange={onShippingDateChange}
                  selectedPoStatus={selectedPoStatus}
                />
              </Form>
            </Panel>
          </Collapse>
          {shippingInfo ? (
            <ShipmentDetails
              setShipmentTableData={setShipmentTableData}
              selectedShipmentDetails={shippingInfo}
              loading={loading}
              selectedPoStatus={selectedPoStatus}
            />
          ) : (
            <></>
          )}
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default ShipmentEntry;
