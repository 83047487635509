import {BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import AppLayOut from "./common/AppLayOut";
import configureStore from "./redux/store";
import {Provider} from "react-redux";

const store = configureStore();

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AppLayOut>
                    <Router />
                </AppLayOut>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
