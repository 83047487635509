import { Card, Col, Row, Typography } from "antd";

import { cardStyle } from ".";
import OtMoreThan2HSvg from "./ot-more-than-2h.svg";
import OtUoTo2HSvg from "./ot-upto-2h.svg";

export default function HeaderDescription() {
  return (
    <Card style={cardStyle}>
      <Row gutter={[16, 16]}>
        <Col>
          <Box title="Weekend" color="#C4BD97" />
          <Box title="Holiday" color="#8DB4E2" />
          <Box title="Work on Weekend" color="#7e7744" />
        </Col>
        <Col>
          <Box title="Unplanned" color="white" />
        </Col>
        <Col>
          <Box title="Delivery on Time" color="#E3ECF3" />
          <Box title="Delivery Delay " color="#FFFF00" />
          <Box title="Delivery Early" color="#0070C0" />
        </Col>
        <Col>
          <Box title="General duty" color="transparent" />
          <Box title="OT upto 2h" color="transparent">
            <img src={OtUoTo2HSvg} alt="Icon" style={svgImg} />
          </Box>
          <Box title="OT more than 2h" color="transparent">
            <img src={OtMoreThan2HSvg} alt="Icon" style={svgImg} />
          </Box>
        </Col>
        <Col>
          <Box title="Learning Curve Applied" color="white">
            <Typography.Text
              style={{
                color: "red",
                fontSize: "12px",
                margin: "10px",
              }}
            >
              Red Text
            </Typography.Text>
          </Box>
          <Box title="Learning Curve not Applied" color="white">
            <Typography.Text
              style={{
                color: "black",
                fontSize: "12px",
                margin: "10px",
              }}
            >
              Black Text
            </Typography.Text>
          </Box>
        </Col>
      </Row>
    </Card>
  );
}

export const Box = ({ title, color, children = null }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "10px 0px",
      }}
    >
      <div
        color={color}
        style={{
          backgroundColor: color,
          borderRadius: "5px",
          height: "24px",
          minWidth: "24px",
          border: "1px solid lightgray",
          marginRight: "10px",
        }}
      >
        {children}
      </div>

      <Typography.Text>{title}</Typography.Text>
    </div>
  );
};

const svgImg = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
  aspectRatio: "1/1",
};
