import {
    GET_BUYING_OFFICE_LIST,
    CREATE_NEW_BUYING_OFFICE,
    DELETE_BUYING_OFFICE,
    GET_BUYING_OFFICE_DETAIL,
    UPDATE_BUYING_OFFICE_DETAIL,
    GET_SALES_CONTRACT_DETAIL,
    GET_SALES_CONTRACT_LIST,
    CREATE_NEW_SALES_CONTRACT, UPDATE_SALES_CONTRACT_DETAIL, DELETE_SALES_CONTRACT
} from '../ActionTypes';

const initialState = {
    salesContractList: [],
    totalSalesContracts: null,
    selectedSalesContract: null,
    detailsSalesContract: null,
    salesContract: [],
    pageSize: 10,
    page:1,
};

const SalesContractReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_SALES_CONTRACT_LIST:
            return {
                ...state,
                salesContractList: action.payload.data,
                totalSalesContracts: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_SALES_CONTRACT:
            return {
                ...state,
                salesContractList: [action.payload.data, ...state.salesContractList],
                totalSalesContracts: state.totalSalesContracts + 1,
            };

        case DELETE_SALES_CONTRACT: {
            return {
                ...state,
                salesContractList: action.payload.list,
                totalSalesContracts: action.payload.total,

            };
        }

        case GET_SALES_CONTRACT_DETAIL:
            return {
                ...state,
                selectedSalesContract: action.payload,
                detailsSalesContract: action.payload,
                salesContract: action.payload?.sales_contract
            };

        case UPDATE_SALES_CONTRACT_DETAIL:
            return {
                ...state,
                selectedSalesContract: action.payload,
                salesContractList: state.salesContractList.map((salesContract) =>
                    salesContract.id === action.payload.id ? action.payload : salesContract,
                ),
            };

        default:
            return state;
    }
};

export default SalesContractReducer;
