import { PlusOutlined } from "@ant-design/icons";
import { Col, Form, Image, Input, message, Row, Select, Switch } from "antd";
import {
  DIGITALIZATION_CHECKLIST_QUESTION,
  GET_USERS_ROLE_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";
import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { isArrayAndHasValue } from "utils/functions";

export default function ChecklistQuestionsForm({ form }) {
  const [file, setFile] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState(null);
  const fileRef = React.useRef(null);
  const [options, setOptions] = React.useState({
    role: [],
  });
  const navigate = useNavigate();
  const { id } = useParams();

  console.log("id", id);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFileUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name_en", values.name_en);
      formData.append("name_bn", values.name_bn);
      formData.append("role_id", values.role_id);
      formData.append("status", values.status ? 1 : 0);
      if (file) {
        formData.append("image", file);
      }

      const link = id
        ? `${DIGITALIZATION_CHECKLIST_QUESTION}/update/${id}`
        : DIGITALIZATION_CHECKLIST_QUESTION;
      await postData(link, formData);
      message.success("Checklist question added successfully");
      navigate("/checklist/checklist-questions/list");
    } catch (error) {
      message.error("Failed to add checklist question");
    }
  };

  const getOptions = async () => {
    try {
      const res = await getData(GET_USERS_ROLE_ENDPOINT, false, {
        app_menu: "yes",
      });
      if (res?.data) {
        setOptions({
          role: res?.data?.data?.data,
        });
      }
    } catch (error) {}
  };

  const getChecklistQuestion = async () => {
    try {
      const res = await getData(`${DIGITALIZATION_CHECKLIST_QUESTION}/${id}`);
      if (res?.data) {
        form.setFieldsValue({
          name_en: res?.data?.data?.name_en,
          name_bn: res?.data?.data?.name_bn,
          role_id: res?.data?.data?.role_id,
          status: res?.data?.data?.status ? true : false,
        });
        setFileUrl(
          `${process.env.REACT_APP_BASE_URL}/${res?.data?.data?.image_path}`,
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getChecklistQuestion();
    }
  }, [id]);
  useEffect(() => {
    getOptions();
  }, []);

  const getAllUserRoles = useCallback(async (filterValues) => {
    try {
      const bodyData = {
        per_page: 10,
        app_menu: "yes",
        ...filterValues,
      };
      const query = `${GET_USERS_ROLE_ENDPOINT}`;

      const response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        const roleList = response?.data?.data?.data;
        let customRoleData = isArrayAndHasValue(roleList) ? [...roleList] : [];
        setOptions({
          role: customRoleData,
        });
      }
    } catch (e) {
      console.log("Fetching error");
    }
  }, []);

  console.log(options)

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <br />
      <Row gutter={24} align={"middle"}>
        <Col span={6}>
          <Form.Item
            label="Name (English)"
            name="name_en"
            rules={[{ required: true, message: "Name (English) is required" }]}
          >
            <Input
              placeholder="Type Name (English)"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Name (Bangla)" name="name_bn">
            <Input placeholder="Type Name (Bangla)" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Role"
            name="role_id"
            rules={[{ required: true, message: "Role is required" }]}
          >
            <Select
              showSearch
              onSearch={(value) => getAllUserRoles({ name: value })}
              placeholder="Select Role"
              style={{ width: "100%" }}
              optionFilterProp="children"
            >
              {options?.role?.map((role) => (
                <Select.Option value={role?.id} key={role?.id}>
                  {role?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={1}>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Required" }]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div
            role="button"
            style={{
              backgroundColor: "#f5f5f5",
              borderRadius: 12,
              border: "1px dashed #d9d9d9",
              height: 100,
              width: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              fileRef.current.click();
            }}
          >
            <button
              style={{
                border: 0,
                background: "none",
                cursor: "pointer",
              }}
              type="button"
            >
              <PlusOutlined style={{ cursor: "pointer" }} />
              <div style={{ marginTop: 8, cursor: "pointer" }}>Upload</div>
            </button>
            <input
              type="file"
              ref={fileRef}
              accept=".jpg,.jpeg,.png"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </Col>
        <Col span={1}>
          {fileUrl ? (
            <div
              style={{
                borderRadius: 12,
                height: 100,
                width: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image src={fileUrl} />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Form>
  );
}
