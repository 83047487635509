import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Select,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
  Typography,
  Breadcrumb,
  Input,
  Space,
} from "antd";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useLocation } from "react-router-dom";
import { MoreOutlined, EditOutlined, DeleteTwoTone, EyeOutlined } from "@ant-design/icons";
import {
  STYLE_WISE_COST_LIST,
  STYLE_WISE_COST_DELETE,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { alertPop } from "../../../apiServices/common/helper";
import { commaSeparateNumber, hasPermission } from "../../../utils/functions";
import { NO_PERMISSION_REQUIRED } from "../../../routes/permissions";

const CostEstimateEntry = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  //   States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [costList, setCostList] = useState(null);
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const columns = [
    {
      title: "Sales Contract",
      dataIndex: ["sales_contract_info", "reference_no"],
      key: "sales_contract",
    },
    {
      title: "Contract Value",
      dataIndex: "contract_value",
      key: "contract_value",
      render: (value) => <span>{commaSeparateNumber(value)}</span>,
    },
    {
      title: "Total Value",
      dataIndex: "total_value",
      key: "total_value",
      render: (value) => <span>{commaSeparateNumber(value)}</span>,
    },
    {
      title: "Total With Adjust",
      dataIndex: "total_value_with_adjust",
      key: "total_value_with_adjust",
      render: (value) => <span>{commaSeparateNumber(value)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <span>{value === 0 ? 'Pending':'Approved'}</span>,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllCost = async (filterValues) => {
    setLoading(true);

    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };
    const response = await getData(STYLE_WISE_COST_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setCostList(response?.data?.data?.data);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    // Get all cost data on fist load
    getAllCost();
  }, [currentPage]);

  const removeCostEstimate = async (value) => {
    const response = await deleteData(`${STYLE_WISE_COST_DELETE}/${value}`);
    if (response && response?.code === 200) {
      alertPop("success", "Deleted Successfully!");
      getAllCost();
    } else {
      alertPop(
        "error",
        response?.data?.message
          ? response?.data?.message[0]
          : "Something Went Wrong!",
      );
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/merchandising/cost-estimate-update/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
        {
          label: (
            <Link to={`/merchandising/cost-estimate-view/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeCostEstimate(data.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: "2",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button key="3" size="small" type="primary">
        <Link to="/merchandising/cost-estimate-entry">Add New</Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>Cost Estimate</Title>}
            actions={extra}
          />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={costList}
            fetchTableData={getAllCost}
            filterName="cost_estimate"
          />
          <Table
            loading={loading}
            dataSource={costList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default CostEstimateEntry;
