import { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import {
  GET_BRAND_ENDPOINT,
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  SALES_CONTRACT_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const BuyerInquiryFilterFields = ({ initialTableData }) => {
  // States
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);

  // Antd
  const { Option } = Select;

  // Effects
  useEffect(() => {
    // Get buying office, sales contract and buyer list at first load
    getBuyingOfficeList();
    getBuyerList();
    getBrandList();
  }, []);

  const getBuyingOfficeList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(GET_BUYING_OFFICE_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOffice(response?.data?.data?.data || []);
    }
  };

  const getBuyerList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    let response = await getData(GET_BUYER_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyerList(response?.data?.data?.data || []);
    }
  };

  const getBrandList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    let response = await getData(GET_BRAND_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBrandList(response?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item label="Inquiry No." name="inquiry_no">
          <Input placeholder="Write Inquiry No." size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buying Office" name="buying_office_id">
          <Select
            showSearch
            placeholder="Select a Buying Office"
            onSearch={(value) => {
              getBuyingOfficeList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {isArrayAndHasValue(buyingOffice) &&
              buyingOffice.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            placeholder="Select a Buyer"
            onSearch={(value) => {
              getBuyerList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {buyerList?.length &&
              buyerList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Brand" name="brand_id">
          <Select
            showSearch
            placeholder="Select a Brand"
            onSearch={(value) => {
              getBuyerList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {brandList?.length &&
              brandList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default BuyerInquiryFilterFields;
