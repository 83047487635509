import { Fragment, useCallback, useEffect, useState } from "react";
import {
  DeleteOutlined,
  DeleteTwoTone,
  EditTwoTone,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  Table,
  Row,
  Col,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Drawer,
} from "antd";
import AppContent from "../../../../common/AppContent";
import AppPageHeader from "../../../../common/PageHeader";
import ExtraContent from "../../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../../Configuration/CommonComponents/FilterComponent";
import {
  getData,
  postData,
  deleteData,
  putData,
} from "../../../../apiServices/common";
import {
  LINE_TARGET_LIST,
  LINE_TARGET_ADD,
  LINE_TARGET_UPDATE,
  LINE_TARGET_DELETE,
  GET_SEWING_LINE_ENDPOINT,
  CM_EXPENSE_CALCULATION_RESOURCE,
  CM_EXPENSE_HEAD_RESOURCE,
} from "../../../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import {
  commaSeparateNumber,
  hasPermission,
  isArrayAndHasValue,
} from "../../../../utils/functions";
import {
  COST_OF_MAKING_LIST,
  COST_OF_MAKING_CREATE,
  COST_OF_MAKING_EDIT,
  COST_OF_MAKING_DELETE,
} from "../../../../routes/permissions";
import { useLocation } from "react-router";
import moment from "moment";
import { render } from "@testing-library/react";

const ExpenseDataCalculation = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [expenseHeads, setExpenseHeads] = useState(null);
  const [totalData, setTotalData] = useState();
  const [rangeData, setRangeData] = useState();
  const [otExpenseEstimatedCost, setOtExpenseEstimatedCost] = useState(null);
  const [otExpenseActualCost, setOtExpenseActualCost] = useState(null);

  // Antd
  const [expenseDataCalculationForm] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;
  const { confirm } = Modal;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Constants
  const api_base_url = process.env.REACT_APP_BASE_URL;

  const getAllExpenseList = useCallback(
    async (filterValues) => {
      const { name } = filterValues || {};

      setLoading(true);

      const query = `${CM_EXPENSE_CALCULATION_RESOURCE}?page=${currentPage}`;
      const bodyData = {
        ...(name && { name: name }),
      };

      const response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        setLoading(false);
        setTableData(response?.data?.data?.data);
        setTotalData(response?.data?.data?.total);
      } else {
        setLoading(false);
        message.error("Error getting Expense Data");
      }
    },
    [currentPage],
  );

  const getExpenseHeads = async () => {
    const query = `${CM_EXPENSE_HEAD_RESOURCE}`;
    const bodyData = {
      per_page: 100,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setExpenseHeads(response?.data?.data?.data);
    } else {
      message.error("Error getting sewing lines");
    }
  };

  // Effects
  useEffect(() => {
    getAllExpenseList(filterData);
    getExpenseHeads();
  }, [refresh, filterData, getAllExpenseList, currentPage]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    expenseDataCalculationForm.resetFields();
    getExpenseHeads();
    setIsModalOpen(false);
    setIsEdit(false);
    refetch();
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const deleteExpenseData = async (id) => {
    setLoading(true);
    const response = await deleteData(
      `${CM_EXPENSE_CALCULATION_RESOURCE}/${id}`,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Data Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editMonthlyExpenseData = async (formValues) => {
    setLoading(true);

    const bodyData = {
      month_year_range: formValues?.month_year_range,
      dollar_conversion_rate: formValues?.dollar_conversion_rate,
      ot_expense_estimated_cost: otExpenseEstimatedCost,
      ot_expense_actual_cost: otExpenseActualCost,
      total_manpower:
        typeof formValues?.total_manpower === "string" &&
        formValues?.total_manpower?.includes(",")
          ? parseFloat(formValues.total_manpower.replace(/,/g, ""))
          : formValues?.total_manpower,
      expense_details: expenseHeads?.map((head) => {
        return {
          estimated_cost_value: head?.estimated_cost_value || 0,
          cost_value: head?.cost_value || 0,
          cm_expense_head_id: head?.id,
        };
      }),
    };

    const response = await putData(
      `${CM_EXPENSE_CALCULATION_RESOURCE}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Expense Data for CM Calculation Updated Successfully");
      refetch();
      expenseDataCalculationForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createMonthlyExpenseData = async (formValues) => {
    setLoading(true);

    const bodyData = {
      month_year_range: formValues?.month_year_range,
      dollar_conversion_rate: formValues?.dollar_conversion_rate,
      ot_expense_estimated_cost: otExpenseEstimatedCost,
      ot_expense_actual_cost: otExpenseActualCost,
      total_manpower: formValues?.total_manpower,
      expense_details: expenseHeads?.map((head) => {
        return {
          estimated_cost_value: head?.estimated_cost_value || 0,
          cost_value: head?.cost_value || 0,
          cm_expense_head_id: head?.id,
        };
      }),
    };

    const response = await postData(CM_EXPENSE_CALCULATION_RESOURCE, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Expense Data for CM Calculation Submitted Successfully");
      refetch();
      expenseDataCalculationForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);
    expenseDataCalculationForm.setFieldsValue({
      month_year_range: record?.month_year_range
        ? record?.month_year_range
        : null,
      dollar_conversion_rate: record?.dollar_conversion_rate
        ? record?.dollar_conversion_rate
        : null,
      total_manpower: record?.total_manpower
        ? commaSeparateNumber(record?.total_manpower)
        : null,
      id: record?.id,
    });
    setOtExpenseEstimatedCost(record?.ot_expense_estimated_cost || null);
    setOtExpenseActualCost(record?.ot_expense_actual_cost || null);
    setExpenseHeads(record?.expenseDetails);
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const onChangeValue = (value, id, context) => {
    const expenseHeadsCopy = [...expenseHeads];
    const foundHead = expenseHeadsCopy.find((head) => head?.id === id);
    foundHead[context] = value ? Number(value) : 0;
    setExpenseHeads(expenseHeadsCopy);
  };

  const tableColumns = [
    {
      title: "Month/Year",
      dataIndex: "month_year",
      key: "month_year",
      width: "10%",
      align: "center",
    },
    {
      title: "Total Estimated Expense (Tk)",
      dataIndex: "total_estimated_cost_value",
      key: "total_estimated_cost_value",
      width: "10%",
      align: "right",
      render: (value) => commaSeparateNumber(value) ?? 0,
    },
    {
      title: "Total Actual Expense (Tk)",
      dataIndex: "total_value",
      key: "total_value",
      width: "10%",
      align: "right",
      render: (value) => commaSeparateNumber(value),
    },
    {
      title: "Total Manpower",
      dataIndex: "total_manpower",
      key: "total_manpower",
      width: "10%",
      align: "right",
      render: (value) => commaSeparateNumber(value),
    },
    {
      title: "Dollar Conversion Rate",
      dataIndex: "dollar_conversion_rate",
      key: "dollar_conversion_rate",
      width: "10%",
      align: "right",
      render: (value) => value ?? 0,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <>
            <Button
              // icon={<EditTwoTone />}
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => {
                openModalForEdit(record);
              }}
              disabled={!hasPermission([COST_OF_MAKING_EDIT])}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={(event) => deleteExpenseData(record?.id)}
            >
              <Button
                size="small"
                danger
                style={{ marginLeft: 5 }}
                disabled={!hasPermission([COST_OF_MAKING_DELETE])}
              >
                {hasPermission([COST_OF_MAKING_DELETE])
                  ? "Delete"
                  : "No Permission"}
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const DrawerHeader = () => {
    return (
      <Row gutter={6}>
        <Col span={12}>
          <span>{isEdit ? "Edit Expense Data" : "Create Expense Data"}</span>
        </Col>
        <Col span={12}>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
                size="small"
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                loading={loading}
                size="small"
                onClick={() => expenseDataCalculationForm.submit()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const getTotalValue = (expenseHeads) => {
    const totalData = expenseHeads?.reduce((acc, curr) => {
      return acc + curr?.cost_value || 0;
    }, 0);

    const totalEstimatedData = expenseHeads?.reduce((acc, curr) => {
      return acc + curr?.estimated_cost_value || 0;
    }, 0);

    const formattedActualTotal = commaSeparateNumber(totalData);
    const formattedEstimatedTotal = commaSeparateNumber(totalEstimatedData);
    return {
      total_actual_cost_value: formattedActualTotal,
      total_estimated_cost_value: formattedEstimatedTotal,
    };
  };

  const getMonthYearOptions = () => {
    const currentYear = moment().year();
    const previousYear = currentYear - 1;
    const years = [currentYear, previousYear];

    const options = [];

    years.forEach((year) => {
      for (let month = 1; month <= 12; month++) {
        const formattedMonth = month < 10 ? `0${month}` : `${month}`;
        const optionValue = `${year}-${formattedMonth}`;
        const optionLabel = moment(
          `${year}-${formattedMonth}`,
          "YYYY-MM",
        ).format("MMMM, YYYY");
        options.push({ value: optionValue, label: optionLabel });
      }
    });

    return options;
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([COST_OF_MAKING_CREATE])}
                addButtonText={
                  hasPermission([COST_OF_MAKING_CREATE])
                    ? "Add New"
                    : "No Permission"
                }
                hideFilterButton
              />
            }
            title={<Title level={5}>Expense Data For CM Calculation</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                bordered
                pagination={{
                  showSizeChanger: false,
                  current: currentPage,
                  total: totalData,
                  pageSize: rangeData,
                  onChange: (page) => paginate(page),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </div>
          </>
        }
      />
      <Drawer
        title={<DrawerHeader />}
        placement="right"
        onClose={onCloseModal}
        open={isModalOpen}
        width={700}
        maskStyle={{ backdropFilter: "blur(4px)" }}
      >
        <Form
          form={expenseDataCalculationForm}
          layout="vertical"
          onFinish={isEdit ? editMonthlyExpenseData : createMonthlyExpenseData}
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="month_year_range"
                placeholder="Month"
                label="Month"
                rules={[
                  {
                    required: true,
                    message: "Month is Required",
                  },
                ]}
              >
                <Select placeholder="Select Month" size="small">
                  {getMonthYearOptions()?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="dollar_conversion_rate"
                placeholder="Dollar Conversion Rate"
                label="Dollar Conversion Rate"
                rules={[
                  {
                    required: false,
                    message: "Dollar Conversion Rate is Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Dollar Conversion Rate"
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="custome-table" style={{ marginBottom: 15 }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  {/* Expense Head */}
                  <th key="header_line_name" className="bc-orange">
                    Expense Head
                  </th>
                  {/* Average Cost Tk. */}
                  <th key="header_capacity" className="bc-orange">
                    Estimated Cost Tk.
                  </th>
                  {/* Average Cost Tk. */}
                  <th key="header_capacity" className="bc-orange">
                    Actual Cost Tk.
                  </th>
                </tr>
              </thead>

              <tbody>
                {isArrayAndHasValue(expenseHeads) ? (
                  <>
                    {expenseHeads.map((obj, index) => {
                      return (
                        <tr key={`table_parent_key_${index}`}>
                          {/* Expense Head */}
                          <td
                            className="t-body sticky"
                            style={{ fontSize: 14 }}
                          >
                            {obj?.expense_details?.name || obj?.name}
                          </td>
                          <td className="t-body sticky">
                            <InputNumber
                              style={{ width: 200 }}
                              placeholder="Value"
                              onChange={(value) => {
                                onChangeValue(
                                  value,
                                  obj?.id,
                                  "estimated_cost_value",
                                );
                              }}
                              value={commaSeparateNumber(
                                obj?.estimated_cost_value,
                              )}
                              size="small"
                            />
                          </td>
                          {/* Average Cost Tk. */}
                          <td className="t-body sticky">
                            <InputNumber
                              style={{ width: 200 }}
                              placeholder="Value"
                              onChange={(value) => {
                                onChangeValue(value, obj?.id, "cost_value");
                              }}
                              value={commaSeparateNumber(obj?.cost_value)}
                              size="small"
                            />
                          </td>
                        </tr>
                      );
                    })}
                    <td
                      className="t-body sticky"
                      colSpan={1}
                      style={{ fontSize: 14 }}
                    >
                      <strong>Total</strong>
                    </td>
                    <td className="t-body sticky" style={{ fontSize: 14 }}>
                      {getTotalValue(expenseHeads)
                        ?.total_estimated_cost_value ?? 0}
                    </td>
                    <td className="t-body sticky" style={{ fontSize: 14 }}>
                      {getTotalValue(expenseHeads)?.total_actual_cost_value ??
                        0}
                    </td>
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            <table style={{ width: "100%" }} className=" mt-3">
              <tbody>
                <tr>
                  {/* Expense Head */}
                  <td className="t-body" style={{ fontSize: 14 }}>
                    OT Expense
                  </td>
                  <td className="t-body">
                    <InputNumber
                      style={{ width: 200, marginBottom: 0 }}
                      placeholder="Value"
                      value={commaSeparateNumber(otExpenseEstimatedCost)}
                      onChange={(value) =>
                        setOtExpenseEstimatedCost(value ? Number(value) : null)
                      }
                      size="small"
                    />
                  </td>
                  {/* Average Cost Tk. */}
                  <td className="t-body sticky">
                    <InputNumber
                      style={{ width: 200, marginBottom: 0 }}
                      placeholder="Value"
                      value={commaSeparateNumber(otExpenseActualCost)}
                      onChange={(value) =>
                        setOtExpenseActualCost(value ? Number(value) : null)
                      }
                      size="small"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Row gutter={6}>
            <Col span={12}>
              <Form.Item
                name="total_manpower"
                placeholder="Total Manpower"
                label="Total Manpower"
                rules={[
                  {
                    required: true,
                    message: "Manpower is Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Total Manpower"
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ExpenseDataCalculation;
