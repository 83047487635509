import { Col, Form, Row, Select, Input } from "antd";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  GET_B2BLCS_FOR_NOTING,
  STYLE_MERCHANDISING_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
const SecurityBoltFilterFields = ({ initialTableData }) => {
  const { Option } = Select;
  const [b2bLcs, setB2bLcs] = useState([]);

  // useEffect(() => {
  //   getB2bLcs();
  // }, []);

  // const getB2bLcs = async () => {
  //   let res = await getData(GET_B2BLCS_FOR_NOTING);
  //   //console.log("res?.data?.data", res?.data?.data);
  //   if (res) {
  //     setB2bLcs(res?.data?.data || []);
  //   }
  // };

  // console.log("b2blcs", b2bLcs);
  return (
    <>
      <Col span={4}>
      <Form.Item label="Security Bolt Seal Number" name="security_bolt_seal_number">
          <Input placeholder="Write Security Bolt Seal Number" size="small" />
        </Form.Item>
      </Col>
      
    </>
  );
};

export default SecurityBoltFilterFields;
