import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../../apiServices";
import {
  ACCEPTANCE_AVAILABLE_BALANCE,
  ACCEPTANCE_B2BLC_DETAILS,
  ACCEPTANCE_B2BLC_LIST,
  ACCEPTANCE_DETAIL_DOC,
  ACCEPTANCE_INVOICE_NUMBERS,
  ACCEPTANCE_REMARKS,
  ACCEPTANCE_REMARK_ADD,
  GET_INVOICE_IN_HOUSE_DATE,
  GET_INVOICE_RECONCILIATION_STATUS,
  IMPORT_ACCEPTANCE_DETAIL_ADD,
  IMPORT_ACCEPTANCE_DETAIL_EDIT,
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import {
  cglFormatDate,
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../utils/functions";

const token = localStorage.getItem("token");

const ImportAcceptanceForm = (props) => {
  // Props
  const { form, isEdit, isAdd, view, editInfo } = props;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint(); // Hook to get screen size

  // Antd
  const { Option } = Select;
  const navigate = useNavigate();

  // States
  const [b2bLcList, setB2bLcList] = useState([]);
  const [invoiceNumbersList, setInvoiceNumberList] = useState([]);
  const [remarks, setRemarks] = useState(null);
  const [addedRemark, setAddedRemark] = useState(null);
  const [paymentTerm, setPaymentTerm] = useState(null);
  const [supplierType, setSupplierType] = useState(null);
  const [visible, setVisible] = useState(false);
  const [rowId, setRowId] = useState(null);

  const [filesList, setFileList] = useState([]);

  // Routers
  const { importId } = useParams();

  const onFinishAcceptanceDetail = async (values) => {
    const query = isEdit
      ? `${IMPORT_ACCEPTANCE_DETAIL_EDIT}/${importId}`
      : `${IMPORT_ACCEPTANCE_DETAIL_ADD}`;
    const invoice_items = invoiceNumbersList?.map((item) => {
      return {
        import_detail_id: item?.id,
        invoice_value: item?.invoice_value || 0,
        penalty_value: item?.penalty_value || 0,
        import_payment_value: item?.invoice_value - item?.penalty_value || 0,
        bank_reference_no: item?.bank_reference_no || null,
        acceptance_release_date: item?.acceptance_release_date || null,
        maturity_date: item?.maturity_date || null,
        payment_release_date: item?.payment_release_date || null,
        in_house_date: item?.in_house_date || null,
        reconciliation_status: item?.reconciliation_status || null,
        remark_id: item?.remarks || [],
        invoice_document: item?.invoice_document || null,
      };
    });

    const bodyData = {
      supplier_id: values?.supplier_id,
      supplierType: supplierType,
      import_lc_number: values?.import_lc_number || null,
      terms_of_payment: values?.terms_of_payment || null,
      invoice_items: invoice_items || null,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        `Acceptance Details ${isEdit ? "updated" : "added"} Successfully`,
        null,
      );
      navigate("/import/acceptance-details");
    } else {
      HandleNotification("error", "bottomRight", "Something went wrong", null);
    }
  };

  const getB2BLcList = async () => {
    const query = `${ACCEPTANCE_B2BLC_LIST}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setB2bLcList(response?.data?.data || null);
    }
  };

  const getRemarksList = async (filterValues) => {
    const query = `${ACCEPTANCE_REMARKS}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 1000,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setRemarks(response?.data?.data);
    }
  };

  useEffect(() => {
    getB2BLcList();
    getRemarksList();
  }, []);

  const onRemarkChange = (event) => {
    setAddedRemark(event.target.value);
  };

  const addNewRemark = async (event) => {
    event.preventDefault();
    const query = `${ACCEPTANCE_REMARK_ADD}`;
    const bodyData = {
      remarks: addedRemark,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setAddedRemark(null);
      HandleNotification(
        "success",
        "bottomRight",
        "Remark added successfully",
        null,
      );
      getRemarksList();
    }
  };

  const getLcDetails = useCallback(
    async (value) => {
      const query = `${ACCEPTANCE_B2BLC_DETAILS}/${value}`;
      const response = await getData(query);
      console.log("response", response);
      if (response && response?.data?.code === 200) {
        const masterData = response?.data?.data[0]?.sales_contract_commercial;
        setPaymentTerm(masterData?.terms_of_payment || null);
        setSupplierType(masterData?.supplier_info?.local_status);

        form.setFieldsValue({
          b2b_lc_date: masterData?.b2b_lc_date
            ? moment(masterData?.b2b_lc_date)
            : null,
          total_lc_value: masterData?.total_lc_value
            ? Number(masterData?.total_lc_value)
            : 0,
          supplier_id: masterData?.supplier_info?.id || null,
          supplier_name:
            masterData?.supplier_info?.short_name ||
            masterData?.supplier_info?.name ||
            null,
          terms_of_payment: masterData?.terms_of_payment || null,
        });
      }
    },
    [form],
  );

  const getAvailableBalance = useCallback(
    async (value) => {
      const query = `${ACCEPTANCE_AVAILABLE_BALANCE}/${value}`;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {
        form.setFieldsValue({
          acceptance_value: response?.data?.data
            ? Number(response?.data?.data)
            : 0,
        });
      }
    },
    [form],
  );

  useEffect(() => {
    // Get data when in edit mode
    if (editInfo) {
      form.setFieldsValue({
        import_lc_number: editInfo?.import_lc_number || null,
      });
      getLcDetails(editInfo?.import_lc_number);
      getAvailableBalance(editInfo?.import_lc_number);
      setInvoiceNumberList(editInfo?.invoice_items || []);

      // Fetch and set the in-house date for each invoice item
      const updatedInvoiceNumberList = editInfo?.invoice_items;
      for (let i = 0; i < updatedInvoiceNumberList?.length; i++) {
        const invoiceId = updatedInvoiceNumberList[i].id;
        if (invoiceId) {
          getInvoiceInHouseDate(invoiceId).then((inHouseDate) => {
            updatedInvoiceNumberList[i].in_house_date = inHouseDate || null;
            updatedInvoiceNumberList[i].findInHouse = inHouseDate
              ? true
              : false;
          });
          getInvoiceReconciliationStauts(invoiceId).then(
            (reconciliation_status) => {
              updatedInvoiceNumberList[i].reconciliation_status =
                reconciliation_status || null;
            },
          );
        }
      }

      setInvoiceNumberList(updatedInvoiceNumberList);
    }
  }, [editInfo, getLcDetails, form, getAvailableBalance]);

  const getInvoices = async (value) => {
    const query = `${ACCEPTANCE_INVOICE_NUMBERS}/${value}/1`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      // setInvoiceNumberList(response?.data?.data);

      const invoiceListStatus = await Promise.all(
        response?.data?.data?.map(async (invoice) => {
          const reconciliationStatus = await getInvoiceReconciliationStauts(
            invoice?.id,
          );
          const inHouseDate = await getInvoiceInHouseDate(invoice?.id);
          return {
            ...invoice,
            in_house_date: inHouseDate,
            findInHouse: inHouseDate ? true : false,
            reconciliation_status:
              reconciliationStatus === null ? "Pending" : reconciliationStatus,
          };
        }),
      );

      setInvoiceNumberList(invoiceListStatus);
    }
  };

  const onChangeInvoiceData = (id, value, context) => {
    const invoiceNumbersListCopy = [...invoiceNumbersList];
    const foundData = invoiceNumbersListCopy.find((item) => item.id === id);
    foundData[context] = value;
    console.log("value", value);
    setInvoiceNumberList(invoiceNumbersListCopy);

    if (context === "penalty_value") {
      // Call the handlePenaltyValueChange function
      handlePenaltyValueChange(id, value);
    }
  };

  const handlePenaltyValueChange = (id, penaltyValue) => {
    const updatedInvoiceNumbersList = invoiceNumbersList.map((invoice) => {
      if (invoice.id === id) {
        // Calculate the new payment value by subtracting penalty value from invoice value
        const newPaymentValue = invoice.invoice_value - penaltyValue;
        return {
          ...invoice,
          penalty_value: penaltyValue,
          import_payment_value: newPaymentValue,
        };
      }
      return invoice;
    });

    setInvoiceNumberList(updatedInvoiceNumbersList);
  };

  const getInvoiceInHouseDate = async (invoice_id) => {
    const getDate = `${GET_INVOICE_IN_HOUSE_DATE}/${invoice_id}/in-house-date`;
    const response = await getData(getDate);

    if (response && response?.data?.code === 200) {
      return response?.data?.data;
    }

    return null;
  };

  const getInvoiceReconciliationStauts = async (invoice_id) => {
    const getDate = `${GET_INVOICE_RECONCILIATION_STATUS}/${invoice_id}`;
    const response = await getData(getDate);

    if (response && response?.data?.code === 200) {
      return response?.data?.data?.status;
    }

    return null;
  };

  let columns = [
    {
      title: "Invoice Number",
      dataIndex: ["invoice_number"],
      key: "invoice_number",
      width: "10%",
    },
    {
      title: "Invoice Value",
      dataIndex: ["invoice_value"],
      key: "invoice_value",
      width: "10%",
      render: (value, record) => {
        const valueToNum = Number(value).toFixed(2);
        const convertedNum = valueToNum ? Number(valueToNum) : 0;
        return (
          <span>${convertedNum ? commaSeparateNumber(convertedNum) : 0}</span>
        );
      },
    },
    {
      title: "In-house Date",
      dataIndex: "in_house_date",
      key: "in_house_date",
      render: (value) => <span>{cglFormatDate(value)}</span>,
    },
  ];

  if (paymentTerm === "Deferred") {
    // If paymentTerm is Deferred, add the additional columns
    columns = columns.concat([
      {
        title: "Acceptance Release Date",
        dataIndex: "acceptance_release_date",
        key: "acceptance_release_date",
        width: "20%",
        render: (value, record) => {
          return (
            <DatePicker
              style={{ width: "100%" }}
              format={"DD-MMM-YYYY"}
              disabled={record?.findInHouse === false || view}
              defaultValue={value ? moment(value) : null}
              onChange={(inputValue) => {
                onChangeInvoiceData(
                  record?.id,
                  moment(inputValue).format("YYYY-MM-DD"),
                  "acceptance_release_date",
                );
              }}
              size="small"
            />
          );
        },
      },
      {
        title: "Maturity Date",
        dataIndex: "maturity_date",
        key: "maturity_date",
        width: "20%",
        render: (value, record) => {
          return (
            <DatePicker
              style={{ width: "100%" }}
              size="small"
              format={"DD-MMM-YYYY"}
              disabled={record?.findInHouse === false || view}
              defaultValue={value ? moment(value) : null}
              onChange={(inputValue) => {
                onChangeInvoiceData(
                  record?.id,
                  moment(inputValue).format("YYYY-MM-DD"),
                  "maturity_date",
                );
              }}
            />
          );
        },
      },
    ]);
  }

  columns = columns.concat([
    {
      title: "Payment Release Date",
      dataIndex: "payment_release_date",
      key: "payment_release_date",
      width: "20%",
      render: (value, record) => {
        return (
          <DatePicker
            style={{ width: "100%" }}
            size="small"
            disabled={view}
            format={"DD-MMM-YYYY"}
            defaultValue={value ? moment(value) : null}
            onChange={(inputValue) => {
              onChangeInvoiceData(
                record?.id,
                inputValue ? moment(inputValue).format("YYYY-MM-DD") : null,
                "payment_release_date",
              );
            }}
          />
        );
      },
    },
    // {
    //   title: "Penalty Value (If any)",
    //   key: "penalty_value",
    //   width: "10%",
    //   render: (value, record) => {
    //     return (
    //       <InputNumber
    //         addonBefore="$"
    //         style={{ width: "100%" }}
    //         size="small"
    //         disabled={view}
    //         value={record?.penalty_value || 0}
    //         onChange={(inputValue) => {
    //           onChangeInvoiceData(record?.id, inputValue, "penalty_value");
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      title: "Payment Value",
      dataIndex: ["import_payment_value"],
      key: "import_payment_value",
      render: (value, record) => {
        const valueToNum = Number(value).toFixed(2);
        const convertedNum = !isNaN(parseFloat(valueToNum))
          ? Number(valueToNum)
          : Number(Number(record?.invoice_value).toFixed(2));
        return (
          <span>${convertedNum ? commaSeparateNumber(convertedNum) : 0}</span>
        );
      },
    },
    // {
    //   title: "Reason of Penalty",
    //   key: "penalty_reason",
    //   width: "20%",
    //   render: (value, record) => {
    //     return (
    //       <Select
    //         style={{
    //           width: "100px",
    //         }}
    //         disabled={view}
    //         size="small"
    //         placeholder="Reasons"
    //         mode="multiple"
    //         onSelect={(value) => {
    //           const context = "remarks";
    //           const reasons_data = record?.remarks || [];
    //           reasons_data.push(value);
    //           onChangeInvoiceData(record?.id, reasons_data, context);
    //         }}
    //         onDeselect={(value) => {
    //           const context = "remarks";
    //           const reasons_data = record?.remarks;
    //           const filteredReasons = reasons_data?.filter(
    //             (item) => item !== value,
    //           );
    //           onChangeInvoiceData(record?.id, filteredReasons, context);
    //         }}
    //         value={record?.remarks}
    //         dropdownRender={(menu) => (
    //           <>
    //             {menu}
    //             <Divider
    //               style={{
    //                 margin: "8px 0",
    //               }}
    //             />
    //             <Space
    //               style={{
    //                 padding: "0 8px 4px",
    //               }}
    //             >
    //               <Input
    //                 placeholder="Enter new reason"
    //                 size="small"
    //                 value={addedRemark}
    //                 onChange={onRemarkChange}
    //               />
    //               <Button
    //                 type="text"
    //                 icon={<PlusOutlined />}
    //                 size="small"
    //                 onClick={addNewRemark}
    //               >
    //                 Add
    //               </Button>
    //             </Space>
    //           </>
    //         )}
    //         options={remarks?.map((item) => ({
    //           label: item?.remarks || "",
    //           value: item?.id,
    //         }))}
    //       />
    //     );
    //   },
    // },
    {
      title: "Reconciliation Status",
      dataIndex: "reconciliation_status",
      key: "reconciliation_status",
      align: "center",
      render: (value, record) => {
        if (supplierType === 1) {
          return (
            <Select
              showSearch
              placeholder="Select a Status"
              size="small"
              disabled={true}
              value={value}
              onChange={(inputValue) => {
                onChangeInvoiceData(
                  record?.id,
                  inputValue,
                  "reconciliation_status",
                );
              }}
            >
              <Option value="Pending" key="Pending">
                Pending
              </Option>
              <Option value="Gap" key="Gap">
                Gap
              </Option>
              <Option value="Done" key="Done">
                Done
              </Option>
            </Select>

            // <Tag><span>{supplierType===1 ? value: 'N/A'}</span></Tag>
          );
        } else {
          return (
            <Tag>
              <span>N/A</span>
            </Tag>
          );
        }
      },
    },
    {
      title: "Bank Reference No",
      dataIndex: "bank_reference_no",
      key: "bank_reference_no",
      render: (value, record) => {
        return (
          <Input
            style={{ width: "150px" }}
            placeholder="Bank Reference No"
            defaultValue={value ? value : ""}
            size="small"
            disabled={view}
            onChange={(inputValue) => {
              onChangeInvoiceData(
                record?.id,
                inputValue.target.value,
                "bank_reference_no",
              );
            }}
          />
        );
      },
    },
    {
      title: "Payment Voucher",
      dataIndex: "payment_release_date",
      key: "payment_release_date",
      render: (value, record) => {
        return (
          <span>
            <a
              href={`${BASE_URL}/storage/submission_realization_voucher/${record?.invoice_document}`}
              target="_blank"
              rel="noreferrer"
            >
              {record?.invoice_document
                ? `${record?.invoice_document?.slice(0, 10)}...`
                : ""}
            </a>
            <Upload
              action={`${BASE_URL}${ACCEPTANCE_DETAIL_DOC}`}
              accept={"image/*,.pdf, .doc, .xlsx"}
              headers={{
                authorization: `Bearer ${token}`,
              }}
              showUploadList={false}
              onChange={(info) => onUploadFile(info, record?.id)}
            >
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                size="small"
                disabled={view}
              >
                Upload
              </Button>
            </Upload>
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, i) => (
        <Space size="middle">
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              setRowId(i);
              setVisible(true);
            }}
          >
            Add Reason
          </Button>
        </Space>
      ),
    },
  ]);

  const onUploadFile = (info, key) => {
    const fileResponse =
      info?.file?.response?.code === 200 ? info?.file?.response?.data : null;
    if (fileResponse) {
      onChangeInvoiceData(key, fileResponse, "invoice_document");
    }
  };

  const TableSummary = ({ pageData }) => {
    const totalPaymentValue = pageData.reduce((acc, item) => {
      const invoiceValue = Number(item?.invoice_value);
      const penaltyValue = Number(item?.penalty_value);

      if (isEdit) {
        return acc + Number(item?.import_payment_value);
      }

      // if(isEdit) {
      //   return acc + Number(item?.import_payment_value);
      // } else if(!isNaN(penaltyValue)) {
      //   return acc + (invoiceValue - penaltyValue);
      // } else {
      //   return acc + invoiceValue;
      // }
    }, 0);

    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#F5F5F5" }}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <strong>{`Total Payment Value`}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          {paymentTerm === "Deferred" && (
            <>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
            </>
          )}
          <Table.Summary.Cell index={5} colSpan={10}>
            <strong>
              $
              {totalPaymentValue
                ? commaSeparateNumber(Number(totalPaymentValue.toFixed(2)))
                : 0}
            </strong>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishAcceptanceDetail}
      >
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item label="BTB LC Number" name="import_lc_number">
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="LC Number"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    getLcDetails(value);
                    getAvailableBalance(value);
                    getInvoices(value);
                  }}
                  size="small"
                  disabled={view}
                >
                  {b2bLcList.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.b2b_lc_number}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="BTB LC Date" name="b2b_lc_date">
                <DatePicker
                  format={"DD-MMM-YYYY"}
                  style={{ width: "100%" }}
                  disabled
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="BTB LC Value" name="total_lc_value">
                <InputNumber style={{ width: "100%" }} disabled size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={0}>
              <Form.Item name="supplier_id" />
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Supplier" name="supplier_name">
                <Input disabled size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                // label="Available Acceptance Value"
                label={
                  paymentTerm === "Deferred"
                    ? "Available Acceptance Value"
                    : "Available Value for payment"
                }
                name="acceptance_value"
              >
                <InputNumber
                  disabled={true}
                  style={{ width: "100%", color: "#000" }}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Terms of Payment" name="terms_of_payment">
                <Input disabled size="small" />
              </Form.Item>
            </Col>
          </Row>
          {isArrayAndHasValue(invoiceNumbersList) && (
            <Card title="Invoice Payment Details">
              <Table
                bordered
                columns={columns}
                dataSource={invoiceNumbersList}
                summary={(pageData) => {
                  return <TableSummary pageData={pageData} />;
                }}
                scroll={{
                  x: 1600,
                }}
              />
            </Card>
          )}
        </Card>
      </Form>
      {/* // add modal in modal add form Reason of Penalty & Penalty Value (If any) in modal */}
      <Modal
        title="Add Reason of Penalty & Penalty Value (If any)"
        visible={visible}
        onOk={() => {
          setVisible(false);
        }}
        okButtonProps={{
          htmlType: "submit",
        }}
        onCancel={() => {
          setRowId(null);
          setVisible(false);
        }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Typography.Text>Reason of Penalty:</Typography.Text>
            <Select
              style={{
                width: "100%",
              }}
              disabled={view}
              size="small"
              placeholder="Reasons"
              mode="multiple"
              onSelect={(value) => {
                const record = invoiceNumbersList[rowId];
                const context = "remarks";
                const reasons_data = record?.remarks || [];
                reasons_data.push(value);
                onChangeInvoiceData(record?.id, reasons_data, context);
              }}
              onDeselect={(value) => {
                const record = invoiceNumbersList[rowId];
                const context = "remarks";
                const reasons_data = record?.remarks;
                const filteredReasons = reasons_data?.filter(
                  (item) => item !== value,
                );
                onChangeInvoiceData(record?.id, filteredReasons, context);
              }}
              value={invoiceNumbersList[rowId]?.remarks}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "0 8px 4px",
                      width: "100%",
                    }}
                  >
                    <Input
                      placeholder="Enter new reason"
                      size="small"
                      value={addedRemark}
                      onChange={onRemarkChange}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      size="small"
                      onClick={addNewRemark}
                    >
                      Add
                    </Button>
                  </Space>
                </>
              )}
              options={remarks?.map((item) => ({
                label: item?.remarks || "",
                value: item?.id,
              }))}
            />
          </Col>
          <Col span={24}>
            <Typography.Text>Penalty Value (If any):</Typography.Text>
            <InputNumber
              addonBefore="$"
              style={{ width: "100%" }}
              size="small"
              disabled={view}
              value={invoiceNumbersList[rowId]?.penalty_value || 0}
              onChange={(inputValue) => {
                const record = invoiceNumbersList[rowId];
                onChangeInvoiceData(record?.id, inputValue, "penalty_value");
              }}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ImportAcceptanceForm;
