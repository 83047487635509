import React, { useRef } from "react";
import { Card, Button, Affix } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "../../../Common/Header";
import { isMobileView } from "../../../Common/AppHelper";
import AddOrUpdateForm from "./AddOrUpdateForm";

const AddTNAPlan = (props) => {
  // Props
  const { width, isEdit } = props;

  // Refs
  const propertyFormRef = useRef(null);

  // Router
  const navigate = useNavigate();

  // Redux
  const discartForm = () => {
    navigate("/tanda/plan-list");
  };

  const add = () => {
    propertyFormRef.current.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="T&A"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} danger onClick={() => discartForm()}>
                Discard
              </Button>,
              <Button size="small" key={2} type="primary" onClick={() => add()}>
                {isEdit ? "Update TNA" : "Add TNA"}
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddOrUpdateForm ref={propertyFormRef} isEdit={isEdit} />
    </>
  );
};

export default AddTNAPlan;
