import { Card, Button, Affix, Form, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {  useEffect, useState } from "react";
import { GET_SINGLE_TNA_PROCESS_DATA } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { isMobileView } from "components/Common/AppHelper";
import Header from "components/Common/Header";
import ProcessForm from "../AddProcess/ProcessForm";

const UpdateProcess = (props) => {
  const { width, viewMode } = props;
  const [editInfo, setEditInfo] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { processId } = useParams();
  const [selectedOwner, setSelectedOwner] = useState([]);

  useEffect(() => {
    if (processId) {
      getProcessInfo(processId);
    }
  }, [processId]);

  const getProcessInfo = async(id) => {
    const query = `${GET_SINGLE_TNA_PROCESS_DATA}/${id}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      const processData = response?.data?.data;
      form.setFieldsValue({
        process_title: processData?.title,
        description: processData?.description,
        is_active: processData?.is_active,
      });
      setSelectedOwner(processData?.owners?.map((i)=>i?.id));
      setEditInfo(processData);
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || "Failed to get inquiry data",
      );
    }
  };

  const discartForm = () => {
    navigate("/tanda/process");
  };

  const addBuyerInquiry = () => {
    form.submit();
  };


  const ActionUI = () => {
    return (
      <Space>
        <Button size="small" key={1} danger onClick={() => discartForm()}>
          Discard
        </Button>

        <Button
          size="small"
          key={2}
          type="primary"
          onClick={() => addBuyerInquiry()}
          hidden={viewMode}
        >
          Update Process
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`${
              viewMode ? "View" : "Edit"
            } Process`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={<ActionUI />}
          />
        </Card>
      </Affix>

      {
        selectedOwner.length > 0 && <ProcessForm
        form={form}
        isEdit={true}
        viewMode={viewMode}
        editInfo={editInfo}
        selectedOwners= {selectedOwner}
      />
      }
      
    </>
  );
};

export default UpdateProcess;
