import { useState } from "react";
import { Input } from "antd";
import { GLOBAL_SEARCH } from "../../../apiServices/API_ENDPOINTS";
import { postData } from "../../../apiServices/common";
import HandleNotification from "../../Notification";
import SearchList from "../../../components/Dashboard/DashboardContent/AdvancedSearch/SearchList";

const GlobalSearch = () => {
  // States
  const [searchResponse, setSearchResponse] = useState(null);

  const onSearch = async (value) => {
    if (!value) {
      setSearchResponse(null);
      return;
    }

    const query = `${GLOBAL_SEARCH}`;
    const bodyData = {
      post_data: value,
    };
    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      const customData = [];
      response?.data && customData.push(response?.data);
      setSearchResponse(customData);
    } else {
      HandleNotification("error", "Error", "Something went wrong");
    }
  };

  return (
    <div>
      <Input
        placeholder="Global Search"
        onChange={(event) => onSearch(event.target.value)}
        style={{
          width: 300,
          height: 25,
        }}
        allowClear
      />
      <div
        style={{
          position: "fixed",
          zIndex: 100,
          height: "70vh",
          overflow: "auto",
        }}
      >
        <SearchList searchData={searchResponse} customCardWidth={300} />
      </div>
    </div>
  );
};

export default GlobalSearch;
