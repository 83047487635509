import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postData, getData } from "../../../../apiServices/common";
import ReconciliationForm from "./ReconciliationForm";
import {
  INVOICE_CHALLAN_MAPPING_RESOURCE
} from "../../../../apiServices/API_ENDPOINTS";

export default function EditReconciliation(props) {
  
  const { reconciliationId } = useParams();

  const { width } = props;
  const propertyFormRef = useRef(null);
  const [reconciliationInfo, setReconciliationInfo] = useState();
  const [form] = Form.useForm();
  
  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/local-purchase-reconciliation");
  };

  const updateReconciliation = () => {
    form.submit();
  };

  const getReconciliationInfo = async () => {
    const edit_url = `${INVOICE_CHALLAN_MAPPING_RESOURCE}/${reconciliationId}/edit`;
    
    const response = await getData(edit_url);

    if (response && response?.data?.code === 200) {
      setReconciliationInfo(response?.data?.data);
    }
  };

  useEffect(() => {
    getReconciliationInfo();
  }, []);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Update Local Purchase Reconciliation"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => updateReconciliation()}
                >
                Update
                </Button>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      
      <ReconciliationForm
        ref={propertyFormRef}
        reconciliationInfo={reconciliationInfo}
        form={form}
        isEdit={true}
        discartForm={discartForm}
      />
    </>
  );
}
