import {
    LOGIN_FAIL,
    LOGIN_SUCCESS, LOGOUT,
    USER_LOGIN
} from '../ActionTypes';
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
    token: null,
    user:  user
        ? { isLoggedIn: true, user }
        : { isLoggedIn: false, user: null },
    name: '',

};

const UserLoginReducer = (state = initialState, action) => {

    switch (action.type) {

        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user:  action.payload.user,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        default:
            return state;
    }

};
export default UserLoginReducer;