import { Row, Col, Form, Input, Button } from "antd";

const BookmarkForm = (props) => {
  // Props
  const { onCloseModal } = props;
  return (
    <>
      <Row gutter={6}>
        <Col span={10}>
          <Form.Item name="id" hidden />
          <Form.Item
            name="name"
            placeholder="Bookmark Name"
            label="Bookmark Name"
            rules={[
              {
                required: true,
                message: "Bookmark Name Required",
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item
            name="book_mark_link"
            placeholder="Bookmark URL"
            label="Bookmark URL"
            rules={[
              {
                required: true,
                message: "Bookmark URL Required",
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Button
            type="danger"
            style={{ width: "100%" }}
            onClick={() => {
              onCloseModal();
            }}
            size="small"
          >
            Discard
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            size="small"
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BookmarkForm;
