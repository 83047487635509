import { Checkbox } from 'antd';
import React, { Fragment } from 'react'
import { isArrayAndHasValue } from 'utils/functions';

const ListView=({datas})=> {
    const { listType, styleData, poData} = datas;
    console.log(listType, styleData, poData )
    const styleTableHead =  <thead>
                        <tr>
                        <th className="t-header bc-java ">Serial</th>
                        <th className="t-header bc-java ">Style No</th>
                        <th className="t-header bc-java ">Description</th>
                        <th className="t-header bc-java ">Order Quantity (P.C)</th>
                        <th className="t-header bc-java ">Unit Price $</th>
                        <th className="t-header bc-java ">Total Value $</th>
                        <th className="t-header bc-java ">PCD</th>
                        </tr>
                    </thead>;
    const poTableHead =  <thead>
                        <tr>
                        <th className="t-header bc-java ">Serial</th>
                        <th className="t-header bc-java ">Internal PO No.</th>
                        <th className="t-header bc-java ">Original Po No.</th>
                        <th className="t-header bc-java ">Shipment Date</th>
                        <th className="t-header bc-java ">Fully Shipped</th>
                        </tr>
                    </thead>;
    const styleTableBody = styleData?.map((item, index)=>{
        return <tr>
                    <td className="t-body">{index + 1}</td>
                    <td className="t-body">{item?.style_no}</td>
                    <td className="t-body">{item?.description}</td>
                    <td className="t-body">{item?.order_quantity}</td>
                    <td className="t-body">{item?.unit_price}</td>
                    <td className="t-body">{item?.total_value}</td>
                    <td className="t-body">{item?.pcd}</td>
               </tr>;
    });
    const poTableBody = poData?.map((item, index)=>{
        return <tr>
                    <td className="t-body">{index + 1}</td>
                    <td className="t-body">{item?.internal_po}</td>
                    <td className="t-body">{item?.original_po}</td>
                    <td className="t-body">{item?.delivery_date}</td>
                    <td className="t-body">{item?.po_status}</td>
               </tr>;
    });
  return (
    <div style={{ display: "flex", marginBottom: 30 }}>
        <div className="custome-table">
            <table style={{width:'100%'}}>
                { 
                    listType === 'style' ? styleTableHead : poTableHead
                }
                <tbody>
                    { 
                        listType === 'style' ? styleTableBody : poTableBody
                    }
                </tbody>
            </table>
        </div>
  </div>
  )
};

export default ListView;
