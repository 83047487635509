import {
    CREATE_NEW_MERCHANDISER,
    DELETE_MERCHANDISER,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GET_MERCHANDISER_LIST,
    SHOW_MESSAGE,
    UPDATE_MERCHANDISER_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_MERCHANDISER_ENDPOINT,
    DELETE_MERCHANDISER_ENDPOINT,
    EDIT_MERCHANDISER_ENDPOINT,
    GET_MERCHANDISER_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetMerchandiserList = (page, per_page, name) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_MERCHANDISER_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && { name: name }),
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_MERCHANDISER_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};



export const onCreateMerchandiser = (merchandiser) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_MERCHANDISER_ENDPOINT}`, merchandiser)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_MERCHANDISER, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Merchandiser Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Merchandiser Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onUpdateSelectedMerchandiser = (merchandiser) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_MERCHANDISER_ENDPOINT}/${merchandiser.id}`, merchandiser)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_MERCHANDISER_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "merchandiser Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Merchandiser updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onDeleteMerchandiser = (merchandiser) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_MERCHANDISER_ENDPOINT}/${merchandiser['id']}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_MERCHANDISER, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted merchandiser Successfully",
                    });

                    HandleNotification('success', 'bottomRight', 'Merchandiser deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
