import { Affix, Button, Card } from "antd";
import Header from "components/Common/Header";
import { DailyLinePlanContext } from "context/DailyLinePlanContext";
import { useContext } from "react";
import DailyLinePlanForm from "../DailyLinePlanForm";
import { useNavigate } from "react-router";

export default function DailyLinePlanEdit() {
  const { form } = useContext(DailyLinePlanContext);
  const navigate = useNavigate();
  return (
    <div>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title="Edit Daily Line Plan"
            subTitle=""
            addLink="/"
            addText="Submit"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => navigate("/digitalization/daily-line-plan")}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => form?.submit()}
                >
                  Update
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <DailyLinePlanForm />
    </div>
  );
}
