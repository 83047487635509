import { Col, Form, Row, Select, DatePicker } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  GET_BUYER_ENDPOINT,
  STYLE_MERCHANDISING_LIST,
  PO_MAP_NEW_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const PackingFilterFields = (props) => {
  // Props
  const { initialTableData } = props;

  // States
  const [buyerList, setBuyerList] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const [poList, setPOList] = useState([]);

  // Others
  const { Option } = Select;

  useEffect(() => {
    getBuyerList();
    getStyleList();
    getPOList();
  }, []);

  const saveToStorage = (value, name) => {
    const existingFilter =
      JSON.parse(localStorage.getItem("packing_filter")) || {};
    existingFilter[name] = value;
    localStorage.setItem("packing_filter", JSON.stringify(existingFilter));
  };

  const getBuyerList = async (filterValues) => {
    const query = `${GET_BUYER_ENDPOINT}`;
    const bodyData = {
      per_page: 10,
      name: filterValues?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBuyerList(res?.data?.data?.data || []);
    }
  };

  const getStyleList = async (style_no) => {
    const bodyData = {
      style_no: style_no,
    };

    let res = await getData(STYLE_MERCHANDISING_LIST, false, bodyData);

    if (res) {
      setStyleList(res?.data?.data?.data || []);
    }
  };

  const getPOList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(PO_MAP_NEW_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setPOList(response?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item label="Packing Date" name="packing_date">
          <CustomDatePicker
            style={{ width: "100%" }}
            onChange={(value) =>
              saveToStorage(moment(value).format("YYYY-MM-DD"), "start")
            }
            size="small"
          />
          {/* <DatePicker
            style={{ width: "100%" }}
            onChange={(value) =>
              saveToStorage(moment(value).format("YYYY-MM-DD"), "start")
            }
            size="small"
          /> */}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Original PO" name="po_map_id">
          <Select
            showSearch
            placeholder="Select an original PO"
            onSearch={(value) => {
              getPOList({ original_po: value });
            }}
            optionFilterProp="children"
            size="small"
            allowClear
            onClear={() => getPOList()}
          >
            {isArrayAndHasValue(poList) &&
              poList.map((po) => (
                <Option value={po.id} key={po.id}>
                  {po.original_po}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            placeholder="Select a Buyer"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getBuyerList({ name: value })}
            allowClear
            onClear={() => getBuyerList()}
          >
            {buyerList?.length &&
              buyerList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Style No." name="style_id">
          <Select
            showSearch
            placeholder="Select a Style No."
            optionFilterProp="children"
            onSearch={(value) => {
              getStyleList(value);
            }}
            size="small"
            allowClear
            onClear={() => getStyleList()}
          >
            {styleList &&
              styleList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.style_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default PackingFilterFields;
