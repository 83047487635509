import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {
    Divider,
    Input,
    Select,
    Space,
    Button,
    Form,
    Row,
    Col,
    Radio,
    Table,
    Dropdown,
    Menu,
    Popconfirm,
    Typography
} from 'antd';
import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {onCreateBrand, onGetBankAccountList, onGetBrandList, onGetBuyerDetails} from "../../../redux/actions";

let index = 0;
const {Option} = Select;
const CreateBrandForm = (props) => {
    const dispatch = useDispatch();
    const {selectedBuyer} = props;
    const [form] = Form.useForm();
    const brandList = useSelector(({brand}) => brand.brandList);
    const createdBrand = useSelector(({brand}) => brand.createdBrand);
    const [name, setName] = useState('');
    const [brandId, setBrandId] = useState(null);
    const inputRef = useRef(null);
    const [brand, setBrand] = useState([...brandList])
    const [refresh, setRefresh] = useState(0);
    const [buyerBrandRelationship, setBuyerBrandRelationship] = useState(null);
    const [createdBrandList, setCreatedBrandList] = useState([]);


    useEffect(() => {
        if(selectedBuyer) {
            const buyerInfo =  selectedBuyer?.brand_info?.map((brand, idx) => {
                return {
                    'brand_name': brand?.brand_info?.name,
                    'brand_id': brand?.brand_info?.id
                }
            });
            setBuyerBrandRelationship(buyerInfo);
            setCreatedBrandList(buyerInfo);
            form.setFieldValue('name', buyerInfo?.[0]?.brand_name)
        }
    }, [selectedBuyer, form]);


    const refetch = () => {
        setRefresh(refresh+1)
    }
    const onNameChange = (event, value) => {
        setName(event.target.value);
    };


    const onFinish = (values, label) => {
        if (brandId){
            values['country_name'] = 'BD'
            values['name'] = name
            values['status'] = '1'
            const createdBrand = {
                brand_name: name,
                brand_id: brandId
            }
            setCreatedBrandList((prev)=> [...prev, createdBrand])
            form.resetFields();
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleDelete = (record, index) => {
        const newData = createdBrandList.filter((item) => item.brand_id !== record.brand_id);
        setCreatedBrandList(newData);
    }
    const addItem = (e) => {
        e.preventDefault();
        const formValues = {};
        formValues['name']= name;
        formValues['status'] = '1';
        formValues['country_name'] = 'BD'
        dispatch(onCreateBrand(formValues));
        form.resetFields();
        refetch()
        setBrand([...brand,{

            "id": createdBrand?.id,
            "name": name,

            "created_at": null,
            "updated_at": null
        } || `New item ${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    useEffect(() => {
        form.resetFields();
    }, [refresh, createdBrand]);


    const onBrandChange = (value,options) => {

        const brandFormat = {}
        brandFormat['brand_name'] = options?.name
        brandFormat['brand_id'] = value
        setName(options.label)
        setBrandId(value)
    }

    localStorage.setItem('brandIds', JSON.stringify(createdBrandList?.map(brand => brand?.brand_id)));


    const tableColumns = [
        {title:"Brand Name", dataIndex:"brand_name", key:"brand_name", width: "30%" },

        {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record, index) => (
                <>
                    { createdBrandList && <Popconfirm title="Are you sure you want to delete?"
                                                   onConfirm={() => {
                                                       handleDelete(record, index)
                                                   }}>

                        <a style={{color: 'red'}} type='button'>

                                            <span>
                                           <MinusCircleOutlined />
                                       </span>
                        </a>
                    </Popconfirm>
                    }
                </>
            ),
        }
    ]
    return (
        <div style={{padding: 5}}>
            <Typography.Title level={5}>
                Add Brand
            </Typography.Title>

            <Form
                form={form}
                initialValues={{
                    name: buyerBrandRelationship?.length === 1 && buyerBrandRelationship?.[0]?.brand_name,
                }}
                layout="inline"
                name="form_in_modal"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{ marginBottom: 10, marginTop: 10 }}
            >

                        <Form.Item label="Brand Name" >
                                <Form.Item
                                    noStyle
                                    name="name"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: "Brand name is required",
                                        },
                                    ]}
                                >
                                    <Select
                                        required
                                        onChange={onBrandChange}
                                        style={{
                                            width: '200px',
                                        }}
                                        onSearch={(value) => dispatch(onGetBrandList(1,10, value))}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder="Brand Name"
                                        size="small"
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: "8px 0",
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: "0 8px 4px",
                                                    }}
                                                >
                                                    <Input
                                                        placeholder="brand name"
                                                        ref={inputRef}
                                                        // value={name}
                                                        onChange={onNameChange}
                                                        size="small"
                                                    />
                                                    <Button
                                                        htmlType="submit"
                                                        icon={<PlusOutlined />}
                                                        onClick={addItem}
                                                        size="small"
                                                    >
                                                        Add
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {brandList?.length > 0 &&
                                        brandList?.map((brand, idx) => (
                                            <Option
                                                key={idx}
                                                value={brand?.id }
                                                name={brand?.name}
                                                id={brand?.id}
                                                label={brand?.name}
                                            >{brand?.name}</Option>
                                        ))}

                                    </Select>
                                </Form.Item>
                        </Form.Item>

                <Form.Item>
                    <Button
                        htmlType="submit"
                        size="small"
                        type="primary"
                        style={{ float: "right" }}
                    >
                        Add
                    </Button>
                </Form.Item>



            </Form>

            <Table columns={tableColumns} dataSource={createdBrandList}  pagination={false}/>
        </div>
    );
};
export default CreateBrandForm;