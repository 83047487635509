import React from 'react';
import { Card } from "antd";
const MachineSummaryHTML = (props) => {
  const { machineSummary } = props;

  const generateTableRows = () => {
    let totalQty = 0; // Initialize total quantity
    const tableRows = machineSummary.map((record, index) => {
      totalQty += Number(record.sum_machine_type_sam); // Add quantity to total
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{record.machine_type_name.toUpperCase()}</td>
          <td>{Number(record.sum_machine_type_sam).toFixed(2)}</td>
        </tr>
      );
    });

    return { tableRows, totalQty }; // Return both table rows and total quantity
  };

  const { tableRows, totalQty } = generateTableRows();

  return (
    <div style={{paddingTop: 15}}>
      <table className="custome-table" border="1" style={{ width: "100%" }} >
        <thead>
          <tr>
            <th colSpan={1} className="bc-orange text-center">NO</th>
            <th colSpan={1} className="bc-orange text-center">MACHINE SUMMARY</th>
            <th colSpan={1} className="bc-orange text-center">QTY</th>
          </tr>
        </thead>
        <tbody className='table-container'>
          {tableRows}
          <tr>
            <td></td>
            <td colSpan={1} className="text-right"><strong>Subtotal</strong></td>
            <td><strong>{totalQty.toFixed(2)}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MachineSummaryHTML;
