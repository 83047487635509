import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Select, Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { isArrayAndHasValue } from "../../../../utils/functions";
import {
  GET_CATEGORY_ENDPOINT,
  GET_SIZES,
  SALES_CONTRACT_SIZE_ADD,
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import { debounce } from "lodash";
import { useParams } from "react-router";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ItemCatSequenceList = (props) => {
  // Props
  const { dataSource, setDataSource, loading } = props;

  // Antd
  const { Option } = Select;

  const onDragEnd = ({ active, over }) => {
    const dataSourceCopy = [...dataSource];
    const activeIndex = dataSourceCopy.findIndex((i) => i.key === active.id);
    const overIndex = dataSourceCopy.findIndex((i) => i.key === over?.id);

    // Ensure the indices are within bounds
    if (
      activeIndex < 0 ||
      activeIndex >= dataSourceCopy.length ||
      overIndex < 0 ||
      overIndex >= dataSourceCopy.length
    ) {
      return;
    }

    // Remove the item from the array
    const [draggedItem] = dataSourceCopy.splice(activeIndex, 1);

    // Insert the dragged item at the new position
    dataSourceCopy.splice(overIndex, 0, draggedItem);

    // Update the position key in every item
    dataSourceCopy.forEach((item, index) => {
      item.position = index + 1;
    });

    // Now, dataSource is updated with the new order
    setDataSource(dataSourceCopy);
  };

  const columns = [
    {
      key: "sort",
      width: "5%",
    },
    {
      title: "Item Category",
      dataIndex: "category_unique_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => {
        return value === "active" ? "Active" : "Inactive";
      },
    },
    {
      title: "Position",
      dataIndex: "position",
    },
  ];

  return (
    <Card>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            loading={loading}
          />
        </SortableContext>
      </DndContext>
    </Card>
  );
};

export default ItemCatSequenceList;
