import React, { useEffect, useState } from "react";
import { Button, Dropdown, Menu, Popconfirm, Space, Typography } from "antd";
import AppPageHeader from "../../common/PageHeader";
import AppContent from "../../common/AppContent";
import { useLocation, useNavigate } from "react-router";
import BuyerListTable from "./BuyerListTable";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  onDeleteSelectedBuyer,
  onGetBuyerList,
} from "../../redux/actions";
import { hasPermission } from "../../utils/functions";
import { 
  BUYER_CREATE_PERMISSION,
  BUYER_EDIT_PERMISSION,
  BUYER_DELETE_PERMISSION,
} from "../../routes/permissions";

const BuyerList = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [currentPage, setCurrentPage] = useState(1);

  // Redux
  const dispatch = useDispatch();
  const buyerList = useSelector(({ buyer }) => buyer.buyerList);
  const pageSize = useSelector(({ buyer }) => buyer.pageSize);
  const total = useSelector(({ buyer }) => buyer.totalBuyer);
  const loading = useSelector(({ common }) => common.loading);
  const filterData = useSelector(({filter}) => filter.filterData);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath]);

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = (record, event) => {
    dispatch(onDeleteSelectedBuyer(record));
    props.refetch();
  };

  const handleEdit = (event, record) => {
    navigate(`/configuration/buyer/edit/${record?.id}`);
  };

  const tableColumns = [
    { title: "Buyer Name", dataIndex: "name", key: "name" },
    { title: "Short Name", dataIndex: "short_name", key: "short_name" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, record, index) => (value == 1 ? "Active" : "Inactive"),
    },
    {
      title: "Action",
      width: '10%',
      key: "action",
      render: (_, record) => (
        <>
          {buyerList.length >= 1 ? (
            <Dropdown.Button
              overlay={
                <Menu
                  // title="action"
                  // mode="vertical"
                  items={[
                    {
                      label: (
                        <Popconfirm
                          title="Are you sure you want to delete?"
                          onConfirm={(event) => handleDelete(record)}
                        >
                          <span>
                            <DeleteTwoTone />
                          </span>
                          <a style={{ color: "red", marginLeft: 5 }}>Delete</a>
                        </Popconfirm>
                      ),
                      key: "1",
                      disabled: !hasPermission([BUYER_DELETE_PERMISSION])
                    },
                    {
                      label: (
                        <>
                          <span>
                            <EditTwoTone />
                          </span>
                          <a
                            type="button"
                            style={{ marginLeft: 5 }}
                            onClick={(event) => {
                              handleEdit(event, record);
                            }}
                          >
                            Edit
                          </a>
                        </>
                      ),
                      key: "2",
                      disabled: !hasPermission([BUYER_EDIT_PERMISSION])
                    },
                  ]}
                />
              }
            ></Dropdown.Button>
          ) : null}
        </>
      ),
    },
  ];

  const handleNav = (event, record) => {
    navigate("/configuration/buyer/create-buyer");
  };

  useEffect(() => {
    dispatch(onGetBuyerList({
        page: currentPage,
        pageSize: 10,
        ...filterData,
    }));
  }, [props.refresh, currentPage, pageSize, dispatch, filterData]);

  const navigate = useNavigate();
  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        type="primary"
        size="small"
        onClick={handleNav}
        disabled={!hasPermission([BUYER_CREATE_PERMISSION])}
      >
        {hasPermission([BUYER_CREATE_PERMISSION])
          ? "Add Buyer"
          : "No Permission"}
      </Button>
    </Space>
  );
  const header = <AppPageHeader extra={extra} title={<Title level={5}>Buyer List</Title>} />;

  const content = (
    <BuyerListTable
      loading={loading}
      onChange={(newPage) => {
        onPageChange(newPage);
      }}
      total={total}
      current={currentPage}
      defaultCurrent={currentPage}
      pageSize={pageSize}
      tableColumns={tableColumns}
      dataSource={buyerList}
      pagination={true}
      filter={true}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
    />
  );

  
  return (
    <>
      <AppContent
        header={header}
        content={content}
      />
    </>
  );
};

export default BuyerList;
