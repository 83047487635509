import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onCreateBranch,
  onGetBankDetails,
  onGetBankList,
} from "../../../redux/actions";

const { Option } = Select;
const BankAccountForm = (props) => {
  const dispatch = useDispatch();
  const [branchName, setBranchName] = useState("");
  const [address, setBranchAddress] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [refresh, setRefresh] = useState(0);
  const selectedBankDetails = useSelector(
    ({ bankAccount }) => bankAccount.selectedBankDetails?.brach_info,
  );

  const refetch = () => {
    setRefresh(refresh + 1);
  };
  const onBranchNameChange = (event, value) => {
    setBranchName(event.target.value);
  };

  const onBranchAddressChange = (event, value) => {
    setBranchAddress(event.target.value);
  };

  const onBranchRoutingNumChange = (event, value) => {
    setRoutingNumber(event.target.value);
  };
  useEffect(() => {
    dispatch(onGetBankDetails(props.selectedBank.id));
  }, [refresh, selectedBankDetails]);

  return (
    <>
      <Row gutter={4}>
        <Col span={8}>
          <Form.Item label="Bank Name">
            <Input.Group compact>
              <Form.Item
                name="bank_id"
                noStyle
                required
                rules={[
                  {
                    required: true,
                    message: "Bank name is required",
                  },
                ]}
              >
                <Select
                  required
                  onChange={props.onBankAccountChange}
                  onSearch={(value) => dispatch(onGetBankList(1, 10, value))}
                  showArrow
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Bank Name"
                  size="small"
                  allowClear
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Bank Name"
                          ref={props.inputRef}
                          onChange={props.onNameChange}
                          size="small"
                        />

                        <Input
                          placeholder="Swift Code"
                          ref={props.inputRef}
                          onChange={props.onSwiftCodeChange}
                          size="small"
                        />

                        <Button
                          htmlType="submit"
                          icon={<PlusOutlined />}
                          onClick={props.addItem}
                          size="small"
                        >
                          Add
                        </Button>
                      </Space>
                    </>
                  )}
                >
                  {props.bankList.length > 0 &&
                    props.bankList.map((bank, idx) => (
                      <Option
                        value={bank?.id}
                        key={idx}
                        label={bank?.name}
                        name={bank?.name}
                        id={bank?.id}
                        branch_info={bank?.branch_info}
                        swift_code={bank?.swift_code}
                        account_info={bank?.account_info}
                      >
                        {bank?.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Branch Name & Address" name="branch_id">
            <Input.Group compact>
              <Form.Item
                name="branch_id"
                noStyle
                required
                rules={[
                  {
                    required: true,
                    message: "Branch name is required",
                  },
                ]}
              >
                <Select
                  name="branch_id"
                  required
                  onChange={props.onBranchChange}
                  showArrow
                  style={{
                    width: "100%",
                  }}
                  placeholder="Branch Name and Address"
                  size="small"
                  allowClear
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Name"
                          ref={props.inputRef}
                          value={branchName}
                          onChange={onBranchNameChange}
                          size="small"
                        />
                        <Input
                          placeholder="Routing Number"
                          ref={props.inputRef}
                          value={routingNumber}
                          onChange={onBranchRoutingNumChange}
                          size="small"
                        />
                        <Input
                          placeholder="Address"
                          ref={props.inputRef}
                          value={address}
                          onChange={onBranchAddressChange}
                          size="small"
                        />
                        <Button
                          htmlType="submit"
                          icon={<PlusOutlined />}
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            const formValues = {};
                            formValues["name"] = branchName;
                            formValues["routing_number"] = routingNumber;
                            formValues["address"] = address;
                            formValues["bank_id"] = props.selectedBank.id;
                            formValues["status"] = "1";
                            dispatch(onCreateBranch(formValues));
                            setRoutingNumber("");
                            setBranchName("");
                            setBranchAddress("");
                            refetch();
                          }}
                        >
                          Add
                        </Button>
                      </Space>
                    </>
                  )}
                >
                  {props.branchOfSelectedBank?.map((branch, idx) => (
                    <>
                      <Option
                        value={branch.id}
                        key={idx}
                        bank_id={branch?.bank_id}
                        id={branch?.id}
                        address={branch?.address}
                        label={branch.name}
                      >
                        {branch.name}
                      </Option>
                    </>
                  ))}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="Account Number" name="account_number">
            <Input placeholder="Account Number" size="small" />
          </Form.Item>
        </Col>
        <Col span={1}>
          <Form.Item label=" ">
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              style={{ float: "right" }}
            >
              Add
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BankAccountForm;
