import { Input, InputNumber } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { isArrayAndHasValue } from "../../../utils/functions";
import { MenuOutlined, MinusCircleOutlined } from "@ant-design/icons";
import "./index.css";
import ManPowerTableData from './manPowerTableData';

const SortableTableBody = SortableContainer(({ children }) => {
  return <tbody className="scrollable-table-container">{children}</tbody>;
});

const RowHandler = SortableHandle(() => (
  <MenuOutlined
    className="cursore-pointer"
    style={{ fontSize: "14px" }}
  />
));

const TableData = (props) => {
  const {
    view,
    edit,
    manpowerMode,
    sectionId,
    sectionName,
    sectionItems,
    removeRow,
    onChangeValue,
    onChangeSAMValue,
    onSortTableBody,
  } = props;

  const SortableOperationItem = SortableElement(({ sectionItem, index }) => {
    const machine_sam = sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "helper" 
                        && sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "iron" 
                        && sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "hp" 
                        ? sectionItem?.sam 
                        : null;
    const helper_sam = sectionItem?.ob_machine_type_info?.name.toLowerCase() === "helper" 
                        || sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp" 
                        ? sectionItem?.sam 
                        : null;
    const iron_sam = sectionItem?.ob_machine_type_info?.name.toLowerCase() === "iron" 
                      || sectionItem?.ob_machine_type_info?.name.toLowerCase() === "ir"
                      ? sectionItem?.sam 
                      : null;

    return (
      <tr key={`table_parent_key_${sectionItem?.id || index}`}>
        {/* SL */}
        <td className="t-body sticky">{sectionItem?.id}</td>
        {/* Operation / Section Name */}
        <td className="t-body sticky">{sectionItem?.name}</td>
        {/* Condition */}
        <td className="t-body sticky">{sectionItem?.condition}</td>
        {/* SMV - M/C */}
        <td className="t-body sticky">
          {sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "helper" &&
          sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "iron" &&
          sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "hp"
            ? sectionItem?.sam
            : ""}
        </td>
        {/* SMV - HP */}
        <td className="t-body sticky">
          {sectionItem?.ob_machine_type_info?.name.toLowerCase() === "helper" ||
          sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
            ? sectionItem?.sam
            : ""}
        </td>
        {/* SMV - IR */}
        <td className="t-body sticky">
          {sectionItem?.ob_machine_type_info?.name.toLowerCase() === "iron"
            ? sectionItem?.sam
            : ""}
        </td>

        {/* For updated smv section */}
        {/* Manul smv - M/C */}
        <>
        <td className="t-body sticky">
          <Input
            size="small"
            value={sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "helper" &&
              sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "iron" &&
              sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "hp"
                ? (sectionItem?.updated_sam ?? sectionItem?.sam)
                : ""}
            disabled={view || manpowerMode || !machine_sam}
            onChange={(event) => {
              onChangeSAMValue(
                event.target.value,
                sectionItem?.id,
                "updated_sam",
              );
            }}
            style={{ width: "75px" }}
          />
        </td>
        {/* Manul smv - HP */}
        <td className="t-body sticky">
          <Input
            size="small"
            value={sectionItem?.ob_machine_type_info?.name.toLowerCase() === "helper" ||
              sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
                ? (sectionItem?.updated_sam ?? sectionItem?.sam)
                : ""}
            disabled={view || manpowerMode || !helper_sam}
            onChange={(event) => {
              onChangeSAMValue(
                event.target.value,
                sectionItem?.id,
                "updated_sam",
              );
            }}
            style={{ width: "75px" }}
          />
        </td>
        {/* Manul smv - IR */}
        <td className="t-body sticky">
          <Input
            size="small"
            value={sectionItem?.ob_machine_type_info?.name.toLowerCase() === "iron"
              ? (sectionItem?.updated_sam ?? sectionItem?.sam)
              : ""}
            disabled={view || manpowerMode || !iron_sam}
            onChange={(event) => {
              onChangeSAMValue(
                event.target.value,
                sectionItem?.id,
                "updated_sam",
              );
            }}
            style={{ width: "75px" }}
          />
        </td>
        </>
        {/* For updated smv section */}

        {/* M/C - Type */}
        <td className="t-body sticky">
          {sectionItem?.ob_machine_type_info?.name}
        </td>
        {/* TGT - M/C */}
        <td className="t-body sticky">
          {sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "helper" &&
          sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "ir" &&
          sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "hp" &&
          sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "iron"
            ? Number((60 / (sectionItem?.updated_sam || sectionItem?.sam)).toFixed(2))
            : ""}
        </td>
        {/* TGT - HP */}
        <td className="t-body sticky">
          {sectionItem?.ob_machine_type_info?.name.toLowerCase() === "helper" ||
          sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
            ? Number((60 / (sectionItem?.updated_sam || sectionItem?.sam)).toFixed(2))
            : ""}
        </td>
        {/* TGT - IR */}
        <td className="t-body sticky">
          {sectionItem?.ob_machine_type_info?.name.toLowerCase() === "iron" ||
          sectionItem?.ob_machine_type_info?.name.toLowerCase() === "ir"
            ? Number((60 / (sectionItem?.updated_sam || sectionItem?.sam)).toFixed(2))
            : ""}
        </td>
        {(view || manpowerMode) && (
          <>
            {/* WORKER - M/C */}
            <td className="t-body sticky">
              {sectionItem?.worker_machine_sam
                ? Number(sectionItem?.worker_machine_sam).toFixed(2)
                : ""}
            </td>
            {/* WORKER - HP */}
            <td className="t-body sticky">
              {sectionItem?.worker_helper_sam
                ? Number(sectionItem?.worker_helper_sam).toFixed(2)
                : ""}
            </td>
            {/* WORKER - IR */}
            <td className="t-body sticky">
              {sectionItem?.worker_iron_sam
                ? Number(sectionItem?.worker_iron_sam).toFixed(2)
                : ""}
            </td>
          </>
        )}
        {/* input manpower */}
        {manpowerMode && (
          <>
            {/* WORKER - M/C */}
            <td className="t-body sticky">
              <Input
                size="small"
                value={sectionItem?.manual_worker_machine_sam}
                disabled={!sectionItem?.worker_machine_sam ? true : false}
                onChange={(event) => {
                  onChangeValue(
                    event.target.value,
                    sectionItem?.id,
                    "manual_worker_machine_sam",
                  );
                }}
                style={{ width: "75px" }}
              />
            </td>
            {/* WORKER - HP */}
            <td className="t-body sticky">
              <Input
                size="small"
                value={sectionItem?.manual_worker_helper_sam}
                disabled={!sectionItem?.worker_helper_sam ? true : false}
                onChange={(event) => {
                  onChangeValue(
                    event.target.value,
                    sectionItem?.id,
                    "manual_worker_helper_sam",
                  );
                }}
                style={{ width: "75px" }}
              />
            </td>
            {/* WORKER - IR */}
            <td className="t-body sticky">
              <Input
                size="small"
                value={sectionItem?.manual_worker_iron_sam}
                disabled={!sectionItem?.worker_iron_sam ? true : false}
                onChange={(event) => {
                  onChangeValue(
                    event.target.value,
                    sectionItem?.id,
                    "manual_worker_iron_sam",
                  );
                }}
                style={{ width: "75px" }}
              />
            </td>
          </>
        )}
        {/* Color Input */}
        <td className="t-body sticky">
          <Input
            size="small"
            value={sectionItem?.color || ""}
            // disabled={!sectionItem?.worker_iron_sam ? true : false}
            onChange={(event) => {
              onChangeValue(
                event.target.value,
                sectionItem?.id,
                "color",
              );
            }}
            style={{ width: "75px" }}
            placeholder='Color'
          />
        </td>
        {/* Actions */}
        {!view && !manpowerMode && (
          <td>
            <div className="action-container">
              <div
                onClick={() => {
                  removeRow(sectionItem?.id);
                }}
              >
                <MinusCircleOutlined
                  className="cursore-pointer"
                  style={{ fontSize: "14px", color: "red" }}
                />
              </div>
              <RowHandler />
            </div>
          </td>
        )}
      </tr>
    );
  });

  return (
    <SortableTableBody
      onSortEnd={onSortTableBody}
      axis="y"
      lockAxis="y"
      lockToContainerEdges={true}
      lockOffset={["30%", "50%"]}
      helperClass="helperContainerClass"
      useDragHandle={true}
    >
      <tr key={`table_header_key_${sectionId}`} style={{position:"sticky", top:72, zIndex:1}}>
        <th key="header_serial_number" className="bc-java text-center">
          NO.
        </th>
        <th key="header_section_name" className="bc-java text-center">
          {sectionName}
        </th>
        <th key="header_condition" className="bc-java text-center">
          CONDITION
        </th>
        <th key="header_smv_machine" className="bc-java text-center">
          M/C
        </th>
        <th key="header_smv_helper" className="bc-java text-center">
          HP
        </th>
        <th key="header_smv_iron_man" className="bc-java text-center">
          IR
        </th>

        {/* For Updated SMV */}
        <th key="header_machine_sam" className="bc-java text-center">
          M/C
        </th>
        <th key="header_helper_sam" className="bc-java text-center">
          HP
        </th>
        <th key="header_iron_man_sam" className="bc-java text-center">
          IR
        </th>
        {/* For Updated SMV */}


        <th key="header_machine_type" className="bc-java text-center">
          TYPE
        </th>
        <th key="header_tgt_machine" className="bc-java text-center">
          M/C
        </th>
        <th key="header_tgt_helper" className="bc-java text-center">
          HP
        </th>
        <th key="header_tgt_iron_man" className="bc-java text-center">
          IR
        </th>
        {(view || manpowerMode) && (
          <>
            <th key="header_worker_machine" className="bc-java text-center">
              M/C
            </th>
            <th key="header_worker_helper" className="bc-java text-center">
              HP
            </th>
            <th key="header_worker_iron_man" className="bc-java text-center">
              IR
            </th>
          </>
        )}
        {manpowerMode && (
          <>
            {/* manual input */}
            <th key="header_worker_machine" className="bc-java text-center">
              M/C
            </th>
            <th key="header_worker_helper" className="bc-java text-center">
              HP
            </th>
            <th key="header_worker_iron_man" className="bc-java text-center">
              IR
            </th>
          </>
        )}
        <th key="header_color" className="bc-java text-center">
          Color
        </th>
        {!view && !manpowerMode && (
          <>
            <th key="header_delete_row" className="bc-java text-center">
              <RowHandler />
            </th>
          </>
        )}
      </tr>
      {/* Table Data render when in manpower mode to avoid focus conflict */}
      {manpowerMode && (
        <ManPowerTableData
          sectionItems={sectionItems}
          onChangeValue={onChangeValue}
          onChangeSAMValue={onChangeSAMValue}
          view={view}
          edit={edit}
          manpowerMode={manpowerMode}
          removeRow={removeRow}
        />
      )}
      {/* Table Data render when not in manpower mode to activate drag drop features */}
      {!manpowerMode &&
        isArrayAndHasValue(sectionItems) &&
        sectionItems?.map((sectionItem, index) => {
          return (
            <SortableOperationItem
              sectionItem={sectionItem}
              key={`item-${index}`}
              index={index}
              collection={sectionId}
            />
          );
        })}
    </SortableTableBody>
  );
};

export default TableData;
