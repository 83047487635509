import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
} from "antd";
import { debounce } from 'lodash'; // Import lodash for debouncing
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    OT_SIGNAL_LIST_ENDPOINT,
    OT_SIGNAL_ADD_ENDPOINT,
    OT_SIGNAL_EDIT_ENDPOINT,
    OT_SIGNAL_DELETE_ENDPOINT,
    OT_SIGNAL_SECTION_LIST_ENDPOINT,
    GET_SEWING_LINE_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/functions";
import { 
    OT_SIGNAL_CREATE_PERMISSION,
    OT_SIGNAL_EDIT_PERMISSION,
    OT_SIGNAL_DELETE_PERMISSION,
} from '../../routes/permissions';
import { useLocation } from "react-router";

const OTSignal = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ sectionList, setSectionList ] = useState([]);
  const [ lineList, setLineList ] = useState([]);
  const [hideLineField, setHideLineField] = useState(true); // Default to hide

  // Antd
  const [ot_signalForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // Debounce search for line and section search
  const debounceSearchLines = useCallback(debounce((value) => {
    getAllLines({ name: value });
  }, 300), []);

  const debounceSearchSections = useCallback(debounce((value) => {
    getAllSections({ name: value });
  }, 300), []);

  // Fetch OT signals
  const getAllOtSignal = useCallback(
    async (filterValues) => {
      const payload = {
        page: filterValues?.page || currentPage,
        ...filterValues,
      };

      setLoading(true);

      const query = `${OT_SIGNAL_LIST_ENDPOINT}`;
      const response = await getData(query, false, payload);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting OT signal data");
      }
    },
    [currentPage],
  );

  // Fetch Sections
  const getAllSections = async (filterValues) => {
    setLoading(true);
    const query = `${OT_SIGNAL_SECTION_LIST_ENDPOINT}`;
    const response = await getData(query, false, filterValues);
    if (response?.status === 200) {
      setLoading(false);
      const filteredSectionListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      setSectionList(filteredSectionListData);
    } else {
      setLoading(false);
      message.error("Error getting sections data");
    }
  };

  // Fetch Lines
  const getAllLines = async (filterValues) => {
    setLoading(true);
    const query = `${GET_SEWING_LINE_ENDPOINT}`;
    const response = await getData(query, false, filterValues);
    if (response?.status === 200) {
      setLoading(false);
      setLineList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting lines data");
    }
  };

  useEffect(() => {
    getAllOtSignal();
    getAllSections();
    getAllLines();
  }, [refresh, getAllOtSignal]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    ot_signalForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
    setHideLineField(true); // Reset the hideLineField to true by default
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedot_signal = async (id) => {
    setLoading(true);
    const response = await deleteData(`${OT_SIGNAL_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("OT Signal Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedot_signal = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      signal_section_id: formValues?.signal_section_id,
      line_ids: formValues?.line_ids,
    };

    const response = await putData(
      `${OT_SIGNAL_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("OT Signal Edited Successfully");
      refetch();
      ot_signalForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewot_signal = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      signal_section_id: formValues?.signal_section_id,
      line_ids: formValues?.line_ids,
    };

    const response = await postData(OT_SIGNAL_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("OT Signal Created Successfully");
      refetch();
      ot_signalForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);
    const shouldShowLineField = record?.signal_section_id !== 1;

    ot_signalForm.setFieldsValue({
      id: record?.id,
      name: record?.name,
      signal_section_id: record?.signal_section_id,
      line_ids: record?.line_info ? record.line_info.map(line => line.line_id) : [],
    });

    setHideLineField(!shouldShowLineField); // Update hideLineField state based on section_id
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "OT Signal Name",
      dataIndex: ["name"],
      key: "ot_signal_name",
    },
    {
      title: "OT Signal Section Name",
      dataIndex: ["ot_singal_section_info", "name"],
      key: "signal_section_id",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedot_signal(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([OT_SIGNAL_DELETE_PERMISSION]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([OT_SIGNAL_EDIT_PERMISSION]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  // Handle form field changes
  const handleFormChange = (changedValues) => {
    if (changedValues.signal_section_id !== undefined) {
      // Hide Line ID field if section ID is not 1
      setHideLineField(changedValues.signal_section_id !== 1);
    }
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([OT_SIGNAL_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([OT_SIGNAL_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>OT Signal</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllOtSignal}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="ot_signal"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit OT Signal" : "Create OT Signal"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={ot_signalForm}
          layout="vertical"
          onValuesChange={handleFormChange}
          onFinish={isEdit ? editSelectedot_signal : createNewot_signal}
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="name"
                placeholder="OT Signal Name"
                label="OT Signal Name"
                rules={[
                  {
                    required: true,
                    message: "OT Signal Name Required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="signal_section_id"
                placeholder="Section ID"
                label="Section ID"
                rules={[
                  {
                    required: true,
                    message: "Section ID Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Section"
                  showSearch
                  style={{width: "100%"}}
                  onSearch={debounceSearchSections} // Use debounced search for sections
                >
                  {sectionList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {!hideLineField && (
            <Col span={24}>
              <Form.Item
                name="line_ids"
                placeholder="Line ID"
                label="Line ID"
                rules={[
                  {
                    required: true,
                    message: "Line ID Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Line"
                  showSearch
                  style={{width: "100%"}}
                  onSearch={debounceSearchLines} // Use debounced search for lines
                  mode="multiple"
                  filterOption={false} // Disable default filtering
                  notFoundContent={loading ? <span>Loading...</span> : null} // Loading indicator
                >
                  {lineList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            )}
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default OTSignal;
