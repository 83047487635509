import { Col, DatePicker, Form, Select } from "antd";
import { useEffect, useState } from "react";
import { GET_BUYER_ENDPOINT, GET_BUYING_OFFICE_ENDPOINT, SALES_CONTRACT_LIST, STYLE_MERCHANDISING_LIST } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const OSRFilterFields = ({ initialTableData }) => {
  // States
  const [salesContractList, setSalesContractList] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const [buyingOfficeList, setBuyingOfficeList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };

  // Antd
  const { Option } = Select;

  useEffect(() => {
    getSalesContractList();
    getBuyerList();
    getBuyingOfficeList();
    getStyleList();
  }, []);

  const getSalesContractList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      reference_no: filterValues?.reference_no || "",
    };
    const response = await getData(SALES_CONTRACT_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSalesContractList(response?.data?.data?.data || []);
    }
  };
  const getStyleList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      style_no: filterValues?.style_no || "",
    };
    const response = await getData(STYLE_MERCHANDISING_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data?.data || []);
    }
  };
  const getBuyerList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      name: filterValues?.name || "",
    };
    const response = await getData(GET_BUYER_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyerList(response?.data?.data?.data || []);
    }
  };
  const getBuyingOfficeList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      name: filterValues?.name || "",
    };
    const response = await getData(GET_BUYING_OFFICE_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOfficeList(response?.data?.data?.data || []);
    }
  };

  const [selectedDateType, setSelectedDateType] = useState('PCD'); // Default value set to 'PCD'

  const handleDateTypeChange = (value) => {
    setSelectedDateType(value);
  };

  console.log("selectedDateType", selectedDateType);

  return (
    <>
    <Col span={4}>
      <Form.Item
        label="Date Type"
        name="date_type"
        initialValue={selectedDateType}
      >
        <Select
          showSearch
          placeholder="Select a Date Type"
          optionFilterProp="children"
          size="small"
          value={selectedDateType}
          onChange={handleDateTypeChange}
        >
          <Option value= "PCD"></Option>
          <Option value= "Shipment"></Option>
        </Select>
      </Form.Item>
    </Col>
    {selectedDateType === 'PCD' && (
        <>
        <Col span={4}>
          <Form.Item
            name="pcd_start"
            placeholder="PCD Start"
            label="PCD Start"
            rules={[
              {
                required: false,
                message: "PCD Start is Required",
              },
            ]}
          >
            <CustomDatePicker style={{width: "100%"}} onChange={onDateChange} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="pcd_end"
            placeholder="PCD End"
            label="PCD End"
            rules={[
              {
                required: false,
                message: "PCD End is Required",
              },
            ]}
          >
            <CustomDatePicker style={{width: "100%"}} onChange={onDateChange} size="small" />
          </Form.Item>
        </Col>
        </>
    )}
    {selectedDateType === 'Shipment' && (
        <>
        <Col span={4}>
        <Form.Item
          name="shipment_start"
          placeholder="Shipment Start"
          label="Shipment Start"
          rules={[
            {
              required: false,
              message: "Shipment Start is Required",
            },
          ]}
        >
          <CustomDatePicker style={{width: "100%"}} onChange={onDateChange} size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          name="shipment_end"
          placeholder="Shipment End"
          label="Shipment End"
          rules={[
            {
              required: false,
              message: "Shipment End is Required",
            },
          ]}
        >
          <CustomDatePicker style={{width: "100%"}} onChange={onDateChange} size="small" />
        </Form.Item>
      </Col>
        </>
    )}
    <Col span={4}>
      <Form.Item
        label="Buying Office"
        name="buying_office_id"
      >
        <Select
          showSearch
          placeholder="Select a Buying Office"
          optionFilterProp="children"
          size="small"
          onSearch={(value) => getBuyingOfficeList({ name: value })}
        >
          {isArrayAndHasValue(buyingOfficeList) &&
            buyingOfficeList.map((sup) => (
              <Option value={sup.id} key={sup.id}>
                {sup.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </Col>
    <Col span={4}>
      <Form.Item
        label="Buyer"
        name="buyer_id"
      >
        <Select
          showSearch
          placeholder="Select a Buyer"
          optionFilterProp="children"
          size="small"
          onSearch={(value) => getBuyerList({ name: value })}
        >
          {isArrayAndHasValue(buyerList) &&
            buyerList.map((sup) => (
              <Option value={sup.id} key={sup.id}>
                {sup.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </Col>
    <Col span={4}>
      <Form.Item
        label="Sales Contract Type"
        name="sc_type"
      >
        <Select
          showSearch
          placeholder="Select a Sales Contract Type"
          optionFilterProp="children"
          size="small"
        >
          <Option value= "Sub-Contract"></Option>
          <Option value= "Sales Contract"></Option>
        </Select>
      </Form.Item>
    </Col>
    </>
  );
};

export default OSRFilterFields;
