import { useState, useEffect, useCallback } from "react";
import {
  Card,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Table,
  Typography,
  Divider,
  Space,
  Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  SALES_CONTRACT_LIST,
  STYLE_WISE_COST_ADD,
  STYLE_WISE_COST_VIEW,
  STYLE_WISE_COST_EDIT,
  REST_SALES_CONTRACT,
} from "../../../../apiServices/API_ENDPOINTS";

import {
  NOTIFICATION_SLUGS
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData, putData } from "../../../../apiServices/common";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../utils/functions";
import HandleNotification from "../../../../common/Notification";


const SlugForm = (props) => {
  // Props
  const { form, editMode, viewMode } = props;
  const [code, setCode] = useState("");

  // Router
  const navigate = useNavigate();
  const { id } = useParams();


  const getSelectedSlugInfo = useCallback(
    async (id) => {
      const query = `${NOTIFICATION_SLUGS}/${id}/edit`;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {

        form.setFieldsValue({
          name: response?.data?.data?.name,
          code: response?.data?.data?.code,
        });
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error in fetching slug Info",
          null,
        );
      }
    },
    [form],
  );

  const handleNameChange = (e) => {
    const name = e.target.value;
    // Replace spaces and special characters with dashes
    const codeValue = name.replace(/[^a-zA-Z0-9]/g, "-");
    setCode(codeValue);
    form.setFieldsValue({ code: codeValue });
  };

  useEffect(() => {
    // get selected cost info on edit mode
    if (id) {
      getSelectedSlugInfo(id);
    }
  }, [editMode, id, getSelectedSlugInfo]);

  const onFinishNotificationSlug = async (values) => {
    
    const query = editMode
    ? `${NOTIFICATION_SLUGS}/${id}`
    : `${NOTIFICATION_SLUGS}`;
    const bodyData = {
      name: values?.name,
      code: values?.code,
    };
    
    const response = editMode ? await putData(query, bodyData) : await postData(query, bodyData);
    
    console.log("response", response);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        `Notification Slug ${editMode ? "updated" : "added"} successfully!`,
        null,
      );
      form.resetFields();
      navigate("/settings/notification-slugs");
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        `Error ${editMode ? "updating" : "adding"} notification slug!`,
        null,
      );
    }
  };

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishNotificationSlug}
      >
        <Card>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item 
                label="Name" 
                name="name" 
                rules={[
                  {
                    required: true,
                    message: "Slug name is required",
                  },
                ]}
              >
                <Input placeholder="Name" size="small" onChange={handleNameChange} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item 
                label="Code" 
                name="code"
                rules={[
                  {
                    required: true,
                    message: "code is required",
                  },
                ]}
              >
                <Input 
                  placeholder="Code" 
                  disabled size="small" 
                />
              </Form.Item>
            </Col>
          </Row>

        </Card>
      </Form>
    </div>
  );
};

export default SlugForm;
