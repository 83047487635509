import React, { useEffect, useState } from "react";
import { ExportOutlined } from "@ant-design/icons";
import { Table, Spin, message, Card, Button, Breadcrumb, Affix, Typography } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import { EXPORT_BOM_ENDPOINT } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common"; // Assuming you have a function to fetch the data

const PreviewBOM = (props) => {
  const { width } = props;
  const { styleId } = useParams();
  // Antd
  const { Title } = Typography;
  const [bomData, setBomData] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/merchandising/BOM");
  };

  useEffect(() => {
    const fetchBOMData = async () => {
      setLoading(true);
      // Call the API to get BOM details based on styleId
      const response = await getData(`/api/bill_of_material/previewBOM/${styleId}`);  // Replace with actual API endpoint

      if (response?.status === 200) {
        setBomData(response?.data?.data); // Set the data when the response is successful
      } else {
        // Handle error if needed
        console.error("Failed to fetch BOM data");
      }
      setLoading(false);
    };

    fetchBOMData();
  }, [styleId]);

  const onExportBOM = async (bom_id) => {
    const bodyData = {
      id: bom_id,
    };

    let response = await getData(EXPORT_BOM_ENDPOINT, false, bodyData);

    if (response && response?.status === 200) {
      const outputFilename = `bom.csv`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } else {
      message.error("Error exporting BOM");
    }
  };

  // Define columns for the table
  const columns = [
    {
      title: "Item Type",
      dataIndex: "item_type",
      key: "item_type",
      width: 150,
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      width: 300,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 80,
    },
    {
      title: "YY",
      dataIndex: "per_product_required_quantity",
      key: "per_product_required_quantity",
      width: 80,
    },
    {
      title: "Order Qty",
      dataIndex: "order_quantity",
      key: "order_quantity",
      width: 100,
    },
    {
      title: "Required Qty",
      dataIndex: "required_quantity",
      key: "required_quantity",
      width: 100,
    },
    {
      title: "Received",
      dataIndex: "total_received",
      key: "total_received",
      width: 80,
    },
    {
      title: "Recv Roll",
      dataIndex: "total_received_roll",
      key: "total_received_roll",
      width: 85,
    },
    {
      title: "Short Qty",
      dataIndex: "short_qty",
      key: "short_qty",
      width: 80,
    },
    {
      title: "Rcv Date",
      dataIndex: "receive_date",
      key: "receive_date",
      width: 80,
    },
    {
      title: "Invoice/Challan",
      dataIndex: "invoice_challan",
      key: "invoice_challan",
      width: 100,
    },
    {
      title: "Consignments",
      dataIndex: "consignment",
      key: "consignment",
      width: 80,
    },
    {
      title: "Issued",
      dataIndex: "total_issued",
      key: "total_issued",
      width: 80,
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      key: "issue_date",
      width: 80,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: 80,
    },
    {
      title: "Shipment Qty",
      dataIndex: "shipment_qty",
      key: "shipment_qty",
      width: 100,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 80,
    },
    {
      title: "Supplier",
      dataIndex: "suppliers",
      key: "suppliers",
      width: 80,
    }
  ];

  // Check if data is available and render table
  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>Pre-View Export Bill of Materials</Title>}
            subTitle=""
            addLink="/"
            actions={[
              <Button size="small" key={1} type="primary" onClick={() => onExportBOM(styleId)}>
                Export BOM
              </Button>,
              <Button size="small" key={2} danger onClick={() => discartForm()}>
                Back
              </Button>,
            ]}
          ></Header>

          {loading ? (
            <Spin size="large" /> // Display loading spinner while fetching data
          ) : bomData ? (
            <Table
              loading={loading}
              dataSource={bomData.details} // Pass the "details" array as dataSource
              columns={columns}
              rowKey="item" // Set a unique key for each row
              pagination={false} // Disable pagination if needed
              bordered // Add borders around the table and cells
              size="small" // Set smaller row size
              style={{ border: "1px solid #ddd" }} // Optional: Add table border styling
              scroll={{
                y: 800, // Set the vertical scroll height
                x: "max-content", // Enable horizontal scrolling for wide tables
              }}
              sticky
            />
          ) : (
            <p>No BOM data available</p>
          )}
        </Card>
      </Affix>
    </>
  );
};

export default PreviewBOM;