import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GET_BOM_DETAIL,
    STORE_NEW_CUTTING,
    GET_CUTTING_DETAIL,
    SHOW_MESSAGE
} from "../ActionTypes";
import JwtAxios from "../../apiServices";
import {GET_CUTTING_ENDPOINT, STORE_CUTTING_ENDPOINT} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onCuttingStore = (cutting) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${STORE_CUTTING_ENDPOINT}`, cutting)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: STORE_NEW_CUTTING, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Cutting Information Stored Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Cutting Information Stored Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};

export const onGetCuttingDetails = (payload) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios.post(`${GET_CUTTING_ENDPOINT}`, payload)
            .then((data) => {

                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_CUTTING_DETAIL, payload: data.data});
                    dispatch({

                        type: SHOW_MESSAGE,
                    });
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};

export const onResetCuttingDetails = () => {
    return (dispatch) => {
        dispatch({type: GET_CUTTING_DETAIL, payload: null});
    };
};