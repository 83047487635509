import {
  Col,
  Input,
  Form,
  Row,
  Select,
  Button,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { onGetUserPermissionList } from "redux/actions";

const UserRoleForm = (props) => {
  // Props
  const { addAllPermissions, removeAllPermissions } = props;

  // Redux
  const dispatch = useDispatch();
  const userPermissionList = useSelector(
    ({ userRole }) => userRole.userPermissionList,
  );

  //   Antd
  const { Option } = Select;

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Role Name"
            name="name"
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input placeholder="Role Name" size="small" />
          </Form.Item>
          <div className="centered-item-parent">
            <Space>
              <Button primary size="small" onClick={addAllPermissions}>
                Add All
              </Button>
              <Button danger size="small" onClick={removeAllPermissions}>
                Remove All
              </Button>
            </Space>
          </div>
        </Col>
        <Col span={20}>
          <Form.Item label="Permissions" name="permissions">
            <Select
              onSearch={(value) =>
                dispatch(onGetUserPermissionList(1, 10, value))
              }
              mode="tags"
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              listHeight={"40vh"}
              className="custom-dropdown-multiple-select"
            >
              {userPermissionList?.map((role, idx) => (
                <Option key={idx} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default UserRoleForm;
