
import { Col, Form, Input, Row, Select } from "antd";
import { GET_ALL_TNA_PROCESS_DATA } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useEffect, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";
const { Option } = Select;

const TNATaskFields = ({ initialTableData }) => {
  const [processList, setProcessList] = useState([]);

  useEffect(()=>{
    getProcessData();
  },[]);

  const getProcessData = async(filterValues)=>{
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(GET_ALL_TNA_PROCESS_DATA, false, bodyData);

    if (response && response?.data?.code === 200) {
      setProcessList(response?.data?.data?.data || []);
    }
  }
return (
    <>
      <Col span={6}>
        <Form.Item label="Task Title" name="title">
          <Input placeholder="Write Task Title ..." size="small" />
        </Form.Item>
      </Col>
      {/* <Col span={6}>
        <Form.Item label="Process" name="process">
          <Select
          allowClear
            showSearch
            placeholder="Select a Process"
            onSearch={(value) => {
              getProcessData({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {isArrayAndHasValue(processList) &&
              processList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.title}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col> */}
    </>
  );
};

export default TNATaskFields;
