import SewingLineList from "./ListSewingLine";

const SewingLine = () => {
  return (
    <>
      <SewingLineList />
    </>
  );
};

export default SewingLine;
