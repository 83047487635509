import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Input, Typography } from "antd";
import "./index.css";
import { useNavigate } from "react-router";
import HandleNotification from "../../common/Notification";
import { USER_LOGIN } from '../../apiServices/API_ENDPOINTS';
import { postData } from "../../apiServices/common";

const LogIn = () => {
  // Router
  const navigate = useNavigate();

  //   Antd Constants
  const { Title, Text } = Typography;

  //   Effects
  useEffect(() => {
    // Clear local storage data when lands to login page
    localStorage.clear();
  }, []);

  const handleLogIn = async (values) => {
    const query = `${USER_LOGIN}`;
    const bodyData = {
      email: values.email,
      password: values.password,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      // setCookie("token", response?.data?.token);
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("name", response.data?.user?.name);
      localStorage.setItem(
        "roleInfo",
        JSON.stringify(response?.data?.user?.role_info),
      );
      localStorage.setItem("user_type", response.data?.user?.user_type);
      localStorage.setItem("user_id", response.data?.user?.id);
      navigate("/");
      window.location.reload();
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Invalid Credentials",
        "Please try with valid email and password!",
      );
    }
  };

  return (
    <>
      <Row align="middle">
        <Col className="gutter-row" span={10} offset={2}>
          <div className="main-form">
            <div className="form-header">
              <Title
                style={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "30px",
                  width: "295px",
                  height: " 42px",
                  lineHeight: "42px",
                }}
              >
                Login to Continue
              </Title>
              <Text
                type="secondary"
                style={{
                  fontWeight: 300,
                  fontSize: "16px",
                  textAlign: "right",
                }}
              >
                Welcome please enter your email address
              </Text>
            </div>

            <div className="login-form">
              <Form
                layout={"vertical"}
                name="basic"
                onFinish={handleLogIn}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  style={{
                    width: "410px",
                    marginBottom: "30px",
                    height: "50px",
                    fontWeight: 600,
                  }}
                  name="email"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Login ID!",
                    },
                  ]}
                >
                  <Input placeholder="Ex: email id" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  style={{
                    width: "410px",
                    height: "50px",
                    marginBottom: "40px",
                    fontWeight: 600,
                  }}
                  rules={[
                    {
                      required: false,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Min. 8 characters" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" block htmlType="submit">
                    Login
                  </Button>
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <a className="login-form-forgot" href="/forget-password">
                    Forgot password
                  </a>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="aside-image"></div>
        </Col>
      </Row>
    </>
  );
};
export default LogIn;
