import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Select,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
  Typography,
  Breadcrumb,
  Input,
  Space,
} from "antd";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useLocation } from "react-router-dom";
import { MoreOutlined, EditOutlined, DeleteTwoTone, EyeOutlined } from "@ant-design/icons";
import {
    HOLIDAYS
} from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { alertPop } from "../../../apiServices/common/helper";
import { cglFormatDate, commaSeparateNumber, hasPermission } from "../../../utils/functions";
import { NO_PERMISSION_REQUIRED } from "../../../routes/permissions";
import moment from "moment";


const Holidays = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  //   States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );

  const [holidayList, setHolidayList] = useState(null);
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      key: "from_date",
      render: (value) => cglFormatDate(value),
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      key: "to_date",
      render: (value) => cglFormatDate(value),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllHoliday = async (filterValues) => {
    setLoading(true);

    const bodyData = filterValues;
    const response = await getData(HOLIDAYS, false, bodyData);
    console.log('filterValues', filterValues);
    console.log('response', response);
    if (response && response?.data?.code === 200) {
      
      setHolidayList(response?.data?.data?.data);
      setTotalData(response?.data?.data?.total);
      setRangeData(response?.data?.data?.per_page);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllHoliday({page: currentPage});
  }, [currentPage]);

  const removeHoliday = async (value) => {
    const response = await deleteData(`${HOLIDAYS}/${value}`);
    if (response && response?.code === 200) {
      alertPop("success", "Deleted Successfully!");
      getAllHoliday({page: currentPage});
    } else {
      alertPop(
        "error",
        response?.data?.message
          ? response?.data?.message[0]
          : "Something Went Wrong!",
      );
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/production-planning/holiday-update/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeHoliday(data.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: "2",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const extra = (
    <Space>
      {/* <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button> */}
      <Button key="3" size="small" type="primary">
        <Link to="/production-planning/holidays/create">Add New</Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>Holidays</Title>}
            actions={extra}
          />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          {/* <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={holidayList}
            fetchTableData={getAllHoliday}
            filterName="holidays"
            // filterName="cost_estimate"
          /> */}
          <Table
            loading={loading}
            dataSource={holidayList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default Holidays;
