import { Col, Form, Row, Select, Input, DatePicker } from "antd";
import { useEffect, useState } from "react";
import {
  LEARNING_CURVE_RESOURCE
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";

const LearningCurveFilterFields = ({ initialTableData }) => {

  return (
    <>  
      <Col span={4}>
        <Form.Item label="From" name="range_start">
          <Input 
            placeholder="From" 
            size="small" 
              rules={[
                {
                  required: true,
                  message: "From is required",
                },
              ]}
          />
        </Form.Item>
      </Col>    
      <Col span={4}>
        <Form.Item label="To" name="range_end">
          <Input 
            placeholder="To" 
            size="small" 
            rules={[
              {
                required: true,
                message: "To is required",
              },
            ]}
          />
        </Form.Item>
      </Col>     
    </>
  );
};

export default LearningCurveFilterFields;
