import { Card, Button, Affix, Form, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {  useEffect, useState } from "react";
import { GET_SINGLE_TNA_DEPARTMENT_DATA } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { isMobileView } from "components/Common/AppHelper";
import Header from "components/Common/Header";
import DepartmentForm from "../AddDepertment/DepartmentForm";

const UpdateDepartment = (props,ref) => {
  const { width, viewMode } = props;
  const [editInfo, setEditInfo] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { deptId } = useParams();
  const [headId, setHeadId] = useState([]);

  useEffect(() => {
    if (deptId) {
      getProcessInfo(deptId);
    }
  }, [deptId]);

  const getProcessInfo = async(id) => {
    const query = `${GET_SINGLE_TNA_DEPARTMENT_DATA}/${id}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      const processData = response?.data?.data;
    //   console.log(processData)
      form.setFieldsValue({
        title: processData?.title,
        description: processData?.description,
        dept_head_id: processData?.head?.id,
        dept_head: processData?.head?.name,
        is_active: processData?.is_active,
      });
      setEditInfo(processData);
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || "Failed to get inquiry data",
      );
    }
  };

  const discartForm = () => {
    navigate("/tanda/department");
  };

  const addBuyerInquiry = () => {
    form.submit();
  };


  const ActionUI = () => {
    return (
      <Space>
        <Button size="small" key={1} danger onClick={() => discartForm()}>
          Discard
        </Button>

        <Button
          size="small"
          key={2}
          type="primary"
          onClick={() => addBuyerInquiry()}
          hidden={viewMode}
        >
          Update Process
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`${
              viewMode ? "View" : "Edit"
            } Department`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={<ActionUI />}
          />
        </Card>
      </Affix>

      <DepartmentForm
        form={form}
        isEdit={true}
        viewMode={viewMode}
        editInfo={editInfo}
      />
      
      
    </>
  );
};

export default UpdateDepartment;
