import {
    GET_SUPPLIER_LIST,
    CREATE_NEW_SUPPLIER,
    DELETE_SUPPLIER,
    GET_SUPPLIER_DETAIL, UPDATE_SUPPLIER_DETAIL
} from '../ActionTypes';

const initialState = {
    supplierList: [],
    totalSupplier: null,
    selectedSupplier: null,
    selectedSupplierDetails: null,
    createdSupplier: null,
    selectedSupplierAccountInfo: null,
    pageSize: 10,
    page:1,
};

const SupplierReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_SUPPLIER_LIST:
            return {
                ...state,
                supplierList: action.payload.data,
                totalSupplier: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_SUPPLIER:
            return {
                ...state,
                createdSupplier: action.payload.data,
                supplierList: [action.payload.data, ...state.supplierList],
                totalSupplier: state.totalSupplier + 1,
            };

        case DELETE_SUPPLIER: {
            return {
                ...state,
                supplierList: action.payload.list,
                totalSupplier: action.payload.total,
            };
        }

        case GET_SUPPLIER_DETAIL:
            return {
                ...state,
                selectedSupplier: action.payload,
                selectedSupplierAccountInfo: action.payload,
                selectedSupplierDetails: action.payload,
            };

        case UPDATE_SUPPLIER_DETAIL:
            return {
                ...state,
                selectedSupplier: action.payload,
                supplierList: state.supplierList.map((supplier) =>
                    supplier.id === action.payload.id ? action.payload : supplier,
                ),
            };

        default:
            return state;
    }
};

export default SupplierReducer;
