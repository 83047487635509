import React, { forwardRef, useState, useEffect, useCallback } from "react";
import { Card, Collapse } from "antd";
import { Row, Col, Form, Select } from "antd";
import {
  SUBMISSION_REALIZATION_ADD,
  GET_SUBMISSION_REALIZATION_LIST,
  GET_EXPORT_DETAILS_INVOICE,
  ALL_SALES_CONTRACT_LIST,
  SUBMISSION_REALIZATION_INVOICE_SEARCH,
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import InvoiceDetails from "./InvoiceDetails";
import InvoiceTableFilterFields from "./InvoiceTableFilterFields";
import { isArrayAndHasValue } from "utils/functions";
import HandleNotification from "common/Notification";

const SubmissionRealizationForm = forwardRef((props, ref) => {
  // Props
  const { form, editMode, exportId, invoiceDetails, view, fileList } = props;

  // States
  const [salesContractList, setSalesContractList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState(null)
  const [invoiceTableData, setInvoiceTableData] = useState([]);
  const [filesList, setFilesList] = useState([]);

  var document = [];
  var document_name = [];

  // Antd
  const sales_contract_id_watch = Form.useWatch("sales_contract_id", form);
  const [searchForm] = Form.useForm();

  useEffect(() => {
    // Assign data to invoiceTableData only when invoiceList is updated
    if (!editMode) {
      setInvoiceTableData(invoiceList || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceList]);

  useEffect(() => {
    if (isArrayAndHasValue(invoiceDetails)) {
      setInvoiceTableData(invoiceDetails);
    }
  }, [invoiceDetails]);

  useEffect(() => {
    // If editMode, fileList props is available and filesList is empty then set fileList to filesList
    if (!isArrayAndHasValue(filesList) && editMode) {
      isArrayAndHasValue(fileList) && setFilesList(fileList);
    }
  }, [filesList, fileList, editMode]);

  const getSalesContractList = useCallback(async (filterValues) => {
    const payload = {
      ...filterValues,
    }
    const response = await getData(ALL_SALES_CONTRACT_LIST, false, payload);

    if (response && response?.status === 200) {
      setSalesContractList(response?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  const getInvoiceList = useCallback(async (salesContractID, filterValues) => {
    let response = await getData(
      `${GET_EXPORT_DETAILS_INVOICE}/${salesContractID}`,
      false,
      filterValues,
    );

    if (response && response?.status === 200) {
      setPaymentTerm(response?.data?.data?.payment_term);

      setInvoiceList(
        isArrayAndHasValue(response?.data?.data?.export_details) ? response?.data?.data?.export_details : [],
      );
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  const onSelectSalesContract = useCallback(
    (value) => {
      // Get invoice list by selected sales contract
      getInvoiceList(value);
    },
    [getInvoiceList],
  );

  // Effects
  useEffect(() => {
    getSalesContractList();
  }, [getSalesContractList]);

  useEffect(() => {
    if (editMode) {
      sales_contract_id_watch && getInvoiceList(sales_contract_id_watch);
      sales_contract_id_watch && onSelectSalesContract(sales_contract_id_watch);
    }
  }, [
    editMode,
    sales_contract_id_watch,
    onSelectSalesContract,
    getInvoiceList,
  ]);

  const onFinishSubmissionRealization = async (values) => {
    if (filesList?.length) {
      filesList.forEach((item) => {
        document_name.push(item?.name);
        document.push(item?.id);
      });
    }

    const bodyData = {
      sales_contract_id: values?.sales_contract_id,
      invoice_items:
        isArrayAndHasValue(invoiceTableData) &&
        invoiceTableData?.map((item) => {
          return {
            id: item?.id || null,
            fdbc_number: item?.fdbc_number || null,
            fdbc_date: item?.fdbc_date || null,
            acceptance_rcv_date: item?.acceptance_rcv_date || null,
            maturity_date: item?.maturity_date || null,
            realization_date: item?.realization_date || null,
            export_details_id: item?.export_details_id || null,
            invoice_no: item?.invoice_no || null,
            invoice_value: item?.invoice_value ? Number(item?.invoice_value) : null,
            invoice_date: item?.invoice_date || null,
            realization_value: item?.realization_value ? Number(item?.realization_value) : null,
            short_value: item?.short_value ? Number(item?.short_value) : null,
            courier_no: item?.courier_no || null,
            courier_date: item?.courier_date || null,
            status: item?.status || null,
            courier_option_id: item?.courier_option_id || null,
            document_submission_date: item?.document_submission_date || null,
            file_id: item?.file_id || null,
          };
        }),
      document,
      document_name,
    };

    if (editMode) {
      const response = await postData(
        `${GET_SUBMISSION_REALIZATION_LIST}/${exportId}/edit`,
        bodyData,
      );

      if (response) {
        alertPop("success", "Submission Realization Updated Successfully");
      } else {
        alertPop("error", "Submission Realization Update Failed!");
      }
    } else {
      const response = await postData(SUBMISSION_REALIZATION_ADD, bodyData);

      if (response) {
        alertPop("success", "Submission Realization Added Successfully");
      } else {
        alertPop("error", "Submission Realization Add Failed!");
      }
    }
  };

  const onTableDataChange = (value, index, context) => {
    const tableDataCopy = [...invoiceTableData];
    tableDataCopy[index][context] = value;
    setInvoiceTableData(tableDataCopy);
  };

  const getFilteredTableData = async (editPayload) => {
    const query = `${SUBMISSION_REALIZATION_INVOICE_SEARCH}/${exportId}`;
    const response = await postData(query, editPayload);

    if (response && response?.code === 200) {
      const masterData = response?.data?.invoice_details;
      setInvoiceTableData(masterData);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  const onTableSearch = (values) => {
    const searchFormValues = searchForm.getFieldsValue();
    const addPayload = {
      invoice_no: searchFormValues?.invoice_no || null,
      invoice_value: searchFormValues?.invoice_value || null,
    };
    const editPayload = {
      invoice_no: searchFormValues?.invoice_no || null,
      invoice_value: searchFormValues?.invoice_value || null,
      fdbc_number: searchFormValues?.fdbc_number || null,
      realization_value: searchFormValues?.realization_value || null,
      status: searchFormValues?.status || null,
    };

    if (!editMode) {
      // When in add mode
      getInvoiceList(sales_contract_id_watch, addPayload);
    } else {
      // When in edit mode
      getFilteredTableData(editPayload);
    }
  };

  const onClearSearch = () => {
    searchForm.resetFields();

    if (!editMode) {
      // When in add mode
      getInvoiceList(sales_contract_id_watch);
    } else {
      // When in edit mode
      getFilteredTableData();
    }
  };

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishSubmissionRealization}
      >
        <Card>
          <Row gutter={6}>
            <Col span={4}>
              <Form.Item
                label="Sales Contract - Master LC"
                name="sales_contract_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select Sales Contract",
                  },
                ]}
              >
                <Select
                  placeholder="Sales Contract - Master LC"
                  style={{ width: "100%" }}
                  onSelect={(value) => {
                    onSelectSalesContract(value);
                  }}
                  size="small"
                  // onSearch={(value) =>
                  //   getSalesContractList({ reference_no: value })
                  // }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onClear={() => {
                    getSalesContractList();
                    setInvoiceList([]);
                  }}
                  allowClear
                  showSearch
                  disabled={view}
                >
                  {salesContractList.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.reference_no}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>

      {/* Sewing Table Search Form */}
      <Collapse>
        <Collapse.Panel header={<b>Table Filter</b>} key="2">
          <Form form={searchForm} layout="vertical" onFinish={onTableSearch}>
            <InvoiceTableFilterFields onClearSearch={onClearSearch} />
          </Form>
        </Collapse.Panel>
      </Collapse>

      <InvoiceDetails
        onTableDataChange={onTableDataChange}
        invoiceTableData={invoiceTableData}
        paymentTerm={paymentTerm}
        setInvoiceTableData={setInvoiceTableData}
        view={view}
      />
    </div>
  );
});

export default SubmissionRealizationForm;
