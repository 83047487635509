import { 
    Form, 
    Layout, 
    Button, 
    Collapse, 
    Typography, 
    Modal, 
    Col, 
    Input, 
    Row, 
    Select, 
    InputNumber, 
    DatePicker,
    message
} from "antd";
import AppPageHeader from "../../../../common/PageHeader";
import InventoryLayOutCommon from "../../../InventoryFabric/LayOut/InventoryLayOutCommon";
import { 
    COST_OF_MAKING_LIST,
    COST_OF_MAKING_CREATE,
    COST_OF_MAKING_EDIT,
    COST_OF_MAKING_DELETE,
} from "../../../../routes/permissions";
import { hasPermission } from "../../../../utils/functions";
import {
  CM_DEFAULT_DATA_RESOURCE
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../apiServices/common";
import { useEffect, useState } from "react";
import { alertPop } from "../../../../apiServices/common/helper";
import moment from "moment";
import HandleNotification from "../../../../common/Notification";

const CmDefaultDataEntry = () => {

      // Antd
  const { Option } = Select;
  // States
  const [poList, setPoList] = useState(null);
  const [styleList, setStyleList] = useState(null);
  const [cartonList, setCartonList] = useState(null);
  const [selectedCarton, setSelectedCarton] = useState([]);

  // Antd
  const { Content } = Layout;
  const { Panel } = Collapse;
  const [CmDefaultDataForm] = Form.useForm();
  const po_map_id_watch = Form.useWatch("po_map_id", CmDefaultDataForm);
  const start_watch = Form.useWatch("start", CmDefaultDataForm);
  const end_watch = Form.useWatch("end", CmDefaultDataForm);
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDefaultData();
    });

    const getDefaultData = async () => {
        const response = await getData(`${CM_DEFAULT_DATA_RESOURCE}`);
        console.log('response', response);

        if (response && response?.data?.code === 200) {
            setLoading(false);
            const defaultData = response?.data?.data;
            CmDefaultDataForm.setFieldsValue({
                working_days_per_month: defaultData?.working_days_per_month,
                working_hours_per_day: defaultData?.working_hours_per_day,
                dollar_conversion_rate: defaultData?.dollar_conversion_rate,
            })
        } else {
            setLoading(false);
            message.error("Error getting Expense Data");
        }
    };

    const onFinishDefaultData = async (formValues) => {
        const bodyData = {
            working_days_per_month: formValues?.working_days_per_month,
            working_hours_per_day: formValues?.working_hours_per_day,
            dollar_conversion_rate: formValues?.dollar_conversion_rate,
        }

        const response = await postData(`${CM_DEFAULT_DATA_RESOURCE}`, bodyData);

        if (response && response?.code === 200) {
          HandleNotification(
            "success",
            "bottomRight",
            `Data Stored Successfully`,
            null,
          );
        } else {
          HandleNotification("error", "bottomRight", "Something went wrong", null);
        }
    }

  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => CmDefaultDataForm.submit()}
        disabled={!hasPermission([COST_OF_MAKING_CREATE])}
      >
        {hasPermission([COST_OF_MAKING_CREATE])
          ? "Submit"
          : "No Permission"}
      </Button>
    </div>
  );

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader
          title={<Title level={5}>Cost Of Making Default Data</Title>}
          extra={extra}
        />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Cost Of Making Default Data Entry</b>} key="1">
              <Form
                layout="vertical"
                onFinish={onFinishDefaultData}
                form={CmDefaultDataForm}
              >
                <Row gutter={3} style={{marginTop: 10}}>
                    <Col span={8}>
                        <Form.Item
                            name="working_days_per_month"
                            label="Working Days per Month"
                            // rules={[
                            // {
                            //     required: true,
                            //     message: `Working Days per Month is required `,
                            // },
                            // ]}
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="working_hours_per_day"
                            label="Working Hours per Day"
                            // rules={[
                            // {
                            //     required: true,
                            //     message: `Working Hours per Day is required `,
                            // },
                            // ]}
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="dollar_conversion_rate"
                            label="Dollar Conversion Rate"
                            // rules={[
                            // {
                            //     required: true,
                            //     message: `Dollar Conversion Rate is required `,
                            // },
                            // ]}
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default CmDefaultDataEntry;
