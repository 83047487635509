import {
    GET_WAREHOUSE_LIST,
} from '../ActionTypes';

const initialState = {
    warehouseList: [],
    pageSize: 10,
    page:1,
};

const WarehouseReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_WAREHOUSE_LIST:
            return {
                ...state,
                warehouseList: action.payload.data,
            };
        default:
            return state;
    }
};

export default WarehouseReducer;
