import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Typography, Tag } from "antd";
import AppPageHeader from "../../../../common/PageHeader";
import AppContent from "../../../../common/AppContent";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  onDeleteUser,
  onGetUserDetails,
  onGetUserList,
} from "../../../../redux/actions";
import { useLocation, useNavigate } from "react-router";
import UserListTable from "./UserListTable";
import { hasPermission } from "../../../../utils/functions";
import {
  USER_CREATE_PERMISSION,
  USER_EDIT_PERMISSION,
  USER_DELETE_PERMISSION,
} from '../../../../routes/permissions';
import { GET_USERS_LIST_ENDPOINT } from '../../../../apiServices/API_ENDPOINTS';
import HandleNotification from "../../../../common/Notification";
import { getData } from "../../../../apiServices/common";
import FilterComponent from "../../../Configuration/CommonComponents/FilterComponent";
import ExtraContent from "../../../Configuration/CommonComponents/ExtraContent";

const UserList = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [userList, setUserList] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  // Redux
  const dispatch = useDispatch();

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllUsers = async (filterValues) => {
    const query = `${GET_USERS_LIST_ENDPOINT}`;
    const bodyData = {
      page: page,
      per_page: filterValues?.per_page || 10,
      ...(filterValues?.name && { name: filterValues?.name }),
      ...(filterValues?.email && { email: filterValues?.email }),
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setUserList(response?.data?.data?.data);
      setPageSize(response?.data?.data?.per_page);
      setTotal(response?.data?.data?.total);
    } else {
      HandleNotification("error", "Error", "Something went wrong");
    }
  };

  useEffect(() => {
    getAllUsers({page: page});
  }, [refresh, page, pageSize]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handleDelete = (event, record) => {
    dispatch(onDeleteUser(record));
    refetch();
  };

  const handleEdit = (event, record) => {
    dispatch(onGetUserDetails(record.id));
    navigate("/settings/users/create-user", { state: record });
  };
  const filterName = "users_list";
  const tableColumns = [
    { title: "Name", dataIndex: "name", key: "name", width: '20%' },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone Number", dataIndex: "phone", key: "phone" },
    { title: "User Type", dataIndex: ["role_info", "name"], key: "name" },
    { title: "Department", dataIndex: ["department_info", "title"], key: "title" },
    {
      title: "User Status",
      dataIndex: "user_status",
      key: "user_status",
      render: (value, record) => {
        if (value) {
          if(record.user_status === "Active"){
            return <Tag color="green">{record?.user_status}</Tag>;
          }else{
            return <Tag color="red">{record?.user_status}</Tag>;
          }
        } else {
          return <Tag color="green">Active</Tag>;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <div>
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={(event) => handleDelete(event, record)}
          >
            <Button
              size="small"
              danger
              style={{ marginLeft: 5 }}
              disabled={!hasPermission([USER_DELETE_PERMISSION])}
            >
              Delete
            </Button>
          </Popconfirm>

          <Button
            style={{ marginLeft: 5 }}
            size="small"
            onClick={(event) => {
              handleEdit(event, record);
            }}
            disabled={!hasPermission([USER_EDIT_PERMISSION])}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const handleNav = (event, record) => {
    navigate("/settings/users/create-user", {
      state: props.selectedUserDetails,
    });
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const extra = (
    <Button
      type="primary"
      size="small"
      onClick={handleNav}
      disabled={!hasPermission([USER_CREATE_PERMISSION])}
    >
      {hasPermission([USER_CREATE_PERMISSION]) ? "Add New" : "No Permission"}
    </Button>
  );
  const header = (
    <AppPageHeader
      extra={
        <ExtraContent
          refresh={refresh}
          setRefresh={setRefresh}
          handleAddItemOpen={handleNav}
          disabled={!hasPermission([USER_CREATE_PERMISSION])}
          addButtonText={
            hasPermission([USER_CREATE_PERMISSION])
              ? "Add New"
              : "No Permission"
          }
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      }
      title={<Title level={5}>Users</Title>}
    />
  );
  const content = (
    <>
      <FilterComponent
        tableData={userList}
        filterAPI={getAllUsers}
        showFilter={showFilter}
        filterName={filterName}
        setShowFilter={setShowFilter}
      />
      <UserListTable
        onChange={(newPage) => {
          onPageChange(newPage);
        }}
        total={total}
        current={page}
        defaultCurrent={page}
        pageSize={pageSize}
        dataSource={userList}
        tableColumns={tableColumns}
        pagination={true}
      />
    </>
  );
  return (
    <>
      <AppContent header={header} content={content} />
    </>
  );
};

export default UserList;
