import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
    DatePicker,
    TimePicker,
    Divider,
    Space,
    InputNumber,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    CAPACITY_OF_SAMPLE_TEAM_LIST_ENDPOINT,
    CAPACITY_OF_SAMPLE_TEAM_ADD_ENDPOINT,
    CAPACITY_OF_SAMPLE_TEAM_EDIT_ENDPOINT,
    CAPACITY_OF_SAMPLE_TEAM_DELETE_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import moment from "moment";

const CapacityOfSampleTeam = () => {

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };
  
  const onTimeChange = (time, timeString) => {
    console.log(time, timeString); // You can handle the selected time here
  };
  
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ purposeList, setPurposeList ] = useState();
  // const [items, setItems] = useState(purposeList || []);
  const [newPurpose, setNewPurpose] = useState("");


  // Antd
  const [visit_dateForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // // Redux
  // const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllCapacityOfSampleTeam = useCallback(
    async (filterValues) => {
      const payload = {
        page: filterValues?.page || currentPage,
        ...filterValues,
      }

      setLoading(true);

      const query = `${CAPACITY_OF_SAMPLE_TEAM_LIST_ENDPOINT}`;
      const bodyData = {
        ...payload,
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting Capacity of Sample Team data");
      }
    },
    [currentPage],
  );
  

  // console.log("Line List dekhi", salesContractList);

  // Effects
  useEffect(() => {
    getAllCapacityOfSampleTeam();
  }, [refresh, getAllCapacityOfSampleTeam]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    visit_dateForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedvisit_date = async (id) => {
    setLoading(true);
    const response = await deleteData(`${CAPACITY_OF_SAMPLE_TEAM_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Capacity of Sample Team Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedvisit_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      sam_range_from: formValues?.sam_range_from,
      sam_range_to: formValues?.sam_range_to,
      daily_capacity: formValues?.daily_capacity,
      no_of_employees: formValues?.no_of_employees,
    };

    const response = await putData(
      `${CAPACITY_OF_SAMPLE_TEAM_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Capacity of Sample Team Edited Successfully");
      refetch();
      visit_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewvisit_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      sam_range_from: formValues?.sam_range_from,
      sam_range_to: formValues?.sam_range_to,
      daily_capacity: formValues?.daily_capacity,
      no_of_employees: formValues?.no_of_employees,
    };

    const response = await postData(CAPACITY_OF_SAMPLE_TEAM_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Capacity of Sample Team Created Successfully");
      refetch();
      visit_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    console.log("recordsss", record);

    visit_dateForm.setFieldsValue({
      id: record?.id,
      sam_range_from: record?.sam_range_from,
      sam_range_to: record?.sam_range_to,
      daily_capacity: record?.daily_capacity,
      no_of_employees: record?.no_of_employees,

    });
    // Trigger handleSelectChange to populate sam_range_from and sales contract
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Sam Range from",
      dataIndex: ["sam_range_from"],
      key: "sam_range_from",
    },
    {
      title: "Sam Range to",
      dataIndex: ["sam_range_to"],
      key: "sam_range_to",
    },
    {
      title: "Daily Capacity",
      dataIndex: ["daily_capacity"],
      key: "daily_capacity",
    },
    {
      title: "No of Employees",
      dataIndex: ["no_of_employees"],
      key: "no_of_employees",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedvisit_date(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                
                addButtonText="Add New"
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Capacity of Sample Team</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllCapacityOfSampleTeam}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="visit_date"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Capacity of Sample Team" : "Create Capacity of Sample Team"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={visit_dateForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedvisit_date : createNewvisit_date}
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="sam_range_from"
                placeholder="Sam Range from"
                label="Sam Range from"
              >
                <InputNumber style={{width:'100%'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sam_range_to"
                placeholder="Sam Range to"
                label="Sam Range to"
              >
                <InputNumber style={{width:'100%'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="daily_capacity"
                placeholder="Daily Capacity"
                label="Daily Capacity"
              >
                <InputNumber style={{width:'100%'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="no_of_employees"
                placeholder="No of Employees"
                label="No of Employees"
              >
                <InputNumber style={{width:'100%'}} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CapacityOfSampleTeam;
