import { useEffect, useState } from "react";
import { Col, Form, Row, Select } from "antd";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  PO_MAP_ENDPOINT,
  PO_MAP_NEW_ENDPOINT,
  SALES_CONTRACT_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const PurchaseOrderFilterFields = ({ initialTableData }) => {
  // States
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [salseCotractList, setSalseCotractList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [poList, setPOList] = useState([]);

  // Antd
  const { Option } = Select;

  // Effects
  useEffect(() => {
    // Get buying office, sales contract and buyer list at first load
    getBuyingOfficeList();
    getSalseCotractList();
    getBuyerList();
    getPOList();
  }, []);

  const getBuyingOfficeList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(GET_BUYING_OFFICE_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOffice(response?.data?.data?.data || []);
    }
  };

  const getSalseCotractList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SALES_CONTRACT_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSalseCotractList(response?.data?.data?.data || []);
    }
  };

  const getBuyerList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    let response = await getData(GET_BUYER_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyerList(response?.data?.data?.data || []);
    }
  };

  const getPOList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(PO_MAP_NEW_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setPOList(response?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item label="Buying Office" name="buying_office_id">
          <Select
            showSearch
            placeholder="Select a Buying Office"
            onSearch={(value) => {
              getBuyingOfficeList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {isArrayAndHasValue(buyingOffice) &&
              buyingOffice.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Sales Contract" name="sales_contract_id">
          <Select
            showSearch
            placeholder="Select a Sales Contract No"
            onSearch={(value) => {
              getSalseCotractList({ reference_no: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {salseCotractList?.length &&
              salseCotractList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.reference_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            placeholder="Select a Buyer"
            onSearch={(value) => {
              getBuyerList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {buyerList?.length &&
              buyerList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Contract Status" name="contract_status">
          <Select
            showSearch
            placeholder="Select Contract Status"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.label
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            size="small"
          >
            <Option value={"Created"} key={1} label={"Created"}>
              {"Created"}{" "}
            </Option>
            <Option value={"Finished"} key={2} label={"Finished"}>
              {"Finished"}{" "}
            </Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Original PO" name="po_map_id">
          <Select
            showSearch
            placeholder="Select an original PO"
            onSearch={(value) => {
              getPOList({ original_po: value });
            }}
            optionFilterProp="children"
            size="small"
            allowClear
            onClear={() => getPOList()}
          >
            {isArrayAndHasValue(poList) &&
              poList.map((po) => (
                <Option value={po.id} key={po.id}>
                  {po.original_po}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default PurchaseOrderFilterFields;
