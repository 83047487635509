import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Breadcrumb,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
} from "antd";
import { MoreOutlined, EditOutlined, DeleteTwoTone } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import {
  SHIPMENT_HANDOVER_LIST,
  SHIPMENT_HANDOVER_DELETE,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { alertPop } from "../../../apiServices/common/helper";
import { hasPermission } from "../../../utils/functions";
import {
  SHIPMENT_HANDOVER_CREATE_PERMISSION,
  SHIPMENT_HANDOVER_EDIT_PERMISSION,
  SHIPMENT_HANDOVER_DELETE_PERMISSION,
} from '../../../routes/permissions';

export default function ShipmentOver(props) {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  //   States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [notingList, setNotingList] = useState([]);
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValues, setSearchValues] = useState();
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const searchFilter = (values) => {
    //console.log("values", values);
  };

  const columns = [
    {
      title: "Sales Contract Number",
      dataIndex: ["sales_contract_info", "reference_no"],
      key: ["sales_contract_info", "reference_no"],
      width: "30%",
    },
    {
      title: "Export Invoice Number",
      dataIndex: ["export_invoice_number"],
      key: ["export_invoice_number"],
      width: "10%",
    },

    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllNotingDetails = async (passedObject) => {
    let filter = passedObject;
    let response = await getData(SHIPMENT_HANDOVER_LIST, false, filter);

    if (response && response?.status === 200) {
      let masterData = response?.data;
      setNotingList(masterData?.data?.data);
      setTotalData(masterData?.data?.total);
      setRangeData(masterData?.data?.per_page);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllNotingDetails();
  }, [currentPage, searchValues]);

  const removeShipmentOver = async (value) => {
    const response = await deleteData(`${SHIPMENT_HANDOVER_DELETE}/${value}`);

    if (response && response?.code === 200) {
      alertPop(
        "success",
        response?.messages ? response?.messages : "Deleted Successfully!",
      );
      getAllNotingDetails();
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link
              to={`/export-customs-clearance/edit-shipment-handover/${data.id}`}
            >
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([SHIPMENT_HANDOVER_EDIT_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeShipmentOver(data.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: "0",
          disabled: !hasPermission([SHIPMENT_HANDOVER_DELETE_PERMISSION]),
        },
      ]}
    />
  );
  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Shipment Handover"
            subTitle=""
            actions={[
              <Button
                type={showFilter ? "danger" : "primary"}
                size="small"
                onClick={handleFilter}
                style={{ float: "right" }}
              >
                {`${showFilter ? "Close" : "Open"} Filter Option`}
              </Button>,
              <Button
                key="3"
                size="small"
                type="primary"
                disabled={!hasPermission([SHIPMENT_HANDOVER_CREATE_PERMISSION])}
              >
                <Link to="/export-customs-clearance/add-shipment-handover">
                  {hasPermission([SHIPMENT_HANDOVER_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"}
                </Link>
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader searchFilter={searchFilter} />
          <CustomFilter
            showFilter={showFilter}
            tableData={notingList}
            fetchTableData={getAllNotingDetails}
            filterName="shipment_handover"
          />
          <Table
            loading={loading}
            dataSource={notingList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}
