import { Affix, Button, Card, Space, Spin } from 'antd';
import { GET_TNA_SUMMAARY_GRAPH } from 'apiServices/API_ENDPOINTS';
import { getData } from 'apiServices/common';
import { isMobileView } from 'components/Common/AppHelper';
import LineChart from 'components/Common/Charts/LineChart';
import Header from 'components/Common/Header';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';

const ProgressGraph=({width})=> {
    const { salesContractId } = useParams();
    const [progressData, setProgressData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchProgressData();
    }, [salesContractId]);

    const fetchProgressData = async() =>{
        setIsLoading(true);
        const response = await getData(`${GET_TNA_SUMMAARY_GRAPH}/${salesContractId}/graph`);
        if(response?.data?.code === 200){
            const allDatas = response?.data?.data;
            const result = allDatas?.flatMap(data => {
                const { title, actual_date, due_date } = data;
                // console.log(actual_date)

                if(!actual_date && !due_date){
                  return [];
                }else if(!actual_date && due_date){
                  return [
                    { title, date: due_date, type: "Due Date", value: due_date }
                ];
                }else if (actual_date && !due_date){
                  return [
                    { title, date: actual_date, type: "Actual Date", value: actual_date },
                   ];
                }else{
                  return [
                    { title, date: actual_date, type: "Actual Date", value: actual_date },
                    { title, date: due_date, type: "Due Date", value: due_date }
                  ];
                }
               
            });
            setProgressData(result);
        }
        await new Promise((r)=>setTimeout(r, 300));
        setIsLoading(false);
    };
    const extra = (
        <Space>
          <Button
            type={"primary"}
            size="small"
            style={{ float: "right" }}
          >
             <Link to={`/tanda/summary`}>
             Back to List
            </Link>
          </Button>
        </Space>
      );
  return (
    <Spin spinning={isLoading}>
        <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header title="T&A Summary Progress" subTitle="" actions={extra} />
        </Card>
      </Affix>
        <div className='mt-4'>
            {
                progressData?.length > 0 ?  <LineChart title="T&A Progress" progressData={progressData}/>
                : <div>No Data Found</div>
            }    
        </div>
    </Spin>
  )
};

export default ProgressGraph;
