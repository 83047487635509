import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    GET_BRAND_LIST,
    CREATE_NEW_BRAND,
    DELETE_BRAND,
    UPDATE_BRAND_DETAIL, GET_BRAND_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_BRAND_ENDPOINT,
    DELETE_BRAND_ENDPOINT,
    EDIT_BRAND_ENDPOINT,
    GET_BRAND_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetBrandList = (page, per_page, name) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_BRAND_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && { name: name }),
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BRAND_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onCreateBrand = (brand) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_BRAND_ENDPOINT}`, brand)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_BRAND, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Brand Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Brand Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};
export const onUpdateSelectedBrand = (brand) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_BRAND_ENDPOINT}/${brand.id}`, brand)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_BRAND_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Brand Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Brand updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onBrandGetDetail = (brandId) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`api/brands/${brandId}/edit`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BRAND_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Got bank information Successfully",

                    });

                    // HandleNotification('success', 'bottomRight', 'Brand deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    // HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};


export const onDeleteBrand = (brand) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_BRAND_ENDPOINT}/${brand['id']}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_BRAND, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted bank Successfully",

                    });

                    HandleNotification('success', 'bottomRight', 'Brand deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
