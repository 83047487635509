import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { commaSeparateNumber, formatNumberWithCommas } from "utils/functions";

const OBForm = (props) => {
  // Props
  const { editInfo } = props;

  const toOBShowPage = () => {
    window.open(
      `/merchandising/operation-bulletin/show/${editInfo?.ob_info?.id}`,
    );
  };

  return (
    <Card
      title={
        <div style={{ display: "flex", gap: "5px" }}>
          <span style={{ fontSize: "14px" }}>OB</span>
          <span
            style={{ fontSize: "15px", cursor: "pointer" }}
            onClick={toOBShowPage}
          >
            <EyeOutlined />
          </span>
        </div>
      }
    >
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item label="OB Status" name="ob_status">
            <Input placeholder="OB Status" size="small" disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Total SAM" name="total_sam">
            <InputNumber
              style={{width: '100%'}}
              placeholder="Total SAM"
              size="small"
              disabled
              formatter={(value) => formatNumberWithCommas(value)}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="No of Workers" name="no_of_workers">
            <InputNumber
              style={{width: '100%'}}
              placeholder="No of Workers"
              size="small"
              disabled
              formatter={(value) => formatNumberWithCommas(value)}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Hours per Day" name="no_of_hour">
            <InputNumber
              style={{width: '100%'}}
              placeholder="Hours per Day"
              size="small"
              disabled
              formatter={(value) => formatNumberWithCommas(value)}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Required Efficiency %" name="required_efficiency">
            <InputNumber
              style={{width: '100%'}}
              placeholder="Required Efficiency %"
              size="small"
              disabled
              formatter={(value) => formatNumberWithCommas(value)}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Target (pc) per Hour" name="per_hour_target">
            <InputNumber
              style={{width: '100%'}}
              placeholder="Target (pc) per Hour"
              size="small"
              disabled
              formatter={(value) => formatNumberWithCommas(value)}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default OBForm;
