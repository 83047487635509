import { Col, Form, Row, Select, Input } from "antd";

const OrganizationFilterFields = ({ initialTableData }) => {
    const { Option } = Select;

    return (
        <>
            <Col span={4}>
                <Form.Item label="Buyer Name" name="name">
                    <Input placeholder="Buyer Name" size="small"/>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label="Buyer Short Name" name="short_name">
                    <Input placeholder="Buyer Short Name" size="small"/>
                </Form.Item>
            </Col>
        </>
    );
};

export default OrganizationFilterFields;
