import { Affix, Button, Card } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getData } from "../../../apiServices/common";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import AddStyleForm from "./AddStyleForm";

export default function UpdateStyle(props) {
  let { styleId } = useParams();
  const { width } = props;
  const propertyFormRef = useRef(null);
  const [permitInfo, setPermitInfo] = useState();

  const navigate = useNavigate();
  const copyStyleForm = () => {
    navigate("/merchandising/add-copied-style");
  };

  const addProduct = () => {
    propertyFormRef.current.submit();
  };
  const edit_url = `/api/styles/${styleId}/edit`;

  const getPIInfo = async () => {
    let res = await getData(edit_url);

    if (res) {
      let masterData = res?.data?.data[0];
      setPermitInfo(masterData);
    }
  };

  useEffect(() => {
    getPIInfo();
  }, []);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Update Style"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Link to={`/merchandising/copy-style/${styleId}`}>
                <Button
                  size="small"
                  key={1}
                  success
                  onClick={() => copyStyleForm()}
                >
                  Copy Style
                </Button>
              </Link>,
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
              >
                Update Style
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddStyleForm ref={propertyFormRef} permitInfo={permitInfo} />
    </>
  );
}
