import { UploadOutlined } from "@ant-design/icons";
import { Button, Space, Upload, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router";

const ExtraContent = (props) => {
  // Props
  const {
    refresh,
    setRefresh,
    handleAddItemOpen,
    hasImportButton,
    addButtonText,
    disabled,
    hideAddButton,
    hideFilterButton,
    showFilter,
    setShowFilter,
    uploadAPI,
    acceptedFileTypes,
    showBackButton,
    afterUploadCustom,
  } = props;

  // States
  const [loading, setLoading] = useState(false);

  // Router
  const navigate = useNavigate();

  // Others
  const token = localStorage.getItem("token");
  const api_base_url = process.env.REACT_APP_BASE_URL;

  const uploadProps = {
    accept: acceptedFileTypes || ".xlsx",
    headers: {
      authorization: `Bearer ${token}`,
    },
    action: api_base_url + `${uploadAPI || "/api/items/import"}`,
    showUploadList: false,
    onChange(action) {
      if (action?.file?.status !== "uploading") {
        setLoading(true);
      }
      if (
        action?.file?.status === "done" &&
        action?.file?.response.code === 200
      ) {
        setLoading(false);
        message.success(
          `${action?.file?.response?.message[0] || "Uploaded successfully"}`,
        );
        setRefresh(refresh + 1);
        afterUploadCustom && afterUploadCustom();
      } else if (
        action?.file?.status === "done" &&
        action?.file?.response.code !== 200
      ) {
        setLoading(false);
        message.error(
          `Error importing file. Please check the file and try again.`,
        );
      } else if (action.file.status === "error") {
        setLoading(false);
        message.error(
          `Error importing file. Please check the file and try again.`,
        );
      }
    },
  };

  return (
    <Space>
      {showBackButton && (
        <Button
          size="small"
          onClick={() => navigate(-1)}
          style={{ float: "right" }}
        >
          Back
        </Button>
      )}

      {!hideFilterButton && (
        <Button
          type={showFilter ? "danger" : "primary"}
          size="small"
          onClick={() => setShowFilter((prev) => !prev)}
          style={{ float: "right" }}
        >
          {`${showFilter ? "Close" : "Open"} Filter Option`}
        </Button>
      )}
      {!hideAddButton && (
        <Button
          type="primary"
          size="small"
          onClick={handleAddItemOpen}
          disabled={disabled}
        >
          {addButtonText ? addButtonText : "Add New"}
        </Button>
      )}

      {hasImportButton && (
        <Upload {...uploadProps} listType="picture-circle">
          <Button
            type="success"
            size="small"
            icon={<UploadOutlined />}
            loading={loading}
          >
            Import
          </Button>
        </Upload>
      )}
    </Space>
  );
};

export default ExtraContent;
