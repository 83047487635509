import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Input,
  Form,
  Row,
  Select,
  Switch,
} from "antd";
import { getData } from "apiServices/common";
import { isArrayAndHasValue } from "../../../../utils/functions";
import { GET_ALL_TNA_DEPARTMENT_DATA, GET_USERS_ROLE_ENDPOINT } from "apiServices/API_ENDPOINTS";

const UserForm = (props) => {
  // Props
  const {userDetails} = props;

  // States
  const [checkedVal, setCheckedVal] = useState("inactive");
  const [departmentList, setDepartmentList] = useState(null);
  const [userRoleList, setUserRoleList] = useState(null);

  // Antd
  const { Option } = Select;

  const getAllDepartment = async (filterValues, extraData) => {
    const bodyData = {
      per_page: 10,
      ...(filterValues && { ...filterValues })
    };
    const query = `${GET_ALL_TNA_DEPARTMENT_DATA}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      const departmentData = response?.data?.data?.data;
      if(extraData) {
        departmentData.push(extraData);
      }
      setDepartmentList(departmentData);
    }
  };

  const getAllUserRoles = useCallback(async(filterValues) => {
    const bodyData = {
      per_page: 10,
      ...filterValues,
    };
    const query = `${GET_USERS_ROLE_ENDPOINT}`;

    const response = await getData(query, false, bodyData);
    const roleInfo = userDetails?.role_info;

    if (response && response?.data?.code === 200) {
      const roleList = response?.data?.data?.data;
      let customRoleData = isArrayAndHasValue(roleList) ? [...roleList] : [];
      if(roleInfo) {
        customRoleData.push(roleInfo);
      }
      setUserRoleList(customRoleData);
    }

  }, [userDetails]);

  const onCheckBoxChange = (checked) => {
    if (checked) {
      setCheckedVal("activate");  
    } else {
      setCheckedVal("inactive");
    }
  };

  useEffect(() => {
    getAllUserRoles();
  }, [getAllUserRoles]);
  
  
  useEffect(() => {
    if (userDetails && isArrayAndHasValue(departmentList)) {
      if (!departmentList.some(dept => dept?.id === userDetails?.department_info?.id)) {
        getAllDepartment(null, userDetails?.department_info);
      }
    }
    else {
      getAllDepartment();
    }
  }, [userDetails]);

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Mobile Number"
            name="phone"
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
          <Col span={4}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: userDetails ? false : true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: userDetails ? false : true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!",
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="small" />
            </Form.Item>
          </Col>
          {/* </>
        )} */}
        <Col span={4}>
          <Form.Item label="User Type" name="role_id">
            <Select
              showSearch
              onSearch={(value) => getAllUserRoles({name: value})}
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
            >
              {userRoleList?.map((role, idx) => (
                <Option key={idx} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
            <Col span={4}>
              <Form.Item label="Department" name="department_id">
                <Select
                  showSearch
                  placeholder="Select a Department"
                  optionFilterProp="children"
                  size="small"
                  onSearch={(value) => getAllDepartment({ title: value })}
                  allowClear
                >
                  {isArrayAndHasValue(departmentList) &&
                    departmentList?.map((departmentItem, index) => (
                      <Option
                        value={departmentItem?.id}
                        key={departmentItem?.id || index}
                      >
                        {departmentItem?.title || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
        <Col span={4}>
          <Form.Item
            name="user_status"
            label="Is Active"
            required
            value={checkedVal}
            className="collection-create-form_last-form-item"
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Yes"
              onChange={onCheckBoxChange}
              unCheckedChildren="No"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default UserForm;
