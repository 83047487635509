import { useCallback, useEffect, useState } from "react";
import { message, Modal, Table, Typography } from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData } from "../../apiServices/common";
import { CAPACITY_BOOKING_REPORT_LIST_ENDPOINT } from "../../apiServices/API_ENDPOINTS";
import { useLocation } from "react-router";

const CapacityBookingsReport = () => {
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const onModalOpen = (summary) => {
    setIsModalOpen(true);
    console.log("summary", summary);
    setModalData(summary); // Ensure modalData is set with the passed summary
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const { Title } = Typography;

  const getAllCapacityBookingsReport = useCallback(async (filterValues) => {
    const payload = {
      page: filterValues?.page,
      ...filterValues,
    };

    setLoading(true);

    const query = `${CAPACITY_BOOKING_REPORT_LIST_ENDPOINT}`;
    const response = await getData(query, false, payload);
    console.log("response", response);
    if (response?.data?.code === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting Capacity Booking Report data");
    }
  }, []);

  useEffect(() => {
    getAllCapacityBookingsReport();
  }, [refresh, getAllCapacityBookingsReport]);


  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                showFilter={showFilter}
                hideAddButton={true}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Capacity Booking Report</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllCapacityBookingsReport}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="capacity_bookings_report"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <table
                border="1"
                cellPadding="5"
                cellSpacing="0"
                style={{ width: "100%", borderColor: "#f0f0f0", fontSize: "12px" }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#1b4b72" }}>
                    <th style={{ textAlign: "left", color: "#fff" }}>Month</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Style</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Substyle</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Buying Office</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Buyer</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Confirm Qty</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Projected Qty</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Total Qty</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Sewing Qty</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Remaining Sewing Qty</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Planned Qty</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Remaining Planned Qty</th>
                    <th style={{ textAlign: "left", color: "#fff" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((eachData, eachDataIndex) => 
                    eachData?.details?.length > 0 ? (
                      // Render month row with rowspan
                      eachData.details.map((eachDataDetail, eachDataDetailIndex) => (
                        <tr key={`${eachDataIndex}-${eachDataDetailIndex}`}>
                          {/* Render month_name only for the first row of each month with rowSpan */}
                          {eachDataDetailIndex === 0 && (
                            <td rowSpan={eachData.details.length}>{eachData.month_name}</td>
                          )}
                          <td>{eachDataDetail.style?.style_no || "N/A"}</td>
                          <td>{eachDataDetail.sub_style?.sub_style_no || "N/A"}</td>
                          <td>{eachDataDetail.style?.buying_office?.office_name || "N/A"}</td>
                          <td>{eachDataDetail.style?.buyer?.buyer_name || "N/A"}</td>
                          <td>{eachDataDetail.confirmed_qty || "0"}</td>
                          <td>{eachDataDetail.projected_qty || "0"}</td>
                          <td>{eachDataDetail.total_qty || "0"}</td>
                          <td>{eachDataDetail.sewing_qty || "0"}</td>
                          <td>{eachDataDetail.remaining_sewing_qty || "0"}</td>
                          <td>{eachDataDetail.plan_done || "0"}</td>
                          <td>{eachDataDetail.plan_due || "0"}</td>
                          {/* Render month_name only for the first row of each month with rowSpan */}
                          {eachDataDetailIndex === 0 && (
                            <td rowSpan={eachData.details.length}><a href="#" onClick={() => onModalOpen(eachData?.summary)}>View</a></td>
                          )}
                        </tr>
                      ))
                    ) : (
                      // Render month row without details
                      <tr key={eachDataIndex}>
                        <td>{eachData.month_name}</td>
                        <td colSpan="10" style={{ textAlign: "center" }}>No details available</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <Modal
              open={isModalOpen}
              title="Capacity Bookings Report Details"
              onCancel={onCloseModal}
              maskStyle={{ backdropFilter: "blur(4px)" }}
              footer={null}
              width={800}
            >
              <div className="table" style={{ marginTop: 10 }}>
              <table
                border="1"
                cellPadding="5"
                cellSpacing="0"
                style={{ width: "100%", borderColor: "#f0f0f0", fontSize: "12px" }}
              >
                <tbody>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Total Running Line</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.total_running_line ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Total Working Day</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.total_working_day ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Number of Operators+Ironmen+Helpers</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.number_of_operators ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 150, textAlign: 'left' }}>Average SAM</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.average_sam ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Efficiency (Plan)</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.plan_efficiency ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Capacity Minute @ 10 Hours</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.capacity_minute_at_10_hours ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Capacity Minute @ 11 Hours</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.capacity_minute_at_11_hours ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Plan Produce Minute</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.plan_produce_minute ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Output @ 10 hours (Plan Eff%)</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.output_10_hours ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Output @ 11 hours (Plan Eff%)</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.output_11_hours ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 150, textAlign: 'left' }}>Shortage Minutes @ 10 Hours</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.shortage_minutes_10_hours ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Shortage Minutes @ 11 Hours</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.shortage_minutes_11_hours ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Shortage QTY @ 10 Hours</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.shortage_qty_10_hours ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th style={{ width: 250, textAlign: 'left' }}>Shortage QTY @ 11 Hours</th>
                  <td style={{ textAlign: 'left' }}>{modalData?.shortage_qty_11_hours ?? 'N/A'}</td>
                </tr>
              </tbody>
              </table>
              </div>
            </Modal>
          </>
        }
      />
    </>
  );
};

export default CapacityBookingsReport;
