import React, { useEffect, useState } from "react";
import { Card, Select, Modal, InputNumber } from "antd";
import {
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { alertPop } from "../../../apiServices/common/helper";
// import { GET_BANK_ENDPOINT } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
const { confirm } = Modal;
const { Option } = Select;
export default function OrganizationBankAcc({
  // buyingOffice,
  selectedBuyerOffice,
  selectedOrgBankAccs,
  setSelectedOrgBankAccs,
  view,
  title,
  setOrgId,
}) {
  const [bankAccs, setBankAccs] = useState([]);
  const [selected, setSelected] = useState();

  const org_bank = `/api/organizations/view`;
  const getAllBnakList = async () => {
    if (selectedBuyerOffice) {
      let res = await getData(org_bank);

      if (res) {
        let masterData = res?.data?.data?.organization_bank_info;
        setBankAccs(masterData);
        setOrgId(masterData[0]?.organization_id);
      }
    }
  };

  const productSelect = (value) => {
    let index = selectedOrgBankAccs.findIndex(
      (item) => item?.id === value || item?.id === value
    );

    if (index === -1) {
      let bank = bankAccs.find((item) => {
        return (
          item?.account_info?.id === value || item?.account_info?.id === value
        );
      });
      setSelectedOrgBankAccs((oldArray) => [...oldArray, bank?.account_info]);
    } else {
      alertPop("warning", "Selected product exists on the list.");
    }
  };

  const removeSelectedProduct = (productId) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        let filter = selectedOrgBankAccs.filter(
          (item) => item.id !== productId
        );
        setSelectedOrgBankAccs([...filter]);
        setSelected(null);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    setBankAccs([]);
    getAllBnakList();
  }, [selectedBuyerOffice]);

  const required = <span style={{ color: "red" }}>*</span>;

  return (
    <Card className="custome-table" title={<span style={{fontSize: '14px'}}>{title}</span>}>
      <div className="root-table">
        <table className="pi-product-details-table">
          <thead>
            <tr>
              <th className="t-header bc-java ">Serial {required}</th>
              <th className="t-header bc-java ">Bank Name {required}</th>
              <th className="t-header bc-java ">Acc No. {required}</th>
              <th className="t-header bc-java ">Branch Name & Address</th>
              <th className="t-header bc-java ">SWIFT Code</th>
              <th className="t-header bc-java "></th>
            </tr>
          </thead>
          <tbody>
            {selectedOrgBankAccs?.length ? (
              <>
                {selectedOrgBankAccs.map((product, index) => (
                  <tr key={index}>
                    <td className="t-body">{index + 1}</td>
                    <td className="t-body">
                      {product?.name
                        ? product?.name
                        : product?.bank_info?.name || ""}
                    </td>
                    <td className="t-body">
                      {product?.account_no
                        ? product?.account_no
                        : product?.account_number || ""}
                    </td>
                    <td className="t-body">
                      {product?.address
                        ? product?.address
                        : product?.bank_info.name +
                          " - " +
                          product?.branch_info?.address}
                    </td>
                    <td className="t-body">
                      {product?.swift_code
                        ? product?.swift_code
                        : product?.bank_info?.swift_code}
                    </td>

                    {!view && (
                      <td
                        className="t-body"
                        onClick={() => {
                          removeSelectedProduct(product.id);
                        }}
                      >
                        <MinusCircleOutlined
                          className="cursore-pointer"
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </>
            ) : (
              ""
            )}

            {/* {selectedOrgBankAccs?.length ? (
              <tr>
                <td className="t-body bc-white" colSpan="3"></td>
                <td className="t-body">{showAllTotal()}</td>
              </tr>
            ) : (
              ""
            )} */}
          </tbody>
        </table>
      </div>

      <Select
        className="mt-2"
        showSearch
        dropdownStyle={{ minWidth: 350 }}
        placeholder="Select a Bank"
        optionFilterProp="children"
        onChange={productSelect}
        // onSearch={onSearch}
        // filterOption={(input, option) =>
        //   option.children.toLowerCase().includes(input.toLowerCase())
        // }
        size="small"
        style={{ width: "300px" }}
        // disabled={view}
        value={selected}
      >
        {bankAccs?.length ? (
          <>
            {bankAccs.map((item) => (
              <Option
                value={item?.account_info?.id}
                key={item?.account_info?.id}
              >
                {item?.account_info?.account_number +
                  " " +
                  item?.account_info?.branch_info?.name +
                  "-" +
                  item?.account_info?.bank_info.name}
              </Option>
            ))}
          </>
        ) : (
          ""
        )}
      </Select>
    </Card>
  );
}
