import {
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { isArrayAndHasValue, precisionValue } from "../../../utils/functions";
import TableData from "../OBTable/tableData";
import "./index.css";

const SortableMainTable = SortableContainer(({ children }) => {
  return (
    <table border="1" className="custome-table">
      {children}
    </table>
  );
});

const SortableSectionItem = SortableElement((props) => {
  const {
    key,
    rowPositionChange,
    view,
    edit,
    manpowerMode,
    sectionId,
    sectionName,
    sectionItems,
    removeRow,
    onChangeValue,
    onChangeSAMValue,
    onSortTableBody,
  } = props;

  return (
    <TableData
      key={key}
      sectionId={sectionId}
      sectionName={sectionName}
      sectionItems={sectionItems}
      removeRow={removeRow}
      view={view}
      edit={edit}
      manpowerMode={manpowerMode}
      onChangeValue={onChangeValue}
      onChangeSAMValue={onChangeSAMValue}
      rowPositionChange={rowPositionChange}
      onSortTableBody={onSortTableBody}
    />
  );
});

const OBTable = (props) => {
  // Props
  const {
    view,
    edit,
    manpowerMode,
    obData,
    sectionData,
    removeRow,
    onChangeValue,
    onChangeSAMValue,
    rowPositionChange,
    onSortTableBody,
    onSortMainTable,
  } = props;

  const calculateTotal = (fieldName) => {
    return sectionData.reduce((total, section) => {
      return (
        total +
        section.section_data.reduce((subTotal, item) => {
          const value = item[fieldName] || 0;
          return (
            subTotal + (typeof value === "number" ? value : parseFloat(value))
          );
        }, 0)
      );
    }, 0);
  };

  return (
    <div style={{ paddingTop: 15 }}>
      <SortableMainTable
        onSortEnd={onSortMainTable}
        axis="y"
        lockAxis="y"
        lockToContainerEdges={true}
        lockOffset={["30%", "50%"]}
        helperClass="helperContainerClass"
        useDragHandle={true}
      >
        <thead>
          <tr style={{position:"sticky", top:48, zIndex:3}}>
            <th colSpan={1} className="bc-orange text-center">
              OP
            </th>
            <th colSpan={2} className="bc-orange text-center">
              OPERATION
            </th>
            <th colSpan={3} className="bc-orange text-center">
              SMV
            </th>
            <th colSpan={3} className="bc-orange text-center">
              Update SMV
            </th>
            <th colSpan={1} className="bc-orange text-center">
              M/C
            </th>
            <th colSpan={3} className="bc-orange text-center">
              TGT
            </th>
            {(view || manpowerMode) && (
              <th colSpan={3} className="bc-orange text-center">
                Workers
              </th>
            )}
            {manpowerMode && (
              <th colSpan={3} className="bc-orange text-center">
                Update Workers (Manual)
              </th>
            )}
            <th colSpan={1} className="bc-orange text-center">
              Color
            </th>
            {!view && !manpowerMode && (
              <th colSpan={1} className="bc-orange text-center">
                Actions
              </th>
            )}
          </tr>
        </thead>
        {isArrayAndHasValue(sectionData) &&
          sectionData?.map((section, index) => {
            return (
              <SortableSectionItem
                key={section?.id || section?.id === 0 ? `item-${section?.id}`  : `item-${index}`}
                index={index}
                sectionId={section?.section_id}
                sectionName={section?.section_name}
                sectionItems={section?.section_data}
                removeRow={removeRow}
                view={view}
                edit={edit}
                manpowerMode={manpowerMode}
                onChangeValue={onChangeValue}
                onChangeSAMValue={onChangeSAMValue}
                rowPositionChange={rowPositionChange}
                onSortTableBody={onSortTableBody}
              />
            );
          })}

        {(view || manpowerMode) && (
          <tr>
            <td className="text-center"></td>
            <td className="text-center"></td>
            <td className="text-center">
              <strong>Subtotal</strong>
            </td>
            <td className="text-right">
              {precisionValue(obData?.total_smv_machine_sam, 2)}
            </td>
            <td className="text-right">
              {precisionValue(obData?.total_smv_helper_sam, 2)}
            </td>
            <td className="text-right">
              {precisionValue(obData?.total_smv_iron_sam, 2)}
            </td>
            <td className="text-right">
              {precisionValue(obData?.total_updated_smv_machine_sam,2)}
            </td>
            <td className="text-right">
              {precisionValue(obData?.total_updated_smv_helper_sam,2)}
            </td>
            <td className="text-right">
              {precisionValue(obData?.total_updated_smv_iron_sam,2)}
            </td>
            <td className="text-center"></td>
            <td className="text-right">
              {/* {precisionValue(obData?.total_target_machine_sam, 2)} */}
            </td>
            <td className="text-right">
              {/* {precisionValue(obData?.total_target_helper_sam, 2)} */}
            </td>
            <td className="text-right">
              {/* {precisionValue(obData?.total_target_iron_sam, 2)} */}
            </td>
            <td className="text-right">
              {precisionValue(obData?.total_worker_machine_sam, 2)}
            </td>
            <td className="text-right">
              {precisionValue(obData?.total_worker_helper_sam, 2)}
            </td>
            <td className="text-right">
              {precisionValue(obData?.total_worker_iron_sam, 2)}
            </td>

            {manpowerMode && (
              <>
                <td className="text-right">
                  {calculateTotal("manual_worker_machine_sam")}
                </td>
                <td className="text-right">
                  {calculateTotal("manual_worker_helper_sam")}
                </td>
                <td className="text-right">
                  {calculateTotal("manual_worker_iron_sam")}
                </td>
              </>
            )}
          </tr>
        )}
      </SortableMainTable>
    </div>
  );
};

export default OBTable;
