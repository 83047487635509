import {
  DeleteTwoTone,
  EditTwoTone,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Descriptions,
  Dropdown,
  Menu,
  Popconfirm,
  Popover,
  Typography,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  ITEM_CATEGORY_DELETE_PERMISSION,
  ITEM_CATEGORY_EDIT_PERMISSION,
} from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import CategoryListTable from "./ListTable";

const ListCategory = (props) => {
  const {
    itemCategoryList,
    pageSize,
    loading,
    totalCategoryList,
    onPageChange,
    pageView,
    page,
    onSelectCategoryForDelete,
    onSelectedCategoryToDelete,
    onOpenEditCategory,
    showFilter,
    setShowFilter,
  } = props;

  const { Title } = Typography;

  const handleDelete = (record) => {
    onSelectedCategoryToDelete(record);
  };

  const handleEdit = (event, record) => {
    onOpenEditCategory(event, record);
  };

  const tableColumns = [
    { title: "Item Category", dataIndex: "name", key: "name" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        return value === "active" ? "Active" : "Inactive";
      },
    },
    {
      title: "Attributes",
      dataIndex: "attribute_info",
      key: "attribute_info",
      width: "10%",
      align: "right",
      render: (_, record) => (
        // console.log();
        <Popover
          content={
            <Descriptions
              column={1}
              bordered
              style={{ overflow: "scroll", height: 200 }}
            >
              {record?.attribute_info?.map((records, index) => (
                <Descriptions.Item
                  key={index}
                  labelStyle={
                    records.title
                      ? { width: "30px" }
                      : {
                          backgroundColor: "white",
                          borderRight: "none",
                          alignContent: "center",
                        }
                  }
                >
                  {records.name}
                </Descriptions.Item>
              ))}
            </Descriptions>
          }
          title="Attribute Information"
        >
          <InfoCircleOutlined
            className="cursore-pointer"
            style={{ fontSize: "20px", color: "green" }}
          />
        </Popover>
      ),
    },
    {
      title: "Action",
      width: "10%",
      align: "right",
      key: "action",
      render: (_, record) => (
        <>
          {itemCategoryList.length >= 1 ? (
            <Dropdown.Button
              overlay={
                <Menu
                  items={[
                    {
                      label: (
                        <Popconfirm
                          title="Are you sure you want to delete?"
                          onConfirm={() => {
                            handleDelete(record);
                          }}
                        >
                          <span>
                            <DeleteTwoTone />
                          </span>
                          <a style={{ color: "red", marginLeft: 5 }}>Delete</a>
                        </Popconfirm>
                      ),
                      key: "1",
                      disabled: hasPermission([ITEM_CATEGORY_DELETE_PERMISSION])
                        ? false
                        : true,
                    },
                    {
                      label: (
                        <>
                          <span>
                            <EditTwoTone />
                          </span>
                          <a
                            type="button"
                            style={{ marginLeft: 5 }}
                            onClick={(event) => {
                              handleEdit(event, record);
                            }}
                          >
                            Edit
                          </a>
                        </>
                      ),
                      key: "2",
                      disabled: hasPermission([ITEM_CATEGORY_EDIT_PERMISSION])
                        ? false
                        : true,
                    },
                  ]}
                />
              }
            ></Dropdown.Button>
          ) : null}
        </>
      ),
    },
  ];
  const header = (
    <AppPageHeader
      extra={props.extra}
      title={<Title level={5}>Item Category</Title>}
    />
  );

  const content = (
    <CategoryListTable
      loading={loading}
      tableColumns={tableColumns}
      onChange={(newPage) => {
        onPageChange(newPage);
      }}
      dataSource={itemCategoryList}
      total={totalCategoryList}
      current={page}
      defaultCurrent={page}
      pageSize={pageSize}
      filter={true}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
    />
  );
  return (
    <>
      <AppContent header={header} content={content} />
    </>
  );
};

export default ListCategory;
