import { EditOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Table, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { STYLE_MERCHANDISING_LIST } from "../../apiServices/API_ENDPOINTS";
import { getData } from "../../apiServices/common";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import {
  STYLE_CREATE_PERMISSION,
  STYLE_EDIT_PERMISSION,
  STYLE_VIEW_PERMISSION,
} from "../../routes/permissions";
import { hasPermission } from "../../utils/functions";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";

export const Style = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState(null);

  // Route
  const navigate = useNavigate();

  // Antd Constants
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  const columns = [
    {
      title: "Style Number",
      dataIndex: "style_no",
      key: "id",
    },
    {
      title: "Buyer Name",
      dataIndex: ["buyer_info", "name"],
      key: "buyer_name",
    },
    {
      title: "Buying Office Name",
      dataIndex: ["buying_office_info", "name"],
      key: "buying_office_name",
    },
    {
      title: "Factory Name",
      dataIndex: ["factory_info", "name"],
      key: "style",
    },
    {
      title: "Address",
      dataIndex: ["factory_info", "address"],
      key: "style",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  // Function to get styles list
  const getAllPermitList = useCallback(
    async (passedObject) => {
      let filter = passedObject;
      const customQuery = `${STYLE_MERCHANDISING_LIST}/?page=${currentPage}`;
      let res = await getData(customQuery, false, filter);

      if (res) {
        let masterData = res?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      setLoading(false);
    },
    [currentPage],
  );

  useEffect(() => {
    setLoading(true);
    getAllPermitList(filterData);
  }, [currentPage, filterData, getAllPermitList]);

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/merchandising/update-style/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([STYLE_EDIT_PERMISSION]),
        },
        {
          label: (
            <Link to={`/merchandising/view-style/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([STYLE_VIEW_PERMISSION]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const onAddStyle = () => {
    navigate("/merchandising/add-style");
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                // refresh={refresh}
                // setRefresh={setRefresh}
                handleAddItemOpen={onAddStyle}
                disabled={!hasPermission([STYLE_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([STYLE_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Style</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={permitList}
              filterAPI={getAllPermitList}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="style"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={permitList}
                columns={columns}
                pagination={{
                  showSizeChanger: false,
                  current: currentPage,
                  total: totalData,
                  pageSize: rangeData,
                  onChange: (page) => paginate(page),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default Style;
