import { Alert, Card, Col, Empty, Form, Input, Row, Table } from "antd";
import { GET_APP_PRODUCTION_DETAILS } from "apiServices/API_ENDPOINTS";
import { postData } from "apiServices/common";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";

// Define columns for the table
const columns = [
  { title: "Hour", dataIndex: "hour", key: "hour" },
  {
    title: "Production (pc)",
    children: [
      { title: "Plan", dataIndex: "production_plan", key: "production_plan" },
      {
        title: "Actual",
        dataIndex: "production_actual",
        key: "production_actual",
      },
      {
        title: "Variance",
        dataIndex: "production_variance",
        key: "production_variance",
      },
    ],
  },
  {
    title: "Cumulative Production (pc)",
    children: [
      { title: "Plan", dataIndex: "cumulative_plan", key: "cumulative_plan" },
      {
        title: "Actual",
        dataIndex: "cumulative_actual",
        key: "cumulative_actual",
      },
      {
        title: "Variance",
        dataIndex: "cumulative_variance",
        key: "cumulative_variance",
      },
    ],
  },
  {
    title: "Efficiency (%)",
    children: [
      {
        title: "Plan",
        dataIndex: "efficiency_plan",
        key: "efficiency_plan",
        render: (value) => parseFloat(value).toFixed(2),
      },
      {
        title: "Actual",
        dataIndex: "efficiency_actual",
        key: "efficiency_actual",
        render: (value) => parseFloat(value).toFixed(2),
      },
      {
        title: "Variance",
        dataIndex: "efficiency_variance",
        key: "efficiency_variance",
        render: (value) => parseFloat(value).toFixed(2),
      },
    ],
  },
  {
    title: "Reject (pc)",
    dataIndex: "rejected_garments",
    key: "rejected_garments",
  },
  {
    title: "Reject (%)",
    dataIndex: "rejected_percentage",
    key: "rejected_percentage",
  },
];

const responseData = [
  {
    id: 3,
    user_id: 1,
    date: "2024-07-28 09:42:32",
    line_id: 20,
    buyer_id: 11,
    style_id: 692,
    po_map_id: null,
    line_chief_emp_id: "3507469",
    line_chief_user_id: 3848,
    ob_id: 20,
    hourly_target_qty: 200,
    total_sam: 34.4,
    efficiency: 45.2,
    no_of_workers: 1750,
    no_of_machines: 15.4,
    status: 1,
    created_at: "2024-07-27T06:26:12.000000Z",
    updated_at: "2024-07-28T06:44:40.000000Z",
    day: 1,
    sam: 34.4,
    table_data: [
      {
        hour: 1,
        time: "13:15-15:15",
        production_plan: 200,
        production_actual: "5",
        production_variance: 200,
        cumulative_plan: 200,
        cumulative_actual: 5,
        cumulative_variance: 200,
        efficiency_plan: 45.2,
        efficiency_actual: 45.425999999999995,
        efficiency_variance: 0.22599999999999199,
        rejected_garments: "0",
        rejected_percentage: 0,
      },
      {
        hour: 2,
        time: "17:00-18:00",
        production_plan: 200,
        production_actual: "1",
        production_variance: 200,
        cumulative_plan: 400,
        cumulative_actual: 6,
        cumulative_variance: 400,
        efficiency_plan: 45.2,
        efficiency_actual: 45.425999999999995,
        efficiency_variance: 0.22599999999999199,
        rejected_garments: "0",
        rejected_percentage: 0,
      },
    ],
    line_info: {
      id: 20,
      name: "Line-1",
      line_unique_id: null,
      status: "1",
      created_at: "2023-04-18T09:42:39.000000Z",
      updated_at: "2023-04-18T09:42:39.000000Z",
      capacity: 4000,
      deleted_at: null,
    },
    buyer_info: {
      id: 11,
      name: "Unlimited Clothing Limited",
      buyer_unique_id: "1",
      short_name: "Unlimited Clothing",
      address:
        "ROOMS 1318-20, 13/F, HOLLYWOOD PLAZA,610 NATHAN ROAD, MONGKOK,,KOWLOON, HONK KONG.",
      status: "1",
      created_at: "2023-07-23T05:14:22.000000Z",
      updated_at: "2023-07-23T05:14:22.000000Z",
      use_both_style_po: "No",
      deleted_at: null,
      merchandiser_for_procurement: null,
    },
    style_info: {
      id: 692,
      sales_contract_id: null,
      buying_office_id: 9,
      buyer_id: 11,
      brand_id: 35,
      style_no: "fishing shirt",
      received_date: "2024-05-16",
      description: "Long & short sleeve",
      created_at: "2024-05-16T07:09:09.000000Z",
      updated_at: "2024-05-16T07:09:09.000000Z",
      inquiry_id: null,
      garment_category_id: 8,
      garment_type_id: 5,
      garment_for_id: 1,
      status: "1",
      created_by: 16,
      deleted_at: null,
    },
  },
];

const AppProductDetails = ({ line_id, currentSlide, slideId }) => {
  const formattedTime = moment().format("h:mm A");
  const formattedDate = moment().format("DD-MMM-YYYY");

  const [records, setRecords] = useState([]);

  const fetchRecords = useCallback(
    async (filterValues) => {
      const query = `${GET_APP_PRODUCTION_DETAILS}`;

      const response = await postData(query, { line_id: line_id });

      if (response && response?.code === 200) {
        const data = response?.data;

        let resData = isArrayAndHasValue(data) ? [...data] : [];
        setRecords(resData);
      }
    },
    [line_id],
  );

  useEffect(() => {
    if (currentSlide === slideId && line_id) {
      fetchRecords();
    }
  }, [line_id, currentSlide, slideId, fetchRecords]);

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        marginBottom: "20px",
      }}
    >
      {records.length > 0 ? (
        records.map((item, index) => (
          <div key={index}>
            {index === 0 && (
              <Form layout="vertical" size="small">
                <Row gutter={[8, 2]} justify="end">
                  <Col xs={24} sm={24} md={24} lg={13} xl={18}>
                    <h2>Production Details</h2>
                  </Col>
                  <Col xs={24} sm={10} lg={4} xl={4} xxl={2}>
                    <Form.Item label="Date">
                      <Input width={80} value={formattedDate} disabled />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={8} lg={4} xl={4} xxl={2}>
                    <Form.Item label="Line">
                      <Input
                        width={80}
                        value={item?.line_info?.name}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={3} xl={4} xxl={2}>
                    <Form.Item label="Time">
                      <Input width={80} value={formattedTime} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}

            <Form layout="vertical">
              <Row gutter={[8, 2]} justify="end">
                <Col xs={24} sm={24} lg={8} xl={7} xxl={5}>
                  <Form.Item label="Buyer">
                    <Input value={item?.buyer_info?.name} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={6} xl={5} xxl={4}>
                  <Form.Item label="Style">
                    <Input value={item?.style_info?.style_no || ""} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={6} xl={5} xxl={4}>
                  <Form.Item label="Sub Style">
                    <Input
                      value={
                        item?.sub_style_info &&
                        item?.sub_style_info?.color_info &&
                        item?.sub_style_info?.sub_style_no
                          ? `${item?.sub_style_info?.color_info?.name} ${item?.sub_style_info?.sub_style_no}`
                          : ""
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={5} xl={4} xxl={3}>
                  <Form.Item label="SAM">
                    <Input value={Number(item?.sam).toFixed(2)} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={3} xl={2} xxl={2}>
                  <Form.Item label="Days">
                    <Input value={item?.day} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Table
              dataSource={item?.table_data}
              columns={columns}
              pagination={false}
              bordered
              rowKey="id"
              size="small"
            />
          </div>
        ))
      ) : (
        <Empty
          style={{
            width: "33%",
            margin: "auto",
            marginTop: "10%",
            marginBottom: "10%",
            height: "100%",
          }}
          description={
            <Alert
              message="No Data Found with selected line-id in Production Details"
              type="warning"
            />
          }
        />
      )}
    </Card>
  );
};

export default AppProductDetails;
