import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Divider,
  Input,
  Select,
  Space,
  Button,
  Form,
  Row,
  Col,
  Radio,
  Table,
  Dropdown,
  Menu,
  Popconfirm,
  Typography,
} from "antd";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onCreateBrand,
  onGetBankAccountList,
  onGetBrandList,
  onGetBuyerDetails,
} from "../../../redux/actions";
import { getData, postData } from "../../../apiServices/common";
import {
  BUYER_SUPPLIERS,
  BUYER_SUPPLIERS_ADD,
} from "apiServices/API_ENDPOINTS";

let index = 0;
const { Option } = Select;
const CreateBrandForm = (props) => {
  const dispatch = useDispatch();
  const { selectedBuyer } = props;
  const [form] = Form.useForm();
  const brandList = useSelector(({ brand }) => brand.brandList);
  const createdBrand = useSelector(({ brand }) => brand.createdBrand);
  const [name, setName] = useState("");
  const [brandId, setBrandId] = useState(null);
  const inputRef = useRef(null);
  const [brand, setBrand] = useState([...brandList]);
  const [refresh, setRefresh] = useState(0);
  const [buyerBrandRelationship, setBuyerBrandRelationship] = useState(null);
  const [createdBrandList, setCreatedBrandList] = useState([]);

  // Supplier state
  const [supplierList, setSupplierList] = useState([]);
  const [supplierName, setSupplierName] = useState("");
  const [supplierShortName, setSupplierShortName] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [supplierPhone, setSupplierPhone] = useState("");
  const [supplierId, setSupplierId] = useState(null);
  const supplierInputRef = useRef(null);
  const [selectedSupplierValue, setSelectedSupplierValue] = useState(null);

  useEffect(() => {
    if (selectedBuyer) {
      const buyerInfo = selectedBuyer?.brand_info?.map((brand, idx) => {
        return {
          brand_name: brand?.brand_info?.name,
          brand_id: brand?.brand_info?.id,
        };
      });
      setBuyerBrandRelationship(buyerInfo);
      setCreatedBrandList(buyerInfo);
      form.setFieldValue("name", buyerInfo?.[0]?.brand_name);

      // If the buyer has a supplier, set it as selected
      if (
        selectedBuyer.buyer_supplier_id &&
        selectedBuyer.buyer_supplier_info
      ) {
        setSelectedSupplierValue(selectedBuyer.buyer_supplier_id);
        form.setFieldsValue({
          supplier_name: selectedBuyer.buyer_supplier_id,
        });
        localStorage.setItem(
          "buyerSupplierId",
          selectedBuyer.buyer_supplier_id,
        );
      }
    }

    // Fetch supplier list
    fetchSuppliers();
  }, [selectedBuyer, form]);

  const fetchSuppliers = async () => {
    try {
      const response = await getData(BUYER_SUPPLIERS);
      if (response) {
        setSupplierList(response.data.data.data || []);
      }
    } catch (error) {}
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onSupplierNameChange = (event) => {
    setSupplierName(event.target.value);
  };

  const onSupplierShortNameChange = (event) => {
    setSupplierShortName(event.target.value);
  };

  const onSupplierAddressChange = (event) => {
    setSupplierAddress(event.target.value);
  };

  const onSupplierPhoneChange = (event) => {
    setSupplierPhone(event.target.value);
  };

  const onFinish = (values, label) => {
    console.log("🚀 ~ onFinish ~ values:", values);
    if (brandId) {
      values["country_name"] = "BD";
      values["name"] = name;
      values["status"] = "1";
      const createdBrand = {
        brand_name: name,
        brand_id: brandId,
      };
      setCreatedBrandList((prev) => [...prev, createdBrand]);
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const handleDelete = (record, index) => {
    const newData = createdBrandList.filter(
      (item) => item.brand_id !== record.brand_id,
    );
    setCreatedBrandList(newData);
  };

  const addItem = (e) => {
    e.preventDefault();
    const formValues = {};
    formValues["name"] = name;
    formValues["status"] = "1";
    formValues["country_name"] = "BD";
    dispatch(onCreateBrand(formValues));
    form.resetFields();
    refetch();
    setBrand([
      ...brand,
      {
        id: createdBrand?.id,
        name: name,
        created_at: null,
        updated_at: null,
      } || `New item ${index++}`,
    ]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const addSupplier = async (e) => {
    e.preventDefault();
    if (!supplierName) return;

    try {
      const response = await postData(BUYER_SUPPLIERS_ADD, {
        name: supplierName,
        short_name: supplierShortName,
        address: supplierAddress,
        phone: supplierPhone,
        status: true,
      });

      if (response && response?.code === 200) {
        // Reset all supplier fields
        setSupplierName("");
        setSupplierShortName("");
        setSupplierAddress("");
        setSupplierPhone("");

        await fetchSuppliers();
      }

      setTimeout(() => {
        supplierInputRef.current?.focus();
      }, 0);
    } catch (error) {}
  };

  useEffect(() => {
    form.resetFields();
  }, [refresh, createdBrand]);

  const onBrandChange = (value, options) => {
    const brandFormat = {};
    brandFormat["brand_name"] = options?.name;
    brandFormat["brand_id"] = value;
    setName(options.label);
    setBrandId(value);
  };

  const onSupplierChange = (value, options) => {
    setSupplierName(options.label);
    setSupplierId(value);
    setSelectedSupplierValue(value);

    form.setFieldsValue({
      supplier_name: value,
    });

    // Store the single supplier ID in localStorage
    localStorage.setItem("buyerSupplierId", value);
  };

  localStorage.setItem(
    "brandIds",
    JSON.stringify(createdBrandList?.map((brand) => brand?.brand_id)),
  );

  const tableColumns = [
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
      width: "30%",
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record, index) => (
        <>
          {createdBrandList && (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                handleDelete(record, index);
              }}
            >
              <a style={{ color: "red" }} type="button">
                <span>
                  <MinusCircleOutlined />
                </span>
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: 5 }}>
      {/* Supplier Section */}
      <Typography.Title level={5}>Add Supplier</Typography.Title>

      <Form
        form={form}
        layout="vertical"
        name="supplier_form"
        style={{ marginBottom: 20, marginTop: 10 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Buyer Supplier" name="supplier_name">
              <Select
                style={{ width: "100%" }}
                onChange={onSupplierChange}
                showSearch
                optionFilterProp="children"
                value={selectedSupplierValue}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Buyer Supplier"
                size="small"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "8px" }}>
                      <Row gutter={8} style={{ width: "100%" }}>
                        <Col span={24} style={{ marginBottom: 8 }}>
                          <Space>
                            <Input
                              placeholder="Name"
                              ref={supplierInputRef}
                              value={supplierName}
                              onChange={onSupplierNameChange}
                              size="small"
                              style={{ width: 120 }}
                            />
                            <Input
                              placeholder="Short Name"
                              value={supplierShortName}
                              onChange={onSupplierShortNameChange}
                              size="small"
                              style={{ width: 120 }}
                            />
                            <Input
                              placeholder="Address"
                              value={supplierAddress}
                              onChange={onSupplierAddressChange}
                              size="small"
                              style={{ width: 120 }}
                            />
                            <Input
                              placeholder="Phone"
                              value={supplierPhone}
                              onChange={onSupplierPhoneChange}
                              size="small"
                              style={{ width: 120 }}
                            />
                            <Button
                              htmlType="button"
                              icon={<PlusOutlined />}
                              onClick={addSupplier}
                              size="small"
                            >
                              Add
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Space>
                  </>
                )}
              >
                {supplierList?.length > 0 &&
                  supplierList.map((supplier, idx) => (
                    <Option
                      key={idx}
                      value={supplier?.id}
                      name={supplier?.name}
                      id={supplier?.id}
                      label={supplier?.name}
                    >
                      {supplier?.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {/* Brand Section */}
      <Typography.Title level={5}>Add Brand</Typography.Title>

      <Form
        form={form}
        initialValues={{
          name:
            buyerBrandRelationship?.length === 1 &&
            buyerBrandRelationship?.[0]?.brand_name,
        }}
        layout="inline"
        name="form_in_modal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Form.Item label="Brand Name">
          <Form.Item
            noStyle
            name="name"
            required
            rules={[
              {
                required: true,
                message: "Brand name is required",
              },
            ]}
          >
            <Select
              required
              onChange={onBrandChange}
              style={{
                width: "200px",
              }}
              onSearch={(value) => dispatch(onGetBrandList(1, 10, value))}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder="Brand Name"
              size="small"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "0 8px 4px",
                    }}
                  >
                    <Input
                      placeholder="brand name"
                      ref={inputRef}
                      // value={name}
                      onChange={onNameChange}
                      size="small"
                    />
                    <Button
                      htmlType="submit"
                      icon={<PlusOutlined />}
                      onClick={addItem}
                      size="small"
                    >
                      Add
                    </Button>
                  </Space>
                </>
              )}
            >
              {brandList?.length > 0 &&
                brandList?.map((brand, idx) => (
                  <Option
                    key={idx}
                    value={brand?.id}
                    name={brand?.name}
                    id={brand?.id}
                    label={brand?.name}
                  >
                    {brand?.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            size="small"
            type="primary"
            style={{ float: "right" }}
          >
            Add
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={tableColumns}
        dataSource={createdBrandList}
        pagination={false}
      />
    </div>
  );
};
export default CreateBrandForm;
