import { Card, Col, Form, Input, Row, Select, Switch, message } from "antd";
import HandleNotification from "common/Notification";
import { putData } from "components/BOM/AddOrUpdateStyle";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  TNA_TEMPLATE_BASE,
} from "../../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../../apiServices/common";
import { isArrayAndHasValue } from "../../../../../utils/functions";
import ItemDetails from "../ItemDetails";

const calculationMethods = [
  {
    id: 0,
    value: "Backward from PCD",
  },
  {
    id: 1,
    value: "Backward from Shipment",
  },
  {
    id: 2,
    value: "Forward from Sales Contract",
  },
];

const AddOrUpdateForm = forwardRef((props, ref) => {
  // Props
  const { view, isEdit } = props;

  // States
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [itemDetailsList, setItemDetailsList] = useState([]);
  const [previousTemplateList, setPreviousTemplateList] = useState([]);

  // Router
  const { templateID } = useParams();
  const navigate = useNavigate();

  // Antd
  const { Option } = Select;
  const [form] = Form.useForm();
  const is_common_watch = Form.useWatch("is_common", form);

  const getPreviousTemplateList = async (filterValues) => {
    const { title } = filterValues || {};

    const query = `${TNA_TEMPLATE_BASE}`;
    const bodyData = {
      ...(title && { title: title }),
    };

    const response = await getData(query, false, bodyData);
    if (response && response?.status === 200) {
      setPreviousTemplateList(response?.data?.data);
    } else {
      message.error("Error getting previous template data");
    }
  };

  const getPrevTemplateData = useCallback(
    async (value) => {
      const endpoint = `${TNA_TEMPLATE_BASE}/${value}`;
      const response = await getData(endpoint);

      if (response && response?.data?.code === 200) {
        const masterData = response?.data?.data;

        // Ensure the selected buying office is included in the options
        if (
          !buyingOffice.some(
            (office) => office.id === masterData?.buying_office?.id,
          )
        ) {
          setBuyingOffice([...buyingOffice, masterData?.buying_office]);
        }

        // Ensure the selected buyer is included in the options
        if (!buyerList.some((b) => b.id === masterData?.buyer?.id)) {
          setBuyerList([...buyerList, masterData?.buyer]);
        }

        // Update the form with data
        form.setFieldsValue({
          title: masterData?.title,
          description: masterData?.description,
          calculation_method: masterData?.calculation_method,
          buying_office_id: masterData?.buying_office?.id,
          buyer_id: masterData?.buyer?.id,
          is_common: masterData?.is_common,
        });

        // Manipulate and set the item details list
        const templateTaskData = isArrayAndHasValue(masterData?.template_tasks)
          ? masterData?.template_tasks
          : [];
        const updatedTemplateTaskData =
          isArrayAndHasValue(masterData?.template_tasks) &&
          templateTaskData.map((item) => {
            return {
              days: item?.days,
              is_add: true,
              is_pcd: item?.is_pcd === 1 ? "Yes" : "No",
              is_ship: item?.is_shipment === 1 ? "Yes" : "No",
              po_wise: item?.relation_with === "PO" ? "Yes" : "No",
              responsible_person_id: item?.responsible_person_id,
              responsible_person_name: item?.responsible_person_name,
              tna_task_name: item?.title,
              tna_task_id: item?.tna_task_id,
              // "tna_process_id": 2,
              tna_process_title: item?.process,
              tna_department_name: item?.department,
              style_wise: item?.relation_with === "Style" ? "Yes" : "No",
              task_dependencies_obj: item?.task_dependencies,
              task_dependencies: item?.task_dependencies?.map(
                (item) => item?.id,
              ),
              task_dependencies_name: item?.task_dependencies?.map(
                (item) => item?.title,
              ),
            };
          });
        setItemDetailsList(updatedTemplateTaskData);
      }
    },
    [form],
  );

  useEffect(() => {
    if (isEdit && templateID) {
      getPrevTemplateData(templateID);
    }
  }, [isEdit, templateID, getPrevTemplateData]);

  useImperativeHandle(ref, () => ({
    submit() {
      form
        .validateFields()
        .then(async (values) => {
          const bodyData = {
            buyer_id: values?.buyer_id,
            buying_office_id: values?.buying_office_id,
            title: values?.title,
            description: values?.description,
            calculation_method: values?.calculation_method,
            is_common: values?.is_common ? 1 : 0,
            is_active: 1,
            template_tasks: itemDetailsList?.map((item) => {
              return {
                tna_task_id: item?.tna_task_id,
                responsible_person_id: item?.responsible_person_id,
                days: item?.days,
                task_dependencies: item?.task_dependencies,
              };
            }),
          };

          if (!isEdit) {
            let response = await postData(TNA_TEMPLATE_BASE, bodyData);
            if (response && response?.code === 200) {
              HandleNotification(
                "success",
                "bottomRight",
                "Template Created Successfully",
                null,
              );
              const editID = response?.data?.id;
              navigate(`/tanda/update-template/${editID}`);
            } else {
              HandleNotification(
                "error",
                "bottomRight",
                "Error Creating Template",
                null,
              );
            }
          } else {
            const endpoint = `${TNA_TEMPLATE_BASE}/${templateID}`;
            let response = await putData(endpoint, bodyData);
            if (response && response?.data?.code === 200) {
              HandleNotification(
                "success",
                "bottomRight",
                "Template Updated Successfully",
                null,
              );
            } else {
              HandleNotification(
                "error",
                "bottomRight",
                "Error Updating Template",
                null,
              );
            }
          }
        })
        .catch((errorInfo) => {
          HandleNotification(
            "error",
            "bottomRight",
            "Error! Please check the form and try again.",
            null,
          );
        });
    },
    discart() {
      form.resetFields();
    },
  }));

  const getBuyingOfficeList = async (filterValues) => {
    const query = `${GET_BUYING_OFFICE_ENDPOINT}`;
    const bodyData = {
      per_page: 10,
      name: filterValues?.name || "",
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOffice(response?.data?.data?.data || []);
    }
  };

  const getBuyerList = async (filterValues) => {
    const query = `${GET_BUYER_ENDPOINT}`;
    const bodyData = {
      per_page: 10,
      name: filterValues?.name || "",
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyerList(response?.data?.data?.data || []);
    }
  };

  useEffect(() => {
    getBuyingOfficeList();
    getPreviousTemplateList();
    getBuyerList();
  }, []);

  return (
    <div className="containt-body">
      <Form layout="vertical" form={form} name="control-hooks">
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Template Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input Template Title",
                  },
                ]}
                disabled={view}
              >
                <Input
                  className="w-100"
                  size="small"
                  placeholder="Template Title"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: false,
                    message: "Please input Description",
                  },
                ]}
                disabled={view}
              >
                <Input
                  className="w-100"
                  size="small"
                  placeholder="Description"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="copy_from_prev_template"
                label="Copy from Prev Template"
              >
                <Select
                  dropdownStyle={{ minWidth: 250 }}
                  showSearch
                  onFocus={(e) => {}}
                  placeholder="Copy from Prev Template"
                  optionFilterProp="children"
                  onSelect={(value) => {
                    getPrevTemplateData(value);
                  }}
                  style={{ width: "100%" }}
                  size="small"
                  disabled={view}
                  onSearch={(value) =>
                    getPreviousTemplateList({ title: value })
                  }
                >
                  {previousTemplateList?.length &&
                    previousTemplateList.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item?.title}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Calculation Method"
                name="calculation_method"
                rules={[
                  {
                    required: true,
                    message: "Please Select Calculation Method",
                  },
                ]}
                initialValue="Backward from PCD"
              >
                <Select
                  showSearch
                  placeholder="Select a Calculation Method"
                  optionFilterProp="children"
                  disabled
                  size="small"
                  onSearch={(value) => getBuyingOfficeList({ name: value })}
                  allowClear
                  onClear={() => getBuyingOfficeList()}
                >
                  {isArrayAndHasValue(calculationMethods) &&
                    calculationMethods.map((item) => (
                      <Option value={item.value} key={item.id}>
                        {item?.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={is_common_watch ? 0 : 4}>
              <Form.Item
                label="Buyer"
                name="buyer_id"
                rules={[
                  {
                    required: is_common_watch ? false : true,
                    message: "Please Select Buyer",
                  },
                ]}
                hidden={is_common_watch}
              >
                <Select
                  showSearch
                  placeholder="Select a Buyer"
                  optionFilterProp="children"
                  disabled={view}
                  size="small"
                  onSearch={(value) => getBuyerList({ name: value })}
                  allowClear
                  onClear={() => getBuyerList()}
                >
                  {isArrayAndHasValue(buyerList) &&
                    buyerList?.map((sup) => (
                      <Option value={sup.id} key={sup.id}>
                        {sup.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={is_common_watch ? 0 : 4}>
              <Form.Item name="buying_office_id" hidden />
              <Form.Item
                label="Buying Office"
                name="buying_office_id"
                rules={[
                  {
                    required: is_common_watch ? false : true,
                    message: "Please Select Buying office!",
                  },
                ]}
                hidden={is_common_watch}
              >
                <Select
                  showSearch
                  placeholder="Select a Buying office"
                  optionFilterProp="children"
                  disabled={view}
                  size="small"
                  onSearch={(value) => getBuyingOfficeList({ name: value })}
                  allowClear
                  onClear={() => getBuyingOfficeList()}
                >
                  {buyingOffice?.length &&
                    buyingOffice.map((sup) => (
                      <Option value={sup.id} key={sup.id}>
                        {sup.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="is_common"
                label="Is Common"
                required
                className="collection-create-form_last-form-item"
                valuePropName="checked"
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <ItemDetails
          view={view}
          itemDetailsList={itemDetailsList}
          setItemDetailsList={setItemDetailsList}
          form={form}
        />
      </Form>
    </div>
  );
});

export default AddOrUpdateForm;
