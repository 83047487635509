import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, InputNumber, Select } from "antd";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";
import { SPECIAL_PROCESS_LIST, disableSelectedProcess } from "../../helper";

const SpecialProcessTable = (props) => {
  // Props
  const { specialProcessList, setSpecialProcessList, view, title } = props;

  const addNewProcess = () => {
    const specialProcessListCopy = isArrayAndHasValue(specialProcessList)
      ? [...specialProcessList]
      : [];

    specialProcessListCopy.push({
      unique_id: isArrayAndHasValue(specialProcessListCopy)
        ? specialProcessListCopy[specialProcessListCopy.length - 1].unique_id +
          1
        : 0,
      is_delete: 0,
    });

    setSpecialProcessList(specialProcessListCopy);
  };

  const removeSingleProcess = (unique_id) => {
    if (!isArrayAndHasValue(specialProcessList)) {
      return;
    }

    const specialProcessListCopy = [...specialProcessList];
    const foundProcess = specialProcessListCopy?.find(
      (item) => item?.unique_id === unique_id,
    );
    foundProcess["is_delete"] = 1;
    setSpecialProcessList(specialProcessListCopy);
  };

  const onChangeValue = (value, unique_id, context) => {
    const specialProcessListCopy = [...specialProcessList];
    const foundProcess = specialProcessListCopy?.find(
      (item) => item?.unique_id === unique_id,
    );
    foundProcess[context] = value;
    setSpecialProcessList(specialProcessListCopy);
  };

  return (
    <Card
      className="custome-table"
      title={
        <span style={{ fontSize: "14px" }}>{title || "Special Process"}</span>
      }
    >
      <div className="root-table">
        <table className="pi-product-details-table">
          <thead>
            <tr>
              <th className="t-header bc-java ">Process</th>
              <th className="t-header bc-java ">Rent Per Garment</th>
              <th hidden={view}>Action</th>
            </tr>
          </thead>
          <tbody>
            {isArrayAndHasValue(specialProcessList) && (
              <>
                {specialProcessList
                  ?.filter((item) => !item?.is_delete)
                  ?.map((item, index) => (
                    <tr key={item.unique_id}>
                      <td className="t-body">
                        <Select
                          showSearch
                          placeholder="Select a Process"
                          optionFilterProp="children"
                          size="small"
                          style={{ width: "100%" }}
                          onSelect={(value) => {
                            onChangeValue(value, item?.unique_id, "process");
                            disableSelectedProcess(value);
                          }}
                          value={item?.process}
                          disabled={view}
                        >
                          {isArrayAndHasValue(SPECIAL_PROCESS_LIST) &&
                            SPECIAL_PROCESS_LIST.map((item) => (
                              <Select.Option
                                value={item.value}
                                key={item.id}
                                disabled={item?.isDisabled}
                              >
                                {item?.label || "N/A"}
                              </Select.Option>
                            ))}
                        </Select>
                      </td>
                      <td className="t-body">
                        <InputNumber
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            onChangeValue(
                              value,
                              item?.unique_id,
                              "rent_per_garment",
                            )
                          }
                          size="small"
                          value={item?.rent_per_garment}
                          disabled={view}
                          formatter={(value) => commaSeparateNumber(value)}
                        />
                      </td>

                      <td
                        className="t-body"
                        onClick={() => removeSingleProcess(item?.unique_id)}
                        hidden={view}
                      >
                        <MinusCircleOutlined
                          className="cursore-pointer"
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      <Button
        type="primary"
        className="mt-4"
        icon={<PlusOutlined />}
        size="small"
        onClick={addNewProcess}
        hidden={view}
      >
        Add Process
      </Button>
    </Card>
  );
};

export default SpecialProcessTable;
