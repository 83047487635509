import { EXPORT_FRIEGHT_ITEM, EXPORT_FRIEGHT_TYPES_REMOVE, EXPORT_FRIEGHT_TYPE_SAVE } from "redux/ActionTypes";

export const onCreateNewFreightTypes = (data) => {
    if(data){
        return (dispatch) => {
            dispatch({
                type:EXPORT_FRIEGHT_ITEM,
                payload:[...data]
            })
        }
    }
}

export const onSaveFreightTypes = (data) => {
    if(data){
        return (dispatch) => {
            dispatch({
                type:EXPORT_FRIEGHT_TYPE_SAVE,
                payload:[...data]
            })
        }
    }
}

export const onRemoveFreightTypes = (data) => {
    if(data){
        return (dispatch) => {
            dispatch({
                type:EXPORT_FRIEGHT_TYPES_REMOVE,
                payload:data
            })
        }
    }
}
