import { Col, Input, Form, Row, Select, Switch } from "antd";

const PackingReqForm = (props) => {
  // Props
  const { poList, onChangeInternalPO, onChangeOriginalPO } = props;
  // Antd
  const { Option } = Select;

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Original PO"
            name="original_po"
            rules={[
              {
                required: true,
                message: `Original PO is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeOriginalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
            >
              {poList &&
                poList?.map((po, idx) => (
                  <Option key={idx} value={po?.original_po}>
                    {po?.original_po}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Internal PO"
            name="internal_po"
            rules={[
              {
                message: `Internal PO is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeInternalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
            >
              {poList &&
                poList?.map((po, idx) => (
                  <Option key={idx} value={po?.internal_po}>
                    {po?.internal_po}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={0}>
          <Form.Item name="buying_office_id" />
        </Col>
        <Col span={4}>
          <Form.Item
            name="buying_office_name"
            label="Buying Office"
            rules={[
              {
                required: true,
                message: `Buying Office is required `,
              },
            ]}
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={0}>
          <Form.Item name="buyer_id" />
        </Col>
        <Col span={4}>
          <Form.Item
            name="buyer_name"
            label="Buyer"
            rules={[
              {
                required: true,
                message: `Buyer is required `,
              },
            ]}
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={0}>
          <Form.Item name="sales_contract_id" />
        </Col>
        <Col span={0}>
          <Form.Item name="po_map_id" />
        </Col>
        <Col span={4}>
          <Form.Item name="reference_no" label="Sales Contract">
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="status"
            placeholder="Status"
            label="Status"
            rules={[
              {
                required: true,
                message: "Status Required",
              },
            ]}
            valuePropName="checked"
            initialValue={false}
          >
            <Switch
              checkedChildren="Approve"
              unCheckedChildren="Pending"
              size="small"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="total_order_quantity"
            label="PO Order Qty"
            // rules={[
            //   {
            //     message: `PO Order Quantity`,
            //   },
            // ]}
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="total_packing_requirements"
            label="PO Packing Req Qty"
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PackingReqForm;
