import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "../../../../apiServices/common";
import AddPiForm from "./AddPiForm";
import { alertPop } from "../../../../apiServices/common/helper";

export default function EditExportSalesContract(props) {
  // Props
  const { width } = props;

  // States
  const [loading, setLoading] = useState(false);
  const [permitInfo, setPermitInfo] = useState();
  const [piStatus, setPiStatus] = useState();

  // Router
  let { importId } = useParams();
  const navigate = useNavigate();

  // Others
  const propertyFormRef = useRef(null);
  const [form] = Form.useForm();

  const addProduct = () => {
    form.submit();
  };

  const edit_url = `/api/accounting/import-pi/edit/${importId}`;
  const getPIInfo = async () => {
    let res = await getData(edit_url);

    if (res && res?.data?.code == 200) {
      let masterData = res?.data?.data[0];
      setPermitInfo(masterData);
      setPiStatus(masterData?.sales_contract_commercial?.status)
    } else  {
      alertPop("error","You cannot update this PI now");
      navigate("/import/pi");
    }
  };

  useEffect(() => {
    getPIInfo();
  }, []);


  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Update Proforma Invoice"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} disabled>
                {piStatus}
              </Button>,
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
                loading={loading}
              >
                Update
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddPiForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        form={form}
        setLoading={setLoading}
      />
    </>
  );
}
