import React from "react";
import {Button, Dropdown, Menu, PageHeader, Popconfirm, Space, Popover, Descriptions, Image, Typography} from "antd";
import AppPageHeader from "../../../common/PageHeader";
import AppContent from "../../../common/AppContent";
import ItemListTable from "./ListTable";
import {CopyOutlined, CopyTwoTone, DeleteTwoTone, EditTwoTone, InfoCircleOutlined} from "@ant-design/icons";
import { hasPermission } from "../../../utils/functions";
import {
    ITEM_CREATE_PERMISSION,
    ITEM_EDIT_PERMISSION,
    ITEM_DELETE_PERMISSION,
} from '../../../routes/permissions';

const ListItem = (props) => {
    const {
        itemList,
        pageSize,
        totalItemList,
        onPageChange,
        pageView,
        page,
        onSelectItemForDelete,
        onSelectedItemToDelete,
        setAttributeInfo,
        onOpenEditItem,
        copyItem,
        showFilter,
        setShowFilter,
    } = props;
    const { Title } = Typography;
    
    const handleDelete = (record) => {
        onSelectedItemToDelete(record);
    };

    const handleEdit = (event,record) => {

        const attribute_infos = record?.category_info?.attribute_info;
        const attribute_value_infos = record?.attribute_value_info;
        let attribute_info_array = [];

        attribute_infos.forEach((at_info, index) => {
            const matchedItem = attribute_value_infos.find(atv_info => atv_info.attribute_id === at_info.id);

            if(matchedItem) {
                // Push info of both arrays if matches
                attribute_info_array.push({
                    // ...at_info,
                    // ...matchedItem,
                });
            }
            else {
                // Push info of only attribute_infos array items if not matches
                record?.attribute_value_info.push({
                    id: "",
                    item_id: record?.id,
                    attribute_info: {...at_info},
                    attribute_id: at_info?.id,
                    attribute_name: at_info?.name,
                    // ...at_info,
                    // ...matchedItem,
                });
            }
        });
        console.log("attribute_info_array", record?.attribute_value_info);

        onOpenEditItem(event, record);
    };

    const handleCopy  = (record) => {
        copyItem(record);
    }

    const tableColumns = [
      {
        title: "Item Category",
        dataIndex: ["category_info", "name"],
        key: ["category_info", "name"],
      },
      { title: "Item", dataIndex: "name", key: "name" },
      {
        title: "Description",
        dataIndex: "attribute_value_info",
        key: "attribute_value_info",
        width: "10%",
        render: (_, record) => (
          // console.log();
          <Popover
            content={
              <Descriptions
                column={1}
                bordered
                style={{ overflow: "scroll", height: 200 }}
              >
                {record?.attribute_value_info?.map((records, index) => (
                  <Descriptions.Item
                    key={index}
                    label={
                      records.attribute_info
                        ? records.attribute_info.name
                        : records.attribute_info.name
                    }
                    labelStyle={
                      records.title
                        ? { width: "30px" }
                        : {
                            backgroundColor: "white",
                            borderRight: "none",
                            alignContent: "center",
                          }
                    }
                  >
                    {records.attribute_info.name && records.attribute_value}
                  </Descriptions.Item>
                ))}
                {record?.image_path ? (
                  <Descriptions.Item
                    label="Image"
                    labelStyle={{
                      backgroundColor: "white",
                      borderRight: "none",
                      alignContent: "center",
                    }}
                  >
                    <Image.PreviewGroup>
                      <Image
                        width={50}
                        height={50}
                        path={record?.image_path}
                        src={`${process.env.REACT_APP_BASE_URL}/${record?.image_path}`}
                      />
                    </Image.PreviewGroup>
                  </Descriptions.Item>
                ) : null}
              </Descriptions>
            }
            title="Attribute Values"
          >
            <InfoCircleOutlined
              className="cursore-pointer"
              style={{ fontSize: "20px", color: "green" }}
            />
          </Popover>
        ),
      },
      {
        title: "Action",
        key: "action",
        width: "10%",
        render: (_, record) => (
          <>
            {itemList.length >= 1 ? (
              <Dropdown.Button
                overlay={
                  <Menu
                    items={[
                      {
                        label: (
                          <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={() => {
                              handleDelete(record);
                            }}
                          >
                            <span>
                              <DeleteTwoTone />
                            </span>
                            <a style={{ color: "red", marginLeft: 5 }}>
                              Delete
                            </a>
                          </Popconfirm>
                        ),
                        key: "1",
                        disabled: !hasPermission([ITEM_DELETE_PERMISSION]),
                      },
                      {
                        label: (
                          <>
                            <span>
                              <EditTwoTone />
                            </span>
                            <a
                              type="button"
                              style={{ marginLeft: 5 }}
                              onClick={(event) => {
                                console.log("record ", record);

                                handleEdit(event, record);
                              }}
                            >
                              Edit
                            </a>
                          </>
                        ),
                        key: "2",
                        disabled: !hasPermission([ITEM_EDIT_PERMISSION]),
                      },
                      {
                        label: (
                          <Popconfirm
                            title="Are you sure you want to copy?"
                            onConfirm={() => {
                              handleCopy(record);
                            }}
                          >
                            <span>
                              <CopyTwoTone />
                            </span>
                            <span style={{ marginLeft: 5 }}>Copy</span>
                          </Popconfirm>
                        ),
                        key: "1",
                        disabled: !hasPermission([ITEM_CREATE_PERMISSION]),
                      },
                    ]}
                  />
                }
              ></Dropdown.Button>
            ) : null}
          </>
        ),
      },
    ];
    const header = <AppPageHeader extra={props.extra} title={<Title level={5}>Item Identifier</Title>}/>
    const content =
        (<ItemListTable
            tableColumns={tableColumns}
            onChange={(newPage) => {
                onPageChange(newPage)
            }}
            dataSource={itemList}
            total={totalItemList}
            current={page}
            defaultCurrent={page}
            pageSize={pageSize}
            filter={true}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
        />)
    return (
        <>
            <AppContent header={header} content={content} />
        </>

    )
}

export default ListItem;