import { Form, Typography } from "antd";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import { NO_PERMISSION_REQUIRED } from "routes/permissions";
import { hasPermission } from "utils/functions";
import ImportInvoiceForm from "../Form";

// Antd Constants
const { Title } = Typography;
export default function ImportInvoiceExpenseUpdate() {
  const [form] = Form.useForm();

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                hideFilterButton
                addButtonText={
                  hasPermission([NO_PERMISSION_REQUIRED])
                    ? "Update"
                    : "No Permission"
                }
                handleAddItemOpen={() => form.submit()}
              />
            }
            title={<Title level={5}>Import Invoice Expense Edit</Title>}
          />
        }
        content={
          <>
            <ImportInvoiceForm form={form} mode="edit" />
          </>
        }
      />
    </>
  );
}
