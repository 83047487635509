import {
    GET_STYLE_LIST,
    GET_STYLE_DETAIL,
    CLEAR_STYLE_DATA,
} from '../ActionTypes';

const initialState = {
    styleList: [],
    totalStyle: null,
    selectedStyle: null,
    selectedStyleDetails: null,
    createdStyle: null,
    selectedStyleAccountInfo: null,
    pageSize: 10,
    page:1,
};

const StyleReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_STYLE_LIST:
            return {
                ...state,
                styleList: action.payload.data,
                totalStyle: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };
        case GET_STYLE_DETAIL:
            return {
                ...state,
                selectedStyle: action.payload,
                selectedStyleAccountInfo: action.payload,
                selectedStyleDetails: action.payload,
            };
        case CLEAR_STYLE_DATA:
            return {
                ...state,
                styleList: [],
                totalStyle: null,
                selectedStyle: null,
                selectedStyleDetails: null,
                createdStyle: null,
                selectedStyleAccountInfo: null,
                pageSize: 10,
                page:1,
            };
        default:
            return state;
    }
};

export default StyleReducer;
