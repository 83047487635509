import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Card,
  Select,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Typography,
  Space,
  Popconfirm,
} from "antd";
import { Link, useLocation } from "react-router-dom";
import {   DELETE_TNA_DEPARTMENT_DATA, GET_TNA_SUMMAARY } from "apiServices/API_ENDPOINTS";
import { deleteData, getData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { DeleteOutlined, EditOutlined, EyeOutlined, MoreOutlined, RadarChartOutlined } from "@ant-design/icons";
import { BUYER_INQUIRY_CREATE_PERMISSION, BUYER_INQUIRY_DELETE_PERMISSION, BUYER_INQUIRY_EDIT_PERMISSION, BUYER_INQUIRY_VIEW_PERMISSION, NO_PERMISSION_REQUIRED } from "routes/permissions";
import { cglFormatDate, hasPermission } from "utils/functions";
import { isMobileView } from "components/Common/AppHelper";
import Header from "components/Common/Header";
import CustomFilter from "components/Common/CustomFilter";

const SummaryList = (props) => {
  const { width } = props;
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();


  const getAllPermitList = useCallback(
    async (filterValues) => {
      setLoading(true);
      const bodyData = {
        per_page: rangeData || 10,
        page: currentPage,
        ...filterValues,
      };
      let response = await getData(GET_TNA_SUMMAARY, false, bodyData);

      if (response && response?.data?.code === 200) {
        const masterData = response?.data;
        const result = masterData?.data?.map((tna)=>{
            return {
                id: tna?.id,
                sales_contract_id: tna?.sales_contract_id,
                buyar: tna?.sales_contract?.buyer?.name,
                buying_office:tna?.sales_contract?.buying_office?.name,
                sales_contract_no: tna?.sales_contract?.reference_no,
                percentage_of_completion: tna?.percent_of_completion,
                current_task: tna?.title,
                responsible: tna?.responsible_person_name,
                due_date: tna?.due_date,
                action: tna?.action_status_string,
                status: tna?.status,
                variance: tna?.variation,
                comment: tna?.comment,
            }
        })
        setPermitList(result);
        setTotalData(masterData?.meta?.total);
        setRangeData(masterData?.meta?.per_page);
      }
      else {
        HandleNotification('error', 'bottomRight', 'Something Went Wrong!', null) 
      }

      setLoading(false);
    },
    [rangeData, currentPage],
  );

  useEffect(() => {
    getAllPermitList();
  }, [currentPage, getAllPermitList]);

  const columns = [
    {
      title: "Buyar",
      dataIndex: "buyar",
      key: "buyar",
    },
    {
      title: "Buying Office",
      dataIndex: "buying_office",
      key: "buying_office",
    },
    {
      title: "Sales Contract No",
      dataIndex: "sales_contract_no",
      key: "sales_contract_no",
    },
    {
      title: "% of Completion",
      dataIndex: "percentage_of_completion",
      key: "percentage_of_completion",
    },
    {
      title: "Current Task",
      dataIndex: "current_task",
      key: "current_task",
    },
    {
      title: "Responsible",
      dataIndex: "responsible",
      key: "responsible",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Variance (Days)",
      dataIndex: "variance",
      key: "variance",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer flex-column">
           <div>
            <Link to={`/tanda/summary/${record?.sales_contract_id}`}>
                <EyeOutlined /> Details
              </Link>
           </div>
         <div>
             <Link to={`/tanda/progress/${record?.sales_contract_id}`}>
              <RadarChartOutlined /> Graph
            </Link>
         </div>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header title="T&A Summary" subTitle=""  />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={permitList}
            fetchTableData={getAllPermitList}
            filterName="tna_department"
          />
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            scroll={{x:'100%'}}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => setCurrentPage(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            
          />
        </Card>
      </div>
    </Fragment>
  );
}

export default SummaryList;
