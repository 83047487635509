import React, { memo, useEffect, useState } from "react";
import { Button, Dropdown, Menu, Popconfirm, Space, Typography } from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import AppPageHeader from "../../common/PageHeader";
import AppContent from "../../common/AppContent";
import OrganizationListTable from "./OrganizationListTable";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { onGetOrganizationList } from "../../redux/actions";
import { onDeleteSelectedOrganization } from "../../redux/actions";
import {
  ORGANIZATION_CREATE_PERMISSION,
  ORGANIZATION_EDIT_PERMISSION,
  ORGANIZATION_DELETE_PERMISSION,
} from "../../routes/permissions";
import { hasPermission } from "../../utils/functions";
import { useLocation } from "react-router";

const OrganizationList = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );

  // Redux
  const dispatch = useDispatch();
  const loading = useSelector(({ common }) => common.loading);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const total = useSelector(
    ({ organization }) => organization.totalOrganization,
  );
  const organizationList = useSelector(
    ({ organization }) => organization.organizationList,
  );
  const pageSize = useSelector(({ organization }) => organization.pageSize);
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = (record, event) => {
    dispatch(onDeleteSelectedOrganization(record));
    props.refetch();
  };
  const handleEdit = (event, record) => {
    setSelectedOrg(record);
    navigate("/configuration/organization/create-organization", {
      state: record,
    });
  };
  const tableColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Short Name",
      dataIndex: "short_name",
      key: "short_name",
    },
    {
      title: "Business Type",
      dataIndex: "business_type",
      key: "business_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (record?.status === "1" ? "Active" : "Inactive"),
    },
    { title: "Address", dataIndex: "address", key: "address" },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <>
          {organizationList.length >= 1 ? (
            <Dropdown.Button
              overlay={
                <Menu
                  title="action"
                  mode="horizontal"
                  items={[
                    {
                      label: (
                        <Popconfirm
                          title="Are you sure you want to delete?"
                          onConfirm={() => handleDelete(record)}
                        >
                          <span>
                            <DeleteTwoTone />
                          </span>
                          <a style={{ color: "red", marginLeft: 5 }}>Delete</a>
                        </Popconfirm>
                      ),
                      key: "1",
                      disabled: hasPermission([ORGANIZATION_DELETE_PERMISSION])
                        ? false
                        : true,
                    },
                    {
                      label: (
                        <>
                          <span>
                            <EditTwoTone />
                          </span>
                          <a
                            type="button"
                            style={{ marginLeft: 5 }}
                            onClick={(event) => {
                              setSelectedOrg(record);

                              handleEdit(event, record);
                            }}
                          >
                            Edit
                          </a>
                        </>
                      ),
                      key: "2",
                      disabled: hasPermission([ORGANIZATION_EDIT_PERMISSION])
                        ? false
                        : true,
                    },
                  ]}
                />
              }
            ></Dropdown.Button>
          ) : null}
        </>
      ),
    },
  ];

  const handleNav = (event, record) => {
    console.log("clicked");
    navigate("/configuration/organization/create-organization", {
      state: selectedOrg,
    });
  };

  useEffect(() => {
    dispatch(
      onGetOrganizationList({
        page: currentPage,
        per_page: 10,
        ...filterData,
      }),
    );
  }, [props.refresh, currentPage, pageSize, filterData, dispatch]);

  const navigate = useNavigate();
  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        type="primary"
        size="small"
        onClick={handleNav}
        disabled={!hasPermission([ORGANIZATION_CREATE_PERMISSION])}
      >
        {hasPermission([ORGANIZATION_CREATE_PERMISSION])
          ? "Add Organization"
          : "No Permission"}
      </Button>
    </Space>
  );
  const header = (
    <AppPageHeader
      extra={extra}
      title={<Title level={5}>Organization List</Title>}
    />
  );

  const content = (
    <OrganizationListTable
      onChange={(newPage) => {
        onPageChange(newPage);
      }}
      loading={loading}
      total={total}
      current={currentPage}
      defaultCurrent={currentPage}
      pageSize={pageSize}
      tableColumns={tableColumns}
      dataSource={organizationList}
      filter={true}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
    />
  );

  return (
    <>
      <AppContent header={header} content={content} />
    </>
  );
};

export default memo(OrganizationList);
