import { Col, Form, Row, Input } from "antd";

const UsersFilterFields = ({ initialTableData }) => {
  return (
    <>
      <Col span={4}>
        <Form.Item label="Name" name="name">
          <Input placeholder="Name" size="small"/>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Email" name="email">
          <Input placeholder="Email"  size="small"/>
        </Form.Item>
      </Col>
    </>
  );
};

export default UsersFilterFields;
