import {
    GET_PACKING_LIST,
    STORE_NEW_PACKING,
    DELETE_PACKING,
    GET_PACKING_DETAIL, UPDATE_PACKING_DETAIL
} from '../ActionTypes';

const initialState = {
    PackingList: [],
    totalPacking: null,
    selectedPacking: null,
    selectedPackingDetails: null,
    createdPacking: null,
    selectedPackingAccountInfo: null,
    pageSize: 10,
    page:1,
};

const PackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PACKING_LIST:
            return {
                ...state,
                PackingList: action.payload.data,
                totalPacking: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case STORE_NEW_PACKING:
            return {
                ...state,
                createdPacking: action.payload.data,
                PackingList: [action.payload.data, ...state.PackingList],
                totalPacking: state.totalPacking + 1,
            };

        case DELETE_PACKING: {
            return {
                ...state,
                PackingList: action.payload.list,
                totalPacking: action.payload.total,
            };
        }

        case GET_PACKING_DETAIL:
            return {
                ...state,
                selectedPacking: action.payload,
                selectedPackingAccountInfo: action.payload,
                selectedPackingDetails: action.payload,
            };

        case UPDATE_PACKING_DETAIL:
            return {
                ...state,
                selectedPacking: action.payload,
                PackingList: state.PackingList.map((Packing) =>
                    Packing.id === action.payload.id ? action.payload : Packing,
                ),
            };

        default:
            return state;
    }
};

export default PackingReducer;
