import { lazy, Suspense } from "react";
import { v4 as uuidv4 } from "uuid";
import BOM from "../components/BOM";
import Buyer from "../components/Buyer";
import CreateOrEditBuyer from "../components/Buyer/CreateOrEditBuyer";
import BuyingOffice from "../components/BuyingOffice";
import CreateOrEditBuyingOffice from "../components/BuyingOffice/CreateOrEditBuyingOffice";
import Dashboard from "../components/Dashboard";
import Item from "../components/Item";
import ItemCategory from "../components/ItemCategory";
import ItemOCR from "../components/ItemOCR";
import Organization from "../components/Organization";
import CreateOrganization from "../components/Organization/CreateOrganization";
import PurchaseOrder from "../components/PurchaseOrder";
import SalesContact from "../components/SalesContract";
import AddSalesContact from "../components/SalesContract/AddOrUpdateSalesContact/create";
import SewingLine from "../components/SewingLine";
import Style from "../components/Style";
import AddCopiedStyle from "../components/Style/AddOrUpdateStyle/CopyStyle";
import UpdateStyle from "../components/Style/AddOrUpdateStyle/Update";
import ViewStyle from "../components/Style/AddOrUpdateStyle/View";
import AddStyle from "../components/Style/AddOrUpdateStyle/create";
import {
  ACTIVITY_LOG_VIEW_PERMISSION,
  APP_DASHBOARD_VIEW_PERMISSION,
  ASSIGNMENT_CREATE_PERMISSION,
  ASSIGNMENT_DELETE_PERMISSION,
  ASSIGNMENT_EDIT_PERMISSION,
  ASSIGNMENT_VIEW_PERMISSION,
  B2BLC_CREATE_PERMISSION,
  B2BLC_DELETE_PERMISSION,
  B2BLC_EDIT_PERMISSION,
  B2BLC_VIEW_PERMISSION,
  BIN_CREATE_PERMISSION,
  BIN_DELETE_PERMISSION,
  BIN_EDIT_PERMISSION,
  BIN_VIEW_PERMISSION,
  BOLT_SEAL_LOG_CREATE_PERMISSION,
  BOLT_SEAL_LOG_DELETE_PERMISSION,
  BOLT_SEAL_LOG_EDIT_PERMISSION,
  BOLT_SEAL_LOG_VIEW_PERMISSION,
  BOM_CREATE_PERMISSION,
  BOM_DELETE_PERMISSION,
  BOM_EDIT_PERMISSION,
  BOM_EXPORT_PERMISSION,
  BOM_VIEW_PERMISSION,
  BUYER_CREATE_PERMISSION,
  BUYER_DELETE_PERMISSION,
  BUYER_EDIT_PERMISSION,
  BUYER_INQUIRY_CREATE_PERMISSION,
  BUYER_INQUIRY_DELETE_PERMISSION,
  BUYER_INQUIRY_EDIT_PERMISSION,
  BUYER_INQUIRY_VIEW_PERMISSION,
  BUYER_PO_PO_WISE_CREATE_PERMISSION,
  BUYER_PO_PO_WISE_DELETE_PERMISSION,
  BUYER_PO_PO_WISE_EDIT_PERMISSION,
  BUYER_PO_PO_WISE_VIEW_PERMISSION,
  BUYER_VIEW_PERMISSION,
  BUYING_OFFICE_CREATE_PERMISSION,
  BUYING_OFFICE_DELETE_PERMISSION,
  BUYING_OFFICE_EDIT_PERMISSION,
  BUYING_OFFICE_VIEW_PERMISSION,
  CAPACITY_BOOKING_MENU_VIEW_PERMISSION,
  CHALLAN_CREATE_PERMISSION,
  CHALLAN_DELETE_PERMISSION,
  CHALLAN_EDIT_PERMISSION,
  CHALLAN_VIEW_PERMISSION,
  CHECKLIST_MENU_VIEW_PERMISSION,
  COLOR_CREATE_PERMISSION,
  COLOR_DELETE_PERMISSION,
  COLOR_EDIT_PERMISSION,
  COLOR_VIEW_PERMISSION,
  COST_ESTIMATE_CREATE_PERMISSION,
  COST_ESTIMATE_DELETE_PERMISSION,
  COST_ESTIMATE_EDIT_PERMISSION,
  COST_ESTIMATE_VIEW_PERMISSION,
  COST_OF_MAKING_CREATE,
  COST_OF_MAKING_DELETE,
  COST_OF_MAKING_DETAILS,
  COST_OF_MAKING_EDIT,
  COST_OF_MAKING_LIST,
  COST_SHEET_REPORT_DELETE_PERMISSION,
  COST_SHEET_REPORT_GENERATE_PERMISSION,
  COST_SHEET_REPORT_VIEW_PERMISSION,
  CUTTING_CREATE_PERMISSION,
  CUTTING_DELETE_PERMISSION,
  CUTTING_EDIT_PERMISSION,
  CUTTING_VIEW_PERMISSION,
  DAILY_IMPORT_POSITION_CREATE_PERMISSION,
  DAILY_IMPORT_POSITION_DELETE_PERMISSION,
  DAILY_IMPORT_POSITION_EDIT_PERMISSION,
  DAILY_IMPORT_POSITION_VIEW_PERMISSION,
  DAILY_LINE_PLAN_VIEW_PERMISSION,
  DAILY_LINE_TARGET_CREATE_PERMISSION,
  DAILY_LINE_TARGET_DELETE_PERMISSION,
  DAILY_LINE_TARGET_EDIT_PERMISSION,
  DAILY_LINE_TARGET_VIEW_PERMISSION,
  DELETE_MRR_REPORT_VIEW_PERMISSION,
  DIGITALIZATION_ABSENTEEISM_VIEW_PERMISSION,
  DIGITALIZATION_CHECKLIST_VIEW_PERMISSION,
  DIGITALIZATION_DEFECT_VIEW_PERMISSION,
  DIGITALIZATION_MENU_VIEW_PERMISSION,
  DIGITALIZATION_ROLE_CHECK_PREMISSION,
  DIGITALIZATION_SETTINGS_VIEW_PERMISSION,
  DRIVER_VEHICLE_INFO_CREATE_PERMISSION,
  DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
  DRIVER_VEHICLE_INFO_DISPATCH_TIME_PERMISSION,
  DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
  DRIVER_VEHICLE_INFO_VIEW_PERMISSION,
  EMBROIDERY_PRINTING_SMOCKING_PINTUCK_VIEW_PERMISSION,
  ERP_USAGE_ISSUE_VIEW_PERMISSION,
  ERP_USAGE_MODULE_VIEW_PERMISSION,
  EXPORT_DETAILS_CREATE_PERMISSION,
  EXPORT_DETAILS_DELETE_PERMISSION,
  EXPORT_DETAILS_EDIT_PERMISSION,
  EXPORT_DETAILS_VIEW_PERMISSION,
  FABRIC_ACCESSORIES_AUTO_RECONCILIATION_VIEW_PERMISSION,
  FABRIC_REPORT_DELETE_PERMISSION,
  FABRIC_REPORT_GENERATE_PERMISSION,
  FABRIC_REPORT_VIEW_PERMISSION,
  FINISH_GARMENT_CREATE_PERMISSION,
  FINISH_GARMENT_DELETE_PERMISSION,
  FINISH_GARMENT_EDIT_PERMISSION,
  FINISH_GARMENT_VIEW_PERMISSION,
  FOLDING_CREATE_PERMISSION,
  FOLDING_DELETE_PERMISSION,
  FOLDING_EDIT_PERMISSION,
  FOLDING_VIEW_PERMISSION,
  GARMENT_CATEGORY_CREATE_PERMISSION,
  GARMENT_CATEGORY_DELETE_PERMISSION,
  GARMENT_CATEGORY_EDIT_PERMISSION,
  GARMENT_CATEGORY_VIEW_PERMISSION,
  GARMENT_TYPE_CREATE_PERMISSION,
  GARMENT_TYPE_DELETE_PERMISSION,
  GARMENT_TYPE_EDIT_PERMISSION,
  GARMENT_TYPE_VIEW_PERMISSION,
  GARMENTS_PARTS_CREATE_PERMISSION,
  GARMENTS_PARTS_DELETE_PERMISSION,
  GARMENTS_PARTS_EDIT_PERMISSION,
  GARMENTS_PARTS_VIEW_PERMISSION,
  GARMENTS_TRACKING_VIEW_PERMISSION,
  GENERATE_MRR_REPORT_VIEW_PERMISSION,
  GOODS_DELIVERY_CREATE_PERMISSION,
  GOODS_DELIVERY_DELETE_PERMISSION,
  GOODS_DELIVERY_EDIT_PERMISSION,
  GOODS_DELIVERY_VIEW_PERMISSION,
  HOUR_CONFIGURATION_CREATE_PERMISSION,
  HOURLY_PRODUCTION_VIEW_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_DELETE_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_VIEW_PERMISSION,
  INQUIRY_REASONS_CREATE_PERMISSION,
  INQUIRY_REASONS_DELETE_PERMISSION,
  INQUIRY_REASONS_EDIT_PERMISSION,
  INQUIRY_REASONS_VIEW_PERMISSION,
  INQUIRY_REMARKS_CREATE_PERMISSION,
  INQUIRY_REMARKS_DELETE_PERMISSION,
  INQUIRY_REMARKS_EDIT_PERMISSION,
  INQUIRY_REMARKS_VIEW_PERMISSION,
  INVENTORY_CREATE_PERMISSION,
  INVENTORY_DELETE_PERMISSION,
  INVENTORY_DETAIL_REPORT_DELETE_PERMISSION,
  INVENTORY_DETAIL_REPORT_GENERATE_PERMISSION,
  INVENTORY_DETAIL_REPORT_VIEW_PERMISSION,
  INVENTORY_EDIT_PERMISSION,
  INVENTORY_MRR_REPORT_VIEW_PERMISSION,
  INVENTORY_VIEW_PERMISSION,
  ITEM_CATEGORY_CREATE_PERMISSION,
  ITEM_CATEGORY_DELETE_PERMISSION,
  ITEM_CATEGORY_EDIT_PERMISSION,
  ITEM_CATEGORY_VIEW_PERMISSION,
  ITEM_CREATE_PERMISSION,
  ITEM_DELETE_PERMISSION,
  ITEM_EDIT_PERMISSION,
  ITEM_OCR_UPLOAD_PERMISSION,
  ITEM_VIEW_PERMISSION,
  LEARNING_CURVE_CREATE_PERMISSION,
  LEARNING_CURVE_DELETE_PERMISSION,
  LEARNING_CURVE_EDIT_PERMISSION,
  LEARNING_CURVE_VIEW_PERMISSION,
  LOCAL_PURCHASE_RECONCILIATION_CREATE_PERMISSION,
  LOCAL_PURCHASE_RECONCILIATION_DELETE_PERMISSION,
  LOCAL_PURCHASE_RECONCILIATION_EDIT_PERMISSION,
  LOCAL_PURCHASE_RECONCILIATION_VIEW_PERMISSION,
  NO_PERMISSION_REQUIRED,
  NOTIFICATION_SETTING_CREATE_PERMISSION,
  NOTIFICATION_SETTING_DELETE_PERMISSION,
  NOTIFICATION_SETTING_EDIT_PERMISSION,
  NOTIFICATION_SETTING_VIEW_PERMISSION,
  NOTIFICATION_SLUG_CREATE_PERMISSION,
  NOTIFICATION_SLUG_DELETE_PERMISSION,
  NOTIFICATION_SLUG_EDIT_PERMISSION,
  NOTIFICATION_SLUG_VIEW_PERMISSION,
  NOTING_ASSESSMENT_CREATE_PERMISSION,
  NOTING_ASSESSMENT_DELETE_PERMISSION,
  NOTING_ASSESSMENT_EDIT_PERMISSION,
  NOTING_ASSESSMENT_VIEW_PERMISSION,
  OB_CREATE_PERMISSION,
  OB_DELETE_PERMISSION,
  OB_EDIT_PERMISSION,
  OB_MACHINE_CREATE_PERMISSION,
  OB_MACHINE_DELETE_PERMISSION,
  OB_MACHINE_EDIT_PERMISSION,
  OB_MACHINE_VIEW_PERMISSION,
  OB_SAM_DATA_BANK_CREATE_PERMISSION,
  OB_SAM_DATA_BANK_DELETE_PERMISSION,
  OB_SAM_DATA_BANK_EDIT_PERMISSION,
  OB_SAM_DATA_BANK_VIEW_PERMISSION,
  OB_SECTION_CREATE_PERMISSION,
  OB_SECTION_DELETE_PERMISSION,
  OB_SECTION_EDIT_PERMISSION,
  OB_SECTION_VIEW_PERMISSION,
  OB_TEMPLATE_CREATE_PERMISSION,
  OB_TEMPLATE_DELETE_PERMISSION,
  OB_TEMPLATE_EDIT_PERMISSION,
  OB_TEMPLATE_VIEW_PERMISSION,
  OB_VIEW_PERMISSION,
  ORGANIZATION_CREATE_PERMISSION,
  ORGANIZATION_DELETE_PERMISSION,
  ORGANIZATION_EDIT_PERMISSION,
  ORGANIZATION_VIEW_PERMISSION,
  OT_SIGNAL_CREATE_PERMISSION,
  OT_SIGNAL_DELETE_PERMISSION,
  OT_SIGNAL_EDIT_PERMISSION,
  OT_SIGNAL_SECTION_CREATE_PERMISSION,
  OT_SIGNAL_SECTION_DELETE_PERMISSION,
  OT_SIGNAL_SECTION_EDIT_PERMISSION,
  OT_SIGNAL_SECTION_VIEW_PERMISSION,
  OT_SIGNAL_VIEW_PERMISSION,
  PACKING_CREATE_PERMISSION,
  PACKING_DELETE_PERMISSION,
  PACKING_EDIT_PERMISSION,
  PACKING_VIEW_PERMISSION,
  PERMISSION_CREATE_PERMISSION,
  PERMISSION_DELETE_PERMISSION,
  PERMISSION_EDIT_PERMISSION,
  PERMISSION_VIEW_PERMISSION,
  PI_BUDGET_VIEW_PERMISSION,
  PI_CREATE_PERMISSION,
  PI_DELETE_PERMISSION,
  PI_EDIT_PERMISSION,
  PI_VIEW_PERMISSION,
  PLAN_CREATE_PERMISSION,
  PLAN_DASHBOARD_VIEW_PERMISSION,
  PLAN_EDIT_PERMISSION,
  POST_COSTING_REPORT_GENERATE_PERMISSION,
  PP_EXCEPTION_CREATE_PERMISSION,
  PP_EXCEPTION_DELETE_PERMISSION,
  PP_EXCEPTION_EDIT_PERMISSION,
  PP_EXCEPTION_VIEW_PERMISSION,
  PP_HOLIDAY_CREATE_PERMISSION,
  PP_HOLIDAY_DELETE_PERMISSION,
  PP_HOLIDAY_EDIT_PERMISSION,
  PP_HOLIDAY_VIEW_PERMISSION,
  PP_PLANNING_REQUEST_CREATE_PERMISSION,
  PP_PLANNING_REQUEST_DELETE_PERMISSION,
  PP_PLANNING_REQUEST_EDIT_PERMISSION,
  PP_PLANNING_REQUEST_VIEW_PERMISSION,
  PP_WEEKEND_CREATE_PERMISSION,
  PP_WEEKEND_DELETE_PERMISSION,
  PP_WEEKEND_EDIT_PERMISSION,
  PP_WEEKEND_VIEW_PERMISSION,
  PRODUCT_DETAIL_REPORT_DELETE_PERMISSION,
  PRODUCT_DETAIL_REPORT_GENERATE_PERMISSION,
  PRODUCT_TRACK_REPORT_DELETE_PERMISSION,
  PRODUCT_TRACK_REPORT_GENERATE_PERMISSION,
  PRODUCT_TRACK_REPORT_VIEW_PERMISSION,
  PURCHASE_ORDER_CREATE_PERMISSION,
  PURCHASE_ORDER_DELETE_PERMISSION,
  PURCHASE_ORDER_EDIT_PERMISSION,
  PURCHASE_ORDER_VIEW_PERMISSION,
  RACK_CREATE_PERMISSION,
  RACK_DELETE_PERMISSION,
  RACK_EDIT_PERMISSION,
  RACK_VIEW_PERMISSION,
  ROLE_CREATE_PERMISSION,
  ROLE_DELETE_PERMISSION,
  ROLE_EDIT_PERMISSION,
  ROLE_VIEW_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_DELETE_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_EDIT_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_VIEW_PERMISSION,
  SALES_CONTRACT_CREATE_PERMISSION,
  SALES_CONTRACT_DELETE_PERMISSION,
  SALES_CONTRACT_EDIT_PERMISSION,
  SALES_CONTRACT_VIEW_PERMISSION,
  SECURITY_BOLT_CREATE_PERMISSION,
  SECURITY_BOLT_EDIT_PERMISSION,
  SEWING_CREATE_PERMISSION,
  SEWING_DELETE_PERMISSION,
  SEWING_EDIT_PERMISSION,
  SEWING_LINE_CREATE_PERMISSION,
  SEWING_LINE_DELETE_PERMISSION,
  SEWING_LINE_EDIT_PERMISSION,
  SEWING_LINE_VIEW_PERMISSION,
  SEWING_VIEW_PERMISSION,
  SHIPMENT_HANDOVER_CREATE_PERMISSION,
  SHIPMENT_HANDOVER_DELETE_PERMISSION,
  SHIPMENT_HANDOVER_EDIT_PERMISSION,
  SHIPMENT_HANDOVER_VIEW_PERMISSION,
  SHIPPING_CREATE_PERMISSION,
  SHIPPING_DELETE_PERMISSION,
  SHIPPING_EDIT_PERMISSION,
  SHIPPING_VIEW_PERMISSION,
  SIZE_CREATE_PERMISSION,
  SIZE_DELETE_PERMISSION,
  SIZE_EDIT_PERMISSION,
  SIZE_VIEW_PERMISSION,
  STYLE_CREATE_PERMISSION,
  STYLE_DELETE_PERMISSION,
  STYLE_EDIT_PERMISSION,
  STYLE_VIEW_PERMISSION,
  SUBCONTRACT_FACTORY_VIEW_PERMISSION,
  SUBMISSION_REALIZATION_CREATE_PERMISSION,
  SUBMISSION_REALIZATION_DELETE_PERMISSION,
  SUBMISSION_REALIZATION_EDIT_PERMISSION,
  SUBMISSION_REALIZATION_VIEW_PERMISSION,
  SUPPLIER_CREATE_PERMISSION,
  SUPPLIER_DELETE_PERMISSION,
  SUPPLIER_EDIT_PERMISSION,
  SUPPLIER_VIEW_PERMISSION,
  SWING_VS_FOLDING_REPORT_GENERATE_PERMISSION,
  TNA_DEPARTMENT_CREATE_PERMISSION,
  TNA_DEPARTMENT_EDIT_PERMISSION,
  TNA_DEPARTMENT_VIEW_PERMISSION,
  TNA_PLAN_CREATE_PERMISSION,
  TNA_PLAN_EDIT_PERMISSION,
  TNA_PLAN_VIEW_PERMISSION,
  TNA_PROCESS_CREATE_PERMISSION,
  TNA_PROCESS_EDIT_PERMISSION,
  TNA_PROCESS_VIEW_PERMISSION,
  TNA_SUMMARY_VIEW_PERMISSION,
  TNA_TASK_CREATE_PERMISSION,
  TNA_TASK_EDIT_PERMISSION,
  TNA_TASK_VIEW_PERMISSION,
  TNA_TEMPLATE_CREATE_PERMISSION,
  TNA_TEMPLATE_EDIT_PERMISSION,
  TNA_TEMPLATE_VIEW_PERMISSION,
  TNA_TO_DO_LIST_VIEW_PERMISSION,
  UNIT_CREATE_PERMISSION,
  UNIT_DELETE_PERMISSION,
  UNIT_EDIT_PERMISSION,
  UNIT_VIEW_PERMISSION,
  UPLOAD_BANK_PERMISSION,
  UPLOAD_BOM_PERMISSION,
  UPLOAD_BRANCH_PERMISSION,
  UPLOAD_BRAND_PERMISSION,
  UPLOAD_BUYER_PERMISSION,
  UPLOAD_BUYING_OFFICE_PERMISSION,
  UPLOAD_COLOR_PERMISSION,
  UPLOAD_MERCHANDISER_PERMISSION,
  UPLOAD_PO_PERMISSION,
  UPLOAD_SALES_CONTRACT_PERMISSION,
  UPLOAD_SIZE_PERMISSION,
  UPLOAD_STYLE_PERMISSION,
  UPLOAD_SUBSTYLE_PERMISSION,
  UPLOAD_SUPPLIER_PERMISSION,
  USER_CREATE_PERMISSION,
  USER_DELETE_PERMISSION,
  USER_EDIT_PERMISSION,
  USER_VIEW_PERMISSION,
  WAREHOUSE_CREATE_PERMISSION,
  WAREHOUSE_DELETE_PERMISSION,
  WAREHOUSE_EDIT_PERMISSION,
  WAREHOUSE_VIEW_PERMISSION,
  WASHING_SNAP_BUTTON_VIEW_PERMISSION,
} from "./permissions";
import Assignment from "components/Production/Assignment";
import Cutting from "components/Production/Cutting";
import CreateCuttingEntry from "components/Production/Cutting/CreateForm";
import Folding from "components/Production/Folding";
import CreateFoldingEntry from "components/Production/Folding/CreateForm";
import CreatePackingEntry from "components/Production/Packing/CreateForm";
import Sewing from "components/Production/Sewing";
import AddSewing from "components/Production/Sewing/SewingCreate";
import BudgetPi from "../components/Account/BudgetPi";
import ExportDetails from "../components/Account/ExportDetails";
import AddExportDetails from "../components/Account/ExportDetails/AddOrUpdateExportDetails/create";
import EditExportDetails from "../components/Account/ExportDetails/AddOrUpdateExportDetails/update";
import ExportSalesContract from "../components/Account/ExportSalesContract";
import EditExportSalesContract from "../components/Account/ExportSalesContract/AddOrUpdateExportSalesContract/Edit";
import AddExportSalesContract from "../components/Account/ExportSalesContract/AddOrUpdateExportSalesContract/create";
import GoodsDelivery from "../components/Account/GoodsDelivery";
import ImportAcceptanceDetails from "../components/Account/ImportAcceptanceDetails";
import AcceptanceDetails from "../components/Account/ImportAcceptanceDetails/AcceptanceDetails";
import ImportB2bLc from "../components/Account/ImportB2bLc";
import EditB2bLc from "../components/Account/ImportB2bLc/AddOrUpdateB2bLc/Edit";
import AddB2bLc from "../components/Account/ImportB2bLc/AddOrUpdateB2bLc/create";
import ImportDetails from "../components/Account/ImportDetails";
import EditDetails from "../components/Account/ImportDetails/AddOrUpdateDetails/Edit";
import AddDetails from "../components/Account/ImportDetails/AddOrUpdateDetails/create";
import ImportPi from "../components/Account/ImportPi";
import EditPi from "../components/Account/ImportPi/AddOrUpdatePi/Edit";
import ViewPi from "../components/Account/ImportPi/AddOrUpdatePi/View";
import AddPi from "../components/Account/ImportPi/AddOrUpdatePi/create";
import Noting from "../components/Account/Noting";
import AddNoting from "../components/Account/Noting/AddOrUpdateNoting/create";
import EditNoting from "../components/Account/Noting/AddOrUpdateNoting/update";
import AddSecurityBolt from "../components/Account/SecurityBolt/AddOrUpdateSecurityBolt/create";
import EditSecurityBolt from "../components/Account/SecurityBolt/AddOrUpdateSecurityBolt/update";
import ShipmentOver from "../components/Account/ShipmentOver";
import AddShipmentOver from "../components/Account/ShipmentOver/AddOrUpdateShipmentOver/create";
import EditShipmentOver from "../components/Account/ShipmentOver/AddOrUpdateShipmentOver/update";
import SubmissionRealization from "../components/Account/SubmissionRealization";
import AddSubmissionRealization from "../components/Account/SubmissionRealization/AddOrUpdateSubmissionRealization/create";
import EditSubmissionRealization from "../components/Account/SubmissionRealization/AddOrUpdateSubmissionRealization/update";
import UpdateBOM from "../components/BOM/AddOrUpdateStyle/Update";
import ViewBOM from "../components/BOM/AddOrUpdateStyle/View";
import PreviewBOM from "../components/BOM/AddOrUpdateStyle/PreviewBom";
import AddBOM from "../components/BOM/AddOrUpdateStyle/create";
import BinConfig from "../components/Configuration/BinConfig";
import ColorConfig from "../components/Configuration/ColorConfig";
import RackConfig from "../components/Configuration/RackConfig";
import SizeConfig from "../components/Configuration/SizeConfig";
import SupplierConfig from "../components/Configuration/SupplierConfig";
import UnitConfig from "../components/Configuration/UnitConfig";
import UnitConvertionConfig from "../components/Configuration/UnitConvertionConfig";
import WarehouseConfig from "../components/Configuration/WarehouseConfig";
import ImportFiles from "../components/ImportFiles";
import CreateFabricInventory from "../components/InventoryFabric/CreateFabricInventory";
import FinishGood from "../components/InventoryFabric/FinishGood";
import UpdatePurchaseOrder from "../components/PurchaseOrder/AddOrUpdateStyle/Update";
import ViewPO from "../components/PurchaseOrder/AddOrUpdateStyle/View";
import AddPurchaseOrder from "../components/PurchaseOrder/AddOrUpdateStyle/create";
import CostSheet from "../components/Report/CostSheet";
import FabricReconcil from "../components/Report/FabricReconcil";
import InventoryDetails from "../components/Report/InventoryDetails";
import ProductionDetails from "../components/Report/ProductionDetails";
import ProductionTracking from "../components/Report/ProductionTracking";
import UpdateSalesContact from "../components/SalesContract/AddOrUpdateSalesContact/Update";
import ViewSalesContact from "../components/SalesContract/AddOrUpdateSalesContact/View";
import CreateUser from "../components/Settings/User/CreateForm";
import UserList from "../components/Settings/User/UserList";
import UserRole from "../components/Settings/UserRole";
import CreateUserRole from "../components/Settings/UserRole/CreateForm";
import ShipmentEntry from "../components/Shipment/CreateForm";
import ShipmentInformation from "../components/Shipment/ShipmentList";
import ActivityLog from "../components/ActivityLog";
import BuyerInquiry from "../components/BuyerInquiry";
import AddBuyerInquiry from "../components/BuyerInquiry/AddOrUpdateBuyerInquiry/create";
import UpdateBuyerInquiry from "../components/BuyerInquiry/AddOrUpdateBuyerInquiry/update";
import ChallanConfig from "../components/Configuration/ChallanConfig";
import GarmentCategoryConfig from "../components/Configuration/GarmentCategoryConfig";
import GarmentTypeConfig from "../components/Configuration/GarmentTypeConfig";
import InquiryReasonsConfig from "../components/Configuration/InquiryReasonsConfig";
import InquiryRemarksConfig from "../components/Configuration/InquiryRemarksConfig";
import OBMachineConfig from "../components/Configuration/OBMachineConfig";
import OBSamBankConfig from "../components/Configuration/OBSAMBankConfig";
import OBSectionConfig from "../components/Configuration/OBSectionConfig";
import OBTemplate from "../components/Configuration/OBTemplate";
import OBTemplateForm from "../components/Configuration/OBTemplate/OBTemplateForm";
import LocalPurchaseReconciliation from "../components/InventoryFabric/LocalPurchaseReconciliation";
import AddLocalPurchaseReconciliation from "../components/InventoryFabric/LocalPurchaseReconciliation/AddOrUpdateLocalPurchaseReconciliation/Create";
import UpdateLocalPurchaseReconciliation from "../components/InventoryFabric/LocalPurchaseReconciliation/AddOrUpdateLocalPurchaseReconciliation/Edit";
import ItemCategorySequence from "../components/ItemCategory/ItemCategorySequence";
import LearningCure from "../components/LearningCurve";
import AddOrUpdateLearningCure from "../components/LearningCurve/AddOrUpdate";
import Notifications from "../components/Notifications";
import OperationBulletin from "../components/OperationBulletin";
import OBEntry from "../components/OperationBulletin/OBEntry";
import PackingConfirmation from "components/Production/Packing/PackingConfirmation";
import CostEstimate from "../components/Account/CostEstimate";
import AddOrUpdateCostEstimate from "../components/Account/CostEstimate/AddOrUpdateCostEstimate";
import PackingList from "../components/Production/Packing/PackingList";
import CreatePackingReq from "../components/Production/PackingReq/CreatePackingReq";
import UpdatePackingReq from "../components/Production/PackingReq/UpdatePackingReq";
import PurchaseOrderNew from "../components/PurchaseOrderNew";
import UpdatePurchaseOrderNew from "../components/PurchaseOrderNew/AddOrUpdateStyle/Update";
import ViewPONew from "../components/PurchaseOrderNew/AddOrUpdateStyle/View";
import AddPurchaseOrderNew from "../components/PurchaseOrderNew/AddOrUpdateStyle/create";
import InventoryMRR from "../components/Report/InventoryMRR";
import PackingReport from "../components/Report/PackingReport";
import NotificationSlugs from "../components/Settings/NotificationSlugs";
import AddOrUpdateNotificationSlug from "../components/Settings/NotificationSlugs/AddOrUpdateNotificationSlug";
import PermissionSettings from "../components/Settings/PermissionSettings";
import AddStyleFromInquiry from "../components/Style/AddOrUpdateStyle/AddStyleFromInquiry";
import Department from "components/TAndA/Department";
import AddDepartment from "components/TAndA/Department/AddDepertment/AddDepartment";
import UpdateDepartment from "components/TAndA/Department/UpdateDepartment/UpdateDepartment";
import Process from "components/TAndA/Process";
import AddProcess from "components/TAndA/Process/AddProcess/AddProcess";
import UpdateProcess from "components/TAndA/Process/UpdateProcess/UpdateProcess";
import ProgressGraph from "components/TAndA/Summary/Progress/Progress";
import SummaryList from "components/TAndA/Summary/SummaryList";
import SummaryView from "components/TAndA/Summary/SummaryView";
import TNAPlan from "components/TAndA/TNAPlan";
import AddTNAPlan from "components/TAndA/TNAPlan/AddTNAPlan";
import TNATemplate from "components/TAndA/TNATemplate";
import AddOrUpdateTNATemplate from "components/TAndA/TNATemplate/AddTNATemplate";
import Task from "components/TAndA/Task";
import AddTask from "components/TAndA/Task/AddTask/AddTask";
import UpdateTask from "components/TAndA/Task/UpdateTask/UpdateTask";
import TodoList from "components/TAndA/Todo";
import DailyLineTarget from "components/Production/DailyLineTarget";
import ViewNotificationSetting from "components/Settings/NotificationSettings/Details";
import NotificationSettings from "../components/Settings/NotificationSettings";
import AddOrUpdateNotificationSetting from "../components/Settings/NotificationSettings/AddOrUpdateNotificationSetting";
import CreateTrimsInventory from "components/InventoryTrims/CreateTrimsInventory";
import AssignmentEntry from "components/Production/Assignment/CreateForm";
import UpdateCutting from "components/Production/Cutting/UpdateForm";
import CreateCuttingItemEntry from "components/Production/CuttingItem/CreateForm";
import FabricAccessoriesAutoReconciliation from "components/Production/FabricAccessoriesAutoReconciliation";
import UpdateFolding from "components/Production/Folding/UpdateForm";
import PackingReqList from "components/Production/PackingReq/PackingReqList";
import SewingNew from "components/Production/Sewing";
import SewingCreate from "components/Production/Sewing/SewingCreate";
import SewingUpdate from "components/Production/Sewing/SewingUpdate";
import AssignmentContextProvider from "context/AssignmentContext/AssignmentContextProvider";
import CuttingContextProvider from "context/CuttingContext/CuttingContextProvider";
import FabricAutoContextProvider from "context/FabricAutoContext/FabricAutoContextProvider";
import FoldingContextProvider from "context/FoldingContext/FoldingContextProvider";
import CmDefaultData from "../components/Account/CostOfMaking/DefaultData";
import CmExpenseCalculation from "../components/Account/CostOfMaking/ExpenseCalculation";
import CostOfMakingReport from "../components/Account/CostOfMaking/Report";
import GoodsDeliveryForm from "../components/Account/GoodsDelivery/GoodsDeliveryForm";
import HourlyProduction from "../components/Report/HourlyProduction";
import SizeSpecs from "../components/SalesContract/AddOrUpdateSalesContact/SizeSpecs";
import BoltSealLogList from "components/Account/BoltSealLog";
import AddOrUpdateBoltSealLog from "components/Account/BoltSealLog/AddOrUpdateBoltSealLog";
import DriverInformation from "components/Account/DriverInformation";
import VehicleInformation from "components/Account/VehicleInformation";
import OperatorAbsenteeism from "components/Digitalization/OperatorAbsenteeism";
import OperatorAbsenteeismView from "components/Digitalization/OperatorAbsenteeism/view";
import ProductionDefect from "components/Digitalization/ProductionDefect";
import ProductionDefectView from "components/Digitalization/ProductionDefect/view";
import SupervisorChecklist from "components/Digitalization/SupervisorChecklist";
import SupervisorChecklistView from "components/Digitalization/SupervisorChecklist/view";
import BoltSealReport from "components/Report/BoltSealReport";
import PostCostingReport from "components/Report/PostCostingReport";
import Weekends from "../components/ProductionPlanning/Weekends";
import AddOrUpdateWeekend from "../components/ProductionPlanning/Weekends/AddOrUpdateWeekend";
import Holidays from "../components/ProductionPlanning/Holidays";
import AddOrUpdateHoliday from "../components/ProductionPlanning/Holidays/AddOrUpdateHoliday";
import Exceptions from "../components/ProductionPlanning/Exceptions";
import AddOrUpdateException from "../components/ProductionPlanning/Exceptions/AddOrUpdateException";
import LoadingComponent from "common/LoadingComponent";
import Accounts from "components/Accounts";
import AppDashboard from "components/AppDashboard";
import AppDashboardLine from "components/AppDashboard/AppDashboardLine";
import CategoryWiseDefect from "components/AppDashboard/CategoryWiseDefect";
import CategoryWiseHourlyDefect from "components/AppDashboard/CategoryWiseHourlyDefect";
import DefectTracker from "components/AppDashboard/DefectTracker";
import OperatorWiseDefect from "components/AppDashboard/OperatorWiseDefect";
import ProductionSummary from "components/AppDashboard/ProductionSummary";
import ProductionTracker from "components/AppDashboard/ProductionTracker";
import RecommendationAgainstDefects from "components/AppDashboard/RecommendationAgainstDefects";
import ResponsibleForDefects from "components/AppDashboard/ResponsibleForDefects";
import AuditDate from "components/AuditDate";
import Banks from "components/Banks";
import Branches from "components/Branches";
import BuyerInspectionDate from "components/BuyerInspectionDate";
import BuyerVisitDate from "components/BuyerVisitDate";
import CapacityBookings from "components/CapacityBookings";
import CapacityOfSampleTeam from "components/CapacityOfSampleTeam";
import GarmentsPartsConfig from "components/Configuration/GarmentsPartsConfig";
import BuyerWiseChecklist from "components/Digitalization/BuyerWiseChecklist";
import BuyerWiseChecklistView from "components/Digitalization/BuyerWiseChecklist/view";
import ChecklistQuestionsList from "components/Digitalization/Checklist/ChecklistQuestions";
import ChecklistQuestionsAdd from "components/Digitalization/Checklist/ChecklistQuestions/Add";
import ChecklistQuestionsEdit from "components/Digitalization/Checklist/ChecklistQuestions/Edit";
import DailyLinePlanList from "components/Digitalization/DailyLinePlan";
import DailyLinePlanAdd from "components/Digitalization/DailyLinePlan/Add";
import DailyLinePlanEdit from "components/Digitalization/DailyLinePlan/Edit";
import GarmentsTracking from "components/Digitalization/GarmentsTracking";
import UpdateGarmentsTracking from "components/Digitalization/GarmentsTracking/UpdateGarmentsTracking";
import SamJustification from "components/Digitalization/SamJustification";
import User from "components/Digitalization/User";
import FabricLoadingCapacityOfStore from "components/FabricLoadingCapacityOfStore";
import OTSignal from "components/OTSignal";
import OvertimeReasons from "components/OvertimeReasons";
import OvertimeSignals from "components/OvertimeSignals";
import PostCuttingProcess from "components/Production/PostCuttingProcess";
import PostCuttingProcessEntry from "components/Production/PostCuttingProcess/CreateForm";
import ProductionPlanning from "components/ProductionPlanning";
import ProductionPlanningDashboard from "components/ProductionPlanning/Dashboard";
import ProductionPlanningDashboard2 from "components/ProductionPlanning/Dashboard2";
import OSRReport from "components/Report/OSRReport";
import SwingvsFolding from "components/Report/SwingvsFoldingReport";
import SampleRequisitions from "components/SampleRequisitions";
import SubSalesContract from "components/SubSalesContract";
import UpdateSubSalesContact from "components/SubSalesContract/AddOrUpdateSalesContact/Update";
import ViewSubSalesContact from "components/SubSalesContract/AddOrUpdateSalesContact/View";
import AddSubSalesContact from "components/SubSalesContract/AddOrUpdateSalesContact/create";
import TAndAInvoices from "components/TAndAInvoices";
import UnitsPrice from "components/UnitsPrice";
import UpdateUnitsPrice from "components/UnitsPrice/AddOrUpdateStyle/Update";
import AddUnitsPrice from "components/UnitsPrice/AddOrUpdateStyle/create";
import DailyLinePlanContextProvider from "context/DailyLinePlanContext";
import PostCuttingProcessContextProvider from "context/PostCuttingProcessContext/PostCuttingProcessContextProvider";
import ProductionPlanningContextProvider from "context/ProductionPlanningContext";
import OTSignalSection from "../components/OTSignalSection";
import PPMeetingDate from "../components/PPMeetingDate";
import PlanningRequests from "../components/ProductionPlanning/PlanningRequests";
import AddOrUpdatePlanningRequest from "../components/ProductionPlanning/PlanningRequests/AddOrUpdatePlanningRequest";
import ViewUnitsPrice from "../components/PurchaseOrder/AddOrUpdateStyle/View";
import UpdatePostCutting from "components/Production/PostCuttingProcess/UpdateForm";
import PostSewingProcess from "components/Production/PostSewingProcess";
import PostSewingProcessContextProvider from "context/PostSewingProcessContext/PostSewingProcessContextProvider";
import PostSewingProcessEntry from "components/Production/PostSewingProcess/CreateForm";
import UpdatePostSewing from "components/Production/PostSewingProcess/UpdateForm";
import CapacityBookingsReport from "components/CapacityBookingsReport";
import ERPUsageModule from "components/ERPUsage/Module";
import ERPUsageModuleUi from "components/ERPUsage/ModuleUi";
import ERPUsageIssue from "components/ERPUsage/Issue";
import SpecialProcess from "components/BuyerInquiry/SpecialProcess";
import ERPUsageDashboard from "components/ERPUsage/Dashboard";
import HourPage from "components/Digitalization/Hour";

const RolePanel = lazy(() => import("components/Digitalization/Roles"));
const UserLineAssign = lazy(() =>
  import("components/Digitalization/UserLineAssign"),
);
const SubContractFactory = lazy(() => import("components/SubContractFactory"));
const DefectCategory = lazy(() =>
  import("components/Digitalization/Defect/Category/DefectCategory"),
);
const DefectReason = lazy(() =>
  import("components/Digitalization/Defect/Reason/DefectReason"),
);
const DefectResponsible = lazy(() =>
  import("components/Digitalization/Defect/Responsible/DefectResponsible"),
);
const DefectRecommendation = lazy(() =>
  import(
    "components/Digitalization/Defect/Recommendation/DefectRecommendation"
  ),
);
const DefectCategoryMapping = lazy(() =>
  import(
    "components/Digitalization/Defect/CategoryMapping/DefectCategoryMapping"
  ),
);
const AddOrEditCategoryMapping = lazy(() =>
  import("components/Digitalization/Defect/CategoryMapping/addEdit/AddOrEdit"),
);
const LineNSectionMapping = lazy(() =>
  import(
    "components/Digitalization/Defect/LineNSectionMapping/LineNSectionMapping"
  ),
);
const HourlyProductionRecord = lazy(() =>
  import("components/Digitalization/HourlyProductionRecord/Index"),
);
const HourlyProductionRecordView = lazy(() =>
  import("components/Digitalization/HourlyProductionRecord/view"),
);
const SalesContract360 = lazy(() =>
  import("components/Merchandising/SalesContract360/SalesContract360"),
);
const AppProductDetails = lazy(() =>
  import("components/AppDashboard/ProductDetails/AppProductDetails"),
);
const OperatorWisePerformance = lazy(() =>
  import(
    "components/AppDashboard/OperatorWisePerformance/OperatorWisePerformance"
  ),
);

const AuthRouter = [
  {
    id: 1,
    path: "/inventory/store/fabric-store-entry",
    name: "inventory-fabric-store",
    component: <CreateFabricInventory />,
    allowedpermissions: [
      INVENTORY_VIEW_PERMISSION,
      INVENTORY_CREATE_PERMISSION,
      INVENTORY_EDIT_PERMISSION,
      INVENTORY_DELETE_PERMISSION,
    ],
  },
  {
    id: 2,
    path: "/production/cutting",
    name: "production",
    component: <Cutting />,
    allowedpermissions: [
      CUTTING_VIEW_PERMISSION,
      CUTTING_CREATE_PERMISSION,
      CUTTING_EDIT_PERMISSION,
      CUTTING_DELETE_PERMISSION,
    ],
  },
  {
    id: 3,
    path: "/production/cutting/entry",
    name: "production",
    component: (
      <CuttingContextProvider>
        <CreateCuttingEntry />
      </CuttingContextProvider>
    ),
    allowedpermissions: [CUTTING_CREATE_PERMISSION, CUTTING_EDIT_PERMISSION],
  },
  {
    id: 4,
    path: "/production/folding/entry",
    name: "production",
    component: (
      <FoldingContextProvider>
        <CreateFoldingEntry />
      </FoldingContextProvider>
    ),
    allowedpermissions: [
      FOLDING_VIEW_PERMISSION,
      FOLDING_CREATE_PERMISSION,
      FOLDING_EDIT_PERMISSION,
      FOLDING_DELETE_PERMISSION,
    ],
  },
  {
    id: 5,
    path: "/production/packing/packing-entry",
    name: "production",
    component: <CreatePackingEntry />,
    allowedpermissions: [
      PACKING_VIEW_PERMISSION,
      PACKING_CREATE_PERMISSION,
      PACKING_EDIT_PERMISSION,
      PACKING_DELETE_PERMISSION,
    ],
  },
  {
    id: 6,
    path: "/production/packing",
    name: "production",
    component: <PackingList />,
    allowedpermissions: [
      PACKING_VIEW_PERMISSION,
      PACKING_CREATE_PERMISSION,
      PACKING_EDIT_PERMISSION,
      PACKING_DELETE_PERMISSION,
    ],
  },
  {
    id: 7,
    path: "/production/folding",
    name: "production-folding",
    component: <Folding />,
    allowedpermissions: [
      FOLDING_VIEW_PERMISSION,
      FOLDING_CREATE_PERMISSION,
      FOLDING_EDIT_PERMISSION,
      FOLDING_DELETE_PERMISSION,
    ],
  },
  {
    id: 8,
    path: "/production/assignment",
    name: "production",
    component: (
      <AssignmentContextProvider>
        <Assignment />
      </AssignmentContextProvider>
    ),
    allowedpermissions: [
      ASSIGNMENT_VIEW_PERMISSION,
      ASSIGNMENT_CREATE_PERMISSION,
      ASSIGNMENT_EDIT_PERMISSION,
      ASSIGNMENT_DELETE_PERMISSION,
    ],
  },
  {
    id: 9,
    path: "/production/sewing",
    name: "production",
    component: <Sewing />,
    allowedpermissions: [
      SEWING_VIEW_PERMISSION,
      SEWING_CREATE_PERMISSION,
      SEWING_EDIT_PERMISSION,
      SEWING_DELETE_PERMISSION,
    ],
  },
  {
    id: 10,
    path: "/production/add-sewing",
    name: "production",
    component: <AddSewing />,
    allowedpermissions: [
      SEWING_VIEW_PERMISSION,
      SEWING_CREATE_PERMISSION,
      SEWING_EDIT_PERMISSION,
      SEWING_DELETE_PERMISSION,
    ],
  },
  {
    id: 12,
    path: "/",
    name: "dashboard",
    component: <Dashboard />,
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 13,
    path: "/configuration/organization",
    name: "organization",
    component: <Organization />,
    allowedpermissions: [
      ORGANIZATION_VIEW_PERMISSION,
      ORGANIZATION_CREATE_PERMISSION,
      ORGANIZATION_EDIT_PERMISSION,
      ORGANIZATION_DELETE_PERMISSION,
    ],
  },
  {
    id: 14,
    path: "/forwarding/shipment",
    name: "shipment",
    component: <ShipmentInformation />,
    allowedpermissions: [
      SHIPPING_VIEW_PERMISSION,
      SHIPPING_CREATE_PERMISSION,
      SHIPPING_EDIT_PERMISSION,
      SHIPPING_DELETE_PERMISSION,
    ],
  },
  {
    id: 15,
    path: "/forwarding/store-shipment-info",
    name: "shipment",
    component: <ShipmentEntry />,
    allowedpermissions: [
      SHIPPING_VIEW_PERMISSION,
      SHIPPING_CREATE_PERMISSION,
      SHIPPING_EDIT_PERMISSION,
      SHIPPING_DELETE_PERMISSION,
    ],
  },
  {
    id: 16,
    path: "/configuration/organization/create-organization",
    name: "create-organization",
    component: <CreateOrganization />,
    allowedpermissions: [ORGANIZATION_CREATE_PERMISSION],
  },
  {
    id: 17,
    path: "/configuration/buyer",
    name: "buyer",
    component: <Buyer />,
    allowedpermissions: [
      BUYER_VIEW_PERMISSION,
      BUYER_CREATE_PERMISSION,
      BUYER_EDIT_PERMISSION,
      BUYER_DELETE_PERMISSION,
    ],
  },
  {
    id: 18,
    path: "/configuration/buyer/create-buyer",
    name: "buyer",
    component: <CreateOrEditBuyer isAdd />,
    allowedpermissions: [BUYER_CREATE_PERMISSION],
  },
  {
    id: 19,
    path: "/configuration/buying-office",
    name: "buying-office",
    component: <BuyingOffice />,
    allowedpermissions: [
      BUYING_OFFICE_VIEW_PERMISSION,
      BUYING_OFFICE_CREATE_PERMISSION,
      BUYING_OFFICE_EDIT_PERMISSION,
      BUYING_OFFICE_DELETE_PERMISSION,
    ],
  },
  {
    id: 20,
    path: "/configuration/buying-office/create-buying-office",
    name: "create-buying-office",
    component: <CreateOrEditBuyingOffice isAdd />,
    allowedpermissions: [BUYING_OFFICE_CREATE_PERMISSION],
  },
  {
    id: 21,
    path: "/configuration/sewing-line",
    name: "sewing-line",
    component: <SewingLine />,
    layout: "/configuration/sewing-line",
    allowedpermissions: [
      SEWING_LINE_VIEW_PERMISSION,
      SEWING_LINE_CREATE_PERMISSION,
      SEWING_LINE_EDIT_PERMISSION,
      SEWING_LINE_DELETE_PERMISSION,
    ],
  },
  {
    id: 22,
    path: "/configuration/items",
    name: "items",
    component: <Item />,
    layout: "/configuration/items",
    allowedpermissions: [
      ITEM_VIEW_PERMISSION,
      ITEM_CREATE_PERMISSION,
      ITEM_EDIT_PERMISSION,
      ITEM_DELETE_PERMISSION,
    ],
  },
  {
    id: 23,
    path: "/settings/users",
    name: "users",
    component: <UserList />,
    layout: "/settings/users",
    allowedpermissions: [
      USER_VIEW_PERMISSION,
      USER_CREATE_PERMISSION,
      USER_EDIT_PERMISSION,
      USER_DELETE_PERMISSION,
    ],
  },
  {
    id: 24,
    path: "/settings/users/create-user",
    name: "create-user",
    component: <CreateUser />,
    layout: "/settings/users/create-user",
    allowedpermissions: [USER_CREATE_PERMISSION],
  },
  {
    id: 25,
    path: "/settings/role",
    name: "user-role-list",
    component: <UserRole />,
    layout: "/settings/role",
    allowedpermissions: [
      ROLE_VIEW_PERMISSION,
      ROLE_CREATE_PERMISSION,
      ROLE_EDIT_PERMISSION,
      ROLE_DELETE_PERMISSION,
    ],
  },
  {
    id: 26,
    path: "/settings/role/create-role",
    name: "create-role",
    component: <CreateUserRole />,
    layout: "/settings/role/create-role",
    allowedpermissions: [ROLE_CREATE_PERMISSION],
  },
  {
    id: 27,
    path: "/configuration/item-category",
    name: "item-category",
    component: <ItemCategory />,
    layout: "/configuration/item-category",
    allowedpermissions: [
      ITEM_CATEGORY_VIEW_PERMISSION,
      ITEM_CATEGORY_CREATE_PERMISSION,
      ITEM_CATEGORY_EDIT_PERMISSION,
      ITEM_CATEGORY_DELETE_PERMISSION,
    ],
  },
  {
    id: 28,
    path: "/merchandising/style",
    name: "style",
    component: <Style />,
    breadcrumbname: "style",
    layout: "/merchandising/style",
    allowedpermissions: [
      STYLE_VIEW_PERMISSION,
      STYLE_CREATE_PERMISSION,
      STYLE_EDIT_PERMISSION,
      STYLE_DELETE_PERMISSION,
    ],
  },
  {
    id: 29,
    path: "/merchandising/add-style",
    name: "Add style",
    component: <AddStyle />,
    breadcrumbname: "style",
    layout: "/merchandising/add-style",
    allowedpermissions: [STYLE_CREATE_PERMISSION],
  },
  {
    id: 30,
    path: "/merchandising/copy-style/:styleId",
    name: "Add Copied style",
    component: <AddCopiedStyle />,
    breadcrumbname: "style",
    layout: "/merchandising/add-copied-style",
    allowedpermissions: [STYLE_CREATE_PERMISSION],
  },
  {
    id: 31,
    path: "/merchandising/update-style/:styleId",
    name: "Update style",
    component: <UpdateStyle />,
    breadcrumbname: "style",
    layout: "/merchandising/update-style",
    allowedpermissions: [STYLE_EDIT_PERMISSION],
  },
  {
    id: 32,
    path: "/merchandising/view-style/:styleId",
    name: "View style",
    component: <ViewStyle />,
    breadcrumbname: "style",
    layout: "/merchandising/view-style",
    allowedpermissions: [STYLE_VIEW_PERMISSION],
  },
  {
    id: 33,
    path: "/merchandising/sales-contact",
    name: "sales-contact",
    component: <SalesContact />,
    breadcrumbname: "SalesContact",
    layout: "/merchandising/sales-contact",
    allowedpermissions: [
      SALES_CONTRACT_VIEW_PERMISSION,
      SALES_CONTRACT_CREATE_PERMISSION,
      SALES_CONTRACT_EDIT_PERMISSION,
      SALES_CONTRACT_DELETE_PERMISSION,
    ],
  },

  {
    id: 34,
    path: "/merchandising/add-sales-contact",
    name: "Add Sales Contact",
    component: <AddSalesContact />,
    breadcrumbname: "SalesContact",
    layout: "/merchandising/add-sales-contact",
    allowedpermissions: [SALES_CONTRACT_CREATE_PERMISSION],
  },
  {
    id: 35,
    path: "/merchandising/update-sales-contact/:styleId",
    name: "Update style",
    component: <UpdateSalesContact isEdit />,
    breadcrumbname: "style",
    layout: "/merchandising/update-sales-contact",
    allowedpermissions: [SALES_CONTRACT_EDIT_PERMISSION],
  },
  {
    id: 36,
    path: "/merchandising/view-sales-contact/:styleId",
    name: "Update style",
    component: <ViewSalesContact />,
    breadcrumbname: "style",
    layout: "/merchandising/view-sales-contact",
    allowedpermissions: [SALES_CONTRACT_VIEW_PERMISSION],
  },
  {
    id: 241,
    path: "/merchandising/sub-sales-contact",
    name: "sub-sales-contact",
    component: <SubSalesContract />,
    breadcrumbname: "SubSalesContact",
    layout: "/merchandising/sub-sales-contact",
    allowedpermissions: [
      SALES_CONTRACT_VIEW_PERMISSION,
      SALES_CONTRACT_CREATE_PERMISSION,
      SALES_CONTRACT_EDIT_PERMISSION,
      SALES_CONTRACT_DELETE_PERMISSION,
    ],
  },
  {
    id: 242,
    path: "/merchandising/add-sub-sales-contact",
    name: "Add Sub Contact",
    component: <AddSubSalesContact />,
    breadcrumbname: "AddSalesContact",
    layout: "/merchandising/add-sub-sales-contact",
    allowedpermissions: [SALES_CONTRACT_CREATE_PERMISSION],
  },
  {
    id: 243,
    path: "/merchandising/update-sub-sales-contact/:styleId",
    name: "Update Sub Contact",
    component: <UpdateSubSalesContact isEdit />,
    breadcrumbname: "EditSalesContact",
    layout: "/merchandising/update-sub-sales-contact",
    allowedpermissions: [SALES_CONTRACT_EDIT_PERMISSION],
  },
  {
    id: 244,
    path: "/merchandising/view-sub-sales-contact/:styleId",
    name: "View Sub Contact",
    component: <ViewSubSalesContact />,
    breadcrumbname: "ViewSalesContact",
    layout: "/merchandising/view-sub-sales-contact",
    allowedpermissions: [SALES_CONTRACT_VIEW_PERMISSION],
  },
  {
    id: 145,
    path: "/merchandising/sub-sales-contact-size-specs/:salesContractId",
    name: "sub-sales-contact-size-specs",
    component: <SizeSpecs />,
    breadcrumbname: "style",
    layout: "/merchandising/sub-sales-contact-size-specs",
    allowedpermissions: [SALES_CONTRACT_EDIT_PERMISSION],
  },
  {
    id: 37,
    path: "/merchandising/purchase-order",
    name: "order-details",
    component: <PurchaseOrder />,
    breadcrumbname: "SalesContact",
    layout: "/merchandising/purchase-order",
    allowedpermissions: [
      PURCHASE_ORDER_VIEW_PERMISSION,
      PURCHASE_ORDER_CREATE_PERMISSION,
      PURCHASE_ORDER_EDIT_PERMISSION,
      PURCHASE_ORDER_DELETE_PERMISSION,
    ],
  },
  {
    id: 38,
    path: "/merchandising/add-purchase-order",
    name: "add-order-details",
    component: <AddPurchaseOrder />,
    breadcrumbname: "SalesContact",
    layout: "/merchandising/add-purchase-order",
    allowedpermissions: [PURCHASE_ORDER_CREATE_PERMISSION],
  },
  {
    id: 39,
    path: "/merchandising/update-purchase-order/:styleId",
    name: "add-order-details",
    component: <UpdatePurchaseOrder />,
    breadcrumbname: "SalesContact",
    layout: "/merchandising/update-purchase-order",
    allowedpermissions: [PURCHASE_ORDER_EDIT_PERMISSION],
  },
  {
    id: 251,
    path: "/merchandising/add-price-purchase-order/:styleId",
    name: "add-price-details",
    component: <UpdatePurchaseOrder isAddPrice={true} />,
    breadcrumbname: "AddPrice",
    layout: "/merchandising/add-price-purchase-order",
    allowedpermissions: [PURCHASE_ORDER_EDIT_PERMISSION],
    // allowedpermissions: [PURCHASE_ORDER_ADD_PRICE_PERMISSION],
  },
  {
    id: 40,
    path: "/merchandising/view-purchase-order/:styleId",
    name: "View style",
    component: <ViewPO />,
    breadcrumbname: "style",
    layout: "/merchandising/view-purchase-order/:styleId",
    allowedpermissions: [PURCHASE_ORDER_VIEW_PERMISSION],
  },
  {
    id: 41,
    path: "/merchandising/BOM",
    name: "BOM",
    component: <BOM />,
    breadcrumbname: "BOM",
    layout: "/merchandising/BOM",
    allowedpermissions: [
      BOM_VIEW_PERMISSION,
      BOM_CREATE_PERMISSION,
      BOM_EDIT_PERMISSION,
      BOM_DELETE_PERMISSION,
      BOM_EXPORT_PERMISSION,
    ],
  },
  {
    id: 42,
    path: "/merchandising/add-BOM",
    name: "BOM",
    component: <AddBOM />,
    breadcrumbname: "BOM",
    layout: "/merchandising/add-BOM",
    allowedpermissions: [BOM_CREATE_PERMISSION],
  },
  {
    id: 43,
    path: "/merchandising/update-BOM/:bomId",
    name: "BOM",
    component: <UpdateBOM />,
    breadcrumbname: "BOM",
    layout: "/merchandising/update-BOM",
    allowedpermissions: [BOM_EDIT_PERMISSION],
  },
  {
    id: 44,
    path: "/merchandising/view-BOM/:styleId",
    name: "BOM",
    component: <ViewBOM />,
    breadcrumbname: "BOM",
    layout: "/merchandising/view-BOM",
    allowedpermissions: [BOM_VIEW_PERMISSION],
  },
  {
    id: 45,  // New ID for Preview
    path: "/merchandising/preview-BOM/:styleId",
    name: "Preview BOM",
    component: <PreviewBOM />,  // Your PreviewBOM component
    breadcrumbname: "Preview BOM",
    layout: "/merchandising/preview-BOM",
    allowedpermissions: [BOM_VIEW_PERMISSION],  // Adjust permissions if needed
  },
  // {
  //   id: 45,
  //   path: "/accounting/iou",
  //   name: "iou",
  //   component: <Iou />,
  //   breadcrumbname: "iou",
  //   layout: "/accounting/iou",
  //   allowedpermissions: [""],
  // },
  // {
  //   id: 46,
  //   path: "/accounting/add-iou",
  //   name: "Add IOU",
  //   component: <AddIou />,
  //   breadcrumbname: "iou",
  //   layout: "/accounting/add-iou",
  //   allowedpermissions: [""],
  // },
  // {
  //   id: 47,
  //   path: "/accounting/update-iou/:iouId",
  //   name: "Update IOU",
  //   component: <UpdateIou />,
  //   breadcrumbname: "iou",
  //   layout: "/accounting/update-iou",
  //   allowedpermissions: [""],
  // },
  // {
  //   id: 48,
  //   path: "/accounting/view-iou/:iouId",
  //   name: "View IOU",
  //   component: <ViewIou />,
  //   breadcrumbname: "iou",
  //   layout: "/accounting/view-iou",
  //   allowedpermissions: [""],
  // },
  {
    id: 49,
    path: "/export/sales-contract",
    name: "export-sales-contract",
    component: <ExportSalesContract />,
    breadcrumbname: "iou",
    layout: "/accounting/iou",
    allowedpermissions: [
      SALES_CONTRACT_COMMERCIAL_VIEW_PERMISSION,
      SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION,
      SALES_CONTRACT_COMMERCIAL_EDIT_PERMISSION,
      SALES_CONTRACT_COMMERCIAL_DELETE_PERMISSION,
    ],
  },
  {
    id: 50,
    path: "/export/add-sales-contract",
    name: "Add Export Sales Contract",
    component: <AddExportSalesContract />,
    breadcrumbname: "iou",
    layout: "/export/add-sales-contract",
    allowedpermissions: [SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION],
  },
  {
    id: 51,
    path: "/export/edit-sales-contract/:exportId",
    name: "Edit Export Sales Contract",
    component: <EditExportSalesContract />,
    breadcrumbname: "iou",
    layout: "/export/edit-sales-contract",
    allowedpermissions: [SALES_CONTRACT_COMMERCIAL_EDIT_PERMISSION],
  },
  {
    id: 52,
    path: "/import/b2blc",
    name: "import-b2blc",
    component: <ImportB2bLc />,
    breadcrumbname: "iou",
    layout: "/accounting/iou",
    allowedpermissions: [
      B2BLC_VIEW_PERMISSION,
      B2BLC_CREATE_PERMISSION,
      B2BLC_EDIT_PERMISSION,
      B2BLC_DELETE_PERMISSION,
    ],
  },
  {
    id: 53,
    path: "/import/add-b2blc",
    name: "Add B2B LC",
    component: <AddB2bLc />,
    breadcrumbname: "iou",
    layout: "/export/add-sales-contract",
    allowedpermissions: [B2BLC_CREATE_PERMISSION],
  },
  {
    id: 54,
    path: "/import/edit-b2blc/:importId",
    name: "Edit Export Sales Contract",
    component: <EditB2bLc />,
    breadcrumbname: "iou",
    layout: "/import/edit-b2blc/:importId",
    allowedpermissions: [B2BLC_EDIT_PERMISSION],
  },
  {
    id: 55,
    path: "/import/pi",
    name: "import-pi",
    component: <ImportPi />,
    breadcrumbname: "iou",
    layout: "/accounting/iou",
    allowedpermissions: [
      PI_VIEW_PERMISSION,
      PI_CREATE_PERMISSION,
      PI_EDIT_PERMISSION,
      PI_DELETE_PERMISSION,
    ],
  },
  {
    id: 56,
    path: "/import/add-pi",
    name: "Add PI",
    component: <AddPi />,
    breadcrumbname: "iou",
    layout: "/export/add-sales-contract",
    allowedpermissions: [PI_CREATE_PERMISSION],
  },
  {
    id: 57,
    path: "/import/edit-pi/:importId",
    name: "Edit PI",
    component: <EditPi />,
    breadcrumbname: "iou",
    layout: "/import/edit-pi/:importId",
    allowedpermissions: [PI_EDIT_PERMISSION],
  },
  {
    id: 57,
    path: "/import/view-pi/:importId",
    name: "View PI",
    component: <ViewPi />,
    breadcrumbname: "iou",
    layout: "/import/view-pi/:importId",
    allowedpermissions: [PI_VIEW_PERMISSION],
  },
  {
    id: 59,
    path: "/import/details/:supplierTypeId",
    name: "import-details",
    component: <ImportDetails />,
    breadcrumbname: "iou",
    layout: "/accounting/iou",
    allowedpermissions: [
      DAILY_IMPORT_POSITION_VIEW_PERMISSION,
      DAILY_IMPORT_POSITION_CREATE_PERMISSION,
      DAILY_IMPORT_POSITION_EDIT_PERMISSION,
      DAILY_IMPORT_POSITION_DELETE_PERMISSION,
    ],
  },
  {
    id: 60,
    path: "/import/add-detail/:supplierType",
    name: "Add Details",
    component: <AddDetails />,
    breadcrumbname: "iou",
    layout: "/export/add-sales-contract",
    allowedpermissions: [DAILY_IMPORT_POSITION_CREATE_PERMISSION],
  },
  {
    id: 61,
    path: "/import/edit-detail/:importId/:supplierType",
    name: "Edit Details",
    component: <EditDetails />,
    breadcrumbname: "iou",
    layout: "/import/edit-detail/:importId",
    allowedpermissions: [DAILY_IMPORT_POSITION_EDIT_PERMISSION],
  },
  {
    id: 62,
    path: "/import/acceptance-details",
    name: "import-acceptance-details",
    component: <ImportAcceptanceDetails />,
    breadcrumbname: "iou",
    layout: "/accounting/iou",
    allowedpermissions: [
      IMPORT_ACCEPTANCE_DETAIL_VIEW_PERMISSION,
      IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION,
      IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION,
      IMPORT_ACCEPTANCE_DETAIL_DELETE_PERMISSION,
    ],
  },
  {
    id: 63,
    path: "/import/add-acceptance-detail",
    name: "Add Acceptance Details",
    component: <AcceptanceDetails isAdd />,
    breadcrumbname: "iou",
    layout: "/export/add-sales-contract",
    allowedpermissions: [IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION],
  },
  {
    id: 64,
    path: "/import/edit-acceptance-detail/:importId",
    name: "Edit Acceptance Details",
    component: <AcceptanceDetails isEdit />,
    breadcrumbname: "iou",
    layout: "/import/edit-acceptance-detail/:importId",
    allowedpermissions: [IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION],
  },
  {
    id: 65,
    path: "/configuration/warehouse",
    name: "Warehouse Configuration",
    component: <WarehouseConfig />,
    breadcrumbname: "warehouse-config",
    layout: "/configuration/warehouse",
    allowedpermissions: [
      WAREHOUSE_VIEW_PERMISSION,
      WAREHOUSE_CREATE_PERMISSION,
      WAREHOUSE_EDIT_PERMISSION,
      WAREHOUSE_DELETE_PERMISSION,
    ],
  },
  {
    id: 66,
    path: "/configuration/rack",
    name: "Rack Configuration",
    component: <RackConfig />,
    breadcrumbname: "rack-config",
    layout: "/configuration/rack",
    allowedpermissions: [
      RACK_VIEW_PERMISSION,
      RACK_CREATE_PERMISSION,
      RACK_EDIT_PERMISSION,
      RACK_DELETE_PERMISSION,
    ],
  },
  {
    id: 67,
    path: "/configuration/bin",
    name: "Bin Configuration",
    component: <BinConfig />,
    breadcrumbname: "bin-config",
    layout: "/configuration/bin",
    allowedpermissions: [
      BIN_VIEW_PERMISSION,
      BIN_CREATE_PERMISSION,
      BIN_EDIT_PERMISSION,
      BIN_DELETE_PERMISSION,
    ],
  },
  {
    id: 68,
    path: "/configuration/unit",
    name: "Unit Configuration",
    component: <UnitConfig />,
    breadcrumbname: "unit-config",
    layout: "/configuration/unit",
    allowedpermissions: [
      UNIT_VIEW_PERMISSION,
      UNIT_CREATE_PERMISSION,
      UNIT_EDIT_PERMISSION,
      UNIT_DELETE_PERMISSION,
    ],
  },
  {
    id: 69,
    path: "/configuration/supplier",
    name: "Supplier Configuration",
    component: <SupplierConfig />,
    breadcrumbname: "supplier-config",
    layout: "/configuration/supplier",
    allowedpermissions: [
      SUPPLIER_VIEW_PERMISSION,
      SUPPLIER_CREATE_PERMISSION,
      SUPPLIER_EDIT_PERMISSION,
      SUPPLIER_DELETE_PERMISSION,
    ],
  },
  {
    id: 70,
    path: "/inventory/finish-good",
    name: "Finish Good",
    component: <FinishGood />,
    breadcrumbname: "finish-good",
    layout: "/inventory/finish-good",
    allowedpermissions: [
      FINISH_GARMENT_VIEW_PERMISSION,
      FINISH_GARMENT_CREATE_PERMISSION,
      FINISH_GARMENT_EDIT_PERMISSION,
      FINISH_GARMENT_DELETE_PERMISSION,
    ],
  },
  {
    id: 71,
    path: "/configuration/color",
    name: "Color Configuration",
    component: <ColorConfig />,
    breadcrumbname: "color-config",
    layout: "/configuration/color",
    allowedpermissions: [
      COLOR_VIEW_PERMISSION,
      COLOR_CREATE_PERMISSION,
      COLOR_EDIT_PERMISSION,
      COLOR_DELETE_PERMISSION,
    ],
  },
  {
    id: 72,
    path: "/configuration/size",
    name: "Size Configuration",
    component: <SizeConfig />,
    breadcrumbname: "size-config",
    layout: "/configuration/size",
    allowedpermissions: [
      SIZE_VIEW_PERMISSION,
      SIZE_CREATE_PERMISSION,
      SIZE_EDIT_PERMISSION,
      SIZE_DELETE_PERMISSION,
    ],
  },
  {
    id: 73,
    path: "/configuration/unit-convertion",
    name: "Unit Convertion",
    component: <UnitConvertionConfig />,
    breadcrumbname: "unit-convertion-config",
    layout: "/configuration/unit-convertion",
    allowedpermissions: [
      UNIT_VIEW_PERMISSION,
      UNIT_CREATE_PERMISSION,
      UNIT_EDIT_PERMISSION,
      UNIT_DELETE_PERMISSION,
    ],
  },
  {
    id: 74,
    path: "/import/budget-pi",
    name: "budget-pi",
    component: <BudgetPi />,
    breadcrumbname: "iou",
    layout: "/accounting/iou",
    allowedpermissions: [PI_BUDGET_VIEW_PERMISSION],
  },
  {
    id: 75,
    path: "/export/export-details",
    name: "export-details",
    component: <ExportDetails />,
    breadcrumbname: "export-details",
    layout: "/export/export-details",
    allowedpermissions: [
      EXPORT_DETAILS_VIEW_PERMISSION,
      EXPORT_DETAILS_CREATE_PERMISSION,
      EXPORT_DETAILS_EDIT_PERMISSION,
      EXPORT_DETAILS_DELETE_PERMISSION,
    ],
  },
  {
    id: 76,
    path: "/export/add-export-details",
    name: "add-export-details",
    component: <AddExportDetails />,
    breadcrumbname: "add-export-details",
    layout: "/export/add-export-details",
    allowedpermissions: [EXPORT_DETAILS_CREATE_PERMISSION],
  },
  {
    id: 77,
    path: "/export/edit-export-details/:exportId",
    name: "edit-export-details",
    component: <EditExportDetails />,
    breadcrumbname: "edit-export-details",
    layout: "/export/edit-export-details",
    allowedpermissions: [EXPORT_DETAILS_EDIT_PERMISSION],
  },
  {
    id: 78,
    path: "/export/submission-realization",
    name: "submission-realization",
    component: <SubmissionRealization />,
    breadcrumbname: "submission-realization",
    layout: "/export/submission-realization",
    allowedpermissions: [
      SUBMISSION_REALIZATION_VIEW_PERMISSION,
      SUBMISSION_REALIZATION_CREATE_PERMISSION,
      SUBMISSION_REALIZATION_EDIT_PERMISSION,
      SUBMISSION_REALIZATION_DELETE_PERMISSION,
    ],
  },
  {
    id: 79,
    path: "/export/add-submission-realization",
    name: "add-submission-realization",
    component: <AddSubmissionRealization />,
    breadcrumbname: "add-submission-realization",
    layout: "/export/add-submission-realization",
    allowedpermissions: [SUBMISSION_REALIZATION_CREATE_PERMISSION],
  },
  {
    id: 80,
    path: "/export/edit-submission-realization/:exportId",
    name: "edit-submission-realization",
    component: <EditSubmissionRealization />,
    breadcrumbname: "edit-submission-realization",
    layout: "/export/edit-submission-realization",
    allowedpermissions: [SUBMISSION_REALIZATION_EDIT_PERMISSION],
  },
  {
    id: 81,
    path: "/import-customs-clearance/noting-assessment-examination",
    name: "noting-assessment-examination",
    component: <Noting />,
    breadcrumbname: "noting-assessment-examination",
    layout: "/import-customs-clearance/noting-assessment-examination",
    allowedpermissions: [
      NOTING_ASSESSMENT_VIEW_PERMISSION,
      NOTING_ASSESSMENT_CREATE_PERMISSION,
      NOTING_ASSESSMENT_EDIT_PERMISSION,
      NOTING_ASSESSMENT_DELETE_PERMISSION,
    ],
  },
  {
    id: 82,
    path: "/import-customs-clearance/add-noting-assessment-examination",
    name: "add-noting-assessment-examination",
    component: <AddNoting />,
    breadcrumbname: "add-noting-assessment-examination",
    layout: "/import-customs-clearance/add-noting-assessment-examination",
    allowedpermissions: [NOTING_ASSESSMENT_CREATE_PERMISSION],
  },
  {
    id: 83,
    path: "/import-customs-clearance/edit-noting-assessment-examination/:notingId",
    name: "edit-noting-assessment-examination",
    component: <EditNoting />,
    breadcrumbname: "edit-noting-assessment-examination",
    layout: "/import-customs-clearance/edit-noting-assessment-examination",
    allowedpermissions: [NOTING_ASSESSMENT_EDIT_PERMISSION],
  },
  {
    id: 84,
    path: "/import/goods-delivery",
    name: "goods-delivery",
    component: <GoodsDelivery />,
    breadcrumbname: "goods-delivery",
    layout: "/import/goods-delivery",
    allowedpermissions: [
      GOODS_DELIVERY_VIEW_PERMISSION,
      GOODS_DELIVERY_CREATE_PERMISSION,
      GOODS_DELIVERY_EDIT_PERMISSION,
      GOODS_DELIVERY_DELETE_PERMISSION,
    ],
  },
  {
    id: 85,
    path: "/import/add-goods-delivery",
    name: "add-goods-delivery",
    component: <GoodsDeliveryForm />,
    breadcrumbname: "add-goods-delivery",
    layout: "/export/add-goods-delivery",
    allowedpermissions: [GOODS_DELIVERY_CREATE_PERMISSION],
  },
  {
    id: 86,
    path: "/import/edit-goods-delivery/:importId",
    name: "edit-goods-delivery",
    component: <GoodsDeliveryForm editMode={true} />,
    breadcrumbname: "edit-goods-delivery",
    layout: "/import/edit-goods-delivery",
    allowedpermissions: [GOODS_DELIVERY_EDIT_PERMISSION],
  },
  {
    id: 87,
    path: "/report/report-fabric-reconciliation",
    name: "report-fabric-reconciliation",
    component: <FabricReconcil />,
    breadcrumbname: "report-fabric-reconciliation",
    layout: "/report-fabric-reconciliation",
    allowedpermissions: [
      FABRIC_REPORT_VIEW_PERMISSION,
      FABRIC_REPORT_GENERATE_PERMISSION,
      FABRIC_REPORT_DELETE_PERMISSION,
    ],
  },
  {
    id: 88,
    path: "/report/report-production-tracking",
    name: "report-production-tracking",
    component: <ProductionTracking />,
    breadcrumbname: "report-production-tracking",
    layout: "/report-production-tracking",
    allowedpermissions: [
      PRODUCT_TRACK_REPORT_VIEW_PERMISSION,
      PRODUCT_TRACK_REPORT_GENERATE_PERMISSION,
      PRODUCT_TRACK_REPORT_DELETE_PERMISSION,
    ],
  },
  {
    id: 89,
    path: "/report/report-production-details",
    name: "report-production-details",
    component: <ProductionDetails />,
    breadcrumbname: "report-production-details",
    layout: "/report-production-details",
    allowedpermissions: [
      PRODUCT_DETAIL_REPORT_GENERATE_PERMISSION,
      PRODUCT_DETAIL_REPORT_DELETE_PERMISSION,
    ],
  },
  {
    id: 90,
    path: "/report/report-cost-sheet",
    name: "report-cost-sheet",
    component: <CostSheet />,
    breadcrumbname: "report-cost-sheet",
    layout: "/report-cost-sheet",
    allowedpermissions: [
      COST_SHEET_REPORT_VIEW_PERMISSION,
      COST_SHEET_REPORT_GENERATE_PERMISSION,
      COST_SHEET_REPORT_DELETE_PERMISSION,
    ],
  },
  {
    id: 91,
    path: "/import-files",
    name: "import-files",
    component: <ImportFiles />,
    breadcrumbname: "import-files",
    layout: "/import-files",
    allowedpermissions: [
      UPLOAD_BUYER_PERMISSION,
      UPLOAD_BRAND_PERMISSION,
      UPLOAD_BUYING_OFFICE_PERMISSION,
      UPLOAD_BANK_PERMISSION,
      UPLOAD_BRANCH_PERMISSION,
      UPLOAD_MERCHANDISER_PERMISSION,
      UPLOAD_STYLE_PERMISSION,
      UPLOAD_SUBSTYLE_PERMISSION,
      UPLOAD_SALES_CONTRACT_PERMISSION,
      UPLOAD_SUPPLIER_PERMISSION,
      UPLOAD_COLOR_PERMISSION,
      UPLOAD_SIZE_PERMISSION,
      UPLOAD_BOM_PERMISSION,
      UPLOAD_PO_PERMISSION,
    ],
  },
  // {
  //   id: 92,
  //   path: "/ex-factory/security-bolt-seal-log",
  //   name: "security-bolt-seal-log",
  //   component: <SecurityBolt />,
  //   breadcrumbname: "security-bolt-seal-log",
  //   layout: "/ex-factory/security-bolt-seal-log",
  //   allowedpermissions: [
  //     SECURITY_BOLT_VIEW_PERMISSION,
  //     SECURITY_BOLT_CREATE_PERMISSION,
  //     SECURITY_BOLT_EDIT_PERMISSION,
  //     SECURITY_BOLT_DELETE_PERMISSION,
  //   ],
  // },

  {
    id: 92,
    path: "/ex-factory/vehicle-information",
    name: "vehicle-information",
    component: <VehicleInformation />,
    breadcrumbname: "vehicle-information",
    layout: "/ex-factory/vehicle-information",
    allowedpermissions: [
      DRIVER_VEHICLE_INFO_VIEW_PERMISSION,
      DRIVER_VEHICLE_INFO_CREATE_PERMISSION,
      DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
      DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
      DRIVER_VEHICLE_INFO_DISPATCH_TIME_PERMISSION,
    ],
  },
  {
    id: uuidv4(),
    path: "/ex-factory/driver-information",
    name: "driver-information",
    component: <DriverInformation />,
    breadcrumbname: "driver-information",
    layout: "/ex-factory/driver-information",
    allowedpermissions: [
      DRIVER_VEHICLE_INFO_VIEW_PERMISSION,
      DRIVER_VEHICLE_INFO_CREATE_PERMISSION,
      DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
      DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
      DRIVER_VEHICLE_INFO_DISPATCH_TIME_PERMISSION,
    ],
  },
  {
    id: 93,
    path: "/ex-factory/bolt-seal-log",
    name: "bolt-seal-log",
    component: <BoltSealLogList />,
    breadcrumbname: "bolt-seal-log",
    layout: "/ex-factory/bolt-seal-log",
    allowedpermissions: [
      BOLT_SEAL_LOG_CREATE_PERMISSION,
      BOLT_SEAL_LOG_EDIT_PERMISSION,
      BOLT_SEAL_LOG_VIEW_PERMISSION,
      BOLT_SEAL_LOG_DELETE_PERMISSION,
    ],
  },
  {
    id: 94,
    path: "/ex-factory/add-bolt-seal-log",
    name: "add-bolt-seal-log",
    component: <AddOrUpdateBoltSealLog />,
    breadcrumbname: "add-bolt-seal-log",
    layout: "/ex-factory/add-bolt-seal-log",
    allowedpermissions: [BOLT_SEAL_LOG_CREATE_PERMISSION],
  },
  {
    id: 958,
    path: "/ex-factory/edit-bolt-seal-log/:boltSealId",
    name: "edit-bolt-seal-log",
    component: <AddOrUpdateBoltSealLog editMode={true} />,
    breadcrumbname: "edit-bolt-seal-log",
    layout: "/ex-factory/edit-bolt-seal-log",
    allowedpermissions: [BOLT_SEAL_LOG_EDIT_PERMISSION],
  },
  {
    id: 93,
    path: "/ex-factory/add-security-bolt-seal-log",
    name: "add-security-bolt-seal-log",
    component: <AddSecurityBolt />,
    breadcrumbname: "add-security-bolt-seal-log",
    layout: "/ex-factory/add-security-bolt-seal-log",
    allowedpermissions: [SECURITY_BOLT_CREATE_PERMISSION],
  },
  {
    id: 94,
    path: "/ex-factory/edit-security-bolt-seal-log/:securityBoltId",
    name: "edit-security-bolt-seal-log",
    component: <EditSecurityBolt />,
    breadcrumbname: "edit-security-bolt-seal-log",
    layout: "/ex-factory/edit-security-bolt-seal-log",
    allowedpermissions: [SECURITY_BOLT_EDIT_PERMISSION],
  },
  {
    id: 95,
    path: "/export-customs-clearance/shipment-handover",
    name: "shipment-handover",
    component: <ShipmentOver />,
    breadcrumbname: "shipment-handover",
    layout: "/ex-factory/security-bolt-seal-log",
    allowedpermissions: [
      SHIPMENT_HANDOVER_VIEW_PERMISSION,
      SHIPMENT_HANDOVER_CREATE_PERMISSION,
      SHIPMENT_HANDOVER_EDIT_PERMISSION,
      SHIPMENT_HANDOVER_DELETE_PERMISSION,
    ],
  },
  {
    id: 96,
    path: "/export-customs-clearance/add-shipment-handover",
    name: "add-shipment-handover",
    component: <AddShipmentOver />,
    breadcrumbname: "add-shipment-handover",
    layout: "/export-customs-clearance/add-shipment-handover",
    allowedpermissions: [SHIPMENT_HANDOVER_CREATE_PERMISSION],
  },
  {
    id: 97,
    path: "/export-customs-clearance/edit-shipment-handover/:shipmentOverId",
    name: "edit-shipment-handover",
    component: <EditShipmentOver />,
    breadcrumbname: "edit-shipment-handover",
    layout: "/export-customs-clearance/edit-shipment-handover",
    allowedpermissions: [SHIPMENT_HANDOVER_EDIT_PERMISSION],
  },
  {
    id: 98,
    path: "/report/report-inventory-details",
    name: "report-inventory-details",
    component: <InventoryDetails />,
    breadcrumbname: "report-inventory-details",
    layout: "/report-inventory-details",
    allowedpermissions: [
      INVENTORY_DETAIL_REPORT_VIEW_PERMISSION,
      INVENTORY_DETAIL_REPORT_GENERATE_PERMISSION,
      INVENTORY_DETAIL_REPORT_DELETE_PERMISSION,
    ],
  },
  {
    id: 166,
    path: "/report/report-bolt-seal",
    name: "report-bolt-seal",
    component: <BoltSealReport />,
    breadcrumbname: "report-bolt-seal",
    layout: "report-bolt-seal",
    allowedpermissions: [
      INVENTORY_DETAIL_REPORT_VIEW_PERMISSION,
      INVENTORY_DETAIL_REPORT_GENERATE_PERMISSION,
      INVENTORY_DETAIL_REPORT_DELETE_PERMISSION,
    ],
  },
  {
    id: 196,
    path: "/report/report-post-costing",
    name: "report-post-costing",
    component: <PostCostingReport />,
    breadcrumbname: "report-post-costing",
    layout: "report-post-costing",
    allowedpermissions: [POST_COSTING_REPORT_GENERATE_PERMISSION],
  },
  {
    id: 99,
    path: "/merchandising/purchase-order-new",
    name: "order-details",
    component: <PurchaseOrderNew />,
    breadcrumbname: "Purchase Order",
    layout: "/merchandising/purchase-order-new",
    allowedpermissions: [
      BUYER_PO_PO_WISE_VIEW_PERMISSION,
      BUYER_PO_PO_WISE_CREATE_PERMISSION,
      BUYER_PO_PO_WISE_EDIT_PERMISSION,
      BUYER_PO_PO_WISE_DELETE_PERMISSION,
    ],
  },
  {
    id: 100,
    path: "/merchandising/update-purchase-order-new",
    name: "update-purchase-order-new",
    component: <UpdatePurchaseOrderNew />,
    breadcrumbname: "Update Purchase Order New",
    layout: "/merchandising/update-purchase-order-new",
    allowedpermissions: [BUYER_PO_PO_WISE_EDIT_PERMISSION],
  },
  {
    id: 101,
    path: "/merchandising/view-purchase-order-new/:styleId",
    name: "View style",
    component: <ViewPONew />,
    breadcrumbname: "View Purchase Order New",
    layout: "/merchandising/view-purchase-order-new/:styleId",
    allowedpermissions: [BUYER_PO_PO_WISE_VIEW_PERMISSION],
  },
  {
    id: 102,
    path: "/merchandising/add-purchase-order-new",
    name: "add-order-details",
    component: <AddPurchaseOrderNew />,
    breadcrumbname: "Add Purchase Order New",
    layout: "/merchandising/add-purchase-order-new",
    allowedpermissions: [BUYER_PO_PO_WISE_CREATE_PERMISSION],
  },
  {
    id: 103,
    path: "/notifications",
    name: "notifications",
    component: <Notifications />,
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 104,
    path: "/activity-log/all",
    name: "activity-log-all",
    component: <ActivityLog />,
    allowedpermissions: [ACTIVITY_LOG_VIEW_PERMISSION],
  },
  {
    id: 105,
    path: "/merchandising/packing-requirements",
    name: "packing-requirements",
    component: <PackingReqList />,
    allowedpermissions: [
      PACKING_VIEW_PERMISSION,
      PACKING_CREATE_PERMISSION,
      PACKING_EDIT_PERMISSION,
      PACKING_DELETE_PERMISSION,
    ],
  },
  {
    id: 106,
    path: "/merchandising/packing-requirements/entry",
    name: "packing-requirements-entry",
    component: <CreatePackingReq />,
    allowedpermissions: [PACKING_CREATE_PERMISSION],
  },
  {
    id: 107,
    path: "/merchandising/packing-requirements/edit/:id",
    name: "packing-requirements-update",
    component: <UpdatePackingReq />,
    allowedpermissions: [PACKING_EDIT_PERMISSION],
  },
  {
    id: 108,
    path: "/report/report-inventory-mrr",
    name: "report-inventory-mrr",
    component: <InventoryMRR />,
    breadcrumbname: "report-inventory-mrr",
    layout: "/report-inventory-mrr",
    allowedpermissions: [
      INVENTORY_MRR_REPORT_VIEW_PERMISSION,
      GENERATE_MRR_REPORT_VIEW_PERMISSION,
      DELETE_MRR_REPORT_VIEW_PERMISSION,
    ],
  },
  {
    id: 109,
    path: "/production/packing/packing-confirmation",
    name: "packing-confirmation",
    component: <PackingConfirmation />,
    allowedpermissions: [
      PACKING_VIEW_PERMISSION,
      PACKING_CREATE_PERMISSION,
      PACKING_EDIT_PERMISSION,
      PACKING_DELETE_PERMISSION,
    ],
  },
  {
    id: 110,
    path: "/report/report-packing",
    name: "report-packing",
    component: <PackingReport />,
    breadcrumbname: "report-packing",
    layout: "/report-packing",
    allowedpermissions: [
      INVENTORY_MRR_REPORT_VIEW_PERMISSION,
      GENERATE_MRR_REPORT_VIEW_PERMISSION,
      DELETE_MRR_REPORT_VIEW_PERMISSION,
    ],
  },
  {
    id: 111,
    path: "/configuration/ob-machine",
    name: "ob-machine",
    component: <OBMachineConfig />,
    breadcrumbname: "ob-machine",
    layout: "/ob-machine",
    allowedpermissions: [
      OB_MACHINE_VIEW_PERMISSION,
      OB_MACHINE_CREATE_PERMISSION,
      OB_MACHINE_EDIT_PERMISSION,
      OB_MACHINE_DELETE_PERMISSION,
    ],
  },
  {
    id: 112,
    path: "/configuration/ob-section",
    name: "ob-section",
    component: <OBSectionConfig />,
    breadcrumbname: "ob-section",
    layout: "/ob-section",
    allowedpermissions: [
      OB_SECTION_VIEW_PERMISSION,
      OB_SECTION_CREATE_PERMISSION,
      OB_SECTION_EDIT_PERMISSION,
      OB_SECTION_DELETE_PERMISSION,
    ],
  },
  {
    id: 113,
    path: "/configuration/ob-sam-data-bank",
    name: "ob-sam-data-bank",
    component: <OBSamBankConfig />,
    breadcrumbname: "ob-sam-data-bank",
    layout: "/ob-sam-data-bank",
    allowedpermissions: [
      OB_SAM_DATA_BANK_VIEW_PERMISSION,
      OB_SAM_DATA_BANK_CREATE_PERMISSION,
      OB_SAM_DATA_BANK_EDIT_PERMISSION,
      OB_SAM_DATA_BANK_DELETE_PERMISSION,
    ],
  },
  {
    id: 114,
    path: "/merchandising/operation-bulletin-entry",
    name: "operation-bulletin-entry",
    component: <OBEntry />,
    breadcrumbname: "operation-bulletin-entry",
    layout: "/operation-bulletin-entry",
    allowedpermissions: [OB_VIEW_PERMISSION, OB_CREATE_PERMISSION],
  },
  {
    id: 115,
    path: "/merchandising/operation-bulletin",
    name: "operation-bulletin",
    component: <OperationBulletin />,
    breadcrumbname: "operation-bulletin",
    layout: "/operation-bulletin",
    allowedpermissions: [
      OB_VIEW_PERMISSION,
      OB_CREATE_PERMISSION,
      OB_EDIT_PERMISSION,
      OB_DELETE_PERMISSION,
    ],
  },
  {
    id: 116,
    path: "/merchandising/operation-bulletin/edit/:ob_id",
    name: "operation-bulletin-edit",
    component: <OBEntry edit={true} />,
    breadcrumbname: "operation-bulletin-edit",
    layout: "/operation-bulletin-edit",
    allowedpermissions: [
      OB_VIEW_PERMISSION,
      OB_CREATE_PERMISSION,
      OB_EDIT_PERMISSION,
      OB_DELETE_PERMISSION,
    ],
  },
  {
    id: 117,
    path: "/merchandising/operation-bulletin/show/:ob_id",
    name: "operation-bulletin-show",
    component: <OBEntry view={true} />,
    breadcrumbname: "operation-bulletin-show",
    layout: "/operation-bulletin-show",
    allowedpermissions: [OB_VIEW_PERMISSION],
  },
  {
    id: 118,
    path: "/merchandising/cost-estimate",
    name: "cost-estimate",
    component: <CostEstimate />,
    breadcrumbname: "cost-estimate",
    layout: "/cost-estimate",
    allowedpermissions: [
      COST_ESTIMATE_VIEW_PERMISSION,
      COST_ESTIMATE_CREATE_PERMISSION,
      COST_ESTIMATE_EDIT_PERMISSION,
      COST_ESTIMATE_DELETE_PERMISSION,
    ],
  },
  {
    id: 119,
    path: "/merchandising/cost-estimate-entry",
    name: "cost-estimate-entry",
    component: <AddOrUpdateCostEstimate />,
    breadcrumbname: "cost-estimate-entry",
    layout: "/cost-estimate-entry",
    allowedpermissions: [COST_ESTIMATE_CREATE_PERMISSION],
  },
  {
    id: 120,
    path: "/merchandising/cost-estimate-update/:id",
    name: "cost-estimate-update",
    component: <AddOrUpdateCostEstimate editMode={true} viewMode={false} />,
    breadcrumbname: "cost-estimate-update",
    layout: "/cost-estimate-update",
    allowedpermissions: [COST_ESTIMATE_EDIT_PERMISSION],
  },
  {
    id: 120,
    path: "/merchandising/cost-estimate-view/:id",
    name: "cost-estimate-view",
    component: <AddOrUpdateCostEstimate viewMode={true} editMode={false} />,
    breadcrumbname: "cost-estimate-view",
    layout: "/cost-estimate-view",
    allowedpermissions: [COST_ESTIMATE_VIEW_PERMISSION],
  },
  {
    id: 121,
    path: "/merchandising/amend-sales-contact/:styleId",
    name: "amend-sales-contact",
    component: <UpdateSalesContact isAmend />,
    breadcrumbname: "style",
    layout: "/merchandising/amend-sales-contact",
    allowedpermissions: [SALES_CONTRACT_EDIT_PERMISSION],
  },
  {
    id: 122,
    path: "/configuration/ob-template",
    name: "ob-template",
    component: <OBTemplate />,
    breadcrumbname: "ob-template",
    layout: "/ob-template",
    allowedpermissions: [
      OB_TEMPLATE_VIEW_PERMISSION,
      OB_TEMPLATE_CREATE_PERMISSION,
      OB_TEMPLATE_EDIT_PERMISSION,
      OB_TEMPLATE_DELETE_PERMISSION,
    ],
  },
  {
    id: 123,
    path: "/configuration/ob-template-add",
    name: "ob-template-add",
    component: <OBTemplateForm isAdd />,
    breadcrumbname: "ob-template-add",
    layout: "/ob-template-add",
    allowedpermissions: [OB_TEMPLATE_CREATE_PERMISSION],
  },
  {
    id: 123,
    path: "/configuration/ob-template-edit/:ob_id",
    name: "ob-template-edit",
    component: <OBTemplateForm isEdit />,
    breadcrumbname: "ob-template-edit",
    layout: "/ob-template-edit",
    allowedpermissions: [OB_TEMPLATE_EDIT_PERMISSION],
  },
  {
    id: 124,
    path: "/configuration/buyer/edit/:buyerId",
    name: "buyer-edit",
    component: <CreateOrEditBuyer isEdit />,
    allowedpermissions: [BUYER_EDIT_PERMISSION],
  },
  {
    id: 125,
    path: "/configuration/buying-office/edit/:buyingOfficeId",
    name: "edit-buying-office",
    component: <CreateOrEditBuyingOffice isEdit />,
    allowedpermissions: [BUYING_OFFICE_CREATE_PERMISSION],
  },
  {
    id: 126,
    path: "/settings/notification-slugs",
    name: "notification-slugs",
    component: <NotificationSlugs />,
    layout: "/settings/notification-slugs",
    allowedpermissions: [
      NOTIFICATION_SLUG_VIEW_PERMISSION,
      NOTIFICATION_SLUG_CREATE_PERMISSION,
      NOTIFICATION_SLUG_EDIT_PERMISSION,
      NOTIFICATION_SLUG_DELETE_PERMISSION,
    ],
  },
  {
    id: 127,
    path: "/settings/notification-slugs/create",
    name: "create-slug",
    component: <AddOrUpdateNotificationSlug />,
    layout: "/settings/notification-slugs/create",
    allowedpermissions: [NOTIFICATION_SLUG_CREATE_PERMISSION],
  },
  {
    id: 128,
    path: "/settings/notification-slug-update/:id",
    name: "create-slug",
    component: <AddOrUpdateNotificationSlug editMode={true} viewMode={false} />,
    layout: "/settings/notification-slugs-update",
    allowedpermissions: [NOTIFICATION_SLUG_EDIT_PERMISSION],
  },
  {
    id: 129,
    path: "/settings/notification-settings",
    name: "notification-settings",
    component: <NotificationSettings />,
    layout: "/settings/notification-settings",
    allowedpermissions: [
      NOTIFICATION_SETTING_VIEW_PERMISSION,
      NOTIFICATION_SETTING_CREATE_PERMISSION,
      NOTIFICATION_SETTING_EDIT_PERMISSION,
      NOTIFICATION_SETTING_DELETE_PERMISSION,
    ],
  },
  {
    id: 130,
    path: "/settings/notification-settings/create",
    name: "create-settings",
    component: <AddOrUpdateNotificationSetting />,
    layout: "/settings/notification-slugs/create",
    allowedpermissions: [NOTIFICATION_SETTING_CREATE_PERMISSION],
  },
  {
    id: 131,
    path: "/settings/notification-settings-update/:id",
    name: "update-settings",
    component: (
      <AddOrUpdateNotificationSetting editMode={true} viewMode={false} />
    ),
    layout: "/settings/notification-settings-update",
    allowedpermissions: [NOTIFICATION_SETTING_EDIT_PERMISSION],
  },
  {
    id: 132,
    path: "/settings/notification-settings-view/:id",
    name: "view-settings",
    component: <ViewNotificationSetting editMode={true} viewMode={false} />,
    layout: "/settings/notification-settings-view",
    allowedpermissions: [NOTIFICATION_SETTING_VIEW_PERMISSION],
  },
  {
    id: 133,
    path: "/configuration/challan",
    name: "Rack Configuration",
    component: <ChallanConfig />,
    breadcrumbname: "challan-config",
    layout: "/configuration/challan",
    allowedpermissions: [
      CHALLAN_CREATE_PERMISSION,
      CHALLAN_EDIT_PERMISSION,
      CHALLAN_VIEW_PERMISSION,
      CHALLAN_DELETE_PERMISSION,
    ],
  },
  {
    id: 134,
    path: "/production/daily-line-target",
    name: "Daily Line Target",
    component: <DailyLineTarget />,
    breadcrumbname: "daily-line-target-config",
    layout: "/production/daily-line-target",
    allowedpermissions: [
      DAILY_LINE_TARGET_CREATE_PERMISSION,
      DAILY_LINE_TARGET_EDIT_PERMISSION,
      DAILY_LINE_TARGET_VIEW_PERMISSION,
      DAILY_LINE_TARGET_DELETE_PERMISSION,
    ],
  },
  {
    id: 135,
    path: "/local-purchase-reconciliation",
    name: "Local Purchase Reconciliation",
    component: <LocalPurchaseReconciliation />,
    breadcrumbname: "local-purchase-reconciliation",
    layout: "/import/local-purchase-reconciliation",
    allowedpermissions: [
      LOCAL_PURCHASE_RECONCILIATION_VIEW_PERMISSION,
      LOCAL_PURCHASE_RECONCILIATION_CREATE_PERMISSION,
      LOCAL_PURCHASE_RECONCILIATION_EDIT_PERMISSION,
      LOCAL_PURCHASE_RECONCILIATION_DELETE_PERMISSION,
    ],
  },
  {
    id: 136,
    path: "/local-purchase-reconciliation/create",
    name: "Local Purchase Reconciliation",
    component: <AddLocalPurchaseReconciliation />,
    breadcrumbname: "local-purchase-reconciliation/create",
    layout: "/local-purchase-reconciliation/create",
    allowedpermissions: [LOCAL_PURCHASE_RECONCILIATION_CREATE_PERMISSION],
  },
  {
    id: 137,
    path: "/local-purchase-reconciliation/edit/:reconciliationId",
    name: "Local Purchase Reconciliation",
    component: <UpdateLocalPurchaseReconciliation />,
    breadcrumbname: "local-purchase-reconciliation/edit/:reconciliationId",
    layout: "/local-purchase-reconciliation/edit/:reconciliationId",
    allowedpermissions: [LOCAL_PURCHASE_RECONCILIATION_EDIT_PERMISSION],
  },
  {
    id: 138,
    path: "/report/hourly-production",
    name: "hourly-production",
    component: <HourlyProduction />,
    breadcrumbname: "hourly-production",
    layout: "/hourly-production",
    allowedpermissions: [
      FABRIC_REPORT_VIEW_PERMISSION,
      FABRIC_REPORT_GENERATE_PERMISSION,
      FABRIC_REPORT_DELETE_PERMISSION,
    ],
  },
  {
    id: 139,
    path: "/configuration/garment-category",
    name: "Garment Category",
    component: <GarmentCategoryConfig />,
    breadcrumbname: "garment-category",
    layout: "/configuration/garment-category",
    allowedpermissions: [
      GARMENT_CATEGORY_VIEW_PERMISSION,
      GARMENT_CATEGORY_CREATE_PERMISSION,
      GARMENT_CATEGORY_EDIT_PERMISSION,
      GARMENT_CATEGORY_DELETE_PERMISSION,
    ],
  },
  {
    id: 140,
    path: "/configuration/garment-type",
    name: "Garment Type",
    component: <GarmentTypeConfig />,
    breadcrumbname: "garment-type",
    layout: "/configuration/garment-type",
    allowedpermissions: [
      GARMENT_TYPE_VIEW_PERMISSION,
      GARMENT_TYPE_CREATE_PERMISSION,
      GARMENT_TYPE_EDIT_PERMISSION,
      GARMENT_TYPE_DELETE_PERMISSION,
    ],
  },
  {
    id: 141,
    path: "/cost-of-making/expense-calculation",
    name: "Expense Calcualtion",
    component: <CmExpenseCalculation />,
    breadcrumbname: "cost-of-making",
    layout: "/cm-expense-calculation",
    allowedpermissions: [
      COST_OF_MAKING_LIST,
      COST_OF_MAKING_CREATE,
      COST_OF_MAKING_EDIT,
      COST_OF_MAKING_DELETE,
    ],
  },
  {
    id: 142,
    path: "/cost-of-making/default-data",
    name: "Default Data",
    component: <CmDefaultData />,
    breadcrumbname: "cost-of-making",
    layout: "/cm-default-data",
    allowedpermissions: [
      COST_OF_MAKING_LIST,
      COST_OF_MAKING_CREATE,
      COST_OF_MAKING_EDIT,
      COST_OF_MAKING_DELETE,
    ],
  },
  {
    id: 143,
    path: "/configuration/inquiry-reasons",
    name: "Inquiry Reasons Configuration",
    component: <InquiryReasonsConfig />,
    breadcrumbname: "inquiry-reasons",
    layout: "/configuration/inquiry-reasons",
    allowedpermissions: [
      INQUIRY_REASONS_CREATE_PERMISSION,
      INQUIRY_REASONS_EDIT_PERMISSION,
      INQUIRY_REASONS_VIEW_PERMISSION,
      INQUIRY_REASONS_DELETE_PERMISSION,
    ],
  },
  {
    id: 144,
    path: "/configuration/inquiry-remarks",
    name: "Inquiry Remarks Configuration",
    component: <InquiryRemarksConfig />,
    breadcrumbname: "inquiry-remarks",
    layout: "/configuration/inquiry-remarks",
    allowedpermissions: [
      INQUIRY_REMARKS_CREATE_PERMISSION,
      INQUIRY_REMARKS_EDIT_PERMISSION,
      INQUIRY_REMARKS_VIEW_PERMISSION,
      INQUIRY_REMARKS_DELETE_PERMISSION,
    ],
  },
  {
    id: 145,
    path: "/merchandising/buyer-inquiry",
    name: "Buyer Inquiry",
    component: <BuyerInquiry />,
    breadcrumbname: "buyer-inquiry",
    layout: "/merchandising/buyer-inquiry",
    allowedpermissions: [
      BUYER_INQUIRY_VIEW_PERMISSION,
      BUYER_INQUIRY_CREATE_PERMISSION,
      BUYER_INQUIRY_EDIT_PERMISSION,
      BUYER_INQUIRY_DELETE_PERMISSION,
    ],
  },
  {
    id: 146,
    path: "/merchandising/add-buyer-inquiry",
    name: "add-buyer-inquiry",
    component: <AddBuyerInquiry />,
    breadcrumbname: "Add Buyer Inquiry",
    layout: "/merchandising/add-buyer-inquiry",
    allowedpermissions: [BUYER_INQUIRY_CREATE_PERMISSION],
  },
  {
    id: 147,
    path: "/merchandising/update-buyer-inquiry/:inquiryId",
    name: "update-buyer-inquiry",
    component: <UpdateBuyerInquiry viewMode={false} editMode={true} />,
    breadcrumbname: "Update Buyer Inquiry",
    layout: "/merchandising/update-buyer-inquiry",
    allowedpermissions: [BUYER_INQUIRY_EDIT_PERMISSION],
  },
  {
    id: 148,
    path: "/cost-of-making/details/:buyerInquiryId",
    name: "cost-of-making-details",
    component: <CostOfMakingReport />,
    breadcrumbname: "Update Buyer Inquiry",
    layout: "/cost-of-making/details",
    allowedpermissions: [COST_OF_MAKING_DETAILS],
  },
  {
    id: 149,
    path: "/merchandising/add-style-from-inquiry/:inquiryId",
    name: "Add style",
    component: <AddStyleFromInquiry />,
    breadcrumbname: "style",
    layout: "/merchandising/add-style",
    allowedpermissions: [STYLE_CREATE_PERMISSION],
  },
  {
    id: 150,
    path: "/merchandising/sales-contact-size-specs/:salesContractId",
    name: "sales-contact-size-specs",
    component: <SizeSpecs />,
    breadcrumbname: "style",
    layout: "/merchandising/sales-contact-size-specs",
    allowedpermissions: [SALES_CONTRACT_EDIT_PERMISSION],
  },
  {
    id: 151,
    path: "/merchandising/learning-curves",
    name: "learning-curves",
    component: <LearningCure />,
    breadcrumbname: "learning-curves",
    layout: "/learning-curves",
    allowedpermissions: [
      LEARNING_CURVE_VIEW_PERMISSION,
      LEARNING_CURVE_CREATE_PERMISSION,
      LEARNING_CURVE_EDIT_PERMISSION,
      LEARNING_CURVE_DELETE_PERMISSION,
    ],
  },
  {
    id: 152,
    path: "/merchandising/learning-curves/create",
    name: "learning-curves-create",
    component: <AddOrUpdateLearningCure />,
    breadcrumbname: "learning-curves/create",
    layout: "/learning-curves-create",
    allowedpermissions: [
      LEARNING_CURVE_VIEW_PERMISSION,
      LEARNING_CURVE_CREATE_PERMISSION,
      LEARNING_CURVE_EDIT_PERMISSION,
      LEARNING_CURVE_DELETE_PERMISSION,
    ],
  },
  {
    id: 153,
    path: "/merchandising/learning-curves/:id",
    name: "learning-curves-edit",
    component: <AddOrUpdateLearningCure editMode={true} viewMode={false} />,
    breadcrumbname: "learning-curves/edit",
    layout: "/learning-curves-edit",
    allowedpermissions: [
      LEARNING_CURVE_VIEW_PERMISSION,
      LEARNING_CURVE_CREATE_PERMISSION,
      LEARNING_CURVE_EDIT_PERMISSION,
      LEARNING_CURVE_DELETE_PERMISSION,
    ],
  },
  {
    id: 154,
    path: "/production/sewing-new",
    name: "sewing",
    component: <SewingNew />,
    allowedpermissions: [
      SEWING_VIEW_PERMISSION,
      SEWING_CREATE_PERMISSION,
      SEWING_EDIT_PERMISSION,
      SEWING_DELETE_PERMISSION,
    ],
  },
  {
    id: 155,
    path: "/production/sewing-new/create",
    name: "sewing-create",
    component: <SewingCreate />,
    allowedpermissions: [SEWING_CREATE_PERMISSION],
  },
  {
    id: 156,
    path: "/production/sewing-new/update",
    name: "sewing-update",
    component: <SewingUpdate />,
    allowedpermissions: [SEWING_EDIT_PERMISSION],
  },
  {
    id: 157,
    path: "/merchandising/operation-bulletin/update-manpower/:ob_id",
    name: "operation-bulletin-update-manpower",
    component: <OBEntry manpowerMode={true} />,
    breadcrumbname: "operation-bulletin-update-manpower",
    layout: "/operation-bulletin-update-manpower",
    allowedpermissions: [
      OB_VIEW_PERMISSION,
      OB_CREATE_PERMISSION,
      OB_EDIT_PERMISSION,
      OB_DELETE_PERMISSION,
    ],
  },
  {
    id: 158,
    path: "/configuration/item-category-sequence",
    name: "item-category-sequence",
    component: <ItemCategorySequence />,
    layout: "/configuration/item-category-sequence",
    allowedpermissions: [
      ITEM_CATEGORY_CREATE_PERMISSION,
      ITEM_CATEGORY_EDIT_PERMISSION,
    ],
  },
  {
    id: 159,
    path: "/settings/permission",
    name: "Permission Settings",
    component: <PermissionSettings />,
    breadcrumbname: "permission-settings",
    layout: "/settings/permission",
    allowedpermissions: [
      PERMISSION_VIEW_PERMISSION,
      PERMISSION_CREATE_PERMISSION,
      PERMISSION_EDIT_PERMISSION,
      PERMISSION_DELETE_PERMISSION,
    ],
  },
  {
    id: 160,
    path: "/production/cutting/cutting-item-entry",
    name: "production",
    component: <CreateCuttingItemEntry />,
    allowedpermissions: [
      CUTTING_VIEW_PERMISSION,
      CUTTING_CREATE_PERMISSION,
      CUTTING_EDIT_PERMISSION,
      CUTTING_DELETE_PERMISSION,
    ],
  },
  {
    id: 161,
    path: "/inventory/store/trims-store-entry",
    name: "inventory-trims-store",
    component: <CreateTrimsInventory />,
    allowedpermissions: [
      INVENTORY_VIEW_PERMISSION,
      INVENTORY_CREATE_PERMISSION,
      INVENTORY_EDIT_PERMISSION,
      INVENTORY_DELETE_PERMISSION,
    ],
  },
  {
    id: 162,
    path: "/production/cutting/update",
    name: "cutting-update",
    component: (
      <CuttingContextProvider>
        <UpdateCutting />
      </CuttingContextProvider>
    ),
    allowedpermissions: [CUTTING_CREATE_PERMISSION, CUTTING_EDIT_PERMISSION],
  },
  {
    id: 163,
    path: "/production/assignment/entry",
    name: "assignment-entry",
    component: (
      <AssignmentContextProvider>
        <AssignmentEntry />
      </AssignmentContextProvider>
    ),
    allowedpermissions: [
      ASSIGNMENT_CREATE_PERMISSION,
      ASSIGNMENT_EDIT_PERMISSION,
    ],
  },
  {
    id: 164,
    path: "/production/folding/update",
    name: "folding-update",
    component: (
      <FoldingContextProvider>
        <UpdateFolding />
      </FoldingContextProvider>
    ),
    allowedpermissions: [FOLDING_CREATE_PERMISSION, FOLDING_EDIT_PERMISSION],
  },
  {
    id: 165,
    path: "/export/view-sales-contract/:exportId",
    name: "View Export Sales Contract",
    component: <EditExportSalesContract view={true} />,
    breadcrumbname: "view-sales-contract",
    layout: "/export/view-sales-contract",
    allowedpermissions: [SALES_CONTRACT_COMMERCIAL_VIEW_PERMISSION],
  },
  {
    id: 166,
    path: "/export/view-export-details/:exportId",
    name: "view-export-details",
    component: <EditExportDetails isView={true} />,
    breadcrumbname: "view-export-details",
    layout: "/export/view-export-details",
    allowedpermissions: [EXPORT_DETAILS_VIEW_PERMISSION],
  },
  {
    id: 167,
    path: "/export/view-submission-realization/:exportId",
    name: "view-submission-realization",
    component: <EditSubmissionRealization view={true} />,
    breadcrumbname: "view-submission-realization",
    layout: "/export/view-submission-realization",
    allowedpermissions: [SUBMISSION_REALIZATION_VIEW_PERMISSION],
  },
  {
    id: 168,
    path: "/import/view-b2blc/:importId",
    name: "View B2BLC",
    component: <EditB2bLc view={true} />,
    breadcrumbname: "import",
    layout: "/import/view-b2blc/:importId",
    allowedpermissions: [B2BLC_VIEW_PERMISSION],
  },
  {
    id: 169,
    path: "/import/view-detail/:importId/:supplierType",
    name: "View Details",
    component: <EditDetails view={true} />,
    breadcrumbname: "view-details",
    layout: "/import/view-detail/:importId",
    allowedpermissions: [DAILY_IMPORT_POSITION_VIEW_PERMISSION],
  },
  {
    id: 170,
    path: "/import/view-acceptance-detail/:importId",
    name: "View Acceptance Details",
    component: <AcceptanceDetails view={true} />,
    breadcrumbname: "view-acceptance-detail",
    layout: "/import/view-acceptance-detail/:importId",
    allowedpermissions: [IMPORT_ACCEPTANCE_DETAIL_VIEW_PERMISSION],
  },

  {
    id: 171,
    path: "/merchandising/view-buyer-inquiry/:inquiryId",
    name: "view-buyer-inquiry",
    component: <UpdateBuyerInquiry viewMode={true} editMode={false} />,
    breadcrumbname: "View Buyer Inquiry",
    layout: "/merchandising/view-buyer-inquiry",
    allowedpermissions: [BUYER_INQUIRY_VIEW_PERMISSION],
  },
  {
    id: 172,
    path: "/tanda/process",
    name: "process",
    component: <Process />,
    breadcrumbname: "Process",
    layout: "/tna/process",
    allowedpermissions: [TNA_PROCESS_VIEW_PERMISSION],
  },
  {
    id: 173,
    path: "/tanda/add-process",
    name: "add-process",
    component: <AddProcess />,
    breadcrumbname: "Add Process",
    layout: "/tanda/add-process",
    allowedpermissions: [TNA_PROCESS_CREATE_PERMISSION],
  },
  {
    id: 174,
    path: "/tanda/update-process/:processId",
    name: "Update Process",
    component: <UpdateProcess viewMode={false} />,
    breadcrumbname: "Update Process",
    layout: "/tanda/update-process",
    allowedpermissions: [TNA_PROCESS_EDIT_PERMISSION],
  },
  {
    id: 175,
    path: "/tanda/view-process/:processId",
    name: "Update Process",
    component: <UpdateProcess viewMode={true} />,
    breadcrumbname: "Update Process",
    layout: "/tanda/view-process",
    allowedpermissions: [TNA_PROCESS_VIEW_PERMISSION],
  },
  {
    id: 176,
    path: "/tanda/department",
    name: "department",
    component: <Department />,
    breadcrumbname: "Department",
    layout: "/tna/department",
    allowedpermissions: [TNA_DEPARTMENT_VIEW_PERMISSION],
  },
  {
    id: 177,
    path: "/tanda/add-department",
    name: "add-department",
    component: <AddDepartment />,
    breadcrumbname: "Add department",
    layout: "/tanda/add-department",
    allowedpermissions: [TNA_DEPARTMENT_CREATE_PERMISSION],
  },
  {
    id: 178,
    path: "/tanda/update-department/:deptId",
    name: "Update department",
    component: <UpdateDepartment viewMode={false} />,
    breadcrumbname: "Update department",
    layout: "/tanda/update-department",
    allowedpermissions: [TNA_DEPARTMENT_EDIT_PERMISSION],
  },
  {
    id: 179,
    path: "/tanda/view-department/:deptId",
    name: "View department",
    component: <UpdateDepartment viewMode={true} />,
    breadcrumbname: "View department",
    layout: "/tanda/view-department",
    allowedpermissions: [TNA_DEPARTMENT_VIEW_PERMISSION],
  },
  {
    id: 180,
    path: "/tanda/task",
    name: "task",
    component: <Task />,
    breadcrumbname: "Task",
    layout: "/tna/task",
    allowedpermissions: [TNA_TASK_VIEW_PERMISSION],
  },
  {
    id: 181,
    path: "/tanda/add-task",
    name: "add-department",
    component: <AddTask />,
    breadcrumbname: "Add Task",
    layout: "/tanda/add-task",
    allowedpermissions: [TNA_TASK_CREATE_PERMISSION],
  },
  {
    id: 182,
    path: "/tanda/update-task/:taskId",
    name: "Update Task",
    component: <UpdateTask viewMode={false} />,
    breadcrumbname: "Update Task",
    layout: "/tanda/update-task",
    allowedpermissions: [TNA_TASK_EDIT_PERMISSION],
  },
  {
    id: 183,
    path: "/tanda/view-task/:taskId",
    name: "View Task",
    component: <UpdateTask viewMode={true} />,
    breadcrumbname: "View Task",
    layout: "/tanda/view-task",
    allowedpermissions: [TNA_TASK_VIEW_PERMISSION],
  },
  {
    id: 184,
    path: "/tanda/template",
    name: "TNA Template List",
    component: <TNATemplate />,
    breadcrumbname: "TNA Template List",
    layout: "/tanda/template-list",
    allowedpermissions: [TNA_TEMPLATE_VIEW_PERMISSION],
  },
  {
    id: 185,
    path: "/tanda/add-template",
    name: "TNA Template Add",
    component: <AddOrUpdateTNATemplate />,
    breadcrumbname: "TNA Template Add",
    layout: "/tanda/add-template",
    allowedpermissions: [TNA_TEMPLATE_CREATE_PERMISSION],
  },
  {
    id: 186,
    path: "/tanda/update-template/:templateID",
    name: "TNA Template Update",
    component: <AddOrUpdateTNATemplate isEdit={true} />,
    breadcrumbname: "TNA Template Update",
    layout: "/tanda/update-template",
    allowedpermissions: [TNA_TEMPLATE_EDIT_PERMISSION],
  },
  {
    id: 187,
    path: "/tanda/plan-list",
    name: "TNA Plan List",
    component: <TNAPlan />,
    breadcrumbname: "TNA Plan List",
    layout: "/tanda/plan-list",
    allowedpermissions: [TNA_PLAN_VIEW_PERMISSION],
  },
  {
    id: 188,
    path: "/tanda/add-plan",
    name: "TNA Plan Add",
    component: <AddTNAPlan />,
    breadcrumbname: "TNA Plan Add",
    layout: "/tanda/add-plan",
    allowedpermissions: [TNA_PLAN_CREATE_PERMISSION],
  },
  {
    id: 189,
    path: "/tanda/update-plan/:planID",
    name: "TNA Plan Update",
    component: <AddTNAPlan isEdit={true} />,
    breadcrumbname: "TNA Plan Update",
    layout: "/tanda/update-plan",
    allowedpermissions: [TNA_PLAN_EDIT_PERMISSION],
  },
  {
    id: 190,
    path: "/tanda/todo-list",
    name: "Todo List",
    component: <TodoList />,
    breadcrumbname: "Todo List",
    layout: "/tanda/todo-lis",
    allowedpermissions: [TNA_TO_DO_LIST_VIEW_PERMISSION],
  },
  {
    id: 191,
    path: "/tanda/summary",
    name: "T & A Summary",
    component: <SummaryList />,
    breadcrumbname: "TNA Summary",
    layout: "/tanda/summary",
    allowedpermissions: [TNA_SUMMARY_VIEW_PERMISSION],
  },
  {
    id: 192,
    path: "/tanda/summary/:summaryId",
    name: "T & A Summary",
    component: <SummaryView />,
    breadcrumbname: "TNA Summary View",
    layout: "/tanda/summary",
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 193,
    path: "/tanda/progress/:salesContractId",
    name: "T&A Progress",
    component: <ProgressGraph />,
    breadcrumbname: "TNA progress View",
    layout: "/tanda/progress",
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 194,
    path: "/production/packing/update",
    name: "packing-update",
    component: <PackingConfirmation isEdit={true} />,
    allowedpermissions: [
      PACKING_VIEW_PERMISSION,
      PACKING_CREATE_PERMISSION,
      PACKING_EDIT_PERMISSION,
      PACKING_DELETE_PERMISSION,
    ],
  },
  {
    id: 195,
    path: "/production/fabric-accessories-auto-reconciliation",
    name: "fabric-accessories-auto-reconciliation",
    component: (
      <FabricAutoContextProvider>
        <FabricAccessoriesAutoReconciliation />
      </FabricAutoContextProvider>
    ),
    allowedpermissions: [FABRIC_ACCESSORIES_AUTO_RECONCILIATION_VIEW_PERMISSION],
  },
  {
    id: 197,
    path: "/digitalization/supervisor-checklist",
    name: "digitalization-supervisor-checklist",
    component: <SupervisorChecklist />,
    allowedpermissions: [DIGITALIZATION_CHECKLIST_VIEW_PERMISSION],
  },
  {
    id: 198,
    path: "/digitalization/supervisor-checklist/view/:id",
    name: "digitalization-supervisor-checklist-view",
    component: <SupervisorChecklistView />,
    allowedpermissions: [DIGITALIZATION_CHECKLIST_VIEW_PERMISSION],
  },
  {
    id: 199,
    path: "/digitalization/operator-absenteeism",
    name: "digitalization-operator-absenteeism",
    component: <OperatorAbsenteeism />,
    allowedpermissions: [DIGITALIZATION_ABSENTEEISM_VIEW_PERMISSION],
  },
  {
    id: 200,
    path: "/digitalization/operator-absenteeism/view/:id",
    name: "digitalization-operator-absenteeism-view",
    component: <OperatorAbsenteeismView />,
    allowedpermissions: [DIGITALIZATION_ABSENTEEISM_VIEW_PERMISSION],
  },
  {
    id: 201,
    path: "/digitalization/production-defect",
    name: "digitalization-production-defect",
    component: <ProductionDefect />,
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 202,
    path: "/digitalization/production-defect/view/:id",
    name: "digitalization-production-defect-view",
    component: <ProductionDefectView />,
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 203,
    path: "/configuration/upload-item-ocr",
    name: "upload-item-ocr",
    component: <ItemOCR />,
    layout: "/configuration/upload-item-ocr",
    allowedpermissions: [ITEM_OCR_UPLOAD_PERMISSION],
  },
  {
    id: 204,
    path: "/production-planning/weekends",
    name: "weekends",
    component: <Weekends />,
    layout: "/production-planning/weekends",
    allowedpermissions: [
      PP_WEEKEND_VIEW_PERMISSION,
      PP_WEEKEND_CREATE_PERMISSION,
      PP_WEEKEND_EDIT_PERMISSION,
      PP_WEEKEND_DELETE_PERMISSION,
    ],
  },
  {
    id: 205,
    path: "/production-planning/weekends/create",
    name: "create-weekend",
    component: <AddOrUpdateWeekend />,
    layout: "/production-planning/weekends/create",
    allowedpermissions: [PP_WEEKEND_CREATE_PERMISSION],
  },
  {
    id: 206,
    path: "/production-planning/weekend-update/:id",
    name: "create-weekend",
    component: <AddOrUpdateWeekend editMode={true} viewMode={false} />,
    layout: "/production-planning/weekends-update",
    allowedpermissions: [PP_WEEKEND_EDIT_PERMISSION],
  },
  {
    id: 207,
    path: "/production-planning/holidays",
    name: "holidays",
    component: <Holidays />,
    layout: "/production-planning/holidays",
    allowedpermissions: [
      PP_HOLIDAY_VIEW_PERMISSION,
      PP_HOLIDAY_CREATE_PERMISSION,
      PP_HOLIDAY_EDIT_PERMISSION,
      PP_HOLIDAY_DELETE_PERMISSION,
    ],
  },
  {
    id: 208,
    path: "/production-planning/holidays/create",
    name: "create-holiday",
    component: <AddOrUpdateHoliday />,
    layout: "/production-planning/holidays/create",
    allowedpermissions: [PP_HOLIDAY_CREATE_PERMISSION],
  },
  {
    id: 209,
    path: "/production-planning/holiday-update/:id",
    name: "create-holiday",
    component: <AddOrUpdateHoliday editMode={true} viewMode={false} />,
    layout: "/production-planning/holidays-update",
    allowedpermissions: [PP_HOLIDAY_EDIT_PERMISSION],
  },
  //exception
  {
    id: 210,
    path: "/production-planning/exceptions",
    name: "exceptions",
    component: <Exceptions />,
    layout: "/production-planning/exceptions",
    allowedpermissions: [
      PP_EXCEPTION_VIEW_PERMISSION,
      PP_EXCEPTION_CREATE_PERMISSION,
      PP_EXCEPTION_EDIT_PERMISSION,
      PP_EXCEPTION_DELETE_PERMISSION,
    ],
  },
  {
    id: 211,
    path: "/production-planning/exceptions/create",
    name: "create-exception",
    component: <AddOrUpdateException />,
    layout: "/production-planning/exceptions/create",
    allowedpermissions: [PP_EXCEPTION_CREATE_PERMISSION],
  },
  {
    id: 212,
    path: "/production-planning/exception-update/:id",
    name: "create-exception",
    component: <AddOrUpdateException editMode={true} viewMode={false} />,
    layout: "/production-planning/exceptions-update",
    allowedpermissions: [PP_EXCEPTION_EDIT_PERMISSION],
  },
  //Planning Request
  {
    id: 213,
    path: "/production-planning/planningRequests",
    name: "planningRequests",
    component: <PlanningRequests />,
    layout: "/production-planning/planningRequests",
    allowedpermissions: [
      PP_PLANNING_REQUEST_VIEW_PERMISSION,
      PP_PLANNING_REQUEST_CREATE_PERMISSION,
      PP_PLANNING_REQUEST_EDIT_PERMISSION,
      PP_PLANNING_REQUEST_DELETE_PERMISSION,
    ],
  },
  {
    id: 214,
    path: "/production-planning/planningRequests/create",
    name: "create-planningRequest",
    component: <AddOrUpdatePlanningRequest />,
    layout: "/production-planning/planningRequests/create",
    allowedpermissions: [PP_PLANNING_REQUEST_CREATE_PERMISSION],
  },
  {
    id: 215,
    path: "/production-planning/planningRequests-update/:id",
    name: "create-planningRequest",
    component: <AddOrUpdatePlanningRequest editMode={true} viewMode={false} />,
    layout: "/production-planning/planningRequests-update",
    allowedpermissions: [PP_PLANNING_REQUEST_EDIT_PERMISSION],
  },
  {
    id: 216,
    path: "/production-planning/plan-entry",
    name: "production-planning-entry",
    component: (
      <ProductionPlanningContextProvider>
        <ProductionPlanning />
      </ProductionPlanningContextProvider>
    ),
    layout: "/production-planning/plan-entry",
    allowedpermissions: [PLAN_CREATE_PERMISSION],
  },
  {
    id: 217,
    path: "digitalization/app-role",
    name: "digitalization-app-role",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <RolePanel />
      </Suspense>
    ),
    layout: "digitalization/app-role",
    allowedpermissions: [DIGITALIZATION_SETTINGS_VIEW_PERMISSION],
  },

  {
    id: 218,
    path: "/digitalization/daily-line-plan",
    name: "digitalization-daily-line-plane",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <DailyLinePlanContextProvider>
          <DailyLinePlanList />
        </DailyLinePlanContextProvider>
      </Suspense>
    ),
    layout: "/digitalization/daily-line-plan",
    allowedpermissions: [DAILY_LINE_PLAN_VIEW_PERMISSION],
  },
  {
    id: 223,
    path: "/digitalization/daily-line-plan/add",
    name: "digitalization-daily-line-plane-add",
    component: (
      <DailyLinePlanContextProvider>
        <DailyLinePlanAdd />
      </DailyLinePlanContextProvider>
    ),
    layout: "/digitalization/daily-line-plan/add",
    allowedpermissions: [DAILY_LINE_PLAN_VIEW_PERMISSION],
  },
  {
    id: 224,
    path: "/digitalization/daily-line-plan/edit/:id",
    name: "digitalization-daily-line-plane-edit",
    component: (
      <DailyLinePlanContextProvider>
        <DailyLinePlanEdit isEdit={true} />
      </DailyLinePlanContextProvider>
    ),
    layout: "/digitalization/daily-line-plan/edit/:id",
    allowedpermissions: [DAILY_LINE_PLAN_VIEW_PERMISSION],
  },
  {
    id: 219,
    path: "digitalization/defect/category",
    name: "digitalization-defect-category",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <DefectCategory />
      </Suspense>
    ),
    layout: "digitalization/defect/category",
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 220,
    path: "digitalization/defect/reason",
    name: "digitalization-defect-reason",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <DefectReason />
      </Suspense>
    ),
    layout: "digitalization/defect/reason",
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 221,
    path: "digitalization/defect/responsible",
    name: "digitalization-defect-responsible",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <DefectResponsible />
      </Suspense>
    ),
    layout: "digitalization/defect/responsible",
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 222,
    path: "digitalization/defect/recommendation",
    name: "digitalization-defect-recommendation",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <DefectRecommendation />
      </Suspense>
    ),
    layout: "digitalization/defect/recommendation",
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 223,
    path: "digitalization/defect/category-mapping",
    name: "digitalization-defect-category-mapping",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <DefectCategoryMapping />
      </Suspense>
    ),
    layout: "digitalization/defect/category-mapping",
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 224,
    path: "digitalization/defect/category-mapping/add",
    name: "digitalization-defect-category-mapping-add",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <AddOrEditCategoryMapping />
      </Suspense>
    ),
    layout: "digitalization/defect/category-mapping-add",
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 225,
    path: "digitalization/defect/category-mapping/edit/:id",
    name: "digitalization-defect-category-mapping-edit/:id",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <AddOrEditCategoryMapping />
      </Suspense>
    ),
    layout: "digitalization/defect/category-mapping-edit",
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 226,
    path: "digitalization/defect/line-and-section-mapping",
    name: "digitalization-defect-line-and-section-mapping",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <LineNSectionMapping />
      </Suspense>
    ),
    layout: "digitalization/defect/line-and-section-mapping",
    allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
  },
  {
    id: 227,
    path: "/production-planning/plan-update/:id",
    name: "production-planning-update",
    component: (
      <ProductionPlanningContextProvider>
        <ProductionPlanning editMode={true} />
      </ProductionPlanningContextProvider>
    ),
    layout: "production-planning-update",
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 228,
    path: "/checklist/checklist-questions/list",
    name: "checklist-checklist-questions-list",
    component: <ChecklistQuestionsList />,
    layout: "checklist-checklist-questions",
    allowedpermissions: [CHECKLIST_MENU_VIEW_PERMISSION],
  },
  {
    id: 229,
    path: "/checklist/checklist-questions/add",
    name: "checklist-questions-add",
    component: <ChecklistQuestionsAdd />,
    layout: "/checklist/checklist-questions/add",
    allowedpermissions: [CHECKLIST_MENU_VIEW_PERMISSION],
  },
  {
    id: 230,
    path: "/checklist/checklist-questions/edit/:id",
    name: "checklist-questions-edit",
    component: <ChecklistQuestionsEdit />,
    layout: "/checklist/checklist-questions/edit/:id",
    allowedpermissions: [CHECKLIST_MENU_VIEW_PERMISSION],
  },
  {
    id: 231,
    path: "/digitalization/buyer-wise-checklist",
    name: "buyer-wise-checklist",
    component: <BuyerWiseChecklist />,
    allowedpermissions: [DIGITALIZATION_ROLE_CHECK_PREMISSION],
  },
  {
    id: 241,
    path: "/digitalization/buyer-wise-checklist/view/:id",
    name: "buyer-wise-checklist-view",
    component: <BuyerWiseChecklistView />,
    allowedpermissions: [DIGITALIZATION_ROLE_CHECK_PREMISSION],
  },
  // {
  //   id: 232,
  //   path: "/production-planning/dashboard",
  //   name: "production-planning-dashboard",
  //   component: <ProductionPlanningDashboard />,
  //   layout: "production-planning-dashboard",
  //   allowedpermissions: [PLAN_DASHBOARD_VIEW_PERMISSION],
  // },
  {
    id: 233,
    path: "/digitalization/hourly-production-record",
    name: "digitalization-hourly-production-record",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <HourlyProductionRecord />
      </Suspense>
    ),
    layout: "/digitalization/hourly-production-record",
    allowedpermissions: [HOURLY_PRODUCTION_VIEW_PERMISSION],
  },
  {
    id: 234,
    path: "/digitalization/hourly-production-record/view",
    name: "digitalization-hourly-production-record-view",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <DailyLinePlanContextProvider>
          <HourlyProductionRecordView />
        </DailyLinePlanContextProvider>
      </Suspense>
    ),
    layout: "/digitalization/hourly-production-record-view",
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 235,
    path: "/merchandising/sales-contract-360",
    name: "merchandising-sales-contract-360",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <SalesContract360 />
      </Suspense>
    ),
    layout: "/merchandising/sales-contract-360",
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 238,
    path: "digitalization/user-line-assign",
    name: "digitalization-user-line-assign",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <UserLineAssign />
      </Suspense>
    ),
    layout: "digitalization/user-line-assign",
    allowedpermissions: [DIGITALIZATION_SETTINGS_VIEW_PERMISSION],
  },

  {
    id: 240,
    path: "merchandising/sub-contract-factory",
    name: "merchandising/sub-contract-factory",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <SubContractFactory />
      </Suspense>
    ),
    layout: "merchandising/sub-contract-factory",
    allowedpermissions: [SUBCONTRACT_FACTORY_VIEW_PERMISSION],
  },
  {
    id: 239,
    path: "/app-dashboard/operator-wise-performance",
    name: "app-dashboard/operator-wise-performance",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <OperatorWisePerformance />
      </Suspense>
    ),
    layout: "app-dashboard/operator-wise-performance",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 236,
    path: "/app-dashboard/production-summary",
    name: "app-dashboard/production-summary",
    component: <ProductionSummary />,
    layout: "app-dashboard/production-summary",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 237,
    path: "/app-dashboard/production-details",
    name: "app-dashboard/production-details",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <AppProductDetails />
      </Suspense>
    ),
    layout: "app-dashboard/production-details",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 241,
    path: "/app-dashboard/responsible-for-defects",
    name: "app-dashboard/responsible-for-defects",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <ResponsibleForDefects />
      </Suspense>
    ),
    layout: "app-dashboard/responsible-for-defects",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 242,
    path: "/app-dashboard/operator-wise-defect",
    name: "app-dashboard/operator-wise-defect",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <OperatorWiseDefect />
      </Suspense>
    ),
    layout: "app-dashboard/operator-wise-defect",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 242,
    path: "/app-dashboard/production-tracker",
    name: "app-dashboard/production-tracker",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <ProductionTracker />
      </Suspense>
    ),
    layout: "app-dashboard/production-tracker",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 243,
    path: "/app-dashboard/defect-tracker",
    name: "app-dashboard/defect-tracker",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <DefectTracker />
      </Suspense>
    ),
    layout: "app-dashboard/defect-tracker",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 244,
    path: "/app-dashboard/category-wise-defect",
    name: "app-dashboard/category-wise-defect",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <CategoryWiseDefect />
      </Suspense>
    ),
    layout: "app-dashboard/defect-tracker",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 245,
    path: "/app-dashboard/category-wise-hourly-defect",
    name: "app-dashboard/category-wise-hourly-defect",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <CategoryWiseHourlyDefect />
      </Suspense>
    ),
    layout: "app-dashboard/category-wise-hourly-defect",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 246,
    path: "/app-dashboard/recommendation-against-defects",
    name: "app-dashboard/recommendation-against-defects",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <RecommendationAgainstDefects />
      </Suspense>
    ),
    layout: "app-dashboard/recommendation-against-defects",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 277,
    path: "/digitalization/app-dashboard/:line_id",
    name: "digitalization/app-dashboard/:line_id",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <AppDashboard />
      </Suspense>
    ),
    layout: "app-dashboard/:line_id",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 261,
    path: "/merchandising/units-price",
    name: "Units Price",
    component: <UnitsPrice />,
    breadcrumbname: "UnitsPrice",
    layout: "/merchandising/units-price",
    allowedpermissions: [
          PURCHASE_ORDER_VIEW_PERMISSION,
          PURCHASE_ORDER_CREATE_PERMISSION,
          PURCHASE_ORDER_EDIT_PERMISSION,
          PURCHASE_ORDER_DELETE_PERMISSION,
    ],
  },
  {
    id: 262,
    path: "/merchandising/add-units-price",
    name: "Add Units Price",
    component: <AddUnitsPrice />,
    breadcrumbname: "UnitsPrice",
    layout: "/merchandising/add-units-price",
    allowedpermissions: [PURCHASE_ORDER_CREATE_PERMISSION],
  },
  {
    id: 263,
    path: "/merchandising/update-units-price/:itemId",
    name: "Update Units Price",
    component: <UpdateUnitsPrice />,
    breadcrumbname: "UnitsPrice",
    layout: "/merchandising/update-units-price",
    allowedpermissions: [PURCHASE_ORDER_EDIT_PERMISSION],
  },
  {
    id: 264,
    path: "/merchandising/view-units-price/:styleId",
    name: "View Units Price",
    component: <ViewUnitsPrice />,
    breadcrumbname: "style",
    layout: "/merchandising/view-units-price/:styleId",
    allowedpermissions: [PURCHASE_ORDER_VIEW_PERMISSION],
  },
  {
    id: 277,
    path: "/digitalization/app-dashboard",
    name: "digitalization/app-dashboard/",
    component: (
      <Suspense fallback={<LoadingComponent />}>
        <AppDashboardLine />
      </Suspense>
    ),
    layout: "app-dashboard",
    allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 287,
    path: "/ot-signal-section",
    name: "ot-signal-section",
    component: <OTSignalSection />,
    allowedpermissions: [
      OT_SIGNAL_SECTION_VIEW_PERMISSION,
      OT_SIGNAL_SECTION_CREATE_PERMISSION,
      OT_SIGNAL_SECTION_EDIT_PERMISSION,
      OT_SIGNAL_SECTION_DELETE_PERMISSION,
    ],
  },
  {
    id: 288,
    path: "/ot-signal",
    name: "ot-signal",
    component: <OTSignal />,
    allowedpermissions: [
      OT_SIGNAL_VIEW_PERMISSION,
      OT_SIGNAL_CREATE_PERMISSION,
      OT_SIGNAL_EDIT_PERMISSION,
      OT_SIGNAL_DELETE_PERMISSION,
    ],
  },
  {
    id: 289,
    path: "/digitalization/pp-meeting-date",
    name: "pp-meeting-date",
    component: <PPMeetingDate />,
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 290,
    path: "/digitalization/buyer-inspection-date",
    name: "buyer-inspection-date",
    component: <BuyerInspectionDate />,
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 291,
    path: "/digitalization/buyer-visit-date",
    name: "buyer-visit-date",
    component: <BuyerVisitDate />,
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 292,
    path: "/digitalization/audit-date",
    name: "audit-date",
    component: <AuditDate />,
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 293,
    path: "/digitalization/capacity-of-sample-team",
    name: "capacity-of-sample-team",
    component: <CapacityOfSampleTeam />,
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 294,
    path: "/digitalization/fabric-loading-capacity-of-store",
    name: "fabric-loading-capacity-of-store",
    component: <FabricLoadingCapacityOfStore />,
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 295,
    path: "/digitalization/sample-requisitions",
    name: "sample-requisitions",
    component: <SampleRequisitions />,
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 296,
    path: "/digitalization/overtime-reasons",
    name: "overtime-reasons",
    component: <OvertimeReasons />,
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 297,
    path: "/capacity-bookings",
    name: "capacity-bookings",
    component: <CapacityBookings />,
    allowedpermissions: [CAPACITY_BOOKING_MENU_VIEW_PERMISSION],
  },
  {
    id: 298,
    path: "/configuration/banks",
    name: "banks",
    component: <Banks />,
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 299,
    path: "/digitalization/garments-tracking",
    name: "garments-tracking",
    component: <GarmentsTracking />,
    allowedpermissions: [GARMENTS_TRACKING_VIEW_PERMISSION],
  },
  {
    id: 300,
    path: "/digitalization/update-garments-tracking/:id",
    name: "update-garments-tracking",
    component: <UpdateGarmentsTracking />,
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 301,
    path: "/digitalization/user-list",
    name: "user-list",
    component: <User />,
    allowedpermissions: [DIGITALIZATION_SETTINGS_VIEW_PERMISSION],
  },
  {
    id: 302,
    path: "/configuration/accounts",
    name: "accounts",
    component: <Accounts />,
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 303,
    path: "/configuration/branches",
    name: "branches",
    component: <Branches />,
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 304,
    path: "/configuration/garments-parts",
    name: "garments-parts",
    component: <GarmentsPartsConfig />,
    allowedpermissions: [
      GARMENTS_PARTS_CREATE_PERMISSION,
      GARMENTS_PARTS_EDIT_PERMISSION,
      GARMENTS_PARTS_VIEW_PERMISSION,
      GARMENTS_PARTS_DELETE_PERMISSION,
    ],
  },
  {
    id: 305,
    path: "/production/post-cutting-process",
    name: "post-cutting-process",
    component: <PostCuttingProcess />,
    allowedpermissions: [EMBROIDERY_PRINTING_SMOCKING_PINTUCK_VIEW_PERMISSION],
  },
  {
    id: 306,
    path: "/production/post-cutting-process/entry",
    name: "post-cutting-process-entry",
    component: (
      <PostCuttingProcessContextProvider>
        <PostCuttingProcessEntry />
      </PostCuttingProcessContextProvider>
    ),
    allowedpermissions: [EMBROIDERY_PRINTING_SMOCKING_PINTUCK_VIEW_PERMISSION],
  },
  {
    id: 307,
    path: "/production/post-cutting-process/update",
    name: "post-cutting-process-update",
    component: (
      <PostCuttingProcessContextProvider>
        <UpdatePostCutting />
      </PostCuttingProcessContextProvider>
    ),
    allowedpermissions: [EMBROIDERY_PRINTING_SMOCKING_PINTUCK_VIEW_PERMISSION],
  },
  {
    id: 308,
    path: "/report/report-osr",
    name: "report-osr",
    component: <OSRReport />,
    allowedpermissions: [PRODUCT_TRACK_REPORT_GENERATE_PERMISSION],
  },
  {
    id: 309,
    path: "/production-planning/dashboard",
    name: "production-planning-dashboard",
    component: <ProductionPlanningDashboard2 />,
    layout: "production-planning-dashboard",
    allowedpermissions: [PLAN_DASHBOARD_VIEW_PERMISSION],
  },
  {
    id: 310,
    path: "/digitalization/overtime-signals",
    name: "overtime-signals",
    component: <OvertimeSignals />,
    layout: "overtime-signals",
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 311,
    path: "/tna/invoice-list",
    name: "TNA Invoice List",
    component: <TAndAInvoices />,
    breadcrumbname: "TNA Invoice List",
    layout: "",
    allowedpermissions: [TNA_TO_DO_LIST_VIEW_PERMISSION],
  },
  {
    id: 312,
    path: "/digitalization/sam-justification",
    name: "Sam Justification",
    component: <SamJustification />,
    breadcrumbname: "Sam Justification",
    layout: "",
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
  },
  {
    id: 313,
    path: "/production/post-sewing-process",
    name: "post-sewing-process",
    component: <PostSewingProcess />,
    allowedpermissions: [WASHING_SNAP_BUTTON_VIEW_PERMISSION],
  },
  {
    id: 314,
    path: "/production/post-sewing-process/entry",
    name: "post-sewing-process-entry",
    component: (
      <PostSewingProcessContextProvider>
        <PostSewingProcessEntry />
      </PostSewingProcessContextProvider>
    ),
    allowedpermissions: [WASHING_SNAP_BUTTON_VIEW_PERMISSION],
  },
  {
    id: 315,
    path: "/production/post-sewing-process/update",
    name: "post-sewing-process-update",
    component: (
      <PostSewingProcessContextProvider>
        <UpdatePostSewing />
      </PostSewingProcessContextProvider>
    ),
    allowedpermissions: [WASHING_SNAP_BUTTON_VIEW_PERMISSION],
  },
  {
    id: 316,
    path: "/capacity-bookings/report",
    name: "capacity-bookings-report",
    component: <CapacityBookingsReport />,
    allowedpermissions: [CAPACITY_BOOKING_MENU_VIEW_PERMISSION],
  },
  {
    id: 317,
    path: "/report/report-sewing-and-folding",
    name: "report-sewing-and-folding",
    component: <SwingvsFolding />,
    allowedpermissions: [SWING_VS_FOLDING_REPORT_GENERATE_PERMISSION],
  },
  {
    id: 318,
    path: "/erp-usage/modules",
    name: "erp-usage/modules",
    component: <ERPUsageModule />,
    allowedpermissions: [ERP_USAGE_MODULE_VIEW_PERMISSION],
  },
  {
    id: 319,
    path: "/erp-usage/module-uis",
    name: "erp-usage/module-uis",
    component: <ERPUsageModuleUi />,
    allowedpermissions: [ERP_USAGE_MODULE_VIEW_PERMISSION],
  },
  {
    id: 320,
    path: "/erp-usage/issues",
    name: "erp-usage/issues",
    component: <ERPUsageIssue />,
    allowedpermissions: [ERP_USAGE_ISSUE_VIEW_PERMISSION],
  },
  {
    id: 321,
    path: "/merchandising/buyer-inquiry/special-process/:buyerInquiryId",
    name: "special-process",
    component: <SpecialProcess />,
    allowedpermissions: [BUYER_INQUIRY_EDIT_PERMISSION],
  },
  {
    id: 322,
    path: "/erp-usage/dashboard",
    name: "erp-usage/dashboard",
    component: <ERPUsageDashboard />,
    allowedpermissions: [ERP_USAGE_MODULE_VIEW_PERMISSION],
  },
  {
    id: 323,
    path: "/digitalization/hour",
    name: "digitalization/hour",
    component: <HourPage />,
    allowedpermissions: [HOUR_CONFIGURATION_CREATE_PERMISSION],
  },
];

export default AuthRouter;
