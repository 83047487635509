import { useEffect } from "react";
import { Select } from "antd";
import "./index.css";
import { isArrayAndHasValue } from "../../../../utils/functions";
import AddForm from "../AddForm";

const UpdateForm = (props) => {
  // Props
  const {
    getUniqueTimes,
    openDrawer,
    uniqueTimes,
    getAssignedItems,
    warehouseList,
    getAllWarehouse,
    onWarehouseSelect,
    onRackSelect,
    onBinSelect,
    unitList,
    getAllUnit,
    onSelectUnit,
    onChangeQuantity,
    checkedStyleItems,
    movementType,
    onCopyRow,
    unitConvert,
    assignType,
    gotAssignedItems,
    copyToNextRows,
  } = props;

  useEffect(() => {
    if (openDrawer) {
      getUniqueTimes();
    }
  }, []);

  return (
    <>
      <div style={{ paddingBottom: "10px" }}>
        <Select
          showSearch
          style={{
            width: "200px",
          }}
          placeholder="Select Time"
          optionFilterProp="children"
          size="small"
          onSelect={getAssignedItems}
        >
          {isArrayAndHasValue(uniqueTimes) &&
            uniqueTimes?.map((item, index) => (
              <Select.Option
                key={item?.unique_time}
                value={item?.unique_time}
                label={item?.unique_time}
              >
                {item?.unique_time || ""}
              </Select.Option>
            ))}
        </Select>
      </div>
      {gotAssignedItems && (
        <AddForm
          warehouseList={warehouseList}
          getAllWarehouse={getAllWarehouse}
          onWarehouseSelect={onWarehouseSelect}
          onRackSelect={onRackSelect}
          onBinSelect={onBinSelect}
          unitList={unitList}
          getAllUnit={getAllUnit}
          onSelectUnit={onSelectUnit}
          onChangeQuantity={onChangeQuantity}
          checkedStyleItems={checkedStyleItems}
          movementType={movementType}
          onCopyRow={onCopyRow}
          unitConvert={unitConvert}
          assignType={assignType}
          copyToNextRows={copyToNextRows}
        />
      )}
    </>
  );
};

export default UpdateForm;
