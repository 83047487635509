import { Column } from "@ant-design/plots";
import { Card, Spin, Table } from "antd";

export default function ToDay({ todayData, loadingTodayData, endDateWatch }) {
  const config = {
    data: todayData?.barChart,
    xField: "line",
    yField: "profit",
    label: {
      text: (originData) => {
        // const val = parseFloat(originData.profit);
        // if (val < 0.05) {
        //   return (val * 100).toFixed(1) + "%";
        // }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  const formatNumberWithCommas = (number) => {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const columns = [
    {
      title: "Sewing Line",
      dataIndex: "line",
      key: "line",
    },
    {
      title: "Line Chief",
      dataIndex: "line_chief",
      key: "line_chief",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
    },
    {
      title: "Line Attendance",
      dataIndex: "line_attendance",
      key: "line_attendance",
      align: "right",
      render: (text) => {
        return text ? text?.toLocaleString() : 0;
      },
    },
    {
      title: "Today's Prod/Pc",
      dataIndex: "today_production",
      key: "today_production",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Avg CM/USD",
      dataIndex: "avg_cm",
      key: "avg_cm",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Income CM/USD",
      dataIndex: "income_cm_usd",
      key: "income_cm_usd",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Income CM/Taka",
      dataIndex: "income_cm_taka",
      key: "income_cm_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Expenditure Taka",
      dataIndex: "expenditure_taka",
      key: "expenditure_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Today's Est. Net Profit/Taka",
      dataIndex: "net_profit",
      key: "net_profit",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Ranking on Profitability",
      dataIndex: "rank",
      key: "rank",
    },
  ];
  if (loadingTodayData) return <Spin />;
  return (
    <Card
      title={`Production, income & profit for Line wise: (${endDateWatch?.format(
        "DD-MMM-YY",
      )})`}
      bordered={false}
      style={{
        boxShadow: "none",
      }}
    >
      <Table
        dataSource={todayData?.data}
        columns={columns}
        pagination={false}
        bordered
        loading={loadingTodayData}
      />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Today's Est. Net Profit/taka
        </h3>
        <Column {...config} />
      </div>
    </Card>
  );
}
