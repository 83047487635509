export const color = {
  weekend: "#C4BD97",
  holiday: "#8DB4E2",
  work_on_weekend: "#494529",
  work_on_holiday: "#16365C",
  unplanned: "#FFFFFF",
  on_time: "#E3ECF3",
  delivery_delay: "#FFFF00",
  delivery_early: "#0070C0",
  general_duty: "transparent",
  ot_upto_2h: "transparent",
  ot_more_than_2h: "transparent",
  learning_curve_applied: "red",
};

export const calendarMainStyle = (days, lines) => {
  return {
    display: "grid",
    gridTemplateColumns: `repeat(${days}, 1fr)`,
    gridTemplateRows: `repeat(${lines.length + 2}, auto)`,
    gridColumnGap: "5px",
    gridRowGap: "5px",
    marginTop: "30px",
    width: "100%",
  };
};

export const calendarProductionListStyle = (lineListColumns) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D5EEFF",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    gridRow: "span 3",
    gridColumn: `span ${lineListColumns}`,
    fontWeight: "bold",
  };
};

export const calendarProductionListPStyle = {
  transform: "rotate(270deg)",
  whiteSpace: "nowrap",
  marginTop: "15px",
};

export const calendarHeaderYearMonthStyle = (days, lineListColumns) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D5EEFF",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    gridColumn: `span ${days - lineListColumns}`,
    fontWeight: "bold",
  };
};

export const calendarHeaderDayStyle = (dayWithDutyColumns, day) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ccc",
    padding: "10px",
    fontSize: "14px",
    borderRadius: "5px",
    gridColumn: `span ${dayWithDutyColumns}`,
    backgroundColor: day.weekStatus ? color[day.weekStatus] : "#f1f1f1",
    color: day.weekStatus === "work_on_weekend" ? "white" : "black",
  };
};

export const calendarHeaderWeekdayStyle = (dayWithDutyColumns, day) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ccc",
    padding: "10px",
    fontSize: "14px",
    borderRadius: "5px",
    gridColumn: `span ${dayWithDutyColumns}`,
    backgroundColor: color[day.weekStatus],
    color: day.weekStatus === "work_on_weekend" ? "white" : "black",
  };
};

export const calendarBodyLinesStyle = (lineListColumns) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ccc",
    backgroundColor: "#f9f9f9",
    padding: "10px",
    fontSize: "14px",
    borderRadius: "5px",
    gridRow: `span 1`,
    gridColumn: `span ${lineListColumns}`,
    fontWeight: "bold",
  };
};

export const calendarBodyDateStyle = (dayWithDutyColumns, task) => {
  const bgColor =
    task.delivery_status === "unplanned"
      ? "white"
      : color[task.delivery_status];
  return {
    display: "grid",
    position: "relative",
    gridTemplateColumns: `repeat(${task?.duty}, 1fr)`,
    gridColumnGap: "5px",
    border: "1px solid #cccccc",
    backgroundColor: bgColor,
    color: task.is_apply_learning_curve
      ? color.learning_curve_applied
      : "black",
    // paddingLeft: "6px",
    // paddingBottom: "2px",
    fontSize: "14px",
    borderRadius: "5px",
    gridColumn: `span ${task.duty * dayWithDutyColumns}`,
    cursor: task?.delivery_status === "unplanned" ? "pointer" : "move",
    transition: "background-color 0.1s ease",
    textTransform: "capitalize",
  };
};
