import { useEffect, useState } from "react";
import { Checkbox, Card, Input } from "antd";
import { isArrayAndHasValue } from "utils/functions";
import "./index.css";

const PackingConfirmTable = (props) => {
  // Props
  const { cartonList, selectedCarton, setSelectedCarton } = props;

  // States
  const [dynamicSizeList, setDynamicSizeList] = useState(null);
  const [colorTotals, setColorTotals] = useState(null);
  const [cartonTotals, setCartonTotals] = useState(null);

  //   Effects
  useEffect(() => {
    if (isArrayAndHasValue(cartonList)) {
      const sizeData = cartonList?.flatMap((carton) => {
        return carton?.colors?.flatMap((color) => {
          return color?.sizes?.map((size) => {
            return {
              size_id: size?.size_id,
              size_name: size?.size_name,
            };
          });
        });
      });

      console.log("cartonList :: ", cartonList);
      setColorTotals(cartonList.reduce((acc, carton) => acc + carton?.colors.reduce((accor, curr)=> accor + curr?.color_total, 0), 0))
      setCartonTotals(cartonList.reduce((acc, curr)=> acc + curr?.carton_total, 0))

      // Remove duplicate sizes by size_id and push unique sizes to a new array
      const uniqueSizeData = sizeData.reduce((acc, curr) => {
        if (!acc.some((item) => item.size_id === curr.size_id)) {
          acc.push(curr);
        }
        return acc;
      }, []);

      isArrayAndHasValue(uniqueSizeData) && setDynamicSizeList(uniqueSizeData);
    }
  }, [cartonList]);

  const getSizeValue = (sizes, size_id) => {
    if (isArrayAndHasValue(sizes)) {
      const size = sizes.find((size) => size?.size_id === size_id);
      return Number(size?.quantity) || 0;
    }
  };

  const onCheck = (cartonId) => {
    const selectedCartonCopy = [...selectedCarton];

    console.log('cartonList', cartonList);

    const isExistsAndChecked = selectedCartonCopy?.find(
      (cartonItem) =>
        cartonItem?.carton_id === cartonId && cartonItem?.status === 1,
    );

    if (isExistsAndChecked) {
      // Change the existing carton status to 0
      const foundCartonIndex = selectedCartonCopy?.findIndex(
        (cartonItem) => cartonItem?.carton_id === cartonId,
      );
      selectedCartonCopy[foundCartonIndex]["status"] = 0;
      setSelectedCarton(selectedCartonCopy);
    } else {
      // For first time check, iterate and add all the items
      // Change status to 1, only for checked item
      if (!isArrayAndHasValue(selectedCartonCopy)) {
        const cartonItems = cartonList?.map((cartonItem) => {
          return {
            carton_no: cartonItem?.carton_no,
            carton_id: cartonItem?.id,
            style_id: cartonItem?.style_id,
            status: cartonItem?.id === cartonId ? 1 : 0,
            colors: cartonItem?.colors?.map((color) => {
              return {
                color_id: color?.color_id,
                sizes: color?.sizes?.map((size) => {
                  return {
                    history_id: size?.history_id || 0,
                    size_ratio_id: size?.id,
                    size_id: size?.size_id,
                    quantity: size?.quantity,
                    packing_day: size?.packing_day,
                    transaction_no_per_day: size?.transaction_no_per_day,
                    status: 1,
                  };
                }),
              };
            }),
          };
        });

        // Push all the items to the selected carton array
        selectedCartonCopy.push(...cartonItems);
        setSelectedCarton(selectedCartonCopy);
      } else {
        //  // Change the existing carton status to 1
        const foundCartonIndex = selectedCartonCopy?.findIndex(
          (cartonItem) => cartonItem?.carton_id === cartonId,
        );

        selectedCartonCopy[foundCartonIndex]["status"] = 1;
        setSelectedCarton(selectedCartonCopy);
      }
    }
  };

  const checkAll = (event) => {
    if (!event.target.checked) {
      setSelectedCarton([]);
      return null;
    }

    if (isArrayAndHasValue(cartonList)) {
      // Empty array to store carton objects
      const selectedCartonArray = [];

      // Loop through carton list
      cartonList?.forEach((carton) => {
        //   Make carton object
        const cartonObject = {
          carton_no: carton?.carton_no,
          carton_id: carton?.id,
          style_id: carton?.style_id,
          status: 1,
          colors: carton?.colors?.map((color) => {
            return {
              color_id: color?.color_id,
              sizes: color?.sizes?.map((size) => {
                return {
                  history_id: size?.history_id || 0,
                  size_ratio_id: size?.id,
                  size_id: size?.size_id,
                  quantity: size?.quantity,
                  packing_day: size?.packing_day,
                  transaction_no_per_day: size?.transaction_no_per_day,
                  status: 1,
                };
              }),
            };
          }),
        };

        selectedCartonArray.push(cartonObject);
      });

      isArrayAndHasValue(selectedCartonArray) &&
        setSelectedCarton(selectedCartonArray);
    }
  };

  return (
    <Card className="mt-3" title="Style PO Details">
      <div className="custome-table">
        <table border="1">
          <thead>
            <tr>
              <th colSpan={3} className="bc-orange">
                Carton Details
              </th>
              {dynamicSizeList?.length > 0 && (
                <th colSpan={dynamicSizeList?.length + 2} className="bc-orange">
                  Size Ratio (pc)
                </th>
              )}
              <th className="bc-orange">Actions</th>
            </tr>
            <tr>
              <th
                key="carton_no_header"
                className="bc-java "
                style={{ width: 20 }}
              >
                Carton No
              </th>
              <th key="style_header" className="bc-java ">
                Style
              </th>
              <th key="color_header" className="bc-java ">
                Color
              </th>
              {/* All Sizes */}
              {isArrayAndHasValue(dynamicSizeList) &&
                dynamicSizeList?.map((size, index) => {
                  return (
                    <th
                      key={`header_size_${size?.size_id}`}
                      className="bc-java "
                    >
                      {size?.size_name}
                    </th>
                  );
                })}
              <th key="color_total_header" className="bc-java ">
                Color Total
              </th>
              <th key="total_header" className="bc-java ">
                Total
              </th>
              <th key="checkbox_header" className="bc-java ">
                <Checkbox
                  checked={
                    isArrayAndHasValue(selectedCarton) &&
                    selectedCarton.every((item) => item?.status === 1)
                  }
                  onChange={(e) => checkAll(e)}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {isArrayAndHasValue(cartonList) &&
              cartonList?.map((carton, cartonIndex) => {
                return (
                  <tr key={`carton_row_key_${carton?.id}`}>
                    {/* Carton ID */}
                    <td
                      key={`carton_id_key_${carton?.id}`}
                      className="t-body sticky"
                    >
                      {carton?.carton_no}
                    </td>
                    {/* Style */}
                    <td
                      key={`style_no_key_${carton?.id}`}
                      className="t-body sticky"
                    >
                      {carton?.style_no}
                    </td>
                    {/* Colors */}
                    <td className="t-body sticky">
                      {isArrayAndHasValue(carton?.colors) &&
                        carton?.colors?.map((color, colorIndex) => {
                          return (
                            <tr key={`color_row_key_${color?.id}`}>
                              <td
                                key={`color_name_key_${color?.id}`}
                                className="t-body sticky w-100"
                              >
                                {color?.color_name}
                              </td>
                            </tr>
                          );
                        })}
                    </td>

                    {isArrayAndHasValue(dynamicSizeList) &&
                      dynamicSizeList?.map((sizeObject, index) => {
                        return (
                          <td className="t-body sticky">
                            {isArrayAndHasValue(carton?.colors) &&
                              carton?.colors?.map((color, colorIndex) => {
                                return (
                                  <tr key={`color_row_key_${color?.id}`}>
                                    <td
                                      key={`color_name_key_${color?.id}`}
                                      className="t-body sticky w-100"
                                    >
                                      <Input
                                        size="small"
                                        disabled
                                        value={getSizeValue(
                                          color?.sizes,
                                          sizeObject?.size_id,
                                        )}
                                        style={{ width: "80px" }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </td>
                        );
                      })}

                    {/* Color Total */}
                    <td className="t-body sticky">
                      {isArrayAndHasValue(carton?.colors) &&
                        carton?.colors?.map((color, colorIndex) => {
                          return (
                            <tr
                              key={`color_total_row_key_${color?.id}`}
                              className="multi-row"
                            >
                              <td
                                key={`color_total_key_${color?.id}`}
                                className="t-body sticky w-100"
                              >
                                {color?.color_total}
                              </td>
                            </tr>
                          );
                        })}
                    </td>
                    {/* Total */}
                    <td
                      key={`total_key_${carton?.id}`}
                      className="t-body sticky"
                    >
                      {carton?.carton_total}
                    </td>
                    {/* Checkbox */}
                    <td
                      key={`checkbox_key_${carton?.id}`}
                      className="t-body sticky"
                    >
                      <Checkbox
                        onChange={() => onCheck(carton?.id)}
                        checked={selectedCarton?.find(
                          (item) =>
                            item?.carton_id === carton?.id &&
                            item?.status === 1,
                        )}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
          {
            (colorTotals || cartonTotals) &&
            (<tfoot>
              <tr>
                <td>Total</td>
                <td colSpan={6}></td>
                <td>{colorTotals}</td>
                <td>{cartonTotals}</td>
                <td></td>
              </tr>
            </tfoot>)
          }
        </table>
      </div>
    </Card>
  );
};

export default PackingConfirmTable;
