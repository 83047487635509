import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    InputNumber,
    Select,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    CAPACITY_BOOKINGS_LIST_ENDPOINT,
    CAPACITY_BOOKINGS_ADD_ENDPOINT,
    CAPACITY_BOOKINGS_EDIT_ENDPOINT,
    CAPACITY_BOOKINGS_DELETE_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useLocation } from "react-router";
import moment from "moment";

const CapacityBookings = () => {
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [capacity_bookingForm] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;
  const currentYear = moment().year();
  const futureYears = Array.from({ length: 10 }, (_, i) => currentYear + i);

  // DataSource for Months
  const dataSource = [
    { key: "1", month: "January" },
    { key: "2", month: "February" },
    { key: "3", month: "March" },
    { key: "4", month: "April" },
    { key: "5", month: "May" },
    { key: "6", month: "June" },
    { key: "7", month: "July" },
    { key: "8", month: "August" },
    { key: "9", month: "September" },
    { key: "10", month: "October" },
    { key: "11", month: "November" },
    { key: "12", month: "December" },
  ];

  // Columns for Table
  const columns = [
    { title: "Months", dataIndex: "month", key: "month" },
    {
      title: "No of Operators, Ironmen and Helpers",
      dataIndex: "no_of_operators_ironmen_and_helpers",
      key: "no_of_operators_ironmen_and_helpers",
      render: (text, record, index) => (
        <Form.Item
          name={["monthly_data", index, "no_of_operators_ironmen_and_helpers"]}
          rules={[{ required: false, message: "This field is required" }]}
          style={{ margin: 0 }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      ),
    },
    {
      title: "SAM",
      dataIndex: "sam",
      key: "sam",
      render: (text, record, index) => (
        <Form.Item
          name={["monthly_data", index, "sam"]}
          rules={[{ required: false, message: "This field is required" }]}
          style={{ margin: 0 }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      ),
    },
    {
      title: "Efficiency %",
      dataIndex: "efficiency",
      key: "efficiency",
      render: (text, record, index) => (
        <Form.Item
          name={["monthly_data", index, "efficiency"]}
          rules={[{ required: false, message: "This field is required" }]}
          style={{ margin: 0 }}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      ),
    },
  ];

  const getAllCapacityBookings = useCallback(async (filterValues) => {
    const payload = {
      page: filterValues?.page || currentPage,
      ...filterValues,
    };

    setLoading(true);

    const query = `${CAPACITY_BOOKINGS_LIST_ENDPOINT}`;
    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      setMetaData({
        defaultPageSize: response?.data?.data?.per_page,
        current: response?.data?.data?.current_page,
        defaultCurrent: response?.data?.data?.current_page,
        total: response?.data?.data?.total,
      });
      setTableData(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting Capacity Bookings data");
    }
  }, [currentPage]);

  useEffect(() => {
    getAllCapacityBookings();
  }, [refresh, getAllCapacityBookings]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    capacity_bookingForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedCapacityBooking = async (id) => {
    setLoading(true);
    const response = await deleteData(`${CAPACITY_BOOKINGS_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Capacity Booking Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  // Update API call to include "month"
  const editSelectedCapacityBooking = async (formValues) => {
    setLoading(true);

    // Map through formValues to include months
    const bodyData = {
      year: formValues?.year,
      monthly_data: dataSource.map((monthItem, index) => ({
        month: monthItem.month,
        no_of_operators_ironmen_and_helpers: formValues?.monthly_data[index]?.no_of_operators_ironmen_and_helpers,
        sam: formValues?.monthly_data[index]?.sam,
        efficiency: formValues?.monthly_data[index]?.efficiency,
      })),
    };

    const response = await putData(
      `${CAPACITY_BOOKINGS_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Capacity Booking Edited Successfully");
      refetch();
      capacity_bookingForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewCapacityBooking = async (formValues) => {
    setLoading(true);

    // Ensure the month field is included
    const bodyData = {
      year: formValues.year,
      monthly_data: dataSource.map((monthItem, index) => ({
        month: monthItem.month,
        no_of_operators_ironmen_and_helpers: formValues?.monthly_data[index]?.no_of_operators_ironmen_and_helpers,
        sam: formValues?.monthly_data[index]?.sam,
        efficiency: formValues?.monthly_data[index]?.efficiency,
      })),
    };

    const response = await postData(CAPACITY_BOOKINGS_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Capacity Booking Created Successfully");
      refetch();
      capacity_bookingForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    const { capacity_bookings } = record;
    const monthly_data = capacity_bookings.map((monthData, index) => ({
      month: monthData.month,
      no_of_operators_ironmen_and_helpers: monthData.no_of_operators_ironmen_and_helpers,
      sam: monthData.sam,
      efficiency: monthData.efficiency,
    }));

    capacity_bookingForm.setFieldsValue({
      id: record?.id,
      year: record?.year,
      monthly_data,
    });

    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <Dropdown.Button
          overlay={
            <Menu
              items={[
                {
                  label: (
                    <Popconfirm
                      title="Are you sure you want to delete?"
                      onConfirm={() => deleteSelectedCapacityBooking(record?.id)}
                    >
                      <span>
                        <DeleteTwoTone />
                      </span>
                      <span style={{ color: "red", marginLeft: 5 }}>
                        Delete
                      </span>
                    </Popconfirm>
                  ),
                  key: "1",
                },
                {
                  label: (
                    <>
                      <span>
                        <EditTwoTone />
                      </span>
                      <span
                        type="button"
                        style={{ marginLeft: 5 }}
                        onClick={() => openModalForEdit(record)}
                      >
                        Edit
                      </span>
                    </>
                  ),
                  key: "2",
                },
              ]}
            />
          }
        ></Dropdown.Button>
      ),
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                addButtonText="Add New"
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Capacity Bookings</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllCapacityBookings}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="capacity_booking"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: changePage,
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Capacity Bookings" : "Create Capacity Bookings"}
        onCancel={onCloseModal}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
        width={800}
      >
        <Form
          form={capacity_bookingForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedCapacityBooking : createNewCapacityBooking}
          initialValues={{
            year: isEdit ? capacity_bookingForm.getFieldValue("year") : undefined,
            monthly_data: dataSource.map((item, index) => ({
              month: item.month,
              no_of_operators_ironmen_and_helpers: "",
              sam: "",
              efficiency: "",
            })),
          }}
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="year"
                label="Select a Year"
                rules={[{ required: true, message: "Year is Required" }]}
              >
                <Select
                  placeholder="Select a Year"
                  showSearch
                  style={{ width: "100%" }}
                >
                  {futureYears.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6} style={{ marginBottom: "20px" }}>
            <Col span={24}>
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                className="less-padding-table"
              />
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={onCloseModal}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CapacityBookings;
