import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { debounce } from "lodash";
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AMEDMENT_ADD,
  AMENDMENT_DOC_ADD,
  COST_SHEET_DOC_ADD,
  GET_BRAND_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  REASONS_ADD,
  REASONS_LIST,
  SALES_CONTRACT_ADD,
  SALES_CONTRACT_STYLE_DETAILS_LIST,
  SALES_CONTRACT_UPDATE,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, getWithData, postData } from "../../../apiServices/common";
import { alertPop } from "../../../apiServices/common/helper";
import HandleNotification from "../../../common/Notification";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
  showTotalPrice,
} from "../../../utils/functions";
import DocumentUpload from "../../Common/DocumentUpload";
import AmendmentTable from "./AmendmentTable";
import BuyingOfficeBankAcc from "./BuyingOfficeBankAcc";
import OrganizationBankAcc from "./OrganizationBankAccount";
import POList from "./PoList";
import StyleDetailsInitial from "./StyleDetails";
import CustomDatePicker from "common/CustomDatePicker";

const { Option } = Select;

const AddSalesContactForm = forwardRef((props, ref) => {
  const { permitInfo, view, isAmend, isEdit, setIsSubmitting } = props;
  const [form] = Form.useForm();
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [brand, setBrand] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [costSheetList, setCostSheetList] = useState([]);

  // const [buyingOfficeBankAccs, setBuyingOfficeBankAccs] = useState([]);
  const [buyingOfficeAccs, setBuyingOfficeAccs] = useState([]);
  const [selectedBuyingOfficeBankAccs, setSelectedBuyingOfficeBankAccs] =
    useState([]);

  const [selectedBuyingOfficeShortName, setSelectedBuyingOfficeShortName] =
    useState();
  const [selectedBuyerOffice, setSelectedBuyerOffice] = useState();
  const [selectedBuying, setSelectedBuying] = useState();
  const [styleList, setStyleList] = useState([]);

  const [selectedOrgBankAccs, setSelectedOrgBankAccs] = useState([]);
  const [selectedBuyerShortName, setSelectedBuyerShortName] = useState();
  const [selectedBuyerStylePO, setSelectedBuyerStylePO] = useState();
  const [salesContractID, setSalesContractID] = useState();
  const [contractDate, setContractdate] = useState();
  const [poList, setPoList] = useState([]);
  const [selectedStyles, setSelectedStyle] = useState([]);
  const [orgId, setOrgId] = useState();
  const [reasons, setReasons] = useState(null);
  const [addedReason, setAddedReason] = useState(null);
  const [totalStyleOrderQty, setTotalStyleOrderQty] = useState(0);
  const [totalStyleOrderValue, setTotalStyleOrderValue] = useState(0);

  const navigate = useNavigate();
  var buying_accounts = [];
  var org_accounts = "";
  var organization_id = [];
  var documents = [];
  var style_id = [];
  var style_detail_id = [];
  var pcd = [];
  var style_order_quantity = [];
  var style_unit_price = [];
  var style_description = [];
  var internal_po = [];
  var purchase_detail_id = [];
  var original_po = [];
  var delivery_date = [];
  var po_status = [];
  var costSheets = [];

  useImperativeHandle(ref, () => ({
    submit() {
      form
        .validateFields()
        .then(async (values) => {
          setIsSubmitting(true);
          if (!isArrayAndHasValue(selectedStyles)) {
            HandleNotification(
              "error",
              "bottomRight",
              "Please add a style",
              null,
            );
            setIsSubmitting(false);
            return null;
          }

          if (isArrayAndHasValue(documentList)) {
            documentList.forEach((item) => {
              documents.push({
                document_id: item?.id,
                is_delete: item?.is_delete || 0,
                amendment_id:
                  permitInfo && isArrayAndHasValue(permitInfo?.amendments)
                    ? permitInfo.amendments[permitInfo.amendments.length - 1][
                        "id"
                      ]
                    : 0,
              });
            });
          } else {
            if (!permitInfo?.sales_contract?.id) {
              HandleNotification(
                "error",
                "bottomRight",
                "Please add document!",
                null,
              );
              setIsSubmitting(false);
              return null;
            }
          }

          if (isArrayAndHasValue(costSheetList)) {
            costSheetList.forEach((item) => {
              costSheets.push({
                id: item?.id,
                is_delete: item?.is_delete || 0,
              });
            });
          } else {
            HandleNotification(
              "error",
              "bottomRight",
              "Please add cost sheet!",
              null,
            );
            setIsSubmitting(false);
            return null;
          }

          if (selectedBuyingOfficeBankAccs?.length) {
            selectedBuyingOfficeBankAccs.forEach((item) => {
              buying_accounts.push(item?.account_id || item?.id);
            });
          }

          if (selectedOrgBankAccs?.length) {
            selectedOrgBankAccs.forEach((item) => {
              org_accounts = item?.id;
              organization_id = orgId;
            });
          }

          if (selectedStyles?.length > 0) {
            selectedStyles.forEach((item) => {
              if (item?.style_id) {
                style_detail_id.push(item?.id);
                style_id.push(item.style_id);
              } else {
                style_id.push(item.id);
              }
              if (item.order_quantity) {
                style_order_quantity.push(item.order_quantity);
              } else {
                style_order_quantity.push(item.quantity);
              }
              if (item.unit_price) {
                style_unit_price.push(item.unit_price);
              } else {
                style_unit_price.push(item.price);
              }
              pcd.push(item.pcd);
              style_description.push(item.description);
            });
          } else {
            setIsSubmitting(false);
            return alertPop("error", "Please Enter Style Info.");
          }

          // if (values.use_both_style_po) {

          original_po = [];
          if (isArrayAndHasValue(poList) && poList.length > 0) {
            poList.forEach((item) => {
              original_po.push(item?.original_po);
              delivery_date.push(item?.delivery_date);
              internal_po.push(item?.internal_po);
              po_status.push("Created");
            });
          } else {
            if (values?.use_both_style_po && poList.length === 0) {
              setIsSubmitting(false);
              return alertPop("error", "Please Enter PO.");
            }
          }
          // }
          // else {
          //   if(isArrayAndHasValue(poList)) {
          //     poList.forEach((item) => {
          //       original_po.push(item?.original_po);
          //       delivery_date.push(item?.delivery_date);
          //       internal_po.push(item?.internal_po);
          //       po_status.push("Created");
          //     });
          //   }
          //   else {
          //     return alertPop("error", "Please Enter PO.");
          //   }
          // }

          // if ((poList?.length > 0) && (!values.use_both_style_po)) {
          //   poList.forEach((item) => {
          //     original_po.push(item?.original_po);
          //     delivery_date.push(item?.delivery_date);
          //     internal_po.push(item?.internal_po);
          //     po_status.push("Created");
          //   });
          // } else {
          //   return alertPop("error", "Please Enter PO.");
          // }

          if (
            values?.use_both_style_po &&
            original_po.some(
              (item) => item === null || item === undefined || item === "",
            )
          ) {
            setIsSubmitting(false);
            return alertPop("error", "Please Enter Original PO.");
          }

          if (
            values?.use_both_style_po &&
            internal_po.some(
              (item) => item === null || item === undefined || item === "",
            )
          ) {
            setIsSubmitting(false);
            return alertPop("error", "Please Enter Internal PO.");
          }

          if (
            values?.use_both_style_po &&
            delivery_date.some(
              (item) => item === null || item === undefined || item === "",
            )
          ) {
            setIsSubmitting(false);
            return alertPop("error", "Please Enter Delivery Date.");
          }

          if (
            values?.use_both_style_po &&
            po_status.some(
              (item) => item === null || item === undefined || item === "",
            )
          ) {
            setIsSubmitting(false);
            return alertPop("error", "Please Enter PO Status.");
          }

          if (permitInfo?.sales_contract?.id) {
            poList.forEach((item) => {
              purchase_detail_id.push(item.id);
            });

            if (pcd?.some((item) => item === null || item === undefined)) {
              setIsSubmitting(false);
              return alertPop("error", "Please Enter PCD Date.");
            }

            if (
              style_order_quantity?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              setIsSubmitting(false);
              return alertPop("error", "Please Enter Order Quantity.");
            }

            if (
              style_unit_price?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              setIsSubmitting(false);
              return alertPop("error", "Please Enter Unit Price.");
            }

            if (
              isAmend &&
              !isArrayAndHasValue(documents) &&
              documents.length === 0
            ) {
              setIsSubmitting(false);
              return alertPop("error", "Please add document!");
            }

            values.id = permitInfo?.sales_contract.id;
            values["documents"] = documents;
            values["buying_account_id"] = buying_accounts;
            values["style_id"] = style_id;
            values["style_detail_id"] = style_detail_id;
            values["pcd"] = pcd;
            values["style_order_quantity"] = style_order_quantity;
            values["style_unit_price"] = style_unit_price;
            values["style_description"] = style_description;
            values["organization_account_id"] = org_accounts;
            values["organization_id"] = organization_id;
            values["original_po"] = original_po;
            values["internal_po"] = internal_po;
            values["purchase_detail_id"] = purchase_detail_id;
            values["po_status"] = po_status;
            values["address"] = "address";
            values["delivery_date"] = delivery_date;
            values["cost_sheet_documents"] = costSheets;

            const amendmentObject = {
              ...(isAmend && { reason_ids: values?.reasons || null }),
              ...(isArrayAndHasValue(permitInfo.amendments) && {
                id:
                  permitInfo.amendments[permitInfo.amendments.length - 1][
                    "id"
                  ] || null,
              }),
              total_style_order_quantity: totalStyleOrderQty,
              total_style_order_value: totalStyleOrderValue,
              document_ids: values?.documents?.map((item) => item?.document_id),
            };

            let response = null;
            const editQuery = `${
              SALES_CONTRACT_UPDATE + permitInfo?.sales_contract?.id
            }`;
            const amendQuery = `${AMEDMENT_ADD}/${permitInfo?.sales_contract?.id}`;

            response = await postData(isAmend ? amendQuery : editQuery, {
              ...values,
              amendment: amendmentObject,
            });
            setIsSubmitting(false);
            if (response) {
              setIsSubmitting(false);
              alertPop(
                "success",
                `Sales Contract ${
                  isAmend ? "Amended" : "Updated"
                } Successfully`,
              );

              navigate("/merchandising/sales-contact");
            }
          } else {
            if (pcd?.some((item) => item === null || item === undefined)) {
              setIsSubmitting(false);
              return alertPop("error", "Please Enter PCD Date.");
            }

            if (
              style_order_quantity?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              setIsSubmitting(false);
              return alertPop("error", "Please Enter Order Quantity.");
            }

            if (
              style_unit_price?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              setIsSubmitting(false);
              return alertPop("error", "Please Enter Unit Price.");
            }

            values["documents"] = documents;
            values["buying_account_id"] = buying_accounts;
            values["style_id"] = style_id;
            values["style_order_quantity"] = style_order_quantity;
            values["pcd"] = pcd;
            values["style_unit_price"] = style_unit_price;
            values["style_description"] = style_description;
            values["organization_account_id"] = org_accounts;
            values["organization_id"] = organization_id;
            values["original_po"] = original_po;
            values["internal_po"] = internal_po;
            values["po_status"] = po_status;
            values["address"] = "address";
            values["delivery_date"] = delivery_date;
            values.status = "1";
            values["cost_sheet_documents"] = costSheets;

            const amendmentObject = {
              id: 0,
              reason_ids: values?.reasons || null,
              total_style_order_quantity: totalStyleOrderQty,
              total_style_order_value: totalStyleOrderValue,
              document_ids: values?.documents?.map((item) => item?.document_id),
            };

            const response = await postData(SALES_CONTRACT_ADD, {
              ...values,
              amendment: amendmentObject,
            });

            if (response && response?.code === 200) {
              alertPop("success", "Sales Contract Created Successfully");
              navigate("/merchandising/sales-contact");
            }
          }
        })
        .catch((errorInfo) => {
          setIsSubmitting(false);
        });
    },
    discart() {
      form.resetFields();
    },
  }));

  useEffect(() => {
    if (isArrayAndHasValue(selectedStyles)) {
      // Calculate and set total style quantity
      const totalStyleQty = selectedStyles?.reduce(
        (acc, currentValue) =>
          acc + parseFloat(currentValue["order_quantity"] || 0),
        0,
      );
      totalStyleQty && setTotalStyleOrderQty(totalStyleQty);

      // Calculate and set total style value
      const totalStyleValue = selectedStyles?.reduce(
        (acc, currentValue) => acc + parseFloat(showTotalPrice(currentValue)),
        0,
      );

      if (totalStyleValue) {
        setTotalStyleOrderValue(totalStyleValue);
        form.setFieldValue("contract_value", totalStyleValue);
      }
    }
  }, [selectedStyles, form]);

  const getBuyingOfficeList = async (filterValues) => {
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: filterValues?.per_page || 10,
    };
    const response = await getData(GET_BUYING_OFFICE_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOffice(response?.data?.data?.data || []);
    }
  };

  const throttledGetBuyingOfficeList = debounce(getBuyingOfficeList, 500);

  const getBuyersList = async (val, formOnLoad, filterValues) => {
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: 10,
    };
    const response = await getData(
      `/api/buying_office/${val}/edit`,
      false,
      bodyData,
    );
    if (formOnLoad) {
      form.resetFields(["buyer_id"]);
    }
    setBuyingOfficeAccs(response?.data?.data?.account_info);
    if (response) {
      setBuyer(response?.data?.data?.buyer || []);
    }
  };

  const getBrand = async (value) => {
    let res = await getData(GET_BRAND_ENDPOINT);

    if (res) {
      setBrand(res?.data?.data?.data);
    }
  };

  const selectBuyingOfficeShortName = async (value) => {
    let res = await getData(`/api/buying_office/${value}/edit`);

    if (res) {
      setSelectedBuyingOfficeShortName(res?.data?.data.short_name);
      setPoList([]);
    }
  };

  const selectBuyerShortName = async (value) => {
    let res = await getData(`/api/buyers/${value}/edit`);

    if (res) {
      setSelectedBuyerShortName(res?.data?.data.short_name);
      setPoList([]);
    }
  };

  const selectBuyerStylePO = async (value) => {
    let res = await getData(`/api/buyers/${value}/edit`);

    if (res) {
      setSelectedBuyerStylePO(res?.data?.data.use_both_style_po);

      form.setFieldsValue({
        use_both_style_po:
          res?.data?.data.use_both_style_po === "Yes" ? true : false,
      });
      setPoList([]);
    }
  };

  const styleDetailsList = async () => {
    let paylaod = {
      buying_office_id: selectedBuying,
      buyer_id: selectedBuyerOffice,
    };

    let res = await getWithData(
      SALES_CONTRACT_STYLE_DETAILS_LIST,
      undefined,
      paylaod,
    );

    if (res) {
      setStyleList(res?.data?.data);
    }
  };

  const getReasonsList = async (filterValues) => {
    const query = `${REASONS_LIST}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 1000,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setReasons(response?.data?.data?.data);
    }
  };

  const onReasonChange = (event) => {
    setAddedReason(event.target.value);
  };

  const addNewReason = async (event) => {
    event.preventDefault();
    const query = `${REASONS_ADD}`;
    const bodyData = {
      name: addedReason,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setAddedReason(null);
      HandleNotification("success", "Reason added successfully");
      getReasonsList();
    }
  };

  const confirmChangeStatus = (val) => {
    Modal.confirm({
      title: "Warning!",
      content: (
        <div>
          <p>You are going to change Contract status!</p>
        </div>
      ),
      onOk() {
        // form.resetFields(["status"]);
      },
      onCancel() {
        form.resetFields(["status"]);
      },
    });
  };

  useEffect(() => {
    if (selectedBuyerOffice && selectedBuying) {
      styleDetailsList();
    }
  }, [selectedBuyerOffice, selectedBuying]);

  useEffect(() => {
    const selected_buying_office_name = permitInfo
      ? permitInfo?.buying_office?.buying_office_info?.name
      : null;
    getBrand();
    getReasonsList();
  }, [permitInfo]);

  useEffect(() => {
    getBuyingOfficeList();
  }, []);

  useEffect(() => {
    if (
      selectedBuyerShortName &&
      selectedBuyingOfficeShortName &&
      contractDate
    ) {
      setSalesContractID(
        selectedBuyingOfficeShortName +
          "_" +
          selectedBuyerShortName +
          "_" +
          moment(contractDate).format("DD-MM-YYYY"),
      );
    }

    form.setFieldsValue({
      sales_contract_unique_id: salesContractID,
    });
  });

  useEffect(() => {
    if (permitInfo?.sales_contract?.id) {
      if (
        !buyingOffice?.some(
          (item) => item?.id === permitInfo?.sales_contract?.buying_office_id,
        )
      ) {
        const clone = [
          permitInfo?.sales_contract?.buying_office_info,
          ...buyingOffice,
        ];

        setBuyingOffice(clone);
      }

      if (
        !buyer?.some(
          (item) => item?.id === permitInfo?.sales_contract?.buyer_id,
        )
      ) {
        setBuyer((oldarr) => [
          ...oldarr,
          {
            id: permitInfo?.sales_contract?.buyer_id,
            name: permitInfo?.sales_contract?.buyer_name,
          },
        ]);
      }
      getBuyersList(permitInfo?.sales_contract?.buying_office_id);
      setSelectedBuying(permitInfo?.sales_contract?.buying_office_id);
      setSelectedBuyerOffice(permitInfo?.sales_contract?.buyer_id);
      form.setFieldsValue({
        sales_contract_unique_id:
          permitInfo?.sales_contract?.sales_contract_unique_id,
        reference_no: permitInfo?.sales_contract?.reference_no,
        buying_office_id: permitInfo?.sales_contract?.buying_office_id,
        buyer_id: permitInfo?.sales_contract?.buyer_id,
        contract_value: permitInfo?.sales_contract?.contract_value,
        contract_date: permitInfo?.sales_contract?.contract_date
          ? moment(permitInfo?.sales_contract?.contract_date, "YYYY-MM-DD")
          : undefined,
        expiry_date: permitInfo?.sales_contract?.expiry_date
          ? moment(permitInfo?.sales_contract?.expiry_date, "YYYY-MM-DD")
          : undefined,
        pcd_date: permitInfo?.sales_contract?.pcd_date
          ? moment(permitInfo?.sales_contract?.pcd_date, "YYYY-MM-DD")
          : undefined,
        status: parseInt(permitInfo?.sales_contract?.status),
        use_both_style_po:
          permitInfo?.sales_contract?.use_both_style_po === "Yes"
            ? true
            : false,
      });
      setSalesContractID(permitInfo?.sales_contract?.sales_contract_unique_id);

      setSelectedStyle(permitInfo?.styleDetail);

      const documents = isAmend
        ? []
        : permitInfo?.documents?.map((item) => {
            return {
              id: item?.id,
              name: item?.document_name,
              document: item?.document,
              path: item?.document,
              is_delete: item?.is_delete,
            };
          });
      setDocumentList(documents);
      
      const costSheets = isArrayAndHasValue(permitInfo?.cost_sheet_documents) && permitInfo?.cost_sheet_documents?.map((item) => {
            return {
              id: item?.id,
              name: item?.document_name,
              document: item?.document,
              path: item?.document,
              is_delete: item?.is_delete,
            };
          });

      setCostSheetList(isAmend ? [] : costSheets);

      setPoList(permitInfo?.purchase_orders);
      if (permitInfo?.purchase_orders.length > 0) {
        let po_length = permitInfo?.purchase_orders?.length;
        let count = 0;
        permitInfo?.purchase_orders.map((item) => {
          if (item?.po_status == "Closed") {
            count = count + 1;
          }
        });
        // if (po_length === count) {
        //   setEditableSetContractStatus(false);
        // }
      }

      // setSelectedBuyerOffice(
      //   permitInfo.sales_contract?.organization_account_info?.id
      // );

      var test2 = permitInfo?.sales_contract?.organization_account_info
        ? permitInfo?.sales_contract?.organization_account_info?.bank_info
        : null;

      test2["account_no"] = permitInfo?.sales_contract
        ?.organization_account_info
        ? permitInfo?.sales_contract?.organization_account_info?.account_number
        : null;

      test2["organization_account_id"] =
        permitInfo?.sales_contract?.organization_account_id;
      test2["address"] =
        permitInfo?.sales_contract?.organization_account_info?.bank_info?.name +
        " - " +
        permitInfo?.sales_contract?.organization_account_info?.branch_info
          ?.address;

      setSelectedOrgBankAccs((oldarr) => [
        ...oldarr,
        permitInfo?.sales_contract?.organization_account_info?.bank_info,
      ]);
      setSelectedBuyingOfficeBankAccs((oldarr) => [
        ...oldarr,
        ...permitInfo?.sales_contract.buying_accounts_infos,
      ]);

      var test = permitInfo?.buying_office?.buying_office_account_info
        ? permitInfo?.buying_office?.buying_office_account_info?.bank_info
        : null;

      if (test) {
        test["address"] =
          permitInfo?.buying_office?.buying_office_account_info?.branch_info
            ?.name +
          " - " +
          permitInfo?.buying_office?.buying_office_account_info?.branch_info
            ?.address;
      }
    }
  }, [permitInfo]);

  return (
    <div className="containt-body">
      <Form layout="vertical" form={form} name="control-hooks">
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract Uniqe ID"
                name="sales_contract_unique_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select ID (auto generate)!",
                  },
                ]}
              >
                <Input
                  className="w-100"
                  disabled={true}
                  value={salesContractID || undefined}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract No."
                name="reference_no"
                rules={[
                  {
                    required: true,
                    message: "Please input Sales Contract No!",
                  },
                ]}
              >
                <Input className="w-100" disabled={view} size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Contract Date"
                name="contract_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Contract Date !",
                  },
                ]}
              >
                <CustomDatePicker
                  className="w-100"
                  disabled={view}
                  onChange={(value) => {
                    setContractdate(value);
                    setPoList([]);
                  }}
                  size="small"
                />
                {/* <DatePicker
                  className="w-100"
                  disabled={view}
                  onChange={(value) => {
                    setContractdate(value);
                    setPoList([]);
                  }}
                  size="small"
                /> */}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Buying Office"
                name="buying_office_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select Buying Office!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Buying Office"
                  onChange={(value) => {
                    if (value) {
                      getBuyersList(value, true);
                      setSelectedBuying(value);
                      selectBuyingOfficeShortName(value);
                      setSelectedBuyingOfficeBankAccs([]);
                      setSelectedBuyerOffice();
                      setSelectedOrgBankAccs([]);
                      setSelectedStyle([]);
                      setStyleList([]);
                    } else {
                      setBuyer([]);
                      setSelectedBuying(null);
                      form.resetFields(["buying_office_id", "buyer_id"]);
                    }
                  }}
                  optionFilterProp="children"
                  SelectedBuyerOffice
                  disabled={view}
                  size="small"
                  onSearch={(value) =>
                    throttledGetBuyingOfficeList({ name: value })
                  }
                  allowClear
                  onClear={() => getBuyingOfficeList()}
                >
                  {isArrayAndHasValue(buyingOffice) &&
                    buyingOffice.map((sup) => (
                      <Option value={sup?.id} key={sup?.id}>
                        {sup.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Buyer"
                name="buyer_id"
                rules={[{ required: true, message: "Please Select Buyer!" }]}
              >
                <Select
                  showSearch
                  placeholder="Select a Buyer."
                  onChange={(value) => {
                    if (value) {
                      setSelectedBuyerOffice(value);
                      setStyleList([]);
                      setSelectedStyle([]);
                      setSelectedOrgBankAccs([]);
                      selectBuyerShortName(value);
                      selectBuyerStylePO(value);
                    } else {
                      setSelectedBuyerOffice(null);
                      form.resetFields(["buyer_id"]);
                    }
                  }}
                  optionFilterProp="children"
                  disabled={view}
                  size="small"
                  onSearch={(value) =>
                    getBuyersList(selectedBuying, false, { name: value })
                  }
                  allowClear
                  onClear={() => getBuyersList(selectedBuying, true)}
                >
                  {isArrayAndHasValue(buyer) &&
                    buyer.map((buy) => (
                      <Option value={buy?.id} key={buy?.id}>
                        {buy.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Contract Value"
                name="contract_value"
                rules={[
                  {
                    required: true,
                    message: "Contract value required. Add style and value.",
                  },
                ]}
              >
                <InputNumber
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={5}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Expiry Date"
                name="expiry_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Expiry Date !",
                  },
                ]}
              >
                <CustomDatePicker
                  className="w-100"
                  disabled={view}
                  size="small"
                />
                {/* <DatePicker className="w-100" disabled={view} size="small" /> */}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Contract Status "
                name="status"
                rules={[
                  { required: false, message: "Please input Contract Status!" },
                ]}
              >
                <Select
                  showSearch
                  onSelect={confirmChangeStatus}
                  placeholder="Select a Contract Status"
                  optionFilterProp="children"
                  disabled={view || !permitInfo ? true : false}
                  defaultValue={1}
                  size="small"
                >
                  <Option value={1} key={1}>
                    Active
                  </Option>
                  <Option value={2} key={2}>
                    Deactive
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={4}>
              <Form.Item
                label="PCD Date"
                name="pcd_date"
                rules={[
                  {
                    required: true,
                    message: "Please input PCD Date !",
                  },
                ]}
              >
                <DatePicker className="w-100" disabled={view} size="small" />
              </Form.Item>
            </Col> */}
            <Col className="gutter-row" span={isAmend ? 4 : 0}>
              <Form.Item
                label="Amendment Reasons"
                name="reasons"
                hidden={!isAmend}
                rules={[
                  {
                    required: isAmend ? true : false,
                    message: "Please input Amendment Reason",
                  },
                ]}
              >
                <Select
                  size="small"
                  placeholder="Reasons"
                  mode="multiple"
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Enter new reason"
                          size="small"
                          value={addedReason}
                          onChange={onReasonChange}
                          // ref={inputRef}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          size="small"
                          onClick={addNewReason}
                        >
                          Add
                        </Button>
                      </Space>
                    </>
                  )}
                  options={reasons?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                name="use_both_style_po"
                label="Use both style and PO"
                required
                className="collection-create-form_last-form-item"
                valuePropName="checked"
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>

      {(isAmend || view || permitInfo) && (
        <AmendmentTable amendments={permitInfo?.amendments} />
      )}

      {!isAmend && (
        <BuyingOfficeBankAcc
          view={view}
          title="Buying Bank Acc"
          form={form}
          buyingOfficeAccs={buyingOfficeAccs}
          selectedBuyingOfficeBankAccs={selectedBuyingOfficeBankAccs}
          setSelectedBuyingOfficeBankAccs={setSelectedBuyingOfficeBankAccs}
        />
      )}

      {!isAmend && (
        <OrganizationBankAcc
          view={view}
          form={form}
          title="Organization Bank Acc"
          selectedBuyerOffice={selectedBuyerOffice}
          selectedOrgBankAccs={selectedOrgBankAccs}
          setSelectedOrgBankAccs={setSelectedOrgBankAccs}
          setOrgId={setOrgId}
        />
      )}

      <StyleDetailsInitial
        title="Style Details"
        selectedStyles={selectedStyles}
        setSelectedStyle={setSelectedStyle}
        styleList={styleList}
        setStyleList={setStyleList}
        view={view}
        totalStyleOrderQty={totalStyleOrderQty}
        totalStyleOrderValue={totalStyleOrderValue}
      />

      {!isAmend && (
        <POList
          view={view}
          poList={poList}
          setPoList={setPoList}
          salesContractID={salesContractID}
        />
      )}

      {!view && (
        <Row gutter={0}>
          <Col span={12}>
            <DocumentUpload
              DOC_ADD={COST_SHEET_DOC_ADD}
              setFileList={setCostSheetList}
              filesList={costSheetList}
              header={!view ? "Cost Sheet *" : null}
              view={view}
            />
          </Col>
          <Col span={12}>
            <DocumentUpload
              DOC_ADD={AMENDMENT_DOC_ADD}
              setFileList={setDocumentList}
              filesList={documentList}
              header={!view ? "Attachment *" : null}
              view={view}
            />
          </Col>
        </Row>
      )}
    </div>
  );
});

export default AddSalesContactForm;
