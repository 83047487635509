import React, { Fragment, useState, useEffect } from "react";
import { Card, Table, Button, Dropdown, Menu, Affix, Popconfirm, Row, Col } from "antd";
import BodyHeader from "../../../Common/BodyHeader";
import Header from "../../../Common/Header";
import { isMobileView } from "../../../Common/AppHelper";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { MoreOutlined, EditOutlined, DeleteTwoTone } from "@ant-design/icons";
import {
    COST_OF_MAKING_RESOURCE
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, deleteData } from "../../../../apiServices/common";
import { hasPermission, formatNumberWithCommas, isArrayAndHasValue, commaSeparateNumber } from "../../../../utils/functions";
import {
  IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_DELETE_PERMISSION,
} from "../../../../routes/permissions";
import moment from "moment";
import HandleNotification from "../../../../common/Notification";
import CustomFilter from "../../../Common/CustomFilter";

const CostOfMakingReport = (props) => {
  // Props
  const { width } = props;

  const { buyerInquiryId } = useParams();

  console.log('buyerInquiryId', buyerInquiryId);

  // States
  const [detailsData, setDetailsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();
  const [showFilter, setShowFilter] = useState(false);

  const getCmReportData = async (buyerInquiryId) => {
    const query = `${COST_OF_MAKING_RESOURCE}/${buyerInquiryId}`;
    const response = await getData(query);

    console.log('response', response);
    if(response && response?.data?.code === 200) {
        setDetailsData(response?.data?.data);
    }
  }

    useEffect(() => {
        if(buyerInquiryId) {
            getCmReportData(buyerInquiryId);
        }
    }, [buyerInquiryId]);

    const getTotalValueFirst = (data, type) => {
        const totalData = data?.reduce((acc, curr) => {
            return acc + (curr && curr[type] && curr[type] || 0);
        }, 0);

        return commaSeparateNumber(Number(totalData).toFixed(2));
    }

    const getTotalValueSecond = (data, type) => {
        const totalData = data?.reduce((acc, curr) => {
            return acc + (curr && curr[type] && curr[type] || 0);
        }, 0);

        return commaSeparateNumber(Number(totalData).toFixed(2));
    }

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Cost Of Making Details"
            subTitle="Buyer Inquiry No: "
          ></Header>
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
            <div className="custome-table" style={{ marginBottom: 50 }}>
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th key="header_capacity" className="bc-blue py-3 px-1 move-left">Cost Head</th>
                            <th key="header_capacity" className="bc-blue py-3 px-1 move-right">Line Cost per day</th>
                            <th key="header_capacity" className="bc-blue py-3 px-1 move-right">Available Minute</th>
                            <th key="header_capacity" className="bc-blue py-3 px-1 move-right">Produced Minute</th>
                            <th key="header_capacity" className="bc-blue py-3 px-1 move-right">Rate Per Minute</th>
                            <th key="header_capacity" className="bc-blue py-3 px-1">Breakeven CM</th>
                        </tr>
                    </thead>

                    <tbody>
                    {isArrayAndHasValue(detailsData?.table_data) ? (
                    <>
                        {detailsData?.table_data?.map((obj, index) => {
                            return (
                                <tr key={`table_parent_key_${index}`}>
                                    <td className="t-body sticky p-1 move-left" style={{ fontSize: 14 }}>
                                    {obj?.name}
                                    </td>
                                    <td className="t-body sticky p-1 move-right" style={{ fontSize: 14}}>
                                        ${commaSeparateNumber(Number(obj?.line_cost_per_day).toFixed(2))}
                                    </td>
                                    <td className="t-body sticky p-1 move-right" style={{ fontSize: 14}}>
                                    {commaSeparateNumber(Number(obj?.available_minute).toFixed(2))}
                                    </td>
                                    <td className="t-body sticky p-1 move-right" style={{ fontSize: 14}}>
                                    {commaSeparateNumber(Number(obj?.produced_minute).toFixed(2))}
                                    </td>
                                    <td className="t-body sticky p-1 move-right" style={{ fontSize: 14}}>
                                    {Number(obj?.rate_per_minute).toFixed(2)}
                                    </td>
                                    {index === 0 && (
                                            <td className="t-body sticky bc-light-orange" style={{ fontSize: 14 }} rowSpan={detailsData?.table_data?.length+1}>
                                                <strong>${Number(detailsData?.cm_info?.breakeven_cm).toFixed(2) ?? 0}</strong>
                                            </td>
                                        )

                                    }
                                </tr>
                            );
                        })}
                        
                        <td className="t-body sticky move-left" colSpan={1} rowSpan={4}  style={{fontSize: 14}}>
                            <strong>Total Cost</strong>
                        </td>
                        <td className="t-body sticky move-right"  style={{fontSize: 14}}>
                            <strong>${commaSeparateNumber(Number(detailsData?.table_totals?.total_line_cost_per_day).toFixed(2)) ?? 0}</strong>
                        </td>
                        <td className="t-body sticky move-right"  style={{fontSize: 14}}>
                            <strong>{commaSeparateNumber(Number(detailsData?.table_totals?.total_available_minute).toFixed(2)) ?? 0}</strong>
                        </td>
                        <td className="t-body sticky move-right"  style={{fontSize: 14}}>
                            <strong>{commaSeparateNumber(Number(detailsData?.table_totals?.total_produced_minute).toFixed(2)) ?? 0}</strong>
                        </td>
                        <td className="t-body sticky move-right"  style={{fontSize: 14}}>
                            <strong>{commaSeparateNumber(Number(detailsData?.table_totals?.total_rate_per_minute).toFixed(4)) ?? 0}</strong>
                        </td>
                    </>
                    ) : (
                    ""
                    )}
                    </tbody>
                </table>
            </div>
            <div className="custome-table" style={{ marginBottom: 25 }}>
                <Row>
                    <Col span={6}>
                        <table style={{ width: "100%" }}>
                                <tr>
                                    <td className="p-1 move-left">Standard Minute (SMV)</td>
                                    <td className="p-1 move-right"><strong>{detailsData?.ob_info?.standard_minute}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-1 move-left">Manpower Per Line</td>
                                    <td className="p-1 move-right"><strong>{detailsData?.ob_info?.manpower_per_line}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-1 move-left">Working Hour Per Day</td>
                                    <td className="p-1 move-right"><strong>{detailsData?.ob_info?.working_hour_per_day}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-1 move-left">Production Per Hours (Pcs)</td>
                                    <td className="p-1 move-right"><strong>{detailsData?.ob_info?.production_per_hours}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-1 move-left">Production Per Day (Pcs)</td>
                                    <td className="p-1 move-right"><strong>{detailsData?.ob_info?.production_per_day}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-1 move-left">Factory Efficiency</td>
                                    <td className="p-1 move-right"><strong>{Number(detailsData?.table_totals?.factory_efficiency).toFixed(2) ?? 0}%</strong></td>
                                </tr>
                        </table>
                    </Col>
                    <Col span={6} offset={1}>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td className="p-1 move-left">Working Day</td>
                                <td className="p-1 move-right"><strong>{Number(detailsData?.cm_default_data?.working_days_per_month).toFixed(2)}</strong></td>
                            </tr>
                            <tr>
                                <td className="p-1 move-left">Conversion Rate</td>
                                <td className="p-1 move-right"><strong>{Number(detailsData?.cm_default_data?.dollar_conversion_rate)}</strong></td>
                            </tr>
                            <tr>
                                <td className="p-1 move-left">Total Manpower (Avg)</td>
                                <td className="p-1 move-right"><strong>{commaSeparateNumber(Number(detailsData?.average_manpower))}</strong></td>
                            </tr>
                        </table>
                    </Col>
                    <Col span={8} offset={2}>
                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th key="header_capacity" className="bc-orange p-2 move-left">Cost Head</th>
                                    <th key="header_capacity" className="bc-orange p-2 move-right">Average Cost</th>
                                    <th key="header_capacity" className="bc-orange p-2 move-right">Per Manpower</th>
                                </tr>
                            </thead>

                            <tbody>
                                {isArrayAndHasValue(detailsData?.table_data) ? (
                                <>
                                    {detailsData?.table_data?.map((obj, index) => {
                                        return (
                                            <tr key={`table_parent_key_${index}`}>
                                                <td className="t-body sticky p-1 move-left" style={{ fontSize: 14 }}>
                                                    {obj?.name}
                                                </td>
                                                <td className="t-body sticky p-1 move-right" style={{ fontSize: 14 }}>
                                                    {commaSeparateNumber(Number(obj?.avg_cost_value).toFixed(2))}
                                                </td>
                                                <td className="t-body sticky p-1 move-right" style={{ fontSize: 14 }}>
                                                    {commaSeparateNumber(Number(obj?.per_manpower).toFixed(2))}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    
                                    <td className="t-body sticky move-left" colSpan={1} rowSpan={4}  style={{fontSize: 14}}>
                                    <strong>Total Cost</strong>
                                    </td>
                                    <td className="t-body sticky move-right"  style={{fontSize: 14}}>
                                        <strong>{getTotalValueSecond(detailsData?.table_data, 'avg_cost_value') ?? 0}</strong>
                                    </td>
                                    <td className="t-body sticky move-right"  style={{fontSize: 14}}>
                                        <strong>{commaSeparateNumber(Number(detailsData?.total_per_manpower).toFixed(2)) ?? 0}</strong>
                                    </td>
                                </>
                                ) : (
                                ""
                                )}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        </Card>
        
      </div>
    </Fragment>
  );
};

export default CostOfMakingReport;
