import { Button, Collapse, Form, Layout, Space } from "antd";
import {
  EDIT_FOLDING_PRODUCT_ENDPOINT,
  GET_FOLDING_PRODUCT_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { postData } from "apiServices/common";
import HandleNotification from "common/Notification";
import AppPageHeader from "common/PageHeader";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import { FoldingContext } from "context/FoldingContext/FoldingContextProvider";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { FOLDING_EDIT_PERMISSION } from "routes/permissions";
import { hasPermission, isArrayAndHasValue } from "utils/functions";
import CuttingForm from "../CreateForm/FoldingForm";
import FoldingDetails from "../FoldingDetails/FoldingDetails";

const { Content } = Layout;
const { Panel } = Collapse;

const UpdateFolding = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Contexts
  const { foldingTableData, getStyleList, setFoldingTableData, setShowFactoryInputs, subContractStatus, setSubContractStatus } =
    useContext(FoldingContext);

  // Antd
  const [form] = Form.useForm();

  // Router
  const navigate = useNavigate();
  const location = useLocation();

  const getFoldingProductInfo = useCallback(
    async ({
      sales_contract_id,
      style_id,
      buying_office_id,
      folding_date,
      transaction_no_per_day,
      factory_id
    }) => {
      if(buying_office_id) {
        setShowFactoryInputs(false);
      } else if(factory_id) {
        setShowFactoryInputs(true);
      }
      const bodyData = {
        sales_contract_id: sales_contract_id ? Number(sales_contract_id) : null,
        style_id: style_id ? Number(style_id) : null,
        buying_office_id: buying_office_id ? Number(buying_office_id) : null,
        factory_id: factory_id ? Number(factory_id) : null,
        folding_date: folding_date
          ? moment(folding_date).format("YYYY-MM-DD")
          : null,
        transaction_no_per_day: transaction_no_per_day
          ? Number(transaction_no_per_day)
          : null,
      };

      const query = `${GET_FOLDING_PRODUCT_ENDPOINT}`;

      const response = await postData(query, bodyData);

      if (response && response?.code === 200) {
        setSubContractStatus(response?.data?.form_data?.subcontract_status)
        // Get searched style list for dropdown
        getStyleList({ style_no: response?.data?.form_data?.style_no });

        // Set form data
        form.setFieldsValue({
          style_id:
            response?.data?.form_data?.active_sales_contract_map_info?.style_id,
          buying_office_id: response?.data?.form_data?.buying_office_info?.id,
          buying_office_name:
            response?.data?.form_data?.buying_office_info?.name,
          buyer_id: response?.data?.form_data?.buyer_info?.id,
          buyer_name: response?.data?.form_data?.buyer_info?.name,
          factory_id: response?.data?.form_data?.factory_info?.id,
          factory_name: response?.data?.form_data?.factory_info?.name,
          address: response?.data?.form_data?.factory_info?.address,
          sales_contract_id:
            response?.data?.form_data?.active_sales_contract_map_info
              ?.active_sales_contract_info?.id,
          sales_contract_name:
            response?.data?.form_data?.active_sales_contract_map_info
              ?.active_sales_contract_info?.reference_no,
          order_quantity: response?.data?.form_data
            ?.active_sales_contract_map_info?.order_quantity
            ? Number(
                response?.data?.form_data?.active_sales_contract_map_info
                  ?.order_quantity,
              )
            : null,
          use_both_style_po:
            response?.data?.form_data?.active_sales_contract_map_info
              ?.active_sales_contract_info?.use_both_style_po,
        });

        // Set cutting details table data
        setFoldingTableData(
          isArrayAndHasValue(response?.data?.table_data)
            ? response?.data?.table_data
            : null,
        );
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something Went Wrong!",
          null,
        );
      }
    },
    // Ignored dependencies to stop loop
    [form, setFoldingTableData, getStyleList],
  );

  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(location.search);

    // Extracting query parameters
    const queryParamsObj = {
      sales_contract_id: searchParams.get("sales_contract_id"),
      style_id: searchParams.get("style_id"),
      buying_office_id: searchParams.get("buying_office_id"),
      folding_date: searchParams.get("folding_date"),
      transaction_no_per_day: searchParams.get("transaction_no_per_day"),
    };

    if (queryParamsObj) {
      getFoldingProductInfo(queryParamsObj);
    }
  }, [location.search, getFoldingProductInfo]);

  const goBackToFoldingRoute = () => {
    navigate("/production/folding");
  };

  const extra = (
    <Space>
      <Button size="small" onClick={goBackToFoldingRoute}>
        Back
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => form.submit()}
        disabled={!hasPermission([FOLDING_EDIT_PERMISSION]) || isSubmitting}
      >
        {hasPermission([FOLDING_EDIT_PERMISSION]) ? "Update" : "No Permission"}
      </Button>
    </Space>
  );

  const onFinishUpdate = async (values) => {
    setIsSubmitting(true);
    const cuttingProducts =
      isArrayAndHasValue(foldingTableData) &&
      foldingTableData?.map((item) => {
        return {
          folding_product_id: item?.folding_products?.folding_product_id,
          history_id: item?.folding_products?.history_id,
          internal_po: item?.internal_po,
          original_po: item?.original_po,
          po_map_id: item?.po_map_id,
          color_id: item?.color?.id,
          sub_style_id: item?.sub_style_info?.id || null,
          size_id: item?.size?.id,
          folding_piece: item?.folding_products?.folding_piece,
          remain_piece: item?.folding_products?.remain_piece,
          cutting_day: item?.folding_products?.cutting_day,
          transaction_no_per_day:
            item?.folding_products?.transaction_no_per_day,
          folding_day: item?.folding_products?.folding_day,
        };
      });

    const query = `${EDIT_FOLDING_PRODUCT_ENDPOINT}`;
    const bodyData = {
      sales_contract_id: values?.sales_contract_id,
      buying_office_id: values?.buying_office_id,
      style_id: values?.style_id,
      folding_date: values?.folding_date,
      use_both_style_po: values?.use_both_style_po,
      buyer_id: values?.buyer_id,
      folding_products: cuttingProducts,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        "Folding updated successfully",
        null,
      );
      // setIsSubmitting(false);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader title={"Folding Update"} extra={extra} />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Order Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinishUpdate} form={form}>
                <CuttingForm
                  form={form}
                  isUpdate={true}
                  onStyleChangeEnableSubmitBtn={() => setIsSubmitting(false)}
                />
              </Form>
            </Panel>
          </Collapse>
          {/* Folding Details Table */}
          <FoldingDetails />
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default UpdateFolding;
