export default function Matrix11by11({ backgroundColor }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(11, 1fr)",
        gridTemplateRows: "repeat(11, 1fr)",
        gap: 1,
        backgroundColor: backgroundColor || "white",
      }}
    >
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
    </div>
  );
}
