import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Switch,
  Select,
  Radio,
  Space,
  Checkbox,
} from "antd";
import AppPageHeader from "../../../common/PageHeader";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  onCreateBuyer,
  onUpdateSelectedBuyer,
  onGetUserList,
} from "../../../redux/actions";
import CreateBrandForm from "../../Brand/CreateBrand";
import CreateMerchandiserForm from "../CreateMerchandiserForm";
import { isArrayAndHasValue } from "../../../utils/functions";

const { Option } = Select;
const BuyerCreateForm = (props) => {
  // Props
  const { selectedBuyer, isEdit, refresh, refetch } = props;

  // States
  const [createdUserList, setCreatedUserList] = useState([]);
  const [userList, setUserList] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const selectedBuyerState = useSelector(({ buyer }) => buyer.selectedBuyer);
  const brandList = useSelector(({ brand }) => brand.brandList);
  // const users = useSelector(({ user }) => user.userList);
  const users = useSelector(({ user }) => user.userList);

  // Antd
  const [form] = Form.useForm();
  const { Title } = Typography;

  // Others
  const navigate = useNavigate();

  useEffect(() => {
    form.resetFields();
  }, [selectedBuyer, selectedBuyerState, form]);

  useEffect(() => {
    dispatch(onGetUserList()); // Fetch user list from the backend
  }, [dispatch]);

  useEffect(() => {
    if (users) {
      if (isEdit && selectedBuyer?.merchandiser_for_procurement_info) {
        const userListCopy = [...users];
        userListCopy.push(selectedBuyer?.merchandiser_for_procurement_info);
        setUserList(userListCopy);
      } else {
        setUserList(users);
      }
    }
  }, [users, isEdit, selectedBuyer]);

  const onFinish = (values) => {
    const payload = values;
    payload["mmd_users"] = isArrayAndHasValue(createdUserList)
      ? createdUserList.map((item) => {
          return {
            id: isEdit ? item?.id : 0,
            user_id: item?.mmd_user_id,
            is_delete: item?.is_delete && isEdit ? 1 : 0,
            buying_office_id: item?.buying_office_id,
          };
        })
      : [];
    payload["brand_id"] = JSON.parse(localStorage.getItem("brandIds"));
    payload["buyer_unique_id"] = "1";
    payload["status"] = payload["status"] ? "1" : "0";
    payload["use_both_style_po"] = payload["use_both_style_po"];
    payload["merchandiser_for_procurement"] =
      payload["merchandiser_for_procurement"];

    // Get supplier ID as single value
    const supplierIdValue = localStorage.getItem("buyerSupplierId");
    payload["buyer_supplier_id"] = supplierIdValue
      ? parseInt(supplierIdValue, 10)
      : null;

    selectedBuyer
      ? dispatch(onUpdateSelectedBuyer(payload, selectedBuyer.id))
      : dispatch(onCreateBuyer(payload));
    form.resetFields();
    navigate("/configuration/buyer/");
  };

  const extra = (
    <div className="extra-button-container">
      <Button
        style={{ marginRight: 10 }}
        size="small"
        danger
        onClick={() => navigate("/configuration/buyer")}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        size="small"
        htmlType="submit"
        onClick={() => form.submit()}
      >
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <AppPageHeader
        extra={extra}
        title={<Title level={5}>Buyer Entry</Title>}
      />
      <div className="formBody" style={{ padding: "10px 0 24px 24px" }}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          style={{ marginTop: 10 }}
          initialValues={{
            status: selectedBuyer?.status === "1" ? true : false,
            use_both_style_po:
              selectedBuyer?.use_both_style_po === "Yes" ? true : false,
            short_name: selectedBuyer?.short_name || "",
            buyer_unique_id: selectedBuyer?.buyer_unique_id || "",
            name: selectedBuyer?.name || "",
            address: selectedBuyer?.address || "",
            merchandiser_for_procurement:
              selectedBuyer?.merchandiser_for_procurement || "",
          }}
          onFinish={(values) => {
            onFinish(values);
            form.resetFields();
          }}
          autoComplete="off"
        >
          <Row gutter={6}>
            <Col span={4}>
              <Form.Item
                label="Buyer Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input  Buyer name!",
                  },
                ]}
              >
                <Input placeholder={"Ex: Buyer Name"} size="small" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Buyer Short Name"
                name="short_name"
                rules={[
                  {
                    required: true,
                    message: "Please input buyer short name!",
                  },
                ]}
              >
                <Input placeholder={"Ex: Buyer Short Name"} size="small" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Please input address" size="small" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label="Merchandiser for Procurement"
                name="merchandiser_for_procurement"
                rules={[
                  {
                    required: true,
                    message: "Please select a Merchandiser!",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Merchandiser"
                  size="small"
                  showSearch
                  onSearch={(value) => dispatch(onGetUserList(1, 100, value))}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {userList.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {user.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="status"
                label="Active Status"
                required
                className="collection-create-form_last-form-item"
                valuePropName="checked"
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="use_both_style_po"
                label="Use both style & PO"
                required
                className="collection-create-form_last-form-item"
                valuePropName="checked"
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <CreateBrandForm
          selectedBuyer={isEdit ? selectedBuyer : null}
          brandList={brandList}
          refresh={refresh}
          refetch={refetch}
        />
        <CreateMerchandiserForm
          selectedBuyer={isEdit ? selectedBuyer : null}
          refetch={refetch}
          createdUserList={createdUserList}
          setCreatedUserList={setCreatedUserList}
          isEdit={isEdit}
        />
      </div>
    </>
  );
};
export default BuyerCreateForm;
