import {
    GET_USER_ROLE_LIST,
    CREATE_NEW_USER_ROLE,
    DELETE_USER_ROLE,
    GET_USER_ROLE_DETAIL, UPDATE_USER_ROLE_DETAIL, GET_USER_PERMISSION_LIST
} from '../ActionTypes';

const initialState = {
    userRoleList: [],
    userPermissionList: [],
    totalUserRole: null,
    totalPermissions: null,
    selectedUserRole: null,
    pageSize: 10,
    page:1,
};

const UserRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_ROLE_LIST:
            return {
                ...state,
                userRoleList: action.payload.data,
                totalUserRole: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };

        case GET_USER_PERMISSION_LIST:
            return {
                ...state,
                userPermissionList: action.payload.data,
                totalPermissions: action.payload.total,
                // pageSize: action.payload.per_page,
                // page: action.payload.current_page
            };



        case CREATE_NEW_USER_ROLE:
            return {
                ...state,
                userRoleList: [action.payload.data, ...state.userRoleList],
                totalUserRole: state.totalUserRole + 1,
            };

        case DELETE_USER_ROLE: {
            return {
                ...state,
                userRoleList: action.payload.list,
                totalUserRole: action.payload.total,
            };
        }

        case GET_USER_ROLE_DETAIL:
            return {
                ...state,
                selectedUserRole: action.payload,
            };

        case UPDATE_USER_ROLE_DETAIL:
            return {
                ...state,
                selectedUserRole: action.payload,
                userRoleList: state.userRoleList.map((UserRole) =>
                    UserRole.id === action.payload.id ? action.payload : UserRole,
                ),
            };

        default:
            return state;
    }
};

export default UserRoleReducer;
