import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Space,
  message,
  Table,
  Popconfirm,
  Typography,
  Spin,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import {
  getData,
  postData,
  deleteData,
  putData,
} from "../../../apiServices/common";
import {
  GET_PACKING_LIST_REPORT,
  EXPORT_PACKING_LIST_REPORT,
  DELETE_PACKING_LIST_REPORT,
} from "../../../apiServices/API_ENDPOINTS";
import { hasPermission } from "../../../utils/functions";
import {
  INVENTORY_MRR_REPORT_VIEW_PERMISSION,
  GENERATE_MRR_REPORT_VIEW_PERMISSION,
  DELETE_MRR_REPORT_VIEW_PERMISSION,
  PACKING_LIST_REPORT_VIEW_PERMISSION,
} from "../../../routes/permissions";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";
import { useReactToPrint } from "react-to-print";

const BuyerPackingReport = () => {
  const exportRef = createRef(null);
  // States

  const [isPrint, setIsPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [htmlResponse, setHtmlResponse] = useState(null);

  // Antd
  const { Title } = Typography;

  const exportPacking = async (filterValues) => {
    setLoading(true);
    const query = `/api/report/view_packing_list_report`;
    const { po_map_id, style_id } = filterValues || {};
    const bodyData = {
      po_map_id,
      style_id,
    };
    const response = await postData(query, bodyData);

    if (response && response?.data) {
      setLoading(false);
      setHtmlResponse(response?.data);
      message.success("Packing Report Exported Successfully");
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (isPrint) {
      printFn();
      setIsPrint(false);
    }
  }, [isPrint]);

  const printFn = useReactToPrint({
    contentRef: exportRef,
    documentTitle: "Operation Bulletin",
    onAfterPrint: () => {
      setIsPrint(false);
    },
  });

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={[]}
        filterAPI={exportPacking}
        filterName={"buyer_packing_report"}
        searchButtonText={"Generate"}
        defaultOpen
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setIsPrint(true);
                  }}
                  disabled={
                    !hasPermission([PACKING_LIST_REPORT_VIEW_PERMISSION])
                  }
                >
                  {hasPermission([PACKING_LIST_REPORT_VIEW_PERMISSION])
                    ? "Print"
                    : "No Permission"}
                </Button>
                {/* <Button
                  type="primary"
                  size="small"
                  onClick={() => showModal("excel")}
                  disabled={!hasPermission([OB_REPORT_PERMISSION])}
                >
                  {hasPermission([PACKING_CREATE_PERMISSION])
                    ? "Download Excel"
                    : "No Permission"}
                </Button> */}
                {/* <Button
                  type="primary"
                  size="small"
                  onClick={() => showModal("pdf")}
                  disabled={!hasPermission([OB_REPORT_PERMISSION])}
                >
                  {hasPermission([PACKING_CREATE_PERMISSION])
                    ? "Download PDF"
                    : "No Permission"}
                </Button> */}
              </Space>
            }
            title={<Title level={5}>Buyer Packing Report</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              {memoizedCustomFilter}

              <Spin spinning={loading}>
                <div
                  ref={exportRef}
                  dangerouslySetInnerHTML={{ __html: htmlResponse }}
                  style={{ height: "100vh" }}
                />
              </Spin>
            </div>
          </>
        }
      />
    </>
  );
};

export default BuyerPackingReport;
