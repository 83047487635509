import {
  CREATE_NEW_ITEM,
  DELETE_ITEM,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ITEM_LIST,
  SHOW_MESSAGE,
  UPDATE_ITEM_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";

import {
     CREATE_ITEM_ENDPOINT,
     DELETE_ITEM_ENDPOINT,
    EDIT_ITEM_ENDPOINT,
    GET_ITEM_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetItemList = (props) => {
  const { page, per_page, name, category_id } = props;

  return (dispatch) => {
    dispatch({type: FETCH_START});
    JwtAxios
        .get(`${GET_ITEM_ENDPOINT}`, {
            params: {
                page: page,
                per_page: per_page,
                ...(name && { name: name }),
                ...(category_id && { category_id: category_id }),
            },
        })
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: GET_ITEM_LIST, payload: data.data.data});
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: "Something went wrong!",
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
  };
};



export const onCreateItem = (item) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    JwtAxios
        .post(`${CREATE_ITEM_ENDPOINT}`, item)
        .then((data) => {
          if (data?.data?.code === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: CREATE_NEW_ITEM, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: "Item Item Created Successfully",
            });
            HandleNotification('success', 'bottomRight', data?.data?.message, null)
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: "Something went wrong!",
            });
            HandleNotification('error', 'bottomRight', data?.data?.message, null)

          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
            HandleNotification('error', 'bottomRight', error?.message, null)
        });
  };
};
export const onUpdateSelectedItem = (item) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    JwtAxios
        .put(`${EDIT_ITEM_ENDPOINT}/${item.id}`, item)

        .then((data) => {
          if (data?.data?.code === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: UPDATE_ITEM_DETAIL, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: "Item Updated Successfully",
            });
            HandleNotification('success', 'bottomRight', data?.data?.message, null)
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: "Something went wrong!",
            });
            HandleNotification('success', 'bottomRight', data?.data?.message, null)
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
            HandleNotification('error', 'bottomRight', error?.message, null)
        });
  };
};
export const onDeleteItem = (item) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    JwtAxios
        .delete(`${DELETE_ITEM_ENDPOINT}/${item['id']}`)
        .then((data) => {
          if (data?.data?.code === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: DELETE_ITEM, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: "Deleted Item Successfully",
            });

            HandleNotification('success', 'bottomRight', data?.data?.message, null)
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: "Something went wrong!",
            });
            HandleNotification('error', 'bottomRight', data?.data?.message, null)
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
            HandleNotification('error', 'bottomRight', error?.message, null)
        });
  };
};
