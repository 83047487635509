import {
  Affix,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ALL_SALES_CONTRACT_LIST,
  GET_ALL_UNITS,
  GET_CONVERSION_RATE,
  IMPORT_PI_ADD,
  IMPORT_PI_ADD_DOC,
  IMPORT_PI_UPDATE,
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../utils/functions";
import ImportB2blcDocument from "../../../Common/ImportB2blcDocument";
import ImportPiDocument from "../../../Common/ImportPiDocument";
import CustomDatePicker from "common/CustomDatePicker";

const { Option } = Select;

export const getConversionData = async (
  pi_unit_id,
  record,
  itemDetailsData,
  setItemDetailsData,
) => {
  const bodyData = {
    convert_from: record?.unit_id,
    convert_to: pi_unit_id,
    base_value: record?.pi_qty,
  };

  const response = await getData(GET_CONVERSION_RATE, false, bodyData);

  let convertedValue;
  if (response?.data?.code === 200) {
    convertedValue = response?.data?.data;
  } else {
    convertedValue = record?.pi_qty;
  }

  const itemDetailsDataCopy = [...itemDetailsData];

  const findThatItem = itemDetailsDataCopy.find(
    (item) => record?.id === item?.id,
  );
  findThatItem["pi_unit_id"] = pi_unit_id;
  findThatItem["qty_in_bom_unit"] = convertedValue;
  findThatItem["balance_qty"] =
    Math.floor(record?.required_qty) - convertedValue;

  setItemDetailsData(itemDetailsDataCopy);
};

export const getUnitList = async (setUnitList) => {
  let response = await getData(GET_ALL_UNITS, false, { per_page: 50 });

  if (response) {
    setUnitList(response?.data?.data?.data || []);
  }
};

const AddExportSalesContractForm = forwardRef((props, ref) => {
  // Props
  const { permitInfo, view, form, show_approve, setLoading } = props;

  // States
  const [supplier, setSupplier] = useState([]);
  const [itemsValue, setItemsValue] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [additionalCharge, setAdditionalCharge] = useState(0);
  const [deductionAmount, setDeductionAmount] = useState(0);
  const [styleNo, setStyleNo] = useState([]);
  const [filesList, setFileList] = useState([]);
  const [b2bLcDocuments, setB2bLcDocuments] = useState([]);
  const [salesContractList, setSalesContractList] = useState([]);
  const [itemDetailsData, setItemDetailsData] = useState([]);
  const [categoryMapData, setCategoryMapData] = useState([]);
  const [unitList, setUnitList] = useState([]);

  // Others
  var document = [];
  var document_name = [];

  const onFinishSalesContract = async (values) => {
    setLoading(true);
    values["is_b2blc_open"] = values.is_b2blc_open ? "1" : "0";

    if (filesList?.length) {
      filesList.forEach((item) => {
        document_name.push(item?.name);
        document.push(item?.id);
      });
    }

    let payload = [];
    payload["document_id"] = document;
    payload["document_name"] = document_name;


  const category_map_data =
    isArrayAndHasValue(categoryMapData) &&
    categoryMapData?.map((item) => {
      return {
        id: item?.id,
        item_category_id: item?.item_category_id,
        expected_in_house_date: item?.expected_in_house_date,
        is_delete: item?.is_delete,
      };
    });

    const bodyData = {
      ...values,
      pi_date: values?.pi_date ? moment(values?.pi_date)?.format("YYYY-MM-DD") : null,
      ship_date: values?.ship_date
        ? moment(values?.ship_date)?.format("YYYY-MM-DD")
        : null,
      eta: values?.eta ? moment(values?.eta)?.format("YYYY-MM-DD") : null,
      itemDetailsData,
      document,
      document_name,
      status: view ? "Approved" : values?.status,
      ...(permitInfo && { id: permitInfo?.sales_contract_commercial?.id }),
      category_map: category_map_data,
    };

    if (bodyData?.id > 0) {
      const response = await postData(
        IMPORT_PI_UPDATE + "/" + bodyData?.id,
        bodyData,
      );

      if (response && response?.code === 200) {
        alertPop("success", "Proforma Invoice Updated Successfully");
      }
    } else {
      const response = await postData(IMPORT_PI_ADD, bodyData);

      if (response && response?.code === 200) {
        alertPop("success", "Proforma Invoice Added Successfully");
      }
    }

    setLoading(false);
  };

  const getSalesContractList = async () => {
    let res = await getData(ALL_SALES_CONTRACT_LIST);

    if (res) {
      setSalesContractList(res?.data?.data || []);
    }
  };

  useEffect(() => {
    getSalesContractList();
    getUnitList(setUnitList);
  }, []);

  const getSelectedSupplierList = async (value, fromOnChange) => {
    form.resetFields(["supplier_name"]);
    // let get_supplier_list = `/api/accounting/supplier-list/${value}`;
    let get_supplier_list = `/api/accounting/sales-contract-suppliers/${value}`;
    let res = await getData(get_supplier_list);
    if (res) {
      setSupplier(res?.data?.data || []);
    }
  };

  useEffect(() => {
    getSelectedSupplierList();
  }, []);

  const getContractIdDetails = async (value, fromOnChange) => {
    styleNo.length = 0;

    let get_contract_details = `/api/sales_contract/${value}/view`;
    let res = await getData(get_contract_details);
    if (res) {
      if (fromOnChange) {
        form.resetFields(["style_no"]);
      }

      let style_no_array = [];
      res?.data?.data?.styleDetail.map((item, index) => {
        style_no_array.push(item?.style_info?.style_no);
        styleNo.push({
          value: item?.style_info?.id,
          label: item?.style_info?.style_no,
        });
      });
      setStyleNo([...styleNo]);

      form.setFieldsValue({
        buyer: res?.data?.data?.sales_contract?.buyer_info?.name,
        buyer_id: res?.data?.data?.sales_contract?.buyer_info?.id,
      });

      return true;
    } else {
      return false;
    }
  };

  const roundToTwo = (num, decimalPlaces = 0) => {
    if (num < 0) return -roundToTwo(-num, decimalPlaces);
    var p = Math.pow(10, decimalPlaces);
    var n = num * p;
    var f = n - Math.floor(n);
    var e = Number.EPSILON * n;

    // Determine whether this fraction is a midpoint value.
    return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p;
  };

  const getItemDetails = async (value) => {
    itemDetailsData.length = 0;
    let style_id = 0;
    if (value.length > 0) {
      style_id = value;
    } else style_id = 0;

    const new_supplier_id = form.getFieldValue("supplier_id");

    let get_item_details = `/api/accounting/get-boms/${style_id}/${new_supplier_id}`;

    let res = await getData(get_item_details);

    let item_array = [];
    const import_pi_items_data = isArrayAndHasValue(res?.data?.data?.import_pi_items) ? [...res?.data?.data?.import_pi_items] : null;

    if (import_pi_items_data) {
      import_pi_items_data?.forEach((item, index) => {
        item_array.push({
          id: uuidv4(),
          category_name: item?.item_category_info?.name,
          category_id: item?.item_category_info?.id,
          item_name: item?.item_info?.name,
          item_id: item?.item_info?.id,
          bom_detail_id: item?.id,
          unit_name: item?.unit_info?.name,
          unit_id: item?.unit_info?.id,
          style_no: item?.bom_info?.style_info?.style_no,
          style_id: item?.bom_info?.style_info?.id,
          required_qty: item.updated_required_quantity,
          pi_qty: item.updated_required_quantity,
          pi_unit_id: item?.pi_unit_id,
          qty_in_bom_unit: item?.qty_in_bom_unit,
          balance_qty: item.updated_required_quantity,
          rate: 0,
          amount: item.updated_required_quantity * 0,
        });
      });
    }

    const category_map_data = isArrayAndHasValue(res?.data?.data?.category_map) ? [...res?.data?.data?.category_map] : null;

    setCategoryMapData(category_map_data);
    setItemDetailsData([...item_array]);
  };

  const getTotalPriceOfItem = (value, data, idx, context) => {
    if (itemDetailsData) {
      itemDetailsData.forEach((item) => {
        if (item.id === idx) {
          item["rate"] = value;
          item[context] = (item.pi_qty || 0) * (value || 0);
        }
      });
    }

    setItemDetailsData([...itemDetailsData]);
  };

  const getTotalPriceOfItemByPiQty = (value, data, idx, context) => {
    if (itemDetailsData) {
      itemDetailsData.forEach((item) => {
        if (item.id === idx) {
          item["pi_qty"] = value;
          item[context] = (value || 0) * (item.rate || 0);

          getConversionData(
            item?.pi_unit_id,
            item,
            itemDetailsData,
            setItemDetailsData,
          );
        }
      });
    }
  };

  useEffect(() => {
    let pi_value_total = 0;

    itemDetailsData &&
      itemDetailsData.forEach((item) => {
        pi_value_total += item.amount;
      });

    let restTotal =
      Number(deductionAmount) +
      Number(serviceCharge) +
      Number(additionalCharge);
    form.setFieldsValue({
      pi_value: roundToTwo(pi_value_total - restTotal, 2),
    });
  }, [itemDetailsData, serviceCharge, additionalCharge, deductionAmount]);

  useEffect(() => {
    const totalSum = itemDetailsData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.amount;
    }, 0);

    const totalQuantity = itemDetailsData.reduce(
      (accumulator, currentValue) => {
        return accumulator + Number(currentValue.pi_qty);
      },
      0,
    );

    setItemsValue(totalSum);

    form.setFieldsValue({
      items_value: roundToTwo(totalSum, 2),
      items_quantity: totalQuantity,
      service_charge: serviceCharge,
      additional_charge: additionalCharge,
      deduction_amount: deductionAmount,
      pi_value: roundToTwo(
        totalSum - (serviceCharge + additionalCharge + deductionAmount),
        2,
      ),
    });
  }, [itemDetailsData]);

  const updatePiValueByService = (event) => {
    setServiceCharge(event.target.value);
    let restTotal =
      Number(event.target.value) +
      Number(additionalCharge) +
      Number(deductionAmount);
    let total_cm = itemsValue - restTotal;
    form.setFieldsValue({
      pi_value: roundToTwo(total_cm, 2),
    });
  };

  const updatePiValueByAdditionalCharge = (event) => {
    setAdditionalCharge(event.target.value);
    let restTotal =
      Number(event.target.value) +
      Number(serviceCharge) +
      Number(deductionAmount);
    let total_cm = itemsValue - restTotal;
    form.setFieldsValue({
      pi_value: total_cm,
    });
  };

  const updatePiValueByDeductionAmount = (event) => {
    setDeductionAmount(event.target.value);
    let restTotal =
      Number(event.target.value) +
      Number(serviceCharge) +
      Number(additionalCharge);
    let total_cm = itemsValue - restTotal;
    form.setFieldsValue({
      pi_value: total_cm,
    });
  };

  const onChangeInHouseDate = (date, record) => {
    const customDate = moment(date).format('YYYY-MM-DD');
    const categoryMapDataCopy = structuredClone(categoryMapData);
    const foundItem = isArrayAndHasValue(categoryMapDataCopy) && categoryMapDataCopy?.find((item) => item?.item_category_id === record?.item_category_id);
    foundItem["expected_in_house_date"] = customDate;
    setCategoryMapData(categoryMapDataCopy);
  }

  const columns = [
    {
      title: "Item Category",
      dataIndex: "category_name",
      key: "category_name",
      width: "20%",
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      width: "20%",
    },
    {
      title: "Style No",
      dataIndex: "style_no",
      key: "style_no",
      width: "20%",
    },
    {
      title: "BOM",
      children: [
        {
          title: "Qty",
          dataIndex: "required_qty",
          key: "required_qty",
          render: (text, record, idx) => {
            return (
              <InputNumber
                style={{ width: "80px" }}
                disabled={true}
                value={Math.floor(record.required_qty)}
                edit={false}
                formatter={(value) => commaSeparateNumber(value)}
                size="small"
              />
            );
          },
        },
        {
          title: "Unit",
          dataIndex: "unit_name",
          key: "unit_name",
        },
      ],
    },
    {
      title: "PI",
      children: [
        {
          title: "Qty",
          dataIndex: "pi_qty",
          key: "pi_qty",
          render: (text, record, idx) => {
            return (
              <InputNumber
                style={{ width: "80px" }}
                disabled={view}
                value={record.pi_qty}
                edit={true}
                onChange={(value) => {
                  getTotalPriceOfItemByPiQty(
                    value,
                    record,
                    record.id,
                    "amount",
                  );
                }}
                formatter={(value) => commaSeparateNumber(value)}
                size="small"
              />
            );
          },
        },
        {
          title: "Unit",
          dataIndex: "pi_unit_id",
          key: "pi_unit_id",
          render: (text, record, idx) => {
            return (
              <Select
                showSearch
                disabled={view}
                defaultValue={record?.pi_unit_id || record?.unit_id}
                value={record?.pi_unit_id || record?.unit_id}
                placeholder="Select Unit"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onSelect={(value) =>
                  getConversionData(
                    value,
                    record,
                    itemDetailsData,
                    setItemDetailsData,
                  )
                }
                size="small"
                style={{ width: "100%" }}
              >
                {isArrayAndHasValue(unitList) &&
                  unitList.map((unit) => (
                    <Option key={unit.id} value={unit.id}>
                      {unit.name}
                    </Option>
                  ))}
              </Select>
            );
          },
        },
      ],
    },
    {
      title: "Qty in BOM Unit",
      dataIndex: "qty_in_bom_unit",
      key: "qty_in_bom_unit",
      render: (text, record, idx) => {
        return (
          <InputNumber
            style={{ width: "80px" }}
            disabled={true}
            value={
              !record?.pi_unit_id && !record?.qty_in_bom_unit
                ? record?.pi_qty
                : record?.qty_in_bom_unit
                ? Number(record.qty_in_bom_unit).toFixed(2)
                : 0
            }
            formatter={(value) => commaSeparateNumber(value)}
            size="small"
          />
        );
      },
    },
    {
      title: "Balance (Remaining)",
      dataIndex: "balance_qty",
      key: "balance_qty",
      render: (text, record, idx) => {
        return (
          <InputNumber
            style={{ width: "80px" }}
            disabled={true}
            value={Number(record.balance_qty).toFixed(2) || 0}
            formatter={(value) => commaSeparateNumber(value)}
            size="small"
          />
        );
      },
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text, record, idx) => {
        return (
          <InputNumber
            style={{ width: "80px" }}
            disabled={view}
            onChange={(value) => {
              getTotalPriceOfItem(value, record, record.id, "amount");
            }}
            value={record.rate || 0}
            size="small"
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => {
        return (
          <InputNumber
            style={{ width: "80px" }}
            disabled={true}
            value={Number(record.amount).toFixed(5) || 0}
            formatter={(value) => commaSeparateNumber(value)}
            size="small"
          />
        );
      },
    },
  ];

  const categoryMapColumns = [
    {
      title: "Item Category",
      dataIndex: ["item_category_info", "name"],
      key: "item_type",
      width: "50%",
    },
    {
      title: "Expected In House Date",
      dataIndex: "expected_in_house_date",
      key: "expected_in_house_date",
      width: "50%",
      render: (value, record, idx) => {
        return (
          <CustomDatePicker
            style={{ width: "100%" }}
            disabled={view}
            size="small"
            value={value ? moment(value) : null}
            onChange={(dateString) => onChangeInHouseDate(dateString, record)}
          />
        );
      },
    },
  ];

  const funcExam = useCallback(async () => {
    const sales_contract_commercial_value =
      permitInfo?.sales_contract_commercial;

    const selected_style_no = permitInfo?.sales_contract_commercial?.style_no;
    const splitted_style_ids = selected_style_no.split(",");
    const selected_style_ids =
      splitted_style_ids?.length > 0 &&
      splitted_style_ids.map((item) => Number(item));

    let sales_contract_id_edit =
      permitInfo?.sales_contract_commercial?.sales_contract_id;

    getSelectedSupplierList(sales_contract_id_edit);

    const import_pi_id = permitInfo?.sales_contract_commercial?.id;
    const import_pi_item_url = `/api/accounting/get-importpi-items/${import_pi_id}`;
    const import_pi_items = await getData(import_pi_item_url);

    itemDetailsData.length = 0;
    import_pi_items?.data?.data[0]?.import_pi_items?.map((item, index) => {
      itemDetailsData.push({
        id: Math.floor(Math.random() * 1000),
        category_name: item?.category_info?.name,
        category_id: item?.category_info?.id,
        item_name: item?.item_info?.name,
        item_id: item?.item_info?.id,
        bom_detail_id: item?.bom_detail_id,
        unit_name: item?.unit_info?.name,
        unit_id: item?.unit_info?.id,
        style_no: item?.style_info?.style_no,
        style_id: item?.style_info?.id,
        required_qty: item.required_qty,
        pi_qty: item.pi_qty,
        pi_unit_id: item?.pi_unit_id,
        qty_in_bom_unit: item?.qty_in_bom_unit,
        balance_qty: item.balance_qty,
        rate: item.rate,
        amount: item.amount,
        expected_in_house_date: item?.expected_in_house_date, 
      });
    });

    const category_map_items = import_pi_items?.data?.data[0]?.category_map;

    setItemDetailsData([...itemDetailsData]);
    setCategoryMapData(category_map_items);

    let sales_contract_details_edit = `/api/sales_contract/${sales_contract_id_edit}/view`;
    let res_edit = await getData(sales_contract_details_edit);
    let style_no_array = [];

    res_edit?.data?.data?.styleDetail.forEach((item, index) => {
      style_no_array.push({
        value: item?.style_info?.id,
        label: item?.style_info?.style_no,
      });
    });

    setStyleNo([...style_no_array]);

    setFileList(
      permitInfo?.sales_contract_commercial?.pi_document.map((item) => {
        return {
          id: item?.id,
          name: item?.document_name,
          document: item?.document,
          path: item?.document,
        };
      }),
    );

    let b2b_lc_documents = [];
    permitInfo?.sales_contract_commercial?.b2b_lc_relation?.b2blc_document.map(
      (item, index) => {
        b2b_lc_documents.push({
          id: item?.id,
          name: item?.document_name,
          path: item?.document,
        });
      },
    );

    setB2bLcDocuments(b2b_lc_documents);

    form.setFieldsValue({
      ...sales_contract_commercial_value,
      pi_date: sales_contract_commercial_value?.pi_date
        ? moment(sales_contract_commercial_value?.pi_date, "YYYY-MM-DD")
        : null,
      ship_date: sales_contract_commercial_value?.ship_date
        ? moment(sales_contract_commercial_value?.ship_date, "YYYY-MM-DD")
        : null,

      eta: sales_contract_commercial_value?.eta
        ? moment(sales_contract_commercial_value?.eta, "YYYY-MM-DD")
        : null,
      style_no: selected_style_ids,
      in_house_date: sales_contract_commercial_value?.in_house_date
        ? moment(sales_contract_commercial_value?.in_house_date, "YYYY-MM-DD")
        : null,
    });
  }, [form, permitInfo]);

  useEffect(() => {
    if (permitInfo && form) {
      funcExam();
    }
  }, [permitInfo, form, funcExam]);

  const TableSummary = (pageData) => {
    let totalPiQty = 0;
    let totalAmount = 0;

    pageData?.pageData?.forEach((item) => {
      totalPiQty = totalPiQty + Number(item.pi_qty);
      totalAmount = totalAmount + Number(item.amount);
    });

    const totalData = {
      totalPiQty: totalPiQty ? totalPiQty.toFixed(2) : 0,
      totalAmount: totalAmount ? totalAmount.toFixed(2) : 0,
    };

    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#F5F5F5" }}>
          <Table.Summary.Cell index={0}>
            <strong>Total</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            {totalData?.totalPiQty
              ? commaSeparateNumber(totalData?.totalPiQty)
              : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {totalData?.totalAmount
              ? commaSeparateNumber(totalData?.totalAmount)
              : 0}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <div className="containt-body">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishSalesContract}
      >
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract Ref No"
                name="sales_contract_id"
                rules={[
                  {
                    required: true,
                    message: "Sales Contract is required",
                  },
                ]}
              >
                <Select
                  placeholder="Sales Contract"
                  showSearch
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    getContractIdDetails(value);
                    getSelectedSupplierList(value);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={view}
                  size="small"
                >
                  {salesContractList.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.reference_no}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="PI No"
                name="pi_number"
                rules={[
                  {
                    required: true,
                    message: "PI Number is required",
                  },
                ]}
              >
                <Input className="w-100" disabled={view} size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="PI Date" name="pi_date">
                <CustomDatePicker
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Supplier Name"
                name="supplier_id"
                rules={[
                  {
                    required: true,
                    message: "Supplier Name is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Supplier"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={view}
                  size="small"
                  onChange={(value) => {
                    const selectedStyleIds = form.getFieldValue("style_no");
                    if (
                      selectedStyleIds !== "undefined" &&
                      selectedStyleIds &&
                      selectedStyleIds.length > 0
                    ) {
                      getItemDetails(selectedStyleIds, value);
                    }
                  }}
                >
                  {supplier?.length &&
                    supplier.map((buy) => (
                      <Option value={buy?.id} key={buy?.id}>
                        {buy.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Style No"
                name="style_no"
                rules={[
                  {
                    required: true,
                    message: "Style No is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Style"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={view}
                  mode="multiple"
                  onChange={(value) => {
                    getItemDetails(value);
                  }}
                  size="small"
                >
                  {styleNo?.length &&
                    styleNo.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Description of Goods"
                name="goods_description"
                rules={[
                  {
                    required: true,
                    message: "Description of Goods is required",
                  },
                ]}
              >
                <Select
                  placeholder="Description of Goods"
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                >
                  <option value="Fabric">Fabric</option>
                  <option value="Accessories">Accessories</option>
                  <option value="Print">Print</option>
                  <option value="Washing">Washing</option>
                  <option value="Embroidery">Embroidery</option>
                  <option value="Testing">Testing Charge</option>
                  <option value="Inspection">Inspection Charge</option>
                  <option value="Courier">Courier Charge</option>
                  <option value="Portal">Portal Charge</option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Buyer" name="buyer">
                <Input
                  disabled={true}
                  style={{ width: "100%", color: "#000" }}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4} hidden>
              <Form.Item label="Buyer ID" name="buyer_id">
                <Input
                  disabled={true}
                  style={{ width: "100%", color: "#000" }}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Items Value" name="items_value">
                <InputNumber
                  style={{ width: "100%", color: "#000" }}
                  disabled={true}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
              <Form.Item name="items_quantity">
                <Input
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%", color: "#000" }}
                  type="hidden"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Service Charge" name="service_charge">
                <Input
                  style={{ width: "100%" }}
                  disabled={view}
                  onChange={updatePiValueByService}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Additional Charge" name="additional_charge">
                <Input
                  style={{ width: "100%" }}
                  disabled={view}
                  onChange={updatePiValueByAdditionalCharge}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Deduction Amount" name="deduction_amount">
                <Input
                  style={{ width: "100%" }}
                  disabled={view}
                  onChange={updatePiValueByDeductionAmount}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="PI Value" name="pi_value">
                <InputNumber
                  style={{ width: "100%", color: "#000" }}
                  disabled={true}
                  formatter={(value) => commaSeparateNumber(value)}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Trade/Freight Term" name="trade_term">
                <Select
                  placeholder="Trade/Freight Term"
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                >
                  <option value="EXW (Ex-Works)">EXW (Ex-Works)</option>
                  <option value="FCA (Free Carrier)">FCA (Free Carrier)</option>
                  <option value="FAS (Free Alongside Ship)">
                    FAS (Free Alongside Ship)
                  </option>
                  <option value="FOB (Free on Board)">
                    FOB (Free on Board)
                  </option>
                  <option value="CFR (Cost & Freight)">
                    CFR (Cost & Freight)
                  </option>
                  <option value="CIF (Cost Insurance & Freight)">
                    CIF (Cost Insurance & Freight)
                  </option>
                  <option value="CPT ( Carriage Paid To)">
                    CPT ( Carriage Paid To)
                  </option>
                  <option value="CIP (Carriage Insurance Paid To)">
                    CIP (Carriage Insurance Paid To)
                  </option>
                  <option value="DPU (Delivered at Place Unloaded)">
                    DPU (Delivered at Place Unloaded)
                  </option>
                  <option value="DAT (Delivered At Terminal)">
                    DAT (Delivered At Terminal)
                  </option>
                  <option value="DAP (Delivered at Place)">
                    DAP (Delivered at Place)
                  </option>
                  <option value="DDP (Delivered Duty Paid)">
                    DDP (Delivered Duty Paid)
                  </option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Payment Term" name="pay_term">
                <Input className="w-100" disabled={view} size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Payment Mode" name="payment_mode">
                <Select
                  placeholder="Payment Mode"
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                >
                  <option value="LC">LC (Letter of Credit)</option>
                  <option value="TT">TT (Telegraphic Transfer)</option>
                  <option value="FDD">FDD (Foreign Demand Draft)</option>
                  <option value="RTGS">
                    RTGS (Real-time gross settlement)
                  </option>
                  <option value="RTGS-CASH">RTGS cash against documents</option>
                  <option value="Cash">Cash</option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Ship Mode" name="ship_mode">
                <Select
                  placeholder="Ship Mode"
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                >
                  <option value="SEA">SEA</option>
                  <option value="AIR">AIR</option>
                  <option value="LAND">LAND</option>
                  <option value="TRANSPORT">TRANSPORT</option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Ship Date" name="ship_date">
                <CustomDatePicker
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="ETA" name="eta">
                <CustomDatePicker
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="is_b2blc_open"
                label="Is B2B LC Open"
                className="collection-create-form_last-form-item"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  disabled={view}
                  size="small"
                />
              </Form.Item>
            </Col>
            {show_approve === 1 ? (
              <>
                <Col span={4}>
                  <Form.Item label="Status" name="status">
                    <Select
                      placeholder="Status"
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <option value="Created">Created</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Updated">Updated</option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Comment" name="comment">
                    <Input className="w-100" size="small" />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col span={4}>
                  <Form.Item label="Status" name="status">
                    <Select
                      placeholder="Status"
                      defaultValue="Created"
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <option value="Created">Created</option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Card>
        <Affix>
          <Card>
            <span style={{ fontSize: "14px" }}>Item Details</span>
          </Card>
        </Affix>
        <Card>
          <Table
            dataSource={itemDetailsData}
            columns={columns}
            summary={(pageData) => {
              return <TableSummary pageData={pageData} />;
            }}
            size="small"
            bordered
          />
        </Card>
        <Affix>
          <Card>
            <span style={{ fontSize: "14px" }}>Expected Inhouse Data</span>
          </Card>
        </Affix>
        <Card>
          <Table
            dataSource={categoryMapData}
            columns={categoryMapColumns}
            size="small"
            bordered
          />
        </Card>
      </Form>
      <ImportPiDocument
        DOC_ADD={IMPORT_PI_ADD_DOC}
        setFileList={setFileList}
        filesList={filesList}
        view={view}
      />
      {b2bLcDocuments.length > 0 ? (
        <ImportB2blcDocument filesList={b2bLcDocuments} view={view} />
      ) : (
        ""
      )}
    </div>
  );
});

export default AddExportSalesContractForm;
