import { isArrayAndHasValue } from "utils/functions";

const FabricAutoTable = (props) => {
  // Props
  const { consumptionTableData } = props;

  return (
    <div>
      <table border="1" className="custome-table">
        <thead>
          <tr>
            <th colSpan={7}></th>
            <th colSpan={3} className="bc-orange text-center">
              Consumption
            </th>
            <th colSpan={2} className="bc-orange text-center">
              Other Movements
            </th>
            <th colSpan={1}></th>
            <th colSpan={2} className="bc-orange text-center">
              Ref.
            </th>
          </tr>
          <tr>
            <th className="bc-java text-center">Category</th>
            <th className="bc-java text-center">Item</th>
            <th className="bc-java text-center">Unit</th>
            <th className="bc-java text-center">Received</th>
            <th className="bc-java text-center">Issued</th>
            <th className="bc-java text-center">Returned</th>
            <th className="bc-java text-center">Dept.</th>
            <th className="bc-java text-center">Actual</th>
            <th className="bc-java text-center">Expected</th>
            <th className="bc-java text-center">Difference</th>
            <th className="bc-java text-center">Incoming</th>
            <th className="bc-java text-center">Outgoing</th>
            <th className="bc-java text-center">Balance</th>
            <th className="bc-java text-center">Output (pc)</th>
            <th className="bc-java text-center">YY</th>
          </tr>
        </thead>
        <tbody className="table-container">
          {isArrayAndHasValue(consumptionTableData) &&
            consumptionTableData?.map((item, index) => {
              return (
                <tr key={`table_parent_key_${index}`}>
                  {/* Category */}
                  <td className="t-body sticky">{item?.category}</td>
                  {/* Item */}
                  <td className="t-body sticky">{item?.item}</td>
                  {/* Unit */}
                  <td className="t-body sticky">{item?.unit}</td>
                  {/* Received */}
                  <td className="t-body sticky">{item?.received}</td>
                  {/* Issued */}
                  <td className="t-body sticky">{item?.issued}</td>
                  {/* Returned */}
                  <td className="t-body sticky">{item?.issue_returned}</td>
                  {/* Department */}
                  <td className="t-body sticky">{item?.department}</td>
                  {/* Actual Consumption */}
                  <td className="t-body sticky">
                    {(item?.issued ? Number(item?.issued) : 0) -
                      (item?.issue_returned ? Number(item?.issue_returned) : 0)}
                  </td>
                  {/* Expected Consumption */}
                  <td className="t-body sticky">{item?.expected}</td>
                  {/* Difference Consumption */}
                  <td
                    className="t-body sticky"
                    style={{ color: item?.difference < 0 ? "red" : "unset" }}
                  >
                    {item?.difference < 0
                      ? `(${item?.difference * -1})`
                      : item?.difference}
                  </td>
                  {/* Other Movements - Incoming */}
                  <td className="t-body sticky">{item?.incoming}</td>
                  {/* Other Movements - Outgoing */}
                  <td className="t-body sticky">{item?.outgoing}</td>
                  {/* Balance */}
                  <td className="t-body sticky">{item?.balance}</td>
                  {/* Ref - Output (pc) */}
                  <td className="t-body sticky">{item?.output}</td>
                  {/* YY */}
                  <td className="t-body sticky">{item?.yy}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default FabricAutoTable;
