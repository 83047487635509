import React, { Fragment, useState } from "react";

import {
  calendarBodyDateStyle,
  calendarBodyLinesStyle,
  calendarHeaderDayStyle,
  calendarHeaderWeekdayStyle,
  calendarHeaderYearMonthStyle,
  calendarMainStyle,
  calendarProductionListPStyle,
  calendarProductionListStyle,
} from "./style";
import {
  handleDragEnd,
  handleDragOver,
  handleDragStart,
  handleDrop,
} from "./utils";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import Matrix from "./Matrix";

const Calendar = ({ calendarData, setCalendarData, refetch }) => {
  const navigation = useNavigate();
  // console.log("calendarData", calendarData);
  const dayWithDutyColumns = 1;
  const lineListColumns = 2 * dayWithDutyColumns;

  const days =
    calendarData.calendarHeader.days.length * dayWithDutyColumns +
    lineListColumns;

  // Dragging state to track dragged item
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // console.log("calendarData.calendarBody", calendarData.calendarBody);

  // task.title === "" &&
  // task.details?.length === 0 &&
  // task?.delivery_status === "unplanned"
  //   ? false
  //   : true

  const isDraggable = (task) => {
    // Get current date
    const today = new Date();
    const currentDate = today.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison
    const taskDate = new Date(task.startDate).setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison

    // Check if the task is unplanned or if the task date is in the past

    if (
      (task.title === "" &&
        task.details?.length === 0 &&
        task?.delivery_status === "unplanned") ||
      taskDate < currentDate
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div style={calendarMainStyle(days, calendarData.calendarBody)}>
      <div style={calendarProductionListStyle(lineListColumns)}>
        <p style={calendarProductionListPStyle}>Production List</p>
      </div>
      <div style={calendarHeaderYearMonthStyle(days, lineListColumns)}>
        {calendarData.calendarHeader.year} - {calendarData.calendarHeader.month}
      </div>
      {calendarData.calendarHeader.days.map((day, index) => (
        <div
          key={index}
          style={calendarHeaderDayStyle(dayWithDutyColumns, day)}
        >
          {day.day}
        </div>
      ))}
      {calendarData.calendarHeader.days.map((day, index) => (
        <div
          key={index}
          style={calendarHeaderWeekdayStyle(dayWithDutyColumns, day)}
        >
          {day.weekday}
        </div>
      ))}
      {calendarData.calendarBody.map((line, line_index) => (
        <Fragment key={line_index}>
          <div style={calendarBodyLinesStyle(lineListColumns)}>
            {line.line_name}
          </div>
          {line.lines.map((task, task_index) => {
            return (
              <Tooltip
                title={task.title}
                placement="bottom"
                key={`${line_index}-${task_index}`}
                trigger={"click"}
              >
                <div
                  onDoubleClick={() => {
                    const today = new Date();
                    const currentDate = today.setHours(0, 0, 0, 0);
                    const taskDate = new Date(task.startDate).setHours(
                      0,
                      0,
                      0,
                      0,
                    );

                    if (task.id === null && taskDate >= currentDate) {
                      navigation(
                        `/production-planning/plan-entry?line_id=${line.line_id}&start_date=${task.startDate}`,
                      );
                    }
                    if (task?.details?.length > 0 && taskDate >= currentDate) {
                      navigation(`/production-planning/plan-update/${task.id}`);
                    }
                  }}
                  draggable={isDraggable(task)}
                  onDrop={(e) =>
                    handleDrop(e, {
                      line_index,
                      task_index,
                      draggedIndex,
                      line,
                      task,
                      calendarData,
                      setDraggedIndex,
                      setCalendarData,
                      refetch,
                    })
                  }
                  onDragStart={(e) =>
                    handleDragStart(e, {
                      line_index,
                      task_index,
                      draggedIndex,
                      line,
                      task,
                      calendarData,
                      setDraggedIndex,
                      setCalendarData,
                    })
                  }
                  onDragOver={(e) => {
                    handleDragOver(e);
                    setHoveredIndex(`${line_index}-${task_index}`);
                  }}
                  onDragEnd={(e) => {
                    handleDragEnd(e);
                    setHoveredIndex(null);
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      "rgb(87, 207, 255)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      calendarBodyDateStyle(
                        dayWithDutyColumns,
                        task,
                      ).backgroundColor)
                  }
                  style={{
                    ...calendarBodyDateStyle(dayWithDutyColumns, task),
                    backgroundColor:
                      hoveredIndex === `${line_index}-${task_index}`
                        ? "rgb(87, 207, 255)"
                        : calendarBodyDateStyle(dayWithDutyColumns, task)
                            .backgroundColor,
                  }}
                >
                  <Matrix task={task} />
                </div>
              </Tooltip>
            );
          })}
        </Fragment>
      ))}
    </div>
  );
};

export default Calendar;
