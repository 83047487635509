import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Table,
} from "antd";

import { BASE_URL } from "apiServices";
import CustomDatePicker from "common/CustomDatePicker";
import moment from "moment";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { onSaveFreightTypes } from "redux/actions/Export";
import { CLEAR_EXPORT_STATE } from "redux/ActionTypes";
import {
  ADD_LC_REASON,
  ALL_SALES_CONTRACT_LIST,
  BANK_NAME_ADDRESS_RESOURCE,
  EXPORT_LC_REASONS,
  SALES_CONTRACT_COMMERCIAL_ADD,
  SALES_CONTRACT_COMMERCIAL_DOC,
  SALES_CONTRACT_COMMERCIAL_EDIT,
  SALES_CONTRACT_COMMERCIAL_FROMS_RESOURCES,
  SALES_CONTRACT_WITHOUT_COMMERCIAL_LIST,
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import HandleNotification from "../../../../common/Notification";
import {
  commaSeparateNumber,
  formatNumberWithCommas,
  isArrayAndHasValue,
} from "../../../../utils/functions";
import AmendmentTable from "../../../SalesContract/AddOrUpdateSalesContact/AmendmentTable";
import FreightSettingFormItem from "./Freight-setting";
import LCDetailsTable from "./LCDetailsTable";

const initialLCDetails = {
  unique_id: 1,
  amendment_reason: [],
  export_lc_date: null,
  export_lc_no: null,
  export_lc_total: null,
  export_lc_type: null,
  export_lc_value: null,
  export_lc_qty: null,
  export_lc_total_qty: null,
  lc_documents: null,
};

const costSheetColumns = [
  {
    title: "Document Name",
    dataIndex: "document_name",
    key: "document_name",
  },
  {
    title: "Document",
    dataIndex: "document",
    key: "document",
    render: (text) => (
      <a href={`${BASE_URL}/${text}`} target="_blank" rel="noopener noreferrer">
        Download
      </a>
    ),
  },
  // {
  //   title: "Created At",
  //   dataIndex: "created_at",
  //   key: "created_at",
  //   render: (text) => cglFormatDate(text),
  // },
  // {
  //   title: "Updated At",
  //   dataIndex: "updated_at",
  //   key: "updated_at",
  //   render: (text) => cglFormatDate(text),
  // },
];

const AddExportSalesContractForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [isDeferred, setIsDeferred] = useState(false);

  const paymentTerms = [
    { id: 1, name: "Deferred" },
    { id: 2, name: "Sight" },
    { id: 3, name: "TT" },
  ];

  const paymentTermsDeferred = [
    { id: 1, value: 30 },
    { id: 2, value: 60 },
    { id: 3, value: 90 },
    { id: 4, value: 120 },
    { id: 5, value: 150 },
    { id: 6, value: 180 },
  ];

  const { permitInfo, view, form } = props;
  const [totalCm, setTotalCm] = useState(0);
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [percentageCm, setPercentageCm] = useState(0);
  const [contractValue, setContractValue] = useState(0);
  const [freightValue, setFreightValue] = useState(0);
  const [buyingCommissionValue, setBuyingCommissionValue] = useState(0);
  const [btbValue, setBtbValue] = useState(0);
  const [percentageBtbValue, setPercentageBtbValue] = useState(0);
  const [fabricLc, setFabricLc] = useState(0);
  const [accessoriesLc, setAccessoriesLc] = useState(0);
  const [totalB2blcValue, setTotalB2blcValue] = useState(0);
  const [exportValue, setExportValue] = useState(0);
  const [exportQuantity, setExportQuantity] = useState(0);
  const [balanceValue, setBalanceValue] = useState(0);
  const [balanceQuantity, setBalanceQuantity] = useState(0);

  const [salesContractList, setSalesContractList] = useState([]);

  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [styleNo, setStyleNo] = useState([]);
  const [styleQuantity, setStyleQuantity] = useState([]);
  const [styleUnitPrice, setStyleUnitPrice] = useState([]);
  const [styleTotalPrice, setStyleTotalPrice] = useState([]);
  const [filesList, setFileList] = useState([]);

  const [salesContractDocuments, setSalesContractDocuments] = useState([]);
  const [increaseQuantityArray, setIncreaseQuantityArray] = useState([]);
  const [increaseValueArray, setIncreaseValueArray] = useState([]);
  const [increaseDateArray, setIncreaseDateArray] = useState([]);
  const [amendmentRemarksArray, setAmendmentRemarksArray] = useState([]);
  const [amendmentIdArray, setAmendmentIdArray] = useState([]);
  const [obj, setObj] = useState([]);
  const [purchaseObj, setPurchaseObj] = useState([]);

  const [salesContractCommercialDocs, setSalesContractCommercialDocs] =
    useState([]);

  var document = [];
  var document_name = [];

  const [selseContractStyleDetailList, setSelseContractStyleDetailList] =
    useState([]);
  const [canEdit, setCanEditValue] = useState(0);
  const [salesContractId, setSalesContractId] = useState(0);
  const [lcValueArray, setLcValueArray] = useState([
    {
      export_lc_no: "",
      export_lc_type: "",
      export_lc_date: "",
      export_lc_value: "",
      export_lc_total: "",
      export_lc_qty: "",
      export_lc_total_qty: "",
      amendment_reason: "",
      export_lc_file: "",
      lc_documents: "",
    },
  ]);

  const [amendmentValueArray, setAmendmentValueArray] = useState([
    {
      amendment_no: "",
      amendment_date: "",
      amendment_quantity: "",
      amendment_value: "",
      amendment_remarks: "",
    },
  ]);
  const [amendments, setAmendments] = useState(null);
  const [reasons, setReasons] = useState(null);
  const [bankList, setBankList] = useState(null);
  const [addedReason, setAddedReason] = useState(null);
  const [lcdetailsList, setLcdetailsList] = useState([initialLCDetails]);
  const [selectedOrderProcess, setSelectedOrderProcess] = useState(null);
  const [bankNameAddress, setBankNameAddress] = useState(null);
  const [costSheetInfo, setCostSheetInfo] = useState(null);

  const salesContractWatch = Form.useWatch("sales_contract_value", form);
  //let increaseObj = [];
  const navigate = useNavigate();
  const [poDetailsForm] = Form.useForm();
  const { exportId } = useParams();

  // start from section
  const [addedFrom, setAddedFrom] = useState(null);
  const [froms, setFroms] = useState([]);
  const inputRef = useRef(null);

  console.log("bankList", bankList);

  // Effects
  useEffect(() => {
    getFromsList();
  }, []);

  const getFromsList = async (filterValues) => {
    const query = `${SALES_CONTRACT_COMMERCIAL_FROMS_RESOURCES}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 1000,
      no_pagination: true,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setFroms(response?.data?.data || []);
    }
  };

  const onFromChange = (event) => {
    setAddedFrom(event.target.value);
  };

  const addNewFrom = async (event) => {
    event.preventDefault();
    const query = `${SALES_CONTRACT_COMMERCIAL_FROMS_RESOURCES}`;
    const bodyData = {
      name: addedFrom,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setAddedFrom(null);
      HandleNotification(
        "success",
        "bottomRight",
        "Sales contract commercial From Item added successfully",
        null,
      );
      getFromsList();
    }
  };
  // end from section

  const onSelectPaymentTerm = (value) => {
    if (value === "Deferred") {
      setIsDeferred(true);
    } else {
      setIsDeferred(false);
    }
  };

  const onFinishSalesContract = async (values) => {
    if (filesList?.length) {
      filesList.forEach((item) => {
        // document.push(item);
        document_name.push(item?.name);
        document.push(item?.id);
      });
    }

    const export_lc_details =
      isArrayAndHasValue(lcdetailsList) &&
      lcdetailsList?.map((item) => {
        return {
          amendment_reason: item?.amendment_reason,
          export_lc_date: item?.export_lc_date,
          export_lc_no: item?.export_lc_no,
          export_lc_total: item?.export_lc_total
            ? Number(item?.export_lc_total)
            : null,
          export_lc_type: item?.export_lc_type,
          export_lc_value: item?.export_lc_value
            ? Number(item?.export_lc_value)
            : null,
          export_lc_qty: item?.export_lc_qty
            ? Number(item?.export_lc_qty)
            : null,
          export_lc_total_qty: item?.export_lc_total_qty
            ? Number(item?.export_lc_total_qty)
            : null,
          lc_documents: item?.lc_documents?.id,
        };
      });

    const currentFreightValue = form.getFieldValue("freight_value") || [];

    const bodyData = {
      ...values,
      freight_value: currentFreightValue,
      document,
      document_name,
      export_lc_details: export_lc_details || null,
      ...(permitInfo && { id: permitInfo?.sales_contract_commercial?.id }),
      sales_contract_commercial_docs: isArrayAndHasValue(
        salesContractCommercialDocs,
      )
        ? salesContractCommercialDocs
        : [],
    };

    dispatch({ type: CLEAR_EXPORT_STATE });

    if (bodyData?.id > 0) {
      let res = await postData(
        SALES_CONTRACT_COMMERCIAL_EDIT + "/" + bodyData?.id,
        bodyData,
      );

      // FOR UPDATED FREIGHT SAVE TYPES
      const edit_url = `/api/accounting/sales-contract-commercial/view/${exportId}`;
      let res2 = await getData(edit_url);
      if (res2) {
        let masterData = res2?.data?.data[0];

        if (masterData?.sales_contract_commercial?.freight_value) {
          const savedTypes =
            masterData?.sales_contract_commercial?.freight_value.map(
              (item) => ({
                id: item?.type_id,
                item: item?.freight_type?.type_name,
                value: item?.value,
              }),
            );
          dispatch(onSaveFreightTypes(savedTypes));
        }
      } // FOR UPDATED FREIGHT SAVE TYPES

      alertPop("success", "Sales Contract Commercial Updated Successfully");
      navigate("/export/edit-sales-contract/" + bodyData?.id);
    } else {
      let res = await postData(SALES_CONTRACT_COMMERCIAL_ADD, bodyData);

      alertPop("success", "Sales Contract Commercial Added Successfully");
      const id = res?.data?.id;
      navigate("/export/edit-sales-contract/" + id);
    }

    //navigate("/export/sales-contract");
  };

  const lcDataSetter = (lcData) => {
    const formattedData =
      isArrayAndHasValue(lcData) &&
      lcData.map((item, index) => {
        return {
          unique_id: index + 1,
          amendment_reason: isArrayAndHasValue(
            item?.sales_contract_commercial_lc_reasons,
          )
            ? item?.sales_contract_commercial_lc_reasons?.map(
                (reason) => reason?.reason_id,
              )
            : [],
          export_lc_date: item?.export_lc_date
            ? moment(item?.export_lc_date)
            : null,
          export_lc_no: item?.export_lc_no,
          export_lc_total: item?.export_lc_total,
          export_lc_type: item?.export_lc_type,
          export_lc_value: item?.export_lc_value,
          lc_documents: {
            document_name: item?.lc_document_info?.document_name,
            document: item?.lc_document_info?.document,
            sales_contract_commercial_id: item?.sales_contract_commercial_id,
            lc_id: item?.lc_id,
            updated_at: item?.updated_at,
            created_at: item?.created_at,
            id: item?.id,
          },
          export_lc_qty: item?.export_lc_qty,
          export_lc_total_qty: item?.export_lc_total_qty,
        };
      });

    formattedData && setLcdetailsList(formattedData);
  };

  const getSalesContractList = async (filterValues) => {
    const payload = {
      per_page: 10,
      ...filterValues,
    };

    if (permitInfo?.sales_contract_commercial?.sales_contract_id > 0) {
      const response = await getData(ALL_SALES_CONTRACT_LIST, false, payload);
      if (response && response?.data?.code === 200) {
        setSalesContractList(response?.data?.data || []);
        lcDataSetter(permitInfo?.sales_contract_commercial?.sale_contract_lc);
      }
    } else {
      const response = await getData(
        SALES_CONTRACT_WITHOUT_COMMERCIAL_LIST,
        false,
        payload,
      );

      if (response && response?.data?.code === 200) {
        setSalesContractList(response?.data?.data || []);
      }
    }
  };

  useEffect(() => {
    getSalesContractList();
    amendmentDetails();
  }, [salesContractWatch]);

  const getReasonsList = async (filterValues) => {
    const query = `${EXPORT_LC_REASONS}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 1000,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setReasons(response?.data?.data);
    }
  };

  // for buyer bank name & address
  const getBankList = async (filterValues) => {
    const query = `${BANK_NAME_ADDRESS_RESOURCE}`;
    const bodyData = {
      per_page: filterValues?.per_page || 1000,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);

    console.log("response", response);

    if (response && response?.data?.code === 200) {
      setBankList(response?.data?.data?.data);
    }
  };

  const onNameAddressChange = (event) => {
    setBankNameAddress(event.target.value);
  };

  const addBankNameAddress = async (e) => {
    e.preventDefault();
    // setBankNameAddress(null);
    const query = `${BANK_NAME_ADDRESS_RESOURCE}`;
    const bodyData = {
      name_address: bankNameAddress,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setBankNameAddress(null);
      HandleNotification(
        "success",
        "bottomRight",
        "Bank Name & Address added successfully",
        null,
      );
      getBankList();
    }
  };

  useEffect(() => {
    getReasonsList();
    getBankList();
  }, []);

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date) => {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  };

  const getContractIdDetails = async (value, fromOnChange) => {
    purchaseOrder.length = 0;
    setCanEditValue(0);

    const get_fabric_accessories_value = `/api/accounting/fabric-accessories-value/${value}`;
    const fabric_accessories_value = await getData(
      get_fabric_accessories_value,
    );

    const get_sales_contract_quantity = `/api/sales_contract/${value}/total-quantity`;
    const sales_contract_quantity = await getData(get_sales_contract_quantity);

    const get_invoice_details = `/api/accounting/get-invoice-details/${value}`;
    const invoice_details_value = await getData(get_invoice_details);

    setFabricLc(fabric_accessories_value?.data?.data[0]?.fabric_lc_total);
    setAccessoriesLc(
      fabric_accessories_value?.data?.data[0]?.accessories_lc_total,
    );

    setTotalB2blcValue(
      fabric_accessories_value?.data?.data[0]?.fabric_lc_total +
        fabric_accessories_value?.data?.data[0]?.accessories_lc_total +
        fabric_accessories_value?.data?.data[0]?.amendment_value,
    );
    setExportValue(invoice_details_value?.data?.data[0]?.invoice_total);
    setExportQuantity(invoice_details_value?.data?.data[0]?.invoice_quantity);

    let get_contract_details = `/api/sales_contract/${value}/view`;
    let res = await getData(get_contract_details);

    if (res) {
      if (fromOnChange) {
        form.resetFields(["buyer", "style_no"]);
      }
      // setSelseContractStyleDetailList([...res.data.data.styleDetail]);
      setSelseContractStyleDetailList((prevState) => {
        return [...prevState, ...res.data.data.styleDetail];
      });
      setContractValue(res?.data?.data?.sales_contract?.contract_value);

      setBalanceValue(
        res?.data?.data?.sales_contract?.contract_value -
          invoice_details_value?.data?.data[0]?.invoice_total,
      );

      setBalanceQuantity(
        sales_contract_quantity?.data?.data?.total_quantity -
          invoice_details_value?.data?.data[0]?.invoice_quantity,
      );

      res?.data?.data?.purchase_orders.map((item, index) => {
        purchaseOrder.push(item?.internal_po);
      });

      res?.data?.data?.styleDetail.map((item, index) => {
        styleNo.push(item?.style_info?.style_no);
        styleUnitPrice.push(item?.unit_price);
        styleQuantity.push(item?.order_quantity);
        styleTotalPrice.push(item?.total_value);
      });

      let increase_quantity_array = [];
      res?.data?.data?.styleDetail.map((item, index) => {
        increase_quantity_array.push(item?.order_quantity);
        increaseQuantityArray.push(item?.order_quantity);
      });
      let increaseQuantity =
        increase_quantity_array.length > 0
          ? increase_quantity_array.join(",")
          : 0;

      let increase_value_array = [];
      res?.data?.data?.styleDetail.map((item, index) => {
        increase_value_array.push(item?.total_value);
        increaseValueArray.push(item?.total_value);
      });
      let increaseValue =
        increase_value_array.length > 0 ? increase_value_array.join(",") : 0;

      let increase_date_array = [];
      res?.data?.data?.styleDetail.map((item, index) => {
        increase_date_array.push(formatDate(new Date(item.created_at)));
        increaseDateArray.push(formatDate(new Date(item.created_at)));
      });
      let increaseDateValue =
        increase_date_array.length > 0 ? increase_date_array.join(",") : 0;

      let amendment_remarks_array = [];
      res?.data?.data?.styleDetail.map((item, index) => {
        amendment_remarks_array.push(item.amendment_remarks);
        amendmentRemarksArray.push(item.amendment_remarks);
      });
      let amendmentRemarksValue =
        amendment_remarks_array.length > 0
          ? amendment_remarks_array.join(",")
          : "";

      let amendment_id_array = [];
      res?.data?.data?.styleDetail.map((item, index) => {
        amendment_id_array.push(item.id);
        amendmentIdArray.push(item.id);
      });
      let amendmentIdValue =
        amendment_id_array.length > 0 ? amendment_id_array.join(",") : "";

      const total_order_quantity = res?.data?.data?.styleDetail?.reduce(
        (a, item) => {
          return a + Number(item?.order_quantity);
        },
        0,
      );

      setOrderQuantity(total_order_quantity);

      let sales_contract_documents = [];
      res?.data?.data?.sales_contract?.sales_contract_documents.map(
        (item, index) => {
          sales_contract_documents.push({
            id: item?.id,
            name: item?.document_name,
            path: item?.document,
          });
        },
      );

      setSalesContractDocuments(sales_contract_documents);
      const balVal =
        res?.data?.data?.sales_contract?.contract_value -
        invoice_details_value?.data?.data[0]?.invoice_total;

      form.setFieldsValue({
        sales_contract_date: res?.data?.data?.sales_contract?.contract_date
          ? moment(res?.data?.data?.sales_contract?.contract_date)
          : null,
        sales_contract_value: res?.data?.data?.sales_contract?.contract_value,
        buying_office_id: res?.data?.data?.sales_contract?.buying_office_id,
        buying_office_name:
          res?.data?.data?.sales_contract?.buying_office_info?.name,
        buyer_name: res?.data?.data?.sales_contract?.buyer_info?.name,
        buyer_address: res?.data?.data?.sales_contract?.buyer_info?.address,
        order_quantity: total_order_quantity,
        value: increaseValue,
        quantity: increaseQuantity,
        increase_date: increaseDateValue,
        fabric_lc: fabric_accessories_value?.data?.data[0]?.fabric_lc_total,
        accessories_lc:
          fabric_accessories_value?.data?.data[0]?.accessories_lc_total,
        export_value: invoice_details_value?.data?.data[0]?.invoice_total,
        export_quantity: invoice_details_value?.data?.data[0]?.invoice_quantity,
        balance_value: balVal,

        balance_quantity:
          sales_contract_quantity?.data?.data?.total_quantity -
          invoice_details_value?.data?.data[0]?.invoice_quantity,
      });

      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const calculateBtbValue =
      contractValue - freightValue - totalCm - buyingCommissionValue;
    setBtbValue(calculateBtbValue);

    const calculatePercentageBtb = (calculateBtbValue * 100) / contractValue;
    setPercentageBtbValue(calculatePercentageBtb);
  }, [permitInfo, contractValue]);

  const updateTotalCm = (event) => {
    setTotalCm(event.target.value * orderQuantity);
    const total_cm = event.target.value * orderQuantity;
    setBtbValue(
      contractValue - total_cm - freightValue - buyingCommissionValue,
    );

    const btb_value =
      contractValue - total_cm - freightValue - buyingCommissionValue;
    setPercentageBtbValue((btb_value * 100) / contractValue);
  };

  const updateFreightValue = (freight_value) => {
    // setFreightValue(event.target.value);
    // const freight_value = event.target.value;
    setBtbValue(
      contractValue - totalCm - freight_value - buyingCommissionValue,
    );
    setPercentageBtbValue((btbValue * 100) / contractValue);
  };

  const updateBuyingCommissionValue = (event) => {
    setBuyingCommissionValue(event.target.value);
    const buying_commision_value = event.target.value;
    setBtbValue(
      contractValue - totalCm - freightValue - buying_commision_value,
    );
    setPercentageBtbValue((btbValue * 100) / contractValue);
  };

  const salesGet = async (salesid) => {
    let sales_contract_details_edit = `/api/sales_contract/${salesid}/view`;
    let res_edit = await getData(sales_contract_details_edit);
    if (res_edit) {
      let sales_contract_documents = [];
      res_edit?.data?.data?.sales_contract?.sales_contract_documents.map(
        (item, index) => {
          sales_contract_documents.push({
            id: item?.id,
            name: item?.document_name,
            path: item?.document,
          });
        },
      );

      setSalesContractDocuments(sales_contract_documents);

      return res_edit;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (permitInfo && form) {
      const sales_contract_id =
        permitInfo?.sales_contract_commercial?.sales_contract_id;
      getContractIdDetails(sales_contract_id);

      const payment_terms =
        permitInfo?.sales_contract_commercial?.payment_terms;

      if (payment_terms === "Deferred") setIsDeferred(true);

      let sales_contract_commercial_value =
        permitInfo?.sales_contract_commercial;
      let sales_contract_id_edit =
        permitInfo?.sales_contract_commercial?.sales_contract_id;

      const freight_value =
        permitInfo?.sales_contract_commercial?.freight_value;
      const totalFreightValue =
        freight_value &&
        freight_value.reduce((acc, item) => acc + item.value, 0);
      setFreightValue(totalFreightValue);
      setCanEditValue(1);
      setSalesContractId(
        permitInfo?.sales_contract_commercial?.sales_contract_id,
      );
      setTotalCm(permitInfo?.sales_contract_commercial?.total_cm);

      const total_order_quantity =
        permitInfo?.sales_contract_commercial?.order_quantity;
      const percentage_cm =
        permitInfo?.sales_contract_commercial?.percentage_cm;
      setOrderQuantity(total_order_quantity);
      setPercentageCm(percentage_cm);
      // setContractValue(
      //   permitInfo?.sales_contract_commercial?.sales_contract_value,
      // );
      // setBtbValue(permitInfo?.sales_contract_commercial?.btb_value);
      // setPercentageBtbValue(
      //   permitInfo?.sales_contract_commercial?.btb_percentage_value,
      // );

      setFileList(
        permitInfo?.sales_contract_commercial?.sale_contract_document.map(
          (item) => {
            return {
              id: item?.id,
              name: item?.document_name,
              document: item?.document,
              // name: file.name,
              path: item?.document,
            };
          },
        ),
      );

      lcValueArray.length = 0;

      permitInfo?.sales_contract_commercial?.sale_contract_lc.length > 0 &&
        permitInfo?.sales_contract_commercial?.sale_contract_lc.map(
          (option) => {
            lcValueArray.push({
              export_lc_no: option.export_lc_no,
              export_lc_type: option.export_lc_type,
              export_lc_date: moment(option.export_lc_date, "YYYY-MM-DD"),
              export_lc_value: option.export_lc_value,
              export_lc_total: option.export_lc_total,
              export_lc_qty: option.export_lc_qty,
              export_lc_total_qty: option.export_lc_total_qty,
              amendment_reason: option.amendment_reason,
              export_lc_file: option.export_lc_file,
              lc_documents: option.lc_documents,
            });
          },
        );

      amendmentValueArray.length = 0;

      permitInfo?.sales_contract_commercial?.sale_contract_amendment.length >
        0 &&
        permitInfo?.sales_contract_commercial?.sale_contract_amendment.map(
          (option) => {
            amendmentValueArray.push({
              amendment_no: option.amendment_no,
              amendment_date: moment(option.amendment_date, "YYYY-MM-DD"),
              amendment_value: option.amendment_value,
              amendment_quantity: option.amendment_quantity,
              amendment_remarks: option.amendment_remarks,
            });
          },
        );

      setTotalB2blcValue(sales_contract_commercial_value?.total_b2blc_value);

      form.setFieldsValue({
        ...sales_contract_commercial_value,
        sales_contract_commercial_id: sales_contract_commercial_value?.id,
        sales_contract_date: moment(
          sales_contract_commercial_value?.sale_contract_info?.contract_date,
          "YYYY-MM-DD",
        ),
        sales_contract_value:
          sales_contract_commercial_value?.sale_contract_info?.contract_value,
        buying_office_name: sales_contract_commercial_value?.buying_office_name,
        buyer_name: sales_contract_commercial_value?.buyer_name,
        buyer_address: sales_contract_commercial_value?.buyer_address,
        buyer_bank_name_address:
          sales_contract_commercial_value?.buyer_bank_name_address &&
          Number(sales_contract_commercial_value?.buyer_bank_name_address)
            ? Number(sales_contract_commercial_value?.buyer_bank_name_address)
            : sales_contract_commercial_value?.buyer_bank_name_address,
        order_quantity: sales_contract_commercial_value?.order_quantity,
        total_cm: sales_contract_commercial_value.total_cm,
        fabric_lc: permitInfo?.sales_contract_commercial?.fabric_lc,
        accessories_lc: permitInfo?.sales_contract_commercial?.accessories_lc,
        shipment_date: sales_contract_commercial_value.shipment_date
          ? moment(sales_contract_commercial_value.shipment_date, "YYYY-MM-DD")
          : null,
        expiry_date: sales_contract_commercial_value.expiry_date
          ? moment(sales_contract_commercial_value.expiry_date, "YYYY-MM-DD")
          : null,
        amendment_date_sales_contract:
          sales_contract_commercial_value.amendment_date_sales_contract != null
            ? moment(
                sales_contract_commercial_value.amendment_date_sales_contract,
                "YYYY-MM-DD",
              )
            : null,
        ud_date: sales_contract_commercial_value.ud_date
          ? moment(sales_contract_commercial_value.ud_date, "YYYY-MM-DD")
          : null,
        ud_amendment_date: sales_contract_commercial_value.ud_amendment_date
          ? moment(
              sales_contract_commercial_value.ud_amendment_date,
              "YYYY-MM-DD",
            )
          : null,
        balance_quantity: sales_contract_commercial_value.balance_quantity,
      });
      setSelectedOrderProcess(
        permitInfo?.sales_contract_commercial?.order_processing,
      );

      if (
        permitInfo?.sales_contract_commercial?.sale_contract_info
          ?.cost_sheet_info?.length > 0
      ) {
        setCostSheetInfo(
          permitInfo.sales_contract_commercial.sale_contract_info
            .cost_sheet_info,
        );
      }
    } else {
    }
  }, [permitInfo, form]);

  form.setFieldsValue({
    total_cm: Number(totalCm).toFixed(2),
    percentage_cm: Number((totalCm * 100) / contractValue).toFixed(2),
    btb_value: Number(btbValue).toFixed(2),
    btb_percentage_value: Number(percentageBtbValue).toFixed(2),
    total_b2blc_value: Number(totalB2blcValue).toFixed(2),
    rest_b2blc_value: Number(btbValue - totalB2blcValue).toFixed(2),
  });

  const purchaseColumns = [
    {
      title: "Internal PO",
      dataIndex: "internal_po",
      key: "internal_po",
    },
    {
      title: "Original PO",
      dataIndex: "original_po",
      key: "original_po",
    },
  ];

  const columns = [
    {
      title: "Style Number",
      dataIndex: "style_number",
      key: "style_number",
    },
    {
      title: "Unit Price",
      dataIndex: "style_unit_price",
      key: "style_unit_price",
    },
    {
      title: "Order Quantity",
      dataIndex: "style_order_quantity",
      key: "style_order_quantity",
    },
    {
      title: "Total Price",
      dataIndex: "style_total_price",
      key: "style_total_price",
      render: (value) => formatNumberWithCommas(value),
    },
  ];

  const amendmentDetails = async () => {
    if (canEdit === 1) {
      let sales_contract_details = `/api/sales_contract/${salesContractId}/view?sales_contract_commercial_id=${exportId}`;
      let res_details = await getData(sales_contract_details);

      const objCopy = [];
      const purchaseCopy = [];

      res_details?.data?.data?.purchase_orders.length > 0 &&
        res_details?.data?.data?.purchase_orders.forEach((povar, i) => {
          purchaseCopy.push({
            internal_po: povar?.internal_po,
            original_po: povar?.original_po,
          });
        });

      setAmendments(res_details?.data?.data?.amendmends);

      setPurchaseObj(purchaseCopy);

      res_details?.data?.data?.styleDetail.length > 0 &&
        res_details?.data?.data?.styleDetail.forEach((povar, i) => {
          objCopy.push({
            style_number: povar?.style_info?.style_no,
            style_unit_price: povar?.unit_price,
            style_order_quantity: povar?.order_quantity,
            style_total_price: povar?.total_value,
          });
        });

      setObj(objCopy);
    } else {
      const objCopy = [...obj];
      const purchaseCopy = [...purchaseObj];

      purchaseOrder.length > 0 &&
        purchaseOrder.forEach((povar, i) => {
          purchaseCopy.push({ internal_po: povar });
          purchaseCopy.push({ original_po: povar });
        });

      styleNo.length > 0 &&
        styleNo.forEach((ele, i) => {
          objCopy.push({
            style_number: ele,
            style_unit_price: styleUnitPrice[i],
            style_order_quantity: styleQuantity[i],
            style_total_price: styleTotalPrice[i],
          });
        });

      setObj(objCopy);
      setPurchaseObj(purchaseCopy);
    }
  };

  const onReasonChange = (event) => {
    setAddedReason(event.target.value);
  };

  const addNewReason = async (event) => {
    event.preventDefault();
    const query = `${ADD_LC_REASON}`;
    const bodyData = {
      amendment_reason: addedReason,
      sales_contract_commercial_id: exportId,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setAddedReason(null);
      HandleNotification("success", "Reason added successfully");
      getReasonsList();
    }
  };

  const uploadFile = async (file, key) => {
    // return;
    // setLoading(true);
    const URL = process.env.REACT_APP_BASE_URL + SALES_CONTRACT_COMMERCIAL_DOC;
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);

    fetch(URL, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        // setLoading(false);
        // addToFilesList(result.data);
        const formData = form.getFieldsValue();
        const exportLCData = formData?.export_lc_details;
        // FINDBYindex
        exportLCData[key] = {
          ...exportLCData[key],
          lc_document_id: result?.data?.id,
          lc_document: result?.data?.document,
          lc_document_name: result?.data?.document_name,
          export_lc_total: "708",
        };
        form.setFieldsValue({
          export_lc_details: exportLCData,
        });
      })
      .catch((error) => {
        // setLoading(false);
        message.error(error.message || "Error uploading file!");
      });
  };

  const TableSummary = ({ pageData }) => {
    const styleOrderQuantity = pageData.reduce((acc, item) => {
      return acc + Number(item?.style_order_quantity);
    }, 0);

    const totalOrderPrice = pageData.reduce((acc, item) => {
      return acc + Number(item?.style_total_price);
    }, 0);

    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#F5F5F5" }}>
          <Table.Summary.Cell index={0}>
            <strong>{`Total`}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <strong>
              {styleOrderQuantity
                ? commaSeparateNumber(Number(styleOrderQuantity))
                : 0}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <strong>
              {totalOrderPrice
                ? commaSeparateNumber(totalOrderPrice.toFixed(2))
                : 0}
            </strong>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };
  /// END

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishSalesContract}
      >
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Sales Contract Ref No"
                name="sales_contract_id"
                rules={[
                  {
                    required: true,
                    message: "Sales Contract is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Sales Contract"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    getContractIdDetails(value);
                    //getB2blcDetailsUsingSalesContract(value);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  size="small"
                  disabled={view || exportId}
                >
                  {salesContractList.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.reference_no}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Sales Contract Date" name="sales_contract_date">
                <CustomDatePicker
                  style={{ width: "100%", color: "#000 !important" }}
                  disabled={true}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract Value"
                name="sales_contract_value"
              >
                <InputNumber
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%", color: "#000" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Buying Office" name="buying_office_name">
                <Input
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%", color: "#000" }}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Buyer" name="buyer_name">
                <Input
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%", color: "#000" }}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Buyer Address" name="buyer_address">
                <Input
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%", color: "#000" }}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Order Quantity (Piece)"
                name="order_quantity"
                rules={[
                  {
                    required: true,
                    message: "Order Quantity is required",
                  },
                ]}
              >
                <InputNumber
                  disabled={true}
                  style={{ width: "100%", color: "#000" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="CM/PCs"
                name="cm_pc"
                onChange={updateTotalCm}
                rules={[
                  {
                    required: true,
                    message: "CM/PCs is required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={view}
                  type="number"
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Total CM" name="total_cm" value={totalCm}>
                <InputNumber
                  style={{ width: "100%", color: "#000" }}
                  disabled={true}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Percentage of CM" name="percentage_cm">
                <InputNumber
                  style={{ width: "100%", color: "#000" }}
                  disabled={true}
                  type="number"
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Tag Number" name="tag_number">
                <Input style={{ width: "100%" }} disabled={view} size="small" />
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={4}>
              <Form.Item
                label="Freight Value(If Any)"
                name="freight_value"
                onChange={updateFreightValue}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={view}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col> */}
            <Form.Item name="po_number">
              <Input
                className="w-100"
                disabled={true}
                style={{ width: "100%", color: "#000" }}
                type="hidden"
                size="small"
              />
            </Form.Item>
            <Form.Item name="style_number">
              <Input
                className="w-100"
                disabled={true}
                style={{ width: "100%", color: "#000" }}
                type="hidden"
                size="small"
              />
            </Form.Item>
            <Form.Item name="style_unit_price">
              <Input
                className="w-100"
                disabled={true}
                style={{ width: "100%", color: "#000" }}
                type="hidden"
                size="small"
              />
            </Form.Item>
            <Form.Item name="style_order_quantity">
              <Input
                className="w-100"
                disabled={true}
                style={{ width: "100%", color: "#000" }}
                type="hidden"
                size="small"
              />
            </Form.Item>
            <Form.Item name="style_total_price">
              <Input
                className="w-100"
                disabled={true}
                style={{ width: "100%", color: "#000" }}
                type="hidden"
                size="small"
              />
            </Form.Item>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Buying Comission(If Any)"
                name="buying_comission"
                onChange={updateBuyingCommissionValue}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={view}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="BTB Value" name="btb_value">
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={true}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Percentage of BTB Value"
                name="btb_percentage_value"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={true}
                  type="number"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Shipment Date"
                name="shipment_date"
                rules={[
                  {
                    required: true,
                    message: "Shipment Date is required",
                  },
                ]}
              >
                <CustomDatePicker
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Expiry Date"
                name="expiry_date"
                rules={[
                  {
                    required: true,
                    message: "Expiry Date is required",
                  },
                ]}
              >
                <CustomDatePicker
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="UD Number" name="ud_number">
                <Input className="w-100" disabled={view} size="small" />
              </Form.Item>
            </Col>
            <Form.Item name="quantity">
              <Input
                className="w-100"
                disabled={true}
                style={{ width: "100%", color: "#000" }}
                type="hidden"
                size="small"
              />
            </Form.Item>
            <Form.Item name="value">
              <Input
                className="w-100"
                disabled={true}
                style={{ width: "100%", color: "#000" }}
                type="hidden"
                size="small"
              />
            </Form.Item>
            <Form.Item name="increase_date">
              <Input
                className="w-100"
                disabled={true}
                style={{ width: "100%", color: "#000" }}
                type="hidden"
                size="small"
              />
            </Form.Item>
            <Col className="gutter-row" span={4}>
              <Form.Item label="UD Date" name="ud_date">
                <CustomDatePicker
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                />
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={4}>
              <Form.Item
                label="Last UD Amend Number"
                name="ud_amendment_number"
              >
                <Input style={{ width: "100%" }} disabled={view} size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Last UD Amend Date" name="ud_amendment_date">
                <CustomDatePicker style={{ width: "100%" }} disabled={view} size="small" />
              </Form.Item>
            </Col> */}
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Buyer's Bank Name & Address"
                name="buyer_bank_name_address"
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  disabled={view}
                  placeholder="Buyer Bank Name & Address"
                  size="small"
                  showSearch
                  onSearch={(value) => getBankList({ name_address: value })}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Please enter Bank Name & Address"
                          value={bankNameAddress}
                          onChange={onNameAddressChange}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addBankNameAddress}
                        >
                          Add item
                        </Button>
                      </Space>
                    </>
                  )}
                >
                  {isArrayAndHasValue(bankList) &&
                    bankList?.map((item) => (
                      <Select.Option
                        key={item?.id}
                        value={item?.name_address}
                        label={item?.name_address}
                      >
                        {item?.name_address}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Terms of Payment"
                name="payment_terms"
                rules={[
                  {
                    required: true,
                    message: "Terms of Payment is required",
                  },
                ]}
              >
                <Select
                  placeholder="Payment Terms"
                  style={{ width: "100%" }}
                  onChange={(value) => onSelectPaymentTerm(value)}
                  size="small"
                  disabled={view}
                >
                  {paymentTerms.map((option) => {
                    return (
                      <option key={option.id} value={option.name}>
                        {option?.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            {isDeferred && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Deferred days"
                    name="payment_term_deferred"
                    rules={[
                      {
                        required: true,
                        message: "Please input Deferred days!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Deferred days"
                      style={{ width: "100%" }}
                      size="small"
                      disabled={view}
                    >
                      {paymentTermsDeferred.map((option) => {
                        return (
                          <option key={option.id} value={option.value}>
                            {option.value}
                          </option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col className="gutter-row" span={4}>
                  <Form.Item label="From" name="from_date">
                    <Input
                      style={{ width: "100%", color: "#000 !important" }}
                      size="small"
                      disabled={view}
                    />
                  </Form.Item>
                </Col> */}

                <Col className="gutter-row" span={4}>
                  <Form.Item label="From" name="from_date">
                    {/* <Input
                      style={{ width: "100%", color: "#000 !important" }}
                      disabled={hide}
                    /> */}
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="From"
                      mode="multiple"
                      disabled={view}
                      size="small"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={addedFrom}
                              onChange={onFromChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addNewFrom}
                            >
                              Add
                            </Button>
                          </Space>
                        </>
                      )}
                      options={
                        froms &&
                        froms.map((item) => ({
                          label: item?.name,
                          value: item?.id,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Trade/Freight Term"
                name="freight_terms"
                rules={[
                  {
                    required: true,
                    message: "Trade/Freight Term is required",
                  },
                ]}
              >
                <Select
                  placeholder="Trade/Freight Term"
                  style={{ width: "100%" }}
                  size="small"
                  disabled={view}
                >
                  <option value="EXW (Ex-Works)">EXW (Ex-Works)</option>
                  <option value="FCA (Free Carrier)">FCA (Free Carrier)</option>
                  <option value="FAS (Free Alongside Ship)">
                    FAS (Free Alongside Ship)
                  </option>
                  <option value="FOB (Free on Board)">
                    FOB (Free on Board)
                  </option>
                  <option value="CFR (Cost & Freight)">
                    CFR (Cost & Freight)
                  </option>
                  <option value="CIF (Cost Insurance & Freight)">
                    CIF (Cost Insurance & Freight)
                  </option>
                  <option value="CPT ( Carriage Paid To)">
                    CPT ( Carriage Paid To)
                  </option>
                  <option value="CIP (Carriage Insurance Paid To)">
                    CIP (Carriage Insurance Paid To)
                  </option>
                  <option value="DPU (Delivered at Place Unloaded)">
                    DPU (Delivered at Place Unloaded)
                  </option>
                  <option value="DAT (Delivered At Terminal)">
                    DAT (Delivered At Terminal)
                  </option>
                  <option value="DAP (Delivered at Place)">
                    DAP (Delivered at Place)
                  </option>
                  <option value="DDP (Delivered Duty Paid)">
                    DDP (Delivered Duty Paid)
                  </option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Total Opened B2BLC value"
                name="total_b2blc_value"
              >
                <InputNumber
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Balance BTB Value" name="rest_b2blc_value">
                <InputNumber
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Opened Fabric LC value" name="fabric_lc">
                <InputNumber
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%" }}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Opened Accessories LC value"
                name="accessories_lc"
              >
                <InputNumber
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%" }}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Total Export Value" name="export_value">
                <Input
                  className="w-100"
                  disabled={true}
                  type="number"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Total Export Quantity" name="export_quantity">
                <Input
                  className="w-100"
                  disabled={true}
                  type="number"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Balance Value" name="balance_value">
                {/* <Input className="w-100" disabled={true} type="number" /> */}
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={true}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Balance Quantity" name="balance_quantity">
                <Input
                  className="w-100"
                  disabled={true}
                  type="number"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Order Processing"
                name="order_processing"
                rules={[
                  {
                    required: true,
                    message: "Order Processing is required",
                  },
                ]}
              >
                <Select
                  placeholder="Order Processing"
                  style={{ width: "100%" }}
                  onSelect={(value) => setSelectedOrderProcess(value)}
                  size="small"
                  disabled={view}
                >
                  <option value="LC">LC</option>
                  <option value="Sales Contract">Sales Contract</option>
                  <option value="Both LC & Sales Contract">
                    Both LC & Sales Contract
                  </option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <FreightSettingFormItem
                form={form}
                updateFreightValue={updateFreightValue}
                view={view}
              />
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={12}>
            <Card title="PO Details">
              <Table dataSource={purchaseObj} columns={purchaseColumns} />
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Style Details">
              <Table
                dataSource={obj}
                columns={columns}
                summary={(pageData) => {
                  return <TableSummary pageData={pageData} />;
                }}
              />
            </Card>
          </Col>
        </Row>

        {costSheetInfo && costSheetInfo?.length > 0 && (
          <Row gutter={16} className="my-3">
            <Col span={24}>
              <Card title="Cost Sheet Info">
                <Table dataSource={costSheetInfo} columns={costSheetColumns} />
              </Card>
            </Col>
          </Row>
        )}

        <AmendmentTable
          amendments={amendments}
          view={view}
          isScCommercial={true}
          salesContractCommercialDocs={salesContractCommercialDocs}
          setSalesContractCommercialDocs={setSalesContractCommercialDocs}
        />

        {selectedOrderProcess === "LC" ||
        selectedOrderProcess === "Both LC & Sales Contract" ? (
          <LCDetailsTable
            lcdetailsList={lcdetailsList}
            setLcdetailsList={setLcdetailsList}
            view={view}
          />
        ) : null}
      </Form>
    </div>
  );
});

export default AddExportSalesContractForm;
