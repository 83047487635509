import { Col, Form, Select } from "antd";
import {
  BUYER_INQUIRY_LIST_ENDPOINT,
  GET_STYLE_LIST_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useEffect, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";

const OBFilterFields = ({ initialTableData }) => {
  // States
  const [buyerInquiryList, setBuyerInquiryList] = useState(null);
  const [styleList, setStyleList] = useState(null);

  // Antd
  const { Option } = Select;

  // Effects
  useEffect(() => {
    // Get buyer inquiry, style and substyle on first load
    getBuyerInquiryList();
    getStyleList();
  }, []);

  const getBuyerInquiryList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };
    const response = await getData(
      BUYER_INQUIRY_LIST_ENDPOINT,
      false,
      bodyData,
    );

    if (response && response?.data?.code === 200) {
      setBuyerInquiryList(response?.data?.data?.data || []);
    }
  };

  const getStyleList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };
    const response = await getData(GET_STYLE_LIST_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item label="Inquiry ID" name="inquiry_id">
          <Select
            showSearch
            placeholder="Select a Buyer Inquiry"
            onSearch={(value) => {
              getBuyerInquiryList({ inquiry_no: value });
            }}
            optionFilterProp="children"
            size="small"
            allowClear
            onClear={() => getBuyerInquiryList()}
          >
            {isArrayAndHasValue(buyerInquiryList) &&
              buyerInquiryList.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.inquiry_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Style ID" name="style_id">
          <Select
            showSearch
            placeholder="Select a Style"
            onSearch={(value) => {
              getStyleList({ style_no: value });
            }}
            optionFilterProp="children"
            size="small"
            allowClear
            onClear={() => getStyleList()}
          >
            {isArrayAndHasValue(styleList) &&
              styleList.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.style_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default OBFilterFields;
