import { Button, Card, Form, message, Select } from "antd";
import { GET_SEWING_LINE_ENDPOINT } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export default function AppDashboardLine() {
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const onFinish = (values) => {
    navigate(
      `/digitalization/app-dashboard/${values.line.join(
        ",",
      )}?without_layout=true`,
    );
  };

  const getAllLines = async (filterValues) => {
    const query = `${GET_SEWING_LINE_ENDPOINT}`;
    const payload = {
      ...filterValues,
    };

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setOptions(
        response.data?.data?.data?.map((line) => ({
          value: line.id,
          label: line.name,
        })),
      );
    } else {
      message.error("Error getting warehouse data");
    }
  };

  useEffect(() => {
    getAllLines();
  }, []);

  console.log("options", options);

  return (
    <Card
      style={{
        margin: "20px",
        padding: "20px",
      }}
    >
      <Form onFinish={onFinish}>
        <Form.Item
          label="Line"
          name="line"
          rules={[
            {
              required: true,
              message: "Please select line",
            },
          ]}
        >
          <Select
            mode="multiple"
            showSearch
            filterOption={false}
            onSearch={(value) => {
              getAllLines({ name: value });
            }}
          >
            {options.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
