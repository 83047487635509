import React from "react";
import {Button, Col, Divider, Form, Input, Row, Select, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useDispatch } from "react-redux";
import {onGetBuyerList, onGetMerchandiserList, onGetSupplierList} from "../../../redux/actions";

const {Option} = Select;
const MerchandiserCreateForm = (props) => {
    const dispatch = useDispatch();

    const {onBuyerChange, merchandiserList, buyerList, selectedMerchandiser, onAddressChange, address,addMerchandiser, addSupplier, onMerchandiserChange,optionsMerchandiser,
        supplierAddress, supplierList, onSupplierAddressChange, onMerchandiserNameChange,
        onSupplierPhoneNumberChange,inputRef, merchandiserName,
        supplierPhoneNumber, supplierName,onSupplierNameChange,phoneNumber,
        name, onPhoneNumberChange, onChangeBusinessStatus,
        businessStatusOpt, onSupplierChange,
        businessStatus} = props;

    const handleSearchSupplier =(value) => {
        dispatch(onGetSupplierList(1,10, value))
    }

    return (
      <>
        <Row gutter={6}>
          <Col span={4}>
            <Form.Item label="Buyer Name">
              <Input.Group compact>
                <Form.Item
                  name="name"
                  noStyle
                  required
                  rules={[
                    {
                      required: true,
                      message: "Buyer name is required",
                    },
                  ]}
                >
                  <Select
                    required
                    onChange={onBuyerChange}
                    onSearch={(value) =>
                      dispatch(
                        onGetBuyerList({
                          page: 1,
                          per_page: 10,
                          name: value,
                        }),
                      )
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={name}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Buyer"
                    size="small"
                  >
                    {buyerList.length > 0 &&
                      buyerList?.map((item, Idx) => (
                        <Option
                          value={item?.id}
                          label={item?.name}
                          name={item?.name}
                          id={item?.id}
                          phone={item?.phone}
                          branch_name={null}
                          address={item?.address}
                          account_info={item?.account_info}
                          key={Idx}
                          short_name={item?.short_name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Business Status">
              <Input.Group compact>
                <Form.Item
                  name="business_status"
                  noStyle
                  required
                  rules={[
                    {
                      required: true,
                      message: "Business Status is required",
                    },
                  ]}
                >
                  <Select
                    required
                    onChange={onChangeBusinessStatus}
                    options={businessStatusOpt}
                    value={businessStatus}
                    showArrow
                    style={{
                      width: "100%",
                    }}
                    placeholder="Business status"
                    size="small"
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Current Supplier">
              <Input.Group compact>
                <Form.Item
                  name="current_supplier"
                  noStyle
                  required
                  rules={[
                    {
                      required: true,
                      message: "Current Supplier is required",
                    },
                  ]}
                >
                  <Select
                    required
                    onChange={onSupplierChange}
                    showArrow
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    onSearch={handleSearchSupplier}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Current Supplier"
                    size="small"
                    allowClear
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: "8px 0",
                          }}
                        />
                        <Space
                          style={{
                            padding: "0 8px 4px",
                          }}
                        >
                          <Input
                            placeholder="Name"
                            ref={inputRef}
                            value={supplierName}
                            onChange={onSupplierNameChange}
                            size="small"
                          />
                          <Input
                            placeholder="Phone Number"
                            ref={inputRef}
                            value={supplierPhoneNumber}
                            onChange={onSupplierPhoneNumberChange}
                            size="small"
                          />
                          <Input
                            placeholder="Address"
                            ref={inputRef}
                            value={supplierAddress}
                            onChange={onSupplierAddressChange}
                            size="small"
                          />
                          <Button
                            htmlType="submit"
                            icon={<PlusOutlined />}
                            onClick={addSupplier}
                            size="small"
                          >
                            Add
                          </Button>
                        </Space>
                      </>
                    )}
                  >
                    {supplierList?.map((item, Idx) => (
                      <Select.Option
                        key={Idx}
                        id={item?.id}
                        value={item?.id}
                        address={item?.address}
                        phone={item?.phone}
                        name={item?.name}
                        label={item?.name}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Merchandiser Name">
              <Input.Group compact>
                <Form.Item
                  name="merchandiser_name"
                  noStyle
                  required
                  rules={[
                    {
                      required: true,
                      message: "Merchandiser name is required",
                    },
                  ]}
                >
                  <Select
                    required
                    onChange={onMerchandiserChange}
                    onSearch={(value) =>
                      dispatch(onGetMerchandiserList(1, 10, value))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    showArrow
                    style={{
                      width: "100%",
                    }}
                    placeholder="Merchandiser"
                    size="small"
                    allowClear
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: "8px 0",
                          }}
                        />
                        <Space
                          style={{
                            padding: "0 8px 4px",
                          }}
                        >
                          <Input
                            placeholder="Name"
                            ref={inputRef}
                            value={merchandiserName}
                            onChange={onMerchandiserNameChange}
                            size="small"
                          />
                          <Input
                            placeholder="Phone Number"
                            ref={inputRef}
                            value={phoneNumber}
                            onChange={onPhoneNumberChange}
                            size="small"
                          />

                          <Input
                            placeholder="Address"
                            ref={inputRef}
                            value={address}
                            onChange={onAddressChange}
                            size="small"
                          />
                          <Button
                            htmlType="submit"
                            icon={<PlusOutlined />}
                            onClick={addMerchandiser}
                            size="small"
                          >
                            Add
                          </Button>
                        </Space>
                      </>
                    )}
                  >
                    {merchandiserList.length > 0 &&
                      merchandiserList?.map((item, Idx) => (
                        <Option
                          value={item?.id}
                          label={item?.name}
                          id={item?.id}
                          phone={item?.phone}
                          branch_name={null}
                          address={item?.address}
                          account_info={item?.account_info}
                          purpose={item?.purpose}
                          key={Idx}
                        >
                          {item?.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Contact Number" name="phone">
              <Input.Group compact>
                <Form.Item
                  noStyle
                  required
                  rules={[
                    {
                      required: true,
                      message: "Contact Number is required",
                    },
                  ]}
                >
                  <Input
                    value={selectedMerchandiser?.phone}
                    disabled={true}
                    size="small"
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Purpose" name="purpose">
              <Input placeholder="Purpose" size="small" />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
}

export default MerchandiserCreateForm;