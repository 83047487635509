import { Card } from "antd";
import { isArrayAndHasValue } from "../../../../../utils/functions";
import SearchCardItem from "./SearchCardItem";

const SearchList = (props) => {
  // Props
  const { searchData, customCardWidth } = props;

  return (
    <div>
      {isArrayAndHasValue(searchData) &&
        searchData.map((item, index) => {
          return (
            <Card
              title={
                <span style={{ fontSize: "14px" }}>
                  {item.description || "Search Result"}
                </span>
              }
              key={item?.id}
              style={{ width: customCardWidth || "unset" }}
            >
              {/* BOM List */}
              {isArrayAndHasValue(item?.boms) && (
                <SearchCardItem data={item?.boms} sectionName={"BOM"} category="bom" />
              )}

              {/* Buyer PO List */}
              {isArrayAndHasValue(item?.buyer_pos) && (
                <SearchCardItem
                  data={item?.buyer_pos}
                  sectionName={"Buyer PO"}
                  category="buyer_po"
                />
              )}

              {/* OB List */}
              {isArrayAndHasValue(item?.operation_bulletins) && (
                <SearchCardItem
                  data={item?.operation_bulletins}
                  sectionName={"Operation Bulletins"}
                  category="ob"
                />
              )}

              {/* Sales Contract List */}
              {isArrayAndHasValue(item?.sales_contracts) && (
                <SearchCardItem
                  data={item?.sales_contracts}
                  sectionName={"Sales Contracts"}
                  category="sales_contracts"
                />
              )}

              {/* Buyers List */}
              {isArrayAndHasValue(item?.buyers) && (
                <SearchCardItem data={item?.buyers} sectionName={"Buyers"} category="buyers" />
              )}

              {/* Buying Offices List */}
              {isArrayAndHasValue(item?.buying_offices) && (
                <SearchCardItem
                  data={item?.buying_offices}
                  sectionName={"Buying Offices"}
                  category="buying_offices"
                />
              )}

              {/* Styles List */}
              {isArrayAndHasValue(item?.styles) && (
                <SearchCardItem data={item?.styles} sectionName={"Styles"} category="styles" />
              )}
            </Card>
          );
        })}
    </div>
  );
};

export default SearchList;
