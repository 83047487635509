import { Card, Collapse, Form, InputNumber, Table, Typography } from "antd";
import { FoldingContext } from "context/FoldingContext/FoldingContextProvider";
import { useContext } from "react";
import { commaSeparateNumber } from "utils/functions";
import FoldingTableFilter from "../FoldingTableFilter";

const FoldingDetails = (props) => {
  // Contexts
  const { foldingTableData, setFoldingTableData } = useContext(FoldingContext);

  const onChangeFoldingValue = (value, id) => {
    const foldingTableDataCopy = [...foldingTableData];
    const foundItem = foldingTableDataCopy.find((item) => item.id === id);
    foundItem["folding_products"]["folding_piece"] = value;
    foundItem["folding_products"]["remain_piece"] =
      foundItem["folding_products"]["total_remain_piece"] - value;

    setFoldingTableData(foldingTableDataCopy);
  };

  const columns = [
    { title: "Original PO", dataIndex: ["original_po"], key: "original_po" },
    {
      title: "Sub Style",
      dataIndex: ["sub_style_info", "sub_style_no"],
      key: "sub_style",
    },
    { title: "Color", dataIndex: ["color", "name"], key: "color" },
    { title: "Size", dataIndex: ["size", "name"], key: "size" },
    {
      title: "Order Qty.",
      dataIndex: ["sales_order_quantity"],
      key: "sales_order_quantity",
    },
    {
      title: "Sewing Qty.",
      dataIndex: ["folding_products", "total_sewing_piece"],
      key: "total_sewing_piece",
    },
    {
      title: "Folding (pc)",
      dataIndex: ["folding_products", "folding_piece"],
      key: "folding_piece",
      editable: true,
      render(text, record) {
        return {
          children: (
            <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
              <InputNumber
                style={{ width: "100%" }}
                value={record?.folding_products?.folding_piece}
                formatter={(value) => commaSeparateNumber(value)}
                size="small"
                onChange={(value) => onChangeFoldingValue(value, record?.id)}
                max={record?.folding_products?.total_remain_piece}
              />
            </Form.Item>
          ),
        };
      },
    },
    {
      title: "Total Folding (pc)",
      dataIndex: ["folding_products", "total_folding_piece"],
      key: "total_folding_piece",
    },
    {
      title: "Remaining (pc)",
      key: "remain_piece",
      dataIndex: ["folding_products", "remain_piece"],
    },
  ];

  const TableSummary = ({ pageData }) => {
    const orderQtySum = pageData?.reduce((total, item) => {
      return total + item.sales_order_quantity;
    }, 0);
    const sewingQtySum = pageData?.reduce((total, item) => {
      return total + item.folding_products?.total_sewing_piece;
    }, 0);
    const foldingQtySum = pageData?.reduce((total, item) => {
      return total + item.folding_products?.folding_piece;
    }, 0);
    const totalFoldingQtySum = pageData?.reduce((total, item) => {
      return total + item.folding_products?.total_folding_piece;
    }, 0);
    const remainingQtySum = pageData?.reduce((total, item) => {
      return total + item.folding_products?.remain_piece;
    }, 0);

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} id="total">
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} id="sub_style" />
          <Table.Summary.Cell index={2} id="color" />
          <Table.Summary.Cell index={3} id="size" />
          <Table.Summary.Cell index={4} id="order_qty">
            <Typography.Text>{orderQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} id="sewing_qty">
            <Typography.Text>{sewingQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} id="folding_pc">
            <Typography.Text>{foldingQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} id="total_folding_pc">
            <Typography.Text>{totalFoldingQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} id="remaining_pc">
            <Typography.Text>{remainingQtySum}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <>
      <div className="foldingTable" style={{ marginTop: 10 }}>
        <Collapse defaultActiveKey={1}>
          <Collapse.Panel header={<b>Table Filter</b>} key={1}>
            <FoldingTableFilter />
          </Collapse.Panel>
        </Collapse>

        <Card style={{ marginTop: 10 }}>
          <Typography.Title style={{ fontSize: 14 }}>
            Folding Details
          </Typography.Title>
          <Table
            pagination={true}
            size={"small"}
            sticky={true}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={foldingTableData}
            columns={columns}
            summary={(pageData) => {
              return <TableSummary pageData={pageData} />;
            }}
          />
        </Card>
      </div>
    </>
  );
};
export default FoldingDetails;
