import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  Affix,
  Button,
  Card,
  Dropdown,
  Menu,
  Popconfirm,
  Space,
  Table,
} from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  BUYER_INQUIRY_DELETE_ENDPOINT,
  BUYER_INQUIRY_LIST_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { deleteData, getData } from "../../apiServices/common";
import HandleNotification from "../../common/Notification";
import {
  BUYER_INQUIRY_CREATE_PERMISSION,
  BUYER_INQUIRY_DELETE_PERMISSION,
  BUYER_INQUIRY_EDIT_PERMISSION,
  BUYER_INQUIRY_VIEW_PERMISSION,
} from "../../routes/permissions";
import { commaSeparateNumber, hasPermission } from "../../utils/functions";
import { isMobileView } from "../Common/AppHelper";
import CustomFilter from "../Common/CustomFilter";
import Header from "../Common/Header";

const BuyerInquiry = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllPermitList = useCallback(
    async (filterValues) => {
      setLoading(true);
      const bodyData = {
        ...filterValues,
        per_page: rangeData || 10,
        page: currentPage,
      };
      const query = `${BUYER_INQUIRY_LIST_ENDPOINT}`;
      let response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        const masterData = response?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something Went Wrong!",
          null,
        );
      }

      setLoading(false);
    },
    [currentPage, rangeData],
  );

  useEffect(() => {
    getAllPermitList();
  }, [currentPage, getAllPermitList]);

  const columns = [
    {
      title: "Inquiry No.",
      dataIndex: ["inquiry_no"],
      key: "inquiry_no",
    },
    {
      title: "Buying Office",
      dataIndex: ["buying_office_info", "name"],
      key: "buying_office_name",
    },
    {
      title: "Brand",
      dataIndex: ["brand_info", "name"],
      key: "brand_name",
    },
    {
      title: "Factory Name",
      dataIndex: ["factory_info", "name"],
      key: "style",
    },
    {
      title: "Address",
      dataIndex: ["factory_info", "address"],
      key: "style",
    },
    {
      title: "Style",
      dataIndex: ["style_inquiry_map", "style_info", "style_no"],
      key: "style",
    },
    {
      title: "Exp. Order Qty.",
      dataIndex: "expected_order_quantity",
      key: "expected_order_quantity",
      render: (value, record) => {
        return(
          <span>{commaSeparateNumber(value)}</span>
        );
      },
    },
    {
      title: "CM Value Status",
      dataIndex: "cm_status",
      key: "cm_status",
      render: (value, record) => {
        return <span>{value === 1 ? "Approved" : "Pending"}</span>;
      },
    },
    {
      title: "",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const deleteSelectedItem = async (id) => {
    const query = `${BUYER_INQUIRY_DELETE_ENDPOINT}/${id}`;
    const response = await deleteData(query);

    if (response) {
      HandleNotification(
        "success",
        "bottomRight",
        "Buyer Inquiry Deleted Successfully!",
        null,
      );
      getAllPermitList();
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/merchandising/view-buyer-inquiry/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: 1,
          disabled: !hasPermission([BUYER_INQUIRY_VIEW_PERMISSION]),
        },
        {
          label: (
            <Link to={`/merchandising/update-buyer-inquiry/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: 2,
          disabled: !hasPermission([BUYER_INQUIRY_EDIT_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                deleteSelectedItem(data?.id);
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                <DeleteOutlined /> Delete
              </span>
            </Popconfirm>
          ),
          key: 3,
          disabled: !hasPermission([BUYER_INQUIRY_DELETE_PERMISSION]),
        },
        {
          label: (
            <Link to={`/merchandising/buyer-inquiry/special-process/${data.id}`}>
              <EditOutlined /> Special Process
            </Link>
          ),
          key: 4,
          disabled: !hasPermission([BUYER_INQUIRY_EDIT_PERMISSION]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        key="3"
        size="small"
        type="primary"
        disabled={!hasPermission([BUYER_INQUIRY_CREATE_PERMISSION])}
      >
        <Link to="/merchandising/add-buyer-inquiry">
          {hasPermission([BUYER_INQUIRY_CREATE_PERMISSION])
            ? "Add New"
            : "No Permission"}
        </Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header title="Buyer Inquiry" subTitle="" actions={extra} />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={permitList}
            fetchTableData={getAllPermitList}
            filterName="buyer_inquiry"
          />
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default BuyerInquiry;
