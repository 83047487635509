import { Alert, Card, Empty, Table, Typography } from "antd";
import { postData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";
import "./style.css";

export default function CategoryWiseHourlyDefect({
  line_id,
  currentSlide,
  slideId,
}) {
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  const initialGetData = useCallback(async () => {
    const response = await postData(
      "/api/digitalization/bigscreen-dashboard/category-hourly-defects",
      {
        line_id: line_id,
      },
    );
    if (response && response?.code === 200) {
      const data = response?.data;
      const tableData = data?.category_data?.map((item, index) => {
        const hourlyData = item?.hourly_defects?.reduce((acc, curr) => {
          acc[curr.hour] = curr.defects;
          return acc;
        }, {});
        return {
          category_name: item.category_name,
          ...hourlyData,
        };
      });

      setDataSource(tableData);
      setColumns([
        {
          title: "Sl No",
          dataIndex: "sl_no",
          key: "sl_no",
          className: "sl_no",
          render: (text, rec, i) => <span>{i + 1}</span>,
        },
        {
          title: "Category",
          dataIndex: "category_name",
          key: "category_name",
          className: "category_name",
          width: "600px",
        },
        {
          title: "Hour",
          dataIndex: "hour",
          key: "hour",
          className: "hourly",
          children: Object.keys(data?.hours).map((hour) => ({
            title: hour,
            dataIndex: hour,
            key: hour,
            className: `hour hour_${hour}`,
            render: (text) => <span>{text}</span>,
          })),
        },
      ]);
    }
  }, [line_id]);

  useEffect(() => {
    if (line_id && currentSlide === slideId) {
      initialGetData();
    }
  }, [line_id, currentSlide, slideId, initialGetData]);

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
      }}
      className="category-wise-hourly-defect"
    >
      {Array.isArray(dataSource) && dataSource?.length > 0 ? (
        <div>
          <Typography.Title level={3}>
            Category Wise Hourly Defect
          </Typography.Title>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
          />
        </div>
      ) : (
        <Empty
          style={{
            width: "33%",
            margin: "auto",
            marginTop: "10%",
            marginBottom: "10%",
            height: "100%",
          }}
          description={
            <Alert
              message="No Data Found with selected line-id in Category Wise Hourly Defect"
              type="warning"
            />
          }
        />
      )}
    </Card>
  );
}
