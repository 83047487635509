import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    STORE_NEW_SHIPMENT,
    GET_SHIPMENT_DETAIL,
    SHOW_MESSAGE
} from "../ActionTypes";
import JwtAxios from "../../apiServices";
import {GET_SHIPMENT_ENDPOINT, STORE_SHIPMENT_ENDPOINT} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onShipmentStore = (shipment) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${STORE_SHIPMENT_ENDPOINT}`, shipment)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: STORE_NEW_SHIPMENT, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Shipment Information Stored Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Shipment Information Stored Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};

export const onGetShipmentDetails = (payload) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios.post(`${GET_SHIPMENT_ENDPOINT}`, payload)
            .then((data) => {

                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_SHIPMENT_DETAIL, payload: data.data});
                    dispatch({

                        type: SHOW_MESSAGE,
                    });
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};