import { Col, Form, Select } from "antd";
import { GET_DIGITALIZATION_STYLES } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { DailyLinePlanContext } from "context/DailyLinePlanContext";
import { useCallback, useContext } from "react";

const DailyLinePlanFilter = ({ initialTableData, form }) => {
  const {
    options,
    // onChangeBuyer,
    setOptions,
    onChangeStyle,
    debouncedFetchStylesOption,

    // isLineSelected,
  } = useContext(DailyLinePlanContext);

  const getStyles = useCallback(
    async (filterValues = {}) => {
      try {
        const bodyData = { style_no: filterValues?.name?.name };
        const response = await getData(
          GET_DIGITALIZATION_STYLES,
          false,
          bodyData,
        );
        console.log(response?.data?.data?.data);
        setOptions((prevState) => ({
          ...prevState,
          styles: response?.data?.data?.data?.map((item) => {
            return {
              value: item?.id,
              label: item?.style_no,
            };
          }),
        }));
      } catch (error) {
        console.error("getLines -> error", error);
        return [];
      }
    },
    [setOptions],
  );

  const handleClear = async () => {
    // form.resetFields(["style"]);
    await getStyles();
  };

  return (
    <>
      {/* <Col span={6}>
        <Form.Item label="Buyer" name="buyer">
          <Select placeholder="Select Buyer" onChange={onChangeBuyer}>
            {options?.buyers?.map((buyer) => (
              <Select.Option key={buyer.value} value={buyer.value}>
                {buyer.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col> */}
      <Col span={6}>
        <Form.Item
          label="Line"
          name="line"
          // rules={[{ required: false, message: "Please select Line!" }]}
        >
          <Select size="small" allowClear placeholder="Select Line">
            {options.lines.map((line) => (
              <Select.Option key={line.value} value={line.value}>
                {line.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="Style" name="style">
          <Select
            size="small"
            allowClear
            placeholder="Select Style"
            // onChange={() => {
            //   onChangeStyle();
            //   setIsFromFilter(true);
            // }}
            showSearch
            optionFilterProp="children"
            onSearch={(value) => debouncedFetchStylesOption({ name: value })}
            onClear={handleClear}
            // rules={[{ required: false, message: "Please select Line!" }]}
          >
            {options?.styles?.map((style) => (
              <Select.Option key={style.value} value={style.value}>
                {style.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {/* <Col span={6}>
        <Form.Item
          label="PO"
          name="po_map_id"
          rules={[{ required: false, message: "Please select PO!" }]}
        >
          <Select placeholder="Select Style">
            {options?.po?.map((po) => (
              <Select.Option key={po.value} value={po.value}>
                {po.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col> */}
    </>
  );
};

export default DailyLinePlanFilter;
