import React from "react";

export default function Matrix({ task }) {
  return task.details?.length > 0
    ? task.details.map((detail, index) => {
        const matrix =
          detail.plan_curve_mh === 8
            ? 1
            : detail.plan_curve_mh === 10
            ? 12
            : 16;
        const length = matrix * matrix;
        console.log("matrix", matrix);
        return (
          <React.Fragment key={detail.id}>
            <span
              style={{
                position: "absolute",
                bottom: "2px",
                left: "4px",
                backgroundColor: "white",
                padding: "1px 2px",
                opacity: 0.5,
              }}
            >
              {String(task.title).split("_").join(" ")}
            </span>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(${matrix}, 1fr)`,
                gridTemplateRows: `repeat(${matrix}, 1fr)`,
                gap: "1px",
              }}
            >
              {Array.from({ length }, (_, i) => (
                <div
                  key={i}
                  style={{
                    backgroundColor: matrix === 1 ? "gray" : "black",
                    borderRadius: matrix === 1 ? "5px" : "50%",
                    opacity: matrix === 1 ? 0.5 : 1,
                  }}
                />
              ))}
            </div>
          </React.Fragment>
        );
      })
    : null;
}
