import {
    GET_USER_LIST,
    CREATE_NEW_USER,
    DELETE_USER,
    GET_USER_DETAIL, UPDATE_USER_DETAIL
} from '../ActionTypes';

const initialState = {
    userList: [],
    totalUser: null,
    selectedUser: null,
    pageSize: 10,
    page:1,
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_LIST:
            return {
                ...state,
                userList: action.payload.data,
                totalUser: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_USER:
            return {
                ...state,
                userList: [action.payload.data, ...state.userList],
                totalUser: state.totalUser + 1,
            };

        case DELETE_USER: {
            return {
                ...state,
                userList: action.payload.list,
                totalUser: action.payload.total,
            };
        }

        case GET_USER_DETAIL:
            return {
                ...state,
                selectedUser: action.payload,
            };

        case UPDATE_USER_DETAIL:
            return {
                ...state,
                selectedUser: action.payload,
                userList: state.userList.map((User) =>
                    User.id === action.payload.id ? action.payload : User,
                ),
            };

        default:
            return state;
    }
};

export default UserReducer;
