import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../Common/Header";
import EstimateForm from "./EstimateForm";
import { COST_ESTIMATE_APPROVE_PERMISSION } from "../../../../routes/permissions";
import { hasPermission } from "../../../../utils/functions";
import { postData, getData } from "../../../../apiServices/common";
import {
  SALES_CONTRACT_LIST,
  STYLE_WISE_COST_ADD,
  STYLE_WISE_COST_VIEW,
  STYLE_WISE_COST_EDIT,
  REST_SALES_CONTRACT,
} from "../../../../apiServices/API_ENDPOINTS";

const AddOrUpdateCostEstimate = (props) => {
  // Props
  const { editMode, viewMode } = props;

  const { id } = useParams();

  const [costEstimateStatus, setCostEstimateStatus] = useState();

  //  Refs
  const propertyFormRef = useRef(null);
  console.log('id', id);
  //  Antd
  const [form] = Form.useForm();

  //  Router
  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/merchandising/cost-estimate");
  };

  const getCostEstimateInfo = async () => {
    let response = await getData(`/api/accounting/style-wise-cost-estimate/edit/${id}`);
    console.log('status', response?.data?.data?.status);
    if (response) {
      setCostEstimateStatus(response?.data?.data?.status);
    }
  };

  useEffect(() => {
    getCostEstimateInfo();
  }, []);

  // console.log('forms', form);

  const addIou = () => {
    form.submit();
  };
// console.log('costestimate statu', costEstimateStatus);
  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={`${
              editMode ? "Edit" : viewMode ? "View" : "Add"
            } Cost Estimate`}
            addLink="/"
            addText="Add New"
            actions={[
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>,
                !viewMode && (
                  <Button
                    size="small"
                    key={2}
                    type="primary"
                    onClick={() => addIou()}
                  >
                    Save
                  </Button>
                ),
                viewMode && (
                  <Button
                    size="small"
                    key={1}
                    type="primary"
                    onClick={() => addIou()}
                    disabled={!hasPermission([COST_ESTIMATE_APPROVE_PERMISSION]) || costEstimateStatus===1}
                  >
                    {hasPermission([COST_ESTIMATE_APPROVE_PERMISSION])
                      ? costEstimateStatus === 1 ? "Approved" : "Approve"
                      : "No Permission"}
                  </Button>
                ),
            ]}
          />
        </Card>
      </Affix>
      <EstimateForm
        ref={propertyFormRef}
        form={form}
        editMode={editMode}
        viewMode={viewMode}
      />
    </>
  );
};

export default AddOrUpdateCostEstimate;
