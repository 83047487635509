import { Card, Col, Layout, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import "./index.css";
import buyerSVG from "../../assets/icons/buyer.svg";
import productionSVG from "../../assets/icons/production.svg";
import salesSVG from "../../assets/icons/sales.svg";
import AppCards from "../../common/Cards";
import { Content } from "antd/es/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { onGetDashboardData } from "../../redux/actions";
import DashboardContent from "./DashboardContent";

const Dashboard = () => {
  // Antd
  const { Title, Text } = Typography;

  // Others
  const dispatch = useDispatch();
  const dashboardData = useSelector(({ dashboard }) => dashboard.dashboardData);
  const buyerIcon = () => <img src={buyerSVG} />;
  const salesIcon = () => <img src={salesSVG} />;
  const productionIcon = () => <img src={productionSVG} />;
  useEffect(() => {
    dispatch(onGetDashboardData());
  }, []);

  const cardArr = [
    {
      title: "Total Buyer",
      count: dashboardData && dashboardData.total_buyer,
      icon: buyerIcon,
    },
    {
      title: "Total Sales Contract",
      count: dashboardData && dashboardData.total_sales_contract,
      icon: salesIcon,
    },
    {
      title: "Total Good Finished Garments",
      count: dashboardData && dashboardData.total_good_finish_garment,
      icon: productionIcon,
    },
  ];

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
      }}
    >
      <AppCards cardArr={cardArr} />
      <Content className="site-layout-background">
        <DashboardContent />
      </Content>
    </Space>
  );
};
export default Dashboard;
