import { Col, Form, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  STYLE_MERCHANDISING_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import CustomDatePicker from "common/CustomDatePicker";
import { isArrayAndHasValue } from "utils/functions";

const CuttingFilterFields = ({
  initialTableData,
  type,
  filterValues = null,
  setFilterValues = () => {},
}) => {
  const [buyerList, setBuyerList] = useState([]);
  const [styleList, setStyleList] = useState([]);

  // Others
  const { Option } = Select;

  useEffect(() => {
    if (filterValues?.buyer_name) {
      getBuyerList({ name: filterValues?.buyer_name });
    } else {
      getBuyerList();
    }

    if (filterValues?.style_no) {
      getStyleList(filterValues?.style_no);
    } else {
      getStyleList();
    }
  }, [filterValues?.buyer_name, filterValues?.style_no]);

  const saveToStorage = (value, name) => {
    const filter_name =
      type === "cutting_filter" ? "cutting_filter" : "post_cutting_filter";
    const existingFilter = JSON.parse(localStorage.getItem(filter_name)) || {};
    existingFilter[name] = value;
    localStorage.setItem(filter_name, JSON.stringify(existingFilter));
  };

  const getBuyerList = async (values) => {
    const query = `${GET_BUYER_ENDPOINT}`;
    const bodyData = {
      per_page: 10,
      name: values?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBuyerList(res?.data?.data?.data || []);
    }
  };

  const getStyleList = async (style_no) => {
    const bodyData = {
      style_no: style_no,
    };

    let res = await getData(STYLE_MERCHANDISING_LIST, false, bodyData);

    if (res) {
      setStyleList(res?.data?.data?.data || []);
    }
  };
  const handleChangeBuyer = (value) => {
    if (value) {
      setFilterValues({
        ...filterValues,
        buyer_name: buyerList.find((buyer) => buyer.id === value)?.name,
      });
    } else {
      setFilterValues({
        ...filterValues,
        buyer_name: null,
      });
    }
  };
  const handleChangeStyleNo = (value) => {
    if (value) {
      setFilterValues({
        ...filterValues,
        style_no: styleList.find((style) => style.id === value)?.style_no,
      });
    } else {
      setFilterValues({
        ...filterValues,
        style_no: null,
      });
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item
          label="Entry Date"
          name={type === "cutting_filter" ? "cutting_date" : "movement_date"}
        >
          <CustomDatePicker
            style={{ width: "100%" }}
            onChange={(value) =>
              saveToStorage(moment(value).format("YYYY-MM-DD"), "start")
            }
            size="small"
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            placeholder="Select a Buyer"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getBuyerList({ name: value })}
            allowClear
            onClear={() => getBuyerList()}
            onChange={handleChangeBuyer}
          >
            {isArrayAndHasValue(buyerList) &&
              buyerList?.map((sup) => (
                <Option value={sup?.id} key={sup?.id}>
                  {sup?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Style No." name="style_id">
          <Select
            showSearch
            placeholder="Select a Style No."
            optionFilterProp="children"
            onSearch={(value) => {
              getStyleList(value);
            }}
            size="small"
            allowClear
            onClear={() => getStyleList()}
            onChange={handleChangeStyleNo}
          >
            {isArrayAndHasValue(styleList) &&
              styleList?.map((sup) => (
                <Option value={sup?.id} key={sup?.id}>
                  {sup?.style_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default CuttingFilterFields;
