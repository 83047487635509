import { Card, Col, Descriptions, Row, Table, Typography, message } from "antd";
import { DIGITALIZATION_CHECKLIST } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

export default function SupervisorChecklistView() {
  const [checklistData, setChecklistData] = useState(null);
  const { id } = useParams();
  const getSupervisorChecklist = useCallback(async () => {
    const query = `${DIGITALIZATION_CHECKLIST}/${id}`;
    const response = await getData(query);
    if (response?.status === 200) {
      setChecklistData(response?.data?.data || {});
    } else {
      message.error("Something went wrong! Please try again.");
    }
  }, [id]);

  useEffect(() => {
    getSupervisorChecklist();
  }, [id, getSupervisorChecklist]);

  console.log({ checklistData });
  const columns = [
    {
      title: "Question",
      dataIndex: ["question", "name"],
      key: "question",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
  ];

  return (
    <Row gutter={4}>
      <Col span={24}>
        <Card
          title={<Typography.Title level={4}>Checklist Info</Typography.Title>}
          bordered
          style={{
            margin: "10px 20px",
            padding: "10px",
          }}
        >
          <Descriptions column={4}>
            <Descriptions.Item label="Name">
              <Typography.Text level={2} strong>
                {checklistData?.user?.name}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              {" "}
              <Typography.Text level={2} strong>
                {checklistData?.user?.phone}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {" "}
              <Typography.Text level={2} strong>
                {checklistData?.user?.email}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Line">
              {" "}
              <Typography.Text level={2} strong>
                {checklistData?.line_id?.name}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          title={<Typography.Title level={4}>Checklists</Typography.Title>}
          bordered
          style={{
            margin: "10px 20px",
            padding: "10px",
          }}
        >
          <Table
            dataSource={checklistData?.question_answeres || []}
            columns={columns}
          />
        </Card>
      </Col>
    </Row>
  );
}
