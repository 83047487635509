import { Form } from "antd";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import { useState } from "react";
import { SEWING_EDIT_PERMISSION } from "routes/permissions";
import { hasPermission } from "utils/functions";
import SewingForm from "../SewingForm";

const SewingUpdate = () => {
  // Antd
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                handleAddItemOpen={onSubmit}
                disabled={
                  !hasPermission([SEWING_EDIT_PERMISSION]) || isSubmitting
                }
                addButtonText={
                  hasPermission([SEWING_EDIT_PERMISSION])
                    ? "Update"
                    : "No Permission"
                }
                hideFilterButton
                showBackButton
              />
            }
            title="Update Sewing"
          />
        }
        content={
          <>
            <SewingForm
              form={form}
              isEdit={true}
              setIsSubmitting={setIsSubmitting}
            />
          </>
        }
      />
    </>
  );
};

export default SewingUpdate;
