import {
  Card,
  Input,
  Select,
  Table,
  Button,
  Breadcrumb,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
} from "antd";
import React, { Fragment, useState, useEffect } from "react";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useLocation } from "react-router-dom";
import { MoreOutlined, EditOutlined, DeleteTwoTone } from "@ant-design/icons";
import {
  GET_CUSTOMS_CLEARANCE_LIST,
  DELETE_CUSTOMS_CLEARANCE,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { alertPop } from "../../../apiServices/common/helper";
import { hasPermission } from "../../../utils/functions";
import {
  NOTING_ASSESSMENT_CREATE_PERMISSION,
  NOTING_ASSESSMENT_EDIT_PERMISSION,
  NOTING_ASSESSMENT_DELETE_PERMISSION,
} from "../../../routes/permissions";


const { Option } = Select;

export default function Noting(props) {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  //   States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [notingList, setNotingList] = useState([]);
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValues, setSearchValues] = useState();
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const searchFilter = (values) => {
    //console.log("values", values);
  };

  const columns = [
    {
      title: "Type Of Shipment",
      dataIndex: ["position_type"],
      key: "position_type",
      width: "10%",
    },
    {
      title: "UD Number",
      dataIndex: ["utilization_declearation_number"],
      key: "utilization_declearation_number",
      width: "20%",
    },
    {
      title: "Invoice Number",
      dataIndex: ["invoice_info", "invoice_number"],
      key: ["invoice_info", "invoice_number"],
      width: "10%",
    },
    {
      title: "Supplier",
      dataIndex: ["supplier_info", "name"],
      key: "document_submission_date",
      width: "20%",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllNotingDetails = async (passedObject) => {
    let filter = passedObject;
    let response = await getData(GET_CUSTOMS_CLEARANCE_LIST, false, filter);

    if (response && response?.status === 200) {
      let masterData = response?.data;

      setNotingList(masterData?.data?.data);
      setTotalData(masterData?.data?.total);
      setRangeData(masterData?.data?.per_page);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllNotingDetails();
  }, [currentPage, searchValues]);

  const removeSubmissionRealization = async (value) => {
    const response = await deleteData(`${DELETE_CUSTOMS_CLEARANCE}/${value}`);

    if (response && response?.code === 200) {
      alertPop(
        "success",
        response?.messages ? response?.messages : "Deleted Successfully!",
      );
      getAllNotingDetails();
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link
              to={`/import-customs-clearance/edit-noting-assessment-examination/${data.id}`}
            >
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([NOTING_ASSESSMENT_EDIT_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeSubmissionRealization(data.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: "0",
          disabled: !hasPermission([NOTING_ASSESSMENT_DELETE_PERMISSION]),
        },
      ]}
    />
  );
  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Noting Assessment and Examinations"
            subTitle=""
            actions={[
              <Button
                type={showFilter ? "danger" : "primary"}
                size="small"
                onClick={handleFilter}
                style={{ float: "right" }}
              >
                {`${showFilter ? "Close" : "Open"} Filter Option`}
              </Button>,
              <Button
                key="3"
                size="small"
                type="primary"
                disabled={!hasPermission([NOTING_ASSESSMENT_CREATE_PERMISSION])}
              >
                <Link to="/import-customs-clearance/add-noting-assessment-examination">
                  {hasPermission([NOTING_ASSESSMENT_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"}
                </Link>
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader
            // title="Style List"
            searchFilter={searchFilter}
          />
          <CustomFilter
            showFilter={showFilter}
            tableData={notingList}
            fetchTableData={getAllNotingDetails}
            filterName="noting_assessment"
          />
          <Table
            loading={loading}
            dataSource={notingList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}
