import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
} from "antd";
import moment from "moment";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { MoreOutlined, EditOutlined, DeleteTwoTone, EyeOutlined } from "@ant-design/icons";
import { IMPORT_DETAIL_LIST, IMPORT_DETAIL_DOWNLOAD } from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import { cglFormatDate, formatNumberWithCommas } from "../../../utils/functions";
import { hasPermission } from "../../../utils/functions";
import CustomFilter from "../../Common/CustomFilter";
import {
  DAILY_IMPORT_POSITION_VIEW_PERMISSION,
  DAILY_IMPORT_POSITION_CREATE_PERMISSION,
  DAILY_IMPORT_POSITION_EDIT_PERMISSION,
  DAILY_IMPORT_POSITION_DELETE_PERMISSION,
} from "../../../routes/permissions";
import { filter } from "lodash";

export default function Forwarder(props) {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  // const [showFilter, setShowFilter] = useState(
  //   localStorage.getItem("filter_status") && routerPath
  //     ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
  //     : false,
  // );

  const supplierTypeList = [
    { label: "Foreign", name: "foreign", value: 0 },
    { label: "Local", name: "local", value: 1 },
    { label: "EPZ", name: "epz", value: 2 },
  ];

  const [showFilter, setShowFilter] = useState(false);
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();
  const [enableDownload, setEnableDownload] = useState(true);
  const [supplierType, setSupplierType] = useState(null);

  let { supplierTypeId } = useParams();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const onForAdd = () => {
    const selectedSupplier = supplierTypeList?.find((item) => item?.value === Number(supplierTypeId));
    const supplierType = selectedSupplier?.name;
    navigate(`/import/add-detail/${supplierType}`);
  }

  const openForView = (id) => {
    const selectedSupplier = supplierTypeList?.find((item) => item?.value === Number(supplierTypeId));
    const supplierType = selectedSupplier?.name;
    navigate(`/import/view-detail/${id}/${supplierType}`);
  };

  const openForEdit = (id) => {
    const selectedSupplier = supplierTypeList?.find((item) => item?.value === Number(supplierTypeId));
    const supplierType = selectedSupplier?.name;
    navigate(`/import/edit-detail/${id}/${supplierType}`);
  };

  const columns = [
    {
      title: "Payment Type",
      dataIndex: ["position_type"],
      key: ["position_type"],
    },
    {
      title: "LC/RTGS/TT Number",
      dataIndex: ["b2blc_info", "b2b_lc_number"],
      key: ["b2blc_info", "b2b_lc_number"],
    },
    {
      title: "Invoice Number",
      dataIndex: ["invoice_number"],
      key: ["invoice_number"],
    },

    {
      title: "Import Quantity",
      dataIndex: ["quantity_ctn"],
      key: "quantity_ctn",
      render: (value, record) => {
        return (
          <>
            {value
              ? `${value} ${
                  record?.import_quantity_unit
                    ? record?.import_quantity_unit
                    : ""
                }`
              : ""}
          </>
        );
      },
    },

    {
      title: "Invoice Quantity",
      dataIndex: ["quantity_yds"],
      key: "quantity_yds",
      render: (value, record) => {
        return (
          <>
            {value
              ? `${value} ${
                  record?.invoice_quantity_unit
                    ? record?.invoice_quantity_unit
                    : ""
                }`
              : ""}
          </>
        );
      },
    },
    {
      title: "Invoice Value",
      dataIndex: ["invoice_value"],
      key: ["invoice_value"],
      render: (value) => formatNumberWithCommas(value),
    },
    {
      title: "Description of Goods",
      dataIndex: ["goods_description"],
      key: ["goods_description"],
    },
    {
      title: "Delivery Date",
      dataIndex: ["delivery_date"],
      key: "delivery_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "In-House Date",
      dataIndex: ["inhouse_date"],
      key: "inhouse_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  // Filter columns based on supplierTypeId
  const filteredColumns = columns.filter(column => {
    
    if (supplierTypeId == 1) {
      return (
        column.key !== "quantity_ctn" && 
        column.key !== "quantity_yds" && 
        column.key !== "delivery_date" && 
        column.key !== "inhouse_date"
      );
    } else if (supplierTypeId == 2) {
      return column.key !== "quantity_yds";
    }
    return true;
  });

  const getAllPermitList = async (passedObject) => {
    let filter = passedObject;
    filter.supplier_type = supplierTypeList[supplierTypeId].label;
    let res = await getData(IMPORT_DETAIL_LIST, false, filter);

    if (res) {
      let masterData = res?.data;
      setPermitList(masterData?.data?.data);
      setTotalData(masterData?.data?.total);
      setRangeData(masterData?.data?.per_page);
      
      // Check if all values are undefined
      delete filter.per_page;
      delete filter.page;
      delete filter.supplier_type;
      // exlude supplier_type from filter object
      if(Object.values(filter).every(value => value === undefined || value === null)) {
        setEnableDownload(true)
      } else {
        setEnableDownload(false)
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const filters = JSON.parse(localStorage.getItem("import_detail_filter_value"));
    getAllPermitList({ page: currentPage, ...filters });
  }, [currentPage, supplierTypeId]);

  const removeDetails = async (value) => {
    let res = await deleteData(`/api/accounting/import-detail/delete/${value}`);
    window.location.reload();
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <>
              <span>
                <EditOutlined />
              </span>

              <span
                type="button"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  openForEdit(data?.id);
                }}
              >
                Edit
              </span>
            </>
          ),
          key: 0,
          disabled: !hasPermission([DAILY_IMPORT_POSITION_EDIT_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeDetails(data.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: 1,
          disabled: !hasPermission([DAILY_IMPORT_POSITION_DELETE_PERMISSION]),
        },
        {
          label: (
            <>
              <span>
                <EyeOutlined />
              </span>

              <span
                type="button"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  openForView(data?.id);
                }}
              >
                View
              </span>
            </>
          ),
          key: 2,
          disabled: !hasPermission([DAILY_IMPORT_POSITION_VIEW_PERMISSION]),
        },
      ]}
    />
  );
  const paginate = (page) => {
    setCurrentPage(page);
  };


  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const onDownload = () => {
    console.log('onDownload');
  }

  const importDownload = async (values) => {
    // Function for import download
    try {
      // Check if all values are undefined
      if (Object.values(values).every(value => value === undefined)) {
        return; // Don't make the API call
      }
      
      // Format from_date and to_date with "DD-MM-YYYY" format
      if (values.from_date) {
        values.from_date = moment(values.from_date).format("YYYY-MM-DD");
      }
      if (values.to_date) {
        values.to_date = moment(values.to_date).format("YYYY-MM-DD");
      }

      const response = await getData(IMPORT_DETAIL_DOWNLOAD, false, values);
      if(response?.status===200) {
        const URL = `${process.env.REACT_APP_BASE_URL}/storage/import_b2blc_document/${response?.data?.data}`;
        window.open(URL, "_blank", "noreferrer");
      }
    } catch (error) {
      console.log('Download Exception Erro !');
    }
  }

  

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`Import Shipment Status (${supplierTypeList[supplierTypeId].label})`}
            subTitle=""
            actions={[
              <Button
                type={showFilter ? "danger" : "primary"}
                size="small"
                onClick={handleFilter}
                style={{ float: "right" }}
              >
                {`${showFilter ? "Close" : "Open"} Filter Option`}
              </Button>,
              <Button
                key="3"
                size="small"
                type="primary"
                disabled={
                  !hasPermission([DAILY_IMPORT_POSITION_CREATE_PERMISSION])
                }
                onClick={onForAdd}
              >
                {hasPermission([DAILY_IMPORT_POSITION_CREATE_PERMISSION])
                  ? "Add New"
                  : "No Permission"}
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          {/* <BodyHeader/> */}
          <CustomFilter
            showFilter={showFilter}
            tableData={permitList}
            fetchTableData={getAllPermitList}
            filterName="import_detail"
            filterAPI={onDownload}
            importDownload={importDownload}
            disabledButton={enableDownload}
            setTableData={setPermitList}
            setSupplierType={setSupplierType}
          />
          <Table
            loading={loading}
            dataSource={permitList}
            columns={filteredColumns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}
