import { Table, Typography, message } from "antd";
import moment from "moment";
import { useCallback, useState } from "react";
import {
  DELETE_INVENTORY_DETAILS,
  EXPORT_INVENTORY_DETAILS,
  GET_INVENTORY_DETAILS,
  VIEW_INVENTORY_DETAILS_REPORT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";
import { cglFormatDate } from "utils/functions";

const InventoryDetails = () => {
  // States
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  // Antd
  const { Title } = Typography;

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/inventory_details/")[1] : "";
    return updatedPath;
  };

  const getAllDetails = useCallback(async () => {
    setLoading(true);
    const query = `${GET_INVENTORY_DETAILS}`;

    const response = await getData(query);

    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting details data");
    }
  }, []);

  const exportDetails = async (filterValues) => {
    setLoading(true);
    const query = `${EXPORT_INVENTORY_DETAILS}`;
    const { start_date, end_date, supplier_id } = filterValues || {};
    const bodyData = {
      start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : null,
      end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : null,
    };
    const response = await getData(
      query,
      false,
      bodyData,
      { "Content-Type": "blob" },
      "arraybuffer",
    );

    if (response && response?.data) {
      // Download the file
      const outputFilename = `${String(start_date)
        .split(" ")
        .join("-")}-${String(end_date).split(" ").join("-")}.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setLoading(false);
      message.success("Inventry Detail Report Exported Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const previewFunction = async (filterValues) => {
    setLoading(true);
    const { start_date, end_date } = filterValues || {};
    const bodyData = {
      start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : null,
      end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : null,
    };

    const query = `${VIEW_INVENTORY_DETAILS_REPORT}`;
    const response = await postData(query, bodyData);

    if (response?.code === 200) {
      setLoading(false);
      setTableData(response?.data);
    } else {
      setLoading(false);
      message.error("Error getting MRR data");
    }
  };

  // // Effects
  // useEffect(() => {
  //   getAllDetails();
  // }, [refresh, getAllDetails]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedTracking = async (name) => {
    setLoading(true);
    const updatedName = cutPath(name);
    const query = `${DELETE_INVENTORY_DETAILS}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setLoading(false);
      message.success("Inventory Details Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/inventory_details/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  const tableColumns = [
    {
      title: "Inventory Details Information",
      children: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          render: (row) => cglFormatDate(row),
        },
        {
          title: "Buying Office",
          dataIndex: "buying_office",
          key: "buying_office",
        },
        {
          title: "Style",
          dataIndex: "style",
          key: "style",
        },
        {
          title: "Color",
          dataIndex: "color",
          key: "color",
        },
        {
          title: "Item Category",
          dataIndex: "item_type",
          key: "item_type",
        },
        {
          title: "Item",
          dataIndex: "item",
          key: "item",
        },
        {
          title: "Unit",
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: "Movement Type",
          dataIndex: "movement_type",
          key: "movement_type",
        },
        {
          title: "Challan/Invoice No.",
          dataIndex: "challan_no",
          key: "challan_no",
        },
        {
          title: "Requisition/Gatepass No.",
          dataIndex: "reference_no",
          key: "reference_no",
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: "Roll/PKG/Bag Qty",
          dataIndex: "shipment_qty",
          key: "shipment_qty",
        },
        {
          title: "Location",
          dataIndex: "location",
          key: "location",
        },
      ],
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportDetails}
                hideAddButton={true}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Inventory Details</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <FilterComponent
                tableData={tableData}
                filterAPI={exportDetails}
                filterName={"inventory_details"}
                searchButtonText={"Export"}
                defaultOpen
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                isPreview={true}
                previewFunction={previewFunction}
              />
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination
                scroll={{ y: "max-content" }}
                bordered
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default InventoryDetails;
