import { Typography } from "antd";
import { PNL_REPORT_OPERATOR_HR_OT_EXPENSES } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import AppContent from "common/AppContent";
import HandleNotification from "common/Notification";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import HROTExpenseDataTable from "./HROTExpenseDataTable";

const HROTExpenseData = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [userList, setUserList] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const filterValuesHandler = (values) => {
    setFilterValues(values);
  };

  const getDataForTable = async (filterValues) => {
    const query = `${PNL_REPORT_OPERATOR_HR_OT_EXPENSES}`;
    const bodyData = {
      page: page,
      per_page: filterValues?.per_page || 10,
      ...(filterValues?.name && { name: filterValues?.name }),
      ...(filterValues?.date && {
        date: dayjs(filterValues?.date).format("YYYY-MM-DD"),
      }),
      ...(filterValues?.card_no && { card_no: filterValues?.card_no }),
      ...(filterValues?.line_id && { line_id: filterValues?.line_id }),
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setUserList(response?.data?.data?.data);
      setPageSize(response?.data?.data?.per_page);
      setTotal(response?.data?.data?.total);
    } else {
      HandleNotification("error", "Error", "Something went wrong");
    }
  };

  useEffect(() => {
    getDataForTable({ page: page, ...filterValues });
  }, [refresh, page, pageSize, filterValues]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const filterName = "he_ot_expense_data";
  const tableColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (text, record) => {
        return dayjs(text).format("DD-MMM-YY");
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    { title: "Card No", dataIndex: "card_no", key: "card_no", align: "center" },

    { title: "Designation", dataIndex: "designation", key: "designation" },
    {
      title: "Sub Department",
      dataIndex: "sub_department",
      key: "sub_department",
    },
    {
      title: "Line",
      dataIndex: ["line_info", "name"],
      key: "line_info_name",
      align: "center",
      render: (text, record) => {
        return record?.line_info?.name ?? "-";
      },
    },
    { title: "Shift", dataIndex: "shift", key: "shift", align: "center" },
    { title: "In Time", dataIndex: "in_time", key: "in_time", align: "center" },
    {
      title: "Out Time",
      dataIndex: "out_time",
      key: "out_time",
      align: "center",
    },
    { title: "OT", dataIndex: "ot", key: "ot", align: "center" },
    { title: "OT Pay", dataIndex: "ot_pay", key: "ot_pay", align: "center" },
    { title: "Status", dataIndex: "status", key: "status", align: "center" },
  ];

  const header = (
    <AppPageHeader
      extra={
        <ExtraContent
          hasImportButton
          uploadAPI={"/api/pnl-report/import-operator-hr-ot-expenses"}
          hideAddButton
          refresh={refresh}
          setRefresh={setRefresh}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      }
      title={<Title level={5}>HR OT Expense Data</Title>}
    />
  );
  const content = (
    <>
      <FilterComponent
        tableData={userList}
        filterAPI={filterValuesHandler}
        showFilter={showFilter}
        filterName={filterName}
        setShowFilter={setShowFilter}
      />
      <HROTExpenseDataTable
        onChange={(newPage) => {
          onPageChange(newPage);
        }}
        total={total}
        current={page}
        defaultCurrent={page}
        pageSize={pageSize}
        dataSource={userList}
        tableColumns={tableColumns}
        pagination={true}
      />
    </>
  );
  return (
    <>
      <AppContent header={header} content={content} />
    </>
  );
};

export default HROTExpenseData;
