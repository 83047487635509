import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Card,
  Button,
  Breadcrumb,
  Affix,
  Col,
  Row,
  Pagination,
  Input, 
  Space, 
  message,
  Select,
  Table,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  Upload,
} from "antd";
import Header from "../Common/Header";
import { Link } from "react-router-dom";
import {
  NOTIFICATIONS_LIST,
  MARK_ALL_READ_NOTIFICATION,
} from "../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../apiServices/common";
import parse from "html-react-parser";
import "./index.css";
import { alertPop } from "../../apiServices/common/helper";
import { timeDifferentCalculation } from "../../utils/functions";

export default function Notifications(props) {
  // States
  const [notifications, setNotifications] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(null);
  const [totalItem, setTotalItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Antd
  const { Search } = Input;

  const getNotificationList = useCallback(async () => {
    let url = NOTIFICATIONS_LIST + "?";
    if (searchValue) url = url + "&search=" + searchValue;
    if (currentPage) url = url + "&page=" + currentPage;

    let response = await getData(url);

    if (response) {
      let masterData = response?.data?.data?.data;
      setNotifications(masterData);
      setTotalPage(response?.data?.data?.last_page);
      setTotalItem(response?.data?.data?.total);
    }
  }, [currentPage, searchValue]);

  // Effects
  useEffect(() => {
    getNotificationList();
  }, [searchValue, currentPage, getNotificationList]);

  const markAllRead = async () => {
    const query = MARK_ALL_READ_NOTIFICATION;
    const response = await postData(query);

    if (response && response?.code === 200) {
      alertPop(
        "success",
        response?.message[0] || `Notification status updated Successfully`,
      );
    } else {
      alertPop(
        "error",
        response?.message[0] || `Something went wrong! Please try again later.`,
      );
    }
  };

  const onSearch = (text) => {
    setSearchValue(text);
  }

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title="Notifications"
            subTitle=""
            actions={[
              <Search
                placeholder="Search Notifications"
                onSearch={onSearch}
                enterButton
                allowClear
                size="small"
              />,
              // <Button key="3" size="medium" type="primary">
              //   Filter
              // </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <div className="containt-body">
        <div className="notification-items px-4 mt-3">
          {notifications && notifications.length > 0 ? (
            <>
              <Button type="primary" className="my-2" onClick={markAllRead}>
                Mark all as read
              </Button>
              {notifications.map((noti) => {
                return (
                  <div
                    className="notification-item"
                    id={`notification-${noti.id}`}
                    key={noti.id}
                    style={!noti.read_at ? { background: "#e2e2e2" } : {}}
                  >
                    <Row>
                      <Col span={24}>
                        <p>{noti.notification_title}</p>
                        <div className="parsedStyle">
                          {parse(noti.notification_body)}
                        </div>
                        <small>
                          {timeDifferentCalculation(noti.created_at)}
                        </small>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="mt-3" style={{ textAlign: "center" }}>
              <h4>There is no notification for you. </h4>
            </div>
          )}
          <div className="centered-item-parent">
            <Pagination
              defaultCurrent={1}
              total={totalItem}
              pageSize={10}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
