import { forwardRef, useState, useEffect } from "react";
import {
  Card,
  DatePicker,
  InputNumber,
  Modal,
  Row,
  Col,
  Form,
  Input,
  Select,
  Space,
  Button,
  message,
  Affix,
} from "antd";
import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  ACCEPTANCE_B2BLC_LIST,
  B2BLC_INVOICE_LIST,
  CHALLAN_LIST,
  IMPORT_VEHICLE,
  FOC_TYPE_INVOICE_LIST,
  SALES_CONTRACT_LIST
} from "../../../apiServices/API_ENDPOINTS";
import { postData, getData, putData } from "../../../apiServices/common";
import { useNavigate, useParams } from "react-router-dom";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../utils/functions";
import HandleNotification from "../../../common/Notification";
import ChallanForm from "../../Configuration/ChallanConfig/ChallanForm";
import Header from "../../Common/Header";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";

// Constants
const transporter = [
  { id: 1, value: "Self Transport" },
  { id: 2, value: "Raj Transport" },
  { id: 3, value: "SR Enterprise" },
  { id: 4, value: "Vendor" },
];

const importer_names = [
  { id: 1, value: "CONCORDE GARMENTS LTD." },
  { id: 2, value: "OSMAN INTERLININGS LTD." },
  { id: 2, value: "OSMAN ACCESSORIES" },
  { id: 2, value: "OTL-DOUBLE GULL MFG. CO. LTD" },
  { id: 2, value: "LILY APPARELS LTD" },
  { id: 2, value: "SSL WIRELESS" },
  { id: 2, value: "REFLECTIONS" },
  { id: 2, value: "STAINED GLASS OVERLAY" },
  { id: 2, value: "BIDDYUT LTD." },
];

const place_of_loading = [
  { id: 1, value: "CTG" },
  { id: 2, value: "AIRPORT" },
  { id: 3, value: "DEPZ" },
  { id: 4, value: "AEPZ" },
  { id: 5, value: "COMILLA EPZ" },
  { id: 6, value: "BENAPOL" },
  { id: 7, value: "ICD" },
  { id: 8, value: "KAMALAPUR" },
  { id: 9, value: "OTHERS" },
];

const place_of_arrival = [
  { id: 1, value: "MIRPUR" },
  { id: 1, value: "SAVAR EPZ" },
  { id: 1, value: "ASHULIA" },
  { id: 1, value: "ESKATON" },
  { id: 1, value: "KAWRANBAZAR" },
  { id: 1, value: "BARIDHARA" },
  { id: 1, value: "OTHERS" },
];

const GoodsDeliveryForm = forwardRef((props, ref) => {
  // Props
  const { editMode } = props;

  // States
  const [b2bLcList, setB2bLcList] = useState([]);
  const [poDetailsData, setPoDetailsData] = useState([{ unique_id: 0 }]);
  const [challanList, setChallanList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [salesContractList, setSalesContractList] = useState(null);

  // Router
  const navigate = useNavigate();
  const { importId } = useParams();

  // Antd
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { Option } = Select;

  const getSelectedVehicleStatus = async (id) => {
    const query = `${IMPORT_VEHICLE}/${id}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      const masterData = response?.data?.data;

      form.setFieldsValue({
        vehicle_number: masterData?.vehicle_number,
        transporter: masterData?.transporter,
        vehicle_size: masterData?.vehicle_size,
        empty_vehicle_arrival_time: masterData?.empty_vehicle_arrival_time
          ? moment(masterData?.empty_vehicle_arrival_time)
          : null,
        driver_name: masterData?.driver_name,
        driver_cell_number: masterData?.driver_cell_number,
        driving_license_number: masterData?.driving_license_number,
        driver_nid_number: masterData?.driver_nid_number,
        loading_place: masterData?.loading_place,
        dispatch_time: masterData?.dispatch_time
          ? moment(masterData?.dispatch_time)
          : null,
        challan_id: masterData?.challan_id,
        security_bolt_seal_number: masterData?.security_bolt_seal_number,
        gprs_tracking_number: masterData?.gprs_tracking_number,
        delivery_place: masterData?.delivery_place,
        unloading_date: masterData?.unloading_date
          ? moment(masterData?.unloading_date)
          : null,
        arrival_date: masterData?.arrival_date
          ? moment(masterData?.arrival_date)
          : null,
        importer_name: masterData?.importer_name,
      });

      const poData =
        isArrayAndHasValue(masterData?.import_vehicle_details) &&
        masterData?.import_vehicle_details?.map((item, index) => {
          return {
            unique_id: index,
            b2b_lc_id: item?.b2b_lc_id ? Number(item?.b2b_lc_id) : null,
            import_details_id: item?.import_details_id
              ? Number(item?.import_details_id)
              : null,
            loading_qty: item?.loading_qty ? Number(item?.loading_qty) : 0,
            loading_cbm: item?.loading_cbm ? Number(item?.loading_cbm) : 0,
            loading_weight: item?.loading_weight
              ? Number(item?.loading_weight)
              : 0,
            gross_weight: item?.invoice_info?.gross_weight
              ? Number(item?.invoice_info?.gross_weight)
              : 0,
            cbm: item?.invoice_info?.cbm ? Number(item?.invoice_info?.cbm) : 0,
            import_qty: item?.invoice_info?.quantity_ctn
              ? Number(item?.invoice_info?.quantity_ctn)
              : 0,
            invoice_list: item?.b2b_lc_info?.invoice_list,
            type_of_shipment: item?.type_of_shipment,
            sales_contract_id: item?.invoice_info?.sales_contract_id,
          };
        });

      setPoDetailsData(poData);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  useEffect(() => {
    if (editMode && importId) {
      getSelectedVehicleStatus(importId);
    }
  }, [editMode, importId]);

  const onChangePoDetailsValue = (value, index, keyName) => {
    // Copy poDetailsData state array
    const poDetailsDataCopy = structuredClone(poDetailsData);
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );
    foundPoItem[keyName] = value;

    setPoDetailsData(poDetailsDataCopy);
  };

  const getB2BLcList = async () => {
    const query = `${ACCEPTANCE_B2BLC_LIST}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setB2bLcList(response?.data?.data || []);
    }
  };

  const getAllChallan = async (filterValues) => {
    const { name } = filterValues || {};
    const query = `${CHALLAN_LIST}`;
    const bodyData = {
      ...(name && { name: name }),
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setChallanList(response?.data?.data?.data);
    } else {
      message.error("Error getting challan data");
    }
  };

  const getSalesContractList = async (filterValues) => {
    const query = `${SALES_CONTRACT_LIST}`;
    const bodyData = {
      "per_page": 10,
      "name": filterValues?.name || "",
    };
    let response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSalesContractList(response?.data?.data?.data);
    }
    else {
      message.error("Error getting sales contract data");
    }
  };

  // Effects
  useEffect(() => {
    getB2BLcList();
    getAllChallan();
    getSalesContractList();
  }, []);


  const getInvoiceList = async (lc_id, unique_id) => {
    const poDetailsDataCopy = structuredClone(poDetailsData);
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === unique_id,
    );

    const response = await getData(`${B2BLC_INVOICE_LIST}/${lc_id}`);

    if (response && response?.status === 200) {
      // Set invoice list and b2blc id in po data
      foundPoItem["invoice_list"] = response?.data?.data;
      foundPoItem["b2b_lc_id"] = lc_id;
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }

    setPoDetailsData(poDetailsDataCopy);
  };

  const getFOCInvoiceList = async (value, unique_id) => {
    const poDetailsDataCopy = structuredClone(poDetailsData);
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === unique_id,
    );

    const response = await getData(`${FOC_TYPE_INVOICE_LIST}`);

    if (response && response?.data?.code === 200) {
      // Set invoice list and b2blc id in po data
      foundPoItem["type_of_shipment"] = value;
      foundPoItem["invoice_list"] = response?.data?.data;
      foundPoItem["b2b_lc_id"] = null;
      // foundPoItem["import_details_id"] = null;
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }

    setPoDetailsData(poDetailsDataCopy);
  };

  const onSelectInvoice = (id, unique_id, invoiceList) => {
    const foundInvoiceItem = invoiceList.find((item) => item.id === id);

    if (foundInvoiceItem) {
      const poDetailsDataCopy = structuredClone(poDetailsData);
      const foundPoItem = poDetailsDataCopy?.find(
        (item) => item?.unique_id === unique_id,
      );
      foundPoItem["import_qty"] = foundInvoiceItem?.quantity_ctn
        ? Number(foundInvoiceItem?.quantity_ctn)
        : 0;
      foundPoItem["cbm"] = foundInvoiceItem?.cbm
        ? Number(foundInvoiceItem?.cbm)
        : 0;
      foundPoItem["gross_weight"] = foundInvoiceItem?.gross_weight
        ? Number(foundInvoiceItem?.gross_weight)
        : 0;
      foundPoItem["import_details_id"] = id;

      setPoDetailsData(poDetailsDataCopy);
    }
  };

  const removeRow = (row_index) => {
    confirm({
      title: "Do you want to delete this row?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const updatedPoDetailsData = poDetailsData.filter(
          (item) => item?.unique_id !== row_index,
        );
        setPoDetailsData(updatedPoDetailsData);
      },
    });
  };

  const addRow = () => {
    const lastItemUniqueId = poDetailsData[poDetailsData.length - 1]?.unique_id;
    const updatedPoDetailsData = [
      ...poDetailsData,
      { unique_id: lastItemUniqueId + 1 },
    ];
    setPoDetailsData(updatedPoDetailsData);
  };

  const onFinishVehicle = async (values) => {
    // console.log("onFinishExportAdd values", values);

    const addQuery = `${IMPORT_VEHICLE}`;
    const editQuery = `${IMPORT_VEHICLE}/${importId}`;

    const bodyData = {
      vehicle_number: values?.vehicle_number,
      transporter: values?.transporter,
      vehicle_size: values?.vehicle_size,
      empty_vehicle_arrival_time: values?.empty_vehicle_arrival_time,
      driver_name: values?.driver_name,
      driver_cell_number: values?.driver_cell_number,
      driving_license_number: values?.driving_license_number,
      driver_nid_number: values?.driver_nid_number,
      loading_place: values?.loading_place,
      dispatch_time: values?.dispatch_time,
      challan_id: values?.challan_id,
      security_bolt_seal_number: values?.security_bolt_seal_number,
      gprs_tracking_number: values?.gprs_tracking_number,
      delivery_place: values?.delivery_place,
      unloading_date: values?.unloading_date,
      arrival_date: values?.arrival_date,
      importer_name: values?.importer_name,
      lc_invoice_items:
        isArrayAndHasValue(poDetailsData) &&
        poDetailsData?.map((obj, index) => {
          return {
            type_of_shipment: obj?.type_of_shipment || null,
            b2b_lc_id: obj?.b2b_lc_id || null,
            import_details_id: obj?.import_details_id || null,
            loading_qty: obj?.loading_qty,
            loading_cbm: obj?.loading_cbm,
            loading_weight: obj?.loading_weight,
            sales_contract_id: obj?.sales_contract_id || null,
          };
        }),
    };

    if (editMode) {
      const response = await putData(editQuery, bodyData);

      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "bottomRight",
          "Successfully Updated!",
          null,
        );
        navigate("/import/goods-delivery");
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something Went Wrong!",
          null,
        );
      }
    } else {
      const response = await postData(addQuery, bodyData);

      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "bottomRight",
          "Successfully Added!",
          null,
        );
        navigate("/import/goods-delivery");
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something Went Wrong!",
          null,
        );
      }
    }
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const discartForm = () => {
    navigate("/import/goods-delivery");
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={`${editMode ? "Update" : "Add"} Vehicle Status`}
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => form.submit()}
                >
                  Save
                </Button>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      <div className="containt-body pt-3">
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={onFinishVehicle}
        >
          <Card>
            <Row gutter={6}>
              <Col span={4}>
                <Form.Item
                  label="Vehicle Number"
                  name="vehicle_number"
                  rules={[
                    { required: true, message: "Please write Vehicle Number" },
                  ]}
                >
                  <Input placeholder="Vehicle Number" size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Transporter" name="transporter">
                  <Select placeholder="Transporter" size="small">
                    {transporter.map((option) => {
                      return (
                        <option key={option.id} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Vehicle Size"
                  name="vehicle_size"
                  rules={[
                    { required: true, message: "Please Select Vehicle Size" },
                  ]}
                >
                  <Select placeholder="Vehicle Size" size="small">
                    <option value="23 FT">23 FT</option>
                    <option value="16 FT">16 FT</option>
                    <option value="8 FT">8 FT</option>
                    <option value="Others">Others</option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Empty Vehicle Arrival Time"
                  name="empty_vehicle_arrival_time"
                >
                  <CustomDatePicker
                    style={{ width: "100%" }}
                    size="small"
                    showTime
                    format="DD-MMM-YYYY HH:mm:ss"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Driver Name"
                  name="driver_name"
                  rules={[
                    { required: true, message: "Please write Driver Name" },
                  ]}
                >
                  <Input placeholder="Driver Name" size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Driver Cell No."
                  name="driver_cell_number"
                  rules={[
                    { required: true, message: "Please write Driver Cell No." },
                  ]}
                >
                  <Input placeholder="Driver Cell No." size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Driving License Number"
                  name="driving_license_number"
                >
                  <Input placeholder="Driving License Number" size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Driver NID Number" name="driver_nid_number">
                  <Input placeholder="Driver NID Number" size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Place of Loading"
                  name="loading_place"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Place of Loading",
                    },
                  ]}
                >
                  <Select placeholder="Place of Loading" size="small">
                    {place_of_loading.map((option) => {
                      return (
                        <option key={option.id} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Dispatch Date Time" name="dispatch_time">
                  <CustomDatePicker
                    style={{ width: "100%" }}
                    size="small"
                    showTime
                    format="DD-MMM-YYYY HH:mm:ss"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Space>
                  <Form.Item
                    label="Challan No."
                    name="challan_id"
                    rules={[
                      {
                        required: true,
                        message: `Please select/write Challan No.`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      allowClear
                      onClear={() => {
                        form.setFieldsValue({ challan_id: null });
                      }}
                      size="small"
                    >
                      {isArrayAndHasValue(challanList) &&
                        challanList?.map((challanItem, index) => (
                          <Option
                            key={challanItem?.id || index}
                            value={challanItem?.id}
                          >
                            {challanItem?.challan_no || ""}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <div className="centered-item-parent">
                    <Form.Item label=" ">
                      <Button
                        icon={<PlusOutlined />}
                        size="small"
                        onClick={onModalOpen}
                      />
                    </Form.Item>
                  </div>
                </Space>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Security Bolt Seal No."
                  name="security_bolt_seal_number"
                  rules={[
                    {
                      required: true,
                      message: "Please write Security Bolt Seal No.",
                    },
                  ]}
                >
                  <Input placeholder="Security Bolt seal number" size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="GPRS Tracking Number"
                  name="gprs_tracking_number"
                >
                  <Input placeholder="GPRS Tracking Number" size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Place of Arrival"
                  name="delivery_place"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Place of Arrival",
                    },
                  ]}
                >
                  <Select placeholder="Place of Arrival" size="small">
                    {place_of_arrival.map((option) => {
                      return (
                        <option key={option.id} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Arrival Date" name="arrival_date">
                  <CustomDatePicker
                    style={{ width: "100%" }}
                    size="small"
                    format="DD-MMM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Unloading Date Time" name="unloading_date">
                  <CustomDatePicker
                    style={{ width: "100%" }}
                    size="small"
                    showTime
                    format="DD-MMM-YYYY HH:mm:ss"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Importer Name"
                  name="importer_name"
                  rules={[
                    { required: true, message: "Please Select Importer Name" },
                  ]}
                >
                  <Select placeholder="Importer Name" size="small">
                    {importer_names.map((option) => {
                      return (
                        <option key={option.id} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>

        <div className="custome-table">
          <table border="1" style={{ marginBottom: "30px" }}>
            <thead>
              <tr>
                <th key="header_shipment_type" className="bc-java ">
                  Type of Shipment
                </th>
                <th key="header_sales_contract" className="bc-java ">
                  Sales Contract
                </th>
                <th key="header_lc_number" className="bc-java ">
                  LC No.
                </th>
                <th key="header_invoice_number" className="bc-java ">
                  Invoice No.
                </th>
                <th key="header_import_qty" className="bc-java ">
                  Import Qty.
                </th>
                <th key="header_cbm" className="bc-java ">
                  CBM
                </th>
                <th key="header_gross_weight" className="bc-java ">
                  Gross Weight
                </th>
                <th key="header_loading_qty" className="bc-java ">
                  Loading Qty.
                </th>
                <th key="header_loading_cbm" className="bc-java ">
                  Loading CBM
                </th>
                <th key="header_loading_weight" className="bc-java ">
                  Loading Weight
                </th>
                <th key="header_balance_qty" className="bc-java ">
                  Balance Qty.
                </th>
                <th key="header_balance_cbm" className="bc-java ">
                  Balance CBM
                </th>
                <th key="header_balance_weight" className="bc-java ">
                  Balance Weight
                </th>
                <th key="header_add_row" className="bc-java ">
                  Add
                </th>
                <th key="header_delete_row" className="bc-java ">
                  Delete
                </th>
              </tr>
            </thead>

            <tbody className="scrollable-table-container">
              {isArrayAndHasValue(poDetailsData) &&
                poDetailsData?.map((obj, index) => {
                  return (
                    <tr key={`table_parent_key_${index}`}>
                      <td className="t-body sticky">
                        <Select
                          placeholder="Type"
                          style={{ width: "100%" }}
                          showSearch
                          size="small"
                          onChange={(value) =>
                            onChangePoDetailsValue(
                              value,
                              obj?.unique_id,
                              "type_of_shipment",
                            )
                          }
                          onSelect={(value) => {
                            if (value === "FOC") {
                              getFOCInvoiceList(value, obj?.unique_id);
                            }
                          }}
                          value={obj?.type_of_shipment}
                        >
                          <option value="LC">LC</option>
                          <option value="RTGS">RTGS</option>
                          <option value="FOC">FOC</option>
                          <option value="TT">TT</option>
                        </Select>
                      </td>
                      <td className="t-body sticky">
                        <Select
                          placeholder="Sales Contract"
                          style={{ width: "100%" }}
                          showSearch
                          size="small"
                          onChange={(value) =>
                            onChangePoDetailsValue(
                              value,
                              obj?.unique_id,
                              "sales_contract_id",
                            )
                          }
                          value={obj?.sales_contract_id}
                          disabled={obj?.type_of_shipment !== "FOC"}
                        >
                          {isArrayAndHasValue(salesContractList) &&
                            salesContractList.map((option) => {
                              return (
                                <option key={option.id} value={option.id}>
                                  {option.reference_no}
                                </option>
                              );
                            })}
                        </Select>
                      </td>
                      <td className="t-body sticky">
                        <Select
                          placeholder="LC Number"
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="small"
                          onSelect={(value) =>
                            getInvoiceList(value, obj?.unique_id)
                          }
                          value={obj?.b2b_lc_id}
                          disabled={obj?.type_of_shipment === "FOC"}
                        >
                          {b2bLcList.map((option) => {
                            return (
                              <option key={option.id} value={option.id}>
                                {option.b2b_lc_number}
                              </option>
                            );
                          })}
                        </Select>
                      </td>
                      <td className="t-body sticky">
                        <Select
                          placeholder="Invoice No."
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="small"
                          onSelect={(value) =>
                            onSelectInvoice(
                              value,
                              obj?.unique_id,
                              obj?.invoice_list,
                            )
                          }
                          disabled={!isArrayAndHasValue(obj?.invoice_list)}
                          value={obj?.import_details_id}
                        >
                          {isArrayAndHasValue(obj?.invoice_list) &&
                            obj?.invoice_list?.map((option) => {
                              return (
                                <option key={option.id} value={option.id}>
                                  {option.invoice_number}
                                </option>
                              );
                            })}
                        </Select>
                      </td>
                      <td className="t-body sticky">
                        <Input
                          value={obj?.import_qty}
                          placeholder="Import Qty."
                          size="small"
                          disabled
                        />
                      </td>
                      <td className="t-body sticky">
                        <Input
                          value={obj?.cbm}
                          placeholder="CBM"
                          size="small"
                          disabled
                        />
                      </td>
                      <td className="t-body sticky">
                        <Input
                          value={obj?.gross_weight}
                          placeholder="Gross Weight"
                          size="small"
                          disabled
                        />
                      </td>
                      <td className="t-body sticky">
                        <InputNumber
                          onChange={(value) =>
                            onChangePoDetailsValue(
                              value,
                              obj?.unique_id,
                              "loading_qty",
                            )
                          }
                          placeholder="Loading Qty."
                          size="small"
                          style={{ width: "100%" }}
                          value={obj?.loading_qty}
                        />
                      </td>
                      <td className="t-body sticky">
                        <InputNumber
                          onChange={(value) =>
                            onChangePoDetailsValue(
                              value,
                              obj?.unique_id,
                              "loading_cbm",
                            )
                          }
                          placeholder="Loading CBM"
                          size="small"
                          style={{ width: "100%" }}
                          value={obj?.loading_cbm}
                        />
                      </td>
                      <td className="t-body sticky">
                        <InputNumber
                          onChange={(value) =>
                            onChangePoDetailsValue(
                              value,
                              obj?.unique_id,
                              "loading_weight",
                            )
                          }
                          placeholder="Loading Weight"
                          size="small"
                          style={{ width: "100%" }}
                          value={obj?.loading_weight}
                        />
                      </td>
                      <td className="t-body sticky">
                        <Input
                          value={obj?.import_qty - obj?.loading_qty || 0}
                          placeholder="Balance Qty."
                          size="small"
                          disabled
                        />
                      </td>
                      <td className="t-body sticky">
                        <Input
                          value={obj?.cbm - obj?.loading_cbm || 0}
                          placeholder="Balance CBM"
                          size="small"
                          disabled
                        />
                      </td>
                      <td className="t-body sticky">
                        <Input
                          value={obj?.gross_weight - obj?.loading_weight || 0}
                          placeholder="Balance Weight"
                          size="small"
                          disabled
                        />
                      </td>
                      <td className="t-body" onClick={addRow}>
                        <PlusCircleOutlined
                          className="cursore-pointer"
                          style={{ fontSize: "16px", color: "Green" }}
                        />
                      </td>
                      <td
                        className="t-body"
                        onClick={() => {
                          removeRow(obj?.unique_id);
                        }}
                      >
                        <MinusCircleOutlined
                          className="cursore-pointer"
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Modal
          open={isModalOpen}
          title={"Create Challan"}
          onCancel={() => {
            onCloseModal();
          }}
          maskStyle={{ backdropFilter: "blur(4px)" }}
          footer={null}
        >
          <ChallanForm
            isEdit={false}
            onCloseModal={onCloseModal}
            from={"inventory"}
            refetch={getAllChallan}
            isVehicleStatus={true}
          />
        </Modal>
      </div>
    </>
  );
});

export default GoodsDeliveryForm;
