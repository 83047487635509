import React, { forwardRef, useEffect, useState, useCallback } from "react";
import {
  Card,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Form,
  Input,
  Select,
} from "antd";
import moment from "moment";
import {
  IMPORT_DETAIL_ADD,
  IMPORT_DETAIL_EDIT,
  IMPORT_DETAIL_ADD_DOC,
  ALL_SALES_CONTRACT_LIST,
  IMPORT_DETAIL_ALL_SUPPLIERS,
  SALES_CONTRACT_LIST,
  GET_ALL_B2BLCS
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import ImportDetailDocument from "../../../Common/ImportDetailDocument";
import { commaSeparateNumber } from "../../../../utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const AddExportSalesContractForm = forwardRef((props, ref) => {
  // Props
  const { permitInfo, view, form, setLoading, supplierType } = props;

  // States
  const [b2bLcList, setB2bLcList] = useState([]);
  const [positionType, setPositionType] = useState();
  const [suppliersList, setSuppliersList] = useState([]);
  const [filesList, setFileList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [salesContractList, setSalesContractList] = useState(null);

  // Others
  var document = [];
  var document_name = [];

  const onFinishSalesContract = async (values) => {
    if (filesList?.length) {
      filesList.forEach((item) => {
        document_name.push(item?.name);
        document.push(item?.id);
      });
    }

    const bodyData = {
      ...values,
      ...(permitInfo && { id: permitInfo?.sales_contract_commercial?.id }),
    };

    setLoading(true)
    if (bodyData?.id > 0) {
      let res = await postData(
        IMPORT_DETAIL_EDIT + "/" + bodyData?.id,
        bodyData,
      );
      if (res && res?.code === 200) {
        alertPop("success", "Daily Import Position Updated Successfully");
      }
    } else {
      let res = await postData(IMPORT_DETAIL_ADD, bodyData);
      if (res && res?.code === 200) {
        alertPop("success", "Daily Import Position Added Successfully");
      }
    }
    setLoading(false)
  };

  const getSuppliersList = useCallback(async () => {
    let response = await getData(IMPORT_DETAIL_ALL_SUPPLIERS);

    if (response && response?.status === 200) {
      setSuppliersList(response?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  const getSalesContractList = async () => {
    let response = await getData(ALL_SALES_CONTRACT_LIST);

    if (response && response?.status === 200) {
      setSalesContractList(response?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  };

  const getBuyerInfo = async (salesContractId) => {
    let response = await getData(
      `${SALES_CONTRACT_LIST}/${salesContractId}/view`,
    );

    if (response && response?.status === 200) {
      form.setFieldValue(
        "buyer_name",
        response?.data?.data?.sales_contract?.buyer_info?.name,
      );
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  };

  const getLcNumber = async (value) => {
    form.resetFields(["import_lc_number"]);
    setPositionType(value);
    const endpoint = `${GET_ALL_B2BLCS}?payment-type=${value}`
    const response = await getData(endpoint);
    if (response) {
      setB2bLcList(response?.data?.data || []);
    }
  };

  const getLcDetails = async (value, fromOnChange) => {
    let get_b2blc_details = `/api/accounting/import-b2blc/view/${value}`;
    let res = await getData(get_b2blc_details);

    if (res) {
      form.setFieldsValue({
        import_date: res?.data?.data[0]?.sales_contract_commercial?.b2b_lc_date
          ? moment(res?.data?.data[0]?.sales_contract_commercial?.b2b_lc_date)
          : null,
        supplier_id: res?.data?.data[0]?.sales_contract_commercial?.supplier_id,
        buyer_name:
          res?.data?.data[0]?.sales_contract_commercial?.sale_contract_info
            ?.buyer_info?.name,
        sales_contract_number:
          res?.data?.data[0]?.sales_contract_commercial?.sale_contract_info
            ?.reference_no,
        sales_contract_id:
          res?.data?.data[0]?.sales_contract_commercial?.sale_contract_info?.id,
        udn: res?.data?.data[0]?.sales_contract_commercial
          ?.sales_contract_commercial_info?.ud_number,
        ud_date: res?.data?.data[0]?.sales_contract_commercial
          ?.sales_contract_commercial_info?.ud_date
          ? moment(
              res?.data?.data[0]?.sales_contract_commercial
                ?.sales_contract_commercial_info?.ud_date,
            )
          : null,
      });
    }
  };

  const getLcDetailsEdit = async (value) => {
    let get_b2blc_details = `/api/accounting/import-b2blc/view/${value}`;
    let res = await getData(get_b2blc_details);

    if (res) {
      const ud_date = res?.data?.data[0]?.sales_contract_commercial
        ?.sales_contract_commercial_info?.ud_date
        ? moment(
            res?.data?.data[0]?.sales_contract_commercial
              ?.sales_contract_commercial_info?.ud_date,
          )
        : null;

      form.setFieldsValue({
        udn: res?.data?.data[0]?.sales_contract_commercial
          ?.sales_contract_commercial_info?.ud_number,
        ud_date: ud_date,
      });
    }
  };

  useEffect(() => {
    getSuppliersList();
    getSalesContractList();
  }, []);

  useEffect(() => {
    if (permitInfo && form) {

      let sales_contract_commercial_value = permitInfo?.sales_contract_commercial;

      if(sales_contract_commercial_value?.position_type !== 'FOC') {
        getLcDetailsEdit(permitInfo?.sales_contract_commercial?.import_lc_number);
        getLcNumber(sales_contract_commercial_value?.position_type);

      delete sales_contract_commercial_value["ud_date"];
      delete sales_contract_commercial_value["udn"];
      }

      setSelectedType(permitInfo?.sales_contract_commercial?.position_type);

      form.setFieldsValue({
        ...sales_contract_commercial_value,
        import_lc_number: sales_contract_commercial_value.import_lc_number,
        import_date:
          sales_contract_commercial_value.import_date != null
            ? moment(sales_contract_commercial_value.import_date, "YYYY-MM-DD")
            : null,
        copy_documents_receive_date:
          sales_contract_commercial_value.copy_documents_receive_date != null
            ? moment(
                sales_contract_commercial_value.copy_documents_receive_date,
                "YYYY-MM-DD",
              )
            : null,
        original_documents_receive_date:
          sales_contract_commercial_value.original_documents_receive_date !=
          null
            ? moment(
                sales_contract_commercial_value.original_documents_receive_date,
                "YYYY-MM-DD",
              )
            : null,
        documents_handover_date:
          sales_contract_commercial_value.documents_handover_date != null
            ? moment(
                sales_contract_commercial_value.documents_handover_date,
                "YYYY-MM-DD",
              )
            : null,
        etd_board_date: sales_contract_commercial_value.etd_board_date
          ? moment(sales_contract_commercial_value.etd_board_date, "YYYY-MM-DD")
          : null,
        eta: sales_contract_commercial_value.eta
          ? moment(sales_contract_commercial_value.eta, "YYYY-MM-DD")
          : null,
        berthing_date:
          sales_contract_commercial_value.berthing_date != null
            ? moment(
                sales_contract_commercial_value.berthing_date,
                "YYYY-MM-DD",
              )
            : null,
        unstuffing_date:
          sales_contract_commercial_value.unstuffing_date != null
            ? moment(
                sales_contract_commercial_value.unstuffing_date,
                "YYYY-MM-DD",
              )
            : null,
        delivery_date:
          sales_contract_commercial_value.delivery_date != null
            ? moment(
                sales_contract_commercial_value.delivery_date,
                "YYYY-MM-DD",
              )
            : null,
        inhouse_date:
          sales_contract_commercial_value.inhouse_date != null
            ? moment(sales_contract_commercial_value.inhouse_date, "YYYY-MM-DD")
            : null,
        bill_entry_date:
          sales_contract_commercial_value.bill_entry_date != null
            ? moment(
                sales_contract_commercial_value.bill_entry_date,
                "YYYY-MM-DD",
              )
            : null,
        factory_receive_confirmation_date:
          sales_contract_commercial_value.factory_receive_confirmation_date !=
          null
            ? moment(
                sales_contract_commercial_value.factory_receive_confirmation_date,
                "YYYY-MM-DD",
              )
            : null,
        courier_date: sales_contract_commercial_value.courier_date
          ? moment(sales_contract_commercial_value.courier_date, "YYYY-MM-DD")
          : null,
          ud_date: sales_contract_commercial_value.ud_date != null
          ? moment(sales_contract_commercial_value.ud_date, "YYYY-MM-DD")
          : null,

          // Conditionally add ud_date if position_type is FOC
          ...(sales_contract_commercial_value.position_type === 'FOC' && {
            udn: sales_contract_commercial_value.udn,
            ud_date: sales_contract_commercial_value.ud_date != null
            ? moment(sales_contract_commercial_value.ud_date, "YYYY-MM-DD")
            : null
        })
      });

      setFileList(
        permitInfo?.sales_contract_commercial?.detail_document.map((item) => {
          return {
            id: item?.id,
            name: item?.document_name,
            document: item?.document,
            // name: file.name,
            path: item?.document,
          };
        }),
      );
    } else {
    }
  }, [permitInfo]);

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishSalesContract}
      >
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Type of Shipment"
                name="position_type"
                rules={[
                  {
                    required: true,
                    message: "Type is required",
                  },
                ]}
              >
                <Select
                  placeholder="Type of Shipment"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    getLcNumber(value);
                    setSelectedType(value);
                  }}
                  disabled={view}
                  size="small"
                >
                  <option value="LC">LC</option>
                  <option value="RTGS">RTGS</option>
                  <option value="FOC">FOC</option>
                  <option value="TT">TT</option>
                </Select>
              </Form.Item>
            </Col>

            {selectedType !== "FOC" && (
              <Col className="gutter-row" span={4}>
                <Form.Item
                  label="Import LC/RTGS/TT No"
                  name="import_lc_number"
                  rules={[
                    {
                      required: true,
                      message: "LC/RTGS/TT Number is required",
                    },
                  ]}
                >
                  {positionType === "FOC" ? (
                    <Input className="w-100" disabled={view} />
                  ) : (
                    <Select
                      showSearch
                      placeholder="LC/RTGS/TT Number"
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        getLcDetails(value);
                      }}
                      disabled={view}
                      size="small"
                    >
                      {b2bLcList.map((option) => {
                        return (
                          <option key={option.id} value={option.id}>
                            {option.b2b_lc_number}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}

            {selectedType !== "FOC" && (
              <Col className="gutter-row" span={4}>
                <Form.Item
                  label="Import LC/RTGS/TT Date"
                  name="import_date"
                  rules={[
                    {
                      required: true,
                      message: "Import LC/RTGS/TT Date is required",
                    },
                  ]}
                >
                  {positionType === "FOC" ? (
                    <CustomDatePicker
                      style={{ width: "100%" }}
                      disabled={view}
                      size="small"
                    />
                  ) : (
                    <CustomDatePicker
                      style={{ width: "100%" }}
                      disabled={true}
                      size="small"
                    />
                  )}
                </Form.Item>
              </Col>
            )}
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract Number"
                name="sales_contract_number"
              >
                {selectedType === "FOC" ? (
                  <Select
                    showSearch
                    placeholder="Sales Contract"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onSelect={(value) => getBuyerInfo(value)}
                    disabled={view}
                    size="small"
                  >
                    {salesContractList.map((option) => {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.reference_no}
                        </option>
                      );
                    })}
                  </Select>
                ) : (
                  <Input
                    className="w-100"
                    disabled={true}
                    placeholder="Sales Contract Number"
                    size="small"
                  />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Supplier Name"
                name="supplier_id"
                rules={[
                  {
                    required: true,
                    message: "Please input Supplier Name!",
                  },
                ]}
              >
                <Select
                  placeholder="Supplier Name"
                  style={{ width: "100%" }}
                  disabled={selectedType !== "FOC"}
                  size="small"
                >
                  {suppliersList.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Buyer Name" name="buyer_name">
                <Input
                  className="w-100"
                  disabled={true}
                  placeholder="Buyer Name"
                  size="small"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="UD Number" name="udn">
                <Input
                  className="w-100"
                  disabled={selectedType !== "FOC"}
                  placeholder="Utilization Declaration Number"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="UD Date"
                name="ud_date"
                placeholder="Utilization Declaration Date"
              >
                <CustomDatePicker
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD"
                  disabled={selectedType !== "FOC"}
                  selected={moment()}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Invoice Number"
                name="invoice_number"
                rules={[
                  {
                    required: true,
                    message: "Invoice Number is required",
                  },
                ]}
              >
                <Input
                  className="w-100"
                  disabled={view}
                  placeholder="Invoice Number"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Invoice Value"
                name="invoice_value"
                rules={[
                  {
                    required: true,
                    message: "Invoice Value is required",
                  },
                ]}
              >
                <InputNumber
                  disabled={view}
                  placeholder="Invoice Number"
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Description of Goods" name="goods_description">
                <Select
                  placeholder="Description of Goods"
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                >
                  <option value="Fabric">Fabric</option>
                  <option value="Accessories">Accessories</option>
                  <option value="Fabric & Accessories">
                    Fabric & Accessories
                  </option>
                  <option value="Print">Print</option>
                  <option value="Washing">Washing</option>
                  <option value="Embroidery">Embroidery</option>
                  <option value="Testing">Testing Charge</option>
                  <option value="Inspection">Inspection Charge</option>
                  <option value="Courier">Courier Charge</option>
                  <option value="Portal">Portal Charge</option>
                </Select>
              </Form.Item>
            </Col>

            {supplierType !== 'local' && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Import Quantity" name="quantity_ctn">
                    <InputNumber
                      className="w-100"
                      disabled={view}
                      placeholder="Import Quantity"
                      style={{ width: "100%" }}
                      formatter={(value) => commaSeparateNumber(value)}
                      precision={2}
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Import Quantity Unit"
                    name="import_quantity_unit"
                  >
                    <Select
                      placeholder="Import Quantity Unit"
                      style={{ width: "100%" }}
                      disabled={view}
                      size="small"
                    >
                      <option value="Cartons">Cartons</option>
                      <option value="Rolls">Rolls</option>
                      <option value="Bales">Bales</option>
                      <option value="Packages">Packages</option>
                      <option value="Pallets">Pallets</option>s
                      <option value="As per Invoice">As per Invoice</option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            {/* Hide for EPZ Supplier */}
            {supplierType !== 'local' && supplierType !== 'epz' && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Invoice Quantity" name="quantity_yds">
                    <InputNumber
                      className="w-100"
                      disabled={view}
                      placeholder="Invoice Quantity"
                      style={{ width: "100%" }}
                      formatter={(value) => commaSeparateNumber(value)}
                      precision={2}
                      size="small"
                    />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Invoice Quantity Unit"
                    name="invoice_quantity_unit"
                  >
                    <Select
                      placeholder="Invoice Quantity Unit"
                      style={{ width: "100%" }}
                      disabled={view}
                      size="small"
                    >
                      <option value="Yds">Yds</option>
                      <option value="Mtr">Mtr</option>
                      <option value="Pcs">Pcs</option>
                      <option value="Cone">Cone</option>
                      <option value="GG">GG</option>
                      <option value="GRS">GRS</option>
                      <option value="KG">KG</option>
                      <option value="As per Invoice">As per Invoice</option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            {supplierType !== 'local' && (
              <Col className="gutter-row" span={4}>
                <Form.Item label="Gross Weight" name="gross_weight">
                  <Input
                    className="w-100"
                    disabled={view}
                    placeholder="Gross Weight"
                    size="small"
                  />
                </Form.Item>
              </Col>
            )}

            {supplierType !== 'local' && supplierType !== 'epz' && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="CBM" name="cbm">
                    <Input
                      className="w-100"
                      disabled={view}
                      placeholder="CBM"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Forwarder / Courier Name"
                    name="forwarder_name"
                  >
                    <Input
                      className="w-100"
                      disabled={view}
                      placeholder="Forwarder / Courier Name"
                      size="small"
                    />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={4}>
                  <Form.Item label="Vessel / Flight Name" name="vessel_name">
                    <Input
                      className="w-100"
                      disabled={view}
                      placeholder="Vessel / Flight Name"
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col className="gutter-row" span={4}>
              <Form.Item
                label={supplierType === 'local' ? "TR/CHALAN No" : "BL/AWBL/TR/CHALAN No"}
                name="bl_number"
                rules={[
                  {
                    required: true,
                    message: "BL / AWBL / TR / CHALLAN Number is required",
                  },
                ]}
              >
                <Input
                  className="w-100"
                  disabled={view}
                  placeholder="BL / AWBL / TR / CHALLAN Number"
                  size="small"
                />
              </Form.Item>
            </Col>

            {supplierType !== 'local' && supplierType !== 'epz' && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Container / Flight Number"
                    name="container_number"
                  >
                    <Input
                      className="w-100"
                      disabled={view}
                      placeholder="Container / Flight Number"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Courier Number" name="courier_number">
                    <Input
                      className="w-100"
                      disabled={view}
                      placeholder="Courier Number"
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {supplierType !== 'local' && supplierType !== 'epz' && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="ETD/On Board Date" name="etd_board_date">
                    <CustomDatePicker style={{ width: "100%" }} disabled={view} size="small" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="ETA Date" name="eta">
                    <CustomDatePicker style={{ width: "100%" }} disabled={view} size="small" />
                  </Form.Item>
                </Col>
              </>
            )}

            {supplierType !== 'local' && supplierType !== 'epz' && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Berthing Date" name="berthing_date">
                    <CustomDatePicker style={{ width: "100%" }} disabled={view} size="small" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Un-Stuffing Date" name="unstuffing_date">
                    <CustomDatePicker style={{ width: "100%" }} disabled={view} size="small" />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Origianl Doc Receive Date"
                name="original_documents_receive_date"
              >
                <CustomDatePicker style={{ width: "100%" }} disabled={view} size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Doc Send/Hand over Date"
                name="documents_handover_date"
              >
                <CustomDatePicker style={{ width: "100%" }} disabled={view} size="small" />
              </Form.Item>
            </Col>

            {supplierType !== 'local' && supplierType !== 'epz' && (
              <Col className="gutter-row" span={4}>
                <Form.Item label="Mode of Shipment" name="shipment_mode">
                  <Select
                    placeholder="Mode of Shipment"
                    style={{ width: "100%" }}
                    size="small"
                    disabled={view}
                  >
                    <option value="SEA">SEA</option>
                    <option value="AIR">AIR</option>
                    <option value="LAND">LAND</option>
                    <option value="TRANSPORT">TRANSPORT</option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {supplierType !== 'local' && (
              <Col className="gutter-row" span={4}>
                <Form.Item label="Shipment Methods" name="shipment_type">
                  <Select
                    placeholder="Shipment Methods"
                    style={{ width: "100%" }}
                    size="small"
                    disabled={view}
                  >
                    <option value="LCL">LCL</option>
                    <option value="FCL">FCL</option>
                    <option value="LTL">LTL</option>
                    <option value="FTL">FTL</option>
                    <option value="ACF">ACF</option>
                    <option value="ASF">ASF</option>
                    <option value="Others">Others</option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {supplierType !== 'local' && (
              <Col className="gutter-row" span={4}>
                <Form.Item label="Delivery Date" name="delivery_date">
                  <CustomDatePicker style={{ width: "100%" }} disabled={view} size="small" />
                </Form.Item>
              </Col>
            )}

            {supplierType !== 'local' && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Projected in-House Date"
                    name="inhouse_date"
                  >
                    <CustomDatePicker style={{ width: "100%" }} disabled={view} size="small" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Clearing Agent" name="clearing_agent">
                    <Select
                      placeholder="Clearing Agent"
                      style={{ width: "100%" }}
                      size="small"
                      disabled={view}
                    >
                      <option value="Self C&F">Self C&F</option>
                      <option value="C&F Vendor">C&F Vendor</option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={4}>
                  <Form.Item label="Loading Point" name="loading_point">
                    <Select
                      placeholder="Loading Point"
                      style={{ width: "100%" }}
                      size="small"
                      disabled={view}
                    >
                      <option value="CTG">CTG</option>
                      <option value="AIRPORT">AIRPORT</option>
                      <option value="AEPZ">AEPZ</option>
                      <option value="DEPZ">DEPZ</option>
                      <option value="Benapole">Benapole</option>
                      <option value="Others">Others</option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
           
            <Form.Item name="sales_contract_id" type="hidden">
              <Input
                className="w-100"
                disabled={true}
                style={{ width: "100%", color: "#000" }}
                type="hidden"
                size="small"
              />
            </Form.Item>
          </Row>
        </Card>
      </Form>
      <ImportDetailDocument
        DOC_ADD={IMPORT_DETAIL_ADD_DOC}
        setFileList={setFileList}
        filesList={filesList}
        view={view}
      />
    </div>
  );
});

export default AddExportSalesContractForm;
