import React from "react";
import {Content} from "antd/es/layout/layout";

const AppContent = (props) => {
    return (
        <div>
            {props.header && props.header}
            <Content
                className="site-layout-background"
            >
                {props.content && props.content}
            </Content>
        </div>
    );
}

export default AppContent;