import {
  CloudUploadOutlined,
  DownloadOutlined,
  EyeOutlined,
  PlusOutlined
} from "@ant-design/icons";
import React, {
  forwardRef,
  useState,
  useEffect,
} from "react";
import { postData, getData } from "../../../../apiServices/common";
import { Button, Upload, message, Space } from "antd";
import { Input, Select, Divider } from "antd";
import {
  SUBMISSION_REALIZATION_DOC,
  SUBMISSION_REALIZATION_INVOICE_DOCUMENT,
  COURIER_OPTION_RESOURCE
} from "apiServices/API_ENDPOINTS";
import moment from "moment";
import { isArrayAndHasValue } from "utils/functions";
import HandleNotification from "../../../../common/Notification";
import CustomDatePicker from "common/CustomDatePicker";

const STATUS_LIST = [
  { id: 1, label: "Unpaid", value: "unpaid" },
  { id: 2, label: "Partial Paid", value: "partial_paid" },
  { id: 3, label: "Fully Paid", value: "fully_paid" },
];

const InvoiceDetails = (props) => {
  // Props
  const {
    invoiceTableData,
    paymentTerm,
    onTableDataChange,
    setInvoiceTableData,
    view,
  } = props;

  // Antd
  const { Option } = Select;

  // Others
  const api_base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  const [CourierOptionList, setCourierOptionList] = useState(null);
  const [CourierOption, setCourierOption] = useState(null);

  const addCourierOption = async (e) => {
    e.preventDefault();
    // setCourierOption(null);
    const query = `${COURIER_OPTION_RESOURCE}`;
    const bodyData = {
      name: CourierOption,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setCourierOption(null);
      HandleNotification(
        "success",
        "bottomRight",
        "Courier Option added successfully",
        null,
      );
      getCourierOptionList();
    }
  };

  const onCourierOptionChange = (event) => {
    setCourierOption(event.target.value);
  };

  // for Courier option list
  const getCourierOptionList = async (filterValues) => {
    const query = `${COURIER_OPTION_RESOURCE}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 100,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setCourierOptionList(response?.data?.data?.data);
    }
  };

    useEffect(() => {
      getCourierOptionList();
    }, []);

  const onChangeFileUpload = (info, index) => {
    if (info.file.status === "done" && info?.file?.response.code === 200) {
      const content = {
        id: info?.file?.response?.data?.id,
        name: info?.file?.response?.data?.document_name,
        path: info?.file?.response?.data?.document,
      };
      if (content) {
        onTableDataChange(content?.id, index, "file_id");
        onTableDataChange(content?.path, index, "file_url");
      }
      message.success(
        `${info?.file?.response?.data?.document_name} file uploaded successfully`,
      );
    } else if (
      info.file.status === "done" &&
      info?.file?.response.code !== 200
    ) {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const calculateShortValue = (value, index) => {
    const invoice_value = invoiceTableData[index]?.invoice_value
      ? Number(invoiceTableData[index]?.invoice_value)
      : null;
    const realization_value = value ? Number(value) : null;

    if (!invoice_value || !realization_value) return null;

    const calculatedValue = invoice_value - realization_value;
    onTableDataChange(calculatedValue, index, "short_value");
  };

  const removeInvoiceItem = (id) => {
    if (!id) return null;
    if (invoiceTableData && invoiceTableData?.length === 1) return null;

    const newInvoiceTableData = invoiceTableData.filter(
      (item) => item?.export_details_id !== id,
    );
    setInvoiceTableData(newInvoiceTableData);
  };

  const openFileInNewTab = (file_url) => {
    const url = `${api_base_url}/${file_url}`;
    window.open(url, "_blank");
  };

  const paymentTermShowHide = (term) => {
    switch(term){
      case "Sight":
        return false;
      case "TT":
        return false;
      default:
        return true;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#FFFFFF",
        padding: "10px",
        height: "400px",
        paddingBottom: "4px",
      }}
    >
      <div className="root-table table-container">
        <table className="pi-product-details-table">
          <thead>
            {isArrayAndHasValue(invoiceTableData) && (
              <tr>
                <th
                  className="t-header bc-java"
                  style={{ position: "sticky", left: 0, zIndex: 3 }}
                  title="SL NO:"
                >
                  SL NO:
                </th>
                <th
                  className="t-header bc-java"
                  style={{ position: "sticky", left: 42, zIndex: 3 }}
                  title="Invoice No"
                >
                  Invoice No
                </th>
                <th className="t-header bc-java" title="Invoice Date">
                  Invoice Date
                </th>
                <th className="t-header bc-java" title="Invoice Value">
                  Invoice Value
                </th>
                <th className="t-header bc-java" title="Invoice Value">
                  Bank Reference/FDBC Number
                </th>
                <th className="t-header bc-java" title="Invoice Value">
                  Bank Reference/FDBC Date
                </th>
                {paymentTermShowHide(paymentTerm) && (
                  <>
                    <th className="t-header bc-java" title="Invoice Value">
                      Acceptance Receive Date
                    </th>
                    <th className="t-header bc-java" title="Invoice Value">
                      Maturity Date
                    </th>
                  </>
                )}
                <th className="t-header bc-java" title="Invoice Value">
                  Realization Date
                </th>
                <th className="t-header bc-java" title="Invoice Value">
                  Documents Submit Date
                </th>
                <th className="t-header bc-java" title="Real Value">
                  Real Value
                </th>
                <th className="t-header bc-java" title="Order Quantity (PC.)">
                  Short Value
                </th>
                <th className="t-header bc-java" title="Courier Option">
                  Courier Option
                </th>
                <th className="t-header bc-java" title={paymentTerm==="Deferred" || paymentTerm==="Sight" ? `Courier No`: `TT No`}>
                  {paymentTerm==="Deferred" || paymentTerm==="Sight" ? `Courier No`: `TT No`}
                </th>
                <th className="t-header bc-java" title={paymentTerm==="Deferred" || paymentTerm==="Sight" ? `Courier Date`: `TT Date`}>
                  {paymentTerm==="Deferred" || paymentTerm==="Sight" ? `Courier Date`: `TT Date`}
                </th>
                <th className="t-header bc-java" title="Status">
                  Status
                </th>
                <th className="t-header bc-java" title="File Upload">
                  File
                </th>
                <th className="t-header bc-java" title="">
                  Action
                </th>
              </tr>
            )}
          </thead>

          <tbody>
            {isArrayAndHasValue(invoiceTableData) ? (
              <>
                {invoiceTableData?.map((invoiceItem, index) => {
                  return (
                    <tr key={index}>
                      <td
                        className="t-body"
                        title="SL NO:"
                        style={{
                          position: "sticky",
                          background: "#ffffff",
                          left: 0,
                          zIndex: 2,
                        }}
                      >
                        <span>{index + 1}</span>
                      </td>
                      <td
                        className="t-body"
                        title="Invoice No"
                        style={{
                          position: "sticky",
                          background: "#ffffff",
                          left: 42,
                          zIndex: 2,
                        }}
                      >
                        <span>{invoiceItem.invoice_no}</span>
                      </td>
                      <td className="t-body sticky" title="Invoice Date">
                        <CustomDatePicker
                          // format="YYYY-MM-DD"
                          style={{
                            width: "110px",
                          }}
                          value={moment(invoiceItem.invoice_date)}
                          disabled
                          size="small"
                        />
                      </td>
                      <td className="t-body sticky" title="Invoice Value">
                        <span>{invoiceItem.invoice_value}</span>
                      </td>
                      <td
                        className="t-body sticky"
                        title="Bank Reference/FDBC Number"
                      >
                        <Input
                          onChange={(event) => {
                            onTableDataChange(
                              event.target.value,
                              index,
                              "fdbc_number",
                            );
                          }}
                          value={invoiceItem?.fdbc_number || ""}
                          placeholder="Bank Reference/FDBC Number"
                          size="small"
                          disabled={view}
                        />
                      </td>
                      <td
                        className="t-body sticky"
                        title="Bank Reference/FDBC Date"
                      >
                        <CustomDatePicker
                          // format="YYYY-MM-DD"
                          style={{
                            width: "110px",
                          }}
                          value={
                            invoiceItem.fdbc_date
                              ? moment(invoiceItem.fdbc_date)
                              : null
                          }
                          onChange={(date, dateString) => {
                            onTableDataChange(dateString, index, "fdbc_date");
                          }}
                          size="small"
                          disabled={view}
                        />
                      </td>
                      {paymentTermShowHide(paymentTerm) && (
                        <>
                          <td
                            className="t-body sticky"
                            title="Acceptance Receive Date"
                          >
                            <CustomDatePicker
                              // format="YYYY-MM-DD"
                              style={{
                                width: "110px",
                              }}
                              value={
                                invoiceItem.acceptance_rcv_date
                                  ? moment(invoiceItem.acceptance_rcv_date)
                                  : null
                              }
                              onChange={(date, dateString) => {
                                onTableDataChange(
                                  dateString,
                                  index,
                                  "acceptance_rcv_date",
                                );
                              }}
                              size="small"
                              disabled={view}
                            />
                          </td>
                          <td className="t-body sticky" title="Maturity Date">
                            <CustomDatePicker
                              // format="YYYY-MM-DD"
                              style={{
                                width: "110px",
                              }}
                              value={
                                invoiceItem.maturity_date
                                  ? moment(invoiceItem.maturity_date)
                                  : null
                              }
                              onChange={(date, dateString) => {
                                onTableDataChange(
                                  dateString,
                                  index,
                                  "maturity_date",
                                );
                              }}
                              size="small"
                              disabled={view}
                            />
                          </td>
                        </>
                      )}
                      <td className="t-body sticky" title="Realization Date">
                        <CustomDatePicker
                          // format="YYYY-MM-DD"
                          style={{
                            width: "110px",
                          }}
                          value={
                            invoiceItem.realization_date
                              ? moment(invoiceItem.realization_date)
                              : null
                          }
                          onChange={(date, dateString) => {
                            onTableDataChange(
                              dateString,
                              index,
                              "realization_date",
                            );
                          }}
                          size="small"
                          disabled={view}
                        />
                      </td>
                      <td
                        className="t-body sticky"
                        title="Documents Submit Date"
                      >
                        <CustomDatePicker
                          // format={"YYYY-MM-DD"}
                          style={{
                            width: "110px",
                          }}
                          size="small"
                          value={
                            invoiceItem.document_submission_date
                              ? moment(invoiceItem.document_submission_date)
                              : null
                          }
                          onChange={(date, dateString) => {
                            onTableDataChange(
                              dateString,
                              index,
                              "document_submission_date",
                            );
                          }}
                          disabled={view}
                        />
                      </td>
                      <td className="t-body sticky" title="Real Value">
                        <Input
                          onChange={(event) => {
                            onTableDataChange(
                              event.target.value,
                              index,
                              "realization_value",
                            );
                            calculateShortValue(event.target.value, index);
                          }}
                          value={invoiceItem?.realization_value || ""}
                          size="small"
                          placeholder="Real Value"
                          style={{ width: "100px" }}
                          disabled={view}
                        />
                      </td>
                      <td className="t-body sticky" title="Short Value">
                        <Input
                          value={invoiceItem?.short_value}
                          disabled
                          size="small"
                          placeholder="Short Value"
                          style={{ width: "100px" }}
                        />
                      </td>
                      <td className="t-body sticky" title="Courier Option">
                            <Select
                              style={{
                                width: "100%",
                              }}
                              placeholder="Please select Courier Option"
                              onChange={(value) => {
                                onTableDataChange(value, index, "courier_option_id");
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return option?.label
                                  ?.toLowerCase()
                                  ?.includes(input?.toLowerCase());
                              }}
                              defaultValue={invoiceItem?.courier_option_id || null}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: '8px 0',
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: '0 8px 4px',
                                    }}
                                  >
                                    <Input
                                      placeholder="Please enter Courier Option"
                                      value={CourierOption}
                                      onChange={onCourierOptionChange}
                                      onKeyDown={(e) => e.stopPropagation()}
                                    />
                                    <Button type="text" icon={<PlusOutlined />} onClick={addCourierOption}>
                                      Add item
                                    </Button>
                                  </Space>
                                </>
                              )}
                              options={CourierOptionList?.map((item) => ({
                                label: item?.name,
                                value: item?.id,
                              }))}
                            />
                      </td>
                      <td className="t-body sticky" title={paymentTerm==="Deferred" || paymentTerm==="Sight" ? `Courier No`: `TT No`}>
                        <Input
                          onChange={(event) =>
                            onTableDataChange(
                              event.target.value,
                              index,
                              "courier_no",
                            )
                          }
                          value={invoiceItem?.courier_no || ""}
                          size="small"
                          placeholder={paymentTerm==="Deferred" || paymentTerm==="Sight" ? `Courier No`: `TT No`}
                          style={{ width: "100px" }}
                          disabled={view}
                        />
                      </td>
                      <td className="t-body sticky" title={paymentTerm==="Deferred" || paymentTerm==="Sight" ? `Courier Date`: `TT Date`}>
                        <CustomDatePicker
                          // format={"YYYY-MM-DD"}
                          style={{
                            width: "110px",
                          }}
                          size="small"
                          value={
                            invoiceItem.courier_date
                              ? moment(invoiceItem.courier_date)
                              : null
                          }
                          onChange={(date, dateString) => {
                            onTableDataChange(
                              dateString,
                              index,
                              "courier_date",
                            );
                          }}
                          disabled={view}
                        />
                      </td>
                      <td className="t-body sticky" title="Status">
                        <Select
                          showSearch
                          placeholder="Please select Status"
                          onChange={(value) => {
                            onTableDataChange(value, index, "status");
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option?.label
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          defaultValue={invoiceItem?.status || null}
                          size="small"
                          style={{ width: "150px" }}
                          disabled={view}
                        >
                          {STATUS_LIST?.length > 0 &&
                            STATUS_LIST?.map((statusItem) => {
                              return (
                                <Option
                                  value={statusItem?.value}
                                  key={statusItem?.id}
                                  label={statusItem?.label}
                                >
                                  {statusItem?.label}
                                </Option>
                              );
                            })}
                        </Select>
                      </td>
                      <td className="t-body sticky" title="">
                        <Space>
                          <Upload
                            action={
                              api_base_url +
                              SUBMISSION_REALIZATION_INVOICE_DOCUMENT
                            }
                            accept="image/*,.pdf, .doc, .xlsx"
                            headers={{
                              authorization: `Bearer ${token}`,
                            }}
                            showUploadList={false}
                            onChange={(info) => onChangeFileUpload(info, index)}
                          >
                            <Button
                              type="primary"
                              icon={<CloudUploadOutlined />}
                              size="small"
                              disabled={view}
                            />
                          </Upload>
                          {(invoiceItem?.file_url || invoiceItem?.documents) && (
                            <Button
                              icon={<EyeOutlined />}
                              size="small"
                              onClick={() =>
                                invoiceItem?.file_url ? openFileInNewTab(invoiceItem?.file_url) : openFileInNewTab(invoiceItem?.documents?.document)
                              }
                              disabled={view}
                            />
                          )}
                        </Space>
                      </td>
                      <td className="t-body sticky" title="">
                        <Button
                          size="small"
                          danger
                          onClick={() =>
                            removeInvoiceItem(invoiceItem?.export_details_id)
                          }
                          disabled={view}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvoiceDetails;
