import { List, Table, Typography } from "antd";
import Card from "antd/lib/card/Card";
import moment from "moment";
import { useEffect, useState } from "react";
import { cglFormatDate, formatNumberWithCommas } from "utils/functions";
import "./index.css";
const dataSource = [
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "1",
    date: "2022-02-01",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
  {
    key: "2",
    date: "2022-02-02",
    estimatedDailyOverTime: 0,
    totalCost: 0,
    sewingProd: 0,
    foldingProdPcs: 0,
    foldingProdDzn: 0,
    cmPerPcs: 0,
    totalCMUSD: "50.5200",
    totalCMTaka: "50.5200",
    operatingProfit: "50.5200",
    cumulativeProfit: "50.5200",
    cumulativeNetProfit: "80.5200",
  },
];
export default function ProfitLossAccount() {
  const [data, setData] = useState({
    listOfBasicInfo: [],
    dataSource: [],
    isLoading: false,
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      fixed: "left",
      render: (date) => cglFormatDate(date),
    },
    {
      title: "Estimated Daily Over Time",
      dataIndex: "estimatedDailyOverTime",
      key: "estimatedDailyOverTime",
      align: "right",
    },
    {
      title: "Total Cost + OT/Day",
      dataIndex: "totalCost",
      key: "totalCost",
      align: "right",
    },
    {
      title: "Sewing Production (pcs)",
      dataIndex: "sewingProd",
      key: "sewingProd",
      align: "right",
    },
    {
      title: "Folding Production (pcs)",
      dataIndex: "foldingProdPcs",
      key: "foldingProdPcs",
      align: "right",
    },
    {
      title: "Folding Production (Dzn)",
      dataIndex: "foldingProdDzn",
      key: "foldingProdDzn",
      align: "right",
    },
    {
      title: "$ CM / Pcs",
      dataIndex: "cmPerPcs",
      key: "cmPerPcs",
      align: "right",
    },
    {
      title: "Total CM / Day (USD)",
      dataIndex: "totalCMUSD",
      key: "totalCMUSD",
      align: "right",
      fixed: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Total CM / Day (Taka)",
      dataIndex: "totalCMTaka",
      key: "totalCMTaka",
      align: "right",
      fixed: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Operating Profit/Loss (Taka)",
      dataIndex: "operatingProfit",
      key: "operatingProfit",
      align: "right",
      fixed: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: `Cumulative Net Profit in ${moment().format("MMMM' YYYY")} (Taka)`,
      dataIndex: "cumulativeProfit",
      key: "cumulativeProfit",
      align: "right",
      fixed: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: `Cumulative Net Profit in ${moment().format("MMMM' YYYY")} (Taka)`,
      dataIndex: "cumulativeNetProfit",
      key: "cumulativeNetProfit",
      align: "right",
      fixed: "right",
      render: (text) => formatNumberWithCommas(text),
    },
  ];

  const getListOfBasicInfo = (item) => {
    const basicInfo = [
      {
        name: "Name of Month",
        value: item?.current_month,
      },
      {
        name: "Total Days During the Month",
        value: item?.total_days,
      },
      {
        name: "Total Days of Leave During the Month",
        value: item?.total_days_of_weekend_holiday,
      },
      {
        name: "Total Working Days During the Month",
        value: item?.total_working_days_of_current_month,
      },
    ];
    return basicInfo;
  };

  const getDailySewingPerformanceData = async () => {
    setData({
      ...data,
      isLoading: true,
    });
    try {
      // const query = `${PNL_REPORT_LINE_WISE_TILL_TODAY_EXPENSE}`;
      // const response = await getData(query);
      // if (response?.data?.code === 200) {
      //   getListOfBasicInfo(response?.data?.data);
      //   setData({
      //     listOfBasicInfo: getListOfBasicInfo(response?.data?.data),
      //     dataSource: getInputDataConvertToDataSourceFormate(
      //       response?.data?.data,
      //     ),
      //     isLoading: false,
      //   });
      setData({
        listOfBasicInfo: getListOfBasicInfo({
          current_month: "February",
          total_days: 28,
          total_days_of_weekend_holiday: 8,
          total_working_days_of_current_month: 22,
        }),
        dataSource: dataSource,
        isLoading: false,
      });
    } catch (error) {
      console.log("Error getting Daily Sewing Performance data");
    }
  };

  useEffect(() => {
    getDailySewingPerformanceData();
  }, []);

  const totalOfLine = (data, index) => {
    return data
      .reduce((acc, current) => {
        return acc + parseFloat(current.line[index].value);
      }, 0)
      ?.toFixed(2);
  };

  return (
    <Card
      title={
        <>
          <Typography.Title
            level={3}
            style={{ textAlign: "center", marginBottom: 0 }}
          >
            CONCORDE GARMENTS LTD.
          </Typography.Title>
          <Typography.Title
            level={4}
            style={{ textAlign: "center", marginTop: 0 }}
          >
            DAILY PROFIT & LOSS ACCOUNT
          </Typography.Title>
        </>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <List
          style={{
            width: "35%",
          }}
          bordered
          dataSource={data.listOfBasicInfo}
          renderItem={(item) => (
            <List.Item style={{ margin: "2px 4px" }}>
              <Typography.Text strong>{item?.name}:</Typography.Text>
              <Typography.Text>{item?.value}</Typography.Text>
            </List.Item>
          )}
        />
      </div>
      <div style={{ marginTop: 20, width: "100%" }}>
        <Table
          bordered
          className="ProfitLossAccount"
          columns={columns}
          dataSource={data.dataSource}
          scroll={{ x: "max-content", y: 500 }}
          pagination={false}
          loading={data.isLoading}
          summary={(pageData) => {
            let totals = {};
            columns.forEach((col) => {
              if (col.dataIndex !== "date") {
                totals[col.dataIndex] = pageData
                  .reduce(
                    (sum, record) =>
                      sum + parseFloat(record[col.dataIndex] || 0),
                    0,
                  )
                  .toFixed(2);
              }
            });
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                {columns.slice(1).map((col) => (
                  <Table.Summary.Cell key={col.dataIndex} align="right">
                    {formatNumberWithCommas(totals[col.dataIndex])}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            );
          }}
        />
      </div>
    </Card>
  );
}
