import React, { useState } from 'react';
import { Button, Form, Input, Table, Space } from 'antd';

export default function AddSubStyle({ subStyleList, setSubStyleList }) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [editForm] = Form.useForm();

  const isEditing = (record) => record.key === editingKey;

  const handleAdd = () => {
    form.validateFields().then(values => {
      setSubStyleList([...subStyleList, { ...values, key: Date.now(), is_delete: 0 }]);
      form.resetFields();
    });
  };

  const edit = (record) => {
    editForm.setFieldsValue({
      sub_style_no: record.sub_style_no
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    editForm.resetFields();
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const values = await editForm.validateFields();
      setSubStyleList(subStyleList.map(item => 
        item.key === key ? { ...item, ...values } : item
      ));
      setEditingKey('');
      editForm.resetFields();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleDelete = (key) => {
    setSubStyleList(subStyleList.map(item => 
      item.key === key ? { ...item, is_delete: 1 } : item
    ));
  };

  const columns = [
    {
      title: 'Sub Style No',
      dataIndex: 'sub_style_no',
      key: 'sub_style_no',
      width: '90%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Form 
            form={editForm} 
            initialValues={{ sub_style_no: record.sub_style_no }} // Set initial values here too
          >
            <Form.Item
              name="sub_style_no"
              style={{ margin: 0 }}
              rules={[{ required: true, message: 'Please input Sub Style No!' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        ) : (
          record.sub_style_no
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <Space size="small" className="flex flex-wrap gap-1">
            {editable ? (
              <>
                <Button type="primary" size="small" onClick={() => save(record.key)}>
                  Save
                </Button>
                <Button size="small" onClick={cancel}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button type="primary" size="small" onClick={() => edit(record)} disabled={editingKey !== ''}>
                  Edit
                </Button>
                <Button danger size="small" onClick={() => handleDelete(record.key)}>
                  Delete
                </Button>
              </>
            )}
          </Space>
        );
      }
    }
  ];

  return (
    <div className="space-y-4">
      <Form form={form} layout="inline">
        <Form.Item
          name="sub_style_no"
          rules={[{ required: true, message: 'Please input Sub Style No!' }]}
          className="flex-1"
          style={{ width: '85%' }}
        >
          <Input placeholder="Sub Style No" />
        </Form.Item>
        <Form.Item style={{ width: "10%"}}>
          <Button type="primary" onClick={handleAdd}>
            Add
          </Button>
        </Form.Item>
      </Form>
      
      <Table 
        dataSource={subStyleList?.filter((val) => val?.is_delete === 0)}
        columns={columns}
        rowKey="key"
        pagination={false}
        bordered
      />
    </div>
  );
}