import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Button, Affix } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { postData, getData } from "../../../apiServices/common";
import AddOrUpdateForm from "./AddOrUpdateForm";
import { BOM } from "../../../apiServices/API_ENDPOINTS";

const UpdateBOM = (props) => {
  // Props
  const { width } = props;

  // States
  const [permitInfo, setPermitInfo] = useState();

  // Others
  const navigate = useNavigate();
  const { bomId } = useParams();
  const propertyFormRef = useRef(null);
  const edit_url = `${BOM}/${bomId}/edit`;

  const addProduct = () => {
    propertyFormRef.current.submit();
  };

  const getPIInfo = useCallback(async () => {
    let res = await getData(edit_url);

    if (res) {
      let masterData = res.data.data;

      if (masterData) {
        setPermitInfo(masterData);
      }
    }
  }, [edit_url]);

  useEffect(() => {
    getPIInfo();
  }, [getPIInfo]);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Update Bill of Materials"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} onClick={() => navigate(-1)} danger>
                Discard
              </Button>,
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
              >
                Update Bill of Materials
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddOrUpdateForm ref={propertyFormRef} permitInfo={permitInfo} />
    </>
  );
};

export default UpdateBOM;
