import { Affix, Button, Card, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "../../../apiServices/common";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import AddSalesContactForm from "./AddSalesContactForm";

export default function ViewSubSalesContact(props) {
  // Props
  const { width } = props;

  // States
  const [permitInfo, setPermitInfo] = useState();

  // Antd
  const { Title } = Typography;

  // Others
  let { styleId } = useParams();
  const propertyFormRef = useRef(null);
  const navigate = useNavigate();

  const discartForm = () => {
    navigate("/merchandising/sales-contact");
  };

  const addProduct = () => {
    propertyFormRef.current.submit();
  };

  const edit_url = `/api/sales_contract/${styleId}/view`;

  const getPIInfo = async () => {
    let res = await getData(edit_url);

    if (res) {
      let masterData = res.data.data;
      console.log("master data edit", res.data.data);
      setPermitInfo(masterData);
    }
  };

  useEffect(() => {
    getPIInfo();
  }, []);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>View Sub Contact</Title>}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} danger onClick={() => discartForm()}>
                Back
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <AddSalesContactForm
        view={true}
        permitInfo={permitInfo}
        ref={propertyFormRef}
      />
    </>
  );
}
