import { Col, Form, InputNumber, Row } from "antd";

export default function DrawerFormSize(props) {
  // Props
  const {
    view,
    unique_id,
    dynamicSizeList,
    updateSizeQuantity,
    commaSeparateNumber,
    breakdownType,
    form,
  } = props;

  // Antd
  const sales_order_price_given_watch = Form.useWatch(
    "sales_order_price_given",
    form,
  );

  return (
    <div className="mt-4 size-div-scroll">
      <Row gutter={[8, 8]}>
        <Form.Item name="sales_order_price_given" hidden />
        {dynamicSizeList?.map((size, index) => {
          return (
            <Col
              className="gutter-row"
              span={8}
              key={size?.label + "_" + size?.value}
            >
              <Form.Item
                label={size?.label}
                rules={[
                  {
                    required: true,
                    message: `Please Input Size of ${size?.label}!`,
                  },
                ]}
                name={size?.label + "_" + size?.value}
                style={{ marginBottom: "unset" }}
                initialValue={0}
              >
                <InputNumber
                  disabled={
                    view ||
                    (breakdownType === "0" && sales_order_price_given_watch)
                  }
                  index={index}
                  style={{ width: "100%" }}
                  precision={2}
                  size="small"
                  min={0}
                  formatter={(value) => commaSeparateNumber(value)}
                  onChange={(value) => {
                    updateSizeQuantity({
                      value: value,
                      unique_id: unique_id,
                      size_object: size,
                      breakdownType: breakdownType,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
