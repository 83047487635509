import { Col, Form, Row, Select, Input } from "antd";

const SewingLineFilterFields = ({ initialTableData }) => {
  const { Option } = Select;

  return (
    <>
      <Col span={4}>
        <Form.Item label="Sewing Line" name="name">
          <Input placeholder="Sewing Line" size="small"/>
        </Form.Item>
      </Col>
    </>
  );
};

export default SewingLineFilterFields;
