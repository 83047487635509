import React, {useEffect, useState} from "react";
import OrganizationCreateForm from "./OrganizationCreateForm";
import AppCreateFormLayOut from "../../../common/CreateFormLayout";
import {onOrganizationCreate} from "../../../apiServices/organizations/service";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {onGetBankList, } from "../../../redux/actions";
import BankAccountInformation from "../../BankAccount";

const CreateOrganization = (props) => {
    // States
    const [refresh, setRefresh] = useState(0);

    // Redux
    const dispatch = useDispatch();
    const bankList = useSelector(({bank}) => bank.bankList);

    // Others
    const location = useLocation();

    const handleSubmit = (payload) => {
        onOrganizationCreate({payload})
    }

    const refetch = () => {
        setRefresh(refresh + 1);
    };
    useEffect(() => {
        // dispatch(onGetBankList(1,10))
    }, [refresh])


    const createComponentForm = <BankAccountInformation selectedOrg={location.state}/>
    const contentForm =
        <div style={{backgroundColor: '#fff', padding: '10px 0 24px 24px'}}>
            <OrganizationCreateForm
                selectedOrg={location.state}
                formTitle={'Organization Information'}
                onCreate={handleSubmit}/>
        </div>


    return (
      <AppCreateFormLayOut
        btnTitle={`Add Bank`}
        CreateComponentForm={createComponentForm}
        header={null}
        contentForm={contentForm}
      />
    );
}

export default CreateOrganization;