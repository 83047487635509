import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GET_BOM_DETAIL,
    STORE_NEW_PACKING,
    GET_PACKING_DETAIL,
    SHOW_MESSAGE
} from "../ActionTypes";
import JwtAxios from "../../apiServices";
import {GET_PACKING_ENDPOINT, STORE_PACKING_ENDPOINT} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onPackingStore = (folding) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${STORE_PACKING_ENDPOINT}`, folding)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: STORE_NEW_PACKING, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Packing Information Stored Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Packing Information Stored Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};

export const onGetPackingDetails = (payload) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios.post(`${GET_PACKING_ENDPOINT}`, payload)
            .then((data) => {

                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_PACKING_DETAIL, payload: data.data});
                    dispatch({

                        type: SHOW_MESSAGE,
                    });
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};

export const onResetPackingDetails = () => {
    return (dispatch) => {
        dispatch({type: GET_PACKING_DETAIL, payload: null});
    };
};