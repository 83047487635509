// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.printCustomDesign {
  width: 100% !important;
}
.printCustomDesign .custome-table {
  width: 100% !important;
}
.printCustomDesign strong {
  font-size: 10px !important;
  line-height: 1;
  margin: 0;
  padding: 0;
}
.printCustomDesign .ant-col {
  font-size: 10px !important;
  line-height: 1;
  margin: 0;
  padding: 0;
}
.printCustomDesign input {
  height: 14px !important;
  font-size: 10px !important;
  line-height: 1;
}
.printCustomDesign td {
  font-size: 10px !important;
  padding: 0 !important;
}

@media print {
  .printCustomDesign {
    margin-left: 1mm; /* Adjust margin as needed */
    margin-right: 1mm; /* Adjust margin as needed */
    width: calc(100% - 2mm); /* Ensure content width fits within the margins */
    box-sizing: border-box; /* Include margins in the width calculation */
  }
  .printCustomDesign td, .printCustomDesign th {
    word-wrap: break-word; /* Prevent content overflow in cells */
  }
}
@media print {
  body {
    transform: scale(0.95); /* Reduce overall scale */
    transform-origin: top 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/reza.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;AAAI;EACI,sBAAA;AAER;AAAI;EACI,0BAAA;EACA,cAAA;EACA,SAAA;EACA,UAAA;AAER;AAAI;EACI,0BAAA;EACA,cAAA;EACA,SAAA;EACA,UAAA;AAER;AAAI;EACI,uBAAA;EACA,0BAAA;EACA,cAAA;AAER;AAAI;EACI,0BAAA;EACA,qBAAA;AAER;;AACA;EACI;IACI,gBAAA,EAAA,4BAAA;IACA,iBAAA,EAAA,4BAAA;IACA,uBAAA,EAAA,iDAAA;IACA,sBAAA,EAAA,6CAAA;EAEN;EAAE;IACI,qBAAA,EAAA,sCAAA;EAEN;AACF;AACA;EACI;IACI,sBAAA,EAAA,yBAAA;IACA,uBAAA;EACN;AACF","sourcesContent":[".printCustomDesign{\n    width: 100% !important;\n    .custome-table{\n        width: 100% !important;\n    }\n    strong{\n        font-size: 10px !important;\n        line-height: 1;\n        margin: 0;\n        padding: 0;\n    }\n    .ant-col{\n        font-size: 10px !important;\n        line-height: 1;\n        margin: 0;\n        padding: 0;\n    }\n    input{\n        height:14px !important;\n        font-size: 10px !important;\n        line-height: 1;\n    }\n    td{\n        font-size: 10px !important;\n        padding:0 !important;\n    }\n}\n@media print {\n    .printCustomDesign {\n        margin-left: 1mm; /* Adjust margin as needed */\n        margin-right: 1mm; /* Adjust margin as needed */\n        width: calc(100% - 2mm); /* Ensure content width fits within the margins */\n        box-sizing: border-box; /* Include margins in the width calculation */\n    }\n    .printCustomDesign td, .printCustomDesign th {\n        word-wrap: break-word; /* Prevent content overflow in cells */\n    }\n}\n\n@media print {\n    body {\n        transform: scale(0.95); /* Reduce overall scale */\n        transform-origin: top 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
