import {
  Card,
  Input,
  Select,
  Table,
  Button,
  Breadcrumb,
  Dropdown,
  Menu,
  Affix,
  Typography,
  Popconfirm,
  Space,
} from "antd";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { Link } from "react-router-dom";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import {
  SALES_CONTRACT_LIST,
  GET_IMPORT_PI_BUDGET_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { Row, Col, Form, Checkbox, Radio, Switch } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { IMPORT_PI_LIST } from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../Common/CustomFilter";
import { formatNumberWithCommas  } from "../../../utils/functions";
import './index.css';

export default function Forwarder(props) {
  // Props
  const { width } = props;

  // States
  const [salesContractList, setSalesContractList] = useState([]);
  const [styleNo, setStyleNo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [updatedTableData, setUpdatedTableData] = useState([]);

  // Antd
  const [budgetForm] = Form.useForm();
  const sales_contract_id_watch = Form.useWatch(
    "sales_contract_id",
    budgetForm,
  );
  const style_id_watch = Form.useWatch("style_id", budgetForm);
  const { Option } = Select;

  const getSalesContractList = async () => {
    const res = await getData(SALES_CONTRACT_LIST);

    if (res) {
      setSalesContractList(res?.data?.data?.data || []);
    }
  };

  const getImportPiBudget = useCallback(async () => {
    setTableData([]);
    const bodyData = {
      sales_contract_id: sales_contract_id_watch,
      style_id: style_id_watch,
    };
    const response = await postData(GET_IMPORT_PI_BUDGET_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setTableData(response?.data || []);
    }
    else {
      setTableData([]);
      setUpdatedTableData([]);
    }
  }, [sales_contract_id_watch, style_id_watch]);

  useEffect(() => {
    getSalesContractList();
  }, []);

  useEffect(() => {
    if (sales_contract_id_watch && style_id_watch) {
      getImportPiBudget();
    }
  }, [sales_contract_id_watch, style_id_watch, getImportPiBudget]);

  useEffect(() => {
    // Count the total values and push it to the updatedTableData
    // updatedTableData value will be shown in the table finally

    if(!tableData || tableData?.length === 0) {
      setUpdatedTableData([]);
      return undefined;
    }

    const tableDataCopy = [...tableData];

    const total_bom_category_budget = tableDataCopy.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.bom_category_budget;
    }, 0);
    const total_pi_category_budget = tableDataCopy.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.pi_category_budget;
    }, 0);
    const total_difference = total_bom_category_budget - total_pi_category_budget;

    const totalData = {
      key: "total",
      bom_category_budget: total_bom_category_budget,
      pi_category_budget: total_pi_category_budget,
      difference: total_difference,
    };

    tableDataCopy.push(totalData);
    setUpdatedTableData(tableDataCopy);
  }, [tableData]);

  const getContractIdDetails = async (value) => {
    const get_contract_details = `${SALES_CONTRACT_LIST}/${value}/view`;
    const res = await getData(get_contract_details);

    if (res) {
      const styleNoCopy = [...styleNo];

      res?.data?.data?.styleDetail.forEach((item, index) => {
        styleNoCopy.push({
          value: item?.style_info?.id,
          label: item?.style_info?.style_no,
        });
      });

      // Empty the selected style
      budgetForm.resetFields(["style_id"]);

      setStyleNo([...styleNoCopy]);
    } else {
      return false;
    }
  };

  const columns = [
    {
      title: "Category Details",
      dataIndex: "category_name",
      key: "category_name",
      width: "45%",
      render: (value, record) => {
        return (
          <div
            style={{
              fontWeight:
                record?.children && record?.children?.length > 0 ? "bold" : 400,
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      title: "Bugdet ($)",
      dataIndex: "bom_category_budget",
      key: "bom_category_budget",
      width: "15%",
      render: (value, record) => {
        return (
          <div
            style={{
              fontWeight:
                record?.children && record?.children?.length > 0 ? "bold" : 400,
            }}
          >
            {formatNumberWithCommas(value)}
          </div>
        );
      },
    },
    {
      title: "PI Value ($)",
      dataIndex: "pi_category_budget",
      key: "pi_category_budget",
      width: "15%",
      render: (value, record) => {
        return (
          <div
            style={{
              fontWeight:
                record?.children && record?.children?.length > 0 ? "bold" : 400,
            }}
          >
            {formatNumberWithCommas (value)}
          </div>
        );
      },
    },
    {
      title: "Budget - Actual",
      dataIndex: "difference",
      key: "difference",
      width: "15%",
      render: (text, record) => {
        if (
          typeof record.bom_category_budget != "undefined" &&
          record.bom_category_budget != null
        ) {
          const difference =
            record?.bom_category_budget - record?.pi_category_budget;
          return (
            <div
              style={{
                fontWeight:
                  record?.children && record?.children?.length > 0
                    ? "bold"
                    : 400,
              }}
            >
              {formatNumberWithCommas (difference)}
            </div>
          );
        } else {
          return <div></div>;
        }
      },
    },
    {
      title: "Within Budget",
      dataIndex: "within_value",
      key: "within_value",
      width: "10%",
      render: (text, record) => {
        const difference =
          record?.bom_category_budget - record?.pi_category_budget;
        if (
          typeof record.bom_category_budget != "undefined" &&
          record.bom_category_budget != null
        ) {
          if (difference >= 0) {
            return {
              props: {
                style: { background: "#1E8449" },
              },
              children: (
                <div
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>YES</div>
                </div>
              ),
            };
          } else {
            return {
              props: {
                style: { background: "#E74C3C" },
              },
              children: (
                <div
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  NO
                </div>
              ),
            };
          }
        } else {
          return <div></div>;
        }
      },
    },
  ];

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Budget VS PI"
            subTitle=""
          />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader />
          <div className="containt-body pt-3">
            <Form form={budgetForm} layout="vertical" name="control-hooks">
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Sales Contract Reference No"
                    name="sales_contract_id"
                  >
                    <Select
                      placeholder="Sales Contract"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        getContractIdDetails(value);
                      }}
                    >
                      {salesContractList.map((option) => {
                        return (
                          <option key={option.id} value={option.id}>
                            {option.reference_no}
                          </option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item label="Select Style" name="style_id">
                    <Select
                      showSearch
                      placeholder="Select Style"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {styleNo?.length &&
                        styleNo.map((item) => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div>
            <Table columns={columns} dataSource={updatedTableData} />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
