import { Button, Form, Layout, message, Typography } from "antd";
import { putData } from "apiServices/common";
import AppPageHeader from "common/PageHeader";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { onCreateUserRole, onGetUserPermissionList } from "redux/actions";
import UserRoleForm from "./UserRoleForm";

const { Content } = Layout;
const { Title } = Typography;

const CreateUserRole = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const useRoleDetails = location.state;
  const userPermissionList = useSelector(
    ({ userRole }) => userRole.userPermissionList,
  );

  const addAllPermissions = () => {
    const allPermissions = userPermissionList?.map((item) => item.id);
    form.setFieldsValue({
      permissions: allPermissions,
    });
  };

  const removeAllPermissions = () => {
    form.setFieldsValue({
      permissions: [],
    });
  };

  const onFinish = async (values) => {
    const payload = {
      ...values,
      has_app_access: values?.has_app_access ? 1 : 0,
    };

    if (!useRoleDetails) {
      // Create Role API Call
      dispatch(onCreateUserRole(payload));
    } else {
      // Edit Role API Call
      const response = await putData(
        `/api/roles/edit/${useRoleDetails?.id}`,
        payload,
      );

      if (response && response?.code === 200) {
        message.success("Role Updated Successfully");
      }
    }
    form.resetFields();
    navigate("/settings/role");
  };

  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        onClick={() => form.submit()}
        style={{ marginRight: 5 }}
      >
        Save
      </Button>
    </div>
  );

  useEffect(() => {
    dispatch(onGetUserPermissionList(1, 100000000));
    form.resetFields();
  }, [dispatch, form]);

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader
          title={
            <Title level={5}>
              {useRoleDetails ? `Update Role` : `Create New User Role`}
            </Title>
          }
          extra={extra}
        />

        <Content className="item-details" style={{ paddingTop: 10 }}>
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              name: useRoleDetails?.name || "",
              permissions: useRoleDetails?.permissions?.map((item) => item.id),
              for: useRoleDetails?.for,
              has_app_access: useRoleDetails?.has_app_access,
            }}
            form={form}
          >
            <UserRoleForm
              addAllPermissions={addAllPermissions}
              removeAllPermissions={removeAllPermissions}
            />
          </Form>
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default CreateUserRole;
