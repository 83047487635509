import {
    GET_INVENTORY_LIST,
    CREATE_NEW_INVENTORY,
    DELETE_INVENTORY,
    GET_INVENTORY_DETAIL, 
    UPDATE_INVENTORY_DETAIL,
    GET_ASSIGN_SUMMARY,
} from '../ActionTypes';

const initialState = {
    inventoryList: [],
    totalInventory: null,
    selectedInventory: null,
    selectedInventoryDetails: null,
    createdInventory: null,
    selectedInventoryAccountInfo: null,
    pageSize: 10,
    page:1,
    itemTracking: null,
};

const InventoryReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_INVENTORY_LIST:
            return {
                ...state,
                inventoryList: action.payload.data,
                totalInventory: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_INVENTORY:
            return {
                ...state,
                createdInventory: action.payload.data,
                inventoryList: [action.payload.data, ...state.inventoryList],
                totalInventory: state.totalInventory + 1,
            };

        case DELETE_INVENTORY: {
            return {
                ...state,
                inventoryList: action.payload.list,
                totalInventory: action.payload.total,
            };
        }

        case GET_INVENTORY_DETAIL:
            return {
                ...state,
                selectedInventory: action.payload,
                selectedInventoryAccountInfo: action.payload,
                selectedInventoryDetails: action.payload,
            };

        case UPDATE_INVENTORY_DETAIL:
            return {
                ...state,
                selectedInventory: action.payload,
                inventoryList: state.inventoryList.map((inventory) =>
                    inventory.id === action.payload.id ? action.payload : inventory,
                ),
            };

        case GET_ASSIGN_SUMMARY:
            return {
                ...state,
                itemTracking: action.payload?.data?.data,
            };
    

        default:
            return state;
    }
};

export default InventoryReducer;
