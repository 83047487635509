import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    BRANCH_LIST_ENDPOINT,
    BRANCH_ADD_ENDPOINT,
    BRANCH_EDIT_ENDPOINT,
    BRANCH_DELETE_ENDPOINT,
    BANK_LIST_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/functions";
import { 
    BRANCH_CREATE_PERMISSION,
    BRANCH_EDIT_PERMISSION,
    BRANCH_DELETE_PERMISSION,
} from '../../routes/permissions';
import { useLocation } from "react-router";

const Branches = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [bankList, setBankList] = useState();

  // Antd
  const [branchForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllBranches = useCallback(
    async (filterValues) => {
      const { name } = filterValues || {};

      setLoading(true);

      const query = `${BRANCH_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...(name && { name: name }),
        type: "all"
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting Branch data");
      }
    },
    [currentPage],
  );

  const getAllBanks = async (filterValues) => {
    setLoading(true);

    const query = `${BANK_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      const filteredBankListData = response?.data?.data?.data?.filter(item => item.status === "1");
      setBankList(filteredBankListData);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  // console.log("Bank List koi", bankList);

  // Effects
  useEffect(() => {
    getAllBranches(filterData);
    getAllBanks();
  }, [refresh, filterData, getAllBranches]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    branchForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedbranch = async (id) => {
    setLoading(true);
    const response = await deleteData(`${BRANCH_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Branch Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedbranch = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      bank_id: formValues?.bank_id,
      routing_number: formValues?.routing_number,
      address: formValues?.address,
      status: formValues?.status === true ? 1 : 0,
    };

    const response = await putData(
      `${BRANCH_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Branch Edited Successfully");
      refetch();
      branchForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewbranch = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      bank_id: formValues?.bank_id,
      routing_number: formValues?.routing_number,
      address: formValues?.address,
      status: formValues?.status,
    };

    const response = await postData(BRANCH_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Branch Created Successfully");
      refetch();
      branchForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    branchForm.setFieldsValue({
      id: record?.id,
      name: record?.name,
      bank_id: record?.bank_id,
      routing_number: record?.routing_number,
      address: record?.address,
      status: record?.status === "1" ? true : false,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Branch Name",
      dataIndex: ["name"],
      key: "name",
    },
    {
      title: "Bank Name",
      dataIndex: ["bank_info", "name"],
      key: "bank_id",
    },
    {
      title: "Routing Number",
      dataIndex: ["routing_number"],
      key: "routing_number",
    },
    {
      title: "Address",
      dataIndex: ["address"],
      key: "address",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      render: (_, record) => {
        return <div>{record?.status === "1" ? "Active" : "Inactive" }</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedbranch(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([BRANCH_DELETE_PERMISSION]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([BRANCH_EDIT_PERMISSION]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([BRANCH_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([BRANCH_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Branches</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllBranches}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Branch" : "Create Branch"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={branchForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedbranch : createNewbranch}
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="name"
                placeholder="Branch Name"
                label="Branch Name"
                rules={[
                  {
                    required: true,
                    message: "Branch Name Required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="bank_id"
                placeholder="Bank"
                label="Bank"
                rules={[
                  {
                    required: true,
                    message: "Bank is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Bank"
                  showSearch
                  style={{width: "100%"}}
                  onSearch={(value) => getAllBanks({name: value})}
                >
                  {bankList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="routing_number"
                placeholder="Routing Number"
                label="Routing Number"
                rules={[
                  {
                    required: true,
                    message: "Routing Number Required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="address"
                placeholder="Address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Address is Required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item
                name="status"
                placeholder="Status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Status Required",
                  },
                ]}
                valuePropName="checked"
                initialValue={true}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Branches;
