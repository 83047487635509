import {
  GET_ITEM_CATEGORY_LIST,
  CREATE_NEW_ITEM_CATEGORY,
  DELETE_ITEM_CATEGORY,
  GET_ITEM_CATEGORY_DETAIL, UPDATE_ITEM_CATEGORY_DETAIL
} from '../ActionTypes';

const initialState = {
  itemCategoryList: [],
  totalItemCategories: null,
  selectedItemCategory: null,
  pageSize: 10,
  page:1,
};

const ItemCategoryReducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_ITEM_CATEGORY_LIST:
      return {
        ...state,
        itemCategoryList: action.payload.data,
        totalItemCategories: action.payload.total,
        pageSize: action.payload.per_page,
        page: action.payload.current_page
      };



    case CREATE_NEW_ITEM_CATEGORY:
      return {
        ...state,
        itemCategoryList: [action.payload.data, ...state.itemCategoryList],
        totalItemCategories: state.totalItemCategories + 1,
      };

    case DELETE_ITEM_CATEGORY: {
      return {
        ...state,
        itemCategoryList: action.payload.list,
        totalItemCategories: action.payload.total,
      };
    }

    case GET_ITEM_CATEGORY_DETAIL:
      return {
        ...state,
        selectedItemCategory: action.payload,
      };

    case UPDATE_ITEM_CATEGORY_DETAIL:
      return {
        ...state,
        selectedItemCategory: action.payload,
        itemCategoryList: state.itemCategoryList.map((itemCategory) =>
          itemCategory.id === action.payload.id ? action.payload : itemCategory,
        ),
      };

    default:
      return state;
  }
};

export default ItemCategoryReducer;
