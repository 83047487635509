import React from "react";
import {notification} from "antd";

const HandleNotification = (type, placement, message, description) => {
    notification[type]({
        message: message,
        description:
        description,
        placement,
    });
};


export default HandleNotification;