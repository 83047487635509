import React from 'react';
import { 
  Card,  
  Row,
  Col 
} from "antd";
import { commaSeparateNumber } from '../../../utils/functions';
const ExpectedBreakdown = (props) => {
  const { breakdownData } = props;

  const learningCurveDays = () => {
    const curveDetails = breakdownData?.learning_curve_info?.curve_details ?? [];
    const additionalDays = breakdownData?.additional_days ?? [];

    const renderRow = (record) => {
      if(!record) return null;

      return(
        <tr key={record?.day}>
          <td className='move-right'>{record?.day}</td>
          <td className='move-right'>{Number(record?.efficiency).toFixed(2) ?? 0}</td>
          <td className='move-right'>{Number(record?.working_hours).toFixed(2) ?? 0}</td>
          <td className='move-right'>{Number(record?.approx_manpower) ?? 0}</td>
          <td className='move-right'>{commaSeparateNumber(Number(record?.dayQty)) ?? 0}</td>
        </tr>
      );
    } 

    return (
      <>
        {curveDetails.map(renderRow)}
        {additionalDays.length > 0 && (
          <tr>
            <td colSpan="5">...</td>
          </tr>
        )}
        
        {additionalDays.length > 0 && renderRow(additionalDays[additionalDays.length - 1])}
      </>
    );
  };
  

  return (
      <Row gutter={6}>
        <Col span={24} className='bc-java text-center mt-3 mb-2 p-2'><strong>Expected Production Breakdown</strong></Col>
        <Col span={12}>
          <table className="custome-table" style={{ width: "100%" }} border="1">
          <thead>
            <tr>
              <th colSpan={1} className="bc-blue move-right pr-2">Day</th>
              <th colSpan={1} className="bc-blue move-right pr-2">Efficiency</th>
              <th colSpan={1} className="bc-blue move-right pr-2">W. Hours</th>
              <th colSpan={1} className="bc-blue move-right pr-2">Apx. MP</th>
              <th colSpan={1} className="bc-blue move-right pr-2">Day Qty</th>
            </tr>
          </thead>
          <tbody>
            {learningCurveDays()}
          </tbody>
        </table>
        </Col>

        <Col span={12}>
          <table className="custome-table" style={{ width: "100%" }} border="1">
            <thead>
              <tr>
                <th colSpan={1} className="bc-blue text-center">Name</th>
                <th  colSpan={1} className="bc-blue text-center"style={{ width: "10%" }}>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='' style={{width: "80%"}}><strong>Order Quantity</strong></td>
                <td style={{textAlign: "right",width: "20%"}}><strong>{commaSeparateNumber(Number(breakdownData?.expected_production_breakdown?.order_quantity)) ?? 0}</strong></td>
              </tr>
              <tr>
                <td className=''>Learning Curve Qty</td>
                <td style={{textAlign: "right"}}>{commaSeparateNumber(Number(breakdownData?.expected_production_breakdown?.learning_curve_quantity)) ?? 0}</td>
              </tr>
              <tr>
                <td className=''>Remaining Qty</td>
                <td style={{textAlign: "right"}}>{commaSeparateNumber(Number(breakdownData?.expected_production_breakdown?.remaining_quantity)) ?? 0}</td>
              </tr>
              <tr>
                <td className=''>Learning Curve Days</td>
                <td style={{textAlign: "right"}}>{breakdownData?.expected_production_breakdown?.learning_curve_days ? Number(breakdownData?.expected_production_breakdown?.learning_curve_days).toFixed(2) : 0}</td>
              </tr>
              <tr>
                <td className=''>Remaining Days for Remaining Qty</td>
                <td style={{textAlign: "right"}}>{breakdownData?.expected_production_breakdown?.remaining_days_for_remaining_qty ? Number(breakdownData?.expected_production_breakdown?.remaining_days_for_remaining_qty).toFixed(2) : 0}</td>
              </tr>
              <tr>
                <td className=''><strong>Total Required Days</strong></td>
                <td style={{textAlign: "right"}}><strong>{breakdownData?.expected_production_breakdown?.total_required_days? Number(breakdownData?.expected_production_breakdown?.total_required_days).toFixed(2) : 0}</strong></td>
              </tr>
              <tr>
                <td className=''>Learning Curve (hr)</td>
                <td style={{textAlign: "right"}}>{breakdownData?.expected_production_breakdown?.learning_curve_hour ? Number(breakdownData?.expected_production_breakdown?.learning_curve_hour).toFixed(2) : 0}</td>
              </tr>
              <tr>
                <td className=''>Remaining (hr)</td>
                <td style={{textAlign: "right"}}>{breakdownData?.expected_production_breakdown?.remaining_hour ? Number(breakdownData?.expected_production_breakdown?.remaining_hour).toFixed(2) : 0}</td>
              </tr>
              <tr>
                <td className=''><strong>Total (hr)</strong></td>
                <td style={{textAlign: "right"}}><strong>{breakdownData?.expected_production_breakdown?.total_hour ? Number(breakdownData?.expected_production_breakdown?.total_hour).toFixed(2) : 0}</strong></td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
  );
};

export default ExpectedBreakdown;
