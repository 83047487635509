import React, { useState } from "react";
import {
  Col,
  Input,
  DatePicker,
  Form,
  Row,
  Select,
  Typography,
  InputNumber,
  Space,
  Button,
  Modal,
} from "antd";
import { onGetStyleList } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import {
  outgoingMovementTypes,
  incomingMovementTypes,
} from "../../../common/helper/helper";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../utils/functions";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import ChallanForm from "../../Configuration/ChallanConfig/ChallanForm";
import CustomDatePicker from "common/CustomDatePicker";

const InventoryForm = (props) => {
  // Props
  const {
    movement,
    styleDetails,
    styleList,
    dateFormat,
    onChangeStyle,
    onChangeInternalPO,
    onChangeOriginalPO,
    onChangeMovement,
    form,
    setSelectedSupplierId,
    invoiceList,
    challanList,
    selectedSupplierType,
    setSelectedSupplierType,
    selectedSupplierId,
    getAllChallan,
    setEdit,
  } = props;

  // States
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Redux
  const dispatch = useDispatch();

  // Antd
  const { Option } = Select;

  const updateMovementType = (selected_movement, movement_category) => {
    onChangeMovement(selected_movement);

    if (movement_category === "incoming") {
      form.resetFields(["movement_type_outgoing"]);
    } else if (movement_category === "outgoing") {
      form.resetFields(["movement_type_incoming"]);
    }
  };

  const onSelectSupplier = (id) => {
    const foundSupplier = styleDetails?.suppliers?.find(
      (item) => item?.id === id,
    );
    setSelectedSupplierId(id);
    setSelectedSupplierType(foundSupplier?.local_status);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Row gutter={8}>
        <Col span={4}>
          <Form.Item
            label="Style No"
            name="style_id"
            rules={[
              {
                required: true,
                message: `Style No. is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeStyle}
              onSearch={(value) => dispatch(onGetStyleList(1, 10, value))}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
            >
              {styleList?.map((style, idx) => (
                <Option key={idx} value={style.id} label={style.style_no}>
                  {style.style_no + " : " + style?.description || "--"}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={styleDetails?.style?.subcontract_status === 1 ? 4 : 0}>
          <Form.Item name="sub_contract_factory_id" hidden />
          <Form.Item
            name="sub_contract_factory_name"
            label="Factory Name"
            rules={[
              {
                required: styleDetails?.style?.subcontract_status === 1,
                message: `Factory Name is required `,
              },
            ]}
            hidden={
              styleDetails?.style?.subcontract_status === 1 ? false : true
            }
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={styleDetails?.style?.subcontract_status === 1 ? 0 : 4}>
          <Form.Item name="buying_office_id" hidden />
          <Form.Item
            name="buying_office_name"
            label="Buying Office"
            rules={[
              {
                required: true,
                message: `Buying Office is required `,
              },
            ]}
            hidden={
              styleDetails?.style?.subcontract_status === 1 ? true : false
            }
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="sales_contract_id" hidden />
          <Form.Item
            name="sales_contract_reference_no"
            label="Sales Contract No."
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={styleDetails?.style?.subcontract_status === 1 ? 0 : 4}>
          <Form.Item name="buyer_id" hidden />
          <Form.Item
            name="buyer_name"
            label="Buyer"
            hidden={
              styleDetails?.style?.subcontract_status === 1 ? true : false
            }
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={styleDetails?.style?.subcontract_status === 1 ? 0 : 4}>
          <Form.Item name="brand_id" hidden />
          <Form.Item
            name="brand_name"
            label="Brand"
            rules={[
              {
                required: true,
                message: `Brand is required`,
              },
            ]}
            hidden={
              styleDetails?.style?.subcontract_status === 1 ? true : false
            }
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="order_quantity"
            label="Order Quantity"
            rules={[
              {
                required: true,
                message: `Order Quantity is required `,
              },
            ]}
          >
            <InputNumber
              disabled={true}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
              style={{ width: "100%" }}
              size="small"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={
              movement.includes("issue")
                ? `Issue Date`
                : movement.includes("receive")
                ? `Receive Date`
                : `Entry Date`
            }
            name="entry_date"
            rules={[
              {
                required: true,
                message: `Entry Date is required `,
              },
            ]}
            initialValue={moment()}
          >
            {/* <CustomDatePicker
              style={{
                width: "100%",
              }}
              size="small"
            /> */}
            <DatePicker
              format={"YYYY-MM-DD"}
              // style={{
              //   width: "100%",
              // }}
              size="small"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={0}>
          <Form.Item label="Internal PO" name="internal_po" hidden={true}>
            <Select
              onChange={onChangeInternalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
            >
              {styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.po_info?.map(
                (po, idx) => (
                  <Option
                    key={idx}
                    internal_po={po?.internal_po}
                    original_po={po?.original_po}
                    sales_contract_id={po?.sales_contract_id}
                    value={po?.internal_po}
                    label={po?.internal_po}
                    po_status={po?.po_status}
                  >
                    {po?.internal_po}
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={0}>
          <Form.Item label="Original PO" name="original_po" hidden={true}>
            <Select
              onChange={onChangeOriginalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
            >
              {styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.po_info?.map(
                (po, idx) => (
                  <Option
                    key={idx}
                    internal_po={po?.internal_po}
                    original_po={po?.original_po}
                    sales_contract_id={po?.sales_contract_id}
                    value={po?.original_po}
                    label={po?.original_po}
                    po_status={po?.po_status}
                  >
                    {po?.original_po}
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Supplier"
            name="supplier_id"
            rules={[
              {
                required:
                  movement === "receive_from_supplier" ||
                  movement === "return_to_supplier"
                    ? true
                    : false,
                message: `Supplier is required `,
              },
            ]}
          >
            <Select
              onSelect={(value) => {
                onSelectSupplier(value);
              }}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              allowClear
              onClear={() => {
                form.setFieldsValue({ supplier_id: null });
                setSelectedSupplierId(null);
              }}
              size="small"
            >
              {styleDetails?.suppliers?.map((supplierItem, index) => (
                <Option
                  key={supplierItem?.id || index}
                  value={supplierItem?.id}
                >
                  {supplierItem?.short_name || supplierItem?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {(movement === "receive_from_supplier" ||
          movement === "return_to_supplier") &&
          isArrayAndHasValue(invoiceList) && (
            <Col span={4}>
              <Form.Item
                label="Invoice"
                name="invoice_id"
                rules={[
                  {
                    required: selectedSupplierType === 0 ? true : false,
                    message: `Invoice is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  allowClear
                  onClear={() => {
                    form.setFieldsValue({ invoice_id: null });
                  }}
                  size="small"
                >
                  {invoiceList?.map((invoiceItem, index) => (
                    <Option
                      key={invoiceItem?.id || index}
                      value={invoiceItem?.id}
                    >
                      {invoiceItem?.invoice_number || ""}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

        {movement.includes(["receive_from_supplier"]) && (
          <Col span={4}>
            <Space>
              <Form.Item
                label="Challan No."
                name="challan_id"
                rules={[
                  {
                    required:
                      selectedSupplierType === 1 || selectedSupplierType === 2
                        ? true
                        : false,
                    message: `Challan No. is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: 100,
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  allowClear
                  onClear={() => {
                    form.setFieldsValue({ challan_id: null });
                  }}
                  size="small"
                  onSearch={(value) => getAllChallan({ name: value })}
                >
                  {isArrayAndHasValue(challanList) &&
                    challanList?.map((challanItem, index) => (
                      <Option
                        key={challanItem?.id || index}
                        value={challanItem?.id}
                      >
                        {challanItem?.challan_no || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <div className="centered-item-parent">
                <Form.Item label=" ">
                  <Button
                    icon={<PlusOutlined />}
                    size="small"
                    onClick={onModalOpen}
                  />
                </Form.Item>
              </div>
            </Space>
          </Col>
        )}

        {movement.includes(["receive_from_sub_con_factory"]) && (
          <Col span={4}>
            <Space>
              <Form.Item
                label="Challan No."
                name="challan_id"
                rules={[
                  {
                    required: selectedSupplierType === 1 ? true : false,
                    message: `Challan No. is required `,
                  },
                ]}
              >
                <Input size="small" placeholder="Challan No." />
              </Form.Item>
            </Space>
          </Col>
        )}

        {movement.includes("issue") && (
          <Col span={4}>
            <Form.Item
              label="Requisition No."
              name="requisition_no"
              rules={[
                {
                  required: true,
                  message: `Requisition No. is required `,
                },
              ]}
            >
              <Input size="small" />
            </Form.Item>
          </Col>
        )}

        {[
          "stock_sale",
          "send_to_buying_office",
          "return_to_supplier",
          "return_to_sub_con_factory",
        ].some((val) => movement.includes(val)) && (
          <Col span={4}>
            <Form.Item
              label="Gate Pass"
              name="reference_no"
              rules={[
                {
                  required: true,
                  message: `Gate Pass is required `,
                },
              ]}
            >
              <Input size="small" />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Typography.Title style={{ fontSize: 14, fontWeight: 600 }}>
        Movement Type
      </Typography.Title>

      <Row style={{ borderTop: "1px solid #e5e5e5", paddingTop: 10 }}>
        <Col span={12}>
          <Form.Item
            label="Movement Type (Incoming)"
            name="movement_type_incoming"
            style={{ padding: 5, borderRadius: 5 }}
            rules={[
              {
                required: form.getFieldValue("movement_type_outgoing")
                  ? false
                  : true,
                message: `Movement Type is required `,
              },
            ]}
          >
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              onSelect={(selectedValue) =>
                updateMovementType(selectedValue, "incoming")
              }
              size="small"
            >
              {incomingMovementTypes?.map((movementInfo, index) => (
                <Option
                  key={movementInfo?.id || index}
                  value={movementInfo?.value}
                >
                  {movementInfo?.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Movement Type (Outgoing)"
            name="movement_type_outgoing"
            style={{ padding: 5, borderRadius: 5 }}
            rules={[
              {
                required: form.getFieldValue("movement_type_incoming")
                  ? false
                  : true,
                message: `Movement Type is required `,
              },
            ]}
          >
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              onSelect={(selectedValue) =>
                updateMovementType(selectedValue, "outgoing")
              }
              size="small"
            >
              {outgoingMovementTypes?.map((movementInfo, index) => (
                <Option
                  key={movementInfo?.id || index}
                  value={movementInfo?.value}
                >
                  {movementInfo?.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Modal
        open={isModalOpen}
        title={"Create Challan"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <ChallanForm
          isEdit={false}
          onCloseModal={onCloseModal}
          supplierId={selectedSupplierId}
          refetch={getAllChallan}
          from={"inventory"}
        />
      </Modal>
    </>
  );
};

export default InventoryForm;
