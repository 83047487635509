import { Col, Form, Input, Select, Switch } from "antd";
import { GET_USERS_ROLE_ENDPOINT } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";

const { Option } = Select;
const STATUS_OPTIONS = [
  {
    id: 0,
    name: "Inactive",
  },
  {
    id: 1,
    name: "Active",
  },
]

const ChecklistQuestionsFilter = ({ initialTableData }) => {

  const [userRoleList, setUserRoleList] = useState(null);
  const [loading, setLoading] = useState(true);

  const getAllUserRoles = useCallback(async (filterValues) => {
    setLoading(true);
    try{
      const bodyData = {
        per_page: 10,
        app_menu: "yes",
        ...filterValues,
      };
      const query = `${GET_USERS_ROLE_ENDPOINT}`;
  
      const response = await getData(query, false, bodyData);
  
      if (response && response?.data?.code === 200) {
        const roleList = response?.data?.data?.data;
        let customRoleData = isArrayAndHasValue(roleList) ? [...roleList] : [];
        setUserRoleList(customRoleData);
      }
    }catch(e){
      console.log("Fetching error");
    }finally{
      setLoading(false);
    }

  }, []);

  useEffect(() => {
    getAllUserRoles()
  }, [getAllUserRoles]);
  
  return (
    <>
      <Col span={6}>
        <Form.Item label="Name (English)" name="name">
          <Input placeholder="Name (English)" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Role Type" name="role_id">
          <Select
            showSearch
            loading={loading}
            onSearch={(value) => getAllUserRoles({ name: value })}
            style={{
              width: "100%",
            }}
            placeholder="Search to Select Role"
            optionFilterProp="children"
            size="small"
          >
            {userRoleList?.map((role, idx) => (
              <Option key={idx} value={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Status" name="status">
          <Select
            showSearch
            onSearch={(value) => getAllUserRoles({ name: value })}
            style={{
              width: "100%",
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            size="small"
          >
            {STATUS_OPTIONS?.map((option, idx) => (
              <Option key={idx} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default ChecklistQuestionsFilter;
