import { Outlet } from "react-router-dom";
import UnauthorisedComponent from '../UnauthorisedComponent';
// import LoadingComponent from '../LoadingComponent';

const RequireAuth = (props) => {
    // Props
    const { allowedpermissions } = props;

    // Tokens
    const accessToken = localStorage.getItem("token");
    const roleInfo = localStorage.getItem("roleInfo") && JSON.parse(localStorage.getItem("roleInfo"));
    const assignedPermissions = roleInfo ? roleInfo?.permissions : null;

    const hasPermission = (allowedpermissions) => {
        const hasRolePermission =
          assignedPermissions &&
          assignedPermissions?.find((permissionItem) =>
            allowedpermissions?.includes(permissionItem?.name),
          );

        // If has this page access
        if(accessToken && hasRolePermission) {
            return true;
        }

        // If the route does not require permissions
        if(accessToken && allowedpermissions.includes("no-permission-required")) {
            return true;
        }
        
        // // Else no access
        return false;
    }

    return (
        hasPermission(allowedpermissions) ? <Outlet/> : <UnauthorisedComponent />
    );
}

export default RequireAuth;