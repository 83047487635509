import React, { useState } from "react";
import { Typography, Button, Row, Space, Col, Form, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const { Title } = Typography;

export default function BodyHeader(props) {
  const { title, searchFilter } = props;
  const [form] = Form.useForm();
  const [showFilter, setShowFilter] = useState(false);

  const onFinish = (values) => {
    searchFilter(values);
  };

  const clearReset = () => {
    form.resetFields();
  };

  return (
    <section>
      <Form
        form={form}
        layout="vertical"
        name="filter"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col className="gutter-row" xs={16} sm={18} md={20}>
            <Title level={5}>{title}</Title>
          </Col>
        </Row>
      </Form>
    </section>
  );
}
