import { Button, Collapse, Form, Layout, Typography } from "antd";
import {
  EXISTING_PO_MAP_LIST,
  GET_PACKING_PRODUCT_ENDPOINT,
  PACKING_CONFIRM,
  PACKING_CONFIRMATION_LIST,
  PACKING_STYLE_LIST,
  UPDATE_PACKING_CONFIRMATION_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";
import { alertPop } from "apiServices/common/helper";
import HandleNotification from "common/Notification";
import AppPageHeader from "common/PageHeader";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { PACKING_CREATE_PERMISSION } from "routes/permissions";
import { hasPermission } from "utils/functions";
import PackingConfirmForm from "./PackingConfirmForm";
import PackingConfirmTable from "./PackingConfirmTable";

const PackingConfirmation = (props) => {
  // Props
  const { isEdit } = props;

  // States
  const [poList, setPoList] = useState(null);
  const [styleList, setStyleList] = useState(null);
  const [cartonList, setCartonList] = useState(null);
  const [selectedCarton, setSelectedCarton] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFactory, setIsFactory] = useState(false);

  // Antd
  const { Content } = Layout;
  const { Panel } = Collapse;
  const { Title } = Typography;
  const [PackConfirmForm] = Form.useForm();
  const po_map_id_watch = Form.useWatch("po_map_id", PackConfirmForm);
  const start_watch = Form.useWatch("start", PackConfirmForm);
  const end_watch = Form.useWatch("end", PackConfirmForm);
  const packing_date_watch = Form.useWatch("packing_date", PackConfirmForm);
  const buyer_id_watch = Form.useWatch("buyer_id", PackConfirmForm);

  // Router
  const location = useLocation();

  console.log("selectedCarton", selectedCarton);

  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => PackConfirmForm.submit()}
        disabled={!hasPermission([PACKING_CREATE_PERMISSION]) || isSubmitting}
      >
        {hasPermission([PACKING_CREATE_PERMISSION])
          ? "Submit"
          : "No Permission"}
      </Button>
      <Button
        size="small"
        htmlType="submit"
        onClick={() => PackConfirmForm.resetFields()}
      >
        {" "}
        Reset
      </Button>
    </div>
  );

  const getPOList = async () => {
    const query = `${EXISTING_PO_MAP_LIST}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setPoList(response?.data?.data);
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  const getPackingProductInfo = useCallback(
    async ({
      po_map_id,
      sales_contract_id,
      buying_office_id,
      transaction_no_per_day,
      packing_date,
      sub_contract_factory_id,
    }) => {
      const bodyData = {
        po_map_id: po_map_id ? Number(po_map_id) : null,
        sub_contract_factory_id: sub_contract_factory_id
          ? Number(sub_contract_factory_id)
          : null,
        sales_contract_id: sales_contract_id ? Number(sales_contract_id) : null,
        buying_office_id: buying_office_id ? Number(buying_office_id) : null,
        packing_date: packing_date
          ? moment(packing_date).format("YYYY-MM-DD")
          : null,
        transaction_no_per_day: transaction_no_per_day
          ? Number(transaction_no_per_day)
          : null,
      };

      const query = `${GET_PACKING_PRODUCT_ENDPOINT}`;

      const response = await postData(query, bodyData);

      if (response && response?.code === 200) {
        const form_data = response?.data?.form_data;
        const table_data = response?.data?.table_data?.map((data) => ({
          ...data,
          carton_id: data?.id,
          status: String(data?.status),
        }));

        const carton_nos = table_data?.map((data) => data?.carton_no);
        const max_carton_no = Math.max(...carton_nos);
        const min_carton_no = Math.min(...carton_nos);

        // Set form data
        PackConfirmForm.setFieldsValue({
          original_po: form_data?.po_map?.original_po,
          internal_po: form_data?.po_map?.internal_po,
          buying_office_id: form_data?.buying_office?.id,
          buying_office_name: form_data?.buying_office?.name,
          buyer_id: form_data?.buyer?.id,
          buyer_name: form_data?.buyer?.name,
          sales_contract_id: form_data?.sales_contract?.id,
          po_map_id: form_data?.po_map?.id,
          reference_no: form_data?.sales_contract?.reference_no,
          start: min_carton_no,
          end: max_carton_no,
          packing_date: packing_date ? moment(packing_date) : moment(),
          sub_contract_factory_id: form_data?.sub_contract_factory?.id,
          sub_contract_factory_name:
            form_data?.sub_contract_factory?.name || "",
        });
        setIsFactory(form_data?.sub_contract_factory?.id ? true : false);

        setCartonList(table_data);
        // check if the status == 1 set selectedCarton
        const selectedCarton = table_data
          ?.filter((carton) => carton?.status === "1" || carton?.status === "2")
          ?.map((carton) => ({
            ...carton,
            carton_id: carton?.carton_id,
            status: String(carton?.status),
          }));
        setSelectedCarton(selectedCarton);
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something Went Wrong!",
          null,
        );
      }
    },
    // Ignored dependencies to stop loop
    [PackConfirmForm],
  );

  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(location.search);

    // Extracting query parameters
    const queryParamsObj = {
      po_map_id: searchParams.get("po_map_id"),
      sales_contract_id: searchParams.get("sales_contract_id"),
      buying_office_id: searchParams.get("buying_office_id"),
      transaction_no_per_day: searchParams.get("transaction_no_per_day"),
      packing_date: searchParams.get("packing_date"),
      sub_contract_factory_id: searchParams.get("sub_contract_factory_id"),
    };

    if (queryParamsObj && isEdit) {
      getPackingProductInfo(queryParamsObj);
    }
  }, [location.search, getPackingProductInfo, isEdit]);

  const getPackingConfirmList = async (po_map_id, start, end, packing_date) => {
    const query = `${PACKING_CONFIRMATION_LIST}`;
    const bodyData = {
      po_map_id: po_map_id,
      start: start,
      end: end,
      packing_date: packing_date,
    };
    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setCartonList(
        response?.data?.map((carton) => ({
          ...carton,
          carton_id: carton?.id,
          status: String(carton?.status),
        })),
      );
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  useEffect(() => {
    // Get all PO List on first render
    getPOList();
  }, []);

  useEffect(() => {
    // Get carton list data for showing in table
    if (
      (po_map_id_watch || po_map_id_watch === 0) &&
      (start_watch || start_watch === 0) &&
      (end_watch || end_watch === 0) &&
      packing_date_watch &&
      !isEdit
    ) {
      getPackingConfirmList(
        po_map_id_watch,
        start_watch,
        end_watch,
        packing_date_watch,
      );
    }
  }, [po_map_id_watch, start_watch, end_watch, packing_date_watch, isEdit]);

  const getStyleList = async (internal_po) => {
    const query = `${PACKING_STYLE_LIST}`;
    const bodyData = {
      internal_po: internal_po,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data);
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  const onChangeInternalPO = (value) => {
    const selectedPO = poList?.find((po) => po?.internal_po === value);
    selectedPO &&
      PackConfirmForm.setFieldsValue({
        original_po: selectedPO?.original_po,
        buying_office_name: selectedPO?.buying_office_name,
        buying_office_id: selectedPO?.buying_office_id,
        buyer_name: selectedPO?.buyer_name,
        buyer_id: selectedPO?.buyer_id,
        sales_contract_id: selectedPO?.sales_contract_id,
        reference_no: selectedPO?.reference_no,
        po_map_id: selectedPO?.id,
        sub_contract_factory_id: selectedPO?.sub_contract_factory_id,
        sub_contract_factory_name: selectedPO?.sub_contract_factory_name || "",
      });
    setIsFactory(selectedPO?.sub_contract_factory_id ? true : false);
    getStyleList(value);
  };

  const onChangeOriginalPO = (value) => {
    setIsSubmitting(false);
    const selectedPO = poList?.find((po) => po?.original_po === value);
    selectedPO &&
      PackConfirmForm.setFieldsValue({
        internal_po: selectedPO?.internal_po,
        buying_office_name: selectedPO?.buying_office_name,
        buying_office_id: selectedPO?.buying_office_id,
        buyer_name: selectedPO?.buyer_name,
        buyer_id: selectedPO?.buyer_id,
        sales_contract_id: selectedPO?.sales_contract_id,
        reference_no: selectedPO?.reference_no,
        po_map_id: selectedPO?.id,
        sub_contract_factory_id: selectedPO?.sub_contract_factory_id,
        sub_contract_factory_name: selectedPO?.sub_contract_factory_name || "",
      });
    setIsFactory(selectedPO?.sub_contract_factory_id ? true : false);
    getStyleList(selectedPO?.internal_po);
  };

  const onFinishPackingConfirm = async (values) => {
    console.log("onFinishPackingConfirm values", values);

    setIsSubmitting(true);
    const query = isEdit
      ? `${UPDATE_PACKING_CONFIRMATION_ENDPOINT}`
      : `${PACKING_CONFIRM}`;
    const bodyData = {
      po_map_id: values?.po_map_id,
      buying_office_id: values?.buying_office_id,
      sales_contract_id: values?.sales_contract_id,
      sub_contract_factory_id: values?.sub_contract_factory_id || null,
      packing_date: moment(values?.packing_date).format("YYYY-MM-DD"),
      buyer_id: buyer_id_watch,
      cartons: selectedCarton?.map((carton) => ({
        ...carton,
        carton_id: carton?.carton_id,
        status: Number(carton?.status),
      })),
    };
    console.log("bodyData", bodyData);
    // return;

    const response = await postData(query, bodyData);

    if (response) {
      alertPop("success", response?.message || "Packing Confirmed!");
      setSelectedCarton([]);
      getPackingConfirmList(po_map_id_watch, start_watch, end_watch);
      // setIsSubmitting(false);
    }
  };

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader
          title={<Title level={5}>Packing Confirmation</Title>}
          extra={extra}
        />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Packing Confirmation Entry</b>} key="1">
              <Form
                layout="vertical"
                onFinish={onFinishPackingConfirm}
                form={PackConfirmForm}
              >
                <PackingConfirmForm
                  poList={poList}
                  onChangeInternalPO={onChangeInternalPO}
                  onChangeOriginalPO={onChangeOriginalPO}
                  isEdit={isEdit}
                  isFactory={isFactory}
                />
              </Form>
            </Panel>
          </Collapse>
          <PackingConfirmTable
            isEdit={isEdit}
            cartonList={cartonList}
            selectedCarton={selectedCarton}
            setSelectedCarton={setSelectedCarton}
          />
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default PackingConfirmation;
