import { Affix, Breadcrumb, Button, Card } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getData } from "../../../apiServices/common";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import AddStyleForm from "./AddStyleForm";

export default function ViewStyle(props) {
  let { styleId } = useParams();
  const { width } = props;
  const propertyFormRef = useRef(null);
  const [permitInfo, setPermitInfo] = useState();

  const navigate = useNavigate();
  const discartForm = () => {
    // propertyFormRef.current.discart();
    navigate("/merchandising/style");
  };
  const edit_url = `/api/styles/${styleId}/edit`;

  const getPIInfo = async () => {
    let res = await getData(edit_url);

    if (res) {
      let masterData = res.data.data[0];
      setPermitInfo(masterData);
    }
  };

  useEffect(() => {
    getPIInfo();
  }, []);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="View Style"
            subTitle=""
            routes={
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/merchandising/style">Style</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>View Style</Breadcrumb.Item>
              </Breadcrumb>
            }
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="large" key={1} danger onClick={() => discartForm()}>
                Back
              </Button>,
              // <Button
              //   size="large"
              //   key={2}
              //   type="primary"
              //   onClick={() => addProduct()}
              // >
              //   View Permit
              // </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <AddStyleForm ref={propertyFormRef} permitInfo={permitInfo} view={true} />
    </>
  );
}
