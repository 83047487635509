import { Affix, Button, Card } from "antd";
import { SUB_CONTRACT_AGREEMENT } from "apiServices/API_ENDPOINTS";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getData } from "../../../apiServices/common";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import AddSalesContactForm from "./AddSalesContactForm";

export default function UpdateSubSalesContact(props) {
  // Props
  const { width, isAmend, isEdit } = props;

  // States
  const [permitInfo, setPermitInfo] = useState();

  // Router
  let { styleId } = useParams();

  // Others
  const propertyFormRef = useRef(null);
  const edit_url = `${SUB_CONTRACT_AGREEMENT}/${styleId}/show`;

  const addProduct = () => {
    propertyFormRef.current.submit();
  };

  const getPIInfo = async () => {
    // console.log("working");
    let res = await getData(edit_url);

    if (res) {
      let masterData = res.data.data;
      setPermitInfo(masterData);
    }
  };

  useEffect(() => {
    getPIInfo();
  }, []);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`${isAmend ? "Amend" : "Update"} Sub Contact`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
              >
                {`${isAmend ? "Amend" : "Update"} Sub Contact`}
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddSalesContactForm
        permitInfo={permitInfo}
        ref={propertyFormRef}
        isAmend={isAmend}
        isEdit={isEdit}
      />
    </>
  );
}
