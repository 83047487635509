import React, { useState } from "react";
import OrganizationList from "./OrganizationList";

const Organization = () => {
  const [refresh, setRefresh] = useState(0);
  const refetch = () => {
    setRefresh(refresh + 1);
  };

  return <OrganizationList refresh={refresh} refetch={refetch} />;
};

export default Organization;
