import {
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Card, Drawer, Form, Select, Typography } from "antd";
import { useState } from "react";
import { commaSeparateNumber } from "utils/functions";
import SizeTracking from "./SizeTracking";
import "./index.css";

const PackingDetails = (props) => {
  // Props
  const {
    view,
    styleList,
    poDetailsData,
    copyRow,
    removeRow,
    onChangePoDetailsValue,
    calculateQty,
    calculateCBM,
    calculateTotalGrossWeight,
    calculateTotalNetWeight,
    selectedPoColorList,
    PackReqForm,
    selectedPOUniqueID,
    insertSizeTableData,
    isEdit,
    setPoDetailsData,
    poList,
    onChangePOList,
    isDrawerOpen,
    setIsDrawerOpen,
    setSelectedPOUniqueID,
    editMode,
    getPOList,
  } = props;

  // States

  const [hoverData, setHoverData] = useState(null);

  // Antd
  const [packingDetailsForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  const RowInfoUI = ({ rowData }) => {
    return (
      <div
        style={{ padding: "10px", display: rowData ? "unset" : "none" }}
        className="row-ui-bottom"
      >
        <div>
          <strong>Carton Qty:</strong>{" "}
          {rowData?.carton_qty
            ? commaSeparateNumber(rowData?.carton_qty)
            : "N/A"}
          ,<strong>CBM:</strong> {rowData?.cbm || "N/A"},<strong>Item:</strong>{" "}
          {rowData?.item || "N/A"},<strong>Total Gross:</strong>{" "}
          {rowData?.total_gross
            ? commaSeparateNumber(rowData?.total_gross)
            : "N/A"}
          ,<strong>Total Net:</strong>{" "}
          {rowData?.total_net ? commaSeparateNumber(rowData?.total_net) : "N/A"}
          <strong>UPC: </strong> {rowData?.upc || "N/A"}
        </div>
      </div>
    );
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Form form={packingDetailsForm}>
        <div className="">
          <Card
            className="mt-2"
            title={
              <span style={{ fontSize: "14px", fontWeight: 600 }}>
                PO List (Qty in pc)
              </span>
            }
          >
            <div className="custome-table">
              <table
                style={{
                  width: "100%",
                }}
              >
                <thead>
                  <tr>
                    <th
                      key="header_serial_number"
                      className="bc-java text-center"
                    >
                      SL
                    </th>
                    <th key="header_from" className="bc-java text-center">
                      PO No{" "}
                    </th>
                    <th key="header_to" className="bc-java text-center">
                      Buyer
                    </th>
                    <th key="header_qty" className="bc-java text-center">
                      Order Qty
                    </th>
                    <th key="header_assortment" className="bc-java text-center">
                      Packing Qty
                    </th>
                    <th key="header_item" className="bc-java text-center">
                      Loading Qty
                    </th>
                    <th key="header_upc" className="bc-java text-center">
                      Remaining Qty
                    </th>
                    <th key="header_length" className="bc-java text-center">
                      Carton Qty
                    </th>
                    <th key="header_width" className="bc-java text-center">
                      CBM
                    </th>

                    {!view && (
                      <>
                        <th
                          className="bc-java text-center"
                        >
                          Edit
                        </th>
                        <th
                          className="bc-java text-center"
                        >
                          Add
                        </th>

                        <th
                          className="bc-java text-center"
                        >
                          Delete
                        </th>
                      </>
                    )}
                  </tr>
                </thead>

                <tbody className="scrollable-table-container">
                  {poDetailsData && poDetailsData?.length > 0 ? (
                    <>
                      {poDetailsData.map((obj, index) => {
                        return (
                          <tr
                            key={`table_parent_key_${index}`}
                            onMouseEnter={() => setHoverData(obj)}
                            onMouseLeave={() => setHoverData(null)}
                          >
                            {/* SL */}
                            <td className="t-body sticky">{index + 1}</td>
                            {/*  PO No */}
                            <td className="t-body sticky">
                              <Select
                                value={obj?.po_map?.original_po || ""}
                                onChange={(value) =>
                                  onChangePOList(value, obj?.unique_id)
                                }
                                showSearch
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                size="small"
                                onSearch={(value) =>
                                  getPOList({ original_po: value })
                                }
                              >
                                {Array.isArray(poList) &&
                                  poList?.map((po, idx) => (
                                    <Option key={idx} value={po?.original_po}>
                                      {po?.original_po}
                                    </Option>
                                  ))}
                              </Select>
                            </td>
                            {/* Buyer */}
                            <td className="t-body sticky">
                              {obj?.buyer?.name || "N/A"}
                            </td>
                            {/* Order Qty */}
                            <td className="t-body sticky">
                              {obj?.order_quantity || "N/A"}
                            </td>
                            {/* Packing Qty */}
                            <td className="t-body sticky">
                              {obj?.packing_quantity || "N/A"}
                            </td>
                            {/* Loading Qty */}
                            <td className="t-body sticky">
                              {obj?.loading_quantity || "N/A"}
                            </td>
                            {/* Remaining Qty */}
                            <td className="t-body sticky">
                              {obj?.remain_quantity}
                            </td>
                            {/* Carton Qty */}
                            <td className="t-body sticky">
                              {obj?.carton_quantity || "N/A"}
                            </td>
                            {/* CBM */}
                            <td className="t-body sticky">
                              {obj?.carton_cbm || "N/A"}
                            </td>

                            {!view && (
                              <td
                                className="t-body"
                                onClick={() => {
                                  openDrawer();
                                  setSelectedPOUniqueID(obj?.unique_id);
                                }}
                              >
                                <EditOutlined
                                  className="cursore-pointer"
                                  style={{ fontSize: "16px" }}
                                />
                              </td>
                            )}
                            {!view && (
                              <td
                                className="t-body"
                                onClick={() => {
                                  copyRow(obj?.unique_id);
                                }}
                              >
                                <PlusCircleOutlined
                                  className="cursore-pointer"
                                  style={{ fontSize: "16px", color: "Green" }}
                                />
                              </td>
                            )}
                            {!view && (
                              <td
                                className="t-body"
                                onClick={() => {
                                  removeRow(obj?.unique_id);
                                }}
                              >
                                <MinusCircleOutlined
                                  className="cursore-pointer"
                                  style={{ fontSize: "16px", color: "red" }}
                                />
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </Card>
          <Drawer
            title="PO Details"
            placement="right"
            onClose={closeDrawer}
            open={isDrawerOpen}
            width={1000}
          >
            <SizeTracking
              selectedPoColorList={selectedPoColorList}
              poDetailsData={poDetailsData}
              PackReqForm={PackReqForm}
              selectedPOUniqueID={selectedPOUniqueID}
              isDrawerOpen={isDrawerOpen}
              insertSizeTableData={insertSizeTableData}
              isEdit={isEdit}
              editMode={editMode}
              setPoDetailsData={setPoDetailsData}
              // updatePackReqFormValue={updatePackReqFormValue}
            />
          </Drawer>
        </div>
      </Form>
      <div>
        <RowInfoUI rowData={hoverData} className="row-ui-bottom" size="small" />
      </div>
    </>
  );
};
export default PackingDetails;
