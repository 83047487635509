import { useEffect, useContext } from "react";
import { AssignmentContext } from "context/AssignmentContext/AssignmentContextProvider";
import { Col, Input, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";

const { Option } = Select;

const AssignmentForm = (props) => {
  // Props
  const { form, isUpdate } = props;

  // Contexts
  const {
    sewingLinesList,
    getSewingList,
    onSelectLine,
    lineTarget,
    setLineAssignmentDetails,
    lineAssignmentDetails,
  } = useContext(AssignmentContext);

  // Antd
  const assignment_date_watch = Form.useWatch("assignment_date", form);
  const sewing_line_id_watch = Form.useWatch("sewing_line_id", form);

  useEffect(() => {
    // Set line target field value
    form.setFieldsValue({
      line_target: lineTarget,
    });
  }, [lineTarget, form]);

  return (
    <>
      <Row gutter={8}>
        <Col span={4}>
          <Form.Item
            label="Sewing Line"
            name="sewing_line_id"
            rules={[
              {
                required: true,
                message: `Sewing Line is required `,
              },
            ]}
          >
            <Select
              onSearch={(value) => getSewingList({ name: value })}
              onSelect={(value) => onSelectLine(value, assignment_date_watch)}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              allowClear
              onClear={() => {
                getSewingList();
                setLineAssignmentDetails(null);
              }}
              disabled={isUpdate}
            >
              {sewingLinesList?.map((line, idx) => (
                <Option key={idx} value={line.id} label={line.name}>
                  {line.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="assignment_date"
            label="Assignment Date"
            initialValue={moment()}
            rules={[
              {
                required: true,
                message: `Assignment Date is required `,
              },
            ]}
          >
            <CustomDatePicker
              style={{
                width: "100%",
              }}
              size="small"
              disabled={isUpdate}
              onChange={(date, dateString) => {
                if (dateString) {
                  onSelectLine(sewing_line_id_watch, dateString);
                } else {
                  setLineAssignmentDetails(null);
                }
              }}
            />
            {/* <DatePicker
              format={"YYYY-MM-DD"}
              style={{
                width: "100%",
              }}
              size="small"
              disabled={isUpdate}
              onChange={(date, dateString) => {
                if (dateString) {
                  onSelectLine(sewing_line_id_watch, dateString);
                } else {
                  setLineAssignmentDetails(null);
                }
              }}
            /> */}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="line_target"
            label="Line Target"
            rules={[
              {
                required: true,
                message: `Line Target is required `,
              },
            ]}
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default AssignmentForm;
