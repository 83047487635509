import { useCallback, useEffect, useMemo, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  ADD_VEHICLE_INFOS,
  DELETE_VEHICLE_INFOS,
  EDIT_VEHICLE_INFOS,
  GET_VEHICLE_INFOS,
  VEHICLE_SIZE_ENDPOINT,
  VEHICLE_TYPE_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { deleteData, getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  DRIVER_VEHICLE_INFO_CREATE_PERMISSION,
  DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
  DRIVER_VEHICLE_INFO_DISPATCH_TIME_PERMISSION,
  DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
} from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const VehicleInformation = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [isEditDispatchDateTime, setIsEditDispatchDateTime] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [options, setOptions] = useState({
    vehicleType: [],
    vehicleSizeId: [],
  });

  // Antd
  const [vehicleAndDriverInformationForm] = Form.useForm();
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // get options list
  const getOptionsList = async () => {
    await getVehicleTypeList();
    await getVehicleSizeList();
  };

  const getVehicleTypeList = async () => {
    let res = await getData(VEHICLE_TYPE_ENDPOINT);

    if (res) {
      setOptions((prevState) => ({
        ...prevState,
        vehicleType: res?.data?.data?.data || [],
      }));
    }
  };
  const getVehicleSizeList = async () => {
    let res = await getData(VEHICLE_SIZE_ENDPOINT);

    if (res) {
      setOptions((prevState) => ({
        ...prevState,
        vehicleSizeId: res?.data?.data?.data || [],
      }));
    }
  };

  useEffect(() => {
    getOptionsList();
  }, []);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllVehicleAndDriverInfo = useCallback(
    async (filterValues) => {
      const {
        vehicle_no,
        vehicle_size_id,
        driver_name,
        nid,
        driving_license_no,
        vehicle_type_id,
        own_vehicle_status,
      } = filterValues || {};

      setLoading(true);

      const query = `${GET_VEHICLE_INFOS}?page=${currentPage}`;
      const bodyData = {
        ...(vehicle_no && { vehicle_no: vehicle_no }),
        ...(vehicle_size_id && { vehicle_size_id: vehicle_size_id }),
        ...(driver_name && { driver_name: driver_name }),
        ...(nid && { vehicle_no: nid }),
        ...(driving_license_no && { driving_license_no: driving_license_no }),
        ...(vehicle_type_id && { vehicle_type_id: vehicle_type_id }),
        ...(own_vehicle_status && {
          own_vehicle_status: own_vehicle_status,
        }),
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting vehicle info data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllVehicleAndDriverInfo(filterData);
  }, [refresh, filterData, getAllVehicleAndDriverInfo]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    vehicleAndDriverInformationForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
    setIsEditDispatchDateTime(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedVehicleAndDriverInfo = async (id) => {
    setLoading(true);
    const response = await deleteData(
      `${DELETE_VEHICLE_INFOS}/${id}`,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Vehicle Info Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Error deleting vehicle info");
    }
  };

  const editSelectedVehicleAndDriverInfo = async (formValues) => {
    setLoading(true);

    const bodyData = {
      ...formValues,
      arrival_date_time: dayjs(formValues?.arrival_date_time).format(
        "YYYY-MM-DD HH:mm:ss",
      ),
    };

    const response = await postData(
      `${EDIT_VEHICLE_INFOS}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Vehicle Info Updated Successfully");
      refetch();
      vehicleAndDriverInformationForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Error updating vehicle info");
    }
  };

  const createNewVehicleAndDriverInfo = async (formValues) => {
    setLoading(true);

    const bodyData = {
      "vehicle_no": formValues?.vehicle_no,
      "vehicle_size_id": formValues?.vehicle_size_id,
      "vehicle_type_id": formValues?.vehicle_type_id,
      "own_vehicle_status": formValues?.own_vehicle_status ? 1 : 0,
      "status": 0,
    };

    const response = await postData(
      ADD_VEHICLE_INFOS,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Vehicle Info Added Successfully");
      refetch();
      vehicleAndDriverInformationForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Error adding vehicle info");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);
    vehicleAndDriverInformationForm.setFieldsValue({
      id: record?.id,
      vehicle_no: record?.vehicle_no,
      vehicle_size_id: record?.vehicle_size_info?.id,
      driver_name: record?.driver_name,
      driver_cell_no: record?.driver_cell_no,
      nid: parseInt(record?.nid),
      vehicle_type_id: record?.vehicle_type_info?.id,
      driving_license_no: parseInt(record?.driving_license_no),
      arrival_date_time: dayjs(record?.arrival_date_time),
      dispatch_date_time: null,
      dispatch_date_time_id: record?.dispatch_date_time_id || null,
      own_vehicle_status: record?.own_vehicle_status ? 1 : 0,
      status: record?.status || 0,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Vehicle No",
      dataIndex: ["vehicle_no"],
      key: "vehicle_no",
    },
    {
      title: "Vehicle Size",
      dataIndex: ["vehicle_size_info", "vehicle_size"],
      key: "vehicle_size",
    },
    {
      title: "Vehicle Type",
      dataIndex: ["vehicle_type_info", "vehicle_type"],
      key: "vehicle_type",
    },
    {
      title: "Vehicle Size in CBM",
      dataIndex: ["vehicle_size_info", "vehicle_size_in_cbm"],
      key: "vehicle_size_in_cbm",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedVehicleAndDriverInfo(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([
                      DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([
                      DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                      {!hasPermission([
                      DRIVER_VEHICLE_INFO_DISPATCH_TIME_PERMISSION,
                        ]) ? "" : (<>

                          <span>
                            <EditTwoTone />
                          </span>
                          <span
                            type="button"
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                              setIsEditDispatchDateTime(true);
                              openModalForEdit(record);
                            }}
                          >
                            Set Dispatch Date Time
                          </span>
                        </>)}
                      </>
                    ),
                    key: "3"
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
              tableData={tableData}
              filterAPI={getAllVehicleAndDriverInfo}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="vehicle_and_driver_information"
            />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={
                  !hasPermission([DRIVER_VEHICLE_INFO_CREATE_PERMISSION])
                }
                addButtonText={
                  hasPermission([DRIVER_VEHICLE_INFO_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Vehicle Information</Title>}
          />
        }
        content={
          <>
            {memoizedCustomFilter}

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={
          isEdit
            ? "Edit Vehicle Information"
            : "Create Vehicle Information"
        }
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          name="vehicle_and_driver_information_form"
          form={vehicleAndDriverInformationForm}
          layout="vertical"
          onFinish={
            isEdit
              ? editSelectedVehicleAndDriverInfo
              : createNewVehicleAndDriverInfo
          }
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="vehicle_no"
                placeholder="Vehicle No"
                label="Vehicle No"
                rules={[
                  {
                    required: true,
                    message: "Vehicle No Required",
                  },
                ]}
              >
                <Input disabled={isEditDispatchDateTime} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vehicle_size_id"
                placeholder="Vehicle Size"
                label="Vehicle Size"
                rules={[
                  {
                    required: true,
                    message: "Vehicle Size Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Vehicle Size"
                  showSearch
                  style={{ width: "100%" }}
                  disabled={isEditDispatchDateTime}
                >
                  {options.vehicleSizeId?.length &&
                    options.vehicleSizeId.map((sup) => (
                      <Select.Option value={sup.id} key={sup.id}>
                        {sup.vehicle_size} ({sup.vehicle_size_in_cbm})
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
         
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item
                name="vehicle_type_id"
                placeholder="Vehicle Type"
                label="Vehicle Type"
                rules={[
                  {
                    required: true,
                    message: "Vehicle Type Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Type"
                  showSearch
                  style={{ width: "100%" }}
                  disabled={isEditDispatchDateTime}
                >
                  {options.vehicleType?.length &&
                    options.vehicleType.map((sup) => (
                      <Select.Option value={sup.id} key={sup.id}>
                        {sup.vehicle_type}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label=" "
                name="own_vehicle_status"
                valuePropName={"checked"}
              >
                <Checkbox name="own_vehicle_status">Own Vehicle</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default VehicleInformation;
