import CreateBankAccount from "./CreateBankAccount";

const BankAccountInformation = (props) => {
  const { selectedOrg, selectedBuyingOffice } = props;

  return (
    <CreateBankAccount
      selectedOrg={selectedOrg}
      selectedBuyingOffice={selectedBuyingOffice}
    />
  );
};

export default BankAccountInformation;
