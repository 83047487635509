import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Row, Col, Input, Table, Typography, InputNumber } from "antd";
import "./index.css";
import { commaSeparateNumber } from "utils/functions";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  useEffect(() => {}, [props]);
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      if (values["packing_information"]) {
        record["packing_information"]["day_quantity"] =
          values?.packing_information?.day_quantity ||
          record?.packing_information?.day_quantity;
      }
      if (values["rejected_information"]) {
        record["rejected_information"]["day_quantity"] =
          values?.rejected_information?.day_quantity ||
          record?.rejected_information?.day_quantity;
      }

      // toggleEdit();

      handleSave(record, values);
    } catch (errInfo) {}
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
          color: "red",
        }}
        name={dataIndex}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (dataIndex[0] === "packing_information") {
                if (
                  parseInt(value) +
                    parseInt(
                      getFieldValue("rejected_information")?.day_quantity || 0,
                    ) >
                    parseInt(record["wip_info"]["total"]) ||
                  !value
                ) {
                  return Promise.reject(
                    new Error(
                      "Total value can not be greater than remaining quantity !",
                    ),
                  );
                } else {
                  return Promise.resolve();
                }
              }
              if (dataIndex[0] === "rejected_information") {
                if (
                  parseInt(value) +
                    parseInt(
                      getFieldValue("packing_information")?.day_quantity || 0,
                    ) >
                    parseInt(record["wip_info"]["total"]) ||
                  !value
                ) {
                  return Promise.reject(
                    new Error(
                      "Total value can not be greater than remaining quantity !",
                    ),
                  );
                } else {
                  return Promise.resolve();
                }
              }
            },
          }),
        ]}
      >
        <Input ref={inputRef} onChange={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const PackingDetails = (props) => {
  const {
    setPackingInformation,
    setPackingItems,
    selectedPackingDetails,
    itemUsageInfo,
  } = props;

  const [onEdit, setEdit] = useState(false);

  const onsetEdit = () => {
    setEdit(!onEdit);
  };
  var packingDetails = selectedPackingDetails;
  useEffect(() => {}, [selectedPackingDetails]);

  const defaultColumns = [
    { title: "Color", dataIndex: ["color", "name"], key: ["color", "name"] },
    { title: "Size", dataIndex: ["size", "name"], key: ["color", "name"] },
    {
      title: "Sub Style",
      dataIndex: ["sub_style_info", "sub_style_no"],
      key: ["sub_style_info", "sub_style_no"],
    },
    {
      title: "Order Qty",
      dataIndex: ["sales_order_quantity"],
      key: ["sales_order_quantity"],
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        )
      },
    },
    {
      title: "Day Qty. (Folding)",
      dataIndex: ["folding_information", "day_quantity"],
      key: ["folding_information", "day_quantity"],
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        )
      },
    },
    {
      title: "Total (Folding)",
      dataIndex: ["folding_information", "total"],
      key: ["folding_information", "total"],
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        )
      },
    },
    {
      title: "Day Qty. (Packing)",
      dataIndex: ["packing_information", "day_quantity"],
      key: ["packing_information", "day_quantity"],
      editable: onEdit,
      render(text, record) {
        return {
          children: (
            <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
              <InputNumber
                value={record?.packing_information?.day_quantity}
                disabled={!onEdit}
                style={{ width: "85px" }}
                formatter={(value) => commaSeparateNumber(value)}
                precision={2}
              />
            </Form.Item>
          ),
        };
      },
    },
    {
      title: "Total (packing)",
      dataIndex: ["packing_information", "total"],
      key: ["packing_information", "total"],
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        )
      },
    },
    {
      title: "Day Qty. (Reject)",
      dataIndex: ["rejected_information", "day_quantity"],
      key: ["rejected_information", "day_quantity"],
      editable: onEdit,
      render(text, record) {
        return {
          children: (
            <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
              <InputNumber
                value={record?.rejected_information?.day_quantity}
                disabled={!onEdit}
                style={{ width: "85px" }}
                formatter={(value) => commaSeparateNumber(value)}
                precision={2}
              />
            </Form.Item>
          ),
        };
      },
    },
    {
      title: "Total (Reject)",
      dataIndex: ["rejected_information", "total"],
      key: ["rejected_information", "total"],
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        )
      },
    },
    {
      title: "WIP (pc)",
      dataIndex: ["wip_info", "total"],
      key: ["wip_info", "total"],
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        )
      },
    },
  ];

  const handleSave = (row, values) => {
    const newData = [...packingDetails];
    const index = newData.findIndex((item) => row.id === item.id);
    newData[index]["packing_information"]["day_quantity"] =
      values?.packing_information
        ? values["packing_information"]["day_quantity"]
        : row["packing_information"]["day_quantity"];
    newData[index]["rejected_information"]["day_quantity"] =
      values?.rejected_information
        ? values["rejected_information"]["day_quantity"]
        : row["rejected_information"]["day_quantity"];
    // setPackingDetails(newData)
    setPackingInformation(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const TableSummary = (pageData) => {
    let totalFoldingDayQty = 0;
    let totalFoldingQty = 0;
    let totalPackingDayQty = 0;
    let totalPackingQty = 0;
    let totalRejectDayQty = 0;
    let totalRejectQty = 0;
    let totalWip = 0;
    let totalOrder = 0;

    pageData?.pageData?.forEach((item) => {
      totalFoldingDayQty += parseInt(item?.folding_information?.day_quantity);
      totalFoldingQty += parseInt(item?.folding_information?.total);
      totalPackingDayQty += parseInt(item?.packing_information?.day_quantity);
      totalPackingQty += parseInt(item?.packing_information?.total);
      totalRejectDayQty += parseInt(item?.rejected_information?.day_quantity);
      totalRejectQty += parseInt(item?.rejected_information?.total);
      totalWip += parseInt(item?.wip_info?.total);
      totalOrder += parseInt(item?.sales_order_quantity);
    });

    const totalData = {
      totalFoldingDayQty,
      totalFoldingQty,
      totalPackingDayQty,
      totalPackingQty,
      totalRejectDayQty,
      totalRejectQty,
      totalWip,
      totalOrder,
    };

    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#F5F5F5" }}>
          <Table.Summary.Cell index={0}>
            <strong>Total</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            {totalData?.totalOrder ? commaSeparateNumber(totalData?.totalOrder) : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            {totalData?.totalFoldingQty ? commaSeparateNumber(totalData?.totalFoldingQty) : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            {totalData?.totalPackingQty ? commaSeparateNumber(totalData?.totalPackingQty) : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8}></Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            {totalData?.totalRejectQty ? commaSeparateNumber(totalData?.totalRejectQty) : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
            {totalData?.totalWip ? commaSeparateNumber(totalData?.totalWip) : 0}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };


  return (
    <>
      <Row>
        <Col span={24} lg={24} xl={24} sm={24} style={{ marginTop: 12 }}>
          <Button
            type="primary"
            size="small"
            onClick={onsetEdit}
            style={{
              float: "right",
              fontWeight: 600,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Edit
          </Button>
          <Typography.Title style={{ fontSize: 14 }}>
            Packing Details
          </Typography.Title>
          <Table
            pagination={false}
            size={"small"}
            scroll={{
              x: 1300,
            }}
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={packingDetails}
            columns={columns}
            summary={(pageData) => {
            return (
              <TableSummary pageData={pageData} />
            );
          }}
          />
        </Col>

        {/* <Col span={24} lg={24} xl={24} sm={24}>
          <div className="item-table" style={{ marginTop: 10 }}>
            <ItemUsageDetails
              setPackingItems={setPackingItems}
              itemUsageInfo={itemUsageInfo}
            />
          </div>
        </Col> */}
      </Row>
    </>
  );
};
export default PackingDetails;
