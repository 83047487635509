import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import AppCreateFormLayOut from "../../../common/CreateFormLayout";
import BuyerCreateForm from "./BuyerCreateForm";
import {onGetBrandList, onGetBuyerDetails} from "../../../redux/actions";
import CreateBrandForm from "../../Brand/CreateBrand";
import CreateMerchandiserForm from "../CreateMerchandiserForm";

const CreateOrEditBuyer = (props) => {
    const { isAdd, isEdit } = props;
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(0);
    const [addItem, setIsAddItem] = useState(false);
    const brandList = useSelector(({brand}) => brand.brandList);
    const selectedBuyer = useSelector(({buyer}) => buyer?.selectedBuyer?.data);
    const { buyerId } = useParams();

    console.log("selectedBuyer", selectedBuyer);

    useEffect(() => {
      if(buyerId || buyerId === 0) {
        // On edit, get selected buyer details
        dispatch(onGetBuyerDetails(Number(buyerId)));
      }
      // Get brands list on first load
      dispatch(onGetBrandList(1,10));
    }, [buyerId, dispatch]);


    const addItemHandler = () => {
        setIsAddItem((prev) => !prev)
    }

    const refetch = () => {
        setRefresh(refresh + 1);
    };

    const contentForm = (
      <div style={{ backgroundColor: "#fff" }}>
        <BuyerCreateForm
          selectedBuyer={isEdit ? selectedBuyer : null}
          formTitle={"Buyer Information"}
          isEdit={isEdit}
          refresh={refresh}
          refetch={refetch}
        />
      </div>
    );

    return (
      <AppCreateFormLayOut
        btnTitle={`Add Brand`}
        brandList={brandList}
        addItemHandler={addItemHandler}
        addItem={addItem}
        header={null}
        contentForm={contentForm}
      />
    );
}

export default CreateOrEditBuyer;