import React, { useCallback, useEffect, useState } from "react";
import { Form, Layout, Button, Collapse, Typography, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InventoryLayOutCommon from "../LayOut/InventoryLayOutCommon";
import AppPageHeader from "../../../common/PageHeader";
import InventoryForm from "./InventoryForm";
import ItemTable from "../ItemTable";
import {
  onGetBOMDetails,
  onGetBuyingOfficeDetails,
  onGetBuyingOfficeList,
  onGetStyleDetails,
  onGetStyleList,
  onResetStyleDetails,
  onResetBOM,
} from "../../../redux/actions";
import { onCreateInventory } from "../../../redux/actions";
import HandleNotification from "../../../common/Notification";
import {
  CHALLAN_LIST,
  INVOICE_BY_SUPPLIER,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";

const CreateFabricInventory = (props) => {
  // States
  const [value, setValue] = useState(null);
  const [originalPO, setOriginalPO] = useState("");
  const [internalPO, setInternalPO] = useState("");
  const [inventoryItems, setInventoryItems] = useState([]);
  const [onEdit, setEdit] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [invoiceList, setInvoiceList] = useState(null);
  const [challanList, setChallanList] = useState(null);
  const [selectedSupplierType, setSelectedSupplierType] = useState(null);

  // Redux
  const dispatch = useDispatch();
  const selectedBOM = useSelector(({ bom }) => bom.selectedBOM);
  const selectedBom = selectedBOM && selectedBOM.data.bill_of_material_details;
  const styleList = useSelector(({ style }) => style.styleList);
  const selectedStyleDetails = useSelector(
    ({ style }) => style.selectedStyleDetails,
  );
  const styleDetails = selectedStyleDetails && selectedStyleDetails.data[0];
  const loading = useSelector(({ common }) => common.loading);

  // Router
  const location = useLocation();
  const selectedInventory = location.state;

  // Antd
  const [form] = Form.useForm();
  const { Content } = Layout;
  const { Panel } = Collapse;
  const { Title } = Typography;
  const entry_date_watch = Form.useWatch("entry_date", form);
  const supplier_id_watch = Form.useWatch("supplier_id", form);


  // Others
  const dateFormat = "YYYY-MM-DD";

  // Effects
  useEffect(() => {
    dispatch(
      onGetBuyingOfficeList({
        page: 1,
        per_page: 10,
      }),
    );
    dispatch(onGetStyleList(1, 10));
    form.resetFields();

    return () => {
      // Reset style and BOM redux state on unmount
      dispatch(onResetStyleDetails());
      dispatch(onResetBOM());
    };
  }, [dispatch, form]);

  const onChangeStyle = (value, options) => {
    dispatch(onGetStyleDetails(value));
  };

  const BOMDetails = useCallback(
    (props) => {
      const {
        buying_office_id,
        entry_date,
        movement_type,
        sales_contract_id,
        style_id,
        supplier_id,
        use_fabric_interlining_store,
        sub_contract_factory_id,
      } = props;

      const payload = {
        buying_office_id,
        entry_date,
        movement_type,
        sales_contract_id,
        style_id,
        supplier_id,
        use_fabric_interlining_store,
        sub_contract_factory_id,
      };

      dispatch(onGetBOMDetails(payload));
    },
    [dispatch],
  );

  const onChangeBuyingOfc = (value, options) => {
    dispatch(onGetBuyingOfficeDetails(value));
  };

  const onChangeInternalPO = (value, options) => {
    setOriginalPO(options?.original_po);
    setInternalPO(options?.internal_po);
    form.setFieldValue("original_po", options?.original_po);
  };

  const onChangeOriginalPO = (value, options) => {
    setOriginalPO(options?.original_po);
    setInternalPO(options?.internal_po);
    form.setFieldValue("internal_po", options?.internal_po);
  };

  const getItemDetailsData = useCallback(() => {
    const payload = {
      buyingId: styleDetails?.style?.buying_office_id,
      entryDate: entry_date_watch,
      movementType: value,
      salesContractId:
        styleDetails?.style?.sales_contract_map_info?.sales_contract_id,
      styleId: styleDetails?.style?.id,
      supplierId: form.getFieldValue("supplier_id"),
    };

    // Check if all keys in payload object has value, if has 0 then it is also considered true
    const allKeyHasValue = Object.values(payload).every(
      (value) => value || value === 0,
    );

    // Call this function and get BOM details (Item Details Table)
    // Only when style, supplier and entry date is changed and all keys in payload object has value
    if (allKeyHasValue) {
      BOMDetails({
        buying_office_id: styleDetails?.style?.buying_office_id,
        entry_date: entry_date_watch,
        movement_type: value,
        sales_contract_id: styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.id,
        style_id: styleDetails?.style?.id,
        supplier_id: form.getFieldValue("supplier_id"),
        use_fabric_interlining_store: "Yes",
        sub_contract_factory_id: styleDetails?.style?.factory_info?.id || null,
      });
    }
  }, [BOMDetails, form, styleDetails, value]);

  useEffect(() => {
    getItemDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    styleDetails?.style?.id,
    supplier_id_watch,
    entry_date_watch,
  ]);

  useEffect(() => {
    if(styleDetails?.style) {
      form.setFieldsValue({
        buying_office_id: styleDetails?.style?.buying_office_info?.id,
        buying_office_name: styleDetails?.style?.buying_office_info?.name,
        sales_contract_id: styleDetails?.style?.active_sales_contract_map_info
        ?.active_sales_contract_info?.id,
        sales_contract_reference_no: styleDetails?.style?.active_sales_contract_map_info
        ?.active_sales_contract_info?.reference_no,
        buyer_id: styleDetails?.style?.buyer_info?.id,
        buyer_name: styleDetails?.style?.buyer_info?.name,
        brand_id: styleDetails?.style?.brand_info?.id,
        brand_name: styleDetails?.style?.brand_info?.name,
        order_quantity: styleDetails?.style?.active_sales_contract_map_info?.order_quantity ? parseInt(styleDetails?.style?.active_sales_contract_map_info?.order_quantity) : 0,
      });
    }

    if(styleDetails?.style?.factory_info) {
      form.setFieldsValue({
        sub_contract_factory_id: styleDetails?.style?.factory_info?.id,
        sub_contract_factory_name: styleDetails?.style?.factory_info?.name,
      });
    }
  }, [styleDetails, form]);

  const getInvoiceBySupplier = useCallback(
    async (supplierId) => {
      const query = `${INVOICE_BY_SUPPLIER}/${selectedSupplierId}`;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {
        setInvoiceList(response?.data?.data);
      }
    },
    [selectedSupplierId],
  );

  const getAllChallan = async (filterValues) => {
    const query = `${CHALLAN_LIST}`;
    const bodyData = {
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setChallanList(response?.data?.data?.data);
    } else {
      message.error("Error getting challan data");
    }
  };

  useEffect(() => {
    // Get challan list on first load
    getAllChallan();
  }, []);

  useEffect(() => {
    // When movement type is receive_from_supplier OR return_to_supplier
    // And supplier id is selected
    if (
      (value === "receive_from_supplier" || value === "return_to_supplier") &&
      selectedSupplierId
    ) {
      getInvoiceBySupplier(selectedSupplierId);
    }
  }, [value, selectedSupplierId, getInvoiceBySupplier]);

  const onChangeMovement = (selected_movement_type) => {
    if (
      (selected_movement_type === "receive_from_supplier" ||
        selected_movement_type === "return_to_supplier") &&
      !selectedSupplierId
    ) {
      HandleNotification(
        "warning",
        "bottomRight",
        "Please select supplier",
        null,
      );
    }
    
    setValue(selected_movement_type);
    BOMDetails({
      buying_office_id: styleDetails?.style?.buying_office_id,
      entry_date: entry_date_watch,
      movement_type: selected_movement_type,
      sales_contract_id: styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.id,
      style_id: styleDetails?.style?.id,
      supplier_id: form.getFieldValue("supplier_id"),
      use_fabric_interlining_store: "Yes",
      sub_contract_factory_id: styleDetails?.style?.factory_info?.id || null,
    });
  };

  const onFinish = (values) => {
    let updatedValues = {};
    updatedValues["movement_type"] = value;
    updatedValues["original_po"] = originalPO;
    updatedValues["internal_po"] = internalPO;

    const styleDetailsInfo = {
      sales_contract_id:
        styleDetails?.style?.sales_contract_map_info?.sales_contract_id,
      buying_office_id: styleDetails?.style?.buying_office_id,
      style_id: styleDetails?.style?.id,
    };

    updatedValues = {
      ...updatedValues,
      ...values,
      ...styleDetailsInfo,
    };

    // Remove unncessary key values
    delete updatedValues["balance_quantity"];
    delete updatedValues["movement_type_incoming"];
    delete updatedValues["received_total_quantity"];
    delete updatedValues["roll_number"];
    delete updatedValues["store_quantity"];

    dispatch(onCreateInventory(updatedValues));
    setEdit(!onEdit);
  };

  const extra = (
    <div className="extra-button-container">
      <Button size="small" htmlType="submit" onClick={() => form.resetFields()}>
        {" "}
        Reset
      </Button>
    </div>
  );

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader
          extra={extra}
          title={<Title level={5}>Fabric Store Entry</Title>}
        />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 15 }}>
            <Panel header={<b>Order Details</b>} key="1">
              <Form
                layout="vertical"
                onFinish={onFinish}
                form={form}
                initialValues={{
                  buyer_name: selectedInventory?.data?.buyer_info?.name || "",
                  brand_name: selectedInventory?.data?.brand_info?.name || "",
                  style_id: selectedInventory?.data?.style_id || "",
                  buying_office_id:
                    selectedInventory?.data?.buying_office_id || "",
                  sales_contract_id:
                    selectedInventory?.data?.sales_contract_id || "",
                  supplier_id: null,
                }}
              >
                <InventoryForm
                  styleDetails={styleDetails}
                  styleList={styleList}
                  onChangeStyle={onChangeStyle}
                  setInventoryItems={setInventoryItems}
                  selectedInventoryDetails={location.state}
                  dateFormat={dateFormat}
                  selectedBOM={
                    selectedInventory
                      ? selectedInventory?.data?.bill_of_material_details
                      : selectedBOM
                  }
                  onChangeBuyingOfc={onChangeBuyingOfc}
                  onChangeInternalPO={onChangeInternalPO}
                  onChangeOriginalPO={onChangeOriginalPO}
                  onChangeMovement={onChangeMovement}
                  originalPO={originalPO}
                  movement={value || ""}
                  form={form}
                  setSelectedSupplierId={setSelectedSupplierId}
                  invoiceList={invoiceList}
                  challanList={challanList}
                  selectedSupplierType={selectedSupplierType}
                  setSelectedSupplierType={setSelectedSupplierType}
                  selectedSupplierId={selectedSupplierId}
                  getAllChallan={getAllChallan}
                  setEdit={setEdit}
                />
              </Form>
            </Panel>
          </Collapse>
          {selectedBom && (
            <ItemTable
              loading={loading}
              setInventoryItems={setInventoryItems}
              selectedBOM={selectedBom}
              movement={value}
              inventoryFormValues={form.getFieldsValue()}
              styleDetails={styleDetails}
              onEdit={onEdit}
              setEdit={setEdit}
              getItemDetailsData={getItemDetailsData}
              form={form}
            />
          )}
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default CreateFabricInventory;
