import { Button, Card, Input, InputNumber, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";
import { useCallback, useEffect, useState } from "react";
import { OB_MACHINE_LIST } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import HandleNotification from "common/Notification";

const MachineRentTable = (props) => {
  // Props
  const {
    machineRentList,
    setMachineRentList,
    view,
    title,
  } = props;  

  // States
  const [machineTypeList, setMachineTypeList] = useState(null);

  const getAllMachineTypeList = useCallback(async (filterValues) => {
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 10,
    };
    const query = `${OB_MACHINE_LIST}`;
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      const listData = response?.data?.data?.data;
      setMachineTypeList(isArrayAndHasValue(listData) ? listData : null);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting buyer machine type list!",
        null,
      );
    }
  }, []);

  useEffect(() => {
    getAllMachineTypeList();
  }, [getAllMachineTypeList]);

  useEffect(() => {
    if (
      !isArrayAndHasValue(machineRentList) ||
      !isArrayAndHasValue(machineTypeList)
    )
      return;

    const notIncludedItems = machineRentList.filter(
      (machine) =>
        !machineTypeList.some(
          (type) => type?.id === machine?.ob_machine_type_info?.id,
        ),
    );

    const customList =
      isArrayAndHasValue(notIncludedItems) &&
      notIncludedItems?.map((item) => {
        return {
          ...item?.ob_machine_type_info,
        };
      });

    const unionList = [...machineTypeList, isArrayAndHasValue(customList) ? [...customList] : []];

    setMachineTypeList(unionList);
  }, [machineRentList]);


  const addNewMachine = () => {
    const machineRentListCopy = isArrayAndHasValue(machineRentList)
      ? [...machineRentList]
      : [];

    machineRentListCopy.push({
      unique_id: isArrayAndHasValue(machineRentListCopy)
        ? machineRentListCopy[machineRentListCopy.length - 1].unique_id + 1
        : 0,
      is_delete: 0,
    });

    setMachineRentList(machineRentListCopy);
  };

  const removeSingleMachine = (unique_id) => {
    if (!isArrayAndHasValue(machineRentList)) {
      return;
    }

    const machineRentListCopy = [...machineRentList];
    const foundMachine = machineRentListCopy?.find(
      (item) => item?.unique_id === unique_id,
    );
    foundMachine["is_delete"] = 1;
    setMachineRentList(machineRentListCopy);
  };

  const onChangeValue = (value, unique_id, context) => {
    const machineRentListCopy = [...machineRentList];
    const foundMachine = machineRentListCopy?.find(
      (item) => item?.unique_id === unique_id,
    );
    foundMachine[context] = value;
    setMachineRentList(machineRentListCopy);

    calculateTotalValue(unique_id);
  };

  const calculateTotalValue = (unique_id) => {
    const machineRentListCopy = isArrayAndHasValue(machineRentList)
      ? [...machineRentList]
      : null;
    const foundMachine = machineRentListCopy?.find(
      (item) => item?.unique_id === unique_id,
    );

    let total_value = 0;

    if (
      !foundMachine?.machine_qty ||
      !foundMachine?.rent_per_day ||
      !foundMachine?.no_of_day
    ) {
      total_value = 0;
    } else {
      total_value =
        foundMachine?.machine_qty *
        foundMachine?.rent_per_day *
        foundMachine?.no_of_day;
    }

    foundMachine["total_value"] = total_value.toFixed(2);

    setMachineRentList(machineRentListCopy);
  };

  return (
    <Card
      className="custome-table"
      title={<span style={{ fontSize: "14px" }}>{title || "Machine to Rent"}</span>}
      style={{ marginTop: "20px" }}
    >
      <div className="root-table">
        <table className="pi-product-details-table">
          <thead>
            <tr>
              <th className="t-header bc-java ">Machine Type</th>
              <th className="t-header bc-java ">Machine Model</th>
              <th className="t-header bc-java ">Qty (pc.)</th>
              <th className="t-header bc-java ">Rent/Day (tk.)</th>
              <th className="t-header bc-java ">No of days</th>
              <th className="t-header bc-java ">Value (tk.)</th>
              <th hidden={view}>Action</th>
            </tr>
          </thead>
          <tbody>
            {isArrayAndHasValue(machineRentList) && (
              <>
                {machineRentList
                  ?.filter((item) => !item?.is_delete)
                  ?.map((item, index) => (
                    <tr key={item.unique_id}>
                      {/* Machine Type */}
                      <td className="t-body">
                        <Select
                          showSearch
                          placeholder="Select a Machine Type"
                          optionFilterProp="children"
                          size="small"
                          style={{ width: "100%" }}
                          onSelect={(value) => {
                            onChangeValue(
                              value,
                              item?.unique_id,
                              "ob_machine_type_id",
                            );
                          }}
                          allowClear
                          onClear={getAllMachineTypeList}
                          onSearch={(value) =>
                            getAllMachineTypeList({ name: value })
                          }
                          value={item?.ob_machine_type_id}
                          disabled={view}
                        >
                          {isArrayAndHasValue(machineTypeList) &&
                            machineTypeList.map((item) => (
                              <Select.Option
                                value={item.id}
                                key={item.id}
                                disabled={item?.isDisabled}
                              >
                                {item?.name || "N/A"}
                              </Select.Option>
                            ))}
                        </Select>
                      </td>
                      {/* Machine Model */}
                      <td className="t-body">
                        <Input
                          placeholder="Model Name"
                          onChange={(event) =>
                            onChangeValue(
                              event.target.value,
                              item?.unique_id,
                              "machine_model",
                            )
                          }
                          size="small"
                          value={item?.machine_model}
                          disabled={view}
                        />
                      </td>
                      {/* Qty (pc.) */}
                      <td className="t-body">
                        <InputNumber
                          placeholder="Qty (pc.)"
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            onChangeValue(value, item?.unique_id, "machine_qty")
                          }
                          size="small"
                          precision={0}
                          value={item?.machine_qty}
                          disabled={view}
                          formatter={(value) => commaSeparateNumber(value)}
                        />
                      </td>
                      {/* Rent/Day (tk.) */}
                      <td className="t-body">
                        <InputNumber
                          placeholder="Rent/Day (tk.)"
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            onChangeValue(
                              value,
                              item?.unique_id,
                              "rent_per_day",
                            )
                          }
                          size="small"
                          value={item?.rent_per_day}
                          disabled={view}
                          formatter={(value) => commaSeparateNumber(value)}
                        />
                      </td>
                      {/* No of days */}
                      <td className="t-body">
                        <InputNumber
                          placeholder="No of days"
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            onChangeValue(value, item?.unique_id, "no_of_day")
                          }
                          size="small"
                          precision={0}
                          value={item?.no_of_day}
                          disabled={view}
                          formatter={(value) => commaSeparateNumber(value)}
                        />
                      </td>
                      {/* Value (tk.) */}
                      <td className="t-body">
                        <InputNumber
                          placeholder="Value (tk.)"
                          disabled
                          style={{ width: "100%" }}
                          value={item?.total_value}
                          size="small"
                          formatter={(value) => commaSeparateNumber(value)}
                        />
                      </td>

                      <td
                        className="t-body"
                        onClick={() => removeSingleMachine(item?.unique_id)}
                        hidden={view}
                      >
                        <MinusCircleOutlined
                          className="cursore-pointer"
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      <Button
        type="primary"
        className="mt-4"
        icon={<PlusOutlined />}
        size="small"
        onClick={addNewMachine}
        hidden={view}
      >
        Add Machine
      </Button>
    </Card>
  );
};

export default MachineRentTable;
