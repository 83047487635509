import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
    DatePicker,
    TimePicker,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    PP_MEETING_DATE_LIST_ENDPOINT,
    PP_MEETING_DATE_ADD_ENDPOINT,
    PP_MEETING_DATE_EDIT_ENDPOINT,
    PP_MEETING_DATE_DELETE_ENDPOINT,
    GET_STYLE_LIST_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";
import { cglFormatDate } from "utils/functions";

const PPMeetingDate = () => {

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };
  
  const onTimeChange = (time, timeString) => {
    console.log(time, timeString); // You can handle the selected time here
  };
  
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ styleList, setStyleList ] = useState();
  const [buyerInput, setBuyerInput] = useState("");
  const [salesContractInput, setSalesContractInput] = useState("");

  const handleSelectChange = (style_id) => {
    // console.log("style_id", style_id);
    // Find the style object that matches the selected style_id
    const selectedStyle = styleList.find(style => style.id === style_id);
    console.log("selectedStyle", selectedStyle);
    if (selectedStyle) {
      // Extract relevant fields (adjust fields based on your actual data structure)
      const buyerValue = selectedStyle?.buyer_info?.name;
      const salesContractValue = selectedStyle?.active_sales_contract_map_info?.active_sales_contract_info?.reference_no;
  
      // Update the form fields using setFieldsValue
      pp_meeting_dateForm.setFieldsValue({
        buyer: buyerValue,
        sales_contract: salesContractValue,
      });
    } 
  };

  // Antd
  const [pp_meeting_dateForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // // Redux
  // const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllPPMeetingDate = useCallback(
    async (filterValues) => {
      const payload = {
        page: filterValues?.page || currentPage,
        ...filterValues,
      }

      setLoading(true);

      const query = `${PP_MEETING_DATE_LIST_ENDPOINT}`;
      const bodyData = {
        ...payload,
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting PP Meeting Date data");
      }
    },
    [currentPage],
  );

  const getAllStyles = async (filterValues) => {
    setLoading(true);

    const query = `${GET_STYLE_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filteredStyleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("mamuuu", response?.data?.data?.data);
      setStyleList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  console.log("Style List gulo dekhi", styleList);

  

  // console.log("Line List dekhi", salesContractList);

  // Effects
  useEffect(() => {
    getAllPPMeetingDate();
    getAllStyles();
  }, [refresh, getAllPPMeetingDate]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    pp_meeting_dateForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedpp_meeting_date = async (id) => {
    setLoading(true);
    const response = await deleteData(`${PP_MEETING_DATE_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("PP Meeting Date Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedpp_meeting_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      style_id: formValues?.style_id,
      pp_meeting_date: moment(formValues?.pp_meeting_date).format('YYYY-MM-DD'),
      pp_meeting_time: moment(formValues?.pp_meeting_time).format('HH:mm'),
    };

    const response = await putData(
      `${PP_MEETING_DATE_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("PP Meeting Date Edited Successfully");
      refetch();
      pp_meeting_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewpp_meeting_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      style_id: formValues?.style_id,
      pp_meeting_date: moment(formValues?.pp_meeting_date).format('YYYY-MM-DD'), // Format as 'Y-m-d'
      pp_meeting_time: moment(formValues?.pp_meeting_time).format('HH:mm'),
    };

    const response = await postData(PP_MEETING_DATE_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("PP Meeting Date Created Successfully");
      refetch();
      pp_meeting_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    console.log("recordsss", record);

    pp_meeting_dateForm.setFieldsValue({
      id: record?.id,
      style_id: record?.style_id,
      pp_meeting_date: moment(record?.pp_meeting_date, 'YYYY-MM-DD'), // Use moment to set the date
      pp_meeting_time: moment(record?.pp_meeting_time, 'HH:mm'),
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Style Name",
      dataIndex: ["style_info", "style_no"],
      key: "style_id",
    },
    {
      title: "Buyer",
      dataIndex: ["style_info", "buyer_info", "name"],
      key: "buyer",
    },
    {
      title: "Sales Contract / Sub-Contract Ref No",
      dataIndex: ["style_info", "active_sales_contract_map_info", "active_sales_contract_info", "reference_no"],
      key: "sales_contract",
    },
    {
      title: "PP Meeting Date",
      dataIndex: ["pp_meeting_date"],
      key: "pp_meeting_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "PP Meeting Time",
      dataIndex: ["pp_meeting_time"],
      key: "pp_meeting_time",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedpp_meeting_date(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                
                addButtonText="Add New"
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>PP Meeting Date</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllPPMeetingDate}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="pp_meeting_date"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit PP Meeting Date" : "Create PP Meeting Date"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={pp_meeting_dateForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedpp_meeting_date : createNewpp_meeting_date}
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="style_id"
                placeholder="Select a Style"
                label="Select a Style"
                rules={[
                  {
                    required: true,
                    message: "Style is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Style"
                  showSearch
                  optionFilterProp="children"
                  style={{width: "100%"}}
                  onSearch={(value) => getAllStyles({style_no: value})}
                  onChange={(style_id) => handleSelectChange(style_id)}
                >
                  {styleList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item.style_no}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="buyer"
                placeholder="Buyer"
                label="Buyer"
              >
                <Input disabled={true} value={buyerInput} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="sales_contract"
                placeholder="Sales Contract"
                label="Sales Contract"
              >
                <Input disabled={true} value={salesContractInput} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="pp_meeting_date"
                placeholder="PP Meeting Date"
                label="PP Meeting Date"
                rules={[
                  {
                    required: true,
                    message: "PP Meeting Date is Required",
                  },
                ]}
              >
                <CustomDatePicker size="medium" style={{width: "100%"}} onChange={onDateChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="pp_meeting_time"
                placeholder="PP Meeting Time"
                label="PP Meeting Time"
                rules={[
                  {
                    required: true,
                    message: "PP Meeting Time is Required",
                  },
                ]}
              >
                <TimePicker 
                  onChange={onTimeChange} 
                  use12Hours 
                  format="h:mm a" 
                  style={{ width: '100%' }} 
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PPMeetingDate;
