import {
  TRIMS_ACCESORY_CONSUMPTION,
  TRIMS_ACCESSORY_STYLES_LIST,
} from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { useState, useEffect, createContext, useCallback } from "react";

export const FabricAutoContext = createContext();

const FabricAutoContextProvider = ({ children }) => {
  // States
  const [styleList, setStyleList] = useState(null);
  const [salesContractList, setSalesContractList] = useState(null);
  const [consumptionTableData, setConsumptionTableData] = useState(null);

  const getStyleList = useCallback(async (filterValues) => {
    const query = `${TRIMS_ACCESSORY_STYLES_LIST}`;
    const bodyData = {
      page: filterValues?.page || 1,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);
    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting style list",
        null,
      );
    }
  }, []);

  useEffect(() => {
    // Get styles on first render
    getStyleList();
  }, [getStyleList]);

  const getConsumptionTableData = useCallback(async (props) => {
    const { style_id, sales_contract_id } = props;

    const query = `${TRIMS_ACCESORY_CONSUMPTION}`;
    const bodyData = {
      style_id: style_id || null,
      sales_contract_id: sales_contract_id || null,
    };

    const response = await postData(query, bodyData);
    if (response && response?.code === 200) {
      setConsumptionTableData(response?.data?.consumption_details);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting consumption table data",
        null,
      );
    }
  }, []);

  return (
    <FabricAutoContext.Provider
      value={{
        styleList,
        getConsumptionTableData,
        getStyleList,
        consumptionTableData,
        setConsumptionTableData,
        salesContractList,
        setSalesContractList,
      }}
    >
      {children}
    </FabricAutoContext.Provider>
  );
};

export default FabricAutoContextProvider;
