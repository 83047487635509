import React, { useState } from 'react';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Upload, message, Button, List, Typography, Spin } from 'antd';
import axios from 'axios';
import { ITEM_OCR_UPLOAD_PERMISSION } from '../../routes/permissions';

const { Title } = Typography;

const ItemOCR = () => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      message.error('You can only upload PDF files!');
    }
    return isPdf || Upload.LIST_IGNORE;
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleRemove = (file) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  const handleGenerate = async () => {
    if (fileList.length === 0) {
      message.error('Please select at least one file first!');
      return;
    }

    setLoading(true);

    try {
      const base64Promises = fileList.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => {
            const fileBase64 = reader.result.split(',')[1];
            resolve(fileBase64);
          };
          reader.onerror = () => {
            reject(new Error(`Failed to read file: ${file.name}.`));
          };
        });
      });

      const base64Strings = await Promise.all(base64Promises);

      const payload = {
        file_type: 'pdf',
        file_b64: base64Strings,
      };

      const response = await axios.post('https://hang-tag-ocr.sslwireless.com/get_csv', payload, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoices.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('File generation error:', error);
      message.error('File generation failed.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
      <div style={{ textAlign: 'center' }}>
        <Title level={3}>Upload Item OCR</Title>

        {fileList.length > 0 && (
          <List
            itemLayout="horizontal"
            dataSource={fileList}
            renderItem={(file) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemove(file)}
                  >
                    Remove
                  </Button>
                ]}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
              >
                <div style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {file.name}
                </div>
              </List.Item>
            )}
          />
        )}

        <Upload
          fileList={fileList}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          onRemove={handleRemove}
          showUploadList={false}
          multiple
          disabled={!ITEM_OCR_UPLOAD_PERMISSION}
        >
          <Button icon={<UploadOutlined />} style={{ marginBottom: 16 }}>
            Click to Upload
          </Button>
        </Upload>
        <Button
          type="primary"
          onClick={handleGenerate}
          disabled={fileList.length === 0 || loading || !ITEM_OCR_UPLOAD_PERMISSION}
          style={{ marginTop: 16 }}
        >
          {loading ? <Spin style={{ color: 'white' }} /> : 'Generate'}
        </Button>
      </div>
    </div>
  );
};

export default ItemOCR;
