import { Form, Select, Row, Col, InputNumber } from "antd";
import "./index.css";
import {
  RAW_MATERIAL_ADD,
  RAW_MATERIAL_ITEM_BY_TIME,
  RAW_MATERIAL_UPDATE,
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { commaSeparateNumber } from "../../../../utils/functions";

const EntryForm = (props) => {
  // Props
  const {
    selectedRecord,
    styleDetails,
    movement,
    inventoryFormValues,
    entryForm,
    isItemUpdate,
    form,
  } = props;

  // States
  const [materialHistory, setMaterialHistory] = useState([]);

  // Antd
  const { Option } = Select;

  const getMaterialByTime = useCallback(async () => {
    const bodyData = {
      buying_office_id: styleDetails?.style?.buying_office_info?.id,
      sales_contract_id:
        styleDetails?.style?.sales_contract_map_info?.sales_contract_id || styleDetails?.style?.active_sales_contract_map_info?.sales_contract_id,
      style_id: styleDetails?.style?.id,
      supplier_id: selectedRecord?.supplier_id,
      item_category_id: selectedRecord?.item_category_id,
      item_id: selectedRecord?.item_id,
      unit_id: selectedRecord?.unit_id,
      entry_date: inventoryFormValues?.entry_date,
      movement_type: movement,
    };

    const response = await getData(RAW_MATERIAL_ITEM_BY_TIME, false, bodyData);

    if (response && response?.status === 200) {
      setMaterialHistory(response?.data?.data);
    }
  }, [styleDetails, selectedRecord, inventoryFormValues, movement]);

  useEffect(() => {
    if (isItemUpdate) {
      getMaterialByTime();
    }
  }, [isItemUpdate, getMaterialByTime]);

  const onSelectMaterialHistory = (value) => {
    const selectedMaterialData = materialHistory?.find(
      (material) => material?.created_at === value,
    );

    entryForm.setFieldsValue({
      receive_quantity: selectedMaterialData?.received_intake_quantity,
      roll_quantity: selectedMaterialData?.shipment_qty,
      selected_material_id: selectedMaterialData?.id,
    });
  };

  return (
    <Row gutter={16}>
      <Col span={isItemUpdate ? 6 : 0}>
        <Form.Item
          label="Selected Material ID"
          name="selected_material_id"
          hidden
        />
        <Form.Item
          label="Time"
          name="time"
          rules={[
            {
              required: isItemUpdate ? true : false,
              message: `Please select a time`,
            },
          ]}
        >
          <Select
            onSelect={(value) => onSelectMaterialHistory(value)}
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            size="small"
          >
            {materialHistory?.map((material, index) => (
              <Option
                key={index}
                value={material?.created_at}
                label={material?.created_at}
              >
                {moment(material?.created_at).format("YYYY-MM-DD HH:mm:ss")}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={isItemUpdate ? 6 : 8}>
        <Form.Item label="Roll Quantity" name="roll_quantity">
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) => commaSeparateNumber(value)}
            precision={2}
            size="small"
          />
        </Form.Item>
      </Col>
      <Col span={isItemUpdate ? 6 : 8}>
        <Form.Item label="Quantity" name="receive_quantity">
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) => commaSeparateNumber(value)}
            precision={2}
            size="small"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default EntryForm;
