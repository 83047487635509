import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import SubmissionRealizationForm from "./SubmissionRealizationForm";
import { VIEW_SELECTED_SUBMISSION_REALIZATION } from "../../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import moment from "moment";
import { unionBy } from 'lodash';

export default function EditSubmissionRealization(props) {
  // Props
  const { view } = props;

  // States
  const [editMode, setEditMode] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [fileList, setFileList] = useState([]);

  //   Refs
  const propertyFormRef = useRef(null);

  //   Antd
  const [form] = Form.useForm();

  //   Router
  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/export/sales-contract");
  };
  const { exportId } = useParams();

  const getSelectedExportDetails = useCallback(async (exportId) => {
    const response = await getData(`${VIEW_SELECTED_SUBMISSION_REALIZATION}/${exportId}`);

    if(response && response.status === 200) {
        const tableData = response?.data?.data;

        form.setFieldsValue({
            ...tableData,
            sales_contract_id: tableData.sales_contract_id || null,
            document_submission_date: tableData.document_submission_date ? moment(tableData.document_submission_date) : null,
            fdbc_number: tableData.fdbc_number || null,
            fdbc_date: tableData.fdbc_date ? moment(tableData.fdbc_date) : null,
            acceptance_rcv_date: tableData.acceptance_rcv_date ? moment(tableData.acceptance_rcv_date) : null,
            maturity_date: tableData.maturity_date ? moment(tableData.maturity_date) : null,
            realization_date: tableData.realization_date ? moment(tableData.realization_date) : null,
        });

        const mergedInvoiceData = unionBy(tableData?.invoice_details, invoiceDetails, 'export_details_id');

        mergedInvoiceData &&
          mergedInvoiceData?.length > 0 &&
          setInvoiceDetails(mergedInvoiceData);


          setFileList(
            tableData?.documents.map(
              (item) => {
                return {
                  id: item?.id,
                  name: item?.document_name,
                  document: item?.document,
                  path: item?.document,
                };
              },
            ),
          );
    }
    else {
        alertPop(
            "error",
            response?.messages ? response?.messages : "Something Went Wrong!",
        );
    }
  }, [form]);

  useEffect(() => {
    if (exportId) {
      setEditMode(true);
      getSelectedExportDetails(exportId);
    }
  }, [exportId, getSelectedExportDetails]);

  const submitForm = () => {
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={`${
              exportId ? `${view ? "View" : "Update"} Export Submission & Realization` : "Add Export Submission & Realization"
            }`}
            subTitle=""
            routes={
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/export/sales-contract">Export</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {`${
                    exportId ? `${view ? "View" : "Update"} Export Submission & Realization` : "Add Export Submission & Realization"
                  }`}
                </Breadcrumb.Item>
              </Breadcrumb>
            }
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                  hidden={view}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => submitForm()}
                  hidden={view}
                >
                  {`${
                    exportId ? "Update" : "Save"
                  }`}
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <SubmissionRealizationForm
        ref={propertyFormRef}
        form={form}
        editMode={editMode}
        exportId={exportId}
        invoiceDetails={invoiceDetails}
        fileList={fileList}
        view={view}
      />
    </>
  );
}
