import { useCallback, useEffect, useState } from "react";
import { Col, Form, Select } from "antd";
import { debounce } from "lodash";
import { isArrayAndHasValue } from "utils/functions";
import { getData } from "apiServices/common";
import { GET_QA_ROLE_USERS, GET_SEWING_LINE_ENDPOINT, OB_SECTION_LIST } from "apiServices/API_ENDPOINTS";

// Antd
const { Option } = Select;

const DigitalDefectLineSectionMappingFilter = ({ initialTableData }) => {

  const [optionsLine, setOptionsLine] = useState(null);
  const [loadingLine, setLoadingLine] = useState(false);

  const [optionsQA, setOptionsQA] = useState(null);
  const [loadingQA, setLoadingQA] = useState(false);

  const [optionsOBSection, setOptionsOBSection] = useState(null);
  const [loadingOBSection, setLoadingOBSection] = useState(false);

  const fetchOptions = useCallback(
    async (endpoint, setLoading, setOption, filterValues = {}) => {
      setLoading(true);
      try {
        const bodyData = { per_page: 100, ...filterValues };
        const response = await getData(endpoint, false, bodyData);

        if (response?.data?.code === 200) {
          const data = response?.data?.data?.data || response?.data?.data || [];
          setOption(isArrayAndHasValue(data) ? data : []);
        }
      } catch (error) {
        console.error("Fetching error", error);
      } finally {
        setLoading(false);
      }
    },
    [],
  );
  // HANDLE DEBOUNCE
  const debouncedFetchLineOption = useCallback(
    debounce((value) => {
      fetchOptions(GET_SEWING_LINE_ENDPOINT, setLoadingLine, setOptionsLine,
        { name: value },
      );
    }, 300),
    [],
  );

  const debouncedFetchQAOption = useCallback(
    debounce((value) => {
      fetchOptions(GET_QA_ROLE_USERS, setLoadingQA, setOptionsQA, {
        name: value,
      });
    }, 300),
    [],
  );

  const debouncedFetchOBSectionOption = useCallback(
    debounce((value) => {
      fetchOptions(OB_SECTION_LIST, setLoadingOBSection, setOptionsOBSection,
        {
          name: value,
        },
      );
    }, 300),
    [],
  );

  // Effects
  useEffect(() => {
    fetchOptions(GET_SEWING_LINE_ENDPOINT, setLoadingLine, setOptionsLine);

    fetchOptions(OB_SECTION_LIST, setLoadingOBSection, setOptionsOBSection);

    fetchOptions(GET_QA_ROLE_USERS, setLoadingQA, setOptionsQA);


  }, [fetchOptions]);

  return (
    <>
      <Col span={4}>
        <Form.Item label="Line" name="line_id">
          <Select
            showSearch
            onSearch={(value) => debouncedFetchLineOption({ name: value })}
            loading={loadingLine}
            style={{
              width: "100%",
            }}
            placeholder="Search to select"
            optionFilterProp="children"
            size="small"
          >
            {optionsLine?.map((option, idx) => (
              <Option key={idx} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="OB Section" name="ob_section_id">
          <Select
            showSearch
            onSearch={(value) => debouncedFetchOBSectionOption({ name: value })}
            loading={loadingOBSection}
            style={{
              width: "100%",
            }}
            placeholder="Search to select"
            optionFilterProp="children"
            size="small"
          >
            {optionsOBSection?.map((option, idx) => (
              <Option key={idx} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="QA User" name="qa_id">
          <Select
            showSearch
            onSearch={(value) => debouncedFetchQAOption({ name: value })}
            loading={loadingQA}
            style={{
              width: "100%",
            }}
            placeholder="Search to select"
            optionFilterProp="children"
            size="small"
          >
            {optionsQA?.map((option, idx) => (
              <Option key={idx} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default DigitalDefectLineSectionMappingFilter;
