import { useEffect, useState } from "react";
import {
  Scheduler,
  SchedulerData,
  ViewType,
  DemoData,
  wrapperFun,
} from "react-big-schedule";
import "./index.css";
import moment from "moment";
import { Col, Row } from "antd";
import { useNavigate } from "react-router";
import { PLAN_INITIATE, SEWING_LINES } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { isArrayAndHasValue } from "utils/functions";

// const resources = [
//   {
//     name: "Resource1",
//     id: "r1",
//   },
//   {
//     name: "Resource2",
//     id: "r2",
//   },
//   {
//     name: "Resource3",
//     id: "r3",
//   },
//   {
//     name: "Resource4",
//     id: "r4",
//   },
// ];

// const events = [
//     {
//         id: 1,
//         start: "2024-08-01 09:30:00",
//         end: "2024-08-03 23:30:00",
//         resourceId: "r1",
//         title: "Event Title 1",
//         bgColor: "#f1c40f",
//     },
//     {
//         id: 2,
//         start: "2024-08-10 10:30:00",
//         end: "2024-08-15 23:30:00",
//         resourceId: "r1",
//         title: "Event Title 2",
//         bgColor: "#45b39d",
//     },
//     {
//         id: 3,
//         start: "2024-08-10 04:30:00",
//         end: "2024-08-12 07:30:00",
//         resourceId: "r2",
//         title: "Not resizable",
//         bgColor: "#884ea0",
//         resizable: false,
//     },
//     {
//         id: 4,
//         start: "2024-08-13 04:30:00",
//         end: "2024-08-15 07:30:00",
//         resourceId: "r3",
//         title: "Not resizable",
//         bgColor: "#d35400",
//         resizable: false,
//     },
//     {
//         id: 4,
//         start: "2024-09-01 04:30:00",
//         end: "2024-09-03 07:30:00",
//         resourceId: "r3",
//         title: "Not resizable",
//         bgColor: "#d35400",
//         resizable: false,
//     },
// ];

const defaultViews = [
  {
    viewName: "Line Wise View",
    viewType: ViewType.Month,
    showAgenda: false,
    isEventPerspective: false,
  },
];

const calendarDefaultProps = {
  date: moment(),
  viewType: ViewType.Month,
  showAgenda: false,
  isEventPerspective: false,
  besidesWidth: "250",
  schedulerContentHeight: "100%",
  views: defaultViews,
  startResizable: false,
  endResizable: false,

  // contentScrollbarHeight: 17,
  // contentScrollbarWidth: 17,
  // eventItemPopoverTrigger: "click",
  // crossResourceMove: false,
  // movable: false,
};

const createCalendarInstance = (props) => {
  const {
    date,
    viewType,
    showAgenda,
    isEventPerspective,
    besidesWidth,
    eventItemPopoverTrigger,
    schedulerContentHeight,
    views,
    startResizable,
    endResizable,
  } = props;

  const calendarInstance = new SchedulerData(
    date,
    viewType,
    showAgenda,
    isEventPerspective,
    {
      besidesWidth: besidesWidth,
      eventItemPopoverTrigger: eventItemPopoverTrigger,
      schedulerContentHeight: schedulerContentHeight,
      views: views,
      startResizable,
      endResizable,
    },
  );

  return calendarInstance;
};

const defaultBGColor = "#f1c40f";
const defaultTextColor = "#e74c3c";

const ReactBigScheduler = () => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [schedulerData, setSchedulerData] = useState(null);
  const [events, setEvents] = useState(null);
  const [resources, setResources] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const newSchedulerData = createCalendarInstance(calendarDefaultProps);

    if (isArrayAndHasValue(resources)) {
      newSchedulerData.setResources(resources);
    }
    if (isArrayAndHasValue(events)) {
      newSchedulerData.setEvents(events);
    }
    setSchedulerData(newSchedulerData);
  }, [events, resources]);

  useEffect(() => {
    getAllPlans();
    getAllLines({per_page: 1000});
  }, []);

  const getAllPlans = async () => {
    const query = `${PLAN_INITIATE}`;

    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setEvents(response?.data?.data);
    } else {
      HandleNotification("error", "bottomRight", "Error getting plans", null);
    }
  };

  const getAllLines = async (filterValues) => {
    const query = `${SEWING_LINES}`;
    const payload = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    }

    const response = await getData(query, false, payload);

    if (response && response?.data?.code === 200) {
      const data = response?.data?.data?.data;

      const customData =
        isArrayAndHasValue(data) &&
        data?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
          };
        });
      setResources(customData);
    } else {
      HandleNotification("error", "bottomRight", "Error getting plans", null);
    }
  };

  const nextClick = () => {
    // Calculate next month
    const nextMonthDateOne = selectedDate
      .clone()
      .add(1, "month")
      .startOf("month");
    setSelectedDate(nextMonthDateOne);

    const newSchedulerData = createCalendarInstance({
      ...calendarDefaultProps,
      date: nextMonthDateOne,
    });

    newSchedulerData.setResources(resources);
    newSchedulerData.setEvents(events);
    setSchedulerData(newSchedulerData);
  };

  const prevClick = () => {
    // Calculate previous month
    const previousMonthDateOne = selectedDate
      .clone()
      .subtract(1, "month")
      .startOf("month");
    setSelectedDate(previousMonthDateOne);

    const newSchedulerData = createCalendarInstance({
      ...calendarDefaultProps,
      date: previousMonthDateOne,
    });

    newSchedulerData.setResources(resources);
    newSchedulerData.setEvents(events);
    setSchedulerData(newSchedulerData);
  };

  const onSelectDate = (defaultData, date) => {
    // const schedulerDataCopy = schedulerData;
    // schedulerDataCopy.setDate(date);
    // schedulerDataCopy.setEvents(DemoData.events);
    // setSchedulerData(schedulerDataCopy);
  };

  const onViewChange = (defaultData, view) => {
    const schedulerDataCopy = schedulerData;
    schedulerDataCopy.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective,
    );
    schedulerDataCopy.setEvents(DemoData.events);
    setSchedulerData(schedulerDataCopy);
  };

  const eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth,
  ) => {
    let borderWidth = isStart ? "4" : "0";
    let borderColor = "rgba(0,139,236,1)";
    let backgroundColor = defaultBGColor;
    let textColor = defaultTextColor;
    let titleText = schedulerData.behaviors.getEventTextFunc(
      schedulerData,
      event,
    );
    if (!!event.type) {
      borderColor =
        event.type === 1
          ? "rgba(0,139,236,1)"
          : event.type === 3
          ? "rgba(245,60,43,1)"
          : "#999";
      // backgroundColor =
      //   event.type === 1 ? "#80C5F6" : event.type === 3 ? "#FA9E95" : "#D9D9D9";
    }

    let divStyle = {
      borderLeft: borderWidth + "px solid " + borderColor,
      backgroundColor: backgroundColor,
      height: mustBeHeight,
      color: textColor,
    };
    if (!!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{ marginLeft: "4px", lineHeight: `${mustBeHeight}px` }}>
          {titleText}
        </span>
      </div>
    );
  };

  const newEvent = (
    schedulerData,
    slotId,
    slotName,
    start,
    end,
    type,
    item,
  ) => {
    if (moment(start).isBefore(moment(), "day")) {
      HandleNotification(
        "error",
        "bottomRight",
        "Cannot add event before today",
        null,
      );
    } else {
      navigate(`/production-planning/plan-entry`);
    }
  };

  const eventClicked = (schedulerData, event) => {
    if (moment(event?.start).isBefore(moment(), "day")) {
      HandleNotification(
        "error",
        "bottomRight",
        "Cannot update event before today",
        null,
      );
    } else {
      navigate(`/production-planning/plan-update/${event?.id}`);
    }
  };

  const eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor,
  ) => {
    return (
      <div style={{ width: "300px" }}>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div
              className="status-dot"
              style={{ backgroundColor: defaultBGColor }}
            />
          </Col>
          <Col span={22} className="overflow-text">
            <span
              className="header2-text"
              style={{ color: defaultTextColor }}
              title={title}
            >
              {title}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span className="header1-text" style={{ fontSize: "1rem" }}>
              {moment(eventItem?.start).format("DD MMM YYYY")} -{" "}
              {moment(eventItem?.end).format("DD MMM YYYY")}
            </span>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      {schedulerData && (
        <Scheduler
          schedulerData={schedulerData}
          prevClick={prevClick}
          nextClick={nextClick}
          newEvent={newEvent}
          eventItemClick={eventClicked}
          eventItemTemplateResolver={eventItemTemplateResolver}
          eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
          // Unused
          onSelectDate={onSelectDate}
          onViewChange={onViewChange}
          viewEventText="Ops 1"
          viewEvent2Text="Ops 2"
          //   viewEventClick={this.ops1}
          //   viewEvent2Click={this.ops2}
          //   updateEventStart={this.updateEventStart}
          //   updateEventEnd={this.updateEventEnd}
          //   moveEvent={this.moveEvent}
          //   newEvent={this.newEvent}
          //   onScrollLeft={this.onScrollLeft}
          //   onScrollRight={this.onScrollRight}
          //   onScrollTop={this.onScrollTop}
          //   onScrollBottom={this.onScrollBottom}
          //   toggleExpandFunc={this.toggleExpandFunc}
        />
      )}
    </>
  );
};

export default wrapperFun(ReactBigScheduler);
