import moment from "moment";

export const isArrayAndHasValue = (value) => {
  if (value && Array.isArray(value) && value.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const formatNumberWithCommasFloat = (number) => {
  // Format number with commas
  // Add 2 decimal places
  return Number(parseFloat(number)).toLocaleString();
};

export const formatNumberWithCommas = (number) => {
  if(!number) return 0;
  // Format number with commas
  // Add 2 decimal places
  return Number(parseFloat(number).toFixed(2)).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatNumberValue = (value) => {
  // If value is positive show the raw value. With comma separated and 2 decimal places.
  // If value is negative show the absolute value without minus. Comma separated. Wrap with bracket () and upto  2 decimal places.
  if (value === null || value === undefined) {
    return "N/A";
  }

  if (value >= 0) {
    return formatNumberWithCommas(value);
  } else {
    const valueWithoutMinus = Math.abs(value);
    const formattedValueWithCommas = formatNumberWithCommas(valueWithoutMinus);
    const wrappedValue = `(${formattedValueWithCommas})`;
    return wrappedValue;
  }
};

export const hasPermission = (allowedpermissions) => {
  // Localstorage Data
  const accessToken = localStorage.getItem("token");
  const roleInfo = localStorage.getItem("roleInfo") && JSON.parse(localStorage.getItem("roleInfo"));
  const assignedPermissions = roleInfo ? roleInfo?.permissions : null;

  const hasRolePermission =
    assignedPermissions &&
    assignedPermissions?.find((permissionItem) =>
      allowedpermissions.includes(permissionItem?.name),
    );

  // If has this page access
  if(accessToken && hasRolePermission) {
      return true;
  }

  // If the route does not require permissions
  if(accessToken && allowedpermissions.includes("no-permission-required")) {
      return true;
  }
  
  // // Else no access
  return false;
}

export const timeDifferentCalculation = (time) => {
  if (time) {
    let createdDateTime = moment(time);
    let todayDateTime = moment(new Date());

    if (todayDateTime.diff(createdDateTime, "minutes") < 60)
      return todayDateTime.diff(createdDateTime, "minutes") + " min ago";
    else if (todayDateTime.diff(createdDateTime, "hours") < 25)
      return todayDateTime.diff(createdDateTime, "hours") + "h ago";
    else return todayDateTime.diff(createdDateTime, "days") + "d ago";
  }
};

export const commaSeparateNumber = (amount) => {
  const commaSeparated = amount || amount === 0 ? amount
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : null;

  if (commaSeparated) {
    return commaSeparated;
  } else {
    return null;
  }
};

export const toFixedAndComma = (amount) => {
  const toNumber = Number(amount);
  if (toNumber || toNumber === 0) {
    const fixed = toNumber.toFixed(2);
    const commaSeparated = commaSeparateNumber(fixed);
    return commaSeparated;
  } else {
    return 0;
  }
};

export const showTotalPrice = (product) => {
  if (product && product.price && product.quantity) {
    const result = product?.price * product?.quantity;
    return result ? result.toFixed(2) : 0;
  } else if (product && product.unit_price && product.order_quantity) {
    const result = product?.unit_price * product?.order_quantity;
    return result ? result.toFixed(2) : 0;
  } else return 0;
};


export const alignTableColumnValueRight = (value) => {
  // Format number with commas
  // Add 2 decimal places
  return <p className="text-right">{value}</p>
};

export const calculateWip = (product, sewingDayQtyInput, rejectDayQtyInput) => {
  let sewingDayQty = null;
  // If sewingDayQtyInput is given, then this will be used
  if(sewingDayQtyInput) {
    sewingDayQty = Number(sewingDayQtyInput);
  }
  else {
    sewingDayQty = product?.sewing?.day_qty
    ? Number(product?.sewing?.day_qty)
    : 0;
  }

  // If rejectDayQtyInput is given then it will be used. 
  let rejectDayQty = null;
  if(rejectDayQtyInput) {
    rejectDayQty = Number(rejectDayQtyInput);
  }
  else {
    rejectDayQty = product?.reject?.day_qty
    ? Number(product?.reject?.day_qty)
    : 0;
  }

  const sewingTotal = product?.sewing?.total
    ? Number(product?.sewing?.total)
    : 0;
  const rejectTotal = product?.reject?.total
    ? Number(product?.reject?.total)
    : 0;
  const total = sewingDayQty + sewingTotal + rejectDayQty + rejectTotal;
  const assignedTotal = product?.assign?.total
    ? Number(product?.assign?.total)
    : 0;
  const result = assignedTotal - total;

  return result || 0;
};

export const precisionValue = (value, precision) => {
  if(value || value === 0) {
    return value.toFixed(precision);
  }
}

export function cglFormatDate(item) {
  if (!item || !moment(item).isValid()) {
    return null; // or return "Invalid date" if you prefer
  }
  return moment(item).format("DD-MMM-YYYY");
}

export const getCountryList = (value) => {
  const countryList  = [
    { id: "USA", name: "USA" },
{ id: "UK", name: "UK" },
{ id: "Afghanistan", name: "Afghanistan" },
{ id: "Albania", name: "Albania" },
{ id: "Algeria", name: "Algeria" },
{ id: "American Samoa", name: "American Samoa" },
{ id: "Andorra", name: "Andorra" },
{ id: "Angola", name: "Angola" },
{ id: "Anguilla", name: "Anguilla" },
{ id: "Antarctica", name: "Antarctica" },
{ id: "Antigua and Barbuda", name: "Antigua and Barbuda" },
{ id: "Argentina", name: "Argentina" },
{ id: "Armenia", name: "Armenia" },
{ id: "Aruba", name: "Aruba" },
{ id: "Australia", name: "Australia" },
{ id: "Austria", name: "Austria" },
{ id: "Azerbaijan", name: "Azerbaijan" },
{ id: "Bahamas", name: "Bahamas" },
{ id: "Bahrain", name: "Bahrain" },
{ id: "Bangladesh", name: "Bangladesh" },
{ id: "Barbados", name: "Barbados" },
{ id: "Belarus", name: "Belarus" },
{ id: "Belgium", name: "Belgium" },
{ id: "Belize", name: "Belize" },
{ id: "Benin", name: "Benin" },
{ id: "Bermuda", name: "Bermuda" },
{ id: "Bhutan", name: "Bhutan" },
{ id: "Bolivia", name: "Bolivia" },
{ id: "Bosnia and Herzegovina", name: "Bosnia and Herzegovina" },
{ id: "Botswana", name: "Botswana" },
{ id: "Bouvet Island", name: "Bouvet Island" },
{ id: "Brazil", name: "Brazil" },
{ id: "British Indian Ocean Territory", name: "British Indian Ocean Territory" },
{ id: "Brunei Darussalam", name: "Brunei Darussalam" },
{ id: "Bulgaria", name: "Bulgaria" },
{ id: "Burkina Faso", name: "Burkina Faso" },
{ id: "Burundi", name: "Burundi" },
{ id: "Cambodia", name: "Cambodia" },
{ id: "Cameroon", name: "Cameroon" },
{ id: "Canada", name: "Canada" },
{ id: "Cape Verde", name: "Cape Verde" },
{ id: "Cayman Islands", name: "Cayman Islands" },
{ id: "Central African Republic", name: "Central African Republic" },
{ id: "Chad", name: "Chad" },
{ id: "Chile", name: "Chile" },
{ id: "China", name: "China" },
{ id: "Christmas Island", name: "Christmas Island" },
{ id: "Cocos (Keeling) Islands", name: "Cocos (Keeling) Islands" },
{ id: "Colombia", name: "Colombia" },
{ id: "Comoros", name: "Comoros" },
{ id: "Congo", name: "Congo" },
{ id: "Congo, The Democratic Republic of The", name: "Congo, The Democratic Republic of The" },
{ id: "Cook Islands", name: "Cook Islands" },
{ id: "Costa Rica", name: "Costa Rica" },
{ id: "Cote D'ivoire", name: "Cote D'ivoire" },
{ id: "Croatia", name: "Croatia" },
{ id: "Cuba", name: "Cuba" },
{ id: "Cyprus", name: "Cyprus" },
{ id: "Czech Republic", name: "Czech Republic" },
{ id: "Denmark", name: "Denmark" },
{ id: "Djibouti", name: "Djibouti" },
{ id: "Dominica", name: "Dominica" },
{ id: "Dominican Republic", name: "Dominican Republic" },
{ id: "Ecuador", name: "Ecuador" },
{ id: "Egypt", name: "Egypt" },
{ id: "El Salvador", name: "El Salvador" },
{ id: "Equatorial Guinea", name: "Equatorial Guinea" },
{ id: "Eritrea", name: "Eritrea" },
{ id: "Estonia", name: "Estonia" },
{ id: "Ethiopia", name: "Ethiopia" },
{ id: "Falkland Islands (Malvinas)", name: "Falkland Islands (Malvinas)" },
{ id: "Faroe Islands", name: "Faroe Islands" },
{ id: "Fiji", name: "Fiji" },
{ id: "Finland", name: "Finland" },
{ id: "France", name: "France" },
{ id: "French Guiana", name: "French Guiana" },
{ id: "French Polynesia", name: "French Polynesia" },
{ id: "French Southern Territories", name: "French Southern Territories" },
{ id: "Gabon", name: "Gabon" },
{ id: "Gambia", name: "Gambia" },
{ id: "Georgia", name: "Georgia" },
{ id: "Germany", name: "Germany" },
{ id: "Ghana", name: "Ghana" },
{ id: "Gibraltar", name: "Gibraltar" },
{ id: "Greece", name: "Greece" },
{ id: "Greenland", name: "Greenland" },
{ id: "Grenada", name: "Grenada" },
{ id: "Guadeloupe", name: "Guadeloupe" },
{ id: "Guam", name: "Guam" },
{ id: "Guatemala", name: "Guatemala" },
{ id: "Guinea", name: "Guinea" },
{ id: "Guinea-bissau", name: "Guinea-bissau" },
{ id: "Guyana", name: "Guyana" },
{ id: "Haiti", name: "Haiti" },
{ id: "Heard Island and Mcdonald Islands", name: "Heard Island and Mcdonald Islands" },
{ id: "Holy See (Vatican City State)", name: "Holy See (Vatican City State)" },
{ id: "Honduras", name: "Honduras" },
{ id: "Hong Kong", name: "Hong Kong" },
{ id: "Hungary", name: "Hungary" },
{ id: "Iceland", name: "Iceland" },
{ id: "India", name: "India" },
{ id: "Indonesia", name: "Indonesia" },
{ id: "Iran, Islamic Republic of", name: "Iran, Islamic Republic of" },
{ id: "Iraq", name: "Iraq" },
{ id: "Ireland", name: "Ireland" },
{ id: "Israel", name: "Israel" },
{ id: "Italy", name: "Italy" },
{ id: "Jamaica", name: "Jamaica" },
{ id: "Japan", name: "Japan" },
{ id: "Jordan", name: "Jordan" },
{ id: "Kazakhstan", name: "Kazakhstan" },
{ id: "Kenya", name: "Kenya" },
{ id: "Kiribati", name: "Kiribati" },
{ id: "Korea, Democratic People's Republic of", name: "Korea, Democratic People's Republic of" },
{ id: "Korea, Republic of", name: "Korea, Republic of" },
{ id: "Kuwait", name: "Kuwait" },
{ id: "Kyrgyzstan", name: "Kyrgyzstan" },
{ id: "Lao People's Democratic Republic", name: "Lao People's Democratic Republic" },
{ id: "Latvia", name: "Latvia" },
{ id: "Lebanon", name: "Lebanon" },
{ id: "Lesotho", name: "Lesotho" },
{ id: "Liberia", name: "Liberia" },
{ id: "Libyan Arab Jamahiriya", name: "Libyan Arab Jamahiriya" },
{ id: "Liechtenstein", name: "Liechtenstein" },
{ id: "Lithuania", name: "Lithuania" },
{ id: "Luxembourg", name: "Luxembourg" },
{ id: "Macao", name: "Macao" },
{ id: "Macedonia, The Former Yugoslav Republic of", name: "Macedonia, The Former Yugoslav Republic of" },
{ id: "Madagascar", name: "Madagascar" },
{ id: "Malawi", name: "Malawi" },
{ id: "Malaysia", name: "Malaysia" },
{ id: "Maldives", name: "Maldives" },
{ id: "Mali", name: "Mali" },
{ id: "Malta", name: "Malta" },
{ id: "Marshall Islands", name: "Marshall Islands" },
{ id: "Martinique", name: "Martinique" },
{ id: "Mauritania", name: "Mauritania" },
{ id: "Mauritius", name: "Mauritius" },
{ id: "Mayotte", name: "Mayotte" },
{ id: "Mexico", name: "Mexico" },
{ id: "Micronesia, Federated States of", name: "Micronesia, Federated States of" },
{ id: "Moldova, Republic of", name: "Moldova, Republic of" },
{ id: "Monaco", name: "Monaco" },
{ id: "Mongolia", name: "Mongolia" },
{ id: "Montserrat", name: "Montserrat" },
{ id: "Morocco", name: "Morocco" },
{ id: "Mozambique", name: "Mozambique" },
{ id: "Myanmar", name: "Myanmar" },
{ id: "Namibia", name: "Namibia" },
{ id: "Nauru", name: "Nauru" },
{ id: "Nepal", name: "Nepal" },
{ id: "Netherlands", name: "Netherlands" },
{ id: "Netherlands Antilles", name: "Netherlands Antilles" },
{ id: "New Caledonia", name: "New Caledonia" },
{ id: "New Zealand", name: "New Zealand" },
{ id: "Nicaragua", name: "Nicaragua" },
{ id: "Niger", name: "Niger" },
{ id: "Nigeria", name: "Nigeria" },
{ id: "Niue", name: "Niue" },
{ id: "Norfolk Island", name: "Norfolk Island" },
{ id: "Northern Mariana Islands", name: "Northern Mariana Islands" },
{ id: "Norway", name: "Norway" },
{ id: "Oman", name: "Oman" },
{ id: "Pakistan", name: "Pakistan" },
{ id: "Palau", name: "Palau" },
{ id: "Palestinian Territory, Occupied", name: "Palestinian Territory, Occupied" },
{ id: "Panama", name: "Panama" },
{ id: "Papua New Guinea", name: "Papua New Guinea" },
{ id: "Paraguay", name: "Paraguay" },
{ id: "Peru", name: "Peru" },
{ id: "Philippines", name: "Philippines" },
{ id: "Pitcairn", name: "Pitcairn" },
{ id: "Poland", name: "Poland" },
{ id: "Portugal", name: "Portugal" },
{ id: "Puerto Rico", name: "Puerto Rico" },
{ id: "Qatar", name: "Qatar" },
{ id: "Reunion", name: "Reunion" },
{ id: "Romania", name: "Romania" },
{ id: "Russian Federation", name: "Russian Federation" },
{ id: "Rwanda", name: "Rwanda" },
{ id: "Saint Helena", name: "Saint Helena" },
{ id: "Saint Kitts and Nevis", name: "Saint Kitts and Nevis" },
{ id: "Saint Lucia", name: "Saint Lucia" },
{ id: "Saint Pierre and Miquelon", name: "Saint Pierre and Miquelon" },
{ id: "Saint Vincent and The Grenadines", name: "Saint Vincent and The Grenadines" },
{ id: "Samoa", name: "Samoa" },
{ id: "San Marino", name: "San Marino" },
{ id: "Sao Tome and Principe", name: "Sao Tome and Principe" },
{ id: "Saudi Arabia", name: "Saudi Arabia" },
{ id: "Senegal", name: "Senegal" },
{ id: "Serbia and Montenegro", name: "Serbia and Montenegro" },
{ id: "Seychelles", name: "Seychelles" },
{ id: "Sierra Leone", name: "Sierra Leone" },
{ id: "Singapore", name: "Singapore" },
{ id: "Slovakia", name: "Slovakia" },
{ id: "Slovenia", name: "Slovenia" },
{ id: "Solomon Islands", name: "Solomon Islands" },
{ id: "Somalia", name: "Somalia" },
{ id: "South Africa", name: "South Africa" },
{ id: "South Georgia and The South Sandwich Islands", name: "South Georgia and The South Sandwich Islands" },
{ id: "Spain", name: "Spain" },
{ id: "Sri Lanka", name: "Sri Lanka" },
{ id: "Sudan", name: "Sudan" },
{ id: "Suriname", name: "Suriname" },
{ id: "Svalbard and Jan Mayen", name: "Svalbard and Jan Mayen" },
{ id: "Swaziland", name: "Swaziland" },
{ id: "Sweden", name: "Sweden" },
{ id: "Switzerland", name: "Switzerland" },
{ id: "Syrian Arab Republic", name: "Syrian Arab Republic" },
{ id: "Taiwan, Province of China", name: "Taiwan, Province of China" },
{ id: "Tajikistan", name: "Tajikistan" },
{ id: "Tanzania, United Republic of", name: "Tanzania, United Republic of" },
{ id: "Thailand", name: "Thailand" },
{ id: "Timor-leste", name: "Timor-leste" },
{ id: "Togo", name: "Togo" },
{ id: "Tokelau", name: "Tokelau" },
{ id: "Tonga", name: "Tonga" },
{ id: "Trinidad and Tobago", name: "Trinidad and Tobago" },
{ id: "Tunisia", name: "Tunisia" },
{ id: "Turkey", name: "Turkey" },
{ id: "Turkmenistan", name: "Turkmenistan" },
{ id: "Turks and Caicos Islands", name: "Turks and Caicos Islands" },
{ id: "Tuvalu", name: "Tuvalu" },
{ id: "Uganda", name: "Uganda" },
{ id: "Ukraine", name: "Ukraine" },
{ id: "United Arab Emirates", name: "United Arab Emirates" },
{ id: "United Kingdom", name: "United Kingdom" },
{ id: "United States", name: "United States" },
{ id: "United States Minor Outlying Islands", name: "United States Minor Outlying Islands" },
{ id: "Uruguay", name: "Uruguay" },
{ id: "Uzbekistan", name: "Uzbekistan" },
{ id: "Vanuatu", name: "Vanuatu" },
{ id: "Venezuela", name: "Venezuela" },
{ id: "Viet Nam", name: "Viet Nam" },
{ id: "Virgin Islands, British", name: "Virgin Islands, British" },
{ id: "Virgin Islands, U.S.", name: "Virgin Islands, U.S." },
{ id: "Wallis and Futuna", name: "Wallis and Futuna" },
{ id: "Western Sahara", name: "Western Sahara" },
{ id: "Yemen", name: "Yemen" },
{ id: "Zambia", name: "Zambia" },
{ id: "Zimbabwe", name: "Zimbabwe" },
  ];

  return countryList;
  
};

export const renderSubstyleName = (item) => {
  // return `${item?.sub_style_no || ""} ${
  //   item?.color_info?.name ? `(${item?.color_info?.name})` : "-"
  // } `;

  return item?.sub_style_no || "-";
};
