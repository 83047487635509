import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Tag,
} from "antd";
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GET_SIZES,
  REMOVE_PO_ROW,
  SALES_CONTRACT_LIST,
  UNIT_PRICE_PO_ADD,
  UNIT_PRICE_PO_UPDATE,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, getWithData, postData } from "../../../apiServices/common";
import { alertPop } from "../../../apiServices/common/helper";
import HandleNotification from "../../../common/Notification";
import { isArrayAndHasValue } from "../../../utils/functions";
import StylePODetails from "./StylePODetails";
import ValueTable from "./ValueTable";
import CustomDatePicker from "common/CustomDatePicker";

const AddUnitsPriceForm = forwardRef((props, ref) => {
  // Props
  const { permitInfo, view, poDetailsData, setPoDetailsData, mode } = props;

  // States
  const [purchesList, setPurchesList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [buyerId, setBuyerId] = useState("");
  const [dynamicSizeList, setDynamicSizeList] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [useBothStylePo, setUseBothStylePo] = useState("");
  const [contractList, setContractList] = useState([]);
  const [buyingOfficeId, setBuyingOfficeId] = useState(null);
  const [subFactoryId, setSubFactoryId] = useState(null);
  const [showFactoryInfoInputs, setShowFactoryInfoInputs] = useState(false);
  const [subContractStatus, setSubContractStatus] = useState(null);
  const [isSizeExists, setIsSizeExists] = useState(false);

  // Others
  const [form] = Form.useForm();
  const breakdown_type = Form.useWatch("breakdown_type", form);
  const navigate = useNavigate();
  const { Option } = Select;
  const { confirm } = Modal;

  //Form Submit
  useImperativeHandle(ref, () => ({
    submit() {
      form
        .validateFields()
        .then(async (values) => {
          const payload = {
            ...(permitInfo && { id: permitInfo?.id }),
            subcontract_status: subContractStatus,
            buying_office_id: subContractStatus === 0 ? buyingOfficeId : null,
            sales_contract_id: values?.sales_contract_id,
            buyer_id: subContractStatus === 0 ? buyerId : null,
            sub_contract_factory_id:
              subContractStatus === 1 ? subFactoryId : null,
            order_quantity: calculateTotalQty(poDetailsData),
            order_price: calculateTotalPrice(poDetailsData),
            contract_status: values?.contract_status || "Created",
            entry_date: values?.entry_date,
            po_id: values?.po_id || null,
          };

          // If podetailsData is empty then return error
          if (!isArrayAndHasValue(poDetailsData)) {
            HandleNotification(
              "error",
              "bottomRight",
              "Please add at least one style to create a purchase order",
              null,
            );
            return;
          }

          const po_details_data_modified = poDetailsData.map((item, index) => {
            const sub_style_info = item?.sub_style_info;
            const isSizeExists = item?.sizes?.length ? true : false;

            let sizes = [];
            if (!isSizeExists) {
              sizes = dynamicSizeList.map((sizeItem) => {
                const sizeObj = {
                  id: sizeItem?.value,
                  sales_order_quantity:
                    item[`${sizeItem?.label}_${sizeItem?.value}`] || 0,
                  po_detail_id: isSizeExists ? sizeItem?.po_detail_id : 0,
                  po_map_id: poDetailsData[index]?.po_map_id,
                  is_delete: sizeItem?.is_delete || 0,
                  common_price: item?.unit_price ? 1 : 0,
                };
                return sizeObj;
              });
            } else {
              sizes = item?.sizes?.map((sizeItem) => {
                const sizeObj = {
                  id: sizeItem?.id,
                  sales_order_quantity: sizeItem?.sales_order_quantity || 0,
                  sales_order_price: sizeItem?.sales_order_price || 0,
                  po_detail_id: permitInfo?.id ? sizeItem?.po_detail_id : 0,
                  po_map_id: item?.po_map_id,
                  is_delete: sizeItem?.is_delete || 0,
                  unit_price_id: sizeItem?.unit_price_id || 0,
                  common_price: item?.unit_price ? 1 : 0,
                };
                return sizeObj;
              });
            }

            return {
              internal_po: item?.internal_po,
              original_po: item?.original_po,
              style_id: item?.style_id || item?.style?.id,
              color_id: !isNaN(item?.color)
                ? item?.color
                : item?.color?.color_id || item?.color?.id || 0,
              sub_style_id: sub_style_info?.id,
              brand_id: item?.brand_info?.id || null,
              extra_cutting: item?.extra_cutting || 0,
              po_status: item?.po_status || "Created",
              sizes: sizes,
              po_map_id: poDetailsData[index]?.po_map_id,
            };
          });

          payload["unit_price_details"] = po_details_data_modified;

          let query = permitInfo?.id
            ? `${UNIT_PRICE_PO_UPDATE}/${permitInfo?.id}`
            : UNIT_PRICE_PO_ADD;

          const response = await postData(query, payload);

          if (response && response?.code === 200) {
            HandleNotification(
              "success",
              "bottomRight",
              `Units Price ${
                permitInfo?.id ? "updated" : "created"
              } successfully`,
              null,
            );
            navigate("/merchandising/units-price");
          } else {
            HandleNotification(
              "error",
              "bottomRight",
              `Error ${permitInfo?.id ? "updating" : "creating"} Units Price`,
              null,
            );
          }
        })
        .catch((errorInfo) => {
          alertPop("error", "Error");
        });
    },
    discart() {
      form.resetFields();
    },
  }));

  const getContractList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SALES_CONTRACT_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setContractList(response?.data?.data?.data || []);
    }
  };

  const getContractIdDetails = async (value) => {
    const query = `/api/sales_contract/${value}/view`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setPurchesList(response.data.data.purchase_orders);
      form.resetFields(["buyer", "style_no", "brand_name", "original_po"]);
      form.setFieldsValue({
        buyer: response?.data?.data?.sales_contract?.buyer_info?.name,
        po_map_id: response?.data?.data?.purchase_orders?.id,
      });
      setBuyerId(response?.data?.data?.sales_contract?.buyer_info?.id);
      setStyleList(response?.data?.data?.styleDetail);

      const newPoDetails = poDetailsData.map((item, index) => {
        const substyleArray = response?.data?.data?.styleDetail.find(
          (itm) => itm?.style_info?.id === item?.style?.id,
        )?.style_info?.sub_style;
        return {
          ...item,
          style: {
            ...item?.style,
            sub_style: substyleArray,
          },
        };
      });

      setPoDetailsData(newPoDetails);

      setUseBothStylePo(
        response?.data?.data?.sales_contract?.use_both_style_po,
      );

      // Get and set dynamicSize state data
      const size_info = response?.data?.data?.sales_contract?.size_info;
      const dynamicSizeNew =
        isArrayAndHasValue(size_info) &&
        size_info.map((item) => {
          return {
            ...item,
            label: item?.sizes?.name,
            value: item?.sizes?.id,
            quantity: 0,
          };
        });
      setDynamicSizeList(dynamicSizeNew);
    }
  };

  useEffect(() => {
    if (permitInfo && poDetailsData.length > 0 && styleList.length > 0) {
      const poDetailsDataCopy = [...poDetailsData];

      poDetailsDataCopy.forEach((obj, index) => {
        let substyleArray = styleList.find(
          (item) => item?.style_info?.id === obj?.style?.id,
        )?.style_info?.sub_style;
        const itemColors = obj?.style?.colors;
        poDetailsDataCopy[index]["color_list_obj"] = substyleArray
          ? substyleArray
          : itemColors;
        setPoDetailsData([...poDetailsDataCopy]);
      });
    }
  }, [styleList]);

  useEffect(() => {
    // If the sales contract coming from edit API is not in the contract list
    // Then push and add to the list to show data in dropdown field
    const contractListCopy = isArrayAndHasValue(contractList)
      ? contractList
      : [];
    const sales_contract_data = permitInfo?.sales_contract || null;

    if (sales_contract_data) {
      const isExistsContract =
        isArrayAndHasValue(contractList) &&
        contractList?.find((item) => item?.id === sales_contract_data?.id);

      if (!isExistsContract) {
        contractListCopy.push(sales_contract_data);
        setContractList(contractListCopy);
      }
    }
  }, [permitInfo, contractList]);

  const getSizeList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 1000,
    };
    const response = await getData(GET_SIZES, false, bodyData);

    if (response) {
      setSizeList(response?.data?.data?.data);
    }
  };

  const OnSearchGetSizeList = async (value) => {
    let payload = {
      name: value,
      per_page: 25,
    };
    let res = await getWithData(GET_SIZES, undefined, payload);

    if (res) {
      setSizeList(res?.data?.data?.data);
    }
  };

  const addSize = (value, sizeObject) => {
    // Add new dynamic size
    const dynamicSizeListCopy = [...dynamicSizeList];
    dynamicSizeListCopy.push({
      label: sizeObject?.label,
      value: value,
      quantity: 0,
    });
    setDynamicSizeList(dynamicSizeListCopy);

    // Add the new size to every items in poDetailsData
    const updatedPoData = poDetailsData.map((item) => {
      const sizesArray = isArrayAndHasValue(item?.sizes)
        ? [...item?.sizes]
        : [];
      const firstPoDeliveryDate = poDetailsData?.[0]?.sizes?.[0]?.delivery_date;

      return {
        ...item,
        sizes: [
          ...sizesArray,
          {
            id: sizeObject?.value,
            name: sizeObject?.label,
            sales_order_quantity: 0,
            ...(firstPoDeliveryDate && { delivery_date: firstPoDeliveryDate }),
            po_detail_id: 0,
          },
        ],
      };
    });
    setPoDetailsData(updatedPoData);
  };

  const deleteSize = (value, sizeObject) => {
    confirm({
      title: "Do you want to delete this Size?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const dynamicSizeListCopy = [...dynamicSizeList];
        const foundSize = dynamicSizeListCopy?.find(
          (sizeItem) => sizeItem?.value === value,
        );
        foundSize["is_delete"] = 1;
        setDynamicSizeList(dynamicSizeListCopy);

        // Manipulate the poDetailsData and remove the size from the list
        const poDetailsDataCopy = structuredClone(poDetailsData);
        poDetailsDataCopy.forEach((obj, index) => {
          // Push delete po details id to the array
          const sizeToBeDeleted = obj?.sizes?.find(
            (sizeItem) => sizeItem?.id === value,
          );
          if (sizeToBeDeleted) {
            sizeToBeDeleted["is_delete"] = 1;
          }
        });

        setPoDetailsData(poDetailsDataCopy);
      },
    });
  };

  useEffect(() => {
    getSizeList();
    getContractList();
  }, [permitInfo]);

  useEffect(() => {
    if (isArrayAndHasValue(poDetailsData) && permitInfo) {
      const uniqueSizes = [];

      poDetailsData?.forEach((item) => {
        item?.sizes?.forEach((size) => {
          const isExists = uniqueSizes.some(
            (element) => element?.label === size?.name,
          );
          if (!isExists && !size?.is_delete) {
            uniqueSizes.push({
              id: size?.id,
              po_detail_id: size?.po_detail_id,
              po_map_id: size?.po_map_id,
              label: size?.name,
              value: size?.id,
              delivery_date: size?.delivery_date,
              sales_order_quantity: size?.sales_order_quantity,
              order_price: size?.order_price || 0,
              is_delete: size?.is_delete || 0,
            });
          }
        });
      });

      setDynamicSizeList([...uniqueSizes]);
    }
  }, [poDetailsData, permitInfo]);

  useEffect(() => {
    if (dynamicSizeList && dynamicSizeList.length > 0) {
      const uniqueSizeIds = [
        ...new Set(dynamicSizeList.map((size) => size.value)),
      ];

      form.setFieldsValue({
        size_id: uniqueSizeIds,
      });
      setIsSizeExists(true);
    }
  }, [dynamicSizeList, form]);

  useEffect(() => {
    if (permitInfo) {
      getContractIdDetails(permitInfo?.sales_contract_id);

      // Set po_id
      form.setFieldsValue({
        po_id: permitInfo?.po_id,
      });

      if (permitInfo?.buying_office) {
        setShowFactoryInfoInputs(false);
        setSubContractStatus(0);
        setBuyingOfficeId(permitInfo?.buying_office_id);
        form.setFieldsValue({
          buying_office_id: permitInfo?.buying_office.name,
          sales_contract_id: permitInfo?.sales_contract_id,
          sales_contract_name: permitInfo?.sales_contract?.reference_no,
          buyer: permitInfo.buyer_info.name,
          contract_status: permitInfo.contract_status,
          order_quantity: parseInt(permitInfo.order_quantity),
          entry_date: moment(permitInfo.entry_date, "YYYY-MM-DD"),
        });
      } else if (permitInfo?.sub_contract_factory) {
        setShowFactoryInfoInputs(true);
        setSubContractStatus(1);
        setSubFactoryId(permitInfo?.sub_contract_factory_id);
        form.setFieldsValue({
          first_party_id: permitInfo?.sub_contract_factory?.name,
          address: permitInfo?.sub_contract_factory?.address,
          sales_contract_id: permitInfo?.sales_contract_id,
          sales_contract_name: permitInfo?.sales_contract?.reference_no,
          contract_status: permitInfo.contract_status,
          order_quantity: parseInt(permitInfo.order_quantity),
          entry_date: moment(permitInfo.entry_date, "YYYY-MM-DD"),
        });
      }
    }
  }, [permitInfo?.sales_contract_id]);

  const showOrderTotal = (poDetailsData, breakdown_type) => {
    const orderTotal = poDetailsData?.reduce((accumulator, currentValue) => {
      return accumulator + showTotalRowWise(currentValue, breakdown_type);
    }, 0);

    return orderTotal || 0;
  };

  const showTotalRowWise = (poObject, breakdown_type) => {
    const sizesArray = poObject?.sizes || null;
    const orderTotal =
      isArrayAndHasValue(sizesArray) &&
      sizesArray?.reduce((accumulator, currentValue) => {
        if (breakdown_type === "1") {
          return accumulator + currentValue?.sales_order_quantity;
        } else {
          return accumulator + currentValue?.sales_order_price;
        }
      }, 0);

    return orderTotal || 0;
  };

  const calculateTotalQty = (poDetailsData) => {
    const getSizeCalc = (poObject) => {
      const sizeTotal =
        isArrayAndHasValue(poObject?.sizes) &&
        poObject?.sizes?.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue?.sales_order_quantity || 0);
        }, 0);

      return sizeTotal || 0;
    };

    const result = poDetailsData?.reduce((accumulator, currentValue) => {
      return accumulator + getSizeCalc(currentValue);
    }, 0);

    return result || 0;
  };

  const calculateTotalPrice = (poDetailsData) => {
    const getSizeCalc = (poObject) => {
      const sizePriceTotal =
        isArrayAndHasValue(poObject?.sizes) &&
        poObject?.sizes?.reduce((accumulator, currentValue) => {
          return (
            accumulator +
            (currentValue?.sales_order_quantity || 0) *
              (currentValue?.sales_order_price || 0)
          );
        }, 0);

      return sizePriceTotal || 0;
    };

    const result = poDetailsData?.reduce((accumulator, currentValue) => {
      return accumulator + getSizeCalc(currentValue);
    }, 0);

    return result || 0;
  };

  const removePoRow = async (poObject) => {
    const poDetailIds = poObject?.sizes?.map(
      (sizeItem) => sizeItem?.po_detail_id,
    );

    const payload = {
      po_detail_id: poDetailIds || [],
    };

    const response = await postData(REMOVE_PO_ROW, payload);

    if (response && response?.code === 200) {
      alertPop("success", response?.message || "Successfully Removed");
    } else {
      alertPop("error", response?.message || "Something went wrong");
    }
  };

  return (
    <div className="containt-body">
      <Form layout="vertical" form={form} name="control-hooks">
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item name="po_id" hidden />
              <Form.Item
                label="Sales Contract / Sub Contract"
                name="sales_contract_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select Sales Contract No!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Sales Contract No"
                  onSelect={(value) => {
                    getContractIdDetails(value);
                    // Filter the buying office options based on the selected contract
                    const filteredContracts = contractList.filter(
                      (data) => value === data.id,
                    );

                    // Set the value of the Buying Office and disable the field
                    if (filteredContracts.length > 0) {
                      setSubContractStatus(
                        filteredContracts[0]?.subcontract_status,
                      );
                      const selectedOffice =
                        filteredContracts[0]?.buying_office_info;
                      const selectedBuyer = filteredContracts[0]?.buyer_info;
                      const selectedFactory =
                        filteredContracts[0]?.factory_info;
                      if (selectedOffice && selectedBuyer) {
                        setShowFactoryInfoInputs(false);
                        setBuyingOfficeId(selectedOffice?.id);
                        setBuyerId(selectedBuyer?.id);
                        form.setFieldsValue({
                          buying_office_id: selectedOffice?.name,
                          buyer: selectedBuyer?.name,
                        });
                      } else if (selectedFactory) {
                        setShowFactoryInfoInputs(true);
                        setSubFactoryId(selectedFactory?.id);
                        form.setFieldsValue({
                          first_party_id: selectedFactory?.name,
                          address: selectedFactory?.address,
                        });
                      }
                    }
                  }}
                  optionFilterProp="children"
                  size="small"
                  onSearch={(value) => {
                    getContractList({ reference_no: value });
                  }}
                  allowClear
                  onClear={() => {
                    form.resetFields([
                      "sales_contract_id",
                      "buying_office_id",
                      "buyer",
                      "first_party_id",
                      "address",
                      "contract_status",
                    ]);
                    setUseBothStylePo("");
                  }}
                >
                  {isArrayAndHasValue(contractList) &&
                    contractList.map((item, index) => (
                      <Option value={item.id} key={index}>
                        {item.reference_no}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {!showFactoryInfoInputs ? (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Buyer"
                    name="buyer"
                    rules={[
                      {
                        required: false,
                        message: "Please input Buyer!",
                      },
                    ]}
                    disabled={view}
                  >
                    <Input
                      className="w-100"
                      disabled={true}
                      placeholder="Please select sales contract"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="po_map_id" hidden />
                  <Form.Item
                    label="Buying Office"
                    name="buying_office_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Buying office!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Buying Office"
                      optionFilterProp="children"
                      disabled
                      size="small"
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="po_map_id" hidden />
                  <Form.Item
                    label="First Party"
                    name="first_party_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Buying office!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a First Party"
                      optionFilterProp="children"
                      disabled
                      size="small"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: "Please input Address!",
                      },
                    ]}
                    disabled={view}
                  >
                    <Input
                      className="w-100"
                      disabled={true}
                      placeholder="Address"
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Entry Date"
                name="entry_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Entry Date!",
                  },
                ]}
              >
                <CustomDatePicker
                  className="w-100"
                  disabled={view}
                  size="small"
                />

                {/* <DatePicker className="w-100" disabled={view} size="small" /> */}
              </Form.Item>
            </Col>
            {useBothStylePo && (
              <Col className="gutter-row" span={6}>
                <Tag
                  style={{
                    padding: "0px 5px",
                    marginTop: "36px",
                    fontWeight: "bold",
                  }}
                  color="error"
                >
                  {useBothStylePo === "Yes"
                    ? `This Breakdown will be effected for Unit Price break down & Production `
                    : `This Breakdown will be effected for only Unit Price break down but not for Production`}
                </Tag>
              </Col>
            )}

            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Size"
                name="size_id"
                rules={[
                  {
                    required: true,
                    message: "Please input Size!",
                  },
                ]}
                disabled={true}
              >
                <Select
                  mode="multiple"
                  onDeselect={(value, label) => {
                    deleteSize(value, label);
                  }}
                  onSelect={(value, label) => {
                    addSize(value, label);
                  }}
                  onSearch={(e) => OnSearchGetSizeList(e)}
                  showSearch
                  placeholder="Select a Size or Create"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  dropdownMatchSelectWidth={false}
                  options={
                    sizeList?.length &&
                    sizeList.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))
                  }
                  disabled={true}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Breakdown Type"
                name="breakdown_type"
                rules={[
                  {
                    required: true,
                    message: "Please input Breakdown Type!",
                  },
                ]}
                initialValue={"1"}
              >
                <Radio.Group
                  disabled={!isSizeExists}
                  optionType="button"
                  buttonStyle="solid"
                  block
                  size="small"
                >
                  <Radio.Button
                    value="1"
                    style={{
                      backgroundColor: `${
                        breakdown_type === "1" ? "#ED7D31" : "#843C17"
                      }`,
                      color: "white",
                    }}
                  >
                    Quantity & Price
                  </Radio.Button>
                  <Radio.Button
                    value="0"
                    style={{
                      backgroundColor: `${
                        breakdown_type === "0" ? "#ED7D31" : "#843C17"
                      }`,
                      color: "white",
                    }}
                  >
                    Size wise Price
                  </Radio.Button>
                  <Radio.Button
                    value="2"
                    style={{
                      backgroundColor: `${
                        breakdown_type === "2" ? "#ED7D31" : "#843C17"
                      }`,
                      color: "white",
                    }}
                  >
                    Value
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
      {/* If breakdown type is Quantity or Size wise */}
      {(breakdown_type === "0" || breakdown_type === "1") && (
        <StylePODetails
          form={form}
          dynamicSizeList={dynamicSizeList}
          purchesList={purchesList}
          styleList={styleList}
          view={view}
          setColorList={setColorList}
          colorList={colorList}
          showOrderTotal={showOrderTotal}
          showTotalRowWise={showTotalRowWise}
          poDetailsData={poDetailsData}
          setPoDetailsData={setPoDetailsData}
          removePoRow={removePoRow}
          useBothStylePo={useBothStylePo}
          mode={mode}
          breakdown_type={breakdown_type}
        />
      )}
      {/* If breakdown type is Value */}
      {breakdown_type === "2" && (
        <ValueTable
          poDetailsData={poDetailsData}
          dynamicSizeList={dynamicSizeList}
          breakdown_type={breakdown_type}
          styleList={styleList}
        />
      )}
    </div>
  );
});

export default AddUnitsPriceForm;
