import { Button, Card, DatePicker, Form, Table } from "antd";
import "./index.css";
import useFetchData from "./useFetchData";
import CustomDatePicker from "common/CustomDatePicker";
export default function SamJustification() {
  const { data, setData, onFinish } = useFetchData();
  const [form] = Form.useForm();

  return (
    <Card title={"Sam Justification"}>
      <Form layout="horizontal" onFinish={onFinish} form={form}>
        <div
          style={{
            display: "flex",
          }}
        >
          <Form.Item label="Date" name={"date"}>
            <CustomDatePicker  size="medium"/>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{
              marginLeft: "5px",
            }}
          >
            Submit
          </Button>
          <Button
            type="default"
            htmlType="button"
            danger
            style={{
              marginLeft: "5px",
            }}
            onClick={() => {
              form.resetFields();
              setData({
                dateSource: [],
                isLoading: false,
              });
            }}
          >
            Reset
          </Button>
        </div>
      </Form>
      <Table
        size="small"
        columns={columns}
        dataSource={data.dateSource}
        loading={data.isLoading}
        className="SamJustification"
        pagination={false}
        bordered
      />
    </Card>
  );
}

const columns = [
  {
    title: "Line No",
    dataIndex: "line",
    className: "sam-justification",
    key: "line",
    render: (text, record) => {
      return {
        children: text,
        props: {
          rowSpan: record.rowSpan,
        },
      };
    },
  },
  {
    title: "Style",
    dataIndex: "style",
    className: "sam-justification",
    render: (text, record) => {
      return {
        children: text,
        props: {
          rowSpan: record.rowSpan,
        },
      };
    },
  },
  {
    title: "Operation",
    dataIndex: "operation_name",
    className: "sam-justification",
  },
  {
    title: "Condition",
    dataIndex: "condition",
    className: "sam-justification",
  },
  {
    title: "Operator Name",
    dataIndex: "assigned_operator",
    className: "sam-justification",
  },
  {
    title: "Operator Id Card",
    dataIndex: "assigned_operator_id_card",
    className: "sam-justification",
  },
  { title: "Sam", dataIndex: "sam", className: "sam-justification" },
  {
    title: "Production per Hour",
    className: "production_per_hour",
    children: [
      {
        title: "OB",
        className: "production_per_hour_ob",
        dataIndex: "production_per_hour_ob",
      },
      {
        title: "Actual",
        dataIndex: "production_per_hour_actual",
        className: "production_per_hour_actual",
      },
    ],
  },
  {
    title: "SAM",
    className: "sam_data",
    children: [
      {
        title: "OB",
        className: "sam_data_ob",
        dataIndex: "sam_data_ob",
      },
      {
        title: "Actual",
        dataIndex: "sam_data_actual",
        className: "sam_data_actual",
      },
      {
        title: "Difference",
        dataIndex: "sam_data_difference",
        className: "sam_data_difference",
      },
    ],
  },
];
