import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Affix,
  Button,
  Card,
  Col,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import {
  SAMPLE_FILES_LIST,
  SAM_IMPORT_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { getData } from "../../apiServices/common";
import {
  NO_PERMISSION_REQUIRED,
  OB_SAM_DATA_BANK_CREATE_PERMISSION,
  UPLOAD_BANK_PERMISSION,
  UPLOAD_BOM_PERMISSION,
  UPLOAD_BRANCH_PERMISSION,
  UPLOAD_BRAND_PERMISSION,
  UPLOAD_BUYER_PERMISSION,
  UPLOAD_BUYING_OFFICE_PERMISSION,
  UPLOAD_COLOR_PERMISSION,
  UPLOAD_MERCHANDISER_PERMISSION,
  UPLOAD_PO_PERMISSION,
  UPLOAD_SALES_CONTRACT_PERMISSION,
  UPLOAD_SIZE_PERMISSION,
  UPLOAD_STYLE_PERMISSION,
  UPLOAD_SUBSTYLE_PERMISSION,
  UPLOAD_SUPPLIER_PERMISSION,
} from "../../routes/permissions";
import { hasPermission } from "../../utils/functions";
import BodyHeader from "../Common/BodyHeader";
import Header from "../Common/Header";

const apiUrls = [
  {
    id: 1,
    name: "Import Size",
    url: "/api/upload-size",
    permissions: [UPLOAD_SIZE_PERMISSION],
  },
  {
    id: 2,
    name: "Import Color",
    url: "/api/upload-color",
    permissions: [UPLOAD_COLOR_PERMISSION],
  },
  {
    id: 3,
    name: "Import Supplier",
    url: "/api/upload-supplier",
    permissions: [UPLOAD_SUPPLIER_PERMISSION],
  },
  {
    id: 4,
    name: "Import Buyer",
    url: "/api/upload-buyer",
    permissions: [UPLOAD_BUYER_PERMISSION],
  },
  {
    id: 5,
    name: "Import Brand",
    url: "/api/upload-Brand",
    permissions: [UPLOAD_BRAND_PERMISSION],
  },
  {
    id: 6,
    name: "Import Buyer Brand Map",
    url: "/api/upload-buyer-brand-map",
    permissions: [UPLOAD_BRAND_PERMISSION],
  },
  {
    id: 7,
    name: "Import Buying Office",
    url: "/api/upload-buying-offices",
    permissions: [UPLOAD_BUYING_OFFICE_PERMISSION],
  },
  {
    id: 8,
    name: "Import Buyer Buying Office",
    url: "/api/upload-buyer-buyingoffice-map",
    permissions: [UPLOAD_BUYING_OFFICE_PERMISSION],
  },
  {
    id: 9,
    name: "Import Bank",
    url: "/api/upload-bank",
    permissions: [UPLOAD_BANK_PERMISSION],
  },
  {
    id: 10,
    name: "Import Branch",
    url: "/api/upload-branch",
    permissions: [UPLOAD_BRANCH_PERMISSION],
  },
  {
    id: 11,
    name: "Import Bank Account",
    url: "/api/upload-bank-account",
    permissions: [UPLOAD_BANK_PERMISSION],
  },
  {
    id: 12,
    name: "Import Bank Buying Office Map",
    url: "/api/upload-bank-buyingoffice-map",
    permissions: [UPLOAD_BANK_PERMISSION],
  },
  {
    id: 13,
    name: "Import Merchandiser",
    url: "/api/upload-merchandiser",
    permissions: [UPLOAD_MERCHANDISER_PERMISSION],
  },
  {
    id: 14,
    name: "Import Merchandiser Buying Office Map",
    url: "/api/upload-merchandiser-buyingoffice-map",
    permissions: [UPLOAD_MERCHANDISER_PERMISSION],
  },
  {
    id: 15,
    name: "Import Styles",
    url: "/api/upload-styles",
    permissions: [UPLOAD_STYLE_PERMISSION],
  },
  {
    id: 16,
    name: "Import Sub-Styles",
    url: "/api/upload-substyles",
    permissions: [UPLOAD_SUBSTYLE_PERMISSION],
  },
  {
    id: 17,
    name: "Import Sales Contract",
    url: "/api/upload-sales-contracts",
    permissions: [UPLOAD_SALES_CONTRACT_PERMISSION],
  },
  {
    id: 18,
    name: "Import Sales Contract Buying Office Bank",
    url: "/api/upload-sales-contract-buying-bank",
    permissions: [UPLOAD_SALES_CONTRACT_PERMISSION],
  },
  {
    id: 19,
    name: "Import Sales Contract Style Info",
    url: "/api/upload-sales-contract-details",
    permissions: [UPLOAD_SALES_CONTRACT_PERMISSION],
  },
  {
    id: 20,
    name: "Import Style Wise Breakdown",
    url: "/api/upload-purchase-orders",
    permissions: [UPLOAD_PO_PERMISSION],
  },
  {
    id: 21,
    name: "Import Purchase Order Map",
    url: "/api/upload-purchase-order-maps",
    permissions: [UPLOAD_PO_PERMISSION],
  },
  {
    id: 22,
    name: "Import Style Wise Breakdown Details",
    url: "/api/upload-purchase-order-details",
    permissions: [UPLOAD_PO_PERMISSION],
  },
  {
    id: 23,
    name: "Import BOM",
    url: "/api/upload-bom",
    permissions: [UPLOAD_BOM_PERMISSION],
  },
  {
    id: 24,
    name: "Import BOM Details",
    url: "/api/upload-bom-details",
    permissions: [UPLOAD_BOM_PERMISSION],
  },
  {
    id: 25,
    name: "Import SAM Data Bank",
    url: SAM_IMPORT_ENDPOINT,
    permissions: [OB_SAM_DATA_BANK_CREATE_PERMISSION],
  },
  {
    id: 26,
    name: "Import Purchase Order",
    url: "/api/upload-unit-price",
    permissions: [NO_PERMISSION_REQUIRED],
  },
  {
    id: 27,
    name: "Import Purchase Order Details",
    url: "/api/upload-unit-price-details",
    permissions: [NO_PERMISSION_REQUIRED],
  },
];

export default function ImportFiles(props) {
  // States
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const [backupFiles, setBackupFiles] = useState(null);
  const [sampleFiles, setSampleFiles] = useState(null);

  // Antd Constants
  const { Option } = Select;
  const { Title } = Typography;

  const getAllFiles = async () => {
    const response = await getData(SAMPLE_FILES_LIST);

    if (response && response.status === 200) {
      setSampleFiles(response.data.data.sample_files || null);
      setBackupFiles(response.data.data.backup_files || null);
    }
  };

  // Effects
  useEffect(() => {
    getAllFiles();
  }, []);

  const uploadFile = async (file) => {
    setLoading(true);
    const URL = process.env.REACT_APP_BASE_URL + url;
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("csv_file", file);

    fetch(URL, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // Check if the response is a file
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/vnd.ms-excel") ||
            contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          // Handle file download
          return response.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            // Get filename from header or use default
            const filename = response.headers.get('content-disposition')?.split('filename=')[1] || 'download.xlsx';
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            setLoading(false);
            setUrl(null);
            return null;
          });
        }
        return response.json();
      })
      .then((result) => {
        if (!result) return;
        if (result.code === 200) {
          setLoading(false);
          setUrl(null);
          message.success("File uploaded successfully");
        } else {
          setLoading(false);
          message.error(result.message || "Error uploading file!");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message || "Error uploading file!");
      });
  };

  const cutPath = (path) => {
    let updatedPath = "";

    if (path && path.includes(`public/CSVTemplate`)) {
      updatedPath = path.split("public/CSVTemplate/")[1];
    }

    if (path && path.includes(`public/cgl`)) {
      updatedPath = path.split("public/cgl/")[1];
    }

    return updatedPath;
  };

  const openFile = (name, fileName) => {
    let URL = "";

    if (fileName && fileName.includes(`public/CSVTemplate`)) {
      URL = `${process.env.REACT_APP_BASE_URL}/storage/CSVTemplate/${name}`;
    }

    if (fileName && fileName.includes(`public/cgl`)) {
      URL = `${process.env.REACT_APP_BASE_URL}/storage/cgl/${name}`;
    }

    window.open(URL, "_blank", "noreferrer");
  };

  const tableColumns = [
    {
      title: "File Name",
      key: "file_name",
      width: "90%",
      render: (_, record) => {
        return <div>{cutPath(record)}</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Space>
            <Button
              onClick={() => openFile(cutPath(record), record)}
              size="small"
            >
              Download
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          // style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Import Files"
            subTitle=""
            // actions={[
            //   <Button key="3" size="large" type="primary">
            //     <Link to="/import-files">Add New</Link>
            //   </Button>,
            // ]}
          />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader
          //   title="Style List"
          //   filtersOption={filtersOption}
          //   searchFilter={searchFilter}
          />
          {/* Uploader UI Start */}
          <Row gutter={16}>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Select
                  showSearch
                  placeholder="Select Import Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: "30%" }}
                  onSelect={(value) => setUrl(value)}
                  value={url}
                  size="small"
                >
                  {apiUrls?.length > 0 &&
                    apiUrls.map((item) => (
                      <Option
                        value={item.url}
                        key={item.id}
                        disabled={!hasPermission(item.permissions)}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
                {!view && (
                  <div style={{ marginLeft: "10px" }}>
                    <input
                      type="file"
                      id="file-input"
                      hidden
                      onChange={(e) => uploadFile(e.target.files[0])}
                    />
                    <label for="file-input" className="file-input-label">
                      {loading ? <LoadingOutlined /> : <UploadOutlined />}
                      {" Upload"}
                    </label>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          {/* Uploader UI End */}

          <Row gutter={16}>
            {/* Left Column */}
            <Col span={12}>
              {/* Backup Files Table UI Start */}
              <Row gutter={16}>
                <Col span={24}>
                  <>
                    <div className="table" style={{ marginTop: 30 }}>
                      <Title level={5}>Backup Files</Title>
                      <Table
                        loading={loading}
                        dataSource={backupFiles}
                        columns={tableColumns}
                      />
                    </div>
                  </>
                </Col>
              </Row>
              {/* Backup Files Table UI End */}
            </Col>

            {/* Right Column */}
            <Col span={12}>
              {/* Sample Files Table UI Start */}
              <Row gutter={16}>
                <Col span={24}>
                  <>
                    <div className="table" style={{ marginTop: 30 }}>
                      <Title level={5}>Sample Files</Title>
                      <Table
                        loading={loading}
                        dataSource={sampleFiles}
                        columns={tableColumns}
                      />
                    </div>
                  </>
                </Col>
              </Row>
              {/* Sample Files Table UI End */}
            </Col>
          </Row>
        </Card>
      </div>
    </Fragment>
  );
}
