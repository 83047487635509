import { Card } from "antd";
import { isArrayAndHasValue } from "../../../../../utils/functions";
import { MinusCircleOutlined } from "@ant-design/icons";
import "./index.css";

const TableData = (props) => {
  const { view, sectionId, sectionName, sectionItems, removeRow } = props;
  return (
    <tbody className="table-container">
      <tr key={`table_header_key_${sectionId}`}>
        <th key="header_serial_number" className="bc-java text-center">
          NO.
        </th>
        <th key="header_section_name" className="bc-java text-center">
          {sectionName}
        </th>
        <th key="header_condition" className="bc-java text-center">
          CONDITION
        </th>
        <th key="header_smv_machine" className="bc-java text-center">
          M/C
        </th>
        <th key="header_smv_helper" className="bc-java text-center">
          HP
        </th>
        <th key="header_smv_iron_man" className="bc-java text-center">
          IR
        </th>
        <th key="header_machine_type" className="bc-java text-center">
          TYPE
        </th>
        <th key="header_tgt_machine" className="bc-java text-center">
          M/C
        </th>
        <th key="header_tgt_helper" className="bc-java text-center">
          HP
        </th>
        <th key="header_tgt_iron_man" className="bc-java text-center">
          IR
        </th>
        {view && (
          <>
            <th key="header_worker_machine" className="bc-java text-center">
              M/C
            </th>
            <th key="header_worker_helper" className="bc-java text-center">
              HP
            </th>
            <th key="header_worker_iron_man" className="bc-java text-center">
              IR
            </th>
          </>
        )}
        {!view && (
          <>
            <th key="header_delete_row" className="bc-java text-center">
              Delete
            </th>
          </>
        )}
      </tr>
      {isArrayAndHasValue(sectionItems) &&
        sectionItems?.map((sectionItem, index) => {
          return (
            <tr key={`table_parent_key_${index}`}>
              {/* SL */}
              <td className="t-body sticky">{sectionItem?.id}</td>
              {/* Operation / Section Name */}
              <td className="t-body sticky">{sectionItem?.name}</td>
              {/* Condition */}
              <td className="t-body sticky">{sectionItem?.condition}</td>
              {/* SMV - M/C */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() !==
                  "helper" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !==
                  "iron" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "hp"
                  ? sectionItem?.sam
                  : ""}
              </td>
              {/* SMV - HP */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() ===
                  "helper" ||
                sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
                  ? sectionItem?.sam
                  : ""}
              </td>
              {/* SMV - IR */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() ===
                "iron"
                  ? sectionItem?.sam
                  : ""}
              </td>
              {/* M/C - Type */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name}
              </td>
              {/* TGT - M/C */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() !==
                  "helper" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !==
                  "ir" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !==
                  "hp" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "iron"
                  ? Number((60 / sectionItem?.sam).toFixed(2))
                  : ""}
              </td>
              {/* TGT - HP */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() ===
                  "helper" ||
                sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
                  ? Number((60 / sectionItem?.sam).toFixed(2))
                  : ""}
              </td>
              {/* TGT - IR */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() ===
                  "iron" ||
                sectionItem?.ob_machine_type_info?.name.toLowerCase() === "ir"
                  ? Number((60 / sectionItem?.sam).toFixed(2))
                  : ""}
              </td>
              {view && (
                <>
                  {/* WORKER - M/C */}
                  <td className="t-body sticky">
                    {sectionItem?.worker_machine_sam
                      ? Number(sectionItem?.worker_machine_sam).toFixed(2)
                      : ""}
                  </td>
                  {/* WORKER - HP */}
                  <td className="t-body sticky">
                    {sectionItem?.worker_helper_sam
                      ? Number(sectionItem?.worker_helper_sam).toFixed(2)
                      : ""}
                  </td>
                  {/* WORKER - IR */}
                  <td className="t-body sticky">
                    {sectionItem?.worker_iron_sam
                      ? Number(sectionItem?.worker_iron_sam).toFixed(2)
                      : ""}
                  </td>
                </>
              )}
              {!view && (
                <td
                  className="t-body add-cell-ob"
                  onClick={() => {
                    removeRow(sectionItem?.id);
                  }}
                >
                  <MinusCircleOutlined
                    className="cursore-pointer"
                    style={{ fontSize: "18px", color: "red" }}
                  />
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};

export default TableData;
