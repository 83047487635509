import {
  DownloadOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Card,
  Form,
  Select,
  Modal,
  Popover,
  InputNumber,
  Input,
  DatePicker,
  Divider,
  Space,
  Button,
  message,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import {
  ADD_LC_REASON,
  EXPORT_LC_REASONS,
  SALES_CONTRACT_COMMERCIAL_DOC,
} from "../../../../apiServices/API_ENDPOINTS";
import { useParams } from "react-router";
import { getData, postData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import { isArrayAndHasValue } from "../../../../utils/functions";
import { BASE_URL } from "../../../../apiServices";
import CustomDatePicker from "common/CustomDatePicker";

const initialLCDetails = {
  unique_id: 1,
  amendment_reason: [],
  export_lc_date: null,
  export_lc_no: null,
  export_lc_total: null,
  export_lc_type: null,
  export_lc_value: null,
  lc_documents: null,
};
const token = localStorage.getItem("token");

const LCDetailsTable = (props) => {
  // Props
  const { lcdetailsList, setLcdetailsList, view } = props;

  // States
  const [addedReason, setAddedReason] = useState(null);
  const [reasons, setReasons] = useState(null);

  // Antd
  const [lcdetailsForm] = Form.useForm();

  // Others
  const { exportId } = useParams();

  const getReasonsList = async (filterValues) => {
    const query = `${EXPORT_LC_REASONS}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 1000,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setReasons(response?.data?.data);
    }
  };

  useEffect(() => {
    getReasonsList();
  }, []);

  const onReasonChange = (event) => {
    setAddedReason(event.target.value);
  };

  const addNewReason = async (event) => {
    event.preventDefault();
    const query = `${ADD_LC_REASON}`;
    const bodyData = {
      amendment_reason: addedReason,
      sales_contract_commercial_id: exportId,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setAddedReason(null);
      HandleNotification("success", "Reason added successfully");
      getReasonsList();
    }
  };

  const addRow = (unique_id, itemDetails) => {
    const lcDetailsListCopy = isArrayAndHasValue(lcdetailsList)
      ? [...lcdetailsList]
      : [];

      console.log('itemDetails',itemDetails);

      let newData;
      
      if(unique_id && lcDetailsListCopy[0].export_lc_no !== null) {
        newData = {
          ...initialLCDetails,
          unique_id: isArrayAndHasValue(lcDetailsListCopy)
            ? lcDetailsListCopy[lcDetailsListCopy.length - 1].unique_id + 1
            : 0,
          export_lc_no: itemDetails.export_lc_no,
          export_lc_type: `AMENDMENT ${lcDetailsListCopy.filter(function(element){
            return element.export_lc_no === itemDetails.export_lc_no && element.export_lc_type !== 'Initial';
        }).length + 1}`,
        };
      } else {
        newData = {
          ...initialLCDetails,
          unique_id: isArrayAndHasValue(lcDetailsListCopy)
            ? lcDetailsListCopy[lcDetailsListCopy.length - 1].unique_id + 1
            : 0,
          export_lc_type:`Initial`,
        };
      }
      
    // console.log('newData',newData);
    lcDetailsListCopy.push(newData);
    setLcdetailsList(lcDetailsListCopy);
  };

  const removeRow = (unique_id) => {
    const lcDetailsListCopy = [...lcdetailsList];
    const newData = lcDetailsListCopy.filter(
      (item) => item?.unique_id !== unique_id,
    );
    setLcdetailsList(newData);
  };

  const onChangeLCValue = (value, index, keyName) => {
    // Copy poDetailsData state array
    const lcDetailsDataCopy = [...lcdetailsList];
    const foundLCItem = lcDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );
    foundLCItem[keyName] = value;

    setLcdetailsList(lcDetailsDataCopy);
  };

  const onUploadFile = (info, key) => {
    const fileResponse =
      info?.file?.response?.code === 200 ? info?.file?.response?.data : null;
    if (fileResponse) {
      onChangeLCValue(fileResponse, key, "lc_documents");
    }
  };

  return (
    <Form form={lcdetailsForm}>
      <div>
        <Card
          title={
            <span style={{ fontSize: "14px" }}>
              Export LC and Amendment Details
            </span>
          }
        >
          <div className="custome-table">
            <table>
              <thead>
                <tr>
                  <th key="header_lc_no" className="bc-java ">
                    Export LC No.{" "}
                  </th>
                  <th key="header_initial_amendment" className="bc-java ">
                    Initial/Amendment{" "}
                  </th>
                  <th key="header_amendment_date" className="bc-java ">
                    LC/Amendment Date{" "}
                  </th>
                  <th key="header_lc_value" className="bc-java ">
                    LC/Amendment Value ${" "}
                  </th>
                  <th key="header_lc_total" className="bc-java ">
                    Total LC Value ${" "}
                  </th>
                  <th key="header_lc_value_qty" className="bc-java ">
                    LC/Amendment Qty{" "}
                  </th>
                  <th key="header_lc_total_total" className="bc-java ">
                    Total LC Qty{" "}
                  </th>
                  <th key="header_lc_reason" className="bc-java ">
                    Reason of Amendment{" "}
                  </th>
                  <th key="header_lc_document" className="bc-java ">
                    Document{" "}
                  </th>
                  <th key="header_lc_upload" className="bc-java ">
                    Upload{" "}
                  </th>
                  <th key="header_lc_add" className="bc-java ">
                    Add{" "}
                  </th>
                  <th key="header_lc_remove" className="bc-java ">
                    Rmv{" "}
                  </th>
                </tr>
              </thead>

              <tbody className="scrollable-table-container">
                {isArrayAndHasValue(lcdetailsList) &&
                  lcdetailsList?.map((item, index) => {
                    return (
                      <tr key={`table_parent_key_${index}`}>
                        {/* Export LC No. */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`export_lc_no_${index}`}
                              value={item?.export_lc_no || ""}
                              onChange={(event) => {
                                let context = "export_lc_no";
                                onChangeLCValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="LC No."
                              disabled={view}
                            />
                          </Form.Item>
                        </td>
                        {/* INITIAL/AMENDMENT */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`export_lc_type_${index}`}
                              value={item?.export_lc_type || ""}
                              onChange={(event) => {
                                let context = "export_lc_type";
                                onChangeLCValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="Initial/Amendment"
                              disabled={true}
                            />
                          </Form.Item>
                        </td>
                        {/* LC/Amendment Date */}
                        <td className="t-body">
                          <Form.Item style={{ marginBottom: "unset" }}>
                            <CustomDatePicker
                              style={{ width: "100%" }}
                              placeholder="LC Date"
                              size="small"
                              value={item?.export_lc_date}
                              onChange={(date, dateString) => {
                                let context = "export_lc_date";
                                onChangeLCValue(date, item?.unique_id, context);
                              }}
                              disabled={view}
                            />
                          </Form.Item>
                        </td>
                        {/* LC VALUE */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`export_lc_value_${index}`}
                              value={item?.export_lc_value || ""}
                              onChange={(event) => {
                                let context = "export_lc_value";
                                onChangeLCValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="LC Value"
                              disabled={view}
                            />
                          </Form.Item>
                        </td>
                        {/* TOTAL LC VALUE */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`export_lc_total_${index}`}
                              value={item?.export_lc_total || ""}
                              onChange={(event) => {
                                let context = "export_lc_total";
                                onChangeLCValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="Total LC Value"
                              disabled={view}
                            />
                          </Form.Item>
                        </td>
                        {/* LC/AMENDMENT QTY */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`export_lc_qty_${index}`}
                              value={item?.export_lc_qty || ""}
                              onChange={(event) => {
                                let context = "export_lc_qty";
                                onChangeLCValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="LC Qty"
                              disabled={view}
                            />
                          </Form.Item>
                        </td>
                        {/* TOTAL LC VALUE */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`export_lc_total_qty_${index}`}
                              value={item?.export_lc_total_qty || ""}
                              onChange={(event) => {
                                let context = "export_lc_total_qty";
                                onChangeLCValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="Total LC Qty"
                              disabled={view}
                            />
                          </Form.Item>
                        </td>
                        {/* REASON OF AMENDMENT */}
                        <td className="t-body">
                          <Form.Item style={{ marginBottom: "unset" }}>
                            <Select
                              style={{
                                width: "100%",
                              }}
                              size="small"
                              placeholder="Reasons"
                              mode="multiple"
                              disabled={view}
                              onSelect={(value) => {
                                let context = "amendment_reason";
                                const reasons_data = item?.amendment_reason;
                                reasons_data.push(value);
                                onChangeLCValue(
                                  reasons_data,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              onDeselect={(value) => {
                                let context = "amendment_reason";
                                const reasons_data = item?.amendment_reason;
                                const index = reasons_data.indexOf(value);
                                if (index > -1) {
                                  reasons_data.splice(index, 1);
                                }
                                onChangeLCValue(
                                  reasons_data,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              value={item?.amendment_reason}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: "8px 0",
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: "0 8px 4px",
                                    }}
                                  >
                                    <Input
                                      placeholder="Enter new reason"
                                      size="small"
                                      value={addedReason}
                                      onChange={onReasonChange}
                                      // ref={inputRef}
                                    />
                                    <Button
                                      type="text"
                                      icon={<PlusOutlined />}
                                      size="small"
                                      onClick={addNewReason}
                                    >
                                      Add
                                    </Button>
                                  </Space>
                                </>
                              )}
                              options={reasons?.map((item) => ({
                                label: item?.amendment_reason,
                                value: item?.id,
                              }))}
                            />
                          </Form.Item>
                        </td>
                        {/* DOCUMENT */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <span>
                              <a
                                href={`${BASE_URL}/${item?.lc_documents?.document}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item?.lc_documents?.document_name
                                  ? `${item?.lc_documents?.document_name?.slice(
                                      0,
                                      10,
                                    )}...`
                                  : ""}
                              </a>
                            </span>
                          </Form.Item>
                        </td>
                        {/* UPLOAD BUTTON */}
                        <td className="t-body">
                          <Upload
                            action={`${BASE_URL}${SALES_CONTRACT_COMMERCIAL_DOC}`}
                            accept={"image/*,.pdf, .doc, .xlsx"}
                            headers={{
                              authorization: `Bearer ${token}`,
                            }}
                            showUploadList={false}
                            onChange={(info) =>
                              onUploadFile(info, item?.unique_id)
                            }
                          >
                            <Button
                              type="primary"
                              icon={<DownloadOutlined />}
                              size="small"
                              disabled={view}
                            >
                              Upload
                            </Button>
                          </Upload>
                        </td>
                        {/* ADD BUTTON */}
                        <td
                          className="t-body"
                          onClick={() => {
                            if(!view) {
                              addRow(item?.unique_id, item);
                            }
                          }}
                        >
                          <PlusCircleOutlined
                            className="cursore-pointer"
                            style={{ fontSize: "16px", color: "Green" }}
                          />
                        </td>
                        <td
                          className="t-body"
                          onClick={() => {
                            if(!view) {
                              removeRow(item?.unique_id);
                            }
                          }}
                        >
                          <MinusCircleOutlined
                            className="cursore-pointer"
                            style={{ fontSize: "16px", color: "Red" }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <Button
              type="primary"
              size="small"
              style={{ marginTop: 10 }}
              onClick={() => addRow()}
              disabled={view}
            >
              Add New
            </Button>
          </div>
        </Card>
      </div>
    </Form>
  );
};

export default LCDetailsTable;
