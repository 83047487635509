import {
    CREATE_NEW_SEWING_LINE,
    DELETE_SEWING_LINE,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GET_SEWING_LINE_LIST,
    SHOW_MESSAGE,
    UPDATE_SEWING_LINE_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
     CREATE_SEWING_LINE_ENDPOINT,
     DELETE_SEWING_LINE_ENDPOINT, EDIT_SEWING_LINE_ENDPOINT,
    GET_SEWING_LINE_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetSewingLineList = (props) => {
    const { page, per_page, name, capacity, status } = props;
    
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_SEWING_LINE_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && {name: name}),
                    ...(capacity && {capacity: capacity}),
                    ...(status && {status: status}),
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_SEWING_LINE_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};



export const onCreateSewingLine = (SewingLine) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_SEWING_LINE_ENDPOINT}`, SewingLine)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_SEWING_LINE, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Sewing Line Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Sewing Line Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};
export const onUpdateSelectedSewingLine = (category) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_SEWING_LINE_ENDPOINT}/${category.id}`, category)

            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_SEWING_LINE_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "SewingLine Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Sewing Line updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};
export const onDeleteSewingLine = (category) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_SEWING_LINE_ENDPOINT}/${category['id']}`)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_SEWING_LINE, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted Sewing Line Successfully",
                    });

                    HandleNotification('success', 'bottomRight', 'Sewing Line deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};
