
import { Table } from 'antd';
import React, {useState} from 'react';
import CustomFilter from '../../Common/CustomFilter';

const SewingLineListTable = (props) => {
    const {
      total,
      loading,
      defaultCurrent,
      pageSize,
      current,
      onChange,
      showFilter,
      setShowFilter,
    } = props;

    const handleFilter = () => {
        setShowFilter((prev) => !prev)
    }

    return (
      <>
        {props.filter && (
          <CustomFilter
            showFilter={showFilter}
            tableData={props.dataSource}
            fetchTableData={null}
            filterName="sewing_line"
          />
        )}
        <div className="table" style={{ marginTop: 10 }}>
          <Table
            loading={loading}
            dataSource={props.dataSource}
            columns={props.tableColumns}
            pagination={{
              showSizeChanger: false,
              defaultPageSize: pageSize,
              current: current,
              onChange: onChange,
              defaultCurrent: defaultCurrent,
              total: total,
            }}
          />
        </div>
      </>
    );
};
export default SewingLineListTable;
