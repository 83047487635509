import { Col, Form, Select } from "antd";
import { DIGITALIZATION_DEFECT_CATEGORY } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";

// Antd
const { Option } = Select;

const DigitalDefectCategoryMappingFilter = ({ initialTableData }) => {

  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCategory = useCallback(async (filterValues) => {
    setLoading(true);
    try{
      const bodyData = {
        per_page: 10,
        ...filterValues,
      };
      const query = `${DIGITALIZATION_DEFECT_CATEGORY}`;
  
      const response = await getData(query, false, bodyData);
  
      if (response && response?.data?.code === 200) {
        const data = response?.data?.data?.data;
        let customData = isArrayAndHasValue(data) ? [...data] : [];
        setOptions(customData);
      }
    }catch(e){
      console.log("Fetching error");
    }finally{
      setLoading(false);
    }

  }, []);

  // Effects
  useEffect(() => {
    fetchCategory()
  }, [fetchCategory]);

  return (
    <>
      <Col span={4}>
        <Form.Item label="Category" name="name">
        <Select
            showSearch
            onSearch={(value) => fetchCategory({ name: value })}
            style={{
              width: "100%",
            }}
            placeholder="Search to select"
            optionFilterProp="children"
            size="small"
          >
            {options?.map((option, idx) => (
              <Option key={idx} value={option.id}>
                {option.name_en}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default DigitalDefectCategoryMappingFilter;
