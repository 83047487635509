import { useCallback, useEffect, useState } from "react";
import { CrownTwoTone, GoldTwoTone, WalletTwoTone } from "@ant-design/icons";
import {
  Button,
  message,
  Table,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Typography,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  getData,
  postData,
  deleteData,
  putData,
} from "../../../apiServices/common";
import {
  FINISH_GOOD_ENDPOINT,
  FINISH_GOOD_SELL_ENDPOINT,
  FINISH_GOOD_SAMPLE_ENDPOINT,
  FINISH_GOOD_GIFT_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { commaSeparateNumber } from "../../../utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const itemTypes = [
  { id: 0, name: "Good", value: "Good" },
  { id: 1, name: "Rejected", value: "Rejected" },
];

const FinishGood = () => {
  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // Antd
  const [finishGoodForm] = Form.useForm();
  const { Option } = Select;
  const { Column, ColumnGroup } = Table;
  const { Title } = Typography;

  const getFinishGood = useCallback(
    async (filterValues) => {
      const { name, per_page } = filterValues || {};
      setLoading(true);

      const query = `${FINISH_GOOD_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...(name && { name: name }),
        ...(per_page && { per_page: per_page }),
      };

      const response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting finish good data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getFinishGood();
  }, [refresh, getFinishGood]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    finishGoodForm.resetFields();
    setIsModalOpen(false);
    setActionType(null);
    setSelectedRecord(null);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const openModalForEdit = (record, actionType) => {
    setActionType(actionType);
    setSelectedRecord(record);

    finishGoodForm.setFieldsValue({
      id: record?.id,
      style_name: record?.style_info?.style_no,
      brand_name: record?.brand_info?.name,
      internal_po: record?.internal_po,
      original_po: record?.original_po,
      color: record?.color_info?.name,
      size: record?.size_info?.name,
      operation_type: actionType === "sell" ? null : "Good",
    });

    onModalOpen();
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const generateModalTitle = (actionType) => {
    switch (actionType) {
      case "sell":
        return "Sell Finish Good";
      case "sample":
        return "Send as Sample";
      case "gift":
        return "Send as Gift";
      default:
        return "Finish Good";
    }
  };

  const onFinishGood = async (values) => {
    const bodyData = {
      style_id: selectedRecord?.style_id,
      sub_style_id: selectedRecord?.sub_style_id,
      brand_id: selectedRecord?.brand_id,
      color_id: selectedRecord?.color_id,
      size_id: selectedRecord?.size_id,
      internal_po: selectedRecord?.internal_po,
      original_po: selectedRecord?.original_po,
      entry_date: selectedRecord?.entry_date,
      operation_type: actionType,
      operation_quantity: values?.operation_quantity,
      product_type: values?.operation_type,
    };

    let response = null;

    switch (actionType) {
      case "sell":
        response = await postData(FINISH_GOOD_SELL_ENDPOINT, bodyData);
        break;
      case "sample":
        response = await postData(FINISH_GOOD_SAMPLE_ENDPOINT, bodyData);
        break;
      case "gift":
        response = await postData(FINISH_GOOD_GIFT_ENDPOINT, bodyData);
        break;
      default:
        break;
    }

    if (response && response?.code === 200) {
      message.success(response?.message[0]);
      onCloseModal();
      refetch();
    } else {
      message.error("Error finish good operation");
    }
  };

  return (
    <>
      <AppContent
        header={<AppPageHeader title={<Title level={5}>Finish Good</Title>} />}
        content={
          <Row>
            <Col span={24}>
              <div className="table" style={{ marginTop: 10 }}>
                <Table
                  loading={loading}
                  dataSource={tableData}
                  bordered
                  pagination={{
                    defaultPageSize: metaData?.defaultPageSize,
                    current: metaData?.current,
                    onChange: (newPage) => {
                      changePage(newPage);
                    },
                    defaultCurrent: metaData?.defaultCurrent,
                    total: metaData?.total,
                  }}
                >
                  <Column
                    title="Style"
                    dataIndex={["style_info", "style_no"]}
                    key="style"
                  />
                  <Column
                    title="Brand"
                    dataIndex={["brand_info", "name"]}
                    key="brand"
                  />
                  <Column
                    title="Internal PO"
                    dataIndex={"internal_po"}
                    key="internal-po"
                  />
                  <Column
                    title="Original PO"
                    dataIndex={"original_po"}
                    key="original-po"
                  />
                  <Column
                    title="Color"
                    dataIndex={["color_info", "name"]}
                    key="color"
                  />
                  <Column
                    title="Size"
                    dataIndex={["size_info", "name"]}
                    key="size"
                  />
                  <ColumnGroup title="Reject Garments (pc.)">
                    <Column
                      title="Sewing"
                      dataIndex="sewing-reject"
                      key="sewing-reject"
                      render={(value) =>
                        value ? commaSeparateNumber(value) : 0
                      }
                    />
                    <Column
                      title="Folding"
                      dataIndex="folding-reject"
                      key="folding-reject"
                      render={(value) =>
                        value ? commaSeparateNumber(value) : 0
                      }
                    />
                    <Column
                      title="Packing"
                      dataIndex="packing-reject"
                      key="packing-reject"
                      render={(value) =>
                        value ? commaSeparateNumber(value) : 0
                      }
                    />
                    <Column
                      title="Balance"
                      dataIndex={["rejected_quantity"]}
                      key="balance-reject"
                      render={(value) =>
                        value ? commaSeparateNumber(value) : 0
                      }
                    />
                  </ColumnGroup>
                  <ColumnGroup title="Good Garments">
                    <Column
                      title="Balance"
                      dataIndex={"good_quantity"}
                      key="balance-good"
                      render={(value) =>
                        value ? commaSeparateNumber(value) : 0
                      }
                    />
                  </ColumnGroup>
                  <Column
                    title="Actions"
                    dataIndex="color"
                    key="color"
                    render={(_, record) => {
                      return (
                        <Dropdown.Button
                          overlay={
                            <Menu
                              items={[
                                {
                                  label: (
                                    <div
                                      onClick={() => {
                                        openModalForEdit(record, "sell");
                                      }}
                                    >
                                      <span>
                                        <WalletTwoTone />
                                      </span>
                                      <span
                                        type="button"
                                        style={{
                                          marginLeft: 5,
                                        }}
                                      >
                                        Sell
                                      </span>
                                    </div>
                                  ),
                                  key: "1",
                                },
                                {
                                  label: (
                                    <>
                                      <span>
                                        <GoldTwoTone />
                                      </span>
                                      <span
                                        type="button"
                                        style={{ marginLeft: 5 }}
                                        onClick={() => {
                                          openModalForEdit(record, "sample");
                                        }}
                                      >
                                        Send as Sample
                                      </span>
                                    </>
                                  ),
                                  key: "2",
                                },
                                {
                                  label: (
                                    <>
                                      <span>
                                        <CrownTwoTone />
                                      </span>
                                      <span
                                        type="button"
                                        style={{ marginLeft: 5 }}
                                        onClick={() => {
                                          openModalForEdit(record, "gift");
                                        }}
                                      >
                                        Send as Gift
                                      </span>
                                    </>
                                  ),
                                  key: "3",
                                },
                              ]}
                            />
                          }
                        ></Dropdown.Button>
                      );
                    }}
                  />
                </Table>
              </div>
            </Col>
          </Row>
        }
      />
      <Modal
        open={isModalOpen}
        title={generateModalTitle(actionType)}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form form={finishGoodForm} layout="vertical" onFinish={onFinishGood}>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="style_name" label="Style">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="brand_name" label="Brand">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="internal_po" label="Internal PO">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="original_po" label="Original PO">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="color" label="Color">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="size" label="Size">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={`Entry Date`}
                name="entry_date"
                rules={[
                  {
                    required: true,
                    message: "Entry Date Required",
                  },
                ]}
              >
                <CustomDatePicker
                  style={{
                    width: "100%",
                  }}
                  size="medium"
                />
                {/* <DatePicker style={{ width: "100%" }} /> */}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item
                name="operation_quantity"
                placeholder="Enter value"
                label={`${generateModalTitle(actionType)} Quantity`}
                rules={[
                  {
                    required: true,
                    message: "Quantity Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="operation_type"
                placeholder="Item Type"
                label="Item Type"
                rules={[
                  {
                    required: true,
                    message: "Item Type Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Item Type"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  style={{ width: "100%" }}
                  disabled={actionType === "sell" ? false : true}
                >
                  {itemTypes?.map((item, index) => (
                    <Option value={item?.value} key={item?.id || index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default FinishGood;
