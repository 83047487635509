import axios from 'axios';
import {useNavigate} from "react-router";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
const getToken = () => {
    return localStorage.getItem('token')
};

const JwtAxios = axios.create({
    baseURL: BASE_URL, //YOUR_API_URL HERE
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
    },
});
// JwtAxios.interceptors.request.use(
//     async config => {
//         config.headers = {
//             Authorization: `Bearer ${getToken()}`,
//             'Accept': 'application/json',
//         }
//         return config;
//     },
//     error => {
//         Promise.reject(error)
//     });
JwtAxios.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response && err.response.status === 401) {
            localStorage.clear();
            window.location.reload();
        }
        return Promise.reject(err);
    },
);
export default JwtAxios;