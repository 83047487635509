import { useCallback, useEffect, useMemo, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  message,
  Table,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  Modal,
  Form,
  Select,
  Row,
  Col,
  Input,
  Button,
} from "antd";
import { useLocation } from "react-router";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../CommonComponents/ExtraContent";
import FilterComponent from "../CommonComponents/FilterComponent";
import {
  getData,
  deleteData,
  postData,
  putData,
} from "../../../apiServices/common";
import {
  GARMENT_CATEGORY_DELETE_ENDPOINT,
  GARMENT_CATEGORY_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission, isArrayAndHasValue } from "../../../utils/functions";
import {
  GARMENT_CATEGORY_CREATE_PERMISSION,
  GARMENT_CATEGORY_EDIT_PERMISSION,
  GARMENT_CATEGORY_DELETE_PERMISSION,
} from "../../../routes/permissions";
import GarmentCategory from "./GarmentCategoryForm";

const GarmentCategoryConfig = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  // Antd
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllGarmentCategory = useCallback(
    async (filterValues) => {
      const { name } = filterValues || {};

      setLoading(true);

      const query = `${GARMENT_CATEGORY_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...(name && { name: name }),
      };

      const response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting garment category data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllGarmentCategory(filterData);
  }, [refresh, filterData, getAllGarmentCategory]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedGarmentCategory = async (id) => {
    setLoading(true);
    const response = await deleteData(
      `${GARMENT_CATEGORY_DELETE_ENDPOINT}/${id}`,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Garment Category Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);
    setSelectedItem(record);
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: ["name"],
      key: "name",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      render: (status) => {
        return <span>{status ? "Active" : "Inactive"}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedGarmentCategory(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([
                      GARMENT_CATEGORY_DELETE_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([
                      GARMENT_CATEGORY_EDIT_PERMISSION,
                    ]),
                  },
                ]}
              />
            }
          />
        );
      },
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={tableData}
        filterAPI={getAllGarmentCategory}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([GARMENT_CATEGORY_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([GARMENT_CATEGORY_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Garment Category Configuration</Title>}
          />
        }
        content={
          <>
            {memoizedCustomFilter}

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Garment Category" : "Create Garment Category"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <GarmentCategory
          isEdit={isEdit}
          onCloseModal={onCloseModal}
          refetch={refetch}
          selectedItem={selectedItem}
        />
      </Modal>
    </>
  );
};

export default GarmentCategoryConfig;
