import React, { useEffect, useState } from "react";
import { Form, Layout, Button, Collapse, Typography } from "antd";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import AppPageHeader from "common/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  onGetPackingDetails,
  onGetStyleDetails,
  onGetStyleList,
  onPackingStore,
  onResetStyleDetails,
  onResetPackingDetails,
} from "redux/actions";
import PackingForm from "./PackingForm";
import PackingDetails from "../PackingDetails/PackingDetails";
import { useNavigate } from "react-router";
import DocumentUpload from "components/Common/DocumentUpload";
import { hasPermission } from "utils/functions";
import { PACKING_CREATE_PERMISSION } from "routes/permissions";

const CreatePackingEntry = (props) => {
  // States
  const [selectedInternalPO, setSelectedInternalPO] = useState(null);
  const [originalPO, setOriginalPO] = useState(null);
  const [packingItems, setPackingItems] = useState([]);
  const [packingInformation, setPackingInformation] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const styleList = useSelector(({ style }) => style.styleList);
  const selectedStyleDetails = useSelector(
    ({ style }) => style.selectedStyleDetails,
  );
  const selectedPackingDetails = useSelector(
    ({ packing }) => packing.selectedPackingDetails,
  );
  const salesContractDocuments =
    selectedStyleDetails?.data[0]?.style?.active_sales_contract_map_info
      ?.active_sales_contract_info?.sales_contract_documents;

  // Destructuring
  const styleDetails = selectedStyleDetails && selectedStyleDetails.data[0];
  const selectedPacking = selectedPackingDetails && selectedPackingDetails.data;
  const packingInfo =
    selectedPackingDetails && selectedPackingDetails.data.packing_product_info;
  const itemUsageInfo =
    selectedPackingDetails && selectedPackingDetails.data.item_usage_info;

  // Router
  const navigate = useNavigate();

  // Antd
  const packing_date_watch = Form.useWatch("packing_date", form);
  const { Content } = Layout;
  const { Panel } = Collapse;
  const { Title } = Typography;

  useEffect(() => {
    return () => {
      // Reset data on component unmount
      dispatch(onResetStyleDetails());
      dispatch(onResetPackingDetails());
    };
  }, []);

  useEffect(() => {
    // Call packing details table API on values change
    // When salesContractId, styleId, packingDate, buyingOfficeId and selectedInternalPO is available
    const salesContractId =
      styleDetails?.style?.sales_contract_map_info?.sales_contract_id;
    const styleId = styleDetails?.style?.id;
    const packingDate = packing_date_watch;
    const buyingOfficeId = styleDetails?.style?.buying_office_id;

    if (
      salesContractId &&
      styleId &&
      packingDate &&
      buyingOfficeId &&
      selectedInternalPO
    ) {
      const requestBody = {
        sales_contract_id: salesContractId,
        style_id: styleId,
        internal_po: selectedInternalPO,
        packing_date: packingDate,
        buying_office_id: buyingOfficeId,
      };

      dispatch(onGetPackingDetails(requestBody));
    }
  }, [styleDetails, selectedInternalPO, form, dispatch, packing_date_watch]);

  const itemArr = {
    item_id: [],
    item_category_id: [],
    regular_quantity: [],
    sub_style_id: [],
    replacement_quantity: [],
    wastage_quantity: [],
    remain_piece: [],
  };

  const itemDetailsState = packingItems?.map((item, idx) => {
    itemArr.item_id.push(item?.item_info?.id);
    itemArr.sub_style_id.push(item?.sub_style_id);
    itemArr.item_category_id.push(item?.item_info.category_id);
    itemArr.regular_quantity.push(parseInt(item?.usage_info.regular));
    itemArr.replacement_quantity.push(parseInt(item?.usage_info.replacement));
    itemArr.remain_piece.push(item?.usage_info?.remain_piece);
    itemArr.wastage_quantity.push(parseInt(item?.wastage_info.day_quantity));
  });

  const packing = {
    color_id: [],
    size_id: [],
    packing_piece: [],
    product_sub_style_id: [],
    rejected_piece: [],
    product_remain_piece: [],
  };

  const foldingMap = packingInformation?.map((item, idx) => {
    packing.color_id.push(item.color.id);
    packing.product_sub_style_id.push(item?.sub_style_id);
    packing.size_id.push(item.size?.id);
    packing.packing_piece.push(parseInt(item.packing_information.day_quantity));
    packing.rejected_piece.push(
      parseInt(item.rejected_information.day_quantity),
    );
    packing.product_remain_piece.push(
      parseInt(item?.folding_information?.total) -
        (parseInt(item?.packing_information?.day_quantity) +
          parseInt(item?.packing_information?.total)),
    );
  });

  const onChangeOriginalPO = (value, options) => {
    setSelectedInternalPO(options?.internal_po);
    setOriginalPO(options?.original_po);
    form.setFieldValue("internal_po", options?.internal_po);
  };

  const onChangeInternalPO = (value, options) => {
    setOriginalPO(options?.original_po);
    setSelectedInternalPO(options?.internal_po);
    form.setFieldValue("original_po", options?.original_po);
  };

  const onChangeStyle = (value, options) => {
    // Reset internal_po and original_po on style change
    setSelectedInternalPO(null);
    setOriginalPO(null);

    // Reset internal_po and original_po form field value
    form.setFieldValue("internal_po", null);
    form.setFieldValue("original_po", null);

    // Call API
    dispatch(onGetStyleDetails(value));
  };

  const onFinish = (values) => {
    values["original_po"] = originalPO;
    values["internal_po"] = selectedInternalPO;
    values["packing_day"] = selectedPacking?.total_packing_day;
    const styleDetailsInfo = {
      sales_contract_id:
        styleDetails?.style?.sales_contract_map_info?.sales_contract_id,
      buying_office_id: styleDetails?.style?.buying_office_id,
      style_id: styleDetails?.style?.id,
    };
    values = { ...values, ...itemArr, ...packing, ...styleDetailsInfo };
    dispatch(onPackingStore(values));
    form.resetFields();
    navigate("/production/packing/packing-entry");
  };

  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => form.submit()}
        disabled={!hasPermission([PACKING_CREATE_PERMISSION])}
      >
        {hasPermission([PACKING_CREATE_PERMISSION])
          ? "Submit"
          : "No Permission"}
      </Button>
      <Button size="small" htmlType="submit" onClick={() => form.resetFields()}>
        {" "}
        Reset
      </Button>
    </div>
  );

  useEffect(() => {
    dispatch(onGetStyleList(1, 10));
    form.resetFields();
  }, []);

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader title={<Title level={5}>Packing Entry</Title>} extra={extra} />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Order Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <PackingForm
                  onChangeOriginalPO={onChangeOriginalPO}
                  styleDetails={styleDetails}
                  onChangeStyle={onChangeStyle}
                  styleList={styleList}
                  onChangeInternalPO={onChangeInternalPO}
                  selectedPacking={selectedPacking}
                  originalPO={originalPO}
                />
              </Form>
            </Panel>
          </Collapse>
          {packingInfo && itemUsageInfo && (
            <PackingDetails
              setPackingInformation={setPackingInformation}
              setPackingItems={setPackingItems}
              itemUsageInfo={itemUsageInfo}
              selectedPackingDetails={packingInfo}
            />
          )}
          <Collapse
            // defaultActiveKey={["2"]}
            style={{ marginTop: 10 }}
          >
            <Panel header={<b>Documents</b>} key="2">
              {salesContractDocuments?.length > 0 ? (
                <DocumentUpload
                  filesList={salesContractDocuments}
                  view={true}
                />
              ) : (
                <div>No documents for this style</div>
              )}
            </Panel>
          </Collapse>
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default CreatePackingEntry;
