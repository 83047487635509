import { Col, Form, Row, Select, Input } from "antd";
import { useEffect, useState } from "react";
import { SALES_CONTRACT_LIST } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import HandleNotification from "../../Notification";
import { isArrayAndHasValue } from "../../../utils/functions";

const CostEstimateFilterFields = ({ initialTableData }) => {
  const { Option } = Select;
  const [salesContractList, setSalesContractList] = useState(null);

  const getAllSalesContract = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${SALES_CONTRACT_LIST}`;
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSalesContractList(response?.data?.data?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error in fetching Sales Contract List",
        null,
      );
    }
  };

  useEffect(() => {
    getAllSalesContract();
  }, []);

  return (
    <>
      <Col className="gutter-row" span={4}>
        <Form.Item
          label="Sales Contract"
          name="sales_contract_id"
          rules={[
            {
              required: true,
              message: "Please Select Sales Contract No!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a Sales Contract No"
            optionFilterProp="children"
            onSearch={(value) => {
              getAllSalesContract({ reference_no: value });
            }}
            size="small"
          >
            {isArrayAndHasValue(salesContractList) &&
              salesContractList?.map((item, index) => (
                <Option value={item?.id} key={item?.id}>
                  {item.reference_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default CostEstimateFilterFields;
