import { Col, DatePicker, Form, Select } from "antd";
import { useEffect, useState } from "react";
import { SEWING_LINES } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const DigitalizationOperatorEfficiencyFilter = ({ initialTableData }) => {
  const [sewingList, setSewingList] = useState([]);

  // Antd
  const { Option } = Select;

  const getSewingList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SEWING_LINES, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSewingList(response?.data?.data?.data || []);
    }
  };

  // Effects
  useEffect(() => {
    getSewingList();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item label="Date" name="checked_date" required>
          <DatePicker
            placeholder="Date"
            format={"DD-MMM-YY"}
            size="small"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item label="Line" name="line_id">
          <Select
            showSearch
            placeholder="Select a Line"
            onSearch={(value) => {
              getSewingList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
            filterOption={false}
          >
            {isArrayAndHasValue(sewingList) &&
              sewingList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default DigitalizationOperatorEfficiencyFilter;
