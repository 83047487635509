import { Button, Popconfirm, Space, Table, Typography, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  DELETE_BOLT_SEAL_LOG_REPORT_ENDPOINT,
  EXPORT_BOLT_SEAL_LOG_REPORT_ENDPOINT,
  GET_BOLT_SEAL_LOG_REPORT_LIST_ENDPOINT,
  VIEW_BOLT_SEAL_LOG_REPORT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  INVENTORY_DETAIL_REPORT_DELETE_PERMISSION,
  INVENTORY_DETAIL_REPORT_VIEW_PERMISSION,
} from "../../../routes/permissions";
import { checkRes } from "apiServices/common/helper";
import HandleNotification from "common/Notification";
import { hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const BoltSealReport = () => {
  // States
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  // Antd
  const { Title } = Typography;

  const cutPath = (path) => {
    console.log("path", path);
    const updatedPath = path ? path.split("public/bolt_seal_logs/")[1] : "";
    console.log("updatedPath", updatedPath);
    return updatedPath;
  };

  const getAllDetails = useCallback(async () => {
    setLoading(true);
    const query = `${GET_BOLT_SEAL_LOG_REPORT_LIST_ENDPOINT}`;

    const response = await getData(query);

    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting details data");
    }
  }, []);

  const exportDetails = async (filterValues) => {
    const {
      arrival_start_date,
      arrival_end_date,
      dispatch_start_date,
      dispatch_end_date,
    } = filterValues || {};
    const bodyData = {
      arrival_start_date: arrival_start_date
        ? moment(arrival_start_date).format("YYYY-MM-DD")
        : null,
      arrival_end_date: arrival_end_date
        ? moment(arrival_end_date).format("YYYY-MM-DD")
        : null,
      dispatch_start_date: dispatch_start_date
        ? moment(dispatch_start_date).format("YYYY-MM-DD")
        : null,
      dispatch_end_date: dispatch_end_date
        ? moment(dispatch_end_date).format("YYYY-MM-DD")
        : null,
    };
    if (
      Object.values(bodyData).length &&
      !Object.values(bodyData).some((val) => val === undefined)
    ) {
      setLoading(true);
      const query = `${EXPORT_BOLT_SEAL_LOG_REPORT_ENDPOINT}`;
      
      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "blob" },
        "arraybuffer",
      );

      if (response && response?.data) {
        // Download the file
        const outputFilename = `bolt_seal_log_report.xlsx`;
        
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setLoading(false);
        message.success("Bolt Seal Log Exported Successfully");
        refetch();
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };

  const previewFunction = async (filterValues) => {
    setLoading(true);

    const bodyData = {
      ...filterValues,
    };

    const query = `${VIEW_BOLT_SEAL_LOG_REPORT}`;
    const response = await postData(query, bodyData);
    if (response?.code === 200) {
      setLoading(false);
      setTableData(response?.data);
    } else {
      setLoading(false);
      message.error("Either both arrival dates or both dispatch dates are required.");
    }
  };
  // Effects
  useEffect(() => {
    getAllDetails();
  }, [getAllDetails]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedTracking = async (name) => {
    setLoading(true);
    const updatedName = cutPath(name);
    const query = `${DELETE_BOLT_SEAL_LOG_REPORT_ENDPOINT}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setLoading(false);
      message.success("Inventory Details Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      if (checkRes(response?.data?.code)) {
        return response.data;
      } else {
        if (response?.data?.message?.length) {
          response?.data?.message.forEach((mess) => {
            HandleNotification("error", "bottomRight", mess ? mess : "Something went wrong", null);
          });
        } else {
          alert();
        }
      }
    }
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/bolt_seal_logs/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };


  const tableColumns = [
    {
      title: "Bolt Seal Log",
      children: [
        {
          title: "SL",
          dataIndex: "sl",
          key: "sl",
          width: 200,
        },
        {
          title: "Vehicle No.",
          dataIndex: "vehicle_no",
          key: "vehicle_no",
          width: 200,
        },
        {
          title: "Vehicle Size",
          dataIndex: "vehicle_size",
          key: "vehicle_size",
          width: 200,
        },
        {
          title: "Bolt Seal No.",
          dataIndex: "bolt_seal_no",
          key: "bolt_seal_no",
          width: 200,
        },
        {
          title: "Challan No.",
          dataIndex: "challan",
          key: "challan",
          width: 200,
        },
        {
          title: "Arrival Date & Time",
          dataIndex: "arrival_date",
          key: "arrival_date",
          width: 200,
        },
        {
          title: "Dispatch Date & Time",
          dataIndex: "dispatch_date",
          key: "dispatch_date",
          width: 200,
        },
        {
          title: "GPRS No.",
          dataIndex: "gprs_no",
          key: "gprs_no",
          width: 200,
        },
        {
          title: "Buyer",
          dataIndex: "buyer",
          key: "buyer",
          width: 200,
        },
        {
          title: "Original PO",
          dataIndex: "original_po",
          key: "original_po",
          width: 200,
        },
        {
          title: "Style",
          dataIndex: "style_no",
          key: "style_no",
          width: 200,
        },
        {
          title: "Order Qty(pc)",
          dataIndex: "order_qty",
          key: "order_qty",
          width: 200,
        },
        {
          title: "Ex-Factory Qty(pc)",
          dataIndex: "ex_factory_qty",
          key: "ex_factory_qty",
          width: 200,
        },
        {
          title: "Carton Qty",
          dataIndex: "carton_qty",
          key: "carton_qty",
          width: 200,
        },
        {
          title: "Volume(CBM)",
          dataIndex: "volume",
          key: "volume",
          width: 200,
        },
        {
          title: "Driver Name",
          dataIndex: "driver_name",
          key: "driver_name",
          width: 200,
        },
        {
          title: "Driver Cell No",
          dataIndex: "driver_cell",
          key: "driver_cell",
          width: 200,
        },
        {
          title: "NID & Driving License",
          dataIndex: "nid_license",
          key: "nid_license",
          width: 200,
        },
        {
          title: "Delivery Place",
          dataIndex: "delivery",
          key: "delivery",
          width: 200,
        },
      ],
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportDetails}
                hideAddButton={true}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Bolt Seal</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <FilterComponent
                tableData={tableData}
                filterAPI={exportDetails}
                filterName={"report_bolt_seal"}
                searchButtonText={"Export"}
                defaultOpen
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                isPreview={true}
                previewFunction={previewFunction}
              />
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination
                scroll={{ y: "max-content" }}
                bordered
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default BoltSealReport;
