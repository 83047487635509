import { Col, DatePicker, Form, Select, message } from "antd";
import { GET_BUYER_ENDPOINT, GET_USERS_LIST_ENDPOINT } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useEffect, useState } from "react";
const { Option } = Select;
const { RangePicker } = DatePicker;

const TNATodoFilter = ({ initialTableData }) => {
  const [buyerList, setBuyerList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentRole, setCurrentRole] = useState(
    localStorage.getItem("roleInfo") 
      ? JSON.parse(localStorage.getItem("roleInfo"))
      : null,
  );

  useEffect(() => {
    getBuyerListData();
    if(currentRole?.name === "Super-Admin" || currentRole?.name === "Admin" || currentRole?.name === "IT"){
      getAllUsers();
    }
  }, []);

  const getBuyerListData = async (filterValues) => {
    const query = `${GET_BUYER_ENDPOINT}`;
    const bodyData = {
      per_page: 10,
      name: filterValues?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBuyerList(res?.data?.data?.data || []);
    }
  };

  const getAllUsers = async (filterValues) => {
    setLoading(true);

    const query = `${GET_USERS_LIST_ENDPOINT}`;
    const payload = {
      per_page: 100,
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      setUserList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  return (
    <>
      <Col span={6}>
        <Form.Item label="Task Filter" name="todoTask">
          <Select
              mode="multiple"
              showSearch
              placeholder="Select for search"
              optionFilterProp="children"
              size="small"
              allowClear
              onClear={() => getBuyerListData()}
            >
                <Option value="1">Todo</Option>
                <Option value="2">Hold</Option>
            </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="As on Date" name="date_range">
          <RangePicker />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            placeholder="Select for Search"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getBuyerListData({ name: value })}
            allowClear
            onClear={() => getBuyerListData()}
          >
            {buyerList?.length &&
              buyerList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      
      {(currentRole?.name === 'Super-Admin' || currentRole?.name === 'Admin' || currentRole?.name === 'IT') && (
          <Col span={4}>
            <Form.Item
              name="responsible_person_id"
              placeholder="Select Responsible Person"
              label="Select Responsible Person"
              rules={[
                {
                  required: false,
                  message: "Responsible Person to is Required",
                },
              ]}
            >
              <Select
                placeholder="Select Responsible Person"
                showSearch
                optionFilterProp="children"
                style={{width: "100%"}}
                onSearch={(value) => getAllUsers({name: value})}
                size="small"
              >
                {userList?.map((item) => (
                  <Option key={item.id} value={item.id}>{item?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
      )}
    </>
  );
};

export default TNATodoFilter;
