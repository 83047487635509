import { useCallback, useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import {
  message,
  Table,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";
import {
  getData,
  deleteData,
  postData,
  putData,
} from "../../../apiServices/common";
import { TNA_PLAN_BASE } from "../../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { cglFormatDate, hasPermission } from "../../../utils/functions";
import { NO_PERMISSION_REQUIRED } from "../../../routes/permissions";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import HandleNotification from "common/Notification";

const TNAPlan = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Antd
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllTNAPlans = useCallback(async (filterValues) => {
    const { name } = filterValues || {};

    setLoading(true);

    const query = `${TNA_PLAN_BASE}?per_page=1000`;
    const bodyData = {
      ...(name && { name: name }),
    };

    const response = await getData(query, false, bodyData);
    if (response && response?.status === 200) {
      setLoading(false);
      setMetaData({
        defaultPageSize: response?.data?.data?.per_page,
        current: response?.data?.data?.current_page,
        defaultCurrent: response?.data?.data?.current_page,
        total: response?.data?.data?.total,
      });
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting TNA data");
    }
  }, []);

  // Effects
  useEffect(() => {
    getAllTNAPlans(filterData);
  }, [refresh, filterData, getAllTNAPlans]);

  const openAddPlanPage = () => {
    navigate("/tanda/add-plan");
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const deleteSelectedItem = async (id) => {
    const query = `${TNA_PLAN_BASE}/${id}`;
    const response = await deleteData(query);

    if (response) {
      HandleNotification(
        "success",
        "bottomRight",
        "TNA Deleted Successfully!",
        null,
      );
      getAllTNAPlans();
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        // {
        //   label: (
        //     <Link to={`/tanda/view-task/${data.id}`}>
        //       <EyeOutlined /> View
        //     </Link>
        //   ),
        //   key: 0,
        //   disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        // },
        {
          label: (
            <Link to={`/tanda/update-plan/${data?.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: 1,
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                deleteSelectedItem(data?.id);
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                <DeleteOutlined /> Delete
              </span>
            </Popconfirm>
          ),
          key: 2,
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
      ]}
    />
  );

  const tableColumns = [
    {
      title: "Sales Contract No",
      dataIndex: ["sales_contract", ["reference_no"]],
      key: "sales_contract_no",
    },
    {
      title: "Template Title",
      dataIndex: ["tna_template", "title"],
      key: "template_title",
    },
    {
      title: "Description",
      dataIndex: ["tna_template", "description"],
      key: "description",
    },
    {
      title: "Calculation Method",
      dataIndex: ["tna_template", "calculation_method"],
      key: "calculation_method",
    },
    {
      title: "Is Common",
      dataIndex: ["tna_template", "is_common"],
      key: "is_common",
      render: (_, record) => {
        return <div>{record?.is_common === 1 ? "Yes" : "No"}</div>;
      },
    },
    {
      title: "Buyer",
      dataIndex: ["sales_contract", "buyer", "name"],
      key: "buyer_name",
    },
    {
      title: "Buying Office",
      dataIndex: ["sales_contract", "buying_office", "name"],
      key: "buying_office_name",
    },
    {
      title: "PCD Date",
      dataIndex: ["sales_contract", "style_details_pcd", "pcd"],
      key: "pcd_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={openAddPlanPage}
                disabled={!hasPermission([NO_PERMISSION_REQUIRED])}
                addButtonText={
                  hasPermission([NO_PERMISSION_REQUIRED])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                hideFilterButton
              />
            }
            title={<Title level={5}>TNA</Title>}
          />
        }
        content={
          <>
            {/* <FilterComponent
              tableData={tableData}
              filterAPI={getAllTNAPlans}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            /> */}

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                // pagination={{
                //   defaultPageSize: metaData?.defaultPageSize,
                //   current: metaData?.current,
                //   onChange: (newPage) => {
                //     changePage(newPage);
                //   },
                //   defaultCurrent: metaData?.defaultCurrent,
                //   total: metaData?.total,
                //   showSizeChanger: false,
                // }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default TNAPlan;
