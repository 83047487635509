import dayjs from "dayjs";

const generateDateStrings = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const dateStrings = [];

  let current = start;
  while (current.isBefore(end) || current.isSame(end)) {
    dateStrings.push(current.format("YYYY-MM-DD"));
    current = current.add(1, "day");
  }

  return dateStrings;
};

const fillUpUnplanned = (output, calendarHeader, date) => {
  const plan_list = output.map((line) => {
    // calendarHeader.days [1,2,....31]
    const lineList = calendarHeader.days.map(({ day, weekStatus }) => {
      const dayDetails = line.lines.find((line) => {
        const startDay = dayjs(line.start).date();
        const endDay = dayjs(line.end).date();

        return day >= startDay && day <= endDay;
      });

      if (dayDetails) {
        return {
          id: dayDetails.id,
          title: dayDetails?.title,
          duty: generateBarBaseOnDate(dayDetails.start, dayDetails.end),
          // dutyStatus: getDutyStatus(dayDetails),
          delivery_status: dayDetails.delivery_status,
          is_apply_learning_curve: dayDetails.is_apply_learning_curve === "1",
          startDate: dayDetails.start,
          endDate: dayDetails.end,
          weekStatus,
          details: dayDetails.details,
        };
      }

      return {
        id: null,
        title: "",
        duty: 1,
        // dutyStatus: "",
        delivery_status: "unplanned",
        is_apply_learning_curve: false,
        startDate: dayjs(date).set("date", day).format("YYYY-MM-DD"),
        endDate: dayjs(date).set("date", day).format("YYYY-MM-DD"),
        weekStatus,
        details: [],
      };
    });

    return {
      line_id: line.line_id,
      line_name: line.line_name,
      lines: lineList,
    };
  });

  return plan_list;
};

export function transformData(
  input,
  date,
  weekend = [
    // "2024-11-01",
    // "2024-11-02",
    // "2024-11-08",
    // "2024-11-09",
    // "2024-11-15",
    // "2024-11-16",
    // "2024-11-22",
    // "2024-11-23",
    // "2024-11-29",
    // "2024-11-30",
  ],
  holiday = [
    // "2024-11-19"
  ],
  workOnHolidayOrWeekend = [
    // "2024-11-15"
  ],
) {
  const calendarHeader = generateCalendar(date);

  calendarHeader.days = calendarHeader.days.map((day) => {
    const date = dayjs().set("date", day).format("YYYY-MM-DD");

    const weekday = dayjs(date).format("ddd");
    // weekend, holiday & workOnHolidayOrWeekend other unplanned
    const weekStatus = workOnHolidayOrWeekend.includes(date)
      ? "work_on_weekend"
      : holiday.includes(date)
      ? "holiday"
      : weekend.includes(date)
      ? "weekend"
      : "unplanned";

    return { day, weekday: weekday.slice(0, 1), weekStatus };
  });

  const output = [];

  input.forEach((item) => {
    // Find if this resourceId already exists in output
    let existingLine = output.find((line) => line.line_id === item.resourceId);

    if (!existingLine) {
      // If not, create a new entry
      existingLine = {
        line_id: item.resourceId,
        line_name: item.resourceName,
        title: item.title,
        lines: [],
      };
      output.push(existingLine);
    }

    // Create the line object for the "lines" array
    const lineDetails = {
      id: item.id,
      title: item.title,
      line_id: item.resourceId,
      start: item.start,
      end: item.end,
      is_apply_learning_curve: item.is_apply_learning_curve,
      delivery_status: item.delivery_status,
      details: item.details,
    };

    // Push the new line details into the lines array
    existingLine.lines.push(lineDetails);
  });

  return {
    calendarHeader,
    calendarBody: removeDuplicateEntries(
      fillUpUnplanned(output, calendarHeader, date),
    ),
  };
}

function removeDuplicateEntries(data) {
  return data.map((line) => {
    // Filter unique objects based on startDate and endDate
    const uniqueDuties = [];
    const uniqueEntries = {};

    line.lines.forEach((entry) => {
      const key = `${entry.startDate}-${entry.endDate}`;
      if (!uniqueEntries[key]) {
        uniqueEntries[key] = entry;
        uniqueDuties.push(entry);
      }
    });

    return { ...line, lines: uniqueDuties };
  });
}

// Function to generate Calendar the date object
export const generateCalendar = (value) => {
  const date = dayjs(value);
  const daysInMonth = date.daysInMonth();
  const month = date.format("MMMM");
  const year = date.format("YYYY");

  // Array of days in the month (1 to 31)
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  // Array of weekdays for the entire month (31 days)
  const weeks = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = dayjs(`${year}-${date.format("MM")}-${day}`);
    const weekday = currentDate.format("dd");
    weeks.push(weekday.charAt(0));
  }

  // Resulting date object
  const dateObject = {
    month,
    year,
    days,
    // weeks,
  };

  return dateObject;
};

// Helper function to determine the duty status
const getDutyStatus = (day) => {
  // console.log(day);
};

// Helper function to determine the delivery status
const getDeliveryStatus = (day) => {
  const startDate = dayjs(day.start);
  const endDate = dayjs(day.end);

  const startDetails = dayjs(day.details[0].details_date);
  const endDetails = dayjs(day.details[day.details.length - 1].details_date);

  if (startDate.isSame(startDetails) && endDate.isSame(endDetails)) {
    return "on_time";
  }

  if (startDate.isSame(startDetails) && endDate.isBefore(endDetails)) {
    return "delivery_early";
  }

  if (startDate.isSame(startDetails) && endDate.isAfter(endDetails)) {
    return "delivery_delay";
  }
  return "unplanned";
};

// generate bar base on start and end date ("2024-02-01", "2024-02-29") grid col span dynamic
const generateBarBaseOnDate = (startDate, endDate) => {
  // use dayjs to calculate the difference between two dates
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const diffDays = end.diff(start, "day") + 1;
  return diffDays;
};

// all events

// Start dragging and set the index of dragged item
export const handleDragStart = (
  e,
  {
    line_index,
    task_index,
    draggedIndex,
    line,
    task: date,
    calendarData,
    setDraggedIndex,
    setCalendarData,
  },
) => {
  e.dataTransfer.setData("text/plain", "anything");
  e.dataTransfer.effectAllowed = "move";

  setDraggedIndex(`${line_index}-${task_index}`);
};

// Handle dropping by updating the state with new sorted items
export const handleDrop = (
  e,
  {
    line_index,
    task_index,
    draggedIndex,
    line,
    task,
    calendarData,
    setDraggedIndex,
    setCalendarData,
  },
) => {
  const [l_index, t_index] = draggedIndex.split("-");
  const draggedLineIndex = parseInt(l_index);
  const draggedTaskIndex = parseInt(t_index);

  if (line_index === draggedLineIndex && task_index === draggedTaskIndex) {
    alert("Cannot drop here");
    return;
  }

  const newCalendarData = JSON.parse(JSON.stringify(calendarData));

  const draggedLine = newCalendarData.calendarBody[draggedLineIndex];
  const draggedTask = draggedLine.lines[draggedTaskIndex];

  const targetLine = newCalendarData.calendarBody[line_index];
  const targetTask = targetLine.lines[task_index];

  console.log("dragged index", draggedLineIndex, draggedTaskIndex);
  console.log("target index", line_index, task_index);

  // console.log("draggedLine", draggedLine);
  // console.log("draggedTask", draggedTask);
  // console.log("targetLine", targetLine);
  // console.log("targetTask", targetTask);
  // Check if the target task is unplanned and has details
  if (
    targetTask.delivery_status !== "unplanned" &&
    targetTask?.details?.length > 0
  ) {
    alert("Cannot drop here");
    return;
  }
  // Check if the target task is unplanned and has no details
  if (
    targetTask.delivery_status === "unplanned" &&
    targetTask?.details?.length === 0
  ) {
    // check if duty
    if (targetTask.duty === 1) {
      const unplannedTask = [];
      for (let i = task_index; i < task_index + draggedTask.duty; i++) {
        const tasked = targetLine.lines[i];
        if (
          tasked &&
          tasked.duty === 1 &&
          tasked.delivery_status === "unplanned" &&
          tasked.details.length === 0
        ) {
          unplannedTask.push(tasked);
        } else {
          unplannedTask.push(false);
        }
      }
      const isAllEmpty = unplannedTask.every((item) => item !== false);
      if (isAllEmpty) {
        if (draggedLineIndex === line_index && draggedTaskIndex > task_index) {
          const dates = generateDateStrings(
            draggedTask.startDate,
            draggedTask.endDate,
          );

          const targetData = dates.map((date) => {
            return {
              ...unplannedTask[0],
              startDate: date,
              endDate: date,
            };
          });

          // swap value
          const temp = targetLine.lines[task_index];
          targetLine.lines[task_index] = {
            ...draggedTask,
            startDate: unplannedTask[0].startDate,
            endDate: unplannedTask[unplannedTask.length - 1].endDate,
          };
          draggedLine.lines[draggedTaskIndex] = temp;

          draggedLine.lines.splice(draggedTaskIndex, 1, ...targetData);

          draggedLine.lines.splice(task_index + 1, draggedTask.duty - 1);

          // console.log("newCalendarData", newCalendarData);
          setCalendarData(newCalendarData);
          setDraggedIndex(null);
        } else {
          // console.log("1 targetLine", targetLine);
          targetLine.lines.splice(task_index, draggedTask.duty);

          // console.log("2 targetLine", targetLine);
          targetLine.lines.splice(task_index, 0, {
            ...draggedTask,
            startDate: unplannedTask[0].startDate,
            endDate: unplannedTask[unplannedTask.length - 1].endDate,
          });

          const dates = generateDateStrings(
            draggedTask.startDate,
            draggedTask.endDate,
          );

          const targetData = dates.map((date) => {
            return {
              ...unplannedTask[0],
              startDate: date,
              endDate: date,
            };
          });

          // console.log("1 draggedLine", draggedLine);
          draggedLine.lines.splice(draggedTaskIndex, 1);
          // console.log("2 draggedLine", draggedLine);
          draggedLine.lines.splice(draggedTaskIndex, 0, ...targetData);

          // console.log("newCalendarData", newCalendarData);

          setCalendarData(newCalendarData);
          setDraggedIndex(null);
        }
      } else {
        alert("Cannot drop here");
      }
    } else {
      alert("Cannot drop here");
    }
  } else {
    alert("Cannot drop here");
  }
};

// Prevent default drag over behavior
export const handleDragOver = (e) => {
  e.preventDefault();

  // Set the drop effect to move
  e.dataTransfer.dropEffect = "move";
};

export const handleDragEnd = (e) => {
  e.preventDefault();
  console.log("drag end");
};

export const res = {
  code: 200,
  status: "success",
  message: "Plan List retrieved",
  data: [
    {
      id: 1,
      start: "2024-11-05",
      end: "2024-11-12",
      resourceId: 20,
      resourceName: "Line-1",
      title: "style nov 3 - 1",
      is_apply_learning_curve: "1",
      delivery_status: "on_time",
      details: [
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 8,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 99,
          pp_initiate_id: 4,
          day_id: 2,
          details_date: "2024-11-06",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.55",
          learning_curve_qty: 1113,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.55",
          plan_curve_qty: 1113,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 100,
          pp_initiate_id: 4,
          day_id: 3,
          details_date: "2024-11-07",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.65",
          learning_curve_qty: 1316,
          plan_curve_mh: 11,
          plan_curve_efficiency: "0.65",
          plan_curve_qty: 1316,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 101,
          pp_initiate_id: 4,
          day_id: 4,
          details_date: "2024-11-08",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1377,
          plan_curve_mh: 11,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1377,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 102,
          pp_initiate_id: 4,
          day_id: 5,
          details_date: "2024-11-09",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1377,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1377,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 103,
          pp_initiate_id: 4,
          day_id: 6,
          details_date: "2024-11-10",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1377,
          plan_curve_mh: 8,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1377,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 104,
          pp_initiate_id: 4,
          day_id: 7,
          details_date: "2024-11-11",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1377,
          plan_curve_mh: 8,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1377,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 105,
          pp_initiate_id: 4,
          day_id: 8,
          details_date: "2024-11-12",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1235,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1235,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
      ],
    },
    {
      id: 2,
      start: "2024-11-21",
      end: "2024-11-28",
      resourceId: 20,
      resourceName: "Line-1",
      title: "style nov 3 - 2",
      is_apply_learning_curve: "1",
      delivery_status: "delivery_delay",
      details: [
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 99,
          pp_initiate_id: 4,
          day_id: 2,
          details_date: "2024-11-06",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.55",
          learning_curve_qty: 1113,
          plan_curve_mh: 11,
          plan_curve_efficiency: "0.55",
          plan_curve_qty: 1113,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 100,
          pp_initiate_id: 4,
          day_id: 3,
          details_date: "2024-11-07",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.65",
          learning_curve_qty: 1316,
          plan_curve_mh: 11,
          plan_curve_efficiency: "0.65",
          plan_curve_qty: 1316,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 101,
          pp_initiate_id: 4,
          day_id: 4,
          details_date: "2024-11-08",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1377,
          plan_curve_mh: 11,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1377,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 102,
          pp_initiate_id: 4,
          day_id: 5,
          details_date: "2024-11-09",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1377,
          plan_curve_mh: 11,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1377,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 103,
          pp_initiate_id: 4,
          day_id: 6,
          details_date: "2024-11-10",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1377,
          plan_curve_mh: 11,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1377,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 104,
          pp_initiate_id: 4,
          day_id: 7,
          details_date: "2024-11-11",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1377,
          plan_curve_mh: 11,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1377,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 105,
          pp_initiate_id: 4,
          day_id: 8,
          details_date: "2024-11-12",
          learning_curve_mh: 11,
          learning_curve_efficiency: "0.68",
          learning_curve_qty: 1235,
          plan_curve_mh: 11,
          plan_curve_efficiency: "0.68",
          plan_curve_qty: 1235,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
      ],
    },
    {
      id: 3,
      start: "2024-11-01",
      end: "2024-11-10",
      resourceId: 19,
      resourceName: "Line-2",
      title: "style nov 3 - 3",
      is_apply_learning_curve: "1",
      delivery_status: "delivery_early",
      details: [
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
        {
          id: 98,
          pp_initiate_id: 4,
          day_id: 1,
          details_date: "2024-11-05",
          learning_curve_mh: 10,
          learning_curve_efficiency: "0.45",
          learning_curve_qty: 828,
          plan_curve_mh: 10,
          plan_curve_efficiency: "0.45",
          plan_curve_qty: 828,
          created_at: "2024-11-03T16:40:51.000000Z",
          updated_at: "2024-11-03T16:40:51.000000Z",
        },
      ],
    },
  ],
};
