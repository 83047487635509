import {
    CREATE_NEW_USER_ROLE,
    DELETE_USER_ROLE,
    UPDATE_USER_ROLE_DETAIL,
    GET_USER_ROLE_DETAIL,
    GET_USER_ROLE_LIST,
    GET_USER_PERMISSION_LIST,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    GET_USER_PERMISSIONS,
    CREATE_USER_ROLE_ENDPOINT,
    DELETE_USER_ROLE_ENDPOINT,
    EDIT_USER_ROLE_ENDPOINT,
    GET_USERS_ROLE_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetUserRoleList = (page, per_page, name) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_USERS_ROLE_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && { name: name }),
                },
            })
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_USER_ROLE_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onGetUserPermissionList = (page, per_page, name) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_USER_PERMISSIONS}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && { name: name }),
                },
            })
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_USER_PERMISSION_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onGetUserRoleDetails = (userRoleId) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`api/userRoles/${userRoleId}/edit`)

            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_USER_ROLE_DETAIL, payload: data.data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "UserRole Updated Successfully",
                    });
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('success', 'bottomRight', error.message, null)

            });
    };
};

export const onCreateUserRole = (userRole) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_USER_ROLE_ENDPOINT}`, userRole)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_USER_ROLE, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "UserRole Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', data?.data?.message, null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', data?.data?.message, null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', error?.message, null)
            });
    };
};

export const onUpdateSelectedUserRole = (userRole) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_USER_ROLE_ENDPOINT}/${userRole.id}`, userRole)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_USER_ROLE_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "UserRole Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', data?.data?.message, null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', data?.data?.message, null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', error?.message, null)
            });
    };
};

export const onDeleteUserRole = (userRoleId) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_USER_ROLE_ENDPOINT}/${userRoleId}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_USER_ROLE, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted userRole Successfully",
                    });

                    HandleNotification('success', 'bottomRight', data?.data?.message, null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', data?.data?.message, null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', error?.message, null)
            });
    };
};



