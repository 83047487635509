import { Button, Col, Form, Input, Row, Select, Space } from "antd";

const STATUS_LIST = [
  { id: 1, label: "Unpaid", value: "unpaid" },
  { id: 2, label: "Partial Paid", value: "partial_paid" },
  { id: 3, label: "Fully Paid", value: "fully_paid" },
];

const InvoiceTableFilterFields = (props) => {
  // Props
  const { onClearSearch } = props;

  return (
    <Row gutter={6}>
      <Col span={4}>
        <Form.Item name="invoice_no" label="Invoice Number">
          <Input size="small" placeholder="Invoice Number" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item name="fdbc_number" label="Bank Reference Number">
          <Input size="small" placeholder="Bank Reference Number" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item name="invoice_value" label="Invoice Value">
          <Input size="small" placeholder="Invoice Value" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item name="realization_value" label="Real Value">
          <Input size="small" placeholder="Real Value" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item name="status" label="Status">
          <Select
            showSearch
            placeholder="Please Select Status"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.label
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            size="small"
            allowClear
          >
            {STATUS_LIST?.length > 0 &&
              STATUS_LIST?.map((statusItem) => {
                return (
                  <Select.Option
                    value={statusItem?.value}
                    key={statusItem?.id}
                    label={statusItem?.label}
                  >
                    {statusItem?.label}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item label=" ">
          <Space>
            <Button size="small" type="primary" htmlType="submit">
              Search
            </Button>
            <Button onClick={onClearSearch} size="small">
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InvoiceTableFilterFields;
