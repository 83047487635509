import {
  Card,
  Form,
  Input,
  Select,
  Divider,
  Space,
  Button,
  InputNumber,
  Upload
} from "antd";
import "../../../../PurchaseOrder/AddOrUpdateStyle/POTable.css";
import {
  PlusCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  CloudUploadOutlined,
  EyeOutlined
} from "@ant-design/icons";
import { GET_PO_INFO } from "../../../../../apiServices/API_ENDPOINTS";
import {
  postData,
  getData,
} from "../../../../../apiServices/common";
import { alertPop } from "../../../../../apiServices/common/helper";
import { useEffect } from "react";
import { useState } from "react";
import {
  ADD_EXPORT_GOODS_DESCRIPTION,
  EXPORT_DETAIL_GOODS_DESCRIPTIONS_LIST,
  EXPORT_DETAILS_DOC_UPLOAD
} from "../../../../../apiServices/API_ENDPOINTS";
import { useParams } from "react-router";
import HandleNotification from "../../../../../common/Notification";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";

const ExportPOTable = (props) => {
  // Props
  const {
    poDetailsData,
    salesContractDetails,
    setPoDetailsData,
    poDetailsForm,
    salesContractId,
    form,
    lcdetailsList, 
    setLcdetailsList,
    isView,
    poFilesList,
    setPoFilesList
  } = props;

  const [currentRole, setCurrentRole] = useState(
    localStorage.getItem("roleInfo") 
      ? JSON.parse(localStorage.getItem("roleInfo"))
      : null,
  );

  // Antd
  const { Option } = Select;
  const { exportDetailId } = useParams();
  const [addedGoodsDescription, setAddedGoodsDescription] = useState(null);
  const [goodsDescriptions, setGoodsDescription] = useState(null);
  const [subStyle, setSubStyle] = useState([]);

  const api_base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");


  const getDescriptionsList = async (filterValues) => {
    const query = `${EXPORT_DETAIL_GOODS_DESCRIPTIONS_LIST}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 10000,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGoodsDescription(response?.data?.data);
    }
  };

  useEffect(() => {
    getDescriptionsList();
  }, []);

  const onGoodsDescriptionChange = (event) => {
    setAddedGoodsDescription(event.target.value);
  };

  const onChangeFileUpload = (info, index) => {
    if (info.file.status === 'done' && info.file.response.code === 200) {
      const response = info.file.response.data;
      const newFilesList = [...poFilesList];
      newFilesList[index] = response.id;
      setPoFilesList(newFilesList);

      // Update the document property of the corresponding obj in poDetailsData
      const updatedPoDetailsData = [...poDetailsData];
      updatedPoDetailsData[index].document = response.document;
      setPoDetailsData(updatedPoDetailsData);
    }
  };
  

  const addNewGoodsDescription = async (event) => {
    event.preventDefault();
    const query = `${ADD_EXPORT_GOODS_DESCRIPTION}`;
    const bodyData = {
      goods_description: addedGoodsDescription,
      export_detail_id: exportDetailId ? Number(exportDetailId) : null,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setAddedGoodsDescription(null);
      HandleNotification("success", "Reason added successfully");
      getDescriptionsList();
    }
  };

  const onChangeLCValue = (value, index, keyName) => {
    // Copy poDetailsData state array
    const lcDetailsDataCopy = [...lcdetailsList];
    
    const foundLCItem = lcDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );
    foundLCItem[keyName] = value;

    setLcdetailsList(lcDetailsDataCopy);
  };


  const copyRow = (index) => {
    const newData = [...poDetailsData];
    const newFilesList = [...poFilesList];
    newFilesList.push(null);
    newData.push({
      unique_id: newData[newData.length - 1].unique_id + 1,
    });

    setPoDetailsData(newData);
    setPoFilesList(newFilesList);
  };

  const getPOInfo = async (sales_contract_id, po_map_id, style_id, index) => {
    const bodyData = {
      sales_contract_id,
      po_map_id,
      style_id,
    };

    //const response = await getData(GET_PO_INFO, false, bodyData);
    const response = await postData(`${GET_PO_INFO}`, bodyData);
    
    if (response && response?.code === 200) {
      const masterData = response?.data;

      const formValues = poDetailsForm.getFieldsValue();
      
      const calculatedBalanceQty =
        masterData?.sales_order_qty - masterData?.ship_qty;

      // Change po details form values
      formValues.sales_order_qty_list[index].sales_order_qty =
        masterData?.sales_order_qty;
      formValues.carton_qty_list[index].carton_qty = masterData?.carton_qty;
      formValues.unit_price_list[index].unit_price = masterData?.unit_price;
      formValues.ship_qty_list[index].ship_qty = masterData?.ship_qty;
      formValues.goods_description_list[index].goods_description = masterData?.goods_description;
      formValues.ship_qty_unit_list[index].ship_qty_unit = masterData?.ship_qty_unit;
      formValues.ship_value_list[index].ship_value = masterData?.ship_value;
      formValues.balance_qty_list[index].balance_qty = calculatedBalanceQty;
      formValues.balance_qty_unit_list[index].balance_qty_unit =
        masterData?.balance_qty_unit;
      formValues.balance_value_list[index].balance_value =
        calculatedBalanceQty * masterData?.unit_price;
      
      if (formValues.export_po_map_doc_lists) {
        formValues.export_po_map_doc_lists[index].document =
          masterData?.document;
      }

      poDetailsForm.setFieldsValue(formValues);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  };

  const qtyUnit = [
    { id: 1, name: "Piece" },
    { id: 2, name: "Dozen" },
    { id: 3, name: "Blister" },
  ];


  const removeRow = (unique_id) => {
    const newData = [...poDetailsData];
    const newFilesList = [...poFilesList];

    // If only item remains then don't delete
    if (newData.length === 1) {
      return;
    }

    // Find index and delete item
    const index = newData.findIndex((item) => item.unique_id === unique_id);
    if (index > -1) {
      newData.splice(index, 1);
      newFilesList.splice(index, 1);
    }
    setPoDetailsData(newData);
    setPoFilesList(newFilesList);
  };

  console.log('poFilesList', poFilesList);

  const onSelectStyle = (value, index) => {

    console.log('value', value);
    console.log('index', index);

    const formValues = poDetailsForm.getFieldsValue();

    console.log('formValues', formValues);

    // Reset substyle field data
    const substyle_data = formValues?.sub_style_numbers_list;

    console.log('substyle_data', substyle_data);

    const currentSubstyleObj = isArrayAndHasValue(substyle_data) ? substyle_data[index] : null;

    console.log('currentSubstyleObj', currentSubstyleObj);

    if(currentSubstyleObj && currentSubstyleObj?.sub_style_id) {
      console.log('dhukse');
      currentSubstyleObj["sub_style_id"] = null;
      poDetailsForm.setFieldsValue({
        sub_style_numbers_list: currentSubstyleObj
      });
    }
    
    const po_map_id = formValues.po_numbers_list[index].po_map_id;

    if (po_map_id || po_map_id === 0) {
      getPOInfo(salesContractId, po_map_id, value, index);
    }
  };

  const onChangeCartonQty = (e, index) => {
    const formValues = poDetailsForm.getFieldsValue();

    const totalCartonQuantity = formValues?.carton_qty_list?.reduce(
      (acc, item) => acc + Number(item?.carton_qty),
      0,
    );
    form.setFieldsValue({ carton_quantity: totalCartonQuantity });
  }

  const onChangeShipQty = (value, index) => {
    const formValues = poDetailsForm.getFieldsValue();

    // Input Values
    const shipQtyInput = value;
    const orderQty = formValues?.sales_order_qty_list[index]?.sales_order_qty;
    const unitPrice = formValues?.unit_price_list[index]?.unit_price;

    // Calculated Values
    const calculated_ship_value = shipQtyInput * unitPrice;
    const calculated_balance_qty = orderQty - shipQtyInput;
    const calculated_balance_value = calculated_balance_qty * unitPrice;

    const calculated_ship_value_formatted = calculated_ship_value ? parseFloat(calculated_ship_value.toFixed(2)) : 0;
    const calculated_balance_value_formatted = calculated_balance_value ? parseFloat(calculated_balance_value.toFixed(2)) : 0;

    // Assing Values to form fields
    formValues.ship_value_list[index].ship_value = calculated_ship_value_formatted;
    formValues.balance_qty_list[index].balance_qty = calculated_balance_qty;
    formValues.balance_value_list[index].balance_value =
    calculated_balance_value_formatted;
    poDetailsForm.setFieldsValue(formValues);

    // Calculate total quantity and invoice value for main form
    const totalQuantity = formValues?.ship_qty_list?.reduce(
      (acc, item) => acc + Number(item?.ship_qty),
      0,
    );
    const invoiceValue = formValues?.ship_value_list?.reduce(
      (acc, item) => acc + Number(item?.ship_value),
      0,
    );

    // Set form values
    form.setFieldsValue({ total_quantity: totalQuantity });    
    form.setFieldsValue({ invoice_value: invoiceValue });
  };

  const onSelectShipmentUnit = (value, index) => {
    const formValues = poDetailsForm.getFieldsValue();
    const balance_qty_unit_list = formValues?.balance_qty_unit_list;
    balance_qty_unit_list[index].balance_qty_unit = value;
    poDetailsForm.setFieldsValue({"balance_qty_unit_list": balance_qty_unit_list});
  }

  const getSubstyle= async (value) => {
    //const cutting_date_watch = Form.useWatch("cutting_date", form);

    let res = await getData(`/api/getSubStyle/${value}`);    
    if (res) {
      setSubStyle(res?.data?.data);
     
    }
  };
  
  const getSubstyleList = (index) => {
    // Get all form values
    const poDetailsFormValues = poDetailsForm.getFieldsValue();
    const style_obj = poDetailsFormValues?.style_numbers_list?.[index];
    const style_id = style_obj?.style_id;
    const foundStyleItem = salesContractDetails?.styleDetail?.find((item) => item?.style_info?.id === style_id);
    const sub_style_list = foundStyleItem?.style_info?.sub_style;

    return isArrayAndHasValue(sub_style_list) ? sub_style_list : [];
  };

  const openFileInNewTab = (file_url) => {
    const url = `${api_base_url}/${file_url}`;
    window.open(url, "_blank");
  };

  return (
    <div className="">
      <Card title="Export PO Table">
        <div className="custome-table" style={{ paddingBottom: "20px" }}>
          <table border="1">
            <thead>
                <tr>
                  <th colSpan={7} className="bc-orange text-center">
                    
                  </th>
                  <th colSpan={3} className="bc-orange text-center border-left-right-black">
                    Shipment
                  </th>
                  <th colSpan={3} className="bc-orange text-center border-left-right-black">
                    Balance
                  </th>
                  <th colSpan={1} className="bc-orange text-center border-left-right-black"></th>
                  <th colSpan={2} className="bc-orange text-center">
                    Action
                  </th>
                </tr>
                <tr>
                <th key="po_number" className="bc-java ">
                  PO Number
                </th>
                <th key="style_number" className="bc-java ">
                  Style Number
                </th>
                {currentRole.name !== "Commercial & Shipping" && currentRole.name !== "Head of Commercial & Shipping" && (
                  <th key="style_number" className="bc-java ">
                  Sub Style Number
                </th>
                )}
                

                

                <th key="goods_description" className="bc-java ">
                  Goods Desc.
                </th>
                <th key="carton_qty" className="bc-java ">
                  Carton Qty.
                </th>
                <th key="order_qty" className="bc-java ">
                  Order Qty.
                </th>
                <th key="unit_price" className="bc-java ">
                  Unit Price{" "}
                </th>
                <th key="ship_qty" className="bc-java border-left-black">
                  Qty.
                </th>
                <th key="ship_qty_unit" className="bc-java ">
                  Qty Unit.
                </th>
                <th key="ship_value" className="bc-java ">
                  Value
                </th>
                <th key="balance_qty" className="bc-java border-left-black">
                  Qty.
                </th>
                <th key="balance_qty_unit" className="bc-java ">
                  Qty. Unit
                </th>
                <th key="balance_value" className="bc-java border-right-black">
                  Value
                </th>
                <th key="header_add_row" className="bc-java border-right-black">
                  Document
                </th>
                <th key="header_add_row" className="bc-java ">
                  Add
                </th>
                <th key="header_delete_row" className="bc-java ">
                  Delete
                </th>
              </tr>
            </thead>

            <tbody className="scrollable-table-container">
              {poDetailsData && poDetailsData?.length > 0 ? (
                <>
                  {poDetailsData.map((obj, index) => {
                    return (
                      <tr key={`table_parent_key_${index}`}>
                        <td className="t-body sticky" style={{ width: "10%" }}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please Select PO Number!",
                              },
                            ]}
                            name={["po_numbers_list", index, "po_map_id"]}
                            style={{ marginBottom: "unset" }}
                          >
                            <Select
                              name={`internal_po_${obj?.unique_id}`}
                              dropdownStyle={{ minWidth: 50 }}
                              // value={obj?.original_po || obj?.internal_po}
                              showSearch
                              placeholder="Select PO"
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                              size="small"
                              disabled={isView}
                            >
                              {salesContractDetails?.purchase_orders?.length &&
                                salesContractDetails?.purchase_orders?.map(
                                  (poItem, index) => {
                                    return (
                                      <Option
                                        value={poItem.id || undefined}
                                        key={`internal_po_key_${index}`}
                                      >
                                        {poItem?.original_po ||
                                          poItem?.internal_po}
                                      </Option>
                                    );
                                  },
                                )}
                            </Select>
                          </Form.Item>
                        </td>
                        <td className="t-body sticky">
                          <Form.Item
                            rules={[
                              {
                                required: false,
                                message: "Please Select Style Number!",
                              },
                            ]}
                            name={["style_numbers_list", index, "style_id"]}
                            style={{ marginBottom: "unset" }}
                          >
                            <Select
                              name={`style_id_${obj?.style_id}`}
                              dropdownStyle={{ minWidth: 50 }}
                              value={obj?.style_id || undefined}
                              showSearch
                              placeholder="Select Style Number"
                              onChange={(value) => {
                                if (value) {
                                  onSelectStyle(value, index);
                                  getSubstyle(value);
                                }
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                if (option?.children != null) {
                                  return option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                                }
                              }}
                              size="small"
                              disabled={isView}
                            >
                              {salesContractDetails?.styleDetail?.length &&
                                salesContractDetails?.styleDetail?.map(
                                  (contractItem, index) => {
                                    return (
                                      <Option
                                        value={
                                          contractItem?.style_id || undefined
                                        }
                                        key={`style_id_key_${index}`}
                                      >
                                        {contractItem?.style_info?.style_no}
                                      </Option>
                                    );
                                  },
                                )}
                            </Select>
                          </Form.Item>
                        </td>
                        {currentRole.name !== "Commercial & Shipping" && currentRole.name !== "Head of Commercial & Shipping" && (
                        <td className="t-body sticky">
                          <Form.Item
                            rules={[
                              {
                                required: false,
                                message: "Please Select Sub Style Number!",
                              },
                            ]}
                            name={[
                              "sub_style_numbers_list",
                              index,
                              "sub_style_id",
                            ]}
                            style={{ marginBottom: "unset" }}
                          >
                            <Select
                              name={`sub_style_id_${obj?.sub_style_id}`}
                              dropdownStyle={{ minWidth: 50 }}
                              // value={obj?.sub_style_id || undefined}
                              showSearch
                              placeholder="Select Sub Style Number"
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                if (option?.children != null) {
                                  return option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                                }
                              }}
                              size="small"
                              disabled={isView}
                            >
                              {isArrayAndHasValue(getSubstyleList(index)) &&
                                getSubstyleList(index)?.map(
                                  (item, mapIndex) => {
                                    return (
                                      <Option
                                        value={item?.id || undefined}
                                        key={`style_id_key_${mapIndex}`}
                                      >
                                        {`${item?.color_info?.name} ${
                                          item?.sub_style_no ? "-" : ""
                                        } ${item?.sub_style_no || ""}`}
                                      </Option>
                                    );
                                  },
                                )}
                            </Select>
                          </Form.Item>
                        </td>
                        )}
                        <td className="t-body">
                          <Form.Item
                            name={[
                              "goods_description_list",
                              index,
                              "goods_description",
                            ]}
                            style={{ marginBottom: "unset" }}
                          >
                            <Select
                              size="small"
                              placeholder="Description of Goods"
                              mode="multiple"
                              onSelect={(value) => {
                                const context = "remarks";
                                const reasons_data = obj?.description;
                                reasons_data.push(value);
                                onChangeLCValue(
                                  reasons_data,
                                  obj?.unique_id,
                                  context,
                                );
                              }}
                              disabled={isView}
                              onDeselect={(value) => {
                                const context = "remarks";
                                const reasons_data = obj?.description;
                                const filteredReasons = reasons_data?.filter(
                                  (item) => item !== value,
                                );
                                onChangeLCValue(
                                  filteredReasons,
                                  obj?.unique_id,
                                  context,
                                );
                              }}
                              value={obj?.description}
                              dropdownRender={(menu) => (
                                <div>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: "8px 0",
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: "0 8px 4px",
                                    }}
                                  >
                                    <Input
                                      placeholder="Description of Goods"
                                      size="small"
                                      value={addedGoodsDescription}
                                      onChange={onGoodsDescriptionChange}
                                    />
                                    <Button
                                      type="text"
                                      icon={<PlusOutlined />}
                                      size="small"
                                      onClick={addNewGoodsDescription}
                                    >
                                      Add
                                    </Button>
                                  </Space>
                                </div>
                              )}
                              options={goodsDescriptions?.map((item) => ({
                                label: item?.description || "",
                                value: item?.id,
                              }))}
                            />
                          </Form.Item>
                        </td>
                        <td className="t-body">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please Input Carton Number.!",
                              },
                            ]}
                            name={["carton_qty_list", index, "carton_qty"]}
                            style={{ marginBottom: "unset" }}
                          >
                            <InputNumber
                              placeholder="Carton Qty."
                              onChange={(e) => onChangeCartonQty(e, index)}
                              size="small"
                              style={{ width: 100 }}
                              precision={0}
                              disabled={isView}
                            />
                          </Form.Item>
                        </td>
                        <td className="t-body sticky">
                          <Form.Item
                            rules={[
                              {
                                required: false,
                                message: "Please Select Order Qty!",
                              },
                            ]}
                            name={[
                              "sales_order_qty_list",
                              index,
                              "sales_order_qty",
                            ]}
                            style={{ marginBottom: "unset" }}
                          >
                            <InputNumber
                              style={{ width: 100 }}
                              placeholder="Order Qty."
                              size="small"
                              disabled
                              precision={0}
                            />
                          </Form.Item>
                        </td>
                        <td className="t-body sticky">
                          <Form.Item
                            rules={[
                              {
                                required: false,
                                message: "Please Select Unit Price!",
                              },
                            ]}
                            name={["unit_price_list", index, "unit_price"]}
                            style={{ marginBottom: "unset" }}
                          >
                            <InputNumber
                              style={{ width: 100 }}
                              placeholder="Unit Price"
                              size="small"
                              disabled
                              precision={2}
                            />
                          </Form.Item>
                        </td>
                        <td className="t-body border-left-black">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please Input Ship Qty.!",
                              },
                            ]}
                            name={["ship_qty_list", index, "ship_qty"]}
                            style={{ marginBottom: "unset" }}
                          >
                            <InputNumber
                              style={{ width: 100 }}
                              placeholder="Ship Qty."
                              onChange={(value) => onChangeShipQty(value, index)}
                              size="small"
                              precision={0}
                              disabled={isView}
                            />
                          </Form.Item>
                        </td>
                        <td className="t-body">
                          <Form.Item
                            name={[
                              "ship_qty_unit_list",
                              index,
                              "ship_qty_unit",
                            ]}
                            style={{ marginBottom: "unset" }}
                          >
                            <Select
                              placeholder="Ship Qty. Unit"
                              style={{ width: "100%" }}
                              size="small"
                              onSelect={(value) =>
                                onSelectShipmentUnit(value, index)
                              }
                              disabled={isView}
                            >
                              {qtyUnit.map((option) => {
                                return (
                                  <option key={option.id} value={option.name}>
                                    {option?.name}
                                  </option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </td>
                        <td className="t-body">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please Input Ship Value!",
                              },
                            ]}
                            name={["ship_value_list", index, "ship_value"]}
                            style={{ marginBottom: "unset" }}
                          >
                            <InputNumber
                              style={{ width: 100 }}
                              placeholder="Ship Value"
                              size="small"
                              disabled
                              precision={2}
                              formatter={(value) => commaSeparateNumber(value)}
                            />
                          </Form.Item>
                        </td>
                        <td className="t-body border-left-black">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please Input Balance Qty.!",
                              },
                            ]}
                            name={["balance_qty_list", index, "balance_qty"]}
                            style={{ marginBottom: "unset" }}
                          >
                            <InputNumber
                              style={{ width: 100 }}
                              placeholder="Balance Qty."
                              size="small"
                              disabled
                            />
                          </Form.Item>
                        </td>
                        <td className="t-body">
                          <Form.Item
                            name={[
                              "balance_qty_unit_list",
                              index,
                              "balance_qty_unit",
                            ]}
                            style={{ marginBottom: "unset" }}
                          >
                            <Select
                              placeholder="Balance Qty. Unit"
                              style={{ width: "100%" }}
                              size="small"
                              disabled={isView}
                            >
                              {qtyUnit.map((option) => {
                                return (
                                  <option key={option.id} value={option.name}>
                                    {option?.name}
                                  </option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </td>
                        <td className="t-body border-right-black">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please Input Balance Value!",
                              },
                            ]}
                            name={[
                              "balance_value_list",
                              index,
                              "balance_value",
                            ]}
                            style={{ marginBottom: "unset" }}
                          >
                            <InputNumber
                              style={{ width: 100 }}
                              placeholder="Balance Value"
                              size="small"
                              disabled
                              precision={2}
                              formatter={(value) => commaSeparateNumber(value)}
                            />
                          </Form.Item>
                        </td>
                        <td className="t-body border-right-black">
                        <Space>
                          <Upload
                            action={
                              api_base_url +
                              EXPORT_DETAILS_DOC_UPLOAD
                            }
                            accept="image/*,.pdf, .doc, .xlsx"
                            headers={{
                              authorization: `Bearer ${token}`,
                            }}
                            showUploadList={false}
                            onChange={(info) => onChangeFileUpload(info, index)}
                          >
                            <Button
                              type="primary"
                              icon={<CloudUploadOutlined />}
                              size="small"
                              // disabled={view}
                            />
                          </Upload>
                          {(obj?.document) && (
                            <Button
                              icon={<EyeOutlined />}
                              size="small"
                              onClick={() =>
                                openFileInNewTab(obj?.document)
                              }
                              // disabled={view}
                            />
                          )}
                        </Space>
                        </td>
                        <td
                          className="t-body"
                          onClick={() => {
                            if(!isView) {
                              copyRow(obj?.unique_id);
                            }
                          }}
                        >
                          <Form.Item style={{ marginBottom: "unset" }}>
                            <PlusCircleOutlined
                              style={{ fontSize: "16px", color: "Green" }}
                            />
                          </Form.Item>
                        </td>
                        <td
                          className="t-body"
                          onClick={() => {
                            if(!isView) {
                              removeRow(obj?.unique_id);
                            }
                          }}
                        >
                          <Form.Item style={{ marginBottom: "unset" }}>
                            <MinusCircleOutlined
                              style={{ fontSize: "16px", color: "red" }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default ExportPOTable;
