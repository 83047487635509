import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { postData } from "components/BOM/AddOrUpdateStyle";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  cglFormatDate,
  hasPermission,
  isArrayAndHasValue,
} from "utils/functions";
import {
  DIGITALIZATION_OPERATOR_EFFICIENCY_EXPORT_REPORT,
  DIGITALIZATION_OPERATOR_EFFICIENCY_LIST_REPORT,
  GET_HOURLY_PRODUCTION_RECORDS,
  SEWING_LINES,
  VIEW_POST_COSTING_REPORT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import { NO_PERMISSION_REQUIRED } from "../../../routes/permissions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";

const OperatorEfficiency = () => {
  // Router
  const location = useLocation();
  const navigate = useNavigate();
  const routerPath = location ? location.pathname : null;

  // States
  const [sewingList, setSewingList] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rangeData, setRangeData] = useState();
  const [activeFilters, setActiveFilters] = useState({});

  // Antd
  const { Title } = Typography;
  const [form] = Form.useForm();
  const fromDateWatcher = Form.useWatch("from_date", form);
  const toDateWatcher = Form.useWatch("to_date", form);
  const lineIdWatcher = Form.useWatch("line_id", form);

  const getAllList = useCallback(
    async (filterValues) => {
      setTableDataLoading(true);
      const query = `${DIGITALIZATION_OPERATOR_EFFICIENCY_LIST_REPORT}`;

      // Combine active filters with pagination params
      const combinedFilters = {
        ...activeFilters,
        ...filterValues,
      };

      const response = await getData(query, false, combinedFilters);
      if (response?.data?.code === 200) {
        setTableDataLoading(false);
        setTableData(response?.data?.data?.data || []);
        setTotalData(response?.data?.data?.total);
        setRangeData(response?.data?.data?.per_page);
      } else {
        setTableDataLoading(false);
        message.error("Something went wrong! Please try again.");
      }
    },
    [activeFilters],
  );

  const getSewingList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SEWING_LINES, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSewingList(response?.data?.data?.data || []);
    }
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  // Effects
  useEffect(() => {
    getAllList({ page: currentPage });
    getSewingList();
  }, [refresh, getAllList, currentPage]);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const tableColumns = [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "sl",
      width: 50,
      render: (_, __, index) => index + 1,
    },
    {
      title: "Checked Date",
      dataIndex: ["operator_efficiency", "checked_date"],
      key: "operator_efficiency.checked_date",
      width: 120,
      render: (value) => <span>{value ? cglFormatDate(value) : "N/A"}</span>,
    },
    {
      title: "Line",
      dataIndex: ["line_info", "name"],
      key: "line_info.name",
      width: 96,
    },
    {
      title: "Operator Name",
      dataIndex: ["operator_info", "name"],
      key: "operator_info.name",
      width: 150,
    },
    {
      title: "Operation Name",
      dataIndex: ["operation_info", "sam_data_bank", "name"],
      key: "operation_info.sam_data_bank.name",
      width: 150,
    },
    {
      title: "Hourly Target",
      dataIndex: "hourly_target",
      key: "hourly_target",
      align: "right",
      width: 120,
    },
    {
      title: "Previous Hour",
      dataIndex: "prev_hour_total_output",
      key: "prev_hour_total_output",
      align: "right",
      width: 120,
    },
    {
      title: "Current Hour",
      dataIndex: "current_hour_total_output",
      key: "current_hour_total_output",
      align: "right",
      width: 120,
    },
    {
      title: "Previous Hour Efficiency",
      dataIndex: "prev_hour_efficiency",
      key: "prev_hour_efficiency",
      align: "right",
      width: 180,
    },
    {
      title: "Current Hour Efficiency",
      dataIndex: "current_hour_efficiency",
      key: "current_hour_efficiency",
      align: "right",
      width: 180,
    },
    {
      title: "Reason for Efficiency Alert",
      dataIndex: "notification_reason",
      key: "notification_reason",
      width: 200,
    },
    {
      title: "Action Taken",
      dataIndex: "action_details",
      key: "action_details",
      width: 150,
      render: (actions) =>
        actions && actions.length > 0
          ? actions
              .map((action) => action.action_info?.title_en)
              .filter(Boolean)
              .join(", ")
          : "",
    },
    {
      title: "Results",
      dataIndex: "result_details",
      key: "result_details",
      width: 150,
      render: (results) =>
        results && results.length > 0
          ? results
              .map((result) => result.result_info?.title_en)
              .filter(Boolean)
              .join(", ")
          : "",
    },
  ];

  const exportDetails = async (filterValues) => {
    setLoading(true);
    const query = `${DIGITALIZATION_OPERATOR_EFFICIENCY_EXPORT_REPORT}`;
    const bodyData = {
      ...filterValues,
    };
    const response = await getData(
      query,
      false,
      bodyData,
      { "Content-Type": "blob" },
      "arraybuffer",
    );

    if (response && response?.data) {
      // Download the file
      const outputFilename = `operator-efficiency.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
      message.success("Operator Efficiency Report Exported Successfully");
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const previewFunction = async (filterValues) => {
    setPreviewLoading(true);
    setCurrentPage(1);

   
    setActiveFilters(filterValues);

    const bodyData = {
      ...filterValues,
      page: 1,
    };

    const query = `${DIGITALIZATION_OPERATOR_EFFICIENCY_LIST_REPORT}`;
    const response = await getData(query, false, bodyData);

    if (response?.data?.code === 200) {
      setTableData(response?.data?.data?.data || []);
      setTotalData(response?.data?.data?.total);
      setRangeData(response?.data?.data?.per_page);
      setPreviewLoading(false);
    } else {
      setPreviewLoading(false);
      message.error("Something went wrong");
    }
  };

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleClearFilters = () => {
    form.resetFields();
    setCurrentPage(1);
    setActiveFilters({});
    refetch();
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                hideAddButton={true}
                disabled={!hasPermission([NO_PERMISSION_REQUIRED])}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            }
            title={<Title level={5}>Operator Efficiency</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10, padding: 10 }}>
              <Form layout="vertical" form={form}>
                <Row gutter={24} align={"bottom"}>
                  <Col span={4}>
                    <Form.Item label="From Date" name="from_date">
                      <DatePicker
                        placeholder="From Date"
                        format={"DD-MMM-YY"}
                        size="small"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="To Date" name="to_date">
                      <DatePicker
                        placeholder="To Date"
                        format={"DD-MMM-YY"}
                        size="small"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Line" name="line_id">
                      <Select
                        showSearch
                        placeholder="Select a Line"
                        onSearch={(value) => {
                          getSewingList({ name: value });
                        }}
                        optionFilterProp="children"
                        size="small"
                        filterOption={false}
                      >
                        {isArrayAndHasValue(sewingList) &&
                          sewingList.map((sup) => (
                            <Select.Option value={sup.id} key={sup.id}>
                              {sup.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={async () => {
                            try {
                              const values = await form.validateFields();
                              const filterValues = {
                                ...(values.from_date && {
                                  from_date: moment(values.from_date).format(
                                    "YYYY-MM-DD",
                                  ),
                                }),
                                ...(values.to_date && {
                                  to_date: moment(values.to_date).format(
                                    "YYYY-MM-DD",
                                  ),
                                }),
                                ...(values.line_id && {
                                  line_id: values.line_id,
                                }),
                              };

                              await exportDetails(filterValues);
                            } catch (error) {
                              console.log("Form validation failed:", error);
                            }
                          }}
                          loading={loading}
                          icon={<DownloadOutlined />}
                        >
                          Export
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          onClick={async () => {
                            try {
                              const values = form.getFieldsValue();
                              const filterValues = {
                                ...(values.from_date && {
                                  from_date: moment(values.from_date).format(
                                    "YYYY-MM-DD",
                                  ),
                                }),
                                ...(values.to_date && {
                                  to_date: moment(values.to_date).format(
                                    "YYYY-MM-DD",
                                  ),
                                }),
                                ...(values.line_id && {
                                  line_id: values.line_id,
                                }),
                              };

                              await previewFunction(filterValues);
                            } catch (error) {
                              console.log("Error:", error);
                            }
                          }}
                          icon={<EyeOutlined />}
                          loading={previewLoading}
                        >
                          Preview
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button onClick={handleClearFilters}>Clear</Button>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Form>
              <Table
                dataSource={tableData}
                columns={tableColumns}
                bordered
                loading={tableDataLoading || previewLoading}
                pagination={{
                  showSizeChanger: false,
                  current: currentPage,
                  total: totalData,
                  pageSize: rangeData,
                  onChange: (page) => paginate(page),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default OperatorEfficiency;
