import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputNumber, Table, Typography } from "antd";
import { commaSeparateNumber } from "../../../utils/functions";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave(record, values);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                parseInt(value) >
                  parseInt(record["daily_consumption"]["remain_piece"]) ||
                !value
              ) {
                return Promise.reject(
                  new Error(
                    "Total value can not be greater than remaining piece !",
                  ),
                );
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input ref={inputRef} onChange={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const ShipmentDetails = (props) => {
  const {
    setShipmentTableData,
    loading,
    selectedShipmentDetails,
    selectedPoStatus,
  } = props;
  var shipmentDetails = selectedShipmentDetails;
  const [onEdit, setEdit] = useState(false);

  const onsetEdit = () => {
    setEdit(!onEdit);
  };

  const defaultColumns = [
    { title: "Color", dataIndex: ["color", "name"], key: ["color", "name"] },
    { title: "Size", dataIndex: ["size", "name"], key: ["color", "name"] },
    {
      title: "Order Quantity (pc.)",
      dataIndex: "sales_order_quantity",
      key: "sales_order_quantity",
      render: (value) => {
        return(
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        );
      },
    },
    {
      title: "Packed Quantity (pc.)",
      dataIndex: ["packing_information", "total"],
      key: ["packing_information", "total"],
      render: (value) => {
        return(
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        );
      },
    },
    {
      title: "Shipment Quantity (pc)",
      dataIndex: ["shipping_information", "day_quantity"],
      key: ["shipping_information", "day_quantity"],
      editable: onEdit,
      render(text, record) {
        return {
          children: (
            <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
              <InputNumber
                value={record?.shipping_information?.day_quantity}
                disabled={!onEdit}
                style={{ width: "85px" }}
                formatter={(value) => commaSeparateNumber(value)}
                precision={2}
              />
            </Form.Item>
          ),
        };
      },
    },
    {
      title: "Shipment Total (pc)",
      dataIndex: ["shipping_information", "total"],
      key: ["shipping_information", "total"],
      render: (value) => {
        return(
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        );
      },
    },
    {
      title: "Transfer To Inventory (pc)",
      dataIndex: ["transfer_information", "total"],
      key: ["transfer_information", "total"],
      render: (value, row, index) => {
        return row?.transfer_information?.total ? commaSeparateNumber(row?.transfer_information?.total) : 0;
      },
    },
    {
      title: "Garments Remains (pc)",
      dataIndex: ["daily_consumption", "remain_piece"],
      key: ["daily_consumption", "remain_piece"],
      render: (value) => {
        return(
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        );
      },
    },
  ];

  const handleSave = (row, values) => {
    const newData = [...shipmentDetails];
    const index = newData.findIndex((item) => row.id === item.id);
    shipmentDetails[index] = row;
    newData[index]["shipping_information"]["day_quantity"] = values[
      "shipping_information"
    ]
      ? values?.shipping_information?.day_quantity
      : row?.shipping_information?.day_quantity;
    setShipmentTableData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  
  return (
    <>
      {shipmentDetails && (
        <div className="shipmentTable" style={{ marginTop: 10 }}>
          <Button
            type="primary"
            size="small"
            onClick={onsetEdit}
            style={{ float: "right", fontWeight: 600, marginBottom: 10 }}
            disabled={selectedPoStatus === "Closed"}
          >
            Edit
          </Button>
          <Typography.Title style={{ fontSize: 14 }}>
            Shipment Details
          </Typography.Title>
          <Table
            loading={loading}
            pagination={false}
            size={"small"}
            scroll={{
              x: 1300,
            }}
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={shipmentDetails}
            columns={columns}
          />
        </div>
      )}
    </>
  );
};
export default ShipmentDetails;
