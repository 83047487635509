import {
  DownloadOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Card,
  Form,
  Select,
  Modal,
  Popover,
  InputNumber,
  Input,
  DatePicker,
  Divider,
  Space,
  Button,
  message,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import {
  ADD_B2BLC_REMARKS,
  ADD_LC_REASON,
  B2BLC_DOCUMENT_UPLOAD,
  B2BLC_REMARKS_LIST,
  EXPORT_LC_REASONS,
  SALES_CONTRACT_COMMERCIAL_DOC,
} from "../../../../apiServices/API_ENDPOINTS";
import { useParams } from "react-router";
import { getData, postData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import { isArrayAndHasValue } from "../../../../utils/functions";
import { BASE_URL } from "../../../../apiServices";

const token = localStorage.getItem("token");

const ChallanAssignTable = (props) => {
  // Props
  const { 
    challanDetailsList, 
    setChallanDetailsList, 
    challanList, 
    invoiceDetails,
    diffDetails,
    setDiffDetails,
    isEdit
  } = props;

  // Antd
  const [challanForm] = Form.useForm();

  // Others
  const { reconciliationId } = useParams();

  const addRow = (unique_id) => {
    const challanDetailsListCopy = [...challanDetailsList];
    const newData = {
      unique_id: challanDetailsListCopy.length + 1,
      challan_id: null,
      received_date: "",
      item_category: "",
      challan_qty: "",
      unit: "",
      challan_value: "",
    };
    challanDetailsListCopy.push(newData);
    setChallanDetailsList(challanDetailsListCopy);
  };

  const removeRow = (unique_id) => {
    const challanDetailsListCopy = challanDetailsList.filter(
      (item) => item.unique_id !== unique_id
    );
    setChallanDetailsList(challanDetailsListCopy);
  };

  const handleChangeChallan = (selectedOption, index) => {
    const selectedChallan = challanList?.find((challan) => challan?.id === selectedOption);
  
    if (selectedChallan) {
      const details = selectedChallan?.details || [];
      const updatedChallanDetailsList = [...challanDetailsList];
      
      // Update the selected row with the details of the selected challan
      updatedChallanDetailsList[index] = {
        ...updatedChallanDetailsList[index],
        challan_id: selectedChallan?.id,
        received_date: "",
        item_category: "",
        challan_qty: "",
        unit: "",
        challan_value: "",
      };
  
      // Remove any existing rows related to the same challan
      const filteredDetails = updatedChallanDetailsList?.filter((item) => item?.challan_id !== selectedChallan?.id);
  
      // Add new rows for each detail
      details?.forEach((detail, detailIndex) => {
        const newDetailRow = {
          unique_id: updatedChallanDetailsList?.length + 1 + detailIndex,
          challan_id: selectedChallan?.id,
          received_date: detail?.received_date,
          item_category: detail?.item_category,
          challan_qty: detail?.challan_qty,
          unit: detail?.unit,
          challan_value: detail?.challan_value,
        };
  
        filteredDetails.push(newDetailRow);
      });
  
      setChallanDetailsList(filteredDetails);
    }
  };
    console.log('assign table challanList', challanList);

  const calculateDifferences = () => {
    let totalChallanQty, totalChallanValue;

    if(challanDetailsList[0]?.challan_id) {
      totalChallanValue = challanDetailsList?.reduce(function(prev, current) {
        return prev + +current?.challan_value
      }, 0);

      totalChallanQty = challanDetailsList?.reduce(function(prev, current) {
        return prev + +current?.challan_qty
      }, 0);

      if(invoiceDetails) {
        let status;
        if(invoiceDetails?.invoice_qty != totalChallanQty && invoiceDetails?.invoice_value != totalChallanValue) {
          status = 'Gap';
        } else if(invoiceDetails?.invoice_qty == totalChallanQty && invoiceDetails?.invoice_value == totalChallanValue) {
          status = 'Done';
        } else {
          status = 'Pending';
        }

        setDiffDetails({
          diffQty: invoiceDetails?.quantity_ctn - totalChallanQty,
          diffValue: invoiceDetails?.invoice_value - totalChallanValue,
          status: status
        })
      }
    }

    if(challanDetailsList?.length === 0) {
      setDiffDetails(null)
    }
  };
    
  useEffect(() => {
    calculateDifferences();
  }, [challanDetailsList]);
  

  return (
    <Form form={challanForm}>
      <div>
        <Card
          title={<span style={{ fontSize: "14px" }}>Assign Challan</span>}
        >
          <div className="custome-table">
            <table style={{width: "80%"}}>
              <thead>
                {/* <tr>
                  <th className="t-header" colSpan="2"> </th>
                  <th className="t-header bc-java " colSpan="2">
                    Change
                  </th>
                </tr> */}
                <tr>
                  <th key="challan_id" className="bc-java ">
                    challan No{" "}
                  </th>
                  <th key="received_date" className="bc-java ">
                    Receive Date{" "}
                  </th>
                  <th key="item_category" className="bc-java ">
                    Item Category{" "}
                  </th>
                  <th key="challan_qty" className="bc-java ">
                    Qty{" "}
                  </th>
                  <th key="unit" className="bc-java ">
                    Unit{" "}
                  </th>
                  <th key="challan_value" className="bc-java ">
                    Challan Value{" "}
                  </th>
                  {/* <th key="header_add" className="bc-java ">
                    Add{" "}
                  </th> */}
                  <th key="header_remove" className="bc-java ">
                    Rmv{" "}
                  </th>
                </tr>
              </thead>

              <tbody className="scrollable-table-container">
                {isArrayAndHasValue(challanDetailsList) &&
                  challanDetailsList?.map((item, index) => {
                    return (
                      <tr key={`table_parent_key_${index}`}>
                        {/* INITIAL/AMENDMENT */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Select
                              showSearch
                              placeholder="Challan"
                              style={{ width: "100%" }}
                              value={item?.challan_id}
                              // disabled={isEdit ? true:false}
                              onChange={(value) => handleChangeChallan(value, index)}
                            >
                              {isArrayAndHasValue(challanList) &&
                                challanList?.map((option) => {
                                  return (
                                    <option key={option.id} value={option.id}>
                                      {option.challan_no}
                                    </option>
                                  );
                              })}
                            </Select>
                          </Form.Item>
                        </td>
                        {/* LC/Amendment Date */}
                        <td className="t-body">
                          <Form.Item style={{ marginBottom: "unset" }}>
                            <Input
                            name={`received_date_${index}`}
                            value={item?.received_date}
                            size="small"
                            placeholder="Received Date"
                            disabled={true}
                          />
                          </Form.Item>
                        </td>
                        {/* LC QUANTITY */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                          <Input
                            name={`item_category_${index}`}
                            value={item.item_category}
                            size="small"
                            placeholder="Item Category"
                            disabled={true}
                          />
                          </Form.Item>
                        </td>
                        {/* LC VALUE */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                          >
                          <InputNumber
                            name={`challan_qty_${index}`}
                            value={item?.challan_qty}
                            size="small"
                            placeholder="Qty"
                            disabled={true}
                          />
                          </Form.Item>
                        </td>

                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                          >
                          <Input
                            name={`unit_${index}`}
                            value={item.unit}
                            size="small"
                            placeholder="Unit"
                            disabled={true}
                          />
                          </Form.Item>
                        </td>

                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                          >
                            <Input
                              name={`challan_value_${index}`}
                              value={item.challan_value}
                              size="small"
                              placeholder="Challan value"
                              disabled={true}
                            />
                          </Form.Item>
                        </td>

                        {/* <td
                          className="t-body"
                          onClick={() => {
                            addRow(item?.unique_id);
                          }}
                        >
                          <PlusCircleOutlined
                            className="cursore-pointer"
                            style={{ fontSize: "16px", color: "Green" }}
                          />
                        </td> */}
                        <td
                          className="t-body"
                          onClick={() => {
                            // Disable the remove icon for the first item with "Initial/Amendment" set to "Initial"
                            // Enable it for the last item
                            if (index === 0 && item?.amendment_number === "Initial") {
                              return;
                            }
                            if (index === challanDetailsList.length - 1) {
                              removeRow(item?.unique_id);
                            }
                          }}
                        >
                          <MinusCircleOutlined
                            className={`cursore-pointer ${index === 0 && item?.amendment_number === "Initial" ? "disabled-icon" : ""}`}
                            style={{ 
                              fontSize: "16px", 
                              // color: "Red" 
                              color: (index === 0 && item?.amendment_number === "Initial") || index != challanDetailsList.length - 1 ? "grey" : "red", // Muted color
                              cursor: (index === 0 && item?.amendment_number === "Initial") || index != challanDetailsList.length - 1 ? "not-allowed" : "pointer", // Disabled cursor
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <Button
              type="primary"
              size="small"
              style={{ marginTop: 10 }}
              onClick={addRow}
            >
              Add New
            </Button>
          </div>
        </Card>
      </div>
    </Form>
  );
};

export default ChallanAssignTable;
