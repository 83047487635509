import { Button, Col, Form, Row } from "antd";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  onCreateNewFilter,
  onGetBuyerList,
  onGetBuyingOfficeList,
  onGetItemCategoryList,
  onGetItemList,
  onGetOrganizationList,
  onGetSewingLineList,
} from "../../redux/actions";
import ActivityLogFilterFields from "./FilterFields/ActivityLogFilterFields";
import AssignmentFilterFields from "./FilterFields/AssignmentFilterFields";
import BoltSealLogFilterField from "./FilterFields/BoltSealLogFilterField";
import BOMFilterFields from "./FilterFields/BOMFilterFields";
import BuyerFilterFields from "./FilterFields/BuyerFilterFields";
import BuyerInquiryFilterFields from "./FilterFields/BuyerInquiryFilterFields";
import BuyerStyleChecklistFilterFields from "./FilterFields/BuyerStyleChecklistFilterFields";
import BuyingOfficeFilterFields from "./FilterFields/BuyingOfficeFilterFields";

import BuyerInspectionDateFilterFields from "./FilterFields/BuyerInspectionDateFilterFields";
import BuyerPackingReportFilterFields from "./FilterFields/BuyerPackingReportFilterFields";
import BuyerVisitDateFilterFields from "./FilterFields/BuyerVisitDateFilterFields";
import CapacityBookingsReportFilterFields from "./FilterFields/CapacityBookingsReportFilterFields";
import ChecklistQuestionsFilter from "./FilterFields/ChecklistQuestionsFilter";
import ConfigurationFilterFields from "./FilterFields/ConfigurationFilterFields";
import CostEstimateFilterFields from "./FilterFields/CostEstimateFilterFields";
import CuttingFilterFields from "./FilterFields/CuttingFilterFields";
import DailyLinePlanFilter from "./FilterFields/DailyLinePlanFilter";
import DigitalAppRoleFilter from "./FilterFields/DigitalAppRoleFilter";
import DigitalDefectCategoryFilter from "./FilterFields/DigitalDefectCategoryFilter";
import DigitalDefectCategoryMappingFilter from "./FilterFields/DigitalDefectCategoryMappingFilter";
import DigitalDefectLineSectionMappingFilter from "./FilterFields/DigitalDefectLineSectionMappingFilter";
import DigitalDefectReasonFilter from "./FilterFields/DigitalDefectReasonFilter";
import DigitalDefectRecommendationFilter from "./FilterFields/DigitalDefectRecommendationFilter";
import DigitalDefectResponsibleFilter from "./FilterFields/DigitalDefectResponsibleFilter";
import DigitalizationGarmentsTrackingFilter from "./FilterFields/DigitalizationGarmentsTrackingFilter";
import DigitalizationOperatorEfficiencyFilter from "./FilterFields/DigitalizationOperatorEfficiency";
import DigitalizationUserLineAssignList from "./FilterFields/DigitalizationUserLineAssignList";
import ERPUsageIssueFilterFields from "./FilterFields/ERPUsageIssueFilterFields";
import ERPUsageModuleFilterFields from "./FilterFields/ERPUsageModuleFilterFields";
import ERPUsageModuleUiFilterFields from "./FilterFields/ERPUsageModuleUiFilterFields";
import ExportDetailsFilterFields from "./FilterFields/ExportDetailsFilterFields";
import FabricReconcilFilterFields from "./FilterFields/FabricReconcilFilterFields";
import FoldingFilterFields from "./FilterFields/FoldingFilterFields";
import HourlyProductionRecFilterField from "./FilterFields/HourlyProductionRecFilterField";
import HourlyProRecDetailsFilter from "./FilterFields/HourlyProRecDetailsFilter";
import HROTExpenseDataFilter from "./FilterFields/HROTExpenseDataFilter";
import ImportAcceptanceDetailFilterFields from "./FilterFields/ImportAcceptanceDetailFilterFields";
import ImportB2blcFilterFields from "./FilterFields/ImportB2blcFilterFields";
import ImportDetailFilterFields from "./FilterFields/ImportDetailFilterFields";
import ImportPiFilterFields from "./FilterFields/ImportPiFilterFields";
import ImportVehicleStatusFilterFields from "./FilterFields/ImportVehicleStatusFilterFields";
import InitialSAMEntryFilter from "./FilterFields/InitialSAMEntryFilter";
import InventoryDetailFilterFields from "./FilterFields/InventoryDetailFilterFields";
import InventoryMRRFilterFields from "./FilterFields/InventoryMRRFilterFields";
import ItemCategoryFilterFields from "./FilterFields/ItemCategoryFilterFields";
import ItemListFilterFields from "./FilterFields/ItemListFilterFields";
import LearningCurveFilterFields from "./FilterFields/LearningCurveFilterFields";
import LocalPurchaseReconciliationFilterFields from "./FilterFields/LocalPurchaseReconciliationFilterFields";
import MerchandisingSubContractFactory from "./FilterFields/MerchandisingSubContractFactory";
import NotificationSettingsFilterFields from "./FilterFields/NotificationSettingsFilterFields";
import NotificationSlugsFilterFields from "./FilterFields/NotificationSlugsFilterFields";
import NotingAssessmentFilterFields from "./FilterFields/NotingAssessmentFilterFields";
import OBFilterFields from "./FilterFields/OBFilterFields";
import OperatorAbsenteeismFilterField from "./FilterFields/OperatorAbsenteeismFilterField";
import OrganizationFilterFields from "./FilterFields/OrganizationFilterFields";
import OSRFilterFields from "./FilterFields/OSRFilterFileds";
import OTSignalFilterFields from "./FilterFields/OTSignalFilterFields";
import PackingFilterFields from "./FilterFields/PackingFilterFields";
import PackingReportFilterFields from "./FilterFields/PackingReportFilterFields";
import PostCostingReportFilter from "./FilterFields/PostCostingReportFilter";
import PPMeetingDateFilterFields from "./FilterFields/PPMeetingDateFilterFields";
import ProductionDefectFilterField from "./FilterFields/ProductionDefectFilterField";
import ProductionDetailsFilterFileds from "./FilterFields/ProductionDetailsFilterFileds";
import PurchaseOrderFilterFields from "./FilterFields/PurchaseOrderFilterFields";
import ReportBoltSealFilterFields from "./FilterFields/ReportBoltSealFilterField";
import SalesContractCommercialFilterFields from "./FilterFields/SalesContractCommercialFilterFields";
import SalesContractFilterFields from "./FilterFields/SalesContractFilterFields";
import SalesContractPOSFilterFields from "./FilterFields/SalesContractPOSFilterFields";
import SecurityBoltFilterFields from "./FilterFields/SecurityBoltFilterFields";
import SewingFilterFields from "./FilterFields/SewingFilterFields";
import SewingLineFilterFields from "./FilterFields/SewingLineFilterFields";
import ShipmentHandoverFilterFields from "./FilterFields/ShipmentHandoverFilterFields";
import StyleFilterFields from "./FilterFields/StyleFilterFields";
import SubmissionRealizationFilterFields from "./FilterFields/SubmissionRealizationFilterFields";
import SubSalesContractFilterFields from "./FilterFields/SubSalesContractFilterFields";
import SupervisorChecklistFilterField from "./FilterFields/SupervisorChecklistFilterField";
import SwingvsFoldingFilterFields from "./FilterFields/SwingvsFoldingFilterFields";
import TNADepartmentFields from "./FilterFields/TNADepartmentFields";
import TNAProcessFields from "./FilterFields/TNAProcessFields";
import TNATaskFields from "./FilterFields/TNATaskFields";
import TNATodoFilter from "./FilterFields/TNATodoFilter";
import UnitConvertionFilterFields from "./FilterFields/UnitConvertionFilterFields";
import UserRoleFilterFields from "./FilterFields/UserRoleFilterFields";
import UsersFilterFields from "./FilterFields/UsersFilterFields";
import VehicleAndDriverInformationFilterField from "./FilterFields/VehicleAndDriverInformationFilterField";

const AdvancedSearchForm = memo((props) => {
  // Props
  const {
    initialTableData,
    filterName,
    fetchTableData,
    searchButtonText,
    customButton,
    importDownload,
    filterAPI,
    disabledButton,
    isPreview = false,
    previewFunction = () => {},
    setTableData,
    setSupplierType,
    filterValues,
    setFilterValues,
  } = props;

  // Antd Constants
  const [form] = Form.useForm();

  // Redux Constants
  const dispatch = useDispatch();

  const onFinish = (values, initialTableData, filterName) => {
    dispatch(onCreateNewFilter(values));

    // Save filter values in local storage
    if (filterName && values) {
      localStorage.setItem(
        `${filterName}_filter_value`,
        JSON.stringify(values),
      );
    }

    // if (!values && filterName === "import_detail") {
    //   // Do nothing when clear is clicked for import_detail
    //   setTableData([]);
    //   return;
    // }

    switch (filterName) {
      case "organization":
        dispatch(
          onGetOrganizationList({
            page: 1,
            per_page: 10,
            ...values,
          }),
        );
        break;
      case "buyer":
        dispatch(
          onGetBuyerList({
            page: 1,
            per_page: 10,
            ...values,
          }),
        );
        break;
      case "buying_office":
        dispatch(
          onGetBuyingOfficeList({
            page: 1,
            per_page: 10,
            ...values,
          }),
        );
        break;
      case "item_category":
        dispatch(
          onGetItemCategoryList({
            page: 1,
            per_page: 10,
            ...values,
          }),
        );
        break;
      case "item_list":
        dispatch(
          onGetItemList({
            page: 1,
            per_page: 10,
            ...values,
          }),
        );
        break;
      case "sewing_line":
        dispatch(
          onGetSewingLineList({
            page: 1,
            per_page: 10,
            ...values,
          }),
        );
        break;
      case "style":
        fetchTableData({
          page: 1,
          per_page: 10,
          buying_office_id: values?.buying_office_id,
          buyer_id: values?.buyer_id,
          brand_id: values?.brand_id,
          style_no: values?.style_no,
        });
        break;
      case "sales_contract_commercial":
        fetchTableData({
          page: 1,
          per_page: 10,
          sales_contract_id: values?.sales_contract_id,
          tag_number: values?.tag_number,
          buyer_name: values?.buyer_name,
          sales_contract_value: values?.sales_contract_value,
        });
        break;
      case "export_details":
        fetchTableData({
          page: 1,
          per_page: 10,
          invoice_number: values?.invoice_number,
          invoice_date: values?.invoice_date,
          sales_contract_id: values?.sales_contract_id,
        });
        break;
      case "import_b2blc":
        fetchTableData({
          page: 1,
          per_page: 10,
          b2b_lc_number: values?.b2b_lc_number,
          b2b_lc_date: values?.b2b_lc_date,
          sales_contract_id: values?.sales_contract_id,
          supplier_id: values?.supplier_id,
        });
        break;
      case "import_pi":
        fetchTableData({
          page: 1,
          per_page: 10,
          pi_number: values?.pi_number,
          sales_contract_id: values?.sales_contract_id,
          supplier_id: values?.supplier_id,
          status: values?.status,
        });
        break;
      case "import_detail":
        fetchTableData({
          page: 1,
          per_page: 10,
          position_type: values?.position_type,
          supplier_type: values?.supplier_type,
          invoice_number: values?.invoice_number,
          b2b_lc_number: values?.b2b_lc_number,
          quantity_ctn: values?.quantity_ctn,
          quantity_yds: values?.quantity_yds,
          total_lc_value: values?.total_lc_value,
          from_date: values?.from_date
            ? moment(values?.from_date).format("YYYY-MM-DD")
            : null,
          to_date: values?.to_date
            ? moment(values?.to_date).format("YYYY-MM-DD")
            : null,
        });
        break;
      case "import_acceptance_detail":
        fetchTableData({
          page: 1,
          per_page: 10,
          terms_of_payment: values?.terms_of_payment,
          import_lc_number: values?.import_lc_number,
          supplier_id: values?.supplier_id,
          type: values?.type,
          from_date: values?.from_date
            ? moment(values?.from_date).format("YYYY-MM-DD")
            : null,
          to_date: values?.to_date
            ? moment(values?.to_date).format("YYYY-MM-DD")
            : null,
        });
        break;
      case "noting_assessment":
        fetchTableData({
          page: 1,
          per_page: 10,
          invoice_id: values?.invoice_id,
          utilization_declearation_number:
            values?.utilization_declearation_number,
        });
        break;
      case "security_bolt":
        fetchTableData({
          page: 1,
          per_page: 10,
          security_bolt_seal_number: values?.security_bolt_seal_number,
        });
        break;
      case "submission_realization":
        fetchTableData({
          page: 1,
          per_page: 10,
          invoice_number: values?.invoice_number,
          sales_contract_id: values?.sales_contract_id,
          fdbc_number: values?.fdbc_number,
        });
        break;
      case "sales_contract":
        fetchTableData({
          page: 1,
          per_page: 10,
          sales_contract_unique_id: values?.sales_contract_unique_id,
          reference_no: values?.reference_no,
          contract_date: values?.contract_date,
        });
        break;
      case "purchase_order":
        fetchTableData({
          page: 1,
          per_page: 10,
          buying_office_id: values?.buying_office_id,
          sales_contract_id: values?.sales_contract_id,
          buyer_id: values?.buyer_id,
          contract_status: values?.contract_status,
          po_map_id: values?.po_map_id,
        });
        break;
      case "bom":
        fetchTableData({
          page: 1,
          per_page: 10,
          buying_office_id: values?.buying_office_id,
          sales_contract_id: values?.sales_contract_id,
          buyer_id: values?.buyer_id,
          style_id: values?.style_id,
        });
        break;
      case "configuration":
        fetchTableData({
          name: values?.name,
        });
        break;
      case "unit_convertion":
        fetchTableData({
          convert_from: values?.convert_from,
          convert_to: values?.convert_to,
        });
        break;
      case "activity_log":
        fetchTableData({
          start: values?.start
            ? moment(values?.start).format("DD-MM-YYYY")
            : null,
          end: values?.end ? moment(values?.end).format("DD-MM-YYYY") : null,
          action: values?.action,
          movement_type: values?.movement_type,
          acted_by: values?.acted_by,
          ui_model_name: values?.ui_model_name,
        });
        break;
      case "packing_list":
        fetchTableData({
          buying_office_id: values?.buying_office_id,
          buyer_id: values?.buyer_id,
          sales_contract_id: values?.sales_contract_id,
          po_map_id: values?.po_map_id,
        });
        break;
      case "inventory_mrr":
        fetchTableData({
          start_date: values?.start_date,
          end_date: values?.end_date,
          supplier_id: values?.supplier_id,
        });
        break;
      case "inventory_details":
        fetchTableData({
          start_date: values?.start_date,
          end_date: values?.end_date,
        });
        break;
      case "packing_report":
        fetchTableData({
          po_map_id: values?.po_map_id,
        });
        break;
      case "buyer_packing_report":
        fetchTableData({
          po_map_id: values?.po_map_id,
          style_id: values?.style_id,
        });
        break;
      case "fabric_reconcil":
        fetchTableData({
          start_date: values?.start_date,
          end_date: values?.end_date,
          sales_contract_id: values?.sales_contract_id,
          style_id: values?.style_id,
          buying_office_id: values?.buying_office_id,
          buyer_id: values?.buyer_id,
          first_party: values?.first_party,
        });
        break;
      case "cost_estimate":
        fetchTableData({
          sales_contract_id: values?.sales_contract_id,
        });
        break;
      case "users_list":
        fetchTableData({
          name: values?.name,
          email: values?.email,
          id_card_no: values?.id_card_no,
        });
        break;
      case "production_details":
        fetchTableData({
          start_date: values?.start_date,
          end_date: values?.end_date,
          sales_contract_id: values?.sales_contract_id,
          style_id: values?.style_id,
          buying_office_id: values?.buying_office_id,
          buyer_id: values?.buyer_id,
        });
        break;
      case "post-costing-report":
        fetchTableData({
          sales_contract_id: values?.sales_contract_id,
        });
        break;
      case "user_role_filter":
        fetchTableData({
          name: values?.name,
        });
        break;
      case "notification_slugs":
        fetchTableData({
          page: 1,
          per_page: 10,
          name: values?.name,
          code: values?.code,
        });
        break;
      case "notification_settings":
        fetchTableData({
          page: 1,
          per_page: 10,
          name: values?.name,
        });
        break;
      case "import_vehicle_status":
        fetchTableData({
          per_page: 10,
          vehicle_number: values?.vehicle_number,
          b2b_lc_number: values?.import_lc_number,
          from_date: values?.from_date
            ? moment(values?.from_date).format("YYYY-MM-DD")
            : null,
          to_date: values?.to_date
            ? moment(values?.to_date).format("YYYY-MM-DD")
            : null,
        });
        break;
      case "learning_curve":
        fetchTableData({
          page: 1,
          per_page: 10,
          range_start: values?.range_start,
          range_end: values?.range_end,
        });
        break;
      case "buyer_inquiry":
        fetchTableData({
          page: 1,
          per_page: 10,
          inquiry_no: values?.inquiry_no,
          buying_office_id: values?.buying_office_id,
          buyer_id: values?.buyer_id,
          brand_id: values?.brand_id,
        });
        break;
      case "tna_process":
        fetchTableData({
          page: 1,
          per_page: 10,
          title: values?.title,
        });
        break;
      case "tna_department":
        fetchTableData({
          page: 1,
          per_page: 10,
          title: values?.title,
        });
        break;
      case "tna_task":
        fetchTableData({
          page: 1,
          per_page: 10,
          title: values?.title,
          process: values?.process,
        });
        break;
      case "tna_todo":
        fetchTableData({
          page: 1,
          per_page: 10,
          action_statuses: values?.todoTask,
          date_range: values?.date_range,
          buyer_id: values?.buyer_id,
          responsible_person_id: values?.responsible_person_id,
        });
        break;
      case "cutting_filter":
        fetchTableData({
          cutting_date: values?.cutting_date
            ? moment(values?.cutting_date).format("YYYY-MM-DD")
            : null,
          buyer_id: values?.buyer_id,
          style_id: values?.style_id,
        });
        break;
      case "post_cutting_filter":
        fetchTableData({
          movement_date: values?.movement_date
            ? moment(values?.movement_date).format("YYYY-MM-DD")
            : null,
          buyer_id: values?.buyer_id,
          style_id: values?.style_id,
        });
        break;
      case "assignment_filter":
        fetchTableData({
          assignment_date: values?.assignment_date
            ? moment(values?.assignment_date).format("YYYY-MM-DD")
            : null,
          buyer_id: values?.buyer_id,
          style_id: values?.style_id,
        });
        break;
      case "sewing_filter":
        fetchTableData({
          sewing_date: values?.sewing_date
            ? moment(values?.sewing_date).format("YYYY-MM-DD")
            : null,
          buyer_id: values?.buyer_id,
          style_id: values?.style_id,
        });
        break;
      case "folding_filter":
        fetchTableData({
          folding_date: values?.folding_date
            ? moment(values?.folding_date).format("YYYY-MM-DD")
            : null,
          buyer_id: values?.buyer_id,
          style_id: values?.style_id,
        });
        break;
      case "packing_filter":
        fetchTableData({
          packing_date: values?.packing_date
            ? moment(values?.packing_date).format("YYYY-MM-DD")
            : null,
          buyer_id: values?.buyer_id,
          style_id: values?.style_id,
          po_map_id: values?.po_map_id,
        });
        break;
      case "report_bolt_seal":
        fetchTableData({
          arrival_start_date: values?.arrival_start_date,
          arrival_end_date: values?.arrival_end_date,
          dispatch_start_date: values?.dispatch_start_date,
          dispatch_end_date: values?.dispatch_end_date,
        });
        break;
      case "supervisor_checklist":
        fetchTableData({
          line_id: values?.line_id,
          user_id: values?.user_id,
        });
        break;
      case "production_defect":
        fetchTableData({
          per_page: values?.per_page || 10,
          date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : null,
          line_id: values?.line_id,
          operation: values?.operation,
          category_id: values?.category_id,
          user_id: values?.user_id,
        });
        break;
      case "digitalization-garments-tracking":
        fetchTableData({
          per_page: values?.per_page || 10,
          date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : null,
          line_id: values?.line_id,
          style_id: values?.style_id,
          buyer_id: values?.buyer_id,
        });
        break;
      case "operator_absenteeism":
        fetchTableData({
          line_id: values?.line_id,
          user_id: values?.user_id,
        });
        break;
      case "digitalization_daily_line_plan":
        fetchTableData({
          style_id: values?.style,
          buyer_id: values?.buyer,
          line_id: values?.line,
        });
        break;
      case "buyer_style_checklist_filter":
        fetchTableData({
          page: 1,
          per_page: 10,
          type: "buyer_wise",
          filter_date: values?.filter_date
            ? moment(values?.filter_date).format("YY-MM-DD")
            : null,
        });
        break;
      case "checklist-questions-filter":
        fetchTableData({
          ...values,
          status: values?.status,
          role_id: values?.role_id,
          name: values?.name,
        });
        break;
      case "hourly_production_record":
        fetchTableData({
          filter_date: values?.date
            ? moment(values?.date).format("YYYY-MM-DD")
            : null,
        });
        break;
      case "hourly_production_record_details":
        fetchTableData({
          filter_date: values?.date
            ? moment(values?.date).format("YYYY-MM-DD")
            : null,
          style_id: values?.style,
          buyer_id: values?.buyer,
          line_id: values?.line,
        });
        break;
      case "sub_sales_contract":
        fetchTableData({
          reference_no: values?.reference_no,
          factory_id: values?.factory_id,
        });
        break;
      case "ot_signal":
        fetchTableData({
          signal_id: values?.signal_id,
          signal_section_id: values?.signal_section_id,
        });
        break;
      case "osr":
        fetchTableData({
          pcd_start: values?.pcd_start ? values?.pcd_start : "",
          pcd_end: values?.pcd_end ? values?.pcd_end : "",
          shipment_start: values?.shipment_start ? values?.shipment_start : "",
          shipment_end: values?.shipment_end ? values?.shipment_end : "",
          buying_office_id: values?.buying_office_id
            ? values?.buying_office_id
            : "",
          buyer_id: values?.buyer_id ? values?.buyer_id : "",
          sc_type: values?.sc_type ? values?.sc_type : "",
        });
        break;
      case "buyer_inspection_date":
        fetchTableData({
          date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : null,
          style_id: values?.style_id,
        });
        break;
      case "capacity_bookings_report":
        fetchTableData({
          filterMonth: values?.filterMonth
            ? moment(values?.filterMonth).format("YYYY-MM")
            : null,
          // style_id: values?.style_id,
        });
        break;
      case "pp_meeting_date":
        fetchTableData({
          date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : null,
          style_id: values?.style_id,
        });
        break;
      case "buyer_visit_date":
        fetchTableData({
          date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : null,
          buyer_name: values?.buyer_name,
        });
        break;
      case "sewing_and_folding":
        fetchTableData({
          start_date: values?.start_date,
          end_date: values?.end_date,
        });
        break;
      case "sales_contract_pos":
        fetchTableData({
          start: values?.start,
          end: values?.end,
          sales_contract_id: values?.sales_contract_id,
          original_po: values?.original_po,
        });
        break;
      case "erp_usage_module":
        fetchTableData({
          module_name: values?.module_name,
          status: values?.status,
        });
        break;
      case "erp_usage_module_ui":
        fetchTableData({
          name: values?.name,
          module_id: values?.module_id,
          user_id: values?.user_id,
          tna_department_id: values?.tna_department_id,
          status: values?.status,
        });
        break;
      case "erp_usage_issue":
        fetchTableData({
          title: values?.title,
          ui_id: values?.ui_id,
          user_id: values?.user_id,
          assigned_to_user_id: values?.assigned_to_user_id,
          status: values?.status,
        });
        break;
      case "ob_filter":
        fetchTableData({
          inquiry_id: values?.inquiry_id,
          style_id: values?.style_id,
          sub_style_id: values?.sub_style_id,
        });
        break;
      case "he_ot_expense_data":
        fetchTableData({
          name: values?.name,
          date: values?.date,
          card_no: values?.card_no,
          line_id: values?.line_id,
        });
        break;
      case "digitalization-operator-efficiency":
        fetchTableData({
          checked_date: values?.checked_date
            ? moment(values?.checked_date).format("YYYY-MM-DD")
            : null,
          line_id: values?.line_id,
        });
        break;
      case "initial_sam_entry":
        fetchTableData({ ...values });
        break;
      default:
        break;
    }
  };

  const RenderFilterFields = ({ initialTableData, filterName }) => {
    switch (filterName) {
      case "organization":
        return <OrganizationFilterFields initialTableData={initialTableData} />;
      case "buyer":
        return <BuyerFilterFields initialTableData={initialTableData} />;
      case "buying_office":
        return <BuyingOfficeFilterFields initialTableData={initialTableData} />;
      case "item_category":
        return <ItemCategoryFilterFields initialTableData={initialTableData} />;
      case "item_list":
        return <ItemListFilterFields initialTableData={initialTableData} />;
      case "sewing_line":
        return <SewingLineFilterFields initialTableData={initialTableData} />;
      case "style":
        return <StyleFilterFields initialTableData={initialTableData} />;
      case "sales_contract":
        return (
          <SalesContractFilterFields initialTableData={initialTableData} />
        );
      case "sub_sales_contract":
        return (
          <SubSalesContractFilterFields initialTableData={initialTableData} />
        );
      case "sales_contract_commercial":
        return (
          <SalesContractCommercialFilterFields
            initialTableData={initialTableData}
          />
        );
      case "import_pi":
        return <ImportPiFilterFields initialTableData={initialTableData} />;
      case "export_details":
        return (
          <ExportDetailsFilterFields initialTableData={initialTableData} />
        );
      case "noting_assessment":
        return (
          <NotingAssessmentFilterFields initialTableData={initialTableData} />
        );
      case "security_bolt":
        return <SecurityBoltFilterFields initialTableData={initialTableData} />;
      case "shipment_handover":
        return (
          <ShipmentHandoverFilterFields initialTableData={initialTableData} />
        );
      case "import_b2blc":
        return <ImportB2blcFilterFields initialTableData={initialTableData} />;
      case "submission_realization":
        return (
          <SubmissionRealizationFilterFields
            initialTableData={initialTableData}
          />
        );
      case "purchase_order":
        return (
          <PurchaseOrderFilterFields initialTableData={initialTableData} />
        );
      case "bom":
        return <BOMFilterFields initialTableData={initialTableData} />;
      case "configuration":
        return (
          <ConfigurationFilterFields initialTableData={initialTableData} />
        );
      case "unit_convertion":
        return (
          <UnitConvertionFilterFields initialTableData={initialTableData} />
        );
      case "activity_log":
        return <ActivityLogFilterFields initialTableData={initialTableData} />;
      case "packing_list":
        return <PackingFilterFields initialTableData={initialTableData} />;
      case "inventory_mrr":
        return <InventoryMRRFilterFields initialTableData={initialTableData} />;
      case "inventory_details":
        return (
          <InventoryDetailFilterFields initialTableData={initialTableData} />
        );
      case "packing_report":
        return (
          <PackingReportFilterFields initialTableData={initialTableData} />
        );
      case "buyer_packing_report":
        return (
          <BuyerPackingReportFilterFields initialTableData={initialTableData} />
        );
      case "fabric_reconcil":
        return (
          <FabricReconcilFilterFields initialTableData={initialTableData} />
        );
      case "osr":
        return <OSRFilterFields initialTableData={initialTableData} />;
      case "import_detail":
        return (
          <ImportDetailFilterFields
            initialTableData={initialTableData}
            setSupplierType={setSupplierType}
          />
        );
      case "import_acceptance_detail":
        return (
          <ImportAcceptanceDetailFilterFields
            initialTableData={initialTableData}
          />
        );
      case "cost_estimate":
        return <CostEstimateFilterFields initialTableData={initialTableData} />;
      case "users_list":
        return <UsersFilterFields initialTableData={initialTableData} />;
      case "production_details":
        return (
          <ProductionDetailsFilterFileds initialTableData={initialTableData} />
        );
      case "post-costing-report":
        return <PostCostingReportFilter initialTableData={initialTableData} />;
      case "user_role_filter":
        return <UserRoleFilterFields initialTableData={initialTableData} />;
      case "notification_slugs":
        return (
          <NotificationSlugsFilterFields initialTableData={initialTableData} />
        );
      case "notification_settings":
        return (
          <NotificationSettingsFilterFields
            initialTableData={initialTableData}
          />
        );
      case "local_purchase_reconciliation":
        return (
          <LocalPurchaseReconciliationFilterFields
            initialTableData={initialTableData}
          />
        );
      case "import_vehicle_status":
        return (
          <ImportVehicleStatusFilterFields
            initialTableData={initialTableData}
          />
        );
      case "learning_curve":
        return (
          <LearningCurveFilterFields initialTableData={initialTableData} />
        );
      case "sewing":
        return (
          <LearningCurveFilterFields initialTableData={initialTableData} />
        );
      case "buyer_inquiry":
        return <BuyerInquiryFilterFields initialTableData={initialTableData} />;
      case "tna_process":
        return <TNAProcessFields initialTableData={initialTableData} />;
      case "tna_department":
        return <TNADepartmentFields initialTableData={initialTableData} />;
      case "tna_task":
        return <TNATaskFields initialTableData={initialTableData} />;
      case "tna_todo":
        return <TNATodoFilter initialTableData={initialTableData} />;
      case "sewing_filter":
        return <SewingFilterFields initialTableData={initialTableData} />;
      case "cutting_filter":
        return (
          <CuttingFilterFields
            initialTableData={initialTableData}
            type={"cutting_filter"}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
          />
        );
      case "post_cutting_filter":
        return (
          <CuttingFilterFields
            initialTableData={initialTableData}
            type={"post_cutting_filter"}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
          />
        );
      case "assignment_filter":
        return <AssignmentFilterFields initialTableData={initialTableData} />;
      case "folding_filter":
        return <FoldingFilterFields initialTableData={initialTableData} />;
      case "packing_filter":
        return <PackingFilterFields initialTableData={initialTableData} />;
      case "bolt_seal_log":
        return <BoltSealLogFilterField initialTableData={initialTableData} />;
      case "vehicle_and_driver_information":
        return (
          <VehicleAndDriverInformationFilterField
            initialTableData={initialTableData}
          />
        );
      case "report_bolt_seal":
        return (
          <ReportBoltSealFilterFields initialTableData={initialTableData} />
        );
      case "supervisor_checklist":
        return (
          <SupervisorChecklistFilterField initialTableData={initialTableData} />
        );
      case "production_defect":
        return (
          <ProductionDefectFilterField initialTableData={initialTableData} />
        );
      case "hourly_production_record":
        return (
          <HourlyProductionRecFilterField initialTableData={initialTableData} />
        );
      case "hourly_production_record_details":
        return (
          <HourlyProRecDetailsFilter initialTableData={initialTableData} />
        );
      case "operator_absenteeism":
        return (
          <OperatorAbsenteeismFilterField initialTableData={initialTableData} />
        );
      case "digitalization_app_role_list":
        return <DigitalAppRoleFilter initialTableData={initialTableData} />;
      case "digitalization_defect_category":
        return (
          <DigitalDefectCategoryFilter initialTableData={initialTableData} />
        );
      case "digitalization_defect_reason":
        return (
          <DigitalDefectReasonFilter initialTableData={initialTableData} />
        );
      case "digitalization_defect_responsible":
        return (
          <DigitalDefectResponsibleFilter initialTableData={initialTableData} />
        );
      case "digitalization_defect_recommendation":
        return (
          <DigitalDefectRecommendationFilter
            initialTableData={initialTableData}
          />
        );
      case "digitalization_daily_line_plan":
        return (
          <DailyLinePlanFilter
            initialTableData={initialTableData}
            form={form}
          />
        );
      case "digitalization_defect_category_mapping":
        return (
          <DigitalDefectCategoryMappingFilter
            initialTableData={initialTableData}
          />
        );
      case "digitalization_defect_line_section_mapping":
        return (
          <DigitalDefectLineSectionMappingFilter
            initialTableData={initialTableData}
          />
        );
      case "buyer_style_checklist_filter":
        return (
          <BuyerStyleChecklistFilterFields
            initialTableData={initialTableData}
          />
        );
      case "checklist-questions-filter":
        return <ChecklistQuestionsFilter initialTableData={initialTableData} />;
      case "digitalization_user_line_assign_list":
        return (
          <DigitalizationUserLineAssignList
            initialTableData={initialTableData}
          />
        );
      case "merchandising-sub-contract-factory":
        return (
          <MerchandisingSubContractFactory
            initialTableData={initialTableData}
          />
        );
      case "ot_signal":
        return <OTSignalFilterFields initialTableData={initialTableData} />;
      case "digitalization-garments-tracking":
        return (
          <DigitalizationGarmentsTrackingFilter
            initialTableData={initialTableData}
          />
        );
      case "buyer_inspection_date":
        return (
          <BuyerInspectionDateFilterFields
            initialTableData={initialTableData}
          />
        );
      case "capacity_bookings_report":
        return (
          <CapacityBookingsReportFilterFields
            initialTableData={initialTableData}
          />
        );
      case "pp_meeting_date":
        return (
          <PPMeetingDateFilterFields initialTableData={initialTableData} />
        );
      case "buyer_visit_date":
        return (
          <BuyerVisitDateFilterFields initialTableData={initialTableData} />
        );
      case "sewing_and_folding":
        return (
          <SwingvsFoldingFilterFields initialTableData={initialTableData} />
        );
      case "sales_contract_pos":
        return (
          <SalesContractPOSFilterFields initialTableData={initialTableData} />
        );
      case "erp_usage_module":
        return (
          <ERPUsageModuleFilterFields initialTableData={initialTableData} />
        );
      case "erp_usage_module_ui":
        return (
          <ERPUsageModuleUiFilterFields initialTableData={initialTableData} />
        );
      case "erp_usage_issue":
        return (
          <ERPUsageIssueFilterFields initialTableData={initialTableData} />
        );
      case "ob_filter":
        return <OBFilterFields initialTableData={initialTableData} />;
      case "he_ot_expense_data":
        return <HROTExpenseDataFilter initialTableData={initialTableData} />;
      case "digitalization-operator-efficiency":
        return (
          <DigitalizationOperatorEfficiencyFilter
            initialTableData={initialTableData}
          />
        );
      case "initial_sam_entry":
        return <InitialSAMEntryFilter initialTableData={initialTableData} />;

      default:
        return <></>;
    }
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={(values) => onFinish(values, initialTableData, filterName)}
      layout="vertical"
    >
      <Row gutter={6}>
        <RenderFilterFields
          initialTableData={initialTableData}
          filterName={filterName}
        />
        <Col span={customButton || filterName === "import_detail" ? 8 : 4}>
          <Form.Item label=" ">
            {customButton}
            <Button type="primary" htmlType="submit" size="small">
              {searchButtonText ? searchButtonText : "Search"}
            </Button>
            {isPreview && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                size="small"
                onClick={async () => {
                  const value = form.getFieldsValue();
                  try {
                    await form.validateFields();
                    await previewFunction(value);
                  } catch (error) {
                    // Handle Errors here
                  }
                }}
              >
                Preview
              </Button>
            )}
            {filterName === "import_detail" && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                size="small"
                onClick={() => {
                  form.submit();
                  importDownload(form.getFieldsValue());
                }}
                disabled={disabledButton}
              >
                Download
              </Button>
            )}
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => {
                form.resetFields();
                localStorage.removeItem(`${filterName}_filter_value`);
                onFinish(null, null, filterName);
              }}
              size="small"
            >
              Clear
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

const SearchForm = (props) => {
  const {
    tableData,
    filterName,
    fetchTableData,
    filterAPI,
    searchButtonText,
    customButton,
    importDownload,
    disabledButton,
    isPreview = false,
    previewFunction = () => {},
    setTableData,
    setSupplierType,
    filterValues,
    setFilterValues,
  } = props;
  const [initialTableData, setInitialTableData] = useState(null);

  useEffect(() => {
    // Only set initial data for the first time load
    if (tableData) {
      setInitialTableData(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AdvancedSearchForm
        tableData={tableData}
        initialTableData={initialTableData}
        filterName={filterName}
        fetchTableData={fetchTableData}
        filterAPI={filterAPI}
        searchButtonText={searchButtonText}
        customButton={customButton}
        importDownload={importDownload}
        disabledButton={disabledButton}
        isPreview={isPreview}
        previewFunction={previewFunction}
        setTableData={setTableData}
        setSupplierType={setSupplierType}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
    </div>
  );
};

export default memo(SearchForm);
