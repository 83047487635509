import { Button, Col, Row } from "antd";
import SearchForm from "../../../common/SearchForm";

const CustomFilter = (props) => {
  // Props
  const {
    showFilter,
    tableData,
    fetchTableData,
    filterName,
    searchButtonText,
    handleFilter,
    customButton,
    importDownload,
    disabledButton,
    isPreview = false,
    previewFunction = () => {},
    setTableData,
    setSupplierType
  } = props;

  return (
    <div className="table-header">
      {handleFilter && (
        <Row>
          <Col span={4} offset={20}>
            <Button
              type={showFilter ? "danger" : "primary"}
              size="small"
              onClick={handleFilter}
              style={{ float: "right" }}
            >
              {`${showFilter ? "Close" : "Open"} Filter Option`}
            </Button>
          </Col>
        </Row>
      )}

      {showFilter && (
        <SearchForm
          tableData={tableData}
          filterName={filterName}
          fetchTableData={fetchTableData}
          searchButtonText={searchButtonText}
          customButton={customButton}
          importDownload={importDownload}
          disabledButton={disabledButton}
          isPreview={isPreview}
          previewFunction={previewFunction}
          setTableData={setTableData}
          setSupplierType={setSupplierType}
        />
      )}
    </div>
  );
};

export default CustomFilter;
