import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postData, getData } from "../../../../apiServices/common";
import AddPiForm from "./AddPiForm";
import { PI_APPROVE_PERMISSION } from "../../../../routes/permissions";
import { hasPermission } from "../../../../utils/functions";

export default function EditExportSalesContract(props) {

  let { importId } = useParams();

  let show_approve = 1;

  const { width } = props;
  const propertyFormRef = useRef(null);
  const [permitInfo, setPermitInfo] = useState();
  const [piStatus, setPiStatus] = useState();
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const discartForm = () => {
    // propertyFormRef.current.discart();
    navigate("/import/pi");
  };

  const addProduct = () => {
    // alert("adfafd");
    // return false;
    form.submit();
    //propertyFormRef.current.submit();
  };
  // const base_url = process.env.REACT_APP_BASE;
  const edit_url = `/api/accounting/import-pi/view/${importId}`;
  const getPIInfo = async () => {
    console.log("working");
    let res = await getData(edit_url);
    console.log(res);
    if (res) {
      let masterData = res?.data?.data[0];
      console.log("update data,", masterData);
      let piStatus = setPiStatus(masterData?.sales_contract_commercial?.status);
      setPermitInfo(masterData);
    }
  };

  useEffect(() => {
    getPIInfo();
  }, []);

  //console.log("propertyFormRef",propertyFormRef);console.log("permitInfo",permitInfo)
  const checkStatus = ["Created", "Updated"];
  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="View Proforma Invoice"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} disabled>
                {piStatus}
              </Button>,
              <Button size="small" key={1} danger onClick={() => discartForm()}>
                Discard
              </Button>,
              checkStatus.includes(piStatus) && (
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => addProduct()}
                  disabled={!hasPermission([PI_APPROVE_PERMISSION])}
                >
                  {hasPermission([PI_APPROVE_PERMISSION])
                    ? "Approve"
                    : "No Permission"}
                </Button>
              ),
            ]}
          />
        </Card>
      </Affix>

      <AddPiForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        form={form}
        view={true}
        show_approve={show_approve}
      />
    </>
  );
}
