import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import { Form } from "antd";
import Modal from "antd/es/modal/Modal";
import {onUpdateSelectedItemCategory, onCreateItemCategory} from "../../../redux/actions";
import ItemCategoryForm from "./CreateCategoryForm";

const CreateItemCategory = (props) => {
    const dispatch = useDispatch();
    const {isAddItemCategory, handleAddCategoryClose, selectedItemCategory, onUpdateItemCategory} = props;
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        let attribute_name = [];

        selectedItemCategory?.attribute_info?.forEach((item, index) => {
            attribute_name.push(item?.name);
        });
        form.setFieldValue("attribute_name", attribute_name);

    }, [selectedItemCategory, form]);

    const onChangeCheckbox = (e) => {
        form.setFieldsValue({
            use_fabric_interlining_store: e.target.checked,
        });
    };
    const onChangeCheckboxcategory_wise = (e) => {
        form.setFieldsValue({
            category_wise_view_in_pi: e.target.checked,
        });
    };

    return (
      <>
        <Modal
          forceRender
          open={isAddItemCategory}
          title={
            selectedItemCategory
              ? `Update Item Category`
              : "Create New Item Category"
          }
          okText={"Save"}
          cancelText="Discard"
          cancelButtonProps={{ type: "danger" }}
          onCancel={() => {
            handleAddCategoryClose();
            // form.resetFields()
          }}
          onOk={(event) => {
            form
              .validateFields()
              .then((values) => {
                if (selectedItemCategory) {
                  let i = 0;
                  const length = values.attribute_name.length;
                  const attribute_names = [];
                  const attribute_ids = [];
                  for (i; i < length; i++) {
                    attribute_names.push(values.attribute_name[i]);
                    attribute_ids.push("");
                  }
                  const updateObj = {
                    id: selectedItemCategory.id,
                    name: selectedItemCategory.name,
                    category_unique_id: selectedItemCategory.category_unique_id,
                    status: selectedItemCategory.status,
                    attribute_name: attribute_names,
                    attribute_id: attribute_ids,
                    use_fabric_interlining_store:
                      values?.use_fabric_interlining_store ? "Yes" : "No",
                    category_wise_view_in_pi: values?.category_wise_view_in_pi
                      ? "Yes"
                      : "No",
                  };
                  dispatch(onUpdateSelectedItemCategory(updateObj));
                  props.refetch();
                  form.resetFields();
                } else {
                  let i = 0;
                  const length = values.attribute_name.length;
                  const attribute_names = [];
                  const attribute_ids = [];
                  for (i; i < length; i++) {
                    attribute_names.push(values.attribute_name[i]);
                    attribute_ids.push("");
                  }
                  const newCategory = {
                    name: values.name,
                    category_unique_id: values.category_unique_id,
                    attribute_name: attribute_names,
                    attribute_id: attribute_ids,
                    status: values.status,
                    use_fabric_interlining_store:
                      values?.use_fabric_interlining_store ? "Yes" : "No",
                    category_wise_view_in_pi: values?.category_wise_view_in_pi
                      ? "Yes"
                      : "No",
                  };
                  dispatch(onCreateItemCategory(newCategory));
                  props.refetch();
                }
                selectedItemCategory && handleAddCategoryClose();
                form.resetFields();
              })
              .catch((info) => {
                // console.log('Validate Failed:', info);
              });
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            preserve={false}
            initialValues={{
              status: selectedItemCategory
                ? selectedItemCategory.status
                : "active",
              name: selectedItemCategory ? selectedItemCategory.name : "",
              use_fabric_interlining_store:
                selectedItemCategory?.use_fabric_interlining_store === "Yes"
                  ? true
                  : false,
              category_wise_view_in_pi:
                selectedItemCategory?.category_wise_view_in_pi === "Yes"
                  ? true
                  : false,
            }}
          >
            <ItemCategoryForm
              onChangeCheckbox={onChangeCheckbox}
              onChangeCheckboxcategory_wise={onChangeCheckboxcategory_wise}
            />
          </Form>
        </Modal>
      </>
    );

}

export default CreateItemCategory;