import { Table, Typography, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import {
  DELETE_FABRIC_RECONCILIATION,
  EXPORT_FABRIC_RECONCILIATION,
  GET_FABRIC_RECONCILIATION,
  VIEW_FABRIC_RECONCILIATION,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import { FABRIC_REPORT_GENERATE_PERMISSION } from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const FabricReconcil = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/fabric_report/")[1] : "";
    return updatedPath;
  };

  const getAllFabric = useCallback(async () => {
    setLoading(true);
    const query = `${GET_FABRIC_RECONCILIATION}`;

    const response = await getData(query);
    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting fabric data");
    }
  }, []);

  const exportFabric = async (filterValues) => {
    const { start_date, end_date, sales_contract_id, style_id, buying_office_id, buyer_id, first_party } = filterValues;
    const bodyData = {
      ...(start_date && {
        start_date: moment(start_date).format("YYYY-MM-DD"),
      }),
      ...(end_date && { end_date: moment(end_date).format("YYYY-MM-DD") }),
      ...(sales_contract_id && { sales_contract_id }),
      ...(buying_office_id && { buying_office_id }),
      ...(buyer_id && { buyer_id }),
      ...(first_party && { first_party }),
      ...(style_id && { style_id }),
    };
    if (
      Object.values(bodyData).length &&
      !Object.values(bodyData).some((val) => val === undefined)
    ) {
      setLoading(true);

      const query = `${EXPORT_FABRIC_RECONCILIATION}`;

      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "blob" },
        "arraybuffer",
      );

      if (response && response?.data) {
        // Download the file
        const outputFilename = `fabric_reconciliation_report.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setLoading(false);
        message.success("Fabric Exported Successfully");
        refetch();
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };
  const previewFunction = async (filterValues) => {
    setLoading(true);

    const bodyData = {
      ...filterValues,
    };

    const query = `${VIEW_FABRIC_RECONCILIATION}`;
    const response = await postData(query, bodyData);

    if (response?.code === 200) {
      setLoading(false);
      setTableData(response?.data);
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };
  // Effects
  // useEffect(() => {
  //   getAllFabric();
  // }, [refresh, getAllFabric]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedFabric = async (name) => {
    setLoading(true);
    const updatedName = cutPath(name);
    const query = `${DELETE_FABRIC_RECONCILIATION}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setLoading(false);
      message.success("Fabric Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/fabric_report/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  const tableColumns = [
    {
      title: "Fabric Re-Conciliation",
      children: [
        {
          title: "Buying Office",
          dataIndex: "buying_office",
          key: "buying_office",
          width: 200,
        },
        {
          title: "Buyer",
          dataIndex: "buyer",
          key: "buyer",
          width: 200,
        },
        {
          title: "Factory/1st Party",
          dataIndex: "factory",
          key: "factory",
          width: 200,
        },
        {
          title: "Sales Contract",
          dataIndex: "sales_contract",
          key: "sales_contract",
          width: 200,
        },
        {
          title: "Style",
          dataIndex: "style",
          key: "style",
          width: 200,
        },
        {
          title: "Item",
          dataIndex: "item",
          key: "item",
          width: 200,
        },
        {
          title: "O.Qty",
          dataIndex: "order_quantity",
          key: "order_quantity",
          width: 200,
        },
        {
          title: "Req  Qty",
          dataIndex: "required_quantity",
          key: "required_quantity",
          width: 200,
        },
        {
          title: "Received",
          dataIndex: "total_received",
          key: "total_received",
          width: 200,
        },
        {
          title: "Issued",
          dataIndex: "total_issued",
          key: "total_issued",
          width: 200,
        },
        {
          title: "Used",
          dataIndex: "total_used",
          key: "total_used",
          width: 200,
        },
        {
          title: "Return From Cutting",
          dataIndex: "return_from_cutting",
          key: "return_from_cutting",
          width: 200,
        },
        {
          title: "Balance",
          dataIndex: "balance",
          key: "balance",
          width: 200,
        },
        {
          title: "Assigned From Free",
          dataIndex: "assign_from_free",
          key: "assign_from_free",
          width: 200,
        },
        {
          title: "Free Balance",
          dataIndex: "free_balance",
          key: "free_balance",
          width: 200,
        },
        {
          title: "Sent to Buying Office",
          dataIndex: "send_buying_office",
          key: "send_buying_office",
          width: 200,
        },
        {
          title: "Return To Supplier",
          dataIndex: "return_supplier",
          key: "return_supplier",
          width: 200,
        },
        {
          title: "Closing Balance",
          dataIndex: "closing_balance",
          key: "closing_balance",
          width: 200,
        },
        {
          title: "Supplier",
          dataIndex: "supplier",
          key: "supplier",
          width: 200,
        },
        {
          title: "Substyle",
          dataIndex: "sub_style",
          key: "sub_style",
          width: 200,
        },
        {
          title: "Location",
          dataIndex: "location",
          key: "location",
          width: 200,
        },
      ],
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={tableData}
        filterAPI={exportFabric}
        filterName={"fabric_reconcil"}
        searchButtonText={"Export"}
        defaultOpen
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        isPreview={true}
        previewFunction={previewFunction}
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportFabric}
                hideAddButton={true}
                disabled={!hasPermission([FABRIC_REPORT_GENERATE_PERMISSION])}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            }
            title={<Title level={5}>Fabric Reconciliations</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              {memoizedCustomFilter}
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination
                bordered
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default FabricReconcil;
