import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, InputNumber, Popover } from "antd";
import { useMemo } from "react";
import { isArrayAndHasValue } from "utils/functions";

const SewingTable = (props) => {
  // Props
  const { sewingTableData, onChangeSewingItem,searchForm } = props;

  // Watch Antd Form
  let style = Form.useWatch("style", searchForm);
  let color_substyle = Form.useWatch("color_substyle", searchForm);

  style = style?.toLowerCase();
  color_substyle = color_substyle?.toLowerCase();

  const getTotalCount = (data, context) => {
    const total = data.reduce((total, currentValue) => {
      return total + (currentValue[context] || 0);
    }, 0);

    return total ? total.toFixed(2) : 0;
  };

  const filterTableData = useMemo(() => {
    let filterData = [];
    if (style) {
      filterData = sewingTableData.filter((item) => {
        return item.style_no?.toLowerCase()?.includes(style);
      });
      if (color_substyle) {
        filterData = filterData.filter((item) => {
          return item.color_name?.toLowerCase()?.includes(color_substyle) || item.sub_style_no?.toLowerCase()?.includes(color_substyle);
        });
        return filterData;
      }
      return filterData;
    } else if (color_substyle) {
      filterData = sewingTableData.filter((item) => {
        return item.color_name?.toLowerCase()?.includes(color_substyle) || item.sub_style_no?.toLowerCase()?.includes(color_substyle);
      });
      return filterData;
    } else {
      return sewingTableData;
    }

  }, [sewingTableData, style, color_substyle]);

  const TableSummary = () => {
    return (
      <tr>
        <td className="t-body bc-white" colSpan="7">
          Total
        </td>
        <td className="t-header bc-white" key={`total_count_order_qty`}>
          {getTotalCount(filterTableData, "order_quantity")}
        </td>
        <td className="t-header bc-white" key={`total_count_assigned_qty`}>
          {getTotalCount(filterTableData, "assigned")}
        </td>
        <td className="t-header bc-white" key={`total_count_sewing_this_hour`}>
          {getTotalCount(filterTableData, "sewing_piece")}
        </td>
        <td className="t-header bc-white" key={`total_count_sewing`}>
          {getTotalCount(filterTableData, "total_sewing_piece")}
        </td>
        <td className="t-header bc-white" key={`total_count_reject_this_hour`}>
          {getTotalCount(filterTableData, "rejected_piece")}
        </td>
        <td className="t-header bc-white" key={`total_count_reject`}>
          {getTotalCount(filterTableData, "total_rejected_piece")}
        </td>
        <td className="t-header bc-white" key={`total_count_reject_percentage`}>
          {getTotalCount(filterTableData, "rejected_percent")}
        </td>
        <td className="t-header bc-white" key={`total_count_wip`}>
          {getTotalCount(filterTableData, "wip")}
        </td>
        <td className="t-header bc-white" key={`total_count_total_wip`}>
          {getTotalCount(filterTableData, "total_wip")}
        </td>
        <td className="t-header bc-white" key={`total_count_sewing_day`}>
          {getTotalCount(filterTableData, "sewing_day")}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <table border="1" className="custome-table">
        <thead>
          <tr>
            <th colSpan={9}></th>
            <th colSpan={2} className="bc-orange text-center">
              Sewing (pc)
            </th>
            <th colSpan={3} className="bc-orange text-center">
              Reject (pc)
            </th>
          </tr>
          <tr>
            <th className="bc-java text-center">Buyer Name</th>
            <th className="bc-java text-center">Buying Office Name</th>
            <th className="bc-java text-center">PO</th>
            <th className="bc-java text-center">Style</th>
            <th className="bc-java text-center">Color</th>
            <th className="bc-java text-center">Sub-style</th>
            <th className="bc-java text-center">Size</th>
            <th className="bc-java text-center">Order Qty (pc)</th>
            <th className="bc-java text-center">Assigned Qty (pc)</th>
            <th className="bc-java text-center">This Hour</th>
            <th className="bc-java text-center">Total</th>
            <th className="bc-java text-center">This Hour</th>
            <th className="bc-java text-center">Total</th>
            <th className="bc-java text-center">%</th>
            <th className="bc-java text-center">WIP (pc)</th>
            <th className="bc-java text-center">Total WIP (pc)</th>
            <th className="bc-java text-center">Sewing Day</th>
            <th className="bc-java text-center">Info</th>
          </tr>
        </thead>
        <tbody className="table-container">
          {isArrayAndHasValue(filterTableData) &&
            filterTableData?.map((item, index) => {
              return (
                <tr key={`table_parent_key_${index}`}>
                  <td className="t-body sticky">{item?.buyer_name}</td>
                  <td className="t-body sticky">{item?.buying_office_name}</td>
                  {/* PO */}
                  <td className="t-body sticky">
                    {item?.original_po ? item?.original_po : item?.internal_po}
                  </td>
                  {/* Style */}
                  <td className="t-body sticky">{item?.style_no}</td>
                  {/* Color */}
                  <td className="t-body sticky">{item?.color_name}</td>
                  {/* Sub-style */}
                  <td className="t-body sticky">{item?.sub_style_no}</td>
                  {/* Size */}
                  <td className="t-body sticky">{item?.size_name}</td>
                  {/* Order Qty */}
                  <td className="t-body sticky">{item?.order_quantity}</td>
                  {/* Assigned Qty */}
                  <td className="t-body sticky">{item?.assigned}</td>
                  {/* Sewing This Hour */}
                  <td className="t-body sticky">
                    <InputNumber
                      value={item?.sewing_piece || 0}
                      onChange={(value) =>
                        onChangeSewingItem(value, item?.id, "sewing_piece")
                      }
                      size="small"
                      className="move-right"
                      controls={false}
                      max={item?.total_wip - (item?.rejected_piece || 0)}
                    />
                  </td>
                  {/* Sewing Total */}
                  <td className="t-body sticky">{item?.total_sewing_piece}</td>
                  {/* Reject This Hour */}
                  <td className="t-body sticky">
                    <InputNumber
                      value={item?.rejected_piece || 0}
                      onChange={(value) =>
                        onChangeSewingItem(value, item?.id, "rejected_piece")
                      }
                      size="small"
                      className="move-right"
                      controls={false}
                      max={item?.total_wip - (item?.sewing_piece || 0)}
                    />
                  </td>
                  {/* Reject Total */}
                  <td className="t-body sticky">
                    {item?.total_rejected_piece}
                  </td>
                  {/* Reject Percent % */}
                  <td className="t-body sticky">
                    {item?.rejected_percent
                      ? item?.rejected_percent.toFixed(2)
                      : ""}
                  </td>
                  {/* WIP */}
                  <td className="t-body sticky">{item?.wip}</td>
                  {/* Total WIP */}
                  <td className="t-body sticky">{item?.total_wip}</td>
                  {/* Sewing Day */}
                  <td className="t-body sticky">
                    {item?.sewing_day ? Number(item?.sewing_day) : ""}
                  </td>
                  {/* Info Icon */}
                  <td className="t-body sticky">
                    <Popover
                      content={
                        <table className="component-api-table">
                          <tbody>
                            <tr>
                              <td>Buyer Name</td>
                              <td>{item?.buyer_name}</td>
                            </tr>
                            <tr>
                              <td>Buying Office Name</td>
                              <td>{item?.buying_office_name}</td>
                            </tr>
                            {item?.sub_contract_factory_name && (
                              <tr>
                                <td>Factory Name</td>
                                <td>{item?.sub_contract_factory_name}</td>
                              </tr>
                            )}
                            <tr>
                              <td>Internal Po</td>
                              <td>{item?.internal_po}</td>
                            </tr>
                            <tr>
                              <td>Original Po</td>
                              <td>{item?.original_po}</td>
                            </tr>
                            <tr>
                              <td>Sales Contact</td>
                              <td>{item?.reference_no}</td>
                            </tr>
                          </tbody>
                        </table>
                      }
                      title={
                        <div className="">
                          <h3>Sewing Info</h3>
                        </div>
                      }
                    >
                      <div className="centered-item-parent">
                        <InfoCircleOutlined
                          className="cursore-pointer"
                          style={{ fontSize: "20px", color: "green" }}
                        />
                      </div>
                    </Popover>
                  </td>
                </tr>
              );
            })}

          {/* Table Summary */}
          {isArrayAndHasValue(filterTableData) && <TableSummary />}
        </tbody>
      </table>
    </div>
  );
};

export default SewingTable;
