import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Typography,
} from "antd";
import { Descriptions } from "antd/es";
import { getData, postData } from "apiServices/common";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import AppPageHeader from "../../../../common/PageHeader";

const UpdateGarmentsTracking = (props) => {
  const [garmentsTrackingDetails, setGarmentsTrackingDetails] = useState();
  const [garmentsTracking, setGarmentsTracking] = useState();
  const [changeGarmentsTracking, setChangeGarmentsTracking] = useState([]);

  //   Routes
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  // get search params
  const searchParams = new URLSearchParams(location.search);
  const date = searchParams.get("date");
  const line_id = searchParams.get("line_id");
  const buyer_id = searchParams.get("buyer_id");
  const style_id = searchParams.get("style_id");
  const sub_style_id = searchParams.get("sub_style_id");
  const po_map_id = searchParams.get("po_map_id");
  const color_id = searchParams.get("color_id");
  const size_id = searchParams.get("size_id");
  const buying_office_id = searchParams.get("buying_office_id");
  const sub_contract_factory_id = searchParams.get("sub_contract_factory_id");
  const sales_contract_id = searchParams.get("sales_contract_id");

  //   Antd Constants
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const body = values.garmentsTracking.map((item) => {
        return {
          id: item.id,
          hour: item.hour,
          defect_garments_qty: item.defect_garments_qty,
          good_garments_qty: item.good_garments_qty,
          reject_garments_qty: item.reject_garments_qty,
        };
      });
      const payload = {
        date: dayjs(garmentsTrackingDetails.date).format("YYYY-MM-DD"),
        line_id,
        buyer_id,
        style_id,
        sub_style_id,
        po_map_id,
        color_id,
        size_id,
        buying_office_id,
        sub_contract_factory_id,
        sales_contract_id,
        details: body,
      }
      const query = `/api/digitalization/garments-tracking-update-qty`;
      const response = await postData(query, payload);

      if (response?.code === 200) {
        form.resetFields();
        navigate("/digitalization/garments-tracking");
        message.success("Garments Tracking Updated Successfully");
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to update Garments Tracking");
    }
  };

  const extra = (
    <div className="extra-button-container">
      <Button
        size="small"
        htmlType="submit"
        style={{ marginRight: 5 }}
        onClick={() => {
          form.resetFields();
          navigate("/digitalization/garments-tracking");
        }}
      >
        {" "}
        Back
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        onClick={() => form.submit()}
      >
        {" "}
        Save
      </Button>
    </div>
  );

  useEffect(() => {
    if (id) {
      const init = async () => {
        try {
          const response = await getData(
            `/api/digitalization/garments-tracking-web-details`,
            null,
            {
              ...(line_id !== "undefined" && { line_id }),
              ...(buyer_id !== "undefined" && { buyer_id }),
              ...(style_id !== "undefined" && { style_id }),
              ...(sub_style_id !== "undefined" && { sub_style_id }),
              ...(po_map_id !== "undefined" && { po_map_id }),
              ...(color_id !== "undefined" && { color_id }),
              ...(size_id !== "undefined" && { size_id }),
              ...(buying_office_id !== "undefined" && { buying_office_id }),
              ...(sub_contract_factory_id !== "undefined" && {
                sub_contract_factory_id,
              }),
              ...(sales_contract_id !== "undefined" && { sales_contract_id }),
              ...(date !== "undefined" && {
                date: dayjs(date).format("YYYY-MM-DD"),
              }),
            },
          );
          const data = response.data?.data;
          console.log({
            data,
          });
          setGarmentsTrackingDetails(data[0]);
          setGarmentsTracking(data);
          // form.setFieldsValue({
          //   garmentsTracking: data,
          // });
          form.setFieldsValue({
            garmentsTracking: data.map((item) => ({
              ...item,
              user_id: item.user_info
                ? `${item.user_info.name} (${item.user_info.id_card_no})`
                : "N/A",
            })),
          });
        } catch (error) {
          console.log(error);
          message.error("Failed to fetch Garments Tracking Details ");
          setGarmentsTrackingDetails(null);
        }
      };
      init();
    }
  }, [id]);

  return (
    <>
      <AppPageHeader
        title={
          <Typography.Title level={5}>
            Update Garments Tracking
          </Typography.Title>
        }
        extra={extra}
      />

      <Layout.Content className="item-details">
        <Card
          title="Garments Tracking Details"
          bordered={false}
          style={{
            marginTop: 5,
          }}
        >
          {garmentsTrackingDetails && (
            <Descriptions column={4}>
              <Descriptions.Item label="Date">
                {dayjs(garmentsTrackingDetails.date).format("YYYY-MM-DD")}
              </Descriptions.Item>
              <Descriptions.Item label="Line Name">
                {garmentsTrackingDetails.line_info?.name || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Buyer Name">
                {garmentsTrackingDetails.buyer_info?.name || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Style Name">
                {garmentsTrackingDetails.style_info?.style_no || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Sub Style Name">
                {garmentsTrackingDetails.sub_style_info?.sub_style_no || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Po Map Name">
                {garmentsTrackingDetails.po_map_info?.original_po || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Color Name">
                {garmentsTrackingDetails.color_info?.name || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Size">
                {garmentsTrackingDetails.size_info?.name || "N/A"}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
        <Card
          title="Garments Tracking Details"
          style={{
            marginTop: 20,
            boxShadow: "none",
          }}
          bordered={false}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.List name="garmentsTracking">
              {(fields) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Card
                      key={key}
                      style={{ marginTop: 10, boxShadow: "none" }}
                    >
                      <Row gutter={24} align={"middle"}>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "user_id"]}
                            fieldKey={[fieldKey, "user_id"]}
                            label="Entry By"
                            initialValue={
                              garmentsTracking[name]?.user_info?.name || "N/A"
                            }
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item
                            hidden
                            {...restField}
                            name={[name, "id"]}
                            fieldKey={[fieldKey, "id"]}
                          >
                            <InputNumber disabled hidden />
                          </Form.Item>
                          <Form.Item
                            label="Hour"
                            {...restField}
                            name={[name, "hour"]}
                            fieldKey={[fieldKey, "hour"]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item
                            label="Time Range"
                            {...restField}
                            name={[name, "hour_time_range"]}
                            fieldKey={[fieldKey, "hour_time_range"]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "defect_garments_qty"]}
                            fieldKey={[fieldKey, "defect_garments_qty"]}
                            label="Defect Garments Qty"
                          >
                            <InputNumber
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "good_garments_qty"]}
                            fieldKey={[fieldKey, "good_garments_qty"]}
                            label="Good Garments Qty"
                          >
                            <InputNumber
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "reject_garments_qty"]}
                            fieldKey={[fieldKey, "reject_garments_qty"]}
                            label="Reject Garments Qty"
                          >
                            <InputNumber
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </>
              )}
            </Form.List>
          </Form>
        </Card>
      </Layout.Content>
    </>
  );
};

export default UpdateGarmentsTracking;
