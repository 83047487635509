import "antd/dist/antd.css";
import {
  Layout,
  Space,
  Typography,
} from "antd";
const { Header, Content, Sider } = Layout;

const AppCreateFormLayOut = (props) => {
  const {
    header,
    contentForm,
    contentTable,
    CreateComponentForm2,
    CreateComponentForm,
  } = props;

  return (
    <Layout>
      <Layout style={{ height: "100%" }}>
        <Layout
          style={{
            padding: "0 5px 5px",
          }}
          className="site-layout"
        >
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: "flex",
            }}
          >
            {header}
            <div className="form-content">{contentForm}</div>

            <Content className="site-layout-background">
              <div style={{ paddingTop: 10 }}>
                {CreateComponentForm && CreateComponentForm}
              </div>

              {contentTable}
            </Content>
            {CreateComponentForm2 && (
              <Content className="site-layout-background">
                <div style={{ paddingTop: 10 }}>{CreateComponentForm2}</div>

                {contentTable}
              </Content>
            )}
          </Space>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default AppCreateFormLayOut;
