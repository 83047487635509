import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Collapse, Form, Layout, Space } from "antd";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  POST_CUTTING_PROCESS_EDITINFO,
  POST_CUTTING_PROCESS_UPDATE,
} from "apiServices/API_ENDPOINTS";
import { postData } from "apiServices/common";
import HandleNotification from "common/Notification";
import AppPageHeader from "common/PageHeader";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import { PostCuttingProcessContext } from "context/PostCuttingProcessContext/PostCuttingProcessContextProvider";
import { CUTTING_EDIT_PERMISSION } from "routes/permissions";
import { hasPermission, isArrayAndHasValue } from "utils/functions";
import PostCuttingForm from "../CreateForm/PostCuttingForm";
import PostCuttingDetails from "../PostCuttingDetails";

const { Content } = Layout;
const { Panel } = Collapse;

const UpdatePostCutting = (props) => {
  // Contexts
  const { cuttingTableData, getStyleList, setCuttingTableData, setShowFactoryInputs, subContractStatus, setSubContractStatus } =
    useContext(PostCuttingProcessContext);
  const [isSubmit, setIsSubmit] = useState(false);

  // Antd
  const [form] = Form.useForm();
  const movement_type_watch = Form.useWatch("movement_type", form);
  const process_watch = Form.useWatch("process_id", form);

  // Router
  const navigate = useNavigate();
  const location = useLocation();

  const getCuttingProductInfo = useCallback(
    async ({
      sales_contract_id,
      style_id,
      sub_contract_factory_id,
      buying_office_id,
      movement_date,
      unique_date_time,
      process,
    }) => {
      if(buying_office_id) {
        setShowFactoryInputs(false);
      } else if(sub_contract_factory_id) {
        setShowFactoryInputs(true);
      }
      const bodyData = {
        sales_contract_id: sales_contract_id ? Number(sales_contract_id) : null,
        style_id: style_id ? Number(style_id) : null,
        sub_contract_factory_id: sub_contract_factory_id || null,
        buying_office_id: buying_office_id ? Number(buying_office_id) : null,
        movement_date: movement_date
          ? moment(movement_date).format("YYYY-MM-DD")
          : null,
        unique_date_time: unique_date_time || null,
        process: process || null,
      };

      const query = `${POST_CUTTING_PROCESS_EDITINFO}`;

      const response = await postData(query, bodyData);

      if (response && response?.code === 200) {
        const form_data = response?.data?.form_data;
        const table_data = response?.data?.table_data;
        
        setSubContractStatus(form_data?.subcontract_status)
        // Get searched style list for dropdown
        getStyleList({ style_no: form_data?.style_no });

        // Set form data
        form.setFieldsValue({
          use_both_style_po: form_data?.active_sales_contract_map_info?.active_sales_contract_info?.use_both_style_po,
          sub_contract_factory_id: form_data?.sub_contract_factory_id,
          subcontract_status: form_data?.subcontract_status,
          style_id: form_data?.active_sales_contract_map_info?.style_id,
          buying_office_id: form_data?.buying_office_info?.id,
          buying_office_name: form_data?.buying_office_info?.name,
          buyer_id: form_data?.buyer_info?.id,
          buyer_name: form_data?.buyer_info?.name,
          factory_name: form_data?.factory_info?.name,
          address: form_data?.factory_info?.address,
          sales_contract_id:
            form_data?.active_sales_contract_map_info
              ?.active_sales_contract_info?.id,
          sales_contract_name:
            form_data?.active_sales_contract_map_info
              ?.active_sales_contract_info?.reference_no,
          process_id: form_data?.process,
          movement_type: form_data?.movement_type,
          movement_date: form_data?.movement_date ? moment(form_data?.movement_date) : null,
          note: form_data?.note,
        });

        // Set cutting details table data
        setCuttingTableData(
          isArrayAndHasValue(table_data)
            ? table_data
            : null,
        );
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something Went Wrong!",
          null,
        );
      }
    },
    // Ignored dependencies to stop loop
    [form, setCuttingTableData, getStyleList],
  );

  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(location.search);

    // Extracting query parameters
    const queryParamsObj = {
      sales_contract_id: searchParams.get("sales_contract_id"),
      style_id: searchParams.get("style_id"),
      sub_contract_factory_id: searchParams.get("sub_contract_factory_id"),
      buying_office_id: searchParams.get("buying_office_id"),
      movement_date: searchParams.get("movement_date"),
      unique_date_time: searchParams.get("unique_date_time"),
      process: searchParams.get("process"),
    };

    if (queryParamsObj) {
      getCuttingProductInfo(queryParamsObj);
    }
  }, [location.search, getCuttingProductInfo]);

  const goBackToCuttingRoute = () => {
    navigate("/production/post-cutting-process");
  };

  const extra = (
    <Space>
      <Button size="small" onClick={goBackToCuttingRoute}>
        Back
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => form.submit()}
        disabled={!hasPermission([CUTTING_EDIT_PERMISSION]) || isSubmit}
      >
        {hasPermission([CUTTING_EDIT_PERMISSION]) ? "Update" : "No Permission"}
      </Button>
    </Space>
  );

  const onFinishUpdate = async (values) => {
    const cuttingProducts =
      isArrayAndHasValue(cuttingTableData) &&
      cuttingTableData?.map((item) => {
        return {
          post_cutting_process_id:
            item?.process_products?.post_cutting_process_id,
          history_id: item?.process_products?.history_id || null,
          po_map_id: item?.po_map_id,
          original_po: item?.original_po,
          internal_po: item?.internal_po,
          sub_style_id: item?.sub_style_id || item?.sub_style_info?.id,
          color_id: item?.color_id || item?.color?.id,
          size_id: item?.size_id || item?.size?.id,
          garment_part_id: item?.process_products?.garment_part_info?.id || item?.garment_part_info?.id || null,
          send_qty: item?.process_products?.send_qty,
          receive_qty: item?.process_products?.receive_qty,
        };
      });

    const query = `${POST_CUTTING_PROCESS_UPDATE}`;
    const bodyData = {
      sales_contract_id: values?.sales_contract_id || null,
      buying_office_id: values?.buying_office_id || null,
      buyer_id: values?.buyer_id || null,
      sub_contract_factory_id: values?.sub_contract_factory_id || null,
      use_both_style_po: values?.use_both_style_po || "No",
      subcontract_status: values?.subcontract_status,
      process: values?.process_id || null,
      style_id: values?.style_id || null,
      movement_date: values?.movement_date || null,
      movement_type: values?.movement_type || null,
      note: values?.note || null,
      process_products: cuttingProducts,
    };

    const response = await postData(query, bodyData);

    if (response) {
      HandleNotification(
        "success",
        "bottomRight",
        "Post Cutting Process created successfully",
        null,
      );
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
      setIsSubmit(false);
    }
  };

  const formSubmitting = () => {
    setIsSubmit(false);
  };

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader
          title={"Embroidery, Printing, Smocking and Pintuck Update"}
          extra={extra}
        />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Order Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinishUpdate} form={form}>
                <PostCuttingForm
                  form={form}
                  isUpdate={true}
                  formSubmitting={formSubmitting}
                />
              </Form>
            </Panel>
          </Collapse>
          {/* Cutting Details Table */}
          <PostCuttingDetails
            isUpdate={true}
            movementType={movement_type_watch}
            process={process_watch}
          />
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default UpdatePostCutting;
