import { Button, Col, Form, Input, Row, Space } from "antd";
import { CuttingContext } from "context/CuttingContext/CuttingContextProvider";
import { useContext, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";

const CuttingTableFilter = () => {
  // Contexts
  const { cuttingTableData, setCuttingTableData } = useContext(CuttingContext);

  //   States
  const [prevCuttingTableData, setPrevCuttingTableData] = useState(null);

  // Antd
  const [searchForm] = Form.useForm();

  const onTableSearch = (values) => {
    // Set previous cutting table data for reset
    if (!isArrayAndHasValue(prevCuttingTableData)) {
      setPrevCuttingTableData(cuttingTableData);
    }

    // Filter data
    const original_po_input = values?.original_po?.trim().toLowerCase() || "";
    const substyle_input = values?.substyle?.trim().toLowerCase() || "";
    const color_input = values?.color?.trim().toLowerCase() || "";

    const filteredData = cuttingTableData?.filter((item) => {
      const original_po = item?.original_po?.trim().toLowerCase() || "";
      const substyle =
        item?.sub_style_info?.sub_style_no?.trim().toLowerCase() || "";
      const color = item?.color?.name?.trim().toLowerCase() || "";

      // Only apply filter if input has value
      const matchPO =
        !original_po_input || original_po.includes(original_po_input);
      const matchStyle = !substyle_input || substyle.includes(substyle_input);
      const matchColor = !color_input || color.includes(color_input);

      // Return true only if all active filters match
      return matchPO && matchStyle && matchColor;
    });

    setCuttingTableData(filteredData);
  };

  const onClearSearch = () => {
    searchForm.resetFields();
    setCuttingTableData(prevCuttingTableData);
  };

  return (
    <Form form={searchForm} layout="vertical" onFinish={onTableSearch}>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item name="original_po" label="Original PO">
            <Input size="small" placeholder="Original PO" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="substyle" label="Sub-style">
            <Input size="small" placeholder="Sub-style" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="color" label="Color">
            <Input size="small" placeholder="Color" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label=" ">
            <Space>
              <Button size="small" type="primary" htmlType="submit">
                Search
              </Button>
              <Button onClick={onClearSearch} size="small">
                Clear
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CuttingTableFilter;
