import React from "react";
import { Col, Form, Row, Select, Input } from "antd";

const ConfigurationFilterFields = (props) => {
  return (
    <>
      <Col span={4}>
        <Form.Item label="Name" name="name">
          <Input placeholder="Name" size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default ConfigurationFilterFields;
