import React, { useCallback, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { useParams } from "react-router-dom";
import AddDetailsForm from "./AddDetailsForm";
import { debounce } from "lodash";

export default function AddIou(props) {
  // Props
  const { width } = props;

  // Antd
  const propertyFormRef = useRef(null);
  const [form] = Form.useForm();

  // States
  const [loading, setLoading] = useState(false);

  // Router
  const { supplierType } = useParams();

  const addIou = () => {
    form.submit();
  };

  const debouncedAddIou = useCallback(debounce(() => {
    addIou();
}, 1000), []); 

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Import Shipment Status"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  loading={loading}
                  onClick={debouncedAddIou}
                >
                  Save
                </Button>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      <AddDetailsForm
        ref={propertyFormRef}
        form={form}
        setLoading={setLoading}
        supplierType={supplierType}
      />
    </>
  );
}
