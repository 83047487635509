import React, { useRef } from "react";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../Common/Header";
import { isMobileView } from "../../../Common/AppHelper";
import ReconciliationForm from "./ReconciliationForm";

export default function CreateReconciliation(props) {
  // Props
  const { width } = props;

  //   Router
  const propertyFormRef = useRef(null);
  const navigate = useNavigate();

  //   Form
  const [form] = Form.useForm();

  const discartForm = () => {
    navigate("/local-purchase-reconciliation");
  };

  const addReconciliation = () => {
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Add Local Purchase Reconciliation"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => addReconciliation()}
                >
                  Save
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <ReconciliationForm ref={propertyFormRef} form={form} discartForm={discartForm} />
    </>
  );
}
