import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Card,
  Input,
  Select,
  Table,
  Button,
  Breadcrumb,
  Dropdown,
  Menu,
  Affix,
  Typography,
  Popconfirm,
  Modal,
  Form,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  DeleteTwoTone,
  EditTwoTone,
  EyeOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import {
  SALES_CONTRACT_COMMERCIAL_LIST,
  SALES_CONTRACT_COMMERCIAL_ADD_DELETE_REASON,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  cglFormatDate,
  formatNumberWithCommas,
} from "../../../utils/functions";
import { hasPermission } from "../../../utils/functions";
import {
  SALES_CONTRACT_COMMERCIAL_VIEW_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_EDIT_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_DELETE_PERMISSION,
} from "../../../routes/permissions";
import { alertPop } from "../../../apiServices/common/helper";
import { CLEAR_EXPORT_STATE } from "redux/ActionTypes";

export default function Forwarder(props) {
  // Props
  const { width } = props;

  const dispatch = useDispatch();
  dispatch({ type: CLEAR_EXPORT_STATE });

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValues, setSearchValues] = useState();
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Antd Constants
  const { Option } = Select;
  const [form] = Form.useForm();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const searchFilter = (values) => {
    console.log("values", values);
  };

  const openForEdit = (record) => {
    navigate(`/export/edit-sales-contract/${record.id}`);
  };

  const openForView = (record) => {
    navigate(`/export/view-sales-contract/${record.id}`);
  };

  const deleteSelectedObSamBank = (id) => {
    form.setFieldValue("id", id);
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "Sales Contract No",
      dataIndex: ["sale_contract_info", "reference_no"],
      key: ["sale_contract_info", "reference_no"],
    },
    {
      title: "Sales Contract Date",
      dataIndex: ["sale_contract_info", "contract_date"],
      key: ["sale_contract_info", "contract_date"],
      render: (value) => (value ? cglFormatDate(value) : ""),
    },
    {
      title: "Sales Contract Value",
      dataIndex: ["sale_contract_info", "contract_value"],
      key: ["sale_contract_info", "contract_value"],
      render: (value) => formatNumberWithCommas(value),
    },
    {
      title: "Order Quantity (Piece)",
      dataIndex: ["order_quantity"],
      key: ["order_quantity"],
      render: (value) => formatNumberWithCommas(value),
    },
    {
      title: "Buyer",
      dataIndex: ["buyer_name"],
      key: ["buyer_name"],
    },
    {
      title: "Tag Number",
      dataIndex: ["tag_number"],
      key: ["tag_number"],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedObSamBank(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([
                      SALES_CONTRACT_COMMERCIAL_DELETE_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([
                      SALES_CONTRACT_COMMERCIAL_EDIT_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EyeOutlined />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openForView(record);
                          }}
                        >
                          View
                        </span>
                      </>
                    ),
                    key: "3",
                    disabled: !hasPermission([
                      SALES_CONTRACT_COMMERCIAL_VIEW_PERMISSION,
                    ]),
                  },
                ]}
              />
            }
          />
        );
      },
    },
  ];

  const getAllPermitList = async (passedObject) => {
    //let filter = passedObject;
    //let res = await getData(SALES_CONTRACT_COMMERCIAL_LIST, false, filter);

    const filter = passedObject;
    const pageQuery = `${filter?.page ? "" : `?page=${currentPage}`}`;
    const customQuery = `${SALES_CONTRACT_COMMERCIAL_LIST}/${pageQuery}`;
    const res = await getData(customQuery, false, filter);

    if (res) {
      let masterData = res?.data;
      setPermitList(masterData?.data?.data);
      setTotalData(masterData?.data?.total);
      setRangeData(masterData?.data?.per_page);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllPermitList(filterData);
  }, [currentPage, searchValues, filterData]);

  const removeDetails = async (values) => {
    const bodyData = {
      ...values,
    };

    const reasonResponse = await postData(
      SALES_CONTRACT_COMMERCIAL_ADD_DELETE_REASON,
      bodyData,
    );

    const response = await deleteData(
      `/api/accounting/sales-contract-commercial/delete/${values?.id}`,
    );

    if (response && response?.code === 200 && reasonResponse?.code === 200) {
      alertPop(
        "success",
        "Success",
        response?.message[0] || "Successfully deleted!",
      );
      setIsModalOpen(false);
      form.resetFields();
      getAllPermitList();
    } else {
      alertPop("error", "Error", "Something went wrong!");
    }
  };

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const memoizedCustomFilter = useMemo(
    () => (
      <CustomFilter
        showFilter={showFilter}
        tableData={permitList}
        fetchTableData={getAllPermitList}
        filterName="sales_contract_commercial"
      />
    ),
    [showFilter],
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Sales Contract / Master LC"
            subTitle=""
            actions={[
              <Button
                type={showFilter ? "danger" : "primary"}
                size="small"
                onClick={handleFilter}
                style={{ float: "right" }}
              >
                {`${showFilter ? "Close" : "Open"} Filter Option`}
              </Button>,
              <Button
                key="3"
                size="small"
                type="primary"
                disabled={
                  !hasPermission([SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION])
                }
              >
                <Link to="/export/add-sales-contract">
                  {hasPermission([SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"}
                </Link>
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader
            // title="Style List"
            // filtersOption={filtersOption}
            searchFilter={searchFilter}
          />
          {memoizedCustomFilter}
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
      <Modal
        title="Close"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={removeDetails} layout="vertical">
          <Form.Item name="id" label="ID" hidden>
            <Input placeholder="Comment" />
          </Form.Item>
          <Form.Item name="comment" label="Comment">
            <Input placeholder="Comment" />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}
