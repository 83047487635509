import {
  CopyOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, Modal, Row } from "antd";
import { useState } from "react";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../utils/functions";
import DrawerForm from "./DrawerForm";
import "./POTable.css";

const StylePODetails = (props) => {
  // Props
  const {
    dynamicSizeList,
    purchesList,
    styleList,
    view,
    showOrderTotal,
    showTotalRowWise,
    poDetailsData,
    setPoDetailsData,
    removePoRow,
    mode,
    useBothStylePo,
    isAddPrice = false,
  } = props;

  // Antd
  const { confirm } = Modal;

  // States
  const [open, setOpen] = useState(false);
  const [hoverData, setHoverData] = useState(null);
  const [uniqueId, setUniqueId] = useState(0);
  const [aIsEdit, setAIsEdit] = useState(false);

  const [form] = Form.useForm();

  const removeRow = (row_index) => {
    confirm({
      title: "Do you want to delete this row?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const clone = [...poDetailsData];
        const foundPoItem = poDetailsData?.find(
          (item) => item?.unique_id === row_index,
        );
        const updatedPoDetailsData = clone.filter((item, i) => i !== row_index);
        setPoDetailsData(updatedPoDetailsData);

        // Call remove po row API
        removePoRow(foundPoItem);
      },
    });
  };

  const onChangePoDetailsValue = (value, index, keyName) => {
    // Copy poDetailsData state array
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );

    foundPoItem[keyName] = value;

    setPoDetailsData(poDetailsDataCopy);
  };

  const setInternalPo = (value, index, keyName) => {
    let foundInternalPo = purchesList.find(
      (item) => item.original_po === value,
    )[keyName];
    // Update the internal PO of changed PO data
    onChangePoDetailsValue(foundInternalPo, index, keyName);
  };

  const setOriginalPo = (value, index, keyName) => {
    let foundOriginalPo = purchesList.find(
      (item) => item.internal_po === value,
    )[keyName];
    // Update the original PO of changed PO data
    onChangePoDetailsValue(foundOriginalPo, index, keyName);
  };

  const copyRow = (index) => {
    setOpen(true);
    setAIsEdit(true);

    const foundPoItem = poDetailsData[index];
    const clonedFoundPoItem = structuredClone(foundPoItem);

    clonedFoundPoItem?.sizes?.forEach((sizeItem, index) => {
      sizeItem.po_detail_id = 0;
    });

    clonedFoundPoItem["sub_style_id"] = clonedFoundPoItem?.sub_style_info?.id;

    const updatePoDetails = [
      ...poDetailsData,
      {
        ...clonedFoundPoItem,
        unique_id: poDetailsData?.length,
        sub_style_id: clonedFoundPoItem?.sub_style_info?.id,
      },
    ];
    setUniqueId(updatePoDetails?.length - 1);
    form.setFieldsValue(clonedFoundPoItem);
    setPoDetailsData(updatePoDetails);
  };

  const editRow = (index) => {
    setOpen(true);
    setAIsEdit(true);
    setUniqueId(index);

    const clone = [...poDetailsData];

    const foundPoItem = clone?.find((item) => item?.unique_id === index);
    const clonedFoundPoItem = structuredClone(foundPoItem);

    const size = {};
    clonedFoundPoItem?.sizes?.forEach((item) => {
      if (isAddPrice) {
        size[`${item.name}_${item.id}`] = item.order_price || 0;
      } else {
        size[`${item.name}_${item.id}`] = item.sales_order_quantity || 0;
      }
    });

    form.setFieldsValue({
      ...clonedFoundPoItem,
      style_id: clonedFoundPoItem?.sub_style_info?.style_id
        ? clonedFoundPoItem?.sub_style_info?.style_id
        : clonedFoundPoItem?.style_id || clonedFoundPoItem?.style?.id,
      sub_style_id:
        clonedFoundPoItem?.sub_style_info?.id ||
        clonedFoundPoItem?.sub_style_id,
      color_id: !isNaN(clonedFoundPoItem?.color)
        ? clonedFoundPoItem?.color
        : clonedFoundPoItem?.color_id || clonedFoundPoItem?.color?.color_id,
      brand: clonedFoundPoItem?.brand_info?.name,
      ...size,
    });
  };

  const addNewRow = () => {
    setOpen(true);
    setAIsEdit(false);

    const clonedPoDetailsData = structuredClone(poDetailsData);
    const lastPoItemId =
      clonedPoDetailsData[clonedPoDetailsData.length - 1]?.unique_id;

    const updatePoDetails = [
      ...clonedPoDetailsData,
      {
        unique_id: lastPoItemId || lastPoItemId === 0 ? lastPoItemId + 1 : 0,
      },
    ];

    setPoDetailsData(updatePoDetails);

    setUniqueId(updatePoDetails.length - 1);
  };

  const showSizeColumnTotal = (sizeObject) => {
    let sizeColumnTotal = 0;

    poDetailsData?.forEach((item) => {
      sizeColumnTotal += item?.[sizeObject?.label + "_" + sizeObject?.value];
    });

    return sizeColumnTotal;
  };

  const styleWiseColorList = (value, index) => {
    const foundStyle = styleList.find((item) => item?.style_info?.id === value);
    const subStyle = foundStyle?.style_info?.sub_style;

    onChangePoDetailsValue(subStyle, index, "color_list_obj");

    const get_brand_info = styleList.find(
      (item) => item?.style_info?.id === value,
    ).style_info?.brand_info;

    const poDetailsDataCopy = [...poDetailsData];

    poDetailsDataCopy.find((item) => item?.unique_id === index)["brand_info"] =
      get_brand_info;

    setPoDetailsData([...poDetailsDataCopy]);
  };

  const getSubStyle = (poId, colorId) => {
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === poId,
    );
    const foundColor = foundPoItem?.color_list_obj?.find(
      (colorItem) => colorItem?.color_info?.id === colorId,
    );
    const sub_style_info_obj = {
      ...foundColor,
      sub_style_no: foundColor?.sub_style_no,
    };

    onChangePoDetailsValue(sub_style_info_obj, poId, "sub_style_info");
  };

  const updateSizeQuantity = (value, unique_id, size_object) => {
    const poDetailsDataCopy = [...poDetailsData];

    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === unique_id,
    );
    const foundSize = foundPoItem?.sizes?.find(
      (item) => item?.id === size_object?.value,
    );

    if (foundSize) {
      foundSize.sales_order_quantity = value;
    } else {
      if (foundPoItem.sizes) {
        foundPoItem?.sizes?.push({
          id: size_object?.value,
          name: size_object?.label,
          sales_order_quantity: value,
          delivery_date:
            foundPoItem?.delivery_date ||
            poDetailsDataCopy?.[0]?.sizes?.[0]?.delivery_date,
          po_detail_id: foundPoItem?.po_detail_id || 0,
        });
      } else {
        foundPoItem["sizes"] = [
          {
            id: size_object?.value,
            name: size_object?.label,
            sales_order_quantity: value,
            delivery_date:
              foundPoItem?.delivery_date ||
              poDetailsDataCopy?.[0]?.sizes?.[0]?.delivery_date,
            po_detail_id: foundPoItem?.po_detail_id || 0,
          },
        ];
      }
    }
    setPoDetailsData(poDetailsDataCopy);
  };

  const setDeliveryDate = (value, index, from) => {
    const foundPoItemInternal = purchesList.find(
      (item) => item?.internal_po === value,
    );
    const foundPoItemOriginal = purchesList.find(
      (item) => item?.original_po === value,
    );
    const foundPoItem =
      from === "from_internal_po" ? foundPoItemInternal : foundPoItemOriginal;
    const delivery_date = foundPoItem?.delivery_date || null;

    onChangePoDetailsValue(delivery_date, index, "delivery_date");
  };

  const RowInfoUI = ({ rowData }) => {
    return (
      <div
        style={{ padding: "10px", display: rowData ? "unset" : "none" }}
        className="row-ui-bottom"
      >
        <div>
          <strong>Internal PO:</strong> {rowData?.internal_po || "N/A"},
          <strong>Original PO:</strong> {rowData?.original_po || "N/A"},
          <strong>Style:</strong> {rowData?.style?.style_no || "N/A"},
          <strong>Color:</strong> {rowData?.color?.name || "N/A"},
          <strong>Substyle:</strong>{" "}
          {rowData?.sub_style_info?.sub_style_no || "N/A"}
        </div>
      </div>
    );
  };

  const changeSizesPoMapId = (value, index) => {
    // Change po_map_id inside every sizes in selected item
    const poDetailsDataCopy = structuredClone(poDetailsData);
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );

    // Insert newly selected po_map_id to the selected item
    foundPoItem["po_map_id"] = value;
    foundPoItem["sizes"] = foundPoItem?.sizes?.map((sizeItem) => {
      return {
        ...sizeItem,
        po_map_id: value,
      };
    });

    setPoDetailsData(poDetailsDataCopy);
  };

  const setPoMapId = (value, index, keyName) => {
    let foundPoId = purchesList.find((item) => item.internal_po === value)[
      keyName
    ];

    // Update the po_map_id and size data of changed PO data
    changeSizesPoMapId(foundPoId, index);
  };

  const setPoMapIdByOriginalPO = (value, index, keyName) => {
    let foundPoId = purchesList.find((item) => item.original_po === value)[
      keyName
    ];

    // Update the po_map_id of changed PO data
    onChangePoDetailsValue(foundPoId, index, "po_map_id");
  };

  const childProps = {
    form,
    view,
    poDetailsData,
    unique_id: uniqueId,
    purchesList,
    setOriginalPo,
    setPoMapId,
    setInternalPo,
    setPoMapIdByOriginalPO,
    onChangePoDetailsValue,
    setDeliveryDate,
    styleList,
    styleWiseColorList,
    color_list_obj: [],
    getSubStyle,
    isArrayAndHasValue,
    dynamicSizeList,
    updateSizeQuantity,
    commaSeparateNumber,
    aIsEdit,
    setUniqueId,
    uniqueId,
    setAIsEdit,
  };

  const renderSizeValue = (size, obj) => {
    const sizeId = size?.size_id || size?.id;
    const sizesArray = obj?.sizes;
    const foundSize = sizesArray?.find((item) => item.id === sizeId);
    if (isAddPrice) {
      return foundSize?.order_price || 0;
    }
    return foundSize?.sales_order_quantity || 0;
  };

  return (
    <Form form={form}>
      <div>
        <Card
          title={
            <span style={{ fontSize: "14px", marginBottom: "10px" }}>
              Style PO Details
            </span>
          }
        >
          <div className="custome-table">
            <table border="1" style={{ marginBottom: "30px" }}>
              <thead>
                <tr>
                  <th key="header_serial_number" className="bc-java ">
                    SL.{" "}
                  </th>

                  {useBothStylePo === "Yes" && (
                    <>
                      <th key="header_internal_po" className="bc-java ">
                        Internal PO
                      </th>
                      <th key="header_original_po" className="bc-java ">
                        Original PO
                      </th>
                    </>
                  )}

                  <th key="header_style" className="bc-java ">
                    Style{" "}
                  </th>
                  <th key="header_sub_style" className="bc-java ">
                    Sub Style{" "}
                  </th>
                  <th key="header_color" className="bc-java ">
                    Color
                  </th>

                  {isArrayAndHasValue(dynamicSizeList) &&
                    dynamicSizeList?.map((obj, index) => {
                      return (
                        <th
                          key={`size_column_key_${index}`}
                          className="bc-orange"
                        >
                          {obj.label}
                        </th>
                      );
                    })}
                  <th key="header_total" className="bc-java ">
                    Total
                  </th>
                  <th key="header_brand" className="bc-java ">
                    Brand
                  </th>
                  <th key="header_extra_quantity" className="bc-java ">
                    Extra Cutting
                  </th>
                  {!view && (
                    <>
                      <th key="header_add_row" className="bc-java ">
                        Copy
                      </th>
                      <th key="header_add_row" className="bc-java ">
                        Edit
                      </th>
                      {isArrayAndHasValue(poDetailsData) && (
                        <th key="header_delete_row" className="bc-java ">
                          Delete
                        </th>
                      )}
                    </>
                  )}
                </tr>
              </thead>

              <tbody className="scrollable-table-container">
                {isArrayAndHasValue(poDetailsData) ? (
                  <>
                    {poDetailsData.map((obj, index) => {
                      return (
                        <tr
                          key={`table_parent_key_${index}`}
                          onMouseEnter={() => setHoverData(obj)}
                          onMouseLeave={() => setHoverData(null)}
                          onDoubleClick={() => {
                            if (mode === "view") return;
                            editRow(index);
                          }}
                        >
                          {/* SL */}
                          <td className="t-body sticky">{index + 1}</td>
                          
                          {useBothStylePo === "Yes" && (
                            <>
                              {/* Internal PO */}
                              <td
                                className="t-body sticky"
                                style={{ width: "20%" }}
                              >
                                {obj?.internal_po || ""}
                              </td>
                              {/* Original PO */}
                              <td className="t-body sticky">
                                {obj?.original_po || ""}
                              </td>
                            </>
                          )}

                          {/* Style */}
                          <td className="t-body sticky">
                            {styleList.find(
                              (item) =>
                                item?.style_info?.id ===
                                (obj?.style_id || obj?.style?.id),
                            )?.style_info?.style_no || ""}
                          </td>
                          {/* Substyle */}
                          <td className="t-body sticky">
                            {obj?.sub_style_info?.sub_style_no || ""}
                          </td>
                          {/* Color */}
                          <td className="t-body sticky">
                            {obj?.color?.color_info?.name ||
                              obj?.color?.name ||
                              ""}
                          </td>

                          {/* Dynamic Sizes */}
                          {isArrayAndHasValue(dynamicSizeList) &&
                            dynamicSizeList?.map((size, index) => {
                              return (
                                <td
                                  className="t-body"
                                  key={`dynamic_size_key_${index}`}
                                >
                                  {renderSizeValue(size, obj)}
                                </td>
                              );
                            })}
                          {/* Total */}
                          <td className="t-body">
                            {commaSeparateNumber(showTotalRowWise(obj))}
                          </td>
                          {/* Brand */}
                          <td className="t-body">
                            {obj?.brand_info?.name}
                            {/* {poDetailsData[0]?.style?.brand_info?.name ||
                              poDetailsData[0]?.brand_info?.name} */}
                          </td>
                          {/* Actions */}
                          <td className="t-body">{obj?.extra_cutting || 0}</td>
                          {!view && (
                            <td
                              className="t-body"
                              onClick={() => {
                                copyRow(index);
                              }}
                            >
                              <CopyOutlined
                                className="cursore-pointer"
                                style={{ fontSize: "16px", color: "Green" }}
                              />
                            </td>
                          )}
                          {!view && (
                            <td
                              className="t-body"
                              onClick={() => {
                                editRow(index);
                              }}
                            >
                              <EditOutlined
                                className="cursore-pointer"
                                style={{ fontSize: "16px", color: "Green" }}
                              />
                            </td>
                          )}
                          {!view && isArrayAndHasValue(poDetailsData) && (
                            <td
                              className="t-body"
                              onClick={() => {
                                removeRow(index);
                              }}
                            >
                              <MinusCircleOutlined
                                className="cursore-pointer"
                                style={{ fontSize: "16px", color: "red" }}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
                {isArrayAndHasValue(poDetailsData) &&
                isArrayAndHasValue(dynamicSizeList) ? (
                  <tr>
                    <td
                      className="t-body"
                      colSpan="6"
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      Total
                    </td>
                    {dynamicSizeList?.map((sizeObject, index) => {
                      return (
                        <td
                          className="t-header"
                          key={`total_count_key_${index}`}
                        >
                          {commaSeparateNumber(showSizeColumnTotal(sizeObject))}
                        </td>
                      );
                    })}
                    <td
                      className="t-body"
                      style={{ textAlign: "left", fontWeight: "bold" }}
                      colSpan={view ? "3" : "6"}
                    >
                      {" "}
                      {commaSeparateNumber(showOrderTotal(poDetailsData))}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
      <Row>
        <Col span={4}>
          <Button
            style={{
              width: "200px",
              position: "fixed",
              paddingRight: "30px",
              bottom: "1vh",
            }}
            type="primary"
            className="mt-2"
            icon={<PlusOutlined />}
            size="small"
            onClick={addNewRow}
            disabled={view || open || !dynamicSizeList?.length > 0}
            hidden={view || open}
          >
            Add
          </Button>
        </Col>
        <Col span={4}>
          <div>
            <RowInfoUI
              rowData={hoverData}
              className="row-ui-bottom"
              size="small"
            />
          </div>
        </Col>
      </Row>

      {open && (
        <DrawerForm
          props={childProps}
          open={open}
          setOpen={setOpen}
          uniqueId={uniqueId}
          totalOrder={commaSeparateNumber(showOrderTotal(poDetailsData))}
          useBothStylePo={useBothStylePo}
          aIsEdit={aIsEdit}
          setPoDetailsData={setPoDetailsData}
        />
      )}
    </Form>
  );
};

export default StylePODetails;
