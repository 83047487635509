import { Col, DatePicker, Form, Select, message } from "antd";
import {
  ALL_SALES_CONTRACT_LIST, GET_BUYER_ENDPOINT, GET_BUYING_OFFICE_ENDPOINT,
  SALES_CONTRACT_LIST, STYLE_MERCHANDISING_LIST,
} from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {isArrayAndHasValue} from "../../../utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const FabricReconcilFilterFields = ({ initialTableData }) => {
  const [salesContractList, setSalesContractList] = useState(null);
  const [stylesList, setStylesList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [subcontractStatusOption, setSubcontractStatusOption] = useState([]);

  const getBuyingOfficeList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(GET_BUYING_OFFICE_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOffice(response?.data?.data?.data || []);
    }
  };

  const getBuyerList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    let response = await getData(GET_BUYER_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyerList(response?.data?.data?.data || []);
    }
  };

  const getSubcontractStatusOption = async (filterValues) => {
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: 10,
    };
    const response = await getData(
        `/api/sub-contract-factory`,
        false,
        bodyData,
    );

    if (response && response?.data?.code === 200) {
      setSubcontractStatusOption(response?.data?.data?.data);
    }
  };

  const getSalesContract = useCallback(async (value = null) => {
    setLoading(true);

    let query = `${SALES_CONTRACT_LIST}`;
    if (value) query = `${SALES_CONTRACT_LIST}?reference_no=${value}`;

    const response = await getData(query);

    if (response && response?.status === 200) {
      setLoading(false);
      setSalesContractList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting sales contract data");
    }
  }, []);

  // const getStyles = useCallback(async (value = null) => {
  //   setLoading(true);
  //
  //   let query = `${ALL_SALES_CONTRACT_LIST}`;
  //   if (value) query = `${ALL_SALES_CONTRACT_LIST}?style_id=${value}`;
  //   const response = await getData(query);
  //
  //   if (response && response?.status === 200) {
  //     setLoading(false);
  //     setStylesList(response?.data?.data);
  //   } else {
  //     setLoading(false);
  //     message.error("Error getting styles data");
  //   }
  // }, []);

  const getStyleList = async (style_no) => {
    const bodyData = {
      "style_no": style_no,
    }

    let res = await getData(STYLE_MERCHANDISING_LIST, false, bodyData);

    if (res) {
      setStylesList(res?.data?.data?.data || []);
    }
  };

  useEffect(() => {
    getSalesContract();
    getStyleList();
    getBuyingOfficeList();
    getBuyerList();
    getSubcontractStatusOption();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item
          label="Start Date"
          name="start_date"
          required
          rules={[
            {
              required: false,
              message: "Start Date is required",
            },
          ]}
        >
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label="End Date"
          name="end_date"
          required
          rules={[
            {
              required: false,
              message: "End Date is required",
            },
          ]}
        >
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      <Col className="gutter-row" span={4}>
        <Form.Item label="Buying Office" name="buying_office_id">
          <Select
              showSearch
              placeholder="Select a Buying Office"
              filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
              }
              onSearch={debounce((value) => {
                if (value) {
                  getBuyingOfficeList({ name: value });
                } else {
                  getBuyingOfficeList();
                }
              }, 500)}
              optionFilterProp="children"
              allowClear
              size="small"
          >
            {isArrayAndHasValue(buyingOffice) &&
                buyingOffice.map((sup) => (
                    <Select.Option value={sup.id} key={sup.id}>
                      {sup.name}
                    </Select.Option>
                ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="gutter-row" span={4}>
        <Form.Item label="Sales Contract" name="sales_contract_id">
          <Select
            showSearch
            placeholder="Select a Sales Contract"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
            allowClear
            onSearch={debounce((value) => {
              if (value) {
                getSalesContract(value);
              } else {
                getSalesContract();
              }
            }, 500)}
          >
            {salesContractList?.length &&
              salesContractList.map((item, index) => (
                <Select.Option value={item.id} key={index}>
                  {item.reference_no}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="gutter-row" span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
              showSearch
              placeholder="Select a Buyer"
              filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onSearch={debounce((value) => {
                if (value) {
                  getBuyerList({ name: value });
                } else {
                  getBuyerList();
                }
              }, 500)}
              optionFilterProp="children"
              size="small"
          >
            {buyerList?.length &&
                buyerList.map((sup) => (
                    <Select.Option value={sup.id} key={sup.id}>
                      {sup.name}
                    </Select.Option>
                ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="gutter-row" span={4}>
        <Form.Item
            label="First Party"
            name="first_party"
        >
          <Select
              showSearch
              placeholder="Select a First Party"
              allowClear
              size="small"
              onSearch={debounce((value) => {
                if (value) {
                  getSubcontractStatusOption(value);
                } else {
                  getSubcontractStatusOption();
                }
              }, 500)}
          >
            {isArrayAndHasValue(subcontractStatusOption) &&
                subcontractStatusOption.map((sup) => (
                    <Select.Option value={sup?.id} key={sup?.id}>
                      {sup.name}
                    </Select.Option>
                ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="gutter-row" span={4}>
        <Form.Item label="Style" name="style_id">
          <Select
            showSearch
            placeholder="Select a Style"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
            onSearch={debounce((value) => {
              if (value) {
                getStyleList(value);
              } else {
                getStyleList();
              }
            }, 500)}
          >
            {stylesList?.length &&
              stylesList.map((item, index) => (
                <Select.Option value={item?.id} key={index}>
                  {item?.style_no}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default FabricReconcilFilterFields;
