import { Column } from "@ant-design/plots";
import {
  Button,
  Card,
  Col,
  DatePicker,
  message,
  Row,
  Space,
  Table,
} from "antd";
import { LINE_WISE_DAILY_CPM } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatNumberWithCommas } from "utils/functions";

export default function CPM() {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [data, setData] = useState({
    dataSource: [],
    barChart: [],
    loading: false,
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const config = {
    data: data.barChart,
    xField: "line",
    yField: "cpm",
    label: {
      text: (originData) => {
        // const val = parseFloat(originData.profit);
        // if (val < 0.05) {
        //   return (val * 100).toFixed(1) + "%";
        // }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  const columns = [
    {
      title: "Sewing Line",
      dataIndex: "line",
      key: "line",
    },
    {
      title: "Line Chief",
      dataIndex: "line_chief",
      key: "line_chief",
      render: (chiefs) =>
        chiefs
          ?.map((c) => c.line_chief)
          .filter(Boolean)
          .join(", "),
    },
    {
      title: "Line Attendance",
      dataIndex: "line_attendance",
      key: "line_attendance",
      align: "right",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (value) => formatNumberWithCommas(value.toFixed(2)),
      align: "right",
    },
    {
      title: "Production",
      dataIndex: "today_production",
      key: "today_production",
      align: "right",
      render: (value) =>
        parseInt(value).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    },
    {
      title: "SAM",
      dataIndex: "sam",
      key: "sam",
      align: "right",
      render: (value) => formatNumberWithCommas(value.toFixed(2)),
    },
    {
      title: "Earn Minutes",
      dataIndex: "earn_minute",
      key: "earn_minute",
      align: "right",
      render: (value) => formatNumberWithCommas(value),
    },
    {
      title: "CPM",
      dataIndex: "cpm",
      key: "cpm",
      align: "right",
      render: (value) => value.toFixed(3),
    },
    {
      title: "Ranking on CPM",
      dataIndex: "rank",
      key: "rank",
    },
  ];

  useEffect(() => {
    getLinewiseDailyCpmData(selectedDate);
  }, [selectedDate]);

  const getLinewiseDailyCpmData = async (date) => {
    setData({
      dataSource: [],
      barChart: [],
      loading: true,
    });
    try {
      const body = {
        start_date: date.format("YYYY-MM-DD"),
      };
      const query = `${LINE_WISE_DAILY_CPM}`;
      const response = await getData(query, false, body);

      if (response?.data?.code === 200) {
        setData({
          dataSource: response?.data?.data?.data.sort((a, b) => {
            const numA = parseInt(a.line.match(/\d+/));
            const numB = parseInt(b.line.match(/\d+/));
            return numA - numB;
          }),
          barChart: response?.data?.data?.data.map((d) => ({
            line: d.line,
            cpm: d.cpm,
          })),
          loading: false,
        });
      } else {
        setData({
          dataSource: [],
          barChart: [],
          loading: false,
        });
        message.error("Failed to fetch data");
      }
    } catch (error) {
      setData({
        dataSource: [],
        barChart: [],
        loading: false,
      });
      message.error("An error occurred while fetching data");
    }
  };

  return (
    <Card
      title={`
        Line wise Daily CPM Report as on ${selectedDate.format("DD-MMM-YYYY")}
      `}
      bordered={false}
    >
      <br />
      <Row gutter={24}>
        <Col span={6}>
          <DatePicker
            allowClear={false}
            format={"DD-MMM-YYYY"}
            style={{
              width: "100%",
            }}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Col>
        <Col span={3}>
          <Space>
            <Button
              type="primary"
              danger
              onClick={() => setSelectedDate(moment())}
            >
              Reset
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <Table
        dataSource={data.dataSource}
        columns={columns}
        pagination={false}
        bordered
        loading={data.loading}
        summary={(tableData) => {
          let totalCost = 0;
          let totalProduction = 0;
          let totalEarnMinutes = 0;
          let totalCPM = 0;
          tableData.forEach(({ cost, today_production, earn_minute, cpm }) => {
            totalCost += parseFloat(cost);
            totalProduction += parseFloat(today_production);
            totalEarnMinutes += parseFloat(earn_minute);
            totalCPM += parseFloat(cpm);
          });

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                {formatNumberWithCommas(totalCost.toFixed(2))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align="right">
                {totalProduction}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6} align="right">
                {totalEarnMinutes}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} align="right">
                {totalCPM.toFixed(3)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Today's Est. Net Profit/taka
        </h3>
        <Column {...config} />
      </div>
    </Card>
  );
}
