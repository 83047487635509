import { Col, Form, Select } from "antd";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  SALES_CONTRACT_LIST,
  STYLE_MERCHANDISING_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const PostCostingReportFilter = ({ initialTableData }) => {
  // States
  const [salesContractList, setSalesContractList] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const [buyingOfficeList, setBuyingOfficeList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);

  // Antd
  const { Option } = Select;

  useEffect(() => {
    getSalesContractList();
    getBuyerList();
    getBuyingOfficeList();
    getStyleList();
  }, []);

  const getSalesContractList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      reference_no: filterValues?.reference_no || "",
    };
    const response = await getData(SALES_CONTRACT_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSalesContractList(response?.data?.data?.data || []);
    }
  };
  const getStyleList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      style_no: filterValues?.style_no || "",
    };
    const response = await getData(STYLE_MERCHANDISING_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data?.data || []);
    }
  };
  const getBuyerList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      name: filterValues?.name || "",
    };
    const response = await getData(GET_BUYER_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyerList(response?.data?.data?.data || []);
    }
  };
  const getBuyingOfficeList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      name: filterValues?.name || "",
    };
    const response = await getData(GET_BUYING_OFFICE_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOfficeList(response?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item label="Sales Contract No" name="sales_contract_id">
          <Select
            showSearch
            placeholder="Select a Sales Contract No"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getSalesContractList({ reference_no: value })}
          >
            {isArrayAndHasValue(salesContractList) &&
              salesContractList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.reference_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default PostCostingReportFilter;
