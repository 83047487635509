import {
    GET_WAREHOUSE_LIST,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    WAREHOUSE_LIST_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetWarehouseList = (page, per_page, name) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${WAREHOUSE_LIST_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && { name: name }),
                },
            })
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_WAREHOUSE_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};
