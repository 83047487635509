import React, { useCallback, useEffect, useState } from "react";
import { Button, Popconfirm, Typography } from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import UserRoleListTable from "./UserRoleListTable";
import AppPageHeader from "../../../../common/PageHeader";
import AppContent from "../../../../common/AppContent";
import { onDeleteUserRole, onGetUserRoleList } from "../../../../redux/actions";
import { getData } from "../../../../apiServices/common";
import { hasPermission } from "../../../../utils/functions";
import {
  ROLE_CREATE_PERMISSION,
  ROLE_EDIT_PERMISSION,
  ROLE_DELETE_PERMISSION,
} from "../../../../routes/permissions";
import ExtraContent from "../../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../../Configuration/CommonComponents/FilterComponent";
import { GET_USERS_ROLE_ENDPOINT } from "../../../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../../../common/Notification";

const UserRoleList = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [userRoleList, setUserRoleList] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [selectedUserRole, setSelectedUserRole] = useState(null);

  // Redux
  const dispatch = useDispatch();

  // Antd
  const { Title } = Typography;

   // Filter open/close status update in localStorage
   useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllRoles = useCallback(async (filterValues) => {
    const query = `${GET_USERS_ROLE_ENDPOINT}`;
    const bodyData = {
      page: page,
      per_page: filterValues?.per_page || 10,
      ...(filterValues?.name && { name: filterValues?.name }),
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setUserRoleList(response?.data?.data?.data);
      setPageSize(response?.data?.data?.per_page);
      setTotal(response?.data?.data?.total);
    } else {
      HandleNotification("error", "Error", "Something went wrong");
    }
  }, [page]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getAllRoles()
  }, [getAllRoles]);

  const handleDelete = (event, record) => {
    dispatch(onDeleteUserRole(record?.id));
    refetch();
  };

  const handleEdit = async (event, record) => {
    const response = await getData(`/api/roles/${record?.id}/edit`);

    if (response && response?.status === 200) {
      navigate("/settings/role/create-role", {
        state: {
          id: response.data.data.id,
          name: response.data.data.name,
          permissions: response.data.data.permissions,
        },
      });
    }
  };

  const tableColumns = [
    { title: "Name", dataIndex: "name", key: "name", width: "80%" },
    {
      title: "Action",
      key: "action",
      width: "80%",
      render: (_, record) => (
        <>
          {userRoleList.length >= 1 && (
            <div className="centered-item-parent">
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={(event) => handleDelete(event, record)}
              >
                <Button
                  size="small"
                  danger
                  style={{ marginLeft: 5 }}
                  disabled={!hasPermission([ROLE_DELETE_PERMISSION])}
                >
                  {hasPermission([ROLE_DELETE_PERMISSION])
                    ? "Delete"
                    : "No Permission"}
                </Button>
              </Popconfirm>

              <Button
                size="small"
                style={{ marginLeft: 15 }}
                onClick={(event) => {
                  setSelectedUserRole(record);
                  handleEdit(event, record);
                }}
                disabled={!hasPermission([ROLE_EDIT_PERMISSION])}
              >
                {hasPermission([ROLE_EDIT_PERMISSION])
                  ? "Edit"
                  : "No Permission"}
              </Button>
            </div>
          )}
        </>
      ),
    },
  ];

  const handleNav = (event, record) => {
    navigate("/settings/role/create-role", {
      state: props.selectedUserDetails,
    });
    window.location.reload();
  };

  const header = (
    <AppPageHeader
      extra={
        <ExtraContent
          refresh={refresh}
          setRefresh={setRefresh}
          handleAddItemOpen={handleNav}
          disabled={!hasPermission([ROLE_CREATE_PERMISSION])}
          addButtonText={
            hasPermission([ROLE_CREATE_PERMISSION])
              ? "Add New"
              : "No Permission"
          }
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      }
      title={<Title level={5}>User Role List</Title>}
    />
  );


  const content = (
    <>
      <FilterComponent
        tableData={userRoleList}
        filterAPI={getAllRoles}
        showFilter={showFilter}
        filterName={"user_role_filter"}
        setShowFilter={setShowFilter}
      />
      <UserRoleListTable
      onChange={(newPage) => {
        onPageChange(newPage);
      }}
      total={total}
      current={page}
      defaultCurrent={page}
      pageSize={pageSize}
      dataSource={userRoleList}
      tableColumns={tableColumns}
      pagination={true}
    />
    </>
    
  );
  return (
    <>
      <AppContent header={header} content={content} />
    </>
  );
};

export default UserRoleList;
