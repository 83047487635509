import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
    DatePicker,
    TimePicker,
    Divider,
    Space,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    AUDIT_DATE_LIST_ENDPOINT,
    AUDIT_DATE_ADD_ENDPOINT,
    AUDIT_DATE_EDIT_ENDPOINT,
    AUDIT_DATE_DELETE_ENDPOINT,
    AUDIT_PARTY_LIST_ENDPOINT,
    AUDIT_PARTY_ADD_ENDPOINT,
    PURPOSE_LIST_ENDPOINT,
    PURPOSE_ADD_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";
import { cglFormatDate } from "utils/functions";

const AuditDate = () => {

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };
  
  const onTimeChange = (time, timeString) => {
    console.log(time, timeString); // You can handle the selected time here
  };
  
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ partyList, setPartyList ] = useState();
  const [newParty, setNewParty] = useState("");
  const [ purposeList, setPurposeList ] = useState();
  const [newPurpose, setNewPurpose] = useState("");


  // Antd
  const [audit_dateForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // // Redux
  // const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllAuditDate = useCallback(
    async (filterValues) => {
      const payload = {
        page: filterValues?.page || currentPage,
        ...filterValues,
      }

      setLoading(true);

      const query = `${AUDIT_DATE_LIST_ENDPOINT}`;
      const bodyData = {
        ...payload,
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting Audit Date data");
      }
    },
    [currentPage],
  );

  const getAllPurposes = async (filterValues) => {
    setLoading(true);

    const query = `${PURPOSE_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      const filteredpurposeListData = response?.data?.data?.data?.filter(item => item.type === "Audit Visit");
      console.log("mamuuu", filteredpurposeListData);
      setPurposeList(filteredpurposeListData);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  console.log("Purpose List gulo dekhi", purposeList);

  const getAllParties = async (filterValues) => {
    setLoading(true);

    const query = `${AUDIT_PARTY_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filteredpurposeListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("mamuuu", response?.data?.data?.data);
      setPartyList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  console.log("Party List gulo dekhi", partyList);

  const addParty = async () => {
    if (newParty) {
      setLoading(true);
      const response = await postData(AUDIT_PARTY_ADD_ENDPOINT, { name: newParty });
      // console.log("response_444", response);
      if (response?.code === 200) {
        message.success("Party added successfully!");

        // Define the new purpose item and add it to the list
        const newPartyItem = { id: response?.data?.id, name: newParty };
        setPartyList([...partyList, newPartyItem]);

        // Set focus on the newly added purpose after updating the state
        setTimeout(() => {
          audit_dateForm.setFieldsValue({
            audit_party: newPartyItem.name,
          });
        }, 0);
        
        setNewParty(""); // Reset input field
      } else {
        message.error("Failed to add party");
      }
      setLoading(false);
    }
  };

  const addPurpose = async () => {
    if (newPurpose) {
      setLoading(true);
      const response = await postData(PURPOSE_ADD_ENDPOINT, { name: newPurpose, type: "Audit Visit" });
      // console.log("response_444", response);
      if (response?.code === 200) {
        message.success("Purpose added successfully!");

        // Define the new purpose item and add it to the list
        const newPurposeItem = { id: response?.data?.id, name: newPurpose };
        setPurposeList([...purposeList, newPurposeItem]);

        // Set focus on the newly added purpose after updating the state
        setTimeout(() => {
          audit_dateForm.setFieldsValue({
            purpose: newPurposeItem.name,
          });
        }, 0);
        
        setNewPurpose(""); // Reset input field
      } else {
        message.error("Failed to add purpose");
      }
      setLoading(false);
    }
  };

  

  // console.log("Line List dekhi", salesContractList);

  // Effects
  useEffect(() => {
    getAllAuditDate();
    getAllParties();
    getAllPurposes();
  }, [refresh, getAllAuditDate]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    audit_dateForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedaudit_date = async (id) => {
    setLoading(true);
    const response = await deleteData(`${AUDIT_DATE_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Audit Date Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedaudit_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      audit_party: formValues?.audit_party,
      audit_date: moment(formValues?.audit_date).format('YYYY-MM-DD'),
      purpose: formValues?.purpose ? formValues?.purpose : null,
    };

    const response = await putData(
      `${AUDIT_DATE_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Audit Date Edited Successfully");
      refetch();
      audit_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewaudit_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      audit_party: formValues?.audit_party,
      audit_date: moment(formValues?.audit_date).format('YYYY-MM-DD'),
      purpose: formValues?.purpose ? formValues?.purpose : null,
    };

    const response = await postData(AUDIT_DATE_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Audit Date Created Successfully");
      refetch();
      audit_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    console.log("recordsss", record);

    audit_dateForm.setFieldsValue({
      id: record?.id,
      audit_party: record?.audit_party,
      audit_date: moment(record?.audit_date, 'YYYY-MM-DD'), // Use moment to set the date
      purpose: record?.purpose ? record?.purpose : null,

    });
    // Trigger handleSelectChange to populate audit_party and sales contract
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Audit Party",
      dataIndex: ["audit_party"],
      key: "audit_party",
    },
    {
      title: "Audit Date",
      dataIndex: ["audit_date"],
      key: "audit_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Purpose",
      dataIndex: ["purpose"],
      key: "purpose",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedaudit_date(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                
                addButtonText="Add New"
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Audit Date</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllAuditDate}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="audit_date"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Audit Date" : "Create Audit Date"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={audit_dateForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedaudit_date : createNewaudit_date}
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="audit_party"
                label="Select an Audit Party"
                rules={[
                  {
                    required: true,
                    message: " Audit Party is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select an Audit Party"
                  showSearch
                  style={{ width: "100%" }}
                  onSearch={(value) => getAllParties({ name: value })}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter New Party"
                          value={newParty}
                          onChange={(e) => setNewParty(e.target.value)}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addParty}
                        >
                          Add Party
                        </Button>
                      </Space>
                    </>
                  )}
                >
                  {partyList?.map((item) => (
                    <Option key={item.id} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="audit_date"
                placeholder="Audit Date"
                label="Audit Date"
                rules={[
                  {
                    required: true,
                    message: "Audit Date is Required",
                  },
                ]}
              >
                <CustomDatePicker size="medium" style={{width: "100%"}} onChange={onDateChange} />
              </Form.Item>
            </Col>
            
            <Col span={24}>
              <Form.Item
                name="purpose"
                label="Select a Purpose"
                rules={[
                  {
                    required: true,
                    message: "Purpose is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Purpose"
                  showSearch
                  style={{ width: "100%" }}
                  onSearch={(value) => getAllPurposes({ name: value })}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter New Purpose"
                          value={newPurpose}
                          onChange={(e) => setNewPurpose(e.target.value)}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addPurpose}
                        >
                          Add Purpose
                        </Button>
                      </Space>
                    </>
                  )}
                >
                  {purposeList?.map((item) => (
                    <Option key={item.id} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AuditDate;
