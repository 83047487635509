import { useNavigate } from "react-router";
import { Button, Collapse, Form, Layout, Space } from "antd";
import AppPageHeader from "common/PageHeader";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import AssignmentForm from "./AssignmentForm";
import AssignmentDetails from "../AssignmentDetails/AssignmentDetails";
import StylePOForm from "./StylePOForm";
import StylePOAssignmentDetails from "../StylePOAssignmentDetails/StylePOAssignmentDetails";
import { useContext } from "react";
import { AssignmentContext } from "context/AssignmentContext/AssignmentContextProvider";

const AssignmentEntry = () => {
  // Contexts
  const { setLineAssignmentDetails, setLineWiseData } =
    useContext(AssignmentContext);

  // Antd
  const [form] = Form.useForm();
  const { Content } = Layout;
  const { Panel } = Collapse;

  // Router
  const navigate = useNavigate();

  const goBackToAssignmentRoute = () => {
    navigate("/production/assignment");
  };

  const onReset = () => {
    // Reset form fields and tables
    form.resetFields();
    setLineAssignmentDetails(null);
    setLineWiseData(null);
  };

  const extra = (
    <div className="extra-button-container">
      <Space>
        <Button
          size="small"
          htmlType="submit"
          onClick={goBackToAssignmentRoute}
        >
          Back
        </Button>
        <Button size="small" htmlType="submit" onClick={onReset}>
          Reset
        </Button>
      </Space>
    </div>
  );

  const onFinish = async (values) => {};

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader title="Assignment Entry" extra={extra} />
        <Content className="item-details">
          {/* Assignment Form */}
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Assignment Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <AssignmentForm form={form} />
              </Form>
            </Panel>
          </Collapse>
          {/* Assignment Details Table */}
          <AssignmentDetails form={form} />
          {/* Style-PO Form */}
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Style-PO</b>} key="1">
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <StylePOForm form={form} />
              </Form>
            </Panel>
          </Collapse>
          {/* Assignment Details Table */}
          <StylePOAssignmentDetails />
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default AssignmentEntry;
