import { Card, Form, Modal, Row, Col, Button } from "antd";
import {
  CopyOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./POTable.css";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../utils/functions";
import { useState } from "react";
import DrawerForm from "./DrawerForm";

const StylePODetails = (props) => {
  // Props
  const {
    form,
    dynamicSizeList,
    styleList,
    mode,
    showOrderTotal,
    showTotalRowWise,
    removePoRow,
    styleWiseInfo,
    setStyleWiseInfo,
    setDynamicSizeList,
  } = props;

  // Antd
  const { confirm } = Modal;
  const [drawerForm] = Form.useForm();

  // States
  const [hoverData, setHoverData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);

  const removeRow = (row_index) => {
    confirm({
      title: "Do you want to delete this item?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const foundPoItem = styleWiseInfo?.find(
          (item) => item?.unique_id === row_index,
        );
        const updatedPoDetailsData = styleWiseInfo.filter(
          (item) => item?.unique_id !== row_index,
        );
        setStyleWiseInfo(updatedPoDetailsData);

        // Call remove po row API
        removePoRow(foundPoItem);
      },
    });
  };

  const copyRow = (row_id) => {
    const foundPoItem = styleWiseInfo.find(
      (item) => item?.unique_id === row_id,
    );
    const clonedFoundPoItem = structuredClone(foundPoItem);
    const lastPoItemId = styleWiseInfo[styleWiseInfo.length - 1]?.unique_id;

    clonedFoundPoItem?.sizes?.forEach((sizeItem, index) => {
      sizeItem.po_detail_id = 0;
    });

    const updatePoDetails = [
      ...styleWiseInfo,
      {
        ...clonedFoundPoItem,
        unique_id: lastPoItemId + 1,
      },
    ];
    setStyleWiseInfo(updatePoDetails);
  };

  const showSizeColumnTotal = (sizeObject) => {
    let sizeColumnTotal = 0;

    styleWiseInfo?.forEach((poObject, poIndex) => {
      poObject &&
        poObject?.sizes?.forEach((poSizeObject, poSizeIndex) => {
          if (poSizeObject?.id === sizeObject?.value) {
            sizeColumnTotal += parseInt(
              poSizeObject?.sales_order_quantity || 0,
            );
          }
        });
    });

    return sizeColumnTotal;
  };

  const RowInfoUI = ({ rowData }) => {
    return (
      <div
        style={{ padding: "10px", display: rowData ? "unset" : "none" }}
        className="row-ui-bottom"
      >
        <div>
          <strong>Internal PO:</strong> {rowData?.internal_po || "N/A"},
          <strong>Original PO:</strong> {rowData?.original_po || "N/A"},
          <strong>Style:</strong> {rowData?.style?.style_no || "N/A"},
          <strong>Color:</strong> {rowData?.color?.name || "N/A"},
          <strong>Substyle:</strong>{" "}
          {rowData?.sub_style_info?.sub_style_no || "N/A"}
        </div>
      </div>
    );
  };

  const addNewRow = () => {
    const clonedStyleWiseData = structuredClone(styleWiseInfo);
    const lastPoItemId =
      clonedStyleWiseData[clonedStyleWiseData.length - 1]?.unique_id;

    const sizesArray =
      isArrayAndHasValue(dynamicSizeList) &&
      dynamicSizeList.map((size) => {
        return {
          id: size?.value,
          sales_order_quantity: size?.quantity || 0,
        };
      });

    const updatedStyleWiseData = [
      ...clonedStyleWiseData,
      {
        unique_id: lastPoItemId || lastPoItemId === 0 ? lastPoItemId + 1 : 0,
        sizes: isArrayAndHasValue(sizesArray) ? sizesArray : [],
      },
    ];
    setStyleWiseInfo(updatedStyleWiseData);

    // Open drawer for adding new row
    setDrawerOpen(true);
    setUniqueId(lastPoItemId || lastPoItemId === 0 ? lastPoItemId + 1 : 0);
  };

  const editRow = (index) => {
    // Size data of selected po item
    const selected_sizes_data = styleWiseInfo[index]?.sizes;

    // Update dynamic size list with selected sizes data
    const customDynamicSizeList = dynamicSizeList?.map((size) => {
      return {
        ...size,
        quantity:
          selected_sizes_data?.find((item) => item?.id === size?.value)
            ?.sales_order_quantity || 0,
      };
    });
    setDynamicSizeList(customDynamicSizeList);

    // Open drawer and set unique id
    setDrawerOpen(true);
    setUniqueId(index);
  };

  const onDrawerClose = () => {
    setDrawerOpen(false);
    setUniqueId(null);

    // Reset form fields
    drawerForm.resetFields();
  };

  const onFinishDrawerForm = (values) => {
    // Create updated style data
    const foundStyleInfo = styleList.find(
      (item) => item?.style_info?.id === values?.style_id,
    );
    const styleObject = {
      id: foundStyleInfo?.style_info?.id,
      style_no: foundStyleInfo?.style_info?.style_no,
      description: foundStyleInfo?.style_info?.description,
    };

    // Create updated sub style data
    const foundSubstyleInfo = foundStyleInfo?.style_info?.sub_style.find(
      (item) => item?.id === values?.sub_style_id,
    );
    const subStyleObject = {
      id: foundSubstyleInfo?.id,
      sub_style_no: foundSubstyleInfo?.sub_style_no || null,
    };

    // Create updated color data
    const foundColorInfo = foundSubstyleInfo?.color_info;
    const colorObject = {
      id: foundColorInfo?.id,
      name: foundColorInfo?.name,
    };

    // Create updated brand data
    const foundBrandInfo = foundStyleInfo?.style_info?.brand_info;
    const brandObject = {
      id: foundBrandInfo?.id,
      name: foundBrandInfo?.name,
    };

    // Copy styleWiseInfo state array
    const styleWiseInfoCopy = [...styleWiseInfo];
    const selected_style_sizes_data = styleWiseInfoCopy[uniqueId]?.sizes;

    // Update sizes array in styleWiseInfo
    const updatedSizesArray = dynamicSizeList.map((size) => {
      const foundSizeItem = selected_style_sizes_data?.find(
        (item) => item?.id === size?.value,
      );
      return {
        id: size?.value,
        sales_order_quantity: size?.quantity || 0,
        po_detail_id: foundSizeItem?.po_detail_id || 0,
        delivery_date: foundSizeItem?.delivery_date || null,
      };
    });

    const customStyleWiseInfoData = {
      unique_id: uniqueId,
      style: styleObject,
      color: colorObject,
      sub_style: subStyleObject,
      brand: brandObject,
      extra_cutting: values?.extra_cutting || 0,
      sizes: updatedSizesArray,
    };

    // Replace selected item data with custom updated data
    let foundStyleWiseItem = styleWiseInfoCopy.find(
      (item) => item?.unique_id === uniqueId,
    );
    foundStyleWiseItem = {
      ...foundStyleWiseItem,
      ...customStyleWiseInfoData,
    };

    styleWiseInfoCopy[uniqueId] = foundStyleWiseItem;

    setStyleWiseInfo(styleWiseInfoCopy);
  };

  return (
    <Form form={form}>
      <div className="">
        <Card className="mt-3" title="Style PO Details">
          <div className="custome-table">
            <table border="1">
              <thead>
                <tr>
                  <th key="header_serial_number" className="bc-java ">
                    SL. No.{" "}
                  </th>
                  <th key="header_style" className="bc-java ">
                    Style{" "}
                  </th>
                  <th key="header_color" className="bc-java ">
                    Color
                  </th>
                  <th key="header_sub_style" className="bc-java ">
                    Sub Style{" "}
                  </th>
                  {dynamicSizeList.map((obj, index) => {
                    return (
                      <th
                        key={`size_column_key_${index}`}
                        className="bc-orange"
                      >
                        {obj.label}
                      </th>
                    );
                  })}
                  <th key="header_total" className="bc-java ">
                    Total
                  </th>
                  <th key="header_brand" className="bc-java ">
                    Brand
                  </th>
                  <th key="header_extra_quantity" className="bc-java ">
                    Extra Cutting
                  </th>
                  {mode !== "view" && (
                    <>
                      {mode === "add" && (
                        <th key="header_add_row" className="bc-java ">
                          Copy
                        </th>
                      )}
                      <th key="header_add_row" className="bc-java ">
                        Edit
                      </th>
                      {isArrayAndHasValue(styleWiseInfo) && (
                        <th key="header_delete_row" className="bc-java ">
                          Delete
                        </th>
                      )}
                    </>
                  )}
                </tr>
              </thead>

              <tbody className="scrollable-table-container">
                {isArrayAndHasValue(styleWiseInfo) ? (
                  <>
                    {styleWiseInfo.map((obj, index) => {
                      return (
                        <tr
                          key={`table_parent_key_${index}`}
                          onDoubleClick={() => {
                            editRow(index);
                          }}
                        >
                          {/* SL */}
                          <td className="t-body sticky">{index + 1}</td>
                          {/* Style */}
                          <td className="t-body sticky">
                            {`${obj?.style?.style_no || ""}${
                              obj?.style?.description
                                ? ` : ${obj?.style?.description}`
                                : ""
                            }`}
                          </td>
                          {/* Color */}
                          <td className="t-body sticky">
                            {obj?.color?.name || ""}
                          </td>
                          {/* Substyle */}
                          <td className="t-body sticky">
                            {obj?.sub_style?.sub_style_no ||
                              obj?.sub_style?.id ||
                              "N/A"}
                          </td>
                          {/* Sizes */}
                          {dynamicSizeList.map((size, index) => {
                            return (
                              <td
                                className="t-body"
                                key={`dynamic_size_key_${index}`}
                              >
                                {obj?.sizes?.find(
                                  (sizeItem) => sizeItem?.id === size?.value,
                                )?.sales_order_quantity || 0}
                              </td>
                            );
                          })}
                          {/* Total */}
                          <td className="t-body">
                            {commaSeparateNumber(showTotalRowWise(obj))}
                          </td>
                          {/* Brand */}
                          <td className="t-body">{obj?.brand?.name || ""}</td>
                          {/* Extra Cutting */}
                          <td className="t-body">{obj?.extra_cutting || 0}</td>
                          {/* Actions */}
                          {mode === "add" && (
                            <td
                              className="t-body"
                              onClick={() => {
                                copyRow(obj?.unique_id);
                              }}
                            >
                              <CopyOutlined
                                className="cursore-pointer"
                                style={{ fontSize: "16px", color: "Green" }}
                              />
                            </td>
                          )}
                          {mode !== "view" && (
                            <td
                              className="t-body"
                              onClick={() => {
                                editRow(index);
                              }}
                            >
                              <EditOutlined
                                className="cursore-pointer"
                                style={{ fontSize: "16px", color: "Green" }}
                              />
                            </td>
                          )}
                          {mode !== "view" &&
                            isArrayAndHasValue(styleWiseInfo) && (
                              <td
                                className="t-body"
                                onClick={() => {
                                  removeRow(obj?.unique_id);
                                }}
                              >
                                <MinusCircleOutlined
                                  className="cursore-pointer"
                                  style={{ fontSize: "16px", color: "red" }}
                                />
                              </td>
                            )}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
                {isArrayAndHasValue(styleWiseInfo) &&
                isArrayAndHasValue(dynamicSizeList) ? (
                  <tr>
                    <td className="t-body bc-white" colSpan="4" style={{ textAlign: "right", fontWeight: 'bold' }}>Total</td>
                    {dynamicSizeList.map((sizeObject, index) => {
                      return (
                        <td
                          className="t-header bc-white"
                          key={`total_count_key_${index}`}
                        >
                          {commaSeparateNumber(showSizeColumnTotal(sizeObject))}
                        </td>
                      );
                    })}
                    <td className="t-body bc-white" style={{ textAlign: "center", fontWeight: 'bold' }}>
                      {" "}
                      {commaSeparateNumber(showOrderTotal(styleWiseInfo))}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </Card>
      </div>

      <Row>
        <Col span={4}>
          <Button
            style={{
              width: "200px",
              position: "fixed",
              paddingRight: "30px",
              bottom: "1vh",
              zIndex: "100",
            }}
            type="primary"
            className="mt-2"
            icon={<PlusOutlined />}
            size="small"
            onClick={addNewRow}
            disabled={mode === "view" || mode === "update"}
          >
            Add
          </Button>
        </Col>
        <Col span={4}>
          <div>
            <RowInfoUI
              rowData={hoverData}
              className="row-ui-bottom"
              size="small"
            />
          </div>
        </Col>
      </Row>

      {drawerOpen && (
        <DrawerForm
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          onDrawerClose={onDrawerClose}
          onFinishDrawerForm={onFinishDrawerForm}
          styleList={styleList}
          uniqueId={uniqueId}
          styleWiseInfo={styleWiseInfo}
          setStyleWiseInfo={setStyleWiseInfo}
          dynamicSizeList={dynamicSizeList}
          setDynamicSizeList={setDynamicSizeList}
          mode={mode}
          drawerForm={drawerForm}
        />
      )}
    </Form>
  );
};

export default StylePODetails;
