import { Col, Form, Row, Select, Input } from "antd";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  GET_B2BLCS_FOR_NOTING,
  GET_INVOICES_FOR_NOTING,
  STYLE_MERCHANDISING_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
const NotingAssessmentFilterFields = ({ initialTableData }) => {
  const { Option } = Select;
  const [b2bLcs, setB2bLcs] = useState([]);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    getInvoicess();
  }, []);

  const getInvoicess = async () => {
    let res = await getData(GET_INVOICES_FOR_NOTING);
    //console.log("res?.data?.data", res?.data?.data);
    if (res) {
      setInvoices(res?.data?.data || []);
    }
  };

  //console.log("b2blcs", b2bLcs);
  return (
    <>
      <Col span={4}>
        <Form.Item label="Invoice No" name="invoice_id">
          <Select
            showSearch
            placeholder="Select Invoice No"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {invoices?.length &&
              invoices.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.invoice_number}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="UD Number" name="utilization_declearation_number">
          <Input placeholder="UD Number" size="small" />
        </Form.Item>
      </Col>
      
    </>
    
  );
};

export default NotingAssessmentFilterFields;
