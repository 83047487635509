import { Col, Form, Input, message, Select } from "antd";
import { SUB_CONTRACT_FACTORY } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useEffect, useState } from "react";

const SubSalesContractFilterFields = ({ initialTableData }) => {
  const [factoryOptions, setFactoryOptions] = useState([]);

  const getFactoryName = async () => {
    const query = `${SUB_CONTRACT_FACTORY}`;

    const response = await getData(query);
    if (response?.status === 200) {
      setFactoryOptions(
        response?.data?.data?.data?.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      );
    } else {
      message.error("Error getting vehicle info data");
    }
  };

  useEffect(() => {
    getFactoryName();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item label="Reference Number" name="reference_no">
          <Input size="small" placeholder="Write Reference Number" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Factory Name" name="factory_id">
          <Select
            size="small"
            placeholder="Select Factory"
            options={factoryOptions}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default SubSalesContractFilterFields;
