import React, { useRef } from "react";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../Common/Header";
import ExceptionForm from "./ExceptionForm";

const AddOrUpdateException = (props) => {
  // Props
  const { editMode, viewMode } = props;

  //  Refs
  const propertyFormRef = useRef(null);

  //  Antd
  const [form] = Form.useForm();

  //  Router
  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/production-planning/exceptions");
  };

  const addIou = () => {
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={`${
              editMode ? "Edit" : viewMode ? "View" : "Add"
            } Weekend and Holiday Exceptions`}
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => addIou()}
                >
                  Save
                </Button>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      <ExceptionForm
        ref={propertyFormRef}
        form={form}
        editMode={editMode}
        viewMode={viewMode}
      />
    </>
  );
};

export default AddOrUpdateException;
