import React, { useState, useRef, useEffect, useCallback } from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  Input,
  Select,
  Space,
  Button,
  Col,
  InputNumber,
  Form,
  Card,
  Table,
} from "antd";
import { getData, postData } from "apiServices/common";
import {
  EXPORT_SALES_TYPES,
  EXPORT_SALES_TYPES_ADD,
} from "apiServices/API_ENDPOINTS";
import {
  onCreateNewFreightTypes,
  onRemoveFreightTypes,
  onSaveFreightTypes,
} from "redux/actions/Export";

const exportFreightItemsSelector = (state) => state.export.exportFreightItems;
const exportFreightSaveTypesSelector = (state) =>
  state.export.exportFreightTypesSave;

const FreightSettingFormItem = ({ form, updateFreightValue, view }) => {
  const dispatch = useDispatch();
  const exportFreightItems = useSelector(exportFreightItemsSelector);
  const exportFreightSaveTypes = useSelector(exportFreightSaveTypesSelector);

  const [name, setName] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showValues, setShowValues] = useState(false);
  const inputRef = useRef(null);

  const typesColumns = [
    {
      title: "Types",
      dataIndex: "item",
      key: "types",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record, index) => (
        <MinusCircleOutlined
          style={{ fontSize: "24px", color: "#08c" }}
          className="dynamic-delete-button"
          onClick={() => removeValues(index)}
        />
      ),
    },
  ];

  // HANDLE METHOD START
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSelectChange = (value) => {
    setSelectedItem(value);

    setShowValues(true);
  };

  const addItem = async (e) => {
    e.preventDefault();

    if (name === "" || name === null) {
      return;
    }

    await storeTypes({ type_name: name });
    await getTypes();

    setName("");
    setSelectedItem(null);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const saveTypesAndValues = () => {
    const typesValue = form.getFieldValue("types_value") || 0;
    if (selectedItem) {
      const selectValueName = exportFreightItems.filter(
        (items) => items.id === selectedItem,
      );

      dispatch(
        onSaveFreightTypes([
          {
            id: selectValueName[0]?.id,
            item: selectValueName[0]?.type_name,
            value: typesValue,
          },
        ]),
      );

      // FOR BTB VALUE UPDATE
      const total_Freight_value =
        (exportFreightSaveTypes && exportFreightSaveTypes.length > 0
          ? exportFreightSaveTypes.reduce((acc, item) => acc + item.value, 0)
          : 0) + typesValue;
      updateFreightValue(total_Freight_value);

      newFormFieldAdd(typesValue); // New Form Field add `freight_value`

      form.resetFields(["freight_types", "types_value"]);
      setSelectedItem(null);
      setShowValues(false);
    }
  };

  const removeValues = (index) => {
    const removedItem = exportFreightSaveTypes[index];
    const removedSaveItems = exportFreightSaveTypes.filter(
      (item, i) => i !== index,
    );

    dispatch(onRemoveFreightTypes(removedSaveItems));

    // Remove from form field
    const currentFreightValue = form.getFieldValue("freight_value") || [];
    const updatedFreightValue = currentFreightValue.filter(
      (item) => item?.type_id !== removedItem?.id,
    );
    form.setFieldsValue({ freight_value: updatedFreightValue });

    // FOR BTB VALUE UPDATE
    const newFilter = exportFreightSaveTypes.filter(
      (item) => item.id !== removedItem.id,
    );
    const total_Freight_value = newFilter.reduce(
      (acc, item) => acc + item.value,
      0,
    );
    updateFreightValue(total_Freight_value);
    form.resetFields(["freight_types", "types_value"]);
  };

  const newFormFieldAdd = (typesValue) => {
    const currentFreightValue = form.getFieldValue("freight_value") || [];

    const existingIndex = currentFreightValue.findIndex(
      (item) => item.type_id === selectedItem,
    );

    if (existingIndex !== -1) {
      const updatedFreightValue = [...currentFreightValue];
      updatedFreightValue[existingIndex].value = typesValue;

      form.setFieldsValue({
        freight_value: updatedFreightValue,
      });
    } else {
      form.setFieldsValue({
        freight_value: [
          ...currentFreightValue,
          {
            type_id: selectedItem,
            value: typesValue,
          },
        ],
      });
    }
  };
  // HANDLE METHOD END

  // API START
  const getTypes = useCallback(async () => {
    let res = await getData(EXPORT_SALES_TYPES);
    if (res) {
      let masterData = res.data.data;
      if (masterData) {
        const filteredArray =
          exportFreightSaveTypes.length > 0
            ? masterData.filter(
                (item1) =>
                  !exportFreightSaveTypes.some(
                    (item2) => item2?.id === item1?.id,
                  ),
              )
            : masterData;

        dispatch(onCreateNewFreightTypes(filteredArray));
      }
    }
  }, [exportFreightSaveTypes, dispatch]);

  const storeTypes = async (bodyData) => {
    await postData(EXPORT_SALES_TYPES_ADD, bodyData);
  };
  // API END
  useEffect(() => {
    getTypes();
  }, [getTypes]);

  // RENDER CONTENT START
  const dropdownRenderSelect = (menu) => (
    <>
      {menu}
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 8px 4px" }}>
        <Input
          placeholder="Please enter item"
          ref={inputRef}
          value={name}
          onChange={onNameChange}
          onKeyDown={(e) => e.stopPropagation()}
          size="small"
        />
        <Button type="text" onClick={addItem}>
          Add item
        </Button>
      </Space>
    </>
  );

  const options = exportFreightItems.map((item) => ({
    label: item?.type_name,
    value: item?.id,
  }));
  // RENDER CONTENT END

  return (
    <>
      <Col className="gutter-row" span={8}>
        <Space>
          <Form.Item label="Other Charges (or create one)" name="freight_types">
            <Select
              style={{ width: "205px" }}
              placeholder="Other Charges"
              dropdownRender={(menu) => dropdownRenderSelect(menu)}
              options={options}
              onChange={handleSelectChange}
              value={selectedItem}
              size="small"
              disabled={view}
            />
          </Form.Item>
          {showValues && (
            <>
              <Form.Item label="Value" name="types_value">
                <InputNumber
                  style={{ width: "205px" }}
                  placeholder="Value"
                  type="number"
                  size="small"
                  disabled={view}
                />
              </Form.Item>
              <Button
                icon={<PlusCircleOutlined />}
                type="default"
                className="mt-1"
                onClick={saveTypesAndValues}
                size="small"
                disabled={view}
              >
                Add Charge
              </Button>
            </>
          )}
        </Space>
      </Col>
      <Col className="gutter-row" span={24}>
        {exportFreightSaveTypes.length > 0 && (
          <Form.Item>
            <Card>
              <Table
                id="item"
                dataSource={exportFreightSaveTypes}
                footer={() => (
                  <p style={{ color: "grey" }}>
                    BTB Value: <span>{form.getFieldValue("btb_value")}</span>
                  </p>
                )}
                columns={typesColumns}
                pagination={false}
                scroll={{ y: 150 }}
              />
            </Card>
          </Form.Item>
        )}
      </Col>
    </>
  );
};

export default FreightSettingFormItem;
