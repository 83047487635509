import {
    CLEAR_EXPORT_STATE,
    EXPORT_FRIEGHT_ITEM, EXPORT_FRIEGHT_TYPES_REMOVE, EXPORT_FRIEGHT_TYPE_SAVE,
} from '../ActionTypes';

const initialState = {
    exportFreightItems: [],
    exportFreightTypesSave: [],
};

const ExportReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPORT_FRIEGHT_ITEM:
            return {
                ...state,
                exportFreightItems: [...action.payload],
            };
        case EXPORT_FRIEGHT_TYPE_SAVE:
            return {
                ...state,
                exportFreightTypesSave: [...state?.exportFreightTypesSave, ...action.payload]
            }
        case EXPORT_FRIEGHT_TYPES_REMOVE:
            return {
                ...state,
                exportFreightTypesSave: [...action.payload]
            }
        case CLEAR_EXPORT_STATE:
            return initialState;
        default:
            return state;
    }
};


export default ExportReducer;
