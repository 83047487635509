import { Col, DatePicker, Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GET_BOLT_SEAL_LOG_REPORT_LIST_ENDPOINT } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import CustomDatePicker from "common/CustomDatePicker";

const ReportBoltSealFilterFields = ({ initialTableData }) => {
  // States
  const [suppliersList, setSuppliersList] = useState(null);

  // Antd
  const { Option } = Select;

  const getAllInventoryDetail = useCallback(async () => {
    const query = `${GET_BOLT_SEAL_LOG_REPORT_LIST_ENDPOINT}`;

    const response = await getData(query);

    if (response?.status === 200) {
      setSuppliersList(response?.data?.data?.suppliers);
    }
  }, []);

  useEffect(() => {
    getAllInventoryDetail();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item label="Arrival Start Date" name="arrival_start_date">
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Arrival End Date" name="arrival_end_date">
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Dispatch Start Date" name="dispatch_start_date">
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Dispatch End Date" name="dispatch_end_date">
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default ReportBoltSealFilterFields;
