// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-cell-ob {
    display: flex;
    justify-content: center;
    /* border-bottom: none; */
    /* border-top: none; */
    border-collapse: collapse;
    border: hidden;
}

.action-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    padding: 5px;
}

.text-danger {
    color: #ff4d4f;
    font-weight: bold;
}

@keyframes highlightNewRow {
  0% { background-color: transparent; }
  50% { background-color: #1890ff33; }
  100% { background-color: transparent; }
}

.highlight-new-row {
  animation: highlightNewRow 2s ease-in-out 4;
}`, "",{"version":3,"sources":["webpack://./src/components/OperationBulletin/OBTable/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,sBAAsB;IACtB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;EACE,KAAK,6BAA6B,EAAE;EACpC,MAAM,2BAA2B,EAAE;EACnC,OAAO,6BAA6B,EAAE;AACxC;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".add-cell-ob {\n    display: flex;\n    justify-content: center;\n    /* border-bottom: none; */\n    /* border-top: none; */\n    border-collapse: collapse;\n    border: hidden;\n}\n\n.action-container {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n    align-items: center;\n    padding: 5px;\n}\n\n.text-danger {\n    color: #ff4d4f;\n    font-weight: bold;\n}\n\n@keyframes highlightNewRow {\n  0% { background-color: transparent; }\n  50% { background-color: #1890ff33; }\n  100% { background-color: transparent; }\n}\n\n.highlight-new-row {\n  animation: highlightNewRow 2s ease-in-out 4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
