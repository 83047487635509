import { Card } from "antd";
import { useEffect, useState } from "react";
import { isArrayAndHasValue } from "../../../utils/functions";

const TargetTable = (props) => {
  // Props
  const { obShowForm, obShowData } = props;

  // States
  const [updatedData, setUpdatedData] = useState(null);

  useEffect(() => {
    if (!obShowData) return;

    let showData = [];

    showData.push({
      name: "Total SMV",
      value: obShowData?.summary?.ob_summary?.total_smv ?? 0,
    });
    showData.push({
      name: "No Of Workers",
      value: obShowData?.summary?.ob_summary?.no_of_workers ?? 0,
    });
    showData.push({
      name: "No Of Hours/Day",
      value: obShowData?.summary?.ob_summary?.no_of_hours_per_day ?? 0,
    });
    showData.push({
      name: "Line Target On",
      value: obShowData?.summary?.ob_summary?.line_targe_on ?? 0,
    });
    showData.push({
      name: "Line Avg. Efficiency",
      value: obShowData?.summary?.ob_summary?.line_avg_efficiency 
            ? `${obShowData?.summary?.ob_summary?.line_avg_efficiency}%` 
            : 0,
    });
    showData.push({
      name: "Day Production",
      value: obShowData?.summary?.ob_summary?.day_production ?? 0,
    });
    showData.push({
      name: "Per hours Target",
      value: obShowData?.summary?.ob_summary?.per_hour_target ?? 0,
    });

    if (isArrayAndHasValue(showData)) {
      setUpdatedData(showData);
    }
  }, [obShowData, obShowForm]);

  return (
    <div style={{paddingTop: 15}}>
      <table className="custome-table" border="1"  style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="bc-orange text-center">OB Summary</th>
            <th className="bc-orange text-center">Value</th>
          </tr>
        </thead>
        <tbody className="table-container" >
          {updatedData &&
            updatedData.map((item, index) => (
              <tr key={index}>
                <td className="">{item.name}</td>
                <td className="move-right">{item.value}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TargetTable;
