import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";
import DrawerFormSize from "./DrawerFormSize";

const DrawerForm = (props) => {
  // Props
  const {
    drawerOpen,
    onDrawerClose,
    onFinishDrawerForm,
    styleList,
    uniqueId,
    styleWiseInfo,
    dynamicSizeList,
    setDynamicSizeList,
    mode,
    drawerForm,
  } = props;

  // States
  const [substyleList, setSubstyleList] = useState(null);

  useEffect(() => {
    // In edit mode, assign the size values to form fields
    if (mode === "update" && isArrayAndHasValue(dynamicSizeList)) {
      dynamicSizeList.forEach((size) => {
        drawerForm.setFieldsValue({
          [`${size.label}_${size.value}`]: size.quantity,
        });
      });
    }
  }, [dynamicSizeList, drawerForm, mode]);

  const updateSizeQuantity = (value, unique_id, size) => {
    const dynamicSizeListCopy = [...dynamicSizeList];
    const foundSize = dynamicSizeListCopy?.find(
      (item) => item?.value === size?.value,
    );
    foundSize.quantity = value;
    setDynamicSizeList(dynamicSizeListCopy);
  };

  const onSelectStyle = useCallback(
    (style_id) => {
      const foundStyleInfo = styleList?.find(
        (item) => item?.style_id === style_id,
      )?.style_info;

      setSubstyleList(foundStyleInfo?.sub_style);

      drawerForm.setFieldsValue({
        style_id: foundStyleInfo?.id,
        brand: foundStyleInfo?.brand_info?.name,
      });
    },
    [drawerForm, styleList],
  );

  useEffect(() => {
    if (styleWiseInfo && uniqueId > -1) {
      // Selected style item
      const selectedItem = styleWiseInfo?.[uniqueId];
      const selected_style_id = selectedItem?.style?.id;

      // Select style, get substyle list, color list and brand
      onSelectStyle(selected_style_id);

      // Fill out the form fields with data
      drawerForm.setFieldsValue({
        sub_style_id: selectedItem?.sub_style?.id,
        color_id: selectedItem?.color?.id,
        extra_cutting: selectedItem?.extra_cutting,
      });
    }
  }, [styleWiseInfo, uniqueId, drawerForm, onSelectStyle]);

  return (
    <>
      <Drawer
        title="Edit Item"
        width={720}
        onClose={onDrawerClose}
        open={drawerOpen}
        styles={{ body: { paddingBottom: 80 } }}
      >
        <Form form={drawerForm} layout="vertical" onFinish={onFinishDrawerForm}>
          <Row gutter={[8, 8]}>
            {/* Style */}
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="style_id"
                label="Style"
                rules={[
                  {
                    required: true,
                    message: "Please Select Style NO",
                  },
                ]}
              >
                <Select
                  dropdownStyle={{ minWidth: 350 }}
                  onSelect={(value) => {
                    onSelectStyle(value);
                  }}
                  showSearch
                  placeholder="Select a style"
                  optionFilterProp="children"
                  size="small"
                >
                  {isArrayAndHasValue(styleList) &&
                    styleList.map((styleItem, index) => {
                      return (
                        <Select.Option
                          value={
                            styleItem?.style_id ||
                            styleItem?.style_info?.id ||
                            undefined
                          }
                          key={`style_id_key_${index}`}
                        >
                          {styleItem?.style_info?.style_no}:{" "}
                          {styleItem?.description}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* Substyle */}
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="sub_style_id"
                label="Sub Style"
                rules={[
                  {
                    required: false,
                    message: "Please Select Substyle",
                  },
                ]}
              >
                <Select
                  dropdownStyle={{ minWidth: 350 }}
                  onChange={(value) => {
                    const foundSubstyleInfo = substyleList?.find(
                      (item) => item?.id === value,
                    );
                    drawerForm.setFieldsValue({
                      color_id:
                        foundSubstyleInfo?.color_info?.id ||
                        foundSubstyleInfo?.color_id,
                    });
                  }}
                  showSearch
                  placeholder="Select a substyle"
                  optionFilterProp="children"
                  size="small"
                >
                  {isArrayAndHasValue(substyleList) &&
                    substyleList?.map((subStyle, index) => {
                      return (
                        <Select.Option value={subStyle?.id} key={subStyle?.id}>
                          {subStyle?.sub_style_no || subStyle?.id}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* Color */}
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="color_id"
                label="Color"
                rules={[
                  {
                    required: true,
                    message: "Please Select Color!",
                  },
                ]}
              >
                <Select
                  dropdownStyle={{ minWidth: 350 }}
                  onChange={(value) => {
                    const foundSubstyleInfo = substyleList?.find(
                      (item) =>
                        item?.color_info?.id === value ||
                        item?.color_id === value,
                    );
                    drawerForm.setFieldsValue({
                      sub_style_id: foundSubstyleInfo?.id,
                    });
                  }}
                  showSearch
                  placeholder="Select an Style No"
                  optionFilterProp="children"
                  size="small"
                >
                  {isArrayAndHasValue(substyleList) &&
                    substyleList?.map((subStyle, index) => {
                      return (
                        <Select.Option
                          value={subStyle?.color_info?.id}
                          key={subStyle?.color_info?.id}
                        >
                          {subStyle?.color_info?.name ||
                            subStyle?.color_info?.id}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* Brand */}
            <Col className="gutter-row" span={12}>
              <Form.Item name="brand" label="Brand">
                <Input size="small" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            {/* Extra Cutting */}
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={"Extra Cutting"}
                name={`extra_cutting`}
                rules={[
                  { required: true, message: "Please Insert Extra Cutting!" },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  precision={2}
                  size="small"
                  placeholder="Extra Cutting (Required)"
                />
              </Form.Item>
            </Col>
          </Row>
          {isArrayAndHasValue(dynamicSizeList) && (
            <DrawerFormSize
              unique_id={uniqueId}
              dynamicSizeList={dynamicSizeList}
              setDynamicSizeList={setDynamicSizeList}
              updateSizeQuantity={updateSizeQuantity}
              commaSeparateNumber={commaSeparateNumber}
            />
          )}
          <Row gutter={8} className="mt-4">
            <Col span={12}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
                size="small"
              >
                {mode === "update" ? `Update` : `Add`}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{ width: "100%" }}
                type="danger"
                size="small"
                onClick={onDrawerClose}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerForm;
