import {
  Col,
  Input,
  DatePicker,
  Form,
  Row,
  Select,
  Typography,
  InputNumber,
} from "antd";
import { useDispatch } from "react-redux";
import {
  onGetStyleList,
} from "redux/actions";
import { commaSeparateNumber } from "utils/functions";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";

const { Option } = Select;

const CuttingForm = (props) => {
  const dispatch = useDispatch();
  const {
    styleList,
    styleDetails,
    onChangeStyle,
    onCuttingDateChange,
    onChangeInternalPO,
    onChangeOriginalPO,
    selectedCutting,
    originalPO,
  } = props;

  
  const dateFormat = "YYYY-MM-DD";
  return (
    <>
      <Row gutter={8}>
        <Col span={4}>
          <Form.Item
            label="Style No"
            name="style_id"
            rules={[
              {
                required: true,
                message: `Buying Office is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeStyle}
              onSearch={(value) => dispatch(onGetStyleList(1, 10, value))}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {styleList?.map((style, idx) => (
                <Option key={idx} value={style.id} label={style.style_no}>
                  {" "}
                  {style.style_no + " : " + style?.description || "--"}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Buying Office"
            rules={[
              {
                required: true,
                message: `Buying Office is required `,
              },
            ]}
          >
            <Input
              onChange={onChangeStyle}
              disabled={true}
              value={styleDetails?.style?.buying_office_info?.name}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Sales Contract No."
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input
              onChange={onChangeStyle}
              disabled={true}
              value={
                styleDetails?.style?.active_sales_contract_map_info
                  ?.active_sales_contract_info?.reference_no
              }
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Order Quantity"
            rules={[
              {
                required: true,
                message: `Order Quantity is required `,
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              onChange={onChangeStyle}
              disabled={true}
              value={
                styleDetails?.style?.sales_contract_map_info?.order_quantity
              }
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="Internal PO"
            name="internal_po"
            rules={[
              {
                required: true,
                message: `Internal PO is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeInternalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.po_info?.map(
                (po, idx) => (
                  <Option
                    key={idx}
                    internal_po={po?.internal_po}
                    original_po={po?.original_po}
                    sales_contract_id={po?.sales_contract_id}
                    value={po?.internal_po}
                    label={po?.internal_po}
                    po_status={po?.po_status}
                  >
                    {po?.internal_po}
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Original PO" name="original_po">
            <Select
              onChange={onChangeOriginalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.po_info?.map(
                (po, idx) => (
                  <Option
                    key={idx}
                    internal_po={po?.internal_po}
                    original_po={po?.original_po}
                    sales_contract_id={po?.sales_contract_id}
                    value={po?.original_po}
                    label={po?.original_po}
                    po_status={po?.po_status}
                  >
                    {po?.original_po}
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* <Typography.Title style={{ fontSize: 15 }}>
        Cutting Summary
      </Typography.Title>
      <Row gutter={8}>
        <Col span={4}>
          <Form.Item label="Cutting Requirement (pc.)">
            <InputNumber
              style={{ width: "100%" }}
              disabled={true}
              value={selectedCutting?.total_required_cutting}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Total Cutting (pc.)">
            <InputNumber
              style={{ width: "100%" }}
              disabled={true}
              name="total_cutting"
              value={selectedCutting?.total_cutting}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Pending Cutting (pc)">
            <InputNumber
              style={{ width: "100%" }}
              disabled={true}
              value={selectedCutting?.total_remain_cutting}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Typography.Title style={{ fontSize: 15 }}>
        Cutting Details
      </Typography.Title>
      <Row gutter={8}>
        <Col span={4}>
          <Form.Item label="Cutting of the Day (pc.)">
            <InputNumber
              style={{ width: "100%" }}
              disabled={true} 
              value={selectedCutting?.total_cutting_day}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Cutting Date"
            name="cutting_date"
            initialValue={moment()}
            rules={[
              {
                required: true,
                message: `Entry Date is required `,
              },
            ]}
          >
            <CustomDatePicker
              // format={dateFormat}
              onChange={onCuttingDateChange}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Cutting Day">
            <Input value={selectedCutting?.total_cutting_day} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CuttingForm;
