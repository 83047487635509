import { ExportOutlined } from "@ant-design/icons";
import { Button, message, Table } from "antd";
import { PACKING_CONF_LIST_ENDPOINT } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { PACKING_CREATE_PERMISSION } from "routes/permissions";
import { cglFormatDate, hasPermission } from "utils/functions";

const PackingList = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Others
  const navigate = useNavigate();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllPacking = useCallback(
    async (filterValues) => {
      setLoading(true);

      const query = `${PACKING_CONF_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...filterValues,
      };

      const response = await getData(query, false, bodyData);
      if (response && response?.data?.code === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting packing data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    const existingFilter =
      JSON.parse(localStorage.getItem("packing_filter_filter_value")) || {};

    getAllPacking(existingFilter);
  }, [refresh, getAllPacking]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const gotoAddRoute = () => {
    navigate("/production/packing/packing-confirmation");
  };

  const gotoUpdateRoute = (record) => {
    const po_map_id = record?.po_map_id;
    const sales_contract_id = record?.sales_contract_id;
    const buying_office_id = record?.buying_office_id;
    const transaction_no_per_day = record?.transaction_no_per_day;
    const sub_contract_factory_id = record?.sub_contract_factory_id;
    const packing_date = record?.packing_date
      ? moment(record?.packing_date).format("YYYY-MM-DD")
      : null;

    const updateLink = `/production/packing/update`;

    const queryParams = {
      po_map_id,
      sales_contract_id,
      buying_office_id,
      transaction_no_per_day,
      packing_date,
      sub_contract_factory_id,
    };

    navigate({
      pathname: updateLink,
      search: new URLSearchParams(queryParams).toString(),
    });
  };

  const tableColumns = [
    {
      title: "Buyer",
      dataIndex: ["buyer_name"],
      key: "buyer_name",
    },
    {
      title: "Style",
      dataIndex: ["styles", "style_no"],
      key: "style_no",
    },
    {
      title: "Original PO",
      dataIndex: ["po_map", "original_po"],
      key: "original_po",
    },
    {
      title: "Packing Date",
      dataIndex: ["packing_date"],
      key: "packing_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Transaction Time",
      dataIndex: ["transaction_no_per_day"],
      key: "transaction_no_per_day",
    },
    {
      title: "Packing Qty",
      dataIndex: ["packing_qty"],
      key: "packing_qty",
    },
    {
      title: "Remain Qty",
      dataIndex: ["remain_qty"],
      key: "remain_qty",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Button
            size="small"
            onClick={() => gotoUpdateRoute(record)}
            icon={<ExportOutlined />}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={gotoAddRoute}
                disabled={!hasPermission([PACKING_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([PACKING_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title="Packing"
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllPacking}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="packing_filter"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
                sticky={true}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default PackingList;
