import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Input,
  Select,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
  Typography,
  Space,
} from "antd";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useLocation } from "react-router-dom";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import {
  IMPORT_PI_LIST,
  SEND_PI_NOTIFICATION,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { commaSeparateNumber, hasPermission } from "../../../utils/functions";
import {
  PI_VIEW_PERMISSION,
  PI_CREATE_PERMISSION,
  PI_EDIT_PERMISSION,
  PI_DELETE_PERMISSION,
} from "../../../routes/permissions";
import { alertPop } from "../../../apiServices/common/helper";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const Forwarder = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();
  const [selectedIds, setSelectedIds] = useState(null);
  const [searchValues, setSearchValues] = useState();

  // Antd
  const { Title } = Typography;

  // Others
  const navigate = useNavigate();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const columns = [
    {
      title: "Sales Contract No",
      dataIndex: ["sale_contract_info", "reference_no"],
      key: ["sale_contract_info", "reference_no"],
    },
    {
      title: "Supplier Name",
      dataIndex: ["supplier_info", "name"],
      key: ["supplier_info", "name"],
      width: "20%",
    },
    {
      title: "PI Number",
      dataIndex: ["pi_number"],
      key: ["pi_number"],
    },
    {
      title: "PI Value",
      dataIndex: ["pi_value"],
      key: ["pi_value"],
      render: (value) => <span>{commaSeparateNumber(value?.toFixed(2))}</span>,
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: ["status"],
    },
    {
      title: "",
      key: "action",
      width: "10%",
      align: "right",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllPermitList = async (passedObject) => {
    const filter = passedObject;
    let res = await getData(IMPORT_PI_LIST, false, filter);

    if (res) {
      let masterData = res?.data;
      const PIList = masterData?.data?.data?.map((item) => {
        return {
          ...item,
          key: item.id,
        };
      });
      setPermitList(PIList);
      setTotalData(masterData?.data?.total);
      setRangeData(masterData?.data?.per_page);
    }
    setLoading(false);
  };

  useEffect(() => {
    const filterValues = localStorage.getItem("import_pi_filter_value")
    ? JSON.parse(localStorage.getItem("import_pi_filter_value"))
    : null;
    setLoading(true);
    
    getAllPermitList({
      page: currentPage,
      ...(filterValues && { ...filterValues }),
    });
  }, [currentPage, searchValues]);

  const removePi = async (value) => {
    let res = await deleteData(`/api/accounting/import-pi/delete/${value}`);
    window.location.reload();
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/import/edit-pi/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([PI_EDIT_PERMISSION]),
        },
        {
          label: (
            <Link to={`/import/view-pi/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([PI_VIEW_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removePi(data.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: "0",
          disabled: !hasPermission([PI_DELETE_PERMISSION]),
        },
      ]}
    />
  );
  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedIds(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      const lcStatus = ['Approved'];
      return {
        disabled: !lcStatus.includes(record.status) ,
      };
    },
  };

  const requestForB2BLC = async () => {
    if(!selectedIds || selectedIds?.length<=0) {
      return null;
    }
    
    const query = SEND_PI_NOTIFICATION;
    const bodyData = {
      id: selectedIds,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      alertPop('success', response?.message[0] || 'Request sent Successfully');
      setSelectedIds(null);
    } else if (response && response?.code === 422) {
      alertPop("error",response?.message[0] || "PI Documents must be attached");
      navigate("/import/pi");
    }
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        key="3"
        size="small"
        type="primary"
        disabled={!hasPermission([PI_CREATE_PERMISSION])}
      >
        <Link to="/import/add-pi">
          {hasPermission([PI_CREATE_PERMISSION]) ? "Add New" : "No Permission"}
        </Link>
      </Button>
      <Button
        key="4"
        size="small"
        type="primary"
        disabled={
          !hasPermission([PI_CREATE_PERMISSION]) ||
          !selectedIds ||
          selectedIds?.length <= 0
        }
        onClick={requestForB2BLC}
      >
        {hasPermission([PI_CREATE_PERMISSION])
          ? "Request for B2B LC"
          : "No Permission"}
      </Button>
    </Space>
  );


  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>Proforma Invoice</Title>}
            subTitle=""
            actions={extra}
          />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <CustomFilter
            showFilter={showFilter}
            tableData={permitList}
            fetchTableData={getAllPermitList}
            filterName="import_pi"
          />

          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}

export default Forwarder;
