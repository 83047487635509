import { Card, List } from "antd";

const SearchCardItem = (props) => {
  // Props
  const { data, sectionName, category } = props;

  const generateLink = (item) => {
    const link = item?.url_link || null;
    if(link) return link;

    switch (category) {
      case "styles":
        return `/merchandising/view-style/${item?.id}`;
      case "sales_contracts":
        return `/merchandising/view-sales-contact/${item?.id}`;
      case "buyers":
        return `/configuration/buyer/edit/${item?.id}`;
      case "buying_offices":
        return `/configuration/buying-office/edit/${item?.id}`;
      default:
        return null;
    }
  }

  const generateName = (item) => {
    switch (category) {
      case "styles":
        return item?.style_no;
      case "buyers":
        return item?.name;
      case "buying_offices":
        return item?.name;
      default:
        return item?.reference_no;
    }
  }

  return (
    <Card title={<span style={{ fontSize: "13px" }}>{sectionName}</span> || ""}>
      <List
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              title={
                <a
                  href={generateLink(item)}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#3366CC", fontSize: "12px" }}
                >
                  {generateName(item)}
                </a>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default SearchCardItem;
