import {
    CREATE_NEW_SALES_CONTRACT,
    DELETE_SALES_CONTRACT,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS, GET_SALES_CONTRACT_DETAIL,
    GET_SALES_CONTRACT_LIST,
    SHOW_MESSAGE,
    UPDATE_SALES_CONTRACT_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_SALES_CONTRACT_ENDPOINT,
    DELETE_SALES_CONTRACT_ENDPOINT, EDIT_SALES_CONTRACT_ENDPOINT,
    GET_SALES_CONTRACT_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetSalesContractList = (page, per_page, name) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_SALES_CONTRACT_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && { name: name }),
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_SALES_CONTRACT_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onCreateSalesContract = (salesContract) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_SALES_CONTRACT_ENDPOINT}`, salesContract)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_SALES_CONTRACT, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Item Sales Contract Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Sales Contract Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onUpdateSelectedSalesContract = (bOfcId, salesContract) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_SALES_CONTRACT_ENDPOINT}/${bOfcId}`, salesContract)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_SALES_CONTRACT_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "SalesContract Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'SalesContract updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

// export const onGetSalesContractDetails = (salesContractId) => {
//     return (dispatch) => {
//         dispatch({type: FETCH_START});
//         JwtAxios
//             .get(`/api/buying_office/${salesContractId}/edit`)
//
//             .then((data) => {
//                 if (data.status === 200) {
//                     dispatch({type: FETCH_SUCCESS});
//                     dispatch({type: GET_SALES_CONTRACT_DETAIL, payload: data.data});
//                     dispatch({
//                         type: SHOW_MESSAGE,
//                         payload: "SalesContract Updated Successfully",
//                     });
//                     // HandleNotification('success', 'bottomRight', 'Sales Contract updated Successfully!', null)
//                 } else {
//                     dispatch({
//                         type: FETCH_ERROR,
//                         payload: "Something went wrong!",
//                     });
//                     // HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
//                 }
//             })
//             .catch((error) => {
//                 dispatch({type: FETCH_ERROR, payload: error.message});
//             });
//     };
// };
export const onDeleteSalesContract = (salesContract) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_SALES_CONTRACT_ENDPOINT}/${salesContract.id}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_SALES_CONTRACT, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted Sales Contract Successfully",
                    });

                    HandleNotification('success', 'bottomRight', 'Sales Contract deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
