import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Common from "./Common";
import Item from './Item';
import ItemCategory from "./ItemCategory";
import SewingLine from "./SewingLine";
import Bank from "./Bank";
import Brand from "./Brand";
import Buyer from "./Buyer";
import Organization from "./Organization";
import BuyingOfficeReducer from "./BuyingOffice";
import BankAccountReducer from "./BankAccount";
import MerchandiserReducer from "./Merchandiser";
import Supplier from "./Supplier";
import BOMReducer from "./BOM";
import InventoryReducer from "./InventoryRM";
import SalesContractReducer from "./SalesContract";
import UserLoginReducer from "./UserLogin";
import CuttingReducer from "./Cutting";
import FoldingReducer from "./Folding";
import PackingReducer from "./Packing";
import ShipmentReducer from "./Shipment";
import StyleReducer from "./Style";
import DashboardReducer from "./Dashboard";
import UserReducer from "./User";
import UserRoleReducer from "./UserRole";
import WarehouseReducer from './Warehouse';
import FilterReducer from './Filter';
import ExportReducer from './Export';

const reducers = (history) =>
    combineReducers({
            router: connectRouter(history),
            common: Common,
            item: Item,
            login: UserLoginReducer,
            sewingLine: SewingLine,
            itemCategory: ItemCategory,
            bankAccount: BankAccountReducer,
            salesContract: SalesContractReducer,
            merchandiser: MerchandiserReducer,
            supplier: Supplier,
            bank: Bank,
            bom: BOMReducer,
            dashboard: DashboardReducer,
            style: StyleReducer,
            cutting: CuttingReducer,
            user: UserReducer,
            userRole: UserRoleReducer,
            shipment: ShipmentReducer,
            inventory: InventoryReducer,
            buyer: Buyer,
            brand: Brand,
            folding: FoldingReducer,
            packing: PackingReducer,
            organization: Organization,
            buyingOffice: BuyingOfficeReducer,
            warehouse: WarehouseReducer,
            filter: FilterReducer,
            export:ExportReducer,
    });

export default reducers;
