import { Column } from "@ant-design/plots";
import { Button, Card, Col, DatePicker, Row, Space, Table } from "antd";
import { LINE_WISE_TARGET_ACHIEVEMENT } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatNumberWithCommas } from "utils/functions";

export default function TargetVsAchievement() {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [data, setData] = useState({
    dataSource: [],
    barChart: [],
    loading: false,
  });

  const fetchData = async (date) => {
    setData((prevData) => ({ ...prevData, loading: true }));
    try {
      const query = `${LINE_WISE_TARGET_ACHIEVEMENT}?start_date=${date.format(
        "YYYY-MM-DD",
      )}`;
      const response = await getData(query);
      const sortedData = response.data.data.data
        .map((item) => {
          const unique_line_chief = [
            ...new Set(item.line_chief.map((chief) => chief?.line_chief)),
          ].join(", ");
          const unique_buyer = [
            ...new Set(item.buyer.map((buyer) => buyer?.buyer_short_name)),
          ].join(", ");

          return {
            ...item,
            line_chief: unique_line_chief,
            buyer: unique_buyer,
          };
        })
        .sort((a, b) => {
          const numA = parseInt(a.line.match(/\d+/));
          const numB = parseInt(b.line.match(/\d+/));
          return numA - numB;
        });

      setData({
        dataSource: sortedData,
        barChart: sortedData.map((item) => ({
          line: item.line,
          value: formatNumberWithCommas(item.achivement_from_be_req_qty),
        })),
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setData((prevData) => ({ ...prevData, loading: false }));
    }
  };

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const config = {
    data: data.barChart,
    xField: "line",
    yField: "value",
    label: {
      text: (originData) => {
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  const columns = [
    {
      title: "Sewing Line",
      dataIndex: "line",
      key: "line",
    },
    {
      title: "Line Chief",
      dataIndex: "line_chief",
      key: "line_chief",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
    },
    {
      title: "Required quantity for B/Even",
      dataIndex: "require_qty",
      key: "require_qty",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "IE given Target",
      dataIndex: "ie_given_target",
      key: "ie_given_target",
      align: "right",
      render: (text) =>
        parseInt(text).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    },
    {
      title: "IE Target % of B/E Qty",
      dataIndex: "ie_target_be_qty",
      key: "ie_target_be_qty",
      align: "right",
      render: (text) => `${formatNumberWithCommas(text)}%`,
    },
    {
      title: "Actual Production",
      dataIndex: "actual_production",
      key: "actual_production",
      align: "right",
      render: (text) =>
        parseInt(text).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    },
    {
      title: "Difference from Breakeven",
      dataIndex: "diff_from_be",
      key: "diff_from_be",
      align: "right",
      render: (text) => `(${formatNumberWithCommas(text)})`,
    },
    {
      title: "Difference from IE Target",
      dataIndex: "diff_from_ie_target",
      key: "diff_from_ie_target",
      align: "right",
      render: (text) => `(${formatNumberWithCommas(text)})`,
    },
    {
      title: "% of Achievement from IE target",
      dataIndex: "achievement_from_ie_target",
      key: "achievement_from_ie_target",
      align: "right",
      render: (text) => `${formatNumberWithCommas(text)}%`,
    },
    {
      title: "% of Achievement from B/E req qty",
      dataIndex: "achivement_from_be_req_qty",
      key: "achivement_from_be_req_qty",
      align: "right",
      render: (text) => `${formatNumberWithCommas(text)}%`,
    },
    {
      title: "Ranking on Achievement from B/E",
      dataIndex: "rank",
      key: "rank",
      align: "right",
    },
  ];

  return (
    <Card
      title={`
        Line wise achivement & Target details as on ${selectedDate.format(
          "DD-MMM-YYYY",
        )}
      `}
      bordered={false}
    >
      <br />
      <Row gutter={24}>
        <Col span={6}>
          <DatePicker
            allowClear={false}
            format={"DD-MMM-YYYY"}
            style={{
              width: "100%",
            }}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Col>
        <Col span={3}>
          <Space>
            <Button
              type="primary"
              danger
              onClick={() => setSelectedDate(moment())}
            >
              Reset
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <Table
        dataSource={data.dataSource}
        columns={columns}
        pagination={false}
        loading={data.loading}
        bordered
        size="small"
        summary={(pageData) => {
          if (pageData.length === 0) {
            return null;
          }
          let totalRequiredQuantityForBEven = 0;
          let totalIeGivenTarget = 0;
          let totalIeTargetPercentageOfBEQty = 0;
          let totalActualProduction = 0;
          let totalDifferenceFromBreakeven = 0;
          let totalDifferenceFromIETarget = 0;
          let totalPercentageOfAchievementFromIETarget = 0;
          let totalPercentageOfAchievementFromBEQty = 0;

          pageData.forEach(
            ({
              require_qty,
              ie_given_target,
              ie_target_be_qty,
              actual_production,
              diff_from_be,
              diff_from_ie_target,
              achievement_from_ie_target,
              achivement_from_be_req_qty,
            }) => {
              totalRequiredQuantityForBEven += require_qty;
              totalIeGivenTarget += ie_given_target;
              totalIeTargetPercentageOfBEQty += ie_target_be_qty;
              totalActualProduction += actual_production;
              totalDifferenceFromBreakeven += diff_from_be;
              totalDifferenceFromIETarget += diff_from_ie_target;
              totalPercentageOfAchievementFromIETarget +=
                achievement_from_ie_target;
              totalPercentageOfAchievementFromBEQty +=
                achivement_from_be_req_qty;
            },
          );

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>Total</Table.Summary.Cell>

                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalRequiredQuantityForBEven)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalIeGivenTarget)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    {formatNumberWithCommas(totalIeTargetPercentageOfBEQty)}%
                  </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalActualProduction)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalDifferenceFromBreakeven)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalDifferenceFromIETarget)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    {(
                      totalPercentageOfAchievementFromIETarget / pageData.length
                    ).toFixed(2)}
                    %
                  </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    {(
                      totalPercentageOfAchievementFromBEQty / pageData.length
                    ).toFixed(2)}
                    %
                  </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Today's Est. Net Profit/taka
        </h3>
        <Column {...config} />
      </div>
    </Card>
  );
}
