import {
    CREATE_NEW_BANK_ACCOUNT,
    DELETE_BANK_ACCOUNT,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS, GET_BANK_ACCOUNT_DETAIL,
    GET_BANK_ACCOUNT_LIST,
    SHOW_MESSAGE,
    UPDATE_BANK_ACCOUNT_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_BANK_ACCOUNT_ENDPOINT,
    DELETE_BANK_ACCOUNT_ENDPOINT, EDIT_BANK_ACCOUNT_ENDPOINT,
    GET_BANK_ACCOUNT_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetBankAccountList = (page, per_page, name) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_BANK_ACCOUNT_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && { name: name }),
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BANK_ACCOUNT_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};



export const onCreateBankAccount = (bankAccount) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_BANK_ACCOUNT_ENDPOINT}`, bankAccount)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_BANK_ACCOUNT, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Bank account Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Bank Account Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                console.log('error ', error);
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onUpdateSelectedBankAccount = (bankAccount) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_BANK_ACCOUNT_ENDPOINT}/${bankAccount.id}`, bankAccount)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_BANK_ACCOUNT_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "BankAccount Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Bank Account updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onGetBankAccountDetails = (bankAccountId) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`api/bank_accounts/${bankAccountId}/edit`)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BANK_ACCOUNT_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "BankAccount Updated Successfully",
                    });
                    // HandleNotification('success', 'bottomRight', 'Bank Account updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    // HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onDeleteBankAccount = (bankAccount) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_BANK_ACCOUNT_ENDPOINT}/${bankAccount.id}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_BANK_ACCOUNT, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted Buying Office Successfully",
                    });

                    HandleNotification('success', 'bottomRight', 'Bank account deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                console.log('error while deleting bankAccount ', error);
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
