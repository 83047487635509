import { Table, Typography, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  EXPORT_SWING_VS_FOLDING,
  VIEW_SWING_VS_FOLDING,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import { SWING_VS_FOLDING_REPORT_GENERATE_PERMISSION } from "../../../routes/permissions";
import { cglFormatDate, hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const SwingvsFolding = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const exportSwingvsFolding = async (filterValues) => {
    const { start_date, end_date } = filterValues;
    const bodyData = {
      ...(start_date && {
        start_date: moment(start_date).format("YYYY-MM-DD"),
      }),
      ...(end_date && { end_date: moment(end_date).format("YYYY-MM-DD") }),
    };
    if (
      Object.values(bodyData).length &&
      !Object.values(bodyData).some((val) => val === undefined)
    ) {
      setLoading(true);

      const query = `${EXPORT_SWING_VS_FOLDING}`;

      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "blob" },
        "arraybuffer",
      );

      if (response && response?.data) {
        // Download the file
        const outputFilename = `swing_vs_folding_report.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setLoading(false);
        message.success("Sewing & Folding report Exported Successfully");
        refetch();
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };
  const previewFunction = async (filterValues) => {
    setLoading(true);

    const bodyData = {
      ...filterValues,
    };

    const query = `${VIEW_SWING_VS_FOLDING}`;
    const response = await postData(query, bodyData);

    if (response?.code === 200) {
      setLoading(false);
      setTableData(response?.data);
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };
  // Effects
  // useEffect(() => {
  //   getAllFabric();
  // }, [refresh, getAllFabric]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const tableColumns = [
    {
      title: "Sewing and Folding",
      children: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          width: 200,
          render: (row) => cglFormatDate(row),
        },
        {
          title: "CGL Merchant",
          dataIndex: "merchant",
          key: "merchant",
          width: 200,
        },
        {
          title: "Buyer/1st Party",
          dataIndex: "buyer",
          key: "buyer",
          width: 200,
        },
        {
          title: "Style",
          dataIndex: "style",
          key: "style",
          width: 200,
        },
        {
          title: "Substyle",
          dataIndex: "sub_style",
          key: "sub_style",
          width: 200,
        },
        {
          title: "Color",
          dataIndex: "color",
          key: "color",
          width: 200,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 200,
        },
        {
          title: "CM",
          dataIndex: "cm",
          key: "cm",
          width: 200,
        },
        {
          title: "Line",
          dataIndex: "line",
          key: "line",
          width: 200,
        },
        {
          title: "Sewing",
          dataIndex: "sewing",
          key: "sewing",
          width: 200,
        },
        {
          title: "Reject",
          dataIndex: "reject",
          key: "reject",
          width: 200,
        },
        {
          title: "Good",
          dataIndex: "good",
          key: "good",
          width: 200,
        },
        {
          title: "Folding",
          dataIndex: "folding",
          key: "folding",
          width: 200,
        },
      ],
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportSwingvsFolding}
                hideAddButton={true}
                disabled={
                  !hasPermission([SWING_VS_FOLDING_REPORT_GENERATE_PERMISSION])
                }
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            }
            title={<Title level={5}>Sewing and Folding</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <FilterComponent
                tableData={tableData}
                filterAPI={exportSwingvsFolding}
                filterName={"sewing_and_folding"}
                searchButtonText={"Export"}
                defaultOpen
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                isPreview={true}
                previewFunction={previewFunction}
              />
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination
                bordered
                scroll={{ x: true }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default SwingvsFolding;
