import { Fragment, useEffect, useState } from "react";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, message, Row, Col, Modal, Form, Input, Select } from "antd";
import { getData, postData, putData } from "../../../../apiServices/common";
import {
  CHALLAN_ADD,
  CHALLAN_UPDATE,
  CHALLAN_DOC_ADD,
  GET_SUPPLIER_ENDPOINT,
} from "../../../../apiServices/API_ENDPOINTS";
import { hasPermission, isArrayAndHasValue } from "../../../../utils/functions";

const ChallanForm = (props) => {
  // Props
  const {
    isEdit,
    isVehicleStatus,
    onCloseModal,
    refetch,
    selectedChallan,
    supplierId,
    from,
  } = props;

  // State
  const [loading, setLoading] = useState(false);
  const [supplierList, setSupplierList] = useState(null);
  const [filesList, setFilesList] = useState([]);

  // Antd
  const [challanForm] = Form.useForm();
  const { Option } = Select;
  const { confirm } = Modal;

  // Constants
  const api_base_url = process.env.REACT_APP_BASE_URL;

  const getAllSupplier = async (filterValues) => {
    setLoading(true);

    const query = `${GET_SUPPLIER_ENDPOINT}`;
    const bodyData = {
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);
    if (response?.status === 200) {
      setLoading(false);
      setSupplierList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting supplier data");
    }
  };

  useEffect(() => {
    getAllSupplier();
  }, []);

  useEffect(() => {
    if (selectedChallan) {
      const supplierListCopy = isArrayAndHasValue(supplierList)
        ? [...supplierList]
        : [];
      const suppliers = selectedChallan?.suppliers;

      // Add suppliers not exist in the supplier list
      suppliers.forEach((supplier) => {
        const isExist = supplierListCopy.find(
          (item) => item.id === supplier.supplier_id,
        );
        if (!isExist) {
          supplierListCopy.push({
            id: supplier?.supplier_id || supplier?.supplier_info?.id,
            name: supplier?.supplier_info?.name,
          });
        }
      });

      setSupplierList(supplierListCopy);

      const makeSupplierIdData = () => {
        if (!isArrayAndHasValue(selectedChallan?.suppliers)) return null;

        if (selectedChallan?.suppliers?.length > 1) {
          // If multiple selected supplies
          return selectedChallan?.suppliers?.map((item) => {
            return item.supplier_id;
          });
        } else {
          // If single selected supplier
          return selectedChallan?.suppliers[0]?.supplier_id;
        }
      };
      challanForm.setFieldsValue({
        id: selectedChallan?.id,
        challan_no: selectedChallan?.challan_no,
        supplier_id: makeSupplierIdData(),
      });
      setFilesList(selectedChallan?.documents);
    }
  }, [selectedChallan, challanForm]);
  

  const makeSupplierIdData = (formValues) => {
    if (formValues?.supplier_id) {
      if (isArrayAndHasValue(formValues?.supplier_id)) {
        // If multiple selected supplies
        return formValues?.supplier_id?.map((item) => {
          return {
            supplier_id: item,
          };
        });
      } else {
        // If single selected supplier
        return [
          {
            supplier_id: formValues?.supplier_id,
          },
        ];
      }
    } else {
      // If supplierId coming from props (for inventory / store challan)
      return (
        [
          {
            supplier_id: supplierId,
          },
        ] || null
      );
    }
  };

  const createNewChallan = async (formValues) => {
    setLoading(true);

    const bodyData = {
      challan_no: formValues?.challan_no,
      suppliers: makeSupplierIdData(formValues),
      isVehicleStatus: isVehicleStatus,
      documents: isArrayAndHasValue(filesList)
        ? filesList?.map((item) => item.id)
        : null,
    };

    const response = await postData(CHALLAN_ADD, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Challan Created Successfully");
      refetch();
      challanForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedChallan = async (formValues) => {
    setLoading(true);

    const bodyData = {
      challan_no: formValues?.challan_no,
      suppliers: makeSupplierIdData(formValues),
      isVehicleStatus: isVehicleStatus,
      documents: isArrayAndHasValue(filesList)
        ? filesList?.map((item) => item.id)
        : null,
    };

    const response = await putData(
      `${CHALLAN_UPDATE}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Challan Edited Successfully");
      refetch();
      challanForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const addToFilesList = (newFileObject) => {
    const filesListCopy = [...filesList];
    const newData = {
      id: newFileObject.id,
      name: newFileObject.document_name,
      path: newFileObject.document,
    };
    filesListCopy.push(newData);
    setFilesList(filesListCopy);
  };

  const uploadFile = async (file) => {
    setLoading(true);
    const URL = process.env.REACT_APP_BASE_URL + CHALLAN_DOC_ADD;
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);

    fetch(URL, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        addToFilesList(result.data);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message || "Error uploading file!");
      });
  };

  const removeFile = (index) => {
    confirm({
      title: "Do you Want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        let items = filesList;
        items.splice(index, 1);
        setFilesList([...items]);
      },
    });
  };

  return (
    <Form
      form={challanForm}
      layout="vertical"
      onFinish={isEdit ? editSelectedChallan : createNewChallan}
    >
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item name="id" hidden />
          <Form.Item
            name="challan_no"
            placeholder="Challan Number"
            label="Challan Number"
            rules={[
              {
                required: true,
                message: "Challan Number Required",
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        {supplierId ? null : (
          <Col span={12}>
            <Form.Item
              name="supplier_id"
              placeholder="Supplier"
              label="Supplier"
              rules={[
                {
                  required: true,
                  message: "Supplier Required",
                },
              ]}
            >
              <Select
                placeholder="Select Supplier"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                style={{ width: "100%" }}
                size="small"
                onSearch={(value) => getAllSupplier({ name: value })}
                mode={
                  isVehicleStatus || selectedChallan?.is_vehicle_status === 1
                    ? "multiple"
                    : ""
                }
              >
                {isArrayAndHasValue(supplierList) &&
                  supplierList?.map((item, index) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item name="documents" placeholder="Documents" label="Documents">
            <input
              type="file"
              id="file-input"
              hidden
              onChange={(e) => uploadFile(e.target.files[0])}
              style={{ fontSize: "16px" }}
            />
            <label for="file-input" className="file-input-label">
              {loading ? (
                <LoadingOutlined />
              ) : (
                <UploadOutlined style={{ fontSize: "14px" }} />
              )}
              <span style={{ fontSize: "12px" }}>{` Upload`}</span>
            </label>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={24} style={{ marginBottom: 10 }}>
          {isArrayAndHasValue(filesList) ? (
            <>
              {filesList.map((file, index) => (
                <Fragment key={index}>
                  <tr>
                    <td className="t-body">{index + 1}</td>
                    <td className="t-body">
                      {file.name || file.document_name}
                    </td>
                    <td className="t-body">
                      <Button
                        type="text"
                        onClick={() =>
                          window.open(
                            api_base_url + "/" + (file.path || file.document),
                            "_blank",
                          )
                        }
                      >
                        <FilePdfOutlined style={{ fontSize: "16px" }} />
                      </Button>
                    </td>

                    <td className="t-body">
                      <DeleteOutlined
                        style={{ fontSize: "16px", color: "red" }}
                        className="cursore-pointer"
                        onClick={() => removeFile(index)}
                      />
                    </td>
                  </tr>
                </Fragment>
              ))}
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Button
            type="danger"
            style={{ width: "100%" }}
            onClick={() => {
              onCloseModal();
            }}
            size="small"
          >
            Discard
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
            size="small"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ChallanForm;
