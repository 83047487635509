
import { Col, Form, Input, Row, Select } from "antd";

const TNAProcessFields = ({ initialTableData }) => {

return (
    <>
      <Col span={6}>
        <Form.Item label="Process Title" name="title">
          <Input placeholder="Write Process Title ..." size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default TNAProcessFields;
