export const forData = [
    {
        label: "Only Web",
        name: "web",
        value: 0,
    },
    {
        label: "Only App",
        name: "app",
        value: 1,
    },
    {
        label: "Web and App Both",
        name: "both",
        value: 2,
    },
];