import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExportDetailsForm from "./ExportDetailsForm";
import { EDIT_EXPORT_DETAILS } from "../../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import moment from "moment";
import { isArrayAndHasValue } from "../../../../utils/functions";

export default function EditExportDetails(props) {
  // Props
  const { isView } = props;

  // States
  const [editMode, setEditMode] = useState(false);
  const [poFormEditValues, setPoFormEditValues] = useState(null);
  const [isDeferredProp, setIsDeferredProp] = useState(false);

  //   Refs
  const propertyFormRef = useRef(null);

  //   Antd
  const [form] = Form.useForm();

  //   Router
  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/export/export-details");
  };
  const { exportId } = useParams();

  const getSelectedExportDetails = useCallback(
    async (exportId) => {
      const response = await getData(`${EDIT_EXPORT_DETAILS}/${exportId}`);

      if (response && response.status === 200) {
        const tableData = response?.data?.data;

        // Set main form field values
        form.setFieldsValue({
          invoice_number: tableData?.invoice_number || null,
          invoice_date: tableData?.invoice_date
            ? moment(tableData?.invoice_date)
            : null,
          import_pi_id: tableData?.import_pi_id || null,
          import_pi_date: tableData?.import_pi_date
            ? moment(tableData?.import_pi_date)
            : null,
          invoice_value: tableData?.invoice_value || null,
          sales_contract_id: tableData?.sales_contract_info?.id || null,
          sales_contract_master_lc_value:
            tableData?.sales_contract_info?.contract_value || null,
          buyer_address: tableData?.buyer_info?.address || null,
          total_quantity: tableData?.total_quantity || null,
          carton_quantity: tableData?.carton_quantity || null,
          payment_term: tableData?.payment_term || null,
          payment_term_deferred: tableData?.payment_term_deferred || null,
          payment_maturity_date: tableData.payment_maturity_date
            ? moment(tableData.payment_maturity_date)
            : null,
          exp_number: tableData?.exp_number || null,
          exp_date: tableData?.exp_date ? moment(tableData?.exp_date) : null,
          booking_submission_date: tableData?.booking_submission_date
            ? moment(tableData?.booking_submission_date)
            : null,
          ex_factory_date: tableData?.ex_factory_date
            ? moment(tableData?.ex_factory_date)
            : null,
          hand_over_date: tableData?.hand_over_date
            ? moment(tableData?.hand_over_date)
            : null,
          boe_number: tableData?.boe_number || null,
          boe_date: tableData?.boe_date ? moment(tableData?.boe_date) : null,
          bl_awbl_number: tableData?.bl_awbl_number || null,
          on_board_date: tableData?.on_board_date
            ? moment(tableData?.on_board_date)
            : null,
          shipment_way: tableData?.shipment_way || null,
          origin_port: tableData?.origin_port || null,
          destination_country: tableData?.destination_country || null,
          mode_of_shipment: tableData?.mode_of_shipment || null,
          // from_date: tableData?.from_date ? moment(tableData?.from_date) : null,
          from_date: tableData?.from_info ? tableData?.froms : [],
          customs_house_code: tableData?.customs_house_code || null,
        });

        // Set PO table form field values
        const poDetailsFormValues = {
          balance_qty_list: tableData?.export_purchase_map_info?.map((item) => {
            return { balance_qty: item?.balance_qty };
          }),
          balance_value_list: tableData?.export_purchase_map_info?.map(
            (item) => {
              return { balance_value: item?.balance_value };
            },
          ),
          po_numbers_list: tableData?.export_purchase_map_info?.map((item) => {
            return { po_map_id: item?.po_map_id };
          }),
          sales_order_qty_list: tableData?.export_purchase_map_info?.map(
            (item) => {
              return { sales_order_qty: item?.po_qty };
            },
          ),
          goods_description_list:
            tableData?.export_purchase_map_info?.length &&
            tableData?.export_purchase_map_info?.map((item, index) => {
              let description_ids =
                item?.goods_description_info?.length &&
                item?.goods_description_info?.map(function (element) {
                  return element.description_id;
                });
              return { goods_description: description_ids };
            }),
          carton_qty_list: tableData?.export_purchase_map_info?.map((item) => {
            return { carton_qty: item?.carton_qty };
          }),
          ship_qty_list: tableData?.export_purchase_map_info?.map((item) => {
            return { ship_qty: item?.ship_qty };
          }),
          ship_qty_unit_list: tableData?.export_purchase_map_info?.map(
            (item) => {
              return { ship_qty_unit: item?.ship_qty_unit };
            },
          ),
          balance_qty_unit_list: tableData?.export_purchase_map_info?.map(
            (item) => {
              return { balance_qty_unit: item?.balance_qty_unit };
            },
          ),
          ship_value_list: tableData?.export_purchase_map_info?.map((item) => {
            return { ship_value: item?.ship_value };
          }),
          style_numbers_list: tableData?.export_purchase_map_info?.map(
            (item) => {
              return {
                sub_style_list: [...item?.style_info?.sub_style],
                style_id: item?.style_info?.id,
              };
            },
          ),
          sub_style_numbers_list: tableData?.export_purchase_map_info?.map(
            (item) => {
              return { sub_style_id: item?.sub_style_id };
            },
          ),
          unit_price_list: tableData?.export_purchase_map_info?.map((item) => {
            return { unit_price: item?.unit_price };
          }),
          po_doc: tableData?.export_purchase_map_info?.map((item) => {
            return item?.document ? { doc_id: item?.document?.id, po_doc: item?.document?.document ?? null, po_doc_name: item?.document?.document_name  ?? null }: null;
          }),
          // documents: isArrayAndHasValue(tableData?.documents)
          //   ? tableData?.documents
          //   : [],
        };

        // Set po details form values
        setPoFormEditValues(poDetailsFormValues);

        // Set isDeferredProp true if payment term is Deferred
        tableData?.payment_term === "Deferred" && setIsDeferredProp(true);
      } else {
        alertPop(
          "error",
          response?.messages ? response?.messages : "Something Went Wrong!",
        );
      }
    },
    [form],
  );

  useEffect(() => {
    if (exportId) {
      setEditMode(true);
      getSelectedExportDetails(exportId);
    }
  }, [exportId, getSelectedExportDetails]);

  const submitForm = () => {
    form.submit();
  };

  const renderHeaderTitle = () => {
    if(isView) {
      return "View Export Details";
    }

    if(exportId) {
      return "Update Export Details";
    }
    else {
      return "Add Export Details";
    }
  }

  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={renderHeaderTitle()}
            subTitle=""
            routes={
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/export/sales-contract">Export</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {`${exportId ? "Update" : "Save"}`}
                </Breadcrumb.Item>
              </Breadcrumb>
            }
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                  hidden={isView}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => submitForm()}
                  hidden={isView}
                >
                  {`${exportId ? "Update" : "Save"}`}
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <ExportDetailsForm
        ref={propertyFormRef}
        form={form}
        editMode={editMode}
        exportId={exportId}
        poFormEditValues={poFormEditValues}
        isDeferredProp={isDeferredProp}
        isView={isView}
      />
    </>
  );
}
