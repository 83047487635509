import { Col, Form, Input } from "antd";
import {
  VEHICLE_SIZE_ENDPOINT,
  VEHICLE_TYPE_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useEffect, useState } from "react";

const BoltSealLogFilterField = (props) => {
  const [options, setOptions] = useState({
    vehicleType: [],
    vehicleSizeId: [],
  });

  const getOptionsList = async () => {
    await getVehicleTypeList();
    await getVehicleSizeList();
  };

  const getVehicleTypeList = async () => {
    let res = await getData(VEHICLE_TYPE_ENDPOINT);

    if (res) {
      setOptions((prevState) => ({
        ...prevState,
        vehicleType: res?.data?.data?.data || [],
      }));
    }
  };
  const getVehicleSizeList = async () => {
    let res = await getData(VEHICLE_SIZE_ENDPOINT);

    if (res) {
      setOptions((prevState) => ({
        ...prevState,
        vehicleSizeId: res?.data?.data?.data || [],
      }));
    }
  };

  useEffect(() => {
    getOptionsList();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item label="Vehicle Driver Info ID" name="vehicle_driver_info_id">
          <Input placeholder="Vehicle Driver Info ID" size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default BoltSealLogFilterField;
