import { Form, Typography } from "antd";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import SalesContractForm from "../SalesContractForm";
// Antd Constants
const { Title } = Typography;
export default function SalesContractExpenseView() {
  const [form] = Form.useForm();

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent hideFilterButton hideAddButton showBackButton />
            }
            title={<Title level={5}>Sales Contract Expense View</Title>}
          />
        }
        content={
          <>
            <SalesContractForm form={form} mode="view" />
          </>
        }
      />
    </>
  );
}
