import { Col, Form, Select } from "antd";
import { useEffect, useState } from "react";
import { EXISTING_PO_MAP_LIST } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";

const PackingReportFilterFields = ({ initialTableData }) => {
  // States
  const [poList, setPoList] = useState(null);

  // Antd
  const { Option } = Select;

  const getPOList = async (value = null) => {
    let query = `${EXISTING_PO_MAP_LIST}`;
    if (value) {
      query = `${query}?po_map_id=${value}`;
    }
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setPoList(response?.data?.data);
    }
  };

  useEffect(() => {
    getPOList();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item
          label="Original PO"
          name="po_map_id"
          rules={[
            {
              required: true,
              message: "Original PO is required",
            },
          ]}
        >
          <Select
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            size="small"
            onSearch={getPOList}
          >
            {poList &&
              poList?.map((po, idx) => (
                <Option key={idx} value={po?.id}>
                  {po?.original_po}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default PackingReportFilterFields;
