import { EyeOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import HandleNotification from "common/Notification";
import { putData } from "components/BOM/AddOrUpdateStyle";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  SALES_CONTRACT_LIST,
  TNA_PLAN_BASE,
  TNA_PLAN_PURCHASE_ORDER_DETAILS,
  TNA_PLAN_STYLES_DETAILS,
  TNA_POS,
  TNA_SALES_CONTRACT,
  TNA_STYLES,
  TNA_TEMPLATE_BASE,
} from "../../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../../apiServices/common";
import {
  cglFormatDate,
  isArrayAndHasValue,
} from "../../../../../utils/functions";
import ItemDetails from "../ItemDetails";
import ListView from "../ListView.js/ListView";
import CustomDatePicker from "common/CustomDatePicker";

const AddOrUpdateForm = forwardRef((props, ref) => {
  // Props
  const { view, isEdit } = props;

  // States
  const [itemDetailsList, setItemDetailsList] = useState([]);
  const [salesContractList, setSalesContractList] = useState(null);
  const [salesContract, setSalesContract] = useState(null);
  const [TNATemplateList, setTNATemplateList] = useState(null);
  const [TNAStyles, setTNAStyles] = useState(null);
  const [TNAPos, setTNAPos] = useState(null);
  const [listTableData, updateListTableDatas] = useReducer(
    (data, partialData) => ({
      ...data,
      ...partialData,
    }),
    {
      isLoading: false,
      salesContractId: null,
      isModalOpen: false,
      listType: null,
      modalTitle: null,
      styleData: null,
      poData: null,
    },
  );

  // Router
  const { planID } = useParams();
  const navigate = useNavigate();

  // Antd
  const { Option } = Select;
  const [form] = Form.useForm();

  const onSelectSalesContract = useCallback(
    async (value) => {
      const query = `${TNA_SALES_CONTRACT}/${value}`;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {
        // setSalesContractList(response?.data?.data?.data);
        const masterData = response?.data?.data;
        setSalesContract(masterData);
        const sales_contract_data = masterData?.sales_contract;

        // Set form data
        form.setFieldsValue({
          buyer_id: sales_contract_data?.buyer?.id,
          buyer_name: sales_contract_data?.buyer?.short_name
            ? sales_contract_data?.buyer?.short_name
            : sales_contract_data?.buyer?.name,
          buying_office_id: sales_contract_data?.buying_office?.id,
          buying_office_name: sales_contract_data?.buying_office?.short_name
            ? sales_contract_data?.buying_office?.short_name
            : sales_contract_data?.buying_office?.name,
          contract_date: sales_contract_data?.contract_date,
          order_quantity: masterData?.order_quantity,
          style_no: masterData?.style_detail_count,
          po_no: masterData?.purchase_order_count,
          plan_cut_dates: masterData?.plan_cut_date.map((item) =>
            cglFormatDate(item),
          ),
          shipment_dates: masterData?.shipment_dates.map((item) =>
            cglFormatDate(item),
          ),
        });

        // Get styles and pos list
        getTNAStyles(sales_contract_data?.id);
        getTNAPos(sales_contract_data?.id);
      } else {
        message.error("Error getting sales contract data");
      }
    },
    [form],
  );

  const findSmallestDate = (dateArray) => {
    const dateObjects = dateArray.map((date) => new Date(date));
    const minDate = new Date(Math.min(...dateObjects));
    return minDate.toISOString().split("T")[0];
  };

  const getTemplateData = useCallback(
    async (value) => {
      let plan_cut_date = salesContract?.plan_cut_date;
      plan_cut_date = findSmallestDate(plan_cut_date);
      const endpoint = `${TNA_TEMPLATE_BASE}/${value}?earliest_plan_cut_date=${plan_cut_date}`;
      const response = await getData(endpoint);

      if (response && response?.data?.code === 200) {
        const masterData = response?.data?.data;

        // Update the form with data
        form.setFieldsValue({
          description: masterData?.description,
          calculation_method: masterData?.calculation_method,
        });

        // Manipulate and set the item details list
        const templateTaskData = isArrayAndHasValue(masterData?.template_tasks)
          ? masterData?.template_tasks
          : [];
        const updatedTemplateTaskData =
          isArrayAndHasValue(masterData?.template_tasks) &&
          templateTaskData.map((item) => {
            return {
              days: item?.days,
              is_add: true,
              is_pcd: item?.is_pcd === 1 ? "Yes" : "No",
              is_ship: item?.is_shipment === 1 ? "Yes" : "No",
              po_wise: item?.relation_with === "PO" ? "Yes" : "No",
              responsible_person_id: item?.responsible_person_id,
              responsible_person_name: item?.responsible_person_name,
              tna_task_name: item?.title,
              tna_task_id: item?.tna_task_id,
              tna_process_title: item?.process,
              tna_department_name: item?.department,
              style_wise: item?.relation_with === "Style" ? "Yes" : "No",
              relation_with: item?.relation_with,
              due_date: item?.due_date,
            };
          });
        setItemDetailsList(updatedTemplateTaskData);
      }
    },
    [form, salesContract],
  );

  const getTNAPlanData = useCallback(
    async (value) => {
      const endpoint = `${TNA_PLAN_BASE}/${value}`;
      const response = await getData(endpoint);

      if (response && response?.data?.code === 200) {
        const masterData = response?.data?.data;
        const sales_contract_data = masterData?.sales_contract;
        const tna_template_data = masterData?.tna_template;

        // Update the form with dat
        form.setFieldsValue({
          sales_contract_id: sales_contract_data?.id,
          template_id: masterData?.tna_template_id,
          description: tna_template_data?.description,
          calculation_method: tna_template_data?.calculation_method,
        });

        // Select sales contract and get data related to sales contract
        onSelectSalesContract(sales_contract_data?.id);

        // Manipulate and set the item details list
        const taskPlanData = isArrayAndHasValue(masterData?.plan_tasks)
          ? masterData?.plan_tasks
          : [];
        const updatedTaskPlanData =
          isArrayAndHasValue(taskPlanData) &&
          taskPlanData.map((item) => {
            return {
              id: item?.id,
              days: item?.days,
              is_add: true,
              is_pcd: item?.is_pcd === 1 ? "Yes" : "No",
              is_ship: item?.is_shipment === 1 ? "Yes" : "No",
              po_wise: item?.relation_with === "PO" ? "Yes" : "No",
              responsible_person_id: item?.responsible_person_id,
              responsible_person_name: item?.responsible_person_name,
              tna_task_name: item?.title,
              tna_task_id: item?.tna_task_id,
              tna_process_title: item?.process,
              tna_department_name: item?.department,
              style_wise: item?.relation_with === "Style" ? "Yes" : "No",
              relation_with: item?.relation_with,
              due_date: item?.due_date,
              task_dependencies: item?.task_dependencies?.map(
                (item) => item?.id,
              ),
              task_dependencies_names: item?.task_dependencies?.map(
                (item) => item?.title,
              ),
              style_ids: item?.task_styles?.map((item) => item?.id),
              style_names: item?.task_styles?.map((item) => item?.style_no),
              po_ids: item?.task_purchase_order_maps?.map((item) => item?.id),
              po_names: item?.task_purchase_order_maps?.map(
                (item) => item?.original_po,
              ),
            };
          });
        setItemDetailsList(updatedTaskPlanData);
        updateListTableDatas({ salesContractId: sales_contract_data?.id });
      }
    },
    [onSelectSalesContract, form],
  );

  useEffect(() => {
    if (isEdit && planID) {
      getTNAPlanData(planID);
    }
  }, [isEdit, planID, getTNAPlanData]);

  useImperativeHandle(ref, () => ({
    submit() {
      form
        .validateFields()
        .then(async (values) => {
          console.log("itemDetailsList PUT", itemDetailsList);
          const bodyData = {
            sales_contract_id: values?.sales_contract_id,
            tna_template_id: values?.template_id,
            is_active: 1,
            plan_tasks: itemDetailsList?.map((item) => {
              return {
                id: item?.id,
                tna_task_id: item?.tna_task_id,
                responsible_person_id: item?.responsible_person_id,
                days: item?.days,
                task_dependencies: item?.task_dependencies,
                task_styles: item?.style_ids,
                task_purchase_orders: item?.po_ids,
              };
            }),
          };

          if (!isEdit) {
            let response = await postData(TNA_PLAN_BASE, bodyData);
            if (response && response?.code === 200) {
              HandleNotification(
                "success",
                "bottomRight",
                "TNA Created Successfully",
                null,
              );
              const editID = response?.data?.id;
              navigate(`/tanda/update-plan/${editID}`);
            } else {
              HandleNotification(
                "error",
                "bottomRight",
                "Error Creating TNA",
                null,
              );
            }
          } else {
            const endpoint = `${TNA_PLAN_BASE}/${planID}`;
            let response = await putData(endpoint, bodyData);
            if (response && response?.data?.code === 200) {
              HandleNotification(
                "success",
                "bottomRight",
                "TNA Updated Successfully",
                null,
              );
              await getTNAPlanData(planID);
            } else {
              HandleNotification(
                "error",
                "bottomRight",
                "Error Updating TNA",
                null,
              );
            }
          }
        })
        .catch((errorInfo) => {
          HandleNotification(
            "error",
            "bottomRight",
            "Error! Please check the form and try again.",
            null,
          );
        });
    },
    discart() {
      form.resetFields();
    },
  }));

  const getSalesContractList = async (filterValues) => {
    const query = `${SALES_CONTRACT_LIST}`;
    const bodyData = {
      per_page: isEdit ? 10000 : 1000,
      name: filterValues?.name || "",
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSalesContractList(response?.data?.data?.data);
    } else {
      message.error("Error getting sales contract data");
    }
  };

  const getAllTNATemplates = async (filterValues) => {
    const { name } = filterValues || {};

    const query = `${TNA_TEMPLATE_BASE}?per_page=1000`;
    const bodyData = {
      ...(name && { title: name }),
    };

    const response = await getData(query, false, bodyData);
    if (response && response?.status === 200) {
      // setLoading(false);
      // setMetaData({
      //   defaultPageSize: response?.data?.data?.per_page,
      //   current: response?.data?.data?.current_page,
      //   defaultCurrent: response?.data?.data?.current_page,
      //   total: response?.data?.data?.total,
      // });
      setTNATemplateList(response?.data?.data);
    } else {
      message.error("Error getting template list data");
    }
  };

  useEffect(() => {
    getSalesContractList();
    getAllTNATemplates();
  }, []);

  const getTNAStyles = async (salesContractID) => {
    const query = `${TNA_STYLES}/${salesContractID}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setTNAStyles(response?.data?.data);
    } else {
      message.error("Error getting TNA styles data");
    }
  };

  const getTNAPos = async (salesContractID) => {
    const query = `${TNA_POS}/${salesContractID}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setTNAPos(response?.data?.data);
    } else {
      message.error("Error getting TNA pos data");
    }
  };

  const handleModalCancel = () => {
    updateListTableDatas({
      listType: null,
      modalTitle: null,
      styleData: null,
      poData: null,
      isModalOpen: false,
    });
  };
  const handleModalOk = () => {
    updateListTableDatas({
      listType: null,
      modalTitle: null,
      styleData: null,
      poData: null,
      isModalOpen: false,
    });
  };

  const handleModalClick = async (type) => {
    updateListTableDatas({ isLoading: true });
    if (isEdit && listTableData?.salesContractId) {
      const url =
        type === "style"
          ? TNA_PLAN_STYLES_DETAILS
          : TNA_PLAN_PURCHASE_ORDER_DETAILS;
      let response = await getData(
        `${url}/${listTableData?.salesContractId}`,
        null,
        {
          per_page: 99999,
          view_details: 1,
        },
      );
      await new Promise((r) => setTimeout(r, 500));
      if (type === "style") {
        updateListTableDatas({
          listType: type,
          modalTitle: "Style Details",
          styleData: response?.data?.data,
          isModalOpen: true,
        });
      } else {
        updateListTableDatas({
          listType: type,
          modalTitle: "PO List",
          poData: response?.data?.data,
          isModalOpen: true,
        });
      }
    }
    updateListTableDatas({ isLoading: false });
  };

  return (
    <div className="containt-body">
      <Spin spinning={listTableData?.isLoading}>
        <Form layout="vertical" form={form} name="control-hooks">
          <Card>
            <Row gutter={6}>
              <Col className="gutter-row" span={4}>
                <Form.Item
                  label="Sales Contract"
                  name="sales_contract_id"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Sales Contract",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select a Sales Contract"
                    optionFilterProp="children"
                    disabled={view}
                    size="small"
                    onSearch={(value) => getSalesContractList({ name: value })}
                    allowClear
                    onClear={() => getSalesContractList()}
                    onSelect={(value) => onSelectSalesContract(value)}
                  >
                    {isArrayAndHasValue(salesContractList) &&
                      salesContractList.map((sup) => (
                        <Option value={sup.id} key={sup.id}>
                          {sup.reference_no}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item name="buyer_id" hidden />
                <Form.Item label="Buyer" name="buyer_name">
                  <Input
                    className="w-100"
                    size="small"
                    placeholder="Buyer"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item name="buying_office_id" hidden />
                <Form.Item label="Buying Office" name="buying_office_name">
                  <Input
                    className="w-100"
                    size="small"
                    placeholder="Buying Office"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item label="Sales Contract Date" name="contract_date">
                  <CustomDatePicker
                    className="w-100"
                    size="small"
                    placeholder="Sales Contract Date"
                    disabled
                  />
                  {/* <Input
                    className="w-100"
                    size="small"
                    placeholder="Sales Contract Date"
                    disabled
                  /> */}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item label="Order Qty (pc.)" name="order_quantity">
                  <Input
                    className="w-100"
                    size="small"
                    placeholder="Order Qty (pc.)"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                <div className="flex-row">
                  <EyeOutlined
                    className="cursore-pointer mr-2"
                    onClick={() => handleModalClick("style")}
                  />
                  <Form.Item label="No. of Style" name="style_no">
                    <Input
                      className="w-100"
                      size="small"
                      placeholder="No. of Style"
                      disabled
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" span={4}>
                <div className="flex-row">
                  <EyeOutlined
                    className="cursore-pointer mr-2"
                    onClick={() => handleModalClick("po")}
                  />
                  <Form.Item label="No. of PO" name="po_no">
                    <Input
                      className="w-100"
                      size="small"
                      placeholder="No. of PO"
                      disabled
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item label="Plan Cut Dates(s)" name="plan_cut_dates">
                  <Input
                    className="w-100"
                    size="small"
                    placeholder="Plan Cut Dates(s)"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item label="Shipment Dates(s)" name="shipment_dates">
                  <Input
                    className="w-100"
                    size="small"
                    placeholder="Shipment Dates(s)"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="template_id"
                  label="Template Title"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Template Title",
                    },
                  ]}
                >
                  <Select
                    dropdownStyle={{ minWidth: 250 }}
                    showSearch
                    onFocus={(e) => {}}
                    placeholder="Copy from Template Title"
                    optionFilterProp="children"
                    onSelect={(value) => {
                      getTemplateData(value);
                    }}
                    style={{ width: "100%" }}
                    size="small"
                    disabled={view}
                    onSearch={(value) => getAllTNATemplates({ title: value })}
                  >
                    {isArrayAndHasValue(TNATemplateList) &&
                      TNATemplateList.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item?.title}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item label="Description" name="description">
                  <Input
                    className="w-100"
                    size="small"
                    placeholder="Description"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item label="Calculation Method" name="calculation_method">
                  <Input
                    className="w-100"
                    size="small"
                    placeholder="Calculation Method"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <ItemDetails
            view={view}
            itemDetailsList={itemDetailsList}
            setItemDetailsList={setItemDetailsList}
            form={form}
            TNAStyles={TNAStyles}
            TNAPos={TNAPos}
          />
        </Form>

        <Modal
          title={listTableData?.modalTitle}
          open={listTableData?.isModalOpen}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          width={1000}
        >
          <ListView datas={listTableData} />
        </Modal>
      </Spin>
    </div>
  );
});

export default AddOrUpdateForm;
