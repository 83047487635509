import { Col, DatePicker, Form, Select } from "antd";
import { useEffect, useState } from "react";
import {
  GET_DIGITALIZATION_STYLES,
  SEWING_LINES,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const DigitalizationGarmentsTrackingFilter = ({ initialTableData }) => {
  // States
  const [sewingList, setSewingList] = useState([]);
  const [buyersList, setBuyersList] = useState([]);
  const [stylesList, setStylesList] = useState([]);

  // Antd
  const { Option } = Select;

  const getSewingList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };
    const response = await getData(SEWING_LINES, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSewingList(response?.data?.data?.data || []);
    }
  };

  const getBuyersList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };
    const response = await getData(
      "/api/digitalization/buyers",
      false,
      bodyData,
    );

    if (response && response?.data?.code === 200) {
      const buyers =
        response?.data?.data?.data?.map((buyer) => ({
          id: buyer.id,
          name: buyer.name,
        })) || [];
      setBuyersList(buyers);
    }
  };

  const getStyleList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };
    const response = await getData(GET_DIGITALIZATION_STYLES, false, bodyData);

    if (response && response?.data?.code === 200) {
      const styles =
        response?.data?.data?.data?.map((style) => ({
          id: style.id,
          name: style.style_no,
        })) || [];
      setStylesList(styles);
    }
  };

  // Effects
  useEffect(() => {
    getSewingList();
    getBuyersList();
    getStyleList();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item label="Date" name="date">
          <CustomDatePicker
            placeholder="Date"
            size="small"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item label="Line" name="line_id">
          <Select
            showSearch
            placeholder="Select a Line"
            onSearch={(value) => {
              getSewingList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
            filterOption={false}
          >
            {isArrayAndHasValue(sewingList) &&
              sewingList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            placeholder="Select Buyer"
            onSearch={(value) => {
              getBuyersList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
            filterOption={false}
          >
            {isArrayAndHasValue(buyersList) &&
              buyersList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item label="Style" name="style_id">
          <Select
            showSearch
            placeholder="Select Style"
            filterOption={false}
            size="small"
            onSearch={(value) => {
              getStyleList({ style_no: value });
            }}
          >
            {isArrayAndHasValue(stylesList) &&
              stylesList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default DigitalizationGarmentsTrackingFilter;
