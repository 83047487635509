import { Button, Card, Col, DatePicker, Form, Row, Select } from "antd";
import { cardStyle } from ".";
import dayjs from "dayjs";
import CustomDatePicker from "common/CustomDatePicker";

export default function Filter({ setDate }) {
  return (
    <Card style={cardStyle}>
      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Timeline" name="timeline">
              <DatePicker
                placeholder={"Select month"}
                style={{
                  width: "100%",
                }}
                displayFormat="MMM-YYYY"
                picker="month"
                onChange={(_date, dateString) => {
                  if (dateString) {
                    setDate(dateString);
                  } else {
                    setDate(dayjs().format("MMM-YYYY"));
                  }
                }}
                size="middle"
              />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="Production" name="production">
              <Select placeholder="Select name" size="middle">
                <Select.Option value="1">Production 1</Select.Option>
                <Select.Option value="2">Production 2</Select.Option>
                <Select.Option value="3">Production 3</Select.Option>
              </Select>
            </Form.Item>
          </Col> */}

          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>
              {/* <Button
                type="primary"
                style={{
                  borderRadius: "5px",
                  marginRight: "5px",
                }}
              >
                Filter
              </Button> */}
              <Button
                style={{
                  borderRadius: "5px",
                }}
                htmlType="reset"
                onClick={() => {
                  setDate(dayjs().format("MMM-YYYY"));
                }}
              >
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
