
import { Line } from '@ant-design/plots';
import React, { useState } from 'react';

const LineChart = ({ title, progressData}) => {
  const [data, setData] = useState(progressData);

  const config = {
    title: {
      title,
      align: 'center'
    },
    data,
    xField: 'title',
    yField: 'value',
    colorField: 'type',
    axis: {
      y: {
        labelFormatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    scale: { color: { range: ['#30BF78', '#FAAD14'] } },
    style: {
      lineWidth: 2,
    },
    legend: {
     color:{
      layout:{
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }
     }
    },
  };

  return <Line {...config} />;
};

export default LineChart;

