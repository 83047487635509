import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    ACCOUNT_LIST_ENDPOINT,
    ACCOUNT_ADD_ENDPOINT,
    ACCOUNT_EDIT_ENDPOINT,
    ACCOUNT_DELETE_ENDPOINT,
    BANK_LIST_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/functions";
import { 
    ACCOUNT_CREATE_PERMISSION,
    ACCOUNT_EDIT_PERMISSION,
    ACCOUNT_DELETE_PERMISSION,
} from '../../routes/permissions';
import { useLocation } from "react-router";

const Accounts = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [bankList, setBankList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  // Antd
  const [accountForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllAccounts = useCallback(
    async (filterValues) => {
      const { name } = filterValues || {};

      setLoading(true);

      const query = `${ACCOUNT_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...(name && { name: name }),
        type: "all"
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting Account data");
      }
    },
    [currentPage],
  );

  const getAllBanks = async (filterValues) => {
    setLoading(true);

    const query = `${BANK_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      const filteredBankListData = response?.data?.data?.data?.filter(item => item.status === "1");
      setBankList(filteredBankListData);
    } else {
      setLoading(false);
      message.error("Error getting bank data");
    }
  };

  // Function to handle branch list population based on selected bank
  const handleBankChange = (bankId) => {
    // Find the selected bank from the bankList based on the selected bankId
    const selectedBank = bankList.find((bank) => bank.id === bankId);

    // If the bank is found, set the branch list from its brach_info property
    if (selectedBank && selectedBank.brach_info) {
      setBranchList(selectedBank.brach_info); // Set branchList with the bank's branch_info
    } else {
      setBranchList([]); // Clear branchList if no bank is found or it has no branches
    }

    // Reset the branch selection field in the form if required
    accountForm.setFieldsValue({ branch_id: undefined });
  };

  // Effects
  useEffect(() => {
    getAllAccounts(filterData);
    getAllBanks();
  }, [refresh, filterData, getAllAccounts]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    accountForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedaccount = async (id) => {
    setLoading(true);
    const response = await deleteData(`${ACCOUNT_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Account Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedaccount = async (formValues) => {
    setLoading(true);

    const bodyData = {
      bank_id: formValues?.bank_id,
      branch_id: formValues?.branch_id,
      account_number: formValues?.account_number,
      status: formValues?.status === true ? 1 : 0,
    };

    const response = await putData(
      `${ACCOUNT_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Account Edited Successfully");
      refetch();
      accountForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewaccount = async (formValues) => {
    setLoading(true);

    const bodyData = {
      bank_id: formValues?.bank_id,
      branch_id: formValues?.branch_id,
      account_number: formValues?.account_number,
      status: formValues?.status === true ? 1 : 0,
    };

    const response = await postData(ACCOUNT_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Account Created Successfully");
      refetch();
      accountForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    accountForm.setFieldsValue({
      id: record?.id,
      bank_id: record?.bank_id,
      branch_id: record?.branch_id,
      account_number: record?.account_number,
      status: record?.status === "1" ? true : false,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Account Number",
      dataIndex: ["account_number"],
      key: "account_number",
    },
    {
      title: "Bank Name",
      dataIndex: ["bank_info", "name"],
      key: "bank_id",
    },
    {
      title: "Branch Name",
      dataIndex: ["branch_info", "name"],
      key: "branch_id",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      render: (_, record) => {
        return <div>{record?.status === "1" ? "Active" : "Inactive" }</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedaccount(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([ACCOUNT_DELETE_PERMISSION]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([ACCOUNT_EDIT_PERMISSION]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([ACCOUNT_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([ACCOUNT_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Accounts</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllAccounts}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Account" : "Create Account"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={accountForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedaccount : createNewaccount}
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="bank_id"
                placeholder="Bank"
                label="Bank"
                rules={[
                  {
                    required: true,
                    message: "Bank is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Bank"
                  showSearch
                  style={{width: "100%"}}
                  onChange={handleBankChange} // Trigger handleBankChange when a bank is selected
                >
                  {bankList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="branch_id"
                placeholder="Branch"
                label="Branch"
                rules={[
                  {
                    required: true,
                    message: "Branch is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Branch"
                  showSearch
                  style={{width: "100%"}}
                >
                  {branchList?.map((branch) => (
                    <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="account_number"
                placeholder="Account Number"
                label="Account Number"
                rules={[
                  {
                    required: true,
                    message: "Account Number is Required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item
                name="status"
                placeholder="Status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Status Required",
                  },
                ]}
                valuePropName="checked"
                initialValue={true}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Accounts;
