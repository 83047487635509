// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editable-row {
    background-color: gainsboro;
}

.required {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}`, "",{"version":3,"sources":["webpack://./src/components/Production/PackingReq/PackingDetails/index.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,+BAA+B;IAC/B,cAAc;IACd,YAAY;AAChB","sourcesContent":[".editable-row {\n    background-color: gainsboro;\n}\n\n.required {\n    display: inline-block;\n    margin-right: 4px;\n    color: #ff4d4f;\n    font-size: 14px;\n    font-family: SimSun, sans-serif;\n    line-height: 1;\n    content: '*';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
