import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onDeleteItemCategory,
  onGetItemCategoryList,
} from "../../../redux/actions";
import ListCategory from "./ListCategory";
import CreateItemCategory from "../CreateItemCategory";

const CategoryList = (props) => {
  const { showFilter, setShowFilter } = props;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemCategoryList = useSelector(
    ({ itemCategory }) => itemCategory.itemCategoryList,
  );
  const totalCategoryList = useSelector(
    ({ itemCategory }) => itemCategory.totalItemCategories,
  );
  const pageSize = useSelector(({ itemCategory }) => itemCategory.pageSize);
  const loading = useSelector(({ common }) => common.loading);
  const filterData = useSelector(({filter}) => filter.filterData);
  const [toDeleteItemCategory, setToDeleteItemCategory] = useState(null);
  const [isAddItemCategory, onSetAddItemCategory] = useState(false);
  const [isShowDetail, onShowDetail] = useState(false);
  const [selectedItemCategory, setSelectedItemCategory] = useState(null);

  const handleAddCategoryOpen = () => {
    onSetAddItemCategory(true);
  };

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleAddCategoryClose = () => {
    onSetAddItemCategory(false);
    setSelectedItemCategory(null);
  };

  const onViewCategoryDetail = (category) => {
    setSelectedItemCategory(category);
    onShowDetail(true);
  };

  const onOpenEditCategory = (event, category) => {
    setSelectedItemCategory(category);
    handleAddCategoryOpen();
  };

  const onUpdateItemCategory = (category) => {
    setSelectedItemCategory(category);
    handleAddCategoryClose();
  };

  const onSelectCategoryForDelete = (category) => {
    setToDeleteItemCategory(category);
  };


  const onSelectedCategoryToDelete = (record) => {
    dispatch(onDeleteItemCategory(record));
    setToDeleteItemCategory(null);
    props.refetch();
  };

  useEffect(() => {
    dispatch(onGetItemCategoryList({
        page: currentPage,
        per_page: 10,
        ...filterData,
    }));
  }, [props.refresh, currentPage, pageSize, filterData, dispatch]);

  return (
    <>
      <ListCategory
        itemCategoryList={itemCategoryList}
        extra={props.extra}
        loading={loading}
        page={currentPage}
        pageSize={pageSize}
        totalCategoryList={totalCategoryList}
        onPageChange={onPageChange}
        handleAddCategoryOpen={handleAddCategoryOpen}
        handleAddCategoryClose={handleAddCategoryClose}
        onSelectCategoryForDelete={onSelectCategoryForDelete}
        onViewCategoryDetail={onViewCategoryDetail}
        onOpenEditCategory={onOpenEditCategory}
        onSelectedCategoryToDelete={onSelectedCategoryToDelete}
        selectedItemCategory={selectedItemCategory}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      
      <CreateItemCategory
        refetch={props.refetch}
        isAddItemCategory={isAddItemCategory}
        handleAddCategoryClose={handleAddCategoryClose}
        selectedItemCategory={selectedItemCategory}
        onUpdateItemCategory={onUpdateItemCategory}
      />
    </>
  );
};

export default CategoryList;
