import { useContext } from "react";
import { Collapse, Table, Typography } from "antd";
import { isArrayAndHasValue } from "utils/functions";
import { AssignmentContext } from "context/AssignmentContext/AssignmentContextProvider";

const StylePOAssignmentDetails = (props) => {
  // Contexts
  const { lineWiseData } = useContext(AssignmentContext);

  const columns = [
    {
      title: "Line",
      dataIndex: ["line_name"],
      key: "line_name",
    },
    {
      title: "Assigned Qty",
      dataIndex: ["line_wise_assignment"],
      key: "line_wise_assignment",
    },
  ];

  const TableSummary = ({pageData}) => {
    const assignedQtySum = pageData?.reduce((total, item) => {
      return total + Number(item?.line_wise_assignment);
    }, 0);

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} id="total">
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} id="assigned_qty">
            <Typography.Text>{assignedQtySum}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <>
      <div className="cuttingTable" style={{ marginTop: 10 }}>
        <Typography.Title style={{ fontSize: 14 }}>
          {isArrayAndHasValue(lineWiseData)
            ? ``
            : `Select style and PO to see Style-PO Assignment Details`}
        </Typography.Title>
        {isArrayAndHasValue(lineWiseData) && (
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 5 }}>
            <Collapse.Panel header={<b>Style-PO Assignment Details</b>} key="1">
              <Table
                pagination={false}
                size={"small"}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={lineWiseData}
                columns={columns}
                sticky={true}
                summary={(pageData) => {
                  return <TableSummary pageData={pageData} />;
                }}
              />
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
    </>
  );
};
export default StylePOAssignmentDetails;
