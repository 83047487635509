import { Line } from "@ant-design/plots";
import { Card, Col, Typography } from "antd";

export default function LineData({ data }) {
  const config = {
    data,
    xField: "hour",
    yField: "defect_qty",
    point: {
      size: 4,
      shape: "circle",
      style: {
        fill: "blue",
      },
    },
    lineStyle: {
      lineWidth: 2,
    },
  };

  return (
    <Col span={12}>
      <Card
        style={{
          marginBottom: "20px",
        }}
      >
        <Line {...config} />
        <Typography.Title level={5} style={{ textAlign: "center" }}>
          <span
            style={{
              marginRight: "20px",
            }}
          >
            X-Axis = Hour
          </span>
          <span>Y-Axis = Defect Qty</span>
        </Typography.Title>
      </Card>
    </Col>
  );
}
