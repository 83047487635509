import { Col, Form, Input, Select } from "antd";
import CustomDatePicker from "common/CustomDatePicker";
import { useEffect, useState } from "react";
import {
  PO_MAP_NEW_ENDPOINT,
  SALES_CONTRACT_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const SalesContractPOSFilterFields = ({ initialTableData }) => {
  const [salesContractList, setSalesContractList] = useState([]);
  const [poList, setPOList] = useState([]);

  // Antd
  const { Option } = Select;

  useEffect(() => {
    getSalesContractList();
    getPOList();
  }, []);

  const getSalesContractList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      reference_no: filterValues?.reference_no || "",
    };
    const response = await getData(SALES_CONTRACT_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSalesContractList(response?.data?.data?.data || []);
    }
  };

  const getPOList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(PO_MAP_NEW_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setPOList(response?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item
          label="Start Date"
          name="start"
          rules={[{ required: true, message: "Please select start date" }]}
        >
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label="End Date"
          name="end"
          rules={[{ required: true, message: "Please select end date" }]}
        >
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Sales Contract No" name="sales_contract_id">
          <Select
            showSearch
            placeholder="Select a Sales Contract No"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getSalesContractList({ reference_no: value })}
          >
            {isArrayAndHasValue(salesContractList) &&
              salesContractList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.reference_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Original PO" name="original_po">
          <Input placeholder="Original PO" />
        </Form.Item>
      </Col>
    </>
  );
};

export default SalesContractPOSFilterFields;
