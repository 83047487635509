import { useContext, useState } from "react";
import { Collapse, Modal, Table, Typography } from "antd";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";
import { AssignmentContext } from "context/AssignmentContext/AssignmentContextProvider";
import { EyeOutlined } from "@ant-design/icons";
import LineDetails from "../LineDetails";

const AssignmentDetails = (props) => {
  // Props
  const { form } = props;

  // Contexts
  const { lineAssignmentDetails, onViewLineItem, setLineItemData } =
    useContext(AssignmentContext);

  // States
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCloseModal = () => {
    setIsModalOpen(false);
    setLineItemData(null);
  };

  const openItem = (itemData) => {
    const sewing_line_id = form.getFieldValue("sewing_line_id");
    const customData = {
      ...itemData,
      sewing_line_id,
    };
    onViewLineItem(customData);

    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "PO",
      key: "po",
      width: "15%",
      render(value, record) {
        return <span>{record?.original_po || record?.internal_po}</span>;
      },
    },
    {
      title: "Style",
      dataIndex: ["style_no"],
      key: "style",
      width: "20%",
    },
    {
      title: "Cut Qty",
      dataIndex: ["total_cutting"],
      key: "total_cutting",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : ""}</span>;
      },
    },
    {
      title: "This Line",
      dataIndex: ["total_assigned_this_line"],
      key: "total_assigned_this_line",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : ""}</span>;
      },
    },
    {
      title: "Total",
      dataIndex: ["total_assignment_all_line"],
      key: "total_assignment_all_line",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : ""}</span>;
      },
    },
    {
      title: "Ass. Remain",
      dataIndex: ["total_remain_assignment"],
      key: "total_remain_assignment",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : 0}</span>;
      },
    },
    {
      title: "Sewing Used",
      dataIndex: ["total_sewing_used"],
      key: "total_sewing_used",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : ""}</span>;
      },
    },
    {
      title: "Sewing Remain",
      dataIndex: ["total_sewing_remain"],
      key: "total_sewing_remain",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : ""}</span>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render(value, record) {
        return (
          <div className="centered-item-parent" style={{ cursor: "pointer" }}>
            <EyeOutlined onClick={() => openItem(record)} />
          </div>
        );
      },
    },
  ];

  const TableSummary = ({pageData}) => {
    const cutQtySum = pageData?.reduce((total, item) => {
      return total + item.total_cutting;
    }, 0);
    const assignedThisLineQtySum = pageData?.reduce((total, item) => {
      return total + Number(item?.total_assigned_this_line);
    }, 0);
    const totalAssignAllLinesQtySum = pageData?.reduce((total, item) => {
      return total + item?.total_assignment_all_line;
    }, 0);
    const totalRemainAssignmentQtySum = pageData?.reduce((total, item) => {
      return total + item?.total_remain_assignment;
    }, 0);
    const totalSewingUsedQtySum = pageData?.reduce((total, item) => {
      return total + item?.total_sewing_used;
    }, 0);
    const totalSewingRemainQtySum = pageData?.reduce((total, item) => {
      return total + item?.total_sewing_remain;
    }, 0);

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} id="total">
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} id="style" />
          <Table.Summary.Cell index={2} id="cut_qty">
            <Typography.Text>{cutQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} id="assigned_this_line">
            <Typography.Text>{assignedThisLineQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} id="total_assigned_all_lines">
            <Typography.Text>{totalAssignAllLinesQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} id="assignment_remain">
            <Typography.Text>{totalRemainAssignmentQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} id="sewing_used">
            <Typography.Text>{totalSewingUsedQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} id="sewing_remain">
            <Typography.Text>{totalSewingRemainQtySum}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <>
      <div style={{ marginTop: 10 }}>
        <Typography.Title style={{ fontSize: 14 }}>
          {isArrayAndHasValue(lineAssignmentDetails)
            ? ``
            : `Select sewing line and assignment date to see line assignment details`}
        </Typography.Title>
        {isArrayAndHasValue(lineAssignmentDetails) && (
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 5 }}>
            <Collapse.Panel header={<b>Line Assignment Details</b>} key="1">
              <Table
                pagination={false}
                size={"small"}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={lineAssignmentDetails}
                columns={columns}
                summary={(pageData) => {
                  return <TableSummary pageData={pageData} />;
                }}
              />
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
      <Modal
        open={isModalOpen}
        title={"Line Details"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
        width={800}
      >
        <LineDetails />
      </Modal>
    </>
  );
};
export default AssignmentDetails;
