
import { Col, DatePicker, Form, Select } from "antd";
import CustomDatePicker from "common/CustomDatePicker";
import { DailyLinePlanContext } from "context/DailyLinePlanContext";
import { useContext } from "react";


// Antd
const { Option } = Select;

const HourlyProRecDetailsFilter = ({ initialTableData }) => {
  const { options, onChangeBuyer, onChangeStyle } =
  useContext(DailyLinePlanContext);
 

  return (
    <>
    <Col span={6}>
        <Form.Item label="Date" name="date">
          <CustomDatePicker placeholder="Date" size="small" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      

      <Col span={6}>
        <Form.Item
          label="Buyer"
          name="buyer"
        >
          <Select placeholder="Select Buyer" onChange={onChangeBuyer} size="small">
            {options?.buyers?.map((buyer) => (
              <Select.Option key={buyer.value} value={buyer.value}>
                {buyer.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item
          label="Style"
          name="style"
        >
          <Select placeholder="Select Style" onChange={onChangeStyle} size="small">
            {options?.styles?.map((style) => (
              <Select.Option key={style.value} value={style.value}>
                {style.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Line"
          name="line"
          rules={[{ required: false, message: "Please select Line!" }]}
        >
          <Select placeholder="Select Line" size="small">
            {options.lines.map((line) => (
              <Select.Option key={line.value} value={line.value}>
                {line.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default HourlyProRecDetailsFilter;
