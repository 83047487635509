import { Col, Form, Input } from "antd";

const DigitalDefectReasonFilter = ({ initialTableData }) => {
  return (
    <>
      <Col span={4}>
        <Form.Item label="Reason" name="name">
          <Input  placeholder="Reason Name" size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default DigitalDefectReasonFilter;
