import { Table } from "antd";
import CustomFilter from "../Common/CustomFilter";

const BuyerListTable = (props) => {
  const {
    total,
    loading,
    defaultCurrent,
    pageSize,
    current,
    onChange,
    showFilter,
  } = props;

  return (
    <>
      {props.filter && (
        <CustomFilter
          showFilter={showFilter}
          tableData={props.dataSource}
          fetchTableData={null}
          filterName="buyer"
        />
      )}

      <div className="table" style={{ marginTop: 10 }}>
        <Table
          loading={loading}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: pageSize,
            current: current,
            onChange: onChange,
            defaultCurrent: defaultCurrent,
            total: total,
          }}
          columns={props.tableColumns}
          dataSource={props.dataSource}
        />
      </div>
    </>
  );
};
export default BuyerListTable;
