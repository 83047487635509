import { Affix, Button, Card, Form } from "antd";
import Header from "components/Common/Header";
import { useNavigate } from "react-router";
import ChecklistQuestionsForm from "../ChecklistQuestionsForm";

export default function ChecklistQuestionsEdit() {
  const [form] = Form.useForm();
  const onSubmit = () => {
    form.submit();
  };
  const navigate = useNavigate();
  return (
    <div>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title="Edit Checklist Questions"
            subTitle=""
            addLink="/"
            addText="Submit"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() =>
                    navigate("/checklist/checklist-questions/list")
                  }
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button size="small" key={2} type="primary" onClick={onSubmit}>
                  Update
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <ChecklistQuestionsForm form={form} />
    </div>
  );
}
