import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Select, Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { isArrayAndHasValue } from "../../../../utils/functions";
import { GET_SIZES, SALES_CONTRACT_SIZE_ADD } from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import { debounce } from "lodash";
import { useParams } from "react-router";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const SizeListTable = (props) => {
  // Props
  const { sizeInfo, form, refresh, setRefresh } = props;

  // States
  const [dataSource, setDataSource] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [deletedSizes, setDeletedSizes] = useState([]);

  // Antd
  const { Option } = Select;

  // Others
  const { salesContractId } = useParams();

  const getAllSizes = async (filterValues) => {
    const query = `${GET_SIZES}`;
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: filterValues?.per_page || 1000,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setAllSizes(response?.data?.data?.data);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  const debouncedGetAllSizes = debounce(getAllSizes, 500);

  useEffect(() => {
    // Get and set size list to the state
    if (sizeInfo && isArrayAndHasValue(sizeInfo?.size_info)) {
      const customSizeInfo = sizeInfo?.size_info?.map((item, index) => {
        return {
          id: item?.id,
          size_id: item?.sizes?.id,
          key: item?.position,
          position: item?.position,
          is_delete: 0,
          name: item?.sizes?.name,
        };
      });
      setDataSource(customSizeInfo);
    }
  }, [sizeInfo]);

  useEffect(() => {
    // Get all sizes on initial render
    getAllSizes();
  }, []);

  const onDragEnd = ({ active, over }) => {
    const dataSourceCopy = [...dataSource];
    const activeIndex = dataSourceCopy.findIndex((i) => i.key === active.id);
    const overIndex = dataSourceCopy.findIndex((i) => i.key === over?.id);

    // Ensure the indices are within bounds
    if (
      activeIndex < 0 ||
      activeIndex >= dataSourceCopy.length ||
      overIndex < 0 ||
      overIndex >= dataSourceCopy.length
    ) {
      return;
    }

    // Remove the item from the array
    const [draggedItem] = dataSourceCopy.splice(activeIndex, 1);

    // Insert the dragged item at the new position
    dataSourceCopy.splice(overIndex, 0, draggedItem);

    // Update the position key in every item
    dataSourceCopy.forEach((item, index) => {
      item.position = index + 1;
    });

    // Now, dataSource is updated with the new order
    setDataSource(dataSourceCopy);
  };

  const onSelectSize = (sizeId) => {
    const dataSourceCopy = [...dataSource];

    // If already exists then return
    const isExists = dataSourceCopy?.find((item) => item?.size_id === sizeId);
    if (isExists) {
      HandleNotification(
        "warning",
        "bottomRight",
        "Size already exists!",
        null,
      );
      // Reset Size Dropdown field
      form.resetFields(["sizes"]);
      return;
    }

    // If size not exists in Datasource, add it
    const foundSize = allSizes?.find((item) => item?.id === sizeId);
    const sizeObj = {
      id: 0,
      size_id: foundSize?.id,
      key: isArrayAndHasValue(dataSource) ? dataSource.length + 1 : 1,
      position: isArrayAndHasValue(dataSource) ? dataSource.length + 1 : 1,
      is_delete: 0,
      name: foundSize?.name,
    };

    // Add new size to the list
    dataSourceCopy.push(sizeObj);
    setDataSource(dataSourceCopy);

    // Reset Size Dropdown field
    form.resetFields(["sizes"]);
  };

  const onRemoveSize = (sizeId) => {
    const dataSourceCopy = [...dataSource];
    const deletedSizesCopy = [...deletedSizes];

    const foundItem = dataSourceCopy?.find((item) => item?.size_id === sizeId);
    foundItem.is_delete = 1;

    deletedSizesCopy.push(foundItem);

    // Remove size from the list
    const filteredData = dataSourceCopy?.filter((item) => item?.size_id !== sizeId);
    // Update position key in every item
    const updatedData = filteredData?.map((item, index) => {
      return {
        ...item,
        position: index + 1,
      };
    });

    setDeletedSizes(deletedSizesCopy);
    setDataSource(updatedData);
  };

  const columns = [
    {
      key: "sort",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Size ID",
      dataIndex: "size_id",
    },
    {
      title: "Position",
      dataIndex: "position",
    },
    {
      title: "Action",
      render: (item) => {
        const { size_id } = item;
        return (
          <Button danger size="small" onClick={() => onRemoveSize(size_id)}>
            Remove
          </Button>
        );
      },
    },
  ];

  const onFinish = async(value) => {
    if(!isArrayAndHasValue(dataSource)) {
      HandleNotification(
        "warning",
        "bottomRight",
        "Please add at least one size!",
        null,
      );
      return;
    }

    const sizeData = dataSource?.map((item) => {
      return {
        "id": item?.id || 0,
        "size_id": item?.size_id,
        "position": item?.position,
        "is_delete": item?.is_delete || 0,
      };
    });

    const deletedSizeData = deletedSizes?.map((item) => {
      return {
        "id": item?.id || 0,
        "size_id": item?.size_id,
        "position": item?.position,
        "is_delete": item?.is_delete || 0,
      };
    });

    const bodyData = {
      "sales_contract_id": salesContractId,
      "sizes": sizeData,
      "delete_sizes": deletedSizeData,
    };
    const query = `${SALES_CONTRACT_SIZE_ADD}`;

    const response = await postData(query, bodyData);

    if(response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        "Size updated successfully!",
        null,
      );
    }
    else {
      HandleNotification(
        "error",
        "bottomRight",
        "Something went wrong!",
        null,
      );
    }
  }

  return (
    <Card>
      <Form form={form} onFinish={onFinish}>
        <Col className="gutter-row" span={6}>
          <Form.Item label="Size" layout="vertical" name="sizes">
            <Select
              showSearch
              placeholder="Select a size"
              optionFilterProp="children"
              size="small"
              allowClear
              onSelect={(value) => onSelectSize(value)}
              onSearch={(value) => debouncedGetAllSizes({ name: value })}
              popupClassName="size-specs-dropdown-custom"
            >
              {isArrayAndHasValue(allSizes) &&
                allSizes.map((item) => (
                  <Option value={item?.id} key={item?.id}>
                    {item?.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Form>

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </Card>
  );
};

export default SizeListTable;
