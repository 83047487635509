import {
    GET_BUYER_LIST,
    CREATE_NEW_BUYER,
    DELETE_BUYER,
    GET_BUYER_DETAIL,
    UPDATE_BUYER_DETAIL, GET_SC_DETAIL
} from '../ActionTypes';

const initialState = {
    buyerList: [],
    totalBuyer: null,
    selectedBuyer: null,
    salesContractDetails: null,
    createdBuyer: null,
    pageSize: 10,
    page:1,
};

const BuyerReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_BUYER_LIST:
            return {
                ...state,
                buyerList: action.payload.data,
                totalBuyer: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_BUYER:
            return {
                ...state,
                createdBuyer: action.payload.data,
                buyerList: [action.payload.data, ...state.buyerList],
                totalBuyer: state.totalBuyer + 1,
            };

        case DELETE_BUYER: {
            return {
                ...state,
                buyerList:  state.buyerList.filter(element => element !== action.payload),
                totalBuyer: action.payload.total,
                selectedBuyer: action.payload,
            };
        }

        case GET_BUYER_DETAIL:
            return {
                ...state,
                selectedBuyer: action.payload,
            };
        case GET_SC_DETAIL:
            return {
                ...state,
                salesContractDetails: action.payload,
            };

        case UPDATE_BUYER_DETAIL:
            return {
                ...state,
                selectedBuyer: action.payload,
                buyerList: state.buyerList.map((buyer) =>
                    buyer.id === action.payload.id ? action.payload : buyer,
                ),
            };

        default:
            return state;
    }
};

export default BuyerReducer;
