import { Form, Typography } from "antd";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import ImportInvoiceForm from "../Form";
// Antd Constants
const { Title } = Typography;
export default function ImportInvoiceExpenseView() {
  const [form] = Form.useForm();

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent hideFilterButton hideAddButton showBackButton />
            }
            title={<Title level={5}>Import Invoice Expense View</Title>}
          />
        }
        content={
          <>
            <ImportInvoiceForm form={form} mode="view" />
          </>
        }
      />
    </>
  );
}
