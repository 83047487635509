import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotingForm from "./NotingForm";
import { VIEW_CUSTOMS_CLEARANCE } from "../../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import moment from "moment";
import { isArrayAndHasValue } from "../../../../utils/functions";

export default function EditNoting(props) {
  // States
  const [editMode, setEditMode] = useState(false);
  const [editInfo, setEditInfo] = useState(null);

  //   Refs
  const propertyFormRef = useRef(null);

  //   Antd
  const [form] = Form.useForm();

  //   Router
  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/import-customs-clearance/noting-assessment-examination");
  };
  const { notingId } = useParams();

  const getSelectedNotingDetails = useCallback(
    async (notingId) => {
      const response = await getData(`${VIEW_CUSTOMS_CLEARANCE}/${notingId}`);

      if (response && response.status === 200) {
        const tableData = response?.data?.data;

        //console.log("tableData", tableData);
        setEditInfo(tableData);
        const container_size_data = tableData?.container_size?.split(",");

        form.setFieldsValue({
          ...tableData,
          position_type: tableData?.position_type,
          buyer_name: tableData?.buyer_name,
          sales_contract_number: (tableData?.sales_contract_info?.reference_no) ? tableData?.sales_contract_info?.reference_no : tableData?.sales_contract_number,
          utilization_declearation_number:
            tableData?.utilization_declearation_number,
          utilization_declearation_date: tableData.utilization_declearation_date
            ? moment(tableData?.utilization_declearation_date)
            : null,
          supplier_id: tableData?.supplier_id,
          b2b_lc_id: tableData?.b2b_lc_id,
          invoice_id: tableData?.invoice_id,
          forwarder_name: tableData?.forwarder_name,
          hbl_number: tableData?.hbl_number,
          qty: tableData?.qty,
          document_rcv_date: tableData.document_rcv_date
            ? moment(tableData?.document_rcv_date)
            : null,
          noting_date: tableData.noting_date
            ? moment(tableData?.noting_date)
            : null,
          custom_assesment_date: tableData.custom_assesment_date
            ? moment(tableData?.custom_assesment_date)
            : null,
          delivery_order_release_date: tableData.delivery_order_release_date
            ? moment(tableData?.delivery_order_release_date)
            : null,
          indent_date: tableData.indent_date
            ? moment(tableData?.indent_date)
            : null,
          shipment_release_date: tableData.shipment_release_date
            ? moment(tableData?.shipment_release_date)
            : null,
          invoice_date: tableData?.invoice_info?.import_date
            ? moment(tableData?.invoice_info?.import_date)
            : null,
          invoice_value: tableData?.invoice_info?.invoice_value,
          b2b_lc_date: tableData?.b2b_lc_info?.b2b_lc_date
            ? moment(tableData?.b2b_lc_info?.b2b_lc_date)
            : null,
          b2b_lc_value: tableData?.b2b_lc_info?.total_lc_value,
          customs_examination_date: tableData.customs_examination_date
            ? moment(tableData?.customs_examination_date)
            : null,
          passbook_entry_date: tableData.passbook_entry_date
            ? moment(tableData?.passbook_entry_date)
            : null,
          import_bill_entry_number: tableData.import_bill_entry_number,
          import_bill_entry_date: tableData.import_bill_entry_date
            ? moment(tableData?.import_bill_entry_date)
            : null,
          container_using_quantity: tableData.container_using_quantity,
          container_size: isArrayAndHasValue(container_size_data) ? container_size_data : [],
          container_number: tableData?.invoice_info?.container_number,
          shipment_mode: tableData?.invoice_info?.shipment_mode,
          shipment_type: tableData?.invoice_info?.shipment_type,
        });
      } else {
        alertPop(
          "error",
          response?.messages ? response?.messages : "Something Went Wrong!",
        );
      }
    },
    [form],
  );

  useEffect(() => {
    if (notingId) {
      setEditMode(true);
      getSelectedNotingDetails(notingId);
    }
  }, [notingId, getSelectedNotingDetails]);

  const submitForm = () => {
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={`${notingId ? "Update Noting" : "Add Noting"}`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => submitForm()}
                >
                  {`${notingId ? "Update" : "Save"}`}
                </Button>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      <NotingForm
        ref={propertyFormRef}
        form={form}
        editMode={editMode}
        notingId={notingId}
        editInfo={editInfo}
      />
    </>
  );
}
