export const NO_PERMISSION_REQUIRED = `no-permission-required`;
export const INVENTORY_VIEW_PERMISSION = `inventory-view-permission`;
export const INVENTORY_CREATE_PERMISSION = `inventory-create-permission`;
export const INVENTORY_EDIT_PERMISSION = `inventory-edit-permission`;
export const INVENTORY_DELETE_PERMISSION = `inventory-delete-permission`;
export const CUTTING_VIEW_PERMISSION = `cutting-view-permission`;
export const CUTTING_CREATE_PERMISSION = `cutting-create-permission`;
export const CUTTING_EDIT_PERMISSION = `cutting-edit-permission`;
export const CUTTING_DELETE_PERMISSION = `cutting-delete-permission`;
export const FOLDING_VIEW_PERMISSION = `folding-view-permission`;
export const FOLDING_CREATE_PERMISSION = `folding-create-permission`;
export const FOLDING_EDIT_PERMISSION = `folding-edit-permission`;
export const FOLDING_DELETE_PERMISSION = `folding-delete-permission`;
export const PACKING_VIEW_PERMISSION = `packing-view-permission`;
export const PACKING_CREATE_PERMISSION = `packing-create-permission`;
export const PACKING_EDIT_PERMISSION = `packing-edit-permission`;
export const PACKING_DELETE_PERMISSION = `packing-delete-permission`;
export const ASSIGNMENT_VIEW_PERMISSION = `assignment-view-permission`;
export const ASSIGNMENT_CREATE_PERMISSION = `assignment-create-permission`;
export const ASSIGNMENT_EDIT_PERMISSION = `assignment-edit-permission`;
export const ASSIGNMENT_DELETE_PERMISSION = `assignment-delete-permission`;
export const SEWING_VIEW_PERMISSION = `sewing-view-permission`;
export const SEWING_CREATE_PERMISSION = `sewing-create-permission`;
export const SEWING_EDIT_PERMISSION = `sewing-edit-permission`;
export const SEWING_DELETE_PERMISSION = `sewing-delete-permission`;
export const ORGANIZATION_VIEW_PERMISSION = `organization-view-permission`;
export const ORGANIZATION_CREATE_PERMISSION = `organization-create-permission`;
export const ORGANIZATION_EDIT_PERMISSION = `organization-edit-permission`;
export const ORGANIZATION_DELETE_PERMISSION = `organization-delete-permission`;
export const SHIPPING_VIEW_PERMISSION = `shipping-view-permission`;
export const SHIPPING_CREATE_PERMISSION = `shipping-create-permission`;
export const SHIPPING_EDIT_PERMISSION = `shipping-edit-permission`;
export const SHIPPING_DELETE_PERMISSION = `shipping-delete-permission`;
export const BUYER_VIEW_PERMISSION = `buyer-view-permission`;
export const BUYER_CREATE_PERMISSION = `buyer-create-permission`;
export const BUYER_EDIT_PERMISSION = `buyer-edit-permission`;
export const BUYER_DELETE_PERMISSION = `buyer-delete-permission`;
export const BUYING_OFFICE_VIEW_PERMISSION = `buying-office-view-permission`;
export const BUYING_OFFICE_CREATE_PERMISSION = `buying-office-create-permission`;
export const BUYING_OFFICE_EDIT_PERMISSION = `buying-office-edit-permission`;
export const BUYING_OFFICE_DELETE_PERMISSION = `buying-office-delete-permission`;
export const SEWING_LINE_VIEW_PERMISSION = `sewing-line-view-permission`;
export const SEWING_LINE_CREATE_PERMISSION = `sewing-line-create-permission`;
export const SEWING_LINE_EDIT_PERMISSION = `sewing-line-edit-permission`;
export const SEWING_LINE_DELETE_PERMISSION = `sewing-line-delete-permission`;
export const ITEM_VIEW_PERMISSION = `item-view-permission`;
export const ITEM_CREATE_PERMISSION = `item-create-permission`;
export const ITEM_EDIT_PERMISSION = `item-edit-permission`;
export const ITEM_DELETE_PERMISSION = `item-delete-permission`;
export const USER_VIEW_PERMISSION = `user-view-permission`;
export const USER_CREATE_PERMISSION = `user-create-permission`;
export const USER_EDIT_PERMISSION = `user-edit-permission`;
export const USER_DELETE_PERMISSION = `user-delete-permission`;
export const ROLE_VIEW_PERMISSION = `role-view-permission`;
export const ROLE_CREATE_PERMISSION = `role-create-permission`;
export const ROLE_EDIT_PERMISSION = `role-edit-permission`;
export const ROLE_DELETE_PERMISSION = `role-delete-permission`;
export const ITEM_CATEGORY_VIEW_PERMISSION = `item-category-view-permission`;
export const ITEM_CATEGORY_CREATE_PERMISSION = `item-category-create-permission`;
export const ITEM_CATEGORY_EDIT_PERMISSION = `item-category-edit-permission`;
export const ITEM_CATEGORY_DELETE_PERMISSION = `item-category-delete-permission`;
export const STYLE_VIEW_PERMISSION = `style-view-permission`;
export const STYLE_CREATE_PERMISSION = `style-create-permission`;
export const STYLE_EDIT_PERMISSION = `style-edit-permission`;
export const STYLE_DELETE_PERMISSION = `style-delete-permission`;
export const SALES_CONTRACT_VIEW_PERMISSION = `sales-contract-view-permission`;
export const SALES_CONTRACT_CREATE_PERMISSION = `sales-contract-create-permission`;
export const SALES_CONTRACT_EDIT_PERMISSION = `sales-contract-edit-permission`;
export const SALES_CONTRACT_DELETE_PERMISSION = `sales-contract-delete-permission`;
export const SALES_CONTRACT_366_VIEW_PERMISSION = `sales-contract-366-view-permission`;
export const SALES_CONTRACT_366_CREATE_PERMISSION = `sales-contract-366-create-permission`;
export const SALES_CONTRACT_366_EDIT_PERMISSION = `sales-contract-366-edit-permission`;
export const SALES_CONTRACT_366_DELETE_PERMISSION = `sales-contract-366-delete-permission`;
export const PURCHASE_ORDER_VIEW_PERMISSION = `purchase-order-view-permission`;
export const PURCHASE_ORDER_CREATE_PERMISSION = `purchase-order-create-permission`;
export const PURCHASE_ORDER_EDIT_PERMISSION = `purchase-order-edit-permission`;
export const PURCHASE_ORDER_ADD_PRICE_PERMISSION = `purchase-order-add-price-permission`;
export const PURCHASE_ORDER_DELETE_PERMISSION = `purchase-order-delete-permission`;
export const BOM_VIEW_PERMISSION = `bom-view-permission`;
export const BOM_CREATE_PERMISSION = `bom-create-permission`;
export const BOM_EDIT_PERMISSION = `bom-edit-permission`;
export const BOM_DELETE_PERMISSION = `bom-delete-permission`;
export const BOM_EXPORT_PERMISSION = `bom-export-permission`;
export const SALES_CONTRACT_COMMERCIAL_VIEW_PERMISSION = `sales-contract-commercial-view-permission`;
export const SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION = `sales-contract-commercial-create-permission`;
export const SALES_CONTRACT_COMMERCIAL_EDIT_PERMISSION = `sales-contract-commercial-edit-permission`;
export const SALES_CONTRACT_COMMERCIAL_DELETE_PERMISSION = `sales-contract-commercial-delete-permission`;
export const B2BLC_VIEW_PERMISSION = `b2b-lc-view-permission`;
export const B2BLC_CREATE_PERMISSION = `b2b-lc-create-permission`;
export const B2BLC_EDIT_PERMISSION = `b2b-lc-edit-permission`;
export const B2BLC_DELETE_PERMISSION = `b2b-lc-delete-permission`;
export const PI_VIEW_PERMISSION = `pi-view-permission`;
export const PI_CREATE_PERMISSION = `pi-create-permission`;
export const PI_EDIT_PERMISSION = `pi-edit-permission`;
export const PI_DELETE_PERMISSION = `pi-delete-permission`;
export const PI_BUDGET_VIEW_PERMISSION = `pi-budget-view-permission`;
export const PI_APPROVE_PERMISSION = `pi-approve-permission`;
export const DAILY_IMPORT_POSITION_VIEW_PERMISSION = `daily-import-position-view-permission`;
export const DAILY_IMPORT_POSITION_CREATE_PERMISSION = `daily-import-position-create-permission`;
export const DAILY_IMPORT_POSITION_EDIT_PERMISSION = `daily-import-position-edit-permission`;
export const DAILY_IMPORT_POSITION_DELETE_PERMISSION = `daily-import-position-delete-permission`;
export const IMPORT_ACCEPTANCE_DETAIL_VIEW_PERMISSION = `import-acceptance-detail-view-permission`;
export const IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION = `import-acceptance-detail-create-permission`;
export const IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION = `import-acceptance-detail-edit-permission`;
export const IMPORT_ACCEPTANCE_DETAIL_DELETE_PERMISSION = `import-acceptance-detail-delete-permission`;
export const WAREHOUSE_VIEW_PERMISSION = `warehouse-view-permission`;
export const WAREHOUSE_CREATE_PERMISSION = `warehouse-create-permission`;
export const WAREHOUSE_EDIT_PERMISSION = `warehouse-edit-permission`;
export const WAREHOUSE_DELETE_PERMISSION = `warehouse-delete-permission`;
export const RACK_VIEW_PERMISSION = `rack-view-permission`;
export const RACK_CREATE_PERMISSION = `rack-create-permission`;
export const RACK_EDIT_PERMISSION = `rack-edit-permission`;
export const RACK_DELETE_PERMISSION = `rack-delete-permission`;
export const BIN_VIEW_PERMISSION = `bin-view-permission`;
export const BIN_CREATE_PERMISSION = `bin-create-permission`;
export const BIN_EDIT_PERMISSION = `bin-edit-permission`;
export const BIN_DELETE_PERMISSION = `bin-delete-permission`;
export const UNIT_VIEW_PERMISSION = `unit-view-permission`;
export const UNIT_CREATE_PERMISSION = `unit-create-permission`;
export const UNIT_EDIT_PERMISSION = `unit-edit-permission`;
export const UNIT_DELETE_PERMISSION = `unit-delete-permission`;
export const SUPPLIER_VIEW_PERMISSION = `supplier-view-permission`;
export const SUPPLIER_CREATE_PERMISSION = `supplier-create-permission`;
export const SUPPLIER_EDIT_PERMISSION = `supplier-edit-permission`;
export const SUPPLIER_DELETE_PERMISSION = `supplier-delete-permission`;
export const FINISH_GARMENT_VIEW_PERMISSION = `finish-garment-view-permission`;
export const FINISH_GARMENT_CREATE_PERMISSION = `finish-garment-create-permission`;
export const FINISH_GARMENT_EDIT_PERMISSION = `finish-garment-edit-permission`;
export const FINISH_GARMENT_DELETE_PERMISSION = `finish-garment-delete-permission`;
export const COLOR_VIEW_PERMISSION = `color-view-permission`;
export const COLOR_CREATE_PERMISSION = `color-create-permission`;
export const COLOR_EDIT_PERMISSION = `color-edit-permission`;
export const COLOR_DELETE_PERMISSION = `color-delete-permission`;
export const SIZE_VIEW_PERMISSION = `size-view-permission`;
export const SIZE_CREATE_PERMISSION = `size-create-permission`;
export const SIZE_EDIT_PERMISSION = `size-edit-permission`;
export const SIZE_DELETE_PERMISSION = `size-delete-permission`;
export const EXPORT_DETAILS_VIEW_PERMISSION = `export-details-view-permission`;
export const EXPORT_DETAILS_CREATE_PERMISSION = `export-details-create-permission`;
export const EXPORT_DETAILS_EDIT_PERMISSION = `export-details-edit-permission`;
export const EXPORT_DETAILS_DELETE_PERMISSION = `export-details-delete-permission`;
export const SUBMISSION_REALIZATION_VIEW_PERMISSION = `submission-realization-view-permission`;
export const SUBMISSION_REALIZATION_CREATE_PERMISSION = `submission-realization-create-permission`;
export const SUBMISSION_REALIZATION_EDIT_PERMISSION = `submission-realization-edit-permission`;
export const SUBMISSION_REALIZATION_DELETE_PERMISSION = `submission-realization-delete-permission`;
export const NOTING_ASSESSMENT_VIEW_PERMISSION = `noting-assessment-detail-view-permission`;
export const NOTING_ASSESSMENT_CREATE_PERMISSION = `noting-assessment-detail-create-permission`;
export const NOTING_ASSESSMENT_EDIT_PERMISSION = `noting-assessment-detail-edit-permission`;
export const NOTING_ASSESSMENT_DELETE_PERMISSION = `noting-assessment-detail-delete-permission`;
export const GOODS_DELIVERY_VIEW_PERMISSION = `goods-delivery-view-permission`;
export const GOODS_DELIVERY_CREATE_PERMISSION = `goods-delivery-create-permission`;
export const GOODS_DELIVERY_EDIT_PERMISSION = `goods-delivery-edit-permission`;
export const GOODS_DELIVERY_DELETE_PERMISSION = `goods-delivery-delete-permission`;
export const FABRIC_REPORT_VIEW_PERMISSION = `fabric-report-view-permission`;
export const FABRIC_REPORT_CREATE_PERMISSION = `fabric-report-create-permission`;
export const FABRIC_REPORT_EDIT_PERMISSION = `fabric-report-edit-permission`;
export const FABRIC_REPORT_DELETE_PERMISSION = `fabric-report-delete-permission`;
export const FABRIC_REPORT_GENERATE_PERMISSION = `fabric-report-generate-permission`;
export const SWING_VS_FOLDING_REPORT_GENERATE_PERMISSION = `fabric-report-generate-permission`;
export const PRODUCT_TRACK_REPORT_VIEW_PERMISSION = `product-track-report-view-permission`;
export const PRODUCT_TRACK_REPORT_CREATE_PERMISSION = `product-track-report-create-permission`;
export const PRODUCT_TRACK_REPORT_EDIT_PERMISSION = `product-track-report-edit-permission`;
export const PRODUCT_TRACK_REPORT_DELETE_PERMISSION = `product-track-report-delete-permission`;
export const PRODUCT_TRACK_REPORT_GENERATE_PERMISSION = `product-track-report-generate-permission`;
export const PRODUCT_DETAIL_REPORT_GENERATE_PERMISSION = `product-detail-report-generate-permission`;
export const PRODUCT_DETAIL_REPORT_VIEW_PERMISSION = `product-detail-report-view-permission`;
export const PRODUCT_DETAIL_REPORT_CREATE_PERMISSION = `product-detail-report-generate-permission`;
export const PRODUCT_DETAIL_REPORT_EDIT_PERMISSION = `product-detail-report-edit-permission`;
export const PRODUCT_DETAIL_REPORT_DELETE_PERMISSION = `product-detail-report-delete-permission`;
export const COST_SHEET_REPORT_VIEW_PERMISSION = `cost-sheet-report-view-permission`;
export const COST_SHEET_REPORT_GENERATE_PERMISSION = `cost-sheet-report-generate-permission`;
export const COST_SHEET_REPORT_CREATE_PERMISSION = `cost-sheet-report-create-permission`;
export const COST_SHEET_REPORT_EDIT_PERMISSION = `cost-sheet-report-edit-permission`;
export const COST_SHEET_REPORT_DELETE_PERMISSION = `cost-sheet-report-delete-permission`;
export const UPLOAD_BUYER_PERMISSION = `upload-buyer-permission`;
export const UPLOAD_BRAND_PERMISSION = `upload-brand-permission`;
export const UPLOAD_BUYING_OFFICE_PERMISSION = `upload-buying-office-permission`;
export const UPLOAD_BANK_PERMISSION = `upload-bank-permission`;
export const UPLOAD_BRANCH_PERMISSION = `upload-branch-permission`;
export const UPLOAD_MERCHANDISER_PERMISSION = `upload-merchandiser-permission`;
export const UPLOAD_STYLE_PERMISSION = `upload-style-permission`;
export const UPLOAD_SUBSTYLE_PERMISSION = `upload-substyle-permission`;
export const UPLOAD_SALES_CONTRACT_PERMISSION = `upload-sales-contract-permission`;
export const UPLOAD_SUPPLIER_PERMISSION = `upload-supplier-permission`;
export const UPLOAD_COLOR_PERMISSION = `upload-color-permission`;
export const UPLOAD_SIZE_PERMISSION = `upload-size-permission`;
export const UPLOAD_BOM_PERMISSION = `upload-bom-permission`;
export const UPLOAD_PO_PERMISSION = `upload-po-permission`;
export const SECURITY_BOLT_VIEW_PERMISSION = `security-bolt-view-permission`;
export const SECURITY_BOLT_CREATE_PERMISSION = `security-bolt-create-permission`;
export const SECURITY_BOLT_EDIT_PERMISSION = `security-bolt-edit-permission`;
export const SECURITY_BOLT_DELETE_PERMISSION = `security-bolt-delete-permission`;
export const SHIPMENT_HANDOVER_VIEW_PERMISSION = `shipment-handover-view-permission`;
export const SHIPMENT_HANDOVER_CREATE_PERMISSION = `shipment-handover-create-permission`;
export const SHIPMENT_HANDOVER_EDIT_PERMISSION = `shipment-handover-edit-permission`;
export const SHIPMENT_HANDOVER_DELETE_PERMISSION = `shipment-handover-delete-permission`;
export const INVENTORY_DETAIL_REPORT_VIEW_PERMISSION = `inventory-detail-report-view-permission`;
export const INVENTORY_DETAIL_REPORT_GENERATE_PERMISSION = `inventory-detail-report-generate-permission`;
export const INVENTORY_DETAIL_REPORT_DELETE_PERMISSION = `inventory-detail-report-delete-permission`;
export const BUYER_PO_PO_WISE_VIEW_PERMISSION = `po-wise-purchase-order-view-permission`;
export const BUYER_PO_PO_WISE_CREATE_PERMISSION = `po-wise-purchase-order-create-permission`;
export const BUYER_PO_PO_WISE_EDIT_PERMISSION = `po-wise-purchase-order-edit-permission`;
export const BUYER_PO_PO_WISE_DELETE_PERMISSION = `po-wise-purchase-order-delete-permission`;
export const ACTIVITY_LOG_VIEW_PERMISSION = `activity-log-view-permission`;
export const INVENTORY_MRR_REPORT_VIEW_PERMISSION = `inventory-mrr-report-view-permission`;
export const GENERATE_MRR_REPORT_VIEW_PERMISSION = `inventory-mrr-report-generate-permission`;
export const DELETE_MRR_REPORT_VIEW_PERMISSION = `inventory-mrr-report-delete-permission`;
export const OB_MACHINE_VIEW_PERMISSION = `ob-machine-type-view-permission`;
export const OB_MACHINE_CREATE_PERMISSION = `ob-machine-type-create-permission`;
export const OB_MACHINE_EDIT_PERMISSION = `ob-machine-type-edit-permission`;
export const OB_MACHINE_DELETE_PERMISSION = `ob-machine-type-delete-permission`;
export const OB_SECTION_VIEW_PERMISSION = `ob-section-view-permission`;
export const OB_SECTION_CREATE_PERMISSION = `ob-section-create-permission`;
export const OB_SECTION_EDIT_PERMISSION = `ob-section-edit-permission`;
export const OB_SECTION_DELETE_PERMISSION = `ob-section-delete-permission`;
export const OB_SAM_DATA_BANK_VIEW_PERMISSION = `ob-sam-data-bank-view-permission`;
export const OB_SAM_DATA_BANK_CREATE_PERMISSION = `ob-sam-data-bank-create-permission`;
export const OB_SAM_DATA_BANK_EDIT_PERMISSION = `ob-sam-data-bank-edit-permission`;
export const OB_SAM_DATA_BANK_DELETE_PERMISSION = `ob-sam-data-bank-delete-permission`;
export const OB_VIEW_PERMISSION = `operation-bulletin-view-permission`;
export const OB_CREATE_PERMISSION = `operation-bulletin-create-permission`;
export const OB_EDIT_PERMISSION = `operation-bulletin-edit-permission`;
export const OB_DELETE_PERMISSION = `operation-bulletin-delete-permission`;
export const OB_CLONE_PERMISSION = `operation-bulletin-clone-permission`;
export const CHALLAN_CREATE_PERMISSION = `challan-no-create-permission`;
export const CHALLAN_EDIT_PERMISSION = `challan-no-edit-permission`;
export const CHALLAN_VIEW_PERMISSION = `challan-no-view-permission`;
export const CHALLAN_DELETE_PERMISSION = `challan-no-delete-permission`;
export const DAILY_LINE_TARGET_CREATE_PERMISSION = `daily-line-target-create-permission`;
export const DAILY_LINE_TARGET_EDIT_PERMISSION = `daily-line-target-edit-permission`;
export const DAILY_LINE_TARGET_VIEW_PERMISSION = `daily-line-target-view-permission`;
export const DAILY_LINE_TARGET_DELETE_PERMISSION = `daily-line-target-delete-permission`;
export const GARMENT_CATEGORY_VIEW_PERMISSION = `garment-category-view-permission`;
export const GARMENT_CATEGORY_CREATE_PERMISSION = `garment-category-create-permission`;
export const GARMENT_CATEGORY_EDIT_PERMISSION = `garment-category-edit-permission`;
export const GARMENT_CATEGORY_DELETE_PERMISSION = `garment-category-delete-permission`;
export const GARMENT_TYPE_VIEW_PERMISSION = `garment-type-view-permission`;
export const GARMENT_TYPE_CREATE_PERMISSION = `garment-type-create-permission`;
export const GARMENT_TYPE_EDIT_PERMISSION = `garment-type-edit-permission`;
export const GARMENT_TYPE_DELETE_PERMISSION = `garment-type-delete-permission`;
export const BUYER_INQUIRY_VIEW_PERMISSION = `buyer-inquiry-view-permission`;
export const BUYER_INQUIRY_CREATE_PERMISSION = `buyer-inquiry-create-permission`;
export const BUYER_INQUIRY_EDIT_PERMISSION = `buyer-inquiry-edit-permission`;
export const BUYER_INQUIRY_DELETE_PERMISSION = `buyer-inquiry-delete-permission`;
export const COST_OF_MAKING_LIST = `cost-of-making-list`;
export const COST_OF_MAKING_CREATE = `cost-of-making-create`;
export const COST_OF_MAKING_EDIT = `cost-of-making-edit`;
export const COST_OF_MAKING_DELETE = `cost-of-making-delete`;
export const COST_OF_MAKING_DETAILS = `cost-of-making-details-permission`;
export const INQUIRY_REASONS_VIEW_PERMISSION = `inquiry-reasons-view-permission`;
export const INQUIRY_REASONS_CREATE_PERMISSION = `inquiry-reasons-create-permission`;
export const INQUIRY_REASONS_EDIT_PERMISSION = `inquiry-reasons-edit-permission`;
export const INQUIRY_REASONS_DELETE_PERMISSION = `inquiry-reasons-delete-permission`;
export const INQUIRY_REMARKS_VIEW_PERMISSION = `inquiry-remarks-view-permission`;
export const INQUIRY_REMARKS_CREATE_PERMISSION = `inquiry-remarks-create-permission`;
export const INQUIRY_REMARKS_EDIT_PERMISSION = `inquiry-remarks-edit-permission`;
export const INQUIRY_REMARKS_DELETE_PERMISSION = `inquiry-remarks-delete-permission`;
export const LEARNING_CURVE_VIEW_PERMISSION = `learning-curve-view-permission`;
export const LEARNING_CURVE_CREATE_PERMISSION = `learning-curve-create-permission`;
export const LEARNING_CURVE_EDIT_PERMISSION = `learning-curve-edit-permission`;
export const LEARNING_CURVE_DELETE_PERMISSION = `learning-curve-delete-permission`;
export const OB_TEMPLATE_VIEW_PERMISSION = `ob-template-view-permission`;
export const OB_TEMPLATE_CREATE_PERMISSION = `ob-template-create-permission`;
export const OB_TEMPLATE_EDIT_PERMISSION = `ob-template-edit-permission`;
export const OB_TEMPLATE_DELETE_PERMISSION = `ob-template-delete-permission`;
export const NOTIFICATION_SLUG_VIEW_PERMISSION = `notification-slug-view-permission`;
export const NOTIFICATION_SLUG_CREATE_PERMISSION = `notification-slug-create-permission`;
export const NOTIFICATION_SLUG_EDIT_PERMISSION = `notification-slug-edit-permission`;
export const NOTIFICATION_SLUG_DELETE_PERMISSION = `notification-slug-delete-permission`;
export const NOTIFICATION_SETTING_VIEW_PERMISSION = `notification-setting-view-permission`;
export const NOTIFICATION_SETTING_CREATE_PERMISSION = `notification-setting-create-permission`;
export const NOTIFICATION_SETTING_EDIT_PERMISSION = `notification-setting-edit-permission`;
export const NOTIFICATION_SETTING_DELETE_PERMISSION = `notification-setting-delete-permission`;
export const COST_ESTIMATE_VIEW_PERMISSION = `cost-estimate-view-permission`;
export const COST_ESTIMATE_CREATE_PERMISSION = `cost-estimate-create-permission`;
export const COST_ESTIMATE_EDIT_PERMISSION = `cost-estimate-edit-permission`;
export const COST_ESTIMATE_DELETE_PERMISSION = `cost-estimate-delete-permission`;
export const COST_ESTIMATE_APPROVE_PERMISSION = `cost-estimate-approve-permission`;
export const LOCAL_PURCHASE_RECONCILIATION_VIEW_PERMISSION = `local-purchase-reconciliation-view-permission`;
export const LOCAL_PURCHASE_RECONCILIATION_CREATE_PERMISSION = `local-purchase-reconciliation-create-permission`;
export const LOCAL_PURCHASE_RECONCILIATION_EDIT_PERMISSION = `local-purchase-reconciliation-edit-permission`;
export const LOCAL_PURCHASE_RECONCILIATION_DELETE_PERMISSION = `local-purchase-reconciliation-delete-permission`;
export const PERMISSION_CREATE_PERMISSION = `permission-create-permission`;
export const PERMISSION_EDIT_PERMISSION = `permission-edit-permission`;
export const PERMISSION_DELETE_PERMISSION = `permission-delete-permission`;
export const PERMISSION_VIEW_PERMISSION = `permission-view-permission`;
export const CM_VALUE_APPROVE_PERMISSION = "cm-value-approve-permission";
export const DRIVER_VEHICLE_INFO_VIEW_PERMISSION = `driver-vehicle-info-view-permission`;
export const DRIVER_VEHICLE_INFO_CREATE_PERMISSION = `driver-vehicle-info-create-permission`;
export const DRIVER_VEHICLE_INFO_EDIT_PERMISSION = `driver-vehicle-info-edit-permission`;
export const DRIVER_VEHICLE_INFO_DELETE_PERMISSION = `driver-vehicle-info-delete-permission`;
export const DRIVER_VEHICLE_INFO_DISPATCH_TIME_PERMISSION = `driver-vehicle-info-dispatch-time-permission`;
export const BOLT_SEAL_LOG_CREATE_PERMISSION = `bolt-seal-log-create-permission`;
export const BOLT_SEAL_LOG_EDIT_PERMISSION = `bolt-seal-log-edit-permission`;
export const BOLT_SEAL_LOG_VIEW_PERMISSION = `bolt-seal-log-view-permission`;
export const BOLT_SEAL_LOG_DELETE_PERMISSION = `bolt-seal-log-delete-permission`;
export const HOUR_CONFIGURATION_CREATE_PERMISSION = `hour-configuration-create-permission`;

export const TNA_DEPARTMENT_CREATE_PERMISSION = `tna-department-create-permission`;
export const TNA_DEPARTMENT_VIEW_PERMISSION = `tna-department-view-permission`;
export const TNA_DEPARTMENT_EDIT_PERMISSION = `tna-department-edit-permission`;
export const TNA_DEPARTMENT_DELETE_PERMISSION = `tna-department-delete-permission`;

export const TNA_PLAN_CREATE_PERMISSION = `tna-plan-create-permission`;
export const TNA_PLAN_VIEW_PERMISSION = `tna-plan-view-permission`;
export const TNA_PLAN_EDIT_PERMISSION = `tna-plan-edit-permission`;
export const TNA_PLAN_DELETE_PERMISSION = `tna-plan-delete-permission`;

export const TNA_PROCESS_CREATE_PERMISSION = `tna-process-create-permission`;
export const TNA_PROCESS_VIEW_PERMISSION = `tna-process-view-permission`;
export const TNA_PROCESS_EDIT_PERMISSION = `tna-process-edit-permission`;
export const TNA_PROCESS_DELETE_PERMISSION = `tna-process-delete-permission`;

export const TNA_TASK_CREATE_PERMISSION = `tna-task-create-permission`;
export const TNA_TASK_VIEW_PERMISSION = `tna-task-view-permission`;
export const TNA_TASK_EDIT_PERMISSION = `tna-task-edit-permission`;
export const TNA_TASK_DELETE_PERMISSION = `tna-task-delete-permission`;

export const TNA_TEMPLATE_CREATE_PERMISSION = `tna-template-create-permission`;
export const TNA_TEMPLATE_VIEW_PERMISSION = `tna-template-view-permission`;
export const TNA_TEMPLATE_EDIT_PERMISSION = `tna-template-edit-permission`;
export const TNA_TEMPLATE_DELETE_PERMISSION = `tna-template-delete-permission`;

export const TNA_TO_DO_LIST_VIEW_PERMISSION = `tna-to-do-list-view-permission`;
export const TNA_SUMMARY_VIEW_PERMISSION = `tna-summary-view-permission`;

export const POST_COSTING_REPORT_GENERATE_PERMISSION = `post-costing-report-generate-permission`;

export const DIGITALIZATION_CHECKLIST_VIEW_PERMISSION = `digitalization-checklist-view-permission`;
export const DIGITALIZATION_ABSENTEEISM_VIEW_PERMISSION = `digitalization-absenteeism-view-permission`;
export const DIGITALIZATION_DEFECT_VIEW_PERMISSION = `digitalization-defect-view-permission`;

export const ITEM_OCR_UPLOAD_PERMISSION = `item-ocr-upload-permission`;

export const PP_WEEKEND_VIEW_PERMISSION = `weekend-view-permission`;
export const PP_WEEKEND_CREATE_PERMISSION = `weekend-create-permission`;
export const PP_WEEKEND_EDIT_PERMISSION = `weekend-edit-permission`;
export const PP_WEEKEND_DELETE_PERMISSION = `weekend-delete-permission`;

export const PP_HOLIDAY_VIEW_PERMISSION = `holiday-view-permission`;
export const PP_HOLIDAY_CREATE_PERMISSION = `holiday-create-permission`;
export const PP_HOLIDAY_EDIT_PERMISSION = `holiday-edit-permission`;
export const PP_HOLIDAY_DELETE_PERMISSION = `holiday-delete-permission`;

export const PP_EXCEPTION_VIEW_PERMISSION = `exception-view-permission`;
export const PP_EXCEPTION_CREATE_PERMISSION = `exception-create-permission`;
export const PP_EXCEPTION_EDIT_PERMISSION = `exception-edit-permission`;
export const PP_EXCEPTION_DELETE_PERMISSION = `exception-delete-permission`;

export const PP_PLANNING_REQUEST_VIEW_PERMISSION = `planning-request-view-permission`;
export const PP_PLANNING_REQUEST_CREATE_PERMISSION = `planning-request-create-permission`;
export const PP_PLANNING_REQUEST_EDIT_PERMISSION = `planning-request-edit-permission`;
export const PP_PLANNING_REQUEST_DELETE_PERMISSION = `planning-request-delete-permission`;
export const DAILY_LINE_PLAN_IMPORT_PERMISSION = `daily-line-plan-import-permission`;
export const DIGITALIZATION_ROLE_CHECK_PREMISSION = `digitalization-role-wise-checklists-permission`;

/*  OT Signal  */
export const OT_SIGNAL_CREATE_PERMISSION = `ot-signal-create-permission`;
export const OT_SIGNAL_VIEW_PERMISSION = `ot-signal-view-permission`;
export const OT_SIGNAL_EDIT_PERMISSION = `ot-signal-edit-permission`;
export const OT_SIGNAL_DELETE_PERMISSION = `ot-signal-delete-permission`;

/*  OT Signal Section  */
export const OT_SIGNAL_SECTION_CREATE_PERMISSION = `ot-signal-section-create-permission`;
export const OT_SIGNAL_SECTION_EDIT_PERMISSION = `ot-signal-section-edit-permission`;
export const OT_SIGNAL_SECTION_VIEW_PERMISSION = `ot-signal-section-view-permission`;
export const OT_SIGNAL_SECTION_DELETE_PERMISSION = `ot-signal-section-delete-permission`;

/*  BANK  */
export const BANK_CREATE_PERMISSION = `bank-create-permission`;
export const BANK_EDIT_PERMISSION = `bank-edit-permission`;
export const BANK_VIEW_PERMISSION = `bank-view-permission`;
export const BANK_DELETE_PERMISSION = `bank-delete-permission`;

/*  BRANCH  */
export const BRANCH_CREATE_PERMISSION = `branch-create-permission`;
export const BRANCH_EDIT_PERMISSION = `branch-edit-permission`;
export const BRANCH_VIEW_PERMISSION = `branch-view-permission`;
export const BRANCH_DELETE_PERMISSION = `branch-delete-permission`;

/*  ACCOUNT  */
export const ACCOUNT_CREATE_PERMISSION = `account-create-permission`;
export const ACCOUNT_EDIT_PERMISSION = `account-edit-permission`;
export const ACCOUNT_VIEW_PERMISSION = `account-view-permission`;
export const ACCOUNT_DELETE_PERMISSION = `account-delete-permission`;

// GARMENTS PARTS
export const GARMENTS_PARTS_CREATE_PERMISSION = `garment-part-create-permission`;
export const GARMENTS_PARTS_EDIT_PERMISSION = `garment-part-edit-permission`;
export const GARMENTS_PARTS_VIEW_PERMISSION = `garment-part-view-permission`;
export const GARMENTS_PARTS_DELETE_PERMISSION = `garment-part-delete-permission`;

// ERP USAGE
export const ERP_USAGE_CREATE_PERMISSION = `erp-usage-create-permission`;
export const ERP_USAGE_EDIT_PERMISSION = `erp-usage-edit-permission`;
export const ERP_USAGE_VIEW_PERMISSION = `erp-usage-view-permission`;
export const ERP_USAGE_DELETE_PERMISSION = `erp-usage-delete-permission`;

// ERP USAGE DASHBOARD
export const ERP_USAGE_DASHBOARD_VIEW_PERMISSION = `erp-usage-dashboard-view-permission`;

// ERP USAGE MODULE
export const ERP_USAGE_MODULE_CREATE_PERMISSION = `erp-usage-module-create-permission`;
export const ERP_USAGE_MODULE_EDIT_PERMISSION = `erp-usage-module-edit-permission`;
export const ERP_USAGE_MODULE_VIEW_PERMISSION = `erp-usage-module-view-permission`;
export const ERP_USAGE_MODULE_DELETE_PERMISSION = `erp-usage-module-delete-permission`;

// ERP USAGE MODULE UI
export const ERP_USAGE_MODULE_UI_CREATE_PERMISSION = `erp-usage-module-ui-create-permission`;
export const ERP_USAGE_MODULE_UI_EDIT_PERMISSION = `erp-usage-module-ui-edit-permission`;
export const ERP_USAGE_MODULE_UI_VIEW_PERMISSION = `erp-usage-module-ui-view-permission`;
export const ERP_USAGE_MODULE_UI_DELETE_PERMISSION = `erp-usage-module-ui-delete-permission`;

// ERP USAGE ISSUES
export const ERP_USAGE_ISSUE_CREATE_PERMISSION = `erp-usage-issue-create-permission`;
export const ERP_USAGE_ISSUE_EDIT_PERMISSION = `erp-usage-issue-edit-permission`;
export const ERP_USAGE_ISSUE_VIEW_PERMISSION = `erp-usage-issue-view-permission`;
export const ERP_USAGE_ISSUE_DELETE_PERMISSION = `erp-usage-issue-delete-permission`;

// PRODUCTION PLANNING
export const PLAN_CREATE_PERMISSION = `plan-entry-create-permission`;
export const PLAN_EDIT_PERMISSION = `plan-entry-edit-permission`;
export const PLAN_VIEW_PERMISSION = `plan-entry-view-permission`;
export const PLAN_DELETE_PERMISSION = `plan-entry-delete-permission`;
export const PLAN_DASHBOARD_VIEW_PERMISSION = `plan-dashboard-view-permission`;

export const CAPACITY_BOOKING_MENU_VIEW_PERMISSION = `capacity-booking-menu-view-permission`;
export const BUYER_WISE_CHECKLIST_VIEW_PERMISSION = `buyerwise-checklist-view-permission`;
export const CHECKLIST_MENU_VIEW_PERMISSION = `checklist-menu-view-permission`;
export const PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION = `pp-buyer-inspection-menu-view-permission`;
export const DIGITALIZATION_SETTINGS_VIEW_PERMISSION = `digitalization-settings-view-permission`;
export const DAILY_LINE_PLAN_VIEW_PERMISSION = `daily-line-plan-view-permission`;
export const GARMENTS_TRACKING_VIEW_PERMISSION = `garments-tracking-view-permission`;
export const HOURLY_PRODUCTION_VIEW_PERMISSION = `hourly-production-view-permission`;
export const SUPERVISOR_CHECKLIST_CREATE_PERMISSION = `supervisor-checklist-create-permission`;
export const SUPERVISOR_CHECKLIST_EDIT_PERMISSION = `supervisor-checklist-edit-permission`;
export const SUPERVISOR_CHECKLIST_VIEW_PERMISSION = `supervisor-checklist-view-permission`;
export const SUPERVISOR_CHECKLIST_DELETE_PERMISSION = `supervisor-checklist-delete-permission`;
export const APP_DASHBOARD_VIEW_PERMISSION = `app-dashboard-view-permission`;
export const DIGITALIZATION_MENU_VIEW_PERMISSION = `digitalization-menu-view-permission`;
export const WASHING_SNAP_BUTTON_VIEW_PERMISSION = `washing-snap-button-view-permission`;
export const EMBROIDERY_PRINTING_SMOCKING_PINTUCK_VIEW_PERMISSION = `embroidery-printing-smocking-pintuck-view-permission`;
export const FABRIC_ACCESSORIES_AUTO_RECONCILIATION_VIEW_PERMISSION = `fabric-accessories-auto-reconciliation-view-permission`;
export const SUBCONTRACT_FACTORY_VIEW_PERMISSION = `subcontract-factory-view-permission`;
export const OB_TEMPLATE_IMPORT_PERMISSION = `ob-template-import-permission`;