import {
  DownloadOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Card,
  Form,
  Select,
  Modal,
  Popover,
  InputNumber,
  Input,
  DatePicker,
  Divider,
  Space,
  Button,
  message,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import {
  ADD_B2BLC_REMARKS,
  ADD_LC_REASON,
  B2BLC_DOCUMENT_UPLOAD,
  B2BLC_REMARKS_LIST,
  EXPORT_LC_REASONS,
  SALES_CONTRACT_COMMERCIAL_DOC,
} from "../../../apiServices/API_ENDPOINTS";
import { useParams } from "react-router";
import { getData, postData } from "../../../apiServices/common";
import HandleNotification from "../../../common/Notification";
import { isArrayAndHasValue } from "../../../utils/functions";
import { BASE_URL } from "../../../apiServices";

const token = localStorage.getItem("token");

const CurveDetailsTable = (props) => {
  // Props
  const { curveDetailsList, setCurveDetailsList } = props;

  // Antd
  const [curveDetailsForm] = Form.useForm();

  // Others
  const { id } = useParams();

  const initialCurveDetails = {
    unique_id: 1,
    day: 1,
    efficiency: null,
    working_hours: null,
    approx_manpower: null,
  };

  const addRow = (unique_id) => {
    const curveDetailsListCopy = isArrayAndHasValue(curveDetailsList)
          ? [...curveDetailsList]
          : [];

      // Check if the last row is fully filled before adding a new row
      const lastRow = curveDetailsListCopy[curveDetailsListCopy.length - 1]

      if (lastRow) {
        // Check if any of the fields in the last row is empty
        const isEmpty = Object.values(lastRow).some(value => value === null || value === '');
        
      if (isEmpty) {
        // Show a message or notification for the empty fields
        message.error("Please fill all fields in the current row before adding a new row.");
        return;
      }
    }
    const newData = {
      ...initialCurveDetails,
      unique_id: isArrayAndHasValue(curveDetailsListCopy)
        ? curveDetailsListCopy[curveDetailsListCopy.length - 1].unique_id + 1
        : 0,
      day: isArrayAndHasValue(curveDetailsListCopy)
        ? curveDetailsListCopy[curveDetailsListCopy.length - 1].day + 1
        : 0,
    };
    curveDetailsListCopy.push(newData);
    setCurveDetailsList(curveDetailsListCopy);
  };

  const removeRow = (unique_id) => {
    const curveDetailsListCopy = [...curveDetailsList];
    const newData = curveDetailsListCopy.filter(
      (item) => item?.unique_id !== unique_id,
    );
    setCurveDetailsList(newData);
  }

  const onChangeCurveValue = (value, index, keyName) => {
    // Copy poDetailsData state array
    const curveDetailsDataCopy = [...curveDetailsList];
    const foundCurveItem = curveDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );
    foundCurveItem[keyName] = value;

    setCurveDetailsList(curveDetailsDataCopy);
  };

  const onUploadFile = (info, key) => {
    const fileResponse =
      info?.file?.response?.code === 200 ? info?.file?.response?.data : null;
    if (fileResponse) {
      onChangeCurveValue(fileResponse, key, "lc_amendment_file");
    }
  };

  console.log('curveDetails', curveDetailsList);

  return (
    <Form form={curveDetailsForm}>
      <div>
        <Card
          title={<span style={{ fontSize: "14px" }}>Learning Curve Details</span>}
        >
          <div className="custome-table">
            <table>
              <thead>
                <tr>
                  <th key="header_initial_amendment" className="bc-java ">
                    Day{" "}
                  </th>
                  <th key="header_amendment_date" className="bc-java ">
                    Efficiency{" "}
                  </th>
                  <th key="header_lc_qty" className="bc-java ">
                    W. Hrs{" "}
                  </th>
                  <th key="header_reason" className="bc-java ">
                    Apx. Man{" "}
                  </th>
                  <th key="header_add" className="bc-java ">
                    Add{" "}
                  </th>
                  <th key="header_remove" className="bc-java ">
                    Rmv{" "}
                  </th>
                </tr>
              </thead>

              <tbody className="scrollable-table-container">
                {isArrayAndHasValue(curveDetailsList) &&
                  curveDetailsList?.map((item, index) => {
                    return (
                      <tr key={`table_parent_key_${index}`}>
                        {/* INITIAL/AMENDMENT */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`day_${index}`}
                              value={item?.day || ""}
                              onChange={(event) => {
                                let context = "day";
                                onChangeCurveValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              disabled={true}
                              size="small"
                              placeholder="Day"
                              className="move-right"
                            />
                          </Form.Item>
                        </td>

                        <td className="t-body">
                          <Form.Item style={{ marginBottom: "unset" }}>
                            <Input
                                name={`efficiency_${index}`}
                                value={item?.efficiency || ""}
                                onChange={(event) => {
                                  let context = "efficiency";
                                  onChangeCurveValue(
                                    event.target.value,
                                    item?.unique_id,
                                    context,
                                  );
                                }}
                                size="small"
                                placeholder="Efficiency"
                                className="move-right"
                              />
                          </Form.Item>
                        </td>

                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`working_hours_${index}`}
                              value={item?.working_hours ?? ""}
                              onChange={(event) => {
                                let context = "working_hours";
                                onChangeCurveValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="W. Hrs"
                              className="move-right"
                            />
                          </Form.Item>
                        </td>

                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            rules={[
                              {
                                required: true,
                                message: "Please Insert Extra Cutting!",
                              },
                            ]}
                          >
                            <Input
                              name={`approx_manpower_${index}`}
                              value={item?.approx_manpower || ""}
                              onChange={(event) => {
                                let context = "approx_manpower";
                                onChangeCurveValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="Approx. Man"
                              className="move-right"
                            />
                          </Form.Item>
                        </td>

                        {/* ADD BUTTON */}
                        <td
                          className="t-body"
                          onClick={() => {
                            if (index === curveDetailsList.length - 1) {
                              addRow(item?.unique_id);
                            }
                          }}
                        >
                          <PlusCircleOutlined
                            className="cursore-pointer"
                            style={{ 
                              fontSize: "16px", 
                              color: index != curveDetailsList.length - 1 ? "grey" : "green", // Muted color
                              cursor: index != curveDetailsList.length - 1 ? "not-allowed" : "pointer", // Disabled cursor
                            }}
                          />
                        </td>
                        <td
                          className="t-body"
                          onClick={() => {
                            // Disable the remove icon for the first item with "Initial/Amendment" set to "Initial"
                            if (index !== 0 && index === curveDetailsList.length - 1) {
                              removeRow(item?.unique_id);
                            }
                          }}
                        >
                          <MinusCircleOutlined
                            className={`cursore-pointer`}
                            style={{ 
                              fontSize: "16px", 
                              color: index===0 || index != curveDetailsList.length - 1 
                                    ? "grey" 
                                    : "red", // Muted color
                              cursor: index===0 || index != curveDetailsList.length - 1 
                                    ? "not-allowed" 
                                    : "pointer", // Disabled cursor
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    </Form>
  );
};

export default CurveDetailsTable;
