import { Table } from "antd";
import CustomFilter from "components/Common/CustomFilter";
import React, { useState } from "react";

const HROTExpenseDataTable = (props) => {
  const { total, loading, defaultCurrent, pageSize, current, onChange } = props;
  const [showFilter, setShowFilter] = useState(false);

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  return (
    <>
      {props.filter && (
        <CustomFilter
          showFilter={showFilter}
          handleFilter={handleFilter}
          tableData={props.dataSource}
          fetchTableData={null}
          filterName="buying_office"
        />
      )}
      <div className="table" style={{ marginTop: 10 }}>
        <Table
          bordered
          loading={loading}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: pageSize,
            current: current,
            onChange: onChange,
            defaultCurrent: defaultCurrent,
            total: total,
          }}
          columns={props.tableColumns}
          dataSource={props.dataSource}
        />
      </div>
    </>
  );
};
export default HROTExpenseDataTable;
