import { useCallback, useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  message,
  Table,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  Form,
  Select,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";
import {
  getData,
  postData,
  deleteData,
  putData,
} from "../../../apiServices/common";
import { TNA_TEMPLATE_BASE } from "../../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../utils/functions";
import { NO_PERMISSION_REQUIRED } from "../../../routes/permissions";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import HandleNotification from "common/Notification";

const TNATemplate = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rackList, setRackList] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Antd
  const [binForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllTNATemplates = useCallback(
    async (filterValues) => {
      const { name } = filterValues || {};

      setLoading(true);

      const query = `${TNA_TEMPLATE_BASE}?per_page=1000`;
      const bodyData = {
        ...(name && { name: name }),
      };

      const response = await getData(query, false, bodyData);
      if (response && response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting bin data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllTNATemplates(filterData);
  }, [refresh, filterData, getAllTNATemplates]);

  const openAddTemplatePage = () => {
    navigate("/tanda/add-template");
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const deleteSelectedItem = async (id) => {
    const query = `${TNA_TEMPLATE_BASE}/${id}`;
    const response = await deleteData(query);

    if (response) {
      HandleNotification(
        "success",
        "bottomRight",
        "TNA Template Deleted Successfully!",
        null,
      );
      getAllTNATemplates();
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        // {
        //   label: (
        //     <Link to={`/tanda/view-task/${data.id}`}>
        //       <EyeOutlined /> View
        //     </Link>
        //   ),
        //   key: 0,
        //   disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        // },
        {
          label: (
            <Link to={`/tanda/update-template/${data?.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: 1,
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                deleteSelectedItem(data?.id);
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                <DeleteOutlined /> Delete
              </span>
            </Popconfirm>
          ),
          key: 2,
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
      ]}
    />
  );

  const tableColumns = [
    {
      title: "Template Title",
      dataIndex: ["title"],
      key: "template_title",
    },
    {
      title: "Description",
      dataIndex: ["description"],
      key: "description",
    },
    {
      title: "Calculation Method",
      dataIndex: ["calculation_method"],
      key: "calculation_method",
    },
    {
      title: "Is Common",
      dataIndex: ["is_common"],
      key: "is_common",
      render: (_, record) => {
        return <div>{record?.is_common === 1 ? "Yes" : "No"}</div>;
      },
    },
    {
      title: "Buyer",
      dataIndex: ["buyer", "name"],
      key: "buyer_name",
    },
    {
      title: "Buying Office",
      dataIndex: ["buying_office", "name"],
      key: "buying_office_name",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={openAddTemplatePage}
                disabled={!hasPermission([NO_PERMISSION_REQUIRED])}
                addButtonText={
                  hasPermission([NO_PERMISSION_REQUIRED])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                hideFilterButton
              />
            }
            title={<Title level={5}>Template</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllTNATemplates}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                // pagination={{
                //   defaultPageSize: metaData?.defaultPageSize,
                //   current: metaData?.current,
                //   onChange: (newPage) => {
                //     changePage(newPage);
                //   },
                //   defaultCurrent: metaData?.defaultCurrent,
                //   total: metaData?.total,
                //   showSizeChanger: false,
                // }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default TNATemplate;
