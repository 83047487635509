import React, { useEffect, useState } from "react";
import { Button, Space, Typography } from "antd";
import { hasPermission, isArrayAndHasValue } from "../../../utils/functions";
import { ITEM_CATEGORY_EDIT_PERMISSION } from "../../../routes/permissions";
import { useLocation, useNavigate } from "react-router";
import ItemCatSequenceList from "./ItemCatSequenceList";
import AppPageHeader from "../../../common/PageHeader";
import AppContent from "../../../common/AppContent";
import { GET_CATEGORY_ENDPOINT, ITEM_CATEGORY_POSITION_FIX } from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import HandleNotification from "../../../common/Notification";

const ItemCategorySequence = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [allItemCategories, setAllItemCategories] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllItemCategories = async (filterValues) => {
    setLoading(true);

    const query = `${GET_CATEGORY_ENDPOINT}`;
    const bodyData = {
      per_page: filterValues?.per_page || 100000,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setAllItemCategories(response?.data?.data?.data);
      setLoading(false);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Get all item categories on initial render
    getAllItemCategories();
  }, []);

  useEffect(() => {
    setLoading(true);

    // Set item categories array to dataSource
    if (isArrayAndHasValue(allItemCategories)) {
      const customData = allItemCategories?.map((item, index) => {
        return {
          ...item,
          key: item?.id,
        };
      });
      const sortedDataByPosition =
        isArrayAndHasValue(customData) &&
        customData?.sort((a, b) => a?.position - b?.position);

      setDataSource(sortedDataByPosition);
    }

    setLoading(false);

  }, [allItemCategories]);

  const onFinishSequence = async() => {
    const query = `${ITEM_CATEGORY_POSITION_FIX}`;
    const customItemCatData = isArrayAndHasValue(dataSource) && dataSource?.map((item, index) => {
      return {
        "id": item?.id,
        "position": item?.position >= 0 ? item?.position : index + 1,
        "usage": item?.usage,
      };
    });
    const bodyData = {
      items: isArrayAndHasValue(customItemCatData) ? customItemCatData : [],
    };

    const response = await postData(query, bodyData);

    if(response && response?.code === 200) {
      HandleNotification('success', 'bottomRight', 'Sequence Updated Successfully', null);
      getAllItemCategories();
    }
    else {
      HandleNotification('error', 'bottomRight', 'Something Went Wrong!', null) 
    }
  };

  const extra = (
    <Space>
      <Button
        type="primary"
        size="small"
        onClick={onFinishSequence}
        disabled={!hasPermission([ITEM_CATEGORY_EDIT_PERMISSION])}
      >
        {hasPermission([ITEM_CATEGORY_EDIT_PERMISSION])
          ? "Update Sequence"
          : "No Permission"}
      </Button>
    </Space>
  );

  const header = (
    <AppPageHeader
      extra={extra}
      title={<Title level={5}>Item Category Sequence</Title>}
    />
  );
  const content = (
    <ItemCatSequenceList
      dataSource={dataSource}
      setDataSource={setDataSource}
      loading={loading}
    />
  );

  return (
    <>
      <AppContent header={header} content={content} />
    </>
  );
};

export default ItemCategorySequence;
