import React, { useEffect, useRef, useState } from "react";
import Header from "../../Common/Header";
import { Card, Button, Affix } from "antd";
import { isMobileView } from "../../Common/AppHelper";
import { useNavigate, useParams } from "react-router-dom";
import AddStyleForm from "./AddStyleForm";
import { getData } from "../../../apiServices/common";
import { BUYER_INQUIRY_LIST_ENDPOINT } from "../../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../../common/Notification";

export default function AddStyleFromInquiry(props) {
  // Props
  const { width } = props;

  //   State
  const [inquiryInfo, setInquiryInfo] = useState(null);
  const [fileData, setFileData] = useState(null);

  //   Route
  const navigate = useNavigate();
  const { inquiryId } = useParams();

  //   Others
  const propertyFormRef = useRef(null);

  const getSelectedInquiryInfo = async (id) => {
    const query = `${BUYER_INQUIRY_LIST_ENDPOINT}/${id}/edit`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setInquiryInfo(response?.data?.data);
      setFileData(response?.data?.data?.documents);
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || "Failed to get inquiry data",
      );
    }
  };

  useEffect(() => {
    if (inquiryId) {
      getSelectedInquiryInfo(inquiryId);
    }
  }, [inquiryId]);

  const discartForm = () => {
    // propertyFormRef.current.discart();
    navigate("/merchandising/style");
  };

  const addStyle = () => {
    propertyFormRef.current.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Add Style"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => addStyle()}
                >
                  Add Style
                </Button>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      <AddStyleForm
        ref={propertyFormRef}
        inquiryInfo={inquiryInfo}
        fileData={fileData}
      />
    </>
  );
}
