import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { postData } from "components/BOM/AddOrUpdateStyle";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  cglFormatDate,
  hasPermission,
  isArrayAndHasValue,
} from "utils/functions";
import {
  DIGITALIZATION_OPERATOR_EFFICIENCY_EXPORT_REPORT,
  DIGITALIZATION_OPERATOR_WISE_HOURLY_PRODUCTION_PREVIEW,
  DIGITALIZATION_OPERATOR_WISE_HOURLY_PRODUCTION_REPORT,
  GET_HOURLY_PRODUCTION_RECORDS,
  SEWING_LINES,
  VIEW_POST_COSTING_REPORT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import { NO_PERMISSION_REQUIRED } from "../../../routes/permissions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";

const OperatorHourlyProduction = () => {
  // Router
  const location = useLocation();
  const navigate = useNavigate();
  const routerPath = location ? location.pathname : null;

  // States
  const [sewingList, setSewingList] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [fullDataset, setFullDataset] = useState([]);
  const [activeFilters, setActiveFilters] = useState({});
  // Antd
  const { Title } = Typography;
  const [form] = Form.useForm();
  const fromDateWatcher = Form.useWatch("from_date", form);
  const toDateWatcher = Form.useWatch("to_date", form);
  const lineIdWatcher = Form.useWatch("line", form);

  const getAllList = useCallback(async (filterValues) => {
    console.log("filterValues:: ", filterValues, showFilter);

    setTableDataLoading(true);
    const query = `${GET_HOURLY_PRODUCTION_RECORDS}`;

    const response = await getData(query, false, filterValues);
    if (response?.status === 200) {
      setTableDataLoading(false);

      setTableData([]);
    } else {
      setTableDataLoading(false);
      message.error("Something went wrong! Please try again.");
    }
  }, []);

  const getSewingList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SEWING_LINES, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSewingList(response?.data?.data?.data || []);
    }
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  // Effects
  useEffect(() => {
    getAllList();
    getSewingList();
  }, [refresh, getAllList]);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const tableColumns = [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "sl",
      width: 50,
      render: (_, __, index) => index + 1,
    },
    {
      title: "Checked Date",
      dataIndex: "checked_date",
      key: "checked_date",
      width: 120,
      render: (value) => <span>{value ? cglFormatDate(value) : "N/A"}</span>,
    },
    {
      title: "Line",
      dataIndex: ["line", "name"],
      key: "line_name",
      width: 96,
    },
    {
      title: "Operation Name",
      dataIndex: "operation_name",
      key: "operation_name",
      width: 150,
    },
    {
      title: "Hourly Target",
      dataIndex: "hourly_target",
      key: "hourly_target",
      align: "right",
      width: 120,
    },
    {
      title: "Previous Hour",
      dataIndex: "previous_hour",
      key: "previous_hour",
      align: "right",
      width: 120,
    },
    {
      title: "Current Hour",
      dataIndex: "current_hour",
      key: "current_hour",
      align: "right",
      width: 120,
    },
    {
      title: "Previous Hour Efficiency",
      dataIndex: "previous_hour_efficiency",
      key: "previous_hour_efficiency",
      align: "right",
      width: 180,
    },
    {
      title: "Current Hour Efficiency",
      dataIndex: "current_hour_efficiency",
      key: "current_hour_efficiency",
      align: "right",
      width: 180,
    },
    {
      title: "Reason for Efficiency Alert",
      dataIndex: "efficiency_alert_reason",
      key: "efficiency_alert_reason",
      width: 200,
    },
    {
      title: "Action Taken",
      dataIndex: "action_taken",
      key: "action_taken",
      width: 150,
    },
    {
      title: "Results",
      dataIndex: "results",
      key: "results",
      width: 150,
    },
  ];

  const exportDetails = async (filterValues) => {
    setLoading(true);
    const query = `${DIGITALIZATION_OPERATOR_WISE_HOURLY_PRODUCTION_REPORT}`;
    const bodyData = {
      ...filterValues,
    };
    const response = await getData(
      query,
      false,
      bodyData,
      { "Content-Type": "blob" },
      "arraybuffer",
    );

    if (response && response?.data) {
      // Download the file
      const outputFilename = `operator-wise-houyly-production.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
      message.success(
        "Operator-wise Hourly Production Report Exported Successfully",
      );
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  // transform
  const transformData = (data) => {
    return data.map((item, index) => ({
      key: index,
      ...(item.SL && { SL: parseInt(item.SL, 10) || item.SL }),
      ...item,
    }));
  };

  
  const getTableColumns = (headers) => {
    return headers.map((header) => ({
      title: header,
      dataIndex: header,
      key: header,
      width:
        header === "OPERATION"
          ? 200
          : header === "NAME"
          ? 150
          : header === "SUPERVISOR&LINE CHIEF NAME"
          ? 170
          : header === "SECTION"
          ? 130
          : 100,
    }));
  };

  // Update the preview function to process the response
  const previewFunction = async (filterValues) => {
    setPreviewLoading(true);
    setCurrentPage(1);

    // Store active filters for pagination
    setActiveFilters(filterValues);

    const bodyData = {
      ...filterValues,
    };

    const query = `${DIGITALIZATION_OPERATOR_WISE_HOURLY_PRODUCTION_PREVIEW}`;
    const response = await getData(query, false, bodyData);

    if (response?.data?.code === 200) {
      const columns = getTableColumns(response.data.data.tableHeader || []);
      let data = transformData(response.data.data.table_data || []);

      // Sort the data numerically
      if (data.length > 0 && data[0].hasOwnProperty("SL")) {
        data = data.sort((a, b) => {
          const aValue = parseInt(a.SL, 10) || 0;
          const bValue = parseInt(b.SL, 10) || 0;
          return aValue - bValue;
        });
      }
      setFullDataset(data);

      setTableData({
        columns,
        data: data.slice(0, pageSize),
        total: data.length,
        per_page: pageSize,
      });

      setPreviewLoading(false);
    } else {
      setPreviewLoading(false);
      message.error("Something went wrong");
    }
  };

  
  const paginate = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setTableData((prevData) => ({
      ...prevData,
      data: fullDataset.slice(startIndex, endIndex),
    }));
  };

  const handleClearFilters = () => {
    form.resetFields();
    setCurrentPage(1);
    setActiveFilters({});
    setFullDataset([]);
    setTableData(null);
    refetch();
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                hideAddButton={true}
                disabled={!hasPermission([NO_PERMISSION_REQUIRED])}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            }
            title={<Title level={5}>Operator-wise Hourly Production</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10, padding: 10 }}>
              <Form layout="vertical" form={form}>
                <Row gutter={24} align={"bottom"}>
                  <Col span={4}>
                    <Form.Item
                      label="From Date"
                      name="from_date"
                      rules={[
                        { required: true, message: "From Date is required!" },
                      ]}
                    >
                      <DatePicker
                        placeholder="From Date"
                        format={"DD-MMM-YY"}
                        size="small"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      label="To Date"
                      name="to_date"
                      rules={[
                        { required: true, message: "To Date is required!" },
                      ]}
                    >
                      <DatePicker
                        placeholder="To Date"
                        format={"DD-MMM-YY"}
                        size="small"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      label="Line"
                      name="line"
                      rules={[
                        { required: false, message: "Line is required!" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a Line"
                        onSearch={(value) => {
                          getSewingList({ name: value });
                        }}
                        optionFilterProp="children"
                        size="small"
                        filterOption={false}
                        allowClear
                      >
                        {isArrayAndHasValue(sewingList) &&
                          sewingList.map((sup) => (
                            <Select.Option value={sup.name} key={sup.id}>
                              {sup.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Space>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={async () => {
                            try {
                              const values = await form.validateFields();
                              await exportDetails({
                                ...(values.from_date && {
                                  from_date: moment(values.from_date).format(
                                    "YYYY-MM-DD",
                                  ),
                                }),
                                ...(values.to_date && {
                                  to_date: moment(values.to_date).format(
                                    "YYYY-MM-DD",
                                  ),
                                }),
                                ...(values.line && { line: values.line }),
                              });
                            } catch (error) {
                              console.log("Form validation failed:", error);
                            }
                          }}
                          loading={loading}
                          icon={<DownloadOutlined />}
                        >
                          Export
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          onClick={async () => {
                            await previewFunction({
                              ...(fromDateWatcher && {
                                from_date:
                                  moment(fromDateWatcher).format("YYYY-MM-DD"),
                              }),
                              ...(toDateWatcher && {
                                to_date:
                                  moment(toDateWatcher).format("YYYY-MM-DD"),
                              }),
                              ...(lineIdWatcher && { line: lineIdWatcher }),
                            });
                          }}
                          icon={<EyeOutlined />}
                          loading={previewLoading}
                        >
                          Preview
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button onClick={() => handleClearFilters()}>
                          Clear
                        </Button>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Form>

              {tableData && tableData.data && (
                <Table
                  dataSource={tableData.data}
                  columns={tableData.columns || tableColumns}
                  bordered
                  loading={tableDataLoading || previewLoading}
                  pagination={{
                    showSizeChanger: false    ,
                    pageSize: pageSize,
                    current: currentPage,
                    onChange: (page, newPageSize) => {
                      if (newPageSize !== pageSize) {
                        setPageSize(newPageSize);
                        const startIndex = 0;
                        const endIndex = newPageSize;
                        setCurrentPage(1);
                        setTableData((prevData) => ({
                          ...prevData,
                          data: fullDataset.slice(startIndex, endIndex),
                          per_page: newPageSize,
                        }));
                      } else {
                        paginate(page);
                      }
                    },
                    total: fullDataset.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  // Enable default sorting
                  // sortDirections={["ascend", "descend"]}
                  scroll={{ x: true }}
                />
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default OperatorHourlyProduction;
