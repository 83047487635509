import React, { Fragment, useState } from "react";
import { Card, Button, Upload, Modal, Input, message } from "antd";
import {
  DownloadOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { postData } from "../../apiServices/common";
// import { FILE_UPLOAD } from "../../Scripts/api";

const { confirm } = Modal;

export default function DocumentUpload2({
  DOC_ADD,
  filesList,
  setFileList,
  view,
}) {
  const [loading, setLoading] = useState(false);

  //console.log('filesList', filesList);
  
  
  // const base_url = process.env.REACT_APP_BASE;
  const api_base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  const props = {
    action: api_base_url + DOC_ADD,
    accept: "image/*,.pdf, .doc, .xlsx",
    headers: {
      authorization: `Bearer ${token}`,
    },
    showUploadList: false,
    onChange(info) {
      console.log("info ", info?.file?.status);
      if (info.file.status !== "uploading") {
        console.log("uploading file");
      }
      if (info.file.status === "done" && info?.file?.response.code == 200) {
        console.log("list ", info?.file?.response?.data?.id);
        let content = {
          id: info?.file?.response?.data?.id,
          name: info?.file?.response?.data?.document_name,
          path: info?.file?.response?.data?.document,
        };
        setFileList((oldArray) => [...oldArray, content]);

        message.success(
          `${info?.file?.response?.data?.document_name} file uploaded successfully`
        );
      } else if (
        info.file.status === "done" &&
        info?.file?.response.code !== 200
      ) {
        message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const removeFile = (index) => {
    confirm({
      title: "Do you Want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        let items = filesList;
        items.splice(index, 1);
        setFileList([...items]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onChangeHandel = (val, index, context) => {
    filesList[index][context] = val;
    setFileList([...filesList]);
  };

  //console.log('api_base_url', api_base_url);


  return (
    <Card className="mt-3 custome-table" title="Upload Payment Voucher">
      <div className="root-table">
        <table>
          <thead>
            <tr>
              <th className="t-header bc-java">Serial</th>
              <th className="t-header bc-java">Document Name</th>
              <th className="t-header bc-java">File</th>
              <th className="t-header bc-java"></th>
            </tr>
          </thead>
          <tbody>
            {filesList?.length ? (
              <>
                {filesList.map((file, index) => (
                  <Fragment key={index}>
                    <tr>
                      <td className="t-body">{index + 1}</td>
                      <td className="t-body">{file.name}</td>
                      <td className="t-body">
                        <Button
                          type="text"
                          onClick={() =>
                            window.open(
                              api_base_url + "/" + file.path,
                              "_blank"
                            )
                          }
                        >
                          <FilePdfOutlined />
                        </Button>
                      </td>
                      {!view && (
                        <td className="t-body">
                          <DeleteOutlined
                            style={{ fontSize: "20px", color: "red" }}
                            className="cursore-pointer"
                            onClick={() => removeFile(index)}
                          />
                        </td>
                      )}
                    </tr>
                  </Fragment>
                ))}
              </>
            ) : (
              ""
            )}

            <tr>
              <td className="t-body"></td>
              <td className="t-body"></td>
              <td className="t-body"></td>
              <td className="t-body">
                {!view && (
                  <Upload {...props}>
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      loading={loading}
                    >
                      Upload
                    </Button>
                  </Upload>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
  );
}
