import {
  Card,
  Select,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
} from "antd";
import React, { Fragment, useState, useEffect, useMemo } from "react";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MoreOutlined,
  EditOutlined,
  DeleteTwoTone,
  EyeOutlined,
} from "@ant-design/icons";
import {
  GET_SUBMISSION_REALIZATION_LIST,
  DELETE_SUBMISSION_REALIZATION,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { alertPop } from "../../../apiServices/common/helper";
import { hasPermission } from "../../../utils/functions";
import {
  SUBMISSION_REALIZATION_CREATE_PERMISSION,
  SUBMISSION_REALIZATION_EDIT_PERMISSION,
  SUBMISSION_REALIZATION_DELETE_PERMISSION,
  SUBMISSION_REALIZATION_VIEW_PERMISSION,
} from "../../../routes/permissions";

export default function SubmissionRealization(props) {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  //   States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [submissionRealizationList, setSubmissionRealizationList] = useState(
    [],
  );
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValues, setSearchValues] = useState();
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const openForView = (id) => {
    navigate(`/export/view-submission-realization/${id}`);
  };

  const columns = [
    {
      title: "Sales Contract Number",
      dataIndex: ["reference_no"],
      key: "reference_no",
    },
    {
      title: "Total Invoice Value",
      dataIndex: ["total_invoice_value"],
      key: "total_invoice_value",
    },
    {
      title: "Total Real Value",
      dataIndex: ["total_real_value"],
      key: "total_real_value",
    },
    {
      title: "Total Short Value",
      dataIndex: ["total_short_value"],
      key: "total_short_value",
    },
    {
      title: "Short/Excess Shipment Qty",
      key: "excess_shipment_quantity",
      render: (value, record) => {
        const convertedScQty = record?.sc_qty ? Number(record?.sc_qty) : 0;
        const convertedShipmentQty = record?.shipment_qty
          ? Number(record?.shipment_qty)
          : 0;
        const result = convertedScQty - convertedShipmentQty;
        return result ? result.toFixed(2) : "";
      },
    },
    {
      title: "Short/Excess Shipment Value",
      key: "excess_shipment_value",
      render: (value, record) => {
        const convertedScValue = record?.sc_value
          ? Number(record?.sc_value)
          : 0;
        const convertedShipmentValue = record?.shipment_value
          ? Number(record?.shipment_value)
          : 0;
        const result = convertedScValue - convertedShipmentValue;
        return result ? result.toFixed(2) : "";
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllSubmissionDetails = async (passedObject) => {
    const filterValues = {
      ...passedObject,
      per_page: passedObject?.per_page || 10,
    };

    const response = await getData(
      GET_SUBMISSION_REALIZATION_LIST,
      false,
      filterValues,
    );

    if (response && response?.data?.code === 200) {
      const masterData = response?.data;
      setSubmissionRealizationList(masterData?.data?.data || []);
      setTotalData(masterData?.data?.total);
      setRangeData(masterData?.data?.per_page);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllSubmissionDetails({ page: currentPage });
  }, [currentPage, searchValues]);

  const removeSubmissionRealization = async (value) => {
    const response = await deleteData(
      `${DELETE_SUBMISSION_REALIZATION}/${value}`,
    );

    if (response && response?.code === 200) {
      alertPop(
        "success",
        response?.messages ? response?.messages : "Deleted Successfully!",
      );
      getAllSubmissionDetails();
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/export/edit-submission-realization/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([SUBMISSION_REALIZATION_EDIT_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeSubmissionRealization(data.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: "0",
          disabled: !hasPermission([SUBMISSION_REALIZATION_DELETE_PERMISSION]),
        },
        {
          label: (
            <>
              <span>
                <EyeOutlined />
              </span>
              <span
                type="button"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  openForView(data?.id);
                }}
              >
                View
              </span>
            </>
          ),
          key: "3",
          disabled: !hasPermission([SUBMISSION_REALIZATION_VIEW_PERMISSION]),
        },
      ]}
    />
  );
  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const memoizedCustomFilter = useMemo(
    () => (
      <CustomFilter
        showFilter={showFilter}
        tableData={submissionRealizationList}
        fetchTableData={getAllSubmissionDetails}
        filterName="submission_realization"
      />
    ),
    [showFilter],
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Export Submission & Realization"
            subTitle=""
            actions={[
              <Button
                type={showFilter ? "danger" : "primary"}
                size="small"
                onClick={handleFilter}
                style={{ float: "right" }}
                disabled
              >
                {`${showFilter ? "Close" : "Open"} Filter Option`}
              </Button>,
              <Button
                key="3"
                size="small"
                type="primary"
                disabled={
                  !hasPermission([SUBMISSION_REALIZATION_CREATE_PERMISSION])
                }
              >
                <Link to="/export/add-submission-realization">
                  {hasPermission([SUBMISSION_REALIZATION_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"}
                </Link>
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader />
          {memoizedCustomFilter}
          <Table
            loading={loading}
            dataSource={submissionRealizationList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}
