import { useCallback, useEffect, useState } from "react";
import { message, Modal, Table, Tag, Typography } from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import {
  getData,
  postData,
  deleteData,
  putData,
} from "../../apiServices/common";
import {
  GET_ALL_ACTIVITY_LOGS,
  ACTIVITY_LOG_REPORT_EXPORT,
  ACTIVITY_LOG_REPORT_LIST,
} from "../../apiServices/API_ENDPOINTS";
import { hasPermission } from "../../utils/functions";
import { ACTIVITY_LOG_VIEW_PERMISSION } from "../../routes/permissions";
import moment from "moment";
import ExportActivity from "./ExportActivity";
import { alertPop } from "../../apiServices/common/helper";
import { useLocation } from "react-router";

const ActivityLog = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportTableData, setReportTableData] = useState(null);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllLogs = useCallback(
    async (filterValues) => {
      const { start, end, action, movement_type, acted_by, ui_model_name } =
        filterValues || {};

      setLoading(true);

      const query = `${GET_ALL_ACTIVITY_LOGS}?page=${currentPage}`;
      const bodyData = {
        per_page: 10,
        ...(start && { start: start }),
        ...(end && { end: end }),
        ...(action && { action: action }),
        ...(movement_type && { movement_type: movement_type }),
        ...(acted_by && { acted_by: acted_by }),
        ...(ui_model_name && { ui_model_name: ui_model_name }),
      };

      const response = await getData(query, false, bodyData);

      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting activity log data");
      }
    },
    [currentPage],
  );

  const getActivityReportList = async () => {
    const response = await getData(ACTIVITY_LOG_REPORT_LIST);

    if (response && response?.status === 200) {
      setReportTableData(response?.data?.data);
      onModalOpen();
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  const exportActivityLog = async () => {
    const filterValues = JSON.parse(
      localStorage.getItem("activity_log_filter"),
    );
    const bodyData = filterValues;
    const response = await getData(ACTIVITY_LOG_REPORT_EXPORT, false, bodyData);

    if (response && response?.status === 200) {
      getActivityReportList();
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  // Effects
  useEffect(() => {
    getAllLogs();
  }, [refresh, getAllLogs]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Log Time",
      dataIndex: ["created_at"],
      key: "log_time",
      render: (text) => {
        return moment().format("hh:mm:ss");
      },
    },
    {
      title: "Log Date",
      dataIndex: ["created_at"],
      key: "log_date",
      render: (text) => {
        return moment().format("DD-MMM-YYYY");
      },
    },
    {
      title: "Basic Info",
      dataIndex: ["basic_info"],
      key: "basic_info",
      render: (text) => {
        if (!text) {
          return null;
        }

        return (
          <div>
            {Object?.keys(text)?.map((key) => {
              if (text[key] && key !== "password") {
                return (
                  <div key={key}>
                    {key}: {text[key]}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      },
    },
    {
      title: "Movement Type",
      dataIndex: ["basic_info", "movement_type"],
      key: "movement_type",
    },
    {
      title: "Previous Info",
      dataIndex: ["properties", "old"],
      key: "previous_info",
      render: (text) => {
        if (!text) {
          return null;
        }

        return (
          <div>
            {Object?.keys(text)?.map((key) => {
              if (text[key] && key !== "password") {
                return (
                  <div key={key}>
                    {key}: {text[key]}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      },
    },
    {
      title: "New Info",
      dataIndex: ["properties", "attributes"],
      key: "new_info",
      render: (text) => {
        if (!text) {
          return null;
        }

        return (
          <div>
            {Object?.keys(text)?.map((key) => {
              if (text[key] && key !== "password") {
                return (
                  <div key={key}>
                    {key}: {text[key]}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: ["event"],
      key: "event",
      render: (item, record) => {
        return <div>{item === "created" ? record?.causer_by : null}</div>;
      },
    },
    {
      title: "Updated By",
      dataIndex: ["event"],
      key: "event",
      render: (item, record) => {
        return <div>{item === "updated" ? record?.causer_by : null}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: ["event"],
      key: "event",
      render: (item) => {
        switch (item) {
          case "created":
            return <Tag color="green">Created</Tag>;
          case "updated":
            return <Tag color="cyan">Updated</Tag>;
          case "deleted":
            return <Tag color="magenta">Deleted</Tag>;
          default:
            return null;
        }
      },
    },
  ];

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportActivityLog}
                disabled={!hasPermission([ACTIVITY_LOG_VIEW_PERMISSION])}
                addButtonText={
                  hasPermission([ACTIVITY_LOG_VIEW_PERMISSION])
                    ? "Export"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>All Activities</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <FilterComponent
                tableData={tableData}
                filterAPI={getAllLogs}
                filterName={"activity_log"}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: 10,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />

      <Modal
        open={isModalOpen}
        title={`Export Activities`}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <ExportActivity
          reportTableData={reportTableData}
          getActivityReportList={getActivityReportList}
        />
      </Modal>
    </>
  );
};

export default ActivityLog;
