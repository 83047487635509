import { Typography, PageHeader, Affix, Card } from "antd";

export default function Header(props) {
  // Props
  const { title, subTitle, routes, actions } = props;

  // Antd
  const { Title } = Typography;

  return (
    <Affix>
      <PageHeader
        title={<Title level={5}>{title}</Title>}
        extra={actions}
        breadcrumb={routes}
      />
    </Affix>
  );
}
