import {
    GET_BANK_ACCOUNT_LIST,
    CREATE_NEW_BANK_ACCOUNT,
    DELETE_BANK_ACCOUNT,
    GET_BANK_ACCOUNT_DETAIL, UPDATE_BANK_ACCOUNT_DETAIL
} from '../ActionTypes';

const initialState = {
    bankAccountList: [],
    totalBankAccounts: null,
    selectedBankAccount: null,
    getBankAccountDetail: null,
    createdBankAccount: null,
    pageSize: 10,
    page:1,
};

const BankAccountReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_BANK_ACCOUNT_LIST:
            return {
                ...state,
                bankAccountList: action.payload.data,
                totalBankAccounts: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_BANK_ACCOUNT:
            return {
                ...state,
                bankAccountList: [action.payload.data, ...state.bankAccountList],
                totalBankAccounts: state.totalBankAccounts + 1,
                createdBankAccount: action.payload.data
            };


        case DELETE_BANK_ACCOUNT: {
            return {
                ...state,
                bankAccountList: action.payload.list,
                totalBankAccounts: action.payload.total,
            };
        }

        case GET_BANK_ACCOUNT_DETAIL:
            return {
                ...state,
                selectedBankAccount: action.payload,
            };

        case UPDATE_BANK_ACCOUNT_DETAIL:
            return {
                ...state,
                selectedBankAccount: action.payload,
                bankAccountList: state.bankAccountList.map((bankAccount) =>
                    bankAccount.id === action.payload.id ? action.payload : bankAccount,
                ),
            };

        default:
            return state;
    }
};

export default BankAccountReducer;
