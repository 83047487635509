import { Button, Drawer, Space, Table, message } from "antd";
import { ASSIGNMENT_LIST_ENDPOINT } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { AssignmentContext } from "context/AssignmentContext/AssignmentContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ASSIGNMENT_CREATE_PERMISSION } from "routes/permissions";
import { cglFormatDate, hasPermission, isArrayAndHasValue } from "utils/functions";
import AssignmentForm from "./AssignmentForm";
import style from "../production.module.css"
import moment from "moment";

const Assignment = () => {
  // Contexts
  const { onSubmitAssignment, onAssignOpen, isSubmitting, setIsSubmitting } =
    useContext(AssignmentContext);

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDrawer, setOpenDrawer] = useState(false);

  // Others
  const navigate = useNavigate();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllAssignment = useCallback(
    async (filterValues) => {
      setLoading(true);

      const query = `${ASSIGNMENT_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...filterValues,
        per_page: filterValues?.per_page || 10,
        assignment_date: filterValues?.assignment_date ? moment(filterValues?.assignment_date).format('YYYY-MM-DD') : null,
      };

      const response = await getData(query, false, bodyData);
      if (response && response?.data?.code === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting cutting data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    const existingFilter =
      JSON.parse(localStorage.getItem("assignment_filter_filter_value")) || {};
    
    getAllAssignment(existingFilter);
  }, [refresh, getAllAssignment]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const gotoAddRoute = () => {
    navigate("/production/assignment/entry");
  };

  const tableColumns = [
    {
      title: "Style",
      dataIndex: ["styles", "style_no"],
      key: "style_no",
    },
    {
      title: "Buyer",
      dataIndex: ["buyer_name"],
      key: "buyer_name",
    },
    {
      title: "Assigned Date",
      dataIndex: ["assigned_date"],
      key: "assigned_date",
      render: (value) => cglFormatDate(value),
    },
    {
      title: "Assigned Qty",
      dataIndex: ["assigned_qty"],
      key: "assigned_qty",
    },
    {
      title: "Line No.",
      dataIndex: ["line_name"],
      key: "line_name",
    },
    {
      title: "Trans. no. per day",
      dataIndex: ["transaction_no_per_day"],
      key: "transaction_no_per_day",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Button
            onClick={() => onOpenDrawer(record)}
            type="primary"
            size="small"
          >
            Update
          </Button>
        );
      },
    },
  ];

  const onDrawerClose = () => {
    setOpenDrawer(false);
  };

  const onOpenDrawer = (data) => {
    const {
      assigned_date,
      buying_office_id,
      sales_contract_id,
      style_id,
      po_map_id,
      internal_po,
      line_id,
      transaction_no_per_day,
    } = data;

    onAssignOpen(
      {
        buying_office_id: buying_office_id,
        sales_contract_id: sales_contract_id,
        style_id: style_id,
        transaction_no_per_day: transaction_no_per_day,
        po_map_id: po_map_id,
        internal_po: internal_po,
        line_id: line_id,
      },
      { assignment_date: assigned_date },
      true,
    );
    setIsSubmitting(false);
    setOpenDrawer(true);
  };

  const assignedQty = () => {
    if(isArrayAndHasValue(tableData)) {
      const result = tableData.reduce((acc, curr) => acc + curr?.assigned_qty, 0);
      return result || 0;
    }
  }

  const tableTitleContent = (<div className={style?.titleHeader}>
    <div className={style?.totalContainer}>
      <div className={style.totalItem}><span className={style.totalLabel}>Total Assigned: </span> {assignedQty()} </div>
    </div>
  </div>)

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={gotoAddRoute}
                disabled={!hasPermission([ASSIGNMENT_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([ASSIGNMENT_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title="Assignment"
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllAssignment}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="assignment_filter"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                title={()=>tableTitleContent}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
                sticky={true}
              />
            </div>
          </>
        }
      />
      {/* Assignment Update by opening drawer */}
      <Drawer
        title={"Assignment"}
        placement="right"
        onClose={onDrawerClose}
        open={openDrawer}
        width={900}
        extra={
          <Space>
            <Button
              onClick={() => onSubmitAssignment(true)}
              type="primary"
              size="small"
              // loading={isSubmitting}
              disabled={isSubmitting}
            >
              Update
            </Button>
          </Space>
        }
      >
        <AssignmentForm isEdit={true} />
      </Drawer>
    </>
  );
};

export default Assignment;
