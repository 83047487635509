import React, { useEffect, useState } from "react";
import { Button, Dropdown, Menu, Popconfirm, Space, Typography } from "antd";
import AppPageHeader from "../../common/PageHeader";
import AppContent from "../../common/AppContent";
import BuyingOfficeListTable from "./BuyingOfficeListTable";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  onDeleteBuyingOffice,
  onGetBuyingOfficeList,
} from "../../redux/actions";
import { useLocation, useNavigate } from "react-router";
import { hasPermission } from "../../utils/functions";
import {
  BUYING_OFFICE_CREATE_PERMISSION,
  BUYING_OFFICE_EDIT_PERMISSION,
  BUYING_OFFICE_DELETE_PERMISSION,
} from "../../routes/permissions";

const BuyingOfficeList = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(0);

  // Redux
  const dispatch = useDispatch();
  const pageSize = useSelector(({ buyingOffice }) => buyingOffice.pageSize);
  const buyingOfficeList = useSelector(
    ({ buyingOffice }) => buyingOffice.buyingOfficeList,
  );
  const loading = useSelector(({ common }) => common.loading);
  const total = useSelector(
    ({ buyingOffice }) => buyingOffice.totalBuyingOffices,
  );
  const filterData = useSelector(({filter}) => filter.filterData);
  
  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = (record) => {
    dispatch(onDeleteBuyingOffice(record));
    refetch();
  };

  const handleEdit = (event, record) => {
    navigate(`/configuration/buying-office/edit/${record?.id}`);
  };

  const tableColumns = [
    { title: "Buying Office Name", dataIndex: "name", key: "name" },
    { title: "Short Name", dataIndex: "short_name", key: "short_name" },
    {
      title: "Contact Person",
      dataIndex: "contract_person",
      key: "contract_person",
    },
    { title: "Email", dataIndex: "email", key: "email" },

    {
      title: "Action",
      width: '10%',
      key: "action",
      render: (_, record) => (
        <>
          {buyingOfficeList.length >= 1 ? (
            <Dropdown.Button
              overlay={
                <Menu
                  title="action"
                  mode="horizontal"
                  items={[
                    {
                      label: (
                        <Popconfirm
                          title="Are you sure you want to delete?"
                          onConfirm={() => handleDelete(record)}
                        >
                          <span>
                            <DeleteTwoTone />
                          </span>
                          <span style={{ color: "red", marginLeft: 5 }}>Delete</span>
                        </Popconfirm>
                      ),
                      key: "1",
                      disabled: !hasPermission([BUYING_OFFICE_DELETE_PERMISSION])
                    },
                    {
                      label: (
                        <>
                          <span>
                            <EditTwoTone />
                          </span>
                          <span
                            type="button"
                            style={{ marginLeft: 5 }}
                            onClick={(event) => {
                              // dispatch(onGetBuyingOfficeDetails(record.id));
                              // setSelectedBuyingOffice(record);
                              handleEdit(event, record);
                            }}
                          >
                            Edit
                          </span>
                        </>
                      ),
                      key: "2",
                      disabled: !hasPermission([BUYING_OFFICE_EDIT_PERMISSION])
                    },
                  ]}
                />
              }
            ></Dropdown.Button>
          ) : null}
        </>
      ),
    },
  ];
  const navigate = useNavigate();

  const handleNav = (event, record) => {
    navigate("/configuration/buying-office/create-buying-office");
  };

  useEffect(() => {
    dispatch(onGetBuyingOfficeList({
        page: currentPage,
        per_page: 10,
        ...filterData,
    }));
  }, [refresh, currentPage, filterData, dispatch]);

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        type="primary"
        size="small"
        onClick={handleNav}
        disabled={!hasPermission([BUYING_OFFICE_CREATE_PERMISSION])}
      >
        {hasPermission([BUYING_OFFICE_CREATE_PERMISSION])
          ? "Add New"
          : "No Permission"}
      </Button>
    </Space>
  );

  const header = <AppPageHeader extra={extra} title={<Title level={5}>Buying Office List</Title>} />;
  
  const content = (
    <BuyingOfficeListTable
      loading={loading}
      onChange={(newPage) => {
        onPageChange(newPage);
      }}
      total={total}
      current={currentPage}
      defaultCurrent={currentPage}
      pageSize={pageSize}
      dataSource={buyingOfficeList}
      tableColumns={tableColumns}
      pagination={true}
      filter={true}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
    />
  );


  return (
    <>
      <AppContent header={header} content={content} />
    </>
  );
};

export default BuyingOfficeList;
