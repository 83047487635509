import { Col, Form, Row, Select } from "antd";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  SALES_CONTRACT_LIST,
  STYLE_MERCHANDISING_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";

const BOMFilterFields = (props) => {
  // Props
  const { initialTableData } = props;

  // States
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [salseCotractList, setSalseCotractList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [styleList, setStyleList] = useState([]);

  // Others
  const { Option } = Select;

  useEffect(() => {
    getBuyingOfficeList();
    getSalseCotractList();
    getBuyerList();
    getStyleList();
  }, []);


  const getBuyingOfficeList = async (filterValues) => {
    const query = `${GET_BUYING_OFFICE_ENDPOINT}`;
    const bodyData = {
      "per_page": 10,
      "name": filterValues?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBuyingOffice(res?.data?.data?.data || []);
    }
  };

  const getSalseCotractList = async (filterValues) => {
    const query = `${SALES_CONTRACT_LIST}`;
    const bodyData = {
      "per_page": 10,
      "reference_no": filterValues?.reference_no || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setSalseCotractList(res?.data?.data?.data || []);
    }
  };

  const getBuyerList = async (filterValues) => {
    const query = `${GET_BUYER_ENDPOINT}`;
    const bodyData = {
      "per_page": 10,
      "name": filterValues?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBuyerList(res?.data?.data?.data || []);
    }
  };

  const getStyleList = async (style_no) => {
    const bodyData = {
      "style_no": style_no,
    }

    let res = await getData(STYLE_MERCHANDISING_LIST, false, bodyData);

    if (res) {
      setStyleList(res?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item label="Buying Office" name="buying_office_id">
          <Select
            showSearch
            placeholder="Select a Buying Office"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getBuyingOfficeList({ name: value })}
            allowClear
            onClear={() => getBuyingOfficeList()}
          >
            {buyingOffice?.length &&
              buyingOffice.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Sales Contract No" name="sales_contract_id">
          <Select
            showSearch
            placeholder="Select a Sales Contract No"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getSalseCotractList({ reference_no: value })}
            allowClear
            onClear={() => getSalseCotractList()}
          >
            {salseCotractList?.length &&
              salseCotractList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.reference_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            placeholder="Select a Buyer"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getBuyerList({ name: value })}
            allowClear
            onClear={() => getBuyerList()}
          >
            {buyerList?.length &&
              buyerList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Style No." name="style_id">
          <Select
            showSearch
            placeholder="Select a Style No."
            optionFilterProp="children"
            onSearch={(value) => {
              getStyleList(value);
            }}
            size="small"
            allowClear
            onClear={() => getStyleList()}
          >
            {styleList &&
              styleList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.style_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default BOMFilterFields;
