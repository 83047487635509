import {
  EditOutlined,
  ExportOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  Affix,
  Button,
  Card,
  Dropdown,
  Menu,
  message,
  Space,
  Table,
} from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  EXPORT_BOM_ENDPOINT,
  GET_BOM_ENDPOINT_LIST,
} from "../../apiServices/API_ENDPOINTS";
import { getData } from "../../apiServices/common";
import {
  BOM_CREATE_PERMISSION,
  BOM_EDIT_PERMISSION,
  BOM_EXPORT_PERMISSION,
  BOM_VIEW_PERMISSION,
} from "../../routes/permissions";
import { hasPermission } from "../../utils/functions";
import { isMobileView } from "../Common/AppHelper";
import CustomFilter from "../Common/CustomFilter";
import Header from "../Common/Header";

const BOM = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Others
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  // const onExportBOM = async (bom_id) => {
  //   const bodyData = {
  //     id: bom_id,
  //   };

  //   let response = await getData(EXPORT_BOM_ENDPOINT, false, bodyData);

  //   if (response && response?.status === 200) {
  //     const outputFilename = `bom.csv`;

  //     // If you want to download file automatically using link attribute.
  //     const url = URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", outputFilename);
  //     document.body.appendChild(link);
  //     link.click();
  //   } else {
  //     message.error("Error exporting BOM");
  //   }
  // };

  const columns = [
    {
      title: "Buying Office Name",
      dataIndex: ["buying_office_info", "name"],
      key: "buying_office_name",
    },
    {
      title: "Buyer Name",
      dataIndex: ["buyer_info", "name"],
      key: "buyer_name",
    },
    {
      title: "Brand Name",
      dataIndex: ["brand_info", "name"],
      key: "brand_name",
    },
    {
      title: "Factory Name",
      dataIndex: ["sub_contract_factory", "name"],
      key: "factory_name",
    },
    {
      title: "Sales Contract/Sub-Contract Agg.",
      dataIndex: ["sales_contract_info", "reference_no"],
      key: "reference_no",
    },
    {
      title: "Style Number",
      dataIndex: ["style_info", "style_no"],
      key: "style_no",
    },
    {
      title: "Actions",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllPermitList = useCallback(
    async (passedObject) => {
      setLoading(true);

      const filter = passedObject;
      const pageQuery = `${filter?.page ? "" : `?page=${currentPage}`}`;
      const customQuery = `${GET_BOM_ENDPOINT_LIST}/${pageQuery}`;
      const res = await getData(customQuery, false, filter);

      if (res) {
        let masterData = res?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      setLoading(false);
    },
    [currentPage],
  );

  useEffect(() => {
    // Get BOM list on first render and on page change
    getAllPermitList(filterData);
  }, [currentPage, getAllPermitList, filterData]);

  const menu = (data) => {
    return (
      <Menu
        items={[
          {
            label: (
              <Link to={`/merchandising/update-BOM/${data.id}`}>
                <EditOutlined /> Edit
              </Link>
            ),
            key: "0",
            disabled: !hasPermission([BOM_EDIT_PERMISSION]),
          },
          {
            label: (
              <Link to={`/merchandising/view-BOM/${data.id}`}>
                <EyeOutlined /> View
              </Link>
            ),
            key: "1",
            disabled: !hasPermission([BOM_VIEW_PERMISSION]),
          },
          {
            label: (
              <Link to={`/merchandising/preview-BOM/${data.id}`}>
                <EyeOutlined /> Export Preview
              </Link>
            ),
            key: "2",
            disabled: !hasPermission([BOM_VIEW_PERMISSION]),  // Adjust permission if needed
          }
          // {
          //   label: (
          //     <span onClick={() => onExportBOM(data?.id)}>
          //       <ExportOutlined /> Export
          //     </span>
          //   ),
          //   key: "3",
          //   disabled: !hasPermission([BOM_EXPORT_PERMISSION]),
          // },
        ]}
      />
    );
  };  

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        key="3"
        size="small"
        type="primary"
        disabled={!hasPermission([BOM_CREATE_PERMISSION])}
      >
        <Link to="/merchandising/add-BOM">
          {hasPermission([BOM_CREATE_PERMISSION]) ? "Add New" : "No Permission"}
        </Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header title="Bill Of Materials" subTitle="" actions={extra} />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={permitList}
            fetchTableData={getAllPermitList}
            filterName="bom"
          />
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default BOM;
