import { Affix, Button, Card, Form, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BUYER_INQUIRY_LIST_ENDPOINT,
  CM_REPORT_DATA,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import HandleNotification from "../../../common/Notification";
import { CM_VALUE_APPROVE_PERMISSION } from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import BuyerInquiryForm from "./BuyerInquiryForm";

const UpdateBuyerInquiry = (props) => {
  // Props
  const { width, editMode, viewMode } = props;

  // States
  const [documents, setDocuments] = useState([]);
  const [editInfo, setEditInfo] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [CMInfo, setCMInfo] = useState(null);
  const [CMStatus, setCMStatus] = useState(null);
  const [users, setUsers] = useState([]);
  const [styles, setStyles] = useState([]);
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [garmentCategoryList, setGarmentCategoryList] = useState([]);
  const [garmentTypeList, setGarmentTypeList] = useState([]);
  const [garmentForList, setGarmentForList] = useState([]);
  const [brand, setBrand] = useState([]);

  // Antd
  const [form] = Form.useForm();
  const breakeven_cm_watch = Form.useWatch("breakeven_cm", form);
  const buyer_target_watch = Form.useWatch("buyer_target", form);
  const style_id_watch = Form.useWatch("style_id", form);
  const expected_margin_watch = Form.useWatch("expected_margin", form);

  // Others
  const navigate = useNavigate();
  const { inquiryId } = useParams();

  useEffect(() => {
    // On first load / when break even from API changes
    if (form) {
      const exp_margin_calc = (Number(breakeven_cm_watch) * 10) / 100;
      const cgl_target_calc =
        Number(breakeven_cm_watch) + Number(exp_margin_calc);

      form.setFieldsValue({
        expected_margin:
          exp_margin_calc || exp_margin_calc === 0 ? exp_margin_calc : null,
        cgl_target:
          cgl_target_calc || cgl_target_calc === 0 ? cgl_target_calc : null,
      });
    }
  }, [breakeven_cm_watch, form]);

  useEffect(() => {
    // When expected margin or buyer target input field values are changed
    if (form) {
      const cgl_target_calc =
        Number(breakeven_cm_watch) + Number(expected_margin_watch);
      const difference_calc =
        Number(cgl_target_calc) - Number(buyer_target_watch);
      const operational_profit_calc =
        Number(buyer_target_watch) - Number(breakeven_cm_watch);

      form.setFieldsValue({
        cgl_target:
          cgl_target_calc || cgl_target_calc === 0 ? cgl_target_calc : null,
        difference: difference_calc || null,
        operational_profit: operational_profit_calc || null,
      });
    }
  }, [expected_margin_watch, form, breakeven_cm_watch, buyer_target_watch]);

  const getSelectedInquiryInfo = async (id) => {
    const query = `${BUYER_INQUIRY_LIST_ENDPOINT}/${id}/edit`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      const style_document = Array.isArray(
        response?.data?.data?.style_inquiry_map?.style_info?.style_document,
      )
        ? response?.data?.data?.style_inquiry_map?.style_info?.style_document
        : [];
      setEditInfo(response?.data?.data);
      setCMStatus(response?.data?.data?.cm_status);
      setFileData(response?.data?.data?.documents);

      setDocuments(
        style_document?.map((itm) => ({
          id: itm?.id,
          name: itm?.document_name,
          path: itm?.document,
          attachment_type: itm?.attachment_type,
          reference_no: itm?.reference_no,
        })),
      );
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || "Failed to get inquiry data",
      );
    }
  };

  const getSelectedCMInfo = async (id) => {
    const query = `${CM_REPORT_DATA}/${id}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      const CMData = response?.data?.data;
      setCMInfo(CMData);
      form.setFieldsValue({
        breakeven_cm:
          CMData?.cm_info?.breakeven_cm || CMData?.cm_info?.breakeven_cm === 0
            ? Number(CMData?.cm_info?.breakeven_cm)
            : null,
      });
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || "Failed to get CM data",
      );
    }
  };

  useEffect(() => {
    if (inquiryId) {
      getSelectedInquiryInfo(inquiryId);
      getSelectedCMInfo(inquiryId);
    }
  }, [inquiryId]);

  useEffect(() => {
    const getObStatus = () => {
      if (editInfo?.ob_info) {
        if (editInfo?.ob_info?.summary?.ob_summary) {
          return "Created";
        }
      } else {
        return "Pending";
      }
    };

    console.log({ editInfo });
    if (
      editInfo?.received_by_info?.name &&
      editInfo?.received_by &&
      !users.some((item) => item.id === editInfo?.received_by)
    ) {
      setUsers((prev) => [
        {
          id: editInfo?.received_by,
          name: editInfo?.received_by_info?.name,
        },
        ...prev,
      ]);
    }

    if (
      editInfo?.style_inquiry_map?.style_info?.id &&
      !styles.some(
        (item) => item.id === editInfo?.style_inquiry_map?.style_info?.id,
      )
    ) {
      setStyles((prev) => [
        {
          id: editInfo?.style_inquiry_map?.style_info?.id,
          style_no: editInfo?.style_inquiry_map?.style_info?.style_no,
        },
        ...prev,
      ]);
    }

    if (
      editInfo?.buying_office_info?.id &&
      !buyingOffice.some((item) => item.id === editInfo?.buying_office_info?.id)
    ) {
      setBuyingOffice((prev) => [
        {
          id: editInfo?.buying_office_info?.id,
          name: editInfo?.buying_office_info?.name,
        },
        ...prev,
      ]);
    }

    if (
      editInfo?.buyer_info?.id &&
      !buyer.some((item) => item.id === editInfo?.buyer_info?.id)
    ) {
      setBuyer((prev) => [
        {
          id: editInfo?.buyer_info?.id,
          name: editInfo?.buyer_info?.name,
        },
        ...prev,
      ]);
    }

    if (
      editInfo?.garment_category_info?.id &&
      !garmentCategoryList.some(
        (item) => item.id === editInfo?.garment_category_info?.id,
      )
    ) {
      setGarmentCategoryList((prev) => [
        {
          id: editInfo?.garment_category_info?.id,
          name: editInfo?.garment_category_info?.name,
        },
        ...prev,
      ]);
    }

    if (
      editInfo?.garment_type_info?.id &&
      !garmentTypeList.some(
        (item) => item.id === editInfo?.garment_type_info?.id,
      )
    ) {
      setGarmentTypeList((prev) => [
        {
          id: editInfo?.garment_type_info?.id,
          name: editInfo?.garment_type_info?.name,
        },
        ...prev,
      ]);
    }

    if (
      editInfo?.garment_for_info?.id &&
      !garmentForList.some((item) => item.id === editInfo?.garment_for_info?.id)
    ) {
      setGarmentForList((prev) => [
        {
          id: editInfo?.garment_for_info?.id,
          name: editInfo?.garment_for_info?.name,
        },
        ...prev,
      ]);
    }

    if (
      editInfo?.brand_info?.id &&
      !brand.some((item) => item.id === editInfo?.brand_info?.id)
    ) {
      setBrand((prev) => [
        {
          id: editInfo?.brand_info?.id,
          name: editInfo?.brand_info?.name,
        },
        ...prev,
      ]);
    }

    form.setFieldsValue({
      inquiry_no: editInfo?.inquiry_no,
      received_date: editInfo?.received_date
        ? moment(editInfo?.received_date)
        : null,
      received_by: editInfo?.received_by,
      received_by_name: editInfo?.received_by_info?.name,
      style_id: editInfo?.style_inquiry_map?.style_info?.id,
      buying_office_id: editInfo?.buying_office_info?.id,
      buying_office_name: editInfo?.buying_office_info?.name,
      buyer_id: editInfo?.buyer_info?.id,
      buyer_short_name: editInfo?.buyer_info?.short_name,
      buyer_name: editInfo?.buyer_info?.name,
      brand_id: editInfo?.brand_info?.id,
      brand_name: editInfo?.brand_info?.name,
      garment_category_id: editInfo?.garment_category_info?.id,
      garment_type_id: editInfo?.garment_type_info?.id,
      garment_for_id: editInfo?.garment_for_info?.id,
      inquiry_status: editInfo?.inquiry_status,
      expected_order_quantity: editInfo?.expected_order_quantity,
      expected_delivery_date: editInfo?.expected_delivery_date
        ? moment(editInfo?.expected_delivery_date)
        : null,
      production_month_from: editInfo?.production_month_from
        ? moment(editInfo?.production_month_from)
        : null,
      production_month_to: editInfo?.production_month_to
        ? moment(editInfo?.production_month_to)
        : null,
      description: editInfo?.description,
      season: editInfo?.season,
      size_range: editInfo?.size_range,
      ob_status: getObStatus(),
      total_sam: editInfo?.ob_info?.total_sam,
      no_of_workers: editInfo?.ob_info?.no_of_workers,
      no_of_hour: editInfo?.ob_info?.no_of_hour,
      required_efficiency: editInfo?.ob_info?.required_efficiency,
      per_hour_target: editInfo?.ob_info?.per_hour_target,
      order_confirmations: editInfo?.order_confirmation?.map((item) => {
        return {
          id: item?.id || 0,
          inquiry_reason_id: item?.inquiry_reason_id || null,
          inquiry_remark_id: item?.inquiry_remark_id || null,
          is_delete: item?.is_delete || 0,
        };
      }),
      order_status: editInfo?.order_status,
      buyer_target: editInfo?.buyer_target,
      sub_contract_factory_id: editInfo?.sub_contract_factory_id,
      subcontract_status: editInfo?.subcontract_status,
      address: editInfo?.factory_info ? editInfo?.factory_info?.address : null,
    });
  }, [editInfo, form]);

  const discartForm = () => {
    navigate("/merchandising/buyer-inquiry");
  };

  const addBuyerInquiry = () => {
    form.submit();
  };

  const cmApproveEndpoint = `/api/cm-value-approve/${inquiryId}`;
  const approveCmValue = async (values) => {
    const response = await postData(cmApproveEndpoint, {});

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "Success!",
        response?.message || `CM Value Approved Successfully`,
      );
      navigate("/merchandising/buyer-inquiry");
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || `Failed to approve cm value`,
      );
    }
  };

  const onCopyStyle = () => {
    window.open(`/merchandising/add-style-from-inquiry/${editInfo?.id}`);
  };

  const ActionUI = () => {
    return (
      <Space>
        <Button size="small" key={1} danger onClick={() => discartForm()}>
          Discard
        </Button>
        {!style_id_watch && (
          <Button
            size="small"
            key={1}
            onClick={() => onCopyStyle()}
            disabled={viewMode}
          >
            Create Style
          </Button>
        )}

        <Button
          size="small"
          key={2}
          type="primary"
          onClick={() => addBuyerInquiry()}
          hidden={viewMode}
        >
          Edit Buyer Inquiry
        </Button>

        <Button
          size="small"
          key={2}
          type="primary"
          onClick={() => approveCmValue()}
          disabled={
            !hasPermission([CM_VALUE_APPROVE_PERMISSION]) || CMStatus === 1
          }
          hidden={editMode}
        >
          {CMStatus === 0 ? `Approve CM Value` : `CM Value Approved`}
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`${viewMode ? "View" : "Edit"} Buyer Inquiry`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={<ActionUI />}
          />
        </Card>
      </Affix>

      <BuyerInquiryForm
        form={form}
        isEdit={true}
        editMode={editMode}
        viewMode={viewMode}
        editInfo={editInfo}
        fileData={fileData}
        CMInfo={CMInfo}
        users={users}
        setUsers={setUsers}
        styles={styles}
        setStyles={setStyles}
        buyingOffice={buyingOffice}
        setBuyingOffice={setBuyingOffice}
        buyer={buyer}
        setBuyer={setBuyer}
        garmentCategoryList={garmentCategoryList}
        setGarmentCategoryList={setGarmentCategoryList}
        garmentTypeList={garmentTypeList}
        setGarmentTypeList={setGarmentTypeList}
        garmentForList={garmentForList}
        setGarmentForList={setGarmentForList}
        brand={brand}
        setBrand={setBrand}
        documents={documents}
        setDocuments={setDocuments}
      />
    </>
  );
};

export default UpdateBuyerInquiry;
