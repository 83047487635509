import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Affix, Form, Spin } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "../../../../apiServices/common";
import AddB2bLcForm from "./AddB2bLcForm";

export default function EditExportSalesContract(props) {
  let { importId } = useParams();

  const { width, view } = props;
  const propertyFormRef = useRef(null);
  const [permitInfo, setPermitInfo] = useState();
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();
  
  const navigate = useNavigate();
  const discartForm = () => {
    // propertyFormRef.current.discart();
    navigate("/import/b2blc");
  };

  const addProduct = () => {
    // alert("adfafd");
    // return false;
    form.submit();
    //propertyFormRef.current.submit();
  };
  // const base_url = process.env.REACT_APP_BASE;
  const edit_url = `/api/accounting/import-b2blc/view/${importId}`;
  const getPIInfo = async () => {
    setLoading(true)
    let res = await getData(edit_url);
    if (res) {
      let masterData = res?.data?.data[0];
      setPermitInfo(masterData);
    }
    setLoading(false)

  };

  useEffect(() => {
    getPIInfo();
  }, []);


  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`${view ? "View" : "Update"} B2B LC/RTGS/TT`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              // <Button size="large" key={1} danger onClick={() => discartForm()}>
              //   Discard
              // </Button>,
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
                hidden={view}
              >
                Update
              </Button>,
            ]}
          />
        </Card>
      </Affix>
      
      {
        loading ? <Spin/> : 
        <AddB2bLcForm
          ref={propertyFormRef}
          permitInfo={permitInfo}
          form={form}
          view={view}
        />
      }
      
    </>
  );
}
