import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { INQUIRY_REFERENCE_ADD } from "apiServices/API_ENDPOINTS";
import { postData } from "apiServices/common";
import HandleNotification from "common/Notification";

const ReferenceForm = (props) => {
  // Props
  const { getAllInquiryReferences } = props;

  // Antd
  const [referenceForm] = Form.useForm();

  const onFinish = async (values) => {
    const query = `${INQUIRY_REFERENCE_ADD}`;
    const payload = {
      name: values?.name,
      designation: values?.designation,
      organization: values?.organization,
      status: 1,
    };

    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        `${response.message || "Refernec Added Succcessfully"}`,
        null,
      );

      // Reload list
      getAllInquiryReferences();

      // Reset form
      referenceForm.resetFields();
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  return (
    <Form form={referenceForm} onFinish={onFinish}>
      <Row gutter={8}>
        <Col span={6}>
          <Form.Item name="name">
            <Input placeholder="Name" size="small" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="designation">
            <Input placeholder="Designation" size="small" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="organization">
            <Input placeholder="Organization" size="small" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <Button htmlType="submit" icon={<PlusOutlined />} size="small">
              Add
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ReferenceForm;
