import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import CustomDatePicker from "common/CustomDatePicker";
import moment from "moment";

const PackingConfirmForm = (props) => {
  // Props
  const { poList, onChangeInternalPO, onChangeOriginalPO, isEdit, isFactory } =
    props;

  // Antd
  const { Option } = Select;

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Original PO"
            name="original_po"
            rules={[{ required: true, message: `Original PO is required ` }]}
          >
            <Select
              onChange={onChangeOriginalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              disabled={isEdit}
            >
              {poList &&
                poList?.map((po, idx) => (
                  <Option key={idx} value={po?.original_po}>
                    {po?.original_po}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="Internal PO"
            name="internal_po"
            rules={[
              {
                required: false,
                message: `Internal PO is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeInternalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              disabled={isEdit}
            >
              {poList &&
                poList?.map((po, idx) => (
                  <Option key={idx} value={po?.internal_po}>
                    {po?.internal_po}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        {isFactory ? (
          <>
            <Col span={4}>
              <Form.Item name="sub_contract_factory_id" hidden />
              <Form.Item
                name="sub_contract_factory_name"
                label="Factory Name"
                rules={[
                  {
                    required: true,
                    message: `Factory Name is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
            <Col span={0}>
              <Form.Item name="buying_office_id" />
            </Col>
            <Col span={4}>
              <Form.Item
                name="buying_office_name"
                label="Buying Office"
                rules={[
                  {
                    required: true,
                    message: `Buying Office is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={0}>
              <Form.Item name="buyer_id" />
            </Col>
            <Col span={4}>
              <Form.Item
                name="buyer_name"
                label="Buyer"
                rules={[
                  {
                    required: true,
                    message: `Buyer is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={0}>
          <Form.Item name="sales_contract_id" />
        </Col>
        <Col span={0}>
          <Form.Item name="po_map_id" />
        </Col>
        <Col span={4}>
          <Form.Item
            name="reference_no"
            label="Sales Contract No. / Sub Contract No."
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="start"
            label="From"
            rules={[
              {
                required: true,
                message: `Start number is required `,
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              size="small"
              disabled={isEdit}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="end"
            label="To"
            rules={[
              {
                required: true,
                message: `End number is required `,
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              size="small"
              disabled={isEdit}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Packing Date"
            name="packing_date"
            initialValue={moment()}
          >
            <CustomDatePicker
              style={{ width: "100%" }}
              size="small"
              disabled={isEdit}
            />
            {/* <DatePicker
              style={{ width: "100%" }}
              size="small"
              disabled={isEdit}
            /> */}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PackingConfirmForm;
