import React, {useEffect, useState} from "react";
import {Button} from "antd";
import AppPageHeader from "../../../common/PageHeader";

import AppContent from "../../../common/AppContent";

import {useDispatch, useSelector} from "react-redux";
import {onGetInventoryDetails} from "../../../redux/actions";
import {useNavigate} from "react-router";

const ShipmentList = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pageSize = useSelector(({cutting}) => cutting.pageSize);
    const total = useSelector(({cutting}) => cutting.totalCutting);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(0);

    const refetch = () => {
        setRefresh(refresh + 1);
    };
    const onPageChange = (newPage) => {
        setPage(newPage);
    };

    const handleEdit = (event, record) => {
        dispatch(onGetInventoryDetails(record.id))
        // navigate('/inventory/store-cutting', {state: selectedInventoryDetails})
    }

    const handleNav = (event, record) => {
        navigate("/forwarding/store-shipment-info", { state: props.selectedInventoryDetails});
        window.location.reload();


    };
    useEffect(() => {
    }, [refresh, page, pageSize])

    const extra = <Button type='primary' onClick={handleNav}>Add New</Button>
    const header = <AppPageHeader extra={extra} title={'Shipment Information'}/>

    return <AppContent header={header}  />

}

export default ShipmentList;