import { Form } from "antd";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import { hasPermission } from "utils/functions";
import { NO_PERMISSION_REQUIRED } from "routes/permissions";
import FabricAutoForm from "./FabricAutoForm";

const FabricAccessoriesAutoReconciliation = () => {
  // Antd
  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                disabled={!hasPermission([NO_PERMISSION_REQUIRED])}
                hideAddButton
                hideFilterButton
                showBackButton
              />
            }
            title="Fabric and Accessories Auto Reconciliation"
          />
        }
        content={
          <>
            <FabricAutoForm form={form} />
          </>
        }
      />
    </>
  );
};

export default FabricAccessoriesAutoReconciliation;
