import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  REST_SALES_CONTRACT,
  SALES_CONTRACT_LIST,
  STYLE_WISE_COST_ADD,
  STYLE_WISE_COST_EDIT,
  STYLE_WISE_COST_VIEW,
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const initialData = [
  {
    id: 1,
    name: "fabric_value",
    label: "Fabric",
    value: null,
    pi_value: null,
  },
  {
    id: 2,
    name: "accessories_value",
    label: "Accessories",
    value: null,
    pi_value: null,
  },
  {
    id: 3,
    name: "print_value",
    label: "Print",
    value: null,
    pi_value: null,
  },
  {
    id: 4,
    name: "washing_value",
    label: "Washing",
    value: null,
    pi_value: null,
  },
  {
    id: 5,
    name: "embroidery_value",
    label: "Embroidery",
    value: null,
    pi_value: null,
  },
  // {
  //   id: 7,
  //   name: "commercial_value",
  //   label: "Commercial Expense",
  //   value: null,
  //   pi_value: null,
  // },
  {
    id: 6,
    name: "commission_value",
    label: "Commission",
    value: null,
    pi_value: null,
  },
  {
    id: 7,
    name: "local_charge_value",
    label: "Local Charge",
    value: null,
    pi_value: null,
  },
  {
    id: 8,
    name: "testing_value",
    label: "Testing Charges",
    value: null,
    pi_value: null,
  },
  {
    id: 9,
    name: "inspection_value",
    label: "Inspection Charges",
    value: null,
    pi_value: null,
  },
  {
    id: 10,
    name: "courier_value",
    label: "Courier Charges",
    value: null,
    pi_value: null,
  },
  {
    id: 11,
    name: "portal_value",
    label: "Portal Charges",
    value: null,
    pi_value: null,
  },
  {
    id: 12,
    name: "cm_value",
    label: "CM (Including Commercial)",
    value: null,
    pi_value: null,
  },
];

const EstimateForm = (props) => {
  // Props
  const { form, editMode, viewMode } = props;

  // States
  const [salesContractList, setSalesContractList] = useState([]);
  const [tableData, setTableData] = useState(initialData);
  const [restSalesContracts, setRestSalesContracts] = useState(null);
  const [salesContract, setSalesContract] = useState(null);

  // console.log('tableData', tableData);

  // Router
  const navigate = useNavigate();
  const { id } = useParams();

  // Antd
  const { Option } = Select;
  const { Title } = Typography;
  const sales_contract_id_watch = Form.useWatch("sales_contract_id", form);

  // Others
  const buyer_id_watch = Form.useWatch("buyer_id", form);
  const adjust_amount_watch = Form.useWatch("save_amount", form);

  const getPiValue = async (sales_contract_id) => {
    const piValue = `/api/accounting/pi-value/${sales_contract_id}`;
    const response = await getData(piValue);

    if (response) {
      const piValuesData = response?.data?.data;
      //console.log('piValuesData', piValuesData);
      const tableDataCopy = [...tableData];
      ///console.log('tableDataCopy', tableDataCopy);
      isArrayAndHasValue(tableDataCopy) &&
        tableDataCopy.forEach((item) => {
          const piValue = piValuesData[item?.name];
          item.pi_value = piValue;
        });
      setTableData(tableDataCopy);
    }
  };

  const getShipmentValue = async (sales_contract_id) => {
    const shipmentValue = `/api/accounting/shipment-value/${sales_contract_id}`;
    const response = await getData(shipmentValue);

    if (response) {
      const shipmentValuesData = response?.data?.data;
      //console.log('piValuesData', piValuesData);
      const tableDataCopy = [...tableData];
      //console.log('tableDataCopy', tableDataCopy);
      isArrayAndHasValue(tableDataCopy) &&
        tableDataCopy.forEach((item) => {
          const shipmentValue = shipmentValuesData[item?.name];
          item.shipment_value = shipmentValue;
        });
      setTableData(tableDataCopy);
    }
  };

  const getRestSalesContract = async (
    salesContractId,
    buyerId,
    filterValues,
  ) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${REST_SALES_CONTRACT}/${salesContractId}/${buyerId}`;
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setRestSalesContracts(response?.data?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error in fetching Rest Sales Contract List",
        null,
      );
    }
  };

  useEffect(() => {
    if (sales_contract_id_watch && buyer_id_watch) {
      getRestSalesContract(sales_contract_id_watch, buyer_id_watch);
    }
  }, [sales_contract_id_watch, buyer_id_watch]);

  // useEffect(() => {
  //   if(sales_contract_id_watch) {
  //     console.log("run");
  //     getPiValue(sales_contract_id_watch);
  //   }
  // }, [sales_contract_id_watch]);

  const getAllSalesContract = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${SALES_CONTRACT_LIST}`;
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSalesContractList(response?.data?.data?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error in fetching Sales Contract List",
        null,
      );
    }
  };

  const getSalesContractDetails = useCallback(
    async (id) => {
      const query = `${SALES_CONTRACT_LIST}/${id}/view`;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {
        form.setFieldsValue({
          buying_office_id:
            response?.data?.data?.sales_contract?.buying_office_info?.id,
          buying_office_name:
            response?.data?.data?.sales_contract?.buying_office_info?.name,
          buyer_id: response?.data?.data?.sales_contract?.buyer_info?.id,
          buyer_name: response?.data?.data?.sales_contract?.buyer_info?.name,
          contract_date: response?.data?.data?.sales_contract?.contract_date
            ? moment(response?.data?.data?.sales_contract?.contract_date)
            : null,
          contract_value: response?.data?.data?.sales_contract?.contract_value
            ? Number(response?.data?.data?.sales_contract?.contract_value)
            : null,
        });
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error in fetching Sales Contract Details",
          null,
        );
      }
    },
    [form],
  );

  const getSelectedCostInfo = useCallback(
    async (id) => {
      const query = `${STYLE_WISE_COST_VIEW}/${id}`;
      const response = await getData(query);

      console.log("view response", response);

      if (response && response?.data?.code === 200) {
        const tableDataCopy = [...tableData];
        tableDataCopy.forEach((item) => {
          item.value = response?.data?.data?.[item.name] || null;
        });
        setTableData(tableDataCopy);

        getPiValue(response?.data?.data?.sales_contract_info?.id);

        getShipmentValue(response?.data?.data?.sales_contract_info?.id);
        setSalesContract(response?.data?.data?.sales_contract_info);
        form.setFieldsValue({
          sales_contract_id: response?.data?.data?.sales_contract_info?.id,
          remain_value: response?.data?.data?.remain_value || null,
          adjust_sales_contract_id:
            response?.data?.data?.adjust_sales_contract_id || null,
          save_amount: response?.data?.data?.save_amount || null,
          remain_value_with_adjust:
            response?.data?.data?.remain_value_with_adjust || null,
          garments_qty_value: response?.data?.data?.garments_qty_value || null,

          status: response?.data?.data?.status,
        });
        getSalesContractDetails(response?.data?.data?.sales_contract_info?.id);
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error in fetching Cost Info",
          null,
        );
      }
    },
    [form, getSalesContractDetails],
  );

  useEffect(() => {
    // get all sales contract on first load
    getAllSalesContract();

    // If add mode then reset the initial data and set to table state
    if (!editMode && !viewMode) {
      const resetInitialData = initialData.map((item) => {
        return {
          ...item,
          value: null,
        };
      });
      setTableData(resetInitialData);
    }
  }, [editMode, viewMode]);

  useEffect(() => {
    // get selected cost info on edit mode
    if (id) {
      getSelectedCostInfo(id);
    }
  }, [editMode, id, getSelectedCostInfo]);

  useEffect(() => {
    // Calculate total value and remaining value
    // Set value to remain_value in form
    const contractValue = form.getFieldValue("contract_value") || 0;
    const totalValue = tableData?.reduce((acc, item) => {
      return acc + Number(item?.value);
    }, 0);
    const remaining = contractValue - totalValue;
    remaining > 0 &&
      form.setFieldsValue({
        remain_value: remaining || 0,
        total_value: totalValue || 0,
      });

    // Calculate and set remain value with adjust
    const totalWithAdjust = (totalValue || 0) + (adjust_amount_watch || 0);
    const remainValueWithAdjust = contractValue - totalWithAdjust;
    form.setFieldsValue({
      remain_value_with_adjust: remainValueWithAdjust || 0,
      total_value_with_adjust: totalWithAdjust || 0,
    });
  }, [tableData, form, adjust_amount_watch]);

  useEffect(() => {
    if (salesContract) {
      if (!salesContractList.find((item) => item.id === salesContract.id)) {
        setSalesContractList([salesContract, ...salesContractList]);
      }
    }
  }, [salesContract, salesContractList, setSalesContractList]);

  const onFinishCostEstimate = async (values) => {
    // console.log("onFinishCostEstimate values", values);

    const tableDataCopy = [...tableData];
    const query =
      editMode || viewMode
        ? `${STYLE_WISE_COST_EDIT}/${id}/edit`
        : `${STYLE_WISE_COST_ADD}`;
    const bodyData = {
      sales_contract_id: values?.sales_contract_id,
      buying_office_name: values?.buying_office_name,
      buyer_name: values?.buyer_name,
      contract_date: values?.contract_date
        ? moment(values?.contract_date).format("YYYY-MM-DD")
        : null,
      contract_value: values?.contract_value,
      remain_value: values?.remain_value || null,
      garments_qty_value: values?.garments_qty_value || null,
      fabric_value:
        tableDataCopy?.find((item) => item?.name === "fabric_value")?.value ||
        null,
      accessories_value:
        tableDataCopy?.find((item) => item?.name === "accessories_value")
          ?.value || null,
      cm_value:
        tableDataCopy?.find((item) => item?.name === "cm_value")?.value || null,
      print_value:
        tableDataCopy?.find((item) => item?.name === "print_value")?.value ||
        null,
      washing_value:
        tableDataCopy?.find((item) => item?.name === "washing_value")?.value ||
        null,
      embroidery_value:
        tableDataCopy?.find((item) => item?.name === "embroidery_value")
          ?.value || null,
      commercial_value:
        tableDataCopy?.find((item) => item?.name === "commercial_value")
          ?.value || null,
      commission_value:
        tableDataCopy?.find((item) => item?.name === "commission_value")
          ?.value || null,
      local_charge_value:
        tableDataCopy?.find((item) => item?.name === "local_charge_value")
          ?.value || null,
      testing_value:
        tableDataCopy?.find((item) => item?.name === "testing_value")?.value ||
        null,
      inspection_value:
        tableDataCopy?.find((item) => item?.name === "inspection_value")
          ?.value || null,
      courier_value:
        tableDataCopy?.find((item) => item?.name === "courier_value")?.value ||
        null,
      portal_value:
        tableDataCopy?.find((item) => item?.name === "portal_value")?.value ||
        null,
      // garments_qty_value:
      // tableDataCopy?.find((item) => item?.name === "garments_qty_value")?.value ||
      // null,
      pi_value:
        tableDataCopy?.find((item) => item?.name === "pi_value")?.value || null,
      shipment_value:
        tableDataCopy?.find((item) => item?.name === "shipment_value")?.value ||
        null,
      total_value: values?.total_value || null,
      adjust_sales_contract_id: values?.adjust_sales_contract_id || null,
      save_amount: values?.save_amount || null,
      remain_value_with_adjust: values?.remain_value_with_adjust || null,
      total_value_with_adjust: values?.total_value_with_adjust || null,
      status: viewMode ? 1 : values?.status,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        `Style wise cost ${
          editMode || viewMode ? "updated" : "added"
        } successfully!`,
        null,
      );
      setTableData(initialData);
      form.resetFields();
      navigate("/merchandising/cost-estimate");
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        `Error ${editMode ? "updating" : "adding"} style wise cost!`,
        null,
      );
    }
  };

  const onChangeEstCost = (value, id) => {
    const tableDataCopy = [...tableData];
    const foundData = tableDataCopy.find((item) => item.id === id);
    foundData.value = value;
    setTableData(tableDataCopy);
  };

  const tableColumnsEdit = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Budget Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Est. Cost ($)",
      dataIndex: "value",
      key: "value",
      width: "20%",
      render: (value, record) => {
        return (
          <InputNumber
            style={{ width: "100%" }}
            placeholder={`${record?.label} Value`}
            value={value || value === 0 ? value : null}
            onChange={(inputValue) => {
              onChangeEstCost(inputValue, record?.id);
            }}
            formatter={(value) => commaSeparateNumber(value)}
            size="small"
            // precision={2}
          />
        );
      },
    },
  ];

  const tableColumnsView = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Budget Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Est. Cost ($)",
      dataIndex: "value",
      key: "value",
      width: "20%",
      render: (value, record) => {
        return (
          <InputNumber
            style={{ width: "100%" }}
            placeholder={`${record?.label} Value`}
            value={value || value === 0 ? value : null}
            onChange={(inputValue) => {
              onChangeEstCost(inputValue, record?.id);
            }}
            formatter={(value) => commaSeparateNumber(value)}
            size="small"
            precision={2}
            disabled={viewMode}
          />
        );
      },
    },
    {
      title: "PI Value",
      dataIndex: "pi_value",
      key: "pi_value",
      render: (value, record) => {
        return (
          <span>
            {value ? commaSeparateNumber(Number(value).toFixed(2)) : 0}
          </span>
        );
      },
    },
    {
      title: "Difference",
      key: "difference",
      render: (value, record) => {
        return (
          <span>
            {commaSeparateNumber(
              (
                Number(record?.value || 0) - Number(record?.pi_value || 0)
              ).toFixed(2),
            )}
          </span>
        );
      },
    },
    {
      title: "Invoice Value",
      dataIndex: "shipment_value",
      key: "shipment_value",
      render: (value, record) => {
        return (
          <span>
            {value ? commaSeparateNumber(Number(value).toFixed(2)) : 0}
          </span>
        );
      },
    },
    {
      title: "Difference",
      key: "difference",
      render: (value, record) => {
        return (
          <span>
            {commaSeparateNumber(
              (
                Number(record?.value || 0) - Number(record?.shipment_value || 0)
              ).toFixed(2),
            )}
          </span>
        );
      },
    },
  ];

  const TableSummary = ({ pageData }) => {
    const total = pageData.reduce((acc, item) => {
      return acc + Number(item?.value);
    }, 0);
    const totalWithAdjustment = total + (adjust_amount_watch || 0);

    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#F5F5F5" }}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <strong>{`Total (Without Adjustment)`}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <strong>
              {total ? commaSeparateNumber(Number(total.toFixed(2))) : 0}
            </strong>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row style={{ backgroundColor: "#F5F5F5" }}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <strong>{`Total (With Adjustment)`}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <strong>
              {totalWithAdjustment
                ? commaSeparateNumber(Number(totalWithAdjustment.toFixed(2)))
                : 0}
            </strong>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishCostEstimate}
      >
        <Card>
          <Row gutter={16}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract"
                name="sales_contract_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select Sales Contract No!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Sales Contract No"
                  onChange={(value) => {
                    getSalesContractDetails(value);
                  }}
                  optionFilterProp="children"
                  onSearch={(value) => {
                    getAllSalesContract({ reference_no: value });
                  }}
                  size="small"
                  disabled={viewMode}
                >
                  {isArrayAndHasValue(salesContractList) &&
                    salesContractList?.map((item, index) => (
                      <Option value={item?.id} key={item?.id}>
                        {item.reference_no}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="buying_office_id" hidden />
              <Form.Item label="Buying Office" name="buying_office_name">
                <Input placeholder="Buying Office" disabled size="small" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item name="buyer_id" hidden />
              <Form.Item label="Buyer" name="buyer_name">
                <Input placeholder="Buyer" disabled size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Date" name="contract_date">
                <CustomDatePicker className="w-100" disabled size="small" />
                {/* <DatePicker className="w-100" disabled size="small" /> */}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Sales Contract Value" name="contract_value">
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Sales Contract Value"
                  disabled
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Remain (W/O Adjust)" name="remain_value">
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Remaining Value"
                  disabled
                  precision={2}
                  size="small"
                  // formatter={(value) => commaSeparateNumber(value)}
                />
              </Form.Item>
              <Form.Item name="total_value" hidden />
              <Form.Item name="total_value_with_adjust" hidden />
              <Form.Item name="status" hidden />
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Remain (With Adjust)"
                name="remain_value_with_adjust"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Remaining Value"
                  disabled
                  precision={2}
                  size="small"
                  // formatter={(value) => commaSeparateNumber(value)}
                />
              </Form.Item>
              <Form.Item name="total_value" hidden />
            </Col>
            {isArrayAndHasValue(restSalesContracts)}
            {isArrayAndHasValue(restSalesContracts) && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Adjust Sales Contract"
                    name="adjust_sales_contract_id"
                  >
                    <Select
                      showSearch
                      placeholder="Select a Sales Contract No"
                      optionFilterProp="children"
                      onSearch={(value) => {
                        getRestSalesContract(
                          sales_contract_id_watch,
                          buyer_id_watch,
                          { reference_no: value },
                        );
                      }}
                      size="small"
                      disabled={viewMode}
                    >
                      {isArrayAndHasValue(restSalesContracts) &&
                        restSalesContracts?.map((item, index) => (
                          <Option value={item?.id} key={item?.id}>
                            {item.reference_no}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Adjust Amount" name="save_amount">
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Adjust Amount"
                      formatter={(value) => commaSeparateNumber(value)}
                      precision={2}
                      size="small"
                      disabled={viewMode}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Garments Qty" name="garments_qty_value">
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Garments Qty"
                      formatter={(value) => commaSeparateNumber(value)}
                      precision={2}
                      size="small"
                      disabled={viewMode}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Card>

        <Card>
          <Table
            dataSource={tableData}
            columns={viewMode ? tableColumnsView : tableColumnsEdit}
            pagination={false}
            bordered
            summary={(pageData) => {
              return <TableSummary pageData={pageData} />;
            }}
          />
        </Card>
      </Form>
    </div>
  );
};

export default EstimateForm;
