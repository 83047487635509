import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Layout, Modal, Typography } from "antd";
import {
  BOLT_SEAL_LOG_ADD_ENDPOINT,
  BOLT_SEAL_LOG_EDIT_ENDPOINT,
  BOLT_SEAL_LOG_LIST_ENDPOINT,
  GET_DRIVER_INFOS,
  GET_VEHICLE_INFOS,
  PO_MAP_ENDPOINT,
  PO_Wise_Packing_Info_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";
import { alertPop } from "apiServices/common/helper";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { PACKING_CREATE_PERMISSION } from "routes/permissions";
import { hasPermission } from "utils/functions";
import AppPageHeader from "../../../../common/PageHeader";
import InventoryLayOutCommon from "../../../InventoryFabric/LayOut/InventoryLayOutCommon";
import PackingDetails from "../PackingDetails";
import PackingReqForm from "../PackingReqForm";
import BoltSealLogForm from "./BoltSealLogForm";
import HandleNotification from "common/Notification";
import moment from "moment";

const AddOrUpdateBoltSealLog = (props) => {
  // Props
  const { isEdit, editMode } = props;

  // States
  const [poList, setPoList] = useState(null);
  const [vehicleNoList, setVehicleNoList] = useState(null);
  const [driverList, setDriverList] = useState(null);
  const [poDetailsData, setPoDetailsData] = useState([{ unique_id: 0 }]);
  const [selectedPoColorList, setSelectedPoColorList] = useState(null);
  const [selectedPOUniqueID, setSelectedPOUniqueID] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(false);

  // Router
  const navigate = useNavigate();
  const params = useParams();
  const boltSealId = params?.boltSealId || null;

  // Antd
  const { Content } = Layout;
  const { Panel } = Collapse;
  const [PackReqForm] = Form.useForm();
  const { confirm } = Modal;
  const { Title } = Typography;

  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => PackReqForm.submit()}
        disabled={!hasPermission([PACKING_CREATE_PERMISSION])}
      >
        {hasPermission([PACKING_CREATE_PERMISSION])
          ? "Submit"
          : "No Permission"}
      </Button>
      <Button
        size="small"
        htmlType="submit"
        onClick={() => PackingReqForm.resetFields()}
      >
        {" "}
        Reset
      </Button>
    </div>
  );

  const getVehicleNoList = async () => {
    const query = `${GET_VEHICLE_INFOS}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setVehicleNoList(response?.data?.data?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting vehicle no list",
        null,
      );
    }
  };

  const getDriverList = async () => {
    const query = `${GET_DRIVER_INFOS}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setDriverList(response?.data?.data?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting driver list",
        null,
      );
    }
  };

  const getPOList = async (filterValues) => {
    const query = `${PO_MAP_ENDPOINT}`;
    const response = await getData(query, false, {
      ...filterValues,
      per_page: filterValues?.per_page || 5,
    });
    if (response) {
      setPoList(response?.data?.data?.data);
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  const getBoltSeal = useCallback(
    (boltSealId) => {
      (async () => {
        const query = `${BOLT_SEAL_LOG_LIST_ENDPOINT}/${boltSealId}/edit`;
        if (editMode && boltSealId && Array.isArray(vehicleNoList)) {
          const cloneVehicleNoList = [...vehicleNoList];
          const response = await getData(query);
          const data = response?.data?.data;
          const selectedVehicleValue = cloneVehicleNoList?.find(
            (item) => item?.id === data?.vehicle_driver_info?.id,
          );

          setSelectedVehicle(selectedVehicleValue);

          const po_list = data?.po_lists?.map((item) => ({
            ...item,
            buyer: {
              name: item?.buyer_name,
            },
            carton_cbm: item?.cbm,
            unique_id: item?.po_map_id,
            po_details: item?.carton?.map((poItem) => ({
              ...poItem,
              id: poItem?.id,
              detail_id: poItem?.id,
              packing_carton_id: poItem?.packing_carton_id,
              is_checked: poItem?.is_checked,
            })),
          }));

          const vehicle_size_in_cbm =
            selectedVehicleValue?.vehicle_size_info?.vehicle_size_in_cbm || 0;
          const available_cbm =
            Array.isArray(data?.po_lists) && data?.po_lists?.length > 0
              ? data?.po_lists?.reduce((result, item) => {
                  return result + Number(item?.cbm || 0);
                }, 0)
              : null;

          const total = vehicle_size_in_cbm - available_cbm;

          if (response) {
            setPoDetailsData(po_list);
            PackReqForm.setFieldsValue({
              vehicle_info_id: data?.vehicle_info_id,
              vehicle_size: `${data?.vehicle_info?.vehicle_size_info?.vehicle_size} - ${data?.vehicle_info?.vehicle_size_info?.vehicle_size_in_cbm}`,
              vehicle_type: data?.vehicle_info?.vehicle_type_info?.vehicle_type,
              driver_info_id: data?.driver_info_id,
              driver_cell_no: data?.driver_info?.driver_cell_no,
              driving_license_no: data?.driver_info?.driving_license_no,
              nid: data?.driver_info?.nid,
              arrival_date_time: data?.arrival_date_time ? moment(data?.arrival_date_time) : null,
              dispatch_date_time: data?.dispatch_date_time ? moment(data?.dispatch_date_time) : null,
              bolt_seal_no: data?.bolt_seal_no,
              bolt_seal_date: data?.bolt_seal_date ? moment(data?.bolt_seal_date) : null,
              available_cbm: total,
              challan_no: data?.challan_no,
              delivery_place: data?.delivery_place,
              gprs_track_no: data?.gprs_track_no,
              note: data?.note,
              gate_pass: data?.gate_pass,
              status: data?.status,
            });
          } else {
            alertPop("error", "Error assiging data to the form");
          }
        }
      })();
    },
    [editMode, setPoDetailsData, PackReqForm, vehicleNoList],
  );

  useEffect(() => {
    // Get List on first render
    getPOList();
    getVehicleNoList();
    getDriverList();
  }, []);

  useEffect(() => {
    if (editMode && boltSealId) {
      getBoltSeal(boltSealId);
    }
  }, [boltSealId, getBoltSeal, editMode]);

  useEffect(() => {
    const vehicle_size_in_cbm =
      selectedVehicle?.vehicle_size_info?.vehicle_size_in_cbm || 0;

    if (Array.isArray(poDetailsData) && poDetailsData.length > 0) {
      const poDetailsDataCopy = [...poDetailsData];
      const available_cbm = poDetailsDataCopy?.reduce((result, item) => {
        return result + Number(item?.carton_cbm || 0);
      }, 0);

      const total = vehicle_size_in_cbm - available_cbm;

      PackReqForm.setFieldsValue({
        available_cbm: total,
      });
    }
  }, [poDetailsData, selectedVehicle, PackReqForm]);

  const onFinishPacking = async (values) => {
    const bodyData = {
      vehicle_info_id: values?.vehicle_info_id,
      driver_info_id: values?.driver_info_id,
      bolt_seal_no: values?.bolt_seal_no,
      bolt_seal_date: values?.bolt_seal_date
        ? moment(values?.bolt_seal_date).format("YYYY-MM-DD HH:mm:ss")
        : null,
      challan_no: values?.challan_no,
      delivery_place: values?.delivery_place,
      gprs_track_no: values?.gprs_track_no,
      note: values?.note,
      arrival_date_time: values?.arrival_date_time
        ? moment(values?.arrival_date_time).format("YYYY-MM-DD HH:mm:ss")
        : null,
      dispatch_date_time: values?.dispatch_date_time
        ? moment(values?.dispatch_date_time).format("YYYY-MM-DD HH:mm:ss")
        : null,
      gate_pass: values?.gate_pass,
      status: 0,
      po_lists: poDetailsData.map((item) => {
        const po_details = item?.po_lists?.length
          ? item?.po_lists
          : item?.carton;

        return {
          id: item?.id,
          po_map_id: item?.po_map_id,
          order_quantity: item?.order_quantity,
          packing_quantity: item?.packing_quantity,
          loading_quantity: item?.loading_quantity,
          remain_quantity: item?.remain_quantity,
          carton_quantity: item?.carton_quantity,
          cbm: item?.carton_cbm,
          po_details: po_details?.map((poItem) => ({
            id: poItem?.id,
            packing_carton_id: poItem?.packing_carton_id,
            is_checked: poItem?.is_checked,
          })),
        };
      }),
    };

    const query = editMode
      ? `${BOLT_SEAL_LOG_EDIT_ENDPOINT}/${boltSealId}`
      : `${BOLT_SEAL_LOG_ADD_ENDPOINT}`;

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      alertPop(
        "success",
        response?.message?.[0] ||
          `Packing Requirement ${
            editMode ? "Updated" : "Inserted"
          } Successfully`,
      );
      navigate(`/ex-factory/bolt-seal-log`);
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  const onChangeVehicleNoList = (value) => {
    const foundVehicleItem = vehicleNoList?.find((item) => item?.id === value);
    setSelectedVehicle(foundVehicleItem);

    foundVehicleItem &&
      PackReqForm.setFieldsValue({
        vehicle_size: `${foundVehicleItem?.vehicle_size_info?.vehicle_size} - ${foundVehicleItem?.vehicle_size_info?.vehicle_size_in_cbm}`,
        vehicle_type: foundVehicleItem?.vehicle_type_info?.vehicle_type,
      });
  };

  const onSelectDriverName = (value) => {
    const foundDriverItem = driverList?.find((item) => item?.id === value);

    foundDriverItem &&
      PackReqForm.setFieldsValue({
        driver_cell_no: foundDriverItem?.driver_cell_no,
        driving_license_no: foundDriverItem?.driving_license_no,
        nid: foundDriverItem?.nid,
      });
  };

  const getPODetailsData = async (po_map_id, unique_id) => {
    const clonePoDetailsData = [...poDetailsData];
    const foundPoItemIndex = clonePoDetailsData?.findIndex(
      (item) => item?.unique_id === unique_id,
    );
    if (foundPoItemIndex === -1) alertPop("error", "Something went wrong");
    const query = `${PO_Wise_Packing_Info_ENDPOINT}`;
    const response = await getData(query, false, { po_map_id });

    if (response) {
      const obj = {
        ...response?.data?.data,
        po_lists: response?.data?.data?.carton?.map((item) => ({
          ...item,
          is_checked: item?.is_checked || 0,
          packing_carton_id: item?.id,
        })),
        unique_id: clonePoDetailsData[foundPoItemIndex]?.unique_id,
      };

      clonePoDetailsData[foundPoItemIndex] = obj;
      setPoDetailsData(clonePoDetailsData);
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  const onChangePOList = (value, unique_id) => {
    const selectedPO = poList?.find((po) => po?.original_po === value);
    selectedPO && getPODetailsData(selectedPO?.id, unique_id);
  };

  const copyRow = (row_id) => {
    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === row_id,
    );
    const clonedFoundPoItem = structuredClone(foundPoItem);
    const lastPoItemId = poDetailsData[poDetailsData.length - 1]?.unique_id;

    clonedFoundPoItem?.sizes?.forEach((sizeItem, index) => {
      sizeItem.po_detail_id = 0;
    });

    const updatePoDetails = [
      ...poDetailsData,
      {
        unique_id: lastPoItemId + 1,
      },
    ];
    setPoDetailsData(updatePoDetails);
  };

  const removeRow = (row_index) => {
    confirm({
      title: "Do you want to delete this row?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const updatedPoDetailsData = poDetailsData.filter(
          (item) => item?.unique_id !== row_index,
        );
        setPoDetailsData(updatedPoDetailsData);
      },
    });
  };

  const onChangePoDetailsValue = (value, index, keyName) => {
    // Copy poDetailsData state array
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );
    foundPoItem[keyName] = value;

    setPoDetailsData(poDetailsDataCopy);
  };

  const calculateQty = (uniqueId) => {
    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === uniqueId,
    );
    const qty = foundPoItem?.end_range - foundPoItem?.start_range;
    if (foundPoItem?.end_range && foundPoItem?.start_range) {
      foundPoItem["carton_qty"] =
        foundPoItem?.end_range - foundPoItem?.start_range + 1;
    } else if (qty && qty > 0) {
      foundPoItem["carton_qty"] = qty + 1;
    } else {
      foundPoItem["carton_qty"] = 0;
    }
  };

  const calculateCBM = (uniqueId) => {
    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === uniqueId,
    );
    const cbm =
      (foundPoItem?.length * foundPoItem?.width * foundPoItem?.height) /
      1000000;

    if (cbm && cbm > 0) {
      foundPoItem["cbm"] = cbm;
    } else {
      foundPoItem["cbm"] = 0;
    }
  };

  const calculateTotalGrossWeight = (uniqueId) => {
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === uniqueId,
    );
    const totalGrossWeight = foundPoItem?.gross * foundPoItem?.carton_qty;

    if (totalGrossWeight && totalGrossWeight > 0) {
      foundPoItem["total_gross"] = totalGrossWeight;
    } else {
      foundPoItem["total_gross"] = 0;
    }

    setPoDetailsData(poDetailsDataCopy);
  };

  const calculateTotalNetWeight = (uniqueId) => {
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === uniqueId,
    );
    const totalNetWeight = foundPoItem?.net * foundPoItem?.carton_qty;

    if (totalNetWeight && totalNetWeight > 0) {
      foundPoItem["total_net"] = totalNetWeight;
    } else {
      foundPoItem["total_net"] = 0;
    }

    setPoDetailsData(poDetailsDataCopy);
  };

  const insertSizeTableData = (tableData, sizeTrackForm) => {
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItemIndex = poDetailsDataCopy?.findIndex(
      (item) => item?.unique_id === selectedPOUniqueID,
    );

    poDetailsDataCopy[foundPoItemIndex]["po_lists"] = tableData.map((item) => ({
      ...item,
      id: item?.detail_id,
      packing_carton_id: item?.packing_carton_id,
      is_checked: item?.is_checked,
    }));

    alertPop("success", "Data Inserted");

    setPoDetailsData(poDetailsDataCopy);
    setIsDrawerOpen(false);
  };

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader
          title={
            <Title level={5}>
              {editMode ? "Edit Bolt Seal" : "Add Bolt Seal"}
            </Title>
          }
          extra={extra}
        />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Requirements Details</b>} key="1">
              <Form
                layout="vertical"
                onFinish={onFinishPacking}
                form={PackReqForm}
              >
                <BoltSealLogForm
                  poList={poList}
                  vehicleNoList={vehicleNoList}
                  onChangeVehicleNoList={onChangeVehicleNoList}
                  driverList={driverList}
                  onSelectDriverName={onSelectDriverName}
                />
              </Form>
            </Panel>
          </Collapse>
          <PackingDetails
            poList={poList}
            getPOList={getPOList}
            poDetailsData={poDetailsData}
            copyRow={copyRow}
            removeRow={removeRow}
            onChangePoDetailsValue={onChangePoDetailsValue}
            calculateQty={calculateQty}
            calculateCBM={calculateCBM}
            calculateTotalGrossWeight={calculateTotalGrossWeight}
            calculateTotalNetWeight={calculateTotalNetWeight}
            selectedPoColorList={selectedPoColorList}
            setSelectedPoColorList={setSelectedPoColorList}
            PackReqForm={PackReqForm}
            selectedPOUniqueID={selectedPOUniqueID}
            setSelectedPOUniqueID={setSelectedPOUniqueID}
            insertSizeTableData={insertSizeTableData}
            isEdit={isEdit}
            editMode={editMode}
            setPoDetailsData={setPoDetailsData}
            onChangePOList={onChangePOList}
            setIsDrawerOpen={setIsDrawerOpen}
            isDrawerOpen={isDrawerOpen}
          />
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default AddOrUpdateBoltSealLog;
