import { Bar } from "@ant-design/plots";
import { Alert, Card, Empty, Typography } from "antd";
import { postData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";

const CategoryWiseDefect = ({ line_id, currentSlide, slideId }) => {
  const [categoryWiseDefectsData, setCategoryWiseDefectsData] = useState({});

  const initialGetData = useCallback(async () => {
    const response = await postData(
      "/api/digitalization/bigscreen-dashboard/category-defects",
      {
        line_id: line_id,
      },
    );

    if (response && response?.code === 200) {
      const data = response?.data;

      setCategoryWiseDefectsData(data);
    }
  }, [line_id]);

  useEffect(() => {
    if (line_id && currentSlide === slideId) {
      initialGetData();
    }
  }, [line_id, currentSlide, slideId, initialGetData]);

  const config = {
    data: categoryWiseDefectsData?.chart_data,
    xField: "name",
    yField: "value",
    label: {
      text: "value",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      tickInterval: 10,
      label: {
        formatter: (val) => {
          const num = Number(val);
          if (num % 10 === 0) {
            return num;
          }
          return "";
        },
      },
    },
  };
  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {categoryWiseDefectsData?.chart_data &&
      categoryWiseDefectsData?.chart_data?.length > 0 ? (
        <div>
          <Typography.Title level={3}>Category Wise Defect</Typography.Title>
          <Bar {...config} />
        </div>
      ) : (
        <Empty
          style={{
            width: "33%",
            margin: "auto",
            marginTop: "10%",
            marginBottom: "10%",
            height: "100%",
          }}
          description={
            <Alert
              message="No Data Found with selected line-id in Category Wise Defect"
              type="warning"
            />
          }
        />
      )}
    </Card>
  );
};

export default CategoryWiseDefect;
