import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../../apiServices/common";
import {
    ERP_USAGE_ISSUE_LIST_ENDPOINT,
    ERP_USAGE_ISSUE_ADD_ENDPOINT,
    ERP_USAGE_ISSUE_EDIT_ENDPOINT,
    ERP_USAGE_ISSUE_DELETE_ENDPOINT,
    ERP_USAGE_MODULE_UI_LIST_ENDPOINT,
    GET_USERS_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../utils/functions";
import { 
    ERP_USAGE_ISSUE_CREATE_PERMISSION,
    ERP_USAGE_ISSUE_EDIT_PERMISSION,
    ERP_USAGE_ISSUE_DELETE_PERMISSION,
} from '../../../routes/permissions';
import { useLocation } from "react-router";
import CustomDatePicker from "common/CustomDatePicker";
import moment from "moment";

const ERPUsageIssue = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ assignedToUserList, setAssignedToUserList ] = useState();
  const [ erpUIList, setErpUIList ] = useState();
  const [tableDataCreatedByUser, setTableDataCreatedByUser] = useState(null);
  const [tableDataAssignedToUser, setTableDataAssignedToUser] = useState(null);

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };

  // Antd
  const [erpUsageIssueForm] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllERPUsageIssue = useCallback(
    async (filterValues) => {
      const { title, assigned_to_user_id, ui_id, status} = filterValues || {};

      console.log("filterValuesRR", filterValues)

      setLoading(true);

      const query = `${ERP_USAGE_ISSUE_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...filterValues
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        console.log("Issue Data", response?.data?.data?.data);
        setTableData(response?.data?.data?.data);
        // Convert the user_id from localStorage to an integer for comparison
        const loggedInUserId = parseInt(localStorage.getItem('user_id'), 10);
        const filteredIssuesCreatedByUser = response?.data?.data?.data?.filter(item => item.user_id === loggedInUserId);
        const filteredIssuesAssignedToUser = response?.data?.data?.data?.filter(item => item.assigned_to_user_id === loggedInUserId);
        setTableDataCreatedByUser(filteredIssuesCreatedByUser);
        setTableDataAssignedToUser(filteredIssuesAssignedToUser);
      } else {
        setLoading(false);
        message.error("Error getting ERP Usage Issue data");
      }
    },
    [currentPage],
  );


  const getAllAssignedToUsers = async (filterValues) => {
    setLoading(true);

    const query = `${GET_USERS_ENDPOINT}`;
    const payload = {
      ...filterValues,
      user_type: "Vendor",
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("assignedToUsers", response?.data?.data?.data);
      setAssignedToUserList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  const getAllErpUsageUIs = async (filterValues) => {
    setLoading(true);

    const query = `${ERP_USAGE_MODULE_UI_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("erpUsageModules", response?.data?.data?.data);
      setErpUIList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  // Effects
  useEffect(() => {
    getAllERPUsageIssue(filterData);
    getAllAssignedToUsers();
    getAllErpUsageUIs();
  }, [refresh, filterData, getAllERPUsageIssue]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    erpUsageIssueForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectederpUsageIssue = async (id) => {
    setLoading(true);
    const response = await deleteData(`${ERP_USAGE_ISSUE_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("ERP Usage Issue Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectederpUsageIssue = async (formValues) => {
    setLoading(true);

    const bodyData = {
      title: formValues?.title,
      // user_id: formValues?.user_id,
      assigned_to_user_id: formValues?.assigned_to_user_id,
      ui_id: formValues?.ui_id,
      details: formValues?.details,
      // Only add progress dates if user_type is "Vendor"
      ...(localStorage.getItem('user_type') === 'Vendor' && {
        progress_start_date: formValues?.progress_start_date 
          ? moment(formValues?.progress_start_date).format('YYYY-MM-DD') 
          : null,
        progress_end_date: formValues?.progress_end_date 
          ? moment(formValues?.progress_end_date).format('YYYY-MM-DD') 
          : null,
      }),
      status: formValues?.status,
    };

    const response = await putData(
      `${ERP_USAGE_ISSUE_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("ERP Usage Issue Edited Successfully");
      refetch();
      erpUsageIssueForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewerpUsageIssue = async (formValues) => {
    setLoading(true);

    const bodyData = {
      title: formValues?.title,
      user_id: formValues?.user_id,
      assigned_to_user_id: formValues?.assigned_to_user_id,
      ui_id: formValues?.ui_id,
      details: formValues?.details,
      // status: formValues?.status,
    };

    const response = await postData(ERP_USAGE_ISSUE_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("ERP Usage Issue Created Successfully");
      refetch();
      erpUsageIssueForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    erpUsageIssueForm.setFieldsValue({
      id: record?.id,
      title: record?.title,
      user_id: record?.user_info?.name || record?.user_id,
      assigned_to_user_id: record?.assigned_to_user_id,
      ui_id: record?.ui_id,
      details: record?.details,
      progress_start_date: record?.progress_start_date ? moment(record?.progress_start_date).format('YYYY-MM-DD') : null,
      progress_end_date: record?.progress_end_date ? moment(record?.progress_end_date).format('YYYY-MM-DD') : null,
      status: record?.status,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Title",
      dataIndex: ["title"],
      key: "title",
    },
    {
      title: "UI",
      dataIndex: ["ui_info", "name"],
      key: "ui_info",
    },
    {
      title: "Created by",
      dataIndex: ["user_info", "name"],
      key: "user_id",
    },
    {
      title: "Assigned to",
      dataIndex: ["assigned_to_user_info", "name"],
      key: "assigned_to_user_id",
    },
    {
      title: "Progress Start Date",
      dataIndex: "progress_start_date",
      key: "progress_start_date",
      render: (date) => date ? moment(date).format('DD-MMM-YYYY') : '',
    },
    {
      title: "Progress End Date",
      dataIndex: "progress_end_date",
      key: "progress_end_date",
      render: (date) => date ? moment(date).format('DD-MMM-YYYY') : '',
    },
    {
      title: "Resolve Date",
      dataIndex: "resolve_date",
      key: "resolve_date",
      render: (date) => date ? moment(date).format('DD-MMM-YYYY') : '',
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectederpUsageIssue(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([ERP_USAGE_ISSUE_DELETE_PERMISSION]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([ERP_USAGE_ISSUE_EDIT_PERMISSION]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([ERP_USAGE_ISSUE_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([ERP_USAGE_ISSUE_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>ERP Usage Issues</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllERPUsageIssue}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="erp_usage_issue"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={localStorage.getItem('user_type') === 'Super-Admin' || localStorage.getItem('user_type') === 'Admin' ? tableData : localStorage.getItem('user_type') === 'Vendor' ? tableDataAssignedToUser : tableDataCreatedByUser }
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit ERP Usage Issue" : "Create ERP Usage Issue"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={erpUsageIssueForm}
          layout="vertical"
          onFinish={isEdit ? editSelectederpUsageIssue : createNewerpUsageIssue}
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="title"
                placeholder="Title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Title Required",
                  },
                ]}
              >
                <Input disabled={localStorage.getItem('user_type') === 'Vendor' ? true : false} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                name="ui_id"
                placeholder="Select UI"
                label="Select UI"
                rules={[
                  {
                    required: true,
                    message: "UI is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select UI"
                  showSearch
                  optionFilterProp="children"
                  style={{width: "100%"}}
                  onSearch={(value) => getAllErpUsageUIs({ui_id: value})}
                  disabled={localStorage.getItem('user_type') === 'Vendor' ? true : false}
                >
                  {erpUIList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                name="details"
                placeholder="Details"
                label="Details"
                rules={[
                  {
                    required: true,
                    message: "Details Required",
                  },
                ]}
              >
                <Input.TextArea disabled={localStorage.getItem('user_type') === 'Vendor' ? true : false} />
              </Form.Item>
            </Col>
          </Row>
          {isEdit && (
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                name="user_id"
                label="Created by"
                rules={[
                  {
                    required: false,
                    message: "Created by is required",
                  },
                ]}
              >
                {/* Display user_id as read-only text */}
                <Typography.Text>
                  {erpUsageIssueForm.getFieldValue("user_id")}
                </Typography.Text>
              </Form.Item>
            </Col>
          </Row>
          )}
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                name="assigned_to_user_id"
                placeholder="Assign to"
                label="Assign to"
                rules={[
                  {
                    required: true,
                    message: "Assign to is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Assign to"
                  showSearch
                  optionFilterProp="children"
                  style={{width: "100%"}}
                  onSearch={(value) => getAllAssignedToUsers({assigned_to_user_id: value})}
                  disabled={localStorage.getItem('user_type') === 'Vendor' ? true : false}
                >
                  {assignedToUserList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {localStorage.getItem('user_type') === 'Vendor' ? 
          <>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item
                name="progress_start_date"
                placeholder="Progress Start Date"
                label="Progress Start Date"
                rules={[
                  {
                    required: true,
                    message: "Progress Start Date is Required",
                  },
                ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%" }}
                  onChange={() => {
                    // Trigger validation for progress_end_date
                    erpUsageIssueForm.validateFields(["progress_end_date"]);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="progress_end_date"
                placeholder="Progress End Date"
                label="Progress End Date"
                dependencies={["progress_start_date"]}
                rules={[
                  {
                    required: true,
                    message: "Progress End Date is Required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startDate = getFieldValue("progress_start_date");
                      if (!startDate) {
                        return Promise.reject(
                          new Error("Please select Progress Start Date first")
                        );
                      }
                      if (!value || moment(value).isSameOrAfter(startDate)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Progress End Date cannot be earlier than Progress Start Date")
                      );
                    },
                  }),
                ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%" }}
                  disabledDate={(currentDate) => {
                    const startDate = erpUsageIssueForm.getFieldValue("progress_start_date");
                    // Disable all dates if no Progress Start Date is selected
                    return !startDate || currentDate.isBefore(startDate, "day");
                  }}
                  onFocus={() => {
                    const startDate = erpUsageIssueForm.getFieldValue("progress_start_date");
                    if (!startDate) {
                      message.error("Please select Progress Start Date first");
                    }
                  }}
                  onChange={() => {
                    // Trigger validation for progress_start_date to ensure sync
                    erpUsageIssueForm.validateFields(["progress_start_date"]);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          </>
          :null }
          {localStorage.getItem('user_type') === 'Vendor' ? 
          <>
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                name="status"
                placeholder="Select Status"
                label="Select Status"
                rules={[
                  {
                    required: false,
                    message: "Status is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Status"
                  showSearch
                  optionFilterProp="children"
                  style={{width: "100%"}}
                >
                  <Option>Select Status</Option>
                  {/* <Option value="In Use">In Use</Option> */}
                  <Option value="Resolved">Resolved</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          </>
          :null}
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ERPUsageIssue;
