import {
  Card,
  DatePicker,
  InputNumber,
  Divider,
  Space,
  Button,
  PageHeader,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Col, Form, Input, Select, Checkbox, Radio, Table } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import React, { forwardRef, useState, useEffect, useCallback } from "react";
import moment from "moment";
import {
  SALES_CONTRACT_LIST,
  GET_PI_NUMBERS_LIST,
  GET_CUSTOMS_CLEARANCE_LIST,
  ADD_CUSTOMS_CLEARANCE,
  GET_B2B_LC_LIST,
  NOTING_ASSESSMENT_DOC,
  IMPORT_DETAIL_ALL_SUPPLIERS,
  IMPORT_DETAIL_LIST,
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData, getWithData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import { useNavigate } from "react-router-dom";
import { commaSeparateNumber } from "../../../../utils/functions";
import NotingAssessmentDocument from "../../../Common/NotingAssessmentDocument";
import CustomDatePicker from "common/CustomDatePicker";
import { flushSync } from "react-dom";

const NotingForm = forwardRef((props, ref) => {
  // Props
  const { form, editMode, notingId, view, editInfo } = props;

  // States
  const [salesContractList, setSalesContractList] = useState([]);
  const [b2bLcList, setB2bLcList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [b2bLcs, setB2bLcs] = useState([]);
  const [focInvoices, setFocInvoices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [suppliersList, setSuppliersList] = useState([]);

  const [filesList, setFileList] = useState([]);

  var document = [];
  var document_name = [];

  // Router
  const navigate = useNavigate();

  // Antd
  const b2b_lc_id_watch = Form.useWatch("b2b_lc_id", form);

  const getSalesContractList = useCallback(async () => {
    let response = await getData(SALES_CONTRACT_LIST);

    if (response && response?.status === 200) {
      setSalesContractList(response?.data?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  const getImportDetails = useCallback(async (value) => {
    const url = IMPORT_DETAIL_LIST + `?position_type=${value}`;
    let response = await getData(url);

    setInvoices(response?.data?.data?.data || []);
    setFocInvoices(response?.data?.data?.data || []);

    // if (response && response?.status === 200) {
    //   setSalesContractList(response?.data?.data?.data || []);
    // } else {
    //   alertPop(
    //     "error",
    //     response?.messages ? response?.messages : "Something Went Wrong!",
    //   );
    // }
  }, []);

  const getLcNumber = useCallback(
    async (value) => {
      form.resetFields(["import_lc_number"]);
      setSelectedType(value);
      let get_b2blc_list = `/api/accounting/get-all-b2blcs`;
      let res = await getData(get_b2blc_list);
      if (res) {
        setB2bLcList(res?.data?.data || []);
      }
    },
    [form],
  );

  const getBuyerInfo = async (salesContractId) => {
    let response = await getData(
      `${SALES_CONTRACT_LIST}/${salesContractId}/view`,
    );

    if (response && response?.status === 200) {
      form.setFieldValue(
        "buyer_name",
        response?.data?.data?.sales_contract?.buyer_info?.name,
      );
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  };

  const getInvoices = useCallback(async (b2b_lc_id_watch) => {
    let get_pi_list = `/api/accounting/invoice-numbers-list/${b2b_lc_id_watch}/0`;
    let invoices = await getData(get_pi_list);

    if (invoices) {
      setInvoices(invoices?.data?.data || []);
    }
  }, []);

  const getFocInvoices = useCallback(
    async (position_type) => {
      let get_b2blc_list = `/api/accounting/foc-invoice-numbers-list`;
      let response = await getData(get_b2blc_list);

      if (response && response?.status === 200) {
        const prepareData = [...response?.data?.data, editInfo?.invoice_info];
        const filterFalse = prepareData.filter(Boolean);

        setFocInvoices(filterFalse || []);
        setInvoices(filterFalse || []);
      } else {
        alertPop(
          "error",
          response?.messages ? response?.messages : "Something Went Wrong!",
        );
      }
    },
    [editInfo],
  );

  const getB2BLcs = useCallback(async () => {
    let get_b2blc_list = `/api/accounting/get-all-b2blcs`;
    let response = await getData(get_b2blc_list);

    if (response && response?.status === 200) {
      setB2bLcs(response?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  const getSuppliersList = useCallback(async () => {
    let response = await getData(IMPORT_DETAIL_ALL_SUPPLIERS);

    if (response && response?.status === 200) {
      setSuppliersList(response?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  const getLcDetails = async (value, fromOnChange) => {
    let get_b2blc_details = `/api/accounting/import-b2blc/view/${value}`;
    let res = await getData(get_b2blc_details);

    setB2bLcList([res?.data?.data?.[0]?.sales_contract_commercial] || []);

    if (res) {
      //setBtbLcDate(res?.data?.data[0]?.sales_contract_commercial?.b2b_lc_date);
      form.setFieldsValue({
        b2b_lc_date: res?.data?.data[0]?.sales_contract_commercial?.b2b_lc_date
          ? moment(res?.data?.data[0]?.sales_contract_commercial?.b2b_lc_date)
          : null,
        b2b_lc_value:
          res?.data?.data[0]?.sales_contract_commercial?.total_lc_value ||
          "N/A",
        buyer_name:
          res?.data?.data[0]?.sales_contract_commercial?.sale_contract_info
            ?.buyer_info?.name,
        supplier_id: res?.data?.data[0]?.sales_contract_commercial?.supplier_id,
        sales_contract_number:
          res?.data?.data[0]?.sales_contract_commercial?.sale_contract_info
            ?.reference_no,
        sales_contract_id:
          res?.data?.data[0]?.sales_contract_commercial?.sale_contract_info?.id,
        utilization_declearation_number:
          res?.data?.data[0]?.sales_contract_commercial
            ?.sales_contract_commercial_info?.ud_number,
        utilization_declearation_date: res?.data?.data[0]
          ?.sales_contract_commercial?.sales_contract_commercial_info?.ud_date
          ? moment(
              res?.data?.data[0]?.sales_contract_commercial
                ?.sales_contract_commercial_info?.ud_date,
            )
          : null,
      });
    }

    // let get_invoice_list = `/api/accounting/invoice-numbers-list/${value}/0`;
    // let invoices = await getData(get_invoice_list);

    // if (invoices) {
    //   setInvoices(invoices?.data?.data || []);
    // }
  };

  // Effects
  useEffect(() => {
    // getSalesContractList();
    getSuppliersList();
    // getB2BLcs();
  }, [getSuppliersList]);

  useEffect(() => {
    if (editMode) {
      //sales_contract_id_watch && getInvoiceList(sales_contract_id_watch);
      // b2b_lc_id_watch && getInvoices(b2b_lc_id_watch);
      setSelectedType(editInfo?.position_type);
      getLcNumber(editInfo?.position_type);
      getImportDetails(editInfo?.position_type);

      setFileList(
        editInfo?.noting_assessment_document?.map((item) => {
          return {
            id: item?.id,
            name: item?.document_name,
            document: item?.document,
            path: item?.document,
          };
        }),
      );
    }
  }, [editMode, b2b_lc_id_watch, editInfo, getImportDetails, getLcNumber]);

  useEffect(() => {
    if (editMode) {
      // getFocInvoices();
    }
  }, [selectedType, editMode]);

  const onFinishNoting = async (values) => {
    //

    if (filesList?.length) {
      filesList.forEach((item) => {
        // document.push(item);
        document_name.push(item?.name);
        document.push(item?.id);
      });
    }

    const bodyData = {
      position_type: values?.position_type,
      utilization_declearation_number: values?.utilization_declearation_number,
      utilization_declearation_date:
        values.utilization_declearation_date != null
          ? moment(values?.utilization_declearation_date, "YYYY-MM-DD")
          : null,
      supplier_id: values?.supplier_id,
      b2b_lc_id: values?.b2b_lc_id,
      invoice_id: values?.invoice_id,
      forwarder_name: values?.forwarder_name,
      hbl_number: values?.hbl_number,
      qty: values?.qty,
      buyer_name: values?.buyer_name,
      sales_contract_number: values?.sales_contract_number,
      document_rcv_date: values.document_rcv_date
        ? moment(values?.document_rcv_date).format("YYYY-MM-DD")
        : null,
      noting_date: values.noting_date
        ? moment(values?.noting_date).format("YYYY-MM-DD")
        : null,
      custom_assesment_date: values.custom_assesment_date
        ? moment(values?.custom_assesment_date).format("YYYY-MM-DD")
        : null,
      delivery_order_release_date: values.delivery_order_release_date
        ? moment(values?.delivery_order_release_date).format("YYYY-MM-DD")
        : null,
      indent_date: values.indent_date
        ? moment(values?.indent_date).format("YYYY-MM-DD")
        : null,
      shipment_release_date: values.shipment_release_date
        ? moment(values?.shipment_release_date).format("YYYY-MM-DD")
        : null,
      customs_examination_date: values.customs_examination_date
        ? moment(values?.customs_examination_date).format("YYYY-MM-DD")
        : null,
      passbook_entry_date: values.passbook_entry_date
        ? moment(values?.passbook_entry_date).format("YYYY-MM-DD")
        : null,
      import_bill_entry_number: values?.import_bill_entry_number,
      import_bill_entry_date: values.import_bill_entry_date
        ? moment(values?.import_bill_entry_date).format("YYYY-MM-DD")
        : null,
      container_size: values?.container_size,
      container_using_quantity: values?.container_using_quantity,
      document,
      document_name,
    };

    if (editMode) {
      const response = await postData(
        `${GET_CUSTOMS_CLEARANCE_LIST}/${notingId}/edit`,
        bodyData,
      );

      if (response) {
        alertPop(
          "success",
          "Noting Assessment and Examinations Updated Successfully",
        );
        navigate("/import-customs-clearance/noting-assessment-examination");
      } else {
        alertPop("error", "Noting Assessment and Examinations Update Failed!");
      }
    } else {
      const response = await postData(ADD_CUSTOMS_CLEARANCE, bodyData);

      if (response) {
        alertPop(
          "success",
          "Noting Assessment and Examinations Added Successfully",
        );
        navigate("/import-customs-clearance/noting-assessment-examination");
      } else {
        alertPop("error", "Noting Assessment and Examinations Add Failed!");
      }
    }
  };

  const onSelectInvoiceNumber = (value) => {
    const selectedInvoice = invoices.find((item) => item.id === value);

    const invoiceDate = selectedInvoice?.import_date
      ? moment(selectedInvoice?.import_date)
      : null;
    const invoiceValue = selectedInvoice?.invoice_value || "N/A";
    const containerNumber = selectedInvoice?.container_number || "N/A";
    const shipmentMode = selectedInvoice?.shipment_mode || "N/A";
    const shipmentType = selectedInvoice?.shipment_type || "N/A";
    const importQuantity = selectedInvoice?.quantity_ctn || 0;
    const importQuantityUnit = selectedInvoice?.import_quantity_unit || "N/A";

    form.setFieldsValue({ invoice_date: invoiceDate });
    form.setFieldsValue({ invoice_value: invoiceValue });
    form.setFieldsValue({ container_number: containerNumber });
    form.setFieldsValue({ shipment_mode: shipmentMode });
    form.setFieldsValue({ shipment_type: shipmentType });
    form.setFieldsValue({ qty: importQuantity });
  };

  const getInvoiceList = async (filterValues) => {
    console.log(
      "🚀 ~ getInvoiceList ~ filterValues:",
      filterValues,
      selectedType,
    );
    const query = IMPORT_DETAIL_LIST;
    const bodyData = {
      per_page: 10,
      invoice_number: filterValues?.name || "",
      position_type: selectedType,
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      // setBuyingOffice(res?.data?.data?.data || []);
      flushSync(() => setInvoices(res?.data?.data?.data || []));
      flushSync(() => setFocInvoices(res?.data?.data?.data || []));
    }
  };

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishNoting}
      >
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Type of Shipment"
                name="position_type"
                rules={[
                  {
                    required: true,
                    message: "Type is required",
                  },
                ]}
              >
                <Select
                  placeholder="Type of Shipment"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    setSelectedType(value);
                    getImportDetails(value);
                    form.resetFields([
                      "invoice_id",
                      "b2b_lc_id",
                      "b2b_lc_date",
                      "b2b_lc_value",
                      "supplier_id",
                      "utilization_declearation_number",
                      "utilization_declearation_date",
                      "sales_contract_number",
                      "buyer_name",
                      "invoice_date",
                      "invoice_value",
                      "qty",
                    ]);
                  }}
                >
                  <option value="LC">LC</option>
                  <option value="RTGS">RTGS</option>
                  <option value="FOC">FOC</option>
                  <option value="TT">TT</option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Invoice Number"
                name="invoice_id"
                rules={[
                  {
                    required: true,
                    message: "Please input Invoice Number!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Invoice Number"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  onChange={(value) => {
                    const selectedOption = invoices.find((option) => option.id === value);
                    onSelectInvoiceNumber(value);
                    form.setFieldsValue({ b2b_lc_id: value });
                    getLcDetails(selectedOption?.import_lc_number);
                  }}
                  onSearch={(value) => getInvoiceList({ name: value })}
                >
                  {invoices.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.invoice_number}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            {selectedType !== "FOC" && (
              <Col className="gutter-row" span={4}>
                <Form.Item
                  label="Import LC/RTGS/TT No"
                  name="b2b_lc_id"
                  rules={[
                    {
                      required: true,
                      message: "LC/RTGS/TT Number is required",
                    },
                  ]}
                >
                  {selectedType === "FOC" ? (
                    <Input className="w-100" disabled={view} />
                  ) : (
                    <Select
                      showSearch
                      placeholder="LC/RTGS/TT Number"
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        getLcDetails(value);
                      }}
                      disabled
                    >
                      {b2bLcList.map((option) => {
                        return (
                          <option key={option?.id} value={option?.id}>
                            {option.b2b_lc_number}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}

            {selectedType !== "FOC" && (
              <Col className="gutter-row" span={4}>
                <Form.Item
                  label="Import LC/RTGS/TT Date"
                  name="b2b_lc_date"
                  rules={[
                    {
                      required: true,
                      message: "Import LC/RTGS/TT Date is required",
                    },
                  ]}
                >
                  {selectedType === "FOC" ? (
                    <CustomDatePicker size="medium" style={{ width: "100%" }} />
                  ) : (
                    <CustomDatePicker
                      size="medium"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
            )}
            {selectedType !== "FOC" && (
              <Col className="gutter-row" span={4}>
                <Form.Item label="B2B LC Value" name="b2b_lc_value">
                  <InputNumber
                    className="w-100"
                    placeholder="B2B LC Value"
                    disabled
                    style={{ width: "100%" }}
                    formatter={(value) => commaSeparateNumber(value)}
                    precision={2}
                  />
                </Form.Item>
              </Col>
            )}

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Supplier Name"
                name="supplier_id"
                rules={[
                  {
                    required: true,
                    message: "Please input Supplier Name!",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Supplier Name"
                  style={{ width: "100%" }}
                  disabled={selectedType !== "FOC"}
                >
                  {suppliersList.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="UD Number"
                name="utilization_declearation_number"
                rules={[
                  {
                    required: true,
                    message: "Please input UD Number",
                  },
                ]}
              >
                <Input
                  className="w-100"
                  disabled={selectedType !== "FOC"}
                  placeholder="UD Number"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="UD Date"
                name="utilization_declearation_date"
                rules={[
                  {
                    required: true,
                    message: "Please input UD Date",
                  },
                ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                  disabled={selectedType !== "FOC"}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract Number"
                name="sales_contract_number"
              >
                {selectedType === "FOC" ? (
                  <Select
                    showSearch
                    placeholder="Sales Contract"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onSelect={(value) => getBuyerInfo(value)}
                  >
                    {salesContractList.map((option) => {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.reference_no}
                        </option>
                      );
                    })}
                  </Select>
                ) : (
                  <Input
                    className="w-100"
                    disabled={true}
                    placeholder="Sales Contract Number"
                  />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Buyer Name" name="buyer_name">
                <Input
                  disabled={true}
                  className="w-100"
                  placeholder="Buyer Name"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Invoice Date" name="invoice_date">
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Invoice Value" name="invoice_value">
                <InputNumber
                  className="w-100"
                  placeholder="Invoice Value"
                  disabled
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Forwarder Name" name="forwarder_name">
                <Input className="w-100" placeholder="Forwarder Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="HBL/HAWBL Number" name="hbl_number">
                <Input className="w-100" placeholder="HBL Number" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Container Number" name="container_number">
                <Input
                  className="w-100"
                  placeholder="Container Number"
                  disabled
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Container Size" name="container_size">
                <Select
                  placeholder="Container Size"
                  style={{ width: "100%" }}
                  size="small"
                  mode="multiple"
                >
                  <option value="20 GP">20 GP</option>
                  <option value="40 STD">40 STD</option>
                  <option value="40 HC">40 HC</option>
                  <option value="Others">Others</option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Container Using Quantity"
                name="container_using_quantity"
              >
                <Input
                  className="w-100"
                  placeholder="Container Using Quantity"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Mode of Shipment" name="shipment_mode">
                <Input
                  className="w-100"
                  placeholder="Mode of Shipment"
                  disabled
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Shipment Methods" name="shipment_type">
                <Input
                  className="w-100"
                  placeholder="Shipment Methods"
                  disabled
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Import Quantity" name="qty">
                <InputNumber
                  className="w-100"
                  placeholder="Import Quantity"
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Documents Receive Date"
                name="document_rcv_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Documents Receive Date!",
                  },
                ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Noting Date"
                name="noting_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Noting Date!",
                  },
                ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Import Bill of Entry Number"
                name="import_bill_entry_number"
                rules={[
                  {
                    required: true,
                    message: "Please Import Bill of Entry Number!",
                  },
                ]}
              >
                <Input
                  className="w-100"
                  placeholder="Import Bill of Entry Number"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Import Bill Entry Date"
                name="import_bill_entry_date"
                rules={[
                  {
                    required: true,
                    message: "Please Import Bill of Entry Date!",
                  },
                ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Customs Examination Date"
                name="customs_examination_date"
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Customs Assessment Date"
                name="custom_assesment_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Customs Assessment Date!",
                  },
                ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Delivery Order Release Date"
                name="delivery_order_release_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Delivery order release Date!",
                  },
                ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={0}>
              <Form.Item label="B2B LC ID (HIDDEN)" name="b2b_lc_id" hidden>
                <Input
                  className="w-100"
                  placeholder="B2B LC ID (HIDDEN)"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Indent Date for FCL / Unstuffing"
                name="indent_date"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input Indent Date for FCL / Unstuffing!",
                //   },
                // ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Projected Release Date"
                name="shipment_release_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Projected Release Date!",
                  },
                ]}
              >
                <CustomDatePicker
                  size="medium"
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Passbook Entry Date" name="passbook_entry_date">
                <DatePicker
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
            
          </Row> */}
        </Card>
      </Form>
      <NotingAssessmentDocument
        DOC_ADD={NOTING_ASSESSMENT_DOC}
        setFileList={setFileList}
        filesList={filesList}
        view={view}
      />
    </div>
  );
});

export default NotingForm;
