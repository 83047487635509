import { Col, Form, Row, Select, Input } from "antd";

const BuyingOfficeFilterFields = ({ initialTableData }) => {
  const { Option } = Select;

  return (
    <>
      <Col span={4}>
        <Form.Item label="Buying Office Name" name="name">
          <Input placeholder="Write Buying Office Name" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buying Office Short Name" name="short_name">
          <Input placeholder="Write Buying Office Short Name" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Contract Person" name="contract_person">
          <Input placeholder="Write Contact Person" size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default BuyingOfficeFilterFields;
