import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { EditTwoTone } from "@ant-design/icons";
import {
  Button,
  message,
  Table,
  Row,
  Col,
  Typography,
  Modal,
  Form,
  Select,
  DatePicker,
  InputNumber,
  Drawer,
} from "antd";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "apiServices/common";
import {
  LINE_TARGET_LIST,
  LINE_TARGET_ADD,
  LINE_TARGET_UPDATE,
  LINE_TARGET_DELETE,
  GET_SEWING_LINE_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { cglFormatDate, hasPermission, isArrayAndHasValue } from "utils/functions";
import {
  DAILY_LINE_TARGET_CREATE_PERMISSION,
  DAILY_LINE_TARGET_EDIT_PERMISSION,
} from "routes/permissions";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";

const DailyLineTarget = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sewingLines, setSewingLines] = useState(null);

  // Antd
  const [lineTargetForm] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;
  const { confirm } = Modal;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Constants
  const api_base_url = process.env.REACT_APP_BASE_URL;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllLineTarget = useCallback(
    async (filterValues) => {
      const { name } = filterValues || {};

      setLoading(true);

      const query = `${LINE_TARGET_LIST}?page=${currentPage}`;
      const bodyData = {
        ...(name && { name: name }),
      };

      const response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        setLoading(false);
        // setMetaData({
        //   defaultPageSize: response?.data?.data?.per_page,
        //   current: response?.data?.data?.current_page,
        //   defaultCurrent: response?.data?.data?.current_page,
        //   total: response?.data?.data?.total,
        // });
        setTableData(response?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting daily line target data");
      }
    },
    [currentPage],
  );

  const getSewingLines = async () => {
    const query = `${GET_SEWING_LINE_ENDPOINT}`;
    const bodyData = {
      per_page: 10000,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSewingLines(response?.data?.data?.data);
    } else {
      message.error("Error getting sewing lines");
    }
  };

  // Effects
  useEffect(() => {
    getAllLineTarget(filterData);
    getSewingLines();
  }, [refresh, filterData, getAllLineTarget]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    lineTargetForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedLineTarget = async (id) => {
    setLoading(true);
    const response = await deleteData(`${LINE_TARGET_DELETE}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Line Target Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedLineTarget = async (formValues) => {
    setLoading(true);

    const bodyData = {
      target_date: formValues?.target_date,
      targets: sewingLines?.map((line) => {
        return {
          id: line?.id,
          line_id: line?.line_id,
          production_target: line?.production_target || 0,
        };
      }),
    };

    const response = await putData(`${LINE_TARGET_UPDATE}`, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Line Target Edited Successfully");
      refetch();
      lineTargetForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewLineTarget = async (formValues) => {
    setLoading(true);

    const bodyData = {
      target_date: formValues?.target_date,
      targets: sewingLines?.map((line) => {
        return {
          production_target: line?.production_target || 0,
          line_id: line?.id,
        };
      }),
    };

    const response = await postData(LINE_TARGET_ADD, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Line Target Created Successfully");
      refetch();
      lineTargetForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);
    lineTargetForm.setFieldsValue({
      target_date: record?.target_date ? moment(record?.target_date) : null,
      id: record?.id,
    });
    setSewingLines(record?.targets);
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const onChangeTarget = (value, id) => {
    const sewingLinesCopy = [...sewingLines];
    const foundLine = sewingLinesCopy.find((line) => line?.id === id);
    foundLine["production_target"] = value ? Number(value) : 0;
    setSewingLines(sewingLinesCopy);
  };

  const tableColumns = [
    {
      title: "Target Date",
      dataIndex: ["target_date"],
      key: "target_date",
      width: "10%",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Total Target",
      key: "total",
      width: "10%",
      render: (value, record) => {
        const totalTarget = record?.targets?.reduce((acc, curr) => {
          return acc + curr?.production_target;
        }, 0);

        return <span>{totalTarget || 0}</span>;
      },
    },
    {
      title: "Lines with no target",
      key: "lines",
      width: "70%",
      render: (value, record) => {
        const withNoTarget = record?.targets?.filter(
          (item) => item?.production_target === 0,
        );

        return (
          <div>
            {isArrayAndHasValue(record?.targets) &&
              withNoTarget?.map((line, index) => {
                return (
                  <span>{`${line?.line_info?.name}${
                    index !== record?.targets?.length - 1 ? "," : ""
                  }`}</span>
                );
              })}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Button
            icon={<EditTwoTone />}
            size="small"
            onClick={() => {
              openModalForEdit(record);
            }}
            disabled={!hasPermission([DAILY_LINE_TARGET_EDIT_PERMISSION])}
          />
        );
      },
    },
  ];

  const sewingColumns = [
    {
      title: "Line ID",
      dataIndex: ["id"],
      key: "id",
    },
    {
      title: "Line Name",
      dataIndex: isEdit ? ["line_info", "name"] : ["name"],
      key: "name",
    },
    {
      title: "Capacity",
      dataIndex: isEdit ? ["line_info", "capacity"] : ["capacity"],
      key: "capacity",
    },
    {
      title: "Production Target",
      dataIndex: ["production_target"],
      key: "production_target",
      render: (text, record) => {
        return (
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Target"
            onChange={(value) => {
              onChangeTarget(value, record?.id);
            }}
            value={record?.production_target}
          />
        );
      },
    },
  ];

  const DrawerHeader = () => {
    return (
      <Row gutter={6}>
        <Col span={12}>
          <span>{isEdit ? "Edit Line Target" : "Create Line Target"}</span>
        </Col>
        <Col span={12}>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
                size="small"
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                loading={loading}
                size="small"
                onClick={() => lineTargetForm.submit()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const getTotalTargets = (sewingLines) => {
    const totalTarget = sewingLines?.reduce((acc, curr) => {
      return acc + curr?.production_target || 0;
    }, 0);

    return totalTarget;
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([DAILY_LINE_TARGET_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([DAILY_LINE_TARGET_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Daily Line Target</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllLineTarget}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  // defaultPageSize: metaData?.defaultPageSize,
                  // current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  // defaultCurrent: metaData?.defaultCurrent,
                  // total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Drawer
        title={<DrawerHeader />}
        placement="right"
        onClose={onCloseModal}
        open={isModalOpen}
        width={550}
        maskStyle={{ backdropFilter: "blur(4px)" }}
      >
        <Form
          form={lineTargetForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedLineTarget : createNewLineTarget}
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="target_date"
                placeholder="Target Date"
                label="Target Date"
                rules={[
                  {
                    required: true,
                    message: "Target Date Required",
                  },
                ]}
              >
                <CustomDatePicker
                  placeholder="Target Date"
                  style={{ width: 315 }}
                  size="small"
                  disabledDate={(current) =>
                    current.isBefore(moment().subtract(1, "day"))
                  }
                />
                {/* <DatePicker
                  placeholder="Target Date"
                  style={{ width: 315 }}
                  size="small"
                  disabledDate={(current) =>
                    current.isBefore(moment().subtract(1, "day"))
                  }
                /> */}
              </Form.Item>
            </Col>
          </Row>

          <div className="custome-table" style={{ marginBottom: 15 }}>
            <table>
              <thead>
                <tr>
                  {/* Line Name */}
                  <th key="header_line_name" className="bc-orange">
                    Line Name
                  </th>
                  {/* Capacity */}
                  <th key="header_capacity" className="bc-orange">
                    Capacity
                  </th>
                  {/* Production Target */}
                  <th key="header_production_target" className="bc-orange">
                    Target
                  </th>
                </tr>
              </thead>

              <tbody>
                {isArrayAndHasValue(sewingLines) ? (
                  <>
                    {sewingLines.map((obj, index) => {
                      return (
                        <tr key={`table_parent_key_${index}`}>
                          {/* Line Name */}
                          <td className="t-body sticky">
                            {obj?.line_info?.name || obj?.name}
                          </td>
                          {/* Capacity */}
                          <td className="t-body sticky">
                            {obj?.line_info?.capacity || obj?.capacity}
                          </td>
                          {/* Production Target */}
                          <td className="t-body sticky">
                            <InputNumber
                              style={{ width: 100 }}
                              placeholder="Target"
                              onChange={(value) => {
                                onChangeTarget(value, obj?.id);
                              }}
                              value={obj?.production_target}
                              size="small"
                            />
                          </td>
                        </tr>
                      );
                    })}
                    <td className="t-body sticky" colSpan={2}>
                      Total
                    </td>
                    <td className="t-body sticky">
                      {getTotalTargets(sewingLines)}
                    </td>
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default DailyLineTarget;
