import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Affix,
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import Header from "components/Common/Header";
import { ProductionPlanningContext } from "context/ProductionPlanningContext";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
  cglFormatDate,
  commaSeparateNumber,
  isArrayAndHasValue,
} from "utils/functions";
import ProductionPlanningForm from "./ProductionPlanningForm";

export default function ProductionPlanning(props) {
  // Props
  const { editMode, viewMode } = props;

  // Params
  const { id } = useParams();

  // Context
  const {
    form,
    planningDetails,
    existingPlan,
    overlapPlans,
    onFinish,
    onDeletePlan,
    onEditPlan,
    onChangePlanValue,
    onRegenerate,
    getPlanDetails,
    selectedPlanDetails,
    onInquiryAndStyleChange,
    setPlanningDetails,
    setExistingPlan,
    options,
    setOptions,
    onSelectBuyerInquiry,
    onDeleteInitiatePlan,
  } = useContext(ProductionPlanningContext);

  //  Router
  const navigate = useNavigate();

  // Antd
  const { confirm } = Modal;
  const line_watch = Form.useWatch("line", form);
  const shipment_date_watch = Form.useWatch("shipment_date", form);
  const start_date_watch = Form.useWatch("start_date", form);
  const order_qty_watch = Form.useWatch("order_qty", form);
  const planned_qty_watch = Form.useWatch("planned_qty", form);
  const existing_plan_sum_watch = Form.useWatch("existing_plan_sum", form);

  useEffect(() => {
    // Get plan details data on edit mode
    if (id && editMode) {
      getPlanDetails(id);
    }
  }, [id, editMode, getPlanDetails]);

  useEffect(() => {
    // Handle inquiry list and style list when in edit mode and plan is selected
    if (editMode && selectedPlanDetails) {
      const optionsCopy = options;

      const inquiry_list = isArrayAndHasValue(optionsCopy?.inquiryList)
        ? optionsCopy?.inquiryList
        : [];
      const style_list = isArrayAndHasValue(optionsCopy?.styleList)
        ? optionsCopy?.styleList
        : [];

      const isExistsInquiry = inquiry_list?.find(
        (item) => item?.id === selectedPlanDetails?.inquiry_id,
      );
      const isExistsStyle = style_list?.find(
        (item) => item?.id === selectedPlanDetails?.style_id,
      );

      if (!isExistsInquiry) {
        inquiry_list.push({
          id: selectedPlanDetails?.inquiry_id,
          inquiry_no: selectedPlanDetails?.inquiry_no,
        });
        optionsCopy["inquiryList"] = inquiry_list;
        setOptions(optionsCopy);
      }

      if (!isExistsStyle) {
        style_list.push({
          id: selectedPlanDetails?.style_id,
          style_no: selectedPlanDetails?.style_no,
        });
        optionsCopy["styleList"] = style_list;
        setOptions(optionsCopy);
      }
    }
  }, [editMode, selectedPlanDetails, options, setOptions]);

  useEffect(() => {
    // Set form data when edit mode and plan is selected
    if (editMode && selectedPlanDetails) {
      onSelectBuyerInquiry(selectedPlanDetails?.inquiry_id);
      onInquiryAndStyleChange(
        {
          line_id_prop: selectedPlanDetails?.line_id,
          inquiry_id_prop: selectedPlanDetails?.inquiry_id,
          style_id_prop: selectedPlanDetails?.style_id,
          sub_style_id_prop: selectedPlanDetails?.sub_style_id,
          po_map_id: selectedPlanDetails?.po_map_id,
        },
        "edit_mode",
      );

      form.setFieldsValue({
        line: selectedPlanDetails?.line_id,
        inquiry_id: selectedPlanDetails?.inquiry_id,
        sales_contract_id: selectedPlanDetails?.sales_contract_id,
        sales_contract_po_id: selectedPlanDetails?.po_map_id,
        shipment_date: selectedPlanDetails?.shipment_date
          ? moment(selectedPlanDetails?.shipment_date)
          : null,
        start_date: selectedPlanDetails?.start_date
          ? moment(selectedPlanDetails?.start_date)
          : null,
        end_date: selectedPlanDetails?.end_date
          ? moment(selectedPlanDetails?.end_date)
          : null,
        is_apply_learning_curve: selectedPlanDetails?.is_apply_learning_curve
          ? true
          : false,
        planned_mh: selectedPlanDetails?.planned_mh,
        planned_qty: selectedPlanDetails?.planned_qty,
        style_id: selectedPlanDetails?.style_id,
        sub_style_id: selectedPlanDetails?.sub_style_id,
        update_id: selectedPlanDetails?.id,
        factory_id: selectedPlanDetails?.factory_id,
        factory_name: selectedPlanDetails?.factory_name,
        created_with: selectedPlanDetails?.created_with,
        no_of_workers: selectedPlanDetails?.no_of_workers,
      });

      setPlanningDetails(selectedPlanDetails?.pp_initiate_details);
      setExistingPlan(selectedPlanDetails?.existing_plan);
    }
  }, [
    selectedPlanDetails,
    editMode,
    form,
    setPlanningDetails,
    setExistingPlan,
  ]);

  useEffect(() => {
    // Calculate sum of all existing plans
    // Exclude current selected plan in edit mode
    // Save the sum value in a form field to use later in calculations
    let existing_plans = isArrayAndHasValue(existingPlan) ? existingPlan : null;

    if (id && existing_plans) {
      // If current selected plan already exists, then filter the plan
      const filtered_plans = existing_plans?.filter(
        (item) => item.id !== Number(id),
      );

      if (filtered_plans) {
        existing_plans = filtered_plans;
      }
    }

    const existing_plan_sum = isArrayAndHasValue(existing_plans)
      ? existing_plans?.reduce((result, item) => result + item?.plan_qty, 0)
      : 0;

    // Set existing plan sum to field
    form.setFieldsValue({
      existing_plan_sum: existing_plan_sum,
    });
  }, [existingPlan, id, form]);

  useEffect(() => {
    // Find remaining qty from current planned qty and sum of all existing plans
    if (!order_qty_watch) return;

    const remaining_qty =
      order_qty_watch -
      ((planned_qty_watch || 0) + (existing_plan_sum_watch || 0));

    form.setFieldsValue({
      remaining_qty: remaining_qty,
    });
  }, [planned_qty_watch, order_qty_watch, form]);

  const discartForm = () => {
    navigate("/production-planning/dashboard");
  };

  const onPlanDelete = (day) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onDeletePlan(day);
      },
    });
  };

  const planningDetailsColumns = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (row) => cglFormatDate(row),
    },
    {
      title: "Learning Curve",
      children: [
        {
          title: "WH",
          dataIndex: "working_hours",
          key: "lc_working_hours",
          render: (value, record) => {
            return Math.ceil(value);
          },
        },
        {
          title: "Efficiency",
          dataIndex: "efficiency",
          key: "lc_efficiency",
          render: (value, record) => {
            return Number(value).toFixed(2);
          },
        },
        {
          title: "Qty",
          dataIndex: "dayQty",
          key: "lc_dayQty",
          render: (value, record) => {
            return Math.round(value);
          },
        },
      ],
    },
    {
      title: "Plan",
      children: [
        {
          title: "WH",
          dataIndex: "plan_curve_mh",
          key: "plan_curve_mh",
          render: (value, record) => {
            return (
              <InputNumber
                size="small"
                value={value}
                disabled={!record?.isEnableField}
                onChange={(value) => {
                  onChangePlanValue(record?.day, value, "plan_curve_mh");
                }}
                formatter={(val) =>
                  val !== null ? Math.ceil(Number(val)) : null
                }
                parser={(val) => (val ? Math.ceil(parseFloat(val)) : null)}
              />
            );
          },
        },
        {
          title: "Efficiency",
          dataIndex: "plan_curve_efficiency",
          key: "plan_curve_efficiency",
          render: (value, record) => {
            return (
              <InputNumber
                size="small"
                value={value}
                disabled={!record?.isEnableField}
                onChange={(value) => {
                  onChangePlanValue(
                    record?.day,
                    value,
                    "plan_curve_efficiency",
                  );
                }}
              />
            );
          },
        },
        {
          title: "Qty",
          dataIndex: "plan_curve_qty",
          key: "plan_curve_qty",
          render: (value, record) => {
            return (
              <InputNumber
                size="small"
                value={value ? Math.round(value) : 0}
                disabled={true}
                onChange={(value) => {
                  onChangePlanValue(record?.day, value, "plan_curve_qty");
                }}
              />
            );
          },
        },
      ],
    },
    {
      title: "Action",
      children: [
        {
          title: "Delete",
          key: "delete_action",
          render: (record) => (
            <Button
              type="default"
              danger
              size="small"
              onClick={() => onPlanDelete(record?.day)}
              icon={<DeleteOutlined />}
              style={{ fontSize: "13px" }}
            >
              Delete
            </Button>
          ),
        },
        {
          title: "Edit",
          align: "center",
          key: "edit_action",
          render: (record) => (
            <Button
              type="primary"
              size="small"
              onClick={() => onEditPlan(record?.day)}
              icon={<EditOutlined />}
              style={{ fontSize: "13px" }}
            >
              Edit
            </Button>
          ),
        },
        {
          title: "Regenerate",
          key: "regenerate_action",
          render: (record) => (
            <Button
              type="primary"
              size="small"
              disabled={!record?.edited}
              onClick={() => onRegenerate(record)}
            >
              Regenerate
            </Button>
          ),
        },
      ],
    },
  ];

  const existingPlanColumns = [
    {
      title: "Line",
      dataIndex: "line_name",
      key: "line_name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "existing_date",
      render: (row) => cglFormatDate(row),
    },
    {
      title: "Plan Qty",
      dataIndex: "plan_qty",
      key: "plan_qty",
    },
    {
      title: "Actual Qty",
      dataIndex: "actual_qty",
      key: "actual_qty",
    },
  ];

  const overlapPlansColumns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "overlap_sl",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "overlap_date",
      render: (row) => cglFormatDate(row),
    },
    {
      title: "End Date",
      dataIndex: "enddate",
      key: "end_date",
      render: (value) => cglFormatDate(value),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "overlap_buyer",
    },
    {
      title: "Buying Office",
      dataIndex: "buying_office",
      key: "overlap_buyingOffice",
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "overlap_orderStatus",
    },
    {
      title: "Inquiry",
      dataIndex: "inquiry_id",
      key: "inquiry_id",
    },
    {
      title: "Style",
      dataIndex: "style_id",
      key: "style_id",
    },
    {
      title: "SubStyle",
      dataIndex: "sub_style_id",
      key: "sub_style_id",
    },
    {
      title: "PCD",
      dataIndex: "pcd",
      key: "overlap_pcd",
    },
    {
      title: "Ship. Date",
      dataIndex: "shipment_date",
      key: "overlap_shipDate",
      render: (value) => cglFormatDate(value),
    },
    {
      title: "New Date",
      dataIndex: "new_date",
      key: "overlap_newDate",
      render: (value) => cglFormatDate(value),
    },
  ];

  const TableSummary = ({ data }) => {
    if (!data) return null;

    const whTotal =
      isArrayAndHasValue(data) &&
      data.reduce((acc, item) => {
        return acc + Number(item?.working_hours);
      }, 0);

    const dayQtyTotal =
      isArrayAndHasValue(data) &&
      data.reduce((acc, item) => {
        return acc + Number(item?.dayQty);
      }, 0);

    const planWHTotal =
      isArrayAndHasValue(data) &&
      data.reduce((acc, item) => {
        return acc + Number(item?.plan_curve_mh);
      }, 0);

    const planQtyTotal =
      isArrayAndHasValue(data) &&
      data.reduce((acc, item) => {
        return acc + Number(item?.plan_curve_qty);
      }, 0);

    form.setFieldsValue({
      planned_qty: Number(planQtyTotal) || null,
      planned_mh: Number(planWHTotal).toFixed(2) || null,
    });

    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#F5F5F5" }}>
          <Table.Summary.Cell index={0}>
            <strong>{`Total`}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <strong>
              {whTotal ? commaSeparateNumber(Math.ceil(Number(whTotal))) : 0}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <strong>
              {dayQtyTotal
                ? commaSeparateNumber(Math.ceil(Number(dayQtyTotal)))
                : 0}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <strong>
              {planWHTotal
                ? commaSeparateNumber(Math.ceil(Number(planWHTotal)))
                : 0}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            <strong>
              {planQtyTotal
                ? commaSeparateNumber(Math.ceil(Number(planQtyTotal)))
                : 0}
            </strong>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const checkDisabled = () => {
    if (line_watch && shipment_date_watch && start_date_watch) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={`Plan ${editMode ? "Update" : "Entry"}`}
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,

              <Affix offsetTop={20}>
                {editMode && (
                  <Popconfirm
                    title="Confim Delete"
                    description="Are you sure to delete this plan?"
                    onConfirm={() => onDeleteInitiatePlan(id)}
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                  >
                    <Button
                      size="small"
                      key={2}
                      danger
                      disabled={checkDisabled()}
                      type="primary"
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                )}
              </Affix>,
              <Affix offsetTop={20}>
                <Popconfirm
                  title="Confim Save"
                  description="Are you sure to save this plan?"
                  onConfirm={() => onFinish(editMode, id)}
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                >
                  <Button
                    size="small"
                    key={2}
                    type="primary"
                    disabled={checkDisabled()}
                  >
                    Save
                  </Button>
                </Popconfirm>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      <ProductionPlanningForm editMode={editMode} viewMode={viewMode} />
      <Card>
        <Row gutter={24}>
          <Col span={24} hidden={!isArrayAndHasValue(planningDetails)}>
            <Typography.Title level={5}>Planning Details</Typography.Title>
            <Table
              dataSource={planningDetails}
              columns={planningDetailsColumns}
              bordered
              pagination={{
                pageSize: 50,
                showSizeChanger: false,
              }}
              summary={(pageData) => {
                return <TableSummary data={planningDetails} />;
              }}
            />
          </Col>
          <Col span={24} hidden={!isArrayAndHasValue(existingPlan)}>
            <Typography.Title level={5}>Existing Plan</Typography.Title>
            <Table
              dataSource={existingPlan}
              columns={existingPlanColumns}
              bordered
              pagination={false}
            />
          </Col>
          <Col span={24} hidden={!isArrayAndHasValue(overlapPlans)}>
            <Typography.Title
              level={5}
              style={{
                marginTop: 20,
              }}
            >
              Planning Overlapping{" "}
            </Typography.Title>
            <Table
              dataSource={overlapPlans}
              columns={overlapPlansColumns}
              bordered
              pagination={false}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
