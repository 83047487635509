import { Card, Input, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { isArrayAndHasValue } from "../../../utils/functions";

const SubStyle = (props) => {
  // Props
  const { setTableState, tableState, view } = props;

  // Antd
  const { confirm } = Modal;

  const removeSelectedProduct = (indexValue) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const tableStateCopy = [...tableState];
        const foundItem = tableStateCopy.find((item) => item?.index === indexValue);
        foundItem["is_delete"] = true;
        setTableState([...tableStateCopy]);
      },
    });
  };

  const onEditProduct = (indexValue) => {
    const tableStateCopy = [...tableState];
    const foundItem = tableStateCopy.find((item) => item?.index === indexValue);
    foundItem["isEdit"] = foundItem?.isEdit ? false : true;
    setTableState([...tableStateCopy]);
  };

  const onChangeSubStyle = (event, indexValue) => {
    const tableStateCopy = [...tableState];
    const foundItem = tableStateCopy.find((item) => item?.index === indexValue);
    foundItem["sub_style_no"] = event?.target?.value;
    setTableState([...tableStateCopy]);
  };

  return (
    <div className="containt-body pt-3">
      <Card className="mt-3 custome-table" title="Sub Styles Details">
        <table border="1">
          <tr>
            <td className="t-header bc-java ">SL. No. </td>
            <td className="t-header bc-java ">Color</td>
            <td className="t-header bc-java ">Sub Style No.</td>
            {!view && (
              <th className="t-header bc-java " colSpan={2}>
                Actions
              </th>
            )}
          </tr>
          <tbody>
            {isArrayAndHasValue(tableState) && (
              <>
                {tableState?.filter((item) => !item?.is_delete)?.map((obj, index) => (
                  <tr key={index}>
                    <td className="t-body">{index + 1}</td>
                    <td className="t-body">{obj?.color || ""}</td>
                    <td className="t-body">
                      {obj?.isEdit ? (
                        <Input
                          value={obj?.sub_style_no}
                          size="small"
                          placeholder="Enter Substyle No."
                          onChange={(event) =>
                            onChangeSubStyle(event, obj?.index)
                          }
                        />
                      ) : (
                        <span>{obj?.sub_style_no || "N/A"}</span>
                      )}
                    </td>

                    {!view && (
                      <>
                        <td
                          className="t-body"
                          onClick={() => {
                            removeSelectedProduct(obj?.index);
                          }}
                        >
                          <DeleteOutlined
                            className="cursore-pointer"
                            style={{ fontSize: "14px", color: "red" }}
                          />
                        </td>
                        <td
                          className="t-body"
                          onClick={() => {
                            onEditProduct(obj?.index);
                          }}
                        >
                          <EditOutlined
                            className="cursore-pointer"
                            style={{
                              fontSize: "14px",
                              color: obj?.isEdit ? "green" : "grey",
                            }}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default SubStyle;
