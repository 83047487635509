import {
    GET_BOM_LIST,
    CREATE_NEW_BOM,
    DELETE_BOM,
    GET_BOM_DETAIL, UPDATE_BOM_DETAIL
} from '../ActionTypes';

const initialState = {
    BOMList: [],
    totalBOM: null,
    selectedBOM: null,
    selectedBOMDetails: null,
    createdBOM: null,
    selectedBOMAccountInfo: null,
    pageSize: 10,
    page:1,
};

const BOMReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_BOM_LIST:
            return {
                ...state,
                BOMList: action.payload.data,
                totalBOM: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_BOM:
            return {
                ...state,
                createdBOM: action.payload.data,
                BOMList: [action.payload.data, ...state.BOMList],
                totalBOM: state.totalBOM + 1,
            };

        case DELETE_BOM: {
            return {
                ...state,
                BOMList: action.payload.list,
                totalBOM: action.payload.total,
            };
        }

        case GET_BOM_DETAIL:
            return {
                ...state,
                selectedBOM: action.payload,
                selectedBOMAccountInfo: action.payload,
                selectedBOMDetails: action.payload,
            };

        case UPDATE_BOM_DETAIL:
            return {
                ...state,
                selectedBOM: action.payload,
                BOMList: state.BOMList.map((BOM) =>
                    BOM.id === action.payload.id ? action.payload : BOM,
                ),
            };

        default:
            return state;
    }
};

export default BOMReducer;
