import React, { useEffect } from "react";
import AuthRouter from "./AuthRouter";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router";
import RequireAuth from "../common/RequireAuth";

const AuthLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="auth-page">
      <Routes>
        {AuthRouter.map((routeItem, index) => {
          return (
            <Route
              element={
                <RequireAuth
                  allowedpermissions={routeItem?.allowedpermissions}
                />
              }
              key={routeItem?.id || index}
            >
              <Route path={routeItem?.path} element={routeItem?.component} />
            </Route>
          );
        })}
      </Routes>
    </div>
  );
};

export default React.memo(AuthLayout);
