import { Alert, Card, Col, Row, Table, Typography, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import {
  EXPORT_POST_COSTING_REPORT,
  VIEW_POST_COSTING_REPORT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import CustomFilter from "../../Common/CustomFilter";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";

const PostCostingReport = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPreviewClicked, setIsPreviewClicked] = useState(true);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const exportDetails = async (filterValues) => {
    if (!Object.values(filterValues).some((val) => val === undefined)) {
      setLoading(true);
      const query = `${EXPORT_POST_COSTING_REPORT}`;
      const bodyData = {
        ...filterValues,
      };
      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "blob" },
        "arraybuffer",
      );

      if (response && response?.data) {
        // Download the file
        const outputFilename = `post-costing-report.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
        message.success("Post Costing Report Exported Successfully");
        refetch();
      } else {
        setLoading(false);
        setIsPreviewClicked(true);
        message.error("Something went wrong");
      }
    } else {
      setIsPreviewClicked(true);
    }
  };

  const previewFunction = async (filterValues) => {
    setLoading(true);

    const bodyData = {
      ...filterValues,
      preview: 1,
    };

    const query = `${VIEW_POST_COSTING_REPORT}`;
    const response = await postData(query, bodyData);

    if (response?.code === 200) {
      const processedData = [];

      response?.data?.data_row.forEach((data) => {
        data?.import_pis?.forEach((pi) => {
          processedData.push({
            id: data?.id,
            acceptance: data?.acceptance,
            b2b_lc_number: data?.b2b_lc_number,
            total_lc_value: data?.total_lc_value,
            goods_description: pi?.goods_description,
            supplier_name: pi?.supplier_info?.name,
            pi_number: pi?.pi_number,
            pi_value: pi?.pi_value,
            invoice_number: null,
            invoice_value: null,
          });
        });

        data?.invoices?.forEach((invoice) => {
          processedData.push({
            id: data?.id,
            acceptance: data?.acceptance,
            b2b_lc_number: data?.b2b_lc_number,
            total_lc_value: data?.total_lc_value,
            goods_description: null,
            supplier_name: null,
            pi_number: null,
            pi_value: null,
            invoice_number: invoice?.invoice_number,
            invoice_value: invoice?.invoice_value,
          });
        });
      });

      const data = processedData.reduce((acc, current, currentIndex, array) => {
        if (acc[current.id]) return acc;
        const filter = array.filter((item) => item.id === current.id);
        if (filter.length > 1) {
          // Fill null values with corresponding values from the array
          filter.forEach((item, index) => {
            if (item.goods_description === null) {
              item.goods_description = filter.find(
                (el) => el.goods_description !== null,
              )?.goods_description;
            }
            if (item.supplier_name === null) {
              item.supplier_name = filter.find(
                (el) => el.supplier_name !== null,
              )?.supplier_name;
            }
            if (item.pi_number === null) {
              item.pi_number = filter.find(
                (el) => el.pi_number !== null,
              )?.pi_number;
            }
            if (item.pi_value === null) {
              item.pi_value = filter.find(
                (el) => el.pi_value !== null,
              )?.pi_value;
            }
            if (item.invoice_number === null) {
              item.invoice_number = filter.find(
                (el) => el.invoice_number !== null,
              )?.invoice_number;
            }
            if (item.invoice_value === null) {
              item.invoice_value = filter.find(
                (el) => el.invoice_value !== null,
              )?.invoice_value;
            }
          });
          acc[current.id] = filter;
        }
        return acc;
      }, {});

      const table1 = [
        {
          name: "Company",
          data: response.data.company,
        },
        {
          name: "Buyer Name",
          data: response.data.buyer_name,
        },
        {
          name: "Master LC No",
          data: response.data.master_lc_no,
        },
        {
          name: "Master LC Date",
          data: response.data.master_lc_date,
        },

        {
          name: "Sales Contract Date",
          data: response.data.sales_contract_date,
        },
        {
          name: "Sales Contract No",
          data: response.data.sales_contract_no,
        },
        {
          name: "Order Qty",
          data: response.data.order_qty,
        },
        {
          name: "Order Value",
          data: response.data.order_value,
        },
        {
          name: "FOB Value",
          data: response.data.fob,
        },
        {
          name: "B2B Value",
          data: response.data.b2b_value,
        },
      ];
      const table2 = [
        {
          name: "Shipment Closing On",
          data: response.data.shipment_closing_on,
        },
        {
          name: "CM PER PC",
          data: response.data.cm_per_pc,
        },
        {
          name: "Total CM",
          data: response.data.other_charges,
        },
        {
          name: "Other Charges",
          data: response.data.other_charges,
        },
        {
          name: "Shipment Quantity",
          data: response.data.shipment_quantity,
        },
        {
          name: "Export Value",
          data: response.data.export_value,
        },
        {
          name: "Excess Shipment",
          data: response.data.excess_shipment,
        },
        {
          name: "Excess Short Shipment Value",
          data: response.data.excess_short_shipment_value,
        },
        {
          name: "Total B2B Opended",
          data: response.data.total_btb_opended,
        },
        {
          name: "Savings From B2B",
          data: response.data.savings_from_btb,
        },
      ];
      setTableData({
        table1: table1,
        table2: table2,
        table3: Object.values(data).flat(),
        table4: {
          cutting_qty: response?.data?.cutting_qty,
          ex_factory_qty: response?.data?.ex_factory_qty,
          reject_qty: response?.data?.reject_qty,
          good_qty: response?.data?.good_qty,
        },
        netSaving: response?.data?.net_savings_loss,
      });
      setIsPreviewClicked(false);
      setLoading(false);
    } else {
      setLoading(false);
      setIsPreviewClicked(true);
      message.error("Something went wrong");
    }
  };

  // Effects
  // useEffect(() => {
  //   getAllDetails();
  // }, [refresh, getAllDetails]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const firstColumns = [
    {
      dataIndex: "name",
      key: "name",
      render: (text) => <strong>{text}</strong>,
    },
    {
      dataIndex: "data",
      key: "data",
    },
  ];

  const lastColumns = [
    {
      title: "Cutting Qty",
      dataIndex: "cutting_qty",
      key: "cutting_qty",
    },
    {
      title: "Ex Factory Qty",
      dataIndex: "ex_factory_qty",
      key: "ex_factory_qty",
    },
    {
      title: "Reject Qty",
      dataIndex: "reject_qty",
      key: "reject_qty",
    },
    {
      title: "Good Qty",
      dataIndex: "good_qty",
      key: "good_qty",
    },
  ];

  const columns = [
    {
      title: "Item",
      dataIndex: "goods_description",
      key: "goods_description",
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    {
      title: "PI No.",
      dataIndex: "pi_number",
      key: "pi_number",
    },
    {
      title: "PI Value",
      dataIndex: "pi_value",
      key: "pi_value",
    },
    {
      title: "B2B LC No.",
      dataIndex: "b2b_lc_number",
      key: "b2b_lc_number",
    },
    {
      title: "B2B Value (USD)",
      dataIndex: "total_lc_value",
      key: "total_lc_value",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
      key: "invoice_number",
    },
    {
      title: "Invoice Value (USD)",
      dataIndex: "invoice_value",
      key: "invoice_value",
    },
    {
      title: "Payment",
      dataIndex: "acceptance",
      key: "acceptance",
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <CustomFilter
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        tableData={tableData}
        fetchTableData={exportDetails}
        filterName="post-costing-report"
        searchButtonText="Export"
        isPreview={true}
        previewFunction={previewFunction}
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportDetails}
                hideAddButton={true}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Post Costing Report</Title>}
          />
        }
        content={
          <>
            <div className="containt-body">
              <Card>
                {memoizedCustomFilter}
              </Card>
              <Row justify={"space-between"}>
                {isPreviewClicked && (
                  <Col span={24}>
                    <Alert
                      style={{ margin: "10px 50px" }}
                      message="Please select Sales Contract to view Post Costing Report."
                      type="warning"
                    />
                  </Col>
                )}
                {tableData?.table1 && tableData?.table2 ? (
                  <>
                    <Col span={24}>
                      <Title
                        level={4}
                        style={{
                          textAlign: "center",
                          backgroundColor: "#1b4b72",
                          margin: 0,
                          padding: 8,
                          color: "#fff",
                        }}
                      >
                        Post Costing Report
                      </Title>
                    </Col>
                    <Col span={10}>
                      <Table
                        loading={loading}
                        dataSource={tableData?.table1}
                        showHeader={false}
                        columns={firstColumns}
                        scroll={{ y: "max-content" }}
                        bordered
                        pagination={false}
                      />
                    </Col>
                    <Col span={10}>
                      <Table
                        loading={loading}
                        showHeader={false}
                        dataSource={tableData?.table2}
                        columns={firstColumns}
                        scroll={{ y: "max-content" }}
                        bordered
                        pagination={false}
                      />
                    </Col>
                    <Col span={24}>
                      <Title
                        level={4}
                        style={{
                          textAlign: "center",
                          border: "1px solid #f0f0f0",
                          margin: 0,
                          padding: 8,
                        }}
                      >
                        {"Net Savings / (Loss):"}{" "}
                        {tableData?.netSaving ? tableData?.netSaving : 0}
                      </Title>
                    </Col>
                  </>
                ) : null}
              </Row>

              <br />
              {tableData?.table3 && (
                <Table
                  loading={loading}
                  dataSource={tableData?.table3}
                  columns={columns}
                  scroll={{ y: "max-content" }}
                  bordered
                  pagination={true}
                />
              )}
              {tableData?.table4 && (
                <Row justify={"center"}>
                  <Col span={8}>
                    <Table
                      loading={loading}
                      dataSource={[tableData?.table4]}
                      columns={lastColumns}
                      bordered
                      scroll={"max-content"}
                      pagination={false}
                    />
                  </Col>
                </Row>
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default PostCostingReport;
