import { Affix, Button, Card, Form } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import BuyerInquiryForm from "./BuyerInquiryForm";

const AddBuyerInquiry = (props) => {
  // Props
  const { width } = props;

  // States
  const [styles, setStyles] = useState([]);
  const [users, setUsers] = useState([]);
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [garmentCategoryList, setGarmentCategoryList] = useState([]);
  const [garmentTypeList, setGarmentTypeList] = useState([]);
  const [garmentForList, setGarmentForList] = useState([]);
  const [brand, setBrand] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Antd
  const [form] = Form.useForm();

  // Others
  const navigate = useNavigate();

  const discartForm = () => {
    navigate("/merchandising/buyer-inquiry");
  };

  const addBuyerInquiry = () => {
    console.log('addBuyerInquiry');
    const formValues = form.getFieldsValue();
    console.log('formValues', formValues);
    
    
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Add Buyer Inquiry"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} danger onClick={() => discartForm()}>
                Discard
              </Button>,
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={addBuyerInquiry}
                loading={isLoading}
              >
                Add Buyer Inquiry
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <BuyerInquiryForm
        form={form}
        users={users}
        setUsers={setUsers}
        styles={styles}
        setStyles={setStyles}
        buyingOffice={buyingOffice}
        setBuyingOffice={setBuyingOffice}
        buyer={buyer}
        setBuyer={setBuyer}
        garmentCategoryList={garmentCategoryList}
        setGarmentCategoryList={setGarmentCategoryList}
        garmentTypeList={garmentTypeList}
        setGarmentTypeList={setGarmentTypeList}
        garmentForList={garmentForList}
        setGarmentForList={setGarmentForList}
        brand={brand}
        setBrand={setBrand}
        documents={documents}
        setDocuments={setDocuments}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default AddBuyerInquiry;
