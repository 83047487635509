import { Col, Form, Row, Select, Input, DatePicker } from "antd";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  SALES_CONTRACT_LIST,
  STYLE_MERCHANDISING_LIST,
  GET_SUPPLIER_ENDPOINT,
  ALL_SALES_CONTRACT_LIST,
  GET_LOCAL_SUPPLIER_LISTS,
  GET_ALL_LOCAL_SUPPLIERS_ALL_INVOICES
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
const LocalPurchaseReconciliationFilterFields = ({ initialTableData }) => {

  const { Option } = Select;

  const [supplierId, setSupplierId] = useState(null);
  const [localSuppliers, setLocalSuppliers] = useState([]);
  const [localSupplierInvoices, setLocalSupplierInvoices] = useState([]);

    // Effects
    useEffect(() => {
        getLocalSuppliers();
        getSupplierInvoiceLists()
    }, []);

    // local purchase reconciliation
    const getLocalSuppliers = async(filterValues) => {

        const bodyData = {
          local_status: 1,
          ...filterValues,
        };
    
        const response = await getData(GET_LOCAL_SUPPLIER_LISTS, false, bodyData);
        if (response && response?.data?.code === 200) {
          setLocalSuppliers(response?.data?.data)
        }
    };

    const getSupplierInvoiceLists = async () => {

        const query = `${GET_ALL_LOCAL_SUPPLIERS_ALL_INVOICES}`;
        const response = await getData(query);
    
        if (response && response?.data?.code==200) {
          setLocalSupplierInvoices(response?.data?.data || []);
        }
    };


  return (
    <>
        <Col className="gutter-row" span={4}>
            <Form.Item
                label="Local Suppliers"
                name="supplier_id"
                // rules={[
                //     {
                //     required: true,
                //     message: "Supplier is required",
                //     },
                // ]}
            >
                <Select
                  showSearch
                  placeholder="Suppliers"
                  style={{ width: "100%" }}
                  onSelect={(value) => {
                    setSupplierId(value)
                    getSupplierInvoiceLists(value);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {localSuppliers.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
            </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
            <Form.Item
                label="Local Supplier Invoices"
                name="import_details_id"
                // rules={[
                //     {
                //     required: true,
                //     message: "Invoice is required",
                //     },
                // ]}
            >
            <Select
                showSearch
                placeholder="Invoice"
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                }
            >
                {localSupplierInvoices.map((option) => {
                return (
                    <option key={option.id} value={option.id}>
                    {option.invoice_number}
                    </option>
                );
                  })}
            </Select>
        </Form.Item>
    </Col>
      
    </>
  );
};

export default LocalPurchaseReconciliationFilterFields;
