import { Col, PageHeader, Affix } from "antd";
import "./index.scss";
import { Row } from "antd";

const AppPageHeader = (props) => {
  const Content = (props) => (
    <Affix offsetTop={20}>
      <Row>
        <Col span={16}>
          <div className="content">
            <div className="main">{props.children && props.children}</div>
          </div>
        </Col>
        <Col span={4} offset={4}>
          <div style={{ float: "right" }}>{props.extra && props.extra}</div>
        </Col>
      </Row>
    </Affix>
  );
  return (
    <>
      <Affix offsetTop={0}>
        <PageHeader
          className="app-page-header buttonRight"
          title={props.title && props.title}
          subTitle={props.subTitle && props.subTitle}
        >
          <Content extra={props.extra}>{props.content}</Content>
        </PageHeader>
      </Affix>
    </>
  );
};

export default AppPageHeader;
