import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postData, getData } from "../../../../apiServices/common";
import AddPiForm from "./AddPiForm";

export default function DeletePi(props) {
  
  
}
