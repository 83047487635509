import { MinusCircleOutlined } from "@ant-design/icons";
import { Form, Table, Popconfirm, Typography, Button, Collapse } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  onCreateMerchandiser,
  onCreateSupplier,
  onGetBuyerList,
  onGetMerchandiserList,
  onGetSupplierList,
} from "../../../redux/actions";
import MerchandiserCreateForm from "./CreateForm";
const { Panel } = Collapse;

const CreateMerchandiser = (props) => {
  const dispatch = useDispatch();
  const supplierList = useSelector(({ supplier }) => supplier.supplierList);

  const { selectedBuyingOffice } = props;
  const merchandiserList = useSelector(
    ({ merchandiser }) => merchandiser.merchandiserList,
  );
  const buyerList = useSelector(({ buyer }) => buyer.buyerList);
  const [refresh, setRefresh] = useState(0);
  const [selectedBuyer, setSelectedBuyer] = useState([]);
  const [merchandiserBuyer, setMerchandiserBuyer] = useState(null);
  const [selectedMerchandiser, setSelectedMerchandiser] = useState(null);
  const [merchandiserSupplier, setMerchandiserSupplier] = useState(null);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const [merchandiserName, setMerchandiserName] = useState("");
  const [address, setMerchandiserAddress] = useState("");
  const [phoneNumber, setMerchandiserPhoneNumber] = useState("");
  const [businessStatus, setBusinessStatus] = useState([]);
  const [supplierName, setSupplierName] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [supplierPhoneNumber, setSupplierPhoneNumber] = useState("");
  const [merchandiserBusinessStatus, setMerchandiserBusinessStatus] =
    useState("");
  const [merchandiserPurpose, setMerchandiserPurpose] = useState("");
  const [selectedMerchandiserPhoneNumber, setSelectedMerchandiserPhoneNumber] =
    useState("");

  const [form] = Form.useForm();

  const [buyingOfcMerchandiser, setBuyingOfcMerchandiser] = useState([]);
  const [createdMerchandiserList, setCreatedMerchandiserList] = useState([]);

  useEffect(() => {
    if(selectedBuyingOffice) {
      const merchandiserInfo = selectedBuyingOffice?.merchandiser_part?.map(
        (item, idx) => {
          return {
            merchandiser: item?.merchandiser?.name,
            merchandiser_id: item?.merchandiser?.id,
            buyer_name: item?.buyer?.name,
            buyer_id: item?.buyer?.id,
            supplier_name: item?.competitor?.name,
            supplier_id: item?.competitor?.id,
            purpose: item?.purpose,
            contact_number: item?.merchandiser?.phone,
          };
        },
      );
      setBuyingOfcMerchandiser(merchandiserInfo);
      setCreatedMerchandiserList(merchandiserInfo);
    }
  }, [selectedBuyingOffice]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const onSupplierAddressChange = (event, value) => {
    setSupplierAddress(event.target.value);
  };

  const onSupplierPhoneNumberChange = (event, value) => {
    setSupplierPhoneNumber(event.target.value);
  };
  const onSupplierNameChange = (event, value) => {
    setSupplierName(event.target.value);
  };
  const onAddressChange = (event, value) => {
    setMerchandiserAddress(event.target.value);
  };
  const onPurposeChange = (event, value) => {
    setMerchandiserPurpose(event.target.value);
  };
  const onPhoneNumberChange = (event, value) => {
    setMerchandiserPhoneNumber(event.target.value);
  };
  const onMerchandiserNameChange = (event, value) => {
    setMerchandiserName(event.target.value);
  };

  const onFinish = (values) => {
    const formValues = values;
    formValues["country_name"] = "BD";
    formValues["swift_code"] = "123";
    formValues["status"] = "1";
    formValues["buyer_unique_id"] = merchandiserBuyer?.buyer_unique_id;
    formValues["short_name"] = merchandiserBuyer?.short_name;
    formValues["address"] = merchandiserBuyer?.address;
    const merchandiseObj = {
      buyer_name: merchandiserBuyer?.name,
      supplier_name: merchandiserSupplier?.name,
      business_status: merchandiserBusinessStatus,
      merchandiser: selectedMerchandiser?.name,
      merchandiser_id: selectedMerchandiser?.id,
      buyer_id: merchandiserBuyer?.id,
      supplier_id: merchandiserSupplier?.id,
      contact_number: selectedMerchandiser?.phone,
      purpose: values?.purpose,
    };
    setCreatedMerchandiserList((prev) => [...prev, merchandiseObj]);
    // dispatch(onCreateBuyer(formValues));
    refetch();
    form.resetFields();
  };

  const handleDelete = (record, index) => {
    const data = createdMerchandiserList.filter((item) => item !== record);
    setCreatedMerchandiserList(data);
  };

  localStorage.setItem(
    "buyerIds",
    JSON.stringify(createdMerchandiserList.map((item) => item?.buyer_id)),
  );
  localStorage.setItem(
    "merchandiserIds",
    JSON.stringify(
      createdMerchandiserList.map((item) => item?.merchandiser_id),
    ),
  );
  localStorage.setItem(
    "competitorsIds",
    JSON.stringify(createdMerchandiserList.map((item) => item?.supplier_id)),
  );
  localStorage.setItem(
    "businessStatus",
    JSON.stringify(
      createdMerchandiserList.map((item) => item?.business_status),
    ),
  );
  localStorage.setItem(
    "purpose",
    JSON.stringify(createdMerchandiserList.map((item) => item?.purpose)),
  );

  const onSupplierChange = (value, options) => {
    setMerchandiserSupplier(options);
    setSupplierName(options.label);
  };
  const onMerchandiserChange = (value, options) => {
    const merchant = merchandiserList?.find((item) => item?.id === value);
    setSelectedMerchandiser(merchant);
  };
  const addMerchandiser = (e) => {
    e.preventDefault();
    const formValues = {};
    formValues["name"] = merchandiserName;
    formValues["address"] = address;
    formValues["status"] = "1";
    formValues["phone"] = phoneNumber;
    formValues["purpose"] = merchandiserPurpose;

    dispatch(onCreateMerchandiser(formValues));
    setMerchandiserAddress("");
    setMerchandiserName("");
    setMerchandiserPhoneNumber("");
    refetch();
  };

  const addSupplier = (e) => {
    e.preventDefault();
    const formValues = {};
    formValues["name"] = supplierName;
    formValues["address"] = supplierAddress;
    formValues["status"] = "1";
    formValues["phone"] = supplierPhoneNumber;

    dispatch(onCreateSupplier(formValues));
    setSupplierAddress("");
    setSupplierName("");
    setSupplierPhoneNumber("");
  };

  useEffect(() => {
    setSelectedMerchandiserPhoneNumber(selectedMerchandiserPhoneNumber);
    dispatch(
      onGetBuyerList({
        page: 1,
        per_page: 10,
      }),
    );
    dispatch(onGetMerchandiserList(1, 10));
    dispatch(onGetSupplierList(1, 10));
    // form.resetFields();
  }, [refresh, selectedBuyingOffice]);

  const onBuyerChange = (value, options) => {
    setMerchandiserBuyer(options);
    setSelectedBuyer((prev) => [...prev, options]);
    setName(options.label);
  };

  const businessStatusOpt = [
    {
      value: "ongoing",
      label: "Ongoing",
    },
    {
      value: "under_development",
      label: "Under Development",
    },
  ];

  const onChangeBusinessStatus = (value, options) => {
    setBusinessStatus((prev) => [...prev, value]);
    const currentSupplier = supplierList.find(
      (item) => item?.name.toLowerCase() === "CGL".toLowerCase(),
    );
    if (value === "ongoing") {
      setMerchandiserSupplier(currentSupplier);
      form.setFieldValue("current_supplier", currentSupplier?.id);
    } else {
      form.setFieldValue(
        "current_supplier",
        form.getFieldValue("current_supplier"),
      );
    }
    setMerchandiserBusinessStatus(value);
  };
  const tableColumns = [
    {
      title: "Merchandiser Name",
      dataIndex: "merchandiser",
      key: "merchandiser",
    },
    { title: "Buyer Name", dataIndex: "buyer_name", key: "buyer_name" },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
    },
    { title: "Purpose", dataIndex: "purpose", key: "purpose" },

    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <>
          {createdMerchandiserList && (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                handleDelete(record, index);
              }}
            >
              <a style={{ color: "red", marginLeft: 5 }} type="button">
                <span>
                  <MinusCircleOutlined />
                </span>
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Collapse
        defaultActiveKey={["1"]}
        style={{ marginTop: 15, marginBottom: 25 }}
      >
        <Panel
          header="Add Buyer Information"
          key="1"
          style={{ fontWeight: 700 }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            initialValues={{
              name:
                buyingOfcMerchandiser?.length === 1 &&
                buyingOfcMerchandiser?.[0]?.buyer_name,
              current_supplier:
                (buyingOfcMerchandiser?.length === 1 &&
                  buyingOfcMerchandiser?.[0]?.supplier_name) ||
                "",
              merchandiser_name:
                (buyingOfcMerchandiser?.length === 1 &&
                  buyingOfcMerchandiser?.[0]?.merchandiser) ||
                "",
              phone:
                (buyingOfcMerchandiser?.length === 1 &&
                  buyingOfcMerchandiser?.[0]?.contact_number) ||
                "",
              purpose:
                (buyingOfcMerchandiser?.length === 1 &&
                  buyingOfcMerchandiser?.[0]?.purpose) ||
                "",
            }}
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <MerchandiserCreateForm
              merchandiserSupplier={merchandiserSupplier}
              supplierList={supplierList}
              merchandiserPurpose={merchandiserPurpose}
              onPurposeChange={onPurposeChange}
              buyingOfcMerchandiser={buyingOfcMerchandiser}
              onBuyerChange={onBuyerChange}
              onMerchandiserChange={onMerchandiserChange}
              selectedMerchandiser={selectedMerchandiser}
              supplierAddress={supplierAddress}
              address={address}
              buyerList={buyerList}
              merchandiserList={merchandiserList}
              addMerchandiser={addMerchandiser}
              addSupplier={addSupplier}
              onAddressChange={onAddressChange}
              onSupplierAddressChange={onSupplierAddressChange}
              onMerchandiserNameChange={onMerchandiserNameChange}
              onSupplierPhoneNumberChange={onSupplierPhoneNumberChange}
              inputRef={inputRef}
              merchandiserName={merchandiserName}
              supplierPhoneNumber={supplierPhoneNumber}
              supplierName={supplierName}
              onSupplierNameChange={onSupplierNameChange}
              phoneNumber={phoneNumber}
              name={name}
              onPhoneNumberChange={onPhoneNumberChange}
              onChangeBusinessStatus={onChangeBusinessStatus}
              selectedMerchandiserPhoneNumber={selectedMerchandiserPhoneNumber}
              businessStatusOpt={businessStatusOpt}
              onSupplierChange={onSupplierChange}
              businessStatus={businessStatus}
            />
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                size="small"
                style={{ float: "right" }}
              >
                Add
              </Button>
            </Form.Item>
          </Form>

          <Table
            columns={tableColumns}
            dataSource={createdMerchandiserList}
            pagination={false}
          />
        </Panel>
      </Collapse>
    </>
  );
};
export default CreateMerchandiser;
