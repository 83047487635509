import { Form, Typography } from "antd";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import ImportLcExpenseForm from "../Form";

// Antd Constants
const { Title } = Typography;
export default function ImportLcExpenseView() {
  const [form] = Form.useForm();

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent hideFilterButton hideAddButton showBackButton />
            }
            title={<Title level={5}>Import LC Expense View</Title>}
          />
        }
        content={
          <>
            <ImportLcExpenseForm form={form} mode="view" />
          </>
        }
      />
    </>
  );
}
