import { Col, Form, message, Select } from "antd";
import { getData } from "apiServices/common";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";

export default function InitialSAMEntryFilter() {
  const [styles, setStyles] = useState([]);
  const [buyingOffices, setBuyingOffices] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [factories, setFactories] = useState([]);

  // Fetch filter options
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const styleRes = await getData("/api/styles");
        const buyingOfficeRes = await getData("/api/buying_office");
        const buyerRes = await getData("/api/buyers");
        const factoryRes = await getData("/api/sub-contract-factory");
        setStyles(styleRes.data?.data?.data || []);
        setBuyingOffices(buyingOfficeRes.data?.data?.data || []);
        setBuyers(buyerRes.data?.data?.data || []);
        setFactories(factoryRes.data?.data?.data || []);
      } catch (error) {
        console.error("Error fetching filter options:", error);
        message.error("Failed to fetch filter options.");
      }
    };

    fetchFilterOptions();
  }, []);

  // Fetch styles based on search query with debounce
  const fetchStyles = async (value) => {
    try {
      const res = await getData("/api/styles", null, { style_no: value });
      setStyles(res.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching styles:", error);
      message.error("Failed to fetch styles.");
    }
  };

  const debouncedFetchStyles = useCallback(debounce(fetchStyles, 500), []);

  // Fetch buying offices based on search query with debounce
  const fetchBuyingOffices = async (value) => {
    try {
      const res = await getData("/api/buying_office", null, { name: value });
      setBuyingOffices(res.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching buying offices:", error);
      message.error("Failed to fetch buying offices.");
    }
  };

  const debouncedFetchBuyingOffices = useCallback(
    debounce(fetchBuyingOffices, 500),
    [],
  );

  // Fetch buyers based on search query with debounce
  const fetchBuyers = async (value) => {
    try {
      const res = await getData("/api/buyers", null, { name: value });
      setBuyers(res.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching buyers:", error);
      message.error("Failed to fetch buyers.");
    }
  };

  const debouncedFetchBuyers = useCallback(debounce(fetchBuyers, 500), []);

  // Fetch factories based on search query with debounce
  const fetchFactories = async (value) => {
    try {
      const res = await getData("/api/sub-contract-factory", null, {
        name: value,
      });
      setFactories(res.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching factories:", error);
      message.error("Failed to fetch factories.");
    }
  };

  const debouncedFetchFactories = useCallback(
    debounce(fetchFactories, 500),
    [],
  );

  return (
    <>
      <Col span={4}>
        <Form.Item label="Style" name="style_id">
          <Select
            showSearch
            onSearch={debouncedFetchStyles}
            filterOption={false}
            allowClear
            placeholder="Select Style"
          >
            {styles.map((style) => (
              <Select.Option key={style.id} value={style.id}>
                {style.style_no}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {/* Buying Office Filter */}
      <Col span={4}>
        <Form.Item label="Buying Office" name="buying_office_id">
          <Select
            showSearch
            onSearch={debouncedFetchBuyingOffices}
            filterOption={false}
            allowClear
            placeholder="Select Buying Office"
          >
            {buyingOffices.map((office) => (
              <Select.Option key={office.id} value={office.id}>
                {office.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      {/* Buyer Filter */}
      <Col span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            onSearch={debouncedFetchBuyers}
            filterOption={false}
            allowClear
            placeholder="Select Buyer"
          >
            {buyers.map((buyer) => (
              <Select.Option key={buyer.id} value={buyer.id}>
                {buyer.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      {/* Factory Filter */}
      <Col span={4}>
        <Form.Item label="Factory" name="sub_contract_factory_id">
          <Select
            showSearch
            onSearch={debouncedFetchFactories}
            filterOption={false}
            allowClear
            placeholder="Select Factory"
          >
            {factories.map((factory) => (
              <Select.Option key={factory.id} value={factory.id}>
                {factory.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}
