import {Button, Col, Form, Input, Radio, Row, Select, Typography, Switch} from 'antd';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import AppPageHeader from "../../../common/PageHeader";
import {onCreateOrganization, onUpdateSelectedOrganization} from "../../../redux/actions";

const {Option} = Select
const OrganizationCreateForm = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const {selectedOrg} = props;
    const { Title } = Typography;

    const [refresh, setRefresh] = useState(0)
    const refetch = () => {
        setRefresh(refresh+1)
    }

    useEffect(() => {
        form.resetFields();
    }, [selectedOrg, refresh])


    const onFinish = (values) => {
        values['account_id'] = JSON.parse(localStorage.getItem('bankAccIds'))

        values['status'] = values.status ? "1" : "0";
        selectedOrg ? dispatch(onUpdateSelectedOrganization(values, selectedOrg.id)) :
            dispatch(onCreateOrganization(values))
        form.resetFields();
        refetch();
        navigate('/configuration/organization');

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const extra = (
      <div className="extra-button-container">
        <Button
          style={{ marginRight: 10 }}
          size="small"
          danger
          onClick={() => navigate("/configuration/organization")}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          htmlType="submit"
          onClick={() => {
            form.submit();
          }}
        >
          Submit
        </Button>
      </div>
    );
    return (
        <>
            <AppPageHeader extra={extra} title={<Title level={5}>Organization Entry</Title>} />
            <div className="formBody">
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    style={{ marginTop: 10 }}
                    // wrapperCol={{
                    //     span: 23,
                    // }}
                    initialValues={{
                        status: selectedOrg?.status === "1" ? true : false,
                        short_name: selectedOrg?.short_name || "",
                        business_type: selectedOrg?.business_type || "",
                        name: selectedOrg?.name || "",
                        address: selectedOrg?.address || "",
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row gutter={6}>
                        <Col span={4}>
                            <Form.Item
                                label="Organization Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please input your organization name!",
                                    },
                                ]}
                            >
                                <Input placeholder={"Ex: Organization Name"} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Organization Short Name"
                                name="short_name"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please input your organization short name!",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Ex: Organization Short Name"}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Address" name="address">
                                <Input placeholder="Address name" />
                            </Form.Item>
                        </Col>
                        
                        <Col span={4}>
                            <Form.Item label="Business Type">
                                <Input.Group compact>
                                    <Form.Item
                                        name="business_type"
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Business Type is required",
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select business type"
                                            style={{ width: "100%" }}
                                        >
                                            <Option value="Interlining">
                                                Interlining
                                            </Option>
                                            <Option value="Garments">
                                                Garments
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="status"
                                label="Active Status"
                                required
                                className="collection-create-form_last-form-item"
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};
export default OrganizationCreateForm;