import { useEffect, useState } from "react";
import { Typography, List, Button } from "antd";
import {
  BOOKMARK_LIST,
  BOOKMARK_DELETE,
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, deleteData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import { isArrayAndHasValue } from "../../../../utils/functions";
import { DeleteOutlined } from "@ant-design/icons";

const BookmarkList = () => {
  // States
  const [bookmarksList, setBookmarksList] = useState(null);

  // Antd
  const { Title } = Typography;

  const getAllBookmarks = async (filterValues) => {
    const query = `${BOOKMARK_LIST}`;
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBookmarksList(response?.data?.data?.data);
    } else {
      HandleNotification("error", "Error in fetching bookmarks");
    }
  };

  useEffect(() => {
    getAllBookmarks();
  }, []);

  const deleteBookmark = async (id) => {
    const query = `${BOOKMARK_DELETE}/${id}`;
    const response = await deleteData(query);

    if (response && response?.code === 200) {
      getAllBookmarks();
    } else {
      HandleNotification("error", "Error in deleting bookmark");
    }
  };

  return (
    <>
      <div>
        <Title level={5} className="centered-item-parent">
          Bookmarks
        </Title>
      </div>
      <List>
        {isArrayAndHasValue(bookmarksList) &&
          bookmarksList?.map((item) => {
            return (
              <List.Item key={item.name}>
                <List.Item.Meta
                  title={
                    <a
                      href={item?.book_mark_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.name}
                    </a>
                  }
                  description={`Link: ${item.book_mark_link}`}
                />
                <Button
                  size="small"
                  onClick={() => deleteBookmark(item?.id)}
                  icon={<DeleteOutlined/>}
                />
              </List.Item>
            );
          })}
      </List>
    </>
  );
};

export default BookmarkList;
