import {
  DownloadOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  EyeOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { Card, Upload, Button, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  cglFormatDate,
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../utils/functions";
import { BASE_URL } from "../../../apiServices";
import {
  ADD_LC_REASON,
  EXPORT_LC_REASONS,
  SALES_CONTRACT_COMMERCIAL_DOC,
  UPLOAD_SC_COMMERCIAL_DOC_FOR_MMD,
} from "../../../apiServices/API_ENDPOINTS";
import { useParams } from "react-router-dom";

const AmendmentTable = (props) => {
  // Props
  const {
    amendments,
    view,
    isScCommercial,
    salesContractCommercialDocs,
    setSalesContractCommercialDocs,
  } = props;

  // sales contract commercial document section start
  // Router
  let { exportId } = useParams();

  const token = localStorage.getItem("token");

  const onChangeFileUpload = (info, index, item) => {
    if (
      info.file.status === "done" &&
      info.file.response.code === 200 &&
      info?.file?.response?.code === 200
    ) {
      const salesContractCommercialDocsCopy = isArrayAndHasValue(
        salesContractCommercialDocs,
      )
        ? [...salesContractCommercialDocs]
        : [];

      // Find index of the existing entry with the same sales_contract_amendments_id
      const existingIndex = salesContractCommercialDocsCopy.findIndex(
        (doc) => doc.sales_contract_amendments_id === item.id,
      );

      if (existingIndex !== -1) {
        // Update the document_id of the existing entry
        salesContractCommercialDocsCopy[existingIndex].document_id =
          info?.file?.response?.data?.id;
      } else {
        // Add a new entry if it does not exist
        const newData = {
          sales_contracts_id: item.sales_contract_id,
          sales_contract_amendments_id: item.id,
          sales_contract_commercials_id: exportId,
          document_id: info?.file?.response?.data?.id,
        };
        salesContractCommercialDocsCopy.push(newData);
      }

      // Update the state with the modified copy
      setSalesContractCommercialDocs(salesContractCommercialDocsCopy);

      // Update the amendments object
      amendments[index].sales_contract_commercial_document =
        info?.file?.response?.data?.document ?? null;
    }
  };

  const openFileInNewTab = (file_url) => {
    const url = `${BASE_URL}/${file_url}`;
    window.open(url, "_blank");
  };

  // sales contract commercial document section end

  return (
    <Card
      style={{ padding: "0px" }}
      className="custome-table"
      title={
        <span style={{ fontSize: "14px" }}>Sales Contract Amendments</span>
      }
    >
      <div className="root-table">
        <table className="pi-product-details-table">
          <thead>
            <tr>
              <th className="t-header" colSpan="4">
                {" "}
              </th>
              <th className="t-header bc-java " colSpan="2">
                Change
              </th>
            </tr>
            <tr>
              <th className="t-header bc-java ">Particular</th>
              <th className="t-header bc-java ">Date</th>
              <th className="t-header bc-java ">Quantity</th>
              <th className="t-header bc-java ">Value</th>
              {/* Change Quantity */}
              <th className="t-header bc-java ">Quantity</th>
              {/* Change Value */}
              <th className="t-header bc-java ">Value</th>
              <th className="t-header bc-java ">Amendment Reasons</th>
              <th className="t-header bc-java ">Documents</th>
              {isScCommercial && (
                <th className="t-header bc-java ">Documents For MMD</th>
              )}
              <th className="t-header bc-java ">Cost Sheet</th>
            </tr>
          </thead>
          <tbody>
            {isArrayAndHasValue(amendments) ? (
              <>
                {amendments.map((item, index) => (
                  <tr key={index}>
                    <td className="t-body">{item?.name}</td>
                    <td className="t-body">
                      {item?.created_at ? cglFormatDate(item?.created_at) : ""}
                    </td>
                    <td className="t-body">
                      {item?.total_style_order_quantity
                        ? commaSeparateNumber(
                            Number(item?.total_style_order_quantity).toFixed(2),
                          )
                        : ""}
                    </td>
                    <td className="t-body">
                      {item?.total_style_order_value
                        ? commaSeparateNumber(
                            Number(item?.total_style_order_value).toFixed(2),
                          )
                        : ""}
                    </td>
                    <td className="t-body">
                      {item?.last_changed_qty
                        ? commaSeparateNumber(
                            Number(item?.last_changed_qty).toFixed(2),
                          )
                        : ""}
                    </td>
                    <td className="t-body">
                      {item?.last_changed_value
                        ? commaSeparateNumber(
                            Number(item?.last_changed_value).toFixed(2),
                          )
                        : ""}
                    </td>
                    <td className="t-body">
                      {isArrayAndHasValue(item?.reason_ids) &&
                        item?.reason_ids?.map((reasonItem) => {
                          return (
                            <div key={reasonItem?.reason?.id}>
                              {reasonItem?.reason?.name}
                            </div>
                          );
                        })}
                    </td>
                    <td className="t-body">
                      {isArrayAndHasValue(item?.document_ids) &&
                        item?.document_ids?.map((docItem) => {
                          return (
                            <div>
                              <a
                                key={docItem?.id}
                                href={`${BASE_URL}/${docItem?.document}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {docItem?.document_name}
                              </a>
                            </div>
                          );
                        })}
                    </td>

                    {/* Sales Contract Commercial Document agains mmd sales contract document */}
                    {isScCommercial && (
                      <td className="t-body border-right-black">
                        <Space>
                          <Upload
                            action={BASE_URL + UPLOAD_SC_COMMERCIAL_DOC_FOR_MMD}
                            accept="image/*,.pdf, .doc, .xlsx"
                            headers={{
                              authorization: `Bearer ${token}`,
                            }}
                            showUploadList={false}
                            data={{
                              sales_contracts_id: item?.sales_contract_id,
                              sales_contract_amendments_id: item?.id,
                              // sales_contract_commercials_id: exportId,
                            }}
                            onChange={(info) =>
                              onChangeFileUpload(info, index, item)
                            }
                          >
                            <Button
                              type="primary"
                              icon={<CloudUploadOutlined />}
                              size="small"
                              disabled={
                                !isArrayAndHasValue(item?.document_ids) || view
                              }
                            />
                          </Upload>
                          {item?.sales_contract_commercial_document && (
                            <Button
                              icon={<EyeOutlined />}
                              size="small"
                              onClick={() =>
                                item?.sales_contract_commercial_document
                                  ? openFileInNewTab(
                                      item?.sales_contract_commercial_document,
                                    )
                                  : null
                              }
                              disabled={view}
                            />
                          )}
                        </Space>
                      </td>
                    )}

                    <td className="t-body">
                      {isArrayAndHasValue(item?.costSheets) &&
                        item?.costSheets?.map((docItem) => {
                          return (
                            <div>
                              <a
                                key={docItem?.id}
                                href={`${BASE_URL}/${docItem?.document}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {docItem?.document_name}
                              </a>
                            </div>
                          );
                        })}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default AmendmentTable;
