export const SPECIAL_PROCESS_LIST = [
    {
        id: 1,
        label: "Embroidery",
        value: "Embroidery",
        isDisabled: false,
    },
    {
        id: 2,
        label: "Printing",
        value: "Printing",
        isDisabled: false,
    },
    {
        id: 3,
        label: "Washing",
        value: "Washing",
        isDisabled: false,
    },
    {
        id: 4,
        label: "Smocking",
        value: "Smoking",
        isDisabled: false,
    },
    {
        id: 5,
        label: "Pintuck",
        value: "Pintuck",
        isDisabled: false,
    },
    {
        id: 6,
        label: "Snap Button",
        value: "Snap Button",
        isDisabled: false,
    },
];

export const disableSelectedProcess = (processValue) => {
    // Find and disable the process with the matching ID
    SPECIAL_PROCESS_LIST.forEach((process) => {
        if (process.value === processValue) {
            process.isDisabled = true;
        }
    });
}

export const ORDER_TYPE = [
    {
        id: 1,
        label: "New",
        value: "New",
        isDisabled: false,
    },
    {
        id: 2,
        label: "Existing",
        value: "Existing",
        isDisabled: false,
    }
];

export const ORDER_CHANNEL = [
    {
        id: 1,
        label: "Buying House",
        value: "Buying House",
        isDisabled: false,
    },
    {
        id: 2,
        label: "Agent-Media",
        value: "Agent-Media",
        isDisabled: false,
    },
    {
        id: 3,
        label: "Factory",
        value: "Factory",
        isDisabled: false,
    }
];

export const FABRIC_TYPE = [
    {
        id: 1,
        label: "Cotton",
        value: "Cotton",
        isDisabled: false,
    },
    {
        id: 2,
        label: "Polyester",
        value: "Polyester",
        isDisabled: false,
    },
    {
        id: 3,
        label: "Muslin",
        value: "Muslin",
        isDisabled: false,
    },
    {
        id: 4,
        label: "Denim",
        value: "Denim",
        isDisabled: false,
    },
    {
        id: 5,
        label: "Fleece",
        value: "Fleece",
        isDisabled: false,
    }
];