import { useState, useEffect, createContext, useCallback } from "react";
import {
  GET_FOLDING_TABLE_ENDPOINT,
  GET_STYLE_LIST_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";
import HandleNotification from "common/Notification";

export const FoldingContext = createContext();

const FoldingContextProvider = ({ children }) => {
  // States
  const [styleList, setStyleList] = useState(null);
  const [selectedStyleDetails, setSelectedStyleDetails] = useState(null);
  const [foldingTableData, setFoldingTableData] = useState(null);
  const [showFactoryInputs, setShowFactoryInputs] = useState(false);
  const [subContractStatus, setSubContractStatus] = useState(null);

  const getStyleList = useCallback(async (filterValues) => {
    const query = `${GET_STYLE_LIST_ENDPOINT}`;
    const bodyData = {
      page: filterValues?.page || 1,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);
    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting style list",
        null,
      );
    }
  }, []);

  useEffect(() => {
    // Get styles list on first render
    getStyleList();
  }, [getStyleList]);

  const getFoldingTableData = useCallback(async (props) => {
    const { sales_contract_id, style_id, buying_office_id, cutting_date } =
      props;

    const query = `${GET_FOLDING_TABLE_ENDPOINT}`;
    const bodyData = {
      sales_contract_id: sales_contract_id || null,
      style_id: style_id || null,
      buying_office_id: buying_office_id || null,
      folding_date: cutting_date || null,
    };

    const response = await postData(query, bodyData);
    if (response && response?.code === 200) {
      setFoldingTableData(response?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting folding table data",
        null,
      );
    }
  }, []);

  const onSelectStyle = (id) => {
    const styleId = id ? Number(id) : null;
    const selectedStyle = styleList.find((style) => style.id === styleId);
    setSelectedStyleDetails(selectedStyle);
    setSubContractStatus(selectedStyle.subcontract_status);
    if(selectedStyle.buying_office_info) {
      setShowFactoryInputs(false);
    } else if(selectedStyle.factory_info){
      setShowFactoryInputs(true);
    }
    setSelectedStyleDetails(selectedStyle);
  };

  return (
    <FoldingContext.Provider
      value={{
        styleList,
        getFoldingTableData,
        onSelectStyle,
        selectedStyleDetails,
        getStyleList,
        foldingTableData,
        setFoldingTableData,
        showFactoryInputs,
        setShowFactoryInputs,
        subContractStatus,
        setSubContractStatus
      }}
    >
      {children}
    </FoldingContext.Provider>
  );
};

export default FoldingContextProvider;
