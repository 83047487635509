import { Alert, Card, Empty, Row, Typography } from "antd";
import { postData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";
import LineData from "./LineData";

const DefectTracker = ({ line_id, currentSlide, slideId }) => {
  const [defectTrackerData, setDefectTrackerData] = useState({});

  const initialGetData = useCallback(async () => {
    const response = await postData(
      "/api/digitalization/bigscreen-dashboard/defect-tracker",
      {
        line_id: line_id,
      },
    );

    if (response && response?.code === 200) {
      const data = response?.data;

      setDefectTrackerData(data);
    }
  }, [line_id]);

  useEffect(() => {
    if (line_id && currentSlide === slideId) {
      initialGetData();
    }
  }, [line_id, currentSlide, slideId, initialGetData]);

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Typography.Title level={3}>Defect Tracker</Typography.Title>
      {defectTrackerData && defectTrackerData?.length > 0 ? (
        <Row gutter={24}>
          {defectTrackerData?.map((p) => (
            <LineData key={p?.id} data={p?.chart_data} />
          ))}
        </Row>
      ) : (
        <Empty
          style={{
            width: "33%",
            margin: "auto",
            marginTop: "10%",
            marginBottom: "10%",
            height: "100%",
          }}
          description={
            <Alert
              message="No Data Found with selected line-id in Production Tracker"
              type="warning"
            />
          }
        />
      )}
    </Card>
  );
};

export default DefectTracker;
