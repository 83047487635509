import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Select,
  Input,
  Radio,
  Upload,
  Button,
  message,
  Col,
  Row,
  Modal,
} from "antd";
import { onGetItemCategoryList } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { getData } from "../../../apiServices/common";
import { UploadOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../apiServices";
import { Divider } from "antd/es";

const ItemCreateForm = (props) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { itemList, form, selectedItem, fileList, setFilePath } = props;
  const [attributeInfo, setAttributeInfo] = useState(null);
  const token = localStorage.getItem("token");
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleCancel = () => setPreviewOpen(false);

  const handleChange = (info, fileList, event) => {
    if (info.file.status !== "uploading") {
      console.log("uploading file");
    }
    if (info.file.status === "done" && info?.file?.response.code == 200) {
      console.log("list ", info?.file?.response?.data?.id);
      setFilePath(info?.file?.response?.data);

      message.success(`File uploaded successfully`);
    } else if (
      info.file.status === "done" &&
      info?.file?.response.code !== 200
    ) {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadProps = {
    accept: "image/*",
    headers: {
      authorization: `Bearer ${token}`,
    },
    action: BASE_URL + `/api/items/upload-file`,
    name: "file",
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    );
  };

  const onSelectedItemChange = async (event, value) => {
    let res = await getData(
      `/api/itemscategories/${form.getFieldValue("category_id")}/edit`,
    );

    if (res) {
      const attribute_infos = res?.data?.data?.attribute_info;
      setAttributeInfo(attribute_infos);
      let attribute_info_array = [];

      attribute_infos &&
        attribute_infos.forEach((item, index) => {
          attribute_info_array.push({
            attribute_id: item?.id,
            attribute_name: item?.name,
            attribute_value: "",
          });
        });

      form.setFieldsValue({
        attribute_info: attribute_info_array,
      });
    } else {
      let editres = await getData(
        `/api/items/${form.getFieldValue("category_id")}/edit`,
      );
      if (editres) {
        const attribute_infos = editres?.data?.data?.attribute_value_info;
        setAttributeInfo(attribute_infos);
        let attribute_info_array = [];

        attribute_infos &&
          attribute_infos.forEach((item, index) => {
            attribute_info_array.push({
              attribute_id: item?.attribute_id,
              attribute_name: item?.attribute_info?.name,
              attribute_value: item?.attribute_value,
            });
          });

        form.setFieldsValue({
          attribute_info: attribute_info_array,
        });
      }
    }
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            name="category_id"
            rules={[
              {
                required: true,
                message: "Item Required",
              },
            ]}
            label="Item Category"
          >
            <Select
              placeholder="Select Category"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              showSearch
              onSearch={(value) =>
                dispatch(
                  onGetItemCategoryList({
                    page: 1,
                    per_page: 10,
                    name: value,
                  }),
                )
              }
              onChange={onSelectedItemChange}
              style={{ width: "100%" }}
            >
              {itemList?.map((item, idx) => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item name="name" label="Item Description"  rules={[
              {
                required: true,
                message: "Item Description Required",
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Divider />

      <Row>
        <Form.List name="attribute_info">
          {(fields, { index, add, remove }) => {
            return (
              <Row gutter={6}>
                {fields.map((field, index) => (
                  <Col span={8}>
                    <>
                      <Form.Item name={[index, "attribute_id"]} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[index, "attribute_value"]}
                        label={
                          selectedItem
                            ? form.getFieldValue("attribute_info")[field.key][
                                "attribute_info"
                              ]["name"]
                            : form.getFieldValue("attribute_info")[field.key][
                                "attribute_name"
                              ]
                        }
                      >
                        <Input placeholder="Attribute Value" />
                      </Form.Item>
                    </>
                  </Col>
                ))}
              </Row>
            );
          }}
        </Form.List>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item name="file">
            <Upload
              {...uploadProps}
              listType="picture-circle"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList?.length >= 8 ? null : (
                <Button type="primary" icon={<UploadOutlined />}>
                  Upload
                </Button>
              )}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label="Active Status"
            required
            className="collection-create-form_last-form-item"
          >
            <Radio.Group>
              <Radio value="1">Active</Radio>
              <Radio value="0">Inactive</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default ItemCreateForm;
