import { Fragment } from "react";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../../utils/functions";
import { Button, Dropdown, InputNumber, Menu, Select } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const BulkTable = (props) => {
  // Props
  const {
    foundItems,
    setFoundItems,
    unitList,
    supplierList,
    addBulkItems,
    subStyleList,
    drawerType,
    editBulkItems,
  } = props;

  //   Antd
  const { Option } = Select;

  const onChangeItemValue = (value, itemId, context, bulkEditId) => {
    const foundItemsCopy = structuredClone(foundItems);
    let foundItem = null;

    if(drawerType === 'bulk_edit') {
      // When bulk edit
      foundItem = foundItemsCopy?.find((item) => item?.bulk_edit_id === bulkEditId);
    }
    else {
      // When bulk add
      foundItem = foundItemsCopy?.find((item) => item?.id === itemId);
    }
    
    foundItem[context] = value;
    setFoundItems(foundItemsCopy);
  };

  const onSelectUnit = (value, itemId, bulkEditId) => {
    const foundUnit = unitList?.find((item) => item?.id === value);
    const unitInfo = {
      id: foundUnit?.id,
      name: foundUnit?.name,
    };
    onChangeItemValue(unitInfo, itemId, "unit_info", bulkEditId);
  };

  const onSelectSuppliers = (value, itemId, bulkEditId) => {
    const foundItemsCopy = structuredClone(foundItems);
    const foundItem = foundItemsCopy?.find((item) => item?.id === itemId);
    const foundSupplier = supplierList?.find((item) => item?.id === value);

    const supplierObj = {
      supplier_id: foundSupplier?.id,
      supplier_info: {
        id: foundSupplier?.id,
        name: foundSupplier?.name,
        short_name: foundSupplier?.short_name,
      },
    };

    let suppliersArray = foundItem?.suppliers || [];
    if (isArrayAndHasValue(suppliersArray)) {
      suppliersArray.push(supplierObj);
    } else {
      suppliersArray = [supplierObj];
    }

    onChangeItemValue(suppliersArray, itemId, "suppliers", bulkEditId);
  };

  const onDeselectSuppliers = (value, itemId) => {
    const foundItemsCopy = structuredClone(foundItems);
    const foundItem = foundItemsCopy?.find((item) => item?.id === itemId);
    const foundSupplierIndex = foundItem?.suppliers?.findIndex(
      (item) => item?.supplier_id === value,
    );
    foundItem?.suppliers?.splice(foundSupplierIndex, 1);
    setFoundItems(foundItemsCopy);
  };

  const onSelectSubstyle = (value, itemId, bulkEditId) => {
    const foundSubstyle = subStyleList?.find((item) => item?.id === value);

    const colorInfoObj = {
      id: foundSubstyle?.color_info?.id,
      name: foundSubstyle?.color_info?.name,
      sub_style_id: foundSubstyle?.id,
    };

    onChangeItemValue(colorInfoObj, itemId, "color_info", bulkEditId);
  };

  const addAllItems = () => {
    addBulkItems();
  };

  const editAllItems = () => {
    editBulkItems();
  }

  const copyValueToNextRows = (value, itemId, context) => {
    const inputValue = value || value === 0 ? value : null;
    const foundItemsCopy = structuredClone(foundItems);
    const foundItemIndex = foundItemsCopy.findIndex(
      (item) => item?.id === itemId,
    );

    // Put inputValue in context to all the items after foundItemIndex
    for (let i = foundItemIndex; i < foundItemsCopy.length; i++) {
      foundItemsCopy[i][context] = inputValue;
    }

    setFoundItems(foundItemsCopy);
  };

  return (
    <>
      <div
        className="centered-item-parent"
        style={{ paddingTop: 5, paddingBottom: 10 }}
      ></div>

      <div style={{ display: "flex", marginBottom: 10 }}>
        <div className="custome-table">
          <table>
            {isArrayAndHasValue(foundItems) && (
              <thead>
                <tr>
                  <th className="t-header bc-java ">Item</th>
                  <th className="t-header bc-java ">Unit</th>
                  <th
                    className="t-header bc-java "
                    title="Order Quantity (PC.)"
                  >
                    O.Qty.
                  </th>
                  <th className="t-header bc-java " title="Consumption">
                    Cons.
                  </th>
                  <th className="t-header bc-java " title="Extra (%)">
                    Extra
                  </th>
                  <th className="t-header bc-java ">Supplier</th>
                  <th className="t-header bc-java ">Substyle</th>
                </tr>
              </thead>
            )}

            <tbody>
              {isArrayAndHasValue(foundItems) ? (
                <>
                  {foundItems.map((product, index) => (
                    <Fragment>
                      <tr
                        key={product?.id || index}
                        className={product?.is_add ? "is-added-item" : ""}
                      >
                        {/* ITEM */}
                        <td className="t-body">{product?.item_info?.name}</td>
                        {/* UNIT */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <Select
                              style={{ width: 70 }}
                              showSearch
                              placeholder="Select a Unit or Create"
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return option?.label
                                  ?.toLowerCase()
                                  ?.includes(input?.toLowerCase());
                              }}
                              dropdownMatchSelectWidth={false}
                              options={
                                unitList?.length &&
                                unitList.map((item) => ({
                                  label: item?.name,
                                  value: item?.id,
                                }))
                              }
                              size="small"
                              onSelect={(value) => {
                                onSelectUnit(value, product?.id, product?.bulk_edit_id);
                              }}
                              value={product?.unit_info?.id}
                            />
                            <span
                              onClick={() => {
                                const unitInfo = {
                                  id: product?.unit_info?.id,
                                  name: product?.unit_info?.name,
                                };
                                copyValueToNextRows(
                                  unitInfo,
                                  product?.id,
                                  "unit_info",
                                );
                              }}
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                        {/* O.QTY.(PC.) */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <InputNumber
                              style={{ width: 70 }}
                              formatter={(value) => commaSeparateNumber(value)}
                              precision={0}
                              size="small"
                              onChange={(value) =>
                                onChangeItemValue(
                                  value,
                                  product?.id,
                                  "order_quantity",
                                  product?.bulk_edit_id,
                                )
                              }
                              value={product?.order_quantity}
                            />
                            <span
                              onClick={() =>
                                copyValueToNextRows(
                                  product?.order_quantity,
                                  product?.id,
                                  "order_quantity",
                                )
                              }
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                        {/* 	CONSUMPTION */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <InputNumber
                              style={{ width: 70 }}
                              formatter={(value) => commaSeparateNumber(value)}
                              size="small"
                              onChange={(value) =>
                                onChangeItemValue(
                                  value,
                                  product?.id,
                                  "per_product_required_quantity",
                                  product?.bulk_edit_id,
                                )
                              }
                              value={product?.per_product_required_quantity}
                            />
                            <span
                              onClick={() =>
                                copyValueToNextRows(
                                  product?.per_product_required_quantity,
                                  product?.id,
                                  "per_product_required_quantity",
                                )
                              }
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                        {/* EXTRA */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <InputNumber
                              style={{ width: 70 }}
                              formatter={(value) => commaSeparateNumber(value)}
                              precision={0}
                              size="small"
                              onChange={(value) =>
                                onChangeItemValue(
                                  value,
                                  product?.id,
                                  "extra_qty",
                                  product?.bulk_edit_id,
                                )
                              }
                              value={product?.extra_qty}
                            />
                            <span
                              onClick={() =>
                                copyValueToNextRows(
                                  product?.extra_qty,
                                  product?.id,
                                  "extra_qty",
                                )
                              }
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>

                        {/* SUPPLIER */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <Select
                              style={{ width: 100 }}
                              showSearch
                              placeholder="Select a Supplier or Create"
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return option?.label
                                  ?.toLowerCase()
                                  ?.includes(input?.toLowerCase());
                              }}
                              dropdownMatchSelectWidth={false}
                              options={
                                supplierList?.length &&
                                supplierList.map((item) => ({
                                  label: item?.short_name || item?.name,
                                  value: item?.id,
                                }))
                              }
                              mode="multiple"
                              size="small"
                              onSelect={(value) =>
                                onSelectSuppliers(value, product?.id, product?.bulk_edit_id)
                              }
                              value={product?.suppliers?.map(
                                (supItem) => supItem?.supplier_id,
                              )}
                              onDeselect={(value) =>
                                onDeselectSuppliers(value, product?.id)
                              }
                            />
                            <span
                              onClick={() =>
                                copyValueToNextRows(
                                  product?.suppliers,
                                  product?.id,
                                  "suppliers",
                                )
                              }
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                        {/* SUBSTYLE */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <Select
                              style={{ width: 100 }}
                              dropdownStyle={{ minWidth: 250 }}
                              className="w-100"
                              showSearch
                              placeholder="Select a Sub Style No"
                              optionFilterProp="children"
                              size="small"
                              onSelect={(value) => {
                                onSelectSubstyle(value, product?.id, product?.bulk_edit_id);
                              }}
                              value={product?.color_info?.sub_style_id}
                            >
                              {subStyleList?.length > 0 &&
                                subStyleList.map((item) => (
                                  <Option value={item?.id} key={item.id}>
                                    {item?.color_info?.name}
                                  </Option>
                                ))}
                            </Select>
                            <span
                              onClick={() =>
                                copyValueToNextRows(
                                  product?.color_info,
                                  product?.id,
                                  "color_info",
                                )
                              }
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="centered-item-parent"
        style={{
          width: "200px",
          position: "fixed",
          bottom: "2vh",
          right: "840px",
        }}
      >
        <Button
          type="primary"
          size="small"
          onClick={drawerType === "bulk_add" ? addAllItems : editAllItems}
        >
          {drawerType === "bulk_add" ? `Add All` : `Update All`}
        </Button>
      </div>
    </>
  );
};

export default BulkTable;
