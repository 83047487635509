import { Col, Form, Select } from "antd";
import { useEffect, useState } from "react";
import {
  EXISTING_PO_MAP_LIST,
  STYLE_MERCHANDISING_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "utils/functions";

const BuyerPackingReportFilterFields = ({ initialTableData }) => {
  // States
  const [poList, setPoList] = useState(null);
  const [styleList, setStyleList] = useState([]);

  // Antd
  const { Option } = Select;

  const getPOList = async (value = null) => {
    let query = `${EXISTING_PO_MAP_LIST}`;
    if (value) {
      query = `${query}?po_map_id=${value}`;
    }
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setPoList(response?.data?.data);
    }
  };

  const getStyleList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      style_no: filterValues?.style_no || "",
    };
    const response = await getData(STYLE_MERCHANDISING_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data?.data || []);
    }
  };

  useEffect(() => {
    getPOList();
    getStyleList();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item
          label="Original PO"
          name="po_map_id"
          rules={[
            {
              required: true,
              message: "Original PO is required",
            },
          ]}
        >
          <Select
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            size="small"
            onSearch={getPOList}
          >
            {poList &&
              poList?.map((po, idx) => (
                <Option key={idx} value={po?.id}>
                  {po?.original_po}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Style No." name="style_id">
          <Select
            showSearch
            placeholder="Select a Style"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getStyleList({ style_no: value })}
          >
            {isArrayAndHasValue(styleList) &&
              styleList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.style_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default BuyerPackingReportFilterFields;
