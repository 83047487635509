import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Form, Input, Radio} from "antd";
import Modal from "antd/es/modal/Modal";
import {onUpdateSelectedItem, onCreateItem} from "../../../redux/actions";
import ItemCreateForm from "./ItemCreateForm";

const CreateItem = (props) => {


    const dispatch = useDispatch();
    const {isAddItem, itemList,categoryList, handleAddItemClose, selectedItem, onUpdateItem} = props;
    const [form] = Form.useForm();
    const [filePath, setFilePath] = useState('');
    console.log('filePath ', filePath)
    useEffect(() => {
        form.resetFields();
    }, [selectedItem]);

    return (
        <>
            <Modal
                forceRender
                open={isAddItem}
                title={selectedItem? `Update Item` : 'Create Item'}
                okText={'Save'}
                cancelText="Discard"
                cancelButtonProps={{type: "danger" }}
                onCancel={() => {
                    handleAddItemClose();
                    // form.resetFields()
                }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {

                            // form.resetFields();

                            if (selectedItem) {
                                let i = 0;
                                const length = values.attribute_info.length;
                                const attribute_values = [];
                                const attribute_ids = [];
                                for(i; i < length; i++){
                                    attribute_values.push(values.attribute_info[i].attribute_value);
                                    attribute_ids.push(values.attribute_info[i].attribute_id);
                                }
                                const updateObj = {
                                    id: selectedItem.id,
                                    image_path: filePath,
                                    name: selectedItem.name,
                                    status: selectedItem.status,
                                    attribute_id:attribute_ids,
                                    attribute_value:attribute_values,
                                    ...values
                                }
                                dispatch(onUpdateSelectedItem(updateObj));
                                props.refetch()
                            }
                            else{
                                let i = 0;
                                const length = values.attribute_info.length;
                                const attribute_values = [];
                                const attribute_ids = [];
                                for(i; i < length; i++){
                                    attribute_values.push(values.attribute_info[i].attribute_value);
                                    attribute_ids.push(values.attribute_info[i].attribute_id);
                                }
                                const newItem = {
                                    // name: values.name,
                                    image_path: filePath,
                                    status: values.status,
                                    attribute_id:attribute_ids,
                                    attribute_value:attribute_values,
                                    ...values
                                }
                                console.log('newItem ', newItem)

                                dispatch(onCreateItem(newItem));
                                form.setFieldsValue( { name: '' } )
                                props.refetch()
                            }
                            selectedItem && handleAddItemClose();
                            form.resetFields();

                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                    <Form
                        form={form}
                        preserve={false}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                            attribute_info: selectedItem?.attribute_value_info,
                            file: selectedItem?.image_path || '',
                            status: selectedItem?.status || '1',
                            category_id: selectedItem?.category_id || '',
                            name: selectedItem?.name || '',
                        }}
                    >
                        <ItemCreateForm setFilePath={setFilePath} selectedItem={selectedItem} itemList={categoryList} form={form} />
                    </Form>

            </Modal>
        </>
    );

}

export default CreateItem;