import React, { useEffect, useState } from "react";
import { Form, Layout, Button, Collapse, Typography } from "antd";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import AppPageHeader from "common/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  onGetCuttingDetails,
  onGetStyleDetails,
  onGetStyleList,
  onResetStyleDetails,
  onResetCuttingDetails,
} from "redux/actions";
import CuttingForm from "./CuttingForm";
import ItemUsageDetails from "../ItemDetails/ItemUsageDetails";
import { useNavigate } from "react-router";
import { hasPermission, isArrayAndHasValue } from "utils/functions";
import {
  CUTTING_CREATE_PERMISSION,
} from 'routes/permissions';
import { getData, postData } from "apiServices/common";
import { GET_CONVERSION_RATE, STORE_CUTTING_ITEM_ENDPOINT } from "apiServices/API_ENDPOINTS";
import HandleNotification from "common/Notification";

const { Content } = Layout;
const { Panel } = Collapse;

const CreateCuttingItemEntry = (props) => {
  // States
 
  const [selectedInternalPO, setSelectedInternalPO] = useState(null);
  const [cuttingTableData, setCuttingTableData] = useState([]);
  const [cuttingItems, setCuttingItems] = useState([]);
  const [originalPO, setOriginalPO] = useState("");
  const [internalPO, setInternalPO] = useState("");

  // Redux
  
  const dispatch = useDispatch();
  const selectedCuttingDetails = useSelector(
    ({ cutting }) => cutting.selectedCuttingDetails,
  );
  let selectedCutting = selectedCuttingDetails && selectedCuttingDetails.data;
  const cuttingInfo =
    selectedCuttingDetails && selectedCuttingDetails.data.cutting_product_info;
  const itemUsageInfo =
    selectedCuttingDetails && selectedCuttingDetails.data.item_usage_info;
  const styleList = useSelector(({ style }) => style.styleList);
  const selectedStyleDetails = useSelector(
    ({ style }) => style.selectedStyleDetails,
  );
  const styleDetails = selectedStyleDetails && selectedStyleDetails.data[0];
  const loading = useSelector(({ common }) => common.loading);
  const salesContractDocuments =
    selectedStyleDetails?.data[0]?.style?.active_sales_contract_map_info
      ?.active_sales_contract_info?.sales_contract_documents;

  const [itemDetailsData, setItemDetailsData] = useState(null);

  console.log("itemUsageInfo main", itemUsageInfo);
  console.log("itemDetailsData", itemDetailsData);

  // Antd Constants
  const [form] = Form.useForm();
  const { Title } = Typography;
  
  // Router
  const navigate = useNavigate();

  useEffect(() => {
    if(isArrayAndHasValue(itemUsageInfo)) {
      setItemDetailsData(itemUsageInfo);
    }
  }, [itemUsageInfo])

  useEffect(() => {
    return () => {
      // Reset data on component unmount
      dispatch(onResetStyleDetails());
      dispatch(onResetCuttingDetails());
    };
  }, [dispatch]);

  const itemArr = {
    item_id: [],
    sub_style_id: [],
    item_category_id: [],
    regular_quantity: [],
    replacement_quantity: [],
    wastage_quantity: [],
  };

  const itemDetailsState = cuttingItems?.map((item, idx) => {
    itemArr.item_id.push(item?.item_info?.id);
    itemArr.sub_style_id.push(item?.sub_style_id);
    itemArr.item_category_id.push(item?.item_info?.category_id);
    itemArr.regular_quantity.push(item?.usage_info?.regular);
    itemArr.replacement_quantity.push(item?.usage_info?.replacement);
    itemArr.wastage_quantity.push(item?.wastage_info?.day_quantity);
  });

  const cutting = {
    color_id: [],
    size_id: [],
    product_sub_style_id: [],
    cutting_piece: [],
    remain_piece: [],
  };

  const cuttingMap = cuttingTableData?.map((item, idx) => {
    cutting.color_id.push(item?.color?.id);
    cutting.size_id.push(item?.size?.id);
    cutting.product_sub_style_id.push(item?.sub_style_id);
    cutting.cutting_piece.push(item?.daily_consumption?.cutting_piece);
    cutting.remain_piece.push(item?.daily_consumption?.remain_piece);
  });

  const onChangeStyle = (value, options) => {
    dispatch(onGetStyleDetails(value));
  };

  const onChangeInternalPO = (value, options) => {
    setSelectedInternalPO(options?.internal_po);
    setOriginalPO(options?.original_po);
    setInternalPO(options?.internal_po);
    form.setFieldValue("original_po", options?.original_po);
  };

  const onChangeOriginalPO = (value, options) => {
    setSelectedInternalPO(options?.internal_po);
    setOriginalPO(options?.original_po);
    setInternalPO(options?.internal_po);
    form.setFieldValue("internal_po", options?.internal_po);
  };

  const onCuttingDateChange = (date, dateString) => {
    const requestBody = {
      sales_contract_id:
        styleDetails?.style?.sales_contract_map_info?.sales_contract_id,
      style_id: styleDetails?.style?.id,
      internal_po: selectedInternalPO,
      cutting_date: dateString,
      buying_office_id: styleDetails?.style?.buying_office_id,
    };
    dispatch(onGetCuttingDetails(requestBody));
  };

  const onFinish = (values) => {
    //console.log("on finish values ", values);return false;
    values["original_po"] = originalPO;
    values["internal_po"] = selectedInternalPO;
    values["cutting_day"] = selectedCutting?.total_cutting_day;

    const styleDetailsInfo = {
      sales_contract_id:
        styleDetails?.style?.sales_contract_map_info?.sales_contract_id,
      buying_office_id: styleDetails?.style?.buying_office_id,
      style_id: styleDetails?.style?.id,
    };

    const customItemData =
      isArrayAndHasValue(itemDetailsData) &&
      itemDetailsData?.map((item) => {
        return item;
      });

    const bodyData = {
      ...values, 
      ...styleDetailsInfo, 
      "items": customItemData 
    };

    const query = `${STORE_CUTTING_ITEM_ENDPOINT}`;
    
    const response = postData(query, bodyData);

    console.log("response", response);

    if (response) {
      HandleNotification('success', 'bottomRight', 'Cutting Item created Successfully!', null)
      form.resetFields();
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };


  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => form.submit()}
        disabled={!hasPermission([CUTTING_CREATE_PERMISSION])}
      >
        {hasPermission([CUTTING_CREATE_PERMISSION])
          ? "Submit"
          : "No Permission"}
      </Button>
      <Button size="small" htmlType="submit" onClick={() => form.resetFields()}>
        {" "}
        Reset
      </Button>
    </div>
  );

  
  useEffect(() => {
    dispatch(onGetStyleList(1, 10));
  }, [cuttingInfo]);

  const getConversionData = async (pi_unit_id, record, isTrue, balanceType='balance', baseValueType='base_value') => {
    //console.log(record);
    const bodyData = {
      convert_from: record?.unit_id,
      convert_to: isTrue ? record?.pi_unit_id : pi_unit_id,
      base_value: record[baseValueType],
    }
  
    const response = await getData(GET_CONVERSION_RATE, false, bodyData);
    
    let convertedValue;

    if(response && response?.data?.code === 200) {
      convertedValue = response?.data?.data;
      // setCurrentQty(prev => ({value: currentQty?.value, balance: convertedValue, id: record?.id }));
      record[balanceType] = convertedValue;
    } else {
      convertedValue = record?.pi_qty;
    }
  
    const itemDetailsDataCopy = [...itemDetailsData];
    const findThatItem = itemDetailsDataCopy.find((item) => record?.item_id === item?.item_id);
    findThatItem["pi_unit_id"] = pi_unit_id;
    findThatItem["qty_in_bom_unit"] = convertedValue;
    findThatItem["balance_qty"] = Math.floor(record?.required_qty) - convertedValue;
    
    setItemDetailsData(itemDetailsDataCopy);
  }

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader title={<Title level={5}>Cutting Entry</Title>} extra={extra} />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 15 }}>
            <Panel header={<b>Order Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <CuttingForm
                  onChangeOriginalPO={onChangeOriginalPO}
                  styleDetails={styleDetails}
                  onChangeStyle={onChangeStyle}
                  styleList={styleList}
                  onChangeInternalPO={onChangeInternalPO}
                  selectedCutting={selectedCutting}
                  originalPO={originalPO}
                  onCuttingDateChange={onCuttingDateChange}
                />
              </Form>
            </Panel>
          </Collapse>
          <div className="item-table" style={{ marginTop: 25 }}>
            {itemUsageInfo && (
              <ItemUsageDetails
                loading={loading}
                setCuttingItems={setCuttingItems}
                itemUsageInfo={itemUsageInfo}
                getConversionData={getConversionData}
              />
            )}
          </div>
          
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default CreateCuttingItemEntry;
