import { Card, Col, Descriptions, Row, Table, Typography, message } from "antd";
import { DIGITALIZATION_DEFECTS } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import moment from "moment";

export default function ProductionDefectView() {
  const [productionDefectData, setProductionDefectData] = useState(null);
  const { id } = useParams();
  const getSupervisorChecklist = useCallback(async () => {
    const query = `${DIGITALIZATION_DEFECTS}/${id}`;
    const response = await getData(query);
    if (response?.status === 200) {
      setProductionDefectData(response?.data?.data || {});
    } else {
      message.error("Something went wrong! Please try again.");
    }
  }, [id]);

  useEffect(() => {
    getSupervisorChecklist();
  }, [id, getSupervisorChecklist]);

  const detailsColumns = [
    {
      title: "SL",
      dataIndex: "sl",
      key: "sl",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Reason",
      dataIndex: ["reason", "reason"],
      key: "reason",
    },
  ];
  const recommendationsColumns = [
    {
      title: "SL",
      dataIndex: "sl",
      key: "sl",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Recommendation",
      dataIndex: ["recommendation", "recommendation"],
      key: "recommendation",
    },
  ];

  return (
    <Row gutter={4}>
      <Col span={24}>
        <Card
          title={<Typography.Title level={5}>Checklist Info</Typography.Title>}
          bordered
          style={{
            margin: "10px 20px",
            padding: "10px",
          }}
        >
          <Descriptions column={4}>
          <Descriptions.Item label="Date">
              <Typography.Text level={2} strong>
                {productionDefectData?.date ? moment(productionDefectData?.date).format("YYYY-MM-DD") : "N/A"}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Time">
              <Typography.Text level={2} strong>
                {productionDefectData?.time ? moment(productionDefectData?.time, "HH:mm").format("HH:mm") : "N/A"}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              <Typography.Text level={2} strong>
                {productionDefectData?.user?.name}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Line">
              {" "}
              <Typography.Text level={2} strong>
                {productionDefectData?.line_info?.name}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Operator">
              {" "}
              <Typography.Text level={2} strong>
                {productionDefectData?.operator?.name}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Category">
              {" "}
              <Typography.Text level={2} strong>
                {productionDefectData?.category?.name}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Quantity">
              {" "}
              <Typography.Text level={2} strong>
                {productionDefectData?.quantity}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title={<Typography.Title level={4}>Checklists</Typography.Title>}
          bordered
          style={{
            margin: "10px 20px",
            padding: "10px",
          }}
        >
          <Table
            dataSource={productionDefectData?.details || []}
            columns={detailsColumns}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title={<Typography.Title level={4}>Checklists</Typography.Title>}
          bordered
          style={{
            margin: "10px 20px",
            padding: "10px",
          }}
        >
          <Table
            dataSource={productionDefectData?.recommendations || []}
            columns={recommendationsColumns}
          />
        </Card>
      </Col>
    </Row>
  );
}
