import { useCallback, useEffect, useState, createRef } from "react";
import {
  Form,
  Layout,
  Button,
  Collapse,
  Typography,
  Space,
  Select,
  Row,
  Col,
  Input,
  DatePicker,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import AppPageHeader from "../../../../common/PageHeader";
import InventoryLayOutCommon from "../../../InventoryFabric/LayOut/InventoryLayOutCommon";
import OBTable from "./OBTable";
import {
  OB_SAM_DATA_BANK_LIST,
  OB_LIST,
  OB_EXCEL_REPORT,
  OB_TEMPLATE_ADD,
  OB_TEMPLATES,
  OB_TEMPLATE_EDIT,
  GARMENT_CATEGORY_LIST_ENDPOINT,
  GARMENT_FOR_LIST_ENDPOINT,
  GARMENT_TYPE_LIST_ENDPOINT,
  GET_TEMPLATE_BY_GARMENT,
} from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import { isArrayAndHasValue } from "../../../../utils/functions";
import { useScreenshot, createFileName } from "use-react-screenshot";
import FormItems from "./FormItems";

const OBTemplateForm = (props) => {
  // Props
  const { isAdd, view } = props;

  // States
  const [obSamBanks, setObSamBanks] = useState(null);
  const [sections, setSections] = useState([]);
  const [garmentCategoryList, setGarmentCategoryList] = useState(null);
  const [garmentTypeList, setGarmentTypeList] = useState(null);
  const [garmentForList, setGarmentForList] = useState(null);
  const [obTemplates, setObTemplates] = useState(null);

  // Antd
  const [form] = Form.useForm();
  const [obShowForm] = Form.useForm();
  const { Content } = Layout;
  const { Panel } = Collapse;
  const { Title } = Typography;
  const { Option } = Select;
  const garment_category_id_watch = Form.useWatch("garment_category_id", form);
  const garment_type_id_watch = Form.useWatch("garment_type_id", form);
  const garment_for_id_watch = Form.useWatch("garment_for_id", form);

  // Router
  const { ob_id } = useParams();
  const navigate = useNavigate();

  // Others
  const exportRef = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const download = (
    image,
    { name = `ob_${ob_id}_export`, extension = "jpg" } = {},
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.target = "_blank";
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () =>
    takeScreenShot(exportRef.current).then(download);

  const getAllSections = async (filterValues) => {
    const query = `${OB_SAM_DATA_BANK_LIST}`;
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      page: filterValues?.page || 1,
      ...filterValues,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      const allOBSAMBanks = response?.data?.data?.data;
      isArrayAndHasValue(allOBSAMBanks) && setObSamBanks(allOBSAMBanks);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting all sections data!",
        null,
      );
    }
  };

  const getSelectedObInfo = useCallback(
    async (id, no_of_hour, per_hour_target) => {
      const editQuery = `${OB_TEMPLATES}/${id}/edit`;
      const query = editQuery;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {
        // Get data and assign
        const garmentCategoryData = response?.data?.data?.garment_category;
        const garmentTypeData = response?.data?.data?.garment_type;
        const garmentForData = response?.data?.data?.garment_for;

        // Check if data exists in dropdown list data
        const garmentCategoryExists = garmentCategoryList?.find((item) => item?.id === garmentCategoryData?.id);
        const garmentTypeExists = garmentTypeList?.find((item) => item?.id === garmentTypeData?.id);
        const garmentForExists = garmentForList?.find((item) => item?.id === garmentForData?.id);

        // Push data if not exists
        if (!garmentCategoryExists) {
          const garmentCategoryCopy = garmentCategoryList ? [...garmentCategoryList] : [];
          garmentCategoryCopy.push(garmentCategoryData);
          setGarmentCategoryList(garmentCategoryCopy);
        }

        if (!garmentTypeExists) {
          const garmentTypeCopy = garmentTypeList ? [...garmentTypeList] : [];
          garmentTypeCopy.push(garmentTypeData);
          setGarmentTypeList(garmentTypeCopy);
        }

        if (!garmentForExists) {
          const garmentForCopy = garmentForList ? [...garmentForList] : [];
          garmentForCopy.push(garmentForData);
          setGarmentForList(garmentForCopy);
        }

        const allSections = [...sections];
        const obData = response?.data?.data;

        // Set form fields data
        form.setFieldsValue({
          tamplate_name: obData?.tamplate_name,
          entry_date: obData?.entry_date ? moment(obData?.entry_date) : null,
          garment_category_id: obData?.garment_category?.id,
          garment_type_id: obData?.garment_type?.id,
          garment_for_id: obData?.garment_for?.id,
        });

        // Iterate and create sections array
        obData?.ob_tamplet_details?.forEach((obDetail) => {
          const foundSAM = obDetail?.sam_data_bank;
          const foundSectionId = foundSAM
            ? foundSAM?.ob_section_info?.id
            : null;
          const foundSection = allSections?.find(
            (section) => section?.section_id === foundSectionId,
          );

          if (!foundSection) {
            // Push new data to array
            allSections.push({
              section_id: foundSAM?.ob_section_info?.id,
              section_name: foundSAM?.ob_section_info?.name,
              section_data: [foundSAM],
            });
          } else {
            // Push data to existing array
            const sectionIndex = allSections.findIndex(
              (section) =>
                section?.section_id === foundSAM?.ob_section_info?.id,
            );
            const isExists = allSections[sectionIndex].section_data.some(
              (sam) => sam?.id === foundSAM?.id,
            );

            if (!isExists) {
              allSections[sectionIndex].section_data.push(foundSAM);
            }
          }
        });

        // Set sections state
        isArrayAndHasValue(allSections) && setSections(allSections);
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error getting selected ob info!",
          null,
        );
      }
    },
    [form, sections, view],
  );

  const getAllGarmentCategory = async (filterValues) => {
    const bodyData = {
      per_page: 10,
      ...filterValues,
    };
    const query = `${GARMENT_CATEGORY_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGarmentCategoryList(response?.data?.data?.data);
    }
  };

  const getAllGarmentType = async (filterValues) => {
    const bodyData = {
      per_page: 10,
      ...filterValues,
    };
    const query = `${GARMENT_TYPE_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGarmentTypeList(response?.data?.data?.data);
    }
  };

  const getAllGarmentFor = async (filterValues) => {
    const bodyData = {
      per_page: 10,
      ...filterValues,
    };
    const query = `${GARMENT_FOR_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGarmentForList(response?.data?.data?.data);
    }
  };

  const getTemplateByGarmentInfo = useCallback(
    async (filterValues) => {
      const bodyData = {
        garment_category_id: garment_category_id_watch,
        garment_type_id: garment_type_id_watch,
        garment_for_id: garment_for_id_watch,
        per_page: 20,
        ...filterValues,
      };
      const query = `${GET_TEMPLATE_BY_GARMENT}`;

      const response = await postData(query, bodyData);

      if (response && response?.code === 200) {
        const masterData = response?.data;
        const templateSamData =
          isArrayAndHasValue(masterData) &&
          masterData?.map((item) => {
            const samData =
              isArrayAndHasValue(item?.ob_tamplet_details) &&
              item?.ob_tamplet_details?.map((sam) => {
                return {
                  ...sam?.sam_data_bank,
                };
              });
            return {
              sam_data_bank: samData,
              template_name: item?.tamplate_name,
              id: item?.id,
            };
          });
        templateSamData && setObTemplates(templateSamData);
      }
    },
    [garment_category_id_watch, garment_type_id_watch, garment_for_id_watch],
  );

  useEffect(() => {
    // Get data on first load
    getAllSections();
    getAllGarmentCategory();
    getAllGarmentType();
    getAllGarmentFor();
  }, []);

  useEffect(() => {
    // Get selected ob info for edit
    if ((ob_id || ob_id === 0) && !view) {
      getSelectedObInfo(ob_id);
    }
  }, [ob_id, view]);

  useEffect(() => {
    // Get OB Template data for dropdown
    // When garments category, type and for changes
    if (
      garment_category_id_watch &&
      garment_type_id_watch &&
      garment_for_id_watch
    ) {
      getTemplateByGarmentInfo();
    }
  }, [
    garment_category_id_watch,
    garment_type_id_watch,
    garment_for_id_watch,
    getTemplateByGarmentInfo,
  ]);

  const onSelectSAM = (id) => {
    const allSections = [...sections];
    const foundSAM = obSamBanks?.find((sam) => sam?.id === id);
    const foundSectionId = foundSAM ? foundSAM?.ob_section_info?.id : null;
    const foundSection = sections?.find(
      (section) => section?.section_id === foundSectionId,
    );

    if (!foundSectionId) {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting section!",
        null,
      );
      return;
    }

    if (!foundSection) {
      // Push new data to array
      allSections.push({
        section_id: foundSAM?.ob_section_info?.id,
        section_name: foundSAM?.ob_section_info?.name,
        section_data: [foundSAM],
      });
    } else {
      // Push data to existing array
      const sectionIndex = allSections.findIndex(
        (section) => section?.section_id === foundSAM?.ob_section_info?.id,
      );
      const isExists = allSections[sectionIndex].section_data.some(
        (sam) => sam?.id === foundSAM?.id,
      );

      if (!isExists) {
        allSections[sectionIndex].section_data.push(foundSAM);
      } else {
        // If data section already exists
        HandleNotification(
          "error",
          "bottomRight",
          "Section already selected before!",
          null,
        );
      }
    }

    form.resetFields(["section_id"]);
    isArrayAndHasValue(allSections) && setSections(allSections);
  };

  const removeRow = (id) => {
    // Find existing section data by id in sections array
    // remove if exists
    const allSections = [...sections];
    const filteredSections = allSections.map((section) => {
      const filteredSectionData = section?.section_data.filter(
        (sam) => sam?.id !== id,
      );
      return {
        ...section,
        section_data: filteredSectionData,
      };
    });

    setSections(filteredSections);
  };

  const onDownloadExcel = async () => {
    const no_of_hour = obShowForm.getFieldValue("no_of_hour");
    const per_hour_target = obShowForm.getFieldValue("per_hour_target");
    const bodyData = {
      id: ob_id,
      no_of_hour: no_of_hour ? Number(no_of_hour) : null,
      per_hour_target: per_hour_target ? Number(per_hour_target) : null,
    };
    const query = `${OB_EXCEL_REPORT}`;

    const response = await getData(
      query,
      false,
      bodyData,
      { "Content-Type": "blob" }, //Custome Header
      "arraybuffer", //Response-Type to get excel file
    );

    if (response && response?.status === 200) {
      const outputFilename = `ob.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting excel report!",
        null,
      );
    }
  };

  const onReset = () => {
    form.resetFields();
    setSections([]);
  };

  const extra = (
    <div className="extra-button-container">
      {!view && (
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          style={{ marginRight: 5 }}
          onClick={() => form.submit()}
        >
          {" "}
          {ob_id || ob_id === 0 ? `Edit` : `Create`}
        </Button>
      )}

      {!view && (
        <Button htmlType="submit" size="small" onClick={onReset}>
          {" "}
          Reset
        </Button>
      )}

      {view && (
        <Space>
          <Button type="primary" size="small" onClick={onDownloadExcel}>
            {" "}
            Download Excel
          </Button>
          <Button type="primary" size="small" onClick={downloadScreenshot}>
            {" "}
            Download PDF
          </Button>
        </Space>
      )}
    </div>
  );

  const onFinish = async (formValues) => {
    // console.log("onFinish", formValues);

    // Sam Data Calculate
    const samData = {
      total_smv_machine_sam: 0,
      total_smv_helper_sam: 0,
      total_smv_iron_sam: 0,
      total_target_machine_sam: 0,
      total_target_helper_sam: 0,
      total_target_iron_sam: 0,
      total_sam: 0,
    };

    sections?.forEach((section) => {
      section?.section_data?.forEach((sectionDataItem) => {
        samData.total_sam += sectionDataItem?.sam;

        switch (sectionDataItem?.ob_machine_type_info?.name.toLowerCase()) {
          case "helper":
            samData.total_smv_helper_sam += sectionDataItem?.sam;
            samData.total_target_helper_sam += 60 / sectionDataItem?.sam;
            break;
          case "hp":
            samData.total_smv_helper_sam += sectionDataItem?.sam;
            samData.total_target_helper_sam += 60 / sectionDataItem?.sam;
            break;
          case "iron":
            samData.total_smv_iron_sam += sectionDataItem?.sam;
            samData.total_target_iron_sam += 60 / sectionDataItem?.sam;
            break;
          case "ir":
            samData.total_smv_iron_sam += sectionDataItem?.sam;
            samData.total_target_iron_sam += 60 / sectionDataItem?.sam;
            break;
          default:
            samData.total_smv_machine_sam += sectionDataItem?.sam;
            samData.total_target_machine_sam += 60 / sectionDataItem?.sam;
            break;
        }
      });
    });

    // Section Values
    const sectionValues = sections?.flatMap((section) => {
      return section?.section_data?.flatMap((sectionDataItem) => {
        return {
          ob_detail_id: null,
          sam_data_bank_id: sectionDataItem?.id,

          target_helper_sam:
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() ===
              "helper" ||
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
              ? Number((60 / sectionDataItem?.sam).toFixed(2))
              : null,
          target_iron_sam:
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() ===
              "iron" ||
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() === "ir"
              ? Number((60 / sectionDataItem?.sam).toFixed(2))
              : null,
          target_machine_sam:
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() !==
              "helper" &&
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() !==
              "hp" &&
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() !==
              "ir" &&
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() !== "iron"
              ? Number((60 / sectionDataItem?.sam).toFixed(2))
              : null,
        };
      });
    });

    const bodyData = {
      tamplate_name: formValues?.tamplate_name,
      entry_date: formValues?.entry_date
        ? moment(formValues?.entry_date).format("YYYY-MM-DD")
        : null,
      total_smv_machine_sam: Number(samData.total_smv_machine_sam).toFixed(2),
      total_smv_helper_sam: Number(samData.total_smv_helper_sam).toFixed(2),
      total_smv_iron_sam: Number(samData.total_smv_iron_sam).toFixed(2),
      total_target_machine_sam: Number(
        samData.total_target_machine_sam.toFixed(2),
      ),
      total_target_helper_sam: Number(
        samData.total_target_helper_sam.toFixed(2),
      ),
      total_target_iron_sam: Number(samData.total_target_iron_sam.toFixed(2)),
      total_sam: samData.total_sam,
      values: sectionValues,
      garment_category_id: formValues?.garment_category_id || null,
      garment_type_id: formValues?.garment_type_id || null,
      garment_for_id: formValues?.garment_for_id || null,
    };

    // API Call
    const query =
      ob_id || ob_id === 0
        ? `${OB_TEMPLATE_EDIT}/${ob_id}`
        : `${OB_TEMPLATE_ADD}`;
    let response = null;

    if (ob_id || ob_id === 0) {
      response = await putData(query, bodyData);
    } else {
      response = await postData(query, bodyData);
    }

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        "OB Template created successfully",
        null,
      );
      // Navigate to ob template list page
      navigate(`/configuration/ob-template`);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error adding OB Template",
        null,
      );
    }
  };

  const onSelectObTemplate = (id) => {
    const foundOb = obTemplates?.find((ob) => ob?.id === id);
    const sam_data_bank = foundOb?.sam_data_bank;
    const allSections = [];

    isArrayAndHasValue(sam_data_bank) &&
      sam_data_bank?.forEach((item) => {
        const sectionExists = allSections?.find(
          (section) => section?.section_id === item?.ob_section_info?.id,
        );

        if (sectionExists) {
          sectionExists?.section_data?.push(item);
        } else {
          allSections.push({
            section_id: item?.ob_section_info?.id,
            section_name: item?.ob_section_info?.name,
            section_data: [item],
          });
        }
      });

    isArrayAndHasValue(allSections) && setSections(allSections);
  };

  return (
    <InventoryLayOutCommon>
      <AppPageHeader
        extra={extra}
        title={
          <Title level={5}>{`OB Template ${isAdd ? "Add" : "Update"}`}</Title>
        }
      />
      <Content className="item-details">
        <div ref={exportRef}>
          {/* OB Form */}
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 15 }}>
            <Panel header={<b>OB Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <FormItems
                  onSelectSAM={onSelectSAM}
                  getAllSections={getAllSections}
                  obSamBanks={obSamBanks}
                  garmentCategoryList={garmentCategoryList}
                  garmentTypeList={garmentTypeList}
                  garmentForList={garmentForList}
                  getAllGarmentCategory={getAllGarmentCategory}
                  getAllGarmentType={getAllGarmentType}
                  getAllGarmentFor={getAllGarmentFor}
                  obTemplates={obTemplates}
                  onSelectObTemplate={onSelectObTemplate}
                />
              </Form>
            </Panel>
          </Collapse>
          {/* OB Table */}
          {isArrayAndHasValue(sections) && (
            <OBTable sectionData={sections} removeRow={removeRow} view={view} />
          )}
        </div>
      </Content>
    </InventoryLayOutCommon>
  );
};

export default OBTemplateForm;
