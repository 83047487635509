import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import { isArrayAndHasValue } from "../../../../../utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const FormItems = (props) => {
  // Props
  const {
    onSelectSAM,
    getAllSections,
    obSamBanks,
    garmentCategoryList,
    garmentTypeList,
    garmentForList,
    getAllGarmentCategory,
    getAllGarmentType,
    getAllGarmentFor,
    obTemplates,
    onSelectObTemplate,
  } = props;

  // Antd
  const { Option } = Select;

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Template Name"
            name="tamplate_name"
            rules={[
              {
                required: true,
                message: `Template Name is required `,
              },
            ]}
          >
            <Input placeholder="Template Name" size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Entry Date"
            name="entry_date"
            rules={[
              {
                required: true,
                message: `Entry Date is required `,
              },
            ]}
            initialValue={moment()}
          >
            <CustomDatePicker
              style={{
                width: "100%",
              }}
              align="right"
              size="small"
            />
            {/* <DatePicker
              format="YYYY-MM-DD"
              style={{
                width: "100%",
              }}
              align="right"
              size="small"
            /> */}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Garments Category" name="garment_category_id">
            <Select
              showSearch
              placeholder="Select a category"
              optionFilterProp="children"
              size="small"
              onSearch={(value) => getAllGarmentCategory({ name: value })}
              allowClear
            >
              {isArrayAndHasValue(garmentCategoryList) &&
                garmentCategoryList?.map((categoryItem, index) => (
                  <Option
                    value={categoryItem?.id}
                    key={categoryItem?.id || index}
                  >
                    {categoryItem?.name || ""}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Garments Type" name="garment_type_id">
            <Select
              showSearch
              placeholder="Select a type"
              optionFilterProp="children"
              size="small"
              onSearch={(value) => getAllGarmentType({ name: value })}
              allowClear
            >
              {isArrayAndHasValue(garmentTypeList) &&
                garmentTypeList?.map((typeItem, index) => (
                  <Option value={typeItem?.id} key={typeItem?.id || index}>
                    {typeItem?.name || ""}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Applicable For" name="garment_for_id">
            <Select
              showSearch
              placeholder="Select for"
              optionFilterProp="children"
              size="small"
              onSearch={(value) => getAllGarmentFor({ name: value })}
              allowClear
            >
              {isArrayAndHasValue(garmentForList) &&
                garmentForList?.map((typeItem, index) => (
                  <Option value={typeItem?.id} key={typeItem?.id || index}>
                    {typeItem?.name || ""}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Insert Operations" name="section_id">
            <Select
              onSelect={(value) => onSelectSAM(value)}
              onSearch={(value) => getAllSections({ combination: value })}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search"
              optionFilterProp="children"
              size="small"
            >
              {obSamBanks?.map((item, index) => (
                <Option
                  key={item?.id}
                  value={item?.id}
                  label={item?.combination}
                >
                  {item?.combination}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {isArrayAndHasValue(obTemplates) && (
          <Col span={4}>
            <Form.Item label="OB Templates" name="ob_template_id">
              <Select
                onSelect={(value) => onSelectObTemplate(value)}
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search"
                optionFilterProp="children"
                size="small"
              >
                {obTemplates?.map((item, index) => (
                  <Option
                    key={item?.id}
                    value={item?.id}
                    label={item?.template_name}
                  >
                    {item?.template_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default FormItems;
