import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Form, Input, Radio} from "antd";
import Modal from "antd/es/modal/Modal";
import {onUpdateSelectedSewingLine, onCreateSewingLine} from "../../../redux/actions";
import SewingLineCreateForm from "./SewingLineCreateForm";
import {useNavigate} from "react-router";


const CreateSewingLine = (props) => {
    const dispatch = useDispatch();
    const {isAddSewingLine, handleAddSewingLineClose, selectedSewingLine, onUpdateSewingLine} = props;
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [selectedSewingLine]);

    return (
        <>
            <Modal forceRender
                open={isAddSewingLine}
                title={selectedSewingLine ? `Update Sewing Line` : 'Create Sewing Line'}
                okText={'Save'}
                cancelText="Discard"
                cancelButtonProps={{type: "danger" }}
                onCancel={() => {
                    handleAddSewingLineClose();
                }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();

                            if (selectedSewingLine) {
                                const updateObj = {
                                    id: selectedSewingLine.id,
                                    name: selectedSewingLine.name,
                                    SewingLine_unique_id: selectedSewingLine.SewingLine_unique_id,
                                    status: selectedSewingLine.status,
                                    ...values
                                }
                                dispatch(onUpdateSelectedSewingLine(updateObj));
                                props.refetch();
                            }
                            else{
                                const newSewingLine = {
                                    ...values
                                }
                                dispatch(onCreateSewingLine(newSewingLine));
                                props.refetch()
                            }
                            handleAddSewingLineClose();
                            form.resetFields();

                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        status: selectedSewingLine?.status || '1',
                        name: selectedSewingLine?.name || '',
                        capacity: selectedSewingLine?.capacity || '',
                    }}
                >
                    <SewingLineCreateForm selectedSewingLine={selectedSewingLine}/>
                </Form>

            </Modal>
        </>
    );

}

export default CreateSewingLine;