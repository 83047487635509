import { Col, Form, Row, Input } from "antd";

const NotificationSlugsFilterFields = ({ initialTableData }) => {
  return (
    <>
      <Col span={4}>
        <Form.Item label="Name" name="name">
          <Input placeholder="Name" size="small"/>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Code" name="code">
          <Input placeholder="Code"  size="small"/>
        </Form.Item>
      </Col>
    </>
  );
};

export default NotificationSlugsFilterFields;
