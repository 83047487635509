import React, { forwardRef, useState, useEffect } from "react";
import {
  Button,
  Card,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Form,
  Input,
  Select,
  Table,
  Space,
} from "antd";
import { FilePdfOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  GET_LOCAL_SUPPLIER_LISTS,
  GET_SUPPLIER_INVOICE_LISTS,
  GET_SUPPLIERS_CHALLAN_LISTS,
  GET_INVOICE_DETAILS,
  INVOICE_CHALLAN_MAPPING_RESOURCE
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData, putData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import { commaSeparateNumber, isArrayAndHasValue } from "../../../../utils/functions";
import ChallanAssignTable from "./ChallanAssignTable";
import { useParams } from "react-router";
// import ImportB2blcDocument from "../../../Common/ImportB2blcDocument";

const ReconciliationForm = forwardRef((props) => {
  // Props
  const { reconciliationInfo, view, form, discartForm, isEdit } = props;

  const { reconciliationId } = useParams();
  const initialChallanDetails = {
    unique_id: 1,
    challan_id: null,
    receive_date: "",
    item_category: "",
    challan_qty: "",
    challan_value: "",
    unit: ""
  };

  // States
  const [piNumbersList, setPiNumberList] = useState([]);
  const [filesList, setFileList] = useState([]);
  const [challanDetailsList, setChallanDetailsList] = useState([initialChallanDetails]);

  // new
  const [localSuppliers, setLocalSuppliers] = useState([]);
  const [localSupplierInvoices, setLocalSupplierInvoices] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [challanList, setChallanList] = useState([]);
  const [diffDetails, setDiffDetails] = useState(null);

  // Antd Constants
  const { Option } = Select;

  // Effects
  useEffect(() => {
    getLocalSuppliers();
  }, []);

  useEffect(() => {
    if(reconciliationInfo) {
      // getLocalSuppliers(reconciliationInfo?.supplier_id);
      getSupplierInvoiceLists(reconciliationInfo?.supplier_id);
      getSupplierChallanLists(reconciliationInfo?.supplier_id);
      getInvoiceDetails(reconciliationInfo?.import_details_id);

      form.setFieldsValue({
        supplier_id: reconciliationInfo?.supplier_id,
        import_details_id: reconciliationInfo?.import_details_id
      });

      const flattenedDetails = reconciliationInfo?.challans?.reduce(
        (acc, challan) => {
          acc.push(...challan?.details);
          return acc;
        },
        []
      );

      // Calculate the unique_id for each detail based on its position in the array
      const challanDetailsWithUniqueID = flattenedDetails?.map((detail, index) => ({
        ...detail,
        unique_id: index + 1, // Increment unique_id starting from 1
      }));

      setChallanDetailsList(challanDetailsWithUniqueID);
    }
  }, [reconciliationInfo, reconciliationId, isEdit]);

  const onFinishReconciliationForm = async (values) => {
    
    const bodyData = {
      ...values,
      challan_details: challanDetailsList,
    };

    if (reconciliationId) {
      const response = await putData(
        INVOICE_CHALLAN_MAPPING_RESOURCE + "/" + reconciliationId,
        bodyData,
      );

      if (response) {
        alertPop("success", "Mapping Updated Successfully");
        form.resetFields();
        discartForm();
      }
    } else {
      const response = await postData(INVOICE_CHALLAN_MAPPING_RESOURCE, bodyData);

      if (response) {
        alertPop("success", "Mapping Created Successfully");
        form.resetFields();
        discartForm();
      }
    }
  };

  // local purchase reconciliation
  const getLocalSuppliers = async(filterValues) => {

    const bodyData = {
      local_status: 1,
      ...filterValues,
    };

    const response = await getData(GET_LOCAL_SUPPLIER_LISTS, false, bodyData);
    console.log('suppliers', response);
    if (response && response?.data?.code === 200) {
      setLocalSuppliers(response?.data?.data)
    }
  };

  const getSupplierInvoiceLists = async (value) => {

    form.resetFields(["import_details_id"]);
    const query = isEdit 
                  ? `${GET_SUPPLIER_INVOICE_LISTS}/${value}?edit=1`
                  :  `${GET_SUPPLIER_INVOICE_LISTS}/${value}`;
    const response = await getData(query);

    if (response && response?.data?.code==200) {
      setLocalSupplierInvoices(response?.data?.data || []);
    }
  };

  const getInvoiceDetails = async (value) => {

    const query = `${GET_INVOICE_DETAILS}/${value}`;
    const response = await getData(query);

    if(response && response?.data?.code == 200){
      setInvoiceDetails(response?.data?.data)
      const details = response?.data?.data;

      if(details) {
        form.setFieldsValue({
          buyer_name: details?.buyer_name,
          position_type: details?.position_type,
          b2b_lc_number: details?.b2blc_info?.b2b_lc_number,
          goods_description: details?.goods_description,
          import_date: details?.import_date,
          invoice_qty: details?.quantity_ctn,
          invoice_number: details?.invoice_number,
          invoice_value: details?.invoice_value,
          import_date: details?.import_date
        });
      }
    }
  };

  const getSupplierChallanLists = async (value) => {
      
    const query = isEdit && reconciliationId
                  ? `${GET_SUPPLIERS_CHALLAN_LISTS}/${value}?edit=1&map_id=${reconciliationId}`
                  : `${GET_SUPPLIERS_CHALLAN_LISTS}/${value}`;
                  
    const response = await getData(query);
    if (response && response?.data?.code==200) {
      setChallanList(response?.data?.data || []);
    }
  };
  // end local purchase reconciliation

  useEffect(() => {
    form.setFieldsValue({
      diff_qty: diffDetails?.diffQty,
      diff_value: diffDetails?.diffValue,
      status: diffDetails?.status,
    })
  }, [diffDetails])


  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishReconciliationForm}
      >
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Local Suppliers"
                name="supplier_id"
                rules={[
                  {
                    required: true,
                    message: "Supplier is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Suppliers"
                  disabled={isEdit ? true: false}
                  style={{ width: "100%" }}
                  onSelect={(value) => {
                    setInvoiceDetails(null);
                    setChallanList([]);
                    getSupplierInvoiceLists(value);
                    getSupplierChallanLists(value);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {localSuppliers.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Invoice"
                name="import_details_id"
                rules={[
                  {
                    required: true,
                    message: "Invoice is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Invoice"
                  style={{ width: "100%" }}
                  disabled={isEdit ? true: false}
                  onChange={(value) => {
                    getInvoiceDetails(value);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {localSupplierInvoices.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.invoice_number}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Buyer" name="buyer_name">
                <Input style={{ width: "100%" }} disabled={true} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Payment Type" name="position_type">
                <Input style={{ width: "100%" }} disabled={true} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Reference No" name="b2b_lc_number">
                <Input style={{ width: "100%" }} disabled={true} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Goods Description" name="goods_description">
                <Input style={{ width: "100%" }} disabled={true} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Import Date" name="import_date">
                <Input style={{ width: "100%" }} disabled={true} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Invoice Number" name="invoice_number">
                <Input style={{ width: "100%" }} disabled={true} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Invoice Value" name="invoice_value">
                <Input style={{ width: "100%" }} disabled={true} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Invoice Qty" name="invoice_qty">
                <Input style={{ width: "100%" }} disabled={true} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Diff Qty" name="diff_qty">
                <Input style={{ width: "100%" }} disabled={true}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Diff Value" name="diff_value">
                <Input style={{ width: "100%" }} disabled={true}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Status" name="status">
                <Input style={{ width: "100%" }} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        {invoiceDetails && (
          <ChallanAssignTable
            challanList={challanList}
            challanDetailsList={challanDetailsList}
            setChallanDetailsList={setChallanDetailsList}
            diffDetails={diffDetails}
            setDiffDetails={setDiffDetails}
            invoiceDetails={invoiceDetails}
            isEdit={isEdit}
          />
        )}
      </Form>
    </div>
  );
});

export default ReconciliationForm;
