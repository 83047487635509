import {
    GET_CUTTING_LIST,
    STORE_NEW_CUTTING,
    DELETE_CUTTING,
    GET_CUTTING_DETAIL, UPDATE_CUTTING_DETAIL
} from '../ActionTypes';

const initialState = {
    CuttingList: [],
    totalCutting: null,
    selectedCutting: null,
    selectedCuttingDetails: null,
    createdCutting: null,
    selectedCuttingAccountInfo: null,
    pageSize: 10,
    page:1,
};

const CuttingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUTTING_LIST:
            return {
                ...state,
                CuttingList: action.payload.data,
                totalCutting: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case STORE_NEW_CUTTING:
            return {
                ...state,
                createdCutting: action.payload.data,
                CuttingList: [action.payload.data, ...state.CuttingList],
                totalCutting: state.totalCutting + 1,
            };

        case DELETE_CUTTING: {
            return {
                ...state,
                CuttingList: action.payload.list,
                totalCutting: action.payload.total,
            };
        }

        case GET_CUTTING_DETAIL:
            return {
                ...state,
                selectedCutting: action.payload,
                selectedCuttingAccountInfo: action.payload,
                selectedCuttingDetails: action.payload,
            };

        case UPDATE_CUTTING_DETAIL:
            return {
                ...state,
                selectedCutting: action.payload,
                CuttingList: state.CuttingList.map((Cutting) =>
                    Cutting.id === action.payload.id ? action.payload : Cutting,
                ),
            };

        default:
            return state;
    }
};

export default CuttingReducer;
