import { Col, Input, Form, Row, Select, Button, Space, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { onGetUserPermissionList } from "redux/actions";
import { forData } from "./static_data";
import { isArrayAndHasValue } from "utils/functions";

const UserRoleForm = (props) => {
  // Props
  const { addAllPermissions, removeAllPermissions } = props;

  // Redux
  const dispatch = useDispatch();
  const userPermissionList = useSelector(
    ({ userRole }) => userRole.userPermissionList,
  );

  //   Antd
  const { Option } = Select;

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                label="Role Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: `Role name is required`,
                  },
                ]}
              >
                <Input placeholder="Role Name" size="small" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="For"
                name="for"
                rules={[
                  {
                    required: true,
                    message: `This field is required `,
                  },
                ]}
                initialValue={0}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select For"
                  optionFilterProp="children"
                  size="small"
                  allowClear
                >
                  {isArrayAndHasValue(forData) &&
                    forData?.map((item, index) => (
                      <Option key={item?.name} value={item?.value}>
                        {item?.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="has_app_access"
                placeholder="Yes / No"
                label="Has App Access"
                valuePropName="checked"
                initialValue={0}
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={20}>
          <div className="centered-item-parent">
            <Space>
              <Button primary size="small" onClick={addAllPermissions}>
                Add All Permissions
              </Button>
              <Button danger size="small" onClick={removeAllPermissions}>
                Remove All Permissions
              </Button>
            </Space>
          </div>
          <Form.Item label="Permissions" name="permissions">
            <Select
              onSearch={(value) =>
                dispatch(onGetUserPermissionList(1, 10, value))
              }
              mode="tags"
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              listHeight={"40vh"}
              className="custom-dropdown-multiple-select"
            >
              {userPermissionList?.map((role, idx) => (
                <Option key={idx} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default UserRoleForm;
