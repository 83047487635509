import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postData, getData } from "../../../../apiServices/common";
import AddPiForm from "./AddPiForm";
import { alertPop } from "../../../../apiServices/common/helper";

export default function EditExportSalesContract(props) {
  let { importId } = useParams();

  const { width } = props;
  const propertyFormRef = useRef(null);
  const [permitInfo, setPermitInfo] = useState();
  const [piStatus, setPiStatus] = useState();
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const discartForm = () => {
    // propertyFormRef.current.discart();
    navigate("/import/pi");
  };

  const addProduct = () => {
    // alert("adfafd");
    // return false;
    form.submit();
    //propertyFormRef.current.submit();
  };
  // const base_url = process.env.REACT_APP_BASE;
  const edit_url = `/api/accounting/import-pi/edit/${importId}`;
  const getPIInfo = async () => {
    let res = await getData(edit_url);

    if (res && res?.data?.code == 200) {
      let masterData = res?.data?.data[0];
      let piStatus = setPiStatus(masterData?.sales_contract_commercial?.status);
      setPermitInfo(masterData);
    } else  {
      alertPop("error","You cannot update this PI now");
      navigate("/import/pi");
    }
  };

  useEffect(() => {
    getPIInfo();
  }, []);


  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Update Proforma Invoice"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} disabled>
                {piStatus}
              </Button>,
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
              >
                Update
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <AddPiForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        form={form}
      />
    </>
  );
}
