import { Form, message } from "antd";
import {
  DAILY_LINE_PLAN,
  GET_DIGITALIZATION_STYLES,
  GET_DIGITALIZATION_SUB_STYLES,
  GET_ID_CARD_NO_USER,
  GET_LINE_OB_INFO,
  GET_OB_INFO,
  GET_SEWING_LINE_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "apiServices/common";
import { debounce } from "lodash";
import moment from "moment";
import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

export const DailyLinePlanContext = createContext();

const DailyLinePlanContextProvider = ({ children }) => {
  const [form] = Form.useForm();
  const [dailyLinePlanData, setDailyLinePlanData] = useState({});

  const [isLineSelected, setIsLineSelected] = useState(false);
  const [isStyleSelected, setIsStyleSelected] = useState(false);

  const [options, setOptions] = useState({
    lines: [],
    buyers: [],
    styles: [],
    subStyles: [],
    po: [],
    section: {},
  });

  // rout
  const { id } = useParams();
  const navigate = useNavigate();

  // Handle Change
  const onChangeLineChief = useCallback(async (value) => {
    if (value) {
      const res = await getNameById(value);
      if (res) {
        form.setFieldsValue({
          line_chief: {
            name: res?.user_info?.name,
            id: value,
            line_chief_id: res?.user_info?.id,
          },
        });
      }
    } else {
      form.setFieldsValue({
        line_chief: {
          name: "",
        },
      });
    }
  }, []);

  // console.log({dailyLinePlanData})

  const onChangeLine = useCallback(
    async (
      line,
      value,
      styleInfo = null,
      saleContractId = null,
      sales_contract = null,
      buyerID = null,
      factoryID = null,
      resData = {},
    ) => {
      // console.log({
      //   value,
      // });
      setIsStyleSelected(!!value);
      setIsLineSelected(!!line);
      if (!id) {
        form.resetFields([
          "style",
          "sub_style_id",
          "buyer",
          "factory",
          "sales_contract",
          "po_map_id",
        ]);
      }

      setIsStyleSelected(false);

      const operation_source = form.getFieldValue("operation_source");

      if (!operation_source) return;
      const poOption = options.styles.find((style) => style.value === value);

      const salesContractInfo =
        poOption?.active_sales_contract_map_info?.active_sales_contract_info;

      if (poOption) {
        form.resetFields([
          "sub_style_id",
          "buyer",
          "factory",
          "sales_contract",
          "po_map_id",
        ]);

        setOptions((prevOptions) => ({
          ...prevOptions,
          subStyles: poOption?.sub_style?.map((subStyle) => ({
            ...subStyle,
            style_info: {
              active_sales_contract_map_info:
                poOption?.active_sales_contract_map_info,
              buyer_id: poOption?.buyer_id,
              buyer_info: poOption?.buyer_info,
              factory_info: poOption?.factory_info,
              sub_contract_factory_id: poOption?.sub_contract_factory_id,
              subcontract_status: poOption?.subcontract_status,
            },
            value: subStyle.id,
            label: subStyle?.sub_style_no,
          })),
        }));

        // console.log({
        //   poOption,
        // });

        form.setFieldsValue({
          po_map_id: undefined,
          subcontract_status: poOption?.subcontract_status,
          buyer:
            poOption?.subcontract_status === 0 && poOption?.buyer_info?.name,
          buyer_id:
            poOption?.subcontract_status === 0 && poOption?.buyer_info?.id,
          factory_id:
            poOption?.subcontract_status === 1 && poOption?.factory_info?.id,
          factory:
            poOption?.subcontract_status === 1 && poOption?.factory_info?.name,
          sales_contract: sales_contract
            ? sales_contract
            : salesContractInfo?.reference_no,
          sales_contract_id: saleContractId
            ? saleContractId
            : salesContractInfo?.id,
          ...(!operation_source && {
            line_chief: {
              line_chief_id: poOption?.line_chief_user_id,
              id: poOption?.line_chief_emp_id,
              name: poOption?.line_chief_user_info?.name,
            },
          }),
        });
      }

      if (!line) {
        message.warning("Please select a line");
        return;
      }

      if (!value) return;

      const response = await getData(`${GET_LINE_OB_INFO}/${line}`, null, {
        operation_source: operation_source ? 1 : 0,
      });

      if (response?.data?.data === null) {
        message.error(response?.data?.message);
      }
      // response.data?.data?.existing_daily_line_plan
      form.setFieldsValue({
        line_chief: {
          line_chief_id:
            response?.data?.data?.existing_daily_line_plan?.line_chief_user_id,
          id: response?.data?.data?.existing_daily_line_plan
            ?.line_chief_user_info?.additional_info?.id_card_no,
          name: response?.data?.data?.existing_daily_line_plan
            ?.line_chief_user_info?.name,
        },
      });

      const data = response.data?.data?.operation_bulletine_details?.map(
        (operation) => {
          const findSection =
            response.data?.data?.existing_daily_line_plan?.section_info?.find(
              (itm) => itm.section_id === operation.section_id,
            );
          const newSectionSupervisor = {};

          if (findSection) {
            newSectionSupervisor.id =
              findSection?.supervisor_info?.additional_info?.id_card_no;
            newSectionSupervisor.supervisor_user_id =
              findSection?.supervisor_info?.id;
            newSectionSupervisor.supervisor_user_name =
              findSection?.supervisor_info?.name;
          }

          return {
            ...operation,
            key: operation.id,
            supervisor: {
              id: undefined,
              operator_emp_id: undefined,
              operator_name: undefined,
              ...newSectionSupervisor,
            },
            details: operation?.details?.map((detail) => {
              const findSectionInfo =
                response.data?.data?.existing_daily_line_plan?.section_info?.find(
                  (section) => section?.section_id === operation?.section_id,
                );
              if (findSectionInfo) {
                const findDetails = findSectionInfo?.operations?.find(
                  (operation) =>
                    operation?.operation_id === detail?.id &&
                    operation?.instance_number === detail?.instance_number,
                );
                if (findDetails) {
                  return {
                    ...detail,
                    operation_id: detail.id,
                    sam: detail.sam_data_bank?.sam,
                    instance_number: detail.instance_number,
                    ob_hourly_target: detail?.hourly_target,
                    operator: {
                      id: findDetails?.operator_info?.additional_info
                        ?.id_card_no,
                      operator_user_id: findDetails?.operator_info?.id,
                      operator_user_name: findDetails?.operator_info?.name,
                    },
                    hourly_target: findDetails?.hourly_target,
                  };
                }
              }
              return {
                ...detail,
                operation_id: detail.id,
                sam: detail.sam_data_bank?.sam,
                instance_number: detail.instance_number, // Ensure instance_number is set
                ob_hourly_target: detail?.hourly_target,
              };
            }),
          };
        },
      );

      const section = data?.reduce((acc, curr, i) => {
        const sec = {
          ...acc,
          [i]: curr?.details?.map((detail) => ({
            ...detail,
            value: detail?.id,
            label: detail?.sam_data_bank?.name,
          })),
        };

        return sec;
      }, {});

      if (poOption) {
        setOptions((prev) => ({
          ...prev,
          po: poOption?.grouped_po_maps?.map((po) => ({
            ...po,
            value: po?.po_map_id,
            label: po?.po_map?.original_po,
          })),
          section,
        }));
      } else {
        setOptions((prev) => ({
          ...prev,
          section,
        }));
      }

      // console.log("onChangeStyle", { data });

      if (id && resData?.style_id) {
        updateDailyLinePlanData(data, resData?.section_info);
      } else {
        setDailyLinePlanData((prevState) => ({
          ...prevState,
          ...response.data?.data,
          operation_bulletine_details: data,
        }));
      }
    },
    [options, form, setOptions],
  );
  const onChangeStyle = useCallback(
    async (
      value,
      styleInfo = null,
      saleContractId = null,
      sales_contract = null,
      buyerID = null,
      factoryID = null,
      resData = {},
    ) => {
      // console.log({
      //   value,
      // });
      setIsStyleSelected(!!value);

      const operation_source = form.getFieldValue("operation_source");
      const line = form.getFieldValue("line");
      const poOption = options.styles.find((style) => style.value === value);

      const salesContractInfo =
        poOption?.active_sales_contract_map_info?.active_sales_contract_info;

      if (poOption) {
        form.resetFields([
          "sub_style_id",
          "buyer",
          "factory",
          "sales_contract",
          "po_map_id",
        ]);

        setOptions((prevOptions) => ({
          ...prevOptions,
          subStyles: poOption?.sub_style?.map((subStyle) => ({
            ...subStyle,
            style_info: {
              active_sales_contract_map_info:
                poOption?.active_sales_contract_map_info,
              buyer_id: poOption?.buyer_id,
              buyer_info: poOption?.buyer_info,
              factory_info: poOption?.factory_info,
              sub_contract_factory_id: poOption?.sub_contract_factory_id,
              subcontract_status: poOption?.subcontract_status,
            },
            value: subStyle.id,
            label: subStyle?.sub_style_no,
          })),
        }));

        // console.log({
        //   poOption,
        // });

        form.setFieldsValue({
          po_map_id: undefined,
          subcontract_status: poOption?.subcontract_status,
          buyer:
            poOption?.subcontract_status === 0 && poOption?.buyer_info?.name,
          buyer_id:
            poOption?.subcontract_status === 0 && poOption?.buyer_info?.id,
          factory_id:
            poOption?.subcontract_status === 1 && poOption?.factory_info?.id,
          factory:
            poOption?.subcontract_status === 1 && poOption?.factory_info?.name,
          sales_contract: sales_contract
            ? sales_contract
            : salesContractInfo?.reference_no,
          sales_contract_id: saleContractId
            ? saleContractId
            : salesContractInfo?.id,
          ...(!operation_source && {
            line_chief: {
              line_chief_id: poOption?.line_chief_user_id,
              id: poOption?.line_chief_emp_id,
              name: poOption?.line_chief_user_info?.name,
            },
          }),
        });
      }

      if (!line) {
        message.warning("Please select a line");
        return;
      }
      if (!value) return;

      if (operation_source) return;

      let response;
      if (id) {
        const saleContract_Id = saleContractId
          ? saleContractId
          : salesContractInfo?.id;

        if (poOption?.subcontract_status === 1 && poOption?.factory_info?.id) {
          response = await getData(
            `${GET_OB_INFO}/${line}/${value}/${saleContract_Id}?line_plan_id=${id}&sub_contract_factory_id=${
              poOption?.factory_info?.id ?? factoryID
            }`,
          );
        } else {
          response = await getData(
            `${GET_OB_INFO}/${line}/${value}/${saleContract_Id}?line_plan_id=${id}&buyer_id=${
              poOption?.buyer_info?.id ?? buyerID
            }`,
          );
        }
      } else {
        if (poOption?.subcontract_status === 1 && poOption?.factory_info?.id) {
          response = await getData(
            `${GET_OB_INFO}/${line}/${value}/${salesContractInfo?.id}?sub_contract_factory_id=${poOption?.factory_info?.id}`,
          );
        } else {
          response = await getData(
            `${GET_OB_INFO}/${line}/${value}/${salesContractInfo?.id}?buyer_id=${poOption?.buyer_info?.id}`,
          );
        }
      }

      if (response?.data?.data === null) {
        message.error(response?.data?.message);
      }
      // response.data?.data?.existing_daily_line_plan
      form.setFieldsValue({
        line_chief: {
          line_chief_id:
            response?.data?.data?.existing_daily_line_plan?.line_chief_user_id,
          id: response?.data?.data?.existing_daily_line_plan
            ?.line_chief_user_info?.additional_info?.id_card_no,
          name: response?.data?.data?.existing_daily_line_plan
            ?.line_chief_user_info?.name,
        },
      });

      const data = response.data?.data?.operation_bulletine_details?.map(
        (operation) => {
          const findSection =
            response.data?.data?.existing_daily_line_plan?.section_info?.find(
              (itm) => itm.section_id === operation.section_id,
            );
          const newSectionSupervisor = {};

          if (findSection) {
            newSectionSupervisor.id =
              findSection?.supervisor_info?.additional_info?.id_card_no;
            newSectionSupervisor.supervisor_user_id =
              findSection?.supervisor_info?.id;
            newSectionSupervisor.supervisor_user_name =
              findSection?.supervisor_info?.name;
          }

          return {
            ...operation,
            key: operation.id,
            supervisor: {
              id: undefined,
              operator_emp_id: undefined,
              operator_name: undefined,
              ...newSectionSupervisor,
            },
            details: operation?.details?.map((detail) => {
              const findSectionInfo =
                response.data?.data?.existing_daily_line_plan?.section_info?.find(
                  (section) => section?.section_id === operation?.section_id,
                );
              if (findSectionInfo) {
                const findDetails = findSectionInfo?.operations?.find(
                  (operation) =>
                    operation?.operation_id === detail?.id &&
                    operation?.instance_number === detail?.instance_number,
                );
                if (findDetails) {
                  return {
                    ...detail,
                    operation_id: detail.id,
                    sam: detail.sam_data_bank?.sam,
                    instance_number: detail.instance_number,
                    ob_hourly_target: detail?.hourly_target,
                    operator: {
                      id: findDetails?.operator_info?.additional_info
                        ?.id_card_no,
                      operator_user_id: findDetails?.operator_info?.id,
                      operator_user_name: findDetails?.operator_info?.name,
                    },
                    hourly_target: findDetails?.hourly_target,
                  };
                }
              }
              return {
                ...detail,
                operation_id: detail.id,
                sam: detail.sam_data_bank?.sam,
                instance_number: detail.instance_number, // Ensure instance_number is set
                ob_hourly_target: detail?.hourly_target,
              };
            }),
          };
        },
      );

      const section = data?.reduce((acc, curr, i) => {
        const sec = {
          ...acc,
          [i]: curr?.details?.map((detail) => ({
            ...detail,
            value: detail?.id,
            label: detail?.sam_data_bank?.name,
          })),
        };

        return sec;
      }, {});

      if (poOption) {
        setOptions((prev) => ({
          ...prev,
          po: poOption?.grouped_po_maps?.map((po) => ({
            ...po,
            value: po?.po_map_id,
            label: po?.po_map?.original_po,
          })),
          section,
        }));
      } else {
        setOptions((prev) => ({
          ...prev,
          section,
        }));
      }

      // console.log("onChangeStyle", { data });

      if (id && resData?.style_id) {
        updateDailyLinePlanData(data, resData?.section_info);
      } else {
        setDailyLinePlanData((prevState) => ({
          ...prevState,
          ...response.data?.data,
          operation_bulletine_details: data,
        }));
      }
    },
    [options, form, setOptions],
  );

  const onChangeSubStyle = useCallback(
    async (value) => {
      const operation_source = form.getFieldValue("operation_source");
      const saleContractId = form.getFieldValue("sales_contract_id");
      const sales_contract = form.getFieldValue("sales_contract");
      const buyerID = form.getFieldValue("buyer_id");
      const factoryID = form.getFieldValue("factory_id");

      const selectedSubStyle = options.subStyles.find(
        (item) => item.value === value,
      );

      // set style options
      const findStyle = options.styles.find(
        (style) => style.value === selectedSubStyle?.style_id,
      );
      if (!findStyle) {
        setOptions((prevOptions) => ({
          ...prevOptions,
          styles: [
            {
              ...selectedSubStyle?.style_info,
              value: selectedSubStyle?.style_info?.id,
              label: selectedSubStyle?.style_info?.style_no,
            },
            ...prevOptions.styles,
          ],
        }));
      }

      const salesContractInfo =
        selectedSubStyle?.style_info?.active_sales_contract_map_info
          ?.active_sales_contract_info;

      if (selectedSubStyle) {
        // console.log("🚀 ~ selectedSubStyle:", selectedSubStyle);
        form?.setFieldsValue({
          style: selectedSubStyle?.style_id,
          subcontract_status: selectedSubStyle?.style_info?.subcontract_status,
          buyer:
            selectedSubStyle?.style_info?.subcontract_status === 0 &&
            selectedSubStyle?.style_info?.buyer_info?.name,
          buyer_id:
            selectedSubStyle?.style_info?.subcontract_status === 0 &&
            selectedSubStyle?.style_info?.buyer_info?.id,
          factory_id:
            selectedSubStyle?.style_info?.subcontract_status === 1 &&
            selectedSubStyle?.style_info?.factory_info?.id,
          factory:
            selectedSubStyle?.style_info?.subcontract_status === 1 &&
            selectedSubStyle?.style_info?.factory_info?.name,
          sales_contract: sales_contract
            ? sales_contract
            : salesContractInfo?.reference_no,
          sales_contract_id: saleContractId
            ? saleContractId
            : salesContractInfo?.id,
        });
      }

      const line = form.getFieldValue("line");
      const sales_contract_id = form.getFieldValue("sales_contract_id");

      if (!line) {
        message.warning("Please select a line");
        return;
      }
      if (!selectedSubStyle?.style_id) return;
      if (!sales_contract_id) return;
      if (!value) return;
      if (operation_source) return;

      const poOption = options.styles.find(
        (style) => style.value === selectedSubStyle?.style_id,
      );

      let response;
      if (id) {
        if (
          selectedSubStyle?.style_info?.subcontract_status === 1 &&
          selectedSubStyle?.style_info?.factory_info?.id
        ) {
          response = await getData(
            `${GET_OB_INFO}/${line}/${
              selectedSubStyle?.style_id
            }/${saleContractId}?line_plan_id=${id}&sub_style_id=${value}&sub_contract_factory_id=${
              selectedSubStyle?.style_info?.factory_info?.id ?? factoryID
            }`,
          );
        } else {
          response = await getData(
            `${GET_OB_INFO}/${line}/${
              selectedSubStyle?.style_id
            }/${saleContractId}?line_plan_id=${id}&sub_style_id=${value}&buyer_id=${
              selectedSubStyle?.style_info?.buyer_info?.id ?? buyerID
            }`,
          );
        }
      } else {
        if (
          selectedSubStyle?.style_info?.subcontract_status === 1 &&
          selectedSubStyle?.style_info?.factory_info?.id
        ) {
          response = await getData(
            `${GET_OB_INFO}/${line}/${selectedSubStyle?.style_id}/${salesContractInfo?.id}?sub_style_id=${value}&sub_contract_factory_id=${selectedSubStyle?.style_info?.factory_info?.id}`,
          );
        } else {
          response = await getData(
            `${GET_OB_INFO}/${line}/${selectedSubStyle?.style_id}/${salesContractInfo?.id}?sub_style_id=${value}&buyer_id=${selectedSubStyle?.style_info?.buyer_info?.id}`,
          );
        }
      }

      if (response?.data?.data === null) {
        message.error(response?.data?.message);
      }

      form.setFieldsValue({
        line_chief: {
          line_chief_id:
            response?.data?.data?.existing_daily_line_plan?.line_chief_user_id,
          id: response?.data?.data?.existing_daily_line_plan
            ?.line_chief_user_info?.additional_info?.id_card_no,
          name: response?.data?.data?.existing_daily_line_plan
            ?.line_chief_user_info?.name,
        },
      });

      const data = response.data?.data?.operation_bulletine_details?.map(
        (operation) => {
          // Find matching section for supervisor info
          const findSection =
            response.data?.data?.existing_daily_line_plan?.section_info?.find(
              (section) => section.section_id === operation.section_id,
            );

          return {
            ...operation,
            key: operation.id,
            supervisor: {
              id:
                findSection?.supervisor_info?.additional_info?.id_card_no ||
                null,
              supervisor_user_id: findSection?.supervisor_info?.id || null,
              supervisor_user_name: findSection?.supervisor_info?.name || null,
            },
            details: operation?.details?.map((detail) => {
              const findSectionInfo =
                response.data?.data?.existing_daily_line_plan?.section_info?.find(
                  (section) => section?.section_id === operation?.section_id,
                );
              if (findSectionInfo) {
                const findDetails = findSectionInfo?.operations?.find(
                  (operation) =>
                    operation?.operation_id === detail?.id &&
                    operation?.instance_number === detail?.instance_number,
                );
                if (findDetails) {
                  return {
                    ...detail,
                    operation_id: detail.id,
                    sam: detail.sam_data_bank?.sam,
                    instance_number: detail.instance_number,
                    ob_hourly_target: detail?.hourly_target,
                    operator: {
                      id: findDetails?.operator_info?.additional_info
                        ?.id_card_no,
                      operator_user_id: findDetails?.operator_info?.id,
                      operator_user_name: findDetails?.operator_info?.name,
                    },
                    hourly_target: findDetails?.hourly_target,
                  };
                }
              }
              return {
                ...detail,
                operation_id: detail.id,
                sam: detail.sam_data_bank?.sam,
                instance_number: detail.instance_number, // Ensure instance_number is set
                ob_hourly_target: detail?.hourly_target,
              };
            }),
          };
        },
      );
      // console.log("onChangeSubStyle", { data });
      const section = data?.reduce((acc, curr, i) => {
        const sec = {
          ...acc,
          [i]: curr?.details?.map((detail) => ({
            ...detail,
            value: detail?.id,
            label: detail?.sam_data_bank?.name,
          })),
        };

        return sec;
      }, {});

      if (poOption) {
        setOptions((prev) => ({
          ...prev,
          po: poOption?.grouped_po_maps?.map((po) => ({
            ...po,
            value: po?.po_map_id,
            label: po?.po_map?.original_po,
          })),
          section,
        }));
      } else {
        setOptions((prev) => ({
          ...prev,
          section,
        }));
      }
      setDailyLinePlanData({
        ...response.data?.data,
        operation_bulletine_details: data,
      });
    },
    [options, form, setOptions],
  );

  const onChangeSupervisorId = async (
    section_index,
    details_id = null,
    name,
    value,
  ) => {
    const res = await getNameById(value);
    // console.log("🚀 ~ onChangeSupervisorId ~ res",res)

    if (res) {
      onChangeDailyLinePlanData(section_index, details_id, name, {
        id: value,
        supervisor_user_id: res?.user_info?.id,
        supervisor_user_name: res?.user_info?.name,
      });
    } else {
      onChangeDailyLinePlanData(section_index, details_id, name, {
        id: value,
        supervisor_user_id: undefined,
        supervisor_user_name: undefined,
      });
    }
  };

  const onChangeOperationId = async (
    section_index,
    details_id = null,
    name,
    value,
    row_index,
  ) => {
    // console.log("🚀 ~ DailyLinePlanContextProvider ~ row_index:", row_index);
    // console.log(
    //   "🚀 ~ DailyLinePlanContextProvider ~ section_index:",
    //   section_index,
    // );
    // console.log("🚀 ~ DailyLinePlanContextProvider ~ details_id:", details_id);
    const res = await getNameById(value);
    if (res) {
      onChangeDailyLinePlanData(
        section_index,
        details_id,
        name,
        {
          id: value,
          operator_user_id: res?.user_info?.id,
          operator_user_name: res?.user_info?.name,
        },
        row_index,
      );
    } else {
      onChangeDailyLinePlanData(
        section_index,
        details_id,
        name,
        {
          id: value,
          operator_user_id: undefined,
          operator_user_name: undefined,
        },
        row_index,
      );
    }
  };

  // add
  const onAdd = (section_index) => {
    const clone = JSON.parse(JSON.stringify(dailyLinePlanData));
    const section = clone?.operation_bulletine_details?.[section_index];
    if (!section) return;
    section.details.push({
      id: `new-${Date.now()}`,
      is_manually_added: 1,
    });
    setDailyLinePlanData(clone);
  };

  // copy
  const onCopy = (section_index, details_id) => {
    const clone = JSON.parse(JSON.stringify(dailyLinePlanData));
    const section = clone?.operation_bulletine_details?.[section_index];
    if (!section) return;
    const detailIndex = section?.details?.findIndex(
      (detail) => detail.id === details_id,
    );
    if (detailIndex === -1) return;
    const detail = section?.details?.[detailIndex];
    section.details.push({
      ...detail,
      id: `new-${Date.now()}`,
      is_manually_added: 1,
    });
    setDailyLinePlanData(clone);
  };

  // delete
  const onDelete = (section_index, details_id) => {
    const clone = JSON.parse(JSON.stringify(dailyLinePlanData));
    const section = clone?.operation_bulletine_details?.[section_index];
    if (!section) return;
    const detailIndex = section?.details?.findIndex(
      (detail) => detail.id === details_id,
    );
    if (detailIndex === -1) return;
    section.details.splice(detailIndex, 1);
    setDailyLinePlanData(clone);
  };

  // on change dailyLinePlanData
  const onChangeDailyLinePlanData = (
    section_index,
    details_id = null,
    name,
    value,
    row_index,
  ) => {
    // console.log("🚀 ~ DailyLinePlanContextProvider ~ row_index:", row_index);
    const clone = JSON.parse(JSON.stringify(dailyLinePlanData));
    const section = clone?.operation_bulletine_details?.[section_index];
    // console.log("section", section);
    if (!section) return;
    const detail = section?.details?.[row_index]; // Access directly by row_index
    // console.log("detail", detail);
    if (!details_id && !row_index) {
      section[name] = value;
      setDailyLinePlanData(clone);
      return;
    }

    if (!detail || detail.id !== details_id) return;

    if (name === "operation_id") {
      // Update the specific operator fields in the row
      detail[name] = value?.id;
      detail["sam"] = value?.sam_data_bank?.sam;
      detail["hourly_target"] = value?.hourly_target;
      detail["sam_data_bank"] = value?.sam_data_bank;
      detail["instance_number"] = value?.instance_number;
      detail["ob_hourly_target"] = value?.hourly_target;
    } else {
      detail[name] = value;
    }
    // console.log("clone", clone);
    setDailyLinePlanData(clone);
  };

  // old
  // const onChangeDailyLinePlanData = (
  //   section_index,
  //   details_id = null,
  //   name,
  //   value,
  // ) => {
  //   const clone = JSON.parse(JSON.stringify(dailyLinePlanData));
  //   const section = clone?.operation_bulletine_details?.[section_index];
  //   if (!section) return;
  //   if (!details_id) {
  //     section[name] = value;
  //     setDailyLinePlanData(clone);
  //     return;
  //   }
  //   const detailIndex = section?.details?.findIndex(
  //     (detail) => detail.id === details_id,
  //   );
  //   if (detailIndex === -1) return;

  //   if (name === "operation_id") {
  //     section.details[detailIndex][name] = value?.id;
  //     section.details[detailIndex]["sam"] = value?.sam_data_bank?.sam;
  //   } else {
  //     section.details[detailIndex][name] = value;
  //   }

  //   setDailyLinePlanData(clone);
  // };

  const onChangeOperation = (section_index, details_id, name, value) => {
    const clone = JSON.parse(JSON.stringify(dailyLinePlanData));
    const section = clone?.operation_bulletine_details?.[section_index];
    if (!section) return;

    const detailIndex = section?.details?.findIndex(
      (detail) => detail.id === details_id,
    );
    if (detailIndex === -1) return;
    section.details[detailIndex][name] = {
      ...section.details[detailIndex][name],
      ...value,
    };

    setDailyLinePlanData(clone);
  };

  const updateDailyLinePlanData = (inputData, responseData) => {
    const convertData = inputData?.map((section, indx) => {
      // Find matching section from the response data
      const matchedSection = responseData.find(
        (resSection) => resSection.section_id === section.section_id,
      );

      return {
        section_id: section.section_id,
        section_name: section.section_name,
        // Update supervisor info from matched section if available
        supervisor: matchedSection
          ? {
              id:
                matchedSection.supervisor_info?.additional_info?.id_card_no ||
                null,
              supervisor_user_id: matchedSection.supervisor_info?.id || null,
              supervisor_user_name:
                matchedSection.supervisor_info?.name || null,
            }
          : section.supervisor, // Keep the current supervisor if no match found
        details: section.details?.map((detail, i) => {
          // Check if operation ID matches to update existing details
          const matchedDetail = matchedSection
            ? matchedSection.operations.find(
                (operation) =>
                  operation.operation_id === detail.operation_id &&
                  operation.instance_number === detail.instance_number &&
                  operation.is_manually_added === detail.is_manually_added,
              )
            : null;

          let operationId = matchedDetail ? matchedDetail.id : null;
          let operator = {
            id: id
              ? matchedDetail?.operator_info?.additional_info?.id_card_no
              : detail.operator?.id,
            operator_user_id: id
              ? matchedDetail?.operator_info?.id
              : detail.operator?.operator_user_id,
            operator_user_name: id
              ? matchedDetail?.operator_info?.name
              : detail.operator?.operator_user_name,
          };
          // operator id set if manually added
          if (detail?.is_manually_added) {
            operationId = detail?.daily_line_plan_section_detail_id;
          }
          // when duplicate operation same instance number and manually added same then find operator by operationId
          const findOperator = matchedSection?.operations?.find(
            (operator_itm) =>
              operator_itm.id === operationId &&
              operator_itm.instance_number === detail.instance_number &&
              operator_itm.is_manually_added === detail.is_manually_added,
          );

          if (findOperator) {
            operator = {
              id: findOperator?.operator_info?.additional_info?.id_card_no,
              operator_user_id: findOperator?.operator_info?.id,
              operator_user_name: findOperator?.operator_info?.name,
            };
          }

          // Get hourly_target from the matched operation in the API response
          const hourlyTarget = matchedDetail
            ? matchedDetail.hourly_target
            : null;

          return {
            id: operationId,
            instance_number: detail.instance_number,
            is_manually_added: detail.is_manually_added || 0,
            operation_id: detail.operation_id,
            operation_name:
              matchedDetail?.operation_info?.sam_data_bank?.name ||
              detail.sam_data_bank?.name,
            sam_data_bank:
              matchedDetail?.operation_info?.sam_data_bank ||
              detail.sam_data_bank,
            operator: operator,
            // Set hourly_target from API response
            hourly_target: hourlyTarget || detail.hourly_target,
            ob_hourly_target: detail.ob_hourly_target || detail.hourly_target,
          };
        }),
      };
    });

    setDailyLinePlanData((prevState) => ({
      ...prevState,
      operation_bulletine_details: convertData,
    }));
  };

  // get name
  const getNameById = useCallback(async (id) => {
    try {
      if (!id) return null;
      const response = await getData(`${GET_ID_CARD_NO_USER}/${id}`);
      return response?.data?.data || null;
    } catch (error) {
      console.error("getNameById -> error", error);
      return null;
    }
  }, []);

  // get line options from API
  const getLines = useCallback(async (filterValues) => {
    try {
      const response = await getData(GET_SEWING_LINE_ENDPOINT, false, {
        per_page: 50,
      });
      return response.data?.data?.data;
    } catch (error) {
      console.error("getLines -> error", error);
      return [];
    }
  }, []);

  // get Style options from API
  const getStyles = useCallback(async (filterValues = {}) => {
    try {
      const bodyData = { style_no: filterValues?.name?.name };
      const response = await getData(
        GET_DIGITALIZATION_STYLES,
        false,
        bodyData,
      );
      return response.data?.data?.data;
    } catch (error) {
      console.error("getLines -> error", error);
      return [];
    }
  }, []);

  // get line options from API
  const getSubStyles = useCallback(async (filterValues = {}) => {
    try {
      // const subcontract_status = form.getFieldValue("subcontract_status")

      const styleID = form.getFieldValue("style");

      const bodyData = {
        sub_style_no: filterValues?.name?.name,
        style_id: styleID,
      };
      const response = await getData(
        GET_DIGITALIZATION_SUB_STYLES,
        false,
        bodyData,
      );
      return response.data?.data?.data;
    } catch (error) {
      console.error("getSub-Styles -> error", error);
      return [];
    }
  }, []);
  // get options from API
  const getOptions = useCallback(async (style_info = null) => {
    const linesRes = await getLines();

    const lines = linesRes?.map((line) => ({
      ...line,
      value: line.id,
      label: line.name,
    }));

    // For Edit Form
    if (style_info) {
      const stylesRes = await getStyles();

      const styles = stylesRes?.map((style) => ({
        ...style,
        value: style.id,
        label: style.style_no,
      }));

      styles.push({
        ...style_info,
        value: style_info?.id,
        label: style_info?.style_no,
      });

      const selectStyle =
        styles &&
        styles.length > 0 &&
        styles.find((style) => style.value === style_info?.id);

      const subStylesEdit = selectStyle?.sub_style?.map((subStyle) => ({
        ...subStyle,
        style_info: {
          active_sales_contract_map_info:
            selectStyle?.active_sales_contract_map_info,
        },
        value: subStyle?.id,
        label: subStyle?.sub_style_no,
      }));

      const po = selectStyle?.grouped_po_maps?.map((po) => ({
        ...po,
        value: po?.po_map_id,
        label: po?.po_map?.original_po,
      }));

      setOptions((prevOptions) => ({
        ...prevOptions,
        lines,
        styles,
        subStyles: subStylesEdit,
        po,
      }));
    } else {
      const stylesRes = await getStyles();
      const subStylesRes = await getSubStyles();
      const styles = stylesRes?.map((style) => ({
        ...style,
        value: style.id,
        label: style.style_no,
      }));
      const subStyles = subStylesRes?.map((subStyle) => ({
        ...subStyle,
        value: subStyle?.id,
        label: subStyle?.sub_style_no,
      }));

      setOptions({
        ...options,
        lines,
        styles,
        subStyles,
      });
    }
  }, []);

  async function convertInputToObject(inputData) {
    const convertData = {
      date: moment(inputData?.date),
      line: inputData?.line_id,
      buyer: inputData?.buyer_id,
      style: inputData?.style_id,
      po_map_id: inputData?.po_map_id,
      id: inputData?.ob_id,
      line_chief_emp_id: inputData?.line_chief_emp_id,
      operation_bulletine_details: [],
    };

    convertData.operation_bulletine_details = inputData?.section_info?.map(
      (section) => {
        return {
          section_id: section?.section_id,
          section_name: section?.ob_section_info?.name,
          supervisor: {
            id: section?.supervisor_info?.additional_info?.id_card_no,
            supervisor_user_id: section?.supervisor_info?.id,
            supervisor_user_name: section?.supervisor_info?.name,
          },
          details: section?.operations?.map((detail) => {
            return {
              id: detail?.id,
              operation_id: detail?.operation_id,
              operation_name: detail?.operation_info?.sam_data_bank?.name,
              sam_data_bank: detail?.operation_info?.sam_data_bank,
              operator: {
                id: detail?.operator_info?.additional_info?.id_card_no,
                operator_user_id: detail?.operator_info?.id,
                operator_user_name: detail?.operator_info?.name,
              },
              hourly_target: detail?.hourly_target,
              ob_hourly_target: detail?.hourly_target,
            };
          }),
        };
      },
    );

    return convertData;
  }

  // get daily line plan data
  const getDailyLinePlanData = useCallback(async () => {
    try {
      const response = await getData(`${DAILY_LINE_PLAN}/${id}`);
      const resData = response?.data?.data;

      const { style_info, buyer_info, sub_contract_factory_id } = resData;
      const factoryID = sub_contract_factory_id
        ? sub_contract_factory_id
        : style_info?.factory_info?.id;
      const buyerID = buyer_info?.id ? buyer_info?.id : style_info?.buyer_id;
      const buyerName = buyer_info?.id
        ? buyer_info?.name
        : style_info?.buyer_info?.name;

      form.setFieldsValue({
        date: moment(resData?.date),
        operation_source: resData?.operation_source,
        line: resData?.line_id,
        style: resData?.style_id,
        sub_style_id: resData?.sub_style_id,
        buyer: buyerName,
        buyer_id: buyerID,
        factory_id: factoryID,
        factory: resData?.factory_info?.name,
        subcontract_status: resData?.subcontract_status,
        hourly_target_qty: resData?.hourly_target_qty,
        po_map_id: resData?.po_map_id,
        sales_contract: resData?.sales_contract?.reference_no,
        sales_contract_id: resData?.sales_contract_id,
        id: resData?.ob_id,
        line_chief_emp_id: resData?.line_chief_emp_id,
        line_chief: {
          line_chief_id: resData?.line_chief_user_id,
          id: resData?.line_chief_user_info?.additional_info?.id_card_no,
          name: resData?.line_chief_user_info?.name,
        },
      });

      // Save section_info with operations for later use
      const sectionInfoData = resData?.section_info || [];

      await onChangeLine(
        resData?.line_id,
        resData?.style_id,
        resData?.style_info,
        resData?.sales_contract_id,
        resData?.sales_contract?.reference_no,
        buyerID,
        factoryID,
        resData,
      );
      await onChangeStyle(
        resData?.style_id,
        resData?.style_info,
        resData?.sales_contract_id,
        resData?.sales_contract?.reference_no,
        buyerID,
        factoryID,
        resData,
      );
      await onChangeSubStyle(resData?.sub_style_id);
      await getOptions(resData?.style_info);

      setDailyLinePlanData((prevState) => {
        const updatedState = {
          ...prevState,
          po_map_id: resData?.po_map_id,
          id: resData?.ob_id,
          line_chief_emp_id: resData?.line_chief_emp_id,
        };

        if (prevState.operation_bulletine_details) {
          updatedState.operation_bulletine_details =
            prevState.operation_bulletine_details.map((section) => {
              const existingSection = sectionInfoData.find(
                (prevSection) => prevSection.section_id === section.section_id,
              );

              return {
                ...section,
                id: existingSection ? existingSection.id : null,
                details: section.details.map((detail) => {
                  // Find matching operation from API response
                  const matchedOperation = existingSection?.operations?.find(
                    (op) =>
                      op.operation_id === detail.operation_id &&
                      op.instance_number === detail.instance_number,
                  );

                  return {
                    ...detail,
                    // Preserve the original ob_hourly_target from the API response
                    // hourly_target will be from the existing daily_line_plan
                    ob_hourly_target: detail.ob_hourly_target,
                    hourly_target: matchedOperation
                      ? matchedOperation.hourly_target
                      : detail.hourly_target,
                  };
                }),
              };
            });
        }

        return updatedState;
      });
    } catch (error) {
      console.error("getDailyLinePlanData -> error", error);
    }
  }, []);
  // console.log("dailyLinePlanData", dailyLinePlanData);
  // Form Submit
  // convert
  function convertData(inputData) {
    const salesContractId = form.getFieldValue("sales_contract_id") || null;
    const buyerId = form.getFieldValue("buyer_id") || null;
    const FactoryID = form.getFieldValue("factory_id") || null;
    const subcontract_status = form.getFieldValue("subcontract_status");

    const {
      operation_source,
      date,
      line,
      style,
      sub_style_id,
      po_map_id,
      line_chief,
      dailyLinePlanData,
      hourly_target_qty,
    } = inputData;

    // console.log("dailyLinePlanData >> ", dailyLinePlanData);

    const output = {
      date: date.format("YYYY-MM-DD HH:mm:ss"),
      operation_source: operation_source ? 1 : 0,
      line_id: line,
      style_id: style,
      sub_style_id: sub_style_id,
      po_map_id,
      hourly_target_qty: hourly_target_qty,
      sales_contract_id: salesContractId,
      buyer_id: buyerId,
      sub_contract_factory_id: FactoryID,
      subcontract_status: subcontract_status,
      line_chief_user_id: line_chief.line_chief_id ?? null,
      line_chief_emp_id: dailyLinePlanData?.line_chief_emp_id,
      ob_id: dailyLinePlanData.id,
      operation_details: [],
    };

    for (const section of dailyLinePlanData.operation_bulletine_details) {
      const sectionDetails = {
        id: section.id,
        section_id: section.section_id,
        supervisor_user_id: section.supervisor.supervisor_user_id ?? null,
        details: [],
      };

      for (const operation of section.details) {
        // console.log("🚀 ~ convertData ~ operation:", operation);
        const detailId =
          typeof operation.id === "string" && operation.id.startsWith("new-")
            ? null
            : operation.id;
        sectionDetails.details.push({
          id: detailId,
          instance_number: operation.instance_number,
          operation_id: operation.operation_id,
          operator_user_id: operation.operator?.operator_user_id || null,
          hourly_target: parseInt(operation.hourly_target) || 0,
          is_manually_added: operation.is_manually_added || 0,
        });
      }

      output.operation_details.push(sectionDetails);
    }

    return output;
  }

  const onFinish = useCallback(
    async (values) => {
      try {
        // console.log("🚀 ~ onFinish ~ values", values);
        // const salesContractId = form.getFieldValue("sales_contract_id") || null;
        // const subcontract_status = form.getFieldValue("subcontract_status");

        // if (salesContractId === null) {
        //   message.error(
        //     "Required Sales Contract ID.",
        //   );
        //   return;
        // }
        // if (subcontract_status === null) {
        //   message.error(
        //     "Required Sub Contract Status.",
        //   );
        //   return;
        // }

        if (!dailyLinePlanData?.operation_bulletine_details) {
          message.error(
            "Required Operation Section! Please, change Buyer or Style.",
          );
          return;
        }

        const body = convertData({ ...values, dailyLinePlanData });
        // console.log("🚀 ~ onFinish ~ body", body);
        // return;
        if (id) {
          const res = await putData(`${DAILY_LINE_PLAN}/${id}`, body);
          if (res?.code === 200) {
            message.success("Data Updated successfully");
            navigate("/digitalization/daily-line-plan");
          }
        } else {
          const res = await postData(DAILY_LINE_PLAN, body);
          if (res?.code === 200) {
            message.success("Data saved successfully");
            navigate("/digitalization/daily-line-plan");
          }
        }
      } catch (error) {
        console.error("onFinish -> error", error);
      }
    },
    [dailyLinePlanData],
  );

  const debouncedFetchStylesOption = useCallback(
    debounce(async (value) => {
      const styles = await getStyles({ name: value });
      setOptions((prevOptions) => ({
        ...prevOptions,
        styles: styles?.map((style) => ({
          ...style,
          value: style.id,
          label: style?.style_no,
        })),
      }));
    }, 300),
    [setOptions],
  );
  const debouncedFetchSubStylesOption = useCallback(
    debounce(async (value) => {
      const subStyles = await getSubStyles({ name: value });
      setOptions((prevOptions) => ({
        ...prevOptions,
        subStyles: subStyles?.map((subStyle) => ({
          ...subStyle,
          value: subStyle.id,
          label: subStyle?.sub_style_no,
        })),
      }));
    }, 300),
    [setOptions],
  );

  useEffect(() => {
    if (id) {
      setIsLineSelected(true);
      setIsStyleSelected(true);
      getDailyLinePlanData();
    } else {
      form.setFieldsValue({
        date: moment().add(1, "days"),
        operation_source: 0,
      });

      getOptions();
    }
  }, [form, getOptions, id]);

  return (
    <DailyLinePlanContext.Provider
      value={{
        form,
        options,
        setOptions,
        isLineSelected,
        isStyleSelected,
        getNameById,
        onFinish,
        onChangeOperationId,
        onChangeLine,
        onChangeStyle,
        onChangeSubStyle,
        onChangeLineChief,
        dailyLinePlanData,
        onChangeDailyLinePlanData,
        onChangeOperation,
        onChangeSupervisorId,
        onAdd,
        onCopy,
        onDelete,
        debouncedFetchStylesOption,
        debouncedFetchSubStylesOption,
        setDailyLinePlanData,
      }}
    >
      {children}
    </DailyLinePlanContext.Provider>
  );
};

export default DailyLinePlanContextProvider;
