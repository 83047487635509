import { Col, Form, Input, message, Row, Select } from "antd";
import {
  EXPORT_SALES_CONTRACT_TRACKER,
  GET_SALES_CONTRACT_LIST,
} from "apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { isArrayAndHasValue } from "utils/functions";

const { Option } = Select;

const SalesContractForm = ({ form, mode }) => {
  const [options, setOptions] = useState({
    salesContract: [],
  });

  const { salesContractExpenseId } = useParams();
  const navigate = useNavigate();

  // form watch values
  const line_cost = Form.useWatch("line_cost", form);
  const bgmea_commission = Form.useWatch("bgmea_commission", form);
  const ud_fee = Form.useWatch("ud_fee", form);
  const amendments = Form.useWatch("amendments", form);

  const onFinish = async (values) => {
    try {
      const body = {
        sales_contract_id: values?.sales_contract_id,
        buyer_id: values?.buyer_id,
        order_qty: values?.order_qty,
        line_cost: values?.line_cost,
        bgmea_commission: values?.bgmea_commission,
        ud_fee: values?.ud_fee,
        amendments: values?.amendments,
      };

      console.log("Final body: ", body);
      // Call API to save data
      if (mode === "update" && salesContractExpenseId) {
        await putData(
          `${EXPORT_SALES_CONTRACT_TRACKER}/${salesContractExpenseId}`,
          body,
        );
      } else {
        await postData(EXPORT_SALES_CONTRACT_TRACKER, body);
      }
      navigate("/import-export-expense-tracking/sales-contract-expense");
      message.success("Data saved successfully");
    } catch (error) {
      console.error("Error saving data: ", error);
      message.error("Failed to save data");
    }
  };

  const getAllPermitList = useCallback(async (passedObject) => {
    const filter = passedObject;
    const customQuery = `${GET_SALES_CONTRACT_LIST}?page=${1}&per_page=${20}`;
    const res = await getData(customQuery, false, filter);

    if (res) {
      let masterData = res?.data;
      setOptions((prev) => ({
        ...prev,
        salesContract: masterData?.data?.data,
      }));
    }
  }, []);

  const onChangeSalesContract = async (value) => {
    if (!value) {
      return;
    }
    // /api/export-expense/sales-contract/55
    const customQuery = `/api/export-expense/sales-contract/${value}`;
    const res = await getData(customQuery, false, {});
    if (res) {
      let masterData = res?.data;
      console.log("masterData", masterData);

      form.setFieldsValue({
        buyer_id: masterData?.buyer?.id,
        buyer_name: masterData?.buyer?.name,
        order_qty: masterData?.sales_contract_commercial?.order_quantity,
        bgmea_commission: Number(
          (masterData?.sales_contract_commercial?.order_quantity * 0.37) / 12,
        ).toFixed(2),
      });
    }
  };

  const totalCalculation = () => {
    const luneCost = Number(line_cost).toFixed(2);
    const bgmeaCommission = Number(bgmea_commission).toFixed(2);
    const udFee = Number(ud_fee).toFixed(2);
    const amendmentsCost = Number(amendments).toFixed(2);

    const total = Number(
      Number(luneCost) +
        Number(bgmeaCommission) +
        Number(udFee) +
        Number(amendmentsCost),
    ).toFixed(2);
    form.setFieldsValue({
      total: total,
    });
  };

  useEffect(() => {
    if (salesContractExpenseId) {
      const customQuery = `${EXPORT_SALES_CONTRACT_TRACKER}/${salesContractExpenseId}/edit`;
      getData(customQuery, false, {}).then((res) => {
        if (res) {
          let masterData = res?.data?.data;
          onChangeSalesContract(masterData?.sales_contract_id);
          form.setFieldsValue({
            sales_contract_id: masterData?.sales_contract_id,
            line_cost: masterData?.line_cost,
            ud_fee: masterData?.ud_fee,
            amendments: masterData?.amendments,
            total: masterData?.total,
          });
        }
      });
    }
    getAllPermitList();
    form.setFieldsValue({
      line_cost: 500,
      ud_fee: 930,
      bgmea_commission: 0,
      amendments: 0,
      total: 0,
    });
  }, []);

  totalCalculation();

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "20px" }}
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item
            label="Sales Contract"
            name="sales_contract_id"
            rules={[
              { required: true, message: "Please select Sales Contract" },
            ]}
          >
            <Select
              disabled={mode === "view"}
              placeholder="Select Sales Contract"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={false}
              onSearch={(value) => getAllPermitList({ reference_no: value })}
              onChange={(value) => onChangeSalesContract(value)}
              onClear={() => {
                form.setFieldsValue({
                  sales_contract_id: "",
                  buyer_id: "",
                  buyer_name: "",
                  order_qty: 0,
                  bgmea_commission: 0,
                });
              }}
            >
              {isArrayAndHasValue(options.salesContract) &&
                options.salesContract.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.reference_no}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="buyer_id" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item label="Buyer" name="buyer_name">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Order Qty (pc)" name="order_qty">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Lien cost @500/Per application" name="line_cost">
            <Input disabled={mode === "view"} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="BGMEA Commission @.37/Dz" name="bgmea_commission">
            <Input disabled placeholder="Order Qty * 0.37 / 12" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="UD FEE @930" name="ud_fee">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Amendment @600" name="amendments">
            <Input disabled={mode === "view"} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Total" name="total">
            <Input style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SalesContractForm;
