import {
    CREATE_FILTER_DATA,
} from '../ActionTypes';

const initialState = {
    filterData: null,
};

const FilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_FILTER_DATA:
            return {
                ...state,
                filterData: action.payload,
            };
        default:
            return state;
    }
};

export default FilterReducer;
