import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Space, message, Table, Popconfirm, Typography } from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import {
  getData,
  postData,
  deleteData,
  putData,
} from "../../../apiServices/common";
import {
  GET_PACKING_LIST_REPORT,
  EXPORT_PACKING_LIST_REPORT,
  DELETE_PACKING_LIST_REPORT,
} from "../../../apiServices/API_ENDPOINTS";
import { hasPermission } from "../../../utils/functions";
import {
  INVENTORY_MRR_REPORT_VIEW_PERMISSION,
  GENERATE_MRR_REPORT_VIEW_PERMISSION,
  DELETE_MRR_REPORT_VIEW_PERMISSION,
} from "../../../routes/permissions";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const PackingReport = () => {
  // States
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  // Antd
  const { Title } = Typography;

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/packing_lists/")[1] : "";
    return updatedPath;
  };

  const getAllPacking = useCallback(async () => {
    setLoading(true);
    const query = `${GET_PACKING_LIST_REPORT}`;
    const response = await getData(query);

    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting Packing data");
    }
  }, []);

  const exportPacking = async (filterValues) => {
    setLoading(true);
    const query = `${EXPORT_PACKING_LIST_REPORT}`;
    const { po_map_id } = filterValues || {};
    const bodyData = {
      po_map_id: po_map_id,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data) {
      setLoading(false);
      message.success("Packing Report Exported Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  // Effects
  useEffect(() => {
    getAllPacking();
  }, [refresh, getAllPacking]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedPackingReport = async (name) => {
    setLoading(true);
    const updatedName = cutPath(name);
    const query = `${DELETE_PACKING_LIST_REPORT}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setLoading(false);
      message.success("Packing Report Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/packing_lists/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  const tableColumns = [
    {
      title: "File Name",
      key: "bin_name",
      width: "90%",
      render: (_, record) => {
        return <div>{cutPath(record)}</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Space>
            <Button
              size="small"
              onClick={() => openFile(cutPath(record))}
              disabled={!hasPermission([INVENTORY_MRR_REPORT_VIEW_PERMISSION])}
            >
              {hasPermission([INVENTORY_MRR_REPORT_VIEW_PERMISSION])
                ? "Download"
                : "No Permission"}
            </Button>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                deleteSelectedPackingReport(record);
              }}
            >
              <Button
                type="primary"
                danger
                size="small"
                disabled={!hasPermission([DELETE_MRR_REPORT_VIEW_PERMISSION])}
              >
                {hasPermission([DELETE_MRR_REPORT_VIEW_PERMISSION])
                  ? "Delete"
                  : "No Permission"}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={tableData}
        filterAPI={exportPacking}
        filterName={"packing_report"}
        searchButtonText={"Export"}
        defaultOpen
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportPacking}
                hideAddButton={true}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Packing Report</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              {memoizedCustomFilter}
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default PackingReport;
