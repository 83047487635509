import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Card,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { MoreOutlined, EditOutlined, DeleteTwoTone, EyeOutlined } from "@ant-design/icons";
import { IMPORT_B2B_LC_LIST } from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { formatNumberWithCommas } from "../../../utils/functions";
import { hasPermission } from "../../../utils/functions";
import {
  B2BLC_CREATE_PERMISSION,
  B2BLC_EDIT_PERMISSION,
  B2BLC_DELETE_PERMISSION,
  B2BLC_VIEW_PERMISSION,
} from "../../../routes/permissions";

export default function Forwarder(props) {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate(); 

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValues, setSearchValues] = useState();
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const searchFilter = (values) => {
    console.log("values", values);
  };

  const openForView = (id) => {
    navigate(`/import/view-b2blc/${id}`);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: ["payment_type"],
      key: ["payment_type"],
    },
    {
      title: "Rereference Number",
      dataIndex: ["b2b_lc_number"],
      key: ["b2b_lc_number"],
    },
    {
      title: "Date",
      dataIndex: ["b2b_lc_date"],
      key: ["b2b_lc_date"],
    },
    {
      title: "Value",
      dataIndex: ["total_lc_value"],
      key: ["total_lc_value"],
      render: (value) => formatNumberWithCommas(value),
    },
    {
      title: "Supplier Name",
      dataIndex: ["supplier_info", "name"],
      key: ["supplier_info", "name"],
    },
    {
      title: "Sales Contract",
      dataIndex: ["sale_contract_info", "reference_no"],
      key: ["sale_contract_info", "reference_no"],
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllPermitList = async (passedObject) => {
    let filter = passedObject;
    let res = await getData(IMPORT_B2B_LC_LIST, false, filter);

    if (res) {
      let masterData = res?.data;
      setPermitList(masterData?.data?.data);
      setTotalData(masterData?.data?.total);
      setRangeData(masterData?.data?.per_page);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const filterValues = JSON.parse(localStorage.getItem("import_b2blc_filter_value"));
    getAllPermitList({
      ...filterValues,
      page: currentPage
    });
  }, [currentPage, searchValues]);

  const removeDetails = async (value) => {
    let res = await deleteData(`/api/accounting/import-b2blc/delete/${value}`);
    window.location.reload();
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/import/edit-b2blc/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: 0,
          disabled: !hasPermission([B2BLC_EDIT_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeDetails(data.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: 1,
          disabled: !hasPermission([B2BLC_DELETE_PERMISSION]),
        },
        {
          label: (
            <>
              <span>
                <EyeOutlined />
              </span>

              <span
                type="button"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  openForView(data?.id);
                }}
              >
                View
              </span>
            </>
          ),

          key: 2,

          disabled: !hasPermission([B2BLC_VIEW_PERMISSION]),
        },
      ]}
    />
  );
  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const memoizedCustomFilter = useMemo(
    () => (
      <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={permitList}
            fetchTableData={getAllPermitList}
            filterName="import_b2blc"
          />
    ),
    [showFilter],
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Import B2B LC/RTGS/TT"
            subTitle=""
            actions={[
              <Button
                type={showFilter ? "danger" : "primary"}
                size="small"
                onClick={() => setShowFilter((prev) => !prev)}
                style={{ float: "right" }}
              >
                {`${showFilter ? "Close" : "Open"} Filter Option`}
              </Button>,
              <Button
                key="3"
                size="small"
                type="primary"
                disabled={!hasPermission([B2BLC_CREATE_PERMISSION])}
              >
                <Link to="/import/add-b2blc">
                  {hasPermission([B2BLC_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"}
                </Link>
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader searchFilter={searchFilter} />

          {memoizedCustomFilter}

          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}
