import {
    GET_BANK_LIST,
    CREATE_NEW_BANK,
    DELETE_BANK,
    GET_BANK_DETAIL, UPDATE_BANK_DETAIL
} from '../ActionTypes';

const initialState = {
    bankList: [],
    totalBank: null,
    selectedBank: null,
    selectedBankDetails: null,
    createdBank: null,
    selectedBankAccountInfo: null,
    pageSize: 10,
    page:1,
};

const BankReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_BANK_LIST:
            return {
                ...state,
                bankList: action.payload.data,
                totalBank: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_BANK:
            return {
                ...state,
                createdBank: action.payload.data,
                bankList: [action.payload.data, ...state.bankList],
                totalBank: state.totalBank + 1,
            };

        case DELETE_BANK: {
            return {
                ...state,
                bankList: action.payload.list,
                totalBank: action.payload.total,
            };
        }

        case GET_BANK_DETAIL:
            return {
                ...state,
                selectedBank: action.payload,
                selectedBankAccountInfo: action.payload,
                selectedBankDetails: action.payload,
            };

        case UPDATE_BANK_DETAIL:
            return {
                ...state,
                selectedBank: action.payload,
                bankList: state.bankList.map((bank) =>
                    bank.id === action.payload.id ? action.payload : bank,
                ),
            };

        default:
            return state;
    }
};

export default BankReducer;
