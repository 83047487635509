import {
    GET_SEWING_LINE_LIST,
    CREATE_NEW_SEWING_LINE,
    DELETE_SEWING_LINE,
    GET_SEWING_LINE_DETAIL,
    UPDATE_SEWING_LINE_DETAIL
} from '../ActionTypes';

const initialState = {
    sewingLineList: [],
    totalSewingLine: null,
    selectedSewingLine: null,
    pageSize: 10,
    page:1,
};

const SewingLineReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_SEWING_LINE_LIST:
            return {
                ...state,
                sewingLineList: action.payload.data,
                totalSewingLine: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_SEWING_LINE:
            return {
                ...state,
                sewingLineList: [action.payload.data, ...state.sewingLineList],
                totalSewingLine: state.totalSewingLine + 1,
            };

        case DELETE_SEWING_LINE: {
            return {
                ...state,
                sewingLineList: action.payload.list,
                totalSewingLine: action.payload.total,
            };
        }

        case GET_SEWING_LINE_DETAIL:
            return {
                ...state,
                selectedSewingLine: action.payload,
            };

        case UPDATE_SEWING_LINE_DETAIL:
            return {
                ...state,
                selectedSewingLine: action.payload,
                sewingLineList: state.sewingLineList.map((sewingLine) =>
                    sewingLine.id === action.payload.id ? action.payload : sewingLine,
                ),
            };

        default:
            return state;
    }
};

export default SewingLineReducer;
