import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GET_STYLE_DETAIL,
    GET_STYLE_LIST,
    SHOW_MESSAGE,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    GET_STYLE_LIST_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetStyleList = (page, per_page, style_no) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_STYLE_LIST_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(style_no && { style_no: style_no }),
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_STYLE_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onGetStyleDetails = (styleId) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`api/styles/${styleId}/edit`)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_STYLE_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Style details fetched",
                    });
                    // HandleNotification('success', 'bottomRight', 'Style Account updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    // HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onResetStyleDetails = () => {
    return (dispatch) => {
        dispatch({type: GET_STYLE_DETAIL, payload: null});
    };
};
