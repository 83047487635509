import { Alert, Card, Empty, Table, Typography } from "antd";
import { postData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";
import "./style.css";
export default function RecommendationAgainstDefects({
  line_id,
  currentSlide,
  slideId,
}) {
  const [dataSource, setDataSource] = useState();
  const initialGetData = useCallback(async () => {
    const response = await postData(
      "/api/digitalization/bigscreen-dashboard/recommendation-defects",
      {
        line_id: line_id,
      },
    );
    if (response && response?.code === 200) {
      const data = response?.data;
      setDataSource(data);
    }
  }, [line_id]);
  useEffect(() => {
    if (line_id && currentSlide === slideId) {
      initialGetData();
    }
  }, [line_id, currentSlide, slideId, initialGetData]);

  const columns = [
    {
      title: "Recommendation",
      dataIndex: "name",
      key: "name",
      className: "name",
    },
    {
      title: "No of Case",
      dataIndex: "value",
      key: "value",
      className: "value",
    },
  ];
  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {Array.isArray(dataSource?.chart_data) &&
      dataSource?.chart_data?.length > 0 ? (
        <div>
          <Typography.Title level={4}>
            Recommendation Against Defects
          </Typography.Title>
          <Table
            className="recommendation-against-defects"
            columns={columns}
            dataSource={dataSource?.chart_data}
            pagination={false}
            bordered
          />
        </div>
      ) : (
        <Empty
          style={{
            width: "33%",
            margin: "auto",
            marginTop: "10%",
            marginBottom: "10%",
            height: "100%",
          }}
          description={
            <Alert
              message="No Data Found with selected line-id in Recommendation Against Defects"
              type="warning"
            />
          }
        />
      )}
    </Card>
  );
}
