import { Table } from "antd";
import React, { useMemo, useState } from "react";
import CustomFilter from "../../Common/CustomFilter";

const ItemListTable = (props) => {
  const {
    total,
    loading,
    defaultCurrent,
    pageSize,
    current,
    onChange,
    showFilter,
    setShowFilter,
  } = props;

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const memoizedCustomFilter = useMemo(
    () => (
      <CustomFilter
        showFilter={showFilter}
        tableData={props.dataSource}
        fetchTableData={null}
        filterName="item_list"
      />
    ),
    [showFilter, props.dataSource],
  );

  return (
    <>
      {props.filter && memoizedCustomFilter}
      <div className="table" style={{ marginTop: 10 }}>
        <Table
          loading={loading}
          dataSource={props.dataSource}
          columns={props.tableColumns}
          pagination={{
            defaultPageSize: pageSize,
            current: current,
            onChange: onChange,
            defaultCurrent: defaultCurrent,
            total: total,
            showSizeChanger: false,
          }}
        />
      </div>
    </>
  );
};
export default ItemListTable;
