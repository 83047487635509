import {
    CREATE_NEW_BOM,
    DELETE_BOM,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GET_BOM_DETAIL,
    GET_BOM_LIST,
    SHOW_MESSAGE,
    UPDATE_BOM_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_BOM_ENDPOINT, CREATE_BRANCH_ENDPOINT,
    DELETE_BOM_ENDPOINT,
    EDIT_BOM_ENDPOINT,
    GET_BOM_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetBOMList = (page, per_page) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_BOM_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BOM_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onGetBOMDetails = (payload) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`/api/bill_of_material/view`, payload)

            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BOM_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "BOM Updated Successfully",
                    });
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};

export const onCreateBOM = (bom) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_BOM_ENDPOINT}`, bom)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_BOM, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "BOM Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'bom Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onUpdateSelectedBOM = (bom) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_BOM_ENDPOINT}/${bom.id}`, bom)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_BOM_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "bom Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'bom updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onDeleteBOM = (bom) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_BOM_ENDPOINT}/${bom['id']}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_BOM, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted bom Successfully",
                    });

                    HandleNotification('success', 'bottomRight', 'bom deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onResetBOM = () => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_BOM_DETAIL, payload: null});
    };
};


