import {MinusCircleTwoTone} from '@ant-design/icons';
import {Collapse, Form, Popconfirm, Table, Typography} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {onCreateBank, onGetBankAccountList, onGetBankDetails, onGetBankList} from "../../../redux/actions";
import BankAccountForm from "./BankAccountForm";
import {onBankAccountCreate} from "../../../apiServices/bank/service";
import HandleNotification from "../../../common/Notification";
import { isArrayAndHasValue } from '../../../utils/functions';

const {Panel} = Collapse;
let index = 0;


const CreateBankAccount = (props) => {
    const {selectedBuyingOffice, selectedOrg} = props;
    const dispatch = useDispatch();
    const createdBankAccount = useSelector(({bankAccount}) => bankAccount.createdBankAccount);
    const bankList = useSelector(({bank}) => bank.bankList);
    const bankDetails = useSelector(({bank}) => bank.selectedBankDetails);

    const orgBankRelationship = selectedOrg?.organization_bank_info?.map((org, idx) => {
        return org.account_info
    })

    const bankAccInfo = orgBankRelationship?.map((bank, idx) => {
        return {
            'account_number': bank?.account_number,
            'branch_id': bank?.branch_info?.id,
            'branch_name':  bank?.branch_info?.name,
            'bank_name': bank?.bank_info?.name,
            'bank_id': bank?.bank_info?.id,
            'account_id': bank?.id,
        }
    })


    const [refresh, setRefresh] = useState(1)
    const [selectedBank, setSelectedBank] = useState('');
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [name, setName] = useState('');
    const [swiftCode, setSwiftCode] = useState('');
    const inputRef = useRef(null);
    const [bankAccList, setBankAccountList] = useState([...bankList])
    const [form] = Form.useForm();
    const [branchName, setBranchName] = useState('');

    const [pageState, setPageState] = useState({
        data: [],
        createdData: {},
        isLoading: false
    });
    const [selectedBuyingOfficeBankInfo, setSelectedBuyingOfficeBankInfo] = useState([]);
    const [selectedBankInfo, setSelectedBankInfo] = useState(selectedOrg ? bankAccInfo : selectedBuyingOfficeBankInfo);
    const initialState = selectedBuyingOffice ? selectedBuyingOfficeBankInfo : selectedOrg? bankAccInfo : []
    const [createdBankAccountList, setCreatedBankAccountList] = useState([...initialState ] );

    useEffect(() => {
      if (selectedBuyingOffice) {
        const branchId = selectedBuyingOffice?.account_info?.[0]?.account_info?.branch_info?.id;
        const bankId = selectedBuyingOffice?.account_info?.[0]?.account_info?.bank_info?.id;
        const accountNumber = selectedBuyingOffice?.account_info?.[0]?.account_info?.account_number;
        const info = selectedBuyingOffice?.account_info?.map((bank, idx) => {
          return {
            account_number: bank?.account_info?.account_number,
            branch_name: bank?.account_info?.branch_info?.name,
            branch_id: bank?.account_info?.branch_info?.id,
            bank_name: bank?.account_info?.bank_info?.name,
            bank_id: bank?.account_info?.bank_info?.id,
            account_id: bank?.account_info?.id,
          };
        });
        setSelectedBuyingOfficeBankInfo(info);
        setSelectedBankInfo(info);
        form.setFieldsValue({
            branch_id: isArrayAndHasValue(selectedBuyingOffice?.account_info) && (branchId || ''),
            bank_id: isArrayAndHasValue(selectedBuyingOffice?.account_info) && (bankId || ''),
            account_number: isArrayAndHasValue(selectedBuyingOffice?.account_info) && (accountNumber || ''),
        });
        setCreatedBankAccountList(info);
      }
    }, [selectedBuyingOffice, form]);

    localStorage.setItem('newAccIds', JSON.stringify(pageState.data))

    const branchOfSelectedBank = bankDetails?.data?.brach_info;

    const selectedBranchIds = createdBankAccountList?.map((item) => item.branch_id)
    const selectedAccountIds = createdBankAccountList?.map((item) => item.account_id)

    localStorage.setItem('bankAccIds', JSON.stringify(selectedAccountIds));
    localStorage.setItem('branchIds', JSON.stringify(selectedBranchIds));

    const branches = selectedBuyingOffice?.data?.bank?.map((branch,idx) => {
        return branch?.brach_info
    })


    const selectedBranchesIds = branches?.map((item, idx) => {
        return item[idx].name;
    })

    const refetch = () => {
        setRefresh(refresh+1)
    }
    const onNameChange = (event, value) => {
        setName(event.target.value);
    };
    const getBankDetails = (bankId) => {
        dispatch(onGetBankDetails(bankId))
    }

    const onBankAccountChange = (value,options) => {
        getBankDetails(options.id);
        setSelectedBank(options);
        setBranchName('')
        setSwiftCode(options.swift_code);
    }


    const onBranchChange = (value,options) => {
        setSelectedBranch(options)
        setBranchName(options.label);
    }

    const handleDelete = (record, index) => {
        const items = createdBankAccountList.filter((item) => item !== record)
        // localStorage.setItem('bankAccIds', JSON.stringify(pageState.data.splice(index,1)))
        setCreatedBankAccountList(items);
    }

    const onSwiftCodeChange = (event, value) => {
        setSwiftCode(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        const formValues = {};
        formValues['name']= name;
        formValues['swift_code']= swiftCode;
        formValues['status'] = '1';
        dispatch(onCreateBank(formValues));
        setBankAccountList([...bankAccList,{
            "id": createdBankAccount?.id,
            "name": name,
            "swift_code": swiftCode,

            "created_at": null,
            "updated_at": null
        } || `New item ${index++}`]);
        refetch();
        setName('');

        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    useEffect(() => {
        // dispatch(onGetBankAccountList(1, 10));
        dispatch(onGetBankList(1, 10));

        form.resetFields();
    }, [refresh, selectedBuyingOffice, createdBankAccount, createdBankAccountList.length, pageState.data]);

    const tableColumns = [
        {title:"Bank Name", dataIndex:"bank_name", key:"bank_name",
            render: (_,record,index) => {
                return  record?.account_info?.[index]?.bank_info.name || record?.bank_name
            }
        },
        {title:"Branch Name", dataIndex:"branch_name", key:"branch_name", render: (_,record,index) => {
                return  record?.account_info?.[index]?.branch_info.name || record?.branch_name
            }},

        {title:"Account Number", dataIndex:'account_number', key:'account_info', render: (_,record,index) => {
                return  record?.account_info?.[index]?.account_number || record?.account_number }},
        // {title:"Account ID", dataIndex:'account_id', key:'account_id', render: (_,record,index) => {
        //         return  record?.account_info?.[index]?.account_number || record?.account_id }},
        {
            title: 'Action',
            key: 'action',
            render: (_, record, index) => (
                <>
                    <Popconfirm title="Are you sure you want to delete?"
                                                         onConfirm={() => {
                                                             handleDelete(record, index)
                                                         }}>

                        <a style={{color: 'red', marginLeft:5}} type='button'>
                            <span>
                               <MinusCircleTwoTone />
                            </span>
                        </a>
                    </Popconfirm>

                </>
            ),
        }
    ]


    return (
        <>
            {/*<Typography.Title style={{fontSize: 18}}>Add Bank Account</Typography.Title>*/}
            <Collapse defaultActiveKey={['1']} style={{marginTop: 15, marginBottom: 25}}>
                <Panel header="Add Bank Account" key="1" style={{fontWeight: 700}}>
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                // initialValues={{
                //     branch_id: selectedBankInfo?.length === 1 && selectedBankInfo?.[0]?.branch_name || '',
                //     bank_id: selectedBankInfo?.length === 1 && selectedBankInfo?.[0]?.bank_name || '',
                //     account_number: selectedBankInfo?.length === 1 && selectedBankInfo?.[0]?.account_number || '',
                // }}
                onFinish={(payload) => {
                    payload['status'] = '1'
                    onBankAccountCreate({setPageState, pageState, payload}).then((response) => {
                        if (response.status === 200){
                            if (response.data.code === 200) {
                                const createdBank = {
                                    'branch_name': selectedBranch.label,
                                    'bank_name': selectedBank.name,
                                    'bank_id' : selectedBranch?.bank_id,
                                    'branch_id' : selectedBranch?.id,
                                    'account_id': response.data.data.id,
                                    'account_number' : payload.account_number,
                                }
                                setCreatedBankAccountList((prev) => [...prev, createdBank])

                                setPageState(old => ({
                                    ...old,
                                    isLoading: false,
                                    data: [...pageState.data, response.data.data.id],
                                    createdData:  response.data.data
                                }))
                                HandleNotification('success', 'bottomRight', 'Bank Account Created Successfully!', null)
                            }
                            else {
                                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                            }

                        }else{
                            HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                        }
                        })
                        .catch((error) => {
                            HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                        });

                    form.resetFields();
                }}
                style={{marginBottom: 5, marginTop: 5}}

            >
                <BankAccountForm
                    selectedBankInfo={selectedBankInfo}
                    onBankAccountChange={onBankAccountChange}
                    swiftCode={swiftCode}
                    onNameChange={onNameChange}
                    addItem={addItem}
                    branchOfSelectedBank={branchOfSelectedBank}
                    selectedBuyingOfcBranches={selectedBranchesIds}
                    onBranchChange={onBranchChange}
                    branchName={branchName}
                    selectedBuyingOfficeBankInfo={selectedBuyingOfficeBankInfo}
                    selectedBank={selectedBank}
                    selectedBranch={selectedBranch}
                    bankList={bankList}
                    name={name}
                    refetch={refetch}
                    onSwiftCodeChange={onSwiftCodeChange}
                    inputRef={inputRef} 
                />

                <Table columns={tableColumns}
                       pagination={false}
                       loading={pageState.isLoading}
                       dataSource={createdBankAccountList}
                />
            </Form>
                </Panel>
            </Collapse>
        </>

    );
};
export default CreateBankAccount;