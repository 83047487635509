import { Button, Carousel } from "antd";
import LoadingComponent from "common/LoadingComponent";
import { Suspense, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CategoryWiseDefect from "./CategoryWiseDefect";
import CategoryWiseHourlyDefect from "./CategoryWiseHourlyDefect";
import DefectTracker from "./DefectTracker";
import OperatorWiseDefect from "./OperatorWiseDefect";
import OperatorWisePerformance from "./OperatorWisePerformance/OperatorWisePerformance";
import AppProductDetails from "./ProductDetails/AppProductDetails";
import ProductionSummary from "./ProductionSummary";
import ProductionTracker from "./ProductionTracker";
import RecommendationAgainstDefects from "./RecommendationAgainstDefects";
import ResponsibleForDefects from "./ResponsibleForDefects";
import "./style.css";
const AppDashboard = () => {
  const [currentSlide, setCurrentSlide] = useState(10);
  const indexRef = useRef(-1);
  const { line_id } = useParams();
  const lineIds = line_id.split(",");
  const navigate = useNavigate();

  const setIndex = (value) => {
    indexRef.current = value;
  };

  if (currentSlide === 10) {
    if (indexRef.current <= lineIds.length - 1) {
      setIndex(indexRef.current + 1);
    }
    if (indexRef.current > lineIds.length - 1) {
      setIndex(0);
    }
  }

  const id = lineIds[indexRef.current];

  return (
    <div
      style={{
        padding: "20px",
        minHeight: "100%",
      }}
    >
      <div style={{ textAlign: "right" }}>
        <Button
          type="primary"
          style={{
            marginBottom: "20px",
          }}
          onClick={() => {
            navigate("/digitalization/app-dashboard");
          }}
        >
          Back
        </Button>
      </div>

      <Carousel
        dotPosition="top"
        dots
        autoplay
        infinite
        arrows
        autoplaySpeed={10000}
        beforeChange={(current, nextSlide) => {
          setCurrentSlide(current + 1);
        }}
        className="app-dashboard-carousel"
      >
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <AppProductDetails
              line_id={id}
              slideId={10}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <ProductionSummary
              line_id={id}
              slideId={1}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <OperatorWisePerformance
              line_id={id}
              slideId={2}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <ResponsibleForDefects
              line_id={id}
              slideId={3}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <OperatorWiseDefect
              line_id={id}
              slideId={4}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <ProductionTracker
              line_id={id}
              slideId={5}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <DefectTracker
              line_id={id}
              slideId={6}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <CategoryWiseDefect
              line_id={id}
              slideId={7}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <CategoryWiseHourlyDefect
              line_id={id}
              slideId={8}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
        <div>
          <Suspense fallback={<LoadingComponent />}>
            <RecommendationAgainstDefects
              line_id={id}
              slideId={9}
              currentSlide={currentSlide}
            />
          </Suspense>
        </div>
      </Carousel>
    </div>
  );
};

export default AppDashboard;
