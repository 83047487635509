import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import CustomDatePicker from "common/CustomDatePicker";
import { isArrayAndHasValue } from "utils/functions";

const BoltSealLogForm = (props) => {
  // Props
  const {
    vehicleNoList,
    onChangeVehicleNoList,
    driverList,
    onSelectDriverName,
  } = props;

  // Antd
  const { Option } = Select;

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Vehicle No"
            name="vehicle_info_id"
            rules={[
              {
                required: true,
                message: `Vehicle No is required `,
              },
            ]}
          >
            <Select
              onSelect={(value) => onChangeVehicleNoList(value)}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
            >
              {isArrayAndHasValue(vehicleNoList) &&
                vehicleNoList?.map((vehicleNo, idx) => (
                  <Option key={vehicleNo?.id} value={vehicleNo?.id}>
                    {vehicleNo?.vehicle_no}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Vehicle Size" name="vehicle_size">
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Vehicle Type" name="vehicle_type">
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Driver Name"
            name="driver_info_id"
            rules={[
              {
                required: true,
                message: `Driver Name is required `,
              },
            ]}
          >
            <Select
              onSelect={(value) => onSelectDriverName(value)}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
            >
              {isArrayAndHasValue(driverList) &&
                driverList?.map((item, index) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.driver_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Driver Cell No" name="driver_cell_no">
            <Input size="small" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Driving License No" name="driving_license_no">
            <Input size="small" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="NID" name="nid">
            <Input size="small" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Arrival Date & Time" name="arrival_date_time">
            <CustomDatePicker
              size="small"
              format="DD-MMM-YYYY"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Dispatch Date & Time" name="dispatch_date_time">
            <CustomDatePicker
              size="small"
              format="DD-MMM-YYYY"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Bolt Seal No"
            name="bolt_seal_no"
            rules={[
              {
                required: true,
                message: `Bolt Seal No is required `,
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Bolt Seal Date"
            name="bolt_seal_date"
            rules={[
              {
                required: true,
                message: `Bolt Seal Date is required `,
              },
            ]}
          >
            <CustomDatePicker
              size="small"
              format="DD-MMM-YYYY"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Available CBM" name="available_cbm">
            <Input disabled size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Challan No"
            name="challan_no"
            rules={[
              {
                required: true,
                message: `Challan No is required `,
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Place of Delivery"
            name="delivery_place"
            rules={[
              {
                required: true,
                message: `Place of Delivery is required `,
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="GPRS Tracking No"
            name="gprs_track_no"
            // rules={[
            //   {
            //     required: true,
            //     message: `GPRS Tracking No is required `,
            //   },
            // ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Note"
            name="note"
            // rules={[
            //   {
            //     required: true,
            //     message: `Note is required `,
            //   },
            // ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Gate Pass" name="gate_pass">
            <Input size="small" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BoltSealLogForm;
