import { Button, Typography } from "antd";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { getData } from "../../../../apiServices/common";
import AppContent from "../../../../common/AppContent";
import HandleNotification from "../../../../common/Notification";
import AppPageHeader from "../../../../common/PageHeader";
import ExtraContent from "../../../Configuration/CommonComponents/ExtraContent";
import GarmentsTrackingTable from "./GarmentsTrackingTable";
import { cglFormatDate } from "utils/functions";

const GarmentsTrackingList = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [userList, setUserList] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getGarmentsTracking = useCallback(
    async (filterValues) => {
      const query = `/api/digitalization/garments-tracking`;
      const bodyData = {
        page: page,
        per_page: filterValues?.per_page || 10,
        ...(filterValues?.date && { date: filterValues?.date }),
        ...(filterValues?.line_id && { line_id: filterValues?.line_id }),
        ...(filterValues?.buyer_id && { buyer_id: filterValues?.buyer_id }),
        ...(filterValues?.style_id && { style_id: filterValues?.style_id }),
      };

      const response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        setUserList(response?.data?.data?.data);
        setPageSize(response?.data?.data?.per_page);
        setTotal(response?.data?.data?.total);
      } else {
        HandleNotification("error", "Error", "Something went wrong");
      }
    },
    [page],
  );

  useEffect(() => {
    getGarmentsTracking({ page: page });
  }, [refresh, page, pageSize]);

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handleEdit = (event, record) => {
    const params = {
      date: record.date,
      line_id: record.line_info?.id,
      buyer_id: record.buyer_info?.id,
      style_id: record.style_info?.id,
      sub_style_id: record.sub_style_info?.id,
      po_map_id: record.po_map_info?.id,
      color_id: record.color_info?.id,
      size_id: record.size_info?.id,
    }

    const searchParams = new URLSearchParams(params).toString();

    navigate(`/digitalization/update-garments-tracking/${params.line_id}?${searchParams}`, );
  };

  const tableColumns = [
    { title: "Date", dataIndex: "date", key: "date", width: "20%",render: (row) => cglFormatDate(row) },
    { title: "Line Name", dataIndex: ["line_info", "name"], key: "name" },
    {
      title: "Buyer Name",
      dataIndex: ["buyer_info", "name"],
      key: "buyer_info_name",
    },
    {
      title: "Style Name",
      dataIndex: ["style_info", "name"],
      key: "style_info_name",
    },
    {
      title: "Good Garments Qty",
      dataIndex: "total_good_garments_qty",
      key: "total_good_garments_qty",
      align: "center"
    },
    {
      title: "Defect Garments Qty",
      dataIndex: "total_defect_garments_qty",
      key: "total_defect_garments_qty",
      align: "center"
    },
    {
      title: "Reject Garments Gty",
      dataIndex: "total_reject_garments_qty",
      key: "total_reject_garments_qty",
      align: "center"
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <div>
          <Button
            style={{ marginLeft: 5 }}
            size="small"
            onClick={(event) => {
              handleEdit(event, record);
            }}
            // disabled={!hasPermission([USER_EDIT_PERMISSION])}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const header = (
    <AppPageHeader
      extra={
        <ExtraContent
          refresh={refresh}
          setRefresh={setRefresh}
          handleAddItemOpen={getGarmentsTracking}
          hideAddButton={true}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
        />
      }
      title={<Title level={5}>Garments Tracking</Title>}
    />
  );
  const content = (
    <>
      <FilterComponent
        tableData={userList}
        filterAPI={getGarmentsTracking}
        filterName={"digitalization-garments-tracking"}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <GarmentsTrackingTable
        onChange={(newPage) => {
          onPageChange(newPage);
        }}
        total={total}
        current={page}
        defaultCurrent={page}
        pageSize={pageSize}
        dataSource={userList}
        tableColumns={tableColumns}
        pagination={true}
      />
    </>
  );
  return (
    <>
      <AppContent header={header} content={content} />
    </>
  );
};

export default GarmentsTrackingList;
