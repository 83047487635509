import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Select,
  Modal,
  Button,
  Space,
  Divider,
  Input,
  DatePicker,
  InputNumber,
} from "antd";
import {
  INQUIRY_REASONS_LIST_ENDPOINT,
  INQUIRY_REMARKS_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import InquiryReasonsForm from "../../Configuration/InquiryReasonsConfig/InquiryReasonsForm";
import InquiryRemarksForm from "../../Configuration/InquiryRemarksConfig/InquiryRemarksForm";

const confirmStatus = [
  {
    id: 1,
    name: "Yes",
    value: "Yes",
  },
  {
    id: 2,
    name: "No",
    value: "No",
  },
  {
    id: 3,
    name: "Waiting For Feedback",
    value: "Waiting For Feedback",
  },
];

const OrderConfirmationForm = (props) => {
  // Props
  const { form, viewMode } = props;

  // States
  const [reasons, setReasons] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModalType, setSelectedModalType] = useState(null);

  // Antd
  const { Option } = Select;
  const order_status_watch = Form.useWatch('order_status', form);

  const getAllReasons = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${INQUIRY_REASONS_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setReasons(response?.data?.data?.data);
    }
  };

  const getAllRemarks = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${INQUIRY_REMARKS_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setRemarks(response?.data?.data?.data);
    }
  };

  useEffect(() => {
    getAllReasons();
    getAllRemarks();
  }, []);

  const onModalOpen = (type) => {
    setSelectedModalType(type);
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setSelectedModalType(null);
    setIsModalOpen(false);
  };

  return (
    <Card title={<span style={{ fontSize: "14px" }}>Order Confirmation</span>}>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item label="Order Confirmed" name="order_status">
            <Select
              showSearch
              placeholder="Select a status"
              optionFilterProp="children"
              size="small"
              style={{ width: 200 }}
              disabled={viewMode}
              
            >
              {isArrayAndHasValue(confirmStatus) &&
                confirmStatus?.map((item, index) => (
                  <Option value={item?.value} key={item?.id || index}>
                    {item?.name || ""}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {order_status_watch === "No" && (
        <Row gutter={6}>
        <div>
          <Form.List name="order_confirmations" initialValue={[
              { inquiry_reason_id: null, inquiry_remark_id: null, is_delete: 0, id: 0 },
            ]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return(
                    <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 5,
                    }}
                    align="baseline"
                  >
                    <Form.Item name={[name, "id"]} hidden />
                    <Form.Item name={[name, "is_delete"]} hidden />
                    <Form.Item
                      {...restField}
                      name={[name, "inquiry_reason_id"]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a reason"
                        optionFilterProp="children"
                        size="small"
                        onSearch={(value) => getAllReasons({ name: value })}
                        allowClear
                        style={{ width: 200 }}
                      >
                        {isArrayAndHasValue(reasons) &&
                          reasons?.map((item, index) => (
                            <Option value={item?.id} key={item?.id || index}>
                              {item?.name || ""}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Button
                      icon={<PlusOutlined />}
                      size="small"
                      onClick={() => onModalOpen("reasons")}
                    />
                    <Form.Item
                      {...restField}
                      name={[name, "inquiry_remark_id"]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a remark"
                        optionFilterProp="children"
                        size="small"
                        onSearch={(value) => getAllRemarks({ name: value })}
                        allowClear
                        style={{ width: 200 }}
                      >
                        {isArrayAndHasValue(remarks) &&
                          remarks?.map((item, index) => (
                            <Option value={item?.id} key={item?.id || index}>
                              {item?.name || ""}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Button
                      icon={<PlusOutlined />}
                      size="small"
                      onClick={() => onModalOpen("remarks")}
                    />
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    size="small"
                    style={{width: 200}}
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </Row>

      )}
      
      <Row gutter={6}>
        <Modal
          open={isModalOpen && selectedModalType}
          title={selectedModalType === "reasons" ? "Add Reason" : "Add Remark"}
          onCancel={() => {
            onCloseModal();
          }}
          maskStyle={{ backdropFilter: "blur(4px)" }}
          footer={null}
        >
          {selectedModalType === "reasons" ? (
            <InquiryReasonsForm
              isEdit={false}
              onCloseModal={onCloseModal}
              refetch={getAllReasons}
            />
          ) : (
            <InquiryRemarksForm
              isEdit={false}
              onCloseModal={onCloseModal}
              refetch={getAllRemarks}
            />
          )}
        </Modal>
      </Row>
    </Card>
  );
};

export default OrderConfirmationForm;
