import { Col, Form, Input, InputNumber, message, Row, Select } from "antd";
import {
  GET_SALES_CONTRACT_LIST,
  IMPORT_EXPENSE_SALES_CONTRACT,
  IMPORT_INVOICE_EXPENSE_LIST,
} from "apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "apiServices/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { isArrayAndHasValue } from "utils/functions";

const { Option } = Select;

const ImportInvoiceForm = ({ form, mode = "create" }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    salesContract: [],
    invoiceNumber: [],
    buyerInfo: [],
    supplierInfo: [],
  });
  const [editData, setEditData] = useState(null);
  const [invoicesLoaded, setInvoicesLoaded] = useState(false);
  // Add state to prevent multiple API calls
  const [apiCallsInProgress, setApiCallsInProgress] = useState({
    salesContract: false,
    editData: false,
  });

  // Calculate acceptance commission based on import value
  const calculateAcceptanceCommission = useCallback((importValue) => {
    const commission = importValue * 0.001; // 0.1%
    return Math.max(commission, 1000); // Minimum 1000
  }, []);

  // Function to calculate total from all relevant fields
  const calculateTotal = useCallback(() => {
    const values = form.getFieldsValue();
    const total =
      (values.import_value || 0) +
      (values.sg_insurance_charges || 0) +
      (values.paid_transport_bill || 0) +
      (values.air_cndf || 0) +
      (values.potential_oppotnity_saving_cndf || 0) +
      (values.paid_cndf_bill || 0) +
      (values.acceptance_commission || 0) +
      (values.edf_interest || 0);

    form.setFieldsValue({ total });
    return total;
  }, [form]);

  const handleInvoiceChange = useCallback(
    (invoiceId) => {
      if (!invoiceId) return;

      const selectedInvoice = options.invoiceNumber.find(
        (invoice) => invoice.id === invoiceId,
      );

      if (selectedInvoice) {
        const importValue =
          selectedInvoice.total_lc_value || selectedInvoice.invoice_value;
        const acceptanceCommission = calculateAcceptanceCommission(importValue);

        form.setFieldsValue({
          supplier_id: selectedInvoice.supplier_id,
          import_value: importValue,
          acceptance_commission: acceptanceCommission,
        });

        // Recalculate total
        setTimeout(calculateTotal, 0);
      }
    },
    [
      options.invoiceNumber,
      form,
      calculateAcceptanceCommission,
      calculateTotal,
    ],
  );

  // Handle sales contract change and fetch related data
  const handleSalesContractChange = useCallback(
    async (contractId) => {
      if (!contractId || apiCallsInProgress.salesContract) return;

      try {
        setApiCallsInProgress((prev) => ({ ...prev, salesContract: true }));

        // Reset relevant fields when sales contract changes
        form.setFieldsValue({
          buyer_id: undefined,
          supplier_id: undefined,
          invoice_no: undefined,
          import_value: 0,
          acceptance_commission: 0,
          total: 0,
        });

        const customQuery = `${IMPORT_EXPENSE_SALES_CONTRACT}/${contractId}`;
        const res = await getData(customQuery, false, null);

        if (res?.data) {
          const contractData = res.data;

          // Extract suppliers
          const allSuppliers =
            contractData?.b2b_lc_info_data?.map((item) => item.supplier_info) ||
            [];

          // Extract and flatten invoices from all suppliers
          const allInvoices =
            contractData?.b2b_lc_info_data?.flatMap((item) => {
              return (item.invoice_list || []).map((invoice) => ({
                ...invoice,
                supplier_id_ref: item.supplier_id,
                total_lc_value: item.total_lc_value,
              }));
            }) || [];

          // Update options
          setOptions((prev) => ({
            ...prev,
            buyerInfo: contractData?.buyer ? [contractData.buyer] : [],
            supplierInfo: allSuppliers,
            invoiceNumber: allInvoices,
          }));

          setInvoicesLoaded(true);

          // Always set buyer when sales contract changes
          if (contractData?.buyer) {
            form.setFieldsValue({
              buyer_id: contractData.buyer.id,
            });
          }

          // In edit mode with edited data, select the correct invoice only if we're loading initial data
          if (
            (mode === "edit" || mode === "view") &&
            editData &&
            !form.getFieldValue("invoice_no")
          ) {
            setTimeout(() => {
              // Find the invoice that matches our editData invoice_no
              const matchedInvoice = allInvoices.find(
                (inv) => inv.id.toString() === editData.invoice_no.toString(),
              );

              if (matchedInvoice) {
                console.log("Found matching invoice:", matchedInvoice);
                form.setFieldsValue({ invoice_no: matchedInvoice.id });
                handleInvoiceChange(matchedInvoice.id);
              } else {
                console.log(
                  "No matching invoice found for ID:",
                  editData.invoice_no,
                );
              }
            }, 100);
          }

          // Recalculate total after setting values
          setTimeout(calculateTotal, 150);
        }
      } catch (error) {
        console.error("Error fetching sales contract data:", error);
      } finally {
        setApiCallsInProgress((prev) => ({ ...prev, salesContract: false }));
      }
    },
    [
      form,
      mode,
      editData,
      handleInvoiceChange,
      apiCallsInProgress.salesContract,
      calculateTotal,
    ],
  );

  // Fetch sales contracts - only called once
  const fetchSalesContracts = useCallback(async (filterObject = {}) => {
    const customQuery = `${GET_SALES_CONTRACT_LIST}?page=1&per_page=20`;
    const res = await getData(customQuery, false, filterObject);

    if (res?.data?.data?.data) {
      setOptions((prev) => ({
        ...prev,
        salesContract: res.data.data.data,
      }));
    }
  }, []);

  // Handle invoice selection

  // Handle import value changes
  const handleImportValueChange = useCallback(
    (value) => {
      if (value) {
        form.setFieldsValue({
          acceptance_commission: calculateAcceptanceCommission(value),
        });
        calculateTotal();
      }
    },
    [form, calculateAcceptanceCommission, calculateTotal],
  );

  // Initialize form for edit mode
  const fetchEditData = useCallback(async () => {
    if (
      !id ||
      !(mode === "edit" || mode === "view") ||
      apiCallsInProgress.editData
    )
      return;

    try {
      setApiCallsInProgress((prev) => ({ ...prev, editData: true }));

      const customQuery = `${IMPORT_INVOICE_EXPENSE_LIST}/${id}/edit`;
      const res = await getData(customQuery, false, {});

      if (res?.data?.data) {
        const masterData = res.data.data;
        console.log("Edit data loaded:", masterData);

        // Store edit data for later use
        setEditData(masterData);

        // Fetch sales contract data to get related information
        const salesContractId = masterData.sales_contract_id;

        // Set form values immediately except for invoice_no which depends on loaded invoices
        form.setFieldsValue({
          sales_contract_id: salesContractId,
          buyer_id: masterData.buyer_id,
          supplier_id: masterData.supplier_id,
          import_value: masterData.import_value || 0,
          sg_insurance_charges: masterData.sg_insurance_charges || 0,
          paid_transport_bill: masterData.paid_transport_bill || 0,
          air_cndf: masterData.air_cndf || 0,
          potential_oppotnity_saving_cndf:
            masterData.potential_oppotnity_saving_cndf || 0,
          paid_cndf_bill: masterData.paid_cndf_bill || 0,
          edf_interest: masterData.edf_interest || 0,
          acceptance_commission: calculateAcceptanceCommission(
            masterData.import_value || 0,
          ),
        });

        // Now fetch the sales contract data which will trigger invoice selection in handleSalesContractChange
        await handleSalesContractChange(salesContractId);

        // Calculate total
        setTimeout(calculateTotal, 150);
      }
    } catch (error) {
      console.error("Error fetching edit data:", error);
    } finally {
      setApiCallsInProgress((prev) => ({ ...prev, editData: false }));
    }
  }, [
    id,
    mode,
    form,
    handleSalesContractChange,
    calculateAcceptanceCommission,
    calculateTotal,
    apiCallsInProgress.editData,
  ]);

  // Initialize form for create mode
  const initializeCreateForm = useCallback(() => {
    if (id || mode === "edit") return;

    // Set default values for numeric fields
    const defaultValues = {
      import_value: 0,
      sg_insurance_charges: 0,
      paid_transport_bill: 0,
      air_cndf: 0,
      potential_oppotnity_saving_cndf: 0,
      paid_cndf_bill: 0,
      acceptance_commission: 0,
      edf_interest: 0,
      total: 0,
    };

    form.setFieldsValue(defaultValues);
    calculateTotal();
  }, [id, mode, form, calculateTotal]);

  // Use effect to monitor when invoices are loaded and editData exists
  useEffect(() => {
    if (invoicesLoaded && editData && (mode === "edit" || mode === "view")) {
      // Try to find and select the correct invoice
      const matchedInvoice = options.invoiceNumber.find(
        (inv) => inv.id.toString() === editData.invoice_no.toString(),
      );

      if (matchedInvoice) {
        console.log("Setting invoice from loaded data:", matchedInvoice);
        form.setFieldsValue({ invoice_no: matchedInvoice.id });
        handleInvoiceChange(matchedInvoice.id);
      }
    }
  }, [
    invoicesLoaded,
    editData,
    options.invoiceNumber,
    form,
    handleInvoiceChange,
    mode,
  ]);

  // Initialize component
  useEffect(() => {
    fetchSalesContracts();

    if ((mode === "edit" || mode === "view") && id) {
      fetchEditData();
    } else {
      initializeCreateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this runs once on mount

  // Remove the dependency on options.invoiceNumber as it can cause extra renders
  useEffect(() => {
    if (invoicesLoaded && editData && (mode === "edit" || mode === "view")) {
      // Try to find and select the correct invoice only if we haven't already done so
      const currentInvoice = form.getFieldValue("invoice_no");
      if (!currentInvoice) {
        const matchedInvoice = options.invoiceNumber.find(
          (inv) => inv.id.toString() === editData.invoice_no.toString(),
        );

        if (matchedInvoice) {
          console.log("Setting invoice from loaded data:", matchedInvoice);
          form.setFieldsValue({ invoice_no: matchedInvoice.id });
          handleInvoiceChange(matchedInvoice.id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesLoaded, editData]); 


  const onFinish = async (values) => {
    try {
      if (!id) {
        await postData(IMPORT_INVOICE_EXPENSE_LIST, values);
      } else if (mode === "edit" && id) {
        await putData(`${IMPORT_INVOICE_EXPENSE_LIST}/${id}`, values);
      }
      navigate("/import-export-expense-tracking/import-invoice-expense");
      message.success("Data saved successfully");
    } catch (error) {}

    console.log("Form values: ", values);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "20px" }}
      onFinish={onFinish}
      disabled={mode === "view"}
      onValuesChange={calculateTotal}
    >
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item
            label="Sales Contract"
            name="sales_contract_id"
            rules={[
              { required: true, message: "Please select Sales Contract" },
            ]}
          >
            <Select
              placeholder="Select Sales Contract"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSearch={(value) => fetchSalesContracts({ reference_no: value })}
              onChange={handleSalesContractChange}
              disabled={mode === "view"}
            >
              {isArrayAndHasValue(options.salesContract) &&
                options.salesContract.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.reference_no}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Buyer" name="buyer_id">
            <Select
              placeholder="Select Buyer"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={false}
              disabled
              defaultActiveFirstOption={true}
            >
              {isArrayAndHasValue(options.buyerInfo) &&
                options.buyerInfo.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Invoice No"
            name="invoice_no"
            rules={[{ required: true, message: "Please select Invoice No" }]}
          >
            <Select
              placeholder="Select Invoice No"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleInvoiceChange}
              disabled={mode === "view"}
            >
              {isArrayAndHasValue(options.invoiceNumber) &&
                options.invoiceNumber.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.invoice_number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Supplier" name="supplier_id">
            <Select
              placeholder="Select Supplier"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={false}
              disabled
            >
              {isArrayAndHasValue(options.supplierInfo) &&
                options.supplierInfo.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item label="Import Value" name="import_value">
            <InputNumber
              style={{ width: "100%" }}
              disabled
              onChange={handleImportValueChange}
              defaultValue={0}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="SG Issuance Charge(as per agreement) [BDT]"
            name="sg_insurance_charges"
          >
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={0}
              onChange={calculateTotal}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="PAID TRASNPORT BILL" name="paid_transport_bill">
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={0}
              onChange={calculateTotal}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="C&F Others (Air)" name="air_cndf">
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={0}
              onChange={calculateTotal}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Potential Opportunity for Savings in C&F (NRA)"
            name="potential_oppotnity_saving_cndf"
          >
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={0}
              onChange={calculateTotal}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="PAID C&F BILL (RA)" name="paid_cndf_bill">
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={0}
              onChange={calculateTotal}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="ACCEPTANCE COMISSION @.1%"
            name="acceptance_commission"
            tooltip="0.1% of Import Value (minimum 1000)"
          >
            <InputNumber style={{ width: "100%" }} disabled defaultValue={0} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="EDF Interest" name="edf_interest">
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={0}
              onChange={calculateTotal}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Total" name="total">
            <InputNumber style={{ width: "100%" }} disabled defaultValue={0} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ImportInvoiceForm;
