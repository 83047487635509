import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Button, Upload, Space, message} from "antd";
import ItemList from "./ListItem";
import CreateItem from "./CreateItem";
import { UploadOutlined } from "@ant-design/icons";
import {onGetItemCategoryList} from "../../redux/actions";
import { hasPermission } from "../../utils/functions";
import { ITEM_CREATE_PERMISSION } from '../../routes/permissions';
import { useLocation } from "react-router";

const Item = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddItem, onSetAddItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  // Others
  const dispatch = useDispatch();
  const api_base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  const itemList = useSelector(({ item }) => item.itemList);
  
  
  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const handleAddItemOpen = () => {
    onSetAddItem(true);
  };

  const handleAddItemClose = () => {
    onSetAddItem(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const uploadProps = {
    accept: ".xlsx",
    headers: {
      authorization: `Bearer ${token}`,
    },
    action: api_base_url + `/api/items/import`,
    onChange(action) {
        if (action.file.status !== "uploading") {
          console.log("uploading file");
        }
        if (action.file.status === "done" && action.file.response.code === 200) {
          message.success(
            `${action.file.response.message[0]}`
          );
        } else if (
          action.file.status === "done" &&
          action?.file?.response.code !== 200
        ) {
          message.error(`Items Cannot Be Imported. Category or Attributes doesnot Exist`);
        } else if (action.file.status === "error") {
          message.error(`file upload failed.`);
        }
        setRefresh(refresh)
      },
  };
  

  useEffect(() => {
    dispatch(onGetItemCategoryList({
      page: currentPage,
      per_page: 1000,
    }));
  }, [refresh, currentPage, dispatch]);

  const categoryList = useSelector(
    ({ itemCategory }) => itemCategory.itemCategoryList
  );

  const Extra = () => {
    return (
      <Space>
        <Button
          type={showFilter ? "danger" : "primary"}
          size="small"
          onClick={() => setShowFilter((prev) => !prev)}
          style={{ float: "right" }}
        >
          {`${showFilter ? "Close" : "Open"} Filter Option`}
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={handleAddItemOpen}
          disabled={!hasPermission([ITEM_CREATE_PERMISSION])}
        >
          {hasPermission([ITEM_CREATE_PERMISSION])
            ? "Add New"
            : "No Permission"}
        </Button>
        <Upload {...uploadProps} listType="picture-circle">
          <Button
            type="success"
            size="small"
            icon={<UploadOutlined />}
            loading={loading}
            disabled={!hasPermission([ITEM_CREATE_PERMISSION])}
          >
            {hasPermission([ITEM_CREATE_PERMISSION])
              ? "Import"
              : "No Permission"}
          </Button>
        </Upload>
      </Space>
    );
  };

  
  return (
    <>
      <ItemList
        style={{ width: 191 }}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemList={itemList}
        categoryList={categoryList}
        extra={<Extra/>}
        refresh={refresh}
        refetch={refetch}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />

      <CreateItem
        refresh={refresh}
        refetch={refetch}
        itemList={itemList}
        categoryList={categoryList}
        isAddItem={isAddItem}
        handleAddItemClose={handleAddItemClose}
      />
    </>
  );
}

export default Item;