import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Select,
  Modal,
  Button,
  Space,
  Divider,
  Input,
  DatePicker,
  InputNumber,
} from "antd";
import {
  INQUIRY_REASONS_LIST_ENDPOINT,
  INQUIRY_REMARKS_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";

const CMForm = (props) => {
  // Props
  const { CMInfo, inquiryId, viewMode } = props;

  const toCMShowPage = () => {
    window.open(
      `/cost-of-making/details/${inquiryId}`,
    );
  };

  return (
    <Card
      title={
        <div style={{ display: "flex", gap: "5px" }}>
          <span style={{ fontSize: "14px" }}>CM</span>
          <span
            style={{ fontSize: "15px", cursor: "pointer" }}
            onClick={toCMShowPage}
          >
            <EyeOutlined />
          </span>
        </div>
      }
    >
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item label="Breakeven CM $" name="breakeven_cm">
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Breakeven CM $"
              size="small"
              disabled
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Expected Margin $" name="expected_margin">
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Expected Margin $"
              size="small"
              precision={2}
              disabled={viewMode}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="CGL Target $" name="cgl_target">
            <InputNumber
              style={{ width: "100%" }}
              placeholder="CGL Target $"
              size="small"
              disabled
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Buyer Target $" name="buyer_target">
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Buyer Target $"
              size="small"
              precision={2}
              disabled={viewMode}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Difference $" name="difference">
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Difference $"
              size="small"
              disabled
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Operational Profit $" name="operational_profit">
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Operational Profit $"
              size="small"
              disabled
              precision={2}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default CMForm;
