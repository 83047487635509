import { Table } from "antd";
import React, { useState } from "react";
import CustomFilter from "../../Common/CustomFilter";

const ItemListTable = (props) => {
    const {
      total,
      loading,
      defaultCurrent,
      pageSize,
      current,
      onChange,
      showFilter,
      setShowFilter,
    } = props;

    const handleFilter = () => {
        setShowFilter((prev) => !prev);
    };

    return (
      <>
        {props.filter && (
          <CustomFilter
            showFilter={showFilter}
            tableData={props.dataSource}
            fetchTableData={null}
            filterName="item_list"
          />
        )}
        <div className="table" style={{ marginTop: 10 }}>
          <Table
            loading={loading}
            dataSource={props.dataSource}
            columns={props.tableColumns}
            pagination={{
              defaultPageSize: pageSize,
              current: current,
              onChange: onChange,
              defaultCurrent: defaultCurrent,
              total: total,
              showSizeChanger: false,
            }}
          />
        </div>
      </>
    );
};
export default ItemListTable;
