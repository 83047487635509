import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Card, Select, Modal, Input, Button, DatePicker } from "antd";
import {
  MinusCircleOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CustomDatePicker from "common/CustomDatePicker";
import { debounce } from "lodash";
const { Option } = Select;

const { confirm } = Modal;

export default function POList({
  view,
  poList,
  setPoList,
  salesContractID,
  poTypes,
  packingTypes,
  getPackingTypes,
  getPOTypes,
}) {
  const removeSelectedProduct = (index) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        poList.splice(index, 1);
        setPoList([...poList]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onChangeHandel = (val, index, context) => {
    // console.log(context)
    if (context === "delivery_date") {
      poList[index][context] = moment(val).format("YYYY-MM-DD");
    } else {
      poList[index][context] = val;
    }
    poList[index]["po_status"] = "Created";
    setPoList([...poList]);
  };
  const incrementClick = (n) => {
    return n > 9 ? "" + n : "0" + n;
  };
  useEffect(() => {
    if (poList.length > 0) {
      poList.map((po) => {
        if (po?.po_status) {
        }
        return 0;
      });
    }
  }, [poList]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      getPOTypes({ value: value });
    }, 500),
    [],
  );

  const debouncedSearchPackingTypes = useCallback(
    debounce((value) => {
      getPackingTypes({ value: value });
    }, 500),
    [],
  );

  return (
    <Card
      className="custome-table"
      title={<span style={{ fontSize: "14px" }}>{`PO List`}</span>}
    >
      <div className="root-table">
        <table className="pi-product-details-table">
          <thead>
            <tr>
              <th className="t-header bc-java ">Serial</th>
              <th className="t-header bc-java ">Internal PO No.</th>
              <th className="t-header bc-java ">Original PO No.</th>
              <th className="t-header bc-java ">PO Type</th>
              <th className="t-header bc-java ">Packing Type</th>
              <th className="t-header bc-java ">Department</th>
              <th className="t-header bc-java ">Shipment Date</th>
              <th className="t-header bc-java ">Fully Shipped</th>
              <th className="t-header bc-java "></th>
            </tr>
          </thead>
          <tbody>
            {poList?.length ? (
              <>
                {poList.map((product, index) => (
                  <tr key={index}>
                    <td className="t-body">{index + 1}</td>
                    <td className="t-body">
                      <Input
                        disabled={true}
                        value={product?.internal_po + "" || undefined}
                        onChange={(e) =>
                          onChangeHandel(e.target.value, index, "internal_po")
                        }
                        size="small"
                      />
                    </td>
                    <td className="t-body">
                      <Input
                        disabled={view}
                        value={
                          product?.original_po !== null
                            ? product?.original_po + ""
                            : undefined
                        }
                        onChange={(e) =>
                          onChangeHandel(e.target.value, index, "original_po")
                        }
                        size="small"
                      />
                    </td>

                    {/* po types */}
                    <td className="t-body">
                      <Select
                        onChange={(value) =>
                          onChangeHandel(value, index, "p_o_type_id")
                        }
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        size="small"
                        value={product?.p_o_type_id}
                        onSearch={debouncedSearch}
                        allowClear
                        disabled={view}
                      >
                        {Array.isArray(poTypes) &&
                          poTypes?.map((po, idx) => (
                            <Option key={idx} value={po?.id}>
                              {po?.name}
                            </Option>
                          ))}
                      </Select>
                    </td>

                    {/* packing types */}
                    <td className="t-body">
                      <Select
                        onChange={(value) =>
                          onChangeHandel(value, index, "packing_type_id")
                        }
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        size="small"
                        value={product?.packing_type_id}
                        onSearch={debouncedSearchPackingTypes}
                        allowClear
                        disabled={view}
                      >
                        {Array.isArray(packingTypes) &&
                          packingTypes?.map((po, idx) => (
                            <Option key={idx} value={po?.id}>
                              {po?.name}
                            </Option>
                          ))}
                      </Select>
                    </td>

                    <td className="t-body">
                      <Input
                        disabled={view}
                        value={product?.department}
                        onChange={(e) =>
                          onChangeHandel(e.target.value, index, "department")
                        }
                        size="small"
                      />
                    </td>

                    <td className="t-body">
                      <CustomDatePicker
                        disabled={view}
                        value={
                          product?.delivery_date
                            ? moment(product?.delivery_date, "YYYY-MM-DD")
                            : undefined
                        }
                        onChange={(val) =>
                          onChangeHandel(val, index, "delivery_date")
                        }
                        size="small"
                      />
                    </td>
                    <td className="t-body">
                      {/* <Input
                        disabled={true}
                        value={product?.po_status || undefined}
                        onChange={(e) =>
                          onChangeHandel(e.target.value, index, "po_status")
                        }
                      /> */}
                      <Select
                        showSearch
                        onChange={(e) =>
                          onChangeHandel(e.target.value, index, "po_status")
                        }
                        placeholder="Select a PO Status"
                        optionFilterProp="children"
                        disabled={true}
                        defaultValue={"Created"}
                        value={product?.po_status || undefined}
                        size="small"
                      >
                        <Option value={"Created"} key={1}>
                          Active
                        </Option>
                        <Option value={"Closed"} key={2}>
                          Closed
                        </Option>
                      </Select>
                    </td>
                    {!product.is_edit
                      ? !product.is_edit
                      : !view && (
                          <td
                            className="t-body"
                            onClick={() => {
                              removeSelectedProduct(index);
                            }}
                          >
                            <MinusCircleOutlined
                              className="cursore-pointer"
                              style={{ fontSize: "16px", color: "red" }}
                            />
                          </td>
                        )}
                  </tr>
                ))}
              </>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>

      <Button
        type="primary"
        className="mt-4"
        icon={<PlusOutlined />}
        size="small"
        disabled={view}
        onClick={() => {
          if (salesContractID) {
            setPoList((oldArray) => [
              ...oldArray,
              {
                internal_po: `${salesContractID}_${moment().format(
                  "HH-mm-ss",
                )}`,
                original_po: "",
                delivery_date: "",
                po_status: "",
                is_edit: true,
              },
            ]);
          } else {
          }
        }}
      >
        Add PO
      </Button>
    </Card>
  );
}
