import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    GET_ORGANIZATION_LIST,
    CREATE_NEW_ORGANIZATION,
    DELETE_ORGANIZATION,
    UPDATE_ORGANIZATION_DETAIL, GET_ORGANIZATION_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_ORGANIZATION_ENDPOINT,
    DELETE_ORGANIZATION_ENDPOINT,
    EDIT_ORGANIZATION_ENDPOINT,
    GET_ORGANIZATION_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";
import { getData } from '../../apiServices/common';

export const onGetOrganizationList = (props) => {
  const { page, per_page, name, short_name, business_type, status, address } =
    props;

  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    const bodyData = {
      page: page,
      per_page: per_page,
      ...(name && { name: name }),
      ...(short_name && { short_name: short_name }),
      ...(business_type && { business_type: business_type }),
      ...(status && { status: status }),
      ...(address && { address: address }),
    };
    const response = await getData(
      `${GET_ORGANIZATION_ENDPOINT}`,
      false,
      bodyData,
    );
    if (response && response?.data?.code === 200) {
      dispatch({ type: FETCH_SUCCESS });
      dispatch({
        type: GET_ORGANIZATION_LIST,
        payload: response?.data?.data,
      });
    } else {
      dispatch({ type: FETCH_ERROR, payload: response?.error?.message });
      HandleNotification("error", "bottomRight", "Something went wrong!", null);
    }
  };
};
export const onCreateOrganization = (organization) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_ORGANIZATION_ENDPOINT}`, organization)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_ORGANIZATION, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Organization Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Organization Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};
export const onUpdateSelectedOrganization = (organization, id) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_ORGANIZATION_ENDPOINT}/${id}`, organization)

            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_ORGANIZATION_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Organization Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Organization updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};
export const onOrganizationGetDetail = (organizationId) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`api/organizations/${organizationId}/edit`)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_ORGANIZATION_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Got bank information Successfully",

                    });

                    // HandleNotification('success', 'bottomRight', 'Organization deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    // HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};


export const onDeleteSelectedOrganization = (organization) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_ORGANIZATION_ENDPOINT}/${organization.id}`)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_ORGANIZATION, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted bank Successfully",

                    });

                    HandleNotification('success', 'bottomRight', 'Organization deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};
