import { Table } from "antd";
import React, { useState } from "react";
import CustomFilter from "../Common/CustomFilter";

const OrganizationListTable = (props) => {
  const {
    total,
    loading,
    defaultCurrent,
    pageSize,
    current,
    onChange,
    showFilter,
    handleFilter,
  } = props;

  return (
    <>
      {props.filter && (
        <CustomFilter
          showFilter={showFilter}
          handleFilter={handleFilter}
          tableData={props.dataSource}
          fetchTableData={null}
          filterName="organization"
        />
      )}
      <div className="table" style={{ marginTop: 10 }}>
        <Table
          loading={loading}
          dataSource={props.dataSource}
          columns={props.tableColumns}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: pageSize,
            current: current,
            onChange: onChange,
            defaultCurrent: defaultCurrent,
            total: total,
          }}
        />
      </div>
    </>
  );
};
export default OrganizationListTable;
