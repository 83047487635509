import { Affix, Button, Card } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getData } from "../../../apiServices/common";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import AddUnitsPriceForm from "./AddUnitsPriceForm";
import { isArrayAndHasValue } from "utils/functions";

export default function UpdateUnitsPrice(props) {
  // Props
  const { width } = props;

  // States
  const [permitInfo, setPermitInfo] = useState();
  const [poDetailsData, setPoDetailsData] = useState(null);

  // Others
  const { itemId } = useParams();
  const propertyFormRef = useRef(null);

  const addProduct = () => {
    propertyFormRef.current.submit();
  };

  const getPOInfo = useCallback(async () => {
    const query = `/api/unit_prices/${itemId}/edit`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      const masterData = response.data.data;
      setPermitInfo(masterData);
      const po_data = masterData ? masterData.unit_price_details : null;

      const po_details = isArrayAndHasValue(po_data) && po_data.map((item) => {
        const sizes = {};

        item?.sizes.forEach((size) => {
          sizes[size?.name + "_" + size?.id] = size?.sales_order_quantity;
        });
        return {
          ...item,
          style_id: item.style.id,
          color_id: item.color.id,
          ...sizes,
        };
      });

      setPoDetailsData(po_details);
    }
  }, [itemId]);

  useEffect(() => {
    // Only run on first render
    // DO NOT add any depenedency here
    getPOInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={
              "Update Purchase Order Breakdown"
            }
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
              >
                Update
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddUnitsPriceForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        poDetailsData={poDetailsData}
        setPoDetailsData={setPoDetailsData}
        mode="update"
      />
    </>
  );
}
