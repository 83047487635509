import { Column } from "@ant-design/plots";
import { Button, Card, Col, DatePicker, Row, Space, Table } from "antd";
import { LINE_WISE_DAILY_EFFICIENCY } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatNumberWithCommas } from "utils/functions";

export default function Efficiency() {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [data, setData] = useState({
    dataSource: [],
    barChart: [],
    loading: false,
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const config = {
    data: data.barChart,
    xField: "line",
    yField: "value",
    label: {
      text: (originData) => {
        // const val = parseFloat(originData.profit);
        // if (val < 0.05) {
        //   return (val * 100).toFixed(1) + "%";
        // }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  const columns = [
    {
      title: "Sewing Line",
      dataIndex: "sewingLine",
      key: "sewingLine",
    },
    {
      title: "Line Chief",
      dataIndex: "lineChief",
      key: "lineChief",
    },
    {
      title: "Manpower",
      dataIndex: "manpower",
      key: "manpower",
      align: "right",
    },
    {
      title: "Production",
      dataIndex: "production",
      key: "production",
      align: "right",
    },
    {
      title: "SAM",
      dataIndex: "sam",
      key: "sam",
      align: "right",
      render: (text) => formatNumberWithCommas(parseFloat(text).toFixed(2)),
    },
    {
      title: "Working Hour",
      dataIndex: "workingHour",
      key: "workingHour",
      align: "right",
    },
    {
      title: "OT Hour Manpower",
      dataIndex: "otHourManpower",
      key: "otHourManpower",
      align: "right",
      render: (text) =>
        parseInt(text).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    },
    {
      title: "Available Minutes",
      dataIndex: "availableMinutes",
      key: "availableMinutes",
      align: "right",
      render: (text) =>
        parseInt(text).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    },
    {
      title: "Earn Minute",
      dataIndex: "earnMinute",
      key: "earnMinute",
      align: "right",
      render: (text) =>
        parseInt(text).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    },
    {
      title: "Actual Efficiency",
      dataIndex: "actualEfficiency",
      key: "actualEfficiency",
      align: "right",
      render: (text) =>
        `${formatNumberWithCommas(parseFloat(text).toFixed(2))}%`,
    },
    {
      title: "Ranking on Efficiency",
      dataIndex: "ranking",
      key: "ranking",
      align: "center",
    },
  ];

  const getEfficiencyData = async () => {
    setData({ dataSource: [], barChart: [], loading: true });
    try {
      const query = `${LINE_WISE_DAILY_EFFICIENCY}?start_date=${selectedDate.format(
        "YYYY-MM-DD",
      )}`;
      const response = await getData(query);

      const lineWiseSortDataSource = response?.data?.data?.data?.sort(
        (a, b) => {
          const numA = parseInt(a?.line?.match(/\d+/));
          const numB = parseInt(b?.line?.match(/\d+/));
          return numA - numB;
        },
      );

      const dataSource = lineWiseSortDataSource.map((item, index) => {
        const lineChief = item?.line_chief?.map((chief) => chief?.line_chief);
        const uniqueLineChiefAsString = Array.from(new Set(lineChief)).join(
          ", ",
        );
        return {
          key: index + 1,
          sewingLine: item.line,
          lineChief: uniqueLineChiefAsString,
          manpower: item.manpower,
          production: item.actual_production,
          sam: item.sam,
          workingHour: item.working_hour,
          otHourManpower: item.ot_hour_manpower,
          availableMinutes: item.available_minute,
          earnMinute: item.earn_minute,
          actualEfficiency: item.actual_efficiency,
          ranking: item.rank,
        };
      });

      const barChart = dataSource.map((item) => {
        return {
          line: item.sewingLine,
          value: formatNumberWithCommas(
            parseFloat(item.actualEfficiency).toFixed(2),
          ),
        };
      });

      setData({ dataSource: dataSource, barChart: barChart, loading: false });
    } catch (error) {
      console.log(error);
      setData({ dataSource: [], barChart: [], loading: false });
    }
  };

  useEffect(() => {
    getEfficiencyData();
  }, [selectedDate]);

  return (
    <Card
      title={`
        Line wise Daily Efficiency Report for ${selectedDate.format(
          "DD-MMM-YYYY",
        )}
      `}
      bordered={false}
    >
      <br />
      <Row gutter={24}>
        <Col span={6}>
          <DatePicker
            allowClear={false}
            format={"DD-MMM-YYYY"}
            style={{
              width: "100%",
            }}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Col>
        <Col span={3}>
          <Space>
            <Button
              type="primary"
              danger
              onClick={() => setSelectedDate(moment())}
            >
              Reset
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <Table
        dataSource={data.dataSource}
        columns={columns}
        pagination={false}
        bordered
        summary={(tableData) => {
          if (tableData.length === 0) return null;
          let totalManpower = 0;
          let totalProduction = 0;
          let totalSam = 0;
          let totalWorkingHour = 0;
          let totalOtHourManpower = 0;
          let totalAvailableMinutes = 0;
          let totalEarnMinute = 0;
          let totalActualEfficiency = 0;

          tableData.forEach((item) => {
            totalManpower += item.manpower;
            totalProduction += item.production;
            totalSam += parseFloat(item.sam);
            totalWorkingHour += item.workingHour;
            totalOtHourManpower += item.otHourManpower;
            totalAvailableMinutes += item.availableMinutes;
            totalEarnMinute += item.earnMinute;
            totalActualEfficiency += parseFloat(item.actualEfficiency);
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalManpower}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalProduction}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>
                    {formatNumberWithCommas(parseFloat(totalSam).toFixed(2))}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalWorkingHour}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalOtHourManpower}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalAvailableMinutes}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalEarnMinute}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>
                    {formatNumberWithCommas(
                      parseFloat(totalActualEfficiency).toFixed(2),
                    )}
                    %
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Today's Est. Net Profit/taka
        </h3>
        <Column {...config} />
      </div>
    </Card>
  );
}
