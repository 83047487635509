import {
  DeleteTwoTone,
  EditOutlined,
  MoreOutlined,
  ExportOutlined
} from "@ant-design/icons";
import {
  Affix,
  Button,
  Card,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
} from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  UNIT_PRICE_DELETE,
  UNIT_PRICE_LIST,
} from "../../apiServices/API_ENDPOINTS";
import { deleteData, getData } from "../../apiServices/common";
import {
  PURCHASE_ORDER_CREATE_PERMISSION,
  PURCHASE_ORDER_EDIT_PERMISSION,
  PURCHASE_ORDER_VIEW_PERMISSION,
} from "../../routes/permissions";
import { hasPermission } from "../../utils/functions";
import { isMobileView } from "../Common/AppHelper";
import CustomFilter from "../Common/CustomFilter";
import Header from "../Common/Header";

const UnitsPrice = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const formatNumberWithCommas = (number) => {
    return Number(parseFloat(number).toFixed(2)).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  const exportUnitPriceQtyBreakDown = async (id) => {

    let response = await getData(`/api/unit_prices/generateExcel/${id}`);

    if (response && response?.status === 200) {
      const outputFilename = `UnitPriceQtyBreakDown.csv`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } else {
      message.error("Error exporting UnitPrice Qty Break Down");
    }
  };


  const columns = [
    {
      title: "Reference No.",
      dataIndex: ["sales_contract", "reference_no"],
      key: "reference_no",
    },
    {
      title: "Buying Office Name",
      dataIndex: ["buying_office", "name"],
      key: "buying_office_name",
    },
    {
      title: "Buyer Name",
      dataIndex: ["buyer_info", "name"],
      key: "buyer_name",
    },
    {
      title: "Factory Name",
      dataIndex: ["sub_contract_factory", "name"],
      key: "factory_name",
    },
    {
      title: "Order Quantity",
      dataIndex: "order_quantity",
      key: "order_quantity",
      render: (_, record) => formatNumberWithCommas(record?.order_quantity),
    },
    {
      title: "Contract Status",
      dataIndex: "contract_status",
      key: "contract_status",
    },
    {
      title: "Actions",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllPermitList = useCallback(
    async (passedObject) => {
      setLoading(true);

      const filter = passedObject;
      const customQuery = `${UNIT_PRICE_LIST}/?page=${currentPage}`;
      const response = await getData(customQuery, false, filter);

      if (response && response?.data?.code === 200) {
        const masterData = response?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      setLoading(false);
    },
    [currentPage],
  );

  useEffect(() => {
    getAllPermitList();
  }, [currentPage, getAllPermitList]);

  const gotoEditRoute = (id) => {
    navigate(`/merchandising/update-units-price/${id}`);
  };

  const handleDelete = (id) => {
    if (!id) return;

    const query = `${UNIT_PRICE_DELETE}/${id}`;

    const response = deleteData(query);

    if (response && response?.code === 200) {
      message.success("Data Deleted Successfully");
      getAllPermitList();
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Button
              icon={<EditOutlined />}
              type="text"
              onClick={() => gotoEditRoute(data?.id)}
            >
              Edit
            </Button>
          ),
          key: "1",
          disabled: !hasPermission([PURCHASE_ORDER_EDIT_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => handleDelete(data?.id)}
            >
              <Button icon={<DeleteTwoTone />} danger type="text">
                Delete
              </Button>
            </Popconfirm>
          ),
          key: "2",
          disabled: !hasPermission([PURCHASE_ORDER_VIEW_PERMISSION]),
        },
        {
          label: (
            <Button
              icon={<ExportOutlined />}
              type="text"
              onClick={() => exportUnitPriceQtyBreakDown(data?.id)}
            >
              Export
            </Button>
          ),
          key: "3",
          disabled: !hasPermission([PURCHASE_ORDER_EDIT_PERMISSION]),
        }
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        key="3"
        size="small"
        type="primary"
        disabled={!hasPermission([PURCHASE_ORDER_CREATE_PERMISSION])}
      >
        <Link to="/merchandising/add-units-price">
          {hasPermission([PURCHASE_ORDER_CREATE_PERMISSION])
            ? "Add New"
            : "No Permission"}
        </Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header title="Purchase Order Breakdown" subTitle="" actions={extra} />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={permitList}
            fetchTableData={getAllPermitList}
            filterName="purchase_order"
          />
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default UnitsPrice;
