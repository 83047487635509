import { Col, DatePicker, Form } from "antd";
import CustomDatePicker from "common/CustomDatePicker";

const SwingvsFoldingFilterFields = ({ initialTableData }) => {
  return (
    <>
      <Col span={4}>
        <Form.Item
          label="Start Date"
          name="start_date"
          required
          rules={[
            {
              required: true,
              message: "Start Date is required",
            },
          ]}
        >
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label="End Date"
          name="end_date"
          required
          rules={[
            {
              required: true,
              message: "End Date is required",
            },
          ]}
        >
          <CustomDatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default SwingvsFoldingFilterFields;
