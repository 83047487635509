import { message } from "antd";
import { postData } from "apiServices/common";
import dayjs from "dayjs";

const generateDateStrings = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const dateStrings = [];

  let current = start;
  while (current.isBefore(end) || current.isSame(end)) {
    dateStrings.push(current.format("YYYY-MM-DD"));
    current = current.add(1, "day");
  }

  return dateStrings;
};

const fillUpUnplanned = (output, calendarHeader, date) => {
  const plan_list = output.map((line) => {
    const lineList = [];
    calendarHeader.days.forEach(({ day, weekStatus }) => {
      const currentDate = dayjs(date).set("date", day).format("YYYY-MM-DD");
      const dayDetails = line.lines.find((line) => {
        const startDay = dayjs(line.start).date();
        const endDay = dayjs(line.end).date();
        return day >= startDay && day <= endDay;
      });
      const dayDetailsIndex = line.lines.findIndex((line) => {
        const startDay = dayjs(line.start).date();
        const endDay = dayjs(line.end).date();
        return day >= startDay && day <= endDay;
      });

      if (dayDetails) {
        let duty = dayDetails.details.length * 2;
        const f_detail = dayDetails.details[0];
        const l_detail = dayDetails.details[dayDetails.details.length - 1];
        const previousDayDetails = line.lines[dayDetailsIndex - 1];
        const f_previousDayDetail = previousDayDetails?.details[0];
        const l_previousDayDetail =
          previousDayDetails?.details[previousDayDetails?.details?.length - 1];
        const nextDayDetails = line.lines[dayDetailsIndex + 1];
        const f_nextDayDetail = nextDayDetails?.details[0];
        const l_nextDayDetail =
          nextDayDetails?.details[nextDayDetails?.details?.length - 1];
        const details = [];

        dayDetails.details.forEach((detail, index) => {
          if (
            index === 0 &&
            detail.plan_curve_mh < 10 &&
            details.length === 1
          ) {
            details.push(detail);
            details.push(detail);
            return;
          }
          if (index === 0 && detail.plan_curve_mh < 10) {
            details.push(detail);
            return;
          }
          if (
            index === dayDetails.details.length - 1 &&
            detail.plan_curve_mh < 10
          ) {
            details.push(detail);
            return;
          }
          details.push(detail);
          details.push(detail);
        });

        // NOTE: Do not break sequence of code related to duty calculation
        // decrease duty if first detail is less than 10
        if (f_detail.plan_curve_mh < 10) {
          duty = duty - 1;
        }

        // fill up unplanned task for first half of the day
        if (duty && f_detail.plan_curve_mh < 10) {
          // check if f_detail plan_curve_mh less than 10 and have l_previousDayDetail and l_previousDayDetail plan_curve_mh less than 10 and f_detail details_date is equal to l_previousDayDetail details_date
          if (
            f_detail.plan_curve_mh < 10 &&
            l_previousDayDetail &&
            l_previousDayDetail.plan_curve_mh < 10 &&
            f_detail.details_date === l_previousDayDetail.details_date
          ) {
          } else {
            lineList.push({
              id: null,
              title: "",
              duty: 1,
              delivery_status: "unplanned",
              is_apply_learning_curve: false,
              startDate: dayDetails.start,
              endDate: dayDetails.end,
              start_plan: dayDetails.start_plan,
              end_plan: dayDetails.end_plan,
              weekStatus,
              details: [],
              firstHalfOfDay: true,
              lastHalfOfDay: false,
            });
          }
        }

        // decrease duty if last detail is less than 10
        if (l_detail.plan_curve_mh < 10) {
          duty = duty - 1;
        }

        // fill up planned task for first half of the day
        lineList.push({
          id: dayDetails.id,
          title: dayDetails?.title,
          duty: duty,
          delivery_status: dayDetails.delivery_status,
          is_apply_learning_curve: dayDetails.is_apply_learning_curve === "1",
          startDate: dayDetails.start,
          endDate: dayDetails.end,
          start_plan: dayDetails.start_plan,
          end_plan: dayDetails.end_plan,
          weekStatus,
          details: details,
          firstHalfOfDay: true,
          lastHalfOfDay: false,
        });

        // fill up unplanned task for last half of the day
        if (l_detail.plan_curve_mh < 10) {
          // check if l_detail plan_curve_mh less than 10 and have f_nextDayDetail and f_nextDayDetail plan_curve_mh less than 10 and l_detail details_date is equal to f_nextDayDetail details_date
          if (
            l_detail.plan_curve_mh < 10 &&
            f_nextDayDetail &&
            f_nextDayDetail.plan_curve_mh < 10 &&
            l_detail.details_date === f_nextDayDetail.details_date
          ) {
          } else {
            lineList.push({
              id: null,
              title: "",
              duty: 1,
              delivery_status: "unplanned",
              is_apply_learning_curve: false,
              startDate: dayDetails.start,
              endDate: dayDetails.end,
              start_plan: dayDetails.start_plan,
              end_plan: dayDetails.end_plan,
              weekStatus,
              details: [],
              firstHalfOfDay: false,
              lastHalfOfDay: true,
            });
          }
        }
      } else {
        lineList.push({
          id: null,
          title: "",
          duty: 1,
          delivery_status: "unplanned",
          is_apply_learning_curve: false,
          startDate: dayjs(date).set("date", day).format("YYYY-MM-DD"),
          endDate: dayjs(date).set("date", day).format("YYYY-MM-DD"),
          start_plan: "",
          end_plan: "",
          weekStatus,
          details: [],
          firstHalfOfDay: true,
          lastHalfOfDay: false,
        });
        lineList.push({
          id: null,
          title: "",
          duty: 1,
          delivery_status: "unplanned",
          is_apply_learning_curve: false,
          startDate: dayjs(date).set("date", day).format("YYYY-MM-DD"),
          endDate: dayjs(date).set("date", day).format("YYYY-MM-DD"),
          start_plan: "",
          end_plan: "",
          weekStatus,
          details: [],
          firstHalfOfDay: false,
          lastHalfOfDay: true,
        });
      }
    });

    return {
      line_id: line.line_id,
      line_name: line.line_name,
      lines: lineList,
    };
  });

  return plan_list;
};

const fillUpUnplannedOld = (output, calendarHeader, date) => {
  const plan_list = output.map((line) => {
    const lineList = calendarHeader.days.map(({ day, weekStatus }) => {
      const dayDetails = line.lines.find((line) => {
        const startDay = dayjs(line.start).date();
        const endDay = dayjs(line.end).date();

        return day >= startDay && day <= endDay;
      });

      if (dayDetails) {
        return {
          id: dayDetails.id,
          title: dayDetails?.title,
          duty: generateBarBaseOnDate(dayDetails),
          // dutyStatus: getDutyStatus(dayDetails),
          delivery_status: dayDetails.delivery_status,
          is_apply_learning_curve: dayDetails.is_apply_learning_curve === "1",
          startDate: dayDetails.start,
          endDate: dayDetails.end,
          start_plan: dayDetails.start_plan,
          end_plan: dayDetails.end_plan,
          weekStatus,
          details: dayDetails.details,
        };
      }

      return {
        id: null,
        title: "",
        duty: 1,
        delivery_status: "unplanned",
        is_apply_learning_curve: false,
        startDate: dayjs(date).set("date", day).format("YYYY-MM-DD"),
        endDate: dayjs(date).set("date", day).format("YYYY-MM-DD"),
        start_plan: "",
        end_plan: "",
        weekStatus,
        details: [],
      };
    });

    return {
      line_id: line.line_id,
      line_name: line.line_name,
      lines: lineList,
    };
  });

  return plan_list;
};

export function transformData(input, date, calendarHeader) {
  const output = [];

  input.forEach((item) => {
    // Find if this resourceId already exists in output
    let existingLine = output.find((line) => line.line_id === item.resourceId);

    if (!existingLine) {
      // If not, create a new entry
      existingLine = {
        line_id: item.resourceId,
        line_name: item.resourceName,
        title: item.title,
        lines: [],
      };
      output.push(existingLine);
    }

    // Create the line object for the "lines" array
    const lineDetails = {
      id: item.id,
      title: item.title,
      line_id: item.resourceId,
      start_plan: item.start,
      end_plan: item.end,
      start: item.details[0]?.details_date || "",
      end: item.details[item.details.length - 1]?.details_date || "",
      is_apply_learning_curve: item.is_apply_learning_curve,
      delivery_status: item.delivery_status,
      details: item.details,
    };

    // Push the new line details into the lines array
    existingLine.lines.push(lineDetails);
  });

  return {
    calendarHeader,
    calendarBody: removeDuplicateEntries(
      fillUpUnplanned(output, calendarHeader, date),
    ),
  };
}

function removeDuplicateEntries(data) {
  return data.map((line) => {
    // Filter unique objects based on startDate and endDate
    const uniqueDuties = [];
    const uniqueEntries = {};

    line.lines.forEach((entry) => {
      const key = `${entry.id}-${entry.startDate}-${entry.endDate}-${entry.firstHalfOfDay}-${entry.lastHalfOfDay}`;
      if (!uniqueEntries[key]) {
        uniqueEntries[key] = entry;
        uniqueDuties.push(entry);
      }
    });

    return { ...line, lines: uniqueDuties };
  });
}

// Function to generate Calendar the date object
export const generateCalendar = (value) => {
  const date = dayjs(value);
  const daysInMonth = date.daysInMonth();
  const month = date.format("MMMM");
  const year = date.format("YYYY");

  // Array of days in the month (1 to 31)
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  // Array of weekdays for the entire month (31 days)
  const weeks = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = dayjs(`${year}-${date.format("MM")}-${day}`);
    const weekday = currentDate.format("dd");
    weeks.push(weekday.charAt(0));
  }

  // Resulting date object
  const dateObject = {
    month,
    year,
    days,
    // weeks,
  };

  return dateObject;
};

// Helper function to determine the duty status
const getDutyStatus = (day) => {
  // console.log(day);
};

// Helper function to determine the delivery status
const getDeliveryStatus = (day) => {
  const startDate = dayjs(day.start);
  const endDate = dayjs(day.end);

  const startDetails = dayjs(day.details[0].details_date);
  const endDetails = dayjs(day.details[day.details.length - 1].details_date);

  if (startDate.isSame(startDetails) && endDate.isSame(endDetails)) {
    return "on_time";
  }

  if (startDate.isSame(startDetails) && endDate.isBefore(endDetails)) {
    return "delivery_early";
  }

  if (startDate.isSame(startDetails) && endDate.isAfter(endDetails)) {
    return "delivery_delay";
  }
  return "unplanned";
};

// generate bar base on start and end date ("2024-02-01", "2024-02-29") grid col span dynamic
const generateBarBaseOnDate = (data) => {
  // use dayjs to calculate the difference between two dates start and end
  const start = dayjs(data.start);
  const end = dayjs(data.end);

  const diffDays = end.diff(start, "day") + 1;

  return diffDays;
};

// all events

// Start dragging and set the index of dragged item
export const handleDragStart = (
  e,
  {
    line_index,
    task_index,
    draggedIndex,
    line,
    task: date,
    calendarData,
    setDraggedIndex,
    setCalendarData,
  },
) => {
  e.dataTransfer.setData("text/plain", "anything");
  e.dataTransfer.effectAllowed = "move";

  setDraggedIndex(`${line_index}-${task_index}`);
};

// Handle dropping by updating the state with new sorted items
export const handleDrop = (
  e,
  {
    line_index,
    task_index,
    draggedIndex,
    line,
    task,
    calendarData,
    setDraggedIndex,
    setCalendarData,
    refetch,
  },
) => {
  const startOfMonth = dayjs(
    `${calendarData.calendarHeader.year}-${calendarData.calendarHeader.month}-01`,
  )
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = dayjs(
    `${calendarData.calendarHeader.year}-${calendarData.calendarHeader.month}-01`,
  )
    .endOf("month")
    .format("YYYY-MM-DD");
  const today = new Date();
  const currentDate = today.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison
  const [l_index, t_index] = draggedIndex.split("-");
  const draggedLineIndex = parseInt(l_index);
  const draggedTaskIndex = parseInt(t_index);

  const newCalendarData = JSON.parse(JSON.stringify(calendarData));

  const draggedLine = newCalendarData.calendarBody[draggedLineIndex];
  const draggedTask = draggedLine.lines[draggedTaskIndex];

  const targetLine = newCalendarData.calendarBody[line_index];
  const targetTask = targetLine.lines[task_index];

  if (dayjs(task.startDate).isBefore(dayjs(currentDate))) {
    message.error("Cannot drop here");
    return;
  }

  if (line_index === draggedLineIndex && task_index === draggedTaskIndex) {
    message.error("Cannot drop here");
    return;
  }

  if (dayjs(draggedTask.start_plan).isBefore(dayjs(startOfMonth))) {
    message.error("Cannot drop here");
    return;
  }

  if (
    targetTask.delivery_status !== "unplanned" &&
    targetTask?.details?.length > 0
  ) {
    message.error("Cannot drop here");
    return;
  }

  if (
    dayjs(draggedTask.start_plan).isBefore(dayjs(draggedTask.startDate)) &&
    dayjs(draggedTask.end_plan).isAfter(dayjs(draggedTask.endDate))
  ) {
    message.error("Cannot drop here");
    return;
  }

  // Check if the target task is unplanned and has no details
  if (
    targetTask.delivery_status === "unplanned" &&
    targetTask?.details?.length === 0
  ) {
    // check if duty
    if (targetTask.duty === 1) {
      // call api with targetTask.id line id and start date

      const plan_id = draggedTask.id;
      const line_id = targetLine.line_id;
      const start_date = targetTask?.firstHalfOfDay
        ? targetTask.startDate
        : targetTask.endDate;
      // console.log("targetTask", targetTask);
      // return;
      postData("/api/productionPlanning/plan_date_change", {
        plan_id,
        line_id,
        start_date,
      })
        .then((res) => {
          console.log("res", res);
          refetch();
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      message.error("Cannot drop here");
    }
  } else {
    message.error("Cannot drop here");
  }
};

export const handleDrop2 = (
  e,
  {
    line_index,
    task_index,
    draggedIndex,
    line,
    task,
    calendarData,
    setDraggedIndex,
    setCalendarData,
  },
) => {
  // calendarData.calendarHeader.month and calendarData.calendarHeader.year November 2024 to start date of month 2024-11-01 and end date of this month 2024-11-30 use dayjs
  const startOfMonth = dayjs(
    `${calendarData.calendarHeader.year}-${calendarData.calendarHeader.month}-01`,
  )
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = dayjs(
    `${calendarData.calendarHeader.year}-${calendarData.calendarHeader.month}-01`,
  )
    .endOf("month")
    .format("YYYY-MM-DD");

  const [l_index, t_index] = draggedIndex.split("-");
  const draggedLineIndex = parseInt(l_index);
  const draggedTaskIndex = parseInt(t_index);

  if (line_index === draggedLineIndex && task_index === draggedTaskIndex) {
    message.error("Cannot drop here");
    return;
  }

  const newCalendarData = JSON.parse(JSON.stringify(calendarData));

  const draggedLine = newCalendarData.calendarBody[draggedLineIndex];
  const draggedTask = draggedLine.lines[draggedTaskIndex];

  const targetLine = newCalendarData.calendarBody[line_index];
  const targetTask = targetLine.lines[task_index];

  console.log("dragged index", draggedLineIndex, draggedTaskIndex);
  console.log("target index", line_index, task_index);

  // console.log("draggedLine", draggedLine);
  // console.log("draggedTask", draggedTask);
  // console.log("targetLine", targetLine);
  // console.log("targetTask", targetTask);
  // Check if the target task is unplanned and has details
  if (
    targetTask.delivery_status !== "unplanned" &&
    targetTask?.details?.length > 0
  ) {
    message.error("Cannot drop here");
    return;
  }

  if (
    dayjs(draggedTask.start_plan).isBefore(dayjs(draggedTask.startDate)) &&
    dayjs(draggedTask.end_plan).isAfter(dayjs(draggedTask.endDate))
  ) {
    message.error("Cannot drop here");
    return;
  }

  if (
    dayjs(draggedTask.start_plan).isBefore(dayjs(startOfMonth)) ||
    dayjs(draggedTask.end_plan).isAfter(dayjs(endOfMonth))
  ) {
    message.error("Cannot drop here");
    return;
  }
  // Check if the target task is unplanned and has no details
  if (
    targetTask.delivery_status === "unplanned" &&
    targetTask?.details?.length === 0
  ) {
    // check if duty
    if (targetTask.duty === 1) {
      const unplannedTask = [];
      for (let i = task_index; i < task_index + draggedTask.duty; i++) {
        const tasked = targetLine.lines[i];
        if (
          tasked &&
          tasked.duty === 1 &&
          tasked.delivery_status === "unplanned" &&
          tasked.details.length === 0
        ) {
          unplannedTask.push(tasked);
        } else {
          unplannedTask.push(false);
        }
      }
      const isAllEmpty = unplannedTask.every((item) => item !== false);
      if (isAllEmpty) {
        if (draggedLineIndex === line_index && draggedTaskIndex > task_index) {
          const dates = generateDateStrings(
            draggedTask.startDate,
            draggedTask.endDate,
          );

          const targetData = dates.map((date) => {
            return {
              ...unplannedTask[0],
              startDate: date,
              endDate: date,
            };
          });

          // swap value
          const temp = targetLine.lines[task_index];
          targetLine.lines[task_index] = {
            ...draggedTask,
            startDate: unplannedTask[0].startDate,
            endDate: unplannedTask[unplannedTask.length - 1].endDate,
          };
          draggedLine.lines[draggedTaskIndex] = temp;

          draggedLine.lines.splice(draggedTaskIndex, 1, ...targetData);

          draggedLine.lines.splice(task_index + 1, draggedTask.duty - 1);

          // console.log("newCalendarData", newCalendarData);

          setCalendarData(newCalendarData);
          setDraggedIndex(null);
        } else {
          // console.log("1 targetLine", targetLine);
          targetLine.lines.splice(task_index, draggedTask.duty);

          // console.log("2 targetLine", targetLine);
          targetLine.lines.splice(task_index, 0, {
            ...draggedTask,
            startDate: unplannedTask[0].startDate,
            endDate: unplannedTask[unplannedTask.length - 1].endDate,
          });

          const dates = generateDateStrings(
            draggedTask.startDate,
            draggedTask.endDate,
          );

          const targetData = dates.map((date) => {
            return {
              ...unplannedTask[0],
              startDate: date,
              endDate: date,
            };
          });

          // console.log("1 draggedLine", draggedLine);
          draggedLine.lines.splice(draggedTaskIndex, 1);
          // console.log("2 draggedLine", draggedLine);
          draggedLine.lines.splice(draggedTaskIndex, 0, ...targetData);

          draggedLine.lines.forEach((task, index) => {
            console.log("task", task);
            console.log("calendarHeader", newCalendarData.calendarHeader);
          });

          // console.log("newCalendarData", newCalendarData);

          setCalendarData(newCalendarData);
          setDraggedIndex(null);
        }
      } else {
        message.error("Cannot drop here");
      }
    } else {
      message.error("Cannot drop here");
    }
  } else {
    message.error("Cannot drop here");
  }
};

// Prevent default drag over behavior
export const handleDragOver = (e) => {
  e.preventDefault();

  // Set the drop effect to move
  e.dataTransfer.dropEffect = "move";
};

export const handleDragEnd = (e) => {
  e.preventDefault();
  console.log("drag end");
};
