import { MinusCircleOutlined } from "@ant-design/icons";
import { Input,InputNumber } from "antd";
import { isArrayAndHasValue } from "utils/functions";

const ManPowerTableData = (props) => {
  const { sectionItems, onChangeValue, onChangeSAMValue, view, edit, manpowerMode, removeRow } = props;

  return (
    <>
      {isArrayAndHasValue(sectionItems) &&
        sectionItems?.map((sectionItem, index) => {
          const machine_sam = sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "helper" 
                              && sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "iron" 
                              && sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "hp" 
                              ? sectionItem?.sam 
                              : null;
          const helper_sam = sectionItem?.ob_machine_type_info?.name.toLowerCase() === "helper" 
                              || sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp" 
                              ? sectionItem?.sam 
                              : null;
          const iron_sam = sectionItem?.ob_machine_type_info?.name.toLowerCase() === "iron" 
                            || sectionItem?.ob_machine_type_info?.name.toLowerCase() === "ir"
                            ? sectionItem?.sam 
                            : null;

          
          return (
            <tr key={`table_parent_key_${sectionItem?.id || index}`}>
              {/* SL */}
              <td className="t-body sticky">{sectionItem?.id}</td>
              {/* Operation / Section Name */}
              <td className="t-body sticky">{sectionItem?.name}</td>
              {/* Condition */}
              <td className="t-body sticky">{sectionItem?.condition}</td>
              {/* SMV - M/C */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() !==
                  "helper" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !==
                  "iron" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "hp"
                  ? sectionItem?.sam
                  : ""}
              </td>
              {/* SMV - HP */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() ===
                  "helper" ||
                sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
                  ? sectionItem?.sam
                  : ""}
              </td>
              {/* SMV - IR */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() ===
                "iron"
                  ? sectionItem?.sam
                  : ""}
              </td>

              {/* For updated smv section */}
              {/* Manul smv - M/C */}
              <>
              <td className="t-body sticky">
                <Input
                  size="small"
                  value={sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "helper" &&
                    sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "iron" &&
                    sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "hp"
                      ? (sectionItem?.updated_sam ?? sectionItem?.sam)
                      : ""}
                  disabled={view || manpowerMode || !machine_sam}
                  onChange={(event) => {
                    onChangeSAMValue(
                      event.target.value,
                      sectionItem?.id,
                      "updated_sam",
                    );
                  }}
                  style={{ width: "75px" }}
                />
              </td>
              {/* Manul smv - HP */}
              <td className="t-body sticky">
                <Input
                  size="small"
                  value={sectionItem?.ob_machine_type_info?.name.toLowerCase() === "helper" ||
                    sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
                      ? (sectionItem?.updated_sam ?? sectionItem?.sam)
                      : ""}
                  disabled={view || manpowerMode || !helper_sam}
                  onChange={(event) => {
                    onChangeSAMValue(
                      event.target.value,
                      sectionItem?.id,
                      "updated_sam",
                    );
                  }}
                  style={{ width: "75px" }}
                />
              </td>
              {/* Manul smv - IR */}
              <td className="t-body sticky">
                <Input
                  size="small"
                  value={sectionItem?.ob_machine_type_info?.name.toLowerCase() === "iron"
                    ? (sectionItem?.updated_sam ?? sectionItem?.sam)
                    : ""}
                  disabled={view || manpowerMode || !iron_sam}
                  onChange={(event) => {
                    onChangeSAMValue(
                      event.target.value,
                      sectionItem?.id,
                      "updated_sam",
                    );
                  }}
                  style={{ width: "75px" }}
                />
              </td>
              </>
              {/* For updated smv section */}
              {/* M/C - Type */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name}
              </td>
              {/* TGT - M/C */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "helper" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "ir" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "hp" &&
                sectionItem?.ob_machine_type_info?.name.toLowerCase() !== "iron"
                  ? Number((60 / (sectionItem?.updated_sam || sectionItem?.sam)).toFixed(2))
                  : ""}
              </td>
              {/* TGT - HP */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() === "helper" ||
                sectionItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
                  ? Number((60 / (sectionItem?.updated_sam || sectionItem?.sam)).toFixed(2))
                  : ""}
              </td>
              {/* TGT - IR */}
              <td className="t-body sticky">
                {sectionItem?.ob_machine_type_info?.name.toLowerCase() === "iron" ||
                sectionItem?.ob_machine_type_info?.name.toLowerCase() === "ir"
                  ? Number((60 / (sectionItem?.updated_sam || sectionItem?.sam)).toFixed(2))
                  : ""}
              </td>
              {(manpowerMode) && (
                <>
                  {/* WORKER - M/C */}
                  <td className="t-body sticky">
                    {sectionItem?.worker_machine_sam
                      ? Number(sectionItem?.worker_machine_sam).toFixed(2)
                      : ""}
                  </td>
                  {/* WORKER - HP */}
                  <td className="t-body sticky">
                    {sectionItem?.worker_helper_sam
                      ? Number(sectionItem?.worker_helper_sam).toFixed(2)
                      : ""}
                  </td>
                  {/* WORKER - IR */}
                  <td className="t-body sticky">
                    {sectionItem?.worker_iron_sam
                      ? Number(sectionItem?.worker_iron_sam).toFixed(2)
                      : ""}
                  </td>
                </>
              )}
              {/* input manpower */}
              {manpowerMode && (
                <>
                  {/* WORKER - M/C */}
                  <td className="t-body sticky">
                    <Input
                      size="small"
                      value={sectionItem?.manual_worker_machine_sam}
                      disabled={!sectionItem?.worker_machine_sam ? true : false}
                      onChange={(event) => {
                        onChangeValue(
                          event.target.value,
                          sectionItem?.id,
                          "manual_worker_machine_sam",
                        );
                      }}
                      style={{ width: "75px" }}
                    />
                  </td>
                  {/* WORKER - HP */}
                  <td className="t-body sticky">
                    <Input
                      size="small"
                      value={sectionItem?.manual_worker_helper_sam}
                      disabled={!sectionItem?.worker_helper_sam ? true : false}
                      onChange={(event) => {
                        onChangeValue(
                          event.target.value,
                          sectionItem?.id,
                          "manual_worker_helper_sam",
                        );
                      }}
                      style={{ width: "75px" }}
                    />
                  </td>
                  {/* WORKER - IR */}
                  <td className="t-body sticky">
                    <Input
                      size="small"
                      value={sectionItem?.manual_worker_iron_sam}
                      disabled={!sectionItem?.worker_iron_sam ? true : false}
                      onChange={(event) => {
                        onChangeValue(
                          event.target.value,
                          sectionItem?.id,
                          "manual_worker_iron_sam",
                        );
                      }}
                      style={{ width: "75px" }}
                    />
                  </td>
                </>
              )}
              {!view && !manpowerMode && (
                <td>
                  <div className="action-container">
                    <div
                      onClick={() => {
                        removeRow(sectionItem?.id);
                      }}
                    >
                      <MinusCircleOutlined
                        className="cursore-pointer"
                        style={{ fontSize: "14px", color: "red" }}
                      />
                    </div>
                  </div>
                </td>
              )}
            </tr>
          );
        })}
    </>
  );
};

export default ManPowerTableData;
