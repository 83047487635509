import {Card, Col, Row, Typography} from "antd";
import Icon from "@ant-design/icons";
import React from "react";

const AppCards =(props) => {
    const { marginBottom, itemsPerRow } = props;
    const {Title, Text} = Typography;
    return (
        <div className="site-card-wrapper" style={{marginTop: 10, marginRight: 2}}>
            <Row gutter={16}>
                {
                    props.cardArr.map((elem, Idx) => (

                            <Col span={itemsPerRow === 4 ? 6 : 8} key={Idx}>
                                <Card bordered={false} style={{ marginBottom: marginBottom, padding:'5px 10px' }}>
                                    <Row>
                                        <Col style={{marginRight:'20px'}}>
                                            <Icon component={elem.icon}></Icon>
                                        </Col>
                                        <Col style={{ flex: 1 }}>
                                            <Text type={"secondary"} style={{fontWeight: 700, fontSize: '16px'}}> {elem.title}</Text>
                                            <Typography style={{fontWeight: 700, textAlign: "left", fontSize: '22px'}}>{elem.count}</Typography>
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>

                        )

                    )
                }

            </Row>
        </div>
    )


}
export default AppCards;