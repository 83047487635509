import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import {
  GET_ALL_TNA_DEPARTMENT_DATA,
  GET_ALL_TNA_PROCESS_DATA,
  POST_TNA_TASK_DATA,
  UPDATE_TNA_TASK_DATA,
} from "apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { forwardRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isArrayAndHasValue } from "utils/functions";
const { Option } = Select;

const TaskForm = forwardRef((props, ref) => {
  const {
    view,
    form,
    isEdit,
    editInfo,
    fileData,
    CMInfo,
    viewMode,
    processId,
    deptId,
  } = props;
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [process, setProcess] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllDatas();
  }, [editInfo]);

  const onFinish = async (values) => {
    // console.log("onFinish values", values);
    if (
      (values?.pcd === true || values?.pcd === 1) &&
      (values?.shipment === true || values?.shipment === 1)
    ) {
      message.info("PCD and Shipment cann't be checked for same task.");
      return;
    }

    const addQuery = `${POST_TNA_TASK_DATA}`;
    const editQuery = `${UPDATE_TNA_TASK_DATA}/${taskId}`;
    const bodyData = {
      title: values?.title,
      description: values?.description,
      tna_process_id: isArrayAndHasValue(values?.process)
        ? values?.process[0]
        : values?.process,
      tna_department_id: isArrayAndHasValue(values?.department)
        ? values?.department[0]
        : values?.department,
      relation_with: values?.relation_with,
      is_pcd: values?.is_pcd ? 1 : 0,
      is_shipment: values?.is_shipment ? 1 : 0,
      is_active: values?.status,
    };
    // console.log(bodyData)
    if (taskId > 0) {
      const response = await putData(editQuery, bodyData);
      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "Success!",
          response?.message || `Successfully Updated Task`,
        );
        navigate("/tanda/task");
      } else {
        // console.log(response)
        HandleNotification(
          "error",
          "Error!",
          response?.message || `Failed to Update Task`,
        );
      }
    } else {
      const response = await postData(addQuery, bodyData);
      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "Success!",
          response?.message || `Successfully Added Task`,
        );
        navigate("/tanda/task");
      } else {
        HandleNotification(
          "error",
          "Error!",
          response?.message || `Failed to add Task`,
        );
      }
    }
  };

  const getAllDatas = async () => {
    setIsLoading(true);
    getAllProcess();
    getAllDepartment();
    setIsLoading(false);
  };
  const getAllProcess = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };

    const response = await getData(GET_ALL_TNA_PROCESS_DATA, false, bodyData);
    if (response && response?.data?.code === 200) {
      setProcess(response?.data?.data?.data);
    }
  };
  const getAllDepartment = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };

    const response = await getData(
      GET_ALL_TNA_DEPARTMENT_DATA,
      false,
      bodyData,
    );
    if (response && response?.data?.code === 200) {
      setDepartments(response?.data?.data?.data);
    }
  };

  const is_pcd = Form.useWatch("is_pcd", form);
  const is_shipment = Form.useWatch("is_shipment", form);

  return (
    <Spin spinning={isLoading}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          process: processId,
          department: deptId,
          status: 1,
        }}
      >
        <Card>
          <Row gutter={6}>
            <Col span={6}>
              <Form.Item
                label="Task Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: `Task Title is required `,
                  },
                ]}
              >
                <Input
                  placeholder="Task Title ...."
                  size="small"
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: false,
                    message: `Description is required `,
                  },
                ]}
              >
                <Input
                  size="small"
                  placeholder="Description"
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              {/* <Form.Item name="process_owner_id" hidden /> */}
              <Form.Item
                label="Relation With"
                name="relation_with"
                rules={[
                  {
                    required: true,
                    message: `Relation With is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select relation with ..."
                  optionFilterProp="children"
                  disabled={viewMode}
                  size="small"
                  allowClear
                >
                  <Option value={"SC"}>SC</Option>
                  <Option value={"Style"}>Style</Option>
                  <Option value={"PO"}>PO</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              {/* <Form.Item name="process_owner_id" hidden /> */}
              <Form.Item
                label="Process"
                name="process"
                rules={[
                  {
                    required: true,
                    message: `Process is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Process ..."
                  optionFilterProp="children"
                  disabled={viewMode}
                  size="small"
                  onSearch={(value) => getAllProcess({ name: value })}
                  allowClear
                >
                  {isArrayAndHasValue(process) &&
                    process?.map((userItem, index) => (
                      <Option value={userItem?.id} key={userItem?.id || index}>
                        {userItem?.title || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              {/* <Form.Item name="process_owner_id" hidden /> */}
              <Form.Item
                label="Department"
                name="department"
                rules={[
                  {
                    required: true,
                    message: `Department is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Department ..."
                  optionFilterProp="children"
                  disabled={viewMode}
                  size="small"
                  onSearch={(value) => getAllDepartment({ name: value })}
                  allowClear
                >
                  {isArrayAndHasValue(departments) &&
                    departments?.map((userItem, index) => (
                      <Option value={userItem?.id} key={userItem?.id || index}>
                        {userItem?.title || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label=""
                valuePropName="checked"
                name="pcd_or_shipment"
                rules={[
                  {
                    required: true,
                    message: `PCD or Shipment is required `,
                  },
                ]}
              >
                <Radio.Group
                  disabled={viewMode}
                  style={{ marginTop: "6%", marginLeft: "25%" }}
                >
                  <Radio value={1}>Is PCD?</Radio>
                  <Radio value={2}>Is Shipment?</Radio>
                </Radio.Group>
              </Form.Item>
            </Col> */}
            <Col span={2}>
              <Form.Item name="is_pcd" valuePropName="checked" label=" ">
                <Checkbox disabled={viewMode || is_shipment}>Is PCD?</Checkbox>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="is_shipment" valuePropName="checked" label=" ">
                <Checkbox disabled={viewMode || is_pcd}>Is Shipment?</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: `Status is required `,
                  },
                ]}
              >
                <Radio.Group disabled={viewMode}>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>In-Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Spin>
  );
});

export default TaskForm;
