import React from "react";
import { Layout, Space } from "antd";
import "../index.css";

const InventoryLayOutCommon = ({ children }) => {
  return (
    <Layout>
      <Layout style={{ height: "100%" }}>
        <Layout
          style={{
            padding: "0 5px 5px",
          }}
          className="site-layout"
        >
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: "flex",
            }}
          >
            {children}
          </Space>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default InventoryLayOutCommon;
