import { Col, Form, Row, Select, Input } from "antd";

const SalesContractFilterFields = ({ initialTableData }) => {
  const { Option } = Select;

  return (
    <>
      <Col span={4}>
        <Form.Item
          label="Sales Contract Unique ID"
          name="sales_contract_unique_id"
        >
          <Input size="small" placeholder="Write Sales Contract Unique ID" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Reference Number" name="reference_no">
          <Input size="small" placeholder="Write Reference Number" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Contract Date" name="contract_date">
          <Input size="small" placeholder="Contract Date" />
        </Form.Item>
      </Col>
    </>
  );
};

export default SalesContractFilterFields;
