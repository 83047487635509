import React, { useRef } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { Link, useNavigate } from "react-router-dom";
import ExportDetailsForm from "./ExportDetailsForm";

export default function CreateExportDetails(props) {
  //   Refs
  const propertyFormRef = useRef(null);

  //   Antd
  const [form] = Form.useForm();

  //   Router
  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/export/export-details");
  };

  const addIou = () => {
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
        >
          <Header
            title="Add Export Details"
            subTitle=""
            routes={
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/export/sales-contract">Export</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Save</Breadcrumb.Item>
              </Breadcrumb>
            }
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => addIou()}
                >
                  Save
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <ExportDetailsForm
        ref={propertyFormRef}
        form={form}
      />
    </>
  );
}
