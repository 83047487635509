import { useState, useEffect, useCallback } from "react";
import {
  Card,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Table,
  Typography,
  Divider,
  Space,
  Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  LEARNING_CURVE_RESOURCE
} from "../../../apiServices/API_ENDPOINTS";
import { postData, getData, putData } from "../../../apiServices/common";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../utils/functions";
import HandleNotification from "../../../common/Notification";
import CurveDetailsForm from "./CurveDetailsTable";


const CurveForm = (props) => {
  // Props
  const { form, editMode, viewMode } = props;
  const [code, setCode] = useState("");
  const { TextArea } = Input;

    // States
    const [addedReason, setAddedReason] = useState(null);
    const [reasons, setReasons] = useState(null);
  
    // Antd
    const [curveDetailsForm] = Form.useForm();
  
    // Others
    const initialCurveDetails = {
      unique_id: 1,
      day: 1,
      efficiency: null,
      working_hours: null,
      approx_manpower: null,
    };
  

    const [curveDetailsList, setCurveDetailsList] = useState([initialCurveDetails]);

  // Router
  const navigate = useNavigate();
  const { id } = useParams();

  // Antd
  const { Option } = Select;


  const getLearningCurveInfo = useCallback(
    async (id) => {
      const query = `${LEARNING_CURVE_RESOURCE}/${id}/edit`;
      const response = await getData(query);
      if (response && response?.data?.code === 200) {

        form.setFieldsValue({
          range_start: response?.data?.data?.range_start,
          range_end: response?.data?.data?.range_end,
        });

        const existingCurveDetails = isArrayAndHasValue(response?.data?.data?.curve_details) 
            && response?.data?.data?.curve_details?.map((item, index) => {
              return {
                ...item,
                unique_id: index + 1,
                day: item?.day,
                efficiency: item?.efficiency,
                working_hours: item?.working_hours,
                approx_manpower: item?.approx_manpower
              };
        });
        setCurveDetailsList(existingCurveDetails);

      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error in fetching Learning Curve Info",
          null,
        );
      }
    },
    [form],
  );

  const handleNameChange = (e) => {
    const name = e.target.value;
    // Replace spaces and special characters with dashes
    const codeValue = name.replace(/[^a-zA-Z0-9]/g, "-");
    setCode(codeValue);
    form.setFieldsValue({ code: codeValue });
  };

  useEffect(() => {
    // get selected cost info on edit mode
    if (id) {
      getLearningCurveInfo(id);
    }
  }, [editMode, id, getLearningCurveInfo]);

  const onFinishLearningCurve = async (values) => {

    const query = editMode
      ? `${LEARNING_CURVE_RESOURCE}/${id}`
      : `${LEARNING_CURVE_RESOURCE}`;

    const bodyData = {
      range: `${values?.range_start}-${values?.range_end}`,
      learning_curve_details: curveDetailsList
    };
    
    const response = editMode 
        ? await putData(query, bodyData) 
        : await postData(query, bodyData);
  
    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        `Learning Curve Details ${editMode ? "updated" : "added"} added successfully!`,
        null,
      );
      form.resetFields();
      navigate("/merchandising/learning-curves");
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        `Error ${editMode ? "updating" : "adding"} notification settings!`,
        null,
      );
    }
  };

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishLearningCurve}
      >
        <Card title={<span style={{ fontSize: "14px" }}>SAM</span>}>
          <Row gutter={24}>
            <Col className="gutter-row" span={4}>
              <Form.Item 
                label="From" 
                name="range_start" 
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input placeholder="From" size="small" />
              </Form.Item>
            </Col>
            
            <Col className="gutter-row" span={4}>
              <Form.Item 
                label="To" 
                name="range_end" 
                rules={[
                  {
                    required: true,
                    message: "To is required",
                  },
                ]}
              >
                <Input placeholder="To" size="small" />
              </Form.Item>
            </Col>
          </Row>

        </Card>

        <CurveDetailsForm
          curveDetailsList={curveDetailsList}
          setCurveDetailsList={setCurveDetailsList}
        />
      </Form>
    </div>
  );
};

export default CurveForm;
