import {Card, Col, Row, Typography} from "antd";
import Icon from "@ant-design/icons";
import React from "react";

const AppCards =(props) => {

    const {Title, Text} = Typography;
    return (
        <div className="site-card-wrapper" style={{marginTop: 10, marginRight: 2}}>
            <Row gutter={16}>
                {
                    props.cardArr.map((elem, Idx) => (

                            <Col span={8} key={Idx}>
                                <Card bordered={false} >
                                    <Row>
                                        <Col span={4} >
                                            <Icon component={elem.icon}></Icon>
                                        </Col>
                                        <Col span={18}>
                                            <Text type={"secondary"} style={{fontWeight: 700, fontSize: '14px'}}> {elem.title}</Text>
                                            <Typography style={{fontWeight: 700, textAlign: "left", fontSize: '22px'}}>{elem.count}</Typography>
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>

                        )

                    )
                }

            </Row>
        </div>
    )


}
export default AppCards;