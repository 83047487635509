import React from "react";
import {
  Col,
  Input,
  DatePicker,
  Form,
  Row,
  Select,
  Typography,
  InputNumber,
} from "antd";
import { useDispatch } from "react-redux";
import { onGetStyleList } from "redux/actions";
import { commaSeparateNumber } from "utils/functions";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";


const PackingForm = (props) => {
  // Props
  const {
    styleList,
    styleDetails,
    selectedPacking,
    onChangeStyle,
    onChangeInternalPO,
    onChangeOriginalPO,
  } = props;

  // Redux
  const dispatch = useDispatch();

  // Antd
  const { Option } = Select;

  // Others
  const dateFormat = "YYYY-MM-DD";

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Style No"
            name="style_id"
            rules={[
              {
                required: true,
                message: `Buying Office is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeStyle}
              onSearch={(value) => dispatch(onGetStyleList(1, 10, value))}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {styleList?.map((style, idx) => (
                <Option key={idx} value={style.id} label={style.style_no}>
                  {" "}
                  {style.style_no + " : " + style?.description || "--"}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Buying Office"
            rules={[
              {
                required: true,
                message: `Buying Office is required `,
              },
            ]}
          >
            <Input
              onChange={onChangeStyle}
              disabled={true}
              value={styleDetails?.style?.buying_office_info?.name}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Sales Contract No."
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input
              onChange={onChangeStyle}
              disabled={true}
              value={
                styleDetails?.style?.active_sales_contract_map_info
                  ?.active_sales_contract_info?.reference_no
              }
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Order Quantity"
            rules={[
              {
                required: true,
                message: `Order Quantity is required `,
              },
            ]}
          >
            <InputNumber
              onChange={onChangeStyle}
              disabled={true}
              value={
                styleDetails?.style?.sales_contract_map_info?.order_quantity
              }
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Internal PO"
            name="internal_po"
            rules={[
              {
                required: true,
                message: `Internal PO is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeInternalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.po_info?.map(
                (po, idx) => (
                  <Option
                    key={idx}
                    internal_po={po?.internal_po}
                    original_po={po?.original_po}
                    sales_contract_id={po?.sales_contract_id}
                    value={po?.internal_po}
                    label={po?.internal_po}
                    po_status={po?.po_status}
                  >
                    {po?.internal_po}
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Original PO" name="original_po">
            <Select
              onChange={onChangeOriginalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.po_info?.map(
                (po, idx) => (
                  <Option
                    key={idx}
                    internal_po={po?.internal_po}
                    original_po={po?.original_po}
                    sales_contract_id={po?.sales_contract_id}
                    value={po?.original_po}
                    label={po?.original_po}
                    po_status={po?.po_status}
                  >
                    {po?.original_po}
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title
        style={{ fontSize: 12, paddingTop: 2, marginTop: 2, marginBottom: 2 }}
      >
        Packing Summary
      </Typography.Title>

      <Row gutter={6}>
        <Col span={4}>
          <Form.Item label="Total Folding (pc.)">
            <InputNumber
              disabled={true}
              value={selectedPacking?.total_folding}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Total Packing (pc.)">
            <InputNumber
              disabled={true}
              value={selectedPacking?.total_packing}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Total Reject (pc.)">
            <InputNumber
              disabled={true}
              value={selectedPacking?.total_rejected_packing}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="WIP (pc.)">
            <InputNumber
              disabled={true} 
              value={selectedPacking?.total_wip_packing} 
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title
        style={{ fontSize: 12, paddingTop: 2, marginTop: 2, marginBottom: 2 }}
      >
        Packing Details
      </Typography.Title>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Packing Date"
            name="packing_date"
            rules={[
              {
                required: true,
                message: `Packing Date is required `,
              },
            ]}
            initialValue={moment()}
          >
            <CustomDatePicker
              // format={dateFormat}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Packing Day">
            <Input value={selectedPacking?.total_packing_day} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PackingForm;
