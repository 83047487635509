import { Button, Form, Typography } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  onCreateBuyingOffice,
  onUpdateSelectedBuyingOffice,
} from "../../../redux/actions";
import AppPageHeader from "../../../common/PageHeader";
import BuyingOfficeForm from "./CreateForm";

const BuyingOfficeCreateForm = (props) => {
  // Props
  const { selectedBuyingOffice } = props;

  //   States
  const [businessRelationDuration, setBusinessRelationDuration] = useState(0);

  //   Redux
  const dispatch = useDispatch();

  //   Antd
  const [form] = Form.useForm();
  const { Title } = Typography;

  //   Others
  const navigate = useNavigate();
  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    if (selectedBuyingOffice) {
      form.setFieldsValue({
        status: selectedBuyingOffice?.status === "1",
        short_name: selectedBuyingOffice?.short_name || "",
        buying_offices_unique_id:
          selectedBuyingOffice?.buying_offices_unique_id || "",
        name: selectedBuyingOffice?.name || "",
        address: selectedBuyingOffice?.address || "",
        liaison_status: selectedBuyingOffice?.liaison_status || "Under",
        business_relation_year:
          selectedBuyingOffice?.business_relation_year || 0,
        business_relation_since:
          moment(selectedBuyingOffice?.business_relation_since) || "2010-01-01",
        email: selectedBuyingOffice?.email || "",
        website: selectedBuyingOffice?.website || "",
        contract_person: selectedBuyingOffice?.contract_person || "",
        contract_number: selectedBuyingOffice?.contract_number || "",
      });
    }
  }, [selectedBuyingOffice, form]);

  const onFinish = (values) => {

    values["account_id"] = JSON.parse(localStorage.getItem("bankAccIds"));
    values["competitor_id"] = JSON.parse(
      localStorage.getItem("competitorsIds"),
    );
    values["merchandiser_id"] = JSON.parse(
      localStorage.getItem("merchandiserIds"),
    );
    values["buyer_id"] = JSON.parse(localStorage.getItem("buyerIds"));
    values["business_relation_year"] = businessRelationDuration;
    values["business_status"] = JSON.parse(
      localStorage.getItem("businessStatus"),
    );
    values["purpose"] = JSON.parse(localStorage.getItem("purpose"));
    values["status"] = values["status"] ? true : false;
    values["liaison_status"] = values["liaison_status"] ? true : false;

    selectedBuyingOffice
      ? dispatch(onUpdateSelectedBuyingOffice(selectedBuyingOffice?.id, values))
      : dispatch(onCreateBuyingOffice(values));
    form.resetFields();
    navigate("/configuration/buying-office/");
  };

  const extra = (
    <div className="extra-button-container">
      <Button
        style={{ marginRight: 10 }}
        size="small"
        danger
        onClick={() => navigate("/configuration/buying-office")}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        size="small"
        htmlType="submit"
        onClick={() => form.submit()}
      >
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <AppPageHeader
        extra={extra}
        title={<Title level={5}>Buying Office Entry</Title>}
      />
      <div className="formBody" style={{ padding: "10px 0 24px 24px" }}>
        <Typography style={{ fontWeight: 800 }}>{props.formTitle}</Typography>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          style={{ marginTop: 10 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <BuyingOfficeForm
            dateFormat={dateFormat}
            selectedBuyingOffice={selectedBuyingOffice}
            businessRelationDuration={businessRelationDuration}
            setBusinessRelationDuration={setBusinessRelationDuration}
          />
        </Form>
      </div>
    </>
  );
};

export default BuyingOfficeCreateForm;
