import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    GET_BUYER_LIST,
    CREATE_NEW_BUYER,
    DELETE_BUYER,
    UPDATE_BUYER_DETAIL, GET_BUYER_DETAIL, GET_SC_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_BUYER_ENDPOINT,
    DELETE_BUYER_ENDPOINT,
    EDIT_BUYER_ENDPOINT,
    GET_BUYER_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetBuyerList = (props) => {
    const { page, per_page, name, short_name, buyer_unique_id } = props;
    
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_BUYER_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                    ...(name && { name: name }),
                    ...(short_name && { short_name: short_name }),
                    ...(buyer_unique_id && { buyer_unique_id: buyer_unique_id }),
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BUYER_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onCreateBuyer = (buyer) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_BUYER_ENDPOINT}`, buyer)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_BUYER, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Buyer Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Buyer Created Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', error.message, null)

            });
    };
};
export const onUpdateSelectedBuyer = (buyer, id) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_BUYER_ENDPOINT}/${id}`, buyer)

            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_BUYER_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Buyer Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Buyer updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', error.message, null)

            });
    };
};
export const onGetBuyerDetails = (buyerId) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`api/buyers/${buyerId}/edit`)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_BUYER_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Got buyer information Successfully",

                    });

                    // HandleNotification('success', 'bottomRight', 'Buyer deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    // HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', error.message, null);

            });
    };
};

export const onGetSalesContractDetails = (scId) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`api/sales_contract/${scId}/edit`)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_SC_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Got buyer information Successfully",

                    });

                    // HandleNotification('success', 'bottomRight', 'Buyer deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: data?.message,
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', "Something Went Wrong!", null)

            });
    };
};
export const onDeleteSelectedBuyer = (buyer) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_BUYER_ENDPOINT}/${buyer.id}`)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_BUYER, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted bank Successfully",

                    });

                    HandleNotification('success', 'bottomRight', 'Buyer deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: data?.data?.message
                            ? data?.data?.message?.[0]
                            : "Something went wrong!",
                    });
                    HandleNotification(
                        "error",
                        "bottomRight",
                        data?.data?.message
                            ? data?.data?.message[0]
                            : "Something went wrong!",
                        null
                    );
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
