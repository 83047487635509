import { Card } from "antd";
import { isArrayAndHasValue } from "utils/functions";

const ValueTable = (props) => {
  // Props
  const { poDetailsData, dynamicSizeList, styleList } = props;

  const renderSizeValue = (size, obj, breakdown_type) => {
    const sizeId = size?.size_id || size?.id;
    const sizesArray = obj?.sizes;
    const foundSize = sizesArray?.find((item) => item.id === sizeId);
    if (breakdown_type === "qty") {
      return foundSize?.sales_order_quantity || 0;
    }
    if (breakdown_type === "price") {
      return foundSize?.sales_order_price || 0;
    }
    if (breakdown_type === "value") {
      return (
        (foundSize?.sales_order_quantity || 0) *
        (foundSize?.sales_order_price || 0)
      );
    }
  };

  const renderRowQtyTotal = (obj) => {
    const foundPoItem = poDetailsData?.find((item) => item?.unique_id === obj?.unique_id);
    const total = isArrayAndHasValue(foundPoItem?.sizes) && foundPoItem?.sizes?.reduce((acc, value) => {
      return acc + value?.sales_order_quantity;
    }, 0);

    return total || 0;
  }

  const renderRowValueTotal = (obj) => {
    const foundPoItem = poDetailsData?.find((item) => item?.unique_id === obj?.unique_id);
    let total = 0;
    isArrayAndHasValue(foundPoItem?.sizes) && foundPoItem?.sizes?.forEach((item) => {
      total += ((item?.sales_order_quantity || 0) * (item?.sales_order_price || 0));
    });

    return total || 0;
  }

  return (
    <Card
      title={
        <span style={{ fontSize: "14px", marginBottom: "10px" }}>
          Order Details
        </span>
      }
    >
      <div className="custome-table">
        <table border="1" style={{ marginBottom: "30px" }}>
          {/* Table Head */}
          <thead>
            {/* Master Header */}
            <tr>
              <th colSpan={5}></th>
              <>
                {isArrayAndHasValue(dynamicSizeList) &&
                  dynamicSizeList?.map((item, index) => {
                    return (
                      <th
                        colSpan={3}
                        key={`header_main_size_${item?.id}`}
                        style={{ backgroundColor: "#548235", color: 'white' }}
                      >
                        {item.label}
                      </th>
                    );
                  })}
              </>
              <th colSpan={2} style={{ backgroundColor: "#548235" }}>
                Total
              </th>
            </tr>
            {/* Sub Headers */}
            <tr>
              <th
                key="header_serial_number"
                style={{ backgroundColor: "#C5E0B4" }}
              >
                SL.
              </th>
              <th
                key="header_original_po"
                style={{ backgroundColor: "#C5E0B4" }}
              >
                PO
              </th>
              <th key="header_style" style={{ backgroundColor: "#C5E0B4" }}>
                Style
              </th>
              <th key="header_sub_style" style={{ backgroundColor: "#C5E0B4" }}>
                Sub Style
              </th>
              <th key="header_color" style={{ backgroundColor: "#C5E0B4" }}>
                Color
              </th>

              {/* Size Row Headers */}
              {isArrayAndHasValue(dynamicSizeList) &&
                dynamicSizeList?.map((obj, index) => {
                  return (
                    <>
                      <th
                        key={`size_column_qty_${index}`}
                        style={{ backgroundColor: "#E2F0D9", fontWeight: 100 }}
                      >
                        Qty
                      </th>
                      <th
                        key={`size_column_price_${index}`}
                        style={{ backgroundColor: "#E2F0D9", fontWeight: 100 }}
                      >
                        Price
                      </th>
                      <th
                        key={`size_column_value_${index}`}
                        style={{ backgroundColor: "#E2F0D9" }}
                      >
                        Value
                      </th>
                    </>
                  );
                })}

              <th key="header_qty" style={{ backgroundColor: "#E2F0D9", fontWeight: 100 }}>
                Qty
              </th>
              <th key="header_value" style={{ backgroundColor: "#E2F0D9" }}>
                Value
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody className="scrollable-table-container">
            {isArrayAndHasValue(poDetailsData) &&
              poDetailsData
                ?.filter((item) => item?.is_delete !== 1)
                .map((obj, index) => {
                  return (
                    <tr key={`table_parent_key_${index}`}>
                      {/* SL */}
                      <td className="t-body sticky">{index + 1}</td>
                      {/* Original PO */}
                      <td className="t-body sticky">
                        {obj?.original_po || ""}
                      </td>
                      {/* Style */}
                      <td className="t-body sticky">
                        {styleList.find(
                          (item) =>
                            item?.style_info?.id ===
                            (obj?.style_id || obj?.style?.id),
                        )?.style_info?.style_no || ""}
                      </td>
                      {/* Substyle */}
                      <td className="t-body sticky">
                        {obj?.sub_style_info?.sub_style_no ||
                          obj?.sub_style_info?.id}
                      </td>
                      {/* Color */}
                      <td className="t-body sticky">
                        {obj?.color?.color_info?.name || obj?.color?.name || ""}
                      </td>

                      {/* Dynamic Sizes Data */}
                      {isArrayAndHasValue(dynamicSizeList) &&
                        dynamicSizeList?.map((size, index) => {
                          return (
                            <>
                              <td
                                className="t-body"
                                key={`dynamic_size_qty_${index}`}
                              >
                                {renderSizeValue(size, obj, "qty")}
                              </td>
                              <td
                                className="t-body"
                                key={`dynamic_size_price_${index}`}
                              >
                                {renderSizeValue(size, obj, "price")}
                              </td>
                              <td
                                className="t-body"
                                key={`dynamic_size_value_${index}`}
                              >
                                <b>{renderSizeValue(size, obj, "value")}</b>
                              </td>
                            </>
                          );
                        })}

                      <td className="t-body sticky">
                        {renderRowQtyTotal(obj)}
                      </td>
                      <td className="t-body sticky">
                        <b>{renderRowValueTotal(obj)}</b>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ValueTable;
