import { Affix, Button, Card } from "antd";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import AddUnitsPriceForm from "./AddUnitsPriceForm";

const AddUnitsPrice = (props) => {
  // Props
  const { width } = props;

  // States
  const [poDetailsData, setPoDetailsData] = useState([]);

  // Others
  const propertyFormRef = useRef(null);
  const navigate = useNavigate();

  const discartForm = () => {
    navigate("/merchandising/purchase-order-breakdown");
  };

  const addPurchaseOrder = () => {
    propertyFormRef.current.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Purchase Order Breakdown"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} danger onClick={() => discartForm()}>
                Discard
              </Button>,
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addPurchaseOrder()}
              >
                Add Purchase Order Breakdown
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddUnitsPriceForm
        ref={propertyFormRef}
        poDetailsData={poDetailsData}
        setPoDetailsData={setPoDetailsData}
        mode={"add"}
      />
    </>
  );
};

export default AddUnitsPrice;
