import { useContext, useEffect } from "react";
import { Col, Form, Input, Row, Table, Typography } from "antd";
import { AssignmentContext } from "context/AssignmentContext/AssignmentContextProvider";
import { commaSeparateNumber } from "utils/functions";

const LineDetails = (props) => {
  // Contexts
  const { lineItemData } = useContext(AssignmentContext);

  // Antd
  const [lineDetailsForm] = Form.useForm();

  useEffect(() => {
    // Set form field values on first load
    if (lineItemData) {
      lineDetailsForm.setFieldsValue({
        style_no: lineItemData?.style?.style_no,
        internal_po: lineItemData?.po_map?.internal_po,
        original_po: lineItemData?.po_map?.original_po,
        line: lineItemData?.sewing_line?.name,
      });
    }
  }, [lineItemData, lineDetailsForm]);

  const columns = [
    {
      title: "Sub-Style",
      dataIndex: ["sub_style_info", "sub_style_no"],
      key: "sub_style",
      width: "15%",
    },
    {
      title: "Color",
      dataIndex: ["color_info", "name"],
      key: "color_name",
      width: "20%",
    },
    {
      title: "Size",
      dataIndex: ["size_info", "name"],
      key: "size_name",
    },
    {
      title: "Cut Qty",
      dataIndex: ["total_cutting"],
      key: "total_cutting",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : ""}</span>;
      },
    },
    {
      title: "This Line",
      dataIndex: ["assigned"],
      key: "assigned",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : ""}</span>;
      },
    },
    {
      title: "Total",
      dataIndex: ["total_assignment_all_line"],
      key: "total_assignment_all_line",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : ""}</span>;
      },
    },
    {
      title: "Assignment Remain",
      dataIndex: ["total_remain_assignment"],
      key: "total_remain_assignment",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : ""}</span>;
      },
    },
    {
      title: "Sewing Used",
      dataIndex: ["total_sewing_used"],
      key: "total_sewing_used",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : 0}</span>;
      },
    },
    {
      title: "Sewing Remaining",
      dataIndex: ["total_sewing_remain"],
      key: "total_sewing_remain",
      render(value, record) {
        return <span>{value ? commaSeparateNumber(value) : 0}</span>;
      },
    },
  ];

  const TableSummary = ({pageData}) => {
    const cutQtySum = pageData?.reduce((total, item) => {
      return total + item.total_cutting;
    }, 0);
    const assignedThisLineQtySum = pageData?.reduce((total, item) => {
      return total + Number(item?.assigned);
    }, 0);
    const totalAssignAllLinesQtySum = pageData?.reduce((total, item) => {
      return total + item?.total_assignment_all_line;
    }, 0);
    const totalRemainAssignmentQtySum = pageData?.reduce((total, item) => {
      return total + item?.total_remain_assignment;
    }, 0);
    const totalSewingUsedQtySum = pageData?.reduce((total, item) => {
      return total + item?.total_sewing_used;
    }, 0);
    const totalSewingRemainQtySum = pageData?.reduce((total, item) => {
      return total + item?.total_sewing_remain;
    }, 0);

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} id="total">
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} id="color" />
          <Table.Summary.Cell index={2} id="size" />
          <Table.Summary.Cell index={3} id="cut_qty">
            <Typography.Text>{cutQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} id="assigned_this_line">
            <Typography.Text>{assignedThisLineQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} id="total_assigned_all_lines">
            <Typography.Text>{totalAssignAllLinesQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} id="assignment_remain">
            <Typography.Text>{totalRemainAssignmentQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} id="sewing_used">
            <Typography.Text>{totalSewingUsedQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} id="sewing_remain">
            <Typography.Text>{totalSewingRemainQtySum}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <>
      <Form layout="vertical" form={lineDetailsForm}>
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item name="style_no" label="Style">
              <Input disabled={true} size="small" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="internal_po" label="Internal PO">
              <Input disabled={true} size="small" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="original_po" label="Original PO">
              <Input disabled={true} size="small" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="line" label="Line">
              <Input disabled={true} size="small" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        pagination={false}
        size={"small"}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={lineItemData?.line_assignment_details}
        columns={columns}
        sticky={true}
        summary={(pageData) => {
          return <TableSummary pageData={pageData} />;
        }}
      />
    </>
  );
};

export default LineDetails;
