import { useEffect, useState } from "react";

import { Button, Card, Col, DatePicker, Form, Row, Space } from "antd";
import { getData } from "apiServices/common";
import moment from "moment";
import CumulativeProfitLossStatement from "./CumulativeProfitLossStatement";
import DailyProfitLossStatement from "./DailyProfitLossStatement";

const PerformanceFolding = () => {
  const [todayData, setTodayData] = useState({
    data: [],
    barChart: [],
  });
  const [upToDateData, setUpToDateData] = useState({
    data: [],
    barChart: [],
  });
  const [loadingTodayData, setLoadingTodayData] = useState(false);
  const [loadingUpToDateData, setLoadingUpToDateData] = useState(false);
  const [form] = Form.useForm();
  const startDateWatch = Form.useWatch("start_date", form) || moment();
  const endDateWatch = Form.useWatch("end_date", form) || moment();

  const getDataForReport = async (firstDate, lastDate) => {
    try {
      const query = `/api/pnl-report/line-wise-sewing-profit-loss?start_date=${firstDate}&end_date=${lastDate}`;
      const res = await getData(query);
      return res.data?.data?.data
        ?.map((item) => {
          const unique_line_chief = [
            ...new Set(item.line_chief.map((chief) => chief?.line_chief)),
          ].join(", ");
          const unique_buyer = [
            ...new Set(item.buyer.map((buyer) => buyer?.buyer_short_name)),
          ].join(", ");

          return {
            ...item,
            line_chief: unique_line_chief,
            buyer: unique_buyer,
          };
        })
        .sort((a, b) => {
          const numA = parseInt(a.line.match(/\d+/));
          const numB = parseInt(b.line.match(/\d+/));
          return numA - numB;
        });
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const onFilter = async (values) => {
    // in start_date and end_date are current month
    const startDateOfCurrentMonth = moment().startOf("month");
    const endDateOfCurrentMonth = moment();
    const { start_date, end_date } = values;
    const startDate = start_date
      ? moment(start_date).format("YYYY-MM-DD")
      : startDateOfCurrentMonth.format("YYYY-MM-DD");
    const endDate = end_date
      ? moment(end_date).format("YYYY-MM-DD")
      : endDateOfCurrentMonth.format("YYYY-MM-DD");

    setLoadingUpToDateData(true);
    try {
      // Today Data
      const todayData = await getDataForReport(endDate, endDate);

      const todayBarChart = todayData?.map((item) => {
        return {
          line: item.line,
          profit: parseFloat(item.net_profit).toFixed(2),
        };
      });

      setTodayData({
        data: todayData,
        barChart: todayBarChart,
      });

      // Up To Date Data
      const upToDateData = await getDataForReport(startDate, endDate);

      const barChart = upToDateData?.map((item) => {
        return {
          line: item.line,
          profit: parseFloat(item.net_profit).toFixed(2),
        };
      });

      setUpToDateData({
        data: upToDateData,
        barChart: barChart,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUpToDateData(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingTodayData(true);
      setLoadingUpToDateData(true);
      try {
        // start_date and end_date are the same
        if (moment().isSame(moment(), "day")) {
          const data = await getDataForReport(
            moment().format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          );

          const todayBarChart = data?.map((item) => {
            return {
              line: item.line,
              profit: parseFloat(item.net_profit).toFixed(2),
            };
          });

          setTodayData({
            data: data,
            barChart: todayBarChart,
          });
        }

        // in start_date and end_date are current month
        const startDateOfCurrentMonth = moment().startOf("month");
        const endDateOfCurrentMonth = moment();

        const data = await getDataForReport(
          startDateOfCurrentMonth.format("YYYY-MM-DD"),
          endDateOfCurrentMonth.format("YYYY-MM-DD"),
        );

        // UpToDate Data
        const upToDateBarChart = data?.map((item) => {
          return {
            line: item.line,
            profit: parseFloat(item.net_profit).toFixed(2),
          };
        });

        setUpToDateData({
          data: data,
          barChart: upToDateBarChart,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingTodayData(false);
        setLoadingUpToDateData(false);
      }
    };

    fetchData();
    form.setFieldsValue({
      start_date: moment(),
      end_date: moment(),
    });
  }, []);

  return (
    <Card
      title={`Performance Folding | ${
        startDateWatch && endDateWatch
          ? `(${moment(startDateWatch).format("DD-MMM-YY")} to ${moment(
              endDateWatch,
            ).format("DD-MMM-YY")})`
          : ""
      }`}
    >
      <Row>
        <Col span={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFilter}
            onReset={onFilter}
          >
            <Row gutter={24} align={"middle"}>
              <Col span={3}>
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select start date",
                    },
                    {
                      validator: async (_, value) => {
                        if (value && value > endDateWatch) {
                          return Promise.reject(
                            new Error("Start date must be less than today"),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <DatePicker
                    allowClear={false}
                    disabledDate={(current) => {
                      if (!current) {
                        return false;
                      }

                      return current > moment();
                    }}
                    showTime={{ format: "DD-MMM-YY" }}
                    format="DD-MMM-YY"
                    style={{ width: "100%" }}
                    defaultValue={moment()}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  label="End Date"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select start date",
                    },
                    {
                      validator: async (_, value) => {
                        if (value && value < startDateWatch) {
                          return Promise.reject(
                            new Error(
                              "End date must be greater than start date",
                            ),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    allowClear={false}
                    disabledDate={(current) => {
                      if (!current) {
                        return false;
                      }
                      const endOfMonth = moment(startDateWatch).endOf("month");
                      const startOfMonth =
                        moment(startDateWatch).startOf("month");
                      return current >= endOfMonth || current <= startOfMonth;
                    }}
                    showTime={{ format: "DD-MMM-YY" }}
                    format="DD-MMM-YY"
                    defaultValue={moment()}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Space>
                  <Button
                    type="primary"
                    htmlType="reset"
                    danger
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Filter
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <DailyProfitLossStatement
            todayData={todayData}
            loading={loadingTodayData}
            endDateWatch={endDateWatch}
          />
        </Col>
        <Col span={24}>
          <CumulativeProfitLossStatement
            todayData={todayData}
            loading={loadingTodayData}
            startDateWatch={startDateWatch}
            endDateWatch={endDateWatch}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default PerformanceFolding;
