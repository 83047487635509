import { useEffect, useState } from "react";
import { Col, Form, Row, Select } from "antd";
import {
  OT_SIGNAL_LIST_ENDPOINT,
  OT_SIGNAL_SECTION_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const OTSignalFilterFields = ({ initialTableData }) => {
  // States
  const [signal, setSignal] = useState([]);
  const [signalSection, setSignalSection] = useState([]);

  // Antd
  const { Option } = Select;

  // Effects
  useEffect(() => {
    // Get Signal Section office, sales contract and buyer list at first load
    getSignalList();
    getSignalSectionList();
  }, []);

  const getSignalList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(OT_SIGNAL_LIST_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSignal(response?.data?.data?.data || []);
    }
  };

  const getSignalSectionList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(OT_SIGNAL_SECTION_LIST_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSignalSection(response?.data?.data?.data || []);
    }
  };

  console.log('signalSection', signalSection);
  

  return (
    <>
      <Col span={4}>
        <Form.Item label="OT Signal" name="signal_id">
          <Select
            showSearch
            placeholder="Select an OT Signal"
            onSearch={(value) => {
              getSignalList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {isArrayAndHasValue(signal) &&
              signal.map((item) => (
                <Option value={item?.id} key={item?.id}>
                  {item?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="OT Signal Section" name="signal_section_id">
          <Select
            showSearch
            placeholder="Select an OT Signal Section"
            onSearch={(value) => {
              getSignalSectionList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {isArrayAndHasValue(signalSection) &&
              signalSection.map((item) => (
                <Option value={item?.id} key={item?.id}>
                  {item?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default OTSignalFilterFields;
