import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import CustomDatePicker from "common/CustomDatePicker";
import { CuttingContext } from "context/CuttingContext/CuttingContextProvider";
import moment from "moment";
import { useContext, useEffect } from "react";
import { commaSeparateNumber } from "utils/functions";

const { Option } = Select;

const CuttingForm = (props) => {
  // Props
  const { form, isUpdate, formSubmitting } = props;

  // Contexts
  const {
    styleList,
    getCuttingTableData,
    onSelectStyle,
    getStyleList,
    selectedStyleDetails,
    showFactoryInputs,
    subContractStatus,
  } = useContext(CuttingContext);

  // Antd
  const cutting_date_watch = Form.useWatch("cutting_date", form);

  useEffect(() => {
    // Set form values after selecting style from dropdown
    if (selectedStyleDetails) {
      form.setFieldsValue({
        buying_office_id: selectedStyleDetails?.buying_office_info?.id,
        buying_office_name: selectedStyleDetails?.buying_office_info?.name,
        buyer_id: selectedStyleDetails?.buyer_info?.id,
        buyer_name: selectedStyleDetails?.buyer_info?.name,
        factory_id: selectedStyleDetails?.factory_info?.id,
        factory_name: selectedStyleDetails?.factory_info?.name,
        address: selectedStyleDetails?.factory_info?.address,
        sales_contract_id:
          selectedStyleDetails?.active_sales_contract_map_info
            ?.active_sales_contract_info?.id,
        sales_contract_name:
          selectedStyleDetails?.active_sales_contract_map_info
            ?.active_sales_contract_info?.reference_no,
        order_quantity:
          selectedStyleDetails?.active_sales_contract_map_info?.order_quantity,
        use_both_style_po:
          selectedStyleDetails?.active_sales_contract_map_info
            ?.active_sales_contract_info?.use_both_style_po,
      });
    }
  }, [selectedStyleDetails, form]);

  useEffect(() => {
    // Call API to get cutting table data
    if (selectedStyleDetails && cutting_date_watch) {
      getCuttingTableData({
        style_id: selectedStyleDetails?.id,
        sales_contract_id:
          selectedStyleDetails?.active_sales_contract_map_info
            ?.active_sales_contract_info?.id,
        buying_office_id: selectedStyleDetails?.buying_office_info?.id,
        cutting_date: cutting_date_watch,
      });
    }
  }, [selectedStyleDetails, cutting_date_watch, getCuttingTableData]);

  return (
    <>
      <Row gutter={8}>
        <Col span={4}>
          <Form.Item name="use_both_style_po" hidden />
          <Form.Item
            label="Style No"
            name="style_id"
            rules={[
              {
                required: true,
                message: `Style is required `,
              },
            ]}
          >
            <Select
              onSearch={(value) => getStyleList({ style_no: value })}
              onSelect={(value) => {
                onSelectStyle(value);
                formSubmitting(false);
              }}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              allowClear
              onClear={() => getStyleList()}
              disabled={isUpdate}
            >
              {styleList?.map((style, idx) => (
                <Option key={idx} value={style.id} label={style.style_no}>
                  {" "}
                  {style.style_no + " : " + style?.description || "--"}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {!showFactoryInputs ? (
          <>
            <Col span={4}>
              <Form.Item name="buying_office_id" hidden />
              <Form.Item
                name="buying_office_name"
                label="Buying Office"
                rules={[
                  {
                    required: true,
                    message: `Buying Office is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="buyer_id" hidden />
              <Form.Item
                name="buyer_name"
                label="Buyer"
                rules={[
                  {
                    required: true,
                    message: `Buyer is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
            <Col span={4}>
              <Form.Item name="factory_id" hidden />
              <Form.Item
                name="factory_name"
                label="First Party"
                rules={[
                  {
                    required: true,
                    message: `First Party is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: `Address is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={4}>
          <Form.Item name="sales_contract_id" hidden />
          <Form.Item
            name="sales_contract_name"
            label="Sales Contract No. / Sub Contract No."
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="order_quantity"
            label="Order Quantity"
            rules={[
              {
                required: true,
                message: `Order Quantity is required `,
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              disabled={true}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
              size="small"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="cutting_date"
            label="Cutting Date"
            initialValue={moment()}
            rules={[
              {
                required: true,
                message: `Entry Date is required `,
              },
            ]}
          >
            <CustomDatePicker
              style={{
                width: "100%",
              }}
              size="small"
              disabled={isUpdate}
            />
            {/* <DatePicker
              format={"YYYY-MM-DD"}
              style={{
                width: "100%",
              }}
              size="small"
              disabled={isUpdate}
            /> */}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CuttingForm;
