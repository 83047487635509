import React, { useEffect, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { useNavigate, useParams } from "react-router-dom";
import ImportAcceptanceForm from "./ImportAcceptanceForm";
import { ACCEPTANCE_DETAILS_VIEW } from "../../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import { hasPermission } from "../../../../utils/functions";
import {
  IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION,
} from "../../../../routes/permissions";

const AcceptanceDetails = (props) => {
  // Props
  const { isAdd, isEdit, width, view } = props;

  // States
  const [editInfo, setEditInfo] = useState(null);

  // Antd
  const [form] = Form.useForm();

  // Others
  const navigate = useNavigate();
  const { importId } = useParams();

  const getAcceptanceDetails = async (id) => {
    const query = `${ACCEPTANCE_DETAILS_VIEW}/${id}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setEditInfo(response?.data?.data);
    } else {
      HandleNotification("error", "bottomRight", "Error getting data", null);
    }
  };

  useEffect(() => {
    // Get details when edit/view mode
    if (importId) {
      getAcceptanceDetails(importId);
    }
  }, [importId]);

  const onSubmitForm = () => {
    form.submit();
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`${
              isAdd
                ? "Add Import Acceptance & Payment details"
                : `${
                    view ? "View" : "Update"
                  } Import Acceptance & Payment details`
            }`}
            actions={[
              <Affix offsetTop={20}>
                <Button size="small" danger onClick={goBack}>
                  Back
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => onSubmitForm()}
                  disabled={
                    !hasPermission([
                      isAdd
                        ? IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION
                        : IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION,
                    ])
                  }
                  hidden={view}
                >
                  {`${isAdd ? "Add" : "Update"}`}
                </Button>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      <ImportAcceptanceForm
        form={form}
        isEdit={isEdit}
        isAdd={isAdd}
        editInfo={editInfo}
        view={view}
      />
    </>
  );
};

export default AcceptanceDetails;
