import { useEffect, useState } from "react";
import {
  Button,
  message,
  Row,
  Col,
  Form,
  Input,
  Switch,
  Select,
  Modal,
} from "antd";
import { getData, postData, putData } from "../../../../apiServices/common";
import {
  GARMENT_TYPE_ADD_ENDPOINT,
  GARMENT_TYPE_EDIT_ENDPOINT,
} from "../../../../apiServices/API_ENDPOINTS";
import { hasPermission, isArrayAndHasValue } from "../../../../utils/functions";

const GarmentTypeForm = (props) => {
  // Props
  const { isEdit, onCloseModal, refetch, selectedItem } = props;

  // State
  const [loading, setLoading] = useState(false);

  // Antd
  const [typeForm] = Form.useForm();

  useEffect(() => {
    if (selectedItem) {
      typeForm.setFieldsValue({
        id: selectedItem?.id,
        name: selectedItem?.name,
        status: selectedItem?.status ? true : false,
      });
    }
  }, [selectedItem, typeForm]);

  const createNewCategory = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      status: formValues?.status ? 1 : 0,
    };

    const response = await postData(GARMENT_TYPE_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Garment Type Created Successfully");
      refetch();
      typeForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedCategory = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      status: formValues?.status ? 1 : 0,
    };

    const response = await putData(
      `${GARMENT_TYPE_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Garment Type Edited Successfully");
      refetch();
      typeForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  return (
    <Form
      form={typeForm}
      layout="vertical"
      onFinish={isEdit ? editSelectedCategory : createNewCategory}
    >
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item name="id" hidden />
          <Form.Item
            name="name"
            placeholder="Name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name Required",
              },
            ]}
          >
            <Input size="small" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            placeholder="Status"
            label="Status"
            rules={[
              {
                required: true,
                message: "Status Required",
              },
            ]}
            valuePropName="checked"
            initialValue={true}
          >
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Button
            type="danger"
            style={{ width: "100%" }}
            onClick={() => {
              onCloseModal();
            }}
            size="small"
          >
            Discard
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
            size="small"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default GarmentTypeForm;
