import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
    DatePicker,
    TimePicker,
    Divider,
    Space,
    InputNumber,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    INVOICES_LIST_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const TAndAInvoices = () => {
  
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // Extract `scid` from the query string
  const queryParams = new URLSearchParams(location.search);
  const scid = queryParams.get("scid");

  // console.log("scid", scid);

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);


  // Antd
  const [visit_dateForm] = Form.useForm();
  const { Title } = Typography;

  // // Redux
  // const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllTAndAInvoices = useCallback(
    async (filterValues) => {
      const payload = {
        page: filterValues?.page || currentPage,
        scid: scid, // Include scid in the payload
        ...filterValues,
      };

      setLoading(true);

      const query = `${INVOICES_LIST_ENDPOINT}`;
      const bodyData = {
        ...payload,
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting TNA Invoice List data");
      }
    },
    [currentPage],
  );
  

  // console.log("Line List dekhi", salesContractList);

  // Effects
  useEffect(() => {
    getAllTAndAInvoices();
  }, [refresh, getAllTAndAInvoices]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    visit_dateForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Invoice Number",
      dataIndex: ["invoice_number"],
      key: "invoice_number",
    },
    {
      title: "Invoice Value",
      dataIndex: ["invoice_value"],
      key: "invoice_value",
    },
    {
      title: "Quantity CTN",
      dataIndex: ["quantity_ctn"],
      key: "quantity_ctn",
    },
    {
      title: "Import Quantity Unit",
      dataIndex: ["import_quantity_unit"],
      key: "import_quantity_unit",
    },
    {
      title: "Quantity YDS",
      dataIndex: ["quantity_yds"],
      key: "quantity_yds",
    },
    {
      title: "Invoice Quantity Unit",
      dataIndex: ["invoice_quantity_unit"],
      key: "invoice_quantity_unit",
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   width: "10%",
    //   render: (_, record) => {
    //     return (
    //       <Dropdown.Button
    //         overlay={
    //           <Menu
    //             items={[
    //               {
    //                 label: (
    //                   <Popconfirm
    //                     title="Are you sure you want to delete?"
    //                     onConfirm={() => {
    //                       deleteSelectedvisit_date(record?.id);
    //                     }}
    //                   >
    //                     <span>
    //                       <DeleteTwoTone />
    //                     </span>
    //                     <span
    //                       style={{
    //                         color: "red",
    //                         marginLeft: 5,
    //                       }}
    //                     >
    //                       Delete
    //                     </span>
    //                   </Popconfirm>
    //                 ),
    //                 key: "1",
                    
    //               },
    //               {
    //                 label: (
    //                   <>
    //                     <span>
    //                       <EditTwoTone />
    //                     </span>
    //                     <span
    //                       type="button"
    //                       style={{ marginLeft: 5 }}
    //                       onClick={() => {
    //                         openModalForEdit(record);
    //                       }}
    //                     >
    //                       Edit
    //                     </span>
    //                   </>
    //                 ),
    //                 key: "2",
                    
    //               },
    //             ]}
    //           />
    //         }
    //       ></Dropdown.Button>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            // extra={
            //   <ExtraContent
            //     refresh={refresh}
            //     setRefresh={setRefresh}
            //     handleAddItemOpen={onModalOpen}
                
            //     addButtonText="Add New"
            //     showFilter={showFilter}
            //     setShowFilter={setShowFilter}
            //   />
            // }
            title={<Title level={5}>TNA Invoice List</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllTAndAInvoices}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="visit_date"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default TAndAInvoices;
