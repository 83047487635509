import { useEffect, useState } from "react";
import { Col, Form, Input, message, Row, Select } from "antd";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";
import {
  ERP_USAGE_MODULE_LIST_ENDPOINT,
  GET_USERS_ENDPOINT,
  POST_TNA_DEPARTMENT_DATA,
} from "../../../apiServices/API_ENDPOINTS";

const ERPUsageModuleUiFilterFields = ({ initialTableData }) => {
  // States
  const [erpModuleList, setErpModuleList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [tnaDepartmentList, setTnaDepartmentList] = useState([]);

  const [loading, setLoading] = useState(false);

  // Antd
  const { Option } = Select;

  const getAllErpUsageModules = async (filterValues) => {
    setLoading(true);

    const query = `${ERP_USAGE_MODULE_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("erpUsageModules", response?.data?.data?.data);
      setErpModuleList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  const getAllUsers = async (filterValues) => {
    setLoading(true);

    const query = `${GET_USERS_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("All Users", response?.data?.data?.data);
      setUserList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  const getAllTnaDepartments = async (filterValues) => {
    setLoading(true);

    const query = `${POST_TNA_DEPARTMENT_DATA}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("tna_departments", response?.data?.data?.data);
      setTnaDepartmentList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  useEffect(() => {
    getAllErpUsageModules();
    getAllUsers();
    getAllTnaDepartments();
  }, []);
  

  

  return (
    <>
      <Col span={4}>
        <Form.Item label="Title" name="name">
          <Input placeholder="Please enter Title" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          name="module_id"
          placeholder="Select Module"
          label="Select Module"
          rules={[
            {
              required: false,
              message: "Module is Required",
            },
          ]}
        >
          <Select
            placeholder="Select Module"
            showSearch
            optionFilterProp="children"
            style={{width: "100%"}}
            onSearch={(value) => getAllErpUsageModules({module_name: value})}
            size="small"
          >
            {erpModuleList?.map((item) => (
              <Option key={item.id} value={item.id}>{item?.module_name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {(localStorage.getItem('user_type') === 'Vendor' || localStorage.getItem('user_type') === 'Admin') && (
        <>
        <Col span={4}>
          <Form.Item
            name="user_id"
            placeholder="Select POC"
            label="Select POC"
            rules={[
              {
                required: false,
                message: "POC is Required",
              },
            ]}
          >
            <Select
              placeholder="Select POC"
              showSearch
              optionFilterProp="children"
              style={{width: "100%"}}
              onSearch={(value) => getAllUsers({name: value})}
              size="small"
            >
              {userList?.map((item) => (
                <Option key={item.id} value={item.id}>{item?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="tna_department_id"
            placeholder="Select Department"
            label="Select Department"
            rules={[
              {
                required: false,
                message: "TNA Department is Required",
              },
            ]}
          >
            <Select
              placeholder="Select Department"
              showSearch
              optionFilterProp="children"
              style={{width: "100%"}}
              onSearch={(value) => getAllTnaDepartments({title: value})}
              size="small"
            >
              {tnaDepartmentList?.map((item) => (
                <Option key={item.id} value={item.id}>{item?.title}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        </>
      )}
      <Col span={4}>
        <Form.Item label="Status" name="status">
          <Select
            placeholder="Select a Status"
            showSearch
            optionFilterProp="children"
            style={{width: "100%"}}
            size="small"
          >
            <Option value="Created">Created</Option>
            <Option value="Halt">Halt</Option>
            <Option value="In Use">In Use</Option>
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default ERPUsageModuleUiFilterFields;
