import {
  Button,
  Col,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  DELETE_COST_SHEET,
  EXPORT_COST_SHEET,
  GET_COST_SHEET,
  SALES_CONTRACT_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  COST_SHEET_REPORT_DELETE_PERMISSION,
  COST_SHEET_REPORT_GENERATE_PERMISSION,
  COST_SHEET_REPORT_VIEW_PERMISSION,
} from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";

const CostSheet = () => {
  // States
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [salesContractList, setSalesContractList] = useState(null);
  const [stylesList, setStylesList] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  // Antd
  const [costSheetform] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/cost_sheet/")[1] : "";
    return updatedPath;
  };

  const getSalesContract = useCallback(async (value) => {
    setLoading(true);

    let query = `${SALES_CONTRACT_LIST}`;
    if (value) query = `${SALES_CONTRACT_LIST}?sales_contract_id=${value}`;
    const response = await getData(query);

    if (response && response?.status === 200) {
      setLoading(false);
      setSalesContractList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting sales contract data");
    }
  }, []);

  const getStyles = async (salesContractId) => {
    setLoading(true);

    const query = `${SALES_CONTRACT_LIST}/${salesContractId}/edit`;
    const response = await getData(query);

    if (response && response?.status === 200) {
      setLoading(false);
      setStylesList(response?.data?.data?.styleDetail);
    } else {
      setLoading(false);
      message.error("Error getting styles data");
    }
  };

  const getAllCostSheet = useCallback(async () => {
    setLoading(true);

    const query = `${GET_COST_SHEET}`;
    const response = await getData(query);

    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting cost sheet data");
    }
  }, []);

  const exportCostSheet = async () => {
    const formValues = costSheetform.getFieldsValue();
    const {
      sales_contract_id,
      style_id,
      cm_cost,
      commercial_cost,
      buying_comm,
    } = formValues;
    const bodyData = {
      sales_contract_id: sales_contract_id,
      style_id: style_id,
      cm_cost: Number(cm_cost),
      commercial_cost: Number(commercial_cost),
      buying_comm: Number(buying_comm),
    };

    setLoading(true);
    const query = `${EXPORT_COST_SHEET}`;
    const response = await getData(query, false, bodyData);

    if (response && response?.data) {
      setLoading(false);
      message.success("Cost Sheet Exported Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  // Effects
  useEffect(() => {
    getAllCostSheet();
  }, [refresh, getAllCostSheet]);

  useEffect(() => {
    getSalesContract();
  }, [getSalesContract]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedCostSheet = async (name) => {
    setLoading(true);
    const updatedName = cutPath(name);
    const query = `${DELETE_COST_SHEET}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setLoading(false);
      message.success("Cost Sheet Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/cost_sheet/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  const tableColumns = [
    {
      title: "File Name",
      key: "bin_name",
      width: "90%",
      render: (_, record) => {
        console.log("record", record);

        return <div>{cutPath(record)}</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Space>
            <Button
              size="small"
              onClick={() => openFile(cutPath(record))}
              disabled={!hasPermission([COST_SHEET_REPORT_VIEW_PERMISSION])}
            >
              {hasPermission([COST_SHEET_REPORT_VIEW_PERMISSION])
                ? "Download"
                : "No Permission"}
            </Button>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                deleteSelectedCostSheet(record);
              }}
            >
              <Button
                type="primary"
                danger
                size="small"
                disabled={!hasPermission([COST_SHEET_REPORT_DELETE_PERMISSION])}
              >
                {hasPermission([COST_SHEET_REPORT_DELETE_PERMISSION])
                  ? "Delete"
                  : "No Permission"}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportCostSheet}
                addButtonText={
                  hasPermission([COST_SHEET_REPORT_GENERATE_PERMISSION])
                    ? "Export"
                    : "No Permission"
                }
                disabled={
                  !hasPermission([COST_SHEET_REPORT_GENERATE_PERMISSION])
                }
                hideFilterButton
              />
            }
            title={<Title level={5}>Cost Sheet</Title>}
          />
        }
        content={
          <div style={{ paddingTop: 10 }}>
            <Form layout="vertical" form={costSheetform}>
              <Row gutter={6}>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Sales Contract"
                    name="sales_contract_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Sales Contract!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Sales Contract"
                      onChange={(value) => {
                        getStyles(value);
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="small"
                      onSearch={getSalesContract}
                    >
                      {salesContractList?.length &&
                        salesContractList.map((item, index) => (
                          <Option value={item.id} key={index}>
                            {item.reference_no}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Style"
                    name="style_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Style!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Style"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={stylesList?.length > 0 ? false : true}
                      size="small"
                    >
                      {stylesList?.length &&
                        stylesList.map((item, index) => (
                          <Option value={item?.style_info?.id} key={index}>
                            {item?.style_info?.style_no}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="CM Cost"
                    name="cm_cost"
                    rules={[
                      {
                        required: true,
                        message: "Please Select CM Cost!",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="CM Cost"
                      precision={2}
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Commercial Cost (%)"
                    name="commercial_cost"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Commercial Cost!",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Commercial Cost"
                      precision={2}
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Buying Comm (%)"
                    name="buying_comm"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Buying Comm!",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Buying Comm"
                      precision={2}
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="table" style={{ marginTop: 10 }}>
              {/* <FilterComponent
                tableData={tableData}
                filterAPI={getAllCostSheet}
                filterName={"fabric_reconcil"}
                searchButtonText={"Export"}
                defaultOpen
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              /> */}
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default CostSheet;
