import React, {useEffect} from "react";
import AppCreateFormLayOut from "../../../common/CreateFormLayout";
import BuyingOfficeCreateForm from "./BuyingOfficeCreateForm";
import {useLocation, useParams} from "react-router-dom";
import BankAccountInformation from "../../BankAccount";
import {useDispatch, useSelector} from "react-redux";
import Merchandiser from "../../Merchandiser";
import { onGetBuyingOfficeDetails } from "../../../redux/actions";

const CreateOrEditBuyingOffice = (props) => {
    const { isAdd, isEdit } = props;
    const location = useLocation();
    const merchandiserList = useSelector(({merchandiser}) => merchandiser.merchandiserList);
    const selectedBuyingOffice = useSelector(({buyingOffice}) => buyingOffice?.detailsBuyingOffice?.data);
    const dispatch = useDispatch();
    const { buyingOfficeId } = useParams();

    useEffect(() => {
        if(buyingOfficeId || buyingOfficeId === 0) {
            dispatch(onGetBuyingOfficeDetails(Number(buyingOfficeId)));
        }
    }, [buyingOfficeId, dispatch]);

    const createComponent = (
      <div style={{ padding: 5 }}>
        <BankAccountInformation selectedBuyingOffice={isEdit ? selectedBuyingOffice : null} />
      </div>
    );
    const createComponent2 = (
      <div style={{ padding: 5 }}>
        <Merchandiser
          selectedBuyingOffice={isEdit ? selectedBuyingOffice : null}
          merchandiserList={merchandiserList}
        />
      </div>
    );

    const contentForm = <div style={{backgroundColor: '#ffffff'}}>
        <BuyingOfficeCreateForm selectedBuyingOffice={isEdit ? selectedBuyingOffice : null} />
    </div>

    return <AppCreateFormLayOut
        CreateComponentForm2={createComponent2}
        btnTitle="Add Bank Account"
        CreateComponentForm={createComponent}
        contentForm={contentForm} >
    </AppCreateFormLayOut>

}

export default CreateOrEditBuyingOffice;