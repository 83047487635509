import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AMENDMENT_DOC_ADD,
  GET_BUYING_OFFICE_ENDPOINT,
  PACKING_TYPES,
  PO_TYPES,
  SUB_CONTRACT_AGREEMENT,
  SUB_CONTRACT_FACTORY,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "../../../apiServices/common";
import { alertPop } from "../../../apiServices/common/helper";
import HandleNotification from "../../../common/Notification";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
  showTotalPrice,
} from "../../../utils/functions";
import DocumentUpload from "../../Common/DocumentUpload";
import POList from "./PoList";
import StyleDetailsInitial from "./StyleDetails";
import CustomDatePicker from "common/CustomDatePicker";

const { Option } = Select;

const AddSalesContactForm = forwardRef((props, ref) => {
  const { permitInfo, view, isAmend, isEdit } = props;
  const [form] = Form.useForm();
  const contract_date = Form.useWatch("contract_date", form);
  const first_party = Form.useWatch("first_party", form);
  const [selectedFactoryName, setSelectedFactoryName] = useState();
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [filesList, setFilesList] = useState([]);

  const [styleList, setStyleList] = useState([]);

  const [salesContractID, setSalesContractID] = useState();
  const [poList, setPoList] = useState([]);
  const [selectedStyles, setSelectedStyle] = useState([]);
  const [totalStyleOrderQty, setTotalStyleOrderQty] = useState(0);
  const [totalStyleOrderValue, setTotalStyleOrderValue] = useState(0);
  const [subcontractStatusOption, setSubcontractStatusOption] = useState([]);
  const [poTypes, setPoTypes] = useState([]);
  const [packingTypes, setPackingTypes] = useState([]);

  const navigate = useNavigate();
  const { styleId } = useParams();

  var buying_accounts = [];
  var org_accounts = "";
  var organization_id = [];
  var documents = [];
  var style_id = [];
  var style_detail_id = [];
  var pcd = [];
  var style_order_quantity = [];
  var style_unit_price = [];
  var style_description = [];
  var internal_po = [];
  var purchase_detail_id = [];
  var original_po = [];
  var delivery_date = [];
  var po_status = [];
  var p_o_type_id = [];
  var packing_type_id = [];
  var departments = [];

  useImperativeHandle(ref, () => ({
    submit() {
      form
        .validateFields()
        .then(async (values) => {
          if (!isArrayAndHasValue(selectedStyles)) {
            HandleNotification(
              "error",
              "bottomRight",
              "Please add a style",
              null,
            );
            return null;
          }
          if (isArrayAndHasValue(filesList)) {
            filesList.forEach((item) => {
              documents.push({
                document_id: item?.id,
                is_delete: item?.is_delete || 0,
                amendment_id:
                  permitInfo && isArrayAndHasValue(permitInfo?.amendments)
                    ? permitInfo.amendments[permitInfo.amendments.length - 1][
                        "id"
                      ]
                    : 0,
              });
            });
          } else {
            if (!permitInfo?.sales_contract?.id) {
              HandleNotification(
                "error",
                "bottomRight",
                "Please add document!",
                null,
              );
              return null;
            }
          }

          if (selectedStyles?.length > 0) {
            selectedStyles.forEach((item) => {
              if (item?.style_id) {
                style_detail_id.push(item?.id);
                style_id.push(item.style_id);
              } else {
                style_id.push(item.id);
              }
              if (item.order_quantity) {
                style_order_quantity.push(item.order_quantity);
              } else {
                style_order_quantity.push(item.quantity);
              }
              if (item.unit_price) {
                style_unit_price.push(item.unit_price);
              } else {
                style_unit_price.push(item.price);
              }
              pcd.push(item.pcd);
              style_description.push(item.description);
            });
          } else {
            return alertPop("error", "Please Enter Style Info.");
          }
          // if (values.use_both_style_po) {
          original_po = [];
          if (isArrayAndHasValue(poList)) {
            poList.forEach((item) => {
              original_po.push(item?.original_po);
              delivery_date.push(item?.delivery_date);
              internal_po.push(item?.internal_po);
              po_status.push("Created");
              p_o_type_id.push(item?.p_o_type_id || null);
              packing_type_id.push(item?.packing_type_id || null);
              departments.push(item?.department || null);
            });
          } else {
            return alertPop("error", "Please Enter PO.");
          }
          // }
          if (permitInfo?.sales_contract?.id) {
            poList.forEach((item) => {
              purchase_detail_id.push(item.id);
            });

            if (pcd?.some((item) => item === null || item === undefined)) {
              return alertPop("error", "Please Enter PCD Date.");
            }

            if (
              style_order_quantity?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              return alertPop("error", "Please Enter Order Quantity.");
            }

            if (
              style_unit_price?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              return alertPop("error", "Please Enter Unit Price.");
            }

            values.id = permitInfo?.sales_contract.id;
            values["documents"] = documents;
            values["buying_account_id"] = buying_accounts;
            values["style_id"] = style_id;
            values["style_detail_id"] = style_detail_id;
            values["pcd"] = pcd;
            values["style_order_quantity"] = style_order_quantity;
            values["style_unit_price"] = style_unit_price;
            values["style_description"] = style_description;
            values["organization_account_id"] = org_accounts;
            values["organization_id"] = organization_id;
            values["original_po"] = original_po;
            values["internal_po"] = internal_po;
            values["purchase_detail_id"] = purchase_detail_id;
            values["po_status"] = po_status;
            values["address"] = "address";
            values["delivery_date"] = delivery_date;
            values["p_o_type_id"] = p_o_type_id;
            values["packing_type_id"] = packing_type_id;
            values["department"] = departments;

            const amendmentObject = {
              ...(isAmend && { reason_ids: values?.reasons || null }),
              ...(isArrayAndHasValue(permitInfo.amendments) && {
                id:
                  permitInfo.amendments[permitInfo.amendments.length - 1][
                    "id"
                  ] || null,
              }),
              total_style_order_quantity: totalStyleOrderQty,
              total_style_order_value: totalStyleOrderValue,
              document_ids: values?.documents?.map((item) => item?.document_id),
            };

            if (
              style_order_quantity?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              return alertPop("error", "Please Enter Order Quantity.");
            }

            if (
              style_unit_price?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              return alertPop("error", "Please Enter Unit Price.");
            }

            values["documents"] = documents;
            values["buying_account_id"] = buying_accounts;
            values["style_id"] = style_id;
            values["style_order_quantity"] = style_order_quantity;
            values["pcd"] = pcd;
            values["style_unit_price"] = style_unit_price;
            values["style_description"] = style_description;
            values["organization_account_id"] = org_accounts;
            values["organization_id"] = organization_id;
            values["original_po"] = original_po;
            values["internal_po"] = internal_po;
            values["po_status"] = po_status;
            values["address"] = "address";
            values["delivery_date"] = delivery_date;
            values.status = "1";

            const body = {
              ...values,
              contract_date: moment(values.contract_date).format("YYYY-MM-DD"),
              ud_date: moment(values.ud_date).format("YYYY-MM-DD"),
              date_of_return_to_party: moment(
                values.date_of_return_to_party,
              ).format("YYYY-MM-DD"),
              expiry_date: moment(values.expiry_date).format("YYYY-MM-DD"),
              licence_date: moment(values.licence_date).format("YYYY-MM-DD"),
            };

            const response = styleId
              ? await putData(`${SUB_CONTRACT_AGREEMENT}/${styleId}`, body)
              : await postData(SUB_CONTRACT_AGREEMENT, body);

            if (response && response?.code === 200) {
              alertPop("success", "Sales Contract Created Successfully");
              navigate("/merchandising/sub-sales-contact");
            }
          } else {
            poList.forEach((item) => {
              purchase_detail_id.push(item.id);
            });

            if (pcd?.some((item) => item === null || item === undefined)) {
              return alertPop("error", "Please Enter PCD Date.");
            }

            if (
              style_order_quantity?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              return alertPop("error", "Please Enter Order Quantity.");
            }

            if (
              style_unit_price?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              return alertPop("error", "Please Enter Unit Price.");
            }

            values["documents"] = documents;
            values["buying_account_id"] = buying_accounts;
            values["style_id"] = style_id;
            values["style_detail_id"] = style_detail_id;
            values["pcd"] = pcd;
            values["style_order_quantity"] = style_order_quantity;
            values["style_unit_price"] = style_unit_price;
            values["style_description"] = style_description;
            values["organization_account_id"] = org_accounts;
            values["organization_id"] = organization_id;
            values["original_po"] = original_po;
            values["internal_po"] = internal_po;
            values["purchase_detail_id"] = purchase_detail_id;
            values["po_status"] = po_status;
            values["address"] = "address";
            values["delivery_date"] = delivery_date;

            if (
              style_order_quantity?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              return alertPop("error", "Please Enter Order Quantity.");
            }

            if (
              style_unit_price?.some(
                (item) => item === null || item === undefined || item === 0,
              )
            ) {
              return alertPop("error", "Please Enter Unit Price.");
            }

            values["documents"] = documents;
            values["buying_account_id"] = buying_accounts;
            values["style_id"] = style_id;
            values["style_order_quantity"] = style_order_quantity;
            values["pcd"] = pcd;
            values["style_unit_price"] = style_unit_price;
            values["style_description"] = style_description;
            values["organization_account_id"] = org_accounts;
            values["organization_id"] = organization_id;
            values["original_po"] = original_po;
            values["internal_po"] = internal_po;
            values["po_status"] = po_status;
            values["address"] = "address";
            values["delivery_date"] = delivery_date;
            values.status = "1";

            const body = {
              ...values,
              contract_date: moment(values.contract_date).format("YYYY-MM-DD"),
              ud_date: moment(values.ud_date).format("YYYY-MM-DD"),
              date_of_return_to_party: moment(
                values.date_of_return_to_party,
              ).format("YYYY-MM-DD"),
              expiry_date: moment(values.expiry_date).format("YYYY-MM-DD"),
              licence_date: moment(values.licence_date).format("YYYY-MM-DD"),
            };

            const response = styleId
              ? await putData(`${SUB_CONTRACT_AGREEMENT}/${styleId}`, body)
              : await postData(SUB_CONTRACT_AGREEMENT, body);

            if (response && response?.code === 200) {
              alertPop("success", "Sales Contract Created Successfully");
              navigate("/merchandising/sub-sales-contact");
            }
          }
        })
        .catch((errorInfo) => {
          // console.log("errorInfo", errorInfo);
        });
    },
    discart() {
      form.resetFields();
    },
  }));

  useEffect(() => {
    if (isArrayAndHasValue(selectedStyles)) {
      // Calculate and set total style quantity
      const totalStyleQty = selectedStyles?.reduce(
        (acc, currentValue) =>
          acc + parseFloat(currentValue["order_quantity"] || 0),
        0,
      );
      totalStyleQty && setTotalStyleOrderQty(totalStyleQty);

      // Calculate and set total style value
      const totalStyleValue = selectedStyles?.reduce(
        (acc, currentValue) => acc + parseFloat(showTotalPrice(currentValue)),
        0,
      );

      if (totalStyleValue) {
        setTotalStyleOrderValue(totalStyleValue);
        form.setFieldValue("contract_value", totalStyleValue);
        form.setFieldValue("quantity", totalStyleQty);
      }
    }
  }, [selectedStyles, form]);

  const getBuyingOfficeList = async (filterValues) => {
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: filterValues?.per_page || 10,
    };
    const response = await getData(GET_BUYING_OFFICE_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOffice(response?.data?.data?.data || []);
    }
  };

  const getSubcontractStatusOption = async (filterValues) => {
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: 10,
    };
    const response = await getData(
      `/api/sub-contract-factory`,
      false,
      bodyData,
    );

    if (response && response?.data?.code === 200) {
      setSubcontractStatusOption(response?.data?.data?.data);
    }
  };

  const getStyleList = async (id) => {
    const response = await getData(`${SUB_CONTRACT_FACTORY}/${id}/show`, false);

    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data?.style_info);
    }
  };

  const confirmChangeStatus = (val) => {
    Modal.confirm({
      title: "Warning!",
      content: (
        <div>
          <p>You are going to change Contract status!</p>
        </div>
      ),
      onOk() {
        // form.resetFields(["status"]);
      },
      onCancel() {
        form.resetFields(["status"]);
      },
    });
  };

  useEffect(() => {
    getBuyingOfficeList();
    getSubcontractStatusOption();
    getPOTypes();
    getPackingTypes();
  }, []);

  useEffect(() => {
    // styleList first_party

    const findFactoryName = subcontractStatusOption?.find(
      (v) => v.id === first_party,
    );

    if (first_party && contract_date && findFactoryName) {
      setSalesContractID(
        findFactoryName?.name +
          "_" +
          moment(contract_date).format("DD-MM-YYYY"),
      );
    }

    form.setFieldsValue({
      sales_contract_unique_id: salesContractID,
    });
  });

  useEffect(() => {
    if (permitInfo?.sales_contract?.id) {
      if (
        !buyingOffice?.some(
          (item) => item?.id === permitInfo?.sales_contract?.buying_office_id,
        )
      ) {
        const clone = [
          permitInfo?.sales_contract?.buying_office_info,
          ...buyingOffice,
        ];

        setBuyingOffice(clone);
      }

      if (
        !buyer?.some(
          (item) => item?.id === permitInfo?.sales_contract?.buyer_id,
        )
      ) {
        setBuyer((oldarr) => [
          ...oldarr,
          {
            id: permitInfo?.sales_contract?.buyer_id,
            name: permitInfo?.sales_contract?.buyer_name,
          },
        ]);
      }
      getStyleList(permitInfo?.sales_contract?.first_party);
      console.log(
        "sales_contract",
        permitInfo?.sales_contract?.use_both_style_po,
      );

      form.setFieldsValue({
        reference_no: permitInfo?.sales_contract?.reference_no,
        contract_date: moment(permitInfo?.sales_contract?.contract_date),
        first_party: permitInfo?.sales_contract?.first_party,
        address: permitInfo?.sales_contract?.factory_info?.address,
        quantity: permitInfo?.sales_contract?.quantity,
        contract_value: permitInfo?.sales_contract?.contract_value,
        bond_licence_no: permitInfo?.sales_contract?.bond_licence_no,
        licence_date: moment(permitInfo?.sales_contract?.licence_date),
        export_lc_sales_contact_no:
          permitInfo?.sales_contract?.export_lc_sales_contact_no,
        ud_no: permitInfo?.sales_contract?.ud_no,
        ud_date: moment(permitInfo?.sales_contract?.ud_date),
        date_of_return_to_party: moment(
          permitInfo?.sales_contract?.date_of_return_to_party,
        ),
        expiry_date: moment(permitInfo?.sales_contract?.expiry_date),
        status: parseInt(permitInfo?.sales_contract?.status),
        use_both_style_po: permitInfo?.sales_contract?.use_both_style_po,
      });
      setSalesContractID(permitInfo?.sales_contract?.sales_contract_unique_id);

      setSelectedStyle(permitInfo?.styleDetail);
      setFilesList(
        permitInfo?.documents?.map((item) => {
          return {
            id: item?.id,
            name: item?.document_name,
            document: item?.document,
            path: item?.document,
            is_delete: item?.is_delete,
          };
        }),
      );

      setPoList(permitInfo?.purchase_orders);
      if (permitInfo?.purchase_orders.length > 0) {
        let po_length = permitInfo?.purchase_orders?.length;
        let count = 0;
        permitInfo?.purchase_orders.map((item) => {
          if (item?.po_status == "Closed") {
            count = count + 1;
          }
        });
        // if (po_length === count) {
        //   setEditableSetContractStatus(false);
        // }
      }

      // setSelectedBuyerOffice(
      //   permitInfo.sales_contract?.organization_account_info?.id
      // );

      var test2 = permitInfo?.sales_contract?.organization_account_info
        ? permitInfo?.sales_contract?.organization_account_info?.bank_info
        : null;

      // test2["account_no"] = permitInfo?.sales_contract
      //   ?.organization_account_info
      //   ? permitInfo?.sales_contract?.organization_account_info?.account_number
      //   : null;

      // test2["organization_account_id"] =
      //   permitInfo?.sales_contract?.organization_account_id;
      // test2["address"] =
      //   permitInfo?.sales_contract?.organization_account_info?.bank_info?.name +
      //   " - " +
      //   permitInfo?.sales_contract?.organization_account_info?.branch_info
      //     ?.address;

      var test = permitInfo?.buying_office?.buying_office_account_info
        ? permitInfo?.buying_office?.buying_office_account_info?.bank_info
        : null;

      if (test) {
        test["address"] =
          permitInfo?.buying_office?.buying_office_account_info?.branch_info
            ?.name +
          " - " +
          permitInfo?.buying_office?.buying_office_account_info?.branch_info
            ?.address;
      }
    }
  }, [permitInfo]);

  const getPOTypes = async (filterValues) => {
      let query = `${PO_TYPES}`;
      const bodyData = {
        per_page: filterValues?.per_page || 10,
        ...filterValues,
      };
      const response = await getData(query, false, bodyData);
      if (response) {
        setPoTypes(response?.data?.data || []);
      } else {
        alertPop("error", "Something went wrong");
      }
    };
  
    const getPackingTypes = async (filterValues) => {
      let query = `${PACKING_TYPES}`;
      const bodyData = {
        per_page: filterValues?.per_page || 10,
        ...filterValues,
      };
      const response = await getData(query, false, bodyData);
      if (response) {
        setPackingTypes(response?.data?.data || []);
      } else {
        alertPop("error", "Something went wrong");
      }
    };

  return (
    <div className="containt-body">
      <Form layout="vertical" form={form} name="control-hooks">
        <Card>
          <Row gutter={6}>
            <Col>
              <Form.Item
                label="Sales Contract Uniqe ID"
                name="sales_contract_unique_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select ID (auto generate)!",
                  },
                ]}
              >
                <Input
                  className="w-100"
                  disabled={true}
                  value={salesContractID || undefined}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Reference No"
                name="reference_no"
                rules={[
                  {
                    required: true,
                    message: "Please input Reference No!",
                  },
                ]}
              >
                <Input className="w-100" disabled={view} size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Agreement Date"
                name="contract_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Agreement Date !",
                  },
                ]}
              >
                <CustomDatePicker
                  className="w-100"
                  disabled={view}
                  size="small"
                />
                {/* <DatePicker className="w-100" disabled={view} size="small" /> */}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label="First Party"
                name="first_party"
                rules={[
                  {
                    required: true,
                    message: "Please input First Party!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a First Party"
                  allowClear
                  size="small"
                  onChange={(value) => {
                    const selectedFactory = subcontractStatusOption.find(
                      (item) => item.id === value,
                    );
                    setSelectedFactoryName(selectedFactory?.name);
                    form.setFieldsValue({
                      address: selectedFactory?.address,
                    });
                    getStyleList(value);
                  }}
                >
                  {isArrayAndHasValue(subcontractStatusOption) &&
                    subcontractStatusOption.map((sup) => (
                      <Option value={sup?.id} key={sup?.id}>
                        {sup.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Address" name="address">
                <Input placeholder="Address" size="small" disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Quantity (pc)" name="quantity">
                <InputNumber
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={5}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Contract Value (TK)" name="contract_value">
                <InputNumber
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={5}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Bond License No." name="bond_licence_no">
                <Input size="small" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="License Date" name="licence_date">
                <CustomDatePicker
                  className="w-100"
                  disabled={view}
                  size="small"
                />
                {/* <DatePicker className="w-100" disabled={view} size="small" /> */}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Export LC/ Sales Contract No."
                name="export_lc_sales_contact_no"
              >
                <Input size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="UD No." name="ud_no">
                <Input size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="UD Date" name="ud_date">
                <CustomDatePicker
                  className="w-100"
                  disabled={view}
                  size="small"
                />
                {/* <DatePicker className="w-100" disabled={view} size="small" /> */}
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Date of Return to Party"
                name="date_of_return_to_party"
              >
                <CustomDatePicker
                  className="w-100"
                  disabled={view}
                  size="small"
                />

                {/* <DatePicker className="w-100" disabled={view} size="small" /> */}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Expiry Date" name="expiry_date">
                <CustomDatePicker
                  className="w-100"
                  disabled={view}
                  size="small"
                />
                {/* <DatePicker className="w-100" disabled={view} size="small" /> */}
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Contract Status "
                name="status"
                rules={[
                  { required: false, message: "Please input Contract Status!" },
                ]}
              >
                <Select
                  showSearch
                  onSelect={confirmChangeStatus}
                  placeholder="Select a Contract Status"
                  optionFilterProp="children"
                  disabled={view || !permitInfo ? true : false}
                  defaultValue={1}
                  size="small"
                >
                  <Option value={1} key={1}>
                    Active
                  </Option>
                  <Option value={2} key={2}>
                    Deactive
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                name="use_both_style_po"
                label="Use both style and PO"
                required
                className="collection-create-form_last-form-item"
                valuePropName={
                  permitInfo?.sales_contract?.use_both_style_po === "Yes"
                    ? "checked"
                    : "unchecked"
                }
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>

      <StyleDetailsInitial
        title="Style Details"
        selectedStyles={selectedStyles}
        setSelectedStyle={setSelectedStyle}
        styleList={styleList}
        setStyleList={setStyleList}
        view={view}
        totalStyleOrderQty={totalStyleOrderQty}
        totalStyleOrderValue={totalStyleOrderValue}
      />

      {!isAmend && (
        <POList
          view={view}
          poList={poList}
          setPoList={setPoList}
          salesContractID={salesContractID}
          getPOTypes={getPOTypes}
          getPackingTypes={getPackingTypes}
          poTypes={poTypes}
          packingTypes={packingTypes}
        />
      )}

      {!view && (
        <DocumentUpload
          DOC_ADD={AMENDMENT_DOC_ADD}
          setFileList={setFilesList}
          filesList={filesList}
          view={view}
          header={!view ? "Attachment *" : null}
        />
      )}
    </div>
  );
});

export default AddSalesContactForm;
