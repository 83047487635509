import { Checkbox, Col, Form, Input, Select } from "antd";
import {
  VEHICLE_SIZE_ENDPOINT,
  VEHICLE_TYPE_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useEffect, useState } from "react";

const VehicleAndDriverInformationFilterField = (props) => {
  const [options, setOptions] = useState({
    vehicleType: [],
    vehicleSizeId: [],
  });

  const getOptionsList = async () => {
    await getVehicleTypeList();
    await getVehicleSizeList();
  };

  const getVehicleTypeList = async () => {
    let res = await getData(VEHICLE_TYPE_ENDPOINT);

    if (res) {
      setOptions((prevState) => ({
        ...prevState,
        vehicleType: res?.data?.data?.data || [],
      }));
    }
  };
  const getVehicleSizeList = async () => {
    let res = await getData(VEHICLE_SIZE_ENDPOINT);

    if (res) {
      setOptions((prevState) => ({
        ...prevState,
        vehicleSizeId: res?.data?.data?.data || [],
      }));
    }
  };

  useEffect(() => {
    getOptionsList();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item label="Vehicle No" name="vehicle_no">
          <Input placeholder="Vehicle No" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Vehicle Size Id" name="vehicle_size_id">
          <Select
            showSearch
            placeholder="Vehicle Size Id"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {options.vehicleSizeId?.length &&
              options.vehicleSizeId.map((sup) => (
                <Select.Option value={sup.id} key={sup.id}>
                  {sup.vehicle_size} ({sup.vehicle_size_in_cbm})
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Driver Name" name="driver_name">
          <Input placeholder="Driver Name" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="NID" name="nid">
          <Input placeholder="NID" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Driving License No" name="driving_license_no">
          <Input placeholder="Driving License No" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Vehicle Type" name="vehicle_type_id">
          <Select
            showSearch
            placeholder="Vehicle Type"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {options.vehicleType?.length &&
              options.vehicleType.map((sup) => (
                <Select.Option value={sup.id} key={sup.id}>
                  {sup.vehicle_type}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label=" "
          name="own_vehicle_status"
          valuePropName={"checked"}
        >
          <Checkbox name="own_vehicle_status">Own Vehicle</Checkbox>
        </Form.Item>
      </Col>
    </>
  );
};

export default VehicleAndDriverInformationFilterField;
