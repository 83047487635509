import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ExportOutlined } from "@ant-design/icons";
import { message, Table, Button } from "antd";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { getData } from "apiServices/common";
import { SEWING_LIST } from "apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { cglFormatDate, hasPermission, isArrayAndHasValue } from "utils/functions";
import { SEWING_CREATE_PERMISSION } from "routes/permissions";
import moment from "moment";
import style from "../production.module.css"

const Sewing = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Others
  const navigate = useNavigate();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllSewing = useCallback(
    async (filterValues) => {
      setLoading(true);

      const query = `${SEWING_LIST}?page=${currentPage}`;
      const bodyData = {
        ...filterValues,
      };

      const response = await getData(query, false, bodyData);
      if (response && response?.data?.code === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting sewing data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    const existingFilter =
      JSON.parse(localStorage.getItem("sewing_filter_filter_value")) || {};

    getAllSewing(existingFilter);
  }, [refresh, filterData, getAllSewing]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const gotoAddRoute = () => {
    navigate("/production/sewing-new/create");
  };

  const gotoUpdateRoute = (record) => {
    const lineId = record?.line_id;
    const sewingDate = record?.sewing_date
      ? moment(record?.sewing_date).format("YYYY-MM-DD")
      : null;
    const productionHour = record?.production_hour;
    const styles = record?.po_and_styles?.map((item) => item?.style_id);

    const updateLink = `/production/sewing-new/update`;

    const queryParams = {
      line_id: lineId,
      sewing_date: sewingDate,
      production_hour: productionHour,
      styles: styles,
    };

    navigate({
      pathname: updateLink,
      search: new URLSearchParams(queryParams).toString(),
    });
  };

  const tableColumns = [
    {
      title: "Sewing Date",
      dataIndex: ["sewing_date"],
      key: "sewing_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Hour",
      dataIndex: ["production_hour"],
      key: "production_hour",
    },
    {
      title: "Line",
      dataIndex: ["line_name"],
      key: "line",
    },
    {
      title: "PO",
      dataIndex: ["po_and_styles"],
      key: "po",
      render: (po_and_styles) => {
        if (!isArrayAndHasValue(po_and_styles)) return null;

        const arraySize = po_and_styles.length;
        const firstThreeItems = po_and_styles.slice(0, 3);
        const firstThreePo = firstThreeItems.map((item) => {
          return item?.original_po ? item?.original_po : item?.internal_po;
        });
        const toString = firstThreePo.join(", ");

        return `${toString} and ${arraySize - 3} more....`;
      },
    },
    {
      title: "Style",
      dataIndex: ["po_and_styles"],
      key: "style",
      render: (po_and_styles) => {
        if (!isArrayAndHasValue(po_and_styles)) return null;

        const arraySize = po_and_styles.length;
        const firstThreeItems = po_and_styles.slice(0, 3);
        const firstThreeStyles = firstThreeItems.map((item) => {
          return item?.style_no;
        });
        const toString = firstThreeStyles.join(", ");

        return `${toString} and ${arraySize - 3} more....`;
      },
    },
    {
      title: "Sewing Qty",
      dataIndex: ["sewing_piece"],
      key: "sewing_piece",
    },
    {
      title: "Reject Qty",
      dataIndex: ["rejected_piece"],
      key: "rejected_piece",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Button
            icon={<ExportOutlined />}
            onClick={() => gotoUpdateRoute(record)}
            size="small"
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const sewingPiece = () => {
    if(isArrayAndHasValue(tableData)) {
      const result = tableData.reduce((acc, curr) => acc + curr?.sewing_piece, 0);
      return result || 0;
    }
  }

  const rejectedPiece = () => {
    if(isArrayAndHasValue(tableData)) {
      const result = tableData.reduce((acc, curr) => acc + curr?.rejected_piece, 0);
      return result || 0;
    }
  }

  const tableTitleContent = (<div className={style?.titleHeader}>
    <div className={style?.totalContainer}>
      <div className={style.totalItem}><span className={style.totalLabel}>Total Sewing: </span> {sewingPiece()} </div>
      <div className={style.totalItem}><span className={style.totalLabel}>Total Reject: </span> {rejectedPiece()}</div>
    </div>
  </div>)

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={gotoAddRoute}
                disabled={!hasPermission([SEWING_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([SEWING_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title="Sewing"
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllSewing}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="sewing_filter"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                title={()=>tableTitleContent}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
                sticky={true}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default Sewing;
