import {
    CREATE_NEW_INVENTORY,
    DELETE_INVENTORY,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS, GET_INVENTORY_DETAIL,
    GET_INVENTORY_LIST,
    SHOW_MESSAGE,
    UPDATE_INVENTORY_DETAIL,
    GET_ASSIGN_SUMMARY,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_INVENTORY_ENDPOINT,
    DELETE_INVENTORY_ENDPOINT,
    EDIT_INVENTORY_ENDPOINT,
    GET_INVENTORY_ENDPOINT,
    GET_ASSIGN_SUMMARY_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetInventoryList = (page, per_page) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_INVENTORY_ENDPOINT}`, {
                params: {
                    page: page,
                    per_page: per_page,
                },
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_INVENTORY_LIST, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onGetInventoryDetails = (inventoryId) => {

    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`api/inventory/${inventoryId}/edit`)

            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_INVENTORY_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Inventory Updated Successfully",
                    });
                    // HandleNotification('success', 'bottomRight', 'Inventory Account updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    // HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onCreateInventory = (inventory) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${CREATE_INVENTORY_ENDPOINT}`, inventory)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREATE_NEW_INVENTORY, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Inventory Created Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Data added Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

            });
    };
};
export const onUpdateSelectedInventory = (inventory) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .put(`${EDIT_INVENTORY_ENDPOINT}/${inventory.id}`, inventory)

            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: UPDATE_INVENTORY_DETAIL, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "inventory Updated Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'inventory updated Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('success', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};
export const onDeleteInventory = (inventory) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .delete(`${DELETE_INVENTORY_ENDPOINT}/${inventory.id}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: DELETE_INVENTORY, payload: data.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Deleted inventory Successfully",
                    });

                    HandleNotification('success', 'bottomRight', 'inventory deleted Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};

export const onGetAssignSummary = (bodyData) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .post(`${GET_ASSIGN_SUMMARY_ENDPOINT}`, bodyData)
            .then((data) => {
                if (data?.data?.code === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_ASSIGN_SUMMARY, payload: data?.data?.data});
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: "Assign Data Fetched Successfully",
                    });
                    HandleNotification('success', 'bottomRight', 'Track Items Data Fetched Successfully!', null)
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
            });
    };
};


