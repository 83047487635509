import { Bar } from "@ant-design/plots";
import { Alert, Card, Empty, Typography } from "antd";
import { postData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";

const ResponsibleForDefects = ({ line_id, currentSlide, slideId }) => {
  const [responsibleForDefectsData, setResponsibleForDefectsData] = useState(
    {},
  );

  const initialGetData = useCallback(async () => {
    const response = await postData(
      "/api/digitalization/bigscreen-dashboard/responsible-defects",
      {
        line_id: line_id,
      },
    );

    if (response && response?.code === 200) {
      const data = response?.data;

      setResponsibleForDefectsData(data);
    }
  }, [line_id]);

  useEffect(() => {
    if (line_id && currentSlide === slideId) {
      initialGetData();
    }
  }, [line_id, currentSlide, slideId, initialGetData]);

  const config = {
    data: responsibleForDefectsData?.chart_data,
    xField: "name",
    yField: "value",
    label: {
      text: "value",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      label: {
        formatter: (text) => `${text}`, // Show value on the x-axis
      },
    },
    yAxis: {
      label: {
        formatter: (text) => `${text}`, // Show responsible name on the y-axis
      },
    },
  };
  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {responsibleForDefectsData?.chart_data &&
      responsibleForDefectsData?.chart_data?.length > 0 ? (
        <>
          <Typography.Title level={3}>Responsible For Defects</Typography.Title>
          <Bar {...config} />
        </>
      ) : (
        <Empty
          style={{
            width: "33%",
            margin: "auto",
            marginTop: "10%",
            marginBottom: "10%",
            height: "100%",
          }}
          description={
            <Alert
              message="No Data Found with selected line-id in Responsible For Defects"
              type="warning"
            />
          }
        />
      )}
    </Card>
  );
};

export default ResponsibleForDefects;
