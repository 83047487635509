import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Table, Typography, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { DIGITALIZATION_ABSENT_LIST } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  DIGITALIZATION_ABSENTEEISM_VIEW_PERMISSION,
  FABRIC_REPORT_GENERATE_PERMISSION,
} from "../../../routes/permissions";
import { cglFormatDate, hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const OperatorAbsenteeism = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllChecklist = useCallback(async () => {
    setLoading(true);
    const query = `${DIGITALIZATION_ABSENT_LIST}?page=${currentPage}`;

    const response = await getData(query);
    if (response?.status === 200) {
      setLoading(false);
      setMetaData({
        defaultPageSize: response?.data?.data?.per_page,
        current: response?.data?.data?.current_page,
        defaultCurrent: response?.data?.data?.current_page,
        total: response?.data?.data?.total,
      });
      setTableData(response?.data?.data?.data || []);
    } else {
      setLoading(false);
      message.error("Something went wrong! Please try again.");
    }
  }, [currentPage]);

  const exportProductionHour = async (filterValues) => {
    setLoading(true);
    const query = `${DIGITALIZATION_ABSENT_LIST}`;

    const response = await getData(query, false, filterValues);
    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data?.data || []);
    } else {
      setLoading(false);
      message.error("Something went wrong! Please try again.");
    }
  };

  // Effects
  useEffect(() => {
    getAllChecklist();
  }, [refresh, getAllChecklist]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };
  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/digitalization/operator-absenteeism/view/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([
            DIGITALIZATION_ABSENTEEISM_VIEW_PERMISSION,
          ]),
        },
      ]}
    />
  );

  const tableColumns = [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "sl",
      width: 150,
      render: (_, __, index) => index + 1,
    },
    {
      title: "Supervisor Name",
      dataIndex: ["user", "name"],
      key: "name",
      width: 150,
    },
    {
      title: "Line",
      dataIndex: ["line_info", "name"],
      key: "line_info_name",
      width: 150,
    },
    {
      title: "Operator",
      dataIndex: ["operator", "name"],
      key: "operator_name",
      width: 150,
    },
    {
      title: "Reason",
      dataIndex: ["reason", "reason"],
      key: "reason",
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 150,
      render: (row) => cglFormatDate(row)
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  console.log({ tableData });

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportProductionHour}
                hideAddButton={true}
                disabled={!hasPermission([FABRIC_REPORT_GENERATE_PERMISSION])}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            }
            title={<Title level={5}>Operator Absenteeism</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <FilterComponent
                tableData={tableData}
                filterAPI={exportProductionHour}
                filterName={"operator_absenteeism"}
                searchButtonText={"Search"}
                defaultOpen
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
                bordered
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default OperatorAbsenteeism;
