import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  InputNumber,
  Table,
  Typography,
  Space,
  Popover,
  Descriptions,
  Image,
  Select,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "../index.css";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";
import {  getUnitList } from "components/Account/ImportPi/AddOrUpdatePi/AddPiForm";

const EditableContext = React.createContext(null);
const { Option } = Select;

 const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  useEffect(() => {}, [props]);
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  // useEffect(() => {
  //   if (editing) {
  //     inputRef.current.focus();
  //   }
  // }, [editing]);

  

  // const toggleEdit = () => {
  //   setEditing(!editing);
  //   form.setFieldsValue({
  //     [dataIndex]: record[dataIndex],
  //   });
  // };

 

  const save = async () => {
    try {
      const values = await form.validateFields();
      if (values["usage_info"]) {
        record["usage_info"]["regular"] =
          values?.usage_info?.regular || record["usage_info"]["regular"];
        record["usage_info"]["replacement"] =
          values?.usage_info?.replacement ||
          record["usage_info"]["replacement"];
      }
      if (values["wastage_info"]) {
        record["wastage_info"]["day_quantity"] =
          values?.wastage_info?.day_quantity ||
          record["wastage_info"]["day_quantity"];
      }

      handleSave(record, values);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  // if (editable) {
  //   childNode = editing ? (
  //     <Form.Item
  //       style={{
  //         margin: 0,
  //       }}
  //       name={dataIndex}
  //       rules={[
  //         ({ getFieldValue }) => ({
  //           validator(_, value) {
  //             if (dataIndex[0] === "usage_info") {
  //               if (
  //                 !value ||
  //                 parseInt(value) <= parseInt(record["usage_info"]["remain"])
  //               ) {
  //                 return Promise.resolve();
  //               } else {
  //                 return Promise.reject(
  //                   new Error(
  //                     "Total value can not be greater than remaining quantity!",
  //                   ),
  //                 );
  //               }
  //             }
  //             if (dataIndex[0] === "wastage_info") {
  //               if (
  //                 parseInt(value) <= parseInt(record["usage_info"]["remain"]) ||
  //                 !value
  //               ) {
  //                 return Promise.resolve();
  //               } else {
  //                 return Promise.reject(
  //                   new Error(
  //                     "Total value can not be greater than remaining quantity!",
  //                   ),
  //                 );
  //               }
  //             }
  //           },
  //         }),
  //       ]}
  //     >
  //       <Input ref={inputRef} onChange={save} />
  //     </Form.Item>
  //   ) : (
  //     <div
  //       className="editable-cell-value-wrap"
  //       style={{
  //         paddingRight: 24,
  //       }}
  //       onClick={toggleEdit}
  //     >
  //       {children}
  //     </div>
  //   );
  // }
  return <td {...restProps}>{childNode}</td>;
};
const ItemUsageDetails = (props) => {

  const { loading, itemUsageInfo, setCuttingItems, getConversionData } = props;

  var itemDetails = itemUsageInfo;
  const [onEdit, setEdit] = useState(false);
  const [unitList, setUnitList] = useState([]);
    const onsetEdit = () => {
    setEdit(!onEdit);
  };
  // useEffect(() => {}, [itemDetails, itemUsageInfo]);
  console.log('itemDetails', itemDetails);
  useEffect(() => {
    getUnitList(setUnitList);
  }, []);
 

  const defaultColumns = [
    {
      title: "Item Type",
      dataIndex: ["item_info", "category_info"],
      key: ["item_info", "category_info"],
      render: (item) => item?.name,
    },
    {
      title: "Item",
      dataIndex: ["item_info"],
      key: ["item_info", "name"],
      width: 350,
      render: (item) => {
        return (
          <div>
            <Space>
              <div>{item?.name}</div>
              <div>
                <Popover
                  content={
                    <Descriptions
                      column={1}
                      bordered
                      style={{
                        height: 300,
                        width: 350,
                        overflow: "auto",
                      }}
                    >
                      {item?.attribute_value_info?.map((attrInfo, index) => {
                        return (
                          <Descriptions.Item
                            label={attrInfo?.attribute_info?.name}
                          >
                            {attrInfo?.attribute_value}
                          </Descriptions.Item>
                        );
                      })}

                      {item?.image_path ? (
                        <Descriptions.Item
                          label="Item Image"
                          labelStyle={{
                            backgroundColor: "white",
                            borderRight: "none",
                            alignContent: "center",
                          }}
                        >
                          <Image.PreviewGroup>
                            <Image
                              width={50}
                              height={50}
                              path={item?.image_path}
                              src={`${process.env.REACT_APP_BASE_URL}/${item?.image_path}`}
                            />
                          </Image.PreviewGroup>
                        </Descriptions.Item>
                      ) : null}
                    </Descriptions>
                  }
                >
                  <div className="centered-item-parent">
                    <InfoCircleOutlined
                      className="cursore-pointer"
                      style={{
                        fontSize: "20px",
                        color: "green",
                      }}
                    />
                  </div>
                </Popover>
              </div>
            </Space>
          </div>
        );
      },
    },
    {
      title: "Sub Style",
      dataIndex: ["sub_style_info", "sub_style_no"],
      key: ["sub_style_info", "sub_style_no"],
    },
    {
      title: "Required Qty.",
      dataIndex: "required_quantity",
      key: "required_quantity",
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        );
      },
    },
    {
      title: "BOM",
      children: [
        {
          title: "Issued Qty.",
          dataIndex: "issued_quantity",
          key: "issued_quantity",
          render: (value) => {
            return (
              <span>
                {value ? commaSeparateNumber(value) : 0}
              </span>
            );
          },
        },
        {
          title: "Unit",
          dataIndex: ["unit_info", "name"],
          key: ["unit_info", "name"],
        },
      ]
    },
    // {
    //   title: "Balance",
    //   dataIndex: ["usage_info", "regular"],
    //   key: ["usage_info", "regular"],
    //   editable: onEdit,
    //   render(text, record) {
    //     return {
    //       children: (
    //         <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
    //           <InputNumber
    //             style={{width: '100%'}}
    //             value={parseInt(record?.usage_info?.regular)}
    //             //disabled={!onEdit}
    //             formatter={(value) => commaSeparateNumber(value)}
    //           />
    //         </Form.Item>
    //       ),
    //     };
    //   },
    // },
    {
      title: "Regular",
      children: [
        {
          title: "Regular Qty.",
          dataIndex: ["regular_qty", "regular"],
          key: ["regular_qty", "regular"],
          editable: onEdit,
          render(text, record) {
            return {
              children: (
                <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
                  <InputNumber
                    style={{width: '100%'}}
                    // value={parseInt(record?.usage_info?.regular)}
                    //disabled={!onEdit}
                    formatter={(value) => commaSeparateNumber(value)}
                    onChange={
                      // (value) => currentValue(value, record?.id)
                     async (value) => {
                        record.reqular_base_value = value;
                        record.pi_unit_id = record?.reqular_pi_unit_id ? record?.reqular_pi_unit_id : record.unit_id
                       await getConversionData(value,record, true, 'reqular_balance', 'reqular_base_value')
                      }
                    }
                  />
                </Form.Item>
              ),
            };
          },
        },
        {
          title: "Unit",
          dataIndex: "pi_unit_id",
          key: "pi_unit_id",
          render: (text, record, idx) => {
            return (
              <Select
                showSearch
                defaultValue={record?.pi_unit_id ?? record?.unit_id}
                placeholder="Select Unit"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onSelect={async(value) => {
                record.reqular_pi_unit_id = value
                await getConversionData(value,record, false, 'reqular_balance', 'reqular_base_value')
                }}
                size="small"
                style={{width: "100%"}}
              >
                {isArrayAndHasValue(unitList) &&
                  unitList.map((unit) => (
                    <Option key={unit.id} value={unit.id}>{unit.name}</Option>
                )
                )}
            </Select>
            );
          },
        },
      ]
    },
    {
      title: "Balance",
      dataIndex: ["regular_balance"],
      key: ["regular_balance"],
      editable: onEdit,
      render(text, record) {
        return {
          children: (
            <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
              <InputNumber
                style={{width: '100%'}}
                value={record?.reqular_balance || 0}
                //disabled={!onEdit}
                formatter={(value) => commaSeparateNumber(value)}
              />
            </Form.Item>
          ),
        };
      },
    },
    {
      title: "Replacement",
      children: [
        {
          title: "Replacement Qty.",
          dataIndex: ["usage_info", "regular"],
          key: ["usage_info", "regular"],
         // editable: onEdit,
          render(text, record) {
            return {
              children: (
                <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
                  <InputNumber
                    style={{width: '100%'}}
                    // value={parseInt(record?.usage_info?.regular)}
                    //disabled={!onEdit}
                    formatter={(value) => commaSeparateNumber(value)}
                    onChange={
                      // (value) => currentValue(value, record?.id)
                     async (value) => {
                        record.replacement_base_value = value;
                        record.pi_unit_id = record?.replacement_pi_unit_id ? record?.replacement_pi_unit_id : record.unit_id
                       await getConversionData(value,record, true, 'replacement_balance', 'replacement_base_value')
                      }
                    }
                  />
                </Form.Item>
              ),
            };
          },
        },
        {
          title: "Unit",
          dataIndex: "replacement_pi_unit_id",
          key: "replacement_pi_unit_id",
          render: (text, record, idx) => {
            return (
              <Select
                showSearch
                defaultValue={record?.pi_unit_id ?? record?.unit_id}
                placeholder="Select Unit"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onSelect={async(value) => {
                record.replacement_pi_unit_id = value
                await getConversionData(value,record, false, 'replacement_balance', 'replacement_base_value')
                }}
                size="small"
                style={{width: "100%"}}
              >
                {isArrayAndHasValue(unitList) &&
                  unitList.map((unit) => (
                    <Option key={unit.id} value={unit.id}>{unit.name}</Option>
                )
                )}
            </Select>
            );
          },
        },
      ]
    },
    {
      title: "Balance",
      dataIndex: ["replacement_balance"],
      key: "replacement_balance",
      //editable: onEdit,
      render(text, record) {
        return {
          children: (
            <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
              <InputNumber
                style={{width: '100%'}}
                value={record?.replacement_balance || 0}
                // disabled={!onEdit}
                formatter={(value) => commaSeparateNumber(value)}
              />
            </Form.Item>
          ),
        };
      },
    },
    // { 
    //   title: "Day Total", 
    //   dataIndex: ["usage_info", "day_total"],
    //   render: (value) => {
    //     return (
    //       <span>
    //         {value ? commaSeparateNumber(value) : 0}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Cumulative Total (usage)",
      dataIndex: ["usage_info", "cumulative_total"],
      key: ["usage_info", "cumulative_total"],
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        );
      },
    },
    {
      title: "Wastage",
      children: [
        {
          title: "Wastage Qty.",
          dataIndex: ["usage_info", "regular"],
          key: ["usage_info", "regular"],
          // editable: onEdit,
          render(text, record) {
            return {
              children: (
                <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
                  <InputNumber
                    style={{width: '100%'}}
                    // value={parseInt(record?.usage_info?.regular)}
                    //disabled={!onEdit}
                    formatter={(value) => commaSeparateNumber(value)}
                    onChange={
                      // (value) => currentValue(value, record?.id)
                     async (value) => {
                        record.base_value = value
                       await getConversionData(value,record, true)
                      }
                    }
                  />
                </Form.Item>
              ),
            };
          },
        },
        // {
        //   title: "Unit",
        //   dataIndex: ["unit_info", "name"],
        //   key: ["unit_info", "name"],
        // },
        {
          title: "Unit",
          dataIndex: "pi_unit_id",
          key: "pi_unit_id",
          render: (text, record, idx) => {
            return (
              <Select
                showSearch
                defaultValue={record?.pi_unit_id ?? record?.unit_id}
                placeholder="Select Unit"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onSelect={async(value) => {
                record.pi_unit_id = value
                await getConversionData(value,record)
                }}
                size="small"
                style={{width: "100%"}}
              >
                {isArrayAndHasValue(unitList) &&
                  unitList.map((unit) => (
                    <Option key={unit.id} value={unit.id}>{unit.name}</Option>
                )
                )}
            </Select>
            );
          },
        },
      ]
    },
    {
      title: "Balance",
      dataIndex: ["wastage_balance"],
      
      key: ["wastage_balance"],
      render(text, record) {
        return {
          children: (
            <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
              <InputNumber
                style={{width: '100%'}}
                value={record?.balance || 0}
                
                formatter={(value) => commaSeparateNumber(value)}
              />
            </Form.Item>
          ),
        };
      },
    },
    {
      title: "Cumulative Total(wastage)",
      dataIndex: ["wastage_info", "cumulative_total"],
      key: ["wastage_info", "cumulative_total"],
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        );
      },
    },
    {
      title: "%",
      dataIndex: ["wastage_info", "percentage"],
      key: ["wastage_info", "percentage"],
      render: (_, record, index) => {
        if (record["usage_info"]["cumulative_total"] === 0) {
          return 0;
        } else {
          return parseFloat(
            record["wastage_info"]["cumulative_total"] /
              record["usage_info"]["cumulative_total"],
          ).toFixed(2);
        }
      },
    },
    {
      title: "Remaining Qty",
      dataIndex: ["usage_info", "remain"],
      key: ["usage_info", "remain"],
      render: (value) => {
        return (
          <span>
            {value ? commaSeparateNumber(value) : 0}
          </span>
        );
      },
    },
    // {title:"Approved", dataIndex:["consumption", "approved"], key:["consumption", "approved"]},
    // {title:"Actual", dataIndex:["consumption", "actual"], key:["consumption", "actual"] },
    // {title:"Savings", dataIndex:["consumption", "savings"], key:["consumption", "savings"] },
  ];

  const handleSave = (row, values) => {
    const newData = [...itemDetails];
    const index = newData.findIndex((item) => row.id === item.id);
    itemDetails[index] = row;

    newData[index]["usage_info"]["regular"] = values?.["usage_info"]?.regular
      ? values["usage_info"]?.regular
      : row.usage_info?.regular;
    newData[index]["usage_info"]["replacement"] = values?.["usage_info"]
      ?.replacement
      ? values["usage_info"]?.replacement
      : row.usage_info?.replacement;
    newData[index]["wastage_info"]["day_quantity"] = values["wastage_info"]
      ? values["wastage_info"]?.day_quantity
      : row.wastage_info?.day_quantity;

    // setItemDetails(newData)
    setCuttingItems(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const TableSummary = (pageData) => {
    let totalReqQty = 0;
    let issuedQty = 0;
    let totalDay = 0;
    let totalCumulative = 0;
    let totalCumWastage = 0;
    let totalRemainingQty = 0;

    pageData?.pageData?.forEach((item) => {
      totalReqQty += parseInt(item["required_quantity"]);
      issuedQty += parseInt(item["issued_quantity"]);
      totalDay += parseInt(item["usage_info"]["day_total"]);
      totalCumulative += parseInt(item["usage_info"]["cumulative_total"]);
      totalCumWastage += parseInt(item["wastage_info"]["cumulative_total"]);
      totalRemainingQty += parseInt(item["usage_info"]["remain"]);
    });

    const totalData = {
      totalReqQty,
      issuedQty,
      totalDay,
      totalCumulative,
      totalCumWastage,
      totalRemainingQty,
    };

    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#F5F5F5" }}>
          <Table.Summary.Cell index={0}>
            <strong>Total</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            {totalData?.totalReqQty ? commaSeparateNumber(totalData?.totalReqQty) : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            {totalData?.issuedQty ? commaSeparateNumber(totalData?.issuedQty) : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}></Table.Summary.Cell>
          <Table.Summary.Cell index={8}>
            {totalData?.totalDay ? commaSeparateNumber(totalData?.totalDay) : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
            {totalData?.totalCumulative ? commaSeparateNumber(totalData?.totalCumulative) : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}></Table.Summary.Cell>
          <Table.Summary.Cell index={11}>
            {totalData?.totalCumWastage ? commaSeparateNumber(totalData?.totalCumWastage) : 0}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12}></Table.Summary.Cell>
          <Table.Summary.Cell index={13}>
            {totalData?.totalRemainingQty ? commaSeparateNumber(totalData?.totalRemainingQty) : 0}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };


  return (
    <div className="cuttingItemTable" style={{ marginTop: 35 }}>
      {/* <Button
        type="primary"
        size="middle"
        onClick={onsetEdit}
        style={{
          float: "right",
          fontWeight: 600,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        Edit
      </Button> */}
      <Typography.Title style={{ fontSize: 16 }}>
        Item Usage Details
      </Typography.Title>
      <Table
        size={"small"}
        pagination={false}
        loading={loading}
        scroll={{
          x: 1300,
        }}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={itemDetails}
        columns={columns}
        summary={(pageData) => {
            return (
              <TableSummary pageData={pageData} />
            );
        }}
      />
    </div>
  );
};
export default ItemUsageDetails;
