import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { getData, postData } from "../../../../apiServices/common";
import {
  ADVANCED_SEARCH,
  GET_BUYING_OFFICE_ENDPOINT,
} from "../../../../apiServices/API_ENDPOINTS";
import { isArrayAndHasValue } from "../../../../utils/functions";
import SearchList from "./SearchList";

const AdvancedSearch = () => {
  // States
  const [buyingOffice, setBuyingOffice] = useState(null);
  const [buyerList, setBuyerList] = useState(null);
  const [searchData, setSearchData] = useState(null);

  // Others
  const { Option } = Select;
  const [advancedSearchForm] = Form.useForm();
  const { Title } = Typography;

  const getBuyingOfficeList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    let response = await getData(GET_BUYING_OFFICE_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      const buyingOfficeData = response?.data?.data?.data;
      setBuyingOffice(buyingOfficeData || []);
    }
  };

  useEffect(() => {
    getBuyingOfficeList();
  }, []);

  const onSearchBuyingOffice = (value) => {
    getBuyingOfficeList({ name: value });
  };

  const getBuyerList = (buyingOfficeId) => {
    const foundItem = buyingOffice.find((item) => item.id === buyingOfficeId);
    const buyers =
      foundItem && foundItem?.merchandiser_part?.map((item) => item?.buyer);
    setBuyerList(buyers);
  };

  const onSearch = async (values) => {
    const bodyData = {
      post_data: values?.post_data,
      buyer_id: values?.buyer_id,
      buying_office_id: values?.buying_office_id,
    };
    const query = `${ADVANCED_SEARCH}`;
    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      if (isArrayAndHasValue(response?.data)) {
        setSearchData(response?.data);
      } else {
        setSearchData(null);
        message.error("No data found");
      }
    }
  };

  const onReset = () => {
    advancedSearchForm.resetFields();
    setSearchData(null);
    setBuyerList(null);
    setBuyingOffice(null);
  };

  return (
    <div style={{ paddingLeft: 5, paddingRight: 5 }}>
      <div>
        <Title level={5} className="centered-item-parent">
          Advanced Search
        </Title>
      </div>
      <Form form={advancedSearchForm} layout="vertical" onFinish={onSearch}>
        <Row gutter={6}>
          <Col span={12}>
            <Form.Item name="post_data">
              <Input size="small" placeholder="Style" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="buying_office_id">
              <Select
                showSearch
                placeholder="Select a Buying Office"
                onSearch={(value) => {
                  onSearchBuyingOffice(value);
                }}
                onChange={(value) => getBuyerList(value)}
                optionFilterProp="children"
                size="small"
              >
                {isArrayAndHasValue(buyingOffice) &&
                  buyingOffice.map((sup) => (
                    <Option value={sup.id} key={sup.id}>
                      {sup.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={12}>
            <Form.Item name="buyer_id">
              <Select
                showSearch
                placeholder="Select buying office first"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children[0].toLowerCase().includes(input.toLowerCase())
                }
                size="small"
                disabled={!isArrayAndHasValue(buyerList)}
              >
                {isArrayAndHasValue(buyerList) &&
                  buyerList.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <div className="centered-item-parent" style={{ gap: "5px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="small"
                  style={{ width: "100%" }}
                >
                  Search
                </Button>
                <Button
                  size="small"
                  style={{ width: "100%" }}
                  onClick={onReset}
                >
                  Reset
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <SearchList searchData={searchData} />
    </div>
  );
};

export default AdvancedSearch;
