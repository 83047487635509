import { Table, Typography, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  DELETE_PRODUCTION_HOUR,
  EXPORT_HOURLY_PRODUCTION,
  GET_HOURLY_PRODUCTION_LIST,
  VIEW_HOURLY_PRODUCTION,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import { FABRIC_REPORT_GENERATE_PERMISSION } from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const HourlyProduction = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/hourly_production/")[1] : "";
    return updatedPath;
  };

  const getAllPorductionHour = useCallback(async () => {
    setLoading(true);
    const query = `${GET_HOURLY_PRODUCTION_LIST}`;

    const response = await getData(query);
    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting production hour data");
    }
  }, []);

  const exportProductionHour = async (filterValues) => {
    if (Object.values(filterValues).some((val) => val)) {
      setLoading(true);
      const { start_date, end_date } = filterValues;
      const query = `${EXPORT_HOURLY_PRODUCTION}`;
      const bodyData = {
        ...(start_date && {
          ...filterValues,
          start_date: moment(start_date).format("YYYY-MM-DD"),
        }),
        ...(end_date && { end_date: moment(end_date).format("YYYY-MM-DD") }),
      };
      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "blob" },
        "arraybuffer",
      );

      if (response && response?.data) {
        // Download the file
        const outputFilename = `${String(start_date)
          .split(" ")
          .join("-")}-${String(end_date).split(" ").join("-")}.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
        message.success("Production Hours Exported Successfully");
        refetch();
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };

  const previewFunction = async (filterValues) => {
    setLoading(true);
    const { start_date, end_date } = filterValues || {};
    const bodyData = {
      ...(start_date && {
        start_date: moment(start_date).format("YYYY-MM-DD"),
      }),
      ...(end_date && { end_date: moment(end_date).format("YYYY-MM-DD") }),
    };

    const query = `${VIEW_HOURLY_PRODUCTION}`;
    const response = await postData(query, bodyData);

    if (response?.code === 200) {
      setLoading(false);
      const rowData = response?.data?.data_row;
      const sumRow = response?.data?.sum_row;
      rowData.push(sumRow);
      setTableData(rowData);
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  // Effects
  // useEffect(() => {
  //   getAllPorductionHour();
  // }, [refresh, getAllPorductionHour]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedHour = async (name) => {
    setLoading(true);
    const updatedName = cutPath(name);
    const query = `${DELETE_PRODUCTION_HOUR}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setLoading(false);
      message.success("Production Hour Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/hourly_production/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  const tableColumns = [
    {
      title: "Sewing Hourly Production Report",
      children: [
        {
          title: "Sewing",
          children: [
            {
              title: "Line",
              dataIndex: ["line"],
              key: "line",
              width: 150,
            },
          ],
        },
        {
          title: "Target",
          children: [
            {
              title: "Plan",
              dataIndex: ["plan"],
              key: "plan",
              width: 150,
            },
            {
              title: "Actual",
              dataIndex: ["actual"],
              key: "actual",
              width: 150,
            },
          ],
        },
        {
          title: "Normal Hour Production",
          children: [
            {
              title: "1st HR",
              dataIndex: ["1st"],
              key: "1st",
              width: 150,
            },
            {
              title: "2nd HR",
              dataIndex: ["2nd"],
              key: "date",
              width: 150,
            },
            {
              title: "3rd HR",
              dataIndex: ["3rd"],
              key: "3rd",
              width: 150,
            },
            {
              title: "4th HR",
              dataIndex: ["4th"],
              key: "date",
              width: 150,
            },
            {
              title: "5th HR",
              dataIndex: ["5th"],
              key: "date",
              width: 150,
            },
            {
              title: "6th HR",
              dataIndex: ["6th"],
              key: "date",
              width: 150,
            },
            {
              title: "7th HR",
              dataIndex: ["7th"],
              key: "date",
              width: 150,
            },
            {
              title: "8th HR",
              dataIndex: ["8th"],
              key: "date",
              width: 150,
            },
            {
              title: "Total NH",
              dataIndex: ["total_n_h"],
              key: "date",
              width: 150,
            },
          ],
        },
        {
          title: "OT Hour Production",
          children: [
            {
              title: "9th HR",
              dataIndex: ["9th"],
              key: "date",
              width: 150,
            },
            {
              title: "10th HR",
              dataIndex: ["10th"],
              key: "date",
              width: 150,
            },
            {
              title: "11th HR",
              dataIndex: ["11th"],
              key: "date",
              width: 150,
            },
            {
              title: "12th HR",
              dataIndex: ["12th"],
              key: "date",
              width: 150,
            },
            {
              title: "13th HR",
              dataIndex: ["13th"],
              key: "date",
              width: 150,
            },
            {
              title: "14th HR",
              dataIndex: ["14th"],
              key: "date",
              width: 150,
            },
            {
              title: "15th HR",
              dataIndex: ["15th"],
              key: "date",
              width: 150,
            },
            {
              title: "16th HR",
              dataIndex: ["16th"],
              key: "date",
              width: 150,
            },
            {
              title: "Total OT",
              dataIndex: ["total_ot"],
              key: "date",
              width: 150,
            },
          ],
        },
        {
          title: "Total Production",
          dataIndex: ["total_production"],
          key: "date",
          width: 150,
        },
        {
          title: "Total Variance",
          dataIndex: ["variance"],
          key: "date",
          width: 150,
        },
      ],
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportProductionHour}
                hideAddButton={true}
                disabled={!hasPermission([FABRIC_REPORT_GENERATE_PERMISSION])}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            }
            title={<Title level={5}>Hourly Production</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <FilterComponent
                tableData={tableData}
                filterAPI={exportProductionHour}
                filterName={"fabric_reconcil"}
                searchButtonText={"Export"}
                defaultOpen
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                isPreview={true}
                previewFunction={previewFunction}
              />
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination
                scroll={{ y: "max-content" }}
                bordered
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default HourlyProduction;
