import { Col, Form, Row, Input, Button, Collapse } from "antd";
import { GET_ITEMS_CHECK } from "../../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../../apiServices/common";
import { useState } from "react";
import { isArrayAndHasValue } from "../../../../utils/functions";
import BulkTable from "./BulkTable";

const BulkItemsManage = (props) => {
  // Props
  const {
    activeBulkKey,
    setActiveBulkKey,
    unitList,
    supplierList,
    foundItems,
    setFoundItems,
    addBulkItems,
    subStyleList,
    drawerType,
    editBulkItems,
  } = props;

  // States
  const [notFoundItems, setNotFoundItems] = useState(null);
  const [showTable, setShowTable] = useState(drawerType === 'bulk_edit' ? true : false);

  // Antd
  const [bulkForm] = Form.useForm();
  const { TextArea } = Input;
  const { Panel } = Collapse;

  const onSubmitText = async (values) => {
    if (!values?.names) return;

    const splittedText = values?.names?.split("\n");
    const bodyData = {
      names: splittedText,
    };
    const query = `${GET_ITEMS_CHECK}`;
    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      const itemsNotFound = isArrayAndHasValue(response?.data?.not_found_data)
        ? response?.data?.not_found_data
        : null;
      const foundItems =
        isArrayAndHasValue(response?.data?.found_data) &&
        response?.data?.found_data?.map((item) => {
          return {
            item_info: {
              id: item?.id,
              name: item?.name,
            },
            id: item?.id,
            item_category_id: item?.category_id,
            item_category_info: item?.category,
            item_id: item?.id,
            isAdd: true,
          };
        });
      setFoundItems(foundItems);
      setNotFoundItems(itemsNotFound);

      //   When itemsNotFound is null, all items are found
      if (!itemsNotFound) {
        setActiveBulkKey(null);
        setShowTable(true);
      }
    }
  };

  const NotFoundError = ({ notFoundItems, foundItems }) => {
    if (!isArrayAndHasValue(notFoundItems)) return null;

    const itemsName = notFoundItems?.map((item) => item?.name);
    const asString = itemsName?.join(", ");

    return (
      <span>
        <span style={{ color: "red" }}>{asString}</span> items not found.
        <span>
          {isArrayAndHasValue(foundItems)
            ? " Proceed to add?"
            : " Please change values."}
        </span>
      </span>
    );
  };

  const proceedAddTable = () => {
    setActiveBulkKey(null);
    setShowTable(true);
  };

  return (
    <>
      <Collapse
        style={{ marginBottom: 10 }}
        activeKey={activeBulkKey}
        onChange={() => {
          setActiveBulkKey(activeBulkKey ? null : 1);
        }}
      >
        <Panel header={<b>Paste Data</b>} key={1} hidden={drawerType === 'bulk_edit'}>
          <>
            <Form form={bulkForm} onFinish={onSubmitText}>
              <Row gutter={6}>
                <Col span={24}>
                  <Form.Item
                    name="names"
                    rules={[
                      {
                        required: true,
                        message: "Please paste texts from your file",
                      },
                    ]}
                  >
                    <TextArea
                      rows={10}
                      placeholder="Paste text here"
                      style={{ width: 1100 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className="centered-item-parent">
                    <Button
                      type="primary"
                      size="small"
                      style={{ width: 150 }}
                      htmlType="submit"
                    >
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
              <NotFoundError
                notFoundItems={notFoundItems}
                foundItems={foundItems}
              />
            </div>
            {isArrayAndHasValue(notFoundItems) &&
              isArrayAndHasValue(foundItems) && (
                <Col span={24}>
                  <div className="centered-item-parent">
                    <Button
                      type="primary"
                      size="small"
                      style={{ width: 150 }}
                      onClick={proceedAddTable}
                    >
                      Proceed To Add
                    </Button>
                  </div>
                </Col>
              )}
          </>
        </Panel>
      </Collapse>
      {showTable && (
        <div>
          <BulkTable
            foundItems={foundItems}
            setFoundItems={setFoundItems}
            unitList={unitList}
            supplierList={supplierList}
            addBulkItems={addBulkItems}
            subStyleList={subStyleList}
            drawerType={drawerType}
            editBulkItems={editBulkItems}
          />
        </div>
      )}
    </>
  );
};

export default BulkItemsManage;
