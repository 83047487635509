import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Layout, Button, Collapse, message, Typography } from "antd";
import InventoryLayOutCommon from "../../../InventoryFabric/LayOut/InventoryLayOutCommon";
import AppPageHeader from "../../../../common/PageHeader";
import {
  onGetUserRoleList,
} from "../../../../redux/actions";
import UserForm from "./UserForm";
import { postData, putData } from "../../../../apiServices/common";
import { EDIT_USER_ENDPOINT, CREATE_USER_ENDPOINT } from "../../../../apiServices/API_ENDPOINTS";


const CreateUser = (props) => {
  // States
  const [refresh, setRefresh] = useState(0);

  // Redux
  const dispatch = useDispatch();

  //   Routes
  const location = useLocation();
  const navigate = useNavigate();
  const userDetails = location.state;

  //   Antd Constants
  const [form] = Form.useForm();
  const { Content } = Layout;
  const { Panel } = Collapse;
  const { Title } = Typography;

  const refetch = () => {
    setRefresh(refresh + 1);
  };
  
  const onFinish = async (values) => {
    // console.log("on finish values ", values);

    const bodyData = {
      name: values.name,
      phone: values.phone,
      email: values.email,
      password: values.password,
      department_id: values.department_id,
      user_status: values.user_status ? `Active` : `Inactive`,
      role_id: values.role_id,
    };

    if (userDetails) {
      const customQuery = `${EDIT_USER_ENDPOINT}/${userDetails.id}`;
      const response = await putData(customQuery, bodyData);

      if (response && response.code === 200) {
        message.success(`User updated successfully`);
        refetch();
      } else {
        message.error(`Error updating user`);
      }
    } else {
      const responseCreate = await postData(CREATE_USER_ENDPOINT, bodyData);

      if (responseCreate && responseCreate.code === 200) {
        message.success(`User created successfully`);
        refetch();
      } else {
        message.error(`Error creating user`);
      }
    }
    form.resetFields();
    navigate("/settings/users/");
  };

  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        onClick={() => form.submit()}
        style={{ marginRight: 5 }}
      >
        {" "}
        Save
      </Button>
      <Button size="small" htmlType="submit" onClick={() => form.resetFields()}>
        {" "}
        Reset
      </Button>
    </div>
  );

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader
          title={
            <Title level={5}>
              {userDetails ? `Update User ` : `Create New User`}
            </Title>
          }
          extra={extra}
        />

        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel
              header={<b>{userDetails ? `Edit User` : `Create User`}</b>}
              key="1"
            >
              <Form
                layout="vertical"
                onFinish={onFinish}
                form={form}
                initialValues={{
                  name: userDetails?.name || "",
                  email: userDetails?.email || "",
                  phone: userDetails?.phone || "",
                  password: userDetails?.password || "",
                  confirm_password: userDetails?.password || "",
                  role_id: userDetails?.role_id || "",
                  department_id: userDetails?.department_id || "",
                  user_status:
                    userDetails?.user_status === 1 ||
                    userDetails?.user_status === true ||
                    userDetails?.user_status === "Active"
                      ? true
                      : false,
                }}
              >
                <UserForm 
                  userDetails={userDetails}
                />
              </Form>
            </Panel>
          </Collapse>
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default CreateUser;
