import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  Button,
  message,
  Table,
  Row,
  Col,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  InputNumber,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../CommonComponents/ExtraContent";
import FilterComponent from "../CommonComponents/FilterComponent";
import {
  getData,
  postData,
  deleteData,
  putData,
} from "../../../apiServices/common";
import {
  OB_SAM_DATA_BANK_LIST,
  OB_SAM_DATA_BANK_ADD,
  OB_SAM_DATA_BANK_EDIT,
  OB_SAM_DATA_BANK_DELETE,
  OB_MACHINE_LIST,
  OB_SECTION_LIST,
  SAM_IMPORT_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../utils/functions";
import {
  OB_SAM_DATA_BANK_CREATE_PERMISSION,
  OB_SAM_DATA_BANK_DELETE_PERMISSION,
  OB_SAM_DATA_BANK_EDIT_PERMISSION,
} from "../../../routes/permissions";
import { useLocation } from "react-router";

const OBSAMBankConfig = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [obMachineList, setObMachineList] = useState(null);
  const [obSectionList, setObSectionList] = useState(null);

  // Antd
  const [ObSamBankForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllObSamDataBank = useCallback(
    async (filterValues) => {
      const { per_page, name } = filterValues || {};

      setLoading(true);

      const query = `${OB_SAM_DATA_BANK_LIST}?page=${currentPage}`;
      const bodyData = {
        ...(per_page && { per_page: per_page }),
        ...(name && { name: name }),
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting OB SAM Bank data");
      }
    },
    [currentPage],
  );

  const getAllObMachine = async (filterValues) => {
    setLoading(true);

    const query = `${OB_MACHINE_LIST}`;
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);
    if (response?.status === 200) {
      setLoading(false);
      setObMachineList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting OB Machine data");
    }
  };

  const getAllObSection = async (filterValues) => {
    setLoading(true);

    const query = `${OB_SECTION_LIST}`;
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);
    if (response?.status === 200) {
      setLoading(false);
      setObSectionList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting OB Section data");
    }
  };

  // Effects
  useEffect(() => {
    getAllObSamDataBank(filterData);
  }, [refresh, filterData, getAllObSamDataBank]);

  useEffect(() => {
    getAllObMachine();
    getAllObSection();
  }, []);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    ObSamBankForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedObSamBank = async (id) => {
    setLoading(true);
    const response = await deleteData(`${OB_SAM_DATA_BANK_DELETE}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("OB SAM Data Bank Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedObSamBank = async (formValues) => {
    setLoading(true);

    const bodyData = {
      id: formValues?.id,
      section_id: formValues?.section_id,
      machine_type_id: formValues?.machine_type_id,
      name: formValues?.name,
      condition: formValues?.condition,
      sam: formValues?.sam,
      status: formValues?.status,
    };

    const response = await putData(
      `${OB_SAM_DATA_BANK_EDIT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("OB SAM Data Bank Edited Successfully");
      refetch();
      ObSamBankForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewOBSamBank = async (formValues) => {
    setLoading(true);

    const bodyData = {
      id: formValues?.id,
      section_id: formValues?.section_id,
      machine_type_id: formValues?.machine_type_id,
      name: formValues?.name,
      condition: formValues?.condition,
      sam: formValues?.sam,
      status: formValues?.status,
    };

    const response = await postData(OB_SAM_DATA_BANK_ADD, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("OB SAM Data Bank Created Successfully");
      refetch();
      ObSamBankForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    // Make data object for machine and section
    const machineData = {
      id: record?.ob_machine_type_info?.id,
      name: record?.ob_machine_type_info?.name,
      status: record?.ob_machine_type_info?.status,
      description: record?.ob_machine_type_info?.description,
    };
    const sectionData = {
      id: record?.ob_section_info?.id,
      name: record?.ob_section_info?.name,
      status: record?.ob_section_info?.status,
      description: record?.ob_section_info?.description,
    };

    const obMachineExists = obMachineList?.find((item) => item.id === record?.ob_machine_type_info?.id);
    const obSectionExists = obSectionList?.find((item) => item.id === record?.ob_section_info?.id);

    // If not exits then push the object values to the state array
    if(!obMachineExists) {
      const obMachineListCopy = [...obMachineList];
      obMachineListCopy.push(machineData);
      setObMachineList(obMachineListCopy);
    }

    if(!obSectionExists) {
      const obSectionListCopy = [...obSectionList];
      obSectionListCopy.push(sectionData);
      setObSectionList(obSectionListCopy);
    }

    setIsEdit(true);
    ObSamBankForm.setFieldsValue({
      id: record?.id,
      section_id: record?.section_id,
      machine_type_id: record?.machine_type_id,
      name: record?.name,
      condition: record?.condition,
      sam: record?.sam,
      status: record?.status ? true : false,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: ["id"],
      key: "id",
      width: "5%",
    },
    {
      title: "Section",
      dataIndex: ["ob_section_info", "name"],
      key: ["ob_section_info", "name"],
    },
    {
      title: "Machine",
      dataIndex: ["ob_machine_type_info", "name"],
      key: ["ob_machine_type_info", "name"],
    },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "name",
    },
    {
      title: "Condition",
      dataIndex: ["condition"],
      key: "condition",
    },
    {
      title: "SAM",
      dataIndex: ["sam"],
      key: "sam",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      render: (_, record) => {
        return <div>{record?.status ? "Active" : "Inactive"}</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedObSamBank(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([
                      OB_SAM_DATA_BANK_DELETE_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([
                      OB_SAM_DATA_BANK_EDIT_PERMISSION,
                    ]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
                <ExtraContent
                  refresh={refresh}
                  setRefresh={setRefresh}
                  handleAddItemOpen={onModalOpen}
                  disabled={
                    !hasPermission([OB_SAM_DATA_BANK_CREATE_PERMISSION])
                  }
                  addButtonText={
                    hasPermission([OB_SAM_DATA_BANK_CREATE_PERMISSION])
                      ? "Add New"
                      : "No Permission"
                  }
                  showFilter={showFilter}
                  setShowFilter={setShowFilter}
                  hasImportButton
                  uploadAPI={SAM_IMPORT_ENDPOINT}
                  acceptedFileTypes={".csv"}
                />
            }
            title={<Title level={5}>OB SAM Data Bank Configuration</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllObSamDataBank}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={
          <span style={{ fontSize: "14px" }}>
            {isEdit ? "Edit OB SAM Data Bank" : "Create OB SAM Data Bank"}
          </span>
        }
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={ObSamBankForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedObSamBank : createNewOBSamBank}
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="id"
                placeholder="Unique ID"
                label="ID"
                rules={[
                  {
                    required: true,
                    message: "ID Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  // precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="machine_type_id"
                placeholder="OB Machine"
                label="OB Machine"
                rules={[
                  {
                    required: true,
                    message: "OB Machine ID Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select OB Machine"
                  showSearch
                  onSearch={(value) => getAllObMachine({name: value})}
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  size="small"
                >
                  {obMachineList?.map((item, index) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="section_id"
                placeholder="OB Section"
                label="OB Section"
                rules={[
                  {
                    required: true,
                    message: "OB Section ID Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select OB Section"
                  onSearch={(value) => getAllObSection({name: value})}
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  showSearch
                  size="small"
                >
                  {obSectionList?.map((item, index) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                placeholder="Name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name Required",
                  },
                ]}
              >
                <Input size="small" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="condition"
                placeholder="Condition"
                label="Condition"
                rules={[
                  {
                    required: false,
                    message: "Condition Required",
                  },
                ]}
              >
                <Input size="small" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sam"
                placeholder="SAM"
                label="SAM"
                rules={[
                  {
                    required: true,
                    message: "SAM Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="status"
                placeholder="Status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Status Required",
                  },
                ]}
                valuePropName="checked"
                initialValue={true}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
                size="small"
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
                size="small"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default OBSAMBankConfig;
