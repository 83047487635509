import { useEffect } from "react";
import CustomFilter from "../../../Common/CustomFilter";

const FilterComponent = (props) => {
  // Props
  const {
    tableData,
    filterAPI,
    filterName,
    searchButtonText,
    defaultOpen,
    showFilter,
    setShowFilter,
    isPreview = false,
    previewFunction = () => {},
    filterValues,
    setFilterValues,
  } = props;

  useEffect(() => {
    if (defaultOpen) {
      setShowFilter(true);
    }
  }, [defaultOpen, setShowFilter]);

  useEffect(() => {
    if (defaultOpen) {
      setShowFilter(true);
    }
  }, [defaultOpen, setShowFilter]);

  return (
    <CustomFilter
      showFilter={showFilter}
      tableData={tableData}
      fetchTableData={filterAPI}
      filterName={filterName ? filterName : "configuration"}
      searchButtonText={searchButtonText}
      isPreview={isPreview}
      previewFunction={previewFunction}
      filterValues={filterValues}
      setFilterValues={setFilterValues}
    />
  );
};

export default FilterComponent;
