import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Button, Affix } from "antd";
import { useLocation } from "react-router-dom";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { postData, getData } from "../../../apiServices/common";
import AddPurchaseOrderForm from "./AddPurchaseOrderForm";
import { SELECTED_PO_NEW_EDIT_INFO } from "apiServices/API_ENDPOINTS";
import HandleNotification from "common/Notification";

export default function UpdatePurchaseOrder(props) {
  // Props
  const { width } = props;

  // States
  const [editInfo, setEditInfo] = useState();

  // Others
  const propertyFormRef = useRef(null);
  const location = useLocation();

  const addProduct = () => {
    propertyFormRef.current.submit();
  };

  const getPOInfo = useCallback(async (params) => {
    const query = `${SELECTED_PO_NEW_EDIT_INFO}`;
    const bodyData = {
      buying_office_id: parseInt(params.buying_office_id) || null,
      sales_contract_id: parseInt(params.sales_contract_id) || null,
      buyer_id: parseInt(params.buyer_id) || null,
      po_map_id: parseInt(params.po_map_id) || null,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setEditInfo(response?.data);
    } else {
      HandleNotification("error", "bottomRight", "Error getting data", null);
    }
  }, []);

  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(location.search);

    // Extracting query parameters
    const queryParamsObj = {
      sales_contract_id: searchParams.get("sales_contract_id"),
      buying_office_id: searchParams.get("buying_office_id"),
      buyer_id: searchParams.get("buyer_id"),
      po_map_id: searchParams.get("po_map_id"),
    };

    if (queryParamsObj) {
      getPOInfo(queryParamsObj);
    }
  }, [location.search, getPOInfo]);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Update Purchase Order"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
              >
                Update Purchase Order
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddPurchaseOrderForm ref={propertyFormRef} editInfo={editInfo} mode="update" />
    </>
  );
}
