export const GET_ITEM_ENDPOINT = `/api/items`;
export const CREATE_ITEM_ENDPOINT = `/api/items/add/`;
export const DELETE_ITEM_ENDPOINT = `/api/items/delete`;
export const EDIT_ITEM_ENDPOINT = `/api/items/edit`;
export const CUTTING_LIST_ENDPOINT = `/api/cutting`;
export const GET_CUTTING_TABLE_ENDPOINT = `/api/cutting/product/create`;
export const STORE_CUTTING_ENDPOINT = `/api/cutting/add`;
export const STORE_CUTTING_PRODUCT_ENDPOINT = `/api/cutting/product/store`;
export const GET_CUTTING_PRODUCT_ENDPOINT = `/api/cutting/edit-product`;
export const EDIT_CUTTING_PRODUCT_ENDPOINT = `/api/cutting/product/edit`;
export const STORE_CUTTING_ITEM_ENDPOINT = `/api/cutting/itemAdd`;
export const GET_CUTTING_ENDPOINT = `/api/cutting/item/create`;
export const STORE_SHIPMENT_ENDPOINT = `/api/shipping/add`;
export const GET_SHIPMENT_ENDPOINT = `/api/shipping/create/by-date`;
export const GET_FOLDING_ENDPOINT = `/api/folding/create/by-date`;
export const STORE_PACKING_ENDPOINT = `/api/packing/add`;
export const GET_PACKING_ENDPOINT = `/api/packing/create/by-date`;
export const GET_STYLE_LIST_ENDPOINT = `/api/styles`;
export const GET_DASHBOARD_ENDPOINT = `/api/dashboard`;
export const GET_CATEGORY_ENDPOINT = `/api/itemscategories`;
export const CREATE_CATEGORY_ENDPOINT = `/api/itemscategories/add`;
export const DELETE_CATEGORY_ENDPOINT = `/api/itemscategories/delete`;
export const EDIT_CATEGORY_ENDPOINT = `/api/itemscategories/edit`;
export const GET_USERS_ENDPOINT = `/api/users`;
export const GET_USERS_LIST_ENDPOINT = `/api/usersList`;
export const GET_ALL_USERS_ENDPOINT = `/api/users-all`;
export const CREATE_USER_ENDPOINT = `/api/users/store`;
export const DELETE_USER_ENDPOINT = `/api/users/delete`;
export const EDIT_USER_ENDPOINT = `/api/users/edit`;
export const GET_USERS_ROLE_ENDPOINT = `/api/roles`;
export const CREATE_USER_ROLE_ENDPOINT = `/api/roles/store`;
export const DELETE_USER_ROLE_ENDPOINT = `/api/roles/delete`;
export const EDIT_USER_ROLE_ENDPOINT = `/api/roles/edit`;
export const GET_USER_PERMISSIONS = `/api/permissions`;
export const GET_SEWING_LINE_ENDPOINT = `/api/sewing_lines`;
export const CREATE_SEWING_LINE_ENDPOINT = `/api/sewing_lines/add`;
export const DELETE_SEWING_LINE_ENDPOINT = `/api/sewing_lines/delete`;
export const EDIT_SEWING_LINE_ENDPOINT = `/api/sewing_lines/edit`;
export const CREATE_BRANCH_ENDPOINT = `/api/branches/add`;
export const GET_BANK_ENDPOINT = `/api/banks`;
export const CREATE_BANK_ENDPOINT = `/api/banks/add`;
export const DELETE_BANK_ENDPOINT = `/api/banks/delete`;
export const EDIT_BANK_ENDPOINT = `/api/banks/edit`;
export const GET_BOM_ENDPOINT = `api/bill_of_material`;
export const CREATE_BOM_ENDPOINT = `/api/bill_of_material/add`;
export const DELETE_BOM_ENDPOINT = `/api/bill_of_material/delete`;
export const EDIT_BOM_ENDPOINT = `/api/bill_of_material/edit`;
export const GET_BOM_DETAILS_ENDPOINT = `/api/bill_of_material/edit`;
export const GET_BOM_ENDPOINT_LIST = `/api/bill_of_material`;
export const UPDATE_BOM = `/api/bill_of_material/edit/`;
export const GET_INVENTORY_ENDPOINT = `api/inventory`;
export const CREATE_INVENTORY_ENDPOINT = `/api/inventory/add`;
export const DELETE_INVENTORY_ENDPOINT = `/api/inventory/delete`;
export const EDIT_INVENTORY_ENDPOINT = `/api/inventory/edit`;
export const GET_ORGANIZATION_ENDPOINT = `/api/organizations`;
export const CREATE_ORGANIZATION_ENDPOINT = `/api/organizations/add`;
export const DELETE_ORGANIZATION_ENDPOINT = `/api/organizations/delete`;
export const EDIT_ORGANIZATION_ENDPOINT = `/api/organizations/edit`;
export const GET_BRAND_ENDPOINT = `/api/brands`;
export const CREATE_BRAND_ENDPOINT = `/api/brands/add`;
export const DELETE_BRAND_ENDPOINT = `/api/brands/delete`;
export const EDIT_BRAND_ENDPOINT = `/api/brands/edit`;
export const GET_BUYER_ENDPOINT = `/api/buyers`;
export const CREATE_BUYER_ENDPOINT = `/api/buyers/add`;
export const DELETE_BUYER_ENDPOINT = `/api/buyers/delete`;
export const EDIT_BUYER_ENDPOINT = `/api/buyers/edit`;
export const GET_COMPETITOR_ENDPOINT = `/api/competitors`;
export const CREATE_COMPETITOR_ENDPOINT = `/api/competitors/add`;
export const DELETE_COMPETITOR_ENDPOINT = `/api/competitors/delete`;
export const EDIT_COMPETITOR_ENDPOINT = `/api/competitors/edit`;
export const GET_SUPPLIER_ENDPOINT = `/api/suppliers`;
export const CREATE_SUPPLIER_ENDPOINT = `/api/suppliers/add`;
export const DELETE_SUPPLIER_ENDPOINT = `/api/suppliers/delete`;
export const EDIT_SUPPLIER_ENDPOINT = `/api/suppliers/edit`;
export const GET_BUYING_OFFICE_ENDPOINT = `/api/buying_office`;
export const CREATE_BUYING_OFFICE_ENDPOINT = `/api/buying_office/add`;
export const DELETE_BUYING_OFFICE_ENDPOINT = `/api/buying_office/delete`;
export const EDIT_BUYING_OFFICE_ENDPOINT = `/api/buying_office/edit`;
export const STYLE_MERCHANDISING_LIST = `/api/styles`;
export const STYLE_MERCHANDISING_ADD = `/api/styles/add`;
export const STYLE_MERCHANDISING_UPDATE = `/api/styles/edit/`;
export const SALES_CONTRACT_360_LIST = `/api/sales_contact`;
export const SALES_CONTRACT_LIST = `/api/sales_contract`;
export const ALL_SALES_CONTRACT_LIST = `/api/accounting/all-sales-contract`;
export const COMMERCIAL_SALES_CONTRACT_LIST = `/api/accounting/commercial-sales-contract`;
export const SALES_CONTRACT_ADD = `/api/sales_contract/add`;
export const ORDER_DETAILS_LIST = `/api/sales_contract`;
export const PURCHAS_ORDER_LIST = `/api/purchase_orders`;
export const PO_ADD = `/api/purchase_orders/add`;
export const GET_BANK_ACCOUNT_ENDPOINT = `/api/bank_accounts`;
export const CREATE_BANK_ACCOUNT_ENDPOINT = `/api/bank_accounts/add`;
export const DELETE_BANK_ACCOUNT_ENDPOINT = `/api/bank_accounts/delete`;
export const EDIT_BANK_ACCOUNT_ENDPOINT = `/api/bank_accounts/edit`;
export const SALES_CONTRACT_STYLE_DETAILS_LIST = `/api/styles/view`;
export const GET_MERCHANDISER_ENDPOINT = `/api/merchandisers`;
export const DELETE_MERCHANDISER_ENDPOINT = `/api/merchandisers/delete/`;
export const EDIT_MERCHANDISER_ENDPOINT = `/api/merchandisers/edit/`;
export const CREATE_MERCHANDISER_ENDPOINT = `/api/merchandisers/add/`;
export const GET_SALES_CONTRACT_ENDPOINT = `/api/sales_contract`;
export const DELETE_SALES_CONTRACT_ENDPOINT = `/api/sales_contract/delete`;
export const EDIT_SALES_CONTRACT_ENDPOINT = `/api/sales_contract/edit`;
export const CREATE_SALES_CONTRACT_ENDPOINT = `/api/sales_contract/add`;
export const GET_COLORS = `/api/colors`;
export const ADD_COLORS = `/api/colors/add`;
export const ADD_SIZE = `/api/sizes/add`;
export const GET_SIZES = `/api/sizes`;
export const GET_ITEM_CATEGORIES = `/api/itemscategories`;
export const GET_ITEM_CATEGORIES_API = `/api/itemscategories/api`;
export const GET_ALL_UNITS = `/api/units`;
export const SALES_ADD_DOC = `/api/sales_contract/add-document`;
export const STYLE_ADD_DOC = `/api/styles/add-document`;
export const SALES_CONTRACT_UPDATE = `/api/sales_contract/edit/`;
export const PO_UPDATE = `/api/purchase_orders/edit/`;
export const IMPORT_PI_ADD_DOC = `/api/accounting/import-pi/add-document`;
export const IMPORT_B2BLC_ADD_DOC = `/api/accounting/import-b2blc/add-document`;
export const OT_SIGNAL_SECTION_WITH_OT_SIGNALS_AND_LINE_ID = `/api/ot-signal-sections-with-ot-signals-and-line-id`;
export const DECISION_POINT_AVAILABILIY = `/api/decisionPointAvailability`;
export const GET_SALES_CONTRACT_DETAILS_ENDPOINT = (salesContractId) => {
  if (salesContractId) {
    return `/api/sales_contract/${salesContractId}/view`;
  } else {
    return null;
  }
};
export const ASSIGNMENT_LIST_ENDPOINT = `/api/assignment/`;
export const SEWING_LIST = `/api/sewing`;
export const GET_ASSIGNMENT_SUMMARY = `/api/assignment/summary-generate`;
export const GET_LINE_ASSIGNMENT = `/api/assignment/line-assignment`;
export const ADD_ASSIGNMENT = `/api/assignment/add`;
export const ADD_UNIT = `/api/units/add`;
export const SWING_ADD_VIEW = `/api/sewing/create/by-subStyle`;
export const SWING_ADD = `/api/sewing/add`;
export const PREVIOUS_STYLE_LIST = `/api/bill_of_material/previous-styles`;
export const PO_WISE_COLOR = `/api/purchase_orders/colors`;
// User List
export const USER_LIST = `/api/users`;
/// Accounting
export const FORWARDER_LIST = `/api/accounting/forwarder`;
export const FORWARDER_ADD = `/api/accounting/forwarder/add`;
export const IOU_LIST = `/api/accounting/ious`;
export const IOU_ADD = `/api/accounting/iou/add`;
export const IOU_EDIT = `/api/accounting/iou/edit`;
export const SALES_CONTRACT_COMMERCIAL_LIST = `/api/accounting/sales-contract-commercials`;
export const SALES_CONTRACT_COMMERCIAL_ADD = `/api/accounting/sales-contract-commercial/add`;
export const SALES_CONTRACT_COMMERCIAL_ADD_DELETE_REASON = `/api/accounting/sales-contract-commercial/add-delete-reason`;
export const SALES_CONTRACT_COMMERCIAL_EDIT = `/api/accounting/sales-contract-commercial/edit`;
export const SALES_CONTRACT_COMMERCIAL_DOC = `/api/accounting/sales-contract-commercial/add-document`;
export const SALES_CONTRACT_WITHOUT_COMMERCIAL_LIST = `/api/accounting/sales-contract-without-commercials`;
export const ALL_SALES_CONTRACT_COMMERCIAL_LIST = `/api/accounting/all-sales-contract-commercials`;
export const IMPORT_COVER_NOT = `/api/accounting/cover-note`;
export const IMPORT_COVER_NOT_ADD = `/api/accounting/cover-note/add`;
export const IMPORT_B2B_LC_LIST = `/api/accounting/import-b2blcs`;
export const IMPORT_B2B_LC_ADD = `/api/accounting/import-b2blc/add`;
export const IMPORT_B2B_LC_EDIT = `/api/accounting/import-b2blc/edit`;
export const IMPORT_PI_LIST = `/api/accounting/import-pis`;
export const IMPORT_PI_ADD = `/api/accounting/import-pi/add`;
export const IMPORT_PI_EDIT = `/api/accounting/import-pi/edit`;
export const IMPORT_PI_VIEW = `/api/accounting/import-pi/view`;
export const IMPORT_PI_UPDATE = `/api/accounting/import-pi/update`;
export const IMPORT_DETAIL_LIST = `/api/accounting/import-details`;
export const IMPORT_DETAIL_ADD = `/api/accounting/import-detail/add`;
export const IMPORT_DETAIL_EDIT = `/api/accounting/import-detail/edit`;
export const IMPORT_DETAIL_ADD_DOC = `/api/accounting/import-detail/add-document`;
export const IMPORT_DETAIL_ALL_SUPPLIERS = `/api/accounting/get-all-suppliers`;
export const IMPORT_ACCEPTANCE_DETAIL_LIST = `/api/accounting/import-acceptance-details`;
export const IMPORT_ACCEPTANCE_DETAIL_ADD = `/api/accounting/import-acceptance-detail/add`;
export const IMPORT_ACCEPTANCE_DETAIL_EDIT = `/api/accounting/import-acceptance-detail/edit`;
export const SECURITY_BOLT_SEAL_LIST = `/api/accounting/security-bolt-seal`;
export const SECURITY_BOLT_SEAL_ADD = `/api/accounting/security-bolt-seal/add`;
export const SECURITY_BOLT_SEAL_EDIT = `/api/accounting/security-bolt-seal/edit`;
export const SECURITY_BOLT_SEAL_BUYERS = `/api/accounting/buyers`;
export const SECURITY_BOLT_SEAL_DELETE = `/api/accounting/security-bolt-seal/delete`;
export const SHIPMENT_HANDOVER_LIST = `/api/accounting/shipment-handover`;
export const SHIPMENT_HANDOVER_ADD = `/api/accounting/shipment-handover/add`;
export const SHIPMENT_HANDOVER_EDIT = `/api/accounting/shipment-handover/edit`;
export const SHIPMENT_HANDOVER_BUYERS = `/api/accounting/buyers`;
export const SHIPMENT_HANDOVER_DELETE = `/api/accounting/shipment-handover/delete`;
export const GET_ASSIGN_SUMMARY_ENDPOINT = `/api/track-items/create`;
export const ASSIGN_TRACK_ENDPOINT = `/api/track-items/store`;
export const WAREHOUSE_LIST_ENDPOINT = `/api/warehouses`;
export const WAREHOUSE_ADD_ENDPOINT = `/api/warehouses/add`;
export const WAREHOUSE_EDIT_ENDPOINT = `/api/warehouses/edit`;
export const WAREHOUSE_DELETE_ENDPOINT = `/api/warehouses/delete`;
export const RACK_LIST_ENDPOINT = `/api/racks`;
export const RACK_ADD_ENDPOINT = `/api/racks/add`;
export const RACK_EDIT_ENDPOINT = `/api/racks/edit`;
export const RACK_DELETE_ENDPOINT = `/api/racks/delete`;
export const BIN_LIST_ENDPOINT = `/api/bins`;
export const BIN_ADD_ENDPOINT = `/api/bins/add`;
export const BIN_EDIT_ENDPOINT = `/api/bins/edit`;
export const BIN_DELETE_ENDPOINT = `/api/bins/delete`;
export const UNIT_LIST_ENDPOINT = `/api/units`;
export const UNIT_ADD_ENDPOINT = `/api/units/add`;
export const UNIT_EDIT_ENDPOINT = `/api/units/edit`;
export const UNIT_DELETE_ENDPOINT = `/api/units/delete`;
export const SUPPLIER_LIST_ENDPOINT = `/api/suppliers`;
export const SUPPLIER_ADD_ENDPOINT = `/api/suppliers/add`;
export const SUPPLIER_EDIT_ENDPOINT = `/api/suppliers/edit`;
export const SUPPLIER_DELETE_ENDPOINT = `/api/suppliers/delete`;
export const COLOR_LIST_ENDPOINT = `/api/colors`;
export const COLOR_ADD_ENDPOINT = `/api/colors/add`;
export const COLOR_EDIT_ENDPOINT = `/api/colors/edit`;
export const COLOR_DELETE_ENDPOINT = `/api/colors/delete`;
export const SIZE_LIST_ENDPOINT = `/api/sizes`;
export const SIZE_ADD_ENDPOINT = `/api/sizes/add`;
export const SIZE_EDIT_ENDPOINT = `/api/sizes/edit`;
export const SIZE_DELETE_ENDPOINT = `/api/sizes/delete`;
export const EXPORT_BOM_ENDPOINT = `/api/bill_of_material/export`;
export const TRACK_ITEM_REMOVE_ENDPOINT = `/api/track-items/delete`;
export const FIND_RACK_ENDPOINT = `/api/racks/show`;
export const FIND_BIN_ENDPOINT = `/api/bins/show`;
export const FINISH_GOOD_ENDPOINT = `/api/finish_garment`;
export const FINISH_GOOD_SELL_ENDPOINT = `/api/finish_garment/sell-add`;
export const FINISH_GOOD_SAMPLE_ENDPOINT = `/api/finish_garment/sample-add`;
export const FINISH_GOOD_GIFT_ENDPOINT = `/api/finish_garment/gift-add`;
export const REMOVE_PO_ROW = `/api/purchase_orders/remove-row`;
export const REMOVE_SIZE_COLUMN = `/api/purchase_orders/remove-column`;
export const UNIT_CONVERTION_ADD_ENDPOINT = `/api/conversions/add`;
export const UNIT_CONVERTION_LIST_ENDPOINT = `/api/conversions`;
export const UNIT_CONVERTION_EDIT_ENDPOINT = `/api/conversions/edit`;
export const UNIT_CONVERTION_DELETE_ENDPOINT = `/api/conversions/delete`;
export const GET_IMPORT_PI_BUDGET_ENDPOINT = `/api/accounting/get-importpi-budget`;
export const GET_PI_NUMBERS_LIST = `/api/accounting/pi-numbers-list-salescontract`;
export const EXPORT_DETAILS_ADD = `/api/accounting/export-details/add`;
export const GET_EXPORT_DETAILS_LIST = `/api/accounting/export-details`;
export const DELETE_EXPORT_DETAILS = `/api/accounting/export-details/delete`;
export const EDIT_EXPORT_DETAILS = `/api/accounting/export-details/edit`;
export const ADD_EXPORT_GOODS_DESCRIPTION = `/api/accounting/export-details/add-goods-description`;
export const EXPORT_DETAIL_GOODS_DESCRIPTIONS_LIST = `/api/accounting/export-detail-goods-descriptions`;
export const SUBMISSION_REALIZATION_ADD = `/api/accounting/submission-realizations/add`;
export const GET_SUBMISSION_REALIZATION_LIST = `/api/accounting/submission-realizations`;
export const DELETE_SUBMISSION_REALIZATION = `/api/accounting/submission-realizations/delete`;
export const VIEW_SELECTED_SUBMISSION_REALIZATION = `/api/accounting/submission-realizations/edit`;
export const SUBMISSION_REALIZATION_DOC = `/api/accounting/submission-realizations/add-document`;
export const SUBMISSION_REALIZATION_INVOICE_DOCUMENT = `/api/accounting/submission-realizations/add-invoice-document`;
export const SUBMISSION_REALIZATION_INVOICE_SEARCH = `/api/accounting/submission-realizations/searchInvoice`;
export const GET_CUSTOMS_CLEARANCE_LIST = `/api/accounting/customs-clearances`;
export const ADD_CUSTOMS_CLEARANCE = `/api/accounting/customs-clearances/add`;
export const VIEW_CUSTOMS_CLEARANCE = `/api/accounting/customs-clearances/edit`;
export const DELETE_CUSTOMS_CLEARANCE = `/api/accounting/customs-clearances/delete`;
export const NOTING_ASSESSMENT_DOC = `/api/accounting/noting-assessments/add-document`;
export const GET_B2BLCS_FOR_NOTING = `/api/accounting/b2blcs-for-noting`;
export const GET_INVOICES_FOR_NOTING = `/api/accounting/get-shipment-invoices`;
export const GET_B2B_LC_LIST = `/api/accounting/get-b2blc`;
export const GET_PO_INFO = `/api/findPurchaseOrderInfo`;
export const GOODS_DELIVERY_ADD = `/api/accounting/goods-delivery/add`;
export const GET_GOODS_DELIVERY_LIST = `/api/accounting/goods-delivery`;
export const DELETE_GOODS_DELIVERY = `/api/accounting/goods-delivery/delete`;
export const EDIT_GOODS_DELIVERY = `/api/accounting/goods-delivery/edit`;
export const GET_FABRIC_RECONCILIATION = `/api/report/all_fabric_reconciliations`;
export const EXPORT_FABRIC_RECONCILIATION = `/api/report/export_fabric_reconciliation_report`;
export const EXPORT_SWING_VS_FOLDING = `/api/report/export_sewing_and_folding_report`;
export const DELETE_FABRIC_RECONCILIATION = `/api/report/delete_fabric_reconciliation_report`;
export const GET_PRODUCTION_TRACKING = `/api/report/all_production_tracks`;
export const EXPORT_PRODUCTION_TRACKING = `/api/report/export_production_tracking_report`;
export const DELETE_PRODUCTION_TRACKING = `/api/report/delete_production_tracking_report`;
export const GET_PRODUCTION_DETAILS = `/api/report/all_production_details`;
export const EXPORT_PRODUCTION_DETAILS = `/api/report/export_production_details_report`;
export const DELETE_PRODUCTION_DETAILS = `/api/report/delete_production_details_report`;
export const GET_COST_SHEET = `/api/report/all_cost_sheet`;
export const EXPORT_COST_SHEET = `/api/report/export_cost_sheet_report`;
export const DELETE_COST_SHEET = `/api/report/delete_cost_sheet_report`;
export const GET_EXPORT_DETAILS_INVOICE = `/api/accounting/export-detail-invoices`;
export const UPLOAD_SIZE = `/api/upload-size`;
export const BACKUP_FILES_LIST = `/api/backupList`;
export const SAMPLE_FILES_LIST = `/api/allTemplates`;
export const RAW_MATERIAL_ADD = `/api/raw_material_inventory/add`;
export const RAW_MATERIAL_ITEM_BY_TIME = `/api/raw_material_inventory/findItem`;
export const RAW_MATERIAL_UPDATE = `/api/raw_material_inventory/edit`;
export const GET_INVENTORY_DETAILS = `/api/report/all_inventory_details`;
export const EXPORT_INVENTORY_DETAILS = `/api/report/export_inventory_details_report`;
export const DELETE_INVENTORY_DETAILS = `/api/report/delete_inventory_details_report`;
export const PURCHAS_ORDER_LIST_NEW = `/api/po_wise_purchase_orders`;
export const PO_ADD_NEW = `/api/po_wise_purchase_orders/add`;
export const PO_UPDATE_NEW = `/api/po_wise_purchase_orders/edit/`;
export const GET_PO_DETAILS_INFO = `/api/po_wise_purchase_orders/edit_view`;
export const GET_ALL_ACTIVITY_LOGS = `/api/activity-logs`;
export const NOTIFICATIONS_LIST = `/api/notifications`;
export const SEND_PI_NOTIFICATION = `/api/accounting/send-pi-notification`;
export const MARK_ALL_READ_NOTIFICATION = `/api/accounting/mark-notification-read`;
export const COUNT_UNREAD_NOTIFICATION = `/api/accounting/count-unread-notifications`;
export const ACTIVITY_LOG_REPORT_EXPORT = `/api/report/export_activity_logs_report`;
export const ACTIVITY_LOG_REPORT_LIST = `/api/report/all_activity_logs`;
export const DELETE_ACTIVITY_LOG_REPORT = `/api/report/delete_activity_logs_report`;
export const PO_MAP_ENDPOINT = `/api/packing_list/purchase_order_map`;
export const PACKING_STYLE_LIST = `/api/packing_list/style_list`;
export const PACKING_COLOR_LIST = `/api/packing_list/color_list`;
export const PACKING_SIZE_LIST = `/api/packing_list/size_list`;
export const PO_WISE_PACKING_ADD = `/api/packing_list/add`;
export const GET_INVENTORY_MRR_REPORT = `/api/report/all_inventory_mrr`;
export const VIEW_INVENTORY_MRR_REPORT = `/api/report/view_inventory_mrr_report`;
export const EXPORT_INVENTORY_MRR_REPORT = `/api/report/export_inventory_mrr_report`;
export const DELETE_INVENTORY_MRR_REPORT = `/api/report/delete_inventory_mrr_report`;
export const PACKING_LIST = `/api/packing_list`;
export const PO_WISE_PACKING_UPDATE = `/api/packing_list/edit`;
export const PACKING_CONFIRMATION_LIST = `/api/packing/packing_confirmation_list`;
export const EXISTING_PO_MAP_LIST = `/api/packing_list/existing_purchase_order_map`;
export const PACKING_CONFIRM = `/api/packing/packing_confirmation/store`;
export const GET_PACKING_LIST_REPORT = `/api/report/all_packing_list`;
export const EXPORT_PACKING_LIST_REPORT = `/api/report/export_packing_list_report`;
export const DELETE_PACKING_LIST_REPORT = `/api/report/delete_packing_list_report`;
export const OB_MACHINE_ADD = `/api/ob-machine-types/add`;
export const OB_MACHINE_LIST = `/api/ob-machine-types`;
export const OB_MACHINE_DELETE = `/api/ob-machine-types/delete`;
export const OB_MACHINE_EDIT = `/api/ob-machine-types/edit`;
export const OB_SECTION_LIST = `/api/ob-sections`;
export const OB_SECTION_ADD = `/api/ob-sections/add`;
export const OB_SECTION_DELETE = `/api/ob-sections/delete`;
export const OB_SECTION_EDIT = `/api/ob-sections/edit`;
export const OB_SAM_DATA_BANK_LIST = `/api/sam-data-banks`;
export const OB_SAM_DATA_BANK_ADD = `/api/sam-data-banks/add`;
export const OB_SAM_DATA_BANK_DELETE = `/api/sam-data-banks/delete`;
export const OB_SAM_DATA_BANK_EDIT = `/api/sam-data-banks/edit`;
export const USER_LOGIN = `/api/login`;
export const OB_STYLE = `/api/obs/styles`;
export const OB_ADD = `/api/obs/add`;
export const OB_LIST = `/api/obs`;
export const OB_DELETE = `/api/obs/delete`;
export const OB_EDIT = `/api/obs/edit`;
export const OB_SHOW = `/api/obs/show`;
export const OB_CLONE = `/api/obs/clone`;
export const EXPORT_SALES_TYPES = `/api/accounting/freight-type`;
export const EXPORT_SALES_TYPES_ADD = `/api/accounting/freight-type/add`;
export const STYLE_WISE_COST_LIST = `/api/accounting/style-wise-cost-estimates`;
export const STYLE_WISE_COST_ADD = `/api/accounting/style-wise-cost-estimate/add`;
export const STYLE_WISE_COST_DELETE = `/api/accounting/style-wise-cost-estimate/delete`;
export const STYLE_WISE_COST_VIEW = `/api/accounting/style-wise-cost-estimate/edit`;
export const STYLE_WISE_COST_EDIT = `/api/accounting/style-wise-cost-estimate`;
export const REST_SALES_CONTRACT = `/api/accounting/rest-sales-contracts`;
export const OB_EXCEL_REPORT = `/api/report/export_xl_ob_report`;
export const OB_PDF_REPORT = `/api/obs/pdf-view/`;
export const BOOKMARK_ADD = `/api/bookmarks/add`;
export const BOOKMARK_LIST = `/api/bookmarks`;
export const BOOKMARK_EDIT = `/api/bookmarks/edit`;
export const BOOKMARK_DELETE = `/api/bookmarks/delete`;
export const BOM = `/api/bill_of_material`;
export const REASONS_LIST = `/api/reasons`;
export const REASONS_ADD = `/api/reasons/add`;
export const REASONS_EDIT = `/api/reasons/edit`;
export const REASONS_DELETE = `/api/reasons/delete`;
export const AMENDMENT_DOC_ADD = `/api/sales_contract/add-amendment-document`;
export const AMEDMENT_ADD = `/api/sales_contract/add/amendment`;
export const EXPORT_LC_REASONS = `/api/accounting/export-lc-reasons`;
export const ADD_LC_REASON = `/api/accounting/sales-contract-commercial/add-lc-reason`;
export const ADD_B2BLC_REMARKS = `/api/accounting/add-b2blc-remarks`;
export const B2BLC_REMARKS_LIST = `/api/accounting/b2blc-remarks`;
export const B2BLC_DOCUMENT_UPLOAD = `/api/accounting/import-b2blc/add-document`;
export const ADVANCED_SEARCH = `/api/advancedSearch`;
export const GLOBAL_SEARCH = `/api/globalSearch`;
export const OB_TEMPLATES = `/api/ob_tamplates`;
export const OB_TEMPLATE_ADD = `/api/ob_tamplates/add`;
export const OB_TEMPLATE_DELETE = `/api/ob_tamplates/delete`;
export const OB_TEMPLATE_EDIT = `/api/ob_tamplates/edit`;
export const ACCEPTANCE_B2BLC_LIST = `/api/accounting/get-all-b2blcs-terms`;
export const ACCEPTANCE_B2BLC_DETAILS = `/api/accounting/import-b2blc/view`;
export const ACCEPTANCE_AVAILABLE_BALANCE = `/api/accounting/get-available-balance`;
export const ACCEPTANCE_INVOICE_NUMBERS = `/api/accounting/invoice-numbers-list`;
export const ACCEPTANCE_REMARKS = `/api/accounting/acceptance-detail-remarks`;
export const ACCEPTANCE_REMARK_ADD = `/api/accounting/add-acceptance-detail-remark`;
export const ACCEPTANCE_FROM_ADD = `/api/accounting/add-acceptance-from`;
export const ACCEPTANCE_FROMS = `/api/accounting/acceptance-froms`;
export const ACCEPTANCE_DELETE = `/api/accounting/import-acceptance-detail/delete`;
export const ACCEPTANCE_DETAILS_VIEW = `/api/accounting/import-acceptance-detail/view`;
export const ACCEPTANCE_DETAIL_DOC = `/api/accounting/import-acceptance-detail/add-document`;
export const INVOICE_BY_SUPPLIER = `/api/accounting/invoices-by-supplier`;
export const CHALLAN_LIST = `/api/challans`;
export const CHALLAN_ADD = `/api/challans/add`;
export const CHALLAN_DOC_ADD = `/api/challans/add-document`;
export const CHALLAN_UPDATE = `/api/challans/edit`;
export const CHALLAN_DELETE = `/api/challans/delete`;
export const LINE_TARGET_LIST = `/api/daily_line_targets`;
export const LINE_TARGET_ADD = `/api/daily_line_targets/add`;
export const LINE_TARGET_UPDATE = `/api/daily_line_targets/edit`;
export const LINE_TARGET_DELETE = `/api/daily_line_targets/delete`;
export const ALL_SUBSTYLES = `/api/sub-styles`;

// Notification slug & settings section
export const NOTIFICATION_SLUGS = `/api/notification-slugs`;
export const NOTIFICATION_SETTINGS = `/api/notification-settings`;
export const GET_ITEMS_CHECK = `/api/bill_of_material/getItems`;
export const IMPORT_DETAIL_DOWNLOAD = `/api/accounting/import-details-download`;
export const GET_INVOICE_IN_HOUSE_DATE = `/api/inventory`;
export const GET_INVOICE_RECONCILIATION_STATUS = `/api/invoice-reconciliation-status`;

export const INVOICE_CHALLAN_MAPPING_RESOURCE = `/api/invoice-mapping`;
export const GET_LOCAL_SUPPLIER_LISTS = `/api/local-supplier-lists`;
export const GET_SUPPLIER_INVOICE_LISTS = `/api/local-supplier-invoices`;
export const GET_SUPPLIERS_CHALLAN_LISTS = `/api/challan-list`;
export const GET_INVOICE_DETAILS = `/api/invoice-details`;
export const GET_CHALLAN_DETAILS = `/api/invoice-details`;
export const GET_ALL_LOCAL_SUPPLIERS_ALL_INVOICES = `/api/local-supplier-invoices-all`;
export const GET_HOURLY_PRODUCTION_LIST = `/api/report/all_hourly_production_list`;
export const EXPORT_HOURLY_PRODUCTION = `/api/report/export_hourly_production_report`;
export const DELETE_PRODUCTION_HOUR = `/api/report/delete_hourly_production_report`;
export const B2BLC_INVOICE_LIST = `/api/accounting/lc-invoice-list`;
export const FOC_TYPE_INVOICE_LIST = `/api/accounting/foc-invoice-list`;
export const IMPORT_VEHICLE = `/api/accounting/import-vehicle`;
export const GARMENT_CATEGORY_LIST_ENDPOINT = `/api/garment-categories`;
export const GARMENT_CATEGORY_ADD_ENDPOINT = `/api/garment-categories/add`;
export const GARMENT_CATEGORY_EDIT_ENDPOINT = `/api/garment-categories/edit`;
export const GARMENT_CATEGORY_DELETE_ENDPOINT = `/api/garment-categories/delete`;
export const GARMENT_TYPE_LIST_ENDPOINT = `/api/garment-types`;
export const GARMENT_TYPE_ADD_ENDPOINT = `/api/garment-types/add`;
export const GARMENT_TYPE_EDIT_ENDPOINT = `/api/garment-types/edit`;
export const GARMENT_TYPE_DELETE_ENDPOINT = `/api/garment-types/delete`;
export const GARMENT_FOR_LIST_ENDPOINT = `/api/garment-for`;
export const CM_EXPENSE_HEAD_RESOURCE = `/api/accounting/cm-expense-head`;
export const CM_EXPENSE_CALCULATION_RESOURCE = `/api/accounting/cm-expense-calculation`;
export const CM_DEFAULT_DATA_RESOURCE = `/api/accounting/cm-default-data`;
export const INQUIRY_REASONS_ADD_ENDPOINT = `/api/inquiry-reasons/add`;
export const INQUIRY_REASONS_LIST_ENDPOINT = `/api/inquiry-reasons`;
export const INQUIRY_REASONS_EDIT_ENDPOINT = `/api/inquiry-reasons/edit`;
export const INQUIRY_REASONS_DELETE_ENDPOINT = `/api/inquiry-reasons/delete`;
export const INQUIRY_REMARKS_ADD_ENDPOINT = `/api/inquiry-remarks/add`;
export const INQUIRY_REMARKS_LIST_ENDPOINT = `/api/inquiry-remarks`;
export const INQUIRY_REMARKS_EDIT_ENDPOINT = `/api/inquiry-remarks/edit`;
export const INQUIRY_REMARKS_DELETE_ENDPOINT = `/api/inquiry-remarks/delete`;
export const BUYER_INQUIRY_LIST_ENDPOINT = `/api/buyer-inquiries`;
export const BUYER_INQUIRY_ADD_ENDPOINT = `/api/buyer-inquiries/add`;
export const BUYER_INQUIRY_DELETE_ENDPOINT = `/api/buyer-inquiries/delete`;
export const BUYER_INQUIRY_EDIT_ENDPOINT = `/api/buyer-inquiries/edit`;
export const COST_OF_MAKING_RESOURCE = `/api/accounting/cm-report`;
export const GET_TEMPLATE_BY_GARMENT = `/api/ob_tamplates/findTamplate`;
export const CM_REPORT_DATA = `/api/accounting/cm-report`;
export const SALES_CONTRACT_SIZE_ADD = `/api/sales_contract/size-store`;
export const LEARNING_CURVE_RESOURCE = `/api/learning-curves`;
export const GET_SEWING_BY_DATE = `/api/sewing/create/by-date`;
export const GET_SELECTED_SEWING_DATA = `/api/sewing/edit`;
export const UPDATE_SEWING = `/api/sewing/edit/product`;
export const UPDATE_MANPOWER = `/api/obs/update-manpower`;
export const ITEM_CATEGORY_POSITION_FIX = `/api/itemscategories/fix-position`;
export const ALL_PERMISSIONS = `/api/permissions`;
export const PERMISSION_ADD = `/api/permissions/store`;
export const PERMISSION_EDIT = `/api/permissions/edit`;
export const PERMISSION_DELETE = `/api/permissions/delete`;
export const GET_CONVERSION_RATE = `/api/accounting/conversion-rate`;
export const BANK_NAME_ADDRESS_RESOURCE = `/api/accounting/bank-name-address`;
export const UNIT_CONVERTION = `/api/conversions/unit-conversion`;
export const GET_SALES_CONTRACT_SUPPLIERS = `/api/accounting/sales-contract-suppliers`;
export const EXPORT_DETAILS_DOC_UPLOAD = `/api/accounting/export-details/add-document`;
export const SAM_IMPORT_ENDPOINT = `/api/upload-sam`;
export const ASSIGNMENT_LINE_SUMMARY = `/api/assignment/line-assignment-summary`;
export const GET_STYLE_PO_ASSIGNMENT_SUMMARY = `/api/assignment/style-po-assignment-summary`;
export const GET_LINE_ASSIGNMENT_DETAILS = `/api/assignment/line-assignment-details`;
export const ASSIGNMENT_ADD_FORM = `/api/assignment/add-form`;
export const ASSIGNMENT_STORE = `/api/assignment/line-assignment/store`;
export const ASSIGNMENT_EDIT_VIEW = `/api/assignment/line-assignment/edit`;
export const ASSIGNMENT_UPDATE = `/api/assignment/line-assignment/update`;
export const GET_FOLDING_TABLE_ENDPOINT = `/api/folding/product/create`;
export const STORE_FOLDING_ENDPOINT = `/api/folding/product/store`;
export const GET_FOLDING_PRODUCT_ENDPOINT = `/api/folding/product/edit`;
export const FOLDING_LIST_ENDPOINT = `/api/folding`;
export const EDIT_FOLDING_PRODUCT_ENDPOINT = `/api/folding/product/update`;
export const GET_INQUIRY_NUMBER = `/api/buyer-inquiries/get-inquiry-no`;
export const GET_OB_INQUIRY_LIST = `/api/ob-inquiry-list`;
export const GET_OB_STYLE_LIST = `/api/ob-style-list`;
export const PACKING_CONF_LIST_ENDPOINT = `/api/packing/packing_confirmation`;
export const GET_PACKING_PRODUCT_ENDPOINT = `/api/packing/packing_confirmation/edit`;
export const UPDATE_PACKING_CONFIRMATION_ENDPOINT = `/api/packing/packing_confirmation/update`;
export const SALES_CONTRACT_SIZE_UPLOAD = `/api/upload-sales-contract-sizes`;
export const TRIMS_ACCESORY_CONSUMPTION = `/api/trims-accessories-consumption`;
export const TRIMS_ACCESSORY_STYLES_LIST = `/api/trims-accessories-styles`;
export const GET_ALL_B2BLCS = `/api/accounting/get-all-b2blcs`;
export const COURIER_OPTION_RESOURCE = `/api/accounting/courier-option`;
export const POST_TNA_PROCESS_DATA = "/api/tna-processes";
export const GET_ALL_TNA_PROCESS_DATA = "/api/tna-processes";
export const GET_SINGLE_TNA_PROCESS_DATA = "/api/tna-processes";
export const UPDATE_TNA_PROCESS_DATA = "/api/tna-processes";
export const DELETE_TNA_PROCESS_DATA = "/api/tna-processes";
export const POST_TNA_DEPARTMENT_DATA = "/api/tna-departments";
export const GET_ALL_TNA_DEPARTMENT_DATA = "/api/tna-departments";
export const GET_SINGLE_TNA_DEPARTMENT_DATA = "/api/tna-departments";
export const UPDATE_TNA_DEPARTMENT_DATA = "/api/tna-departments";
export const DELETE_TNA_DEPARTMENT_DATA = "/api/tna-departments";
export const POST_TNA_TASK_DATA = "/api/tna-tasks";
export const GET_ALL_TNA_TASK_DATA = "/api/tna-tasks";
export const GET_SINGLE_TNA_TASK_DATA = "/api/tna-tasks";
export const UPDATE_TNA_TASK_DATA = "/api/tna-tasks";
export const DELETE_TNA_TASK_DATA = "/api/tna-tasks";
export const TNA_TEMPLATE_BASE = `/api/tna-templates`;
export const TNA_SALES_CONTRACT = `/api/tna-plans/sales-contracts`;
export const TNA_STYLES = `/api/tna-plans/styles`;
export const TNA_POS = `/api/tna-plans/purchase-orders`;
export const TNA_TODO_ENDPOINT = `/api/tna-plans/todos`;
export const GET_TNA_SUMMAARY = `/api/tna-plans/summaries`;
export const GET_TNA_SUMMAARY_DETAILS = `/api/tna-plans/summaries`;
export const GET_TNA_SUMMAARY_GRAPH = `/api/tna-plans/summaries`;
export const TNA_PLAN_BASE = `/api/tna-plans`;
export const TNA_DUE_DATE = `/api/tna-plans/due-date`;
export const TNA_PLAN_STYLES_DETAILS = `/api/tna-plans/styles`;
export const TNA_PLAN_PURCHASE_ORDER_DETAILS = `/api/tna-plans/purchase-orders`;
export const VEHICLE_SIZE_ENDPOINT = `/api/vehicle-sizes`;
export const VEHICLE_TYPE_ENDPOINT = `/api/vehicle-types`;
export const VEHICLE_DRIVER_INFOS_ENDPOINT = `/api/vehicle-driver-infos`;
export const VEHICLE_DRIVER_INFOS_ADD_ENDPOINT = `/api/vehicle-driver-infos/add`;
export const VEHICLE_DRIVER_INFOS_EDIT_ENDPOINT = `/api/vehicle-driver-infos/edit`;
export const DRIVER_VEHICLE_INFO_DELETE_ENDPOINT = `/api/vehicle-driver-infos/delete`;
export const BOLT_SEAL_LOG_LIST_ENDPOINT = `/api/bolt-seal-logs`;
export const BOLT_SEAL_LOG_ADD_ENDPOINT = `/api/bolt-seal-logs/add`;
export const BOLT_SEAL_LOG_EDIT_ENDPOINT = `/api/bolt-seal-logs/edit`;
export const BOLT_SEAL_LOG_DELETE_ENDPOINT = `/api/bolt-seal-logs/delete`;
export const EXPORT_BOLT_SEAL_LOG_REPORT_ENDPOINT = `/api/report/export_bolt_seal_log_report`;
export const GET_BOLT_SEAL_LOG_REPORT_LIST_ENDPOINT = `/api/report/all_bolt_seal_log_report_list`;
export const VIEW_BOLT_SEAL_LOG_REPORT = `/api/report/view_bolt_seal_log_report`;
export const DELETE_BOLT_SEAL_LOG_REPORT_ENDPOINT = `/api/report/delete_bolt_seal_log_report`;
export const PO_Wise_Packing_Info_ENDPOINT = `/api/po-wise-packing-info`;
export const EXPORT_DETAILS_FROMS_RESOURCES = `/api/accounting/export-details-from`;
export const SALES_CONTRACT_COMMERCIAL_FROMS_RESOURCES = `/api/accounting/sales-contract-commercial-from`;
export const PO_WISE_INFO = `/api/po_wise_purchase_orders/po-wise-info`;
export const UPLOAD_SC_COMMERCIAL_DOC_FOR_MMD = `/api/accounting/upload-sc-commercial-doc-for-mmd`;
export const SELECTED_PO_NEW_EDIT_INFO = `/api/po_wise_purchase_orders/editView`;
export const VIEW_HOURLY_PRODUCTION = `/api/report/view_hourly_production_report`;
export const VIEW_PRODUCTION_DETAILS = `/api/report/view_production_details_report`;
export const VIEW_PRODUCTION_TRACKING = `/api/report/view_production_tracking_report`;
export const VIEW_FABRIC_RECONCILIATION = `/api/report/view_fabric_reconciliation_report`;
export const VIEW_SWING_VS_FOLDING = `/api/report/view_sewing_and_folding_report`;
export const PO_MAP_NEW_ENDPOINT = `/api/packing_list/purchase_order_map_lists`;
export const TRIMS_ACCESSORIES_ITEMS_LIST = `/api/trim-accessories-inventory/items`;
export const VIEW_PRODUCTION_DETAILS_REPORT = `/api/report/view_production_details_report`;
export const VIEW_INVENTORY_DETAILS_REPORT = `/api/report/view_inventory_details_report`;
export const EXPORT_POST_COSTING_REPORT = `/api/report/export_post_costing_report`;
export const VIEW_POST_COSTING_REPORT = `/api/report/view_post_costing_report`;
export const TRIMS_ACCESSORIES_STORE = `/api/trim-accessories-inventory/store`;
export const DIGITALIZATION_CHECKLIST = `/api/digitalization/checklist`;
export const SEWING_LINES = `/api/sewing_lines`;
export const SUPERVISOR_LIST = `/api/supervisor-list`;
export const DIGITALIZATION_ABSENT_LIST = `/api/digitalization/absinthium`;
export const DIGITALIZATION_DEFECTS = `/api/digitalization/defects`;
export const GET_TRIMS_UNIQUE_TIMES = `/api/trim-accessories-inventory/get-unique-times`;
export const GET_TRIMS_ASSIGNED_ITEMS = `/api/trim-accessories-inventory/track-items-list`;
export const UPDATE_TRIMS_INVENTORY = `/api/trim-accessories-inventory/update`;
export const USER_LOGOUT = `/api/logout`;
export const UPDATE_PASSWORD = `/api/users/updatePassword`;

// DIGITALIZATION
export const GET_DIGITALIZATION_APP_MENUS_ROLE = `/api/digitalization/app-menus`;
export const UPDATE_DIGITALIZATION_APP_MENU_ROLE = `/api/digitalization/app-menu`;
export const GET_QA_ROLE_USERS = "/api/digitalization/qa-role-users";
// DEFECT
export const DIGITALIZATION_DEFECT_CATEGORY = `/api/digitalization/defect-category-infos`;
export const DIGITALIZATION_DEFECT_REASON = `/api/digitalization/defect-reasons`;
export const DIGITALIZATION_DEFECT_RESPONSIBLE = `/api/digitalization/defect-responsibles`;
export const DIGITALIZATION_DEFECT_RECOMMENDATION = `/api/digitalization/defect-recommendations`;
export const DIGITALIZATION_DEFECT_CATEGORY_MAPPING = `/api/digitalization/defect-category-operation-mappings`;
export const DIGITALIZATION_DEFECT_LINE_SECTION_MAPPING = `/api/digitalization/line-section-qa-mappings`;

// Production Planning Weekends
export const WEEKENDS = `/api/productionPlanning/weekend`;
export const HOLIDAYS = `/api/productionPlanning/holiday`;
export const WH_EXCEPTIONS = `/api/productionPlanning/weekend-holiday-exception`;
export const WEEKEND_HOLIDAY_LIST = `/api/productionPlanning/weekend-holiday-exception/exceptionList`;
export const PLANNING_REQUESTS = `/api/productionPlanning/planning_request`; // PlanningRequests
export const UN_PLANNING_BUYER_INQUIRY_LIST = `/api/productionPlanning/buyer_inquiry_list`; // PlanningRequests

export const GET_VEHICLE_INFOS = `/api/vehicle-infos`;
export const ADD_VEHICLE_INFOS = `/api/vehicle-infos/add`;
export const EDIT_VEHICLE_INFOS = `/api/vehicle-infos/edit`;
export const DELETE_VEHICLE_INFOS = `/api/vehicle-infos/delete`;
export const GET_DRIVER_INFOS = `/api/driver-infos`;
export const ADD_DRIVER_INFOS = `/api/driver-infos/add`;
export const EDIT_DRIVER_INFOS = `/api/driver-infos/edit`;
export const DELETE_DRIVER_INFOS = `/api/driver-infos/delete`;

export const GET_LINE_LIST = `/api/sewing_lines`;
export const GET_INQUIRY_STYLE_LIST = `/api/productionPlanning/inquiry-style-list`;
export const GET_INQUIRY_STYLE_DETAILS = `/api/productionPlanning/inquiry-style-details`;
export const TRACK_LIST_FOR_TRIMS_ADD = `/api/trim-accessories-inventory/track-items-list-for-add`;
export const GENERATE_PLANNING_DETAILS = `/api/productionPlanning/generate-planning-details`;
export const GET_SEWING_LINES = `/api/digitalization/sewing-lines`;
export const GET_HOURLY_PRODUCTION_RECORDS =
  "/api/digitalization/hourly-production-record-lists";
export const GET_HOURLY_PRODUCTION_RECORD_DETAILS =
  "/api/digitalization/hourly-production-record-details";
export const GET_BUYERS = `/api/digitalization/buyers`;
export const GET_OB_INFO = `/api/digitalization/ob-info`;
export const DAILY_LINE_PLAN = `/api/digitalization/daily-line-plan`;
export const GET_ID_CARD_NO_USER = `/api/digitalization/id-card-no-user`;
export const REGENERATE_PLAN = `/api/productionPlanning/re-generate-planning`;
export const PLAN_INITIATE = `/api/productionPlanning/plan_initiate`;
export const ImportDailyLinePlan = `/api/digitalization/import-daily-line-plan`;

export const DIGITALIZATION_CHECKLIST_QUESTION = `/api/digitalization/checklist-question`;
export const DIGITALIZATION_ROLES_CHECKLIST = `/api/digitalization/web/roles-checklists`;
export const DEFECT_CATEGORY_LISTS = `/api/digitalization/defect-category-infos`;

export const GET_APP_PRODUCTION_DETAILS = `/api/digitalization/bigscreen-dashboard/productionDetailsReport`;
export const GET_APP_OPERATOR_WISE_PERFORMANCE = `/api/digitalization/bigscreen-dashboard/operatorWisePerformanceReport`;

export const ASSIGN_LINES = `/api/digitalization/user/assign-lines`;
export const APP_USER_LIST = `/api/digitalization/app-user-list`;
export const USER_ASSIGNED_LINE_LISTS = `/api/digitalization/user-assigned-line-lists`;
export const USER_ASSIGNED_LINE = `/api/digitalization/user/assigned-line`;

export const SUB_CONTRACT_FACTORY = `/api/sub-contract-factory`;
export const GET_SALES_CONTRACT_LIST = `/api/sales_contract_list`;

export const SUB_CONTRACT_AGREEMENT = `/api/sub-contract-agreement`;
export const SUBSTYLE_LIST = `/api/obs/substyles`;

export const GET_DIGITALIZATION_STYLES = `/api/digitalization/styles`;
export const GET_DIGITALIZATION_SUB_STYLES = `/api/digitalization/subtyles`;
export const GET_DIGITALIZATION_APP_USERS = `/api/digitalization/app-users`;

// OT SIGNAL
export const OT_SIGNAL_LIST_ENDPOINT = `/api/ot-signals`;
export const OT_SIGNAL_ADD_ENDPOINT = `/api/ot-signals/add`;
export const OT_SIGNAL_EDIT_ENDPOINT = `/api/ot-signals/edit`;
export const OT_SIGNAL_DELETE_ENDPOINT = `/api/ot-signals/delete`;

// OT SIGNAL SECTION
export const OT_SIGNAL_SECTION_LIST_ENDPOINT = `/api/ot-signal-sections`;
export const OT_SIGNAL_SECTION_ADD_ENDPOINT = `/api/ot-signal-sections/add`;
export const OT_SIGNAL_SECTION_EDIT_ENDPOINT = `/api/ot-signal-sections/edit`;
export const OT_SIGNAL_SECTION_DELETE_ENDPOINT = `/api/ot-signal-sections/delete`;

// PP MEETING DATE
export const PP_MEETING_DATE_LIST_ENDPOINT = `/api/pp-meeting-dates`;
export const PP_MEETING_DATE_ADD_ENDPOINT = `/api/pp-meeting-dates/add`;
export const PP_MEETING_DATE_EDIT_ENDPOINT = `/api/pp-meeting-dates/edit`;
export const PP_MEETING_DATE_DELETE_ENDPOINT = `/api/pp-meeting-dates/delete`;

// BUYER INSPECTION DATE
export const BUYER_INSPECTION_DATE_LIST_ENDPOINT = `/api/buyer-inspection-dates`;
export const BUYER_INSPECTION_DATE_ADD_ENDPOINT = `/api/buyer-inspection-dates/add`;
export const BUYER_INSPECTION_DATE_EDIT_ENDPOINT = `/api/buyer-inspection-dates/edit`;
export const BUYER_INSPECTION_DATE_DELETE_ENDPOINT = `/api/buyer-inspection-dates/delete`;

// PURPOSE
export const PURPOSE_LIST_ENDPOINT = `/api/purposes`;
export const PURPOSE_ADD_ENDPOINT = `/api/purposes/add`;
export const PURPOSE_EDIT_ENDPOINT = `/api/purposes/edit`;
export const PURPOSE_DELETE_ENDPOINT = `/api/purposes/delete`;

// AUDIT PARTY
export const AUDIT_PARTY_LIST_ENDPOINT = `/api/audit-parties`;
export const AUDIT_PARTY_ADD_ENDPOINT = `/api/audit-parties/add`;
export const AUDIT_PARTY_EDIT_ENDPOINT = `/api/audit-parties/edit`;
export const AUDIT_PARTY_DELETE_ENDPOINT = `/api/audit-parties/delete`;

// BUYER VISIT DATE
export const BUYER_VISIT_DATE_LIST_ENDPOINT = `/api/buyer-visit-dates`;
export const BUYER_VISIT_DATE_ADD_ENDPOINT = `/api/buyer-visit-dates/add`;
export const BUYER_VISIT_DATE_EDIT_ENDPOINT = `/api/buyer-visit-dates/edit`;
export const BUYER_VISIT_DATE_DELETE_ENDPOINT = `/api/buyer-visit-dates/delete`;

// AUDIT DATE
export const AUDIT_DATE_LIST_ENDPOINT = `/api/audit-dates`;
export const AUDIT_DATE_ADD_ENDPOINT = `/api/audit-dates/add`;
export const AUDIT_DATE_EDIT_ENDPOINT = `/api/audit-dates/edit`;
export const AUDIT_DATE_DELETE_ENDPOINT = `/api/audit-dates/delete`;

// CAPACITY OF SAMPLE TEAM
export const CAPACITY_OF_SAMPLE_TEAM_LIST_ENDPOINT = `/api/capacity-of-sample-teams`;
export const CAPACITY_OF_SAMPLE_TEAM_ADD_ENDPOINT = `/api/capacity-of-sample-teams/add`;
export const CAPACITY_OF_SAMPLE_TEAM_EDIT_ENDPOINT = `/api/capacity-of-sample-teams/edit`;
export const CAPACITY_OF_SAMPLE_TEAM_DELETE_ENDPOINT = `/api/capacity-of-sample-teams/delete`;

// FABRIC LOADING CAPACITY OF STORE
export const FABRIC_LOADING_CAPACITY_OF_STORES_LIST_ENDPOINT = `/api/fabric-loading-capacity-of-stores`;
export const FABRIC_LOADING_CAPACITY_OF_STORES_ADD_ENDPOINT = `/api/fabric-loading-capacity-of-stores/add`;
export const FABRIC_LOADING_CAPACITY_OF_STORES_EDIT_ENDPOINT = `/api/fabric-loading-capacity-of-stores/edit`;
export const FABRIC_LOADING_CAPACITY_OF_STORES_DELETE_ENDPOINT = `/api/fabric-loading-capacity-of-stores/delete`;

// OVERTIME REASONS
export const OVERTIME_REASONS_LIST_ENDPOINT = `/api/overtime-reasons`;
export const OVERTIME_REASONS_ADD_ENDPOINT = `/api/overtime-reasons/add`;
export const OVERTIME_REASONS_EDIT_ENDPOINT = `/api/overtime-reasons/edit`;
export const OVERTIME_REASONS_DELETE_ENDPOINT = `/api/overtime-reasons/delete`;

// SAMPLE REQUISITION
export const SAMPLE_REQUISITIONS_LIST_ENDPOINT = `/api/sample-requisitions`;
export const SAMPLE_REQUISITIONS_ADD_ENDPOINT = `/api/sample-requisitions/add`;
export const SAMPLE_REQUISITIONS_EDIT_ENDPOINT = `/api/sample-requisitions/edit`;
export const SAMPLE_REQUISITIONS_DELETE_ENDPOINT = `/api/sample-requisitions/delete`;

// Unit Price
export const UNIT_PRICE_LIST = `/api/unit_prices`;
export const UNIT_PRICE_PO_ADD = `/api/unit_prices/add`;
export const UNIT_PRICE_PO_UPDATE = `/api/unit_prices/edit`;
export const UNIT_PRICE_DELETE = `/api/unit_prices/delete`;

// SALES CONTRACT 360
export const SALES_CONTRACT_AMENDMENTS = `/api/sales_contract_amendments`;
export const SALES_CONTRACT_STYLE_INQUIRY_DETAILS = `/api/sales_contract_style_inquiry_details`;
export const SALES_CONTRACT_ORDER_DETAILS = `/api/sales_contract_order_details`;
export const SALES_CONTRACT_PRICE = `/api/sales_contract_price`;
export const SALES_CONTRACT_OB_SUMMARY = `/api/sales_contract_ob_summary`;
export const SALES_CONTRACT_CM_SUMMARY = `/api/sales_contract_cm_summary`;
export const SALES_CONTRACT_BOOKING_PI = `/api/sales_contract_booking_pi`;
export const SALES_CONTRACT_BUDGET_VS_PI = `/api/sales_contract_budget_vs_pi`;
export const SALES_CONTRACT_BTB_RTGS_TT = `/api/sales_contract_btb_rtgs_tt`;
export const SALES_CONTRACT_IMPORT_INVOICE = `/api/sales_contract_import_invoice`;
export const SALES_CONTRACT_IMPORT_PAYMENT = `/api/sales_contract_import_payment`;
export const SALES_CONTRACT_EXPORT_INVOICE = `/api/sales_contract_export_invoice`;
export const SALES_CONTRACT_EXPORT_INVOICE_REALIZATION = `/api/sales_contract_export_invoice_realization`;
export const SALES_CONTRACT_IMPORT_NOTING_ASSESSMENT = `/api/sales_contract_import_noting_assessment`;
export const SALES_CONTRACT_TNA = `/api/sales_contract_tna`;
export const SALES_CONTRACT_BOLT_SEAL_LOGS = `/api/sales_contract_bolt_seal_logs`;
export const SALES_CONTRACT_INVENTORY = `/api/sales_contract_inventory`;
export const SALES_CONTRACT_PRODUCTION_PLAN = `/api/sales_contract_production_plan`;
export const SALES_CONTRACT_PRODUCTION_INFORMATION = `/api/sales_contract_production_information`;
export const SALES_CONTRACT_SHIPMENT_INFORMATION = `/api/sales_contract_shipment_information`;

// CAPACITY BOOKING
export const CAPACITY_BOOKINGS_LIST_ENDPOINT = `/api/capacity-bookings`;
export const CAPACITY_BOOKINGS_ADD_ENDPOINT = `/api/capacity-bookings/add`;
export const CAPACITY_BOOKINGS_EDIT_ENDPOINT = `/api/capacity-bookings/edit`;
export const CAPACITY_BOOKINGS_DELETE_ENDPOINT = `/api/capacity-bookings/delete`;

// BANK
export const BANK_LIST_ENDPOINT = `/api/banks`;
export const BANK_ADD_ENDPOINT = `/api/banks/add`;
export const BANK_EDIT_ENDPOINT = `/api/banks/edit`;
export const BANK_DELETE_ENDPOINT = `/api/banks/delete`;

// GARMENTS PARTS
export const GARMENTS_PARTS_LIST = `/api/garmentPart`;
export const GARMENTS_PARTS_ADD = `/api/garmentPart/add`;
export const GARMENTS_PARTS_EDIT = `/api/garmentPart/edit`;
export const GARMENTS_PARTS_DELETE = `/api/garmentPart/delete`;

// BRANCH
export const BRANCH_LIST_ENDPOINT = `/api/branches`;
export const BRANCH_ADD_ENDPOINT = `/api/branches/add`;
export const BRANCH_EDIT_ENDPOINT = `/api/branches/edit`;
export const BRANCH_DELETE_ENDPOINT = `/api/branches/delete`;

// ACCOUNT
export const ACCOUNT_LIST_ENDPOINT = `/api/bank_accounts`;
export const ACCOUNT_ADD_ENDPOINT = `/api/bank_accounts/add`;
export const ACCOUNT_EDIT_ENDPOINT = `/api/bank_accounts/edit`;
export const ACCOUNT_DELETE_ENDPOINT = `/api/bank_accounts/delete`;

// OSR Report
export const OSR_LIST_REPORT_ENDPOINT = `/api/report/export_osr_report`;

// POST CUTTING PROCESS
export const POST_CUTTING_PROCESS_LIST = `/api/postCutting`;
export const POST_CUTTING_PROCESS_ADD = `/api/postCutting/product/store`;
export const POST_CUTTING_PROCESS_INFO = `/api/postCutting/product/create`;
export const POST_CUTTING_PROCESS_EDITINFO = `/api/postCutting/product/edit`;
export const POST_CUTTING_PROCESS_UPDATE = `/api/postCutting/product/update`;


// OVERTIME SIGNALS
export const OVERTIME_SIGNALS_LIST_ENDPOINT = `/api/overtime-signals`;
export const OVERTIME_SIGNALS_ADD_ENDPOINT = `/api/overtime-signals/add`;
export const OVERTIME_SIGNALS_EDIT_ENDPOINT = `/api/overtime-signals/edit`;
export const OVERTIME_SIGNALS_DELETE_ENDPOINT = `/api/overtime-signals/delete`;

// All Invoice List
export const INVOICES_LIST_ENDPOINT = `/api/invoice-list`;

// POST SEWING PROCESS
export const POST_SEWING_PROCESS_LIST = `/api/postSewing`;
export const POST_SEWING_PROCESS_INFO = `/api/postSewing/product/create`;
export const POST_SEWING_PROCESS_ADD = `/api/postSewing/product/store`;
export const POST_SEWING_PROCESS_EDITINFO = `/api/postSewing/product/edit`;
export const POST_SEWING_PROCESS_UPDATE = `/api/postSewing/product/update`;

// Capacity Booking Report
export const CAPACITY_BOOKING_REPORT_LIST_ENDPOINT = `/api/capacity-booking-report`;

// ERP USAGE MODULE
export const ERP_USAGE_MODULE_LIST_ENDPOINT = `/api/erp-usage/modules`;
export const ERP_USAGE_MODULE_ADD_ENDPOINT = `/api/erp-usage/modules/add`;
export const ERP_USAGE_MODULE_EDIT_ENDPOINT = `/api/erp-usage/modules/edit`;
export const ERP_USAGE_MODULE_DELETE_ENDPOINT = `/api/erp-usage/modules/delete`;

// ERP USAGE MODULE UI
export const ERP_USAGE_MODULE_UI_LIST_ENDPOINT = `/api/erp-usage/module-uis`;
export const ERP_USAGE_MODULE_UI_ADD_ENDPOINT = `/api/erp-usage/module-uis/add`;
export const ERP_USAGE_MODULE_UI_EDIT_ENDPOINT = `/api/erp-usage/module-uis/edit`;
export const ERP_USAGE_MODULE_UI_DELETE_ENDPOINT = `/api/erp-usage/module-uis/delete`;

// ERP USAGE ISSUE
export const ERP_USAGE_ISSUE_LIST_ENDPOINT = `/api/erp-usage/issues`;
export const ERP_USAGE_ISSUE_ADD_ENDPOINT = `/api/erp-usage/issues/add`;
export const ERP_USAGE_ISSUE_EDIT_ENDPOINT = `/api/erp-usage/issues/edit`;
export const ERP_USAGE_ISSUE_EDIT_ASSIGNED_TO_ME_ENDPOINT = `/api/erp-usage/issues-assigned-to-me/edit`;
export const ERP_USAGE_ISSUE_DELETE_ENDPOINT = `/api/erp-usage/issues/delete`;

