import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, GET_DASHBOARD_DATA, GET_INVENTORY_LIST} from "../ActionTypes";
import JwtAxios from "../../apiServices";
import {GET_DASHBOARD_ENDPOINT} from "../../apiServices/API_ENDPOINTS";

export const onGetDashboardData = () => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        JwtAxios
            .get(`${GET_DASHBOARD_ENDPOINT}`)
            .then((data) => {
                if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: GET_DASHBOARD_DATA, payload: data.data.data});
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: "Something went wrong!",
                    });
                }
            })
            .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
            });
    };
};