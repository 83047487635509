import {
    GET_SHIPMENT_LIST,
    STORE_NEW_SHIPMENT,
    DELETE_SHIPMENT,
    GET_SHIPMENT_DETAIL, UPDATE_SHIPMENT_DETAIL
} from '../ActionTypes';

const initialState = {
    ShipmentList: [],
    totalShipment: null,
    selectedShipment: null,
    selectedShipmentDetails: null,
    createdShipment: null,
    selectedShipmentAccountInfo: null,
    pageSize: 10,
    page:1,
};

const ShipmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SHIPMENT_LIST:
            return {
                ...state,
                ShipmentList: action.payload.data,
                totalShipment: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case STORE_NEW_SHIPMENT:
            return {
                ...state,
                createdShipment: action.payload.data,
                ShipmentList: [action.payload.data, ...state.ShipmentList],
                totalShipment: state.totalShipment + 1,
            };

        case DELETE_SHIPMENT: {
            return {
                ...state,
                ShipmentList: action.payload.list,
                totalShipment: action.payload.total,
            };
        }

        case GET_SHIPMENT_DETAIL:
            return {
                ...state,
                selectedShipment: action.payload,
                selectedShipmentAccountInfo: action.payload,
                selectedShipmentDetails: action.payload,
            };

        case UPDATE_SHIPMENT_DETAIL:
            return {
                ...state,
                selectedShipment: action.payload,
                ShipmentList: state.ShipmentList.map((Shipment) =>
                    Shipment.id === action.payload.id ? action.payload : Shipment,
                ),
            };

        default:
            return state;
    }
};

export default ShipmentReducer;
