import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Table,
  Typography,
  Drawer,
  Row,
  Col,
  Space,
  Popover,
  Descriptions,
  Image,
  message,
  Checkbox,
} from "antd";
import {
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./index.css";
import moment from "moment";
import HandleNotification from "../../../common/Notification";
import { postData, getData } from "../../../apiServices/common";
import {
  GET_TRIMS_ASSIGNED_ITEMS,
  GET_TRIMS_UNIQUE_TIMES,
  TRACK_LIST_FOR_TRIMS_ADD,
  TRIMS_ACCESSORIES_STORE,
  UNIT_CONVERTION,
  UNIT_LIST_ENDPOINT,
  UPDATE_TRIMS_INVENTORY,
  WAREHOUSE_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import {
  getMovementType,
  getMovementTypeLabel,
} from "../../../common/helper/helper";
import UpdateForm from "./UpdateForm";
import {
  commaSeparateNumber,
  hasPermission,
  isArrayAndHasValue,
} from "../../../utils/functions";
import {
  INVENTORY_CREATE_PERMISSION,
  INVENTORY_EDIT_PERMISSION,
} from "../../../routes/permissions";
import AddForm from "./AddForm";
import { v4 as uuidv4 } from "uuid";

const BOMTable = (props) => {
  // Props
  const { selectedBOM, loading, movement, styleDetails, form, getBOMDetails } =
    props;

  // States
  const [openDrawer, setOpenDrawer] = useState(false);
  const [BOMDetails, setBOMDetails] = useState(selectedBOM);
  const [checkedStyleItems, setCheckedStyleItems] = useState([]);
  const [warehouseList, setWarehouseList] = useState(null);
  const [unitList, setUnitList] = useState(null);
  const [assignType, setAssignType] = useState(null);
  const [uniqueTimes, setUniqueTimes] = useState(null);
  const [gotAssignedItems, setGotAssignedItems] = useState(false);

  // Antd
  const [tableItemSearchForm] = Form.useForm();

  const getAllWarehouse = useCallback(async (filterValues) => {
    const { name } = filterValues || {};

    const query = `${WAREHOUSE_LIST_ENDPOINT}`;
    const bodyData = {
      ...(name && { name: name }),
    };

    const response = await getData(query, false, bodyData);
    if (response?.status === 200) {
      setWarehouseList(response?.data?.data?.data);
    } else {
      message.error("Error getting warehouse data");
    }
  }, []);

  const getAllUnit = useCallback(async (filterValues) => {
    const query = `${UNIT_LIST_ENDPOINT}`;
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);
    if (response?.status === 200) {
      setUnitList(response?.data?.data?.data);
    } else {
      message.error("Error getting unit data");
    }
  }, []);

  useEffect(() => {
    // update BOMDetails when selectedBOM is changed
    if (selectedBOM) {
      setBOMDetails(selectedBOM);
    }
  }, [selectedBOM]);

  useEffect(() => {
    getAllWarehouse();
    getAllUnit({ per_page: 10000 });
  }, [getAllWarehouse, getAllUnit]);

  const onAssign = async (selectedStyleItems, type) => {
    setOpenDrawer(true);

    if (type === "update") {
      setAssignType("update");
    } else {
      setAssignType("add");
    }
  };

  const onWarehouseSelect = (selectedWarehouseId, rowId) => {
    // Filter racks list based in selected warehouse
    const filteredWarehouse =
      warehouseList &&
      warehouseList.find(
        (item) => Number(item.id) === Number(selectedWarehouseId),
      );

    const racksList = isArrayAndHasValue(filteredWarehouse?.racks_info)
      ? filteredWarehouse?.racks_info
      : [
          {
            ...filteredWarehouse?.racks_info,
          },
        ];

    // Iterate and push racklist data to tempRowData to show options in rack dropdown
    const tempRowDataCopy = [...checkedStyleItems];

    if (isArrayAndHasValue(checkedStyleItems)) {
      const filteredRowData = tempRowDataCopy.find(
        (item) => item?.unique_id === rowId,
      );
      if (filteredRowData) {
        filteredRowData.warehouse_id = selectedWarehouseId;
        filteredRowData.racksList = racksList;
      } else {
        tempRowDataCopy.push({
          warehouse_id: selectedWarehouseId,
          row_id: rowId,
          racksList: racksList,
        });
      }
    } else {
      tempRowDataCopy.push({
        warehouse_id: selectedWarehouseId,
        row_id: rowId,
        racksList: racksList,
      });
    }

    setCheckedStyleItems(tempRowDataCopy);
  };

  const onRackSelect = (selectedRackId, rowId) => {
    // Filter bin list based in selected selectedRackId
    const tempRowDataCopy = [...checkedStyleItems];
    const currentRowData = tempRowDataCopy.find(
      (item) => item.unique_id === rowId,
    );
    const filteredRack = currentRowData?.racksList?.find(
      (item) => Number(item.id) === Number(selectedRackId),
    );

    const binList = isArrayAndHasValue(filteredRack?.bins_info)
      ? filteredRack?.bins_info
      : [
          {
            ...filteredRack?.bins_info,
          },
        ];

    if (isArrayAndHasValue(binList)) {
      // Replace binList in currentRowData
      currentRowData.binList = binList;
      currentRowData.rack_id = selectedRackId;
    }

    setCheckedStyleItems(tempRowDataCopy);
  };

  const onBinSelect = (selectedBinId, rowId) => {
    const tempRowDataCopy = [...checkedStyleItems];
    const currentRowData = tempRowDataCopy.find(
      (item) => item.unique_id === rowId,
    );
    currentRowData.bin_id = selectedBinId;

    setCheckedStyleItems(tempRowDataCopy);
  };

  const onSelectUnit = (selectedUnitId, rowId) => {
    const tempRowDataCopy = [...checkedStyleItems];
    const currentRowData = tempRowDataCopy.find(
      (item) => item.unique_id === rowId,
    );
    currentRowData.convertion_unit_id = selectedUnitId;

    setCheckedStyleItems(tempRowDataCopy);
  };

  const onChangeQuantity = (quantity, rowId) => {
    const tempRowDataCopy = [...checkedStyleItems];
    const currentRowData = tempRowDataCopy.find(
      (item) => item.unique_id === rowId,
    );
    currentRowData.shipment_qty = quantity;

    setCheckedStyleItems(tempRowDataCopy);
  };

  const formatNumberWithCommas = (number) => {
    return Number(parseFloat(number).toFixed(2)).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  const onCheckboxChange = (e, record) => {
    const BOMDetailsCopy = [...BOMDetails];
    const checkedStyleItemsCopy = [...checkedStyleItems];

    if (e.target.checked) {
      // from BOMDetailsCopy array set is_checked flag to true
      const index = BOMDetailsCopy.findIndex((item) => item.id === record.id);
      BOMDetailsCopy[index] = {
        ...record,
        is_checked: true,
      };

      // Add is_checked flag and push to array
      const isItemExists = checkedStyleItemsCopy.find(
        (item) => item.id === record.id,
      );
      if (!isItemExists) {
        // Push to checked array
        checkedStyleItemsCopy.push({
          ...record,
          is_checked: true,
        });
      } else {
        // Update is_checked flag if already exists in the array
        const index = checkedStyleItemsCopy.findIndex(
          (item) => item.id === record.id,
        );
        checkedStyleItemsCopy[index] = {
          ...record,
          is_checked: true,
        };
      }
    } else {
      // from BOMDetailsCopy array set is_checked flag to false
      const index = BOMDetailsCopy.findIndex((item) => item.id === record.id);
      BOMDetailsCopy[index] = {
        ...record,
        is_checked: false,
      };

      // Remove item from checked array
      const itemIndex = checkedStyleItemsCopy.findIndex(
        (item) => item.id === record.id,
      );
      checkedStyleItemsCopy.splice(itemIndex, 1);
    }

    setBOMDetails(BOMDetailsCopy);
    setCheckedStyleItems(checkedStyleItemsCopy);
  };

  const checkAllStyleItems = (e) => {
    if (e.target.checked) {
      // Add is_checked flag to all items
      const BOMDetailsCopy = [...BOMDetails];
      const updatedBOMDetails =
        isArrayAndHasValue(BOMDetailsCopy) &&
        BOMDetailsCopy.map((item) => {
          return {
            ...item,
            is_checked: true,
          };
        });
      setBOMDetails(updatedBOMDetails);
      // Set checked items to the state
      setCheckedStyleItems(updatedBOMDetails);
    } else {
      // Add is_checked flag to all items
      const BOMDetailsCopy = [...BOMDetails];
      const updatedBOMDetails =
        isArrayAndHasValue(BOMDetailsCopy) &&
        BOMDetailsCopy.map((item) => {
          return {
            ...item,
            is_checked: false,
          };
        });
      setBOMDetails(updatedBOMDetails);
      // Set checked items to the state
      setCheckedStyleItems([]);
    }
  };

  const deselectAllStyleItems = () => {
    // Empty out the selected array
    setCheckedStyleItems([]);

    // Add is_checked flag false to all items
    const BOMDetailsCopy = [...BOMDetails];
    const updatedBOMDetails =
      isArrayAndHasValue(BOMDetailsCopy) &&
      BOMDetailsCopy.map((item) => {
        return {
          ...item,
          is_checked: false,
        };
      });
    setBOMDetails(updatedBOMDetails);
  };

  const onCopyRow = (product, index) => {
    const tempRowDataCopy = structuredClone(checkedStyleItems);
    const copiedRow = tempRowDataCopy.find(
      (item) => item.unique_id === product.unique_id,
    );
    copiedRow.id = uuidv4();

    tempRowDataCopy.push(copiedRow);
    setCheckedStyleItems(tempRowDataCopy);
  };

  const defaultColumns = [
    {
      title: (text, record) => {
        return (
          <>
            <span>Check All</span>
            <div className="centered-item-parent">
              <Checkbox
                checked={BOMDetails?.every((item) => item?.is_checked)}
                onChange={(e) => checkAllStyleItems(e)}
              />
            </div>
          </>
        );
      },
      dataIndex: "checkbox",
      key: "checkbox",
      render: (text, record) => {
        return (
          <div className="centered-item-parent">
            <Checkbox
              checked={record?.is_checked}
              onChange={(e) => onCheckboxChange(e, record)}
            />
          </div>
        );
      },
    },
    {
      title: "SL",
      dataIndex: "sl",
      key: "sl",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Item Category",
      dataIndex: "item_category_info",
      key: "item_category_info",
      render: (item, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{item?.name}</div>
          </Popover>
        );
      },
    },
    {
      title: "Item",
      dataIndex: "item_info",
      key: "item_info",
      width: 450,
      render: (item, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>
              <Space>
                <div>{item?.name}</div>
                <div>
                  <Popover
                    content={
                      <Descriptions
                        column={1}
                        bordered
                        style={{
                          height: 300,
                          width: 350,
                          overflow: "auto",
                        }}
                      >
                        {item?.attribute_value_info?.map((attrInfo, index) => {
                          return (
                            <Descriptions.Item
                              key={attrInfo?.id || index}
                              label={attrInfo?.attribute_info?.name}
                            >
                              {attrInfo?.attribute_value}
                            </Descriptions.Item>
                          );
                        })}

                        {item?.image_path ? (
                          <Descriptions.Item
                            label="Item Image"
                            labelStyle={{
                              backgroundColor: "white",
                              borderRight: "none",
                              alignContent: "center",
                            }}
                          >
                            <Image.PreviewGroup>
                              <Image
                                width={50}
                                height={50}
                                path={item?.image_path}
                                src={`${process.env.REACT_APP_BASE_URL}/${item?.image_path}`}
                              />
                            </Image.PreviewGroup>
                          </Descriptions.Item>
                        ) : null}
                      </Descriptions>
                    }
                  >
                    <div className="centered-item-parent">
                      <InfoCircleOutlined
                        className="cursore-pointer"
                        style={{
                          fontSize: "20px",
                          color: "green",
                        }}
                      />
                    </div>
                  </Popover>
                </div>
              </Space>
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Sub-Style",
      dataIndex: "sub_style_map",
      key: "sub_style_map",
      render: (sub_style_info, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{sub_style_info?.sub_style_no}</div>
          </Popover>
        );
      },
    },
    {
      title: "O.Qty",
      dataIndex: "order",
      key: "order",
      render: (text, record) => {
        const value = record?.order_quantity
          ? Number(record?.order_quantity).toFixed(2)
          : 0;
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{formatNumberWithCommas(value)}</div>
          </Popover>
        );
      },
    },
    {
      title: "YY",
      dataIndex: "per_product_required_quantity",
      key: "per_product_required_quantity",
      render: (text, record) => {
        const value = record?.per_product_required_quantity
          ? Number(record?.per_product_required_quantity).toFixed(2)
          : 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "BOM Unit",
      dataIndex: "unit_info",
      key: "unit_info",
      render: (item, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{record?.unit_info?.name}</div>
          </Popover>
        );
      },
    },
    {
      title: "Req. Qty.",
      dataIndex: "required_quantity",
      key: "required_quantity",
      render: (text, record) => {
        const value = record?.required_quantity
          ? Number(record?.required_quantity).toFixed(2)
          : 0;
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{formatNumberWithCommas(value)}</div>
          </Popover>
        );
      },
    },
    {
      title: "Received Qty",
      dataIndex: "total_recieved",
      key: "total_recieved",
      render: (text, record) => {
        const value = record?.total_recieved
          ? Number(record?.total_recieved).toFixed(2)
          : 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "Receivable Qty",
      dataIndex: "receivable_quantity",
      key: "receivable_quantity",
      render: (value, record) => {
        const requiredQty = record?.required_quantity
          ? parseFloat(record?.required_quantity)
          : 0;
        const receivedQty = record?.total_recieved
          ? parseFloat(record?.total_recieved)
          : 0;
        const difference = requiredQty - receivedQty;
        const finalValue = difference ? Number(difference).toFixed(2) : 0;
        return <span>{finalValue}</span>;
      },
    },
    {
      title: "Issued Qty",
      dataIndex: "total_issued",
      key: "total_issued",
      render: (text, record) => {
        const value = record?.total_issued
          ? Number(record?.total_issued).toFixed(2)
          : 0;
        return <span>{value}</span>;
      },
    },
    {
      title: "Issue Balance",
      dataIndex: "issue_balance",
      key: "issue_balance",
      render: (value, record) => {
        const receivedQty = record?.total_recieved
          ? parseFloat(record?.total_recieved)
          : 0;
        const issueddQty = record?.total_issued
          ? parseFloat(record?.total_issued)
          : 0;
        const difference = receivedQty - issueddQty;
        const finalValue = difference ? Number(difference).toFixed(2) : 0;
        return <span>{finalValue}</span>;
      },
    },
    {
      title: "Other Info",
      key: "other_info",
      render: (item, record) => {
        return (
          <Popover
            content={
              <Descriptions
                column={1}
                bordered
                style={{
                  height: 300,
                  width: 350,
                  overflow: "auto",
                }}
              >
                <Descriptions.Item label={"Supplier Name"}>
                  {record?.supplier_info?.name}
                </Descriptions.Item>
                <Descriptions.Item label={"Order Qty."}>
                  {record?.order_quantity
                    ? commaSeparateNumber(record?.order_quantity)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label={"Day Total"}>
                  {record?.received_total_quantity
                    ? commaSeparateNumber(record?.received_total_quantity)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label={"Cumulative Total"}>
                  {record?.cumulative_total
                    ? commaSeparateNumber(record?.cumulative_total)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={`${
                    movement?.includes("receive") ? "Receive " : ""
                  }Remaining`}
                >
                  {record?.balance_quantity
                    ? commaSeparateNumber(record?.balance_quantity)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label={"Free Balance"}>
                  {record?.free_inventory
                    ? commaSeparateNumber(record?.free_inventory)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label={"Free Balance"}>
                  {record?.balance_quantity
                    ? commaSeparateNumber(parseFloat(record?.balance_quantity))
                    : "N/A"}
                </Descriptions.Item>
              </Descriptions>
            }
            title={
              <div className="">
                <h3>Other Info</h3>
              </div>
            }
          >
            <div className="centered-item-parent">
              <InfoCircleOutlined
                className="cursore-pointer"
                style={{ fontSize: "20px", color: "green" }}
              />
            </div>
          </Popover>
        );
      },
    },
  ];

  const onDrawerClose = () => {
    setOpenDrawer(false);
    deselectAllStyleItems();
    setAssignType(null);
    setGotAssignedItems(false);
  };

  const onSearchTable = (values) => {
    let filteredData = [];
    let categoryFilteredData = [];
    let nameFilteredData = [];
    let substyleFilteredData = [];

    if (values) {
      if (values?.item_category_search_input) {
        categoryFilteredData = selectedBOM.filter((item) =>
          item?.item_category_info?.name
            ?.toLowerCase()
            ?.includes(values?.item_category_search_input?.toLowerCase()),
        );
      }
      if (values?.item_name_search_input) {
        nameFilteredData = selectedBOM.filter((item) =>
          item?.item_info?.name
            ?.toLowerCase()
            ?.includes(values?.item_name_search_input?.toLowerCase()),
        );
      }
      if (values?.item_substyle_search_input) {
        substyleFilteredData = selectedBOM.filter((item) =>
          item?.sub_style_map?.sub_style_no
            ?.toLowerCase()
            ?.includes(values?.item_substyle_search_input?.toLowerCase()),
        );
      }
    }

    // If all the search inputs are given
    // But any of the filtered data is empty, then the filtered data should be empty
    if (
      values?.item_category_search_input &&
      values?.item_name_search_input &&
      values?.item_substyle_search_input
    ) {
      if (
        !isArrayAndHasValue(categoryFilteredData) ||
        !isArrayAndHasValue(nameFilteredData) ||
        !isArrayAndHasValue(substyleFilteredData)
      ) {
        filteredData = [];
        setBOMDetails(filteredData);
        return;
      }
    }

    // Assign filteredData by combining and intersection of the result arrays
    filteredData = selectedBOM.filter(
      (item) =>
        (categoryFilteredData.length === 0 ||
          categoryFilteredData.includes(item)) &&
        (nameFilteredData.length === 0 || nameFilteredData.includes(item)) &&
        (substyleFilteredData.length === 0 ||
          substyleFilteredData.includes(item)),
    );

    if (filteredData.length > 0) {
      setBOMDetails(filteredData);
    }
  };

  const onResetTableSearch = () => {
    setBOMDetails(selectedBOM);
    tableItemSearchForm.resetFields();
  };

  const RowInfoUI = ({ rowData }) => {
    return (
      <div style={{ padding: "10px" }}>
        <div>
          <strong>Item Type:</strong>{" "}
          {rowData?.item_category_info?.name || "N/A"}
        </div>
        <div>
          <strong>Item Name:</strong> {rowData?.item_info?.name || "N/A"}
        </div>
        <div>
          <strong>Color:</strong> {rowData?.color_info?.name || "N/A"}
        </div>
        <div>
          <strong>Substyle:</strong>{" "}
          {rowData?.sub_style_map?.sub_style_no || "N/A"}
        </div>
        <div>
          <strong>Order Qty:</strong>{" "}
          {rowData?.order_quantity
            ? commaSeparateNumber(rowData?.order_quantity)
            : "N/A"}
        </div>
        <div>
          <strong>Required Qty:</strong>{" "}
          {rowData?.required_quantity
            ? commaSeparateNumber(rowData?.required_quantity)
            : "N/A"}
        </div>
        <div>
          <strong>Unit:</strong> {rowData?.unit_info?.name || "N/A"}
        </div>
      </div>
    );
  };

  const unitConvert = async (
    convertion_unit,
    assign_shipment_qty_value,
    bom_unit,
    index,
  ) => {
    // Convert value to selected RCV unit
    if (!convertion_unit) {
      HandleNotification(
        "error",
        "bottomRight",
        "Please select Unit first!",
        null,
      );
      return;
    }

    const bodyData = {
      convert_from: convertion_unit ? Number(convertion_unit) : null,
      convert_to: bom_unit ? Number(bom_unit) : null,
      bom_value: assign_shipment_qty_value
        ? Number(assign_shipment_qty_value)
        : 0,
    };

    const response = await postData(UNIT_CONVERTION, bodyData);

    const checkedStyleItemsCopy = [...checkedStyleItems];
    const foundItem = checkedStyleItemsCopy.find(
      (item) => item.unique_id === index,
    );

    if (response && response?.code === 200) {
      const convertedValue = response?.data?.converted_value
        ? Number(response?.data?.converted_value)
        : null;

      foundItem["bom_unit_total_qty"] = convertedValue;
      foundItem["convertion_rate"] = response?.data?.conversion_rate;
      setCheckedStyleItems(checkedStyleItemsCopy);
    } else {
      foundItem["bom_unit_total_qty"] = assign_shipment_qty_value
        ? Number(assign_shipment_qty_value)
        : 0;
      foundItem["convertion_rate"] = 1;
      setCheckedStyleItems(checkedStyleItemsCopy);

      HandleNotification(
        "error",
        "bottomRight",
        response?.data?.message
          ? response?.data?.message[0]
          : "Error converting unit value!",
        null,
      );
    }
  };

  const groupByItemId = (data) => {
    // Create an empty object to store grouped data
    const groupedData = {};

    // Iterate over each item in the data array
    isArrayAndHasValue(data) &&
      data.forEach((item) => {
        // Check if the item_id already exists in the groupedData object
        if (!groupedData[item.item_id] && item?.shipment_qty) {
          // If it doesn't exist, create a new entry with the initial values
          groupedData[item.item_id] = {
            inventory_detail_id:
              assignType === "add" ? 0 : item?.inventory_detail_id,
            item_category_id:
              item?.item_category_id || item?.item_category_info?.id,
            item_id: item?.item_id,
            sub_style_id: item?.sub_style_map?.id || item?.sub_style_id || null,
            color_id: item?.color_id || null,
            bom_unit_id: item?.unit_id,
            required_quantity: item?.required_quantity
              ? Number(item?.required_quantity)
              : null,
            total_quantity: 0,
            total_shipment_quantity: 0,
            locations: [],
          };
        }

        // Add the quantity to the total_quantity
        if (groupedData[item.item_id] && item?.shipment_qty) {
          groupedData[item.item_id].total_quantity += item.bom_unit_total_qty;
          groupedData[item.item_id].total_shipment_quantity +=
            item.shipment_qty;
        }

        // Add the item to the locations array
        if (groupedData[item.item_id]?.total_quantity) {
          if (item?.shipment_qty) {
            groupedData[item.item_id].locations.push({
              track_id: item?.id ? item?.id : 0,
              history_id: item?.history_id ? item?.history_id : 0,
              bin_id: item?.bin_id,
              bom_unit_total_qty: item?.bom_unit_total_qty,
              movement: getMovementType(movement),
              convertion_unit_id: item?.convertion_unit_id,
              convertion_rate: item?.convertion_rate,
              shipment_qty: item?.shipment_qty,
            });
          }
        }
      });

    // Convert the grouped data object back to an array
    return Object.values(groupedData);
  };

  const onSubmitDrawerForm = async () => {
    const groupedItemData = groupByItemId(checkedStyleItems);
    console.log("🚀 ~ onSubmitDrawerForm ~ checkedStyleItems:", checkedStyleItems)

    const formValues = form.getFieldsValue();

    const payload = {
      buying_office_id: formValues?.buying_office_id || null,
      sales_contract_id: formValues?.sales_contract_id || null,
      style_id: formValues?.style_id || null,
      supplier_id: formValues?.supplier_id || null,
      challan_id: formValues?.challan_id || null,
      invoice_id: formValues?.invoice_id || null,
      entry_date: formValues?.entry_date
        ? moment(formValues?.entryDate).format("YYYY-MM-DD")
        : null,
      use_fabric_interlining_store: "No",
      movement_type: movement,
      reference_no: formValues?.reference_no || null,
      requisition_no: formValues?.requisition_no || null,
      items: groupedItemData,
      sub_contract_factory_id: formValues?.sub_contract_factory_id || null,
    };

    const query =
      assignType === "update"
        ? `${UPDATE_TRIMS_INVENTORY}`
        : `${TRIMS_ACCESSORIES_STORE}`;
    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        "Data assigned successfully",
      );
      onDrawerClose();

      getBOMDetails({
        buying_office_id: styleDetails?.style?.buying_office_id,
        entry_date: form.getFieldValue("entry_date"),
        movement_type: movement,
        sales_contract_id:
          styleDetails?.style?.active_sales_contract_map_info
            ?.active_sales_contract_info?.id,
        style_id: styleDetails?.style?.id,
        supplier_id: form.getFieldValue("supplier_id"),
        use_fabric_interlining_store: "No",
        sub_contract_factory_id: styleDetails?.style?.factory_info?.id || null,
      });
    } else {
      HandleNotification("error", "bottomRight", "Error storing data", null);
    }
  };

  const copyValueToNextRows = (value, itemId, context) => {
    const inputValue = value || value === 0 ? value : null;
    const styleItemsCopy = structuredClone(checkedStyleItems);
    const foundItemIndex = styleItemsCopy.findIndex(
      (item) => item?.id === itemId,
    );

    // Put inputValue in context to all the items after foundItemIndex
    for (let i = foundItemIndex; i < styleItemsCopy.length; i++) {
      styleItemsCopy[i][context] = inputValue;
    }

    setCheckedStyleItems(styleItemsCopy);
  };

  const copyToNextRows = (itemID) => {
    const checkedStyleItemsCopy = structuredClone(checkedStyleItems);
    const foundItem = checkedStyleItemsCopy.find(
      (item) => item.unique_id === itemID,
    );
    const foundItemIndex = checkedStyleItemsCopy.findIndex(
      (item) => item?.unique_id === itemID,
    );

    if (!isArrayAndHasValue(checkedStyleItemsCopy)) {
      return;
    }

    // Put inputValue in context to all the items after foundItemIndex
    for (let i = foundItemIndex; i < checkedStyleItemsCopy.length; i++) {
      checkedStyleItemsCopy[i]["warehouse_id"] = foundItem?.warehouse_id;
      checkedStyleItemsCopy[i]["racksList"] = foundItem?.racksList;
      checkedStyleItemsCopy[i]["binList"] = foundItem?.binList;
      checkedStyleItemsCopy[i]["rack_id"] = foundItem?.rack_id;
      checkedStyleItemsCopy[i]["bin_id"] = foundItem?.bin_id;
    }

    setCheckedStyleItems(checkedStyleItemsCopy);
  };

  const getUniqueTimes = async () => {
    const formValues = form.getFieldsValue();
    const groupedItemData = groupByItemId(checkedStyleItems);

    const query = `${GET_TRIMS_UNIQUE_TIMES}`;
    const payload = {
      buying_office_id: formValues?.buying_office_id,
      entry_date: formValues?.entry_date,
      movement_type: movement,
      sales_contract_id: formValues?.sales_contract_id || null,
      style_id: formValues?.style_id || null,
      supplier_id: formValues?.supplier_id || null,
      items: groupedItemData?.map((groupItem) => {
        return {
          item_id: groupItem?.item_id,
          item_category_id: groupItem?.item_category_id,
        };
      }),
    };

    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      setUniqueTimes(response?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting unique times",
        null,
      );
    }
  };

  const warehouseWithEditData = (responseData) => {
    // Push new warehouse data found from edit/add trims API to the existing warehouse list
    const warehousesFound = isArrayAndHasValue(responseData)
      ? responseData?.map((item) => {
          return {
            ...item?.bins_info?.rack_info?.warehouse_info,
            racks_info: {
              ...item?.bins_info?.rack_info,
              bins_info: {
                ...item?.bins_info,
              },
            },
          };
        })
      : [];

    const warehouseListCopy = isArrayAndHasValue(warehouseList)
      ? [...warehouseList]
      : [];

    // Merge warehousesFound and warehouseListCopy array and unique
    const mergedWarehouseList = [
      ...new Map(
        [...warehouseListCopy, ...warehousesFound].map((item) => [
          item["id"],
          item,
        ]),
      ).values(),
    ];

    isArrayAndHasValue(mergedWarehouseList) &&
      setWarehouseList(mergedWarehouseList);
  };

  const getTrackItemsForAdd = async (time) => {
    const formValues = form.getFieldsValue();

    const query = `${TRACK_LIST_FOR_TRIMS_ADD}`;
    const payload = {
      sales_contract_id: formValues?.sales_contract_id || null,
      style_id: formValues?.style_id || null,
      items:
        isArrayAndHasValue(checkedStyleItems) &&
        checkedStyleItems?.map((item) => {
          return {
            ...item,
            item_id: item?.item_id,
            item_category_id: item?.item_category_id,
          };
        }),
    };

    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      const responseData = response?.data;
      const customData =
        isArrayAndHasValue(responseData) &&
        responseData?.map((item) => {
          const matchedItem = checkedStyleItems.find(
            (checkedItem) => checkedItem.item_id === item.item_id,
          );

          return {
            ...item,
            sub_style_id:
              matchedItem?.sub_style_map?.id ||
              matchedItem?.sub_style_id ||
              null,
            color_id: matchedItem?.color_id || null,
            warehouse_id: item?.bins_info?.rack_info?.warehouse_info?.id,
            rack_id: item?.bins_info?.rack_info?.id,
            bin_id: item?.bins_info?.id,
            is_checked: true,
            balance_quantity: item?.total_quantity,
            bom_unit_total_qty: item?.assign_qty,
            shipment_qty: 0, // For add always 0
            warehouseList: [
              {
                ...item?.bins_info?.rack_info?.warehouse_info,
              },
            ],
            racksList: [
              {
                ...item?.bins_info?.rack_info,
              },
            ],
            binList: [
              {
                ...item?.bins_info,
              },
            ],
          };
        });
      isArrayAndHasValue(customData) && setCheckedStyleItems(customData);

      // Push data to warehouse list
      warehouseWithEditData(responseData);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting assigned items",
        null,
      );
    }
  };

  const getAssignedItems = async (time) => {
    const formValues = form.getFieldsValue();

    const foundItem = uniqueTimes.find((item) => item?.unique_time === time);

    const query = `${GET_TRIMS_ASSIGNED_ITEMS}`;
    const payload = {
      unique_time: time,
      sales_contract_id: formValues?.sales_contract_id || null,
      style_id: formValues?.style_id || null,

      items:
        isArrayAndHasValue(foundItem?.inventory_details) &&
        foundItem?.inventory_details.map((item) => {
          return {
            item_id: item?.item_id,
            item_category_id: item?.item_category_id,
            inventory_detail_id: item?.inventory_detail_id,
          };
        }),
    };

    const response = await postData(query, payload);
    if (response && response?.code === 200) {
      const responseData = response?.data;

      const customData =
        isArrayAndHasValue(responseData) &&
        responseData?.map((item) => {
          const matchedItem = checkedStyleItems.find(
            (checkedItem) => checkedItem.item_id === item.item_id,
          );
          return {
            ...item,
            sub_style_id: matchedItem?.sub_style_map?.id || null,
            color_id: matchedItem?.color_id || null,
            warehouse_id: item?.bins_info?.rack_info?.warehouse_info?.id,
            rack_id: item?.bins_info?.rack_info?.id,
            bin_id: item?.bins_info?.id,
            is_checked: true,
            balance_quantity: item?.total_quantity,
            bom_unit_total_qty: item?.assign_qty,
            shipment_qty: item?.assign_shipment_qty,
            warehouseList: [
              {
                ...item?.bins_info?.rack_info?.warehouse_info,
              },
            ],
            racksList: [
              {
                ...item?.bins_info?.rack_info,
              },
            ],
            binList: [
              {
                ...item?.bins_info,
              },
            ],
          };
        });
      setGotAssignedItems(true);
      setCheckedStyleItems(customData);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting assigned items",
        null,
      );
    }
  };

  return (
    <div className="inventoryTable" style={{ marginTop: 25 }}>
      <Typography.Title style={{ fontSize: 16 }}>Search Item</Typography.Title>
      <Form
        form={tableItemSearchForm}
        layout="vertical"
        onFinish={onSearchTable}
      >
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item name="item_category_search_input" label="Item Category">
              <Input size="small" placeholder="Item Category" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="item_name_search_input" label="Item Name">
              <Input size="small" placeholder="Item Name" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="item_substyle_search_input" label="Substyle">
              <Input size="small" placeholder="Substyle" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <div className="centered-item-parent fullHeightWidth">
              <Button
                style={{ width: "100%" }}
                htmlType="submit"
                type="primary"
                size="small"
              >
                Search
              </Button>
            </div>
          </Col>
          <Col span={4}>
            <div className="centered-item-parent fullHeightWidth">
              <Button
                style={{ width: "100%" }}
                onClick={onResetTableSearch}
                size="small"
              >
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      {BOMDetails?.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              Item Details
            </span>
            <Space>
              <Button
                onClick={() => {
                  onAssign(checkedStyleItems);
                }}
                type="primary"
                icon={<PlusOutlined />}
                disabled={
                  !hasPermission([INVENTORY_CREATE_PERMISSION]) ||
                  !isArrayAndHasValue(checkedStyleItems)
                }
                size="small"
              >
                Add
              </Button>
              <Button
                onClick={() => {
                  onAssign(checkedStyleItems, "update");
                }}
                type="primary"
                icon={<EditOutlined />}
                disabled={
                  !hasPermission([INVENTORY_EDIT_PERMISSION]) ||
                  !isArrayAndHasValue(checkedStyleItems)
                }
                size="small"
              >
                Update
              </Button>
            </Space>
          </div>

          <Table
            scroll={{
              x: 1600,
              y: 400,
            }}
            pagination={false}
            loading={loading}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={BOMDetails}
            columns={defaultColumns}
          />
        </>
      ) : (
        <></>
      )}
      <Drawer
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {assignType === "update"
                ? `${getMovementTypeLabel(movement)}`
                : `${getMovementTypeLabel(movement)}`}
            </div>
            <div>
              <Button type="primary" size="small" onClick={onSubmitDrawerForm}>
                {assignType === "update" ? "Update" : "Submit"}
              </Button>
            </div>
          </div>
        }
        placement="right"
        onClose={onDrawerClose}
        open={openDrawer}
        width={1100}
      >
        <div>
          {assignType === "update" ? (
            <UpdateForm
              getUniqueTimes={getUniqueTimes}
              openDrawer={openDrawer}
              uniqueTimes={uniqueTimes}
              getAssignedItems={getAssignedItems}
              warehouseList={warehouseList}
              getAllWarehouse={getAllWarehouse}
              onWarehouseSelect={onWarehouseSelect}
              onRackSelect={onRackSelect}
              onBinSelect={onBinSelect}
              unitList={unitList}
              getAllUnit={getAllUnit}
              onSelectUnit={onSelectUnit}
              onChangeQuantity={onChangeQuantity}
              checkedStyleItems={checkedStyleItems}
              movementType={getMovementType(movement)}
              onCopyRow={onCopyRow}
              unitConvert={unitConvert}
              assignType={assignType}
              gotAssignedItems={gotAssignedItems}
              copyToNextRows={copyToNextRows}
            />
          ) : (
            <>
              <Typography.Title style={{ fontSize: 16 }}>
                Location and Item Details
              </Typography.Title>

              <AddForm
                warehouseList={warehouseList}
                getAllWarehouse={getAllWarehouse}
                onWarehouseSelect={onWarehouseSelect}
                onRackSelect={onRackSelect}
                onBinSelect={onBinSelect}
                unitList={unitList}
                getAllUnit={getAllUnit}
                onSelectUnit={onSelectUnit}
                onChangeQuantity={onChangeQuantity}
                checkedStyleItems={checkedStyleItems}
                movementType={getMovementType(movement)}
                onCopyRow={onCopyRow}
                unitConvert={unitConvert}
                assignType={assignType}
                copyToNextRows={copyToNextRows}
                getTrackItemsForAdd={getTrackItemsForAdd}
              />
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};
export default BOMTable;
