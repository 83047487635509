import { forwardRef, useState, useEffect, useCallback } from "react";
import {
  Card,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Select,
  Modal,
  InputNumber,
  Divider,
  Space,
  Button,
  Spin,
  Radio,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { BUYER_INQUIRY_ADD_ENDPOINT, BUYER_INQUIRY_EDIT_ENDPOINT, GARMENT_CATEGORY_LIST_ENDPOINT, GARMENT_FOR_LIST_ENDPOINT, GARMENT_TYPE_LIST_ENDPOINT, GET_BUYING_OFFICE_ENDPOINT, GET_INQUIRY_NUMBER, GET_STYLE_LIST_ENDPOINT, GET_USERS_ENDPOINT, POST_TNA_PROCESS_DATA, UPDATE_TNA_PROCESS_DATA } from "apiServices/API_ENDPOINTS";
import HandleNotification from "common/Notification";
import { isArrayAndHasValue } from "utils/functions";
import { getData, postData, putData } from "apiServices/common";
const { Option } = Select;

const ProcessForm = forwardRef((props, ref) => {
  const { view, form, isEdit, editInfo, fileData, CMInfo, viewMode, selectedOwners} = props;
  const navigate = useNavigate();
  const { processId } = useParams();
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    getAllUsers();
  },[editInfo]);

  const onFinish = async (values) => {
    // console.log("onFinish values", values);

    const addQuery = `${POST_TNA_PROCESS_DATA}`;
    const editQuery = `${UPDATE_TNA_PROCESS_DATA}/${processId}`;

    const bodyData = {
        title: values?.process_title,
        description: values?.description,
        owner_ids: values?.process_owner,
        is_active: values?.status
    };

    if(processId > 0){
      const response = await putData(editQuery, bodyData);
      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "Success!",
          response?.message ||
            `Successfully Updated Process`,
        );
        navigate("/tanda/process");
      } else {
        HandleNotification(
          "error",
          "Error!",
          response?.message || `Failed to Update process`,
        );
      }
    }else{
      const response = await postData(addQuery, bodyData);
      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "Success!",
          response?.message ||
            `Successfully Added Process`,
        );
        navigate("/tanda/process");
      } else {
        HandleNotification(
          "error",
          "Error!",
          response?.message || `Failed to add process`,
        );
      }
    }
  };

  const getAllUsers = async (filterValues) => {
    setIsLoading(true);
    const bodyData = {
      per_page: filterValues?.per_page || 2000000000,
      page: 1,
      ...filterValues,
    };

    const response = await getData(GET_USERS_ENDPOINT, false, bodyData);
    if (response && response?.data?.code === 200) {
      setUsers(response?.data?.data?.data);
    }
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          process_owner: selectedOwners,
          status: 1
        }}
      >
        <Card>
          <Row gutter={6}>
            <Col span={6}>
              <Form.Item
                label="Process Title"
                name="process_title"
                rules={[
                  {
                    required: true,
                    message: `Process Title is required `,
                  },
                ]}
              >
                <Input placeholder="Process Title ...." size="small" disabled={viewMode}/>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: false,
                    message: `Description is required `,
                  },
                ]}
              >
                <TextArea size="small" placeholder="Description" disabled={viewMode}/>
              </Form.Item>
            </Col>

            <Col span={6}>
              {/* <Form.Item name="process_owner_id" hidden /> */}
              <Form.Item
                label="Process Owner(s)"
                name="process_owner"
                rules={[
                  {
                    required: true,
                    message: `Process Owner's is required `,
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="Select Owners ..."
                  optionFilterProp="children"
                  disabled={viewMode}
                  size="small"
                  onSearch={(value) => getAllUsers({ name: value })}
                  allowClear
                >
                  {isArrayAndHasValue(users) &&
                    users?.map((userItem, index) => (
                      <Option value={userItem?.id} key={userItem?.id || index}>
                        {userItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: `Status is required `,
                  },
                ]}
              >
                 <Radio.Group  disabled={viewMode} >
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>In-Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
           
          </Row>
        </Card>
      </Form>
    </Spin>
  );
});

export default ProcessForm;
