import { Card, Spin, Table } from "antd";

const formatIntWithCommas = (value) => {
  return parseInt(value)?.toLocaleString();
};

const formatFloatWithCommas = (value) => {
  return parseFloat(value).toFixed(2).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const columnsForToday = [
  {
    title: "Concern",
    dataIndex: "concern",
    key: "concern",
  },
  {
    title: "Budgeted Production/pc",
    dataIndex: "budgetedProduction",
    key: "budgetedProduction",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Folding",
    dataIndex: "folding",
    key: "folding",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Avg CM/USD",
    dataIndex: "avgCmUsd",
    key: "avgCmUsd",
    align: "right",
    render: formatFloatWithCommas,
  },
  {
    title: "Income CM/USD",
    dataIndex: "incomeCmUsd",
    key: "incomeCmUsd",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Income CM/Taka",
    dataIndex: "incomeCmTaka",
    key: "incomeCmTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Expenditure Taka",
    dataIndex: "expenditureTaka",
    key: "expenditureTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Today's Est. Net Profit/Taka",
    dataIndex: "estNetProfitTaka",
    key: "estNetProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Today's Projected Profit/Taka",
    dataIndex: "projectedProfitTaka",
    key: "projectedProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Variance-Profit/Taka",
    dataIndex: "varianceProfitTaka",
    key: "varianceProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
  },
];

const dataSourceForToday = [
  {
    key: "1",
    concern: "CGL",
    budgetedProduction: "21188",
    folding: "24805",
    avgCmUsd: 0.86654654564,
    incomeCmUsd: "21449",
    incomeCmTaka: "2509479",
    expenditureTaka: "3376234",
    estNetProfitTaka: "866755",
    projectedProfitTaka: "359795",
    varianceProfitTaka: "506960",
    remarks: "",
  },
  {
    key: "2",
    concern: "CGL",
    budgetedProduction: "21188",
    folding: "24805",
    avgCmUsd: 876.8774654,
    incomeCmUsd: "21449",
    incomeCmTaka: "2509479",
    expenditureTaka: "3376234",
    estNetProfitTaka: "866755",
    projectedProfitTaka: "359795",
    varianceProfitTaka: "506960",
    remarks: "",
  },
];

const columnsSharingMerchandisers = [
  {
    title: "Dealing Merchandiser",
    dataIndex: "merchandiser",
    key: "merchandiser",
  },
  {
    title: "Buyer Name",
    dataIndex: "buyerName",
    key: "buyerName",
  },
  {
    title: "Folding",
    dataIndex: "folding",
    key: "folding",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "CM/PC (USD)",
    dataIndex: "cmPerPc",
    key: "cmPerPc",
    align: "right",
    render: formatFloatWithCommas,
  },
  {
    title: "Income CM/USD",
    dataIndex: "incomeCmUsd",
    key: "incomeCmUsd",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "CM Income (Taka)",
    dataIndex: "cmIncomeTaka",
    key: "cmIncomeTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Expenses (Taka)",
    dataIndex: "expensesTaka",
    key: "expensesTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Today's Est. Net Profit (Taka)",
    dataIndex: "estNetProfitTaka",
    key: "estNetProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Today's Projected Profit (Taka)",
    dataIndex: "projectedProfitTaka",
    key: "projectedProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Variance-Profit (Taka)",
    dataIndex: "varianceProfitTaka",
    key: "varianceProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "% of NP on Total Day/Profit",
    dataIndex: "npPercentage",
    key: "npPercentage",
    align: "right",
    render: (value) => `${formatFloatWithCommas(value)}%`,
  },
];

const dataSourceSharingMerchandisers = [
  {
    key: "1",
    merchandiser: "MR. SUMON",
    buyerName: "G-TEX",
    folding: 1550,
    cmPerPc: 1.8,
    incomeCmUsd: 2790,
    cmIncomeTaka: 326430,
    expensesTaka: 439176,
    estNetProfitTaka: 112746,
    projectedProfitTaka: 46802,
    varianceProfitTaka: 65945,
    npPercentage: "13.0132455",
  },
  {
    key: "2",
    merchandiser: "Ms. ZOHORA",
    buyerName: "REVO SOURCING",
    folding: 2580,
    cmPerPc: 1.1,
    incomeCmUsd: 2838,
    cmIncomeTaka: 332046,
    expensesTaka: 446732,
    estNetProfitTaka: 114686,
    projectedProfitTaka: 47607,
    varianceProfitTaka: 67079,
    npPercentage: "13.2300687867",
  },
  {
    key: "3",
    merchandiser: "MR. SUMON",
    buyerName: "G-TEX",
    folding: 1550,
    cmPerPc: 1.8,
    incomeCmUsd: 2790,
    cmIncomeTaka: 326430,
    expensesTaka: 439176,
    estNetProfitTaka: 112746,
    projectedProfitTaka: 46802,
    varianceProfitTaka: 65945,
    npPercentage: "13.0132455",
  },
  {
    key: "4",
    merchandiser: "Ms. ZOHORA",
    buyerName: "REVO SOURCING",
    folding: 2580,
    cmPerPc: 1.1,
    incomeCmUsd: 2838,
    cmIncomeTaka: 332046,
    expensesTaka: 446732,
    estNetProfitTaka: 114686,
    projectedProfitTaka: 47607,
    varianceProfitTaka: 67079,
    npPercentage: "13.2300687867",
  },
];
export default function DailyProfitLossStatement({
  todayData,
  loadingTodayData,
  endDateWatch,
}) {
  if (loadingTodayData) return <Spin />;
  return (
    <Card
      title={`Daily Profit & (Loss) Statement of: (${endDateWatch?.format(
        "DD-MMM-YY",
      )})`}
      bordered={false}
      style={{
        boxShadow: "none",
      }}
    >
      {/* Daily Profit & (Loss) Statement  */}
      <Table
        dataSource={dataSourceForToday}
        columns={columnsForToday}
        pagination={false}
        bordered
        loading={loadingTodayData}
        title={() => "Production, Income & profit:"}
        summary={(data) => {
          let totalFolding = 0;
          let totalAvgCmUsd = 0;
          let totalIncomeCmUsd = 0;
          let totalIncomeCmTaka = 0;
          let totalExpensesTaka = 0;
          let totalEstNetProfitTaka = 0;
          let totalProjectedProfitTaka = 0;
          let totalVarianceProfitTaka = 0;
          data.forEach(
            ({
              folding,
              avgCmUsd,
              incomeCmUsd,
              incomeCmTaka,
              expenditureTaka,
              estNetProfitTaka,
              projectedProfitTaka,
              varianceProfitTaka,
            }) => {
              totalFolding += parseFloat(folding);
              totalAvgCmUsd += parseFloat(avgCmUsd);
              totalIncomeCmUsd += parseFloat(incomeCmUsd);
              totalIncomeCmTaka += parseFloat(incomeCmTaka);
              totalExpensesTaka += parseFloat(expenditureTaka);
              totalEstNetProfitTaka += parseFloat(estNetProfitTaka);
              totalProjectedProfitTaka += parseFloat(projectedProfitTaka);
              totalVarianceProfitTaka += parseFloat(varianceProfitTaka);
            },
          );
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={2}>
                  {formatIntWithCommas(totalFolding)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  {formatFloatWithCommas(totalAvgCmUsd / data.length)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  {formatIntWithCommas(totalIncomeCmUsd)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  {formatIntWithCommas(totalIncomeCmTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  {formatIntWithCommas(totalExpensesTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="right">
                  {formatIntWithCommas(totalEstNetProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align="right">
                  {formatIntWithCommas(totalProjectedProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align="right">
                  {formatIntWithCommas(totalVarianceProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <br />

      <Table
        dataSource={dataSourceSharingMerchandisers}
        columns={columnsSharingMerchandisers}
        pagination={false}
        bordered
        loading={loadingTodayData}
        title={() => "Sharing of above All by Merchandisers: "}
        footer={() =>
          "The expenses have been allocated on pc based on % of CM on total earned CM."
        }
        summary={(data) => {
          let totalFolding = 0;
          let totalCmPerPc = 0;
          let totalIncomeCmUsd = 0;
          let totalCmIncomeTaka = 0;
          let totalExpensesTaka = 0;
          let totalEstNetProfitTaka = 0;
          let totalProjectedProfitTaka = 0;
          let totalVarianceProfitTaka = 0;
          let totalNpPercentage = 0;
          data.forEach(
            ({
              folding,
              cmPerPc,
              incomeCmUsd,
              cmIncomeTaka,
              expensesTaka,
              estNetProfitTaka,
              projectedProfitTaka,
              varianceProfitTaka,
              npPercentage,
            }) => {
              totalFolding += parseFloat(folding);
              totalCmPerPc += parseFloat(cmPerPc);
              totalIncomeCmUsd += parseFloat(incomeCmUsd);
              totalCmIncomeTaka += parseFloat(cmIncomeTaka);
              totalExpensesTaka += parseFloat(expensesTaka);
              totalEstNetProfitTaka += parseFloat(estNetProfitTaka);
              totalProjectedProfitTaka += parseFloat(projectedProfitTaka);
              totalVarianceProfitTaka += parseFloat(varianceProfitTaka);
              totalNpPercentage += parseFloat(npPercentage);
            },
          );
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={2}>
                  {formatIntWithCommas(totalFolding)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  {formatFloatWithCommas(totalCmPerPc)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  {formatIntWithCommas(totalIncomeCmUsd)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  {formatIntWithCommas(totalCmIncomeTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  {formatIntWithCommas(totalExpensesTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={6}
                  align="
                  right"
                >
                  {formatIntWithCommas(totalEstNetProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align="right">
                  {formatIntWithCommas(totalProjectedProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align="right">
                  {formatIntWithCommas(totalVarianceProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} align="right">
                  {formatFloatWithCommas(totalNpPercentage)}%
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Card>
  );
}
