import {
    GET_BRAND_LIST,
    CREATE_NEW_BRAND,
    DELETE_BRAND,
    GET_BRAND_DETAIL,
    UPDATE_BRAND_DETAIL
} from '../ActionTypes';

const initialState = {
    brandList: [],
    totalBrand: null,
    selectedBrand: null,
    createdBrand: null,
    pageSize: 10,
    page:1,
};

const BrandReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_BRAND_LIST:
            return {
                ...state,
                brandList: action.payload.data,
                totalBrand: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_BRAND:
            return {
                ...state,
                createdBrand: action.payload.data,
                brandList: [action.payload.data, ...state.brandList],
                totalBrand: state.totalBrand + 1,
            };

        case DELETE_BRAND: {
            return {
                ...state,
                brandList:  state.brandList.filter(element => element !== action.payload),
                totalBrand: action.payload.total,
                selectedBrand: action.payload,
            };
        }

        case GET_BRAND_DETAIL:
            return {
                ...state,
                selectedBrand: action.payload,
            };

        case UPDATE_BRAND_DETAIL:
            return {
                ...state,
                selectedBrand: action.payload,
                brandList: state.brandList.map((brand) =>
                    brand.id === action.payload.id ? action.payload : brand,
                ),
            };

        default:
            return state;
    }
};

export default BrandReducer;
