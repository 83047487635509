import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
} from "antd";
import { MoreOutlined, EditOutlined, DeleteTwoTone, EditTwoTone, EyeOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import {
  GET_EXPORT_DETAILS_LIST,
  DELETE_EXPORT_DETAILS,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { alertPop } from "../../../apiServices/common/helper";
import { cglFormatDate, formatNumberWithCommas } from "../../../utils/functions";
import { hasPermission } from "../../../utils/functions";
import {
  EXPORT_DETAILS_CREATE_PERMISSION,
  EXPORT_DETAILS_EDIT_PERMISSION,
  EXPORT_DETAILS_DELETE_PERMISSION,
  EXPORT_DETAILS_VIEW_PERMISSION,
} from "../../../routes/permissions";

export default function ExportDetails(props) {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  //   States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [exportDetailsList, setExportDetailsList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();



  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const searchFilter = (values) => {
    console.log("values", values);
  };

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: ["invoice_number"],
      key: "invoice_number",
      width: "20%",
    },
    {
      title: "Invoice Date",
      dataIndex: ["invoice_date"],
      key: "invoice_date",
      width: "16%",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Invoice Value",
      dataIndex: ["invoice_value"],
      key: "invoice_value",
      render: (value) => formatNumberWithCommas(value),
      width: "16%",
    },
    {
      title: "Invoice Quantity",
      dataIndex: ["total_quantity"],
      key: "total_quantity",
      render: (value) => formatNumberWithCommas(value),
      width: "16%",
    },
    {
      title: "OnBoard Date",
      dataIndex: ["on_board_date"],
      key: "on_board_date",
      width: "16%",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Action",
      key: "action",
      width: "16%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          removeExportDetails(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([
                      EXPORT_DETAILS_DELETE_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openForEdit(record?.id);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([
                      EXPORT_DETAILS_EDIT_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EyeOutlined />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openForView(record?.id);
                          }}
                        >
                          View
                        </span>
                      </>
                    ),
                    key: "3",
                    disabled: !hasPermission([
                      EXPORT_DETAILS_VIEW_PERMISSION,
                    ]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  const getAllExportDetails = async (passedObject) => {
    let filter = passedObject;
    let response = await getData(GET_EXPORT_DETAILS_LIST, false, filter);

    if (response && response?.status === 200) {
      let masterData = response?.data;
      setExportDetailsList(masterData?.data?.data);
      setTotalData(masterData?.data?.total);
      setRangeData(masterData?.data?.per_page);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllExportDetails({page: currentPage});
  }, [currentPage]);

  const removeExportDetails = async (value) => {
    const response = await deleteData(`${DELETE_EXPORT_DETAILS}/${value}`);

    if (response && response?.code === 200) {
      alertPop(
        "success",
        response?.messages ? response?.messages : "Deleted Successfully!",
      );
      getAllExportDetails();
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  };

  const openForEdit = (id) => {
    navigate(`/export/edit-export-details/${id}`)
  };

  const openForView = (id) => {
    navigate(`/export/view-export-details/${id}`)
  };

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Export Details"
            subTitle=""
            actions={[
              <Button
                type={showFilter ? "danger" : "primary"}
                size="small"
                onClick={handleFilter}
                style={{ float: "right" }}
              >
                {`${showFilter ? "Close" : "Open"} Filter Option`}
              </Button>,
              <Button
                key="3"
                size="small"
                type="primary"
                disabled={!hasPermission([EXPORT_DETAILS_CREATE_PERMISSION])}
              >
                <Link to="/export/add-export-details">
                  {hasPermission([EXPORT_DETAILS_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"}
                </Link>
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader
            searchFilter={searchFilter}
          />
          <CustomFilter
            showFilter={showFilter}
            tableData={exportDetailsList}
            fetchTableData={getAllExportDetails}
            filterName="export_details"
          />
          <Table
            loading={loading}
            dataSource={exportDetailsList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}
