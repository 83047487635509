import { message, Typography } from 'antd';
import AppCards from 'common/Cards';
import { getData } from "../../../apiServices/common";
import {
    ERP_USAGE_DASHBOARD_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { useEffect, useState } from 'react';
import {
  AppstoreOutlined,
  DesktopOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  CheckCircleOutlined,
  PlusSquareOutlined,
  MinusCircleOutlined,
  MinusSquareOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileExclamationOutlined,
  FileImageOutlined,
  FileUnknownOutlined,
  FileProtectOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons'; // Imported Ant Design icons

const ERPUsageDashboard = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [dataCountList, setDataCountList] = useState(null);

  // Antd
  const { Title } = Typography;

  const getAllERPDashboardData = async (filterValues = {}) => {
    setLoading(true);

    const query = `${ERP_USAGE_DASHBOARD_LIST_ENDPOINT}`;
    const payload = { ...filterValues };

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      console.log("getAllERPDashboardData", response.data?.data);
      setDataCountList(response.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  // Effects
  useEffect(() => {
    getAllERPDashboardData();
  }, []);

  // Icon components with Ant Design
  const moduleIcon = () => <AppstoreOutlined style={{ fontSize: 30, color: '#4A5568' }} />;
  const moduleActiveIcon = () => <PlusSquareOutlined style={{ fontSize: 30, color: '#48BB78' }} />;
  const moduleInactiveIcon = () => <MinusSquareOutlined style={{ fontSize: 30, color: '#E53E3E' }} />;
  const uiIcon = () => <FileImageOutlined style={{ fontSize: 30, color: '#5499c7' }} />;
  const uiCreatedIcon = () => <FileDoneOutlined style={{ fontSize: 30, color: '#8e44ad' }} />;
  const uiHaltIcon = () => <FileUnknownOutlined style={{ fontSize: 30, color: '#ECC94B' }} />;
  const uiInUseIcon = () => <FileProtectOutlined style={{ fontSize: 30, color: '#48BB78' }} />;
  const totalIssueIcon = () => <FileUnknownOutlined style={{ fontSize: 30, color: '#E53E3E' }} />;
  const createdIssueIcon = () => <PlusCircleOutlined style={{ fontSize: 30, color: '#38A169' }} />;
  const haltIssueIcon = () => <FileExclamationOutlined style={{ fontSize: 30, color: '#ECC94B' }} />;
  const inUseIssueIcon = () => <PlayCircleOutlined style={{ fontSize: 30, color: '#3182CE' }} />;
  const resolvedIssueIcon = () => <FileDoneOutlined style={{ fontSize: 30, color: '#48BB78' }} />;

  // Card arrays with correct count values
  const cardArrModules = [
    {
      title: "Total Modules",
      count: dataCountList?.modules_total || 0,
      icon: moduleIcon,
    },
    {
      title: "Total Active Modules",
      count: dataCountList?.modules_active_total || 0,
      icon: moduleActiveIcon,
    },
    {
      title: "Total Inactive Modules",
      count: dataCountList?.modules_inactive_total || 0,
      icon: moduleInactiveIcon,
    },
  ];

  const cardArrUis = [
    {
      title: "Total UIs",
      count: dataCountList?.module_uis_total || 0,
      icon: uiIcon,
    },
    {
      title: "Total Created UIs",
      count: dataCountList?.module_uis_created_total || 0,
      icon: uiCreatedIcon,
    },
    {
      title: "Total Halt UIs",
      count: dataCountList?.module_uis_halt_total || 0,
      icon: uiHaltIcon,
    },
    {
      title: "Total In Use UIs",
      count: dataCountList?.module_uis_in_use_total || 0,
      icon: uiInUseIcon,
    },
  ];

  const cardArrIssues = [
    {
      title: "Total Issues",
      count: dataCountList?.issues_total || 0,
      icon: totalIssueIcon,
    },
    {
      title: "Total Halt Issues",
      count: dataCountList?.issues_total_halt || 0,
      icon: haltIssueIcon,
    },
    {
      title: "Total Resolved Issues",
      count: dataCountList?.issues_total_resolved || 0,
      icon: resolvedIssueIcon,
    },
  ];

  return (
    <>
      <Title style={{ marginTop: '10px' }} level={5}>ERP Usage Dashboard</Title>
      <Title style={{ marginTop: '30px' }} level={3}>Modules</Title>
      <AppCards cardArr={cardArrModules} itemsPerRow={3} marginBottom={15} />
      <Title style={{ marginTop: '10px' }} level={3}>UIs</Title>
      <AppCards cardArr={cardArrUis} itemsPerRow={3} marginBottom={15} />
      <Title style={{ marginTop: '10px' }} level={3}>Issues</Title>
      <AppCards cardArr={cardArrIssues} itemsPerRow={3} marginBottom={15} />
    </>
  );
};

export default ERPUsageDashboard;
