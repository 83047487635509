import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Dropdown,
  Menu,
  Typography,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MoreOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { PURCHAS_ORDER_LIST_NEW } from "../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../apiServices/common";
import { hasPermission } from "../../utils/functions";
import {
  BUYER_PO_PO_WISE_VIEW_PERMISSION,
  BUYER_PO_PO_WISE_CREATE_PERMISSION,
  BUYER_PO_PO_WISE_EDIT_PERMISSION,
  BUYER_PO_PO_WISE_DELETE_PERMISSION,
} from "../../routes/permissions";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";

const PurchaseOrder = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState(null);

  // Antd
  const { Title } = Typography;

  // Router
  const navigate = useNavigate();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const gotoUpdateRoute = (record) => {
    const buying_office_id = record?.buying_office_id;
    const sales_contract_id = record?.sales_contract_id;
    const buyer_id = record?.buyer_id;
    const po_map_id = record?.po_map_id;

    const updateLink = `/merchandising/update-purchase-order-new`;

    const queryParams = {
      buying_office_id,
      sales_contract_id,
      buyer_id,
      po_map_id,
    };

    navigate({
      pathname: updateLink,
      search: new URLSearchParams(queryParams).toString(),
    });
  };

  const columns = [
    {
      title: "Buying Office Name",
      dataIndex: ["buying_office_name"],
      key: "buying_office_name",
    },
    {
      title: "Buyer Name",
      dataIndex: ["buyer_name"],
      key: "buyer_name",
    },
    {
      title: "Original PO",
      dataIndex: "original_po",
      key: "original_po",
    },
    {
      title: "Reference No.",
      dataIndex: "reference_no",
      key: "id",
    },
    {
      title: "Actions",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllPermitList = useCallback(
    async (passedObject) => {
      setLoading(true);

      let filter = passedObject;
      const customQuery = `${PURCHAS_ORDER_LIST_NEW}/?page=${currentPage}`;
      let res = await getData(customQuery, false, filter);

      if (res) {
        let masterData = res?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      setLoading(false);
    },
    [currentPage],
  );

  useEffect(() => {
    getAllPermitList();
  }, [currentPage, getAllPermitList]);

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <span onClick={() => gotoUpdateRoute(data)}>
              <EditOutlined /> Edit
            </span>
          ),
          key: "0",
          disabled: !hasPermission([BUYER_PO_PO_WISE_EDIT_PERMISSION]),
        },
        {
          label: (
            <Link to={`/merchandising/view-purchase-order/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([BUYER_PO_PO_WISE_VIEW_PERMISSION]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const onAddNew = () => {
    navigate("/merchandising/add-purchase-order-new");
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                handleAddItemOpen={onAddNew}
                disabled={!hasPermission([BUYER_PO_PO_WISE_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([BUYER_PO_PO_WISE_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Buyer PO (New)</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={permitList}
              filterAPI={getAllPermitList}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="purchase_order"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={permitList}
                columns={columns}
                pagination={{
                  showSizeChanger: false,
                  current: currentPage,
                  total: totalData,
                  pageSize: rangeData,
                  onChange: (page) => paginate(page),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default PurchaseOrder;
