import { forwardRef, useState, useEffect, useCallback } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Select,
  Spin,
  Radio,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { GET_USERS_ENDPOINT, POST_TNA_DEPARTMENT_DATA, POST_TNA_PROCESS_DATA, UPDATE_TNA_DEPARTMENT_DATA, UPDATE_TNA_PROCESS_DATA } from "apiServices/API_ENDPOINTS";
import HandleNotification from "common/Notification";
import { isArrayAndHasValue } from "utils/functions";
import { getData, postData, putData } from "apiServices/common";
const { Option } = Select;

const DepartmentForm = forwardRef((props, ref) => {
  const { view, form, isEdit, editInfo, fileData, CMInfo, viewMode} = props;
  const navigate = useNavigate();
  const { deptId } = useParams();
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    getAllUsers();
  },[editInfo]);

  const onFinish = async (values) => {
    // console.log("onFinish values", values);

    const addQuery = `${POST_TNA_DEPARTMENT_DATA}`;
    const editQuery = `${UPDATE_TNA_DEPARTMENT_DATA}/${deptId}`;
    const bodyData = {
        title: values?.title,
        description: values?.description,
        head_id: values?.dept_head_id,
        is_active: values?.status
    };

    if(deptId > 0){
      const response = await putData(editQuery, bodyData);
      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "Success!",
          response?.message ||
            `Successfully Updated Department`,
        );
        navigate("/tanda/department");
      } else {
        HandleNotification(
          "error",
          "Error!",
          response?.message || `Failed to Update Department`,
        );
      }
    }else{
      const response = await postData(addQuery, bodyData);
      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "Success!",
          response?.message ||
            `Successfully Added Department`,
        );
        navigate("/tanda/department");
      } else {
        HandleNotification(
          "error",
          "Error!",
          response?.message || `Failed to add Department`,
        );
      }
    }
  };

  const getAllUsers = async (filterValues) => {
    setIsLoading(true);
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };

    const response = await getData(GET_USERS_ENDPOINT, false, bodyData);
    if (response && response?.data?.code === 200) {
      setUsers(response?.data?.data?.data);
    }
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          status: 1
        }}
      >
        <Card>
          <Row gutter={6}>
            <Col span={6}>
              <Form.Item
                label="Department Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: `Department Title is required `,
                  },
                ]}
              >
                <Input placeholder="Department Title ...." size="small" disabled={viewMode}/>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: false,
                    message: `Description is required `,
                  },
                ]}
              >
                <TextArea size="small" placeholder="Description" disabled={viewMode}/>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="dept_head_id" hidden />
              <Form.Item
                label="Department Head"
                name="dept_head"
                rules={[
                  {
                    required: false,
                    message: `Department Head is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select department head ..."
                  optionFilterProp="children"
                  disabled={viewMode}
                  size="small"
                  onSearch={(value) => getAllUsers({ name: value })}
                  allowClear
                  onSelect={(value) => {
                    form.setFieldsValue({
                      dept_head_id: value,
                    });
                  }}
                >
                  {isArrayAndHasValue(users) &&
                    users?.map((userItem, index) => (
                      <Option value={userItem?.id} key={userItem?.id || index}>
                        {userItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: `Status is required `,
                  },
                ]}
               
              >
                 <Radio.Group  disabled={viewMode}>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>In-Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
           
          </Row>
        </Card>
      </Form>
    </Spin>
  );
});

export default DepartmentForm;
