import { Layout } from "antd";
import "antd/dist/antd.css";
import { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import LogIn from "../../components/LogIn";
import { menu } from "../../routes/menuConfig";
import { isArrayAndHasValue } from "../../utils/functions";
import AppHeader from "../Header";
import SideBar from "../SideBar";
import "./index.css";
import { debounce } from "lodash";

const { Content } = Layout;

const AppLayOut = ({ children }) => {
  // States
  const [collapsed, setCollapsed] = useState(false);
  const [menuList, setMenuList] = useState(menu);

  // Router
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const without_layout = searchParams.get("without_layout") || null;

  useEffect(() => {
    // Store location history in localstorage
    const maxRoutesToStore = 5;
    const currentPath = location.pathname;
    const locationItem = localStorage.getItem("locationHistory");
    const locationHistory = locationItem ? JSON.parse(locationItem) : [];
    locationHistory.unshift(currentPath);
    const uniqueRoutes = [...new Set(locationHistory)];
    const routesHistory = uniqueRoutes.slice(0, maxRoutesToStore);
    localStorage.setItem("locationHistory", JSON.stringify(routesHistory));
  }, [location]);

  const contentRef = useRef(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      const contentElement = contentRef.current;
      if (contentElement) {
        // Perform your resize logic here
        console.log("Resized:", contentElement.getBoundingClientRect());
      }
    }, 100); // Adjust debounce delay as needed

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    const contentElement = contentRef.current;
    if (contentElement) {
      resizeObserver.observe(contentElement);
    }

    return () => {
      if (contentElement) {
        resizeObserver.unobserve(contentElement);
      }
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    // If collapsed reset menu to default
    if (collapsed) {
      setMenuList(menu);
    }
  }, [collapsed]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onSearchMenu = (menuName) => {
    // Reset to default menu if cleared / no value
    if (!menuName) {
      setMenuList(menu);
      return;
    }

    // Sort menu by given menu name
    const sortedByMenuName = [];
    menuList?.forEach((item) => {
      if (item?.key?.toLowerCase()?.includes(menuName) && !item?.children) {
        sortedByMenuName.push(item);
      }
      if (item?.children) {
        const sortedBySubMenuName = item?.children?.filter((subItem) =>
          subItem?.key?.toLowerCase()?.includes(menuName),
        );

        isArrayAndHasValue(sortedBySubMenuName) &&
          sortedByMenuName.push({
            ...item,
            children: sortedBySubMenuName,
          });
      }
    });

    isArrayAndHasValue(sortedByMenuName) && setMenuList(sortedByMenuName);
  };

  let layout;

  if (location.pathname === "/login") {
    layout = <LogIn />;
  } else if (without_layout) {
    layout = (
      <div
        style={{
          padding: "0 5px 5px",
          height: "100%", // Ensure the layout takes the full viewport height
          overflow: "auto", // Enable scrolling if content overflows
        }}
        className="site-layout"
      >
        {children}
      </div>
    );
  } else {
    layout = (
      <Layout>
        <Content
          ref={contentRef}
          style={{ minHeight: "280px" }}
        >
          <AppHeader
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
            onSearchMenu={onSearchMenu}
          />
          <Layout style={{ height: "100%" }} className="body-layout">
            <SideBar collapsed={collapsed} menuList={menuList} />
            <Layout
              style={{
                padding: "0 5px 5px",
              }}
              className="site-layout"
            >
              {children}
            </Layout>
          </Layout>
        </Content>
      </Layout>
    );
  }

  return layout;
};
export default AppLayOut;
