import { EditTwoTone } from "@ant-design/icons";
import { Dropdown, Image, Menu, message, Table, Typography } from "antd";
import {
  DAILY_LINE_PLAN,
  DIGITALIZATION_CHECKLIST_QUESTION,
  ImportDailyLinePlan,
} from "apiServices/API_ENDPOINTS";
import { deleteData, getData } from "apiServices/common";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  DAILY_LINE_PLAN_IMPORT_PERMISSION,
  NO_PERMISSION_REQUIRED,
} from "routes/permissions";
import { hasPermission } from "utils/functions";

const ChecklistQuestionsList = () => {
  // Router
  const navigate = useNavigate();
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [showFilter, setShowFilter] = useState();

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const getAppRoleMenu = useCallback(
    async (filterValues) => {
      setLoading(true);

      const query = `${DIGITALIZATION_CHECKLIST_QUESTION}?page=${currentPage}`;
      const bodyData = {
        ...(filterValues?.name && { name: filterValues?.name }),
        ...(filterValues?.role_id && { role_id: filterValues?.role_id }),
        ...{ status: filterValues?.status },
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting vehicle info data");
      }
    },
    [currentPage],
  );

  const onAdd = () => {
    navigate("/checklist/checklist-questions/add");
  };

  const onEdit = (id) => {
    navigate(`/checklist/checklist-questions/edit/${id}`);
  };

  const onDelete = async (id) => {
    setLoading(true);
    const response = await deleteData(`${DAILY_LINE_PLAN}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success(
        response?.message || "Defect Line Plan Deleted Successfully",
      );
      refetch();
    } else {
      setLoading(false);
      message.error("Error Deleting Defect Line Plan");
    }
  };

  // Effects
  useEffect(() => {
    getAppRoleMenu();
  }, [refresh, getAppRoleMenu]);

  const tableColumns = [
    {
      title: "Name (English)",
      dataIndex: "name_en",
      key: "name_en",
    },
    {
      title: "Name (Bangla)",
      dataIndex: "name_bn",
      key: "name_bn",
    },
    {
      title: "Role Name",
      dataIndex: ["role", "name"],
      key: "role_name",
    },
    {
      title: "Image",
      dataIndex: "image_path",
      key: "image_path",
      render: (text) => {
        return (
          <Image width={80} src={`${process.env.REACT_APP_BASE_URL}/${text}`} />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="0"
                  onClick={() => {
                    onEdit(record.id);
                  }}
                >
                  <EditTwoTone />
                  Edit
                </Menu.Item>
                {/* <Menu.Item
                  key="1"
                  onClick={() => {
                    onDelete(record.id);
                  }}
                >
                  Delete
                </Menu.Item> */}
              </Menu>
            }
            trigger={["click"]}
          >
            <span
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              style={{ cursor: "pointer", color: "#1890ff" }}
            >
              Actions
            </span>
          </Dropdown>
        );
      },
    },
  ];
  console.log(
    "DAILY_LINE_PLAN_IMPORT_PERMISSION",
    DAILY_LINE_PLAN_IMPORT_PERMISSION,
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onAdd}
                disabled={!hasPermission([NO_PERMISSION_REQUIRED])}
                addButtonText={
                  hasPermission([NO_PERMISSION_REQUIRED])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={
              <Typography.Title level={5}>Checklist Questions</Typography.Title>
            }
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAppRoleMenu}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="checklist-questions-filter"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default ChecklistQuestionsList;
