import { DatePicker } from "antd";
import moment from "moment";
import PropTypes from "prop-types";

const CustomDatePicker = ({
  onChange,
  size = "small",
  style = { width: "100%" },
  displayFormat = "DD-MMM-YYYY",
  value,
  placeholder,
  disabled,
  disabledDate,
  allowClear = true,
  ...rest
}) => {
  const handleChange = (date) => {
    if (date) {
      const formattedDate = moment(date).toISOString();
      onChange?.(formattedDate);
    } else {
      // Handle cleared value
      onChange?.(null);
    }
  };

  return (
    <DatePicker
      style={style}
      size={size}
      format={displayFormat}
      onChange={handleChange}
      value={value ? moment(value) : null}
      placeholder={placeholder}
      disabled={disabled}
      disabledDate={disabledDate}
      allowClear={allowClear}
      {...rest}
    />
  );
};

CustomDatePicker.propTypes = {
  onChange: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  style: PropTypes.object,
  displayFormat: PropTypes.string,
  //   payloadFormat: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  disabledDate: PropTypes.func,
};

export default CustomDatePicker;
