import { Table } from "antd";

const GarmentsTrackingTable = (props) => {
  const { total, loading, defaultCurrent, pageSize, current, onChange } = props;

  return (
    <>
      {/* {props.filter && (
        <CustomFilter
          showFilter={showFilter}
          handleFilter={handleFilter}
          tableData={props.dataSource}
          fetchTableData={null}
          filterName="buying_office"
        />
      )} */}
      <div className="table" style={{ marginTop: 10 }}>
        <Table
          loading={loading}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: pageSize,
            current: current,
            onChange: onChange,
            defaultCurrent: defaultCurrent,
            total: total,
          }}
          columns={props.tableColumns}
          dataSource={props.dataSource}
        />
      </div>
    </>
  );
};
export default GarmentsTrackingTable;
