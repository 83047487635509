import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "../../../../apiServices/common";
import AddPiForm from "./AddPiForm";
import { PI_APPROVE_PERMISSION } from "../../../../routes/permissions";
import { hasPermission } from "../../../../utils/functions";

export default function EditExportSalesContract(props) {
  // Props
  const { width } = props;

  // States
  const [permitInfo, setPermitInfo] = useState();
  const [piStatus, setPiStatus] = useState();
  const [loading, setLoading] = useState(false);

  // Others
  let { importId } = useParams();
  let show_approve = 1;
  const propertyFormRef = useRef(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const discartForm = () => {
    navigate("/import/pi");
  };

  const addProduct = () => {
    form.submit();
  };

  const edit_url = `/api/accounting/import-pi/view/${importId}`;
  const getPIInfo = async () => {
    let res = await getData(edit_url);
    if (res) {
      let masterData = res?.data?.data[0];
      setPermitInfo(masterData);
      setPiStatus(masterData.sales_contract_commercial.status);
    }
  };

  useEffect(() => {
    getPIInfo();
  }, []);
  //console.log("propertyFormRef",propertyFormRef);console.log("permitInfo",permitInfo)
  const checkStatus = ["Created", "Updated"];
  
  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="View Proforma Invoice"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} danger onClick={() => discartForm()}>
                Discard
              </Button>,
              checkStatus.includes(piStatus) && (
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => addProduct()}
                  disabled={!hasPermission([PI_APPROVE_PERMISSION])}
                  loading={loading}
                >
                  {hasPermission([PI_APPROVE_PERMISSION])
                    ? "Approve"
                    : "No Permission"}
                </Button>
              ),
            ]}
          />
        </Card>
      </Affix>

      <AddPiForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        form={form}
        view={true}
        show_approve={show_approve}
        setLoading={setLoading}
      />
    </>
  );
}
