import { Col, Form, Row, Select, Input } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const { Option } = Select;
const ItemListFilterFields = ({ initialTableData }) => {
  const categoryList = useSelector(
    ({ itemCategory }) => itemCategory.itemCategoryList,
  );

  return (
    <>
      <Col span={4}>
        <Form.Item label="Item Category" name="category_id">
          <Select
            placeholder="Select a Category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            style={{ width: "100%" }}
            size="small"
          >
            {categoryList &&
              categoryList?.map((item, Idx) => (
                <Option value={item?.id}>{item?.name}</Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Item Name" name="name">
          <Input placeholder="Write Item" size="small"/>
        </Form.Item>
      </Col>
    </>
  );
};

export default ItemListFilterFields;
