import React, { Fragment, useState } from "react";

import {
  calendarBodyDateStyle,
  calendarBodyLinesStyle,
  calendarHeaderDayStyle,
  calendarHeaderWeekdayStyle,
  calendarHeaderYearMonthStyle,
  calendarMainStyle,
  calendarProductionListPStyle,
  calendarProductionListStyle,
} from "./style";
import {
  handleDragEnd,
  handleDragOver,
  handleDragStart,
  handleDrop,
  onClickPlanEntry,
} from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import Matrix from "./Matrix";

const Calendar = ({ calendarData, setCalendarData }) => {
  const navigation = useNavigate();
  // console.log("calendarData", calendarData);
  const dayWithDutyColumns = 1;
  const lineListColumns = 2 * dayWithDutyColumns;

  const days =
    calendarData.calendarHeader.days.length * dayWithDutyColumns +
    lineListColumns;

  // Dragging state to track dragged item
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // console.log("calendarData.calendarBody", calendarData.calendarBody);

  return (
    <div style={calendarMainStyle(days, calendarData.calendarBody)}>
      <div style={calendarProductionListStyle(lineListColumns)}>
        <p style={calendarProductionListPStyle}>Production List</p>
      </div>
      <div style={calendarHeaderYearMonthStyle(days, lineListColumns)}>
        {calendarData.calendarHeader.year} - {calendarData.calendarHeader.month}
      </div>
      {calendarData.calendarHeader.days.map((day, index) => (
        <div
          key={index}
          style={calendarHeaderDayStyle(dayWithDutyColumns, day)}
        >
          {day.day}
        </div>
      ))}
      {calendarData.calendarHeader.days.map((day, index) => (
        <div
          key={index}
          style={calendarHeaderWeekdayStyle(dayWithDutyColumns, day)}
        >
          {day.weekday}
        </div>
      ))}
      {calendarData.calendarBody.map((line, line_index) => (
        <Fragment key={line_index}>
          <div style={calendarBodyLinesStyle(lineListColumns)}>
            {line.line_name}
          </div>
          {line.lines.map((task, task_index) => {
            return (
              <Tooltip
                title={task.title}
                placement="bottom"
                key={`${line_index}-${task_index}`}
              >
                <div
                  onClick={() => {
                    if (task.id === null) {
                      navigation(
                        `/production-planning/plan-entry?line_id=${line.line_id}&start_date=${task.startDate}`,
                      );
                    }
                  }}
                  draggable={
                    task.title === "" &&
                    task.details?.length === 0 &&
                    task?.delivery_status === "unplanned"
                      ? false
                      : true
                  }
                  onDrop={(e) =>
                    handleDrop(e, {
                      line_index,
                      task_index,
                      draggedIndex,
                      line,
                      task,
                      calendarData,
                      setDraggedIndex,
                      setCalendarData,
                    })
                  }
                  onDragStart={(e) =>
                    handleDragStart(e, {
                      line_index,
                      task_index,
                      draggedIndex,
                      line,
                      task,
                      calendarData,
                      setDraggedIndex,
                      setCalendarData,
                    })
                  }
                  onDragOver={(e) => {
                    handleDragOver(e);
                    setHoveredIndex(`${line_index}-${task_index}`);
                  }}
                  onDragEnd={(e) => {
                    handleDragEnd(e);
                    setHoveredIndex(null);
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      "rgb(87, 207, 255)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      calendarBodyDateStyle(
                        dayWithDutyColumns,
                        task,
                      ).backgroundColor)
                  }
                  style={{
                    ...calendarBodyDateStyle(dayWithDutyColumns, task),
                    backgroundColor:
                      hoveredIndex === `${line_index}-${task_index}`
                        ? "rgb(87, 207, 255)"
                        : calendarBodyDateStyle(dayWithDutyColumns, task)
                            .backgroundColor,
                  }}
                >
                  {/* <Matrix task={task} /> */}
                </div>
              </Tooltip>
            );
          })}
        </Fragment>
      ))}
    </div>
  );
};

export default Calendar;
