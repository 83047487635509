// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.digitalization_hour .ant-card-body {
    padding: 20px !important;
}
.digitalization_hour .ant-space-item:nth-child(-n+3) {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Digitalization/HourlyProductionRecordHours/index.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;AACA;IACI,WAAW;AACf","sourcesContent":[".digitalization_hour .ant-card-body {\n    padding: 20px !important;\n}\n.digitalization_hour .ant-space-item:nth-child(-n+3) {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
