import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  message,
} from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GET_ALL_UNITS,
  GET_ITEM_CATEGORIES_API,
  GET_SUPPLIER_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { deleteData, getData, getWithData } from "../../../apiServices/common";
import HandleNotification from "../../../common/Notification";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
  toFixedAndComma,
} from "../../../utils/functions";
import BulkItemsManage from "./BulkItemsManage";
import ItemEdit from "./ItemEdit";

const ItemDetails = (props) => {
  // Props
  const {
    view,
    itemDetailsList,
    setItemDetailsList,
    subStyleList,
    form,
    style,
  } = props;

  // States
  const [itemTypeList, setItemTypeList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [drawerType, setDrawerType] = useState(null);
  const [activeBulkKey, setActiveBulkKey] = useState(null);
  const [foundItems, setFoundItems] = useState(null);

  // Antd
  const { Option } = Select;
  const { confirm } = Modal;
  const [searchForm] = Form.useForm();
  const { Panel } = Collapse;

  // Router
  const { bomId } = useParams();

  // Others
  const searchRef = useRef();
  const tableBottomRef = useRef();

  const removeSelectedProduct = async (value, index) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        let is_add = itemDetailsList.at(index);
        if (is_add && is_add["is_add"] === true) {
          itemDetailsList.splice(index, 1);
          setItemDetailsList([...itemDetailsList]);
        } else {
          let res = await deleteData(
            `/api/bill_of_material/${value}/delete_details`,
          );

          if (res?.code === 200) {
            itemDetailsList.splice(index, 1);
            setItemDetailsList([...itemDetailsList]);
            setItemList(res?.data?.data?.data);
            HandleNotification("success", "bottomRight", "Item Deleted", null);
          }
        }
      },
    });
  };

  const showTotalPrice = (product) => {
    if (
      product &&
      product.order_quantity &&
      product.per_product_required_quantity
    ) {
      const extra_qty_percent = product?.extra_qty
        ? Number(product?.extra_qty)
        : 0;
      const total_req =
        product.order_quantity * product.per_product_required_quantity;
      const extra_count = (extra_qty_percent * total_req) / 100;
      const subTotalReq = total_req + extra_count;
      product.required_quantity = subTotalReq;
      return subTotalReq.toFixed(2) || 0;
    } else return 0;
  };

  const showAllTotalPrice = (product) => {
    if (product && product.unit_cost && product.required_quantity) {
      let total_all_cost = product.unit_cost * product.required_quantity;
      product.total_cost = total_all_cost;
      return total_all_cost.toFixed(2);
    } else {
      return 0;
    }
  };

  const getItemTypeList = async () => {
    const bodyData = {
      per_page: 10000,
    };
    let res = await getData(GET_ITEM_CATEGORIES_API, false, bodyData);

    if (res) {
      setItemTypeList(res?.data?.data?.data);
    }
  };

  const OnSearchgetItemTypeList = async (value) => {
    let payload = {
      name: value,
      per_page: 10,
    };
    let res = await getWithData(GET_ITEM_CATEGORIES_API, undefined, payload);

    if (res) {
      setItemTypeList(res?.data?.data?.data);
    }
  };

  const getItemList = async (value) => {
    const foundItemCategory = itemTypeList.find(
      (category) => category.id === Number(value),
    );

    if (foundItemCategory) {
      setItemList(foundItemCategory?.items);
    }
  };

  const getItemInfo = async (value, index) => {
    let res = await getData(`/api/items/${value}/edit/api`);
    let attributeInfos = res?.data?.data?.attribute_value_info;
    let imagePath = res?.data?.data?.image_path;

    onChangeHandel(attributeInfos, index, "attribute_list");
    onChangeHandel(imagePath, index, "image_path");
  };

  const getUnitList = async () => {
    const bodyData = {
      per_page: 10000,
    };

    let res = await getData(GET_ALL_UNITS, false, bodyData);

    if (res) {
      setUnitList(res?.data?.data?.data);
    }
  };

  const getSupplierList = async () => {
    const bodyData = {
      per_page: 10000,
    };
    if (style?.factory_info) {
      bodyData["local_status"] = 7;
    }

    let res = await getData(GET_SUPPLIER_ENDPOINT, false, bodyData);

    if (res) {
      setSupplierList(res?.data?.data?.data);
    }
  };

  const getSubStyleColor = (val, index) => {
    let obj = subStyleList.find((o) => o.id === val);
    let colorName = obj?.sub_style_no;
    onChangeHandel(obj?.color_id, index, "color_id");
    onChangeHandel(obj?.color_info, index, "color_info");
    onChangeHandel(colorName, index, "sub_style_color_name");
  };

  const getSubStyleNo = (value, index) => {
    const foundSubStyle = subStyleList?.find((item) => item?.id === value);
    onChangeHandel(foundSubStyle?.sub_style_no, index, "sub_style_no");
  };

  const onChangeHandel = (val, index, context) => {
    const itemDetailsListCopy = [...itemDetailsList];
    itemDetailsListCopy[index][context] = val;
    setItemDetailsList([...itemDetailsListCopy]);
  };

  // Effects
  useEffect(() => {
    getItemTypeList();
    getUnitList();
    getSupplierList();
  }, []);
  // Effects
  useEffect(() => {
    getSupplierList();
  }, [style]);

  useEffect(() => {
    if (!drawerType) {
      // Uncheck all the items if checked before
      const itemDetailsListCopy = structuredClone(itemDetailsList);
      const uncheckedList = itemDetailsListCopy?.map((item) => {
        return {
          ...item,
          is_checked: false,
        };
      });
      setItemDetailsList(uncheckedList);
    }
    // This executes only when drawerType changes
    // All other dependency warning should be ignored
  }, [drawerType]);

  const onEditOpen = (index) => {
    if (view) return;
    const itemSelected = itemDetailsList[index] || null;

    // Find and set item list dropdown data
    const foundCategory = itemTypeList.find(
      (category) => category.id === Number(itemSelected?.item_category_id),
    );
    const itemsChecked = itemDetailsList?.map((item) => ({
      ...item,
      is_checked: false,
    }));
    setItemList(foundCategory?.items);
    setItemDetailsList(itemsChecked);
    setFoundItems(itemsChecked);

    // Set selected item with index
    const withIndex = {
      ...itemSelected,
      index: index,
    };
    setDrawerType(null);
    setSelectedItem(withIndex);
    setIsDrawerOpen(true);
  };

  const onAddOpen = () => {
    setDrawerType(null);
    setSelectedItem(null);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedItem(null);
    setIsDrawerOpen(false);
    setActiveBulkKey(null);
    setDrawerType(null);

    // Reset found items, it will be calculated again
    setFoundItems(null);
  };

  const onBulkAddOpen = () => {
    setDrawerType("bulk_add");
    setActiveBulkKey(1);
    setSelectedItem(null);
    setIsDrawerOpen(true);
  };

  const onBulkEditOpen = () => {
    setDrawerType("bulk_edit");
    setActiveBulkKey(null);
    setSelectedItem(null);
    setIsDrawerOpen(true);
  };

  const onAddNewItem = (data) => {
    const itemDetailsListCopy = structuredClone(itemDetailsList);
    itemDetailsListCopy.push({
      ...data,
      is_add: true,
    });
    setItemDetailsList([...itemDetailsListCopy]);
    scrollToBottom();
    HandleNotification("success", "bottomRight", "Item Added", null);
  };

  const onUpdateItem = (index, updateData) => {
    const itemDetailsListCopy = structuredClone(itemDetailsList);
    itemDetailsListCopy[index]["item_category_id"] =
      updateData?.item_category_id;
    itemDetailsListCopy[index]["item_id"] = updateData?.item_id;
    itemDetailsListCopy[index]["unit_id"] = updateData?.unit_id;
    itemDetailsListCopy[index]["order_quantity"] = updateData?.order_quantity;
    itemDetailsListCopy[index]["per_product_required_quantity"] =
      updateData?.per_product_required_quantity;
    itemDetailsListCopy[index]["extra_qty"] = updateData?.extra_qty;
    itemDetailsListCopy[index]["unit_cost"] = updateData?.unit_cost;
    itemDetailsListCopy[index]["supplier_ids"] = updateData?.supplier_ids;
    itemDetailsListCopy[index]["sub_style_id"] = updateData?.sub_style_id;
    itemDetailsListCopy[index]["sub_style_info"] = updateData?.sub_style_info;
    itemDetailsListCopy[index]["unit_info"] = updateData?.unit_info;
    itemDetailsListCopy[index]["suppliers"] = updateData?.suppliers;
    itemDetailsListCopy[index]["is_add"] = updateData?.is_add || false;
    itemDetailsListCopy[index]["deleted_suppliers"] =
      updateData?.deleted_suppliers || [];

    if (updateData?.item_info) {
      itemDetailsListCopy[index]["item_info"] = updateData?.item_info;
    }
    if (updateData?.item_category_info) {
      itemDetailsListCopy[index]["item_category_info"] =
        updateData?.item_category_info;
    }
    if (updateData?.color_info) {
      itemDetailsListCopy[index]["color_info"] = updateData?.color_info;
    }

    setItemDetailsList([...itemDetailsListCopy]);
    closeDrawer();

    HandleNotification("success", "bottomRight", "Item Updated", null);
  };

  const onCopyItem = (product, index) => {
    // Find and set item list dropdown data
    const foundCategory = itemTypeList.find(
      (category) => category.id === Number(product?.item_category_id),
    );
    setItemList(foundCategory?.items);

    // Push new item to item details list
    const itemDetailsListCopy = structuredClone(itemDetailsList);
    itemDetailsListCopy.push({
      ...product,
      is_add: true,
    });
    setItemDetailsList([...itemDetailsListCopy]);

    // Add the item to selected item
    setSelectedItem({
      ...product,
      index: itemDetailsListCopy.length - 1,
      is_add: true,
    });
    setIsDrawerOpen(true);

    message.success(`Item #${index} Copied Successfully!`);
  };

  const scrollToBottom = () => {
    tableBottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const menu = (product, index) => {
    return (
      <Menu
        items={[
          {
            label: (
              <span
                onClick={() => {
                  onEditOpen(index);
                }}
              >
                <EditOutlined />
                <span className="ml-2">Edit</span>
              </span>
            ),
            key: "0",
            // disabled: !hasPermission([BOM_EDIT_PERMISSION]),
          },
          {
            label: (
              <span onClick={() => onCopyItem(product, index)}>
                <CopyOutlined />
                <span className="ml-2">Copy</span>
              </span>
            ),
            key: "1",
            // disabled: !hasPermission([BOM_VIEW_PERMISSION]),
          },
          {
            label: (
              <span onClick={onAddOpen}>
                <PlusSquareOutlined />
                <span className="ml-2">Add</span>
              </span>
            ),
            key: "2",
            // disabled: !hasPermission([BOM_VIEW_PERMISSION]),
          },
          {
            label: (
              <span
                onClick={() => {
                  removeSelectedProduct(product.id, index);
                }}
              >
                <DeleteOutlined style={{ color: "red" }} />
                <span className="ml-2" style={{ color: "red" }}>
                  Delete
                </span>
              </span>
            ),
            key: "3",
            // disabled: !hasPermission([BOM_EXPORT_PERMISSION]),
          },
        ]}
      />
    );
  };

  const onSearchItem = (values) => {
    const itemDetailsListCopy = structuredClone(itemDetailsList);
    const formCategoryValue = searchForm.getFieldsValue().category;
    const formItemValue = searchForm.getFieldsValue().item;

    const filteredList = itemDetailsListCopy.filter((item) => {
      const category_name = item?.item_category_info?.name?.toLowerCase();
      const item_name = item?.item_info?.name?.toLowerCase();

      if (
        category_name.includes(formCategoryValue?.toLowerCase()) ||
        item_name.includes(formItemValue?.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });

    isArrayAndHasValue(filteredList) && setItemDetailsList(filteredList);
  };

  const onSearchReset = () => {
    searchForm.resetFields();
    window.location.reload();
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      onSearchItem();
    }
  };

  const renderDrawerTitle = () => {
    switch (drawerType) {
      case "bulk_add":
        return `Bulk Add`;
      case "bulk_edit":
        return `Bulk Edit`;
      default:
        return selectedItem
          ? `Item #${selectedItem?.index + 1} Edit`
          : `Item Add`;
    }
  };

  const addBulkItems = () => {
    const customFoundItems = foundItems?.map((item) => ({ ...item, id: null }));
    const totalData = [...itemDetailsList, ...customFoundItems];
    setItemDetailsList(totalData);
    closeDrawer();
  };

  const editBulkItems = () => {
    // const itemDetailsListCopy = JSON.parse(JSON.stringify(itemDetailsList));
    // foundItems?.forEach((item) => {
    //   const foundItemIndex = item?.bulk_edit_id;
    //   console.log({
    //     foundItemIndex,
    //   });
    //   if (foundItemIndex !== undefined && foundItemIndex !== null) {
    //     // Replace updated data to old data
    //     itemDetailsListCopy[foundItemIndex] = item;
    //     // Delete bulk edit id
    //     delete itemDetailsListCopy[foundItemIndex]["bulk_edit_id"];
    //   }
    // });
    const updateData = itemDetailsList?.map((item) => {
      const find = foundItems?.find(
        (foundItem) => foundItem?.item_id === item?.item_id,
      );
      if (find) {
        return { ...find, is_checked: false };
      }
      return {
        ...item,
        is_checked: false,
      };
    });
    setItemDetailsList(updateData);
    closeDrawer();
  };

  const checkAll = (event) => {
    if (event.target.checked) {
      const itemsChecked = itemDetailsList?.map((item) => ({
        ...item,
        is_checked: true,
      }));
      setItemDetailsList(itemsChecked);
      setFoundItems(itemsChecked);
    } else {
      const itemsUnChecked = itemDetailsList?.map((item) => ({
        ...item,
        is_checked: false,
      }));
      setItemDetailsList(itemsUnChecked);
      setFoundItems([]);
    }
  };

  const checkSingle = (event, index, itemId) => {
    const foundItemsCopy = isArrayAndHasValue(foundItems)
      ? [...foundItems]
      : [];
    const itemDetailsListCopy = isArrayAndHasValue(itemDetailsList)
      ? [...itemDetailsList]
      : [];

    // Check/Uncheck item status
    const foundItem = itemDetailsListCopy[index];
    foundItem["is_checked"] = foundItem?.is_checked ? false : true;
    setItemDetailsList(itemDetailsListCopy);

    if (event.target.checked) {
      // Add bulk edit id to match later
      foundItem["bulk_edit_id"] = index;
      // Push item to found items
      foundItemsCopy.push(foundItem);
      setFoundItems(foundItemsCopy);
    } else {
      const foundItemIndex = foundItemsCopy?.findIndex(
        (item) => item?.id === itemId,
      );
      // Remove selected item from item list
      (foundItemIndex || foundItemIndex === 0) &&
        foundItemsCopy.splice(foundItemIndex, 1);
      setFoundItems(foundItemsCopy);
    }
  };

  const renderSubstyleNo = (product) => {
    if(!product?.color_info && !product?.sub_style_info) return "N/A";

    return `${product?.color_info?.name} ${
      product?.sub_style_info?.sub_style_no ? "-" : ""
    } ${product?.sub_style_info?.sub_style_no || ""}`;
  };

  return (
    <Card
      className="custome-table scrollable-table-container"
      title={<span style={{ fontSize: "14px" }}>Item Details</span>}
    >
      <Collapse style={{ marginBottom: 10 }}>
        <Panel header={<b>Filter</b>} key="1">
          <Form form={searchForm} ref={searchRef} onKeyUp={handleKeyUp}>
            <Row gutter={6} style={{ marginBottom: "16px" }}>
              <Col span={4}>
                <Form.Item name="category" label="Category">
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="item" label="Item">
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label=" ">
                  <Button
                    onClick={onSearchItem}
                    type="primary"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label=" ">
                  <Button
                    onClick={onSearchReset}
                    size="small"
                    style={{ width: "100%" }}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>

      <div style={{ display: "flex", marginBottom: 30 }}>
        <div className="custome-table">
          <table>
            {isArrayAndHasValue(itemDetailsList) && (
              <thead>
                <tr>
                  <th className="t-header bc-java ">
                    <Checkbox
                      onChange={checkAll}
                      checked={itemDetailsList?.every(
                        (item) => item?.is_checked,
                      )}
                    />
                  </th>
                  <th className="t-header bc-java ">SL</th>
                  <th className="t-header bc-java ">Category</th>
                  <th className="t-header bc-java ">Item</th>
                  <th className="t-header bc-java ">Unit</th>
                  <th
                    className="t-header bc-java "
                    title="Order Quantity (PC.)"
                  >
                    O.Qty.
                  </th>
                  <th className="t-header bc-java " title="Consumption">
                    Cons.
                  </th>
                  <th className="t-header bc-java " title="Extra (%)">
                    Extra
                  </th>
                  <th
                    className="t-header bc-java "
                    title="Total Required Quantity"
                  >
                    Req.Qty.
                  </th>
                  {/* <th className="t-header bc-java ">Unit Cost</th>
                  <th className="t-header bc-java ">Total Cost</th> */}
                  <th className="t-header bc-java ">Supplier</th>
                  <th className="t-header bc-java ">Sub Style</th>
                  {!view && (
                    <>
                      <th className="t-header bc-java ">Action</th>
                    </>
                  )}
                </tr>
              </thead>
            )}

            <tbody>
              {itemDetailsList?.length ? (
                <>
                  {itemDetailsList.map((product, index) => (
                    <Fragment>
                      <tr
                        key={index || product?.id || product?.item_id}
                        onDoubleClick={() => onEditOpen(index)}
                        className={product?.is_add ? "is-added-item" : ""}
                      >
                        {/* Checkbox */}
                        <td className="t-body">
                          <Checkbox
                            onChange={(event) =>
                              checkSingle(event, index, product?.id)
                            }
                            checked={product?.is_checked}
                          />
                        </td>
                        {/* SL */}
                        <td className="t-body">{index + 1}</td>

                        {/* Category */}
                        <td className="t-body">
                          {product?.item_category_info?.name}
                        </td>
                        {/* ITEM */}
                        <td className="t-body">{product?.item_info?.name}</td>
                        {/* UNIT */}
                        <td className="t-body">{product?.unit_info?.name}</td>
                        {/* O.QTY.(PC.) */}
                        <td className="t-body">
                          {toFixedAndComma(product?.order_quantity)}
                        </td>
                        {/* 	CONSUMPTION */}
                        <td className="t-body">
                          {product?.per_product_required_quantity}
                        </td>
                        {/* EXTRA */}
                        <td>{product?.extra_qty}</td>
                        {/* REQ.QTY. */}
                        <td className="t-body">
                          {commaSeparateNumber(
                            Math.ceil(showTotalPrice(product)),
                          )}
                        </td>
                        {/* UNIT COST */}
                        {/* <td className="t-body">{product?.unit_cost}</td> */}
                        {/* TOTAL COST */}
                        {/* <td className="t-body">
                          {commaSeparateNumber(showAllTotalPrice(product))}
                        </td> */}
                        {/* SUPPLIER */}
                        <td className="t-body">
                          <>
                            {isArrayAndHasValue(product?.suppliers) &&
                              product?.suppliers?.map((item) => {
                                return (
                                  <div>
                                    {item?.supplier_info?.short_name ||
                                      item?.supplier_info?.name}
                                  </div>
                                );
                              })}
                          </>
                        </td>
                        {/* SUBSTYLE */}
                        <td className="t-body">{renderSubstyleNo(product)}</td>

                        {!view && (
                          <td className="t-body">
                            <div className="cursore-pointer">
                              <Dropdown overlay={menu(product, index)}>
                                <MoreOutlined style={{ fontSize: "20px" }} />
                              </Dropdown>
                            </div>
                          </td>
                        )}
                      </tr>
                    </Fragment>
                  ))}
                </>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div ref={tableBottomRef} />

      <Button
        style={{ width: "200px", position: "fixed", bottom: "3vh" }}
        type="primary"
        className="mt-2"
        icon={<PlusOutlined />}
        size="small"
        disabled={view || isDrawerOpen}
        hidden={view || isDrawerOpen}
        onClick={onAddOpen}
      >
        Add Item
      </Button>
      <Button
        style={{
          width: "200px",
          position: "fixed",
          bottom: "3vh",
          left: "420px",
        }}
        type="primary"
        className="mt-2"
        icon={<PlusSquareOutlined />}
        size="small"
        disabled={view || isDrawerOpen}
        hidden={view || isDrawerOpen}
        onClick={onBulkAddOpen}
      >
        Bulk Add
      </Button>
      <Button
        style={{
          width: "200px",
          position: "fixed",
          bottom: "3vh",
          left: "625px",
        }}
        type="primary"
        className="mt-2"
        icon={<EditOutlined />}
        size="small"
        disabled={!itemDetailsList?.some((item) => item?.is_checked)}
        hidden={view || isDrawerOpen}
        onClick={onBulkEditOpen}
      >
        Bulk Edit
      </Button>

      <Drawer
        title={renderDrawerTitle()}
        placement="right"
        onClose={closeDrawer}
        open={isDrawerOpen}
        width={
          drawerType === "bulk_add" || drawerType === "bulk_edit" ? 1000 : 550
        }
        maskStyle={{
          backdropFilter: "blur(0px)",
          backgroundColor: "rgba(0,0,0,0)",
        }}
      >
        {drawerType === "bulk_add" || drawerType === "bulk_edit" ? (
          <BulkItemsManage
            foundItems={foundItems}
            setFoundItems={setFoundItems}
            activeBulkKey={activeBulkKey}
            setActiveBulkKey={setActiveBulkKey}
            unitList={unitList}
            supplierList={supplierList}
            addBulkItems={addBulkItems}
            subStyleList={subStyleList}
            drawerType={drawerType}
            editBulkItems={editBulkItems}
          />
        ) : (
          <ItemEdit
            selectedItem={selectedItem}
            OnSearchgetItemTypeList={OnSearchgetItemTypeList}
            getItemList={getItemList}
            itemTypeList={itemTypeList}
            getItemInfo={getItemInfo}
            itemList={itemList}
            unitList={unitList}
            supplierList={supplierList}
            subStyleList={subStyleList}
            getSubStyleColor={getSubStyleColor}
            getSubStyleNo={getSubStyleNo}
            closeDrawer={closeDrawer}
            onUpdateItem={onUpdateItem}
            onAddNewItem={onAddNewItem}
            isDrawerOpen={isDrawerOpen}
            style={style}
          />
        )}
      </Drawer>
    </Card>
  );
};

export default ItemDetails;
