import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import CustomDatePicker from "common/CustomDatePicker";
import { PostCuttingProcessContext } from "context/PostCuttingProcessContext/PostCuttingProcessContextProvider";
import moment from "moment";
import { useContext, useEffect } from "react";
import { isArrayAndHasValue } from "utils/functions";

const { Option } = Select;

const processes = [
  {
    id: 1,
    label: "Embroidery",
    value: "Embroidery",
  },
  {
    id: 2,
    label: "Printing",
    value: "Printing",
  },
  {
    id: 3,
    label: "Smoking",
    value: "Smoking",
  },
  {
    id: 4,
    label: "Pintuck",
    value: "Pintuck",
  },
];

const movementTypes = [
  {
    id: 1,
    label: "Send",
    value: "Send",
  },
  {
    id: 2,
    label: "Receive",
    value: "Receive",
  },
];

const PostCuttingProcessForm = (props) => {
  // Props
  const { form, formSubmitting, isUpdate } = props;

  // Contexts
  const {
    styleList,
    getPostCuttingTableData,
    onSelectStyle,
    getStyleList,
    selectedStyleDetails,
    showFactoryInputs,
  } = useContext(PostCuttingProcessContext);

  // Antd
  const movement_date_watch = Form.useWatch("movement_date", form);
  const factory_id_watch = Form.useWatch("factory_id", form);
  const movement_type_watch = Form.useWatch("movement_type", form);

  useEffect(() => {
    // Set form values after selecting style from dropdown
    if (selectedStyleDetails) {
      form.setFieldsValue({
        buying_office_id: selectedStyleDetails?.buying_office_info?.id,
        buying_office_name: selectedStyleDetails?.buying_office_info?.name,
        buyer_id: selectedStyleDetails?.buyer_info?.id,
        buyer_name: selectedStyleDetails?.buyer_info?.name,
        factory_id: selectedStyleDetails?.factory_info?.id,
        factory_name: selectedStyleDetails?.factory_info?.name,
        address: selectedStyleDetails?.factory_info?.address,
        sales_contract_id:
          selectedStyleDetails?.active_sales_contract_map_info
            ?.active_sales_contract_info?.id,
        sales_contract_name:
          selectedStyleDetails?.active_sales_contract_map_info
            ?.active_sales_contract_info?.reference_no,
        order_quantity:
          selectedStyleDetails?.active_sales_contract_map_info?.order_quantity,
        use_both_style_po:
          selectedStyleDetails?.active_sales_contract_map_info
            ?.active_sales_contract_info?.use_both_style_po,
        sub_contract_factory_id:
          selectedStyleDetails?.sub_contract_factory_id || null,
        subcontract_status:
          selectedStyleDetails?.active_sales_contract_map_info
            ?.active_sales_contract_info?.subcontract_status,
      });
    }
  }, [selectedStyleDetails, form]);

  useEffect(() => {
    // Call API to get cutting table data
    if (selectedStyleDetails && movement_date_watch && movement_type_watch) {
      getPostCuttingTableData({
        sales_contract_id:
          selectedStyleDetails?.active_sales_contract_map_info
            ?.active_sales_contract_info?.id,
        style_id: selectedStyleDetails?.id,
        movement_date: movement_date_watch,
        buying_office_id: selectedStyleDetails?.buying_office_info?.id,
        sub_contract_factory_id: factory_id_watch || null,
      });
    }
  }, [
    selectedStyleDetails,
    movement_date_watch,
    factory_id_watch,
    getPostCuttingTableData,
    movement_type_watch,
  ]);

  return (
    <>
      <Row gutter={8}>
        <Col span={4}>
          <Form.Item name="use_both_style_po" hidden />
          <Form.Item name="sub_contract_factory_id" hidden />
          <Form.Item name="subcontract_status" hidden />
          <Form.Item
            label="Style No"
            name="style_id"
            rules={[
              {
                required: true,
                message: `Style is required `,
              },
            ]}
          >
            <Select
              onSearch={(value) => getStyleList({ style_no: value })}
              onSelect={(value) => {
                onSelectStyle(value);
                formSubmitting(false);
              }}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              allowClear
              onClear={() => getStyleList()}
              disabled={isUpdate}
            >
              {styleList?.map((style, idx) => (
                <Option key={idx} value={style.id} label={style.style_no}>
                  {" "}
                  {style.style_no + " : " + style?.description || "--"}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {!showFactoryInputs ? (
          <>
            <Col span={4}>
              <Form.Item name="buying_office_id" hidden />
              <Form.Item
                name="buying_office_name"
                label="Buying Office"
                rules={[
                  {
                    required: true,
                    message: `Buying Office is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="buyer_id" hidden />
              <Form.Item
                name="buyer_name"
                label="Buyer"
                rules={[
                  {
                    required: true,
                    message: `Buyer is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
            <Col span={4}>
              <Form.Item
                name="factory_name"
                label="First Party"
                rules={[
                  {
                    required: true,
                    message: `First Party is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: `Address is required `,
                  },
                ]}
              >
                <Input disabled={true} size="small" />
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={4}>
          <Form.Item name="sales_contract_id" hidden />
          <Form.Item
            name="sales_contract_name"
            label="Sales Contract No."
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Process"
            name="process_id"
            rules={[
              {
                required: true,
                message: `Process is required `,
              },
            ]}
          >
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              allowClear
              disabled={isUpdate}
            >
              {isArrayAndHasValue(processes) &&
                processes?.map((item) => {
                  return (
                    <Option key={item?.id} value={item?.value}>
                      {item?.label}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Movement Type"
            name="movement_type"
            rules={[
              {
                required: true,
                message: `Movement Type is required `,
              },
            ]}
          >
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              allowClear
              disabled={isUpdate}
            >
              {isArrayAndHasValue(movementTypes) &&
                movementTypes?.map((item) => {
                  return (
                    <Option key={item?.id} value={item?.value}>
                      {item?.label}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="movement_date"
            label="Movement Date"
            initialValue={moment()}
            rules={[
              {
                required: true,
                message: `Movement Date is required `,
              },
            ]}
          >
            <CustomDatePicker
              style={{
                width: "100%",
              }}
              size="small"
              disabled={isUpdate}
            />
            {/* <DatePicker
              format={"YYYY-MM-DD"}
              style={{
                width: "100%",
              }}
              size="small"
              disabled={isUpdate}
            /> */}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="note" label="Note">
            <Input size="small" disabled={isUpdate} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PostCuttingProcessForm;
