import {
    GET_ORGANIZATION_LIST,
    CREATE_NEW_ORGANIZATION,
    DELETE_ORGANIZATION,
    GET_ORGANIZATION_DETAIL,
    UPDATE_ORGANIZATION_DETAIL
} from '../ActionTypes';

const initialState = {
    organizationList: [],
    totalOrganization: null,
    selectedOrganization: null,
    createdOrganization: null,
    pageSize: 10,
    page:1,
};

const OrganizationReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_ORGANIZATION_LIST:
            return {
                ...state,
                organizationList: action.payload.data,
                totalOrganization: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_ORGANIZATION:
            return {
                ...state,
                createdOrganization: action.payload.data,
                organizationList: [action.payload.data, ...state.organizationList],
                totalOrganization: state.totalOrganization + 1,
            };

        case DELETE_ORGANIZATION: {
            return {
                ...state,
                organizationList:  state.organizationList.filter(element => element !== action.payload),
                totalOrganization: action.payload.total,
                selectedOrganization: action.payload,
            };
        }

        case GET_ORGANIZATION_DETAIL:
            return {
                ...state,
                selectedOrganization: action.payload,
            };

        case UPDATE_ORGANIZATION_DETAIL:
            return {
                ...state,
                selectedOrganization: action.payload,
                organizationList: state.organizationList.map((organization) =>
                    organization.id === action.payload.id ? action.payload : organization,
                ),
            };

        default:
            return state;
    }
};

export default OrganizationReducer;
