import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import { useEffect } from "react";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../utils/functions";

const ItemEdit = (props) => {
  // Props
  const {
    selectedItem,
    OnSearchgetItemTypeList,
    getItemList,
    itemTypeList,
    getItemInfo,
    itemList,
    unitList,
    supplierList,
    subStyleList,
    getSubStyleColor,
    getSubStyleNo,
    closeDrawer,
    onUpdateItem,
    onAddNewItem,
    isDrawerOpen,
    style,
  } = props;

  // Antd
  const [editForm] = Form.useForm();
  const { Option } = Select;

  //   Effects
  useEffect(() => {
    if (selectedItem) {
      editForm.setFieldsValue({
        item_category_id: selectedItem?.item_category_id,
        item_id: selectedItem?.item_id,
        unit_id: selectedItem?.unit_id || selectedItem?.unit_info?.id,
        order_quantity: selectedItem?.order_quantity,
        per_product_required_quantity:
          selectedItem?.per_product_required_quantity,
        extra_qty: selectedItem?.extra_qty,
        unit_cost: selectedItem?.unit_cost,
        supplier_ids: selectedItem?.suppliers?.map((item) => item.supplier_id),
        sub_style_id:
          selectedItem?.sub_style_id || selectedItem?.color_info?.sub_style_id,
        is_add: selectedItem?.is_add,
      });
    } else {
      const lengthOfSubStyleList = style?.sub_style?.length;
      editForm.setFieldsValue({
        sub_style_id:
          lengthOfSubStyleList === 1 ? style?.sub_style[0]?.id : null,
      });
    }
  }, [selectedItem, editForm, isDrawerOpen]);

  const onCancel = () => {
    editForm.resetFields();
    closeDrawer();
  };

  const onUpdateOrAdd = (values) => {
    const foundSubStyle = subStyleList?.find(
      (item) => item?.id === values?.sub_style_id,
    );
    const filteredSuppliers = supplierList?.filter((item) =>
      values?.supplier_ids?.includes(item?.id),
    );
    const foundItem = itemList?.find((item) => item?.id === values?.item_id);
    const foundItemType = itemTypeList?.find(
      (item) => item?.id === values?.item_category_id,
    );
    const foundUnit = unitList?.find((item) => item?.id === values?.unit_id);
    const suppliersList = filteredSuppliers?.map((item) => {
      return {
        supplier_id: item?.id,
        supplier_info: {
          id: item?.id,
          name: item?.name,
          short_name: item?.short_name,
        },
      };
    });
    const itemInfo = {
      id: foundItem?.id,
      name: foundItem?.name,
    };
    const itemCategoryInfo = {
      id: foundItemType?.id,
      name: foundItemType?.name,
    };
    const unitInfo = {
      id: foundUnit?.id,
      name: foundUnit?.name,
    };

    const modifiedData = {
      item_category_id: values?.item_category_id,
      item_id: values?.item_id,
      unit_id: values?.unit_id,
      order_quantity: values?.order_quantity
        ? Number(values?.order_quantity)
        : 0,
      per_product_required_quantity: values?.per_product_required_quantity
        ? Number(values?.per_product_required_quantity)
        : 0,
      extra_qty: values?.extra_qty ? Number(values?.extra_qty) : 0,
      unit_cost: values?.unit_cost ? Number(values?.unit_cost) : 0,
      supplier_ids: isArrayAndHasValue(values?.supplier_ids)
        ? values?.supplier_ids
        : [],
      sub_style_id: values?.sub_style_id || null,
      sub_style_info: foundSubStyle
        ? {
            id: foundSubStyle?.id,
            sub_style_no: foundSubStyle?.sub_style_no,
          }
        : null,
      suppliers: isArrayAndHasValue(suppliersList) ? suppliersList : [],
      item_info: foundItem ? { ...itemInfo } : null,
      item_category_info: foundItemType ? { ...itemCategoryInfo } : null,
      unit_info: foundUnit ? { ...unitInfo } : null,
      is_add: values?.is_add || false,
      color_info: foundSubStyle ? { ...foundSubStyle?.color_info } : null,
      deleted_suppliers: isArrayAndHasValue(values?.deleted_suppliers)
        ? values?.deleted_suppliers
        : [],
    };

    // Add/Edit
    if (selectedItem) {
      onUpdateItem(selectedItem?.index, modifiedData);
    } else {
      onAddNewItem(modifiedData);
      editForm.resetFields([
        "item_id",
        "order_quantity",
        "per_product_required_quantity",
        "extra_qty",
        "unit_cost",
        "supplier_ids",
      ]);
    }
  };

  const onRemoveSupplier = (value) => {
    const deleted_suppliers = editForm.getFieldValue("deleted_suppliers") || [];
    deleted_suppliers.push(value);
    editForm.setFieldsValue({
      deleted_suppliers: deleted_suppliers,
    });
  };

  return (
    <Form form={editForm} layout="vertical" onFinish={onUpdateOrAdd}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="is_add" hidden />
          <Form.Item name="deleted_suppliers" hidden />
          <Form.Item
            name="item_category_id"
            label="Category"
            rules={[
              {
                required: true,
                message: "Category is required",
              },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: 250 }}
              className="w-100"
              showSearch
              onSearch={(e) => OnSearchgetItemTypeList(e)}
              onChange={(value) => {
                getItemList(value);
                editForm.resetFields(["item_id"]);
              }}
              placeholder="Select a Item Type"
              optionFilterProp="children"
              size="small"
            >
              {itemTypeList?.length > 0 &&
                itemTypeList.map((item) => (
                  <Option value={item?.id} key={item?.id}>
                    {item?.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="item_id"
            label="Item"
            rules={[
              {
                required: true,
                message: "Item is required",
              },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: 250 }}
              className="w-100"
              showSearch
              onChange={(value) => {
                if (selectedItem) {
                  getItemInfo(value, selectedItem?.index);
                }
              }}
              placeholder="Select a Item"
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              // onFocus={(e) => {
              //   if (selectedItem?.item_category_info?.id) {
              //     getItemList(selectedItem?.item_category_info?.id);
              //   }
              //   if (selectedItem?.item_category_id) {
              //     getItemList(selectedItem?.item_category_id);
              //   }
              // }}
              size="small"
            >
              {(isArrayAndHasValue(itemList) &&
                itemList.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item?.name || item?.prev_item_name}
                  </Option>
                ))) || (
                <Option
                  value={
                    selectedItem?.item_info?.id
                      ? selectedItem?.item_info?.id
                      : selectedItem?.item_id
                  }
                  key={selectedItem?.item_info?.id}
                >
                  {selectedItem?.item_info?.name
                    ? selectedItem?.item_info?.name
                    : selectedItem?.prev_item_name}
                </Option>
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="unit_id"
            label="Unit"
            rules={[
              {
                required: true,
                message: "Unit is required",
              },
            ]}
          >
            <Select
              className="w-100"
              showSearch
              placeholder="Select a Unit or Create"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              dropdownMatchSelectWidth={false}
              options={
                unitList?.length &&
                unitList.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))
              }
              size="small"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="order_quantity"
            label="Order Qty. (PC.)"
            rules={[
              {
                required: true,
                message: "Order Qty. is required",
              },
            ]}
          >
            <InputNumber
              name={"order_quantity"}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={0}
              size="small"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="per_product_required_quantity"
            label="Consumption"
            rules={[
              {
                required: true,
                message: "Consumption is required",
              },
            ]}
          >
            <InputNumber
              name={"per_product_required_quantity"}
              maxLength={14}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              size="small"
              // precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="extra_qty" label="Extra (%)">
            <InputNumber
              name={"extra_qty"}
              maxLength={14}
              style={{ width: "100%" }}
              precision={2}
              size="small"
            />
          </Form.Item>
        </Col>
        {/* <Col span={8}>
          <Form.Item name="unit_cost" label="Unit Cost">
            <InputNumber
              name={"unit_cost"}
              maxLength={14}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={4}
              size="small"
            />
          </Form.Item>
        </Col> */}
        <Col span={8}>
          <Form.Item name="supplier_ids" label="Suppliers">
            <Select
              className="w-100"
              showSearch
              placeholder="Select a Supllier or Create"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              dropdownMatchSelectWidth={false}
              options={
                supplierList?.length &&
                supplierList.map((item) => ({
                  label: item?.short_name || item?.name,
                  value: item?.id,
                }))
              }
              mode="multiple"
              size="small"
              onDeselect={(value) => onRemoveSupplier(value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="sub_style_id" label="Sub Style Color">
            <Select
              dropdownStyle={{ minWidth: 250 }}
              className="w-100"
              showSearch
              onChange={(value) => {
                if (selectedItem) {
                  getSubStyleColor(value, selectedItem?.index);
                  getSubStyleNo(value, selectedItem?.index);
                }
              }}
              placeholder="Select a Sub Style No"
              optionFilterProp="children"
              size="small"
              allowClear
            >
              {subStyleList?.length > 0 &&
                subStyleList.map((item) => (
                  <Option value={item?.id} key={item.id}>
                    {`${item?.color_info?.name} ${
                      item?.sub_style_no ? "-" : ""
                    } ${item?.sub_style_no || ""}`}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            size="small"
          >
            {selectedItem ? `Update` : `Add`}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            type="danger"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ItemEdit;
