// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.green-row {
  background-color: rgb(131, 226, 131) !important;
}

/* .daily-sewing-performance-table .ant-table-tbody > tr:hover > td {
  background: none !important;
} */
/* .daily-sewing-performance-table .ant-table-measure-row {
  display: none !important;
} */
`, "",{"version":3,"sources":["webpack://./src/components/PNLReport/DailySewingPerformance/index.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;AACjD;;AAEA;;GAEG;AACH;;GAEG","sourcesContent":[".green-row {\n  background-color: rgb(131, 226, 131) !important;\n}\n\n/* .daily-sewing-performance-table .ant-table-tbody > tr:hover > td {\n  background: none !important;\n} */\n/* .daily-sewing-performance-table .ant-table-measure-row {\n  display: none !important;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
