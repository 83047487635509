import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onDeleteSewingLine,
  onGetSewingLineList,
} from "../../../redux/actions";
import ListSewingLine from "./ListSewingLine";
import CreateSewingLine from "../CreateSewingLine";
import { Button, Space } from "antd";
import { hasPermission } from "../../../utils/functions";
import { 
  SEWING_LINE_CREATE_PERMISSION,
} from '../../../routes/permissions';
import { useLocation } from "react-router";

const SewingLineList = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States

  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [toDeleteSewingLine, setToDeleteSewingLine] = useState(null);
  const [isAddSewingLine, onSetAddSewingLine] = useState(false);
  const [isShowDetail, onShowDetail] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedSewingLine, setSelectedSewingLine] = useState(null);

  // Others
  const dispatch = useDispatch();
  const loading = useSelector(({ common }) => common.loading);
  const sewingLineList = useSelector(
    ({ sewingLine }) => sewingLine.sewingLineList,
  );
  const totalSewingLine = useSelector(
    ({ sewingLine }) => sewingLine.totalSewingLine,
  );
  const pageSize = useSelector(({ sewingLine }) => sewingLine.pageSize);
  const filterData = useSelector(({filter}) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const handleAddSewingLineOpen = () => {
    onSetAddSewingLine(true);
  };
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleAddSewingLineClose = () => {
    onSetAddSewingLine(false);
    setSelectedSewingLine(null);
  };
  const onViewSewingLineDetail = (SewingLine) => {
    setSelectedSewingLine(SewingLine);
    onShowDetail(true);
  };
  const onOpenEditSewingLine = (event, SewingLine) => {
    handleAddSewingLineOpen();
  };

  const onUpdateSewingLine = (SewingLine) => {
    setSelectedSewingLine(SewingLine);
    handleAddSewingLineClose();
  };

  const onSelectSewingLineForDelete = (SewingLine) => {
    setToDeleteSewingLine(SewingLine);
  };

  const onSelectedSewingLineToDelete = (record) => {
    dispatch(onDeleteSewingLine(record));
    setToDeleteSewingLine(null);
    props.refetch();
  };

  useEffect(() => {
    dispatch(onGetSewingLineList({
        page: currentPage,
        per_page: 10,
    }));
  }, [refresh, currentPage, pageSize, selectedSewingLine, dispatch]);

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        type="primary"
        size="small"
        onClick={handleAddSewingLineOpen}
        disabled={!hasPermission([SEWING_LINE_CREATE_PERMISSION])}
      >
        {hasPermission([SEWING_LINE_CREATE_PERMISSION])
          ? "Add New"
          : "No Permission"}
      </Button>
    </Space>
  );

  return (
    <>
      <ListSewingLine
        sewingLineList={sewingLineList}
        extra={extra}
        page={currentPage}
        loading={loading}
        pageSize={pageSize}
        setSelectedSewingLine={setSelectedSewingLine}
        totalSewingLine={totalSewingLine}
        onPageChange={onPageChange}
        handleAddSewingLineOpen={handleAddSewingLineOpen}
        handleAddSewingLineClose={handleAddSewingLineClose}
        onSelectSewingLineForDelete={onSelectSewingLineForDelete}
        onViewSewingLineDetail={onViewSewingLineDetail}
        onOpenEditSewingLine={onOpenEditSewingLine}
        onSelectedSewingLineToDelete={onSelectedSewingLineToDelete}
        selectedSewingLine={selectedSewingLine}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />

      <CreateSewingLine
        refetch={refetch}
        isAddSewingLine={isAddSewingLine}
        handleAddSewingLineClose={handleAddSewingLineClose}
        selectedSewingLine={selectedSewingLine}
        onUpdateSewingLine={onUpdateSewingLine}
      />
    </>
  );
};

export default SewingLineList;
