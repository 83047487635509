import React from "react";
import { Row, Col } from "antd";
import { cglFormatDate } from "utils/functions";

const OBFormView = (props) => {
  // Props
  const { obData } = props;

  return (
    <>
    <Row gutter={24} >
      <Col span={24} style={{textAlign:"center"}}>
            <strong>
                  CONCORDE GARMENTS LTD<br />
                  IE Department<br />
                  Operation Bulletin<br />
            </strong>
      </Col>
    </Row><br />
      <Row gutter={24} justify={"space-between"} >
            <Col >
            <strong>Buying Office: </strong>
            {obData?.buying_office?.name}
            <br />
            <strong>Buyer: </strong>
            {obData?.buyer?.name}
            <br />
            <strong>Style: </strong>
            {obData?.style?.style_no}
            <br />
            </Col>

            <Col >
            <strong>OB Version: </strong>
            1
            <br />
            <strong>Date: </strong>
            {cglFormatDate(obData?.entry_date)}
            <br />
            <strong>Description: </strong>
            {obData?.style?.description}
            <br />
            </Col>
      </Row>
    </>
  );
};

export default OBFormView;
