import { EditOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Table, Typography } from "antd";
import { IMPORT_INVOICE_EXPENSE_LIST } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  IMPORT_INVOICE_EXPENSE_TRACKER_CREATE_PERMISSION,
  IMPORT_INVOICE_EXPENSE_TRACKER_EDIT_PERMISSION,
  IMPORT_INVOICE_EXPENSE_TRACKER_VIEW_PERMISSION,
} from "routes/permissions";
import { hasPermission } from "utils/functions";

const ImportInvoiceExpense = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState(null);

  // Route
  const navigate = useNavigate();

  // Antd Constants
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  const columns = [
    {
      title: "Sales Contract",
      dataIndex: ["sales_contract", "reference_no"],
      key: "sales_contract_id",
      render: (text, record) => record.sales_contract?.reference_no || "-",
    },
    {
      title: "Buyer",
      dataIndex: ["buyer_info", "name"],
      key: "buyer_id",
      render: (text, record) => record.buyer_info?.name || "-",
    },
    {
      title: "Supplier",
      dataIndex: ["supplier", "name"],
      key: "supplier_id",
      render: (text, record) => record.supplier?.name || "-",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_no",
      key: "invoice_no",
    },
    {
      title: "Import Value",
      dataIndex: "import_value",
      key: "import_value",
    },
    {
      title: "SG Insurance Charges",
      dataIndex: "sg_insurance_charges",
      key: "sg_insurance_charges",
    },
    {
      title: "Paid Transport Bill",
      dataIndex: "paid_transport_bill",
      key: "paid_transport_bill",
    },
    {
      title: "Air C&F",
      dataIndex: "air_cndf",
      key: "air_cndf",
    },
    {
      title: "Potential Opportunity Saving C&F",
      dataIndex: "potential_oppotnity_saving_cndf",
      key: "potential_oppotnity_saving_cndf",
    },
    {
      title: "Paid C&F Bill",
      dataIndex: "paid_cndf_bill",
      key: "paid_cndf_bill",
    },
    {
      title: "Acceptance Commission",
      dataIndex: "acceptance_commission",
      key: "acceptance_commission",
    },
    {
      title: "EDF Interest",
      dataIndex: "edf_interest",
      key: "edf_interest",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  // Function to get styles list
  const getAllPermitList = useCallback(
    async (passedObject) => {
      let filter = passedObject;
      const customQuery = `${IMPORT_INVOICE_EXPENSE_LIST}/?page=${currentPage}`;
      let res = await getData(customQuery, false, filter);

      if (res) {
        let masterData = res?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      setLoading(false);
    },
    [currentPage],
  );

  useEffect(() => {
    setLoading(true);
    getAllPermitList(filterData);
  }, [currentPage, filterData, getAllPermitList]);

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link
              to={`/import-export-expense-tracking/import-invoice-expense/update/${data.id}`}
            >
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([
            IMPORT_INVOICE_EXPENSE_TRACKER_EDIT_PERMISSION,
          ]),
        },
        {
          label: (
            <Link
              to={`/import-export-expense-tracking/import-invoice-expense/view/${data.id}`}
            >
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([
            IMPORT_INVOICE_EXPENSE_TRACKER_VIEW_PERMISSION,
          ]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const onAdd = () => {
    navigate("/import-export-expense-tracking/import-invoice-expense/create");
  };

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={permitList}
        filterAPI={getAllPermitList}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        filterName="style"
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                // refresh={refresh}
                // setRefresh={setRefresh}
                handleAddItemOpen={onAdd}
                disabled={
                  !hasPermission([
                    IMPORT_INVOICE_EXPENSE_TRACKER_CREATE_PERMISSION,
                  ])
                }
                addButtonText={
                  hasPermission([
                    IMPORT_INVOICE_EXPENSE_TRACKER_CREATE_PERMISSION,
                  ])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Import Invoice Expense</Title>}
          />
        }
        content={
          <>
            {/* {memoizedCustomFilter} */}
            <div className="table" style={{ marginTop: 10, padding: 10 }}>
              <Table
                loading={loading}
                dataSource={permitList}
                columns={columns}
                scroll={{
                  x: "max-content",
                }}
                pagination={{
                  showSizeChanger: false,
                  current: currentPage,
                  total: totalData,
                  pageSize: rangeData,
                  onChange: (page) => paginate(page),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default ImportInvoiceExpense;
