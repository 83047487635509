import { Col, Form, Row, Select, Input } from "antd";

const ItemCategoryFilterFields = ({ initialTableData }) => {
  const { Option } = Select;

  return (
    <Col span={4}>
      <Form.Item label="Item Category Name" name="name">
        <Input placeholder="Item Category Name" size="small"/>
      </Form.Item>
    </Col>
  );
};

export default ItemCategoryFilterFields;
