import { useContext } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { ProductionPlanningContext } from "context/ProductionPlanningContext";
import moment from "moment";
import { isArrayAndHasValue, renderSubstyleName } from "utils/functions";
import CustomDatePicker from "common/CustomDatePicker";

const ProductionPlanningForm = (props) => {
  // Props
  const { editMode } = props;

  // Context
  const {
    form,
    options,
    onGenerate,
    onInquiryAndStyleChange,
    planDataChangeOnLC,
    getInquiryList,
    getStyleList,
    getSubStyleList,
    onSelectBuyerInquiry,
    onSelectStyle,
    onSelectSubStyle,
    onChangeCreatedStatus,
    shipmentDateSetter,
  } = useContext(ProductionPlanningContext);

  const line = Form.useWatch("line", form);
  const use_both_style_po_watch = Form.useWatch("use_both_style_po", form);
  const subcontract_status_watch = Form.useWatch("subcontract_status", form);
  const inquiry_id_watch = Form.useWatch("inquiry_id", form);
  const style_id_watch = Form.useWatch("style_id", form);
  const sub_style_id_watch = Form.useWatch("sub_style_id", form);
  const remaining_qty_watch = Form.useWatch("remaining_qty", form);

  const onCheckLearningCurve = (event) => {
    planDataChangeOnLC(event.target.checked);
  };

  const inquiryFieldCheck = (fieldName) => {
    if (line) {
      if (editMode) {
        if (fieldName) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Card>
      <Form layout="vertical" form={form} onFinish={onGenerate}>
        <Row gutter={24} align={"middle"}>
          <Col span={4}>
            <Form.Item name="subcontract_status" hidden />
            <Form.Item name="sub_contract_factory_id" hidden />
            <Form.Item name="update_id" hidden />
            <Form.Item name="created_with" hidden />
            <Form.Item
              label="Line"
              name="line"
              rules={[
                {
                  required: true,
                  message: "Line is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Line"
                options={options.lines}
                size="small"
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="inquiry_id_when_not_selected" hidden />
            <Form.Item label="Inquiry" name="inquiry_id">
              <Select
                showSearch
                placeholder="Select Inquiry"
                optionFilterProp="children"
                size="small"
                onSearch={(value) =>
                  getInquiryList({
                    inquiry_no: value,
                    active_sales_contract: true,
                  })
                }
                allowClear
                onClear={getInquiryList}
                onSelect={(value) => {
                  onSelectBuyerInquiry(value);
                  onInquiryAndStyleChange({ inquiry_id_prop: value });
                  onChangeCreatedStatus("with_inquiry");
                  form.setFieldsValue({sales_contract_po_id: null});
                }}
                disabled={inquiryFieldCheck(inquiry_id_watch) || editMode}
              >
                {isArrayAndHasValue(options?.inquiryList) &&
                  options?.inquiryList?.map((item) => {
                    return (
                      <Select.Option value={item.id} key={item.id}>
                        {item?.inquiry_no || ""}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Style" name="style_id">
              <Select
                showSearch
                placeholder="Select Style"
                optionFilterProp="children"
                size="small"
                onSearch={(value) => getStyleList({ style_no: value })}
                allowClear
                onClear={getStyleList}
                onSelect={(value) => {
                  onSelectStyle(value);
                  onInquiryAndStyleChange({
                    style_id_prop: value,
                    options: options,
                  });
                  onChangeCreatedStatus("with_style_substyle");
                  form.setFieldsValue({sales_contract_po_id: null});
                }}
                disabled={inquiryFieldCheck(style_id_watch) || editMode}
              >
                {isArrayAndHasValue(options?.styleList) &&
                  options?.styleList?.map((item) => {
                    return (
                      <Select.Option value={item.id} key={item.id}>
                        {item?.style_no || ""}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Sub Style" name="sub_style_id">
              <Select
                showSearch
                placeholder="Select Sub Style"
                optionFilterProp="children"
                size="small"
                onSearch={(value) => getSubStyleList({ sub_style_no: value })}
                allowClear
                onClear={getSubStyleList}
                onSelect={(value) => {
                  onSelectSubStyle(value);
                  onInquiryAndStyleChange({
                    sub_style_id_prop: value,
                    options: options,
                  });
                  onChangeCreatedStatus("with_style_substyle");
                }}
                disabled={inquiryFieldCheck(sub_style_id_watch) || editMode}
              >
                {isArrayAndHasValue(options?.subStyleList) &&
                  options?.subStyleList?.map((item) => {
                    return (
                      <Select.Option value={item.id} key={item.id}>
                        {renderSubstyleName(item)}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={subcontract_status_watch ? 4 : 0}>
            <Form.Item label="Factory Name" name="factory_name">
              <Input placeholder="Factory Name" size="small" disabled />
            </Form.Item>
          </Col>
          <Col span={subcontract_status_watch ? 0 : 4}>
            <Form.Item label="Buyer" name={["buyer", "id"]} hidden>
              <Input size="small" />
            </Form.Item>
            <Form.Item
              label="Buyer"
              name={["buyer", "name"]}
              hidden={subcontract_status_watch ? true : false}
            >
              <Input disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={subcontract_status_watch ? 0 : 4}>
            <Form.Item
              label="Buying Office"
              name={["buying_office", "id"]}
              hidden
            >
              <Input size="small" />
            </Form.Item>
            <Form.Item
              label="Buying Office"
              name={["buying_office", "name"]}
              hidden={subcontract_status_watch ? true : false}
            >
              <Input disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Sales Contract No"
              name={["sales_contract", "id"]}
              hidden
            >
              <Input
                showSearch
                placeholder="Sales Contract No"
                disabled
                size="small"
              />
            </Form.Item>
            <Form.Item
              label="Sales Contract No"
              name={["sales_contract", "sales_contract_no"]}
            >
              <Input
                showSearch
                placeholder="Sales Contract No"
                disabled
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="PO"
              name="sales_contract_po_id"
              rules={[
                {
                  required: use_both_style_po_watch === "No" ? false : true,
                  message: "PO is required",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select PO"
                options={options.po}
                onChange={(value) => {
                  const foundPo = options?.po?.find(
                    (item) => item?.id === value,
                  );
                  shipmentDateSetter(foundPo?.delivery_date || null);
                }}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Order Qty" name="order_qty">
              <InputNumber
                placeholder="Order Qty"
                disabled
                style={{ width: "100%" }}
                size="small"
                precision={0}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="PCD" name="pcd">
              <Input placeholder="PCD" disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Shipment Date"
              name="shipment_date"
              rules={[
                {
                  required: true,
                  message: "Shipment Date is required",
                },
              ]}
            >
              <CustomDatePicker />
              {/* <DatePicker
                style={{ width: "100%" }}
                size="small"
              /> */}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="SAM" name="smv">
              <Input placeholder="SAM" disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="MP" name="no_of_workers">
              <Input placeholder="MP" disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Hourly Production" name="per_hour_target">
              <Input placeholder="Hourly Production" disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Order Status" name="order_status_name">
              <Input placeholder="Order Status" disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Start Date"
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Start Date is required",
                },
              ]}
            >
              <CustomDatePicker
                disabledDate={(current) => {
                  return current && current < moment().startOf("day");
                }}
              />
              {/* <DatePicker
                style={{ width: "100%" }}
                size="small"
                disabledDate={(current) => {
                  return current && current < moment().startOf('day')}
                }
              /> */}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Apply Learning Curve"
              name="is_apply_learning_curve"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox size="small" onChange={onCheckLearningCurve} />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label="Planned Qty" name="planned_qty">
              <InputNumber style={{width: '100%'}} precision={0} placeholder="Planned Qty" disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Actual Qty" name="actual_qty">
              <Input placeholder="Actual Qty" disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="existing_plan_sum" hidden />
            <Form.Item label="Remaining Qty" name="remaining_qty">
              <InputNumber style={{width: '100%'}} placeholder="Remaining Qty" disabled size="small" precision={0} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Planned MH" name="planned_mh">
              <Input placeholder="Planned MH" disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Actual MH" name="actual_mh">
              <Input placeholder="Actual MH" disabled size="small" />
            </Form.Item>
          </Col>
          <Col span={0}>
            <Form.Item name="ob_id" hidden />
          </Col>
          <Col span={0}>
            <Form.Item name="use_both_style_po" hidden />
          </Col>

          <Col span={2}>
            <Button
              type="primary"
              htmlType="submit"
              size="small"
              disabled={remaining_qty_watch <= 0}
            >
              Generate
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ProductionPlanningForm;
