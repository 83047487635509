import { checkRes } from "apiServices/common/helper";
import axios from "axios";
import HandleNotification from "common/Notification";

const base_url = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("token");

axios.defaults.headers.post["Content-Type"] = "application/json";

const alert = (messages) => {
  HandleNotification("error", "bottomRight", messages ? messages : "Something went wrong", null);

};

/* query ---> api url to query with
   no_token ---> acts as a flag for no need to use token */
export const getData = async (query, no_token) => {
  try {
    let data = await axios.get(`${base_url}${query}`, {
      headers: no_token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
          },
    });
    return data;
  } catch (error) {
    let errors = error.response?.data?.errors;

    if (error.response?.data?.message) {
      alert(error.response?.data?.message);
    } else if (errors.length) {
      if (errors && Object.keys(errors).length) {
        for (var key of Object.keys(errors)) {
          alert(errors[key]);
        }
      }
    } else {
      alert();
    }
  }
};

/* query ---> api url to query with
     data ---> data to be posted
     no_token ---> acts as a flag for no need to use token */
export const postData = async (query, data, no_token) => {
  try {
    let res = await axios({
      method: "post",
      url: `${base_url}${query}`,
      headers: no_token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
          },
      data: data,
    });

    if (checkRes(res?.data?.code)) {
      return res.data;
    } else {
      if (res?.data?.message?.length) {
        res?.data?.message.forEach((mess) => {
          HandleNotification("error", "bottomRight", mess ? mess : "Something went wrong", null);
        });
      } else {
        alert();
      }
    }
  } catch (error) {
    let errors = error.response?.data?.errors;

    if (error.response?.data?.message) {
      alert(error.response?.data?.message);
    } else if (errors?.length) {
      if (errors && Object.keys(errors)?.length) {
        for (var key of Object.keys(errors)) {
          alert(errors[key]);
        }
      }
    } else {
      alert();
    }

    return false;
  }
};

export const putData = async (query, data, no_token) => {
  try {
    let res = await axios({
      method: "put",
      url: `${base_url}${query}`,
      headers: no_token
      ? {}
      : {
          Authorization: `Bearer ${token}`,
        },
      data: data,
    });

    return res;
  } catch (error) {
    return false;
  }
};

export const deleteData = async (query, no_token) => {
  try {
    let data = await axios.delete(`${base_url}${query}`, {
      headers: no_token
        ? {}
        : {
            "x-auth-token": `${token}`,
          },
    });
    return data;
  } catch (error) {}
};
