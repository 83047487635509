import { Card, Button, Breadcrumb, Affix } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import AddPurchaseOrderForm from "./AddPurchaseOrderForm";

export default function ViewPO(props) {
  // Props
  const { width } = props;

  // Others
  const navigate = useNavigate();

  const discartForm = () => {
    navigate("/merchandising/purchase-order-new");
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="View Purchase Order"
            subTitle=""
            routes={
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/merchandising/purchase-order">Purchase Order</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>View Purchase Order</Breadcrumb.Item>
              </Breadcrumb>
            }
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="large" key={1} danger onClick={() => discartForm()}>
                Back
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddPurchaseOrderForm mode="view" />
    </>
  );
}
