export const USER_LOGIN = "USER_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const  CREATE_NEW_ITEM = 'CREATE_NEW_ITEM';
export const  DELETE_ITEM = 'DELETE_ITEM';
export const  GET_STYLE_DETAIL = 'GET_STYLE_DETAIL';
export const  GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const  GET_STYLE_LIST = 'GET_STYLE_LIST';
export const  UPDATE_ITEM_DETAIL = 'UPDATE_ITEM_DETAIL';
export const  GET_ITEM_DETAIL = 'GET_ITEM_DETAIL';
export const  GET_ITEM_LIST = 'GET_ITEM_LIST';

export const  STORE_NEW_SHIPMENT = 'STORE_NEW_FOLDING';
export const  DELETE_SHIPMENT = 'DELETE_SHIPMENT';
export const  UPDATE_SHIPMENT_DETAIL = 'UPDATE_SHIPMENT_DETAIL';
export const  GET_SHIPMENT_DETAIL = 'GET_SHIPMENT_DETAIL';
export const  GET_SHIPMENT_LIST = 'GET_SHIPMENT_LIST';

export const  STORE_NEW_FOLDING = 'STORE_NEW_FOLDING';
export const  DELETE_FOLDING = 'DELETE_FOLDING';
export const  UPDATE_FOLDING_DETAIL = 'UPDATE_FOLDING_DETAIL';
export const  GET_FOLDING_DETAIL = 'GET_FOLDING_DETAIL';
export const  GET_FOLDING_LIST = 'GET_FOLDING_LIST';

export const  CREATE_NEW_USER = 'CREATE_NEW_USER';
export const  DELETE_USER = 'DELETE_USER';
export const  UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL';
export const  GET_USER_DETAIL = 'GET_USER_DETAIL';
export const  GET_USER_LIST = 'GET_USER_LIST';

export const  CREATE_NEW_USER_ROLE = 'CREATE_NEW_USER_ROLE';
export const  DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const  UPDATE_USER_ROLE_DETAIL = 'UPDATE_USER_ROLE_DETAIL';
export const  GET_USER_ROLE_DETAIL = 'GET_USER_ROLE_DETAIL';
export const  GET_USER_ROLE_LIST = 'GET_USER_ROLE_LIST';
export const  GET_USER_PERMISSION_LIST = 'GET_USER_PERMISSION_LIST';

export const  STORE_NEW_PACKING = 'STORE_NEW_PACKING';
export const  DELETE_PACKING = 'DELETE_PACKING';
export const  UPDATE_PACKING_DETAIL = 'UPDATE_PACKING_DETAIL';
export const  GET_PACKING_DETAIL = 'GET_PACKING_DETAIL';
export const  GET_PACKING_LIST = 'GET_PACKING_LIST';

export const  STORE_NEW_CUTTING = 'STORE_NEW_CUTTING';
export const  DELETE_CUTTING = 'DELETE_CUTTING';
export const  UPDATE_CUTTING_DETAIL = 'UPDATE_CUTTING_DETAIL';
export const  GET_CUTTING_DETAIL = 'GET_CUTTING_DETAIL';
export const  GET_CUTTING_LIST = 'GET_CUTTING_LIST';


export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const  CREATE_NEW_ITEM_CATEGORY = 'CREATE_NEW_ITEM_CATEGORY';
export const  DELETE_ITEM_CATEGORY = 'DELETE_ITEM_CATEGORY';
export const  UPDATE_ITEM_CATEGORY_DETAIL = 'UPDATE_ITEM_CATEGORY_DETAIL';
export const  GET_ITEM_CATEGORY_DETAIL = 'GET_ITEM_CATEGORY_DETAIL';
export const  GET_ITEM_CATEGORY_LIST = 'GET_ITEM_CATEGORY_LIST';

export const  CREATE_NEW_BUYING_OFFICE = 'CREATE_NEW_BUYING_OFFICE';
export const  DELETE_BUYING_OFFICE = 'DELETE_BUYING_OFFICE';
export const  UPDATE_BUYING_OFFICE_DETAIL = 'UPDATE_BUYING_OFFICE_DETAIL';
export const  GET_BUYING_OFFICE_DETAIL = 'GET_BUYING_OFFICE_DETAIL';
export const  GET_BUYING_OFFICE_LIST = 'GET_BUYING_OFFICE_LIST';


export const  CREATE_NEW_SALES_CONTRACT = 'CREATE_NEW_SALES_CONTRACT';
export const  DELETE_SALES_CONTRACT = 'DELETE_SALES_CONTRACT';
export const  UPDATE_SALES_CONTRACT_DETAIL = 'UPDATE_SALES_CONTRACT_DETAIL';
export const  GET_SALES_CONTRACT_DETAIL = 'GET_SALES_CONTRACT_DETAIL';
export const  GET_SALES_CONTRACT_LIST = 'GET_SALES_CONTRACT_LIST';


export const  CREATE_NEW_BANK_ACCOUNT = 'CREATE_NEW_BANK_ACCOUNT';
export const  CREATE_NEW_BRANCH = 'CREATE_NEW_BRANCH';
export const  DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';
export const  UPDATE_BANK_ACCOUNT_DETAIL = 'UPDATE_BANK_ACCOUNT_DETAIL';
export const  GET_BANK_ACCOUNT_DETAIL = 'GET_BANK_ACCOUNT_DETAIL';
export const  GET_BANK_ACCOUNT_LIST = 'GET_BANK_ACCOUNT_LIST';

export const  CREATE_NEW_MERCHANDISER = 'CREATE_NEW_MERCHANDISER';
export const  DELETE_MERCHANDISER = 'DELETE_MERCHANDISER';
export const  UPDATE_MERCHANDISER_DETAIL = 'UPDATE_MERCHANDISER_DETAIL';
export const  GET_MERCHANDISER_DETAIL = 'GET_MERCHANDISER_DETAIL';
export const  GET_MERCHANDISER_LIST = 'GET_MERCHANDISER_LIST';

export const  CREATE_NEW_SEWING_LINE = 'CREATE_NEW_SEWING_LINE';
export const  DELETE_SEWING_LINE = 'DELETE_SEWING_LINE';
export const  UPDATE_SEWING_LINE_DETAIL = 'UPDATE_SEWING_LINE_DETAIL';
export const  GET_SEWING_LINE_DETAIL = 'GET_SEWING_LINE_DETAIL';
export const  GET_SEWING_LINE_LIST = 'GET_SEWING_LINE_LIST';

export const  CREATE_NEW_BRAND = 'CREATE_NEW_BRAND';
export const  DELETE_BRAND = 'DELETE_BRAND';
export const  UPDATE_BRAND_DETAIL = 'UPDATE_BRAND_DETAIL';
export const  GET_BRAND_LIST = 'GET_BRAND_LIST';
export const  GET_BRAND_DETAIL = 'GET_BRAND_DETAIL';

export const  CREATE_NEW_BUYER = 'CREATE_NEW_BUYER';
export const  DELETE_BUYER = 'DELETE_BUYER';
export const  UPDATE_BUYER_DETAIL = 'UPDATE_BUYER_DETAIL';
export const  GET_BUYER_LIST = 'GET_BUYER_LIST';
export const  GET_BUYER_DETAIL = 'GET_BUYER_DETAIL';
export const  GET_SC_DETAIL = 'GET_SC_DETAIL';

export const  CREATE_NEW_BOM = 'CREATE_NEW_BOM';
export const  DELETE_BOM = 'DELETE_BOM';
export const  UPDATE_BOM_DETAIL = 'UPDATE_BOM_DETAIL';
export const  GET_BOM_LIST = 'GET_BOM_LIST';
export const  GET_BOM_DETAIL = 'GET_BOM_DETAIL';

export const  CREATE_NEW_INVENTORY = 'CREATE_NEW_INVENTORY';
export const  DELETE_INVENTORY = 'DELETE_INVENTORY';
export const  UPDATE_INVENTORY_DETAIL = 'UPDATE_INVENTORY_DETAIL';
export const  GET_INVENTORY_LIST = 'GET_INVENTORY_LIST';
export const  GET_INVENTORY_DETAIL = 'GET_INVENTORY_DETAIL';

export const  CREATE_NEW_SUPPLIER = 'CREATE_NEW_SUPPLIER';
export const  DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const  UPDATE_SUPPLIER_DETAIL = 'UPDATE_SUPPLIER_DETAIL';
export const  GET_SUPPLIER_LIST = 'GET_SUPPLIER_LIST';
export const  GET_SUPPLIER_DETAIL = 'GET_SUPPLIER_DETAIL';

export const  CREATE_NEW_BANK = 'CREATE_NEW_BANK';
export const  DELETE_BANK = 'DELETE_BANK';
export const  UPDATE_BANK_DETAIL = 'UPDATE_BANK_DETAIL';
export const  GET_BANK_DETAIL = 'GET_BANK_DETAIL';
export const  GET_BANK_LIST = 'GET_BANK_LIST';


export const  CREATE_NEW_ORGANIZATION = 'CREATE_NEW_ORGANIZATION';
export const  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const  UPDATE_ORGANIZATION_DETAIL = 'UPDATE_ORGANIZATION_DETAIL';
export const  GET_ORGANIZATION_LIST = 'GET_ORGANIZATION_LIST';
export const  GET_ORGANIZATION_DETAIL = 'GET_ORGANIZATION_DETAIL';

export const GET_ASSIGN_SUMMARY = 'GET_ASSIGN_SUMMARY';
export const GET_WAREHOUSE_LIST = 'GET_WAREHOUSE_LIST';

export const CREATE_FILTER_DATA = `CREATE_FILTER_DATA`;

export const CLEAR_STYLE_DATA = 'CLEAR_STYLE_DATA';

export const EXPORT_FRIEGHT_ITEM = `EXPORT_FRIEGHT_ITEM`;
export const EXPORT_FRIEGHT_TYPE_SAVE = `EXPORT_FRIEGHT_TYPE_SAVE`;
export const EXPORT_FRIEGHT_TYPES_REMOVE = `EXPORT_FRIEGHT_TYPES_REMOVE`;

export const CLEAR_EXPORT_STATE = `CLEAR_EXPORT_STATE`;
