import { Button, Collapse, Form, Layout, Space } from "antd";
import { POST_CUTTING_PROCESS_ADD } from "apiServices/API_ENDPOINTS";
import { postData } from "apiServices/common";
import HandleNotification from "common/Notification";
import AppPageHeader from "common/PageHeader";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import { PostCuttingProcessContext } from "context/PostCuttingProcessContext/PostCuttingProcessContextProvider";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { CUTTING_CREATE_PERMISSION } from "routes/permissions";
import { hasPermission, isArrayAndHasValue } from "utils/functions";
import PostCuttingProcessForm from "./PostCuttingForm";
import PostCuttingDetails from "../PostCuttingDetails";

const { Content } = Layout;
const { Panel } = Collapse;

const PostCuttingProcessEntry = (props) => {
  // Contexts
  const { cuttingTableData } = useContext(PostCuttingProcessContext);
  const [isSubmit, setIsSubmit] = useState(false);

  // Antd
  const [form] = Form.useForm();
  const movement_type_watch = Form.useWatch("movement_type", form);
  const process_watch = Form.useWatch("process_id", form);

  // Router
  const navigate = useNavigate();

  const goBackToCuttingRoute = () => {
    navigate("/production/post-cutting-process");
  };

  const extra = (
    <Space>
      <Button size="small" onClick={goBackToCuttingRoute}>
        Back
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        onClick={() => form.submit()}
        disabled={!hasPermission([CUTTING_CREATE_PERMISSION]) || isSubmit}
      >
        {hasPermission([CUTTING_CREATE_PERMISSION])
          ? "Submit"
          : "No Permission"}
      </Button>
      <Button size="small" htmlType="submit" onClick={() => form.resetFields()}>
        {" "}
        Reset
      </Button>
    </Space>
  );

  const onFinish = async (values) => {
    const cuttingProducts =
      isArrayAndHasValue(cuttingTableData) &&
      cuttingTableData?.map((item) => {
        return {
          post_cutting_process_id:
            item?.process_products?.post_cutting_process_id,
          detail_id: item?.story_id || null,
          po_map_id: item?.po_map_id,
          original_po: item?.original_po,
          internal_po: item?.internal_po,
          sub_style_id: item?.sub_style_id || item?.sub_style_info?.id,
          color_id: item?.color_id || item?.color?.id,
          size_id: item?.size_id || item?.size?.id,
          garment_part_id: item?.process_products?.garment_part_info?.id || null,
          send_qty: item?.process_products?.send_qty,
          receive_qty: item?.process_products?.receive_qty,
        };
      });

    const query = `${POST_CUTTING_PROCESS_ADD}`;
    const bodyData = {
      sales_contract_id: values?.sales_contract_id || null,
      buying_office_id: values?.buying_office_id || null,
      buyer_id: values?.buyer_id || null,
      sub_contract_factory_id: values?.sub_contract_factory_id || null,
      use_both_style_po: values?.use_both_style_po || "No",
      subcontract_status: values?.subcontract_status,
      process: values?.process_id || null,
      style_id: values?.style_id || null,
      movement_date: values?.movement_date || null,
      movement_type: values?.movement_type || null,
      note: values?.note || null,
      process_products: cuttingProducts,
    };

    const response = await postData(query, bodyData);

    if (response) {
      HandleNotification(
        "success",
        "bottomRight",
        "Post Cutting Process created successfully",
        null,
      );
      navigate(`/production/post-cutting-process`);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
      setIsSubmit(false);
    }
  };

  const formSubmitting = () => {
    setIsSubmit(false);
  };

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader title="Embroidery, Printing, Smocking and Pintuck" extra={extra} />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 15 }}>
            <Panel header={<b>Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <PostCuttingProcessForm form={form} formSubmitting={formSubmitting} />
              </Form>
            </Panel>
          </Collapse>
          {/* Post Cutting Details Table */}
          <PostCuttingDetails movementType={movement_type_watch} process={process_watch} />
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default PostCuttingProcessEntry;
