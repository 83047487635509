import { Button, Col, Form, Input, Row, Space } from "antd";
import { FoldingContext } from "context/FoldingContext/FoldingContextProvider";
import { useContext, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";

const FoldingTableFilter = () => {
  // Contexts
  const { foldingTableData, setFoldingTableData } = useContext(FoldingContext);

  //   States
  const [prevFoldingTableData, setPrevFoldingTableData] = useState(null);

  // Antd
  const [searchForm] = Form.useForm();

  const onTableSearch = (values) => {
    // Save the original data only once
    if (!isArrayAndHasValue(prevFoldingTableData)) {
      setPrevFoldingTableData(foldingTableData);
    }

    // Filter data
    const original_po_input = values?.original_po?.trim().toLowerCase() || "";
    const substyle_input = values?.substyle?.trim().toLowerCase() || "";
    const color_input = values?.color?.trim().toLowerCase() || "";

    const filteredData = foldingTableData?.filter((item) => {
      const original_po = item?.original_po?.trim().toLowerCase() || "";
      const substyle =
        item?.sub_style_info?.sub_style_no?.trim().toLowerCase() || "";
      const color = item?.color?.name?.trim().toLowerCase() || "";

      // Only apply filter if input has value
      const matchPO =
        !original_po_input || original_po.includes(original_po_input);
      const matchStyle = !substyle_input || substyle.includes(substyle_input);
      const matchColor = !color_input || color.includes(color_input);

      // Return true only if all active filters match
      return matchPO && matchStyle && matchColor;
    });

    setFoldingTableData(filteredData);
  };

  const onClearSearch = () => {
    searchForm.resetFields();
    setFoldingTableData(prevFoldingTableData);
  };

  return (
    <Form form={searchForm} layout="vertical" onFinish={onTableSearch}>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item name="original_po" label="Original PO">
            <Input size="small" placeholder="Original PO" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="substyle" label="Sub-style">
            <Input size="small" placeholder="Sub-style" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="color" label="Color">
            <Input size="small" placeholder="Color" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label=" ">
            <Space>
              <Button size="small" type="primary" htmlType="submit">
                Search
              </Button>
              <Button onClick={onClearSearch} size="small">
                Clear
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FoldingTableFilter;
