import {
  DeleteTwoTone,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Affix, Button, Card, Dropdown, Menu, Popconfirm, Table } from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ACCEPTANCE_DELETE,
  IMPORT_ACCEPTANCE_DETAIL_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { deleteData, getData } from "../../../apiServices/common";
import HandleNotification from "../../../common/Notification";
import {
  IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_DELETE_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_VIEW_PERMISSION,
} from "../../../routes/permissions";
import {
  cglFormatDate,
  formatNumberWithCommas,
  hasPermission,
} from "../../../utils/functions";
import { isMobileView } from "../../Common/AppHelper";
import BodyHeader from "../../Common/BodyHeader";
import CustomFilter from "../../Common/CustomFilter";
import Header from "../../Common/Header";

const ImportAcceptanceDetails = (props) => {
  // Props
  const { width } = props;

  // States
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();
  const [showFilter, setShowFilter] = useState(false);

  let filter = localStorage.getItem("import_acceptance_detail_filter_value");
  filter = filter ? JSON.parse(filter) : {};

  // Router
  const navigate = useNavigate();

  const openForView = (id) => {
    navigate(`/import/view-acceptance-detail/${id}`);
  };

  const columns = [
    {
      title: "Import LC Number",
      dataIndex: ["acceptance_details", "b2blc_detail", "b2b_lc_number"],
      key: "b2b_lc_number",
    },
    {
      title: "Terms of Payment",
      dataIndex: ["acceptance_details", "b2blc_detail", "terms_of_payment"],
      key: "terms_of_payment",
    },
    {
      title: "Invoice Number",
      dataIndex: ["invoice_detail", "invoice_number"],
      key: "invoice_number",
    },
    {
      title: "Invoice Value",
      dataIndex: ["invoice_detail", "invoice_value"],
      key: "invoice_value",
      render: (value) => (value ? formatNumberWithCommas(value) : ""),
    },
    {
      title: "Supplier",
      dataIndex: [
        "acceptance_details",
        "b2blc_detail",
        "supplier_info",
        "name",
      ],
      key: ["b2blc_detail", "supplier_info", "name"],
    },
    {
      title: "Acceptance Release Date",
      dataIndex: ["acceptance_release_date"],
      key: "acceptance_release_date",
      render: (value, record) => {
        return (
          <span>{value ? cglFormatDate(value) : "N/A"}</span>
        );
      },
    },
    {
      title: "Maturity Date",
      dataIndex: ["maturity_date"],
      key: "maturity_date",
      render: (value, record) => {
        return (
          <span>{value ? cglFormatDate(value) : "N/A"}</span>
        );
      },
    },
    {
      title: "Payment Release Date",
      dataIndex: ["payment_release_date"],
      key: "payment_release_date",
      render: (value, record) => {
        return (
          <span>{value ? cglFormatDate(value) : "N/A"}</span>
        );
      },
    },
    {
      title: "Payment Value",
      dataIndex: ["import_payment_value"],
      key: "import_payment_value",
      render: (value) => (value ? formatNumberWithCommas(value) : ""),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllPermitList = async (passedObject) => {
    let filter = passedObject;
    const query = `${IMPORT_ACCEPTANCE_DETAIL_LIST}`;
    let res = await getData(query, false, filter);

    if (res) {
      let masterData = res?.data;
      setPermitList(masterData?.data?.data);
      setTotalData(masterData?.data?.total);
      setRangeData(masterData?.data?.per_page);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllPermitList({
      ...filter,
      page: currentPage,
    });
  }, [currentPage]);

  const removeDetails = async (value) => {
    const query = `${ACCEPTANCE_DELETE}/${value}`;
    const response = await deleteData(query);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        "Deleted successfully",
        null,
      );
      getAllPermitList();
    } else {
      HandleNotification("error", "bottomRight", "Something went wrong", null);
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link
              to={`/import/edit-acceptance-detail/${data?.import_acceptance_detail_id}`}
            >
              <EditOutlined /> Edit
            </Link>
          ),
          key: 0,
          disabled: !hasPermission([IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeDetails(data?.import_acceptance_detail_id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: 1,
          disabled: !hasPermission([
            IMPORT_ACCEPTANCE_DETAIL_DELETE_PERMISSION,
          ]),
        },
        {
          label: (
            <>
              <span>
                <EyeOutlined />
              </span>

              <span
                type="button"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  openForView(data?.import_acceptance_detail_id);
                }}
              >
                View
              </span>
            </>
          ),
          key: 2,
          disabled: !hasPermission([IMPORT_ACCEPTANCE_DETAIL_VIEW_PERMISSION]),
        },
      ]}
    />
  );
  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Import Acceptance & Payment details"
            subTitle=""
            actions={[
              <Button
                type={showFilter ? "danger" : "primary"}
                size="small"
                onClick={handleFilter}
                style={{ float: "right" }}
              >
                {`${showFilter ? "Close" : "Open"} Filter Option`}
              </Button>,

              <Button
                size="small"
                type="primary"
                disabled={
                  !hasPermission([IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION])
                }
              >
                <Link to="/import/add-acceptance-detail">
                  {hasPermission([IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"}
                </Link>
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader
          // title="Style List"
          // searchFilter={searchFilter}
          />
          <CustomFilter
            showFilter={showFilter}
            tableData={permitList}
            fetchTableData={getAllPermitList}
            filterName="import_acceptance_detail"
          />
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default ImportAcceptanceDetails;
