import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { TNA_DUE_DATE } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useEffect } from "react";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../../utils/functions";

const ItemEdit = (props) => {
  // Props
  const {
    selectedItem,
    getTNATaskList,
    TNATaskList,
    closeDrawer,
    onUpdateItem,
    onAddNewItem,
    isDrawerOpen,
    userList,
    itemDetailsList,
    TNAStyles,
    TNAPos,
    getUserList,
  } = props;

  // Antd
  const [editForm] = Form.useForm();
  const { Option } = Select;

  //   Effects
  useEffect(() => {
    if (selectedItem) {
      editForm.setFieldsValue({
        // tna_process_id: foundTask?.process?.id,
        tna_process_title: selectedItem?.tna_process_title,
        task_dependencies: selectedItem?.task_dependencies,
        tna_department_name: selectedItem?.tna_department_name,
        style_wise: selectedItem?.style_wise,
        po_wise: selectedItem?.po_wise,
        is_pcd: selectedItem?.is_pcd,
        is_ship: selectedItem?.is_ship,
        tna_task_name: selectedItem?.tna_task_name,
        tna_task_id: selectedItem?.tna_task_id,
        days: selectedItem?.days,
        responsible_person_id: selectedItem?.responsible_person_id,
        responsible_person_name: selectedItem?.responsible_person_name,
        style_ids: selectedItem?.style_ids,
        style_names: selectedItem?.style_names,
        po_ids: selectedItem?.po_ids,
        po_names: selectedItem?.po_names,
      });
      getDueDate(selectedItem?.days);
    }
  }, [selectedItem, editForm, isDrawerOpen]);

  const onCancel = () => {
    editForm.resetFields();
    closeDrawer();
  };

  const onUpdateOrAdd = (values) => {
    console.log({ values });
    // Add/Edit
    if (selectedItem) {
      onUpdateItem(selectedItem?.index, values);
      editForm.resetFields();
    } else {
      onAddNewItem(values);
      editForm.resetFields();
    }
  };

  const onSelectTask = (id) => {
    const foundTask = TNATaskList?.find((item) => item?.id === id);
    editForm.setFieldsValue({
      tna_process_id: foundTask?.process?.id,
      tna_process_title: foundTask?.process?.title,
      tna_department_name: foundTask?.department?.title,
      style_wise: foundTask?.relation_with === "Style" ? "Yes" : "No",
      po_wise: foundTask?.relation_with === "PO" ? "Yes" : "No",
      is_pcd: foundTask?.is_pcd === 1 ? "Yes" : "No",
      is_ship: foundTask?.is_shipment === 1 ? "Yes" : "No",
      tna_task_name: foundTask?.title,
    });
  };

  const getDueDate = async (value) => {
    const query = `${TNA_DUE_DATE}/${value}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      editForm.setFieldsValue({
        due_date: response?.data?.data?.due_date,
      });
    } else {
      message.error("Error getting due date");
    }
  };

  return (
    <Form form={editForm} layout="vertical" onFinish={onUpdateOrAdd}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="tna_task_name" hidden />
          <Form.Item
            name="tna_task_id"
            label="Task"
            rules={[
              {
                required: true,
                message: "Task is required",
              },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: 250 }}
              className="w-100"
              showSearch
              onSearch={(e) => getTNATaskList(e)}
              onSelect={(value) => {
                onSelectTask(value);
              }}
              placeholder="Select a Task"
              optionFilterProp="children"
              size="small"
            >
              {isArrayAndHasValue(TNATaskList) &&
                TNATaskList?.map((item) => (
                  <Option value={item?.id} key={item?.id}>
                    {item?.title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="tna_process_id" hidden />
          <Form.Item name="tna_process_title" label="Process">
            <Input size="small" placeholder="Process" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="tna_department_name" label="Department">
            <Input size="small" placeholder="Department" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="style_wise" label="Style Wise">
            <Input size="small" placeholder="Style Wise" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="po_wise" label="PO Wise">
            <Input size="small" placeholder="PO Wise" disabled />
          </Form.Item>
        </Col>
        <Col
          span={
            selectedItem?.style_wise !== "Yes" ||
            selectedItem?.relation_with === "SC"
              ? 0
              : 8
          }
        >
          <Form.Item name="style_names" hidden />
          <Form.Item
            label="Style(s)"
            name="style_ids"
            hidden={
              selectedItem?.style_wise !== "Yes" ||
              selectedItem?.relation_with === "SC"
            }
          >
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Styles"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              options={
                isArrayAndHasValue(TNAStyles) &&
                TNAStyles?.map((item) => ({
                  label: item?.style_no,
                  value: item?.id,
                }))
              }
              size="small"
              onSelect={(value) => {
                const editFormValues = editForm?.getFieldsValue();
                const styleNames = isArrayAndHasValue(
                  editFormValues?.style_names,
                )
                  ? editFormValues?.style_names
                  : [];
                const foundStyle = TNAStyles?.find(
                  (item) => item?.id === value,
                );
                const foundStyleName = foundStyle?.style_no;
                styleNames.push(foundStyleName);
                editForm.setFieldValue("style_names", styleNames);
              }}
            />
          </Form.Item>
        </Col>
        <Col
          span={
            selectedItem?.po_wise !== "Yes" ||
            selectedItem?.relation_with === "SC"
              ? 0
              : 8
          }
        >
          <Form.Item name="po_names" hidden />
          <Form.Item
            label="PO(s)"
            name="po_ids"
            hidden={
              selectedItem?.po_wise !== "Yes" ||
              selectedItem?.relation_with === "SC"
            }
          >
            <Select
              mode="multiple"
              showSearch
              placeholder="Select PO(s)"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              options={
                isArrayAndHasValue(TNAPos) &&
                TNAPos?.map((item) => ({
                  label: item?.original_po,
                  value: item?.id,
                }))
              }
              size="small"
              onSelect={(value) => {
                const editFormValues = editForm?.getFieldsValue();
                const poNames = isArrayAndHasValue(editFormValues?.po_names)
                  ? editFormValues?.po_names
                  : [];
                const foundPO = TNAPos?.find((item) => item?.id === value);
                console.log("foundPO", foundPO);
                const foundPOName = foundPO?.original_po;
                poNames.push(foundPOName);
                editForm.setFieldValue("po_names", poNames);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="days"
            label="Days"
            rules={[
              {
                required: true,
                message: "Days required",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              size="small"
              onChange={(value) => getDueDate(value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="due_date" label="Due Date">
            <Input size="small" placeholder="Due Date" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="responsible_person_name" hidden />
          <Form.Item
            name="responsible_person_id"
            label="Responsible"
            rules={[
              {
                required: true,
                message: "Responsible is required",
              },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: 250 }}
              className="w-100"
              showSearch
              placeholder="Select responsible person"
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              size="small"
              onSelect={(value) => {
                const foundUser = userList?.find((user) => user?.id === value);
                editForm.setFieldValue(
                  "responsible_person_name",
                  foundUser?.name,
                );
              }}
              onSearch={(value) => getUserList(value)}
              allowClear
              onClear={() => getUserList()}
            >
              {isArrayAndHasValue(userList) &&
                userList?.map((item) => (
                  <Option value={item?.id} key={item?.id}>
                    {item?.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* <Form.Item name="task_dependencies_names" hidden /> */}
          <Form.Item label="Dependency" name="task_dependencies">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select dependencies"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              options={
                isArrayAndHasValue(itemDetailsList) &&
                itemDetailsList?.map((item) => ({
                  label: item?.tna_task_name,
                  value: item?.tna_task_id,
                }))
              }
              size="small"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            size="small"
          >
            {selectedItem ? `Update` : `Add`}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            type="danger"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ItemEdit;
