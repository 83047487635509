import React, { useEffect, useState } from "react";
import { Form, Layout, Button, message, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import AppPageHeader from "common/PageHeader";
import {
    onCreateUserRole,
    onGetUserPermissionList,
} from "redux/actions";
import UserRoleForm from "./UserRoleForm";
import { putData } from 'apiServices/common';

const { Content } = Layout;
const { Title } = Typography;

const CreateUserRole = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const useRoleDetails = location.state;
    const userPermissionList = useSelector(
      ({ userRole }) => userRole.userPermissionList,
    );

    const addAllPermissions = () => {
        const allPermissions = userPermissionList?.map((item) => item.id);
        form.setFieldsValue({
            permissions: allPermissions,
        });
    }

    const removeAllPermissions = () => {
        form.setFieldsValue({
            permissions: [],
        });
    };

    const onFinish = async(values) => {
        if(!useRoleDetails) {
            // Create Role API Call
            dispatch(onCreateUserRole(values));
        }
        else {
            // Edit Role API Call
            const response = await putData(`/api/roles/edit/${useRoleDetails?.id}`, values);

            if(response && response?.code === 200) {
                message.success("Role Updated Successfully");
            }
        }
        form.resetFields();
        navigate("/settings/role");
    };

    const extra = (
        <div className="extra-button-container">
            <Button
                htmlType="submit"
                type="primary"
                size="small"
                onClick={() => form.submit()}
                style={{ marginRight: 5 }}
            >
                {" "}
                Save
            </Button>
        </div>
    );

    useEffect(() => {
        dispatch(onGetUserPermissionList(1, 10));
        form.resetFields();
    }, [dispatch, form]);

    return (
      <>
        <InventoryLayOutCommon>
          <AppPageHeader
            title={
              <Title level={5}>
                {useRoleDetails ? `Update Role` : `Create New User Role`}
              </Title>
            }
            extra={extra}
          />

          <Content className="item-details" style={{ paddingTop: 10 }}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              initialValues={{
                name: useRoleDetails?.name || "",
                permissions: useRoleDetails?.permissions?.map(
                  (item) => item.id,
                ),
              }}
              form={form}
            >
              <UserRoleForm
                addAllPermissions={addAllPermissions}
                removeAllPermissions={removeAllPermissions}
              />
            </Form>
          </Content>
        </InventoryLayOutCommon>
      </>
    );
};

export default CreateUserRole;
