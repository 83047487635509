import { Col, Form, Row, Select, Input } from "antd";
import { useEffect, useState } from "react";
import {
  SALES_CONTRACT_LIST,
  GET_BUYING_OFFICE_ENDPOINT,
  GET_B2BLCS_FOR_NOTING,
  STYLE_MERCHANDISING_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
const ShipmentHandoverFilterFields = ({ initialTableData }) => {
  const { Option } = Select;

  const [salseCotractList, setSalseCotractList] = useState([]);

  useEffect(() => {
    getSalseCotractList();
  }, []);

  const getSalseCotractList = async () => {
    let res = await getData(SALES_CONTRACT_LIST);

    if (res) {
      setSalseCotractList(res?.data?.data?.data || []);
    }
  };
  return (
    <>
      <Col span={4}>
      <Form.Item label="Sales Contract No" name="sales_contract_id">
          <Select
            showSearch
            placeholder="Select a Sales Contract No"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {salseCotractList?.length &&
              salseCotractList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.reference_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      
    </>
  );
};

export default ShipmentHandoverFilterFields;
