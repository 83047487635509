import { Col, Form, Select } from "antd";
import { useEffect, useState } from "react";
import {
  SEWING_LINES,
  SUPERVISOR_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const OperatorAbsenteeismFilterField = ({ initialTableData }) => {
  // States
  const [sewingList, setSewingList] = useState([]);
  const [supervisorUserList, setSupervisorUserList] = useState([]);

  // Antd
  const { Option } = Select;

  // Effects
  useEffect(() => {
    // Get buying office, sales contract and buyer list at first load
    getSewingList();
    getSupervisorUserList();
  }, []);

  const getSewingList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SEWING_LINES, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSewingList(response?.data?.data?.data || []);
    }
  };

  const getSupervisorUserList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SUPERVISOR_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSupervisorUserList(response?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item label="Line" name="line_id">
          <Select
            showSearch
            placeholder="Select a Line"
            onSearch={(value) => {
              getSewingList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {isArrayAndHasValue(sewingList) &&
              sewingList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Supervisor User" name="user_id">
          <Select
            showSearch
            placeholder="Select a Supervisor User"
            onSearch={(value) => {
              getSupervisorUserList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {supervisorUserList?.length &&
              supervisorUserList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      {/* <Col span={4}>
        <Form.Item label="Operator" name="operator_id">
          <Select
            showSearch
            placeholder="Select a Operator"
            onSearch={(value) => {
              getSupervisorUserList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            
            {supervisorUserList?.length &&
              supervisorUserList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col> */}
    </>
  );
};

export default OperatorAbsenteeismFilterField;
