import { Card, Collapse, Form, InputNumber, Table, Typography } from "antd";
import { CuttingContext } from "context/CuttingContext/CuttingContextProvider";
import { useContext } from "react";
import { commaSeparateNumber } from "utils/functions";
import CuttingTableFilter from "../CuttingTableFilter";

const CuttingDetails = (props) => {
  // Props
  const { isUpdate } = props;

  // Contexts
  const { cuttingTableData, setCuttingTableData } = useContext(CuttingContext);

  const onChangeCuttingValue = (value, id) => {
    const cuttingTableDataCopy = [...cuttingTableData];
    const foundItem = cuttingTableDataCopy.find((item) => item.id === id);
    foundItem["cutting_products"]["cutting_piece"] = value;
    foundItem["cutting_products"]["remain_piece"] =
      foundItem["cutting_products"]["total_remain_piece"] - value;

    setCuttingTableData(cuttingTableDataCopy);
  };

  const columns = [
    { title: "Original PO", dataIndex: ["original_po"], key: "original_po" },
    {
      title: "Sub Style",
      dataIndex: ["sub_style_info", "sub_style_no"],
      key: "sub_style",
    },
    { title: "Color", dataIndex: ["color", "name"], key: "color" },
    { title: "Size", dataIndex: ["size", "name"], key: "size" },
    {
      title: "Order Qty.",
      dataIndex: ["sales_order_quantity"],
      key: "sales_order_quantity",
    },
    {
      title: "Required Qty.",
      dataIndex: ["cutting_products", "required_quantity"],
      key: "required_quantity",
    },
    {
      title: "Cutting (pc)",
      dataIndex: ["cutting_products", "cutting_piece"],
      key: "cutting_piece",
      editable: true,
      render(text, record) {
        return {
          children: (
            <Form.Item style={{ marginBottom: "0px", fontWeight: 700 }}>
              <InputNumber
                style={{ width: "100%" }}
                value={record?.cutting_products?.cutting_piece}
                formatter={(value) => commaSeparateNumber(value)}
                size="small"
                onChange={(value) => onChangeCuttingValue(value, record?.id)}
              />
            </Form.Item>
          ),
        };
      },
    },
    {
      title: "Total Cutting (pc)",
      dataIndex: ["cutting_products", "total_cutting_piece"],
      key: "total_cutting",
    },
    {
      title: "Remaining (pc)",
      key: "remain_piece",
      dataIndex: ["cutting_products", "remain_piece"],
      render: (value, record) => {
        console.log('record', record);
        
        return (
          <span>
            {record?.cutting_products?.remain_piece}
          </span>
        );
      },
    },
  ];

  const TableSummary = ({ pageData }) => {
    const orderQtySum = pageData?.reduce((total, item) => {
      return total + item.sales_order_quantity;
    }, 0);
    const requiredQtySum = pageData?.reduce((total, item) => {
      return total + item.cutting_products?.required_quantity;
    }, 0);
    const cuttingQtySum = pageData?.reduce((total, item) => {
      return total + item.cutting_products?.cutting_piece;
    }, 0);
    const totalCuttingQtySum = pageData?.reduce((total, item) => {
      return total + item.cutting_products?.total_cutting_piece;
    }, 0);
    const remainingQtySum = pageData?.reduce((total, item) => {
      return total + item.cutting_products?.remain_piece;
    }, 0);

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} id="total">
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} id="sub_style" />
          <Table.Summary.Cell index={2} id="color" />
          <Table.Summary.Cell index={3} id="size" />
          <Table.Summary.Cell index={4} id="order_qty">
            <Typography.Text>{orderQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} id="req_qty">
            <Typography.Text>{requiredQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} id="cutting_pc">
            <Typography.Text>{cuttingQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} id="total_cutting_pc">
            <Typography.Text>{totalCuttingQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} id="remaining_pc">
            <Typography.Text>{remainingQtySum}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <>
      <div className="cuttingTable" style={{ marginTop: 10 }}>
        <Collapse defaultActiveKey={1}>
          <Collapse.Panel header={<b>Table Filter</b>} key={1}>
            <CuttingTableFilter />
          </Collapse.Panel>
        </Collapse>

        <Card style={{ marginTop: 10 }}>
          <Typography.Title style={{ fontSize: 14 }}>
            Cutting Details
          </Typography.Title>
          <Table
            pagination={true}
            size={"small"}
            sticky={true}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={cuttingTableData}
            columns={columns}
            summary={(pageData) => {
              return <TableSummary pageData={pageData} />;
            }}
          />
        </Card>
      </div>
    </>
  );
};
export default CuttingDetails;
