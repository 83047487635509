import React, { useState, useEffect } from "react";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Select, Button, Form, Table, Popconfirm, Typography } from "antd";
import { GET_BUYING_OFFICE_ENDPOINT, GET_USERS_ENDPOINT } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import HandleNotification from "../../../common/Notification";
import { isArrayAndHasValue } from "../../../utils/functions";

const CreateMerchandiserForm = (props) => {
  // Props
  const { createdUserList, setCreatedUserList, isEdit, selectedBuyer } = props;

  // States
  const [usersList, setUsersList] = useState([]);
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [selectedBuyingOffice, setSelectedBuyingOffice] = useState(null);

  //   Antd
  const [form] = Form.useForm();
  const { Option } = Select;

  const getAllUsers = async (filterValues) => {
    const query = `${GET_USERS_ENDPOINT}`;
    const bodyData = {
      ...filterValues,
      per_page: 20,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setUsersList(response?.data?.data?.data);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  const getBuyingOfficeList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(GET_BUYING_OFFICE_ENDPOINT, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOffice(response?.data?.data?.data || []);
    }
  };

  useEffect(() => {
    // Get all users and buying offices list on first load
    getAllUsers();
    getBuyingOfficeList();
  }, []);

  useEffect(() => {
    // On edit mode, when selectedBuyer value is available
    if (isEdit && selectedBuyer) {
      setCreatedUserList(selectedBuyer?.mmd_users);
    }
  }, [selectedBuyer, isEdit, setCreatedUserList]);

  const onFinish = (values) => {
    const isExists = createdUserList.find(
      (item) => item?.mmd_user_id === values?.mmd_user_id && item?.buying_office_id === values?.buying_office_id,
    );

    if (!values?.mmd_user_id || !values?.buying_office_id) {
      HandleNotification(
        "warning",
        "bottomRight",
        "Please Select Merchandiser and Buying Office",
        null,
      );
      return null;
    }
    if (isExists) {
      if (isExists?.is_delete) {
        // If already exist and once deleted
        const createdUserListCopy = [...createdUserList];
        const foundIndex = createdUserListCopy.findIndex(
          (item) => item.mmd_user_id === values?.mmd_user_id,
        );
        createdUserListCopy[foundIndex]["is_delete"] = 0;
        setCreatedUserList(createdUserListCopy);
        form.resetFields();
        return null;
      }

      form.resetFields();
      HandleNotification(
        "warning",
        "bottomRight",
        "Merchandiser Already Exists",
        null,
      );
      return null;
    }

    const usersListCopy = [...usersList];
    const foundUser = usersListCopy.find(
      (item) => item?.id === values?.mmd_user_id,
    );
    const newItemObject = {
      id: 0,
      mmd_user_id: foundUser?.id,
      created_at: foundUser?.created_at,
      updated_at: foundUser?.updated_at,
      mmd_user_info: {
        id: foundUser?.id,
        name: foundUser?.name,
        email: foundUser?.email,
      },
      is_delete: 0,
      buying_office_id: values?.buying_office_id,
      buying_office_info: {
        id: selectedBuyingOffice?.id,
        name: selectedBuyingOffice?.name,
      },
    };
    setCreatedUserList((prev) => [...prev, newItemObject]);
    form.resetFields();
    setSelectedBuyingOffice(null);
  };

  const handleDelete = (record) => {
    const createdUserListCopy = [...createdUserList];

    if (isEdit) {
      // When edit mode, is_delete will be true
      const foundUser = createdUserListCopy.find(
        (item) => item.mmd_user_id === record.mmd_user_id,
      );
      foundUser["is_delete"] = 1;
      setCreatedUserList(createdUserListCopy);
    } else {
      // When add mode, remove from list
      const filteredList = createdUserListCopy.filter(
        (item) => item.mmd_user_id !== record.mmd_user_id,
      );
      setCreatedUserList(filteredList);
    }
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: ["mmd_user_info", "name"],
      key: "name",
    },
    {
      title: "User Email",
      dataIndex: ["mmd_user_info", "email"],
      key: "mmd_user_email",
    },
    {
      title: "Buying Office",
      dataIndex: ["buying_office_info","name"],
      key: "buying_office_name",
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record, index) => {
        return (
          <>
            {isArrayAndHasValue(createdUserList) && (
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => {
                  handleDelete(record, index);
                }}
              >
                <Button
                  type="link"
                  icon={<MinusCircleOutlined style={{ color: "red" }} />}
                />
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div style={{ padding: 5 }}>
      <Typography.Title level={5}>Add Merchandiser and Buying Office</Typography.Title>

      <Form
        form={form}
        layout="inline"
        onFinish={onFinish}
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Form.Item label="Merchandiser">
          <Form.Item noStyle name="mmd_user_id">
            <Select
              required
              style={{
                width: "200px",
              }}
              onSearch={(value) => getAllUsers({ name: value })}
              showSearch
              optionFilterProp="children"
              size="small"
              placeholder="Select/Search Merchandiser"
              allowClear
            >
              {isArrayAndHasValue(usersList) &&
                usersList?.map((item, index) => (
                  <Option
                    key={item?.id || index}
                    value={item?.id}
                    name={item?.name}
                    id={item?.id}
                    label={item?.name}
                  >
                    {item?.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Buying Office" name="buying_office_id">
          <Select
            showSearch
            placeholder="Select a Buying Office"
            onSearch={(value) => {
              getBuyingOfficeList({ name: value });
            }}
            onSelect={(value, option) => {
              const selectedOffice = buyingOffice.find((office) => office.id === value);
              setSelectedBuyingOffice(selectedOffice);
            }}
            optionFilterProp="children"
            size="small"
          >
            {isArrayAndHasValue(buyingOffice) &&
              buyingOffice.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            size="small"
            type="primary"
            style={{ float: "right" }}
          >
            Add
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={tableColumns}
        dataSource={createdUserList?.filter((item) => !item?.is_delete)}
        pagination={false}
      />
    </div>
  );
};
export default CreateMerchandiserForm;
