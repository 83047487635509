import { Card, Button, Affix, Form } from "antd";
import { isMobileView } from "components/Common/AppHelper";
import Header from "components/Common/Header";
import { useNavigate } from "react-router-dom";
import DepartmentForm from "./DepartmentForm";

const AddDepartment = (props) => {
  const { width } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const discartForm = () => {
    navigate("/tanda/department");
  };
  const addNewProcess = () => {
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Add New Department"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} danger onClick={() => discartForm()}>
                Discard
              </Button>,
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addNewProcess()}
              >
                Add Department
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <DepartmentForm form={form} />
    </>
  );
};

export default AddDepartment;
