import React from "react";
import { Form, Input, Radio, Row, Col, InputNumber } from "antd";
import { commaSeparateNumber } from "../../../utils/functions";

const SewingLineCreateForm = (props) => {
  return (
    <Row gutter={6}>
      <Col span={12}>
        <Form.Item label="Sewing Line">
          <Input.Group compact>
            <Form.Item
              name="name"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Sewing Line is required",
                },
              ]}
            >
              <Input placeholder="Ex: Sewing Line" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="capacity" label="Initial Capacity">
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Ex: Initial Capacity"
            formatter={(value) => commaSeparateNumber(value)}
            precision={0}
          />
        </Form.Item>
      </Col>

      <Form.Item
        name="status"
        label="Active Status"
        required
        className="collection-create-form_last-form-item"
      >
        <Radio.Group>
          <Radio value="1">Active</Radio>
          <Radio value="0">Inactive</Radio>
        </Radio.Group>
      </Form.Item>
    </Row>
  );
};
export default SewingLineCreateForm;
