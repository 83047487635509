import React, { useEffect, useRef, useState } from "react";
import Header from "../../Common/Header";
import { Card, Button, Breadcrumb, Affix } from "antd";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postData, getData } from "../../../apiServices/common";
import AddStyleForm from "./AddStyleForm";
import { STYLE_MERCHANDISING_ADD } from "../../../apiServices/API_ENDPOINTS";

export default function AddStyle(props) {
  let { styleId } = useParams();
  const { width } = props;
  const propertyFormRef = useRef(null);
  const [permitInfo, setPermitInfo] = useState();

  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/merchandising/style");
  };

  const addStyle = () => {
    propertyFormRef.current.submit();
  };
  // const base_url = process.env.REACT_APP_BASE;
  const edit_url = `/api/styles/${styleId}/edit`;
  const getPIInfo = async () => {
    console.log("working");
    let res = await getData(edit_url);

    if (res) {
      let masterData = res?.data?.data[0];
      console.log("update data,", masterData);
      setPermitInfo(masterData);
    }
  };

  useEffect(() => {
    getPIInfo();
  }, []);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Add Copy Style"
            subTitle=""
            routes={
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/merchandising/style">Style</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Style</Breadcrumb.Item>
              </Breadcrumb>
            }
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="large"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="large"
                  key={2}
                  type="primary"
                  onClick={() => addStyle()}
                >
                  Add Style
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <AddStyleForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        isCopy={true}
      />
    </>
  );
}
