import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { PNL_REPORT_BUYER_WISE_INDIVIDUAL_PERFORMANCE } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatNumberWithCommas } from "utils/functions";

const IPRMMD = () => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [data, setData] = useState({
    dataSource: [],
    loading: false,
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const columns = [
    {
      title: "SL",
      dataIndex: "sl",
      key: "sl",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
    },
    {
      title: "Dealing Merchandiser",
      dataIndex: "dealing_merchandiser",
      key: "dealing_merchandiser",
    },
    {
      title: "CM/PC (USD)",
      dataIndex: "cm",
      key: "cm",
      align: "right",
      render: (text) => formatNumberWithCommas(parseFloat(text).toFixed(2)),
    },
    {
      title: "Sewing QTY",
      dataIndex: "swing_qty",
      key: "swing_qty",
      align: "right",
    },
    {
      title: "Income CM/USD",
      dataIndex: "income_cm",
      key: "income_cm",
      align: "right",
      render: (text) => formatNumberWithCommas(parseFloat(text).toFixed(2)),
    },
    {
      title: "CM Income (Taka)",
      dataIndex: "cm_income",
      key: "cm_income",
      align: "right",
      render: (text) => formatNumberWithCommas(parseFloat(text).toFixed(2)),
    },
    {
      title: "Expenses (Taka)",
      dataIndex: "expenses_taka",
      key: "expenses_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(parseFloat(text).toFixed(2)),
    },
    {
      title: "Est. Net Profit (Taka)",
      dataIndex: "net_profit_taka",
      key: "net_profit_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(parseFloat(text).toFixed(2)),
    },
  ];

  const getDataSource = async () => {
    try {
      setData({ dataSource: [], loading: true });
      const query = `?filter_date=${selectedDate.format("YYYY-MM-DD")}`;
      const res = await getData(
        `${PNL_REPORT_BUYER_WISE_INDIVIDUAL_PERFORMANCE}${query}`,
      );
      setData({
        dataSource: res.data?.data?.map((item) => ({
          ...item,
          key: item.id,
          expenses_taka: item?.ex_total || 0,
          net_profit_taka: item?.net_profit || 0,
        })),
        loading: false,
      });
    } catch (error) {
      console.log("Error", error);
      setData({ dataSource: [], loading: false });
    }
  };

  useEffect(() => {
    // Fetch data here
    if (selectedDate) {
      getDataSource();
    }
  }, [selectedDate]);

  return (
    <Card
      title={`Individual Performance Report | CGL | ${moment(
        selectedDate,
      ).format("DD-MMM-YY HH:mm")}`}
    >
      <br />
      <Row gutter={24}>
        <Col span={6}>
          <DatePicker
            allowClear={false}
            format={"DD-MMM-YYYY"}
            style={{
              width: "100%",
            }}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Col>
        <Col span={3}>
          <Space>
            <Button
              type="primary"
              danger
              onClick={() => setSelectedDate(moment())}
            >
              Reset
            </Button>
          </Space>
        </Col>

        <Col span={24}>
          <br />
          <Table
            size="small"
            columns={columns}
            dataSource={data?.dataSource}
            pagination={false}
            bordered
            loading={data?.loading}
            summary={(pageData) => {
              if (pageData?.length === 0) return null;
              let total_cm = 0;
              let total_swing_qty = 0;
              let total_income_cm = 0;
              let total_cm_income = 0;
              let total_expenses = 0;
              let total_net_profit = 0;

              pageData.forEach(
                ({
                  cm,
                  swing_qty,
                  income_cm,
                  cm_income,
                  expenses_taka,
                  net_profit_taka,
                }) => {
                  total_cm += parseFloat(cm);
                  total_swing_qty += parseInt(swing_qty);
                  total_income_cm += parseFloat(income_cm);
                  total_cm_income += parseFloat(cm_income);
                  total_expenses += parseFloat(expenses_taka);
                  total_net_profit += parseFloat(net_profit_taka);
                },
              );

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={3} align="right">
                      <Typography.Text strong></Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(
                          (total_cm / pageData.length).toFixed(2),
                        )}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>
                        {total_swing_qty}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(total_income_cm.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(total_cm_income.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(total_expenses.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(total_net_profit.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default IPRMMD;
