import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Select,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
  Typography,
  Breadcrumb,
  Input,
  Space,
  Tag
} from "antd";
import Header from "components/Common/Header";
import { isMobileView } from "components/Common/AppHelper";
import { Link, useLocation } from "react-router-dom";
import { MoreOutlined, EditOutlined, DeleteTwoTone, EyeOutlined } from "@ant-design/icons";
import {
  NOTIFICATION_SETTINGS
} from "apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "apiServices/common";
import { alertPop } from "apiServices/common/helper";
import { commaSeparateNumber, hasPermission,isArrayAndHasValue } from "utils/functions";
import { NO_PERMISSION_REQUIRED } from "routes/permissions";
import { useNavigate, useParams } from "react-router-dom";

const NotificationSettingsView = (props) => {
  // Props
  const { width } = props;
  const { id } = useParams();

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  const [settingDetails, setSettingDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [otherEmails, setOtherEmails] = useState(null);
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

    //   States
    const [showFilter, setShowFilter] = useState(
      localStorage.getItem("filter_status") && routerPath
        ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
        : false,
    );

  // Antd
  const { Title } = Typography;

  const columns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (_,record, index) => <span>{(index+1)}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_,record) => <span>{(record?.user?.name)}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_,record) => <span>{(record?.user?.email)}</span>,
    },    
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (_,record) => <span>{(record?.user?.phone)}</span>,
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      render: (_,record) => <span>{(record?.user?.user_type)}</span>,
    },
    {
      title: "Status",
      dataIndex: "user_status",
      key: "user_status",
      render: (_,record) => <span>{(record?.user?.user_status)}</span>,
    },
  ];

  const getSettingDetails = async (filterValues) => {
    setLoading(true);

    const bodyData = filterValues;
    const response = await getData(`${NOTIFICATION_SETTINGS}/${id}`, false, bodyData);
    if (response && response?.data?.code === 200) {
      setSettingDetails(response?.data?.data);
      setOtherEmails(response?.data?.data?.other_emails?.split(','));
      setUserDetails(response?.data?.data?.settings_details)
      setTotalData(response?.data?.data?.total);
      setRangeData(response?.data?.data?.per_page);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    // Get all cost data on fist load
    // getAllCost();
    setLoading(true);
    getSettingDetails({ page: currentPage });
  }, [currentPage]);

  const removeNotificationSettings = async (value) => {
    const response = await deleteData(`${NOTIFICATION_SETTINGS}/${value}`);
    if (response && response?.code === 200) {
      alertPop("success", "Deleted Successfully!");
      // getAllCost();
      getSettingDetails();
    } else {
      alertPop(
        "error",
        response?.data?.message
          ? response?.data?.message[0]
          : "Something Went Wrong!",
      );
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/settings/notification-settings-update/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
        {
          label: (
            <Link to={`/settings/notification-settings-view/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeNotificationSettings(data.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: "2",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button key="3" size="small" type="primary">
        <Link to="/settings/notification-settings/create">Add New</Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>Notification Settings Details</Title>}
            // actions={extra}
          />
        </Card>
      </Affix>

      <div className="containt-body pt-3">
        <Card>
          <Row gutter={24}>
            <Col span={12}>
              <Card title="Name" bordered={false}>{settingDetails?.name}</Card>
            </Col>
            <Col span={12}>
              <Card title="Slug" bordered={false}>{settingDetails?.slug_details?.code}</Card>
            </Col>
          </Row>
        </Card>
      </div>

      <div className="containt-body">
        <Card>
          <Header
            title={<Title level={5}>User Emails</Title>}
            // actions={extra}
          />
          {/* <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={settingList}
            fetchTableData={getAllSettings}
            filterName="user_emails"
          /> */}
          <Table
            loading={loading}
            dataSource={userDetails}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>

      <div className="containt-body pt-3">
        <Card>
          <Header
            title={<Title level={5}>Other Emails</Title>}
            // actions={extra}
          />
          <Space size={[8, 16]} wrap className="pb-3">
            {isArrayAndHasValue(otherEmails) &&
              otherEmails.map((action) => (
                  <Tag style={{fontSize:"14px"}}>{action}</Tag>
              ))}
          </Space>
        </Card>
      </div>
    </Fragment>
  );
};

export default NotificationSettingsView;
