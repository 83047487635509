import { useEffect, useState } from "react";
import { Col, Form, Input, message, Row, Select } from "antd";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";
import {
  ERP_USAGE_MODULE_UI_LIST_ENDPOINT,
  GET_USERS_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";

const ERPUsageIssueFilterFields = ({ initialTableData }) => {
  // States
  const [ userList, setUserList ] = useState([]);
  const [ userVendorList, setUserVendorList ] = useState([]);
  const [ erpUIList, setErpUIList ] = useState([]);

  const [loading, setLoading] = useState(false);

  // Antd
  const { Option } = Select;

  const getAllUsers = async (filterValues) => {
    setLoading(true);

    const query = `${GET_USERS_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("assignedToUsers", response?.data?.data?.data);
      setUserList(response?.data?.data?.data);
      // Filter for modules where status is 1
      const filteredData = response.data.data.data.filter((item) => item.user_type === 'Vendor');
      // console.log("Filtered ERP Usage Modules:", filteredData);
      setUserVendorList(filteredData); 
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  const getAllErpUsageUIs = async (filterValues) => {
    setLoading(true);

    const query = `${ERP_USAGE_MODULE_UI_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("erpUsageModules", response?.data?.data?.data);
      setErpUIList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  useEffect(() => {
    getAllUsers();
    getAllErpUsageUIs();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item label="Title" name="title">
          <Input placehoder="Please enter Title" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          name="user_id"
          placeholder="Created by"
          label="Created by"
          rules={[
            {
              required: false,
              message: "Created by is Required",
            },
          ]}
        >
          <Select
            placeholder="Created by"
            showSearch
            optionFilterProp="children"
            style={{width: "100%"}}
            onSearch={(value) => getAllUsers({user_id: value})}
            size="small"
          >
            {userList?.map((item) => (
              <Option key={item.id} value={item.id}>{item?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          name="assigned_to_user_id"
          placeholder="Assign to"
          label="Assign to"
          rules={[
            {
              required: false,
              message: "Assign to is Required",
            },
          ]}
        >
          <Select
            placeholder="Assign to"
            showSearch
            optionFilterProp="children"
            style={{width: "100%"}}
            onSearch={(value) => getAllUsers({assigned_to_user_id: value})}
            size="small"
          >
            {userVendorList?.map((item) => (
              <Option key={item.id} value={item.id}>{item?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          name="ui_id"
          placeholder="Select UI"
          label="Select UI"
          rules={[
            {
              required: false,
              message: "UI is Required",
            },
          ]}
        >
          <Select
            placeholder="Select UI"
            showSearch
            optionFilterProp="children"
            style={{width: "100%"}}
            onSearch={(value) => getAllErpUsageUIs({ui_id: value})}
            size="small"
          >
            {erpUIList?.map((item) => (
              <Option key={item.id} value={item.id}>{item?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Status" name="status">
          <Select
            placeholder="Select Status"
            showSearch
            optionFilterProp="children"
            style={{width: "100%"}}
            size="small"
          >
            <Option value="Created">Created</Option>
            <Option value="Halt">Halt</Option>
            <Option value="In Use">In Use</Option>
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default ERPUsageIssueFilterFields;
