import React from "react";
import JwtAxios from "../index";
import HandleNotification from "../../common/Notification";
import {
    GET_ORGANIZATION_ENDPOINT,
    EDIT_ORGANIZATION_ENDPOINT,
    DELETE_ORGANIZATION_ENDPOINT,
    CREATE_ORGANIZATION_ENDPOINT, EDIT_BANK_ENDPOINT
} from "../API_ENDPOINTS";


export const onDeleteOrganization = async ({setPageState, pageState, orgId}) => {
    setPageState(old => ({...old, isLoading: true}))
    return await JwtAxios.delete(`${DELETE_ORGANIZATION_ENDPOINT}/${orgId}`)
        .then((response) => {
            if (response.status === 200){
                if (response.data.code === 200){
                    console.log('get item ', response.data.data.data)
                    setPageState(old => ({...old, isLoading: false,
                        data: pageState.data.filter(item => item.id !== orgId),

                    }))
                    HandleNotification('success', 'bottomRight', 'Bank deleted Successfully!', null)

                }else{
                    HandleNotification('error', 'bottomRight', 'Something Went Wrong!', null)

                }
            }
        })
        .catch((error) => {
            console.log('error is ', error)
        });
}

export const onEditBank = async ({setPageState, pageState, payload}) => {
    setPageState(old => ({...old, isLoading: true}))
    return await JwtAxios.put(`${EDIT_BANK_ENDPOINT}/${payload['id']}`, payload)
        .then((response) => {
            if (response.status === 200){
                if (response.data.code === 200){
                    const newData = [...pageState.data];
                    const index = newData.findIndex((item) =>payload.id === item.id);
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...response.data.data,
                    });
                    setPageState(old => ({...old, isLoading: false,
                        data: newData,

                    }))
                    HandleNotification('success', 'bottomRight', 'Bank information updated Successfully!', null)

                }else{
                    HandleNotification('error', 'bottomRight', 'Something Went Wrong!', null)

                }
            }
        })
        .catch((error) => {
            console.log('error is ', error)
        });
}


export const onOrganizationCreate = async ({payload}) => {
    return  JwtAxios.post(`${CREATE_ORGANIZATION_ENDPOINT}`, payload)
        .then((response) => {
            if (response.status === 200){
                if (response.data.code === 200) {
                    HandleNotification('success', 'bottomRight', 'Item Created Successfully!', null)
                }
                else {
                    HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                }

            }else{
                HandleNotification('error', 'bottomRight', 'Something went wrong!', null)
                console.log('network error');
            }
        })
        .catch((error) => {
            console.log('error is ', error)
        });

};


export const onGetOrganizationList = async ({setPageState, pageState}) => {
    setPageState(old => ({...old, isLoading: true}))

    return await JwtAxios.get(`${GET_ORGANIZATION_ENDPOINT}?page=${pageState.page}`)
        .then((response) => {
            console.log('get item ', response.data.data.data)
            setPageState(old => ({...old, isLoading: false,
                data: response.data.data.data,
                total: response.data.data.total,
                pageSize: response.data.data.per_page,
                page: response.data.data.current_page,
                last_page: response.data.data.last_page,

            }))
        })
        .catch((error) => {
            console.log('error is ', error)
        });
}
