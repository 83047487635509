import { Column } from "@ant-design/plots";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { getData } from "apiServices/common";
import moment from "moment";
import { useEffect } from "react";
import { formatNumberWithCommas } from "utils/functions";
const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Projected CM/Working Day (Taka)",
    dataIndex: "projectedCM",
    key: "projectedCM",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Actual CM/Working Day (Taka)",
    dataIndex: "actualCM",
    key: "actualCM",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Actual Cost/Working Day (Taka)",
    dataIndex: "actualCost",
    key: "actualCost",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Projected Cost (Taka)",
    dataIndex: "projectedCost",
    key: "projectedCost",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Projected Operating Profit/(Loss) TK",
    dataIndex: "projectedProfit",
    key: "projectedProfit",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Actual Operating Profit/(Loss) TK",
    dataIndex: "actualProfit",
    key: "actualProfit",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Variance Between Budgeted & Actual Profit",
    dataIndex: "variance",
    key: "variance",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Cumulative Projected Operating Profit/Loss TK",
    dataIndex: "cumProjectedProfit",
    key: "cumProjectedProfit",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Cumulative Operating Profit/Loss TK",
    dataIndex: "cumActualProfit",
    key: "cumActualProfit",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
];
const dataBar = [
  { line: "Line #01", profit: 5 },
  { line: "Line #02", profit: 10 },
  { line: "Line #03", profit: 15 },
  { line: "Line #04", profit: 20 },
  { line: "Line #05", profit: 25 },
  { line: "Line #06", profit: 30 },
  { line: "Line #07", profit: 35 },
  { line: "Line #08", profit: 40 },
  { line: "Line #09", profit: 45 },
  { line: "Line #10", profit: 50 },
  { line: "Line #11", profit: 55 },
  { line: "Line #12", profit: 60 },
  { line: "Line #13", profit: 65 },
  { line: "Line #14", profit: 70 },
  { line: "Line #15", profit: 75 },
  { line: "Line #16", profit: 80 },
  { line: "Line #17", profit: 85 },
  { line: "Line #18", profit: 90 },
  { line: "Line #19", profit: 95 },
  { line: "Line #20", profit: 100 },
  { line: "Line #21", profit: 105 },
  { line: "Line #22", profit: 110 },
  { line: "Line #23", profit: 115 },
  { line: "Line #24", profit: 120 },
  { line: "Line #25", profit: 125 },
  { line: "Line #26", profit: 130 },
  { line: "Line #27", profit: 135 },
  { line: "Line #28", profit: 140 },
  { line: "Line #29", profit: 145 },
  { line: "Line #30", profit: 150 },
  { line: "Line #31", profit: 155 },
  { line: "Line #32", profit: 160 },
  { line: "Line #33", profit: 165 },
  { line: "Line #34", profit: 170 },
  { line: "Line #35", profit: 175 },
  { line: "Line #36", profit: 180 },
  { line: "Line #37", profit: 185 },
  { line: "Line #38", profit: 190 },
];

const data = [
  {
    key: "1",
    date: "01-01-2021",
    projectedCM: "1000.90",
    actualCM: "900.90",
    actualCost: "800.90",
    projectedCost: "700.90",
    projectedProfit: "600.90",
    actualProfit: "500.90",
    variance: "400.90",
    cumProjectedProfit: "300.90525",
    cumActualProfit: "200.90525",
  },
  {
    key: "2",
    date: "02-01-2021",
    projectedCM: "1000.90",
    actualCM: "900.90",
    actualCost: "800.90",
    projectedCost: "700.90",
    projectedProfit: "600.90",
    actualProfit: "500.90",
    variance: "400.90",
    cumProjectedProfit: "300.90525",
    cumActualProfit: "200.90525",
  },
  {
    key: "3",
    date: "03-01-2021",
    projectedCM: "1000.90",
    actualCM: "900.90",
    actualCost: "800.90",
    projectedCost: "700.90",
    projectedProfit: "600.90",
    actualProfit: "500.90",
    variance: "400.90",
    cumProjectedProfit: "300.90525",
    cumActualProfit: "200.90525",
  },
];

const DailyProfitLossSummary = () => {
  const [form] = Form.useForm();
  const startDateWatch = Form.useWatch("start_date", form) || moment();
  const endDateWatch = Form.useWatch("end_date", form) || moment();

  const config = {
    data: dataBar,
    xField: "line",
    yField: "profit",
    label: {
      text: (originData) => {
        // const val = parseFloat(originData.profit);
        // if (val < 0.05) {
        //   return (val * 100).toFixed(1) + "%";
        // }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  const onFilter = async (values) => {
    // in start_date and end_date are current month
    const startDateOfCurrentMonth = moment().startOf("month");
    const endDateOfCurrentMonth = moment();
    const { start_date, end_date } = values;
    const startDate = start_date
      ? moment(start_date).format("YYYY-MM-DD")
      : startDateOfCurrentMonth.format("YYYY-MM-DD");
    const endDate = end_date
      ? moment(end_date).format("YYYY-MM-DD")
      : endDateOfCurrentMonth.format("YYYY-MM-DD");

    // setLoadingUpToDateData(true);
    try {
      const data = await getDataForReport(startDate, endDate);

      const barChart = data?.map((item) => {
        return {
          line: item.line,
          profit: parseFloat(item.net_profit).toFixed(2),
        };
      });

      // setUpToDateData({
      //   data: data,
      //   barChart: barChart,
      // });
    } catch (error) {
      console.log(error);
    } finally {
      // setLoadingUpToDateData(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // setLoadingTodayData(true);
      // setLoadingUpToDateData(true);
      try {
        // start_date and end_date are the same
        if (moment().isSame(moment(), "day")) {
          const data = await getDataForReport(
            moment().format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          );

          const todayBarChart = data?.map((item) => {
            return {
              line: item.line,
              profit: parseFloat(item.net_profit).toFixed(2),
            };
          });

          // setTodayData({
          //   data: data,
          //   barChart: todayBarChart,
          // });
        }

        // in start_date and end_date are current month
        const startDateOfCurrentMonth = moment().startOf("month");
        const endDateOfCurrentMonth = moment();

        const data = await getDataForReport(
          startDateOfCurrentMonth.format("YYYY-MM-DD"),
          endDateOfCurrentMonth.format("YYYY-MM-DD"),
        );

        // UpToDate Data
        const upToDateBarChart = data?.map((item) => {
          return {
            line: item.line,
            profit: parseFloat(item.net_profit).toFixed(2),
          };
        });

        // setUpToDateData({
        //   data: data,
        //   barChart: upToDateBarChart,
        // });
      } catch (error) {
        console.log(error);
      } finally {
        // setLoadingTodayData(false);
        // setLoadingUpToDateData(false);
      }
    };

    fetchData();

    form.setFieldsValue({
      start_date: moment(),
      end_date: moment(),
    });
  }, []);

  const getDataForReport = async (firstDate, lastDate) => {
    try {
      const query = `/api/pnl-report/line-wise-sewing-profit-loss?start_date=${firstDate}&end_date=${lastDate}`;
      const res = await getData(query);
      return res.data?.data?.data
        ?.map((item) => {
          const unique_line_chief = [
            ...new Set(item.line_chief.map((chief) => chief?.line_chief)),
          ].join(", ");
          const unique_buyer = [
            ...new Set(item.buyer.map((buyer) => buyer?.buyer_short_name)),
          ].join(", ");

          return {
            ...item,
            line_chief: unique_line_chief,
            buyer: unique_buyer,
          };
        })
        .sort((a, b) => {
          const numA = parseInt(a.line.match(/\d+/));
          const numB = parseInt(b.line.match(/\d+/));
          return numA - numB;
        });
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  return (
    <Card
      title={`Daily Profit & Loss Summary: ${
        startDateWatch && endDateWatch
          ? `(${moment(startDateWatch).format("DD-MMM-YY")} to ${moment(
              endDateWatch,
            ).format("DD-MMM-YY")})`
          : ""
      }`}
    >
      <Row>
        <Col span={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFilter}
            onReset={onFilter}
          >
            <Row gutter={24} align={"middle"}>
              <Col span={3}>
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select start date",
                    },
                    {
                      validator: async (_, value) => {
                        if (value && value > endDateWatch) {
                          return Promise.reject(
                            new Error("Start date must be less than today"),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <DatePicker
                    allowClear={false}
                    disabledDate={(current) => {
                      if (!current) {
                        return false;
                      }

                      return current > moment();
                    }}
                    showTime={{ format: "DD-MMM-YY" }}
                    format="DD-MMM-YY"
                    style={{ width: "100%" }}
                    defaultValue={moment()}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  label="End Date"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select start date",
                    },
                    {
                      validator: async (_, value) => {
                        if (value && value < startDateWatch) {
                          return Promise.reject(
                            new Error(
                              "End date must be greater than start date",
                            ),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    allowClear={false}
                    disabledDate={(current) => {
                      if (!current) {
                        return false;
                      }
                      const endOfMonth = moment(startDateWatch).endOf("month");
                      const startOfMonth =
                        moment(startDateWatch).startOf("month");
                      return current >= endOfMonth || current <= startOfMonth;
                    }}
                    showTime={{ format: "DD-MMM-YY" }}
                    format="DD-MMM-YY"
                    defaultValue={moment()}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Space>
                  <Button
                    type="primary"
                    htmlType="reset"
                    danger
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Filter
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            summary={(data) => {
              let totalProjectedCM = 0;
              let totalActualCM = 0;
              let totalActualCost = 0;
              let totalProjectedCost = 0;
              let totalProjectedProfit = 0;
              let totalActualProfit = 0;
              let totalVariance = 0;
              let totalCumProjectedProfit = 0;
              let totalCumActualProfit = 0;
              data.forEach(
                ({
                  projectedCM,
                  actualCM,
                  actualCost,
                  projectedCost,
                  projectedProfit,
                  actualProfit,
                  variance,
                  cumProjectedProfit,
                  cumActualProfit,
                }) => {
                  totalProjectedCM += parseFloat(projectedCM);
                  totalActualCM += parseFloat(actualCM);
                  totalActualCost += parseFloat(actualCost);
                  totalProjectedCost += parseFloat(projectedCost);
                  totalProjectedProfit += parseFloat(projectedProfit);
                  totalActualProfit += parseFloat(actualProfit);
                  totalVariance += parseFloat(variance);
                  totalCumProjectedProfit += parseFloat(cumProjectedProfit);
                  totalCumActualProfit += parseFloat(cumActualProfit);
                },
              );

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <Typography.Text strong>Total</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(totalProjectedCM.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(totalActualCM.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(totalActualCost.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(totalProjectedCost.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(
                          totalProjectedProfit.toFixed(2),
                        )}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(totalActualProfit.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(totalVariance.toFixed(2))}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(
                          totalCumProjectedProfit.toFixed(2),
                        )}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right">
                      <Typography.Text strong>
                        {formatNumberWithCommas(
                          totalCumActualProfit.toFixed(2),
                        )}
                      </Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
        <Col span={24}>
          {/* Daily Profit & Loss Summary of CGL (Projected vs Actual) */}
          <Typography.Text strong>
            Daily Profit & Loss Summary of CGL (Projected vs Actual)
          </Typography.Text>
          <Column {...config} />
          <br />
        </Col>
        <Col span={24}>
          {/* Cumulative Profit & Loss Summary of CGL (Projected vs Actual) */}
          <Typography.Text strong>
            Cumulative Profit & Loss Summary of CGL (Projected vs Actual)
          </Typography.Text>
          <Column {...config} />
        </Col>
      </Row>
    </Card>
  );
};

export default DailyProfitLossSummary;
