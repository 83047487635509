import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Select,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Popconfirm,
  Typography,
  Breadcrumb,
  Input,
  Space,
} from "antd";
import BodyHeader from "../Common/BodyHeader";
import Header from "../Common/Header";
import { isMobileView } from "../Common/AppHelper";
import { Link, useLocation } from "react-router-dom";
import { MoreOutlined, EditOutlined, DeleteTwoTone, EyeOutlined } from "@ant-design/icons";
import {
  LEARNING_CURVE_RESOURCE
} from "../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../apiServices/common";
import CustomFilter from "../Common/CustomFilter";
import { alertPop } from "../../apiServices/common/helper";
import { commaSeparateNumber, hasPermission } from "../../utils/functions";
import { 
  LEARNING_CURVE_VIEW_PERMISSION,
  LEARNING_CURVE_CREATE_PERMISSION,
  LEARNING_CURVE_EDIT_PERMISSION,
  LEARNING_CURVE_DELETE_PERMISSION
} from "../../routes/permissions";

const LearningCurve = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  //   States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [costList, setCostList] = useState(null);
  const [listData, setListData] = useState(null);
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const columns = [
    {
      title: "Range",
      dataIndex: ["curve_info", "range"],
      key: "range",
    },
    {
      title: "Day",
      dataIndex: ["day"],
      key: "day",
    },
    {
      title: "Efficiency",
      dataIndex: ["efficiency"],
      key: "efficiency",
    },
    {
      title: "Working Hours",
      dataIndex: ["working_hours"],
      key: "working_hours",
    },
    {
      title: "Approx. Man",
      dataIndex: ["approx_manpower"],
      key: "approx_manpower",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllLearningCurve = async (filterValues) => {
    setLoading(true);

    const bodyData = filterValues;
    const response = await getData(LEARNING_CURVE_RESOURCE, false, bodyData);

    if (response && response?.data?.code === 200) {
      setListData(response?.data?.data?.data);
      setTotalData(response?.data?.data?.total);
      setRangeData(response?.data?.data?.per_page);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    // Get all cost data on fist load
    // getAllCost();
    setLoading(true);
    getAllLearningCurve({ page: currentPage });
  }, [currentPage]);

  const removeLearningCurve = async (value) => {
    const response = await deleteData(`${LEARNING_CURVE_RESOURCE}/${value}`);
    if (response && response?.code === 200) {
      alertPop("success", "Deleted Successfully!");
      getAllLearningCurve();
    } else {
      alertPop(
        "error",
        response?.data?.message
          ? response?.data?.message[0]
          : "Something Went Wrong!",
      );
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/merchandising/learning-curves/${data?.curve_info?.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([LEARNING_CURVE_EDIT_PERMISSION]),
        },
        // {
        //   label: (
        //     <Link to={`/settings/notification-settings-view/${data?.curve_info?.id}`}>
        //       <EyeOutlined /> View
        //     </Link>
        //   ),
        //   key: "1",
        //   disabled: !hasPermission([LEARNING_CURVE_VIEW_PERMISSION]),
        // },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeLearningCurve(data?.curve_info?.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: "2",
          disabled: !hasPermission([LEARNING_CURVE_DELETE_PERMISSION]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button key="3" size="small" type="primary">
        <Link to="/merchandising/learning-curves/create">Add New</Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>Learning Curve</Title>}
            actions={extra}
          />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={listData}
            fetchTableData={getAllLearningCurve}
            filterName="learning_curve"
          />
          <Table
            loading={loading}
            dataSource={listData}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default LearningCurve;
