import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Table,
  Typography,
  message,
} from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  ADD_DRIVER_INFOS,
  DELETE_DRIVER_INFOS,
  EDIT_DRIVER_INFOS,
  GET_DRIVER_INFOS,
} from "../../../apiServices/API_ENDPOINTS";
import { deleteData, getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  DRIVER_VEHICLE_INFO_CREATE_PERMISSION,
  DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
  DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
} from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const DriverInformation = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Antd
  const [driverInformationForm] = Form.useForm();
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllDriverInfo = useCallback(
    async (filterValues) => {
      setLoading(true);

      const query = `${GET_DRIVER_INFOS}?page=${currentPage}`;
      const bodyData = {
        per_page: 10,
        ...filterValues,
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting driver info data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllDriverInfo(filterData);
  }, [refresh, filterData, getAllDriverInfo]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    driverInformationForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedDriverInfo = async (id) => {
    setLoading(true);
    const response = await deleteData(`${DELETE_DRIVER_INFOS}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Driver Info Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Error deleting driver info");
    }
  };

  const onFinishDriverInfo = async (values) => {
    setLoading(true);

    const query = isEdit
      ? `${EDIT_DRIVER_INFOS}/${values?.id}`
      : `${ADD_DRIVER_INFOS}`;

    const bodyData = {
      driver_name: values?.driver_name,
      driver_cell_no: values?.driver_cell_no,
      nid: values?.nid,
      driving_license_no: values?.driving_license_no,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success(
        `Driver Info ${isEdit ? "Updated" : "Added"} Successfully`,
      );
      refetch();
      driverInformationForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error(`Error ${isEdit ? "updating" : "adding"} driver info`);
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);
    driverInformationForm.setFieldsValue({
      id: record?.id,
      driver_name: record?.driver_name,
      driver_cell_no: record?.driver_cell_no,
      nid: record?.nid,
      driving_license_no: record?.driving_license_no,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Driver Name",
      dataIndex: ["driver_name"],
      key: "driver_name",
    },
    {
      title: "Cell No.",
      dataIndex: ["driver_cell_no"],
      key: "driver_cell_no",
    },
    {
      title: "License No.",
      dataIndex: ["driving_license_no"],
      key: "driving_license_no",
    },
    {
      title: "Driver NID",
      dataIndex: ["nid"],
      key: "nid",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedDriverInfo(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([
                      DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([
                      DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
                    ]),
                  },
                ]}
              />
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={
                  !hasPermission([DRIVER_VEHICLE_INFO_CREATE_PERMISSION])
                }
                addButtonText={
                  hasPermission([DRIVER_VEHICLE_INFO_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Driver Information</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllDriverInfo}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="vehicle_and_driver_information"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={`${isEdit ? "Edit" : "Add"} Driver Information`}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={driverInformationForm}
          layout="vertical"
          onFinish={onFinishDriverInfo}
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="driver_name"
                placeholder="Driver Name"
                label="Driver Name"
                rules={[
                  {
                    required: true,
                    message: "Driver Name Required",
                  },
                ]}
              >
                <Input size="small" placeholder="Driver Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="driver_cell_no"
                placeholder="Driver Cell No."
                label="Driver Cell No."
                rules={[
                  {
                    required: true,
                    message: "Driver Cell No. Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="small"
                  placeholder="Driver Cell No."
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="nid" label="NID">
                <Input size="small" placeholder="NID" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="driving_license_no" label="Driving License No.">
                <Input size="small" placeholder="Driving License No." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default DriverInformation;
