import {
  BellOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  Popover,
  Row,
  Space,
} from "antd";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BOOKMARK_ADD,
  COUNT_UNREAD_NOTIFICATION,
  MARK_ALL_READ_NOTIFICATION,
  NOTIFICATIONS_LIST,
  UPDATE_PASSWORD,
  USER_LOGOUT,
} from "../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../apiServices/common";
import { timeDifferentCalculation } from "../../utils/functions";
import HandleNotification from "../Notification";
import BookmarkForm from "./BookmarkForm";
import ChangePassword from "./ChangePassword";
import GlobalSearch from "./GlobalSearch";
import "./index.css";

const { Header } = Layout;

const AppHeader = (props) => {
  // Props
  const { collapsed, toggleCollapsed, onSearchMenu } = props;

  // States
  const [userName, setUserName] = useState(
    localStorage.getItem("name") || "User",
  );
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationsList, setNotificationsList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForChangePass, setIsModalOpenForChangePass] =
    useState(false);

  //   Router
  const navigate = useNavigate();
  const location = useLocation();

  // Antd
  const { Search } = Input;
  const [bookmarkForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const { Header } = Layout;

  const getUnreadCount = async () => {
    const response = await getData(COUNT_UNREAD_NOTIFICATION);
    if (response && response?.status === 200) {
      setUnreadCount(response?.data?.data?.count);
    }
  };

  // Effects
  useEffect(() => {
    getUnreadCount();
    getAllNotifications();
  }, []);

  const markAllRead = async () => {
    const query = MARK_ALL_READ_NOTIFICATION;
    const response = await postData(query);

    if (response && response?.code === 200) {
      getUnreadCount();
    }
  };

  const getAllNotifications = async () => {
    const response = await getData(NOTIFICATIONS_LIST);

    if (response && response?.status === 200) {
      setNotificationsList(response?.data?.data?.data);
    }
  };

  const handleLogout = async () => {
    // Logout API Call
    const query = `${USER_LOGOUT}`;
    const response = await postData(query);

    if (response && response?.code === 200) {
      HandleNotification("success", "Success", "Logged out successfully!");
    } else {
      HandleNotification("error", "Error", "Something went wrong!");
    }

    // Logout anyway
    localStorage.clear();
    navigate("/login");
  };

  const openModal = () => {
    bookmarkForm.setFieldValue("book_mark_link", location.pathname);
    setIsModalOpen(true);
  };

  const openChangePassModal = () => {
    setIsModalOpenForChangePass(true);
  };

  const menuItem = (
    <Menu theme="light">
      <Menu.Item key={0}>
        <div onClick={openChangePassModal}>Change Password</div>
      </Menu.Item>
      <Menu.Item key={1}>
        <div onClick={openModal}>Bookmark</div>
      </Menu.Item>
      <Menu.Item key={2}>
        <div onClick={handleLogout}>Logout</div>
      </Menu.Item>
    </Menu>
  );

  const NotificationContent = () => {
    return (
      <>
        <div className="header-noti-body">
          <div
            className="header-notification-items px-4 mt-3"
            style={{ overflowY: "scroll" }}
          >
            {notificationsList && notificationsList.length > 0 ? (
              <>
                {notificationsList.map((noti) => {
                  return (
                    <div
                      className="header-notification-item"
                      id={`notification-${noti.id}`}
                      key={noti.id}
                      style={!noti.read_at ? { background: "#e2e2e2" } : {}}
                    >
                      <Row>
                        <Col span={24}>
                          <p>{noti.notification_title}</p>
                          <div className="header-parsedStyle">
                            {parse(noti.notification_body)}
                          </div>
                          <small>
                            {timeDifferentCalculation(noti.created_at)}
                          </small>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="mt-3" style={{ textAlign: "center" }}>
                <h4>There is no notification for you. </h4>
              </div>
            )}
          </div>
        </div>
        <div className="centered-item-parent">
          <a href="/notifications">See all notifications</a>
        </div>
      </>
    );
  };

  const onCloseModal = () => {
    bookmarkForm.resetFields();
    setIsModalOpen(false);
  };

  const onCloseForChangePassModal = () => {
    changePasswordForm.resetFields();
    setIsModalOpenForChangePass(false);
  };

  const addBookMark = async (values) => {
    const query = `${BOOKMARK_ADD}`;
    const bodyData = {
      name: values.name,
      book_mark_link: values.book_mark_link,
    };
    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      console.log("response", response);
      HandleNotification("success", "Success", "Bookmark added successfully!");
      onCloseModal();
    } else {
      HandleNotification("error", "Error", "Something went wrong!");
    }
  };
  const changePassword = async (values) => {
    const query = `${UPDATE_PASSWORD}`;

    const response = await postData(query, values);

    if (response && response?.code === 200) {
      console.log("response", response);
      HandleNotification("success", "Success", "Change Password successfully!");
      onCloseForChangePassModal();
    } else {
      HandleNotification("error", "Error", "Something went wrong!");
    }
  };

  return (
    <div>
      <Header className="header">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "40px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={toggleCollapsed}
              style={{
                fontSize: "16px",
                color: "#fff",
              }}
            />
            {!collapsed && (
              <Input
                placeholder="Search Menu"
                onChange={(event) => onSearchMenu(event.target.value)}
                style={{
                  width: 140,
                  height: 25,
                }}
                allowClear
              />
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <GlobalSearch />
          </div>
          <Space>
            <Popover
              placement="leftTop"
              title={
                <div className="centered-item-parent">
                  <h4>Notifications</h4>
                </div>
              }
              content={<NotificationContent />}
              trigger="click"
            >
              <Badge count={unreadCount} size="small">
                <BellOutlined
                  style={{ fontSize: "18px", color: "#fff" }}
                  onClick={markAllRead}
                />
              </Badge>
            </Popover>

            <Dropdown overlay={menuItem} trigger={["click"]}>
              <span
                style={{ color: "#fff", cursor: "pointer" }}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {userName} <DownOutlined />
              </span>
            </Dropdown>
          </Space>
        </div>
      </Header>
      {/* Modal UI */}
      <Modal
        open={isModalOpen}
        title={<span style={{ fontSize: "14px" }}>Bookmark</span>}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form form={bookmarkForm} layout="vertical" onFinish={addBookMark}>
          <BookmarkForm onCloseModal={onCloseModal} />
        </Form>
      </Modal>
      <Modal
        open={isModalOpenForChangePass}
        title={<span style={{ fontSize: "14px" }}>Change Password</span>}
        onCancel={() => {
          onCloseForChangePassModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={changePasswordForm}
          layout="vertical"
          onFinish={changePassword}
        >
          <ChangePassword onCloseModal={onCloseForChangePassModal} />
        </Form>
      </Modal>
    </div>
  );
};

export default AppHeader;
