import { PACKING_LIST } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { alertPop } from "apiServices/common/helper";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isArrayAndHasValue } from "utils/functions";
import CreatePackingReq from "../CreatePackingReq";

const ViewPackingReq = () => {
  // States
  const [selectedPoEditData, setSelectedPoEditData] = useState(null);
  const [poMapId, setPoMapId] = useState(null);

  // Router
  const { id } = useParams();

  const getPOData = useCallback(async () => {
    const query = `${PACKING_LIST}/${id}/view/edit`;

    const response = await getData(query);

    console.log("response", response);

    if (response && response?.data?.code === 200) {
      const masterData = response?.data?.data;
      const cartonData = masterData?.cartons;

      setPoMapId(masterData?.po_map_id);

      if (isArrayAndHasValue(cartonData)) {
        // Create custom carton data for edit
        const customData = cartonData.map((item, index) => {
          return {
            unique_id: index,
            colors: item?.colors?.map((colorItem, colorIndex) => {
              return {
                unique_id: colorIndex,
                color_id: colorItem?.color_id,
                sub_style_id: colorItem?.sub_style_id,
                sizes: colorItem?.sizes?.map((sizeItem) => {
                  return {
                    size_id: sizeItem?.size_id,
                    quantity: Number(sizeItem?.quantity),
                  };
                }),
                color_total: colorItem?.sizes?.reduce((result, colorItem) => {
                  return result + Number(colorItem?.quantity);
                }, 0),
                req_sizes: colorItem?.sizes?.map((sizeItem) => {
                  return {
                    size_id: sizeItem?.size_id,
                    quantity:
                      Number(sizeItem?.quantity) * item?.carton_qty || 0,
                  };
                }),
                req_color_total: colorItem?.sizes?.reduce(
                  (result, colorItem) => {
                    return (
                      result +
                      (Number(colorItem?.quantity) * item?.carton_qty || 0)
                    );
                  },
                  0,
                ),
              };
            }),
            carton_qty: Number(item?.carton_qty),
            assortment: item?.assortment,
            item: item?.item,
            upc: item?.upc,
            length: Number(item?.length),
            width: Number(item?.width),
            height: Number(item?.height),
            cbm: Number(item?.cbm),
            gross: Number(item?.gross),
            net: Number(item?.net),
            total_gross: Number(item?.total_gross),
            total_net: Number(item?.total_net),
            style_id: item?.style_id,
            start_range: Number(item?.start_range),
            end_range: Number(item?.end_range),
            carton_pack_req_qty: item?.carton_pack_req_qty
              ? Number(item?.carton_pack_req_qty)
              : 0,
            total_quantity: item?.total_quantity
              ? Number(item?.total_quantity)
              : 0,
            customer_po: item?.customer_po,
          };
        });

        setSelectedPoEditData(customData);
      }
    } else {
      alertPop(
        "error",
        "Error",
        response?.data?.message?.[0] || "Something went wrong!",
      );
    }
  }, [id]);

  useEffect(() => {
    // Get PO data on first render
    if (id) {
      getPOData();
    }
  }, [id, getPOData]);

  return (
    <div>
      <CreatePackingReq
        selectedPoEditData={selectedPoEditData}
        poMapId={poMapId}
        isEdit={true}
        isView={true}
        editId={Number(id)}
      />
    </div>
  );
};

export default ViewPackingReq;
