import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Card,
  Input,
  Select,
  Table,
  Button,
  Breadcrumb,
  Dropdown,
  Menu,
  Affix,
  Typography,
  Popconfirm,
  Modal,
  Form,
} from "antd";
import { MoreOutlined, EditOutlined, DeleteTwoTone } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import BodyHeader from "../../Common/BodyHeader";
import Header from "../../Common/Header";
import { isMobileView } from "../../Common/AppHelper";
import { SALES_CONTRACT_COMMERCIAL_LIST, SALES_CONTRACT_COMMERCIAL_ADD_DELETE_REASON, INVOICE_CHALLAN_MAPPING_RESOURCE } from "../../../apiServices/API_ENDPOINTS";
import { getData, deleteData, postData } from "../../../apiServices/common";
import CustomFilter from "../../Common/CustomFilter";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberWithCommas } from "../../../utils/functions";
import { hasPermission } from "../../../utils/functions";
import {
  SALES_CONTRACT_COMMERCIAL_VIEW_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_EDIT_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_DELETE_PERMISSION,
  NO_PERMISSION_REQUIRED
} from "../../../routes/permissions";
import { alertPop } from "../../../apiServices/common/helper";

export default function Forwarder(props) {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValues, setSearchValues] = useState();
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Antd Constants
  const { Option } = Select;
  const [form] = Form.useForm();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const searchFilter = (values) => {
    console.log("values", values);
  };

  const columns = [
    {
      title: "Supplier Name",
      dataIndex: ["supplier_name"],
      key: ["supplier_name"],
    },
    {
      title: "Invoice Number",
      dataIndex: ["invoice_number"],
      key: ["invoice_number"],
    },
    {
      title: "Payment Type",
      dataIndex: ["invoice_position_type"],
      key: ["invoice_position_type"]
    },
    {
      title: "Rereference Number",
      dataIndex: ["b2b_lc_number"],
      key: ["b2b_lc_number"],
    },
    // {
    //   title: "Invoice Qty",
    //   dataIndex: ["invoice_qty"],
    //   key: ["invoice_qty"],
    //   render: (value) => formatNumberWithCommas(value),
    // },
    {
      title: "Invoice Value",
      dataIndex: ["invoice_value"],
      key: ["invoice_value"],
      render: (value) => formatNumberWithCommas(value),
    },
    {
      title: "Buyer",
      dataIndex: ["buyer_name"],
      key: ["buyer_name"],
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: ["status"],
    },
    {
      title: "challans",
      dataIndex: ["challan_lists"],
      key: ["challan_lists"],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllPermitList = async (passedObject) => {

    const filter = passedObject;
    const pageQuery = `${filter?.page ? "" : `?page=${currentPage}`}`;
    const customQuery = `${INVOICE_CHALLAN_MAPPING_RESOURCE}/${pageQuery}`;
    const response = await getData(customQuery, false, filter);

    if (response && response?.data?.code === 200) {
      setPermitList(response?.data?.data?.data);
      setTotalData(response?.data?.data?.total);
      setRangeData(response?.data?.data?.per_page);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllPermitList(filterData);
  }, [currentPage, searchValues, filterData]);

  const removeReconciliation = async (value) => {
    const response = await deleteData(`${INVOICE_CHALLAN_MAPPING_RESOURCE}/${value}`);

    if (response && response?.code === 200) {
      alertPop("success", "Deleted Successfully!");
      getAllPermitList({page: currentPage});

    } else {
      alertPop(
        "error",
        response?.data?.message
          ? response?.data?.message[0]
          : "Something Went Wrong!",
      );
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/local-purchase-reconciliation/edit/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => removeReconciliation(data?.id)}
            >
              <DeleteTwoTone /> Delete
            </Popconfirm>
          ),
          key: "2",
          disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const memoizedCustomFilter = useMemo(
    () => (
      <CustomFilter
        showFilter={showFilter}
        tableData={permitList}
        fetchTableData={getAllPermitList}
        filterName="local_purchase_reconciliation"
      />
    ),
    [showFilter],
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Local Purchase Reconciliation"
            subTitle=""
            actions={[
              <Button
                type={showFilter ? "danger" : "primary"}
                size="small"
                onClick={handleFilter}
                style={{ float: "right" }}
              >
                {`${showFilter ? "Close" : "Open"} Filter Option`}
              </Button>,
              <Button
                key="3"
                size="small"
                type="primary"
                disabled={
                  !hasPermission([NO_PERMISSION_REQUIRED])
                }
              >
                <Link to="/local-purchase-reconciliation/create">
                  {hasPermission([NO_PERMISSION_REQUIRED])
                    ? "Add New"
                    : "No Permission"}
                </Link>
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <BodyHeader
            // title="Style List"
            // filtersOption={filtersOption}
            searchFilter={searchFilter}
          />
          {memoizedCustomFilter}
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}
