import { useCallback, useEffect, useMemo, useState } from "react";
import { DeleteTwoTone, EditTwoTone, UploadOutlined } from "@ant-design/icons";
import {
    Button,
    Space,
    Upload,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../CommonComponents/ExtraContent";
import FilterComponent from "../CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../../apiServices/common";
import {
    BIN_LIST_ENDPOINT,
    BIN_ADD_ENDPOINT,
    BIN_EDIT_ENDPOINT,
    BIN_DELETE_ENDPOINT,
    RACK_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../utils/functions";
import {
  BIN_CREATE_PERMISSION,
  BIN_EDIT_PERMISSION,
  BIN_DELETE_PERMISSION,
} from "../../../routes/permissions";
import { useLocation } from "react-router";

const BinConfig = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rackList, setRackList] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Antd
  const [binForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllBin = useCallback(
    async (filterValues) => {
      const { name } = filterValues || {};

      setLoading(true);

      const query = `${BIN_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...(name && { name: name }),
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting bin data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllBin(filterData);
    getAllRack();
  }, [refresh, filterData, getAllBin]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    binForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const getAllRack = async (filterValues) => {
    setLoading(true);

    const query = `${RACK_LIST_ENDPOINT}`;
    const payload = {
      page: 1,
      per_page: 10,
      ...filterValues,
    };

    const response = await getData(query, false, payload);

    if (response?.status === 200) {
      setLoading(false);
      setRackList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting rack data");
    }
  };

  const deleteSelectedBin = async (id) => {
    setLoading(true);
    const response = await deleteData(`${BIN_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Bin Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedBin = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      description: formValues?.description,
      status: formValues?.status,
      rack_id: Number(formValues?.rack_id),
    };

    const response = await putData(
      `${BIN_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Bin Edited Successfully");
      refetch();
      binForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewBin = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      description: formValues?.description,
      status: formValues?.status,
      rack_id: Number(formValues?.rack_id),
    };

    const response = await postData(BIN_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Bin Created Successfully");
      refetch();
      binForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    // Push the selected rack info to the rack array
    const rackInfo = {
      id: Number(record?.rack_info?.id),
      name: record?.rack_info?.name,
      warehouse_info: record?.rack_info?.warehouse_info,
    };

    const valueExists = rackList?.find((item) => item.id === rackInfo.id);

    if(!valueExists) {
      const rackListCopy = [...rackList];
      rackListCopy.push(rackInfo);
      setRackList(rackListCopy);
    }

    binForm.setFieldsValue({
      id: record?.id,
      name: record?.name,
      description: record?.description,
      status: record?.status === "1" ? true : false,
      rack_id: record?.rack_info?.id,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Bin Name",
      dataIndex: ["name"],
      key: "bin_name",
    },
    {
      title: "Warehouse Name",
      dataIndex: ["rack_info", "warehouse_info", "name"],
      key: "warehouse_name",
    },
    {
      title: "Rack Name",
      dataIndex: ["rack_info", "name"],
      key: "rack_name",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "bin_status",
      render: (_, record) => {
        return <div>{record?.status === "1" ? "Active" : "Inactive"}</div>;
      },
    },
    {
      title: "Description",
      dataIndex: ["description"],
      key: "bin_description",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedBin(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([BIN_DELETE_PERMISSION]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([BIN_EDIT_PERMISSION]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  const memoizedCustomFilter = useMemo(
      () => (
        <FilterComponent
          tableData={tableData}
          filterAPI={getAllBin}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      ),
      [showFilter],
    );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([BIN_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([BIN_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Bin Configuration</Title>}
          />
        }
        content={
          <>
            {memoizedCustomFilter}

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                  showSizeChanger: false,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Bin" : "Create Bin"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={binForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedBin : createNewBin}
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="name"
                placeholder="Bin Name"
                label="Bin Name"
                rules={[
                  {
                    required: true,
                    message: "Bin Name Required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="description"
                placeholder="Description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Description Required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item
                name="status"
                placeholder="Status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Status Required",
                  },
                ]}
                valuePropName="checked"
                initialValue={true}
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="rack_id"
                placeholder="Rack ID"
                label="Rack ID"
                rules={[
                  {
                    required: true,
                    message: "Rack ID Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Rack"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  style={{ width: "100%" }}
                >
                  {rackList?.map((item, index) => (
                    <Option key={item.id} value={item.id}>
                      {`Rack: ${item.name}, Warehouse: ${item?.warehouse_info?.name}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default BinConfig;
