import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useEffect } from "react";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../../utils/functions";

const ItemEdit = (props) => {
  // Props
  const {
    selectedItem,
    getTNATaskList,
    TNATaskList,
    closeDrawer,
    onUpdateItem,
    onAddNewItem,
    isDrawerOpen,
    userList,
    itemDetailsList,
    getUserList,
  } = props;

  console.log('userList', userList);
  

  // Antd
  const [editForm] = Form.useForm();
  const { Option } = Select;

  //   Effects
  useEffect(() => {
    if (selectedItem) {
      editForm.setFieldsValue({
        // tna_process_id: foundTask?.process?.id,
        tna_process_title: selectedItem?.tna_process_title,
        tna_department_name: selectedItem?.tna_department_name,
        task_dependencies: selectedItem?.task_dependencies,
        task_dependencies_name: selectedItem?.task_dependencies_name,
        style_wise: selectedItem?.style_wise,
        po_wise: selectedItem?.po_wise,
        is_pcd: selectedItem?.is_pcd,
        is_ship: selectedItem?.is_ship,
        tna_task_name: selectedItem?.tna_task_name,
        tna_task_id: selectedItem?.tna_task_id,
        days: selectedItem?.days,
        responsible_person_id: selectedItem?.responsible_person_id,
        responsible_person_name: selectedItem?.responsible_person_name,
      });
    }
  }, [selectedItem, editForm, isDrawerOpen]);

  const onCancel = () => {
    editForm.resetFields();
    closeDrawer();
  };

  const onUpdateOrAdd = (values) => {
    // Add/Edit
    if (selectedItem) {
      onUpdateItem(selectedItem?.index, values);
    } else {
      onAddNewItem(values);
      editForm.resetFields();
    }
  };

  const onSelectTask = (id) => {
    const foundTask = TNATaskList?.find((item) => item?.id === id);
    editForm.setFieldsValue({
      tna_process_id: foundTask?.process?.id,
      tna_process_title: foundTask?.process?.title,
      tna_department_name: foundTask?.department?.title,
      style_wise: foundTask?.relation_with === "Style" ? "Yes" : "No",
      po_wise: foundTask?.relation_with === "PO" ? "Yes" : "No",
      is_pcd: foundTask?.is_pcd === 1 ? "Yes" : "No",
      is_ship: foundTask?.is_shipment === 1 ? "Yes" : "No",
      tna_task_name: foundTask?.title,
    });
  };

  return (
    <Form form={editForm} layout="vertical" onFinish={onUpdateOrAdd}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="tna_task_name" hidden />
          <Form.Item
            name="tna_task_id"
            label="Task"
            rules={[
              {
                required: true,
                message: "Task is required",
              },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: 250 }}
              className="w-100"
              showSearch
              onSearch={(e) => getTNATaskList(e)}
              onSelect={(value) => {
                onSelectTask(value);
              }}
              placeholder="Select a Task"
              optionFilterProp="children"
              size="small"
            >
              {isArrayAndHasValue(TNATaskList) &&
                TNATaskList?.map((item) => (
                  <Option value={item?.id} key={item?.id}>
                    {item?.title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="tna_process_id" hidden />
          <Form.Item name="tna_process_title" label="Process">
            <Input size="small" placeholder="Process" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="tna_department_name" label="Department">
            <Input size="small" placeholder="Department" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="style_wise" label="Style Wise">
            <Input size="small" placeholder="Style Wise" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="po_wise" label="PO Wise">
            <Input size="small" placeholder="PO Wise" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="is_pcd" label="Is PCD?">
            <Input size="small" placeholder="Yes/No" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="is_ship" label="Is Ship.?">
            <Input size="small" placeholder="Yes/No" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="days"
            label="Days"
            rules={[
              {
                required: true,
                message: "Days required",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              size="small"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="responsible_person_name" hidden />
          <Form.Item
            name="responsible_person_id"
            label="Responsible"
            rules={[
              {
                required: true,
                message: "Responsible is required",
              },
            ]}
          >
            <Select
              placeholder="Select responsible person"
              size="small"
              onSelect={(value) => {
                const foundUser = userList?.find((user) => user?.id === value);
                editForm.setFieldValue(
                  "responsible_person_name",
                  foundUser?.name,
                );
              }}
              showSearch
              onSearch={(value) => getUserList({name: value})}
              optionFilterProp="children"
            >
              {isArrayAndHasValue(userList) &&
                userList?.map((item) => (
                  <Option value={item?.id} key={item?.id}>
                    {item?.name || item?.title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="task_dependencies_name" hidden />
          <Form.Item label="Dependency" name="task_dependencies">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select dependencies"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              options={
                isArrayAndHasValue(itemDetailsList) &&
                itemDetailsList?.map((item) => ({
                  label: item?.tna_task_name,
                  value: item?.tna_task_id,
                }))
              }
              size="small"
              onChange={(value) => {
                // const editFormValues = editForm?.getFieldsValue();
                // const taskDepNames = isArrayAndHasValue(
                //   editFormValues?.task_dependencies_name,
                // )
                //   ? editFormValues?.task_dependencies_name
                //   : [];
                const foundTask = TNATaskList?.filter((item) =>
                  value?.includes(item?.id),
                )?.map((item) => item?.title);

                // console.log({ foundTask });

                editForm.setFieldValue("task_dependencies_name", foundTask);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            size="small"
          >
            {selectedItem ? `Update` : `Add`}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            style={{ width: "100%" }}
            type="danger"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ItemEdit;
