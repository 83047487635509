import { Col, Form, Select } from "antd";
import {
  APP_USER_LIST,
  GET_USERS_ROLE_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";

const DigitalizationUserLineAssignList = ({ initialTableData }) => {
  const [options, setOptions] = useState({
    lines: [],
    users: [],
    roles: [],
  });
  // API CALL
  // get line options from API
  //   const getLines = useCallback(async (filterValues) => {
  //     try {
  //       const response = await getData(GET_SEWING_LINE_ENDPOINT, false, {
  //         per_page: 50,
  //       });
  //       return response.data?.data?.data?.map((line) => ({
  //         ...line,
  //         value: line.id,
  //         label: line.name,
  //       }));
  //     } catch (error) {
  //       console.error("getLines -> error", error);
  //       return [];
  //     }
  //   }, []);

  const getUser = useCallback(async (filterValues) => {
    try {
      const response = await getData(APP_USER_LIST, false, {
        per_page: 10,
        ...filterValues,
      });
      return response.data?.data?.data?.map((user) => ({
        ...user,
        value: user.id,
        label: user.name,
      }));
    } catch (error) {
      console.error("getUser -> error", error);
      return [];
    }
  }, []);
  // API CALL
  const fetchRoleTypes = useCallback(async (filterValues) => {
    const bodyData = {
      per_page: 3,
      app_menu: "yes",
      ...filterValues,
    };
    const query = `${GET_USERS_ROLE_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      return response.data?.data?.data?.map((role) => ({
        ...role,
        value: role.id,
        label: role.name,
      }));
    } else {
      return [];
    }
  }, []);
  const getOptionsList = async () => {
    // const line = await getLines();
    const user = await getUser();
    const role = await fetchRoleTypes();
    setOptions((prev) => ({
      ...prev,
      users: user,
      roles: role,
    }));
  };

  useEffect(() => {
    getOptionsList();
  }, []);

  const onSearchUser = async (values) => {
    const user = await getUser({ name: values });

    setOptions((prev) => ({
      ...prev,
      users: user,
    }));
  };

  const onSearchRole = async (values) => {
    const role = await fetchRoleTypes({ name: values });

    setOptions((prev) => ({
      ...prev,
      roles: role,
    }));
  };

  return (
    <>
      <Col span={6}>
        <Form.Item label="User" name="user_id">
          <Select
            placeholder="Select User"
            options={options?.users}
            allowClear
            showSearch
            onSearch={onSearchUser}
            filterOption={false}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Role" name="role_id">
          <Select
            placeholder="Select Role"
            options={options?.roles}
            allowClear
            showSearch
            filterOption={false}
            onSearch={onSearchRole}
          />
        </Form.Item>
      </Col>
      {/* <Col span={6}>
        <Form.Item label="Line" name="line_id">
          <Select placeholder="Select Line" options={options?.lines} />
        </Form.Item>
      </Col> */}
    </>
  );
};

export default DigitalizationUserLineAssignList;
