import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";

const SizeTracking = (props) => {
  // Props
  const {
    view,
    editMode,
    poDetailsData,
    PackReqForm,
    selectedPOUniqueID,
    isDrawerOpen,
    insertSizeTableData,
  } = props;

  // States
  const [sizeTrackingData, setSizeTrackingData] = useState([{ unique_id: 0 }]);
  const [dynamicSizeList, setDynamicSizeList] = useState(null);
  const [isAllChecked, setIsAllChecked] = useState(0);

  // Antd
  const [sizeTrackingForm] = Form.useForm();
  const { Option } = Select;
  const { confirm } = Modal;

  // Effects
  useEffect(() => {
    if (!isDrawerOpen) {
      // Reset track when drawer is closed
      resetTrack();
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    // Get existing size tracking data and set to state
    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === selectedPOUniqueID,
    );

    // console.log("foundPoItem", foundPoItem);

    if (foundPoItem) {
      if (
        isArrayAndHasValue(foundPoItem?.po_lists) ||
        isArrayAndHasValue(foundPoItem?.carton)
      ) {
        if (editMode) {
          setSizeTrackingData(
            foundPoItem?.carton?.map((item) => ({ ...item, is_checked: 1 })),
          );
          sizeTrackingForm.setFieldsValue({
            po_no: foundPoItem?.po_map?.original_po,
            loading_quantity: foundPoItem?.loading_quantity,
            cbm: foundPoItem?.cbm,
          });
        } else {
          setSizeTrackingData(foundPoItem?.po_lists);
          sizeTrackingForm.setFieldsValue({
            po_no: foundPoItem?.po_map?.original_po,
            loading_quantity: foundPoItem?.loading_quantity,
            cbm: foundPoItem?.carton_cbm,
          });
        }
      }
    }
    // Dependency limited to avoid re-render
    // Change dependency array with caution
  }, [
    isDrawerOpen,
    selectedPOUniqueID,
    poDetailsData,
    editMode,
    sizeTrackingForm,
  ]);

  const resetTrack = () => {
    setSizeTrackingData([{ unique_id: 0 }]);
    setDynamicSizeList(null);
    setIsAllChecked(0);
  };

  useEffect(() => {
    // sizeTrackingData if checked then show loading_quantity total base on checked carton carton_cbm
    if (isArrayAndHasValue(sizeTrackingData)) {
      const checkedCarton = sizeTrackingData?.filter(
        (item) => item?.is_checked === 1,
      );

      const totalCBM = checkedCarton?.reduce(
        (acc, item) => acc + item?.carton_cbm,
        0,
      );
      sizeTrackingForm.setFieldsValue({
        cbm: totalCBM,
      });
    }
  }, [sizeTrackingData]);

  return (
    <Form form={sizeTrackingForm} layout={"horizontal"}>
      <Card>
        <Row gutter={6}>
          <Col span={8}>
            <Form.Item name="po_no" label="PO No">
              <Input
                type="text"
                style={{ width: "100%" }}
                size="small"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="loading_quantity" label="Loading Qty (pc)">
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => commaSeparateNumber(value)}
                precision={2}
                size="small"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="cbm" label="CBM">
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => commaSeparateNumber(value)}
                precision={2}
                size="small"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <div className="">
        <Card className="mt-3" title="Carton List">
          <div className="custome-table">
            <table
              style={{
                width: "100%",
              }}
            >
              <thead>
                {/* <tr>
                  <th colSpan={5}></th>

                  <th colSpan={2} className="bc-light-orange">
                    Actions
                  </th>
                </tr> */}
                <tr>
                  {/* Serial Number */}
                  <th key="header_serial_number" className="bc-java ">
                    SL
                  </th>
                  {/* Color */}
                  <th colSpan={dynamicSizeList?.length + 1} className="bc-java">
                    Carton No
                  </th>
                  {/* Substyle */}
                  <th key="header_substyle" className="bc-java ">
                    Qty (pc)
                  </th>
                  <th key={`size_ratio_color_total`} className="bc-java ">
                    CBM
                  </th>
                  <th key={`size_ratio_color_total`} className="bc-java ">
                    <Checkbox
                      name="load"
                      checked={isAllChecked}
                      onChange={(e) => {
                        setIsAllChecked(e?.target?.checked ? 1 : 0);
                        let sizeTrackingDataCopy = [...sizeTrackingData];
                        sizeTrackingDataCopy?.forEach((item) => {
                          item.is_checked = e?.target?.checked ? 1 : 0;
                        });
                        setSizeTrackingData(sizeTrackingDataCopy);
                      }}
                    >
                      Load
                    </Checkbox>
                  </th>
                </tr>
              </thead>

              <tbody>
                {sizeTrackingData && sizeTrackingData?.length > 0 ? (
                  <>
                    {sizeTrackingData.map((obj, index) => {
                      return (
                        <tr key={`table_parent_key_${index}`}>
                          {/* SL No. */}
                          <td className="t-body sticky">{index + 1}</td>

                          {/* Carton No */}
                          <td className="t-body sticky">{obj?.carton_no}</td>

                          {/*  Qty (pc) */}
                          <td className="t-body sticky">{obj?.carton_qty}</td>
                          {/* CBM */}
                          <td className="t-body sticky">{obj?.carton_cbm}</td>
                          {/* Load */}
                          <td className="t-body sticky">
                            <Checkbox
                              name="load"
                              checked={obj?.is_checked}
                              onChange={(e) => {
                                const sizeTrackingDataCopy = [
                                  ...sizeTrackingData,
                                ];
                                sizeTrackingDataCopy[index].is_checked = e
                                  ?.target?.checked
                                  ? 1
                                  : 0;
                                setSizeTrackingData(sizeTrackingDataCopy);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </Card>
        <div className="centered-item-parent">
          <Button
            type="primary"
            onClick={() =>
              insertSizeTableData(sizeTrackingData, sizeTrackingForm)
            }
            size="small"
            style={{ width: 200, marginTop: 10 }}
          >
            Insert
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default SizeTracking;
