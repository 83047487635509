import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import ShipmentOverForm from "./ShipmentOverForm";
import { SHIPMENT_HANDOVER_EDIT } from "../../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import moment from "moment";

export default function EditShipmentOver(props) {
  // States
  const [editMode, setEditMode] = useState(false);

  //   Refs
  const propertyFormRef = useRef(null);

  //   Antd
  const [form] = Form.useForm();

  //   Router
  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/export-customs-clearance/shipment-handover");
  };
  const { shipmentOverId } = useParams();

  const getSelectedNotingDetails = useCallback(
    async (shipmentOverId) => {

      const response = await getData(
        `${SHIPMENT_HANDOVER_EDIT}/${shipmentOverId}`,
      );

      console.log("response?.data?.data",response?.data?.data);
      if (response && response.status === 200) {
        const tableData = response?.data?.data;

        form.setFieldsValue({
          ...tableData,
          sales_contract_id: tableData?.sales_contract_id,
          utilization_decleration_number: tableData.utilization_decleration_number,
          utilization_decleration_date: tableData.utilization_decleration_date ? moment(tableData.utilization_decleration_date) : null,
          shipment_rcv_date: tableData.shipment_rcv_date ? moment(tableData.shipment_rcv_date) : null,
          export_invoice_number: tableData?.export_invoice_number,
          export_invoice_date: tableData.export_invoice_date ? moment(tableData.export_invoice_date) : null,
          export_invoice_value: tableData?.export_invoice_value,
          carton_qty: tableData?.carton_qty,
          shipment_qty: tableData?.shipment_qty,
          vehicle_number: tableData?.vehicle_number,
          challan_no: tableData?.challan_no,
          depot_name: tableData?.depot_name,
          custom_assesment_date: tableData.custom_assesment_date ? moment(tableData.custom_assesment_date) : null,
          custom_examination_date: tableData.custom_examination_date ? moment(tableData.custom_examination_date) : null,
          forwarder_name: tableData?.forwarder_name,
          handover_date: tableData.handover_date ? moment(tableData.handover_date) : null,
          export_bill_of_entry_number: tableData?.export_bill_of_entry_number,
          bill_of_entry_date: tableData.bill_of_entry_date ? moment(tableData.bill_of_entry_date) : null,
        });
      } else {
        alertPop(
          "error",
          response?.messages ? response?.messages : "Something Went Wrong!",
        );
      }
    },
    [form],
  );

  useEffect(() => {
    if (shipmentOverId) {
      setEditMode(true);
      getSelectedNotingDetails(shipmentOverId);
    }
  }, [shipmentOverId, getSelectedNotingDetails]);

  const submitForm = () => {
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={`${
              shipmentOverId
                ? "Update Shipment Handover"
                : "Add Shipment Handover"
            }`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => submitForm()}
                >
                  {`${
                    shipmentOverId
                      ? "Update"
                      : "Save"
                  }`}
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <ShipmentOverForm
        ref={propertyFormRef}
        form={form}
        editMode={editMode}
        shipmentOverId={shipmentOverId}
      />
    </>
  );
}
