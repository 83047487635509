import React, { useEffect, useState } from "react";
import { Button, Space } from "antd";
import CategoryList from "./ListItemCategory";
import CreateItemCategory from "./CreateItemCategory";
import { hasPermission } from "../../utils/functions";
import {
  ITEM_CATEGORY_CREATE_PERMISSION,
  ITEM_CATEGORY_EDIT_PERMISSION,
  ITEM_CATEGORY_DELETE_PERMISSION,
} from '../../routes/permissions';
import { useLocation, useNavigate } from "react-router";

const ItemCategory = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isAddItemCategory, onSetAddItemCategory] = useState(false);
  const [refresh, setRefresh] = useState(0);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};
    
    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const handleAddCategoryOpen = () => {
    onSetAddItemCategory(true);
  };

  const handleAddCategoryClose = () => {
    onSetAddItemCategory(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const goToItemSequencePage = () => {
    navigate(`/configuration/item-category-sequence`);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        size="small"
        onClick={goToItemSequencePage}
        style={{ float: "right" }}
      >
        Item Sequence
      </Button>
      <Button
        type="primary"
        size="small"
        onClick={handleAddCategoryOpen}
        disabled={!hasPermission([ITEM_CATEGORY_CREATE_PERMISSION])}
      >
        {hasPermission([ITEM_CATEGORY_CREATE_PERMISSION])
          ? "Add New"
          : "No Permission"}
      </Button>
    </Space>
  );

  return (
    <>
      <CategoryList
        extra={extra}
        refresh={refresh}
        refetch={refetch}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <CreateItemCategory
        refresh={refresh}
        refetch={refetch}
        isAddItemCategory={isAddItemCategory}
        handleAddCategoryClose={handleAddCategoryClose}
      />
    </>
  );
};

export default ItemCategory;
