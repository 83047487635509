import { Card, Spin, Table } from "antd";
import moment from "moment";
const formatIntWithCommas = (value) => {
  return parseInt(value)?.toLocaleString();
};

const formatFloatWithCommas = (value) => {
  return parseFloat(value).toFixed(2).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
const columnsForToday = [
  {
    title: "Concern",
    dataIndex: "concern",
    key: "concern",
  },
  {
    title: "Budgeted Production/pc",
    dataIndex: "budgetedProduction",
    key: "budgetedProduction",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Folding",
    dataIndex: "folding",
    key: "folding",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Avg CM/USD",
    dataIndex: "avgCmUsd",
    key: "avgCmUsd",
    align: "right",
    render: formatFloatWithCommas,
  },
  {
    title: "Income CM/USD",
    dataIndex: "incomeCmUsd",
    key: "incomeCmUsd",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Income CM/Taka",
    dataIndex: "incomeCmTaka",
    key: "incomeCmTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Expenditure Taka",
    dataIndex: "expenditureTaka",
    key: "expenditureTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Up-to-date Est. Net Profit/Taka",
    dataIndex: "estNetProfitTaka",
    key: "estNetProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Cumulative Projected Profit/(Loss) Taka",
    dataIndex: "cumulativeProfitLossTaka",
    key: "cumulativeProfitLossTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Variance-Profit/Taka",
    dataIndex: "varianceProfitTaka",
    key: "varianceProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
  },
];

const dataSourceForToday = [
  {
    key: "1",
    concern: "CGL",
    budgetedProduction: "572080",
    folding: "208988",
    avgCmUsd: 0.99,
    incomeCmUsd: "207604",
    incomeCmTaka: "24289615",
    expenditureTaka: "41242891",
    estNetProfitTaka: "16953276",
    cumulativeProfitLossTaka: "3711015",
    varianceProfitTaka: "13242262",
    remarks: "",
  },
  {
    key: "2",
    concern: "CGL",
    budgetedProduction: "572080",
    folding: "208988",
    avgCmUsd: 768.9868787,
    incomeCmUsd: "207604",
    incomeCmTaka: "24289615",
    expenditureTaka: "41242891",
    estNetProfitTaka: "16953276",
    cumulativeProfitLossTaka: "3711015",
    varianceProfitTaka: "13242262",
    remarks: "",
  },
];

const columnsSharingMerchandisers = [
  {
    title: "Dealing Merchandiser",
    dataIndex: "merchandiser",
    key: "merchandiser",
  },
  {
    title: "Buyer Name",
    dataIndex: "buyerName",
    key: "buyerName",
  },
  {
    title: "Folding",
    dataIndex: "folding",
    key: "folding",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Avg CM/USD",
    dataIndex: "avgCmUsd",
    key: "avgCmUsd",
    align: "right",
    render: formatFloatWithCommas,
  },
  {
    title: "Income CM/USD",
    dataIndex: "incomeCmUsd",
    key: "incomeCmUsd",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Income CM/Taka",
    dataIndex: "incomeCmTaka",
    key: "incomeCmTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Expenditure Taka",
    dataIndex: "expenditureTaka",
    key: "expenditureTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Up-to-date Est. Net Profit/Taka",
    dataIndex: "estNetProfitTaka",
    key: "estNetProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Cumulative Projected Profit/(Loss) Taka",
    dataIndex: "cumulativeProfitLossTaka",
    key: "cumulativeProfitLossTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "Variance-Profit/Taka",
    dataIndex: "varianceProfitTaka",
    key: "varianceProfitTaka",
    align: "right",
    render: formatIntWithCommas,
  },
  {
    title: "% of NP on Total Day/Profit",
    dataIndex: "npPercentage",
    key: "npPercentage",
    align: "right",
  },
];

const dataSourceSharingMerchandisers = [
  {
    key: "1",
    merchandiser: "MR. MAMUN",
    buyerName: "DENIMACH GAS",
    folding: 525,
    avgCmUsd: 0.47,
    incomeCmUsd: 247,
    incomeCmTaka: "28,875",
    expenditureTaka: "49,029",
    estNetProfitTaka: "20,154",
    cumulativeProfitLossTaka: "4,412",
    varianceProfitTaka: "15,742",
    npPercentage: "0.12",
  },
  {
    key: "2",
    merchandiser: "MR. SUMON",
    buyerName: "LA CAMICA (SUB)",
    folding: 50713,
    avgCmUsd: 0.74,
    incomeCmUsd: 37710,
    incomeCmTaka: "44,12,031",
    expenditureTaka: "74,91,470",
    estNetProfitTaka: "3,079,439",
    cumulativeProfitLossTaka: "674,079",
    varianceProfitTaka: "2,405,360",
    npPercentage: "18.16",
  },
  {
    key: "3",
    merchandiser: "MR. HIRON",
    buyerName: "WRANGLER (SUB)",
    folding: 48321,
    avgCmUsd: 0.78,
    incomeCmUsd: 37690,
    incomeCmTaka: "44,09,774",
    expenditureTaka: "74,87,638",
    estNetProfitTaka: "3,077,864",
    cumulativeProfitLossTaka: "673,734",
    varianceProfitTaka: "2,404,130",
    npPercentage: "18.15",
  },
  {
    key: "4",
    merchandiser: "MR. NADIM",
    buyerName: "LIDL/JSTEX (SUB)",
    folding: 20720,
    avgCmUsd: 0.43,
    incomeCmUsd: 8855,
    incomeCmTaka: "10,36,000",
    expenditureTaka: "17,59,091",
    estNetProfitTaka: "723,091",
    cumulativeProfitLossTaka: "158,282",
    varianceProfitTaka: "564,809",
    npPercentage: "4.27",
  },
  {
    key: "5",
    merchandiser: "MR. SUMON",
    buyerName: "G-TEX",
    folding: 26612,
    avgCmUsd: 1.75,
    incomeCmUsd: 46517,
    incomeCmTaka: "54,42,494",
    expenditureTaka: "92,41,158",
    estNetProfitTaka: "3,798,665",
    cumulativeProfitLossTaka: "831,515",
    varianceProfitTaka: "2,967,150",
    npPercentage: "22.41",
  },
  {
    key: "6",
    merchandiser: "MR. HIRON",
    buyerName: "ROKIES (SUB)",
    folding: 645,
    avgCmUsd: 1.09,
    incomeCmUsd: 701,
    incomeCmTaka: "82,027",
    expenditureTaka: "1,39,279",
    estNetProfitTaka: "57,252",
    cumulativeProfitLossTaka: "12,532",
    varianceProfitTaka: "44,720",
    npPercentage: "0.34",
  },
  {
    key: "7",
    merchandiser: "MR. HIRON",
    buyerName: "CORONA",
    folding: 7820,
    avgCmUsd: 0.84,
    incomeCmUsd: 6569,
    incomeCmTaka: "7,68,550",
    expenditureTaka: "13,04,970",
    estNetProfitTaka: "536,420",
    cumulativeProfitLossTaka: "117,421",
    varianceProfitTaka: "418,999",
    npPercentage: "3.16",
  },
  {
    key: "8",
    merchandiser: "MR. SUMON",
    buyerName: "FORTUNEX",
    folding: 1025,
    avgCmUsd: 1.44,
    incomeCmUsd: 1476,
    incomeCmTaka: "1,72,692",
    expenditureTaka: "2,93,225",
    estNetProfitTaka: "120,533",
    cumulativeProfitLossTaka: "26,384",
    varianceProfitTaka: "94,149",
    npPercentage: "0.71",
  },
  {
    key: "9",
    merchandiser: "MR. MIZAN",
    buyerName: "PERRY ELLIS",
    folding: 20855,
    avgCmUsd: 1.56,
    incomeCmUsd: 32571,
    incomeCmTaka: "38,10,789",
    expenditureTaka: "64,70,583",
    estNetProfitTaka: "2,659,794",
    cumulativeProfitLossTaka: "582,220",
    varianceProfitTaka: "2,077,574",
    npPercentage: "15.69",
  },
  {
    key: "10",
    merchandiser: "Ms. ZOHORA",
    buyerName: "REVO SOURCING",
    folding: 18387,
    avgCmUsd: 1.1,
    incomeCmUsd: 20226,
    incomeCmTaka: "23,66,407",
    expenditureTaka: "40,18,074",
    estNetProfitTaka: "1,651,667",
    cumulativeProfitLossTaka: "361,544",
    varianceProfitTaka: "1,290,123",
    npPercentage: "9.74",
  },
  {
    key: "11",
    merchandiser: "MR. MIZAN",
    buyerName: "F & F",
    folding: 1313,
    avgCmUsd: 2.2,
    incomeCmUsd: 2889,
    incomeCmTaka: "3,37,966",
    expenditureTaka: "5,73,854",
    estNetProfitTaka: "235,888",
    cumulativeProfitLossTaka: "51,635",
    varianceProfitTaka: "184,253",
    npPercentage: "1.39",
  },
  {
    key: "12",
    merchandiser: "MR. SUMON",
    buyerName: "FORTUNEX (NAUTICA)",
    folding: 7392,
    avgCmUsd: 1.4,
    incomeCmUsd: 10349,
    incomeCmTaka: "12,10,810",
    expenditureTaka: "20,55,911",
    estNetProfitTaka: "845,101",
    cumulativeProfitLossTaka: "184,990",
    varianceProfitTaka: "660,112",
    npPercentage: "4.98",
  },
  {
    key: "13",
    merchandiser: "MR. MAMUN",
    buyerName: "PURBACHAL (SUB-CON)",
    folding: 4660,
    avgCmUsd: 0.39,
    incomeCmUsd: 1805,
    incomeCmTaka: "2,11,200",
    expenditureTaka: "3,58,610",
    estNetProfitTaka: "147,410",
    cumulativeProfitLossTaka: "32,268",
    varianceProfitTaka: "115,142",
    npPercentage: "0.8",
  },
];

const CumulativeProfitLossStatement = ({
  upToDateData,
  loadingUpToDateData,
  startDateWatch,
  endDateWatch,
}) => {
  if (loadingUpToDateData) return <Spin />;
  return (
    <Card
      title={`Cumulative Profit & (Loss) Statement: ${
        startDateWatch && endDateWatch
          ? `(${moment(startDateWatch).format("DD-MMM-YY")} to ${moment(
              endDateWatch,
            ).format("DD-MMM-YY")})`
          : ""
      }`}
      bordered={false}
      style={{
        boxShadow: "none",
      }}
    >
      <Table
        dataSource={dataSourceForToday}
        columns={columnsForToday}
        pagination={false}
        bordered
        loading={loadingUpToDateData}
        title={() => "Up to date production, Income & profit:"}
        summary={(data) => {
          let totalFolding = 0;
          let totalAvgCmUsd = 0;
          let totalIncomeCmUsd = 0;
          let totalIncomeCmTaka = 0;
          let totalExpenditureTaka = 0;
          let totalEstNetProfitTaka = 0;
          let totalCumulativeProfitLossTaka = 0;
          let totalVarianceProfitTaka = 0;
          data.forEach(
            ({
              folding,
              avgCmUsd,
              incomeCmUsd,
              incomeCmTaka,
              expenditureTaka,
              estNetProfitTaka,
              cumulativeProfitLossTaka,
              varianceProfitTaka,
            }) => {
              totalFolding += parseFloat(folding);
              totalAvgCmUsd += parseFloat(avgCmUsd);
              totalIncomeCmUsd += parseFloat(incomeCmUsd);
              totalIncomeCmTaka += parseFloat(incomeCmTaka);
              totalExpenditureTaka += parseFloat(expenditureTaka);
              totalEstNetProfitTaka += parseFloat(estNetProfitTaka);
              totalCumulativeProfitLossTaka += parseFloat(
                cumulativeProfitLossTaka,
              );
              totalVarianceProfitTaka += parseFloat(varianceProfitTaka);
            },
          );
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell />
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalFolding)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatFloatWithCommas(totalAvgCmUsd / data.length)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalIncomeCmUsd)}
                </Table.Summary.Cell>

                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalIncomeCmTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalExpenditureTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalEstNetProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalCumulativeProfitLossTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalVarianceProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell />
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <br />
      <Table
        dataSource={dataSourceSharingMerchandisers}
        columns={columnsSharingMerchandisers}
        pagination={false}
        bordered
        loading={loadingUpToDateData}
        title={() => "Sharing of above All by Merchandisers: "}
        footer={() => (
          <>
            <p>
              The expenses has been Allocated on pc based on % of CM on total
              earned CM.
            </p>
            <p>
              The performance has been calculated in combination of three
              garment units day production basis.
            </p>
          </>
        )}
        summary={(data) => {
          let totalFolding = 0;
          let totalAvgCmUsd = 0;
          let totalIncomeCmUsd = 0;
          let totalIncomeCmTaka = 0;
          let totalExpenditureTaka = 0;
          let totalEstNetProfitTaka = 0;
          let totalCumulativeProfitLossTaka = 0;
          let totalVarianceProfitTaka = 0;
          let totalNpPercentage = 0;
          data.forEach(
            ({
              folding,
              avgCmUsd,
              incomeCmUsd,
              incomeCmTaka,
              expenditureTaka,
              estNetProfitTaka,
              cumulativeProfitLossTaka,
              varianceProfitTaka,
              npPercentage,
            }) => {
              totalFolding += parseFloat(folding);
              totalAvgCmUsd += parseFloat(avgCmUsd);
              totalIncomeCmUsd += parseFloat(incomeCmUsd);
              totalIncomeCmTaka += parseFloat(incomeCmTaka);
              totalExpenditureTaka += parseFloat(expenditureTaka);
              totalEstNetProfitTaka += parseFloat(estNetProfitTaka);
              totalCumulativeProfitLossTaka += parseFloat(
                cumulativeProfitLossTaka,
              );
              totalVarianceProfitTaka += parseFloat(varianceProfitTaka);
              totalNpPercentage += parseFloat(npPercentage);
            },
          );
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell />
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalFolding)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatFloatWithCommas(totalAvgCmUsd / data.length)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalIncomeCmUsd)}
                </Table.Summary.Cell>

                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalIncomeCmTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalExpenditureTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalEstNetProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalCumulativeProfitLossTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {formatIntWithCommas(totalVarianceProfitTaka)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  {totalNpPercentage.toFixed(2)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Card>
  );
};

export default CumulativeProfitLossStatement;
