import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Affix, Button, Card, Space } from "antd";
import {
  ADD_BUYER_INQUIRY_PROCESS_MACHINE,
  BUYER_INQUIRY_LIST_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { BUYER_INQUIRY_EDIT_PERMISSION } from "routes/permissions";
import { hasPermission, isArrayAndHasValue } from "utils/functions";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import SpecialProcessTable from "./SpecialProcessTable";
import MachineRentTable from "./MachineRentTable";
import { disableSelectedProcess } from "../helper";

const BuyerInquiry = (props) => {
  // Props
  const { width } = props;

  // States
  const [specialProcessList, setSpecialProcessList] = useState(null);
  const [machineRentList, setMachineRentList] = useState(null);

  // Router
  const { buyerInquiryId } = useParams();

  const disableBySwitchValues = (props) => {
    const {
      Embroidery,
      Printing,
      Washing,
      Smoking,
      Pintuck,
      SnapButton,
    } = props;

    Embroidery === "No" && disableSelectedProcess("Embroidery");
    Printing === "No" && disableSelectedProcess("Printing");
    Washing === "No" && disableSelectedProcess("Washing");
    Smoking === "No" && disableSelectedProcess("Smoking");
    Pintuck === "No" && disableSelectedProcess("Pintuck");
    SnapButton === "No" && disableSelectedProcess("Snap Button");
  }

  const getProcessMachineData = useCallback(
    async (filterValues) => {
      const bodyData = {
        ...filterValues,
        per_page: filterValues?.per_page || 10,
      };
      const query = `${BUYER_INQUIRY_LIST_ENDPOINT}/${buyerInquiryId}/view-process-machine-rent`;
      const response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        // Disable values by switch value
        disableBySwitchValues({
          Embroidery: response?.data?.data?.embroidery,
          Printing: response?.data?.data?.printing,
          Washing: response?.data?.data?.washing,
          Smoking: response?.data?.data?.smoking,
          Pintuck: response?.data?.data?.pintuck,
          SnapButton: response?.data?.data?.snap_button,
        });

        // Work with process array data
        const processData = isArrayAndHasValue(
          response?.data?.data?.process_info,
        )
          ? response?.data?.data?.process_info
          : null;
        const customProcessData =
          processData &&
          processData?.map((item, index) => {
            // Disable the once used process
            disableSelectedProcess(item?.process);

            // Return custom data
            return {
              ...item,
              is_delete: 0,
              unique_id: index,
            };
          });
        const machineData = isArrayAndHasValue(
          response?.data?.data?.machine_info,
        )
          ? response?.data?.data?.machine_info
          : null;
        const customMachineData =
        machineData &&
        machineData?.map((item, index) => {
            return {
              ...item,
              is_delete: 0,
              unique_id: index,
            };
            
          });
        setSpecialProcessList(customProcessData);
        setMachineRentList(customMachineData);
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error getting process and machine data",
          null,
        );
      }
    },
    [buyerInquiryId],
  );

  useEffect(() => {
    getProcessMachineData();
  }, [getProcessMachineData]);

  const onSubmit = async () => {
    // Check validation
    if (!isArrayAndHasValue(specialProcessList)) {
      HandleNotification(
        "error",
        "bottomRight",
        "Please add atleast one special process",
        null,
      );
      return;
    }
    if (!isArrayAndHasValue(machineRentList)) {
      HandleNotification(
        "error",
        "bottomRight",
        "Please add atleast one machine to rent",
        null,
      );
      return;
    }
    const process_infos = specialProcessList.map((item) => {
      return {
        id: item?.id || 0,
        is_delete: item?.is_delete,
        process: item?.process,
        rent_per_garment: item?.rent_per_garment,
      };
    });

    const machine_infos = machineRentList.map((item) => {
      return {
        id: item?.id || 0,
        is_delete: item?.is_delete,
        ob_machine_type_id: item?.ob_machine_type_id,
        machine_model: item?.machine_model,
        machine_qty: item?.machine_qty,
        rent_per_day: item?.rent_per_day,
        no_of_day: item?.no_of_day,
        total_value: item?.total_value,
      };
    });

    const payload = {
      process_infos,
      machine_infos,
    };
    const query = `${ADD_BUYER_INQUIRY_PROCESS_MACHINE}/${buyerInquiryId}`;
    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "Success!",
        response?.message || `Successfully updated inquiry`,
      );
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || `Failed to update inquiry`,
      );
    }
  };

  const extra = (
    <Space>
      <Button
        key={0}
        size="small"
        type="primary"
        disabled={!hasPermission([BUYER_INQUIRY_EDIT_PERMISSION])}
        onClick={onSubmit}
      >
        {hasPermission([BUYER_INQUIRY_EDIT_PERMISSION])
          ? "Save"
          : "No Permission"}
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Special Process and Rented Machine Information"
            subTitle=""
            actions={extra}
          />
        </Card>
      </Affix>

      <div className="containt-body">
        <SpecialProcessTable
          specialProcessList={specialProcessList}
          setSpecialProcessList={setSpecialProcessList}
        />

        <MachineRentTable
          machineRentList={machineRentList}
          setMachineRentList={setMachineRentList}
        />
      </div>
    </Fragment>
  );
};

export default BuyerInquiry;
