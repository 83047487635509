import { postData } from "apiServices/common";
import { useState } from "react";

export default function useFetchData() {
  const [data, setData] = useState({
    dateSource: [],
    isLoading: false,
  });
  const onFinish = async (values) => {
    setData({ ...data, isLoading: true });
    // /api/digitalization/sam-justifications/report
    try {
      const response = await postData(
        "/api/digitalization/sam-justifications/report",
        {
          date: values.date.format("YYYY-MM-DD"),
        },
      );
      const formateData = [];
      if (Array.isArray(response.data) && response.data?.length > 0) {
        response.data?.forEach((item, index) => {
          if (
            !Array.isArray(item?.operation_data) ||
            item?.operation_data?.length === 0
          ) {
            formateData.push({
              key: index,
              line: item.line,
              daily_line_plan_id: item.daily_line_plan_id,
              date: item.date,
              ...item,
              rowSpan: 1,
            });
            return;
          }
          item?.operation_data?.forEach((subItem, subIndex) => {
            formateData.push({
              key: `${index}-${subIndex}`,
              line: item.line,
              style: item.style,
              daily_line_plan_id: item.daily_line_plan_id,
              date: item.date,
              ...subItem,
              rowSpan: subIndex === 0 ? item?.operation_data?.length : 0,
            });
          });
        });
      }
      setData({
        dateSource: formateData,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      setData({
        dateSource: [],
        isLoading: false,
      });
    }
  };
  return { data, onFinish, setData };
}
