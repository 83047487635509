import { Col, Form, InputNumber, Row } from "antd";

export default function DrawerFormSize({
  view,
  unique_id,
  dynamicSizeList,
  updateSizeQuantity,
  commaSeparateNumber,
}) {
  return (
    <div className="mt-4 size-div-scroll">
      <Row gutter={[8, 8]}>
        {dynamicSizeList?.map((size, index) => {
          return (
            <Col
              className="gutter-row"
              span={8}
              key={size?.label + "_" + size?.value}
            >
              <Form.Item
                label={size?.label}
                rules={[
                  {
                    required: true,
                    message: `Please Input Size of ${size?.label}!`,
                  },
                ]}
                name={size?.label + "_" + size?.value}
                style={{ marginBottom: "unset" }}
                initialValue={0}
              >
                <InputNumber
                  disabled={view}
                  index={index}
                  style={{ width: "100%" }}
                  precision={2}
                  size="small"
                  formatter={(value) => commaSeparateNumber(value)}
                  onChange={(value) =>
                    updateSizeQuantity(value, unique_id, size)
                  }
                />
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
