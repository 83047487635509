import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  Button,
  Space,
  Upload,
  message,
  Table,
  Row,
  Col,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  InputNumber,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../CommonComponents/ExtraContent";
import FilterComponent from "../CommonComponents/FilterComponent";
import {
  getData,
  postData,
  deleteData,
  putData,
} from "../../../apiServices/common";
import {
  UNIT_LIST_ENDPOINT,
  UNIT_CONVERTION_ADD_ENDPOINT,
  UNIT_CONVERTION_LIST_ENDPOINT,
  UNIT_CONVERTION_EDIT_ENDPOINT,
  UNIT_CONVERTION_DELETE_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../utils/functions";
import {
  UNIT_CREATE_PERMISSION,
  UNIT_EDIT_PERMISSION,
  UNIT_DELETE_PERMISSION,
} from "../../../routes/permissions";
import { useLocation } from "react-router";

const UnitConvertionConfig = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [unitList, setUnitList] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Antd
  const [unitConvertionForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllConvertion = useCallback(
    async (filterValues) => {
      const { convert_from, convert_to } = filterValues || {};

      setLoading(true);

      const query = `${UNIT_CONVERTION_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...(convert_from && { convert_from: convert_from }),
        ...(convert_to && { convert_to: convert_to }),
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting unit convertion data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllConvertion(filterData);
    getAllUnits();
  }, [refresh, filterData, getAllConvertion]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    unitConvertionForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const getAllUnits = async (filterValues) => {
    setLoading(true);
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...(filterValues?.name && { name: filterValues?.name }),
    };

    const response = await getData(UNIT_LIST_ENDPOINT, false, bodyData);
    if (response?.status === 200) {
      setLoading(false);
      setUnitList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting unit list data");
    }
  };

  const deleteSelectedConvertion = async (id) => {
    setLoading(true);
    const response = await deleteData(
      `${UNIT_CONVERTION_DELETE_ENDPOINT}/${id}`,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Convertion Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedConvertion = async (formValues) => {
    setLoading(true);

    const bodyData = {
      convert_from: formValues?.convert_from,
      convert_rate: formValues?.convert_rate,
      convert_to: formValues?.convert_to,
      status: formValues?.status ? 1 : 0,
    };

    const response = await putData(
      `${UNIT_CONVERTION_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Unit Convertion Edited Successfully");
      refetch();
      unitConvertionForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewConvertion = async (formValues) => {
    setLoading(true);

    const bodyData = {
      convert_from: formValues?.convert_from,
      convert_rate: formValues?.convert_rate,
      convert_to: formValues?.convert_to,
      status: formValues?.status ? 1 : 0,
    };

    const response = await postData(UNIT_CONVERTION_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Convertion Created Successfully");
      refetch();
      unitConvertionForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    // Push unit info to the unit array if not exists
    const convert_from_unit_info = {
      id: Number(record?.convert_from?.id),
      name: record?.convert_from?.name,
      status: record?.convert_from?.status,
    };

    const convert_to_unit_info = {
      id: Number(record?.convert_to?.id),
      name: record?.convert_to?.name,
      status: record?.convert_to?.status,
    };

    const isConvertFromUnitExists = unitList?.find((item) => {
      return item.id === convert_from_unit_info.id;
    });

    const isConvertToUnitExists = unitList?.find((item) => {
      return item.id === convert_to_unit_info.id;
    });

    const unitListCopy = [...unitList];

    // If not exists, push the unit to the unit list
    if (!isConvertFromUnitExists || !isConvertToUnitExists) {
      if (!isConvertFromUnitExists) {
        unitListCopy.push(convert_from_unit_info);
      }

      if (!isConvertToUnitExists) {
        unitListCopy.push(convert_to_unit_info);
      }
      setUnitList(unitListCopy);
    }

    unitConvertionForm.setFieldsValue({
      convert_from: record?.convert_from?.id,
      convert_rate: record?.convert_rate,
      convert_to: record?.convert_to?.id,
      status: record?.status === "1" || record?.status === 1 ? true : false,
      id: record?.id,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Convert From",
      dataIndex: ["convert_from", "name"],
      key: "convert_from",
    },
    {
      title: "Convert To",
      dataIndex: ["convert_to", "name"],
      key: "convert_to",
    },
    {
      title: "Convert Rate",
      dataIndex: ["convert_rate"],
      key: "convert_rate",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "convertion_status",
      width: "10%",
      render: (_, record) => {
        return (
          <div>
            {record?.status === "1" || record?.status === 1
              ? "Active"
              : "Inactive"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedConvertion(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([UNIT_DELETE_PERMISSION]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([UNIT_EDIT_PERMISSION]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([UNIT_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([UNIT_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Unit Convertion</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllConvertion}
              filterName="unit_convertion"
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Convertion" : "Create Convertion"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={unitConvertionForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedConvertion : createNewConvertion}
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="convert_from"
                placeholder="Convert From"
                label="Convert From"
                rules={[
                  {
                    required: true,
                    message: "Convert From Unit Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Unit"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  style={{ width: "100%" }}
                  onSearch={(value) => getAllUnits({ name: value })}
                  onSelect={(value) => getAllUnits()}
                >
                  {unitList?.map((item, index) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="convert_to"
                placeholder="Convert To"
                label="Convert To"
                rules={[
                  {
                    required: true,
                    message: "Convert To Unit Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Unit"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  style={{ width: "100%" }}
                  onSearch={(value) => getAllUnits({ name: value })}
                  onSelect={(value) => getAllUnits()}
                >
                  {unitList?.map((item, index) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item
                name="convert_rate"
                placeholder="Convert Rate"
                label="Convert Rate"
                rules={[
                  {
                    required: true,
                    message: "Convert Rate Required",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} precision={10} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="status"
                placeholder="Status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Status Required",
                  },
                ]}
                valuePropName="checked"
                initialValue={true}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UnitConvertionConfig;
