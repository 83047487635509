import { Button, Card, Col, DatePicker, Form, Row, Select } from "antd";
import { cardStyle } from ".";
import dayjs from "dayjs";
import CustomDatePicker from "common/CustomDatePicker";

export default function Filter({ setDate }) {
  return (
    <Card style={cardStyle}>
      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Timeline (Date Range)" name="timeline">
              <CustomDatePicker
                placeholder={"Select date range"}
                style={{
                  width: "100%",
                }}
                displayFormat="MMM-YYYY"
                picker="month"
                onChange={(_date, dateString) => {
                  setDate(dateString);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Production" name="production">
              <Select placeholder="Select name">
                <Select.Option value="1">Production 1</Select.Option>
                <Select.Option value="2">Production 2</Select.Option>
                <Select.Option value="3">Production 3</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Button
                type="primary"
                style={{
                  borderRadius: "5px",
                  marginRight: "5px",
                }}
              >
                Filter
              </Button>
              <Button
                style={{
                  borderRadius: "5px",
                }}
              >
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
