import { Table, Typography, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import {
  SALES_CONTRACT_PO_LIST,
  EXPORT_CONTRACT_PO_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import { VIEW_ALL_ACTIVE_POS_PERMISSION } from "../../../routes/permissions";
import { cglFormatDate, hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const SalesContractPOS = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const exportSalesContractPOS = useCallback(async (filterValues) => {
    const { start_date, end_date, ...rest } = filterValues;
    const bodyData = {
      ...(start_date && {
        start: moment(start_date).format("YYYY-MM-DD"),
      }),
      ...(end_date && { end: moment(end_date).format("YYYY-MM-DD") }),
      ...rest,
    };

    setLoading(true);

    const query = `${EXPORT_CONTRACT_PO_LIST}`;

    const response = await getData(
      query,
      false,
      bodyData,
      { "Content-Type": "blob" },
      "arraybuffer",
    );

    if (response && response?.data) {
      // Download the file
      const outputFilename = `sales_contract_pos_list.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setLoading(false);
      message.success("Sales contract POS report Exported Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  }, []);
  const previewFunction = async (filterValues) => {
    setLoading(true);

    const bodyData = {
      ...filterValues,
    };

    const query = `${SALES_CONTRACT_PO_LIST}`;
    const response = await postData(query, bodyData);

    if (response?.code === 200) {
      setLoading(false);
      setTableData(response?.data);
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    previewFunction();
  }, []);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const tableColumns = [
    {
      title: "Sales Contract POS",
      children: [
        {
          title: "Internal PO",
          dataIndex: "internal_po",
          key: "internal_po",
          width: 200,
        },
        {
          title: "Original PO",
          dataIndex: "original_po",
          key: "original_po",
          width: 200,
        },

        {
          title: "Reference No",
          dataIndex: "reference_no",
          key: "reference_no",
          width: 200,
        },
        {
          title: "Delivery Date",
          dataIndex: "delivery_date",
          key: "delivery_date",
          width: 200,
          render: (row) => cglFormatDate(row),
        },
        {
          title: "Contract Date",
          dataIndex: "contract_date",
          key: "contract_date",
          width: 200,
          render: (row) => cglFormatDate(row),
        },
        {
          title: "Expiry Date",
          dataIndex: "expiry_date",
          key: "expiry_date",
          width: 200,
          render: (row) => cglFormatDate(row),
        },

        {
          title: "PO Status",
          dataIndex: "po_status",
          key: "po_status",
          width: 200,
        },
      ],
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={tableData}
        filterAPI={exportSalesContractPOS}
        filterName={"sales_contract_pos"}
        searchButtonText={"Export"}
        defaultOpen
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        isPreview={true}
        previewFunction={previewFunction}
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportSalesContractPOS}
                hideAddButton={true}
                disabled={!hasPermission([VIEW_ALL_ACTIVE_POS_PERMISSION])}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            }
            title={<Title level={5}>Sales Contract POS List</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              {memoizedCustomFilter}
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination
                bordered
                scroll={{ x: true }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default SalesContractPOS;
