import { Col, Form, Row, Select, Input } from "antd";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  GET_BRAND_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";

const StyleFilterFields = ({ initialTableData }) => {
  // States
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  // Others
  const { Option } = Select;

  useEffect(() => {
    getBuyingOfficeList();
    getBuyerList();
    getBrandList();
  }, []);

  const getBuyingOfficeList = async (filterValues) => {
    const query = `${GET_BUYING_OFFICE_ENDPOINT}`;
    const bodyData = {
      "per_page": 10,
      "name": filterValues?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBuyingOffice(res?.data?.data?.data || []);
    }
  };

  const getBuyerList = async (filterValues) => {
    const query = `${GET_BUYER_ENDPOINT}`;
    const bodyData = {
      "per_page": 10,
      "name": filterValues?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBuyerList(res?.data?.data?.data || []);
    }
  };

  const getBrandList = async (filterValues) => {
    const query = `${GET_BRAND_ENDPOINT}`;
    const bodyData = {
      "per_page": 10,
      "name": filterValues?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBrandList(res?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item label="Buying Office" name="buying_office_id">
          <Select
            showSearch
            placeholder="Select a Buying Office"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getBuyingOfficeList({ name: value })}
            allowClear
            onClear={() => getBuyingOfficeList()}
          >
            {buyingOffice?.length &&
              buyingOffice.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            placeholder="Select a Buyer"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getBuyerList({ name: value })}
            allowClear
            onClear={() => getBuyerList()}
          >
            {buyerList?.length &&
              buyerList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Brand" name="brand_id">
          <Select
            showSearch
            placeholder="Select a Brand"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getBrandList({ name: value })}
            allowClear
            onClear={() => getBrandList()}
          >
            {brandList?.length &&
              brandList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Style Number" name="style_no">
          <Input placeholder="Write Style Number" size="small"/>
        </Form.Item>
      </Col>
    </>
  );
};

export default StyleFilterFields;
