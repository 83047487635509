import { Button, InputNumber, Select, Table, Typography, message } from "antd";
import { getData, postData, putData } from "apiServices/common";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { useEffect, useMemo, useState } from "react";
import { INITIAL_SAM_STORE_PERMISSION } from "routes/permissions";
import { commaSeparateNumber, hasPermission } from "utils/functions";

const { Option } = Select;

const InitialSAMEntry = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [metaData, setMetaData] = useState({
    par_page: 10,
    total: 0,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    style_id: null,
    buying_office_id: null,
    buyer_id: null,
    sub_contract_factory_id: null,
  });

  const convertData = (originData) => {
    return originData.map((item) => ({
      key: item.id, // Unique key for table rows
      id: item.id,
      subcontract_status: item.subcontract_status,
      buyer: item.buyer_info?.name,
      buyer_id: item.buyer_id, // Include buyer_id for payload
      buying_office_factory: item.subcontract_status
        ? item.factory_info?.name
        : item.buying_office_info?.name, // Display factory name if subcontracted else buying office name
      buying_office_id: item.buying_office_id, // Include buying_office_id for payload
      style_no: item.style_inquiry_map?.style_info?.style_no,
      style_id: item.style_inquiry_map?.style_id, // Include style_id for payload
      order_qty: item.expected_order_quantity,
      total_sam: item.initial_sam?.total_sam || null,
      no_of_workers: item.initial_sam?.no_of_workers || null,
      inquiry_id: item.style_inquiry_map?.inquiry_id,
      sub_style_id: null,
      sub_contract_factory_id: item.sub_contract_factory_id,
      sales_contract_id: item.sales_contract_id || null,
      order_quantity: item.expected_order_quantity,
      initial_sam_id: item.initial_sam?.id || null,
      created_for:
        item.initial_sam?.operation_bulletin?.created_for || "Initial Sam",
    }));
  };

  // Fetch data based on filters
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getData("/api/initial-sams", null, {
        ...filters,
        page: currentPage,
      });
      setMetaData({
        ...metaData,
        total: res.data?.data?.total,
      });
      const formattedData = convertData(res.data?.data?.data || []);
      setData(formattedData || []);
    } catch (error) {
      setData([]);
      message.error("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters, currentPage, refresh]);

  // Handle save
  const handleSave = async (record) => {
    if (!record.total_sam && !record.no_of_workers) {
      message.error("Please fill all fields");
      return;
    }
    const payload = {
      inquiry_id: record.inquiry_id,
      style_id: record.style_id,
      sub_style_id: record.sub_style_id,
      subcontract_status: record.subcontract_status,
      buyer_id: record.buyer_id,
      buying_office_id: record.buying_office_id,
      sub_contract_factory_id: record.sub_contract_factory_id,
      sales_contract_id: record.sales_contract_id,
      total_sam: record.total_sam,
      no_of_workers: record.no_of_workers,
      order_quantity: record.order_quantity,
    };

    if (record.initial_sam_id) {
      // Update existing record
      await putData(
        `/api/initial-sam/update/${record.initial_sam_id}`,
        payload,
      );
    } else {
      // Create new record
      await postData("/api/initial-sam/store", payload);
    }
    // Refresh data
    setRefresh((prev) => prev + 1);
  };

  // Table columns
  const columns = [
    {
      title: "Buying Office / Factory",
      dataIndex: "buying_office_factory",
      key: "buying_office_factory",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
    },
    {
      title: "Style No",
      dataIndex: "style_no",
      key: "style_no",
    },
    {
      title: "Order Qty",
      dataIndex: "order_qty",
      key: "order_qty",
      render: (text) => commaSeparateNumber(text),
      align: "right",
    },
    {
      title: "SAM",
      dataIndex: "total_sam",
      key: "total_sam",

      render: (text, record) => (
        <InputNumber
          disabled={record?.created_for !== "Initial Sam"}
          style={{ width: "100%" }}
          value={text}
          placeholder="SAM"
          onChange={(value) =>
            setData((prev) =>
              prev.map((item) =>
                item.key === record.key ? { ...item, total_sam: value } : item,
              ),
            )
          }
        />
      ),
    },
    {
      title: "MP",
      dataIndex: "no_of_workers",
      key: "no_of_workers",
      render: (text, record) => (
        <InputNumber
          disabled={record?.created_for !== "Initial Sam"}
          placeholder="MP"
          style={{ width: "100%" }}
          value={text}
          onChange={(value) =>
            setData((prev) =>
              prev.map((item) =>
                item.key === record.key
                  ? { ...item, no_of_workers: value }
                  : item,
              ),
            )
          }
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => handleSave(record)}
          size="small"
          disabled={
            !hasPermission([INITIAL_SAM_STORE_PERMISSION]) ||
            record?.created_for !== "Initial Sam"
          }
        >
          {record.initial_sam_id ? "Update" : "Add"}
        </Button>
      ),
    },
  ];

  const getFilterValue = (filterValues) => {
    setFilters(filterValues);
  };

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={data}
        filterAPI={getFilterValue}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        filterName="initial_sam_entry"
      />
    ),
    [showFilter],
  );

  return (
    <AppContent
      header={
        <AppPageHeader
          extra={
            <ExtraContent
              refresh={refresh}
              setRefresh={setRefresh}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              hideAddButton
            />
          }
          title={
            <Typography.Title level={5}>Initial SAM Entry</Typography.Title>
          }
        />
      }
      content={
        <>
          {memoizedCustomFilter}

          <div className="table" style={{ marginTop: 10 }}>
            <Table
              columns={columns}
              dataSource={data}
              loading={loading}
              rowKey="key"
              pagination={{
                current: currentPage,
                onChange: (page) => setCurrentPage(page),
                pageSize: 10,
                total: metaData.total,
                showTotal: (total) => `Total ${total} items`,
                showSizeChanger: false,
              }}
              bordered
            />
          </div>
        </>
      }
    />
  );
};

export default InitialSAMEntry;
