import { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const ERPUsageModuleFilterFields = ({ initialTableData }) => {
  // States

  // Antd
  const { Option } = Select;

  

  return (
    <>
      <Col span={4}>
        <Form.Item label="Title" name="module_name">
          <Input placehoder="Please enter Title" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Status" name="status">
          <Select
            showSearch
            placeholder="Select Status"
            optionFilterProp="children"
            size="small"
          >
            <Option value={1}>Active</Option>
            <Option value={0}>Inactive</Option>
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default ERPUsageModuleFilterFields;
