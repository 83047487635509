import { Alert, Card, Empty, Form, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { postData } from "../../../apiServices/common";
import PlanVsActualTable from "./PlanVsActualTable";
import ProductionSummaryForm from "./ProductionSummaryForm";

const resposeData = {
  code: 200,
  status: "success",
  message: "Data retrieved Successfully",
  data: {
    id: 20,
    name: "Line-1",
    no_of_workers: 1800,
    no_of_machines: 26.5,
    no_of_operations: 14,
    line_chiefs: [
      {
        id: 3848,
        name: "Md. Rafiqul Islam",
        phone: "01934-909507",
        email: "line1sc@cgl.com",
        email_verified_at: null,
        user_type: "Line Chief",
        user_status: "Active",
        created_by: "Admin",
        updated_by: "Admin",
        created_at: "2024-06-11T10:57:25.000000Z",
        updated_at: "2024-06-11T10:57:25.000000Z",
        role_id: 29,
        line_id: 20,
        deleted_at: null,
        department_id: 0,
        profile_image:
          "http://localhost:4016/images/checklist_question_placeholder.jpg",
      },
    ],
    supervisors: [
      {
        id: 3846,
        name: "Aklima",
        phone: "01952-078358",
        email: "01952078358@cgl.com",
        email_verified_at: null,
        user_type: "Supervisor",
        user_status: "Active",
        created_by: "Admin",
        updated_by: "Admin",
        created_at: "2024-06-11T10:57:25.000000Z",
        updated_at: "2024-07-30T10:39:51.000000Z",
        role_id: 25,
        line_id: 20,
        deleted_at: null,
        department_id: 0,
        profile_image:
          "http://localhost:4016/images/checklist_question_placeholder.jpg",
        additional_info: {
          id: 2783,
          user_id: 3846,
          emp_id: "50001102",
          designation_name: "Supervisor",
        },
      },
      {
        id: 3847,
        name: "Md. Faruk",
        phone: "01723-371160",
        email: "01723371160@cgl.com",
        email_verified_at: null,
        user_type: "Supervisor",
        user_status: "Active",
        created_by: "Admin",
        updated_by: "Admin",
        created_at: "2024-06-11T10:57:25.000000Z",
        updated_at: "2024-07-30T10:39:51.000000Z",
        role_id: 25,
        line_id: 20,
        deleted_at: null,
        department_id: 0,
        profile_image:
          "http://localhost:4016/images/checklist_question_placeholder.jpg",
        additional_info: {
          id: 2784,
          user_id: 3847,
          emp_id: "3505024",
          designation_name: "Supervisor",
        },
      },
      {
        id: 4892,
        name: "Shabuj Sarker",
        phone: "01639-203429",
        email: "line1q1@cgl.com",
        email_verified_at: null,
        user_type: "Supervisor",
        user_status: "Active",
        created_by: "Admin",
        updated_by: "Admin",
        created_at: "2024-06-11T10:57:29.000000Z",
        updated_at: "2024-06-11T10:57:29.000000Z",
        role_id: 25,
        line_id: 20,
        deleted_at: null,
        department_id: 0,
        profile_image:
          "http://localhost:4016/images/checklist_question_placeholder.jpg",
        additional_info: {
          id: 4329,
          user_id: 4892,
          emp_id: "Q12208",
          designation_name: "Q.ins",
        },
      },
    ],
    plan_vs_actual: [
      {
        buyer: {
          id: 11,
          name: "Unlimited Clothing Limited",
          short_name: "Unlimited Clothing",
        },
        style: {
          id: 692,
          style_no: "fishing shirt",
        },
        sam: 34.4,
        efficiency: 45.2,
        production_plan: 200,
        production_actual: "6",
        production_achive: 100,
        effeciency_plan: 45.2,
        effeciency_actual: 45.425999999999995,
        effeciency_achive: 99.50248756218907,
        rejected_production: "0",
      },
      {
        buyer: {
          id: 17,
          name: "Mango",
          short_name: "Mango",
        },
        style: {
          id: 711,
          style_no: "testinitiate1234",
        },
        sam: 36.5,
        efficiency: 68.8,
        production_plan: 200,
        production_actual: null,
        production_achive: 100,
        effeciency_plan: 68.8,
        effeciency_actual: 69.14399999999999,
        effeciency_achive: 99.50248756218907,
        rejected_production: 0,
      },
    ],
  },
};

const ProductionSummary = ({ line_id, currentSlide, slideId }) => {
  // States
  const [summaryData, setSummaryData] = useState(resposeData?.data);

  // Antd
  const [form] = Form.useForm();

  useEffect(() => {
    if (summaryData) {
      form.setFieldsValue({
        date_time: moment().format("DD-MMM-YYYY   hh:mm A"),
        no_of_workers: summaryData?.no_of_workers,
        line_name: summaryData?.name,
        no_of_machines: summaryData?.no_of_machines,
        no_of_operation: summaryData?.no_of_operations,
        line_chief: summaryData?.line_chiefs
          ?.map((item) => item?.name)
          .join(", "),
      });
    }
  }, [summaryData, form]);

  const getSummaryData = async (filterValues) => {
    const response = await postData(
      `/api/digitalization/bigscreen-dashboard/productionSummaryReport`,
      filterValues,
    );

    if (response && response?.code === 200) {
      setSummaryData(response?.data);
    }
  };

  useEffect(() => {
    if (currentSlide === slideId && line_id) {
      getSummaryData({
        line_id: line_id,
      });
    }
  }, [line_id, currentSlide, slideId]);

  return (
    <>
      <Card
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {Object.keys(summaryData).length !== 0 ? (
          <>
            <Typography.Title level={4}>Production Summary</Typography.Title>
            <ProductionSummaryForm form={form} summaryData={summaryData} />
            <PlanVsActualTable summaryData={summaryData} />
          </>
        ) : (
          <Empty
            style={{
              width: "33%",
              margin: "auto",
              marginTop: "10%",
              marginBottom: "10%",
              height: "100%",
            }}
            description={
              <Alert
                message="No Data Found with selected line-id in Production Summary"
                type="warning"
              />
            }
          />
        )}
      </Card>
    </>
  );
};

export default ProductionSummary;
