import { Row, Col, Card, Typography } from "antd";
import BookmarkList from "./BookmarkList";
import RecentlyVisited from "./RecentlyVisited";
import AdvancedSearch from "./AdvancedSearch";

const DashboardContent = () => {
  // Antd
  const { Title } = Typography;

  // Styles
  const colStyle = {
    paddingLeft: 10,
    paddingRight: 10,
    borderRight: "1px solid lightgrey",
  };

  return (
    <Row gutter={6} style={{padding: 5}}>
      <Col span={8} style={colStyle}>
        <RecentlyVisited />
      </Col>
      <Col span={8} style={colStyle}>
        <BookmarkList />
      </Col>
      <Col span={8}>
        <AdvancedSearch />
      </Col>
    </Row>
  );
};

export default DashboardContent;
