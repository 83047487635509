import {
  Card,
  Col,
  InputNumber,
  message,
  Row,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Space } from "antd";
import "./index.css";
import { getData, postData } from "apiServices/common";
export default function HourPage() {
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    try {
      const body = {
        details: values.details.map((item) => ({
          hour: item.hour,
          from_time: dayjs(item.from_time).format("HH:mm"),
          to_time: dayjs(item.to_time).format("HH:mm"),
        })),
      };
      const query = `/api/digitalization/hour-configurations`;
      const res = await postData(`${query}`, body);
      form.resetFields();
      message.success(res.message || "Hour Updated Successfully");
      await init();
    } catch (error) {
      console.log(error);
      message.error(error.message || "Failed to update Hour");
      await init();
    }
  };

  const init = async () => {
    try {
      const query = `/api/digitalization/hour-configurations`;
      const res = await getData(`${query}`);
      if (res.data.data.length !== 0) {
        form.setFieldsValue({
          details: res.data.data.map((item) => ({
            hour: item.hour,
            from_time: dayjs(`2024-01-01T${item.from_time}`),
            to_time: dayjs(`2024-01-01T${item.to_time}`),
          })),
        });
      } else {
        form.setFieldsValue({
          details: [
            {
              hour: null,
              from_time: "",
              to_time: "",
            },
          ],
        });
      }
    } catch (error) {
      console.log(error);
      message.error(error.message || "Failed to fetch Hour");
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Card title="Hour" className="digitalization_hour">
      <Row>
        <Col span={24}>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              details: [
                {
                  hour: null,
                  from_time: "",
                  to_time: "",
                },
              ],
            }}
          >
            <Form.List
              name="details"
              style={{
                width: "100%",
              }}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, i) => (
                    <React.Fragment key={key}>
                      {i === 0 && (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                          align="baseline"
                        >
                          <Typography.Text strong>Hour</Typography.Text>
                          <Typography.Text strong>From Time</Typography.Text>
                          <Typography.Text strong style={{ marginLeft: -3 }}>
                            To Time
                          </Typography.Text>
                          <div style={{ width: 30 }}></div>
                        </Space>
                      )}
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          width: "100%",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "hour"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing hour",
                            },
                            {
                              type: "number",
                              min: 1,
                              max: 16,
                              message: "Hour must be between 1 and 16",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("details").filter(
                                    (item) => item.hour === value,
                                  ).length === 1
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Hour must be unique"),
                                );
                              },
                            }),
                          ]}
                          style={{ width: "100%" }}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            placeholder="Hour"
                            min={1}
                            max={16}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "from_time"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing from time",
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <TimePicker
                            style={{ width: "100%" }}
                            placeholder="From Time"
                            format={"HH:mm"}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "to_time"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing to time",
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <TimePicker
                            style={{ width: "100%" }}
                            placeholder="To Time"
                            format={"HH:mm"}
                          />
                        </Form.Item>
                        <Tooltip title="Remove">
                          <MinusCircleOutlined
                            style={{
                              color: "red",
                            }}
                            onClick={() => remove(name)}
                          />
                        </Tooltip>
                        <Tooltip title="Add">
                          <PlusCircleOutlined
                            style={{
                              color: "#1890ff",
                              visibility:
                                fields.length - 1 === i ? "visible" : "hidden",
                            }}
                            onClick={() => add()}
                          />
                        </Tooltip>
                      </Space>
                    </React.Fragment>
                  ))}
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}
