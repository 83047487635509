import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../../apiServices/common";
import {
    ERP_USAGE_MODULE_UI_LIST_ENDPOINT,
    ERP_USAGE_MODULE_UI_ADD_ENDPOINT,
    ERP_USAGE_MODULE_UI_EDIT_ENDPOINT,
    ERP_USAGE_MODULE_UI_DELETE_ENDPOINT,
    ERP_USAGE_MODULE_LIST_ENDPOINT,
    GET_USERS_ENDPOINT,
    POST_TNA_DEPARTMENT_DATA,
} from "../../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../utils/functions";
import { 
    ERP_USAGE_MODULE_UI_CREATE_PERMISSION,
    // ERP_USAGE_MODULE_UI_EDIT_PERMISSION,
    ERP_USAGE_MODULE_UI_DELETE_PERMISSION,
} from '../../../routes/permissions';
import { useLocation } from "react-router";

const ERPUsageModuleUi = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ erpModuleList, setErpModuleList ] = useState();
  const [ erpModuleActiveList, setErpModuleActiveList ] = useState();
  const [ userList, setUserList ] = useState();
  const [ tnaDepartmentList, setTnaDepartmentList ] = useState();

  // Antd
  const [erpUsageModuleUiForm] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllERPUsageModuleUi = useCallback(
    async (filterValues) => {
      const { name, module_id, user_id, tna_department_id, status} = filterValues || {};

      console.log("filterValuesRR", filterValues)

      setLoading(true);

      const query = `${ERP_USAGE_MODULE_UI_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...filterValues
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        console.log("UI Data", response?.data?.data?.data);
        const userType = localStorage?.getItem("user_type");
        const userId = parseInt(localStorage?.getItem("user_id"), 10); // Ensure user_id is parsed as a number

        if (userType === "Super Admin" || userType === "Admin") {
          // For Super Admin or Admin, show the full data
          setTableData(response?.data?.data?.data);
        } else {
          // For other user types, filter by user_id
          const filteredData = response?.data?.data?.data?.filter(
            (item) => item.user_id === userId
          );
          setTableData(filteredData);
        }
   
      } else {
        setLoading(false);
        message.error("Error getting ERP Usage Module UI data");
      }
    },
    [currentPage],
  );

  const getAllErpUsageModules = async (filterValues) => {
    setLoading(true);

    const query = `${ERP_USAGE_MODULE_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("erpUsageModules", response?.data?.data?.data);
      setErpModuleList(response?.data?.data?.data);
      // Filter for modules where status is 1
      const filteredData = response.data.data.data.filter((item) => item.status === 1);
      // console.log("Filtered ERP Usage Modules:", filteredData);
      setErpModuleActiveList(filteredData); 
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  const getAllUsers = async (filterValues) => {
    setLoading(true);

    const query = `${GET_USERS_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("All Users", response?.data?.data?.data);
      setUserList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  const getAllTnaDepartments = async (filterValues) => {
    setLoading(true);

    const query = `${POST_TNA_DEPARTMENT_DATA}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filterederpModuleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("tna_departments", response?.data?.data?.data);
      setTnaDepartmentList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  // Effects
  useEffect(() => {
    getAllERPUsageModuleUi(filterData);
    getAllErpUsageModules();
    getAllUsers();
    getAllTnaDepartments();
  }, [refresh, filterData, getAllERPUsageModuleUi]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    erpUsageModuleUiForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectederpUsageModuleUi = async (id) => {
    setLoading(true);
    const response = await deleteData(`${ERP_USAGE_MODULE_UI_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("ERP Usage Module UI Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectederpUsageModuleUi = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      module_id: formValues?.module_id,
      user_id: formValues?.user_id,
      tna_department_id: formValues?.tna_department_id,
      status: formValues?.status ? formValues?.status : "Created",
    };

    const response = await putData(
      `${ERP_USAGE_MODULE_UI_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("ERP Usage Module UI Edited Successfully");
      refetch();
      erpUsageModuleUiForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewerpUsageModuleUi = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      module_id: formValues?.module_id,
      user_id: formValues?.user_id,
      tna_department_id: formValues?.tna_department_id,
      status: "Created",
    };

    const response = await postData(ERP_USAGE_MODULE_UI_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("ERP Usage Module UI Created Successfully");
      refetch();
      erpUsageModuleUiForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    erpUsageModuleUiForm.setFieldsValue({
      id: record?.id,
      name: record?.name,
      module_id: record?.module_id,
      user_id: record?.user_id,
      tna_department_id: record?.tna_department_id,
      status: record?.status,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Title",
      dataIndex: ["name"],
      key: "name",
    },
    {
      title: "Module",
      dataIndex: ["module_info", "module_name"],
      key: "module_id",
    },
    (localStorage.getItem('user_type') === 'Vendor' || localStorage.getItem('user_type') === 'Admin') && {
      title: "POC",
      dataIndex: ["poc_info", "name"],
      key: "user_id",
    },
    (localStorage.getItem('user_type') === 'Vendor' || localStorage.getItem('user_type') === 'Admin') && 
    {
      title: "Department",
      dataIndex: ["tna_department_info", "title"],
      key: "tna_department_id",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectederpUsageModuleUi(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([ERP_USAGE_MODULE_UI_DELETE_PERMISSION]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    // disabled: !hasPermission([ERP_USAGE_MODULE_UI_EDIT_PERMISSION]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ].filter(Boolean);

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([ERP_USAGE_MODULE_UI_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([ERP_USAGE_MODULE_UI_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>ERP Usage Module UIs</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllERPUsageModuleUi}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="erp_usage_module_ui"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit ERP Usage Module UI" : "Create ERP Usage Module UI"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={erpUsageModuleUiForm}
          layout="vertical"
          onFinish={isEdit ? editSelectederpUsageModuleUi : createNewerpUsageModuleUi}
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="name"
                placeholder="Name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name Required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                name="module_id"
                placeholder="Select Module"
                label="Select Module"
                rules={[
                  {
                    required: true,
                    message: "Module is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Module"
                  showSearch
                  optionFilterProp="children"
                  style={{width: "100%"}}
                  onSearch={(value) => getAllErpUsageModules({module_id: value})}
                >
                  {erpModuleActiveList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item?.module_name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                name="user_id"
                placeholder="Select POC"
                label="Select POC"
                rules={[
                  {
                    required: true,
                    message: "POC is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select POC"
                  showSearch
                  optionFilterProp="children"
                  style={{width: "100%"}}
                  onSearch={(value) => getAllUsers({name: value})}
                >
                  {userList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                name="tna_department_id"
                placeholder="Select Department"
                label="Select Department"
                rules={[
                  {
                    required: true,
                    message: "Department is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Department"
                  showSearch
                  optionFilterProp="children"
                  style={{width: "100%"}}
                  onSearch={(value) => getAllTnaDepartments({title: value})}
                >
                  {tnaDepartmentList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item?.title}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {(localStorage.getItem('user_type') !== 'Admin') && (
            <>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  name="status"
                  placeholder="Select Status"
                  label="Select Status"
                  rules={[
                    {
                      required: true,
                      message: "Status is Required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Status"
                    showSearch
                    optionFilterProp="children"
                    style={{width: "100%"}}
                  >
                    {/* <Option value="Created">Created</Option> */}
                    <Option value="Halt">Halt</Option>
                    <Option value="In Use">In Use</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            </>
          )}
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ERPUsageModuleUi;
