import { useCallback, useEffect, useState } from "react";
import { Dropdown, Menu, Table, message } from "antd";
import { useLocation } from "react-router";
import { DIGITALIZATION_ROLES_CHECKLIST } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { DIGITALIZATION_CHECKLIST_VIEW_PERMISSION, DIGITALIZATION_ROLE_CHECK_PREMISSION } from "routes/permissions";
import { cglFormatDate, hasPermission, isArrayAndHasValue } from "utils/functions";
import moment from "moment";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const BuyerWiseChecklist = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllData = useCallback(
    async (filterValues) => {
      setLoading(true);

      const query = `${DIGITALIZATION_ROLES_CHECKLIST}`;
      const bodyData = {
        page: filterValues?.page || currentPage,
        ...filterValues,
      };

      const response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting cutting data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllData({ type: "buyer_wise" });
  }, [refresh, getAllData]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const menu = (id) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/digitalization/buyer-wise-checklist/view/${id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([DIGITALIZATION_CHECKLIST_VIEW_PERMISSION]),
        },
      ]}
    />
  );

  const tableColumns = [
    {
      title: "Role",
      dataIndex: ["title"],
      key: "role",
    },
    {
      title: "Buyer",
      key: "buyer",
      render: (value, record) => {
        const buyers =
          isArrayAndHasValue(record?.details) &&
          record?.details?.map((item) => item?.buyer?.name);
        return (
          <div>
            {isArrayAndHasValue(buyers) &&
              buyers?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item}</td>
                  </tr>
                );
              })}
          </div>
        );
      },
    },
    {
      title: "Style",
      key: "style",
      render: (value, record) => {
        const styles =
          isArrayAndHasValue(record?.details) &&
          record?.details?.map((item) => item?.style?.style_no);
        return (
          <div>
            {isArrayAndHasValue(styles) &&
              styles?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item}</td>
                  </tr>
                );
              })}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (value, record) => {
        const statuses =
          isArrayAndHasValue(record?.details) &&
          record?.details?.map((item) => {
            return item?.status === 1 ? "No" : "Yes";
          });
        return (
          <div>
            {isArrayAndHasValue(statuses) &&
              statuses?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item}</td>
                  </tr>
                );
              })}
          </div>
        );
      },
    },
    {
      title: "Date",
      key: "created_at",
      render: (value, record) => {
        const dates =
          isArrayAndHasValue(record?.details) &&
          record?.details?.map((item) =>
            item?.created_at
              ? cglFormatDate(item?.created_at)
              : null,
          );

        return (
          <div>
            {isArrayAndHasValue(dates) &&
              dates?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item}</td>
                  </tr>
                );
              })}
          </div>
        );
      },
    },
    {
      title: "Submitted By",
      key: "submitted_by",
      render: (value, record) => {
        const users =
          isArrayAndHasValue(record?.details) &&
          record?.details?.map((item) => (item?.user ? item?.user?.name : ""));

        return (
          <div>
            {isArrayAndHasValue(users) &&
              users?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item}</td>
                  </tr>
                );
              })}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        const recordsId =
          isArrayAndHasValue(record?.details) &&
          record?.details?.map((item) => item?.id);
        return (
          <div>
            {isArrayAndHasValue(recordsId) &&
              recordsId?.map((item, index) => {
                return (
                  <tr key={index}>
                    <div className="cursore-pointer">
                      <Dropdown overlay={menu(item)}>
                        <MoreOutlined style={{ fontSize: "20px" }} />
                      </Dropdown>
                    </div>
                  </tr>
                );
              })}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                hideAddButton
                disabled={
                  !hasPermission([DIGITALIZATION_ROLE_CHECK_PREMISSION])
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title="Buyer Style Checklist"
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllData}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="buyer_style_checklist_filter"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
                sticky={true}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default BuyerWiseChecklist;
