import { Affix, Button, Card, Form } from "antd";
import Header from "components/Common/Header";
import { useNavigate } from "react-router";
import ChecklistQuestionsForm from "../ChecklistQuestionsForm";

export default function ChecklistQuestionsAdd() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  return (
    <div>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title="Add Checklist Questions"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() =>
                    navigate("/checklist/checklist-questions/list")
                  }
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => form?.submit()}
                >
                  Save
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <ChecklistQuestionsForm form={form} />
    </div>
  );
}
