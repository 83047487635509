import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
    DatePicker,
    TimePicker,
    InputNumber,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    SAMPLE_REQUISITIONS_LIST_ENDPOINT,
    SAMPLE_REQUISITIONS_ADD_ENDPOINT,
    SAMPLE_REQUISITIONS_EDIT_ENDPOINT,
    SAMPLE_REQUISITIONS_DELETE_ENDPOINT,
    GET_BUYER_ENDPOINT,
    BUYER_INQUIRY_LIST_ENDPOINT,
    GET_STYLE_LIST_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";
import { cglFormatDate } from "utils/functions";

const SampleRequisitions = () => {

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };
  
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ buyerList, setBuyerList ] = useState();
  const [ inquiryList, setInquiryList ] = useState();
  const [ styleList, setStyleList ] = useState();
  const [ inquiryStyle, setInquiryStyle ] = useState('Inquiry');

  // Antd
  const [requisition_dateForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // // Redux
  // const filterData = useSelector(({ filter }) => filter.filterData);

  const handleSelectChange = (selectedValue) => {
    const isInquiry = inquiryList.some(item => item.id === selectedValue);
    const isStyle = styleList.some(item => item.id === selectedValue);
    
    if (isInquiry) {
      setInquiryStyle("Inquiry");
    } else if (isStyle) {
      setInquiryStyle("Style");
    }
  };

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllSampleRequisitions = useCallback(
    async (filterValues) => {
      const payload = {
        page: filterValues?.page || currentPage,
        ...filterValues,
      }

      setLoading(true);

      const query = `${SAMPLE_REQUISITIONS_LIST_ENDPOINT}`;
      const bodyData = {
        ...payload,
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting Sample Requisition data");
      }
    },
    [currentPage],
  );

  const getAllBuyers = async (filterValues) => {
    setLoading(true);

    const query = `${GET_BUYER_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filteredStyleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("mamuuu", response?.data?.data?.data);
      setBuyerList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  console.log("Buyer List gulo dekhi", buyerList);


  const getAllInquiries = async (filterValues) => {
    setLoading(true);

    const query = `${BUYER_INQUIRY_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filteredStyleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("mamuuu", response?.data?.data?.data);
      setInquiryList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  console.log("Inquiry List gulo dekhi", inquiryList);

  const getAllStyles = async (filterValues) => {
    setLoading(true);

    const query = `${GET_STYLE_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filteredStyleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("mamuuu", response?.data?.data?.data);
      setStyleList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  console.log("Style List gulo dekhi", styleList);

  const handleSearch = async (value) => {
    setLoading(true); // Optional: set loading for both requests
  
    // Perform both requests simultaneously
    await Promise.all([getAllStyles({ name: value }), getAllInquiries({ name: value })]);
  
    setLoading(false); // Disable loading after both requests are done
  };

  

  // Effects
  useEffect(() => {
    getAllSampleRequisitions();
    getAllBuyers();
    getAllInquiries();
    getAllStyles();
  }, [refresh, getAllSampleRequisitions]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    requisition_dateForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedrequisition_date = async (id) => {
    setLoading(true);
    const response = await deleteData(`${SAMPLE_REQUISITIONS_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Sample Requisition Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedrequisition_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      requisition_date: moment(formValues?.requisition_date).format('YYYY-MM-DD'), // Format as 'Y-m-d'
      buyer_id: formValues?.buyer_id,
      inquiry_style_id: formValues?.inquiry_style_id,
      type: inquiryStyle,
      sample_type: formValues?.sample_type,
      quantity: formValues?.quantity,
      required_date: moment(formValues?.required_date).format('YYYY-MM-DD'), // Format as 'Y-m-d'
    };

    const response = await putData(
      `${SAMPLE_REQUISITIONS_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Sample Requisition Edited Successfully");
      refetch();
      requisition_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewrequisition_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      requisition_date: moment(formValues?.requisition_date).format('YYYY-MM-DD'), // Format as 'Y-m-d'
      buyer_id: formValues?.buyer_id,
      inquiry_style_id: formValues?.inquiry_style_id,
      type: inquiryStyle,
      sample_type: formValues?.sample_type,
      quantity: formValues?.quantity,
      required_date: moment(formValues?.required_date).format('YYYY-MM-DD'), // Format as 'Y-m-d'
    };

    const response = await postData(SAMPLE_REQUISITIONS_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Sample Requisition Created Successfully");
      refetch();
      requisition_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    console.log("recordsss", record);

    requisition_dateForm.setFieldsValue({
      id: record?.id,
      requisition_date: moment(record?.requisition_date, 'YYYY-MM-DD'), // Format as 'Y-m-d'
      buyer_id: record?.buyer_id,
      inquiry_style_id: record?.inquiry_style_id,
      type: record?.type,
      sample_type: record?.sample_type,
      quantity: record?.quantity,
      required_date: moment(record?.required_date, 'YYYY-MM-DD') // Format as 'Y-m-d'
    });
    // Trigger handleSelectChangeInquiryStyle to populate buyer and sales contract
    
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Requisition Date",
      dataIndex: ["requisition_date"],
      key: "requisition_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Buyer",
      dataIndex: ["buyer_info", "name"],
      key: "buyer_id",
    },
    {
      title: "Inquiry/Style",
      key: "inquiry_style_id",
      render: (_, record) => {
        const styleNo = record?.style_info?.style_no;
        const inquiryId = record?.inquiry_info?.inquiry_no;
  
        // Display both inquiry and style numbers
        return (
          <>
            {styleNo && inquiryId ? `${styleNo} / ${inquiryId}` : styleNo || inquiryId || "N/A"}
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: ["type"],
      key: "type",
    },
    {
      title: "Sample Type",
      dataIndex: ["sample_type"],
      key: "sample_type",
    },
    {
      title: "Quantity (pc)",
      dataIndex: ["quantity"],
      key: "quantity",
    },
    {
      title: "Required Date",
      dataIndex: ["required_date"],
      key: "required_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedrequisition_date(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                
                addButtonText="Add New"
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Sample Requisition</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllSampleRequisitions}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="requisition_date"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Sample Requisition" : "Create Sample Requisition"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={requisition_dateForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedrequisition_date : createNewrequisition_date}
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="requisition_date"
                placeholder="Requisition date"
                label="Requisition date"
                rules={[
                  {
                    required: true,
                    message: "Requisition date is Required",
                  },
                ]}
              >
                <CustomDatePicker style={{width: "100%"}} onChange={onDateChange} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="buyer_id"
                placeholder="Select a Buyer"
                label="Select a Buyer"
                rules={[
                  {
                    required: true,
                    message: "Buyer is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Buyer"
                  showSearch
                  style={{width: "100%"}}
                  onSearch={(value) => getAllBuyers({name: value})}
                >
                  {buyerList?.map((item) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="inquiry_style_id"
                placeholder="Select a Inquiry/Style"
                label="Select a Inquiry/Style"
                rules={[
                  {
                    required: true,
                    message: "Inquiry/Style is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Inquiry/Style"
                  showSearch
                  style={{width: "100%"}}
                  onSearch={(value) => handleSearch(value)} // updated to handle both calls
                  onChange={(value) => handleSelectChange(value)}
                >
                  {/* Inquiry Options */}
                  {inquiryList?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.inquiry_no}
                    </Option>
                  ))}

                  {/* Style Options */}
                  {styleList?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.style_no}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="sample_type"
                placeholder="Select a Sample Type"
                label="Select a Sample Type"
                rules={[
                  {
                    required: true,
                    message: "Sample Type is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Sample Type"
                  showSearch
                  style={{width: "100%"}}            
                >
                  <Option value="Sample">Sample</Option>
                  <Option value="Tech Pack">Tech Pack</Option>
                  <Option value="Sketch">Sketch</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="quantity"
                placeholder="Quantity (pc)"
                label="Quantity (pc)"
                rules={[
                  {
                    required: true,
                    message: "Quantity (pc) is Required",
                  },
                ]}
              >
                <InputNumber style={{width:'100%'}} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="required_date"
                placeholder="Required date"
                label="Required date"
                rules={[
                  {
                    required: true,
                    message: "Required date is Required",
                  },
                ]}
              >
                <CustomDatePicker size="medium" style={{width: "100%"}} onChange={onDateChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SampleRequisitions;
