import {
  CREATE_NEW_ITEM_CATEGORY,
  DELETE_ITEM_CATEGORY,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ITEM_CATEGORY_LIST,
  SHOW_MESSAGE,
  UPDATE_ITEM_CATEGORY_DETAIL,
} from '../ActionTypes'
import JwtAxios from "../../apiServices";
import {
    CREATE_CATEGORY_ENDPOINT,
    DELETE_CATEGORY_ENDPOINT, EDIT_CATEGORY_ENDPOINT,
    GET_CATEGORY_ENDPOINT
} from "../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../common/Notification";

export const onGetItemCategoryList = (props) => {
  const { page, per_page, name, category_unique_id, status } = props;

  return (dispatch) => {
    dispatch({type: FETCH_START});
    JwtAxios.get(`${GET_CATEGORY_ENDPOINT}`, {
        params: {
            page: page,
            per_page: per_page,
            ...(name && { name: name }),
            ...(category_unique_id && {
                category_unique_id: category_unique_id,
            }),
            ...(status && { status: status }),
        },
    })
        .then((data) => {

            if (data?.data?.code === 200) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: GET_ITEM_CATEGORY_LIST,
                    payload: data.data.data,
                });
            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: "Something went wrong!",
                });
            }
        })
        .catch((error) => {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            HandleNotification('error', 'bottomRight', 'Something went wrong!', null)

        });
  };
};



export const onCreateItemCategory = (itemCategory) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    JwtAxios
        .post(`${CREATE_CATEGORY_ENDPOINT}`, itemCategory)
        .then((data) => {
          if (data?.data?.code === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: CREATE_NEW_ITEM_CATEGORY, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: "Item Category Created Successfully",
            });
              HandleNotification('success', 'bottomRight', data?.data?.message, null)
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: "Something went wrong!",
            });
              HandleNotification('error', 'bottomRight', data?.data?.message, null)

          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
            HandleNotification('error', 'bottomRight', error?.message, null)
        });
  };
};
export const onUpdateSelectedItemCategory = (category) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    JwtAxios
        .put(`${EDIT_CATEGORY_ENDPOINT}/${category.id}`, category)

        .then((data) => {
          if (data?.data?.code === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: UPDATE_ITEM_CATEGORY_DETAIL, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: "ItemCategory Updated Successfully",
            });
              HandleNotification('success', 'bottomRight', data?.data?.message, null)
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: "Something went wrong!",
            });
              HandleNotification('success', 'bottomRight', data?.data?.message, null)
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
            HandleNotification('error', 'bottomRight', error?.message, null)
        });
  };
};
export const onDeleteItemCategory = (category) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    JwtAxios
        .delete(`${DELETE_CATEGORY_ENDPOINT}/${category['id']}`)
        .then((data) => {
          if (data?.data?.code === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: DELETE_ITEM_CATEGORY, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: "Deleted Category Successfully",
            });

              HandleNotification('success', 'bottomRight', data?.data?.message, null)
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: "Something went wrong!",
            });
              HandleNotification('error', 'bottomRight', data?.data?.message, null)
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
            HandleNotification('error', 'bottomRight',error?.message, null)
        });
  };
};
