import axios from "axios";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
const getToken = () => {
  return localStorage.getItem("token") || null;
};

const JwtAxios = axios.create({
  baseURL: BASE_URL, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});
JwtAxios.interceptors.request.use(
  async (config) => {
    // check data instance of FormData
    config.headers = {
      Authorization: `Bearer ${getToken()}`,
      Accept:
        config.data instanceof FormData
          ? "multipart/form-data"
          : "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
JwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    return Promise.reject(err);
  },
);
export default JwtAxios;
