import { Table, Typography, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  OSR_LIST_REPORT_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import { FABRIC_REPORT_GENERATE_PERMISSION } from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const OSRReport = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/fabric_report/")[1] : "";
    return updatedPath;
  };

  const exportOSR = async (filterValues) => {
    const { pcd_start, pcd_end, shipment_start, shipment_end, buying_office_id, buyer_id } = filterValues;
    const bodyData = {
      ...(pcd_start && {
        pcd_start: moment(pcd_start).format("YYYY-MM-DD"),
      }),
      ...(pcd_end && { pcd_end: moment(pcd_end).format("YYYY-MM-DD") }),
      ...(shipment_start && { shipment_start: moment(shipment_start).format("YYYY-MM-DD") }),
      ...(shipment_end && { shipment_end: moment(shipment_end).format("YYYY-MM-DD") }),
      ...(buying_office_id && { buying_office_id }),
      ...(buying_office_id && { buying_office_id }),
      ...(buyer_id && { buyer_id }),
    };
    if (
      Object.values(bodyData).length &&
      !Object.values(bodyData).some((val) => val === undefined)
    ) {
      setLoading(true);

      const query = `${OSR_LIST_REPORT_ENDPOINT}`;

      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "blob" },
        "arraybuffer",
      );

      if (response && response?.data) {
        // Download the file
        const outputFilename = `osr_report.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setLoading(false);
        message.success("OSR Exported Successfully");
        refetch();
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/osr_report/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportOSR}
                hideAddButton={true}
                disabled={!hasPermission([FABRIC_REPORT_GENERATE_PERMISSION])}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            }
            title={<Title level={5}>OSR Report</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <FilterComponent
                filterAPI={exportOSR}
                filterName={"osr"}
                searchButtonText={"Export"}
                defaultOpen
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />

            </div>
          </>
        }
      />
    </>
  );
};

export default OSRReport;
