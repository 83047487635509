import { useState, useEffect, useCallback } from "react";
import {
  Card,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Table,
  Typography,
  Divider,
  Space,
  Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  NOTIFICATION_SLUGS,
  NOTIFICATION_SETTINGS,
  GET_ALL_USERS_ENDPOINT
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData, putData } from "../../../../apiServices/common";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../utils/functions";
import HandleNotification from "../../../../common/Notification";


const SettingsForm = (props) => {
  // Props
  const { form, editMode, viewMode } = props;
  const [code, setCode] = useState("");
  const { TextArea } = Input;

  // Router
  const navigate = useNavigate();
  const { id } = useParams();

  // States
  const [usersList, setUsersList] = useState(null);
  const [slugsList, setSlugsList] = useState(null);

  // Antd
  const { Option } = Select;


  const getSelectedSettingInfo = useCallback(
    async (id) => {
      const query = `${NOTIFICATION_SETTINGS}/${id}/edit`;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {

        form.setFieldsValue({
          name: response?.data?.data?.name,
          slug_id: response?.data?.data?.slug_id,
          user_ids: response?.data?.data?.settings_user_ids,
          other_emails: response?.data?.data?.other_emails,
        });
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error in fetching Settings Info",
          null,
        );
      }
    },
    [form],
  );

  const getAllUsers = async () => {
    let res = await getData(GET_ALL_USERS_ENDPOINT);

    if (res) {
      setUsersList(res?.data?.data || []);
    }
  };

  const getAllSlugs = async (filterValues) => {
    const bodyData = {
      pagination: "no",
    };
    let res = await getData(NOTIFICATION_SLUGS, false, bodyData);

    if (res) {
      setSlugsList(res?.data?.data || []);
    }
  };

  useEffect(() => {
    getAllSlugs();
    getAllUsers();
  }, []);

  const handleNameChange = (e) => {
    const name = e.target.value;
    // Replace spaces and special characters with dashes
    const codeValue = name.replace(/[^a-zA-Z0-9]/g, "-");
    setCode(codeValue);
    form.setFieldsValue({ code: codeValue });
  };

  useEffect(() => {
    // get selected cost info on edit mode
    if (id) {
      getSelectedSettingInfo(id);
    }
  }, [editMode, id, getSelectedSettingInfo]);

  const onFinishNotificationSettings = async (values) => {

    const query = editMode
    ? `${NOTIFICATION_SETTINGS}/${id}`
    : `${NOTIFICATION_SETTINGS}`;
    const bodyData = {
      name: values?.name,
      slug_id: values?.slug_id,
      user_ids: values?.user_ids,
      other_emails: values?.other_emails
    };
    
    const response = editMode ? await putData(query, bodyData) : await postData(query, bodyData);
  
    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        `Notification Settings ${editMode ? "updated" : "added"} added successfully!`,
        null,
      );
      form.resetFields();
      navigate("/settings/notification-settings");
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        `Error ${editMode ? "updating" : "adding"} notification settings!`,
        null,
      );
    }
  };

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishNotificationSettings}
      >
        <Card>
          <Row gutter={24}>
            <Col className="gutter-row" span={8}>
              <Form.Item 
                label="Name" 
                name="name" 
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input placeholder="Name" size="small" />
              </Form.Item>
            </Col>
            
            <Col span={8}>
              <Form.Item label="Slug" name="slug_id">
                <Select
                  showSearch
                  placeholder="Select a Slug"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  size="small"
                >
                  {isArrayAndHasValue(slugsList) &&
                    slugsList.map((slug) => (
                      <Option value={slug.id} key={slug.id}>
                        {slug.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Users" name="user_ids">
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select User"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  size="small"
                >
                  {isArrayAndHasValue(usersList) &&
                    usersList.map((user) => (
                      <Option value={user.id} key={user.id}>
                        {user.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>


            <Col className="gutter-row" span={24}>
              <Form.Item 
                label="Other Emails" 
                name="other_emails"
              >
                <TextArea rows={4} placeholder="Enter emails with comma (,) separated" />
              </Form.Item>
            </Col>
          </Row>

        </Card>
      </Form>
    </div>
  );
};

export default SettingsForm;
