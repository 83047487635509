import { Affix, Button, Card, Form, Space } from "antd";
import { GET_SINGLE_TNA_TASK_DATA } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { isMobileView } from "components/Common/AppHelper";
import Header from "components/Common/Header";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TaskForm from "../AddTask/TaskForm";

const UpdateTask = (props, ref) => {
  const { width, viewMode } = props;
  const [editInfo, setEditInfo] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [processId, setProcessId] = useState([]);
  const [deptId, setDeptId] = useState([]);

  useEffect(() => {
    if (taskId) {
      getTaskInfo(taskId);
    }
  }, [taskId]);

  const getTaskInfo = async (id) => {
    const query = `${GET_SINGLE_TNA_TASK_DATA}/${id}`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      const taskData = response?.data?.data;
      // console.log(taskData)
      form.setFieldsValue({
        title: taskData?.title,
        description: taskData?.description,
        is_active: taskData?.is_active,
        is_pcd: taskData?.is_pcd ? 1 : 0,
        is_shipment: taskData?.is_shipment ? 1 : 0,
        relation_with: taskData?.relation_with,
      });
      setDeptId([taskData?.department?.id]);
      setProcessId([taskData?.process?.id]);
      setEditInfo(taskData);
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || "Failed to get inquiry data",
      );
    }
  };

  const discartForm = () => {
    navigate("/tanda/task");
  };

  const addBuyerInquiry = () => {
    form.submit();
  };

  const ActionUI = () => {
    return (
      <Space>
        <Button size="small" key={1} danger onClick={() => discartForm()}>
          Discard
        </Button>

        <Button
          size="small"
          key={2}
          type="primary"
          onClick={() => addBuyerInquiry()}
          hidden={viewMode}
        >
          Update Task
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`${viewMode ? "View" : "Edit"} Task`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={<ActionUI />}
          />
        </Card>
      </Affix>

      {processId.length > 0 && (
        <TaskForm
          form={form}
          isEdit={true}
          viewMode={viewMode}
          editInfo={editInfo}
          processId={processId}
          deptId={deptId}
        />
      )}
    </>
  );
};

export default UpdateTask;
