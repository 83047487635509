import {
    GET_BUYING_OFFICE_LIST,
    CREATE_NEW_BUYING_OFFICE,
    DELETE_BUYING_OFFICE,
    GET_BUYING_OFFICE_DETAIL, UPDATE_BUYING_OFFICE_DETAIL
} from '../ActionTypes';

const initialState = {
    buyingOfficeList: [],
    totalBuyingOffices: null,
    selectedBuyingOffice: null,
    detailsBuyingOffice: null,
    salesContract: [],
    pageSize: 10,
    page:1,
};

const BuyingOfficeReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_BUYING_OFFICE_LIST:
            return {
                ...state,
                buyingOfficeList: action.payload.data,
                totalBuyingOffices: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_BUYING_OFFICE:
            return {
                ...state,
                buyingOfficeList: [action.payload.data, ...state.buyingOfficeList],
                totalBuyingOffices: state.totalBuyingOffices + 1,
            };

        case DELETE_BUYING_OFFICE: {
            return {
                ...state,
                buyingOfficeList: action.payload.list,
                totalBuyingOffices: action.payload.total,

            };
        }

        case GET_BUYING_OFFICE_DETAIL:
            return {
                ...state,
                selectedBuyingOffice: action.payload,
                detailsBuyingOffice: action.payload,
                salesContract: action.payload?.sales_contract
            };

        case UPDATE_BUYING_OFFICE_DETAIL:
            return {
                ...state,
                selectedBuyingOffice: action.payload,
                buyingOfficeList: state.buyingOfficeList.map((buyingOffice) =>
                    buyingOffice.id === action.payload.id ? action.payload : buyingOffice,
                ),
            };

        default:
            return state;
    }
};

export default BuyingOfficeReducer;
