import { Col, Form, Row, Select, Input, DatePicker } from "antd";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  SALES_CONTRACT_LIST,
  STYLE_MERCHANDISING_LIST,
  GET_SUPPLIER_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
const SubmissionRealizationFilterFields = ({ initialTableData }) => {
  const { Option } = Select;
  const [salseCotractList, setSalseCotractList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);

  useEffect(() => {
    getSalseCotractList();
    getSupplierList();
  }, []);

  const getSalseCotractList = async () => {
    let res = await getData(SALES_CONTRACT_LIST);

    if (res) {
      setSalseCotractList(res?.data?.data?.data || []);
    }
  };

  const getSupplierList = async () => {
    let res = await getData(GET_SUPPLIER_ENDPOINT);

    if (res) {
      setSupplierList(res?.data?.data?.data || []);
    }
  };
  return (
    <>
      <Col span={4}>
        <Form.Item label="Invoice Number" name="invoice_number">
          <Input placeholder="Write Invoice Number" size="small" />
        </Form.Item>
      </Col>
      
      <Col span={4}>
        <Form.Item label="Sales Contract No" name="sales_contract_id">
          <Select
            showSearch
            placeholder="Select a Sales Contract No"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {salseCotractList?.length &&
              salseCotractList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.reference_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="FDBC Number" name="fdbc_number">
          <Input placeholder="Write FDBC Number" size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default SubmissionRealizationFilterFields;
