import { Card, Table, Typography, message } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import {
  DELETE_PRODUCTION_DETAILS,
  EXPORT_PRODUCTION_DETAILS,
  GET_PRODUCTION_DETAILS,
  VIEW_PRODUCTION_DETAILS,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import CustomFilter from "../../Common/CustomFilter";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";
import { cglFormatDate } from "utils/functions";

const ProductionDetails = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/production_details/")[1] : "";
    return updatedPath;
  };

  const getAllDetails = useCallback(async (filterValues) => {
    setLoading(true);
    const query = `${GET_PRODUCTION_DETAILS}`;
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);

    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting details data");
    }
  }, []);

  const exportDetails = async (filterValues) => {
    const { start_date, end_date, sales_contract_id, style_id, buying_office_id, buyer_id } = filterValues;
    const bodyData = {
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
      ...(sales_contract_id && { sales_contract_id }),
      ...(buying_office_id && { buying_office_id }),
      ...(buyer_id && { buyer_id }),
      ...(style_id && { style_id }),
    };
    if (
      Object.values(bodyData).length &&
      !Object.values(bodyData).some((val) => val === undefined)
    ) {
      setLoading(true);

      const query = `${EXPORT_PRODUCTION_DETAILS}`;

      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "blob" },
        "arraybuffer",
      );

      if (response && response?.data) {
        // Download the file
        const outputFilename = `production_details_report.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setLoading(false);
        message.success("Fabric Exported Successfully");
        refetch();
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };

  const previewFunction = async (filterValues) => {
    setLoading(true);

    const bodyData = {
      ...filterValues,
    };

    const query = `${VIEW_PRODUCTION_DETAILS}`;
    const response = await postData(query, bodyData);

    if (response?.code === 200) {
      setLoading(false);
      setTableData(response?.data);
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  // Effects
  // useEffect(() => {
  //   getAllDetails();
  // }, [refresh, getAllDetails]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedTracking = async (name) => {
    setLoading(true);
    const updatedName = cutPath(name);
    const query = `${DELETE_PRODUCTION_DETAILS}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setLoading(false);
      message.success("Production Details Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/production_details/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: 'Contract Type',
      dataIndex: 'type',
      key: 'type',
      width: 150,
    },
    {
      title: 'Buyer/1st Party',
      dataIndex: 'buyer',
      key: 'buyer',
      width: 150,
    },
    {
      title: "Reference No",
      dataIndex: "sales_contract",
      key: "sales_contract",
      width: 150,
    },
    {
      title: "PO",
      dataIndex: "po",
      key: "po",
      width: 150,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 150,
    },
    {
      title: "Style",
      dataIndex: "style",
      key: "style",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 150,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      width: 150,
    },
    {
      title: "Substyle",
      dataIndex: "sub_style",
      key: "sub_style",
      width: 150,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 150,
    },
    {
      title: "O.Qty",
      dataIndex: "order_quantity",
      key: "order_quantity",
      width: 150,
    },
    {
      title: "Cutting TDY",
      dataIndex: "cut_today",
      key: "cut_today",
      width: 150,
    },
    {
      title: "Cutting UTD",
      dataIndex: "cut_uptodate",
      key: "cut_uptodate",
      width: 150,
    },
    {
      title: "Cutting WIP",
      dataIndex: "cut_balance",
      key: "cut_balance",
      width: 150,
    },
    {
      title: "Cutting Start",
      dataIndex: "cutting_start",
      key: "cutting_start",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Cutting End",
      dataIndex: "cutting_end",
      key: "cutting_end",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Line",
      dataIndex: "line",
      key: "line",
      width: 150,
    },
    {
      title: "Assign TDY",
      dataIndex: "assign_today",
      key: "assign_today",
      width: 150,
    },
    {
      title: "Assign UTD",
      dataIndex: "assign_uptodate",
      key: "assign_uptodate",
      width: 150,
    },
    {
      title: "Assign WIP",
      dataIndex: "assign_wip",
      key: "assign_wip",
      width: 150,
    },
    {
      title: "Assign Start",
      dataIndex: "assign_start",
      key: "assign_start",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Assign End",
      dataIndex: "assign_end",
      key: "assign_end",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Line",
      dataIndex: "sew_line",
      key: "sew_line",
      width: 150,
    },
    {
      title: "Sewing TDY",
      dataIndex: "sew_today",
      key: "sew_today",
      width: 150,
    },
    {
      title: "Sewing UTD",
      dataIndex: "sew_uptodate",
      key: "sew_uptodate",
      width: 150,
    },
    {
      title: "Sewing WIP",
      dataIndex: "sew_wip",
      key: "sew_wip",
      width: 150,
    },
    {
      title: "Rejected",
      dataIndex: "reject",
      key: "reject",
      width: 150,
    },
    {
      title: "Sewing Start",
      dataIndex: "sewing_start",
      key: "sewing_start",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Sewing End",
      dataIndex: "sewing_end",
      key: "sewing_end",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Folding TDY",
      dataIndex: "fold_today",
      key: "fold_today",
      width: 150,
    },
    {
      title: "Folding UTD",
      dataIndex: "fold_uptodate",
      key: "fold_uptodate",
      width: 150,
    },
    {
      title: "Folding WIP",
      dataIndex: "fold_wip",
      key: "fold_wip",
      width: 150,
    },
    {
      title: "Folding Start",
      dataIndex: "folding_start",
      key: "folding_start",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Folding End",
      dataIndex: "folding_end",
      key: "folding_end",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Packing TDY",
      dataIndex: "pack_today",
      key: "pack_today",
      width: 150,
    },
    {
      title: "Packing UTD",
      dataIndex: "pack_uptodate",
      key: "pack_uptodate",
      width: 150,
    },
    {
      title: "Packing WIP",
      dataIndex: "pack_wip",
      key: "pack_wip",
      width: 150,
    },
    {
      title: "Packing Start",
      dataIndex: "packing_start",
      key: "packing_start",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Packing End",
      dataIndex: "packing_end",
      key: "packing_end",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Ex-Factory Qty",
      dataIndex: "ex_factory_quantity",
      key: "ex_factory_quantity",
      width: 150,
    },
    {
      title: "Ex-factory WIP",
      dataIndex: "ex_short",
      key: "ex_short",
      width: 150,
    },
    {
      title: "Ex-Factory Date",
      dataIndex: "ex_factory_start",
      key: "ex_factory_start",
      width: 150,
      render: (value) => cglFormatDate(value)
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={tableData}
        filterAPI={exportDetails}
        filterName={"production_details"}
        searchButtonText={"Export"}
        defaultOpen
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        isPreview={true}
        previewFunction={previewFunction}
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportDetails}
                hideAddButton={true}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Production Details</Title>}
          />
        }
        content={
          <>
            <div className="containt-body">
              <Card>
                {memoizedCustomFilter}
              </Card>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination
                scroll={{ y: "max-content" }}
                bordered
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default ProductionDetails;
