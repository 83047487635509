import React, { useRef } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Affix, Form, Typography } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { Link, useNavigate } from "react-router-dom";
import AddPiForm from "./AddPiForm";

export default function AddIou(props) {
  // Props
  const { permitInfo, width } = props;

  //   Antd
  const { Title } = Typography;

  // Others
  const propertyFormRef = useRef(null);
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/import/pi");
  };

  const addIou = () => {
    form.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>Add Proforma Invoice</Title>}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => addIou()}
                >
                  Save
                </Button>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      <AddPiForm ref={propertyFormRef} form={form} />
    </>
  );
}
