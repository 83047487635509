import React, { useEffect, useState } from "react";
import { Form, Typography } from "antd";
import { useParams } from "react-router-dom";
import { getData } from "../../../../apiServices/common";
import {
  SALES_CONTRACT_LIST,
  SALES_CONTRACT_SIZE_UPLOAD,
} from "../../../../apiServices/API_ENDPOINTS";
import HandleNotification from "../../../../common/Notification";
import SizeListTable from "./SizeListTable";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";

const SizeSpecs = (props) => {
  // States
  const [sizeInfo, setSizeInfo] = useState(null);
  const [refresh, setRefresh] = useState(0);

  // Router
  const { salesContractId } = useParams();

  // Antd
  const [form] = Form.useForm();
  const { Title } = Typography;

  const getSizeInfo = async (id) => {
    const query = `${SALES_CONTRACT_LIST}/${id}/size-view`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setSizeInfo(response?.data?.data);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  useEffect(() => {
    getSizeInfo(salesContractId);
  }, [salesContractId]);

  const onSubmit = () => {
    form.submit();
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                afterUploadCustom={getSizeInfo}
                handleAddItemOpen={onSubmit}
                addButtonText={`Update`}
                hasImportButton
                uploadAPI={SALES_CONTRACT_SIZE_UPLOAD}
                acceptedFileTypes={".csv"}
                hideFilterButton
                setRefresh={setRefresh}
              />
            }
            title={<Title level={5}>Size Specification</Title>}
          />
        }
        content={
          <>
            <SizeListTable
              sizeInfo={sizeInfo}
              form={form}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </>
        }
      />
    </>
  );
};

export default SizeSpecs;
