import React, { Fragment, useState, useEffect, useCallback, useMemo } from "react";
import {
  Card,
  Select,
  Table,
  Button,
  Dropdown,
  Menu,
  Affix,
  Typography,
  Space,
  Popconfirm,
} from "antd";
import { Link, useLocation } from "react-router-dom";
import {   DELETE_TNA_DEPARTMENT_DATA, DELETE_TNA_PROCESS_DATA, GET_ALL_TNA_DEPARTMENT_DATA, GET_ALL_TNA_PROCESS_DATA } from "apiServices/API_ENDPOINTS";
import { deleteData, getData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { DeleteOutlined, EditOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { BUYER_INQUIRY_CREATE_PERMISSION, BUYER_INQUIRY_DELETE_PERMISSION, BUYER_INQUIRY_EDIT_PERMISSION, BUYER_INQUIRY_VIEW_PERMISSION } from "routes/permissions";
import { hasPermission } from "utils/functions";
import { isMobileView } from "components/Common/AppHelper";
import Header from "components/Common/Header";
import CustomFilter from "components/Common/CustomFilter";

const Department = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();


  const getAllPermitList = useCallback(
    async (filterValues) => {
      setLoading(true);
      const bodyData = {
        per_page: rangeData || 10,
        page: currentPage,
        ...filterValues,
      };
      let response = await getData(GET_ALL_TNA_DEPARTMENT_DATA, false, bodyData);

      if (response && response?.data?.code === 200) {
        const masterData = response?.data;
        const result = masterData?.data?.data?.map((process)=>{
            return {
                id: process?.id,
                title: process?.title,
                description: process?.description,
                head: process?.head?.name,
                status: process?.is_active === 1 ? 'Active' : 'In-Active'
            }
        })
        // console.log('masterData', masterData);
        setPermitList(result);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      else {
        HandleNotification('error', 'bottomRight', 'Something Went Wrong!', null) 
      }

      setLoading(false);
    },
    [rangeData, currentPage],
  );

  useEffect(() => {
    getAllPermitList();
  }, [currentPage, getAllPermitList]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Department Head",
      dataIndex: "head",
      key: "head",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const deleteSelectedItem = async (id) => {
    const query = `${DELETE_TNA_DEPARTMENT_DATA}/${id}`;
    const response = await deleteData(query);

    if (response) {
      HandleNotification(
        "success",
        "bottomRight",
        "Process are Deleted Successfully!",
        null,
      );
      getAllPermitList();
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/tanda/view-department/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([BUYER_INQUIRY_VIEW_PERMISSION]),
        },
        {
          label: (
            <Link to={`/tanda/update-department/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "2",
          disabled: !hasPermission([BUYER_INQUIRY_EDIT_PERMISSION]),
        },
        {
          label: (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                deleteSelectedItem(data?.id);
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                <DeleteOutlined /> Delete
              </span>
            </Popconfirm>
          ),
          key: "1",
          disabled: !hasPermission([BUYER_INQUIRY_DELETE_PERMISSION]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        key="3"
        size="small"
        type="primary"
        disabled={!hasPermission([BUYER_INQUIRY_CREATE_PERMISSION])}
      >
        <Link to="/tanda/add-department">
          {hasPermission([BUYER_INQUIRY_CREATE_PERMISSION])
            ? "Add New"
            : "No Permission"}
        </Link>
      </Button>
    </Space>
  );

  const memoizedCustomFilter = useMemo(
      () => (
        <CustomFilter
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          tableData={permitList}
          fetchTableData={getAllPermitList}
          filterName="tna_department"
        />
      ),
      [showFilter],
    );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header title="Department" subTitle="" actions={extra} />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          {memoizedCustomFilter}
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}

export default Department;
