import {
  ASSIGNMENT_ADD_FORM,
  ASSIGNMENT_EDIT_VIEW,
  ASSIGNMENT_LINE_SUMMARY,
  ASSIGNMENT_STORE,
  ASSIGNMENT_UPDATE,
  GET_LINE_ASSIGNMENT_DETAILS,
  GET_SEWING_LINE_ENDPOINT,
  GET_STYLE_PO_ASSIGNMENT_SUMMARY,
  STYLE_MERCHANDISING_LIST,
} from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";
import HandleNotification from "common/Notification";
import moment from "moment";
import { createContext, useCallback, useEffect, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";

export const AssignmentContext = createContext();

const AssignmentContextProvider = ({ children }) => {
  // States
  const [sewingLinesList, setSewingLinesList] = useState(null);
  const [lineTarget, setLineTarget] = useState(null);
  const [lineAssignmentDetails, setLineAssignmentDetails] = useState(null);
  const [styleList, setStyleList] = useState(null);
  const [poList, setPOList] = useState(null);
  const [lineWiseData, setLineWiseData] = useState(null);
  const [stylePOData, setStylePOData] = useState(null);
  const [lineItemData, setLineItemData] = useState(null);
  const [addFormData, setAddFormData] = useState(null);
  const [useBothStylePo, setUseBothStylePo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getSewingList = useCallback(async (filterValues) => {
    const query = `${GET_SEWING_LINE_ENDPOINT}`;
    const bodyData = {
      page: filterValues?.page || 1,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);
    if (response && response?.data?.code === 200) {
      setSewingLinesList(response?.data?.data?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting sewing lines",
        null,
      );
    }
  }, []);

  const getStyleList = useCallback(async (filterValues) => {
    const query = `${STYLE_MERCHANDISING_LIST}`;
    const bodyData = {
      page: filterValues?.page || 1,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);
    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data?.data);
    } else {
      HandleNotification("error", "bottomRight", "Error getting styles", null);
    }
  }, []);

  useEffect(() => {
    // Get sewing list, styles on first render
    getSewingList();
    getStyleList();
  }, [getSewingList, getStyleList]);

  const onSelectLine = async (line_id, assignment_date) => {
    if (!line_id || line_id < 0) return null;
    if (!assignment_date) return null;

    const query = `${ASSIGNMENT_LINE_SUMMARY}`;
    const payload = {
      line_id: line_id,
      assigned_date: assignment_date
        ? moment(assignment_date).format("YYYY-MM-DD")
        : null,
    };

    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      setLineTarget(response?.data?.line_target);
      setLineAssignmentDetails(response?.data?.line_assignment_details);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting line summary",
        null,
      );
    }
  };

  const onSelectStyle = async (style_id) => {
    setIsSubmitting(false);
    if (!style_id || style_id < 0) return null;

    const query = `${STYLE_MERCHANDISING_LIST}/${style_id}/edit`;
    const payload = {
      style_id: style_id,
    };

    const response = await getData(query, false, payload);

    if (response && response?.data?.code === 200) {
      const active_sales_contract_map_info =
        response?.data?.data?.[0]?.style?.active_sales_contract_map_info;
      const poData =
        active_sales_contract_map_info?.active_sales_contract_info?.po_info;
      setPOList(poData);
      setUseBothStylePo(
        response?.data?.data?.[0]?.style?.active_sales_contract_map_info
          ?.active_sales_contract_info?.use_both_style_po,
      );
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting style details",
        null,
      );
    }
  };

  const getStylePOAssignmentSummary = useCallback(async (values) => {
    const { style_id, po_map_id, internal_po } = values;
    const payload = {
      style_id: style_id,
      po_map_id: po_map_id,
      internal_po: internal_po,
    };
    const query = `${GET_STYLE_PO_ASSIGNMENT_SUMMARY}`;

    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      // Line data for table
      setLineWiseData(response?.data?.line_wise_assign_detail);

      // Po Data for two fields - Cut Qty and Assignment Remaining
      const total_cutting = response?.data?.total_cutting || null;
      const total_assign = response?.data?.total_assign || null;
      const assignment_remaining = total_cutting - total_assign;
      setStylePOData({
        cut_qty: response?.data?.total_cutting,
        assignment_remaining: assignment_remaining || null,
        buying_office_id: response?.data?.buying_office_id,
        sales_contract_id: response?.data?.sales_contract_id,
        sub_contract_factory_id: response?.data?.sub_contract_factory_id,
      });
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting style po assignment summary",
        null,
      );
    }
  }, []);

  const onViewLineItem = async (itemData) => {
    const payload = {
      line_id: itemData?.sewing_line_id,
      style_id: itemData?.style_id,
      po_map_id: itemData?.po_map_id,
      internal_po: itemData?.internal_po,
      sales_contract_id: itemData?.sales_contract_id,
      buying_office_id: itemData?.buying_office_id,
      sub_contract_factory_id: itemData?.sub_contract_factory_id,
    };

    const query = `${GET_LINE_ASSIGNMENT_DETAILS}`;

    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      setLineItemData(response?.data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting line item details",
        null,
      );
    }
  };

  const onAssignOpen = async (data, formValues, isEdit) => {
    // Set assignment date to stylePOData. Will be used later in onSubmitAssignment
    const stylePODataCopy = { ...stylePOData };
    stylePODataCopy["assignment_date"] = formValues?.assignment_date;
    setStylePOData(stylePODataCopy);

    // Get assignment add form data. Data load after drawer open.
    const payload = {
      ...data,
      assigned_date: formValues?.assignment_date
        ? moment(formValues?.assignment_date).format("YYYY-MM-DD")
        : null,
    };

    const query = `${isEdit ? ASSIGNMENT_EDIT_VIEW : ASSIGNMENT_ADD_FORM}`;

    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      setAddFormData(response?.data);
    } else {
      HandleNotification("error", "bottomRight", "Error getting data", null);
    }
  };

  const onSubmitAssignment = async (isEdit) => {
    setIsSubmitting(true);
    
    const payload = {
      sales_contract_id: addFormData?.sales_contract_id || null,
      buying_office_id: addFormData?.buying_office_id || null,
      buyer_id: addFormData?.buyer_id || null,
      line_id: addFormData?.line_id || null,
      style_id: addFormData?.style_id || null,
      po_map_id: addFormData?.po_map_id || null,
      internal_po: addFormData?.internal_po || null,
      original_po: addFormData?.original_po || null,
      transaction_no_per_day: addFormData?.transaction_no_per_day || null,
      assigned_date: stylePOData?.assignment_date
        ? moment(stylePOData?.assignment_date).format("YYYY-MM-DD")
        : null,
      assignments:
        isArrayAndHasValue(addFormData?.assignments) &&
        addFormData?.assignments.map((item) => {
          return {
            assignment_id: item?.assignment_id,
            color_id: item?.color_id,
            size_id: item?.size_id,
            sub_style_id: item?.sub_style_id,
            assigned: item?.assigned,
            assignment_history_id: item?.assignment_history_id || null,
          };
        }),
      sub_contract_factory_id: addFormData?.sub_contract_factory_id,
    };

    const query = `${isEdit ? ASSIGNMENT_UPDATE : ASSIGNMENT_STORE}`;

    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      // setIsSubmitting(false);
      HandleNotification(
        "success",
        "bottomRight",
        `Assignment ${isEdit ? "updated" : "added"} successfully`,
        null,
      );
    } else {
      HandleNotification("error", "bottomRight", "Error getting data", null);
      setIsSubmitting(false);
    }
  };

  return (
    <AssignmentContext.Provider
      value={{
        sewingLinesList,
        getSewingList,
        onSelectLine,
        lineTarget,
        lineAssignmentDetails,
        setLineAssignmentDetails,
        styleList,
        getStyleList,
        onSelectStyle,
        poList,
        setPOList,
        getStylePOAssignmentSummary,
        lineWiseData,
        stylePOData,
        setStylePOData,
        setLineWiseData,
        onViewLineItem,
        lineItemData,
        setLineItemData,
        onAssignOpen,
        addFormData,
        setAddFormData,
        onSubmitAssignment,
        useBothStylePo,
        isSubmitting,
        setIsSubmitting
      }}
    >
      {children}
    </AssignmentContext.Provider>
  );
};

export default AssignmentContextProvider;
