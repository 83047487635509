import React from "react";
import {Button, Dropdown, Menu, PageHeader, Popconfirm, Space, Typography} from "antd";
import AppPageHeader from "../../../common/PageHeader";
import AppContent from "../../../common/AppContent";
import SewingLineListTable from "./ListTable";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import { hasPermission } from "../../../utils/functions";
import {
    SEWING_LINE_EDIT_PERMISSION,
    SEWING_LINE_DELETE_PERMISSION,
} from '../../../routes/permissions';

const ListSewingLine = (props) => {
    const {
        loading,
        setSelectedSewingLine,
        handleAddSewingLineOpen,
        sewingLineList,
        pageSize,
        totalSewingLine,
        onPageChange,
        pageView,
        page,
        onSelectSewingLineForDelete,
        onSelectedSewingLineToDelete,
        onOpenEditSewingLine,
        showFilter,
        setShowFilter,
    } = props;
    const { Title } = Typography;

    const handleDelete = (record) => {
        onSelectedSewingLineToDelete(record);
    };

    const handleEdit = (event,record) => {
        setSelectedSewingLine(record)
        handleAddSewingLineOpen(true);
    };

    const formatNumberWithCommas = (number) => {
      return number.toLocaleString(); // Format number with commas
    };

    const tableColumns = [
        {title:"Sewing Line", dataIndex:"name", key:"name" },
        {title:"Capacity Unit", dataIndex:"capacity", key:"capacity", render: (_, record) => formatNumberWithCommas(record.capacity)},
        {
            title:"Status", 
            dataIndex:"status", 
            key:"status",
            render: (_, record) => {
                return(
                        record?.status === "1" ? "Active" : "Inactive"
            );
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (_, record) => (
                <>
                    { sewingLineList.length >= 1 ? <Dropdown.Button overlay={

                        <Menu
                            items={[
                                {
                                    label: <Popconfirm title="Are you sure you want to delete?"
                                                       onConfirm={() => {
                                                           handleDelete(record)
                                                       }}>
                                       <span>
                                           <DeleteTwoTone />
                                       </span>
                                        <a style={{color: 'red', marginLeft:5}}>Delete</a>
                                    </Popconfirm>,
                                    key: '1',
                                    disabled: !hasPermission([SEWING_LINE_DELETE_PERMISSION]),
                                },
                                {
                                    label:
                                        <>
                                            <span><EditTwoTone /></span>
                                            <a type='button' style={{marginLeft: 5}} onClick={(event) => {
                                                handleEdit(event,record);

                                            }}>Edit</a>
                                        </>,
                                    key: '2',
                                    disabled: !hasPermission([SEWING_LINE_EDIT_PERMISSION]),
                                },
                            ]}
                        />


                    } >
                    </Dropdown.Button>: null
                    }
                </>
            ),
        }
    ]
    const header = <AppPageHeader extra={props.extra} title={<Title level={5}>Sewing Line</Title>}/>
    const content =
        (<SewingLineListTable
            tableColumns={tableColumns}
            onChange={(newPage) => {
                onPageChange(newPage)
            }}
            dataSource={sewingLineList}
            total={totalSewingLine}
            loading={loading}
            current={page}
            defaultCurrent={page}
            pageSize={pageSize}
            filter={true}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
        />)
    return (
        <>
            <AppContent header={header} content={content} />
        </>

    )
}

export default ListSewingLine;