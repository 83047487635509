import { useContext } from "react";
import { Card, Collapse, InputNumber, Table, Typography } from "antd";
import { commaSeparateNumber } from "utils/functions";
import SewingTableFilter from "../SewingTableFilter";
import { PostSewingProcessContext } from "context/PostSewingProcessContext/PostSewingProcessContextProvider";

const PostSewingDetails = (props) => {
  // Props
  const { movementType, process } = props;

  // Contexts
  const {
    sewingTableData,
    onChangeProcessData,
  } = useContext(PostSewingProcessContext);

  const columns = [
    { title: "PO", dataIndex: ["original_po"], key: "original_po" },
    {
      title: "Sub Style",
      dataIndex: ["sub_style_info", "sub_style_no"],
      key: "sub_style",
    },
    { title: "Color", dataIndex: ["color", "name"], key: "color" },
    { title: "Size", dataIndex: ["size", "name"], key: "size" },
    { title: "Order Qty", dataIndex: ["sales_order_quantity"], key: "sales_order_quantity" },
    {
      title: `${process || "_______"} Quantity (pc)`,
      children: [
        {
          title: `${movementType || "_____"}`,
          key: "send_qty",
          render: (value, record) => {
            return (
              <InputNumber
                size="small"
                placeholder="Input Number"
                style={{ width: "100%" }}
                formatter={(value) => commaSeparateNumber(value)}
                onChange={(value) =>
                  onChangeProcessData(
                    record?.id,
                    value,
                    movementType === "Send" ? "send_qty" : "receive_qty",
                  )
                }
                value={
                  movementType === "Send"
                    ? record?.process_products?.send_qty
                    : record?.process_products?.receive_qty
                }
              />
            );
          },
        },
        {
          title: "Total Sent",
          dataIndex: ["process_products", "total_send_qty"],
          key: "total_send_qty",
        },
        {
          title: "Total Received",
          dataIndex: ["process_products", "total_receive_qty"],
          key: "total_receive_qty",
        },
        {
          title: "Short",
          dataIndex: ["process_products", "total_short_qty"],
          key: "total_short_qty",
        },
      ],
    },
  ];

  const TableSummary = ({ pageData }) => {
    const sendQtySum = pageData?.reduce((total, item) => {
      return total + item?.process_products?.send_qty;
    }, 0);
    const totalSendQtySum = pageData?.reduce((total, item) => {
      return total + item?.process_products?.total_send_qty;
    }, 0);
    const totalReceivedQtySum = pageData?.reduce((total, item) => {
      return total + item?.process_products?.total_receive_qty;
    }, 0);
    const totalShortQtySum = pageData?.reduce((total, item) => {
      return total + item?.process_products?.total_short_qty;
    }, 0);

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={5} id="total">
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} id="send_qty">
            <Typography.Text>{sendQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} id="total_send_qty">
            <Typography.Text>{totalSendQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} id="total_received_qty">
            <Typography.Text>{totalReceivedQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9} id="total_short_qty">
            <Typography.Text>{totalShortQtySum}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <>
      <div className="cuttingTable" style={{ marginTop: 10 }}>
        <Collapse defaultActiveKey={1}>
          <Collapse.Panel header={<b>Table Filter</b>} key={1}>
            <SewingTableFilter />
          </Collapse.Panel>
        </Collapse>

        <Card style={{ marginTop: 10 }}>
          <Typography.Title style={{ fontSize: 14 }}>
            Washing and Snap Button Details
          </Typography.Title>
          <Table
            pagination={true}
            size={"small"}
            sticky={true}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={sewingTableData}
            columns={columns}
            summary={(pageData) => {
              return <TableSummary pageData={pageData} />;
            }}
          />
        </Card>
      </div>
    </>
  );
};
export default PostSewingDetails;
