import { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Form, Input, Select } from "antd";
import { isArrayAndHasValue } from "utils/functions";
import FabricAutoTable from "../FabricAutoTable";
import { FabricAutoContext } from "context/FabricAutoContext/FabricAutoContextProvider";

const FabricAutoForm = (props) => {
  // Props
  const { form } = props;

  // Antd
  const { Option } = Select;
  const style_id_watch = Form.useWatch("style_id", form);
  const sales_contract_id_watch = Form.useWatch("sales_contract_id", form);

  //states
  const [showFactoryInputs, setShowFactoryInputs] = useState(false);
  const [subContractDetails, setSubContractDetails] = useState(null);

  // Contexts
  const {
    styleList,
    getStyleList,
    salesContractList,
    getConsumptionTableData,
    consumptionTableData,
    setSalesContractList,
  } = useContext(FabricAutoContext);

  useEffect(() => {
    if (style_id_watch && sales_contract_id_watch) {
      getConsumptionTableData({
        style_id: style_id_watch,
        sales_contract_id: sales_contract_id_watch,
      });
    }
  }, [style_id_watch, sales_contract_id_watch, getConsumptionTableData]);

  const onSelectSalesContract = (id) => {
    const foundSalesContract = salesContractList?.find(
      (item) => item?.sales_contract_id === id,
    );
    form.setFieldsValue({
      buyer: foundSalesContract?.all_sales_contract_infos?.buyer_info?.name,
      buying_office:
        foundSalesContract?.all_sales_contract_infos?.buying_office_info?.name,
      first_party: subContractDetails?.name,
      address: subContractDetails?.address,
    });
  };

  const onSelectStyle = (id) => {
    // Reset fields
    form.resetFields(["sales_contract_id", "buyer", "buying_office", "first_party", "address"]);

    // Find selected style and assign sales contract list
    const styleId = id ? Number(id) : null;
    const selectedStyle = styleList.find((style) => style.id === styleId);
    if(selectedStyle?.factory_info) {
      setShowFactoryInputs(true);
      setSubContractDetails(selectedStyle?.factory_info);
    } else if(selectedStyle?.buying_office_id) {
      setShowFactoryInputs(false);
    }
    const SCList = selectedStyle?.all_sales_contract_maps;
    isArrayAndHasValue(SCList) && setSalesContractList(SCList);
  };

  return (
    <>
      <Card>
        <Form
          form={form}
          layout="vertical"
          // onFinish={}
        >
          <Row gutter={6}>
            <Col span={4}>
              <Form.Item
                label="Style No"
                name="style_id"
                rules={[
                  {
                    required: true,
                    message: `Style is required `,
                  },
                ]}
              >
                <Select
                  onSearch={(value) => getStyleList({ style_no: value })}
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  size="small"
                  allowClear
                  onClear={() => getStyleList()}
                  onSelect={(value) => onSelectStyle(value)}
                >
                  {styleList?.map((style, idx) => (
                    <Option key={idx} value={style.id} label={style.style_no}>
                      {" "}
                      {style.style_no + " : " + style?.description || "--"}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Sales Contract"
                name="sales_contract_id"
                rules={[{ required: true, message: "Sales Contract Required" }]}
              >
                <Select
                  showSearch
                  placeholder="Select a Sales Contract No"
                  optionFilterProp="children"
                  size="small"
                  onSelect={(value) => {
                    onSelectSalesContract(value);
                  }}
                >
                  {isArrayAndHasValue(salesContractList) &&
                    salesContractList?.map((item, index) => (
                      <Option value={item?.sales_contract_id} key={item?.id}>
                        {item?.all_sales_contract_infos?.reference_no}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {
              !showFactoryInputs ? <>
                <Col span={4}>
                  <Form.Item name="buyer" label="Buyer">
                    <Input size="small" disabled />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="buying_office" label="Buying Office">
                    <Input size="small" disabled />
                  </Form.Item>
                </Col>
              </> : <>
                <Col span={4}>
                  <Form.Item name="first_party" label="First Party">
                    <Input size="small" disabled />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="address" label="Address">
                    <Input size="small" disabled />
                  </Form.Item>
                </Col>
              </>
            }
          </Row>
        </Form>
      </Card>
      {/* Consumption Table */}
      {isArrayAndHasValue(consumptionTableData) && (
        <>
          {/* Fabric Auto Reconciliation Table */}
          <FabricAutoTable consumptionTableData={consumptionTableData} />
        </>
      )}
    </>
  );
};

export default FabricAutoForm;
