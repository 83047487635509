import React from "react";

export default function Matrix16by16({ backgroundColor }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(16, 1fr)",
        gridTemplateRows: "repeat(16, 1fr)",
        gap: 1,
        backgroundColor: backgroundColor || "white",
      }}
    >
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
      <div style={{ backgroundColor: "black", borderRadius: "50%" }} />
    </div>
  );
}
