import { Button, Col, Form, Input, Row } from "antd";

const ChangePassword = (props) => {
  // Props
  const { onCloseModal } = props;
  return (
    <>
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item name="id" hidden />
          <Form.Item
            name="old_password"
            placeholder="Enter Old Password"
            label="Old Password"
            rules={[
              {
                required: true,
                message: "Old Password Required",
              },
            ]}
          >
            <Input.Password size="small" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="password"
            placeholder="Enter Current Password"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Current Password Required",
              },
            ]}
          >
            <Input.Password size="small" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Button
            type="danger"
            style={{ width: "100%" }}
            onClick={() => {
              onCloseModal();
            }}
            size="small"
          >
            Discard
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            size="small"
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ChangePassword;
