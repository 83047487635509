import { Card } from "antd";
import { isArrayAndHasValue } from "../../../../../utils/functions";
import TableData from "../OBTable/tableData";
import "./index.css";

const OBTable = (props) => {
  // Props
  const { view, sectionId, sectionName, sectionData, removeRow } = props;

  return (
    <div style={{paddingTop: 15}}>
      <table border="1" className="custome-table">
        <thead>
          <tr>
            <th colSpan={1} className="bc-orange text-center">
              OP
            </th>
            <th colSpan={2} className="bc-orange text-center">
              OPERATION
            </th>
            <th colSpan={3} className="bc-orange text-center">
              SMV
            </th>
            <th colSpan={1} className="bc-orange text-center">
              M/C
            </th>
            <th colSpan={3} className="bc-orange text-center">
              TGT
            </th>
            {view && (
              <th colSpan={3} className="bc-orange text-center">
                Workers
              </th>
            )}
            {!view && (
              <th colSpan={2} className="bc-orange text-center">
                Actions
              </th>
            )}
          </tr>
        </thead>
        {isArrayAndHasValue(sectionData) &&
          sectionData?.map((section, index) => {
            return (
              <TableData
                sectionID={section?.id}
                sectionName={section?.section_name}
                sectionItems={section?.section_data}
                removeRow={removeRow}
                view={view}
              />
            );
          })}
      </table>
    </div>
  );
};

export default OBTable;
