import { Col, Form, Input } from "antd";

const DigitalDefectCategoryFilter = ({ initialTableData }) => {
  return (
    <>
      <Col span={4}>
        <Form.Item label="Name" name="name">
          <Input  placeholder="Name" size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default DigitalDefectCategoryFilter;
