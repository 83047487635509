import { Card, Col, Divider, Form, Row, Select, Space } from "antd";
import { INQUIRY_REFERENCE_LIST } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import HandleNotification from "common/Notification";
import { useEffect } from "react";
import ReferenceForm from "./ReferenceForm";
import { isArrayAndHasValue } from "utils/functions";

const Reference = (props) => {
  // Props
  const { title, inquiryReferences, setInquiryReferences, viewMode } = props;

  // Effects
  useEffect(() => {
    getAllInquiryReferences();
  }, []);

  const getAllInquiryReferences = async (customValues) => {
    const filterValues = {
      per_page: customValues?.per_page || 10,
      ...customValues,
    };
    const query = `${INQUIRY_REFERENCE_LIST}`;

    const response = await getData(query, false, filterValues);

    if (response && response?.data?.code === 200) {
      setInquiryReferences(response?.data?.data?.data);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  return (
    <Card
      title={<span style={{ fontSize: "14px" }}>{title || "Reference"}</span>}
    >
      <Row gutter={4}>
        <Col span={9}>
          <Form.Item>
            <Form.Item
              name="reference_id"
              noStyle
              required
              rules={[
                {
                  required: true,
                  message: "Reference is required",
                },
              ]}
            >
              <Select
                showSearch
                onSearch={(value) => getAllInquiryReferences({ name: value })}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Reference"
                size="small"
                allowClear
                disabled={viewMode}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <ReferenceForm
                        getAllInquiryReferences={getAllInquiryReferences}
                      />
                    </Space>
                  </>
                )}
              >
                {isArrayAndHasValue(inquiryReferences) &&
                  inquiryReferences?.map((item, index) => (
                    <Select.Option
                      value={item?.id || null}
                      key={item?.id || index}
                      label={item?.name}
                    >
                      {item?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default Reference;
