import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone, UploadOutlined } from "@ant-design/icons";
import {
  message,
  Table,
  Row,
  Col,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  InputNumber,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../CommonComponents/ExtraContent";
import FilterComponent from "../CommonComponents/FilterComponent";
import {
  getData,
  postData,
  deleteData,
  putData,
} from "../../../apiServices/common";
import {
  OB_TEMPLATES,
  OB_TEMPLATE_DELETE,
  OB_TEMPLATE_IMPORT_ENDPOINT
} from "../../../apiServices/API_ENDPOINTS";
import { cglFormatDate, hasPermission } from "../../../utils/functions";
import {
  OB_SAM_DATA_BANK_CREATE_PERMISSION,
  OB_SAM_DATA_BANK_DELETE_PERMISSION,
  OB_SAM_DATA_BANK_EDIT_PERMISSION,
  OB_TEMPLATE_IMPORT_PERMISSION
} from "../../../routes/permissions";
import { useLocation, useNavigate } from "react-router";

const OBTemplate = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllObTemplates = useCallback(
    async (filterValues) => {
      const { per_page, name } = filterValues || {};

      setLoading(true);

      const query = `${OB_TEMPLATES}?page=${currentPage}`;
      const bodyData = {
        ...(per_page && { per_page: per_page }),
        ...(name && { name: name }),
      };

      const response = await getData(query, false, bodyData);
      if (response && response?.data?.code === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting OB Templates");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllObTemplates();
  }, [refresh, getAllObTemplates]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteOBTemplate = async (id) => {
    setLoading(true);
    const response = await deleteData(`${OB_TEMPLATE_DELETE}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("OB Template Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const openEdit = (ob_id) => {
    navigate(`/configuration/ob-template-edit/${ob_id}`);
  };

  const tableColumns = [
    // {
    //   title: "ID",
    //   dataIndex: ["id"],
    //   key: "id",
    //   width: "5%",
    // },
    {
      title: "Template Name",
      dataIndex: ["tamplate_name"],
      key: "tamplate_name",
    },
    {
      title: "Entry Date",
      dataIndex: ["entry_date"],
      key: "entry_date",
      render: (value) => cglFormatDate(value)
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteOBTemplate(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([
                      OB_SAM_DATA_BANK_DELETE_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openEdit(record?.id);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([
                      OB_SAM_DATA_BANK_EDIT_PERMISSION,
                    ]),
                  },
                ]}
              />
            }
          />
        );
      },
    },
  ];

  const onAdd = () => {
    navigate("/configuration/ob-template-add");
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onAdd}
                disabled={!hasPermission([OB_SAM_DATA_BANK_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([OB_SAM_DATA_BANK_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                hasImportButton={hasPermission([OB_TEMPLATE_IMPORT_PERMISSION])}
                acceptedFileTypes=".xlsx"
                uploadAPI={OB_TEMPLATE_IMPORT_ENDPOINT}
              />
            }
            title={<Title level={5}>OB Template Configuration</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllObTemplates}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default OBTemplate;
