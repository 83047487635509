import { useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import {
  GET_STYLE_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";
import moment from "moment";
import CustomDatePicker from "common/CustomDatePicker";

const PPMeetingDateFilterFields = ({ initialTableData }) => {
  // States
  const [styleList, setStyleList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Option } = Select;

  // Effects
  useEffect(() => {
    // Get Signal Section office, sales contract and buyer list at first load
    getAllStyles();
  }, []);

  const getAllStyles = async (filterValues) => {
    setLoading(true);

    const query = `${GET_STYLE_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      // const filteredStyleListData = response?.data?.data?.data?.filter(item => item.status === "Yes");
      console.log("mamuuu", response?.data?.data?.data);
      setStyleList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  // const saveToStorage = (value, name) => {
  //   const existingFilter =
  //     JSON.parse(localStorage.getItem("cutting_filter")) || {};
  //   existingFilter[name] = value;
  //   localStorage.setItem("cutting_filter", JSON.stringify(existingFilter));
  // };
  

  return (
    <>
      <Col span={4}>
        <Form.Item label="Date" name="date">
          <CustomDatePicker
            style={{ width: "100%" }}
            onChange={(value) =>
              moment(value).format("YYYY-MM-DD")
            }
            size="small"
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Style" name="style_id">
          <Select
              showSearch
              placeholder="Select a style"
              onSearch={(value) => {
                getAllStyles({ style_no: value });
              }}
              optionFilterProp="children"
              size="small"
            >
              {isArrayAndHasValue(styleList) &&
                styleList.map((item) => (
                  <Option value={item?.id} key={item?.id}>
                    {item?.style_no}
                  </Option>
                ))}
            </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default PPMeetingDateFilterFields;
