import {
    GET_FOLDING_LIST,
    STORE_NEW_FOLDING,
    DELETE_FOLDING,
    GET_FOLDING_DETAIL, UPDATE_FOLDING_DETAIL
} from '../ActionTypes';

const initialState = {
    FoldingList: [],
    totalFolding: null,
    selectedFolding: null,
    selectedFoldingDetails: null,
    createdFolding: null,
    selectedFoldingAccountInfo: null,
    pageSize: 10,
    page:1,
};

const FoldingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FOLDING_LIST:
            return {
                ...state,
                FoldingList: action.payload.data,
                totalFolding: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case STORE_NEW_FOLDING:
            return {
                ...state,
                createdFolding: action.payload.data,
                FoldingList: [action.payload.data, ...state.FoldingList],
                totalFolding: state.totalFolding + 1,
            };

        case DELETE_FOLDING: {
            return {
                ...state,
                FoldingList: action.payload.list,
                totalFolding: action.payload.total,
            };
        }

        case GET_FOLDING_DETAIL:
            return {
                ...state,
                selectedFolding: action.payload,
                selectedFoldingAccountInfo: action.payload,
                selectedFoldingDetails: action.payload,
            };

        case UPDATE_FOLDING_DETAIL:
            return {
                ...state,
                selectedFolding: action.payload,
                FoldingList: state.FoldingList.map((Folding) =>
                    Folding.id === action.payload.id ? action.payload : Folding,
                ),
            };

        default:
            return state;
    }
};

export default FoldingReducer;
