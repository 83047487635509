import { Col, Form, Input } from "antd";

const MerchandisingSubContractFactory = ({ initialTableData }) => {
  return (
    <>
      <Col span={6}>
        <Form.Item label="Name" name="name">
          <Input placeholder="Enter Name" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Contact Person Name" name="contact_person">
          <Input placeholder="Enter Contact Person Name" />
        </Form.Item>
      </Col>
    </>
  );
};

export default MerchandisingSubContractFactory;
