import { Table } from "antd";
import CustomFilter from "../Common/CustomFilter";
import { useMemo } from "react";

const BuyerListTable = (props) => {
  const {
    total,
    loading,
    defaultCurrent,
    pageSize,
    current,
    onChange,
    showFilter,
  } = props;

  const memoizedCustomFilter = useMemo(
    () => (
      <CustomFilter
        showFilter={showFilter}
        tableData={props.dataSource}
        fetchTableData={null}
        filterName="buyer"
      />
    ),
    [showFilter],
  );

  return (
    <>
      {props.filter && memoizedCustomFilter}

      <div className="table" style={{ marginTop: 10 }}>
        <Table
          loading={loading}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: pageSize,
            current: current,
            onChange: onChange,
            defaultCurrent: defaultCurrent,
            total: total,
          }}
          columns={props.tableColumns}
          dataSource={props.dataSource}
        />
      </div>
    </>
  );
};
export default BuyerListTable;
