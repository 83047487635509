import { Button, Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import { AssignmentContext } from "context/AssignmentContext/AssignmentContextProvider";
import { useContext, useEffect, useState } from "react";
import AssignmentForm from "../AssignmentForm";

const { Option } = Select;

const StylePOForm = (props) => {
  // Props
  const { form, isUpdate } = props;

  // Contexts
  const {
    styleList,
    getStyleList,
    onSelectStyle,
    poList,
    setPOList,
    getStylePOAssignmentSummary,
    stylePOData,
    setStylePOData,
    setLineWiseData,
    onAssignOpen,
    onSubmitAssignment,
    useBothStylePo,
    isSubmitting,
  } = useContext(AssignmentContext);

  // States
  const [openDrawer, setOpenDrawer] = useState(false);

  // Antd
  const style_id_watch = Form.useWatch("style_id", form);
  const po_map_id_watch = Form.useWatch("po_map_id", form);
  const internal_po_watch = Form.useWatch("internal_po", form);
  const original_po_watch = Form.useWatch("original_po", form);

  useEffect(() => {
    // Get line wise assignment details data for table
    // When style is selected
    if (style_id_watch) {
      getStylePOAssignmentSummary({
        style_id: style_id_watch,
        po_map_id: po_map_id_watch,
        internal_po: internal_po_watch,
      });
    }
  }, [
    getStylePOAssignmentSummary,
    internal_po_watch,
    po_map_id_watch,
    style_id_watch,
  ]);

  useEffect(() => {
    // Set form fields value - Cut Qty and Assignment Remaining
    if (stylePOData) {
      form.setFieldsValue({
        cut_qty: stylePOData?.cut_qty,
        assignment_remaining: stylePOData?.assignment_remaining,
        buying_office_id: stylePOData?.buying_office_id,
        sales_contract_id: stylePOData?.sales_contract_id,
        sub_contract_factory_id: stylePOData?.sub_contract_factory_id,
      });
    } else {
      form.setFieldsValue({
        cut_qty: null,
        assignment_remaining: null,
      });
    }
  }, [stylePOData, form]);

  const onSelectInternalPO = (value) => {
    const foundPO = poList?.find((item) => item.internal_po === value);

    form.setFieldsValue({
      po_map_id: foundPO?.id,
      original_po: foundPO?.original_po,
    });
  };

  const onSelectOriginalPO = (value) => {
    const foundPO = poList?.find((item) => item.original_po === value);

    form.setFieldsValue({
      po_map_id: foundPO?.id,
      internal_po: foundPO?.internal_po,
    });
  };

  const onClearPO = () => {
    form.setFieldsValue({
      po_map_id: null,
      original_po: null,
      internal_po: null,
    });

    // Remove table and form data
    setStylePOData(null);
    setLineWiseData(null);
  };

  const onDrawerClose = () => {
    setOpenDrawer(false);
  };

  const onOpenDrawer = () => {
    const formValues = form.getFieldsValue();
    
    onAssignOpen(
      {
        buying_office_id: formValues?.buying_office_id,
        sales_contract_id: formValues?.sales_contract_id,
        style_id: formValues?.style_id,
        po_map_id: formValues?.po_map_id,
        internal_po: formValues?.internal_po,
        line_id: formValues?.sewing_line_id,
        sub_contract_factory_id: formValues?.sub_contract_factory_id,
      },
      formValues,
    );
    setOpenDrawer(true);
  };

  return (
    <>
      <Row gutter={8}>
        <Col span={4}>
          <Form.Item name="buying_office_id" hidden />
          <Form.Item name="sales_contract_id" hidden />
          <Form.Item name="sub_contract_factory_id" hidden />
          <Form.Item
            label="Style"
            name="style_id"
            rules={[
              {
                required: true,
                message: `Style is required `,
              },
            ]}
          >
            <Select
              onSearch={(value) => getStyleList({ style_no: value })}
              onSelect={(value) => onSelectStyle(value)}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              size="small"
              allowClear
              onClear={() => {
                getStyleList();
                setPOList(null);
              }}
              disabled={isUpdate}
            >
              {styleList?.map((item, idx) => (
                <Option key={idx} value={item.id} label={item.style_no}>
                  {`${item?.style_no}${
                    item?.description ? ` - ${item?.description}` : ""
                  }`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="po_map_id" hidden />
          <Form.Item
            label="Internal PO"
            name="internal_po"
            rules={[
              {
                required: useBothStylePo === "Yes" ? true : false,
                message: `Internal PO is required `,
              },
            ]}
          >
            <Select
              onSelect={(value) => onSelectInternalPO(value)}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              size="small"
              allowClear
              disabled={isUpdate}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onClear={onClearPO}
            >
              {poList?.map((item, idx) => (
                <Option
                  key={idx}
                  value={item.internal_po}
                  label={item?.internal_po}
                >
                  {`${item?.internal_po}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Original PO"
            name="original_po"
            rules={[
              {
                required: useBothStylePo === "Yes" ? true : false,
                message: `Original PO is required `,
              },
            ]}
          >
            <Select
              onSelect={(value) => onSelectOriginalPO(value)}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              size="small"
              allowClear
              disabled={isUpdate}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onClear={onClearPO}
            >
              {poList?.map((item, idx) => (
                <Option
                  key={idx}
                  value={item.original_po}
                  label={item.original_po}
                >
                  {`${item.original_po}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="cut_qty"
            label="Cut Qty"
            rules={[
              {
                required: true,
                message: `Cut Qty is required `,
              },
            ]}
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="assignment_remaining"
            label="Assignment Remaining"
            rules={[
              {
                required: true,
                message: `Assignment Remaining is required `,
              },
            ]}
          >
            <Input disabled={true} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="assign_button" label=" ">
            <Button
              type="primary"
              size="small"
              style={{ width: "100%" }}
              onClick={onOpenDrawer}
              disabled={
                (useBothStylePo === "Yes" &&
                  (!original_po_watch || !internal_po_watch)) ||
                !style_id_watch
              }
            >
              Assign
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Drawer
        title={"Assignment"}
        placement="right"
        onClose={onDrawerClose}
        open={openDrawer}
        width={900}
        extra={
          <Space>
            <Button
              onClick={() => onSubmitAssignment(false)}
              type="primary"
              size="small"
              disabled={isSubmitting}
              // loading={isSubmitting}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <AssignmentForm />
      </Drawer>
    </>
  );
};

export default StylePOForm;
