import React, {useState} from "react";
import BuyerList from "./BuyerList";

const Buyer = () => {
    const [refresh, setRefresh] = useState(0);
    const refetch = () => {
        setRefresh(refresh + 1);
    };

    return <BuyerList  refresh={refresh} refetch={refetch} />
}

export default Buyer;