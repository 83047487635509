import { Col, Form, Row, Select, Input } from "antd";

const OrganizationFilterFields = ({ initialTableData }) => {
    const { Option } = Select;

    return (
        <>
            <Col span={4}>
                <Form.Item label="Org Name" name="name">
                    <Input size="small" placeholder="Write Organization Name"/>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label="Org Short Name" name="short_name">
                    <Input size="small" placeholder="Write Organization Short Name"/>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label="Business Type" name="business_type">
                    <Select
                        showSearch
                        placeholder="Select Business Type"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option?.label
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                        }}
                        size="small"
                        // disabled={loading}
                    >
                        <Option value={"Garments"} key={1} label={"Garments"}>
                            {"Garments"}{" "}
                        </Option>
                        <Option
                            value={"Interlining"}
                            key={2}
                            label={"Interlining"}
                        >
                            {"Interlining"}{" "}
                        </Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label="Status" name="status">
                    <Select
                        showSearch
                        placeholder="Select Status"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option?.label
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                        }}
                        size="small"
                        // disabled={loading}
                    >
                        <Option value={"1"} key={1} label={"Active"}>
                            {"Active"}{" "}
                        </Option>
                        <Option value={"0"} key={2} label={"Inactive"}>
                            {"Inactive"}{" "}
                        </Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label="Address" name="address">
                    <Input size="small" placeholder="Write Address"/>
                </Form.Item>
            </Col>
        </>
    );
};

export default OrganizationFilterFields;
