import {
    GET_MERCHANDISER_LIST,
    CREATE_NEW_MERCHANDISER,
    DELETE_MERCHANDISER,
    GET_MERCHANDISER_DETAIL,
    UPDATE_MERCHANDISER_DETAIL
} from '../ActionTypes';

const initialState = {
    merchandiserList: [],
    totalMerchandiser: null,
    selectedMerchandiser: null,
    createdMerchandiser: null,
    pageSize: 10,
    page:1,
};

const MerchandiserReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_MERCHANDISER_LIST:
            return {
                ...state,
                merchandiserList: action.payload.data,
                totalMerchandiser: action.payload.total,
                pageSize: action.payload.per_page,
                page: action.payload.current_page
            };



        case CREATE_NEW_MERCHANDISER:
            return {
                ...state,
                createdMerchandiser: action.payload.data,
                merchandiserList: [action.payload.data, ...state.merchandiserList],
                totalMerchandiser: state.totalMerchandiser + 1,
            };

        case DELETE_MERCHANDISER: {
            return {
                ...state,
                merchandiserList:  state.merchandiserList.filter(element => element !== action.payload),
                totalMerchandiser: action.payload.total,
                selectedMerchandiser: action.payload,
            };
        }

        case GET_MERCHANDISER_DETAIL:
            return {
                ...state,
                selectedMerchandiser: action.payload,
            };

        case UPDATE_MERCHANDISER_DETAIL:
            return {
                ...state,
                selectedMerchandiser: action.payload,
                merchandiserList: state.merchandiserList.map((merchandiser) =>
                    merchandiser.id === action.payload.id ? action.payload : merchandiser,
                ),
            };

        default:
            return state;
    }
};

export default MerchandiserReducer;
