import React from "react";
import { Form, Input, Radio, Select, Col, Row, Checkbox } from "antd";

const ItemCategoryForm = (props) => {
  // Props
  const { onChangeCheckbox, onChangeCheckboxcategory_wise } = props;

  return (
    <Row gutter={6}>
      <Col span={12}>
        <Form.Item
          name="name"
          label="Item Category"
          rules={[
            {
              required: true,
              message: "Please input the title of collection!",
            },
          ]}
        >
          <Input placeholder="Ex: Item Category" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Attributes" name="attribute_name">
          <Select
            mode="tags"
            style={{
              width: "100%",
              marginBottom: 10,
            }}
            placeholder="Attribute"
          ></Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="status" label="Status" required>
          <Radio.Group>
            <Radio value="active">Active</Radio>
            <Radio value="inactive">Inactive</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="use_fabric_interlining_store"
          label=""
          valuePropName="checked"
        >
          <Checkbox onChange={onChangeCheckbox}>
            Use Fabric Interlining Store
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="category_wise_view_in_pi"
          label=""
          valuePropName="checked"
        >
          <Checkbox onChange={onChangeCheckboxcategory_wise}>
            Category Wise View In PI
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
};
export default ItemCategoryForm;
