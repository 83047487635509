import { forwardRef, useState, useEffect, useCallback,useRef } from "react";
import {
  Card,
  DatePicker,
  InputNumber,
} from "antd";
import { Row, Col, Form, Input, Select,Divider, Space, Button } from "antd";
import moment from "moment";
import {
  ALL_SALES_CONTRACT_COMMERCIAL_LIST,
  EXPORT_DETAILS_ADD,
  GET_EXPORT_DETAILS_LIST,
  SALES_CONTRACT_LIST,
  EXPORT_DETAILS_DOC_UPLOAD,
  EXPORT_DETAILS_FROMS_RESOURCES,
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import { useNavigate } from "react-router-dom";
import ExportPOTable from "./ExportPOTable";
import { commaSeparateNumber, getCountryList, isArrayAndHasValue } from "../../../../utils/functions";
import DocumentUpload from "../../../Common/DocumentUpload";
import HandleNotification from "../../../../common/Notification";
import { FilePdfOutlined, MinusOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import CustomDatePicker from "common/CustomDatePicker";

const ExportDetailsForm = forwardRef((props, ref) => {
  // Props
  const { form, editMode, exportId, poFormEditValues, isDeferredProp, isView } = props;

  // States
  const [salesContractList, setSalesContractList] = useState([]);
  const [isDeferred, setIsDeferred] = useState(isDeferredProp ? true : false);
  const [isSalesContractSelected, setIsSalesContractSelected] = useState(false);
  const [salesContractDetails, setSalesContractDetails] = useState(null);
  const [poDetailsData, setPoDetailsData] = useState([{unique_id: 0}]);
  const [filesList, setFileList] = useState([]);
  // const [poFilesList, setPoFilesList] = useState(Array(poDetailsData.length).fill(null));

  const [addedFrom, setAddedFrom] = useState(null);
  const [froms, setFroms] = useState([]);
  const inputRef = useRef(null);

  // Router
  const navigate = useNavigate();

  // Antd
  const sales_contract_id_watch = Form.useWatch("sales_contract_id", form);
  const [poDetailsForm] = Form.useForm();

  // Constants
  const customHouse = [
    { id: 101, name: "101-(Dhaka Customs House)" },
    { id: 102, name: "102-(ICD, Kamalapur)" },
    { id: 301, name: "301-(Customs House, Chattogram) " },
    { id: 303, name: "303-(Chattogram EPZ)" },
    { id: 381, name: "381-(Comilla EPZ)" },
    { id: 601, name: "601-(Benapole Customs House)" },
    { id: 752, name: "752-(Pangaon Customs House) " },
    { id: 803, name: "803-(DEPZ)" },
    { id: 804, name: "804-(AEPZ)" }, 
    { id: 501, name: "501-(Mongla Customs House)" },
    { id: 503, name: "503-(Payra Customs House)" },
  ];
  const paymentTerms = [
    { id: 1, name: "Deferred" },
    { id: 2, name: "Sight" },
    { id: 3, name: "TT" },
  ];
  const paymentTermsDeferred = [
    { id: 1, value: 30 },
    { id: 2, value: 60 },
    { id: 3, value: 90 },
    { id: 4, value: 120 },
    { id: 5, value: 150 },
    { id: 6, value: 180 },
  ];
  const originPorts = [
    { id: 1, name: "CHITTAGONG" },
    { id: 2, name: "DHAKA" },
    { id: 3, name: "KAMALAPUR" },
    { id: 4, name: "BENAPOLE" },
  ];

  
  const shipmentWays = [
    { id: 1, name: "SEA" },
    { id: 2, name: "AIR" },
    { id: 3, name: "ICD" },
    { id: 4, name: "BENAPOLE" },
  ];
  const shipmentModes = [
    { id: 1, name: "FOB" },
    { id: 2, name: "FCA" },
    { id: 3, name: "CPT" },
    { id: 4, name: "CFR" },
    { id: 5, name: "CIF" },
    { id: 6, name: "DDP" },
    { id: 7, name: "DDU" },
    { id: 8, name: "DAP" },
    { id: 9, name: "DAT" },
  ];

  // Effects
  useEffect(() => {
    getFromsList();
  }, []);

  useEffect(() => {
    if(editMode && poFormEditValues) {
      poDetailsForm.setFieldsValue(poFormEditValues);

      // Set PO Details Data to render number of rows in po details table
      const poDetailsDataCopy = [];
      poFormEditValues?.po_numbers_list?.forEach((item,index) => {
        poDetailsDataCopy.push({
          unique_id: poDetailsDataCopy.length > 0 ? poDetailsDataCopy[poDetailsDataCopy.length - 1].unique_id + 1 : 0,
          // document: poFormEditValues?.po_doc?.[index]?.po_doc || null,
          // document_name: poFormEditValues?.po_doc?.[index]?.po_doc_name || null,
        });
      });

      setPoDetailsData(poDetailsDataCopy);

      // poFormEditValues?.po_doc?.forEach((item, index) => {
      //   poFilesList[index] = item?.doc_id || null;
      // });
      
      // setPoFilesList(poFilesList);

      // Set files
      setFileList(poFormEditValues?.documents || []);
    }
  }, [editMode, poFormEditValues, poDetailsForm]);

  useEffect(() => {
    // Set payment method `Deferred` flag to true if `isDeferredProp` is true
    if(isDeferredProp) {
      setIsDeferred(true);
    }
  }, [isDeferredProp]);

  const getSalesContractList = useCallback(async () => {
    let response = await getData(ALL_SALES_CONTRACT_COMMERCIAL_LIST);

    if (response && response?.status === 200) {
      setSalesContractList(response?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  const onSelectSalesContract = useCallback(
    (value) => {
      const selectedSalesContract = salesContractList.find(
        (item) => item.sales_contract_id === value,
      );

      selectedSalesContract && setIsSalesContractSelected(true);

      const contractValue = selectedSalesContract?.contract_value || "N/A";
      const contractDate = selectedSalesContract?.contract_date || "N/A";
      const udNumber = selectedSalesContract?.ud_number || "N/A";
      const buyerName = selectedSalesContract?.buyer_name || "N/A";
      const buyerId = selectedSalesContract?.buyer_id || "N/A";
      const expiryDate = selectedSalesContract?.expiry_date || "N/A";
      const buyingOfficeName = selectedSalesContract?.buying_office_name || "N/A";
      const buyingOfficeId = selectedSalesContract?.buying_office_id || "N/A";
      const shipmentDate = selectedSalesContract?.shipment_date || null;
      const buyerAddress = selectedSalesContract?.buyer_address || null;

      // Change main form values
      form.setFieldsValue({ sales_contract_master_lc_value: contractValue });
      form.setFieldsValue({ sales_contract_date: contractDate });
      form.setFieldsValue({ ud_number: udNumber });
      form.setFieldsValue({ expiry_date: expiryDate });
      form.setFieldsValue({ buyer_name: buyerName });
      form.setFieldsValue({ buyer_id: buyerId });
      form.setFieldsValue({ buying_office_name: buyingOfficeName });
      form.setFieldsValue({ buying_office_id: buyingOfficeId });
      form.setFieldsValue({ shipment_date: shipmentDate });
      form.setFieldsValue({ buyer_address: buyerAddress });

    },
    [form, salesContractList],
  );

  const getSalesContractDetails = useCallback(async(id) => {
    const endpoint = `${SALES_CONTRACT_LIST}/${id}/edit`;
    const response = await getData(endpoint);


    if(response && response?.status === 200) {
      setSalesContractDetails(response?.data?.data || null);
    }
    else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  // Effects
  useEffect(() => {
    getSalesContractList();
  }, [getSalesContractList]);

  useEffect(() => {
    // Get sales contract details if sales contract selected
    if(sales_contract_id_watch) {
      getSalesContractDetails(sales_contract_id_watch);
    }
  }, [sales_contract_id_watch, getSalesContractDetails]);

  useEffect(() => {
    if (editMode) {
      sales_contract_id_watch && onSelectSalesContract(sales_contract_id_watch);
    }
  }, [editMode, sales_contract_id_watch, onSelectSalesContract]);

  const onFinishExportAdd = async (values) => {
     
    const poDetailsFormValues = poDetailsForm.getFieldsValue();
    
    const bodyData = {
      buying_office_id: values?.buying_office_id || null,
      buyer_id: values?.buyer_id || null,
      sales_contract_id: values?.sales_contract_id || null,
      total_quantity: values?.total_quantity || null,
      carton_quantity: values?.carton_quantity || null,
      shipment_date: values?.shipment_date || null,
      payment_term: values?.payment_term || null,

      payment_maturity_date: values.payment_maturity_date
        ? moment(values?.payment_maturity_date).format("YYYY-MM-DD")
        : null,
      invoice_number: values?.invoice_number || null,
      invoice_date: values.invoice_date
        ? moment(values?.invoice_date).format("YYYY-MM-DD")
        : null,
      exp_number: values?.exp_number || null,
      exp_date: values.exp_date
        ? moment(values?.exp_date).format("YYYY-MM-DD")
        : null,
      po_map_id:
        poDetailsFormValues?.po_numbers_list?.map((item) => item?.po_map_id) ||
        null,
      style_id:
        poDetailsFormValues?.style_numbers_list?.map(
          (item) => item?.style_id,
        ) || null,
      sub_style_id:
        poDetailsFormValues?.sub_style_numbers_list?.map(
          (item) => item?.sub_style_id,
        ) || null,
      goods_description:
        poDetailsFormValues?.goods_description_list?.map(
          (item) => item?.goods_description,
        ) || null,
      po_qty:
        poDetailsFormValues?.sales_order_qty_list?.map(
          (item) => item?.sales_order_qty,
        ) || null,
      carton_qty:
        poDetailsFormValues?.carton_qty_list?.map((item) => item?.carton_qty) ||
        null,
      ship_qty:
        poDetailsFormValues?.ship_qty_list?.map((item) => item?.ship_qty) ||
        null,
      ship_qty_unit:
        poDetailsFormValues?.ship_qty_unit_list?.map(
          (item) => item?.ship_qty_unit,
        ) || null,
      ship_value:
        poDetailsFormValues?.ship_value_list?.map((item) => item?.ship_value) ||
        null,
      balance_qty:
        poDetailsFormValues?.balance_qty_list?.map(
          (item) => item?.balance_qty,
        ) || null,
      balance_qty_unit:
        poDetailsFormValues?.balance_qty_unit_list?.map(
          (item) => item?.balance_qty_unit,
        ) || null,
      balance_value:
        poDetailsFormValues?.balance_value_list?.map(
          (item) => item?.balance_value,
        ) || null,
      unit_price:
        poDetailsFormValues?.unit_price_list?.map((item) => item?.unit_price) ||
        null,
      booking_submission_date: values.booking_submission_date
        ? moment(values?.booking_submission_date).format("YYYY-MM-DD")
        : null,
      ex_factory_date: values.ex_factory_date
        ? moment(values?.ex_factory_date).format("YYYY-MM-DD")
        : null,
      hand_over_date: values.hand_over_date
        ? moment(values?.hand_over_date).format("YYYY-MM-DD")
        : null,
      boe_number: values?.boe_number || null,
      boe_date: values.boe_date
        ? moment(values?.boe_date).format("YYYY-MM-DD")
        : null,
      bl_awbl_number: values?.bl_awbl_number || null,
      on_board_date: values.on_board_date
        ? moment(values?.on_board_date).format("YYYY-MM-DD")
        : null,
      shipment_way: values?.shipment_way || null,
      origin_port: values?.origin_port || null,
      destination_country: values?.destination_country || null,
      mode_of_shipment: values?.mode_of_shipment || null,
      invoice_value: values?.invoice_value || null,
      payment_term_deferred: values?.payment_term_deferred || null,
      froms: values?.from_date || null,
      // from_date: values.from_date
      //   ? moment(values?.from_date).format("YYYY-MM-DD")
      //   : null,
      documents: isArrayAndHasValue(filesList)
        ? filesList?.map((item) => {
            return {
              id: item?.id,
              document_name: item?.name,
              is_delete: item?.is_delete || 0,
            };
          })
        : [],
      customs_house_code: values?.customs_house_code,
      // po_documents: poFilesList ?? [],
    };

    const shipValues = bodyData.ship_value || [];
    const balanceValues = bodyData.balance_value || [];
  
    let hasNegativeValue = false;
  
    shipValues.forEach((value) => {
      if (value < 0) {
        hasNegativeValue = true;
        return;
      }
    });
  
    if (!hasNegativeValue) {
      balanceValues.forEach((value) => {
        if (value < 0) {
          hasNegativeValue = true;
          return;
        }
      });
    }

    // Display alert if a negative value is found
    if (hasNegativeValue) {
      alertPop("error", "Ship/Balance value Found negative value");
    }
  

    if (editMode) {
      // Edit Export Details API call
      const response = await postData(
        `${GET_EXPORT_DETAILS_LIST}/${exportId}/edit`,
        bodyData,
      );

      if (response) {
        alertPop("success", "Export Details Updated Successfully");
        //navigate("/export/export-details");
      } else {
        alertPop("error", "Sales Contract Update Failed!");
      }
    } else {
      // Add New Export Details API call
      const response = await postData(EXPORT_DETAILS_ADD, bodyData);

      if (response) {
        alertPop("success", "Export Details Added Successfully");
        const id = response?.data?.id;
        navigate("/export/edit-export-details/"+id);
      } else {
        alertPop("error", "Sales Contract Add Failed!");
      }
    }
  };


  const onSelectPaymentTerm = (value) => {
    if (value === "Deferred") {
      setIsDeferred(true);
    } else {
      setIsDeferred(false);
    }
  };

  const getFromsList = async (filterValues) => {
    const query = `${EXPORT_DETAILS_FROMS_RESOURCES}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 1000,
      no_pagination: true,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setFroms(response?.data?.data || []);
    }
  };

  const onFromChange = (event) => {
    setAddedFrom(event.target.value);
  };

  const addNewFrom = async (event) => {
    event.preventDefault();
    const query = `${EXPORT_DETAILS_FROMS_RESOURCES}`;
    const bodyData = {
      name: addedFrom,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setAddedFrom(null);
      HandleNotification(
        "success",
        "bottomRight",
        "Export Details From Item added successfully",
        null,
      );
      getFromsList();
    }
  };
console.log('filesList', filesList);

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishExportAdd}
      >
        <Card>
          <Row gutter={24}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Invoice Number"
                name="invoice_number"
                rules={[
                  {
                    required: true,
                    message: "Please input Invoice Number!",
                  },
                ]}
              >
                <Input placeholder="Invoice Number" size="small" disabled={isView} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Invoice Date" name="invoice_date" rules={[
                  {
                    required: true,
                    message: "Please input Invoice Date!",
                  },
                ]}>
                <CustomDatePicker
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Invoice Value" name="invoice_value">
                <Input placeholder="Invoice Value" disabled size="small"/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract/ Master LC"
                name="sales_contract_id"
                rules={[
                  {
                    required: true,
                    message: "Please input Sales Contract!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Sales Contract / Master LC"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    onSelectSalesContract(value);
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  size="small"
                  disabled={isView}
                >
                  {salesContractList.map((option) => {
                    return (
                      <option
                        key={option.sales_contract_id}
                        value={option.sales_contract_id}
                      >
                        {option?.reference_no}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label=" Value"
                name="sales_contract_master_lc_value"
              >
                <Input
                  placeholder="Sales Contract - Master LC Value"
                  disabled
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract Date"
                name="sales_contract_date"
              >
                <Input
                  size="small"
                  placeholder="Sales Contract - Master LC Value"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="UD Number"
                name="ud_number"
              >
                <Input
                  size="small"
                  placeholder="UD Number"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Buyer/Consignee Name"
                name="buyer_name"
                rules={[
                  {
                    required: true,
                    message: "Please input Buyer/Consignee Name!",
                  },
                ]}
              >
                <Input
                  size="small"
                  placeholder="Buyer/Consignee Name"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Buyer/Consignee Address"
                name="buyer_address"
              >
                <Input
                  size="small"
                  placeholder="Buyer/Consignee Address"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Buyer/Consignee Office"
                name="buying_office_name"
                rules={[
                  {
                    required: true,
                    message: "Please input Buyer/Consignee Office!",
                  },
                ]}
              >
                <Input
                  size="small"
                  placeholder="Buyer/Consignee Office"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={0}>
              <Form.Item label="Buyer ID (HIDDEN)" name="buyer_id" hidden />
            </Col>
            <Col className="gutter-row" span={0}>
              <Form.Item
                label="Buying Office ID (HIDDEN)"
                name="buying_office_id"
                hidden
              />
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Invoice Quantity" name="total_quantity">
                <InputNumber
                  size="small"
                  placeholder="Invoice Quantity"
                  disabled
                  style={{width: '100%'}}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                />
              </Form.Item>
            </Col> 
            <Col className="gutter-row" span={4}>
              <Form.Item label="Carton Quantity" name="carton_quantity">
                <InputNumber
                  size="small"
                  placeholder="Carton Quantity"
                  disabled
                  style={{width: '100%'}}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Payment Terms" name="payment_term"
                rules={[
                  {
                    required: true,
                    message: "Please input Payment Terms!",
                  },
                ]}
              >
                <Select
                  placeholder="Payment Terms"
                  style={{ width: "100%" }}
                  onChange={(value) => onSelectPaymentTerm(value)}
                  size="small"
                  disabled={isView}
                >
                  {paymentTerms.map((option) => {
                    return (
                      <option key={option.id} value={option.name}>
                        {option?.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            {isDeferred && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Deferred days"
                    name="payment_term_deferred"
                    rules={[
                      {
                        required: true,
                        message: "Please input Deferred days!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Deferred days"
                      style={{ width: "100%" }}
                      size="small"
                      disabled={isView}
                    >
                      {paymentTermsDeferred.map((option) => {
                        return (
                          <option key={option.id} value={option.value}>
                            {option.value}
                          </option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>


                {/* <Col className="gutter-row" span={8}>
                  <Form.Item label="From" name="from_date">
                    <Input
                      style={{ width: "100%" }}
                      size="small"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col> */}

                <Col className="gutter-row" span={4}>
                  <Form.Item label="From" name="from_date">
                    {/* <Input
                      style={{ width: "100%", color: "#000 !important" }}
                      disabled={hide}
                    /> */}
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="From"
                      mode="multiple"
                      disabled={isView}
                      size="small"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={addedFrom}
                              onChange={onFromChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addNewFrom}
                            >
                              Add
                            </Button>
                          </Space>
                        </>
                      )}
                      options={
                        froms &&
                        froms.map((item) => ({
                          label: item?.name,
                          value: item?.id,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col className="gutter-row" span={4}>
              <Form.Item label="EXP Number" name="exp_number">
                <Input
                  size="small"
                  className="w-100"
                  placeholder="EXP Number"
                  disabled={isView}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="EXP Date" name="exp_date">
                <CustomDatePicker
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Booking Submission Date- Online/Manual"
                name="booking_submission_date"
              >
                <CustomDatePicker
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Ex-Factory Date" name="ex_factory_date">
                <CustomDatePicker
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Hand Over Date" name="hand_over_date">
                <CustomDatePicker
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Bill of Export Number" name="boe_number">
                <Input size="small" placeholder="Bill of Export Number" disabled={isView} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Bill of Export Date" name="boe_date">
                <CustomDatePicker
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Customs House Code" name="customs_house_code">
                <Select
                  placeholder="Customs House Code"
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                >
                  {customHouse.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="BL/AWBL Number" name="bl_awbl_number">
                <Input size="small" placeholder="BL/AWBL Number" disabled={isView} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="On Board Date" name="on_board_date">
                <CustomDatePicker
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Mode Of Shipment" name="shipment_way">
                <Select
                  placeholder="Mode Of Shipment"
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                >
                  {shipmentWays.map((option) => {
                    return (
                      <option key={option.id} value={option.name}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={4}>
              <Form.Item label="Origin Port" name="origin_port">
                <Select placeholder="Origin Port" style={{ width: "100%" }} size="small">
                  {originPorts.map((option) => {
                    return (
                      <option key={option.id} value={option.name}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col> */}
            <Col className="gutter-row" span={4}>
              <Form.Item label="Destination Country" name="destination_country">
              <Select
                  showSearch
                  placeholder="Destination Country"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  size="small"
                  disabled={isView}
              >
                  {getCountryList().map((option) => {
                    return (
                      <option key={option.id} value={option.name}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Trade/Freight Term" name="mode_of_shipment" rules={[
                  {
                    required: true,
                    message: "Please input Trade/Freight Term!",
                  },
                ]}>
                <Select
                  placeholder="Trade/Freight Term"
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isView}
                >
                  <option value="EXW (Ex-Works)">EXW (Ex-Works)</option>
                  <option value="FCA (Free Carrier)">FCA (Free Carrier)</option>
                  <option value="FAS (Free Alongside Ship)">
                    FAS (Free Alongside Ship)
                  </option>
                  <option value="FOB (Free on Board)">
                    FOB (Free on Board)
                  </option>
                  <option value="CFR (Cost & Freight)">
                    CFR (Cost & Freight)
                  </option>
                  <option value="CIF (Cost Insurance & Freight)">
                    CIF (Cost Insurance & Freight)
                  </option>
                  <option value="CPT ( Carriage Paid To)">
                    CPT ( Carriage Paid To)
                  </option>
                  <option value="CIP (Carriage Insurance Paid To)">
                    CIP (Carriage Insurance Paid To)
                  </option>
                  <option value="DPU (Delivered at Place Unloaded)">
                    DPU (Delivered at Place Unloaded)
                  </option>
                  <option value="DAT (Delivered At Terminal)">
                    DAT (Delivered At Terminal)
                  </option>
                  <option value="DAP (Delivered at Place)">
                    DAP (Delivered at Place)
                  </option>
                  <option value="DDP (Delivered Duty Paid)">
                    DDP (Delivered Duty Paid)
                  </option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>

      <DocumentUpload
        DOC_ADD={EXPORT_DETAILS_DOC_UPLOAD}
        setFileList={setFileList}
        filesList={filesList}
        preview={true}
        singleFile={true}
        view={isView}
      />
      
      {isSalesContractSelected && (
        <Form
          layout="vertical"
          form={poDetailsForm}
        >
          <ExportPOTable
            poDetailsData={poDetailsData}
            salesContractDetails={salesContractDetails}
            setPoDetailsData={setPoDetailsData}
            poDetailsForm={poDetailsForm}
            salesContractId={sales_contract_id_watch}
            form={form}
            isView={isView}
            // poFilesList={poFilesList}
            // setPoFilesList={setPoFilesList}
          />
        </Form>
      )}
    </div>
  );
});

export default ExportDetailsForm;
